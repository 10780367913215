import { List, fromJS } from 'immutable';
import { SearchState } from 'types/searchState';
import { ImmutableUser } from 'types/types';
import {
  SEARCH_CHANGE,
  SEARCH_CUSTOMERS_REQUEST,
  SEARCH_CUSTOMERS_RECEIVE,
  SEARCH_CUSTOMERS_FAILURE,
  SEARCH_CLEAR,
  SEARCH_VISIBILITY_FILTER,
  LAST_USERS_CHANGE,
  SearchCustomersAction,
} from './searchActions';

export const initialState: SearchState = {
  isSearching: false,
  showSearch: false,
  query: '',
  searchResults: List(),
  totalCount: 0,
  lastUsers: {
    searched: [],
    added: [],
  },
  visibilityFilter: false,
  hydrated: false,
};

function sortByOwnCustomers(searchedCustomers: List<ImmutableUser>, managerId: string | null) {
  if (managerId) {
    return searchedCustomers.sortBy((customer) => {
      if (customer?.get('accountManagerId', '') === managerId) {
        return -1;
      }
      return 0;
    });
  }
  return searchedCustomers;
}

export function searchReducer(state = initialState, action: SearchCustomersAction) {
  // https://github.com/reactjs/redux/issues/433
  if (!state.hydrated) {
    state = {
      ...initialState,
      ...state,
      hydrated: true,
    };
  }
  switch (action.type) {
    case SEARCH_CHANGE:
      return {
        ...state,
        query: action.query,
      };
    case SEARCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        showSearch: true,
        isSearching: true,
        searchResults: state.searchResults.clear(),
        totalCount: 0,
      };
    case SEARCH_CUSTOMERS_RECEIVE:
      return {
        ...state,
        isSearching: false,
        showSearch: true,
        searchResults: sortByOwnCustomers(fromJS(action.result.results), action.managerId),
        totalCount: action.result.totalResults,
      };
    case SEARCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        isSearching: false,
        showSearch: true,
      };
    case SEARCH_CLEAR:
      return {
        ...state,
        showSearch: false,
        query: '',
        searchResults: state.searchResults.clear(),
        totalCount: 0,
      };
    case SEARCH_VISIBILITY_FILTER:
      return {
        ...state,
        visibilityFilter: action.filter,
      };
    case LAST_USERS_CHANGE:
      return {
        ...state,
        lastUsers: {
          searched: action.lastUsers,
        },
      };
    default:
      return state;
  }
}
