import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import {
  getBondOrderLine,
  getFundOrderLine,
  getPrivateEquityOrderLine,
  getShareOrderLine,
  getStructuredProductOrderLine,
  getWarrantOrderLine,
  isListed,
} from 'core/portfolios';
import {
  FUND,
  STRUCTURED_PRODUCT,
  WARRANT,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  SHARE,
  ETF,
  CERTIFICATE,
  SUBSCRIPTION_RIGHT,
  OPTION,
  BOND,
  InstrumentForm,
} from 'constants/instrumentForms';
import { SELL } from 'constants/sides';
import { ORDER } from 'constants/orderRowTypes';
import { WRITTEN_METHOD } from 'constants/shareOptions';
import { RootState } from 'types/rootState';
import { MinutesOfInvestmentServiceType, OrderLineType } from 'types/ordersState';
import { OrderSideType } from 'types/orderDialogState';
import { ImmutableOrderLineTypeList } from 'types/orderLinesState';

export const selectOrderLines = (state: RootState): ImmutableOrderLineTypeList => {
  return state.orderLines.orderLines;
};

export const selectOrderLinesByPortfolioId = (state: RootState, portfolioId: string): ImmutableOrderLineTypeList => {
  return state.orderLines.orderLines.filter((x) => x?.toJS().portfolioId === portfolioId);
};

const selectOrderLinesByOrderLineIds = (state: RootState, orderLineIds: number[], portfolioId: string) => {
  return state.orderLines.orderLines.filter(
    (line) => orderLineIds?.includes(line?.get('_id')) && line?.get('portfolioId') === portfolioId
  );
};

export const selectContractOrderLines = createSelector(selectOrderLines, (orderLines: ImmutableOrderLineTypeList) => {
  return fromJS(
    orderLines.toJS().map((line: OrderLineType) => {
      let newLine;
      switch (line?.financialInstrumentForm) {
        case FUND:
          newLine = getFundOrderLine(line);
          break;
        case STRUCTURED_PRODUCT:
          newLine = getStructuredProductOrderLine(line);
          break;
        case WARRANT:
          newLine = getWarrantOrderLine(line);
          break;
        case PRIVATE_EQUITY:
        case PRIVATE_EQUITY_PROFIT_SHARING_LOAN:
        case CO_INVESTMENTS:
        case CO_INVESTMENTS_PROFIT_SHARING_LOAN:
          newLine = getPrivateEquityOrderLine(line);
          break;
        case SHARE:
        case ETF:
        case CERTIFICATE:
        case SUBSCRIPTION_RIGHT:
        case OPTION:
          newLine = getShareOrderLine(line);
          break;
        case BOND:
          newLine = getBondOrderLine(line);
          break;
        default:
          newLine = {} as OrderLineType;
          break;
      }
      if (!line.minutesOfInvestmentService) {
        return;
      }
      newLine.minutesOfInvestmentService = line.minutesOfInvestmentService;
      return newLine;
    })
  );
});

export const selectContractOrderLinesByPortfolioId = (state: RootState, portfolioId: string) => {
  const orderLines = selectOrderLinesByPortfolioId(state, portfolioId);
  return fromJS(
    orderLines.toJS().map((line: OrderLineType) => {
      switch (line?.financialInstrumentForm) {
        case FUND:
          return getFundOrderLine(line);
        case STRUCTURED_PRODUCT:
          return getStructuredProductOrderLine(line);
        case WARRANT:
          return getWarrantOrderLine(line);
        case PRIVATE_EQUITY:
        case PRIVATE_EQUITY_PROFIT_SHARING_LOAN:
        case CO_INVESTMENTS:
        case CO_INVESTMENTS_PROFIT_SHARING_LOAN:
          return getPrivateEquityOrderLine(line);
        case SHARE:
        case ETF:
        case CERTIFICATE:
        case SUBSCRIPTION_RIGHT:
        case OPTION:
          return getShareOrderLine(line);
        case BOND:
          return getBondOrderLine(line);
        default:
          return [];
      }
    })
  );
};

export const selectContractOrderLinesByOrderLineIds = (
  state: RootState,
  orderLineIds: number[],
  portfolioId: string
): OrderLineType[] => {
  const lines = selectOrderLinesByOrderLineIds(state, orderLineIds, portfolioId);
  return fromJS(
    lines.toJS().map((line: OrderLineType) => {
      let newLine: OrderLineType;
      const minutesOfInvestmentService = line.minutesOfInvestmentService;
      switch (line?.financialInstrumentForm) {
        case FUND:
          newLine = getFundOrderLine(line);
          break;
        case STRUCTURED_PRODUCT:
          newLine = getStructuredProductOrderLine(line);
          break;
        case WARRANT:
          newLine = getWarrantOrderLine(line);
          break;
        case PRIVATE_EQUITY:
        case PRIVATE_EQUITY_PROFIT_SHARING_LOAN:
        case CO_INVESTMENTS:
        case CO_INVESTMENTS_PROFIT_SHARING_LOAN:
          newLine = getPrivateEquityOrderLine(line);
          break;
        case SHARE:
        case ETF:
        case CERTIFICATE:
        case SUBSCRIPTION_RIGHT:
        case OPTION:
          newLine = getShareOrderLine(line);
          break;
        case BOND:
          newLine = getBondOrderLine(line);
          break;
        default:
          return [];
      }
      if (!minutesOfInvestmentService || someMoisFieldEmpty(minutesOfInvestmentService)) {
        return newLine;
      }
      return withMOISReassigned(newLine, minutesOfInvestmentService);
    })
  );
};

const someMoisFieldEmpty = (mois: MinutesOfInvestmentServiceType) =>
  !!Object.keys(mois).find((key) => mois[key] === '' || !mois[key]);

const withMOISReassigned = (line: OrderLineType, minutesOfInvestmentService: MinutesOfInvestmentServiceType) => {
  line.moisInfo = minutesOfInvestmentService?.info;
  line.moisLocation = minutesOfInvestmentService?.location;
  line.moisParticipants = minutesOfInvestmentService?.participants;
  line.moisDate = minutesOfInvestmentService?.date;
  line.moisConvener = minutesOfInvestmentService?.convener;
  delete line.minutesOfInvestmentService;
  return line;
};

export const selectHasAssignments = (state: RootState) => {
  const hasAssignments = !state.orderLines.orderLines.isEmpty();
  return hasAssignments;
};

export const selectCurrentInstrumentFormByOrderLineId = (state: RootState, orderLineId: number): InstrumentForm => {
  const orderLine = state.orderLines.orderLines.toJS().find((orderLine) => orderLine._id === orderLineId);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return orderLine?.financialInstrumentForm;
};

export const selectCurrentSideByOrderLineId = (state: RootState, orderLineId: number): OrderSideType => {
  const orderLine = state.orderLines.orderLines.toJS().find((orderLine) => orderLine._id === orderLineId);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return orderLine?.side;
};

export const selectHasOnlySellOrdersByPortfolioId = (state: RootState, portfolioId: string) =>
  selectOrderLinesByPortfolioId(state, portfolioId).every((order) => order?.get('side') === SELL);

export const selectOrderReceivedWrittenByPortfolioId = (state: RootState, portfolioId: string) =>
  selectOrderLinesByPortfolioId(state, portfolioId).some((l) => l?.get('receivedFromClientMethod') === WRITTEN_METHOD);

export const selectHasOrdersByPortfolioId = (state: RootState, portfolioId: string) =>
  selectOrderLinesByPortfolioId(state, portfolioId).some((l) => l?.get('rowType') === ORDER);

// AMLT-4690 -> disabled, because the only function using this in selectIsFullPowerButRequiresSignatureByPortfolioIdAndOrderLineId on ordersSelectors.tsx is disabled (commented out)
//export const selectIsCurrentInstrumentListedByOrderLineId = (state: RootState, orderLineId: number) => {
//  const form = selectCurrentInstrumentFormByOrderLineId(state, orderLineId);
//  const side = selectCurrentSideByOrderLineId(state, orderLineId);
//  return isListed(form, side);
//};

export const selectAreOrderLinesListed = (orderLines: OrderLineType[]) => {
  return orderLines.every((line) => isListed(line.financialInstrument?.financialInstrumentForm, line.side));
};

export const selectOrderLinesHaveReceivedWritten = (orderLines: OrderLineType[]) => {
  return orderLines.some((line) => line.receivedFromClientMethod === WRITTEN_METHOD);
};
