import { Map, List } from 'immutable';
import { omitBy } from 'lodash';
import accounting from 'accounting';
import { ImmutableOrderLineType, OrderLineType } from 'types/ordersState';
import { countDecimal } from 'core/index';
import { WARRANT } from 'constants/instrumentForms';
import { isStructuredSellOrder } from 'core/orders';
import { BUY } from 'constants/sides';
import { AddOrderLineAction } from 'features/orderLines/orderLines.types';
import { ImmutableOrderLineTypeList, OrderLinesState } from 'types/orderLinesState';

export const getUnitNumber = (order: OrderLineType) => {
  if (order.subscriptionSum) {
    return `${accounting.formatMoney(order.subscriptionSum)} ${order.currency}`;
  } else if (order.quantity) {
    return `${accounting.formatNumber(order.quantity, countDecimal(order.quantity))} kpl`;
  } else if (order.value) {
    return `${accounting.formatMoney(order.value)} ${order.currency}`;
  } else if (order.amount) {
    return `${accounting.formatMoney(order.amount)} ${order.currency}`;
  } else if (order.notionalAmount) {
    return `${accounting.formatMoney(order.notionalAmount)} ${order.currency}`;
  }
};

export const getFee = (order: OrderLineType) => {
  if (order.financialInstrument.financialInstrumentForm === WARRANT && order.side === BUY) {
    if (!order?.unitFee || !order?.currency) {
      return '-';
    }
    return `${order.unitFee} ${order.currency} / kpl`;
  }

  if (isStructuredSellOrder(order.financialInstrument.financialInstrumentForm, order.side)) {
    return '-';
  }

  if (Number.isFinite(order.subscriptionFee)) {
    return `${order.subscriptionFee?.toLocaleString('fi')} %`;
  }

  return '-';
};

export const getWarrantQuantity = (order: OrderLineType) => {
  if (order.financialInstrument.financialInstrumentForm === WARRANT && order.side === BUY && order?.quantity) {
    return `${accounting.formatNumber(order.quantity, countDecimal(order.quantity))} kpl`;
  }
};

export const addOrderLine = (state: OrderLinesState, action: AddOrderLineAction) => {
  const newLine = omitBy(
    action,
    (value, key) => key === 'type' || (value === 0 && key !== 'unitFee' && key !== 'subscriptionFee')
  );
  const newLineImmutable = Map(newLine);
  const index = state.orderLines.findIndex((l) => l?.get('_id') === newLine._id);

  let orderLines = List() as ImmutableOrderLineTypeList;

  if (index >= 0) {
    // If order line with same id already exists (= user has edited order line before confirming order), update order line
    orderLines = state.orderLines.set(index, newLineImmutable as ImmutableOrderLineType);
  } else {
    // else add new order line
    orderLines = state.orderLines.push(newLineImmutable as ImmutableOrderLineType);
  }

  return orderLines;
};
