import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import translate from 'counterpart';
import { Button } from 'features/allocator/common/Button';
import { FlexColumn, FlexJustifyBetween, HelpText, HelpTextSmall, styles } from 'features/common/StyledComponents';
import { optimize } from 'features/weights/weightsThunks';
import { RootState } from 'types/rootState';
import { clearError } from 'features/errors/errorActions';
import { Spinner } from 'features/common/Spinner';
import {
  isLoadingPlan,
  selectPlanInstrumentsNotInPositions,
  selectPositionsNotInPlan,
} from 'features/allocator/allocatorSelectors';
import { colors } from 'styles/colors';
import { useAppDispatch } from 'core/hooks';
import { convertExistingPositionsToPlan } from 'features/allocator/investmentPlan/investmentPlanActions';

export const UnequalPositions = () => {
  const dispatch = useAppDispatch();

  const positions = useSelector((state: RootState) => state.portfolio.positions);
  const loadingPositions = useSelector((state: RootState) => state.portfolio.loadingPositions);
  const loadingPlan = useSelector(isLoadingPlan);

  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);

  const planInstrumentsNotInPositions = useSelector(
    selectPlanInstrumentsNotInPositions({ removeZeroWeightPlanInstruments: true })
  );

  const hasOpenTrades = positions.some(
    (pos) => pos.marketValueNet.baseCurrency.value !== pos.marketValue.baseCurrency.value
  );

  const recreatePlan = () => {
    dispatch(clearError('allPositionsNotInPlan'));
    dispatch(clearError('allPlanInstrumentsNotInPositions'));
    dispatch(convertExistingPositionsToPlan());
    // force optimization without additional instruments – only use positions in this case:
    dispatch(optimize({ payload: { additionalInstruments: {} } }));
  };

  const UpdatePlan = () => (
    <>
      <StrongGreen>{translate('allocator.positionsNotEqual.updatePlanInfo1')}</StrongGreen>
      <StyledButton
        onClick={recreatePlan}
        label={translate('allocator.positionsNotEqual.button')}
        data-testkey="update-plan"
        secondary
        small
      />
      <HelpTextSmall>{translate('allocator.positionsNotEqual.updatePlanInfo2')}</HelpTextSmall>
    </>
  );

  const loading = loadingPlan || loadingPositions;

  return (
    <NotEqualContainer data-testkey="positions-not-equal">
      <h2>{translate('allocator.positionsNotEqual.title')}</h2>
      <section>
        {planInstrumentsNotInPositions.length > 0 ? (
          <div>
            <HelpText>
              {`${translate('allocator.positionsNotEqual.morePlanInstruments')} ${
                hasOpenTrades ? translate('allocator.positionsNotEqual.hasOpenTrades') : ''
              }`}
            </HelpText>

            <GreyList>
              <li>
                {translate('allocator.positionsNotEqual.bullet1a')}
                <b>{translate('allocator.positionsNotEqual.bullet1b')}</b>
              </li>
              <li>
                {translate('allocator.positionsNotEqual.bullet2a')}
                <b>{translate('allocator.positionsNotEqual.bullet2b')}</b>
              </li>
              <li>
                {translate('allocator.positionsNotEqual.bullet3a')}
                <b>{translate('allocator.positionsNotEqual.bullet3b')}</b>
                <UpdatePlan />
              </li>
            </GreyList>
          </div>
        ) : (
          positionsNotInPlan.length > 0 && (
            <div>
              <HelpText>{translate('allocator.positionsNotEqual.morePositions')}</HelpText>
              <UpdatePlan />
            </div>
          )
        )}

        <div>
          {loading && <Spinner size={30} />}

          {!loading && !!positionsNotInPlan.length && (
            <div>
              <h3>{translate('allocator.positionsNotEqual.positionsNotInPlan')}</h3>
              <ul>
                {positionsNotInPlan.map((p) => (
                  <li key={p.security + p.portfolioId} data-testkey="position-not-in-plan">
                    {p.name}
                  </li>
                ))}
              </ul>
            </div>
          )}

          {!loading && !!planInstrumentsNotInPositions.length && (
            <div>
              <h3>{translate('allocator.positionsNotEqual.planInstrumentsNotInPositions')}</h3>
              <ul>
                {planInstrumentsNotInPositions.map((p) => (
                  <li key={p.security + p.portfolioId} data-testkey="plan-instrument-not-in-positions">
                    <FlexJustifyBetween>
                      <div>{p.name}</div>
                      <div>{p.portfolioId}</div>
                    </FlexJustifyBetween>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </section>
    </NotEqualContainer>
  );
};

const NotEqualContainer = styled(FlexColumn)`
  grid-area: unequalpositions;
  ${styles.criticalMessage};
  ${styles.cardPadding}

  h2 {
    border-bottom: 1px solid #eee;
    padding-bottom: 1rem;
  }

  section {
    display: flex;

    & > div {
      padding-right: 3rem;
      flex: 1;
    }
  }
`;

const StrongGreen = styled.div`
  color: hsl(170deg 74% 33%);
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.25px;
  font-style: italic;
  margin-top: 0.5rem;
`;

const StyledButton = styled(Button)`
  margin: 0.5rem 0;
`;

const GreyList = styled.ul`
  color: ${colors.dark_gray};
  margin: 1rem 0 0 1.2rem;
  list-style: upper-alpha;

  li {
    padding-bottom: 1rem;
    padding-left: 1rem;

    &::marker {
      color: ${colors.black};
      font-weight: 600;
    }

    b {
      color: ${colors.black};
    }
  }
`;
