import {
  ConstraintsAction,
  CUSTOM_CONSTRAINTS_UPDATE,
  OPTIMIZATION_CONSTRAINTS_UPDATE,
  SET_USE_BACKEND_CONSTRAINTS,
} from './investmentPlanConstraintsActions';
import {
  INITIALIZE_INVESTMENT_PLAN_STATE,
  FLAG_CHECK_PORTFOLIOS,
  SET_OPTIMIZATION_FORECAST_TYPE,
  TARGET_RISK_LEVEL_UPDATE,
  TEST_RISK_LEVEL_UPDATE,
  CREATE_PLAN_PORTFOLIOS,
  InvestmentPlanAction,
  SET_PLAN_STATE,
  GET_INVESTMENT_PLAN_NOT_FOUND,
  SET_LOADING_PLAN,
} from './investmentPlanActions';
import {
  InstrumentsAction,
  SET_INSTRUMENTS_SELECTED,
  SET_SELECTED_INSTRUMENT_GROUP,
} from 'features/allocator/investmentPlan/investmentPlanInstrumentsActions';
import { InvestmentPlanState } from 'types/investmentPlanState';
import { ConstraintsState } from 'types/constraintsState';
import { InstrumentsState } from 'types/instrumentsState';

const initialConstraintsState: ConstraintsState = {
  optimizationConstraints: [],
  customConstraints: [],
  useBackendConstraints: true,
  errors: [],
};

const initialInstrumentsState: InstrumentsState = {
  instrumentsSelected: [],
  selectedInstrumentGroup: '',
};

const initialState: InvestmentPlanState = {
  planPortfolios: [],
  planState: 'notLoaded',
  loadingPlan: false,
  optimizationForecastType: 'company',
  targetRiskLevel: 0,
  testRiskLevel: 0,
  flagCheckPortfolios: false,
  instruments: initialInstrumentsState,
  constraints: initialConstraintsState,
};

export function portfoliosReducer(
  state = initialState,
  action: InvestmentPlanAction | InstrumentsAction | ConstraintsAction
) {
  switch (action.type) {
    case INITIALIZE_INVESTMENT_PLAN_STATE:
      return {
        ...initialState,
      };

    case TARGET_RISK_LEVEL_UPDATE:
      return {
        ...state,
        targetRiskLevel: action.result,
      };

    case TEST_RISK_LEVEL_UPDATE:
      return {
        ...state,
        testRiskLevel: action.result,
      };

    case CREATE_PLAN_PORTFOLIOS:
      return {
        ...state,
        planPortfolios: action.result,
      };

    case FLAG_CHECK_PORTFOLIOS: {
      return {
        ...state,
        flagCheckPortfolios: action.payload,
      };
    }

    case SET_LOADING_PLAN:
      return {
        ...state,
        loadingPlan: action.value,
      };

    case GET_INVESTMENT_PLAN_NOT_FOUND:
      return {
        ...state,
        planState: 'noPlan',
      };

    case SET_PLAN_STATE:
      return {
        ...state,
        planState: action.payload,
      };

    case SET_OPTIMIZATION_FORECAST_TYPE:
      return {
        ...state,
        optimizationForecastType: action.value,
      };

    case SET_INSTRUMENTS_SELECTED:
      return {
        ...state,
        instruments: {
          ...state.instruments,
          instrumentsSelected: action.result,
        },
      };

    case SET_SELECTED_INSTRUMENT_GROUP:
      return {
        ...state,
        instruments: {
          ...state.instruments,
          selectedInstrumentGroup: action.result,
        },
      };

    case CUSTOM_CONSTRAINTS_UPDATE:
      return {
        ...state,
        constraints: {
          ...state.constraints,
          customConstraints: action.result,
        },
      };

    case OPTIMIZATION_CONSTRAINTS_UPDATE:
      return {
        ...state,
        constraints: {
          ...state.constraints,
          optimizationConstraints: action.result,
        },
      };

    case SET_USE_BACKEND_CONSTRAINTS:
      return {
        ...state,
        constraints: {
          ...state.constraints,
          useBackendConstraints: action.value,
        },
      };

    default:
      return state;
  }
}
