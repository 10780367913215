import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { RiskYield } from './components/RiskYield';
import { SubPageContainer } from 'features/common/StyledComponents';
import { Card } from 'features/allocator/common/Card';
import { RiskScale } from './components/RiskScale';
import { flagCheckPortfolios, setTargetRiskLevel } from 'features/allocator/investmentPlan/investmentPlanActions';
import { RootState } from 'types/rootState';
import { RiskLevel } from 'types/types';
import { optimize } from 'features/weights/weightsThunks';
import { Spinner } from 'features/common/Spinner';
import { selectRisk } from 'features/allocator/allocatorSelectors';
import { Grid } from 'features/allocator/common/Grid';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { resetOptimizedPortfoliosSettings } from 'features/weights/weightsSlice';
import { useAppDispatch } from 'core/hooks';

export const RiskSelectionView = () => {
  const customerId = useSelector(selectCustomerId);
  const selectedRiskLevel = useSelector((state: RootState) => state.profile.customer.toJS().selectedRiskLevel);
  const targetRiskLevel = useSelector(selectRisk);
  const examplePortfolios = useSelector((state: RootState) => state.portfolioManager.commonData.examplePortfolios);
  const loadingExamplePortfolios = useSelector(
    (state: RootState) => state.portfolioManager.commonData.loadingExamplePortfolios
  );

  const [effectiveRiskLevel, setEffectiveRiskLevel] = useState<RiskLevel>(targetRiskLevel);
  const [initialRisk, setInitialRisk] = useState<RiskLevel>(targetRiskLevel);

  const isNewCustomer = useSelector(
    (state: RootState) => !state.portfolioManager.weights.currentWeights.assetCategoryWeights
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    setInitialRisk(effectiveRiskLevel);
  }, []);

  useEffect(() => {
    if (selectedRiskLevel) {
      setEffectiveRiskLevel(selectedRiskLevel);
    }
  }, [selectedRiskLevel]);

  const discardChanges = () => setEffectiveRiskLevel(initialRisk);

  const onRiskButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLButtonElement;
    const newRiskLevel = Number(element.value) as RiskLevel;
    setEffectiveRiskLevel(newRiskLevel);
  };

  const saveAndResetPortfolios = () => {
    if (isNewCustomer) {
      dispatch(resetOptimizedPortfoliosSettings());
    }
    dispatch(setTargetRiskLevel(effectiveRiskLevel));
    dispatch(flagCheckPortfolios(true));
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
    dispatch(optimize());
  };

  return (
    <SubPageContainer>
      <AllocatorHeader
        headerLabel={translate('allocator.configureRisk.riskHeader')}
        discardChanges={discardChanges}
        hasChanged={initialRisk !== effectiveRiskLevel && !selectedRiskLevel}
        customerId={customerId}
        saveChanges={saveAndResetPortfolios}
      />
      <RiskSelectionContainer>
        <Card>
          <RiskScale disabled={selectedRiskLevel > 0} onClick={onRiskButtonClick} risk={effectiveRiskLevel} />
        </Card>
        {loadingExamplePortfolios && <Spinner />}
        {examplePortfolios.length > 0 && effectiveRiskLevel !== 0 && <RiskYield riskLevel={effectiveRiskLevel} />}
      </RiskSelectionContainer>
    </SubPageContainer>
  );
};

const RiskSelectionContainer = styled(Grid)`
  grid-template-columns: 1fr 5fr;
  grid-gap: 4rem;
`;
