import { MenuItem, FormControl, Select, SelectChangeEvent } from '@mui/material';
import translate from 'counterpart';
import React from 'react';
import { SignatureMethodType } from 'types/types';
import { SpinnerStyled, PreventSigningStyled } from './styledComponents';

interface SignatureSelectionProps {
  selectionHintText: string;
  selectedSignatureMethod: SignatureMethodType;
  onSignatureMethodSelected: (value: SignatureMethodType) => void;
  selectableSignatureMethods: string[];
  preventSigning?: boolean;
  fetchingWebServiceContractStatus?: boolean;
  hideSigningOptions?: boolean;
}

const SignatureSelection = (props: SignatureSelectionProps) => {
  const {
    hideSigningOptions,
    fetchingWebServiceContractStatus,
    preventSigning,
    selectionHintText,
    selectedSignatureMethod,
    selectableSignatureMethods,
    onSignatureMethodSelected,
  } = props;
  if (hideSigningOptions) {
    return null;
  }
  if (fetchingWebServiceContractStatus) {
    return <SpinnerStyled color="#ffffff" />;
  }
  if (preventSigning) {
    return <PreventSigningStyled>{`newContract.contractExists`}</PreventSigningStyled>;
  }

  const getSignatureMenuItems = () =>
    selectableSignatureMethods.map((m) => (
      <MenuItem
        value={m}
        key={m}
        sx={{
          '&.MuiMenuItem-root': {
            fontFamily: 'Calibre, sans-serif',
            fontSize: '16px',
          },
          '&.Mui-selected': {
            fontFamily: 'Calibre, sans-serif',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.06)',
            },
          },
        }}
      >
        {translate(`contract.${m}`)}
      </MenuItem>
    ));

  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: '200px!important',
        backgroundColor: 'white!important',
      }}
    >
      <Select
        renderValue={(selectedSignatureMethod) => {
          if (!selectedSignatureMethod) {
            return <span>{selectionHintText}</span>;
          }
          return translate(`contract.${selectedSignatureMethod}`);
        }}
        onChange={(event: SelectChangeEvent) => onSignatureMethodSelected(event.target.value as SignatureMethodType)}
        value={selectedSignatureMethod ?? ''}
        label={selectionHintText}
        placeholder={selectionHintText}
        displayEmpty
        variant="standard"
        sx={{
          '&.MuiPopover-root': {
            top: '50px',
          },
          '&.MuiInputBase-root': {
            width: '100%',
            marginBottom: '15px',
            color: 'rgba(0, 0, 0, 0.6)',
            '&::after': {
              borderBottomColor: '#000000',
            },
            ':focus': {
              backgroundColor: '#ffffff',
            },
          },
          '& .MuiInputLabel-root': {
            fontFamily: 'Calibre, sans-serif',
            top: '5px',
          },
          '&.MuiSelect-root': {
            backgroundColor: '#ffffff',
            marginLeft: '5px',
          },
          '& .MuiMenuItem-root': {
            fontFamily: 'Calibre, sans-serif',
            fontSize: '16px',
            backgroundColor: '#000',
            '&.Mui-selected': {
              backgroundColor: '#ffffff',
            },
          },
          '& .MuiSelect-select': {
            fontFamily: 'Calibre, sans-serif',
            fontSize: '16px',
          },
          '& .MuiSelect-nativeInput': {
            fontFamily: 'Calibre, sans-serif',
            fontSize: '16px',
          },
          '& .MuiInput-input': {
            color: '#000',
            paddingTop: '15px',
            ':focus': {
              backgroundColor: '#ffffff',
            },
          },
        }}
      >
        {getSignatureMenuItems()}
      </Select>
    </FormControl>
  );
};

export default SignatureSelection;
