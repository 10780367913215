import React from 'react';
import Immutable from 'immutable';
import Activity from './Activity';
import { CustomerInfo } from './CustomerInfo';
import Schedule from './Schedule';
import Notes from './Notes';
import spinner from 'hocs/spinner';
import Error from 'features/common/404';
import { Sheet } from 'features/common/Sheet';
import { ACCOUNT } from 'constants/customerStates';
import { Note } from 'types/ordersState';
import { Customer } from 'types/profileState';
import { ValidationErrorValue } from 'types/orderDialogState';

interface Props {
  note: Note;
  customer: Customer;
  editNote: (key: string, value: string) => void;
  mergeNote: (note: Partial<Note>) => void;
  isLoading: boolean;
  errors: ValidationErrorValue[];
  touched: Immutable.Set<string>;
  savingFailed: boolean;
  errorMessage: string;
}

const Meeting = ({ note, customer, editNote, mergeNote, errors, touched, savingFailed, errorMessage }: Props) => {
  let content;

  if (customer.state !== ACCOUNT) {
    content = <Error />;
  } else if (savingFailed) {
    content = <Error error={errorMessage} />;
  } else {
    const commonProps = {
      activity: note.activity,
      editNote,
      state: customer.state,
      errors,
      touched,
    };
    content = (
      <Sheet>
        <CustomerInfo name={customer.name} customerId={customer.customerId} state={customer.state} />
        <Activity
          directionCode={note.directionCode}
          topic={note.topic}
          subject={note.subject}
          mergeNote={mergeNote}
          includesReporting={note.includesReporting}
          {...commonProps}
        />
        <Schedule
          scheduledStart={note.scheduledStart}
          scheduledEnd={note.scheduledEnd}
          location={note.location}
          mergeNote={mergeNote}
          {...commonProps}
        />
        <Notes
          present={note.present}
          description={note.description}
          topic={note.topic}
          includesReporting={note.includesReporting}
          {...commonProps}
        />
      </Sheet>
    );
  }
  return content;
};

export default spinner(({ isLoading }: Props) => isLoading)(Meeting);
