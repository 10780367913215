import * as t from 'io-ts';
import { customerKinds } from 'constants/allocator';

// STATE

export type CustomerKind = (typeof customerKinds)[number];

export interface SearchResultCustomer {
  kind: CustomerKind;
  customerId: string;
  customerName: string;
  selectedRisk: number;
  timestamp: string;
  isFound: boolean;
  aum?: number;
  actualRisk?: number;
  hasInvestmentPlan?: boolean;
  riskDifference?: number;
  useCompanyOptimizationForecast?: boolean;
  weightDifference?: number;
  withdrawalBalance?: number;
}

export interface BasicCustomer {
  customerId: string;
  customerName: string;
  selectedRisk: number;
  timestamp: string;
}

export interface CustomerWithPlanNoPositions extends BasicCustomer {
  useCompanyOptimizationForecast: boolean;
}

export interface CustomerWithoutPlan extends BasicCustomer {
  aum: number;
  actualRisk: number;
  withdrawalBalance: number;
}

export interface CustomerWithPlan extends CustomerWithoutPlan {
  useCompanyOptimizationForecast: boolean;
  weightDifference: number;
}

export interface BankersCustomersBatchResponse {
  withPlan: CustomerWithPlan[];
  withPlanNoPositions: CustomerWithPlanNoPositions[];
  withoutPlan: CustomerWithoutPlan[];
  errors: BasicCustomer[];
}

export interface CustomerListComponentState {
  inputText: string;
  sortByKey: keyof SearchResultCustomer;
  sortedCustomers: Customers;
  isFetching: boolean;
  bankerId: string;
  filter: string;
}

export type Customers = {
  [k in string]: SearchResultCustomer[];
};

// IO-TS stuff

const CustomerStatus = t.type({
  kind: t.string,
  customerName: t.string,
  isFound: t.boolean,
  selectedRisk: t.number,
  hasInvestmentPlan: t.union([t.boolean, t.undefined]),
  useCompanyOptimizationForecast: t.union([t.boolean, t.undefined]),
  aum: t.union([t.number, t.undefined, t.null]),
  actualRisk: t.union([t.number, t.undefined, t.null]),
  riskDifference: t.union([t.number, t.undefined]),
  weightDifference: t.union([t.number, t.undefined]),
});

export const GetCustomersBatchResponse = t.array(CustomerStatus);

export type GetCustomersBatchResponse = t.TypeOf<typeof GetCustomersBatchResponse>;
