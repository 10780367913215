import React from 'react';
import { Link } from 'react-router';
import moment from 'moment';
import translate from 'counterpart';
import styles from './Drafts.scss';
import { ImmutableDraft } from 'types/profileState';

interface DraftItemProps {
  path: string;
  modified: string;
  count: number;
}

function DraftItem(props: DraftItemProps) {
  return (
    <Link to={{ pathname: props.path, state: { restore: true } }} className="row">
      <div>
        <span className={styles.modified}>{props.modified}</span>
        <span>{`${props.count} keskeneräistä sopimusta`}</span>
      </div>
      <span data-testkey="continue-draft-contract-span">{translate('customer.continue')}</span>
    </Link>
  );
}

interface DraftsProps {
  draft: ImmutableDraft;
  link: string;
}

export default function Drafts(props: DraftsProps) {
  const { draft, link } = props;
  let content = null;

  if (draft.size > 0) {
    const count = draft.get('count');
    let component;
    if (count) {
      const modified = moment(draft.get('modified')).format('DD.MM.YYYY');
      component = <DraftItem modified={modified} path={link} count={count} />;
    } else {
      component = <div className="row align-center">{translate('customer.noDraftContracts')}</div>;
    }
    content = (
      <section className={styles.drafts}>
        <header>{translate('customer.draftContracts')}</header>
        {component}
      </section>
    );
  }

  return content;
}
