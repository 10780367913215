import React from 'react';
import translate from 'counterpart';
import cloneDeep from 'lodash/cloneDeep';
import { branch, renderNothing } from 'recompose';
import _ from 'lodash';
import { SelectWithLabel } from './SelectWithLabel';
import { moneySources, EXTERNAL_SOURCE } from 'constants/receiveInfoOptions';
import { FULL_POWER_OF_ATTORNEY, CONSULTATIVE_AGREEMENT } from 'constants/contractNames';
import { BUY } from 'constants/sides';
import { DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { SelectValue } from 'types/types';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  portfolioContractName: string;
  assignment: OrderLineType;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultAccount: DefaultAccount;
}

const MoneySource = (props: Props) => {
  const dispatch = useAppDispatch();

  const setMoneySource = (obj: SelectValue) => {
    dispatch(setEditorValue('moneySource', obj.value));
  };

  const { portfolioContractName, assignment } = props;
  let options = cloneDeep(moneySources);

  if (portfolioContractName === FULL_POWER_OF_ATTORNEY || portfolioContractName === CONSULTATIVE_AGREEMENT) {
    options = options.filter((o) => o.value !== EXTERNAL_SOURCE);
  }

  return (
    <SelectWithLabel
      label={translate(assignment.side === BUY ? 'order.financingBuy' : 'order.financingSell')}
      onChange={setMoneySource}
      options={options}
      value={assignment.moneySource}
      data-testkey="order-money-source"
    />
  );
};

export default branch(
  ({ assignment, defaultAccount }: Props) => assignment.side !== BUY || _.get(defaultAccount, ['accountType']) === '03',
  renderNothing
)(MoneySource);
