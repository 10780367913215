import React from 'react';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  onCancel: () => void;
  onDiscard: () => void;
  isOpen: boolean;
}

export const DiscardSessionDialog = ({ onCancel = () => {}, onDiscard = () => {}, isOpen = false }: Props) => {
  const renderDiscardButton = () => {
    return (
      <StyledButton
        onClick={onDiscard}
        data-testkey="confirm-delete-contract-drafts-button"
        key="discardButton"
        variant="text"
      >
        Poista
      </StyledButton>
    );
  };

  const renderCancelButton = () => {
    return (
      <StyledButton onClick={onCancel} variant="text" key="cancelButton">
        Peruuta
      </StyledButton>
    );
  };

  const renderActions = () => {
    return [renderDiscardButton(), renderCancelButton()];
  };

  return (
    <ModalDialog
      title="Sopimusten poistaminen"
      actions={renderActions()}
      open={isOpen}
      content="Haluatko varmasti poistaa keskeneräiset sopimukset?"
    />
  );
};
