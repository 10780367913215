import { CSSProperties } from 'react';

export const tableHeaderStyles: CSSProperties = {
  verticalAlign: 'bottom',
  paddingLeft: 0,
  fontSize: 'inherit',
  fontWeight: 700,
  paddingRight: 15,
  paddingBottom: 5,
  height: 'auto',
  textAlign: 'right',
  textTransform: 'uppercase',
  whiteSpace: 'normal',
};

export const tableFooterStyles: CSSProperties = {
  paddingLeft: 0,
  fontWeight: 700,
  fontSize: 'inherit',
  verticalAlign: 'top',
  height: 'auto',
  textAlign: 'right',
  paddingRight: 15,
};

export const rowStyles: CSSProperties = {
  paddingLeft: 0,
  color: 'black',
  fontSize: '12px',
};

export const columnStyles: CSSProperties = {
  textAlign: 'right',
  fontSize: 'inherit',
  paddingLeft: 0,
  paddingRight: 15,
  whiteSpace: 'normal',
};
