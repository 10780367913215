import React, { ReactElement, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from 'types/rootState';
import { FullScreenError } from 'features/allocator/common/FullScreenError';
import { clearAllocatorErrors } from 'features/allocator/allocatorUtils';
import { fetchExamplePortfolios, fetchInstrumentGroups } from 'features/portfolioManager/commonData/commonDataActions';
import { cancelOptimization } from 'features/weights/weightsThunks';
import { preventAllocatorUse } from 'features/errors/errorUtils';
import { initializeInvestmentPlanState } from 'features/allocator/investmentPlan/investmentPlanActions';
import { resetOptimizedWeights } from 'features/weights/weightsSlice';
import { useAppDispatch } from 'core/hooks';

interface Props {
  children: ReactElement;
}

export const AllocatorRoot = ({ children }: Props) => {
  const examplePortfolios = useSelector((state: RootState) => state.portfolioManager.commonData.examplePortfolios);
  const instrumentGroups = useSelector((state: RootState) => state.portfolioManager.commonData.instrumentGroups);

  const preventUse = useSelector(preventAllocatorUse);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(clearAllocatorErrors());
    if (examplePortfolios.length === 0) {
      dispatch(fetchExamplePortfolios());
    }
    if (instrumentGroups.length === 0) {
      dispatch(fetchInstrumentGroups());
    }
    return () => {
      dispatch(cancelOptimization('optimize'));
      dispatch(initializeInvestmentPlanState());
      dispatch(resetOptimizedWeights());
    };
  }, []);

  return <AllocatorContainer className="allocator">{preventUse ? <FullScreenError /> : children}</AllocatorContainer>;
};

const AllocatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
