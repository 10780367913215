import {
  EMAIL_REPORTS,
  EMAIL_REPORTS_COMPANY,
  WEB_SERVICE_CONTRACT,
  WEB_SERVICE_CONTRACT_PROXY,
  BASIC_INFO_PERSON,
  BASIC_INFO_COMPANY,
  BASIC_INFO_INSTITUTION,
  DISCRETIONARY_CONTRACT_PERSON,
  CONSULTATIVE_CONTRACT_PERSON,
  DISCRETIONARY_CONTRACT_COMMUNITY,
  CONSULTATIVE_CONTRACT_COMMUNITY,
} from './contractIds';

export const BASIC_INFO = [BASIC_INFO_PERSON, BASIC_INFO_COMPANY, BASIC_INFO_INSTITUTION] as const;

export const WEALTH_MANAGEMENT = [
  DISCRETIONARY_CONTRACT_PERSON,
  CONSULTATIVE_CONTRACT_PERSON,
  DISCRETIONARY_CONTRACT_COMMUNITY,
  CONSULTATIVE_CONTRACT_COMMUNITY,
];

export const SIGNATORY_NOT_EDITABLE = [
  WEB_SERVICE_CONTRACT,
  WEB_SERVICE_CONTRACT_PROXY,
  EMAIL_REPORTS,
  EMAIL_REPORTS_COMPANY,
];

const WEB_SERVICE_CONTRACTS = [WEB_SERVICE_CONTRACT, WEB_SERVICE_CONTRACT_PROXY];

export function isWebServiceContract(contractType: string) {
  return WEB_SERVICE_CONTRACTS.includes(contractType);
}
