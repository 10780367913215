import React from 'react';
import { omit } from 'lodash';
import { Link } from 'react-router';
import styled from 'styled-components';
import translate from 'counterpart';
import { fontSize } from 'features/common/StyledComponents';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { colors } from 'styles/colors';
import { SearchResultCustomer } from 'types/customerListState';

interface Props {
  customer: SearchResultCustomer;
}

export const CustomerListItem = React.memo(
  ({ customer }: Props) => {
    const { aum, customerName, customerId, withdrawalBalance } = customer;

    return (
      <React.Fragment key={customerId}>
        <td>
          <Link
            label={translate('portfolioManager.customerList.goToProfile')}
            to={`/customer/${customerId}`}
            data-testkey={`go-to-profile-${customerId}`}
          >
            <NameContainer>
              <div>{customerName}</div>
              <CustomerId>{customerId}</CustomerId>
            </NameContainer>
          </Link>
        </td>

        <td>
          <AUM>{aum !== undefined && formatToDecimals(aum, 0, '€')}</AUM>
        </td>

        <td>
          {withdrawalBalance !== undefined && !isNaN(withdrawalBalance) && (
            <Cash>{formatToDecimals(withdrawalBalance, 0, '€')}</Cash>
          )}
        </td>
      </React.Fragment>
    );
  },
  (prevProps: Props, nextProps: Props) => {
    const customerWithoutOneProp = (customer: SearchResultCustomer) => JSON.stringify(omit(customer, 'isFound'));
    return customerWithoutOneProp(prevProps.customer) === customerWithoutOneProp(nextProps.customer);
  }
);

CustomerListItem.displayName = 'CustomerListItem';

const NameContainer = styled.div`
  white-space: normal;
  padding-left: 0.75rem;
  letter-spacing: 0.3px;
`;

const CustomerId = styled.div`
  font-size: ${fontSize.smaller};
  color: ${colors.dark_gray};
`;

const AUM = styled.div`
  text-align: right;
`;

const Cash = styled.div`
  text-align: right;
`;
