import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { RootState } from 'types/rootState';
import { AssetClassDistributionBar } from 'features/allocator/common/AssetClassDistributionBar';
import { FlexColumn } from 'features/common/StyledComponents';
import { ReturnAndVolatility } from 'features/allocator/overview/components/ReturnAndVolatility';
import { DistributionTable } from 'features/allocator/common/DistributionTable';
import { ASSET_CATEGORY_ORDER, weightTypes } from 'constants/allocator';
import {
  customerHasValidPlan,
  isLoadingInitialItems,
  isOptimizingCurrent,
  selectOptimizedWeightsForRiskLevel,
  selectCurrentWeights,
} from 'features/allocator/allocatorSelectors';
import { Card } from 'features/allocator/common/Card';
import {
  selectIsLoadingCurrentWeightsDistribution,
  selectIsLoadingOptimizedWeightsDistribution,
} from 'features/weights/weightsSelectors';

export const PortfolioDistribution = () => {
  const currentWeights = useSelector(selectCurrentWeights);

  const hasPlan = useSelector(customerHasValidPlan);
  const planLengthToUse = hasPlan ? 'withoutIlliquids' : 'withIlliquids';

  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const sortedAssetCategories = useSelector((state: RootState) =>
    state.portfolioManager.weights.currentWeights?.assetCategoryWeights
      ?.slice()
      .sort((a, b) => ASSET_CATEGORY_ORDER.indexOf(a.name) - ASSET_CATEGORY_ORDER.indexOf(b.name))
  );
  const loadingInitialItems = useSelector(isLoadingInitialItems);

  const loadingCurrentWeightsDistribution = useSelector(selectIsLoadingCurrentWeightsDistribution);
  const loadingOptimizedWeightsDistribution = useSelector(selectIsLoadingOptimizedWeightsDistribution(planLengthToUse));

  return (
    <DistributionCard>
      <Bars>
        <h2>{translate('portfolioManager.assetCategoryDistribution.distribution')}</h2>

        <label>{translate('portfolioManager.assetCategoryDistribution.current')}</label>
        <AssetClassDistributionBar weights={currentWeights} loading={loadingCurrentWeightsDistribution} />

        <label>{translate('portfolioManager.assetCategoryDistribution.optimized')}</label>

        {optimizedWeights[planLengthToUse]?.assetCategoryWeights && (
          <AssetClassDistributionBar
            weights={optimizedWeights[planLengthToUse]}
            loading={loadingOptimizedWeightsDistribution}
          />
        )}
      </Bars>

      {!loadingCurrentWeightsDistribution && (
        <>
          <Distribution>
            <DistributionTable
              items={sortedAssetCategories}
              itemType="assetCategory"
              compareTo={weightTypes.optimizedWeights}
            />
          </Distribution>
          <Figures>
            <ReturnAndVolatility
              returnStatistics={currentWeights.returnStatistics}
              compareTo={weightTypes.optimizedWeights}
              planLength={planLengthToUse}
              loading={loadingInitialItems || optimizingCurrent}
            />
          </Figures>
        </>
      )}
    </DistributionCard>
  );
};

const DistributionCard = styled(Card)`
  grid-area: distribution;
  display: grid;
  grid-template-areas:
    'bars bars'
    'distribution figures';
  grid-gap: 1rem 4rem;
  width: 100%;
  align-content: start;
`;

const Bars = styled.div`
  grid-area: bars;
`;

const Distribution = styled.div`
  grid-area: distribution;
`;

const Figures = styled(FlexColumn)`
  grid-area: figures;
`;
