import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { RiskLevel } from 'types/types';
import { AssetCategoryDistribution } from 'features/allocator/common/AssetCategoryDistribution';
import { DistributionPie } from 'features/allocator/riskSelection/components/DistributionPie';
import { Grid } from 'features/allocator/common/Grid';
import { YieldGraphAndLegend } from 'features/allocator/common/YieldGraphAndLegend';
import { getExamplePortfolioPropsForRiskLevel } from 'features/portfolioManager/commonData/commonDataUtils';

interface Props {
  riskLevel: RiskLevel;
}

export const RiskYield = ({ riskLevel }: Props) => {
  const { weights, portfolioVolatility, portfolioReturn, confidenceLevels } = useSelector(
    getExamplePortfolioPropsForRiskLevel(riskLevel)
  );

  return (
    <ContainerGrid gap="4rem">
      <Grid vertical>
        <h2>{translate('allocator.configureRisk.estimatedDistribution')}</h2>
        <DistributionPie weights={weights} sections="assetCategories" />
        <AssetCategoryDistribution weights={weights} />
        <div>
          {translate('allocator.volatility')}: {formatToDecimals(portfolioVolatility, 1, '%')}
        </div>
      </Grid>
      <YieldGraphAndLegend confidenceLevels={confidenceLevels} portfolioReturn={portfolioReturn} startValue={100} />
    </ContainerGrid>
  );
};

const ContainerGrid = styled(Grid)`
  padding: 1.5rem 0;
  grid-template-columns: 1fr 4fr;
`;
