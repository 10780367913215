import React, { CSSProperties } from 'react';
import classnames from 'classnames';
import accounting from 'accounting';
import styles from './PortfolioDetailsHeader.scss';
import { NOT_APPLICABLE } from 'constants/common';
import { MarketInfo } from 'types/portfolioState';

interface FieldProps {
  label: string;
  value?: string | number;
  className?: string;
}

const Field = ({ label, value, className }: FieldProps) => {
  const valueField = value ? <span className={styles.value}>{value}</span> : null;
  return (
    <div className={classnames(styles.field, className)}>
      <span className={styles.title}>{label}</span>
      {valueField}
    </div>
  );
};

interface SegregatedBankAccountDetailsProps {
  accountType: string;
  paymentReference: string;
}

const SegregatedBankAccountDetails = ({ accountType, paymentReference }: SegregatedBankAccountDetailsProps) => {
  switch (accountType) {
    case 'AS':
      return <Field label={'Asiakasvaratili, viite'} value={paymentReference} />;
    case 'DG':
      return <Field label={'Digi-asiakasvaratili, viite'} value={paymentReference} />;
    case 'HF':
      return <Field label={'Henki-Fennia -asiakasvaratili, viite'} value={paymentReference} />;
    default:
      return null;
  }
};

interface PortfolioDetailsProps {
  withdrawalBalance: number;
  withdrawalCurrency: string;
  state: string;
  iban: string;
  marketValueOfMoneyPositionsCurrency: string;
  marketValueOfMoneyPositions: number;
  accountType: string;
  paymentReference: string;
}

const PortfolioDetails = ({
  withdrawalBalance,
  withdrawalCurrency,
  marketValueOfMoneyPositions,
  marketValueOfMoneyPositionsCurrency,
  state,
  iban,
  accountType,
  paymentReference,
}: PortfolioDetailsProps) => (
  <section>
    <Field
      className={styles.large}
      label={withdrawalCurrency !== NOT_APPLICABLE ? 'Käytettävissä oleva saldo' : 'Salkussa ei ole tiliä'}
      value={
        withdrawalCurrency !== NOT_APPLICABLE
          ? `${accounting.formatMoney(withdrawalBalance)} ${withdrawalCurrency}`
          : ''
      }
    />
    <Field
      className={styles.large}
      label={'Tapahtumapäivän saldo'}
      value={`${accounting.formatMoney(marketValueOfMoneyPositions)} ${marketValueOfMoneyPositionsCurrency}`}
    />
    <Field label={state} />
    <Field label={'IBAN'} value={iban} />
    <SegregatedBankAccountDetails accountType={accountType} paymentReference={paymentReference} />
  </section>
);

PortfolioDetails.defaultProps = {
  withdrawalBalance: 0,
  withdrawalCurrency: '',
};

interface MarketInfoRowProps {
  marketValue: string;
  marketValueCurrency: string;
  purchaseValue: string;
  marketValueChangePercent: string;
  marketValueChange: string;
  purchaseValueCurrency: string;
  loading: boolean;
}

const MarketInfoRow = ({
  purchaseValue,
  purchaseValueCurrency,
  marketValue,
  marketValueCurrency,
  marketValueChangePercent,
  marketValueChange,
  loading,
}: MarketInfoRowProps) => {
  return !loading ? (
    <section>
      <Field className={styles.large} label={'Hankinta-arvo'} value={`${purchaseValue} ${purchaseValueCurrency}`} />
      <Field className={styles.large} label={'Markkina-arvo'} value={`${marketValue} ${marketValueCurrency}`} />
      <Field
        className={styles.large}
        label={'Muutos'}
        value={`${marketValueChangePercent} % / ${marketValueChange} ${marketValueCurrency}`}
      />
    </section>
  ) : null;
};

MarketInfoRow.defaultProps = {
  marketValue: 0,
  marketValueCurrency: 'EUR',
  purchaseValue: 0,
  marketValueChangePercent: 0,
  marketValueChange: 0,
  purchaseValueCurrency: 'EUR',
};

interface PortfolioDetailsHeaderProps {
  marketInfo: MarketInfo;
  style?: CSSProperties;
  loading: boolean;
}

//this needs to be class component because of how parent react-sticky sticky component uses refs
//https://github.com/captivationsoftware/react-sticky/issues/94
// eslint-disable-next-line react/prefer-stateless-function
class PortfolioDetailsHeader extends React.Component<PortfolioDetailsHeaderProps> {
  constructor(props: PortfolioDetailsHeaderProps) {
    super(props);
  }

  render() {
    const { marketInfo, style, loading } = this.props;
    const details = marketInfo?.details;
    const detailsComponent = details ? (
      <PortfolioDetails
        {...details}
        marketValueOfMoneyPositions={marketInfo?.marketValueOfMoneyPositions}
        marketValueOfMoneyPositionsCurrency={marketInfo?.marketValueOfMoneyPositionsCurrency}
      />
    ) : null;

    const defaultHeader = loading ? 'Ladataan, odota hetki...' : 'Omistuksien yhteenlaskettu arvo';
    const header = marketInfo?.details ? marketInfo.details.portfolioName : defaultHeader;

    return (
      <section className={styles.header} style={{ ...style, top: '70px' }}>
        <div>
          <h2>{header}</h2>
          <MarketInfoRow
            purchaseValue={accounting.formatMoney(marketInfo?.purchaseValue)}
            purchaseValueCurrency={marketInfo?.purchaseValueCurrency}
            marketValue={accounting.formatMoney(marketInfo?.marketValue)}
            marketValueCurrency={marketInfo?.marketValueCurrency}
            marketValueChangePercent={accounting.formatNumber(marketInfo?.marketValueChangePercent, 2)}
            marketValueChange={accounting.formatMoney(marketInfo?.marketValueChange)}
            loading={loading}
          />
          {detailsComponent}
        </div>
      </section>
    );
  }
}

export default PortfolioDetailsHeader;
