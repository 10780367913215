import React, { useEffect, useRef, useState } from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { sortBy, omit } from 'lodash';
import { push } from 'react-router-redux';
import { SubPageContainer, HelpText, fontSize, Flex, FlexAlignCenter, styles } from 'features/common/StyledComponents';
import { PlanPortfolioSettings } from './components/PlanPortfolioSettings';
import { PlanPortfolioContents } from './components/PlanPortfolioContents';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { colors } from 'styles/colors';
import { Button } from 'features/allocator/common/Button';
import { ContractTypeId, PortfolioManagerId, OptimizationMannerId } from 'types/types';
import {
  getPortfolioSettings,
  isConsideredPortfolio,
  isIgnoredPortfolio,
  isOptimizedPortfolio,
} from './planPortfolioUtils';
import { cancelOptimization, optimize, optimizeCurrent } from 'features/weights/weightsThunks';
import {
  selectHasPositions,
  selectCurrentWeights,
  isOptimizingPlan,
  selectOptimizedWeightsForRiskLevel,
  selectRisk,
  selectPositionsNotInPlan,
} from 'features/allocator/allocatorSelectors';
import { AllocatedPortfolioRows, AllocatorPortfolio, PortfolioSettingsType } from 'types/investmentPlanState';
import { OptimizedWeights, Weights } from 'types/weightsState';
import { emptyOptimizedWeights } from 'constants/allocator';
import { clearError } from 'features/errors/errorActions';
import { flagCheckPortfolios } from 'features/allocator/investmentPlan/investmentPlanActions';
import { selectCustomerId } from 'features/profile/profileSelectors';
import {
  customerHasCurrentWeights,
  customerHasOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';
import {
  updateOptimizedPortfolios,
  updateOptimizedPortfoliosSettings,
  updateCurrentWeights,
  updateOptimizedWeights,
} from 'features/weights/weightsSlice';
import { RootState } from 'types/rootState';
import { useAppDispatch } from 'core/hooks';

export const PlanPortfoliosView = () => {
  const dispatch = useAppDispatch();

  const customerId = useSelector(selectCustomerId);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const currentWeights = useSelector(selectCurrentWeights);
  const hasOptimizedWeights = useSelector(customerHasOptimizedWeights('withoutIlliquids'));
  const hasCurrentWeights = useSelector(customerHasCurrentWeights);
  const hasPositions = useSelector(selectHasPositions);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);

  const initialSettings = useRef<AllocatorPortfolio[]>([]);
  const initialOptimizedWeights = useRef<OptimizedWeights>(emptyOptimizedWeights);
  const initialCurrentWeights = useRef<Weights>({} as Weights);
  const exitAction = useRef<'save' | 'cancel'>('cancel');

  const [portfolioSettings, setPortfolioSettings] = useState<AllocatorPortfolio[]>([]); // portfolio settings, react to user clicks
  const [latestPortfolios, setLatestPortfolios] = useState<AllocatorPortfolio[]>([]);

  const riskLevel = useSelector(selectRisk);

  const optimizationForecastType = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.optimizationForecastType
  );

  useEffect(() => {
    return () => {
      dispatch(cancelOptimization('optimize'));
      dispatch(cancelOptimization('optimizeCurrent'));
      if (exitAction.current === 'cancel') {
        dispatch(
          updateOptimizedPortfolios({
            portfolios: initialSettings.current,
            riskLevel,
            optimizationForecastType,
          })
        );
        dispatch(updateOptimizedPortfoliosSettings(initialSettings.current));
        dispatch(updateOptimizedWeights(initialOptimizedWeights.current));
        dispatch(updateCurrentWeights(initialCurrentWeights.current));
        if (initialOptimizedWeights.current.withIlliquids.assetCategoryWeights.length > 0) {
          dispatch(clearError('optimizePlan'));
        }
      }
    };
  }, []);

  useEffect(() => {
    // runs once
    if (
      initialSettings.current.length === 0 &&
      optimizedPortfolios.length > 0 &&
      (hasCurrentWeights || !hasPositions)
    ) {
      initialSettings.current = optimizedPortfolios;
      initialOptimizedWeights.current = optimizedWeights;
      initialCurrentWeights.current = currentWeights;
      setPortfolioSettings(optimizedPortfolios);
      setLatestPortfolios(optimizedPortfolios);
    }
  }, [optimizedPortfolios, initialSettings, optimizedWeights, hasCurrentWeights]);

  useEffect(() => {
    if (portfolioSettings.length > 0) {
      const updatedPortfolioContents = portfolioSettings.map((p) => ({
        ...p,
        allocatedPortfolioRows:
          optimizedPortfolios.find((q) => q.portfolioId === p.portfolioId)?.allocatedPortfolioRows ||
          ({} as AllocatedPortfolioRows),
      }));
      setPortfolioSettings(updatedPortfolioContents);
      setLatestPortfolios(updatedPortfolioContents);
    }
  }, [JSON.stringify(optimizedPortfolios)]);

  const toggleOptimized = (id: string, optimizationMannerId: OptimizationMannerId) =>
    changePortfolioSettings(id, 'optimizationManner', optimizationMannerId);

  const changePortfolioSettings = (
    id: string,
    key: 'contractType' | 'portfolioManager' | 'optimizationManner',
    value: ContractTypeId | PortfolioManagerId | OptimizationMannerId
  ) => {
    const newportfolioSettings = portfolioSettings.map((p) =>
      p.allocatorPortfolioId === id
        ? {
            ...p,
            manualSettings: {
              ...p.manualSettings,
              [key]: value,
            } as PortfolioSettingsType,
          }
        : p
    );
    setPortfolioSettings(newportfolioSettings);
  };

  const resetToOptimizedSettings = () => {
    const noManualSettings = portfolioSettings.map((p) => omit(p, ['manualSettings']));
    setPortfolioSettings(noManualSettings);
  };

  const saveChanges = () => {
    exitAction.current = 'save';
    const settingsAndContents = portfolioSettings.map((p) => ({
      ...p,
      allocatedPortfolioRows:
        latestPortfolios.find((q) => q.portfolioId === p.portfolioId)?.allocatedPortfolioRows ||
        ({} as AllocatedPortfolioRows),
    }));

    dispatch(updateOptimizedPortfolios({ portfolios: settingsAndContents, riskLevel, optimizationForecastType }));
    dispatch(updateOptimizedPortfoliosSettings(settingsAndContents));
    dispatch(flagCheckPortfolios(false));
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
  };

  const cancel = () => {
    exitAction.current = 'cancel';
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
  };

  const reoptimize = () => {
    const portfolios = portfolioSettings;
    const ignoredPortfolios = portfolios.filter((p) => isIgnoredPortfolio(p)) || [];
    const optimizedPortfolioIds = portfolios.filter((p) => isOptimizedPortfolio(p))?.map((p) => p.portfolioId) || [];
    const staticPortfolioIds = portfolios.filter((p) => isConsideredPortfolio(p))?.map((p) => p.portfolioId) || [];

    const optimizePayload = {
      optimizedPortfolios: optimizedPortfolioIds,
      staticPortfolios: staticPortfolioIds,
    };

    dispatch(optimize({ payload: optimizePayload, ignoredPortfolios }));
    dispatch(
      optimizeCurrent({
        customerId,
        nonIgnoredPortfolios: [...optimizedPortfolioIds, ...staticPortfolioIds],
        ignoredPortfolios: ignoredPortfolios.map((p) => p.portfolioId),
      })
    );
  };

  const portfolioSettingsHaveChanged =
    JSON.stringify(portfolioSettings.map((p) => getPortfolioSettings(p))) !==
    JSON.stringify(latestPortfolios.map((p) => getPortfolioSettings(p)));

  const hasNoOptimizedPortfolios =
    optimizedPortfolios.length > 0 && !portfolioSettings.some((p) => isOptimizedPortfolio(p));

  const disableReOptimize =
    (!portfolioSettingsHaveChanged && hasOptimizedWeights) ||
    optimizingPlan ||
    hasNoOptimizedPortfolios ||
    positionsNotInPlan.length > 0;

  const sortedPortfolioStateSettings = sortBy(portfolioSettings, (i) => i.allocatorPortfolioId);
  const sortedPortfolioStateContents = sortBy(latestPortfolios, (i) => i.allocatorPortfolioId);

  return (
    <SubPageContainer>
      <AllocatorHeader
        customerId={customerId}
        hasChanged={portfolioSettingsHaveChanged}
        headerLabel={translate('allocator.portfoliosView.title')}
        discardChanges={cancel}
        saveChanges={saveChanges}
        view="portfolios"
      />

      <WarningCantSave>
        {!optimizingPlan && !hasOptimizedWeights && (
          <>
            <div>{translate('allocator.portfoliosView.cantSaveBecauseOptimizationFailed')}</div>
            <div>{translate('allocator.portfoliosView.tryDifferentSettings')}</div>
          </>
        )}
      </WarningCantSave>

      <h2>{translate('allocator.portfoliosView.portfolioSettings')}</h2>

      {!hasPositions && <HelpText>{translate('allocator.portfoliosView.pageDescription')}</HelpText>}

      <Buttons>
        {hasPositions && (
          <div>
            <ReOptimizeButton
              disabled={disableReOptimize}
              label={'Optimoi uudelleen'}
              onClick={reoptimize}
              data-testkey={'button-reoptimize'}
              small
            />
            <WarningNoOptimized>
              {hasNoOptimizedPortfolios && translate('allocator.portfoliosView.selectAtLeastOneOptimized')}
            </WarningNoOptimized>
          </div>
        )}

        <ResetButton
          label={translate('allocator.portfoliosView.resetSettings')}
          onClick={resetToOptimizedSettings}
          disabled={optimizingPlan}
          secondary
          small
        />
      </Buttons>

      <SectionContainer>
        <PortfolioTable>
          <thead>
            <tr>
              <th>
                <h4>{translate('allocator.portfoliosView.portfolioName')}</h4>
              </th>
              <th>
                <h4>{translate('allocator.portfoliosView.portfolioModel')}</h4>
              </th>
              <LiabilityCell>
                <h4>{translate('allocator.portfoliosView.liability')}</h4>
              </LiabilityCell>
              <OptimizationCell>
                <h4>{translate('allocator.portfoliosView.investmentModel')}</h4>
              </OptimizationCell>
              <th>
                <h4>{translate('allocator.portfoliosView.portfolioWeight')}</h4>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedPortfolioStateSettings.map((p) => (
              <PlanPortfolioSettings
                key={p.portfolioId}
                portfolio={p}
                toggleOptimized={toggleOptimized}
                onChangeSelect={changePortfolioSettings}
                hasChanged={portfolioSettingsHaveChanged}
              />
            ))}
          </tbody>
        </PortfolioTable>

        <OptimizationModels>
          <FlexAlignCenter>
            <label>{translate('allocator.portfoliosView.toBeOptimized')}: </label>
            <div>{translate('allocator.portfoliosView.descriptionOptimize')}</div>
          </FlexAlignCenter>
          <FlexAlignCenter>
            <label> {translate('allocator.portfoliosView.toBeConsidered')}: </label>
            <div>{translate('allocator.portfoliosView.descriptionConsider')}</div>
          </FlexAlignCenter>
          {hasPositions && (
            <FlexAlignCenter>
              <label> {translate('allocator.portfoliosView.toBeIgnored')}: </label>
              <div>{translate('allocator.portfoliosView.descriptionIgnore')}</div>
            </FlexAlignCenter>
          )}
        </OptimizationModels>
      </SectionContainer>

      <SectionContainer>
        <h2>{translate('allocator.portfoliosView.portfolioContents')}</h2>
        {hasPositions && (
          <DifferenceHelpText>{translate('allocator.portfoliosView.differenceHelpText')}</DifferenceHelpText>
        )}
        <PlanPortfolioContents portfolios={sortedPortfolioStateContents} />
      </SectionContainer>
    </SubPageContainer>
  );
};

const DifferenceHelpText = styled.div`
  color: ${colors.dark_gray};
  font-style: italic;
  margin-bottom: 2rem;
`;

const OptimizationModels = styled.div`
  padding: 1rem 0 0 1rem;
  font-size: ${fontSize.smaller};
  color: ${colors.dark_gray};
  line-height: 1.8;

  b {
    color: ${colors.black};
  }

  label {
    display: inline;
    font-weight: 700;
    width: 7rem;
  }
`;

const Buttons = styled(Flex)`
  margin-top: 1rem;
`;

const ResetButton = styled(Button)`
  background: white;
`;
const ReOptimizeButton = styled(Button)`
  margin-right: 1rem;
`;

const Warning = styled.div`
  color: ${colors.red};
  text-align: right;
  font-weight: 700;
  position: absolute;
`;

const WarningCantSave = styled(Warning)`
  right: 1.5rem;
  width: 50%;
`;

const WarningNoOptimized = styled(Warning)`
  margin-top: 1rem;
  left: 2rem;
`;

const SectionContainer = styled.div`
  margin-top: 3rem;
`;

const PortfolioTable = styled.table`
  width: 100%;
  border-spacing: 0 5px;
  border-collapse: separate;

  td {
    padding-right: 4rem;
    height: 4rem;
  }

  thead {
    h4 {
      padding: 0 0 0.5rem;
    }

    td {
      height: auto;
    }
  }

  tbody {
    td {
      padding-left: 0.625rem;
    }

    tr {
      ${styles.card};
    }
  }
`;

export const OptimizationCell = styled.td``;

export const LiabilityCell = styled.td`
  & > span {
    padding-left: 0.5rem;
  }
`;
