import React from 'react';
import styled from 'styled-components';
import { BarChart } from 'features/allocator/common/BarChart';
import { distributionBarHeight, Flex } from 'features/common/StyledComponents';
import { ASSET_CATEGORY_ORDER } from 'constants/allocator';
import { Weights } from 'types/weightsState';
import { colors } from 'styles/colors';

interface Props {
  weights: Weights;
  loading: boolean;
}

export const AssetClassDistributionBar = ({ loading = false, weights }: Props) => {
  const hasWeights = !loading && weights?.assetCategoryWeights?.length > 0;
  const noWeights = !loading && weights.assetCategoryWeights.length === 0;

  return (
    <AssetClassDistributionBarContainer>
      {loading && <AnimatedLoadingBar data-testkey="bar-loading" />}
      {noWeights && <EmptyBar data-testkey="bar-error" />}
      {hasWeights &&
        ASSET_CATEGORY_ORDER.map((assetCategory) => {
          const currentAssetCategory = weights.assetCategoryWeights?.find((i) => i.name === assetCategory);
          return (
            <BarChart
              key={assetCategory}
              items={currentAssetCategory?.assetClasses || []}
              assetCategory={assetCategory}
              totalWeight={currentAssetCategory?.weight || 0}
            />
          );
        })}
    </AssetClassDistributionBarContainer>
  );
};

const EmptyBar = styled.div`
  background: repeating-linear-gradient(-55deg, #fff 0%, #fff 0.75%, #fdd 0.75%, #fdd 1.5%);
  width: 100%;
  margin-right: 5px;
`;

const AssetClassDistributionBarContainer = styled(Flex)`
  width: 100%;
  height: ${distributionBarHeight};
  margin-bottom: 1rem;
  border-radius: 4px;
  overflow: hidden;
`;

const AnimatedLoadingBar = styled.div`
  width: 100%;
  height: ${distributionBarHeight};
  margin-right: 5px;
  border-radius: 4px;
  background: linear-gradient(120deg, ${colors.med_gray} 33%, ${colors.gray_light} 50%, ${colors.med_gray} 67%);
  background-size: 300% 100%;
  animation: gradient 1.5s ease infinite;
  margin-bottom: 1rem;

  @keyframes gradient {
    0% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
`;
