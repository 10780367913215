import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { StepNavigationItem, StepItemHeader, LowerPart, LinkButtonWithMargin } from './StepNavigationStyles';
import { HelpText, HelpTextSmall } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { colors } from 'styles/colors';
import { StepNavigationStepProps } from 'features/allocator/stepNavigation/stepNavigationTypes';
import { Spinner } from 'features/common/Spinner';
import { selectCustomerId } from 'features/profile/profileSelectors';

export const StepConstraints = ({ unset, disableEdit, locked, loading }: StepNavigationStepProps) => {
  const customerId = useSelector(selectCustomerId);
  const optimizationConstraints = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.constraints.optimizationConstraints
  );
  const customConstraints = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.constraints.customConstraints
  );

  const customConstraintsInEffect = customConstraints.some((i) => i.min !== 0 || i.max !== 1);

  return (
    <StepNavigationItem unset={unset}>
      <div>
        <StepItemHeader>{translate('allocator.investmentPlanSteps.constraints.constraintsTitle')}</StepItemHeader>

        {loading && <Spinner position="left" size={30} />}

        {!loading &&
          (optimizationConstraints.length ? (
            <ConstraintTable>
              <tbody>
                {optimizationConstraints.map((c) => {
                  const hasChanged = c.min > 0 || c.max < 1;
                  return (
                    <ConstraintRow key={c.id} hasChanged={hasChanged}>
                      <td>{c.label}</td>
                      <td>{Math.round(c.min * 100)}</td>
                      <td> ...</td>
                      <td> {Math.round(c.max * 100)} %</td>
                    </ConstraintRow>
                  );
                })}
              </tbody>
            </ConstraintTable>
          ) : (
            <HelpText>{translate('allocator.investmentPlanSteps.constraints.constraintsDetails')}</HelpText>
          ))}
      </div>

      <LowerPart>
        <HelpTextSmall>
          {customConstraintsInEffect &&
            translate('allocator.investmentPlanSteps.constraints.customConstraintsIncluded')}
        </HelpTextSmall>

        <LinkButtonWithMargin
          to={`customer/${customerId}/portfolioManager/allocator/constraints`}
          secondary={!!optimizationConstraints.length}
          label={translate(
            `allocator.investmentPlanSteps.constraints.button${optimizationConstraints.length ? 'Change' : 'Select'}`
          )}
          disabled={disableEdit || locked}
          data-testkey={'select-constraints-button'}
        />
      </LowerPart>
    </StepNavigationItem>
  );
};

const ConstraintTable = styled.table`
  td {
    &:first-child {
      padding-right: 1rem;
    }

    &:not(:first-child) {
      text-align: right;
      padding: 0;
    }
  }
`;

interface RowProps {
  hasChanged: boolean;
}

const ConstraintRow = styled.tr<RowProps>`
  ${(props) => !props.hasChanged && `color: ${colors.gray_inactive}`};
`;
