import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { BasicInstrument, PortfolioInstrument } from 'types/instrumentsState';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { colors } from 'styles/colors';
import FormSelectionItem from 'features/allocator/common/FormSelectionItem';
import { getPortfolioIndex } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { Flex, fontSize } from 'features/common/StyledComponents';
import { selectCurrentWeights } from 'features/allocator/allocatorSelectors';
import { selectOptimizedPortfolioIds } from 'features/allocator/planPortfolios/planPortfolioSelectors';

interface Props {
  instrument: BasicInstrument;
  optimizedPortfolios: AllocatorPortfolio[];
  portfolioInstruments: PortfolioInstrument[];
  originalInstrumentsSelected: PortfolioInstrument[];
  handleInstrumentSelect: (instrument: BasicInstrument) => void;
  newInstrumentsSelected: PortfolioInstrument[];
  portfolioId: string;
}

export const InstrumentListItem = ({
  instrument,
  optimizedPortfolios,
  portfolioInstruments,
  originalInstrumentsSelected,
  handleInstrumentSelect,
  newInstrumentsSelected,
  portfolioId,
}: Props) => {
  const currentWeights = useSelector(selectCurrentWeights);
  const optimizedPortfoliosIds = useSelector(selectOptimizedPortfolioIds);

  const isSameInstrument = (i: PortfolioInstrument, j: BasicInstrument) =>
    i.security === j.security && (optimizedPortfolios.length === 0 || i.portfolioId === portfolioId);

  const isChosen = !!portfolioInstruments.find((i) => isSameInstrument(i, instrument));
  const isInOriginalSelection = originalInstrumentsSelected.find((i) => isSameInstrument(i, instrument));
  const isAdded = isChosen && !isInOriginalSelection;
  const removedFromPlan = isInOriginalSelection && !isChosen;

  const isInPositions = currentWeights.portfolioWeights
    .flatMap((i) => i.instruments)
    .find((i) => i.security === instrument.security && i.portfolioId === portfolioId);

  const otherPortfoliosWithPosition = currentWeights.portfolioWeights
    .flatMap((i) => i.instruments)
    .filter((i) => i.security === instrument.security && i.portfolioId !== portfolioId);

  const otherPortfoliosWithPositionIndex =
    otherPortfoliosWithPosition?.map((p) => getPortfolioIndex(p.portfolioId)) || [];

  const otherPortfoliosWithInstrumentInPlan = newInstrumentsSelected.filter(
    (i) => i.security === instrument.security && i.portfolioId !== portfolioId
  );

  const otherPortfoliosWithPlanIndex =
    (!otherPortfoliosWithPosition.length &&
      otherPortfoliosWithInstrumentInPlan?.map((p) => getPortfolioIndex(p.portfolioId))) ||
    [];

  const portfolioIsNotOptimized = !optimizedPortfoliosIds.includes(portfolioId);

  return (
    <StyledFormSelectionItem
      key={instrument.security}
      selected={isChosen}
      value={instrument.name}
      data-testkey="select-instrument"
      onChange={() => handleInstrumentSelect(instrument)}
      disabled={!!isInPositions || (optimizedPortfolios.length > 0 && portfolioIsNotOptimized)}
      bg="white"
      type="checkbox"
    >
      <Name>{instrument.name || instrument.security}</Name>
      <HasChanged>
        {isAdded && <Green data-testkey="added-to-plan">{translate('allocator.instrumentsView.addedToPlan')}</Green>}

        {!!otherPortfoliosWithPositionIndex.length && (
          <div data-testkey="position-in-another-portfolio">
            {`${translate(
              'allocator.instrumentsView.inPositionsInAnotherPortfolio'
            )} ${otherPortfoliosWithPositionIndex.join(', ')}`}
          </div>
        )}

        {!!isInPositions && (
          <div data-testkey="position-in-another-portfolio">{translate('allocator.instrumentsView.inPositions')}</div>
        )}

        {!!otherPortfoliosWithPlanIndex.length && (
          <div data-testkey="in-plan-in-another-portfolio">
            {`${translate('allocator.instrumentsView.inPlanInAnotherPortfolio')} ${otherPortfoliosWithPlanIndex.join(
              ', '
            )}`}
          </div>
        )}

        {removedFromPlan && translate('allocator.instrumentsView.removedFromPlan')}
      </HasChanged>
      <Type>{instrument.type}</Type>
    </StyledFormSelectionItem>
  );
};

const Green = styled.div`
  color: ${colors.primary_dark};
  font-weight: 700;
`;

const StyledFormSelectionItem = styled(FormSelectionItem)`
  padding-left: 1rem;
  height: 44px;
`;

const Name = styled(Flex)`
  align-items: baseline;
  flex: auto;

  h3 {
    margin: 0;
  }
`;

const HasChanged = styled.div`
  font-size: ${fontSize.smaller};
  flex: 0 0 225px;
  line-height: 1.2;
  padding-right: 1rem;
`;

const Type = styled.div`
  flex: 0 0 150px;
  font-size: ${fontSize.smaller};
`;
