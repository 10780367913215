import React, { Component, ReactElement } from 'react';
import Immutable, { Map } from 'immutable';
import translate from 'counterpart';
import Refresh from '@mui/icons-material/Refresh';
import { NavBar } from 'features/common/NavBar';
import styles from './List.scss';
import SearchFilters from './SearchFilters';
import Pagination from './Pagination';
import { SavedFilters } from './SavedFilters';
import { ModalDialog } from 'features/common/ModalDialog';
import SearchFilterEditor from './SearchFilterEditor';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Spinner } from 'features/common/Spinner';
import { ImmutableOrderListItems, SearchFiltersType } from 'types/orderListState';
import { FilterType } from 'features/listView/listView.types';
import { ImmutableContracts } from 'types/ordersState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  items: ImmutableOrderListItems | ImmutableContracts;
  searchFilters: SearchFiltersType;
  mergeSearchFilters: (filters: SearchFiltersType) => void;
  handleInstrumentKeywords: (value: string) => void;
  type: FilterType;
  header: string;
  setSearchString: (value: string) => void;
  setSearchFilters: (filters: SearchFiltersType) => void;
  fetchItems: () => void;
  fetchPage: (skip: number) => void;
  loadingOrders: boolean;
  searchSkip: number;
  searchTake: number;
  totalResults: number;
  savedFilters: SearchFiltersType[];
  deleteSavedFilter: () => void;
  saveFilters: (filters: SearchFiltersType) => void;
  bankers: { value: string }[];
  children: ReactElement;
}

interface State {
  editorOpen: boolean;
  searchFilterEditor: Immutable.Map<string, object>;
  isNameEmpty: boolean;
}

export default class List extends Component<Props, State> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  searchTimeout: number;

  constructor(props: Props) {
    super(props);
    this.state = {
      editorOpen: false,
      searchFilterEditor: Map(),
      isNameEmpty: false,
    };

    this.mergeSearchFilters = this.mergeSearchFilters.bind(this);
    this.setSearchFilters = this.setSearchFilters.bind(this);
    this.searchItems = this.searchItems.bind(this);
    this.searchItemsOnClick = this.searchItemsOnClick.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
  }

  setSearchFilters(filters: SearchFiltersType) {
    this.props.setSearchFilters(filters);
  }

  mergeSearchFilters(key: string, value: string | Date) {
    const filters = {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    filters[key] = value;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.mergeSearchFilters(filters);
  }

  fetchPage(skip: number, event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    this.props.fetchPage(skip);
  }

  fetchItems = () => {
    this.props.fetchItems();
  };

  searchItemsOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.clearTimeout(this.searchTimeout);
    this.props.setSearchString(event.currentTarget.value);
    this.searchTimeout = window.setTimeout(this.fetchItems, 500);
  };

  searchItems(event: React.ChangeEvent<HTMLInputElement>) {
    window.clearTimeout(this.searchTimeout);
    this.props.setSearchString(event.target.value);
    this.searchTimeout = window.setTimeout(this.fetchItems, 500);
  }

  searchInstrumentsKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
    window.clearTimeout(this.searchTimeout);
    this.props.handleInstrumentKeywords(event.target.value);
    this.searchTimeout = window.setTimeout(this.fetchItems, 500);
  };

  mergeFilterEditorData = (data: SearchFiltersType) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.setState({ searchFilterEditor: this.state.searchFilterEditor.merge(data) });
  };

  openEditor = () => {
    this.setState({
      editorOpen: true,
      searchFilterEditor: Map(this.props.searchFilters),
    });
  };

  closeEditor = () => {
    this.setState({ editorOpen: false });
  };

  saveFilters = () => {
    const { searchFilterEditor } = this.state;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (searchFilterEditor.get('searchName', '') === '') {
      this.setState({
        isNameEmpty: true,
      });
    } else {
      this.setState({
        isNameEmpty: false,
      });
      this.closeEditor();
      this.props.saveFilters(this.state.searchFilterEditor.toJS());
    }
  };

  resetSearchFilters = () => {
    this.props.setSearchFilters({} as SearchFiltersType);
  };

  renderSaveButton() {
    return (
      <StyledButton
        onClick={this.saveFilters}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        disabled={this.state.searchFilterEditor.get('searchName', '') === ''}
        data-testkey="modal-save"
        key="saveFiltersButton"
        variant="text"
      >
        Tallenna
      </StyledButton>
    );
  }

  renderCancelButton() {
    return (
      <StyledButton variant="text" onClick={this.closeEditor} key="cancelButton">
        Peruuta
      </StyledButton>
    );
  }

  renderActions() {
    return [this.renderSaveButton(), this.renderCancelButton()];
  }

  render() {
    const {
      items,
      searchFilters,
      type,
      header,
      setSearchFilters,
      loadingOrders,
      searchSkip,
      searchTake,
      totalResults,
      savedFilters,
      deleteSavedFilter,
      bankers,
      children,
    } = this.props;
    let content;
    let pagination;

    if (loadingOrders) {
      content = <Spinner className={styles.spinner} size={100} />;
    } else if (items.isEmpty()) {
      content = (
        <div className={styles.spinner}>
          {translate(type === 'orders' ? 'orderList.noOrdersFound' : 'orderList.noContractsFound')}
        </div>
      );
    } else {
      content = children;
      pagination = (
        <Pagination skip={searchSkip} take={searchTake} total={totalResults} fetchContracts={this.fetchPage} />
      );
    }

    return (
      <section className={styles.orderlist}>
        <NavBar leftContent={<div className={styles.navHeader}>{translate(header)}</div>} />
        <ErrorBoundary>
          <SearchFilters
            searchFilters={searchFilters}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            mergeSearchFilters={this.mergeSearchFilters}
            searchItems={this.searchItems}
            handleInstrumentKeywords={this.searchInstrumentsKeywords}
            type={type}
            setSearchFilters={setSearchFilters}
            bankers={bankers}
          />
          <div className="row">
            <div className="column small-8">
              <span className={styles.header}>{translate(`orderList.savedSearches`)}</span>
              <StyledButton
                id="SaveSearch"
                onClick={this.openEditor}
                className={styles.btn}
                style={{ marginLeft: '2rem' }}
                data-testkey="save-search"
                variant="contained"
              >
                {translate('orderList.saveNew')}
              </StyledButton>
            </div>
            <div className="small-4 columns text-right">
              <StyledButton
                variant="contained"
                className={styles.btn}
                onClick={this.searchItemsOnClick}
                startIcon={<Refresh />}
              />
              <StyledButton
                id="ResetSearchFilters"
                className={styles.btn}
                onClick={this.resetSearchFilters}
                data-testkey="button-reset-search-filters"
                variant="contained"
              >
                {translate('orderList.removeFilters')}
              </StyledButton>
            </div>
            <div className="column small-8">
              <SavedFilters
                savedFilters={savedFilters}
                setSearchFilters={setSearchFilters}
                deleteSavedFilter={deleteSavedFilter}
              />
            </div>
          </div>
          <div className="row">
            <div className="column small-12">{content}</div>
          </div>
          <div className="row">
            <div className="column small-12 text-center">{pagination}</div>
          </div>
          <ModalDialog
            title={translate('orderList.saveSearch')}
            content={
              <SearchFilterEditor
                searchFilterEditor={this.state.searchFilterEditor.toJS()}
                setFilterEditorData={this.mergeFilterEditorData}
                type={type}
                bankers={bankers}
              />
            }
            modalDialogWidth="90%"
            open={this.state.editorOpen}
            actions={this.renderActions()}
            borderTopStyle="1px solid rgb(224, 224, 224)"
          />
        </ErrorBoundary>
      </section>
    );
  }
}
