import React, { ReactElement } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';

export interface ModalDialogProps {
  title?: string | null;
  content: string | ReactElement | null | undefined;
  actions: ReactElement | ReactElement[];
  open: boolean;
  close?: (close: boolean) => void;
  modalDialogWidth?: string | null;
  borderTopStyle?: string | null;
  borderBottomStyle?: string | null;
}

export const ModalDialog = ({
  title,
  content,
  actions,
  open = false,
  close,
  modalDialogWidth,
  borderTopStyle,
  borderBottomStyle,
}: ModalDialogProps) => {
  return (
    <Dialog
      onClose={close}
      open={open}
      sx={[
        {
          '& .MuiDialog-paper': {
            maxWidth: 'none',
            width: modalDialogWidth ? modalDialogWidth : '50%',
          },
          '& .MuiDialogActions-root': {
            display: 'block',
            textAlign: 'right',
          },
          '& .MuiDialogContent-root': {
            color: 'rgba(0, 0, 0, 0.6)',
            lineHeight: '24px',
            fontFamily: 'Calibre, sans-serif;',
            fontSize: '16px',
            borderTop: borderTopStyle ? borderTopStyle : 'none',
            borderBottom: borderBottomStyle ? borderBottomStyle : 'none',
          },
          '& .MuiTypography-root': {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: 'Calibre, sans-serif;',
          },
        },
      ]}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};
