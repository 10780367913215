import { Template } from 'types/ordersState';

export const companyProfile: Template = {
  form: {
    contractType: 'customer_company',
    items: [
      {
        key: 'sections.clientData',
        type: 'section',
        title: {
          fi: 'Perustiedot',
          en: 'Customer summary information',
          sv: '',
        },
        items: [
          {
            key: 'sections.clientData.name',
            title: {
              fi: 'Nimi',
              en: 'Name',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.businessId',
            title: {
              fi: 'Y-tunnus',
              en: 'Business ID',
              sv: '',
            },
            readOnly: true,
          },
          {
            key: 'sections.clientData.streetName',
            title: {
              fi: 'Osoite',
              en: 'Address',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.postalCode',
            title: {
              fi: 'Postinumero',
              en: 'Postal code',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.city',
            title: {
              fi: 'Postitoimipaikka',
              en: 'City',
              sv: '',
            },
          },
        ],
      },
      {
        key: 'sections.procurationInfo',
        type: 'section',
        title: {
          en: '',
          fi: 'Nimenkirjoittajat',
          sv: '',
        },
        items: [
          {
            key: 'sections.procurationInfo.procurationAbstractDescription',
            title: {
              fi: 'Nimenkirjoitusoikeus',
              en: '',
              sv: '',
            },
            readOnly: true,
          },
          {
            key: 'sections.procurationInfo.procurationDescription',
            title: {
              fi: 'Nimenkirjoittajien kuvaus',
              en: '',
              sv: '',
            },
            readOnly: true,
          },
          {
            key: 'sections.procurationInfo.procurationPersons',
            title: {
              fi: 'Nimenkirjoittajat',
              en: '',
              sv: '',
            },
            items: [
              {
                key: 'sections.procurationInfo.procurationPersons.ssn',
                title: {
                  fi: 'Henkilötunnus',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.procurationPersons.name',
                title: {
                  fi: 'Nimi',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.procurationPersons.phoneNumber',
                title: {
                  fi: 'Puhelinnumero',
                  en: '',
                  sv: '',
                },
              },
              {
                key: 'sections.procurationInfo.procurationPersons.email',
                title: {
                  fi: 'Sähköposti',
                  en: '',
                  sv: '',
                },
              },
            ],
          },
          {
            key: 'sections.procurationInfo.board',
            title: {
              fi: 'Vastuuhenkilöt',
              en: '',
              sv: '',
            },
            items: [
              {
                key: 'sections.procurationInfo.board.ssn',
                title: {
                  fi: 'Henkilötunnus',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.board.firstName',
                title: {
                  fi: 'Etunimi',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.board.lastName',
                title: {
                  fi: 'Sukunimi',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.board.roles',
                title: {
                  fi: 'Roolit',
                  en: '',
                  sv: '',
                },
                readOnly: true,
              },
              {
                key: 'sections.procurationInfo.board.phoneNumber',
                title: {
                  fi: 'Puhelinnumero',
                  en: '',
                  sv: '',
                },
              },
              {
                key: 'sections.procurationInfo.board.email',
                title: {
                  fi: 'Sähköposti',
                  en: '',
                  sv: '',
                },
              },
            ],
          },
        ],
      },
    ],
    creator: '',
    formCreationTime: '',
    editor: '',
    editorFullName: '',
    formEditTime: '',
    hasCrmIntegration: false,
    id: '',
  },
  schema: {
    contractType: 'customer_company',
    properties: {
      sections: {
        properties: {
          clientData: {
            properties: {
              name: {
                minLength: 1,
                type: 'string',
              },
              businessId: {
                minLength: 1,
                type: 'string',
              },
              postalCode: {
                minLength: 1,
                type: 'string',
              },
              streetName: {
                minLength: 1,
                type: 'string',
              },
              city: {
                minLength: 0,
                type: 'string',
              },
            },
            required: ['name', 'businessId', 'streetName', 'postalCode', 'city'],
            sectionType: 'BasicInfoSectionPrivate',
            description: 'Customer basic information',
            version: '0.0.1',
            type: 'object',
          },
          procurationInfo: {
            properties: {
              procurationAbstractDescription: {
                minLength: 0,
                maxLength: 1000,
                type: 'string',
              },
              procurationDescription: {
                minLength: 0,
                maxLength: 1000,
                type: 'string',
              },
              procurationPersons: {
                minItems: 0,
                items: {
                  properties: {
                    ssn: {
                      minLength: 0,
                      type: 'string',
                    },
                    name: {
                      minLength: 0,
                      type: 'string',
                    },
                    phoneNumber: {
                      minLength: 4,
                      type: 'string',
                    },
                    email: {
                      minLength: 0,
                      type: 'string',
                      format: 'email',
                    },
                  },
                  required: [],
                  type: 'object',
                },
                type: 'array',
              },
              board: {
                minItems: 0,
                items: {
                  properties: {
                    ssn: {
                      minLength: 0,
                      type: 'string',
                    },
                    firstName: {
                      minLength: 0,
                      type: 'string',
                    },
                    lastName: {
                      minLength: 0,
                      type: 'string',
                    },
                    roles: {
                      minLength: 0,
                      type: 'string',
                    },
                    phoneNumber: {
                      minLength: 4,
                      type: 'string',
                    },
                    email: {
                      minLength: 0,
                      type: 'string',
                      format: 'email',
                    },
                  },
                  required: [],
                  type: 'object',
                },
                type: 'array',
              },
            },
            required: [],
            sectionType: 'section',
            type: 'object',
          },
        },
        type: 'object',
      },
    },
    definitions: {},
    description: 'New private customer questions',
    type: 'object',
    categories: [],
    creator: '',
    customerTypes: [],
    editorFullName: '',
    isArchived: false,
    markFinished: false,
    name: '',
    priority: 1,
    schemaCreationTime: '',
    id: '',
    editor: '',
    schemaEditTime: '',
    names: [],
  },
  contractType: '',
  isDraft: false,
  versions: [],
  formVersions: [],
};
