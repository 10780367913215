export default {
  general: {
    loading: 'Ladataan...',
    instrument: 'Instrumentti',
    assetCategory: 'Pääomaluokka',
    security: 'Tunnus',
    weight: 'Paino',
    back: 'Takaisin',
    noInvestments: 'Ei sijoituksia',
    backToManager: 'Sijoitusten hallinta',
  },
  customerHeader: {
    customerList: 'Omat asiakkaani',
    linkToPT: 'Profiili',
    portfolioManagement: 'Sijoitusten hallinta',
    customerSearch: 'Etsi asiakas',
    myCustomers: 'Omat asiakkaani',
    createNewCustomer: 'Luo uusi asiakkuus',
    contracts: 'Tehdyt sopimukset',
    orders: 'Tehdyt toimeksiannot',
    allocatorDemo: 'Kokeile allokaattoria',
    toFrontPage: 'Etusivu',
  },
  customerList: {
    title: 'Omat asiakkaani',
    customer: 'Asiakas',
    filterByNameOrId: 'Rajaa nimellä tai asiakasnumerolla...',
    title_all: 'Kaikki',
    title_withPlan: 'Sijoitussuunnitelma toteutettu',
    title_withoutPlan: 'Ei sijoitussuunnitelmaa',
    title_withPlanNoPositions: 'Ei omistuksia',
    title_withErrors: 'Ei tietoa',
    nCustomers: 'asiakasta',
    weightDifference: 'Poikkeama painoista',
    riskDifference: 'Poikkeama riskistä',
    aum: 'AUM',
    availableCash: 'Käteisvarat',
    withdrawalBalanceLine1: 'Käytettävissä',
    withdrawalBalanceLine2: 'oleva saldo',
    createPlan: 'Luo suunnitelma',
    goToProfile: 'Profiili',
    goToPortfolioManager: 'Salkunhallinta',
    updating: 'Päivitetään...',
    dataDate: 'Tiedot:',
  },
  customerPage: {
    balance: 'Tasapainota optimoitavat salkut',
    loading: 'Ladataan asiakkaan tietoja...',
  },
  customerPortfolio: {
    title: 'Asiakkaan sijoitukset',
  },
  overview: {
    calculatingDistribution: 'Lasketaan sijoitusten jakaumaa...',
    value: {
      cash: 'Käytettävissä oleva saldo',
    },
    yield: {
      yield: 'Kehitys',
      fromStart: 'Tuotto alusta',
    },
    planInfo: {
      title: 'Sijoitussuunnitelma',
      label_notLoaded: 'Sijoitussuunnitelma',
      label_riskConflict:
        'Suunnitelman ja sopimuksen riskiluvut ovat eriävät. Näet lisätietoja siirtymällä suunnitelmalle.',
      label_invalidPlan: 'Suunnitelma ei ole ajan tasalla. Näet lisätietoja siirtymällä suunnitelmalle.',
      label_noPlan: 'Sijoitussuunnitelma',
      label_unlockedPlan: 'Sijoitussuunnitelma',
      label_lockedPlan: 'Sijoitussuunnitelma (lukittu)',
      button_notLoaded: 'Siirry sijoitussuunnitelmalle',
      button_noPlan: 'Luo sijoitussuunnitelma',
      button_unlockedPlan: 'Muokkaa sijoitussuunnitelmaa',
      button_invalidPlan: 'Muokkaa sijoitussuunnitelmaa',
      button_lockedPlan: 'Näytä lukittu sijoitussuunnitelma',
    },
    risk: 'Riskiluku',
    riskTarget: 'Riskitavoite',
    actualRisk: 'Laskennallinen riski',
    loadingPlan: 'Ladataan suunnitelmaa...',
    invalidPlan:
      'Tallennettu suunnitelma on vanhan kehitysversion mukainen, eikä siksi yhteensopiva. Luo uusi suunnitelma valitsemalla "Muokkaa sijoitussuunnitelma"',
    goSavePlanAgain: 'Käy tallentamassa uusi suunnitelma',
  },
  customerConversion: {
    introText:
      'Luo asiakkaalle sijoitussuunnitelma, jonka perusteella sijoituksia optimoidaan jatkossa. Alla voit vertailla sijoitusten nykyistä jakaumaa optimoituun suunnitelmaan ennen sen luomista.',
    chooseRisk: 'Valitse optimoidun jakauman luomisessa käytettävä riskiluku:',
    actualRisk: 'Nykyisten sijoitusten laskennallinen riskiluku',
    maxRisk: 'Korkein nykyisen sopimuksen sallima riskiluku',
    customerHasNoPlan: 'Asiakkaalla ei ole sijoitussuunnitelmaa',
  },
  assetCategoryDistribution: {
    distribution: 'Jakauma',
    current: 'Nykyinen',
    optimized: 'Optimoitu',
  },
  managedPortfolio: {
    loadingPortfolios: 'Ladataan asiakkaan salkkuja',
    loadingPlan: 'Ladataan asiakkaan sijoitussuunnitelmaa',
    planPortfolios: 'Asiakkaan sijoitussuunnitelma',
    currentPortfoliosTitle: 'Asiakkaan salkut',
    distributionByType: 'Sijoitusten jakauma tyypeittäin',
    distributionByInstrument: 'Omistusten instrumentit',
    distributionByAssetClass: 'Omistusten omaisuusluokat',
    optimizedInvestments: 'Optimoitavat salkut',
    optimizedInvestmentsHelpText:
      'Näiden instrumenttien painoihin suositetaan muutoksia, jotta riskiluku ja omaisuusluokkien painot pysyvät tavoitteessa.',
    fixedInvestments: 'Kiinteät salkut',
    fixedInvestmentsHelpText:
      'Näillä salkuilla ei käydä kauppaa. Huomioitavat salkut otetaan huomioon, kun tasapainotetaan optimoitavia salkkuja. Erillisiä salkkuja ei huomioida millään tavalla.',
    consideredInvestments: 'Huomioitavat salkut',
    consideredInvestmentsHelpText: 'Nämä sijoitukset otetaan huomioon optimoitaessa muita sijoituksia',
    ignoredInvestments: 'Erilliset salkut',
    ignoredInvestmentsHelpText: 'Näitä sijoituksia ei huomioida optimoitaessa muita sijoituksia',
    futureInvestments: 'Tulevat salkut',
    futureInvestmentsHelpText: 'Nämä odottavat tuotteiden saataville tuloa',
  },
  trade: {
    title: 'Käy kauppaa instrumenteilla',
    backToManager: 'Sijoitusten hallinta',
    backToPT: 'Pankkiirin työpöytä',
    portfolio: 'Salkku',
    cashAmount: 'Käteisvarat',
    pageDescription:
      'Valitse instrumentit, joilla haluat käydä kauppaa. Jos muutat valintoja, toteutettavien kauppojen määrät täytyy laskea uudestaan ennen kuin voit toteuttaa kaupat.',
    possession: 'Omistus',
    trading: 'Kaupankäynti',
    Sell: 'Myy',
    SellAll: 'Myy kaikki',
    Buy: 'Osta',
    contractType: 'Sopimusmalli',
    portfolioManager: 'Vastuu',
    optimizationManner: 'Optimointimalli',
    portfolioWeight: 'Paino',
    portfolioValue: 'Arvo',
    portfolioTargetWeight: 'Tavoitepaino',
    Unknown: 'Tuntematon',
    Banker: 'Pankkiiri',
    ExchangeManagement: 'Välitys',
    StockPortfolioManagement: 'Osakesalkunhoito',
    BondPortfolioManagement: 'Korkosalkunhoito',
    AllocationPortfolioManagement: 'Allokaatiosalkunhoito',
    Include: 'Optimoi',
    Consider: 'Huomioi',
    Ignore: 'Erillään',
    TV: 'TV',
    TYS: 'TYS',
    KONS: 'KONS',
    Koonti: 'Koontiraportointisalkku',
    optimizing: 'Optimoidaan...',
    loadingProfile: 'Ladataan henkilötietoja...',
    loadingPortfolios: 'Ladataan salkkuja...',
    calculatingWeights: 'Lasketaan painoja...',
    loadingPrices: 'Haetaan hintoja...',
    quantity: 'kpl',
    illiquid: 'Epälikvidi',
    percentagePoints: '%%-yks.',
    buttonRebalance: 'Optimoi kaupat uudelleen',
    sumToTrade: 'Kaupankäyntisumma',
    underOneExplanation:
      'Seuraavilla instrumenteilla ei voida käydä kauppaa, sillä niiden kaupankäyntimäärä olisi alle 1,00, eivätkä ne ole sijoitusrahastoja',
    sumWithoutUnderOne: 'Toteutunut kaupankäyntisumma ilman yllä mainittuja instrumentteja',
    sumOffset: 'Toteutunut yli-/alijäämä, kun yllä mainitut kaupat jätetään pois',
    acceptTrade: 'Hyväksy kaupat',
    Indeksiosuusrahasto: 'ETF',
    Sijoitusrahastot: 'Sijoitusrahastot',
    Joukkovelkakirjat: 'Joukkovelkakirjat',
    Osakkeet: 'Osakkeet',
    Omaisuuslaji: 'Omaisuuslaji',
    Käteisraha: 'Käteisraha',
    Muu: 'Muu',
    noCash: 'Asiakkaalla ei ole käteistä',
    optimizeCurrentFailed: 'Nykyisten sijoitusten laskenta epäonnistui.',
    riskConflict:
      'Suunnitelman ja sopimuksen riskiluvut ovat eriävät, joten kaupankäynti on estetty. Näet lisätietoja siirtymällä asiakkaan sijoitussuunnitelmalle.',
    instrumentSelectionHasChanged: 'Instrumenttivalinnat ovat muuttuneet, optimoi kaupat uudestaan.',
    optimizeFailed: 'Optimointi ei onnistunut. Yritä uudelleen eri valinnoilla.',
    noInstrumentsSelected: 'Yhtään instrumenttia ei ole valittuna.',
    instrumentsHaveErrors: 'Osa instrumenteista ei sovellu asiakkaalle. Optimoi kaupat uudelleen.',
    tradesNotAllowedBecauseOfContract: 'Sopimusmalli ei salli kaupankäyntiä.',
    portfolioStateIsNotAllowed: 'Salkun tila ei salli kaupankäyntiä.',
    contractAndStrategyAreNotOk: 'Sopimusmalli ei salli kaupankäyntiä',
    customerStateIsNotAccount: 'Asiakkuuden tila ei salli kaupankäyntiä',
    hasNoCurrency: 'Salkulla ei ole valuuttaa',
    classIsForbidden: 'Salkun luokka ei salli kaupankäyntiä',
    orderSide: 'Suunta',
    tooLowTradeAmount: 'Liian alhainen kaupankäyntimäärä',
    cashMismatch:
      'Asiakkaan käteispositioiden summa ei vastaa käytettävissä olevan käteisen määrää. Tarkista, onko asiakkaalla toimeksiantoja kesken. Jos kauppoja on kesken, selvitä ne ennen sijoitusten tasapainotusta.',
  },
};
