import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { push } from 'react-router-redux';
import moment from 'moment';
import { Location } from 'history';
import { last } from 'lodash';
import styles from './Nav.scss';
import { NavBar } from 'features/common/NavBar';
import SaveButton from 'features/common/SaveButton';
import { showConfirmDialog, createOrders, clearOrderDetails } from 'features/orders/ordersActions';
import { selectPositionValueDate, selectCurrentPortfolio } from 'features/portfolio/portfolioSelectors';
import { selectCustomerHeader } from 'features/profile/profileSelectors';
import { RootState } from 'types/rootState';
import {
  canCreateOrders,
  selectAreSignaturesRequired,
  selectFinishedCreatingOrders,
  selectIsBasisOfAdviceFieldRequired,
  selectSuitabilityToDateCheckRequiredAndNotSet,
} from 'features/orders/ordersSelectors';
import { selectHasAssignments } from 'features/orderLines/orderLinesSelectors';
import { clearAllOrderLines } from 'features/orderLines/orderLinesActions';
import { useAppDispatch } from 'core/hooks';

interface Props {
  customerId: string;
  location: Location;
}

export const PortfolioNav = ({ customerId, location }: Props) => {
  const dispatch = useAppDispatch();
  const state = useSelector((state: RootState) => state);
  const loadingContractTemplates = useSelector((state: RootState) => state.orders.loadingContractTemplates);

  const loadingSimulatedAndAdvicedRisk = useSelector((state: RootState) => state.risk.loadingSimulatedAndAdvicedRisk);
  const simulatedAndAdvicedRisk = useSelector((state: RootState) => state.risk.simulatedAndAdvicedRisk);
  const loadingSimulatedRisk = useSelector((state: RootState) => state.risk.loadingSimulatedRisk);
  const simulatedRisk = useSelector((state: RootState) => state.risk.simulatedRisk);
  const simulatedRiskReady = !!(
    !loadingSimulatedAndAdvicedRisk &&
    (simulatedAndAdvicedRisk || simulatedAndAdvicedRisk === 0) &&
    !loadingSimulatedRisk &&
    (simulatedRisk || simulatedRisk === 0)
  );

  const pathname = location.pathname;
  const backUrl = `/customer/${customerId}`;

  const portfolioDetails = selectCurrentPortfolio(state);
  const portfolioId = portfolioDetails?.portfolioId;

  const isBasisOfAdviceRequired = useSelector((state: RootState) => selectIsBasisOfAdviceFieldRequired(state));
  const basisOfAdviceEmpty = useSelector(
    (state: RootState) => !state.orderDialog.basisOfAdvice || state.orderDialog.basisOfAdvice === ''
  );
  const basisOfAdviceRequiredAndNotSet = isBasisOfAdviceRequired && basisOfAdviceEmpty;
  const suitabilityToDateCheckRequiredAndNotSet = useSelector(selectSuitabilityToDateCheckRequiredAndNotSet);

  const hasAssignments = selectHasAssignments(state);
  const orderReady = canCreateOrders(state);
  const confirmSigning = selectAreSignaturesRequired(state);
  const creatingOrders = selectFinishedCreatingOrders(state);

  const confirmDisabled = !hasAssignments || basisOfAdviceRequiredAndNotSet || suitabilityToDateCheckRequiredAndNotSet;

  const getCurrentPath = () => {
    const pathSections = pathname.split('/').filter(Boolean);
    return last(pathSections) || '';
  };

  const path = getCurrentPath();

  const getHeader = () => {
    const customerName = selectCustomerHeader(state.profile);
    const valueDate = selectPositionValueDate(state);
    const name = customerName || '';
    const date = valueDate ? moment(valueDate).format('DD.MM.YYYY') : '';
    const order =
      portfolioDetails && portfolioDetails.externalId ? `- Toimeksianto: ${portfolioDetails.externalId}` : '';
    const header = `${name} ${customerId} ${date} ${order}`;
    return header;
  };

  const getRightContent = () => {
    if (path === 'portfolio') {
      return (
        <div className={styles.right}>
          {portfolioId !== 'all' && (
            <button
              onClick={() => {
                dispatch(clearAllOrderLines());
              }}
              className={styles.clear}
              title={translate('order.removeOrders')}
              disabled={!hasAssignments}
            >
              <i className="icon icon-delete" />
            </button>
          )}

          {!loadingContractTemplates && (
            <button onClick={() => dispatch(push(backUrl))} className={styles.close} data-testkey="nav-close-button">
              {translate(`common.close`)}
            </button>
          )}

          <SaveButton
            onClick={() => {
              dispatch(push(`${backUrl}/portfolio/order/preview`));
            }}
            label={translate('order.confirm')}
            disabled={confirmDisabled}
          />
        </div>
      );
    }

    if (path === 'preview') {
      if (confirmSigning) {
        return (
          <div className={styles.right}>
            <SaveButton
              onClick={() => dispatch(showConfirmDialog())}
              label={translate(`order.send`)}
              disabled={!orderReady || !simulatedRiskReady}
            />
          </div>
        );
      } else {
        return (
          <div className={styles.right}>
            <SaveButton
              onClick={() => {
                dispatch(createOrders());
              }}
              label={translate(`order.send`)}
              disabled={!orderReady || !simulatedRiskReady}
            />
          </div>
        );
      }
    }

    if (path === 'sent') {
      return (
        <div className={styles.right}>
          <SaveButton
            label={
              <span>
                {translate(`order.move`)}
                <br />
                {translate(`order.toCustomerSearch`)}
              </span>
            }
            onClick={() => {
              dispatch(clearOrderDetails());
              dispatch(push('/search'));
            }}
            style={{ width: '12rem' }}
            tabIndex="1"
            disabled={creatingOrders}
            data-testkey={'button-back-to-customer-search'}
          />
          <SaveButton
            label={
              <span>
                {translate(`order.return`)}
                <br />
                {translate(`order.toPortfolio`)}
              </span>
            }
            onClick={() => {
              dispatch(clearOrderDetails());
              dispatch(push(`/customer/${customerId}/portfolio?f=all`));
            }}
            style={{ margin: 0, borderLeft: '1px solid #30201b', width: '12rem' }}
            tabIndex="2"
            disabled={creatingOrders}
            data-testkey={'button-back-to-portfolios'}
          />
        </div>
      );
    }
  };

  const leftContent = <h1 className={styles.leftLabel}>{getHeader()}</h1>;
  const rightContent = getRightContent();

  return (
    <NavBar
      rightContent={rightContent}
      leftContent={leftContent}
      showModalOnExit={hasAssignments}
      modalTitle={translate('order.portfolioHasIncompleteOrders')}
      modalContent={translate('order.incompleteInfoWhenMovingFrontPage')}
    />
  );
};
