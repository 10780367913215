import { useRef, useEffect, Dispatch } from 'react';
import { batch } from 'react-redux';
import { ContractTypeId, AppThunk } from 'types/types';
import { initializeInvestmentPlanState } from 'features/allocator/investmentPlan/investmentPlanActions';
import { clearAllErrors, clearError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { cancelOptimization } from 'features/weights/weightsThunks';
import {
  isFullPowerOfAttorneyPortfolioManagementPortfolio,
  isFullPowerOfAttorneyBankerOrServiceDesk,
  isConsultative,
  isFullPowerOfAttorneyIndividual,
  isOutsideFundsPortfolio,
  isNoContractPortfolio,
} from 'core/portfolios';
import { PortfolioDetails } from 'types/portfolioState';
import { RootState } from 'types/rootState';
import { allocatorContractTypes } from 'constants/allocator';
import { initializePortfoliosAndPositions } from 'features/portfolio/portfolioActions';
import { initializeValueData } from 'features/portfolioManager/valueData/valueDataActions';
import { initializeRisk } from 'features/risk/riskActions';
import { resetCustomer } from 'features/profile/profileActions';
import { selectOptimizedPortfolioIds } from 'features/allocator/planPortfolios/planPortfolioSelectors';
import { selectHasError, positionsAreEqual, selectHasPositions, hasOptimizeFailedError } from './allocatorSelectors';
import { initializeWeightsState } from 'features/weights/weightsSlice';

export const formatToDecimals = (value: number | undefined, decimals: number, unit?: string): string => {
  if (value !== 0 && (!value || typeof value === undefined)) {
    return '?';
  }
  const returnValue = unit === '%' ? value * 100 : value;
  const decimalValue =
    returnValue.toLocaleString('fi', {
      minimumFractionDigits: value !== 0 ? decimals : 0,
      maximumFractionDigits: value !== 0 ? decimals : 0,
    }) + ` ${unit || ''}`;

  return decimalValue;
};

export const formatMoney = (value: number): string[] => {
  const string = Number(value?.toFixed(2) || '0').toLocaleString('fi', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const int = string.split(',')[0];
  const decimals = string.split(',')[1];
  return [int, decimals];
};

export const useDidUpdateEffect = (fn: () => void, inputs: unknown[]) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      fn();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
};

export const clearAllocatorErrors = () => (dispatch: Dispatch<{ type: string }>) => {
  dispatch(clearError(errorKeys.fetchExamplePortfolios));
  dispatch(clearError(errorKeys.saveInvestmentPlan));
  dispatch(clearError(errorKeys.setConstraints));
};

export const resetCustomerState = (): AppThunk => (dispatch) => {
  batch(() => {
    dispatch(clearAllErrors());
    dispatch(cancelOptimization('optimize'));
    dispatch(resetCustomer());
    dispatch(initializeInvestmentPlanState());
    dispatch(initializeWeightsState());
    dispatch(initializeValueData());
    dispatch(initializePortfoliosAndPositions());
    dispatch(initializeRisk());
  });
};

export const cannotCompareWeights = (state: RootState) => {
  const allPositionsNotInPlan = selectHasError(['allPositionsNotInPlan'])(state);
  const negativePositions = selectHasError(['negativePositions'])(state);
  const noOptimizedPortfolios = selectOptimizedPortfolioIds(state)?.length === 0;
  const positionsEqual = positionsAreEqual(state);
  const hasPositions = selectHasPositions(state);
  const optimizeFailed = hasOptimizeFailedError(state);

  return (
    allPositionsNotInPlan ||
    negativePositions ||
    noOptimizedPortfolios ||
    !positionsEqual ||
    !hasPositions ||
    optimizeFailed
  );
};

export const getContractType = (portfolio: PortfolioDetails): ContractTypeId => {
  if (!portfolio) {
    return allocatorContractTypes.TUNTEMATON;
  }
  if (isNoContractPortfolio(portfolio)) {
    return allocatorContractTypes.NO_CONTRACT;
  }
  if (isOutsideFundsPortfolio(portfolio)) {
    return allocatorContractTypes.KOONTI;
  }
  if (isConsultative(portfolio)) {
    return allocatorContractTypes.KONS;
  }
  if (
    isFullPowerOfAttorneyPortfolioManagementPortfolio(portfolio) ||
    isFullPowerOfAttorneyBankerOrServiceDesk(portfolio)
  ) {
    return allocatorContractTypes.TV;
  }
  if (isFullPowerOfAttorneyIndividual(portfolio)) {
    return allocatorContractTypes.TYS;
  }
  return allocatorContractTypes.TUNTEMATON;
};

export const isAllocatorRoot = () =>
  window.location.pathname.endsWith('allocator/') || window.location.pathname.endsWith('allocator');

export const isPortfolioManager = () =>
  window.location.pathname.toLowerCase().endsWith('portfoliomanager/') ||
  window.location.pathname.toLowerCase().endsWith('portfoliomanager');

export const isAllocator = () => urlContains('/allocator');

export const urlContains = (text: string) => window.location.pathname.toLowerCase().includes(text);
