import { ConfidenceLevel } from 'types/weightsState';

export const formatVarValue = (year: number, confidenceLevels: ConfidenceLevel[] | undefined) => {
  const varValue = confidenceLevels?.[year]?.itemValue;
  if (!varValue) {
    return undefined;
  }
  const formatted = (100 - varValue) / 100;
  return formatted;
};
