import React from 'react';
import { branch, renderComponent } from 'recompose';
import { Volume } from './Volume';
import { WARRANT } from 'constants/instrumentForms';
import { isBond } from 'core/portfolios';
import { TouchedFields, ValidationErrorValue } from 'types/orderDialogState';
import { OrderLineType } from 'types/ordersState';

interface QuantityProps {
  touchedFields: TouchedFields;
  assignment: OrderLineType;
  currency: string;
  onQuantityChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  quantityHidden?: boolean;
  validationErrors: ValidationErrorValue[];
}

const Quantity = ({
  touchedFields,
  assignment,
  currency,
  onQuantityChange,
  onValueChange,
  quantityHidden,
  validationErrors,
}: QuantityProps) => {
  const error = validationErrors && validationErrors.find((e) => e.key === 'subscriptionSum');
  return (
    <div className={'row'}>
      {quantityHidden ? undefined : (
        <div className="columns small-6">
          <Volume
            touched={touchedFields.quantity}
            value={assignment.quantity}
            onChange={onQuantityChange}
            textFieldProps={{
              label: 'Määrä (kpl)',
              defaultValue: 'kpl',
            }}
            data-testkey={'order-volume-amount'}
          />
        </div>
      )}
      {assignment.financialInstrumentForm !== WARRANT ? (
        <div className="columns small-6">
          <Volume
            touched={touchedFields.amount}
            value={assignment.amount}
            onChange={onValueChange}
            textFieldProps={{
              label: `Arvo (${currency})`,
              defaultValue: currency,
            }}
            data-testkey={'order-volume-price'}
            error={error}
          />
        </div>
      ) : undefined}
    </div>
  );
};

Quantity.defaultProps = {
  quantityHidden: false,
};

interface NominalValueProps {
  touchedFields: TouchedFields;
  assignment: OrderLineType;
  currency: string;
  onValueChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
}

const NominalValue = ({ touchedFields, assignment, currency, onValueChange }: NominalValueProps) => (
  <div className={'row'}>
    <div className="columns small-9">
      <Volume
        touched={touchedFields.amount}
        value={assignment.amount}
        onChange={onValueChange}
        textFieldProps={{
          label: `Nimellisarvo (${currency})`,
          defaultValue: currency,
        }}
        decimalScale={0}
        data-testkey={'order-volume-price'}
      />
    </div>
  </div>
);

export default branch(
  ({ assignment }: QuantityProps & NominalValueProps) => isBond(assignment.financialInstrumentForm),
  renderComponent(NominalValue)
)(Quantity);
