import { apiCall } from 'core/apiCall';
import { errorKeys } from 'features/errors/errorUtils';
import { setError, clearError } from 'features/errors/errorActions';
import { AppThunk, RiskLevel } from 'types/types';
import { getAssetCategoryForAssetClass } from 'features/allocator/instruments/instrumentsUtils';
import { AssetResponse, InstrumentGroupResponse, InstrumentGroup, ExamplePortfolio } from 'types/commonDataState';
import { OptimizePlanResponseUnit } from 'types/newCustomerOptimization';
import { ALL_INSTRUMENTS_GROUP } from 'constants/allocator';
import { mapAssetCategoryWeights } from 'features/weights/weightsUtils';
import { InstrumentType } from 'types/instrumentsState';
import {
  getAssetClasses,
  getAssetClassIdForInstrumentWithWeights,
  getExamplePortfolios,
} from 'features/portfolioManager/commonData/commonDataUtils';
import { fetchPortfolio, fetchPositions } from 'features/portfolio/portfolioActions';
import { DEMO_CUSTOMER } from 'constants/common';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';

// Action types
export const EXAMPLE_PORTFOLIO_SUCCESS = 'EXAMPLE_PORTFOLIO_SUCCESS';
export const SET_LOADING_EXAMPLE_PORTFOLIOS = 'SET_LOADING_EXAMPLE_PORTFOLIOS';
export const SET_LOADING_ASSET_CLASSES = 'SET_LOADING_ASSET_CLASSES';
export const ASSET_CLASSES_SUCCESS = 'ASSET_CLASSES_SUCCESS';
export const SET_LOADING_INSTRUMENT_GROUPS = 'SET_LOADING_INSTRUMENT_GROUPS';
export const INSTRUMENT_GROUPS_SUCCESS = 'INSTRUMENT_GROUPS_SUCCESS';

// Sync actions

const setLoadingAssetClasses = (value: boolean) => {
  return <const>{
    type: SET_LOADING_ASSET_CLASSES,
    value,
  };
};

const setAssetClasses = (result: AssetResponse[]) => {
  return <const>{
    type: ASSET_CLASSES_SUCCESS,
    result,
  };
};

const setLoadingExamplePortfolios = (value: boolean) => {
  return <const>{
    type: SET_LOADING_EXAMPLE_PORTFOLIOS,
    value,
  };
};

const createExamplePortfoliosSuccess = (result: ExamplePortfolio[]) => {
  return <const>{
    type: EXAMPLE_PORTFOLIO_SUCCESS,
    result,
  };
};

const setLoadingInstrumentGroups = (value: boolean) => {
  return <const>{
    type: SET_LOADING_INSTRUMENT_GROUPS,
    value,
  };
};

const instrumentGroupSuccess = (result: InstrumentGroup[]) => {
  return <const>{
    type: INSTRUMENT_GROUPS_SUCCESS,
    result,
  };
};

export type CommonDataAction = ReturnType<
  | typeof setLoadingAssetClasses
  | typeof setAssetClasses
  | typeof setLoadingExamplePortfolios
  | typeof createExamplePortfoliosSuccess
  | typeof setLoadingInstrumentGroups
  | typeof instrumentGroupSuccess
>;

// Async actions

export const getPortfolioManagerUserData = (customerId: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const assetClasses = state.portfolioManager.commonData.assetClasses;
    const hasAssetClasses = assetClasses.length > 0;

    if (customerId === DEMO_CUSTOMER) {
      !hasAssetClasses && dispatch(fetchAssetClasses());
      return;
    }

    const portfolioDetailsById = selectPortfolioDetailsById(state);
    const hasPortfolios = Object.keys(portfolioDetailsById).length > 0;
    const hasPositions = !!state.portfolio.positions.length;

    await Promise.all([
      !hasAssetClasses ? dispatch(fetchAssetClasses()) : Promise.resolve(),
      !hasPortfolios ? dispatch(fetchPortfolio(customerId)) : Promise.resolve(),
      !hasPositions ? dispatch(fetchPositions(customerId)) : Promise.resolve(),
    ]);
  };
};

const fetchAssetClasses = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(clearError(errorKeys.fetchAssetClasses));
    try {
      dispatch(setLoadingAssetClasses(true));
      const accessToken = getState().oidc.user.access_token;
      const result = await getAssetClasses(accessToken);
      dispatch(setAssetClasses(result));
    } catch (error) {
      dispatch(setError({ context: errorKeys.fetchAssetClasses }));
    } finally {
      dispatch(setLoadingAssetClasses(false));
    }
  };
};

export const fetchExamplePortfolios = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(clearError(errorKeys.fetchExamplePortfolios));
    dispatch(setLoadingExamplePortfolios(true));

    try {
      const accessToken = getState().oidc.user.access_token;
      const result = await getExamplePortfolios(accessToken);
      const mappedResult = result.map((e: OptimizePlanResponseUnit) => ({
        risk: e.risk as RiskLevel,
        neutralOptimizationForecastStatistics: e.neutralOptimizationForecastStatistics,
        companyOptimizationForecastStatistics: e.companyOptimizationForecastStatistics,
        weights: mapAssetCategoryWeights(e.weights),
        returnStatistics: {
          portfolioReturn: e.companyOptimizationForecastStatistics.weightStats.expectedReturn,
          portfolioVolatility: e.companyOptimizationForecastStatistics.weightStats.volatility,
          riskFloat: e.companyOptimizationForecastStatistics.weightStats.riskFloat,
        },
      }));
      dispatch(createExamplePortfoliosSuccess(mappedResult));
    } catch (error) {
      dispatch(setError({ context: errorKeys.fetchExamplePortfolios }));
    } finally {
      dispatch(setLoadingExamplePortfolios(false));
    }
  };
};

export const fetchInstrumentGroups = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setLoadingInstrumentGroups(true));
    dispatch(clearError(errorKeys.fetchInstrumentGroups));
    try {
      const token = getState().oidc.user.access_token;
      const result = (await apiCall({
        method: 'get',
        path: 'api/v1/allocator/instrumentgroups',
        token,
      })) as InstrumentGroupResponse[];
      dispatch(handleInstrumentGroups(result));
    } catch (error) {
      dispatch(setError({ context: errorKeys.fetchInstrumentGroups }));
    } finally {
      dispatch(setLoadingInstrumentGroups(false));
    }
  };
};

const handleInstrumentGroups =
  (result: InstrumentGroupResponse[]): AppThunk =>
  (dispatch) => {
    const groups = result
      .filter((group) => group.showGroup || group.groupId === ALL_INSTRUMENTS_GROUP)
      .map((group: InstrumentGroupResponse) => ({
        displayName: group.displayName,
        groupId: group.groupId,
        securities: group.securities,
        instruments: group.instruments.map((i) => {
          const assetClassId = getAssetClassIdForInstrumentWithWeights(i);
          const assetCategory = getAssetCategoryForAssetClass(assetClassId);
          return {
            security: i.security,
            name: i.name,
            type: i.type as InstrumentType,
            liquidity: i.liquidity,
            assetClasses: i.weights.map((j) => ({
              assetClassId: j.assetClass,
              weight: j.weight,
            })),
            assetClassId,
            assetCategory,
          };
        }),
      }));
    dispatch(instrumentGroupSuccess(groups));
  };
