import React, { ReactElement } from 'react';
import translate from 'counterpart';

function pagination(c: number, m: number) {
  const current = c;
  const last = m;
  const delta = 2;
  const left = current - delta;
  const right = current + delta + 1;
  const range = [];
  const rangeWithDots = [];
  let l;

  for (let i = 1; i <= last; i++) {
    if (i === 1 || i === last || (i >= left && i < right)) {
      range.push(i);
    }
  }

  for (const i of range) {
    if (l) {
      if (i - l === 2) {
        rangeWithDots.push(l + 1);
      } else if (i - l !== 1) {
        rangeWithDots.push('dots');
      }
    }
    rangeWithDots.push(i);
    l = i;
  }

  return rangeWithDots;
}

function createLinks(
  currentPage: number,
  pages: number,
  take: number,
  clickHandler: (skip: number, event: React.ChangeEvent<HTMLInputElement>) => void
) {
  const linksContent = pagination(currentPage, pages);
  let dotsCounter = 0;

  const links = linksContent.map((linkText) => {
    let item: ReactElement;

    if (linkText === 'dots') {
      item = <li className="ellipsis" aria-hidden="true" key={`dots-${dotsCounter++}`} />;
    } else if (linkText === currentPage) {
      item = (
        <li className="current" key={linkText}>
          <span className="show-for-sr">{translate(`common.youReOnPage`)}</span> {linkText}
        </li>
      );
    } else {
      item = (
        <li key={linkText}>
          <a
            href="#"
            aria-label={`Page  ${linkText}`}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onClick={clickHandler.bind(this, ((linkText as number) - 1) * take)}
          >
            {linkText}
          </a>
        </li>
      );
    }

    return item;
  });

  if (currentPage === 1) {
    links.unshift(
      <li className="pagination-previous disabled" key="previous">
        <span>{translate(`common.previous`)}</span> <span className="show-for-sr">{translate(`common.page`)}</span>
      </li>
    );
  } else {
    links.unshift(
      <li className="pagination-previous" key="previous">
        <a
          href="#"
          aria-label="Previous page"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onClick={clickHandler.bind(this, (currentPage - 2) * take)}
        >
          <span>{translate(`common.previous`)}</span> <span className="show-for-sr">{translate(`common.page`)}</span>
        </a>
      </li>
    );
  }

  if (currentPage === pages) {
    links.push(
      <li className="pagination-next disabled" key="next">
        <span>{translate(`common.next`)}</span> <span className="show-for-sr">{translate(`common.page`)}</span>
      </li>
    );
  } else {
    links.push(
      <li className="pagination-next" key="next">
        <a
          href="#"
          aria-label="Next page"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onClick={clickHandler.bind(this, currentPage * take)}
        >
          <span>{translate(`common.next`)}</span> <span className="show-for-sr">{translate(`common.page`)}</span>
        </a>
      </li>
    );
  }

  return links;
}

interface Props {
  skip: number;
  take: number;
  total: number;
  fetchContracts: (skip: number, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Pagination = (props: Props) => {
  const { skip, take, total, fetchContracts } = props;
  const pages = Math.ceil(total / take);
  const currentPage = Math.ceil(skip / take);
  let paginationLinks;

  if (pages > 1) {
    paginationLinks = createLinks(currentPage + 1, pages, take, fetchContracts);
  }

  return (
    <ul className="pagination" role="navigation" aria-label="Pagination">
      {paginationLinks}
    </ul>
  );
};

export default Pagination;
