import qs from 'qs';
import cloneDeep from 'lodash/cloneDeep';
import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  SET_ORDERS_SEARCH_FILTERS,
  MERGE_ORDERS_SEARCH_FILTERS,
  SAVED_ORDERS_FILTERS_SUCCESS,
  DELETE_SAVED_ORDERS_FILTER,
  ORDERS_PDF_DOC_REQUEST,
  ORDERS_PDF_DOC_SUCCESS,
  ORDERS_PDF_DOC_FAILURE,
  ADD_SAVED_ORDERS_FILTER_SUCCESS,
} from './orderListConstants';
import { createSearchParams, createPageParams } from 'core/functions';
import PromiseStore from 'core/PromiseStore';
import { apiCall, showBlob } from 'core/apiCall';
import { CreatePageParams, CreateSearchParams } from 'core/functionsTypes';
import { AppThunk, OrderSearchResults } from 'types/types';
import { SearchFiltersType } from 'types/orderListState';
import { getSavedOrderFilters, deleteSearchFilter, postSearchFilters } from 'features/orderList/orderListUtils';

export const getOrders = (params = {}): AppThunk => {
  const searchParams = createSearchParams(params as CreateSearchParams);
  const pageParams = createPageParams(params as CreatePageParams);
  const queryParams = Object.assign(
    {
      contractMainTypes: 'order',
      ignoreCreators: 'webshop',
    },
    searchParams,
    pageParams
  );
  return (dispatch, getState) => {
    dispatch(ordersRequest());
    PromiseStore.search.cancel();

    // @ts-expect-error: ?
    PromiseStore.search = (
      PromiseStore.createCancellableRequest(
        `/api/v1/order/keywords?${qs.stringify(queryParams, { skipNulls: true })}`,
        getState().oidc.user.access_token
      ) as Promise<OrderSearchResults>
    )
      .then((result) => {
        dispatch(ordersSuccess(result));
      })
      .catch((e) => dispatch(ordersFailure(e)));
  };
};

const ordersRequest = () =>
  <const>{
    type: ORDERS_REQUEST,
  };

const ordersSuccess = (result: OrderSearchResults) =>
  <const>{
    type: ORDERS_SUCCESS,
    result,
  };

const ordersFailure = (e: string) =>
  <const>{
    type: ORDERS_FAILURE,
    error: e,
  };

export const setSearchFilters = (filters: SearchFiltersType): AppThunk => {
  return (dispatch, getState) => {
    const searchFilters = cloneDeep(filters);
    delete searchFilters.searchName;
    dispatch(setOrdersSearchFilters(searchFilters));
    const state = getState().orderList;
    return dispatch(
      getOrders({
        skip: 0,
        take: state.searchTake,
        searchFilters: state.searchFilters.toJS(),
      })
    );
  };
};

const setOrdersSearchFilters = (searchFilters: SearchFiltersType) =>
  <const>{
    type: SET_ORDERS_SEARCH_FILTERS,
    searchFilters,
  };

export const setSearchString = (searchString: string) =>
  <const>{
    type: MERGE_ORDERS_SEARCH_FILTERS,
    searchFilters: {
      searchString,
    } as SearchFiltersType,
  };

export const setInstrumentKeywords = (instrumentKeywords: string) =>
  <const>{
    type: MERGE_ORDERS_SEARCH_FILTERS,
    searchFilters: {
      instrumentKeywords,
    } as SearchFiltersType,
  };

export const mergeSearchFilters = (searchFilters: SearchFiltersType): AppThunk => {
  return (dispatch, getState) => {
    dispatch(mergeOrdersSearchFilters(searchFilters));
    const state = getState().orderList;
    return dispatch(
      getOrders({
        skip: 0,
        take: state.searchTake,
        searchFilters: state.searchFilters.toJS(),
      })
    );
  };
};

const mergeOrdersSearchFilters = (searchFilters: SearchFiltersType) =>
  <const>{
    type: MERGE_ORDERS_SEARCH_FILTERS,
    searchFilters,
  };

export const fetchSavedOrderFilters = (): AppThunk => {
  return async (dispatch, getState) => {
    try {
      const token = getState().oidc.user.access_token;
      const sub = getState().oidc.user.profile.sub;
      const result = await getSavedOrderFilters(token, sub);
      dispatch(savedOrdersFiltersSuccess(result));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };
};

const savedOrdersFiltersSuccess = (result: OrderSearchResults) =>
  <const>{
    type: SAVED_ORDERS_FILTERS_SUCCESS,
    result,
  };

export const saveFilters = (filters: SearchFiltersType): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(setSearchFilters(filters));
    try {
      const token = getState().oidc.user.access_token;
      const sub = getState().oidc.user.profile.sub;
      await postSearchFilters(token, sub, filters);
      dispatch(addSavedOrdersFilterSuccess(filters));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };
};

const addSavedOrdersFilterSuccess = (filters: SearchFiltersType) =>
  <const>{
    type: ADD_SAVED_ORDERS_FILTER_SUCCESS,
    result: filters,
  };

export const deleteSavedFilter = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(deleteOrdersFilter(id));
    try {
      const token = getState().oidc.user.access_token;
      const sub = getState().oidc.user.profile.sub;
      await deleteSearchFilter(token, sub, id);
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };
};

const deleteOrdersFilter = (id: string) =>
  <const>{
    type: DELETE_SAVED_ORDERS_FILTER,
    id,
  };

export const getContractPdf = (id: string): AppThunk => {
  return (dispatch, getState) => {
    const newWindow = window.open();
    dispatch(ordersPdfDocRequest(id));
    return apiCall({
      method: 'get',
      token: getState().oidc.user.access_token,
      path: `/api/v1/contract/pdffile/${id}`,
      responseType: 'blob',
      responseResolver: (res) => res.xhr.response,
    })
      .then((blob) => {
        // @ts-expect-error: newWindow may be null
        showBlob(newWindow, blob);
        dispatch(ordersPdfDocSuccess());
      })
      .catch((e) => dispatch(ordersPdfDocFailure(e)));
  };
};

const ordersPdfDocRequest = (id: string) =>
  <const>{
    type: ORDERS_PDF_DOC_REQUEST,
    id,
  };

const ordersPdfDocSuccess = () =>
  <const>{
    type: ORDERS_PDF_DOC_SUCCESS,
  };

const ordersPdfDocFailure = (e: string) =>
  <const>{
    type: ORDERS_PDF_DOC_FAILURE,
    error: e,
  };

export type OrderListAction = ReturnType<
  | typeof ordersRequest
  | typeof ordersSuccess
  | typeof ordersFailure
  | typeof setOrdersSearchFilters
  | typeof setSearchString
  | typeof setInstrumentKeywords
  | typeof mergeOrdersSearchFilters
  | typeof savedOrdersFiltersSuccess
  | typeof addSavedOrdersFilterSuccess
  | typeof deleteOrdersFilter
  | typeof ordersPdfDocRequest
  | typeof ordersPdfDocSuccess
  | typeof ordersPdfDocFailure
>;
