import { DEMO_CUSTOMER } from 'constants/common';
import { ACCOUNT } from 'constants/customerStates';
import { PERSON } from 'constants/customerTypes';
import { apiCall } from 'core/apiCall';
import { GetContractDraftsResponse, GetContractsResponse } from 'features/profile/profileTypes';
import { Contract } from 'types/ordersState';
import { Customer, ImmutableCustomer } from 'types/profileState';

export const getCustomerProfile = (clientId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/client/${clientId}`,
  }) as Promise<Customer>;
};

export const getContractsApiCall = async (customerId: string, token: string) => {
  return apiCall({
    path: `/api/v1/contract/client/${customerId}`,
    token,
    method: 'get',
  }) as Promise<GetContractsResponse>;
};

export const getContractDrafts = async (customerId: string, token: string) => {
  return apiCall({
    path: `/api/v1/contract/${customerId}/state`,
    token,
    method: 'get',
  }) as Promise<GetContractDraftsResponse>;
};

export const getCustomerImages = async (customerId: string, token: string) => {
  return apiCall({
    path: `/api/v1/client/identityimages/${customerId}`,
    token,
    method: 'get',
  }) as Promise<object[]>;
};

export const sortByCreationDate = (contracts: Contract[]) => {
  return contracts.sort((c1, c2) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const creationDate1 = Date.parse(c1.get('creationDate'));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const creationDate2 = Date.parse(c2.get('creationDate'));
    return creationDate2 - creationDate1;
  });
};

export const hasCustomerChanged = (customerId: string, newCustomer: ImmutableCustomer) => {
  const newCustomerId = newCustomer.get('customerId');
  const customerChanged = customerId !== newCustomerId;
  return customerChanged;
};

export const demoCustomer: Customer = {
  name: DEMO_CUSTOMER,
  customerId: DEMO_CUSTOMER,
  businessId: 'string',
  header: 'Demo-asiakas',
  firstName: 'Demo-asiakas',
  lastName: '',
  selectedRiskLevel: 0,
  contracts: [],
  isIdentified: true,
  incompetent: true,
  city: 'string',
  accountManagerName: 'string',
  state: ACCOUNT,
  contacts: [],
  segment: 'string',
  isPersonnel: true,
  phoneNumber: 'string',
  shortId: 'string',
  customerType: PERSON,
  postalCode: 'string',
  customerRelation: 'string',
  id: 'string',
  accountManagerId: 'string',
  lastScreened: 'string',
  ssn: 'string',
  customerCategorisation: 'string',
  basicContractSigned: 'string',
  mytyContractSigned: 'string',
  nextKycUpdate: 'string',
  isDigiEligible: false,
  accountNumber: 'string',
  country: 'string',
  email: 'string',
  identifier: 'string',
  riskTolerance: 0,
  streetAddress: 'string',
  riskProfile: 0,
};
