import {
  RiskAction,
  CUSTOMER_RISK_SIMULATED_REQUEST,
  CUSTOMER_RISK_SIMULATED_SUCCESS,
  CUSTOMER_RISK_SIMULATED_FAILURE,
  CUSTOMER_RISK_SIMULATED_AND_ADVICED_REQUEST,
  CUSTOMER_RISK_SIMULATED_AND_ADVICED_SUCCESS,
  CUSTOMER_RISK_SIMULATED_AND_ADVICED_FAILURE,
  CUSTOMER_RISK_ACTUAL,
  CUSTOMER_RISK_ACTUAL_SUCCESS,
  CUSTOMER_RISK_ACTUAL_FAILURE,
  INITIALIZE_RISK,
} from 'features/risk/riskActions';
import { RiskState } from 'types/riskState';

const initialState: RiskState = {
  actualRisk: 0,
  loadingActualRisk: false,
  simulatedRisk: 0,
  loadingSimulatedRisk: false,
  simulatedAndAdvicedRisk: 0,
  loadingSimulatedAndAdvicedRisk: false,
};

export const riskReducer = (state: RiskState = initialState, action: RiskAction) => {
  switch (action.type) {
    case CUSTOMER_RISK_SIMULATED_REQUEST:
      return {
        ...state,
        loadingSimulatedRisk: true,
        simulatedRisk: 0,
      };
    case CUSTOMER_RISK_SIMULATED_SUCCESS:
      return {
        ...state,
        simulatedRisk: action.risk,
        loadingSimulatedRisk: false,
      };
    case CUSTOMER_RISK_SIMULATED_FAILURE:
      return {
        ...state,
        loadingSimulatedRisk: false,
        simulatedRisk: undefined,
      };
    case CUSTOMER_RISK_SIMULATED_AND_ADVICED_REQUEST:
      return {
        ...state,
        loadingSimulatedAndAdvicedRisk: true,
        simulatedAndAdvicedRisk: 0,
      };
    case CUSTOMER_RISK_SIMULATED_AND_ADVICED_SUCCESS:
      return {
        ...state,
        simulatedAndAdvicedRisk: action.risk,
        loadingSimulatedAndAdvicedRisk: false,
      };
    case CUSTOMER_RISK_SIMULATED_AND_ADVICED_FAILURE:
      return {
        ...state,
        loadingSimulatedAndAdvicedRisk: false,
        simulatedAndAdvicedRisk: undefined,
      };
    case CUSTOMER_RISK_ACTUAL:
      return {
        ...state,
        loadingActualRisk: true,
        actualRisk: 0,
      };
    case CUSTOMER_RISK_ACTUAL_SUCCESS:
      return {
        ...state,
        actualRisk: action.result,
        loadingActualRisk: false,
      };
    case CUSTOMER_RISK_ACTUAL_FAILURE:
      return {
        ...state,
        loadingActualRisk: false,
        actualRisk: undefined,
      };
    case INITIALIZE_RISK: {
      return initialState;
    }
    default:
      return state;
  }
};
