import React from 'react';
import { branch, renderComponent } from 'recompose';
import translate from 'counterpart';
import styles from './SignatoryListItem.scss';
import Warning from 'features/common/Warning';
import { Signatory } from 'types/ordersState';

const warning = <Warning>{translate(`contract.signatoryWarning`)}</Warning>;

interface Props {
  signatory: Signatory;
  onSelect?: (signatory: Signatory) => void;
  isSelectable: boolean;
}

export class SignatoryListItem extends React.Component<Props> {
  static defaultProps = {
    onSelect: () => {},
    isSelectable: true,
  };

  handleOnSelect = () => {
    if (this.props.onSelect) {
      this.props.onSelect(this.props.signatory);
    }
  };

  signatoryListItemClassname = () => {
    if (this.props.isSelectable) {
      return styles.signatoryListItemSelectable;
    }
    return styles.signatoryListItemUnselectable;
  };

  getSignatory = () => {
    const { signatory } = this.props;
    if (signatory) {
      const { firstName, lastName, ssn, email, phone } = signatory;
      return [
        <div key={'name'}>
          <b>{`${firstName} ${lastName} (${ssn})`}</b>
        </div>,
        <div key={'email'}>{`${email}, ${phone}`}</div>,
      ];
    }
    return warning;
  };

  render() {
    return (
      <div className={this.signatoryListItemClassname()} onClick={this.handleOnSelect}>
        {this.getSignatory()}
      </div>
    );
  }
}

export default branch(
  ({ signatory, isSelectable }: Props) => !signatory && !isSelectable,
  renderComponent(() => warning)
)(SignatoryListItem);
