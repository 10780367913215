import translate from 'counterpart';
import { OptimizePlanPayload } from 'types/newCustomerOptimization';
import { clearError, setError } from 'features/errors/errorActions';
import {
  checkIfCantOptimize,
  mapOptimizationResult,
  optimizeForExistingCustomer,
  optimizeForNewCustomer,
  selectIgnoredPortfolioIdsForOptimization,
  selectNonIgnoredPortfolioIdsForOptimization,
} from './weightsUtils';
import { errorKeys } from 'features/errors/errorUtils';
import { setAUM } from 'features/portfolioManager/valueData/valueDataActions';
import { clearPortfolioWeights } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { AppThunk, ErrorContext, PromiseType, RiskLevel } from 'types/types';
import {
  selectHasPositions,
  selectOptimizedWeightsForRiskLevel,
  selectRisk,
} from 'features/allocator/allocatorSelectors';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { ExistingCustomerOptimizePlanPayload, StaticInstrument } from 'types/existingCustomerOptimization';
import { cancelPromise, postOptimizeCurrent } from 'features/weights/weightsApiCalls';
import {
  updateOptimizedPortfoliosSettings,
  updateOptimizedPortfolios,
  resetCurrentWeights,
  resetOptimizedWeights,
  setOptimizingCurrent,
  setOptimizingPlan,
  updateCurrentWeights,
  updateOptimizedWeights,
  deleteSavedOptimizationResults,
} from 'features/weights/weightsSlice';
import {
  createOptimizedPortfoliosFromOptimizedValues,
  selectHasPositionsInNonIgnoredPortfolio,
  selectOptimizedPortfoliosForRiskLevel,
} from 'features/weights/weightsSelectors';
import { OptimizedWeights } from 'types/weightsState';
import { OptimizationOutputPortfolios } from 'features/weights/weightTypes';
import { selectOptimizedPortfolioIds } from 'features/allocator/planPortfolios/planPortfolioSelectors';

// Async thunk actions

export const cancelOptimization =
  (promiseName: PromiseType): AppThunk =>
  (dispatch) => {
    dispatch(setOptimizingPlan(false));
    cancelPromise(promiseName);
  };

export const optimizeCurrent = (params: {
  customerId: string;
  nonIgnoredPortfolios?: string[];
  ignoredPortfolios?: string[];
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(clearError(errorKeys.optimizeCurrent));
    const state = getState();
    const hasPositions = selectHasPositions(state);

    const nonIgnoredPortfolioIds = params?.nonIgnoredPortfolios || selectNonIgnoredPortfolioIdsForOptimization(state);
    const ignoredPortfolioIds = params?.ignoredPortfolios || selectIgnoredPortfolioIdsForOptimization(state);

    const hasPositionsInNonIgnoredPortfolio = selectHasPositionsInNonIgnoredPortfolio(state);

    const payloadWithNonIgnoredPortfolioIds = {
      customerId: params.customerId,
      portfolioIds: nonIgnoredPortfolioIds,
      getCommitments: false,
    };

    const payloadWithIgnoredPortfolioIds = {
      ...payloadWithNonIgnoredPortfolioIds,
      portfolioIds: ignoredPortfolioIds,
    };

    if (hasPositions && !hasPositionsInNonIgnoredPortfolio) {
      dispatch(setError({ context: errorKeys.cannotOptimizeCurrent }));
    }

    if (!hasPositions || !hasPositionsInNonIgnoredPortfolio) {
      return;
    }

    try {
      dispatch(setOptimizingCurrent(true));

      const [resultForNonIgnoredPortfolioIds, resultForIgnoredPortfolioIds] = await Promise.all([
        postOptimizeCurrent(payloadWithNonIgnoredPortfolioIds, state),
        ignoredPortfolioIds.length > 0 ? postOptimizeCurrent(payloadWithIgnoredPortfolioIds, state) : null,
      ]);

      const combinedResults = {
        ...resultForNonIgnoredPortfolioIds,
        instrumentPortfolioWeights: resultForNonIgnoredPortfolioIds.instrumentPortfolioWeights
          .concat(resultForIgnoredPortfolioIds?.instrumentPortfolioWeights || [])
          .filter(Boolean),
      };

      if (!combinedResults.weights) {
        dispatch(setOptimizingCurrent(false));
        return;
      }

      const mappedResult = mapOptimizationResult(combinedResults);
      dispatch(updateCurrentWeights(mappedResult));
      dispatch(setAUM(combinedResults?.totalAum || 0));
    } catch (error) {
      dispatch(setError({ context: errorKeys.optimizeCurrent }));
      return dispatch(resetCurrentWeights());
    }
  };
};

export const optimizeForTrade = (
  staticInstruments: StaticInstrument[],
  selectedInstruments: string[]
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(clearError(errorKeys.optimizePlan));
    dispatch(clearError(errorKeys.optimizePlanWithIlliquids));
    dispatch(clearError(errorKeys.optimizePlanWithoutIlliquids));
    dispatch(cancelOptimization('reoptimize'));
    dispatch(setOptimizingPlan(true));
    const hasPositions = selectHasPositions(state);

    try {
      const { weights, portfolios } = !hasPositions
        ? await optimizeForNewCustomer(state, { securitiesAvailable: selectedInstruments })
        : await optimizeForExistingCustomer(state, { staticInstruments });

      dispatch(updateOptimizedWeights(weights));
      dispatch(
        updateOptimizedPortfolios({
          portfolios: portfolios.portfolios,
          riskLevel: portfolios.riskLevel,
          optimizationForecastType: portfolios.optimizationForecastType,
        })
      );
    } catch (error) {
      dispatch(
        setError({
          error: error.msg || translate(`errors.${errorKeys.optimizePlan}`),
          context: errorKeys.optimizePlan,
        })
      );
    } finally {
      dispatch(setOptimizingPlan(false));
    }
  };
};

export const optimize = (params?: {
  payload?: Partial<ExistingCustomerOptimizePlanPayload | OptimizePlanPayload>;
  ignoredPortfolios?: AllocatorPortfolio[];
  useCache?: boolean;
}): AppThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const hasPositions = selectHasPositions(state);
    const cantOptimize = checkIfCantOptimize(state);
    const riskLevel = selectRisk(state);

    if (cantOptimize) {
      dispatch(resetOptimizedWeights(riskLevel));
      const optimizedPortfolios = selectOptimizedPortfolioIds(state);
      const hasPositions = selectHasPositions(state);
      if (hasPositions && optimizedPortfolios?.length === 0) {
        dispatch(setError({ context: 'noOptimizedPortfolios' }));
      }
      return;
    }

    dispatch(clearError(errorKeys.optimizePlan));
    dispatch(clearError(errorKeys.optimizePlanWithIlliquids));
    dispatch(clearError(errorKeys.optimizePlanWithoutIlliquids));
    dispatch(cancelOptimization('optimize'));

    if (params?.useCache) {
      // Check if saved optimization results already exist
      const optimizedWeights = selectOptimizedWeightsForRiskLevel(state);
      if (optimizedWeights.riskLevel !== 0) {
        return;
      }
    }

    dispatch(setOptimizingPlan(true));

    try {
      const { weights, portfolios } = !hasPositions
        ? await optimizeForNewCustomer(state, params?.payload) // optimize according to plan, not possessions
        : await optimizeForExistingCustomer(state, params?.payload);

      if (!weights || !portfolios) {
        throw 'Optimization failed for hiven parameters';
      } else {
        dispatch(handleSuccessfullOptimization(weights, portfolios, params?.useCache));
      }
    } catch (error) {
      dispatch(handleFailedOptimization(error, params?.useCache));
    } finally {
      dispatch(setOptimizingPlan(false));
    }
  };
};

const handleSuccessfullOptimization =
  (resultWeights: OptimizedWeights, resultPortfolios: OptimizationOutputPortfolios, useCache?: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    // If result is only partially ok, we still want to show an error
    if (
      resultWeights.withIlliquids.assetCategoryWeights.length === 0 ||
      resultWeights.withoutIlliquids.assetCategoryWeights.length === 0
    ) {
      const failedPlan =
        resultWeights.withIlliquids.assetCategoryWeights.length === 0 ? 'WithIlliquids' : 'WithoutIlliquids';

      dispatch(setError({ context: `${errorKeys.optimizePlan}${failedPlan}` as ErrorContext }));
    }
    // Don't use cache -> doing something that should also reset cache, like adding instruments
    if (!useCache) {
      dispatch(deleteSavedOptimizationResults());
    }
    dispatch(updateOptimizedWeights(resultWeights));
    dispatch(
      updateOptimizedPortfolios({
        portfolios: resultPortfolios.portfolios,
        riskLevel: resultPortfolios.riskLevel,
        optimizationForecastType: resultPortfolios.optimizationForecastType,
      })
    );

    // only update portfolio settings if they don't exist
    const optimizedPortfolios = selectOptimizedPortfoliosForRiskLevel(state)(resultPortfolios.riskLevel as RiskLevel);
    if (optimizedPortfolios.portfolios.length === 0) {
      dispatch(updateOptimizedPortfoliosSettings(resultPortfolios.portfolios));
    }
    dispatch(clearError(errorKeys.noOptimizedPortfolios));
  };

const handleFailedOptimization =
  (error: { name: string }, useCache?: boolean): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    // If not using cache -> doing something that should also reset cache, like adding instruments
    if (!useCache) {
      dispatch(deleteSavedOptimizationResults());
    }

    // Optimization failed but we still want to construct optimizedPortfolios with empty weight values
    const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);
    const portfoliosWithoutWeights = clearPortfolioWeights(optimizedPortfolios);
    const riskLevel = selectRisk(state);
    const optimizationForecastType = state.portfolioManager.investmentPlan.optimizationForecastType;

    dispatch(
      updateOptimizedPortfolios({
        portfolios: portfoliosWithoutWeights,
        riskLevel,
        optimizationForecastType,
      })
    );
    dispatch(updateOptimizedPortfoliosSettings(optimizedPortfolios));

    const cancellationError = error.name === 'CancellationError';
    if (!cancellationError) {
      dispatch(resetOptimizedWeights(riskLevel));
      dispatch(
        setError({
          error: translate(`errors.${errorKeys.optimizePlan}`),
          context: errorKeys.optimizePlan,
        })
      );
    }
  };
