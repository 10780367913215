import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import { fontSize } from 'features/common/StyledComponents';
import { RiskLevel } from 'types/types';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { getExamplePortfolioPropsForRiskLevel } from 'features/portfolioManager/commonData/commonDataUtils';

interface Props {
  riskLevel: RiskLevel;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  disabled: boolean;
}

export const RiskScaleButton = ({ riskLevel, onClick, selected, disabled }: Props) => {
  const { portfolioReturn } = useSelector(getExamplePortfolioPropsForRiskLevel(riskLevel));

  return (
    <RiskButtonContainer
      selected={selected}
      disabled={disabled}
      value={riskLevel}
      onClick={onClick}
      type={'button'}
      data-testkey={`select-risk-button-${riskLevel}`}
    >
      <StyledRiskLevel>{riskLevel}</StyledRiskLevel>
      <YieldValue>{formatToDecimals(portfolioReturn, 1, '%')}</YieldValue>
    </RiskButtonContainer>
  );
};

interface StyledButtonProps {
  selected: boolean;
  disabled: boolean;
}

const RiskButtonContainer = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: ${(props) => `2px solid ${props.selected ? colors.primary : 'transparent'}`};
  ${(props) => props.selected && `background: ${colors.light_green}`};
  border-radius: 2px;
  ${(props) => props.disabled && !props.selected && 'opacity: .4'};

  & > * {
    pointer-events: none;
  }
`;

const StyledRiskLevel = styled.div`
  text-align: center;
  font-weight: 700;
  padding: 1rem;
`;

const YieldValue = styled.div`
  color: ${colors.dark_gray};
  text-align: center;
  font-size: ${fontSize.smaller};
  padding: 1rem;
`;
