import {
  INITIALIZE_VALUE_DATA,
  SET_TRADE_ORDER_LINES,
  ValueDataAction,
  SET_SUITABILITY_ERRORS_TRADE_VIEW,
  SET_AUM,
  SET_CASH_TRADE_AMOUNT,
} from 'features/portfolioManager/valueData/valueDataActions';
import { ValueDataState } from 'types/portfolioManagerState';

const initialState: ValueDataState = {
  aum: 0,
  cashTradeAmount: 0,
  tradeOrderLines: [],
  suitabilityErrors: [],
};

export function valueDataReducer(state = initialState, action: ValueDataAction) {
  switch (action.type) {
    case INITIALIZE_VALUE_DATA:
      return {
        ...initialState,
      };

    case SET_AUM:
      return {
        ...state,
        aum: action?.result || 0,
      };

    case SET_CASH_TRADE_AMOUNT:
      return {
        ...state,
        cashTradeAmount: action?.result || 0,
      };

    case SET_TRADE_ORDER_LINES:
      return {
        ...state,
        tradeOrderLines: action.tradeOrderLines,
      };

    case SET_SUITABILITY_ERRORS_TRADE_VIEW:
      return {
        ...state,
        suitabilityErrors: action.suitabilityErrors,
      };

    default:
      return state;
  }
}
