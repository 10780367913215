import { groupBy } from 'lodash';
import { AssetGroupType } from 'features/allocator/allocatorTypes';
import { Constraint, ConstraintPayload, ConstraintsState } from 'types/constraintsState';
import { AssetClassId } from 'types/types';
import { getAssetCategoryForAssetClass } from 'features/allocator/instruments/instrumentsUtils';
import { AssetClass } from 'types/commonDataState';

const constraintCategories = {
  Osakkeet: 'Stocks',
  Korot: 'Bonds',
  Vaihtoehtoiset: 'Alternatives',
  Custom: 'Custom',
  Muut: 'Other',
  Allokaatiot: 'Allocations',
  Tuntematon: 'Tuntematon',
};

const getEmptyConstraints = (assetClasses: AssetClass[], allAssetClassesGrouped: AssetGroupType): Constraint[] => {
  return assetClasses.reduce((constraints, assetClass) => {
    const existing = constraints.find((constraint) => constraint.id === assetClass.assetCategory);

    const subAssetCategory = {
      id: assetClass.assetClassId,
      min: 0.0,
      max: 1.0,
      assetClasses: [assetClass.assetClassId],
      inRelationTo: [],
      isCustom: false,
      label: assetClass.name,
      items: [],
      category: constraintCategories[getAssetCategoryForAssetClass(assetClass.assetClassId)],
    };

    if (existing) {
      existing.items?.push(subAssetCategory);
    } else {
      const assetCategory = {
        id: assetClass.assetCategory || assetClass.assetClassId,
        min: 0.0,
        max: 1.0,
        assetClasses: assetClass.assetCategory
          ? allAssetClassesGrouped[assetClass.assetCategory]
          : [assetClass.assetClassId],
        inRelationTo: [],
        items: [subAssetCategory],
        isCustom: false,
        label: assetClass.assetCategory || assetClass.assetClassId,
        category: constraintCategories[assetClass.assetCategory],
      };

      constraints.push(assetCategory);
    }

    return constraints;
  }, [] as Constraint[]);
};

export const getInitialConstraints = (assetClasses: AssetClass[]): Constraint[] => {
  const assetClassesWithoutCash = assetClasses.filter((a) => a.assetClassId !== 'CASH');
  const allAssetClassesGrouped = getAssetClassessByAssetCategory(assetClassesWithoutCash);
  return getEmptyConstraints([...assetClassesWithoutCash], allAssetClassesGrouped);
};

const getAssetClassessByAssetCategory = (assetClasses: AssetClass[]): AssetGroupType => {
  const assetClassesGrouped = groupBy(assetClasses, 'assetCategory');
  const keys = Object.keys(assetClassesGrouped) as AssetClassId[];
  const assetClassesByAssetCategory = keys.reduce((acc, key) => {
    acc[key] = assetClassesGrouped[key].map((i) => i.assetClassId);
    return acc;
  }, {} as AssetGroupType);
  return assetClassesByAssetCategory;
};

export const validateConstraintsMin = (constraints: Constraint[]) => {
  return !(sumOfMin(constraints) > 1);
};
export const validateConstraintsMax = (constraints: Constraint[]) => {
  return !(sumOfMax(constraints) < 1);
};

export const sumOfMax = (constraints: Constraint[]) => {
  return constraints.reduce((acc, constraint) => acc + constraint.max, 0);
};

export const sumOfMin = (constraints: Constraint[]) => {
  return constraints.reduce((acc, constraint) => acc + constraint.min, 0);
};

export const createConstraintsPayload = (constraints: ConstraintsState): ConstraintPayload[] =>
  selectAssetCategoryConstraints(constraints.optimizationConstraints).concat(
    selectAssetCategoryConstraints(constraints.customConstraints)
  );

const selectAssetCategoryConstraints = (constraints: Constraint[]): ConstraintPayload[] => {
  if (!constraints) {
    return [];
  }
  return constraints.reduce((acc, constraint) => {
    if (constraint.min === 0 && constraint.max === 1 && constraint?.items && !constraint.isCustom) {
      return acc.concat([...selectAssetCategoryConstraints(constraint.items)]);
    } else if (constraint.min === 0 && constraint.max === 1 && !constraint.isCustom) {
      return acc;
    } else {
      return acc.concat([
        {
          min: constraint.min,
          max: constraint.max,
          assetClassesConstrained: constraint.assetClasses,
          assetClassesInRelation: constraint.inRelationTo,
          label: constraint.label || '',
          type: 'CustomConstraint', // type for constraints coming from front-end, not actual custom constraints
          category: constraint.category,
        },
        ...selectAssetCategoryConstraints(constraint.items),
      ]);
    }
  }, [] as ConstraintPayload[]);
};

export const getConstraintId = (customConstraints: Constraint[], label: string): string => {
  let constraintId = label.split(' ').join('-');
  const duplicateId = customConstraints.find((customConstraint) => customConstraint.id === constraintId);

  if (duplicateId) {
    constraintId = constraintId.concat(new Date().getTime().toString().substr(8, 5));
  }
  return constraintId;
};
