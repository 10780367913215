import React from 'react';
import accounting from 'accounting';
import translate from 'counterpart';
import moment from 'moment';
import Select from 'react-select-old';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { push } from 'react-router-redux';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { OrderSide } from 'features/orders/components/OrderSide';
import { canBeAdviced, isListed } from 'core/portfolios';
import { rowTypes } from 'constants/orderRowTypes';
import { PortfolioDetails } from 'types/portfolioState';
import { OrderLineType } from 'types/ordersState';
import { getUnitNumber, getFee, getWarrantQuantity } from 'features/orderLines/orderLinesUtils';
import { removeOrderLine } from 'features/orders/ordersActions';
import { copyMOISToCurrent, editOrderLine } from 'features/orderDialog/orderDialogActions';
import { changeRowType } from 'features/orderLines/orderLinesActions';
import { SelectValue } from 'types/types';
import { RootState } from 'types/rootState';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { useAppDispatch } from 'core/hooks';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { colors } from 'styles/colors';

interface Props {
  order: OrderLineType;
  portfolioDetails: PortfolioDetails;
  editable: boolean;
}

export const OrderLine = ({ order, portfolioDetails, editable }: Props) => {
  const dispatch = useAppDispatch();
  const customerId = useSelector(selectCustomerId);
  const currentPortfolioId = useSelector((state: RootState) => state.routing.locationBeforeTransitions.query.f);
  const changeTypeEnabled = false; // flag for enabling/disabling type of order on order line, see AMLT-4748

  const getFullName = () => {
    const targetName = order.financialInstrument.name;
    const fullName = order.counterFinancialInstrument ? (
      <>
        {targetName} <i className="icon icon-nuoli" /> {order.counterFinancialInstrument.name}
      </>
    ) : (
      targetName
    );
    return fullName;
  };

  const onDelete = () => {
    dispatch(removeOrderLine(order._id, portfolioDetails.portfolioId));
  };

  const onEdit = () => {
    dispatch(editOrderLine(order._id));
    if (portfolioDetails.portfolioId !== currentPortfolioId) {
      dispatch(push(`customer/${customerId}/portfolio?f=${portfolioDetails.portfolioId}`));
    }
  };

  const onChangeRowType = (selectValue: SelectValue) => {
    dispatch(changeRowType(order._id, selectValue));
  };

  const copyMOISToCurrentForm = () => {
    if (!order.minutesOfInvestmentService) {
      return;
    }
    dispatch(copyMOISToCurrent(order.minutesOfInvestmentService));
  };

  return (
    <>
      <PtTableRow
        data-testkey="order-line"
        data-security={`${order.portfolioId}-${order.financialInstrument.symbol}`}
        side={order.side}
        sx={
          editable
            ? { 'td, th': { border: 0, borderColor: colors.gray_light } }
            : { '&:last-child td, &:last-child th': { border: 0 } }
        }
      >
        <PtCell>
          <OrderSide type={order.side} />
        </PtCell>

        <PtCell>{getFullName()}</PtCell>

        <PtCell>{translate(`order.${order.financialInstrument.financialInstrumentForm}`)}</PtCell>

        <PtCell>{`${portfolioDetails.externalId ?? ''}${portfolioDetails.externalId ? '/' : ''}${
          portfolioDetails.portfolioName
        }`}</PtCell>

        <PtCell>
          {translate('order.fee')}: <NoLineBreak>{getFee(order)}</NoLineBreak>
        </PtCell>

        <PtCell>{getWarrantQuantity(order)}</PtCell>

        <PtCell className="text-right" data-testkey="order-line-units">
          <b>
            <NoLineBreak>{getUnitNumber(order)}</NoLineBreak>
          </b>
        </PtCell>

        {isListed(order.financialInstrument.financialInstrumentForm, order.side) ? (
          <>
            <PtCell>{translate(`order.${order.orderType}`)}</PtCell>
            <PtCell>{order.price ? `${accounting.formatMoney(order.price)} ${order.priceCurrency}` : ''}</PtCell>
            <PtCell>{moment(order.expireDate, 'YYYY-MM-DD').format('DD.MM.YYYY')}</PtCell>
          </>
        ) : (
          <>
            <PtCell />
            <PtCell />
            <PtCell />
          </>
        )}

        {editable && changeTypeEnabled ? (
          <PtCell
            style={{ minWidth: '10rem' }}
            key={'rowType'}
            data-testkey={`order-or-advice-${order.financialInstrument.symbol}-select`}
          >
            <ColoredSelect
              side={order.side}
              options={rowTypes}
              className="Select-white"
              value={order.rowType}
              clearable={false}
              searchable={false}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange={onChangeRowType}
              disabled={!canBeAdviced(portfolioDetails.contractName, order.issuer)}
            />
          </PtCell>
        ) : (
          <PtCell />
        )}

        {editable ? (
          <PtCell className="text-right" style={{ paddingRight: '5px' }} key={'editRow'}>
            <i
              onClick={onEdit}
              style={{ marginLeft: '1rem', cursor: 'pointer', color: '#00eb64' }}
              className="icon icon-edit"
              data-testkey={`edit-row-icon-${order.financialInstrument.symbol}`}
            />
          </PtCell>
        ) : (
          <PtCell />
        )}

        <PtCell className="text-right" style={{ paddingRight: '15px' }}>
          <i
            onClick={onDelete}
            style={{ marginLeft: '1rem', cursor: 'pointer', color: '#ed0726' }}
            className="icon icon-close remove-orderline"
            data-testkey={`remove-orderline-${order.portfolioId}-${order.financialInstrument.symbol}`}
          />
        </PtCell>
      </PtTableRow>
      {editable && order.minutesOfInvestmentService?.info && (
        <PtTableRow side={order.side} $secondRow={true} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <PtCell className="text-left">Pöytäkirja</PtCell>
          <PtCell className="text-left">
            <OverflowEllipsis>
              {moment(order.minutesOfInvestmentService?.date, 'YYYY-MM-DD HH:mm').format('DD.MM.YYYY HH:mm')}
            </OverflowEllipsis>
          </PtCell>
          <PtCell className="text-left">
            <OverflowEllipsis>{order.minutesOfInvestmentService?.location}</OverflowEllipsis>
          </PtCell>
          <PtCell className="text-left">
            <OverflowEllipsis>{order.minutesOfInvestmentService?.participants}</OverflowEllipsis>
          </PtCell>
          <PtCell className="text-left">
            <OverflowEllipsis>{order.minutesOfInvestmentService?.convener}</OverflowEllipsis>
          </PtCell>
          <PtCell />
          <PtCell />
          <PtCell />
          <PtCell />
          <PtCell />
          <PtCell />
          <PtCell />
          <PtCell className="text-right">
            <StyledButton onClick={copyMOISToCurrentForm}>Kopioi</StyledButton>
          </PtCell>
        </PtTableRow>
      )}
    </>
  );
};

const NoLineBreak = styled.span`
  white-space: nowrap;
`;

const OverflowEllipsis = styled.span`
  text-overflow: ellipsis;
  width: 50px;
`;

const PtCell = styled(TableCell)`
  ${`&.${tableCellClasses.body}`} {
    font-size: 16px;
    font-weight: 400;
    color: black;
    font-family: 'Calibre', sans-serif;
  }
`;
const PtTableRow = styled(TableRow)`
  ${(props: RowProps) =>
    props.side === 'Buy' && `background-color: ${props.$secondRow ? colors.mois_green : colors.buy_green}`}
  ${(props: RowProps) =>
    props.side === 'Exchange' && `background-color: ${props.$secondRow ? colors.mois_blue : colors.exchange_blue}`}
  ${(props: RowProps) =>
    props.side === 'Sell' && `background-color: ${props.$secondRow ? colors.mois_red : colors.sell_red}`}
`;

const ColoredSelect = styled(Select)`
  ${(props: RowProps) => props.side === 'Buy' && `background-color: ${colors.buy_green}`}
  ${(props: RowProps) => props.side === 'Exchange' && `background-color: ${colors.exchange_blue}`}
  ${(props: RowProps) => props.side === 'Sell' && `background-color: ${colors.sell_red}`}
`;

interface RowProps {
  side: string;
  $secondRow?: boolean;
}
