import { BUSINESS } from 'constants/meetingReason';
import { MEETING_DESCRIPTION_PATTERN } from 'constants/schemas';

export default {
  properties: {
    activity: {
      type: 'string',
      minLength: 1,
    },
    directionCode: {
      type: 'string',
      minLength: 1,
    },
    topic: {
      type: 'string',
      enum: [BUSINESS],
    },
    description: {
      type: 'string',
      pattern: MEETING_DESCRIPTION_PATTERN,
    },
    finished: {
      type: 'boolean',
    },
    scheduledEnd: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    scheduledStart: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    subject: {
      type: 'string',
      minLength: 1,
    },
  },
  type: 'object',
  required: ['activity', 'directionCode', 'topic', 'subject', 'scheduledStart', 'scheduledEnd'],
};
