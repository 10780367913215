import { fromJS, Map, List } from 'immutable';
import {
  ORDERS_PREVIEW_REQUEST,
  ORDERS_PREVIEW_SUCCESS,
  ORDERS_PREVIEW_FAILURE,
  ORDERS_PDF_PREVIEW_REQUEST,
  ORDERS_PDF_PREVIEW_SUCCESS,
  PDF_ORDER_REQUEST,
  PDF_ORDER_SUCCESS,
  ORDERS_PDF_PREVIEW_FAILURE,
  ORDER_TEMPLATE_RECEIVE,
  ORDER_MANUAL_SIGNATURES,
  ORDER_PREFILLED_SUCCESS,
  ORDER_FUND,
  ORDER_STRUCTURED_PRODUCT,
  ORDER_WARRANT,
  ORDER_PRIVATE_EQUITY,
  ORDER_SHARE,
  ORDER_BOND,
  REMOVE_ORDERLINE_FROM_ORDER,
  ORDER_SIGNATURE_METHOD,
  ORDER_PREFILL_SIGNATORY,
  ORDER_ADD_SIGNATORY,
  ORDER_REMOVE_SIGNATORY,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_FAILURE,
  ORDER_CREATE_AND_SIGN_REQUEST,
  ORDER_CREATE_AND_SIGN_SUCCESS,
  ORDER_CREATE_AND_SIGN_FAILURE,
  CHANGE_CONTRACT_FIELD,
  ORDER_CREATE_AND_SIGN_BACKGROUND_REQUEST,
  SHOW_CONFIRM,
  HIDE_CONFIRM,
  GET_ORDER_SIGNATORIES_FOR_CUSTOMER,
  BISNODE_ORDER_GET,
  BISNODE_UPDATE_REQUEST,
  BISNODE_UPDATE_SUCCESS,
  BISNODE_UPDATE_FAILURE,
  SET_INITIAL_ORDER_DETAILS,
  INIT_ORDER_STATE,
  CLEAR_ORDER_DETAILS,
  ORDER_CREATE_AND_SIGN_BACKGROUND_SUCCESS,
  ORDER_CREATE_AND_SIGN_BACKGROUND_FAILURE,
  ORDER_TEMPLATE_FINISH_LOADING,
  ORDER_TEMPLATE_START_LOADING,
  OrdersAction,
} from './ordersActions';
import { fixContractSchema } from 'core/functions';
import { isValidSignatory } from 'core/validations';
import { SENT_FOR_INK_SIGNING } from 'constants/contractStates';
import { FULL_POWER_OF_ATTORNEY } from 'constants/contractNames';
import {
  isIndividualStrategy,
  getFundOrderLine,
  getStructuredProductOrderLine,
  getWarrantOrderLine,
  getPrivateEquityOrderLine,
  getShareOrderLine,
  getBondOrderLine,
} from 'core/portfolios';
import { askForConfirmation } from 'core/orders';
import {
  EXTERNAL_SOURCE,
  ADDITIONAL_INVESTMENT,
  BANKER,
  CLIENT,
  CUSTOMER,
  PHONE,
  POST,
} from 'constants/receiveInfoOptions';
import { hasOnlySellOrders } from 'features/orders/ordersSelectors';
import { PHONE_METHOD, WRITTEN_METHOD } from 'constants/shareOptions';
import {
  OrdersState,
  OrderLineType,
  ImmutableSignatory,
  ImmutableContract,
  ImmutablePdfPreviewLink,
  ImmutableOrderLineType,
  Orders,
} from 'types/ordersState';
import {
  BisnodeGet,
  BisnodeUpdateSuccess,
  CreateOrder,
  GetOrderSignatoriesForCustomer,
  OrderAddSignatory,
  OrderPrefilledSuccess,
  OrderRemoveSignary,
  RemoveOrderlineFromOrder,
} from 'features/orders/ordersTypes';

const initialState: OrdersState = {
  showConfirmDialog: false,
  ordersByPortfolioId: {},
  loadingContractTemplates: false,
};

const initOrdersC = () => {
  return new Orders();
};

const orderPrefilledSuccess = (state: OrdersState, action: OrderPrefilledSuccess, portfolioIds: string[]) => {
  let newState = {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
    },
  };
  portfolioIds.forEach((portfolioId) => {
    const contract = action.result;
    const template = state.ordersByPortfolioId[portfolioId].template;
    const fixedContract = fixContractSchema(contract, template, action.countries);
    newState = {
      ...newState,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ordersByPortfolioId: {
        ...newState.ordersByPortfolioId,
        [portfolioId]: {
          ...newState.ordersByPortfolioId[portfolioId],
          contract:
            state.ordersByPortfolioId[portfolioId].contract !== undefined
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                state.ordersByPortfolioId[portfolioId].contract.merge(fixedContract)
              : fixedContract,
          contractPrefilled: true,
        },
      },
    };
  });
  return newState;
};

const getReceiveInfo = (
  portfolioId: string,
  receiverEmail: string,
  contractName: string,
  externalPortfolioId: string,
  portfolioStrategy: string,
  accountType: string,
  capitalCallEmail: string,
  capitalCallMethod: string,
  orderLine: OrderLineType
) => {
  let moneySource;
  if (!accountType || accountType === '03') {
    moneySource = EXTERNAL_SOURCE;
  } else if (contractName === FULL_POWER_OF_ATTORNEY) {
    moneySource = ADDITIONAL_INVESTMENT;
  }
  let receiveType;
  switch (orderLine.receivedFromClientMethod) {
    case PHONE_METHOD:
      receiveType = PHONE;
      break;
    case WRITTEN_METHOD:
      receiveType = POST;
      break;
  }
  return {
    portfolioId,
    receiverEmail,
    receiveType,
    customerHasSeenDocuments: contractName === FULL_POWER_OF_ATTORNEY && !isIndividualStrategy(portfolioStrategy),
    receiveDate: orderLine.receivedFromClientDate,
    giveType: orderLine.orderInitializerType === CLIENT ? CUSTOMER : BANKER,
    externalPortfolioId,
    moneySource,
    capitalCallEmail,
    capitalCallMethod,
    adviceDeliveryAfterwards: true,
    isOrderConfirmed: !askForConfirmation(orderLine.financialInstrument.instrumentGroups, contractName),
    orderBasis: orderLine.orderBasis,
  };
};

// Handle creating order contract
const createOrder = (state: OrdersState, action: CreateOrder, orderLineFunc: Function, portfolioId: string) => {
  let contract = fromJS(state.ordersByPortfolioId[portfolioId].contract);
  const portfolioContractName = action.contractName;
  const portfolioStrategy = action.strategyName;

  const orderLine = orderLineFunc(action);
  const receiveInfo = getReceiveInfo(
    action.portfolioId,
    action.billingEmail,
    portfolioContractName,
    action.externalPortfolioId,
    portfolioStrategy,
    action.defaultAccountType,
    action.capitalCallEmail,
    action.capitalCallMethod,
    orderLine
  );

  contract = <ImmutableContract>contract.setIn(['sections', 'receiveInfo'], fromJS(receiveInfo));
  contract = <ImmutableContract>contract.setIn(['sections', 'order', 'basisOfAdvice'], fromJS(action.basisOfAdvice));
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        contract,
        portfolioContractName,
        portfolioStrategy,
      },
    },
  };
};

const orderFund = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getFundOrderLine, portfolioId);

const orderStructuredProduct = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getStructuredProductOrderLine, portfolioId);

const orderWarrant = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getWarrantOrderLine, portfolioId);

const orderPrivateEquity = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getPrivateEquityOrderLine, portfolioId);

const orderShare = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getShareOrderLine, portfolioId);

const orderBond = (state: OrdersState, action: CreateOrder, portfolioId: string) =>
  createOrder(state, action, getBondOrderLine, portfolioId);

const removeOrderLine = (state: OrdersState, action: RemoveOrderlineFromOrder, portfolioId: string) => {
  let contract = <ImmutableContract>(
    state.ordersByPortfolioId[portfolioId].contract
      .updateIn(['sections', 'order', 'orderLines'], (o) =>
        o.filterNot(
          (immutableOrderLineType: ImmutableOrderLineType) => immutableOrderLineType.get('_id') === action.orderId
        )
      )
      .setIn(['sections', 'order', 'basisOfAdvice'], null)
  );
  if (hasOnlySellOrders(contract)) {
    contract = <ImmutableContract>contract.deleteIn(['sections', 'receiveInfo', 'moneySource']);
  }
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        orderDetails: [],
        contract,
      },
    },
  };
};

const addSignatoryToContract = (state: OrdersState, action: OrderAddSignatory, portfolioId: string) => {
  const signatory = action.signatory;
  if (
    !isValidSignatory(signatory) ||
    state.ordersByPortfolioId[portfolioId].signatories.some(
      (s: ImmutableSignatory | undefined) => s?.get('ssn') === signatory.ssn
    )
  ) {
    return state;
  }

  state.ordersByPortfolioId[portfolioId].signatories.push(fromJS(signatory));
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        signatories: state.ordersByPortfolioId[portfolioId].signatories.push(fromJS(signatory)),
      },
    },
  };
};

const prefillSignatory = (state: OrdersState, portfolioId: string) => {
  if (
    state.ordersByPortfolioId[portfolioId].signatories.isEmpty() &&
    state.ordersByPortfolioId[portfolioId].contract.get('contractType') === 'yksToimeksianto'
  ) {
    const { firstName, lastName, ssn, email, phoneNumber } = state.ordersByPortfolioId[portfolioId].contract
      .getIn(['sections', 'clientSummary'], Map())
      .toJS();
    const signatory = { firstName, lastName, ssn, email, phone: phoneNumber };
    const action: OrderAddSignatory = { signatory, type: ORDER_ADD_SIGNATORY, portfolioId };
    return addSignatoryToContract(state, action, portfolioId);
  }
  return state;
};

const removeSignatoryFromContract = (state: OrdersState, action: OrderRemoveSignary, portfolioId: string) => {
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        signatories: <List<ImmutableSignatory>>(
          state.ordersByPortfolioId[portfolioId].signatories.filter(
            (signatory: ImmutableSignatory | undefined) => signatory?.get('ssn') !== action.signatory.ssn
          )
        ),
      },
    },
  };
};

const getClientSigners = (state: OrdersState, action: GetOrderSignatoriesForCustomer) => {
  let newState = {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
    },
  };
  action.portfolioIds.forEach((portfolioId) => {
    newState = {
      ...newState,
      ordersByPortfolioId: {
        ...newState.ordersByPortfolioId,
        [portfolioId]: {
          ...newState.ordersByPortfolioId[portfolioId],
          savedSigners: action.signers.signers,
        },
      },
    };
  });

  return newState;
};

const bisnodeGet = (state: OrdersState, action: BisnodeGet, portfolioId: string) => {
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        bisnode: action.bisnode,
        canUpdateBisnode: action.canUpdateBisnode,
      },
    },
  };
};

const bisnodeUpdateSuccess = (state: OrdersState, action: BisnodeUpdateSuccess, portfolioId: string) => {
  return {
    ...state,
    ordersByPortfolioId: {
      ...state.ordersByPortfolioId,
      [portfolioId]: {
        ...state.ordersByPortfolioId[portfolioId],
        bisnode: action.bisnode,
        canUpdateBisnode: action.canUpdateBisnode,
      },
    },
  };
};

export const ordersReducer = (state: OrdersState = initialState, action: OrdersAction): OrdersState => {
  switch (action.type) {
    case ORDERS_PREVIEW_REQUEST: {
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.pdfPreviewLink = Map() as ImmutablePdfPreviewLink;
              }
              return details;
            }),
          },
        },
      };
    }

    case ORDERS_PREVIEW_SUCCESS: {
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (JSON.stringify(details.orderLineIds) === JSON.stringify(action.orderLineIds)) {
                details.pdfPreviewLink = fromJS({ id: action.result });
              }
              return details;
            }),
          },
        },
      };
    }

    case ORDERS_PREVIEW_FAILURE: {
      return state;
    }

    case ORDERS_PDF_PREVIEW_REQUEST: {
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              details.pdfPreviewLink = details?.pdfPreviewLink.merge({ isBusy: true }) as ImmutablePdfPreviewLink;
              return details;
            }),
          },
        },
      };
    }

    case ORDERS_PDF_PREVIEW_SUCCESS:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.pdfPreviewLink = details?.pdfPreviewLink.merge({
                  isBusy: false,
                  blobUrl: action.blobUrl,
                }) as ImmutablePdfPreviewLink;
              }
              return details;
            }),
          },
        },
      };

    case PDF_ORDER_REQUEST: {
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.pdfPreviewLink = details?.pdfPreviewLink.merge({ isBusy: true }) as ImmutablePdfPreviewLink;
              }
              return details;
            }),
          },
        },
      };
    }

    case PDF_ORDER_SUCCESS:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.pdfPreviewLink = details?.pdfPreviewLink.merge({
                  isBusy: false,
                  blobUrl: action.blobUrl,
                }) as ImmutablePdfPreviewLink;
              }
              return details;
            }),
          },
        },
      };

    case ORDERS_PDF_PREVIEW_FAILURE:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.pdfPreviewLink = Map({
                  isBusy: false,
                  error: action.error ?? undefined,
                }) as ImmutablePdfPreviewLink;
              }
              return details;
            }),
          },
        },
      };

    case ORDER_TEMPLATE_START_LOADING:
      return {
        ...state,
        loadingContractTemplates: true,
      };

    case ORDER_TEMPLATE_FINISH_LOADING:
      return {
        ...state,
        loadingContractTemplates: false,
      };

    case ORDER_TEMPLATE_RECEIVE: {
      const orders = initOrdersC();
      orders.template = action.result;
      let newState = {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
        },
      };
      action.portfolioIds.forEach((portfolioId: string) => {
        newState = {
          ...newState,
          ordersByPortfolioId: {
            ...newState.ordersByPortfolioId,
            [portfolioId]: orders,
          },
        };
      });
      return newState;
    }

    case ORDER_MANUAL_SIGNATURES:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.manualSigners = action.amount;
              }
              return details;
            }),
          },
        },
      };

    case ORDER_PREFILLED_SUCCESS:
      return orderPrefilledSuccess(state, action, action.portfolioIds);

    case ORDER_FUND:
      // @ts-expect-error: Action type and reducer not matching
      return orderFund(state, action, action.portfolioId);

    case ORDER_STRUCTURED_PRODUCT:
      // @ts-expect-error: Action type and reducer not matching
      return orderStructuredProduct(state, action, action.portfolioId);

    case ORDER_WARRANT:
      // @ts-expect-error: Action type and reducer not matching
      return orderWarrant(state, action, action.portfolioId);

    case ORDER_PRIVATE_EQUITY:
      // @ts-expect-error: Action type and reducer not matching
      return orderPrivateEquity(state, action, action.portfolioId);

    case ORDER_SHARE:
      // @ts-expect-error: Action type and reducer not matching
      return orderShare(state, action, action.portfolioId);

    case ORDER_BOND:
      // @ts-expect-error: Action type and reducer not matching
      return orderBond(state, action, action.portfolioId);

    case REMOVE_ORDERLINE_FROM_ORDER:
      return removeOrderLine(state, action, action.portfolioId);

    case ORDER_SIGNATURE_METHOD: {
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.signatureMethod = action.method;
              }
              return details;
            }),
            signatories:
              action.method === SENT_FOR_INK_SIGNING
                ? <List<ImmutableSignatory>>state.ordersByPortfolioId[action.portfolioId].signatories.take(1)
                : state.ordersByPortfolioId[action.portfolioId].signatories,
          },
        },
      };
    }
    case ORDER_PREFILL_SIGNATORY:
      return prefillSignatory(state, action.portfolioId);

    case ORDER_ADD_SIGNATORY:
      return addSignatoryToContract(state, action, action.portfolioId);

    case ORDER_REMOVE_SIGNATORY:
      return removeSignatoryFromContract(state, action, action.portfolioId);

    case ORDER_CREATE_REQUEST:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.creatingContract = true;
                details.contractSignatoriesFailed = false;
              }
              return details;
            }),
          },
        },
      };

    case ORDER_CREATE_SUCCESS:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = fromJS(action.result.contractInfo).set('confirmed', false);
                details.createContractSucceeded = action.result.succeeded;
                details.createContractMessages = action.result.messages;
                details.creatingContract = false;
                details.contractSignatoriesFailed = false;
              }
              return details;
            }),
            contract: state.ordersByPortfolioId[action.portfolioId].contract
              .setIn(['sections', 'order', 'orderLines'], List())
              .setIn(['sections', 'order', 'basisOfAdvice'], null) as ImmutableContract,
          },
        },
      };

    case ORDER_CREATE_FAILURE:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = Map<string, unknown>();
                details.creatingContract = false;
                details.contractSignatoriesFailed = true;
              }
              return details;
            }),
            contract: state.ordersByPortfolioId[action.portfolioId].contract
              .setIn(['sections', 'order', 'orderLines'], List())
              .setIn(['sections', 'order', 'basisOfAdvice'], null) as ImmutableContract,
          },
        },
      };

    case ORDER_CREATE_AND_SIGN_REQUEST:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.creatingContract = true;
              }
              return details;
            }),
          },
        },
      };

    case ORDER_CREATE_AND_SIGN_SUCCESS:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = fromJS(action.result.contractInfo).set('confirmed', true);
                details.createContractSucceeded = action.result.succeeded;
                details.createContractMessages = action.result.messages;
                details.creatingContract = false;
              }
              return details;
            }),
            contract: state.ordersByPortfolioId[action.portfolioId].contract
              .setIn(['sections', 'order', 'orderLines'], List())
              .setIn(['sections', 'order', 'basisOfAdvice'], null) as ImmutableContract,
          },
        },
      };

    case ORDER_CREATE_AND_SIGN_FAILURE:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = Map<string, unknown>();
                details.creatingContract = false;
              }
              return details;
            }),
          },
        },
      };

    case CHANGE_CONTRACT_FIELD:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            contract: action.contract,
          },
        },
      };

    case INIT_ORDER_STATE:
      return initialState;

    case CLEAR_ORDER_DETAILS: {
      let newState = {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
        },
      };
      Object.keys(state.ordersByPortfolioId).forEach((portfolioId) => {
        newState = {
          ...newState,
          ordersByPortfolioId: {
            ...newState.ordersByPortfolioId,
            [portfolioId]: {
              ...newState.ordersByPortfolioId[portfolioId],
              orderDetails: [],
              signatories: List(),
            },
          },
        };
      });
      return newState;
    }

    case ORDER_CREATE_AND_SIGN_BACKGROUND_REQUEST:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            contract: state.ordersByPortfolioId[action.portfolioId].contract
              .setIn(['sections', 'order', 'orderLines'], List())
              .setIn(['sections', 'order', 'basisOfAdvice'], null) as ImmutableContract,
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.creatingContract = true;
                details.contractSignatoriesFailed = false;
              }
              return details;
            }),
          },
        },
      };

    case ORDER_CREATE_AND_SIGN_BACKGROUND_SUCCESS:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = fromJS(action.result.contractInfo).set('confirmed', false);
                details.createContractSucceeded = action.result.succeeded;
                details.createContractMessages = action.result.messages;
                details.creatingContract = false;
                details.contractSignatoriesFailed = false;
              }
              return details;
            }),
            contract: state.ordersByPortfolioId[action.portfolioId].contract
              .setIn(['sections', 'order', 'orderLines'], List())
              .setIn(['sections', 'order', 'basisOfAdvice'], null) as ImmutableContract,
          },
        },
      };

    case ORDER_CREATE_AND_SIGN_BACKGROUND_FAILURE:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: state.ordersByPortfolioId[action.portfolioId].orderDetails.map((details) => {
              if (details.orderLineIds === action.orderLineIds) {
                details.finalContract = Map<string, unknown>();
                details.creatingContract = false;
              }
              return details;
            }),
          },
        },
      };

    case SHOW_CONFIRM:
      return {
        ...state,
        showConfirmDialog: true,
      };

    case HIDE_CONFIRM:
      return {
        ...state,
        showConfirmDialog: false,
      };

    case GET_ORDER_SIGNATORIES_FOR_CUSTOMER:
      return getClientSigners(state, action);

    case BISNODE_ORDER_GET:
      return bisnodeGet(state, action, action.portfolioId);

    case BISNODE_UPDATE_REQUEST:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            canUpdateBisnode: false,
          },
        },
      };

    case BISNODE_UPDATE_SUCCESS:
      return bisnodeUpdateSuccess(state, action, action.portfolioId);

    case BISNODE_UPDATE_FAILURE:
      return {
        ...state,
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            canUpdateBisnode: false,
          },
        },
      };

    case SET_INITIAL_ORDER_DETAILS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ordersByPortfolioId: {
          ...state.ordersByPortfolioId,
          [action.portfolioId]: {
            ...state.ordersByPortfolioId[action.portfolioId],
            orderDetails: [
              ...state.ordersByPortfolioId[action.portfolioId].orderDetails,
              {
                signatureMethod: action.signatureMethod,
                orderLineIds: action.orderLineIds,
                orderType: action.orderType,
              },
            ],
          },
        },
      };

    default:
      return state;
  }
};
