import React from 'react';
import { OpenFile } from 'features/contracts/components/DownloadPdf';
import { SignatureConfirmItem } from 'features/contracts/components/SignatureConfirmItem';
import ElectronicSignatureConfirmItem from 'features/contracts/components/ElectronicSignatureConfirmItem';
import { InkSignatureConfirmItem } from 'features/contracts/components/InkSignatureConfirmItem';
import {
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_BANKER_APPROVAL,
  SENT_FOR_SIGNING,
  SENT_FOR_INK_SIGNING,
  SIGNED,
  contractSignatureStates,
} from 'constants/contractStates';
import { ImmutablePdfPreviewLink, OrderDetailsType, ImmutableSignatoryList } from 'types/ordersState';
import { getOrderPdf } from 'features/orders/ordersActions';
import { useAppDispatch } from 'core/hooks';

interface Props {
  details: OrderDetailsType;
  signatories: ImmutableSignatoryList | undefined;
  pdfPreviewLink: ImmutablePdfPreviewLink;
  portfolioId: string;
}

export const Signatures = ({ details, signatories, pdfPreviewLink, portfolioId }: Props) => {
  const dispatch = useAppDispatch();

  const link = pdfPreviewLink.toJS();
  const contract = details.finalContract?.toJS();
  const icon = contractSignatureStates[contract?.state] ? contractSignatureStates[contract?.state].icon : undefined;

  const headerIcon = (
    <OpenFile
      isBusy={link.isBusy}
      icon={icon}
      loadFile={() => dispatch(getOrderPdf(contract.shortId, portfolioId, details.orderLineIds))}
      blobUrl={link.blobUrl}
    />
  );

  const getConfirmItem = () => {
    if (
      contract?.state === SENT_FOR_MANUAL_SIGNING ||
      contract?.state === SENT_FOR_BANKER_APPROVAL ||
      contract?.state === SIGNED
    ) {
      return <SignatureConfirmItem key={contract.shortId} headerIcon={headerIcon} contract={contract} />;
    }

    if (contract?.state === SENT_FOR_SIGNING) {
      return (
        <ElectronicSignatureConfirmItem
          key={contract.shortId}
          headerIcon={headerIcon}
          contract={contract}
          signatories={signatories?.toJS()}
        />
      );
    }

    if (contract?.state === SENT_FOR_INK_SIGNING) {
      return (
        <InkSignatureConfirmItem
          key={contract.shortId}
          headerIcon={headerIcon}
          contract={contract}
          signatories={signatories?.toJS()}
        />
      );
    }
  };

  return (
    <section>
      <div className="row">
        <div className="columns small-12">{getConfirmItem()}</div>
      </div>
    </section>
  );
};
