import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { colors, ASSET_CLASS_COLORS } from 'styles/colors';
import { FlexColumn, fontSize, Flex } from 'features/common/StyledComponents';
import { Dot } from 'features/allocator/common/Dot';
import { AssetClassId } from 'types/types';
import { BasicInstrument, PortfolioInstrument } from 'types/instrumentsState';
import {
  customerHasCurrentWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';
import { InstrumentListItem } from 'features/allocator/instruments/components/InstrumentListItem';
import { selectAssetClasses } from 'features/allocator/allocatorSelectors';

interface Props {
  instruments: BasicInstrument[];
  newInstrumentsSelected: PortfolioInstrument[];
  originalInstrumentsSelected: PortfolioInstrument[];
  assetClassId: AssetClassId;
  portfolioId: string;
  assetClassNotInPositions: boolean;
  handleInstrumentSelect: (instrument: BasicInstrument) => void;
}

export const InstrumentsList = ({
  instruments,
  assetClassId,
  newInstrumentsSelected,
  originalInstrumentsSelected,
  portfolioId,
  assetClassNotInPositions,
  handleInstrumentSelect,
}: Props) => {
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const assetClasses = useSelector(selectAssetClasses);
  const newCustomer = !useSelector(customerHasCurrentWeights);

  const dotColor = ASSET_CLASS_COLORS[assetClassId] || colors.dark_gray;
  const assetClassName = assetClasses.find((e) => e.assetClassId === assetClassId)?.name || assetClassId;

  const portfolioInstruments = newInstrumentsSelected.filter((i) => i.portfolioId === portfolioId);

  if (instruments?.length === 0) {
    return null;
  }

  return (
    <AssetTypeList>
      {assetClassId !== 'ALLOC' && (
        <HeaderRow>
          <Name>
            <Dot bg={dotColor} />
            <h3>{assetClassName}</h3>
            {!newCustomer && assetClassNotInPositions && (
              <b data-testkey="not-in-positions">{translate('allocator.instrumentsView.notInPositions')}</b>
            )}
          </Name>
          <HasChanged />
          <Type>{translate('allocator.instrumentsView.type')}</Type>
        </HeaderRow>
      )}

      {instruments?.map((instrument) => {
        return (
          <InstrumentListItem
            key={instrument.security}
            instrument={instrument}
            optimizedPortfolios={optimizedPortfolios}
            portfolioInstruments={portfolioInstruments}
            originalInstrumentsSelected={originalInstrumentsSelected}
            handleInstrumentSelect={handleInstrumentSelect}
            newInstrumentsSelected={newInstrumentsSelected}
            portfolioId={portfolioId}
          />
        );
      })}
    </AssetTypeList>
  );
};

const AssetTypeList = styled(FlexColumn)`
  margin-bottom: 1.5rem;
`;

const HeaderRow = styled(Flex)`
  font-size: ${fontSize.smaller};
  font-weight: 900;
  margin-bottom: 0.5rem;
  margin-left: -0.75rem;
  align-items: baseline;

  b {
    margin-left: 1rem;
    font-weight: 900;
    color: ${colors.red};
  }
`;

const Name = styled(Flex)`
  align-items: baseline;
  flex: auto;

  h3 {
    margin: 0;
  }
`;

const HasChanged = styled.div`
  font-size: ${fontSize.smaller};
  flex: 0 0 200px;
  line-height: 1.2;
  padding-right: 1rem;
`;

const Type = styled.div`
  flex: 0 0 150px;
  font-size: ${fontSize.smaller};
`;
