import forEach from 'lodash/forEach';
import qs from 'qs';
import { Map, fromJS } from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import { makeBlobUrl } from 'core/apiCall';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';
import { isProspect, hasMandatoryContract, mandatoryContracts, mandatoryKycContracts } from 'core/customer';
import { fixContractSchema, updateContractTemplateVersions } from 'core/functions';
import { WEB_SERVICE_CONTRACT, WEB_SERVICE_CONTRACT_PROXY } from 'constants/contractIds';
import { Contract, FoundPerson, ImmutableContracts, Item, Signatory, Template } from 'types/ordersState';
import { Customer } from 'types/profileState';
import { AppThunk } from 'types/types';
import { ApiClient } from 'core/ApiClient';
import { Bisnode, BisnodeUpdate, Signers } from 'features/orders/ordersTypes';
import { ContractsState, Countries, Schema } from 'types/contractsState';
import { setFetchingContract, SET_SELECTED_RISK_LEVEL } from 'features/profile/profileActions';
import {
  CreateWebServiceContractResponse,
  GetHasWebServiceContractResponse,
  NewContractRequest,
  PdfAttachment,
} from 'features/contracts/contracts.types';
import {
  deleteDraftContracts,
  getContract,
  getContractPdf,
  getPdfForPreviewRequest,
  getPrefilled,
  getSignatures,
  postContract,
  postContractForPreview,
  getContractTemplate,
  getContractTemplateForCustomerType,
  postPdf,
  deletePdf,
  getAttachmentDataRequest,
} from 'features/contracts/contractsUtils';
import { getBisnodeData, getSigners, updateBisnodeData } from 'features/orders/ordersUtils';
import { CommonState } from 'types/commonState';
import {
  ATTACHMENT_TEMPLATE_ID,
  PDF_ATTACHMENT_SUCCESS,
  PDF_ATTACHMENT_TEMP_ID,
  SUITABILITY_TEMPLATE_ID,
} from 'features/contracts/contractsConstants';
import { config } from 'config';

/*
 * action types
 */

export const CONTRACT_UPLOAD_ATTACHMENT_REQUEST = 'CONTRACT_UPLOAD_ATTACHMENT_REQUEST';
export const CONTRACT_UPLOAD_ATTACHMENT_SUCCESS = 'CONTRACT_UPLOAD_ATTACHMENT_SUCCESS';
export const CONTRACT_UPLOAD_ATTACHMENT_FAILURE = 'CONTRACT_UPLOAD_ATTACHMENT_FAILURE';
export const CONTRACT_UPLOAD_ATTACHMENT_SIGNATURE_METHOD = 'CONTRACT_UPLOAD_ATTACHMENT_SIGNATURE_METHOD';
export const CONTRACT_UPLOAD_ATTACHMENT_ADD_SIGNATURE = 'CONTRACT_UPLOAD_ATTACHMENT_ADD_SIGNATURE';
export const CONTRACT_UPLOAD_ATTACHMENT_REMOVE_SIGNATURE = 'CONTRACT_UPLOAD_ATTACHMENT_REMOVE_SIGNATURE';
export const CONTRACT_REMOVE_ATTACHMENT_REQUEST = 'CONTRACT_REMOVE_ATTACHMENT_REQUEST';
export const CONTRACT_REMOVE_ATTACHMENT_SUCCESS = 'CONTRACT_REMOVE_ATTACHMENT_SUCCESS';
export const CONTRACT_REMOVE_ATTACHMENT_FAILURE = 'CONTRACT_REMOVE_ATTACHMENT_FAILURE';
export const SET_ATTACHMENT_MANUAL_SIGNATURES = 'SET_ATTACHMENT_MANUAL_SIGNATURES';
export const CONTRACT_ADD_ATTACHMENT = 'CONTRACT_ADD_ATTACHMENT';
export const CONTRACT_RESET_ATTACHMENTS = 'CONTRACT_RESET_ATTACHMENTS';

export const CONTRACT_TEMPLATES_REQUEST = 'CONTRACT_TEMPLATES_REQUEST';
export const CONTRACT_TEMPLATES_RECEIVE = 'CONTRACT_TEMPLATES_RECEIVE';
export const CONTRACT_TEMPLATES_FAILURE = 'CONTRACT_TEMPLATES_FAILURE';
export const SHOW_UNSAVED_DATA_WARNING = 'SHOW_UNSAVED_DATA_WARNING';
export const HIDE_UNSAVED_DATA_WARNING = 'HIDE_UNSAVED_DATA_WARNING';
export const CONTRACT_RESET_NOTIFICATION = 'CONTRACT_RESET_NOTIFICATION';
export const CONTRACT_MERGE_DRAFT = 'CONTRACT_MERGE_DRAFT';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';
export const USER_SESSION_CREATE = 'USER_SESSION_CREATE';
export const USER_SESSION_RESET = 'USER_SESSION_RESET';
const CONTRACT_PREFILLED_REQUEST = 'CONTRACT_PREFILLED_REQUEST';
export const CONTRACT_PREFILLED_SUCCESS = 'CONTRACT_PREFILLED_SUCCESS';
export const CONTRACT_PREFILLED_FAILURE = 'CONTRACT_PREFILLED_FAILURE';
const CONTRACT_SAVE_DRAFT_REQUEST = 'CONTRACT_SAVE_DRAFT_REQUEST';
export const CONTRACT_SAVE_DRAFT_SUCCESS = 'CONTRACT_SAVE_DRAFT_SUCCESS';
const CONTRACT_SAVE_DRAFT_FAILURE = 'CONTRACT_SAVE_DRAFT_FAILURE';
export const CONTRACTS_CREATE_REQUEST = 'CONTRACTS_CREATE_REQUEST';
export const CONTRACTS_CREATE_SUCCESS = 'CONTRACTS_CREATE_SUCCESS';
export const CONTRACTS_CREATE_ERROR = 'CONTRACTS_CREATE_ERROR';
export const CONTRACTS_CREATE_DATA_ERROR = 'CONTRACTS_CREATE_DATA_ERROR';
export const CONTRACT_SIGNATURE_METHOD = 'CONTRACT_SIGNATURE_METHOD';
export const CONTRACT_MANUAL_SIGNATURES = 'CONTRACT_MANUAL_SIGNATURES';
export const CONTRACT_PREVIEW_REQUEST = 'CONTRACT_PREVIEW_REQUEST';
export const CONTRACT_PREVIEW_RECEIVE = 'CONTRACT_PREVIEW_RECEIVE';
const CONTRACT_PREVIEW_FAILURE = 'CONTRACT_PREVIEW_FAILURE';
export const CONTRACT_TEMPLATE_RECEIVE = 'CONTRACT_TEMPLATE_RECEIVE';
export const CONTRACT_TEMPLATE_FAILURE = 'CONTRACT_TEMPLATE_FAILURE';
export const CONTRACT_TEMPLATE_START_LOADING = 'CONTRACT_TEMPLATE_START_LOADING';
export const CONTRACT_TEMPLATE_FINISH_LOADING = 'CONTRACT_TEMPLATE_FINISH_LOADING';
const DELETE_DRAFT_REQUEST = 'DELETE_DRAFT_REQUEST';
const DELETE_DRAFT_SUCCESS = 'DELETE_DRAFT_SUCCESS';
const DELETE_DRAFT_FAILURE = 'DELETE_DRAFT_FAILURE';
export const TOGGLE_CONTRACT = 'TOGGLE_CONTRACT';
export const CHANGE_SECTION_FIELD = 'CHANGE_CONTRACT_SECTION_FIELD';
export const FIELD_VALIDATION = 'FIELD_VALIDATION';
export const MODULE_VALIDATION = 'MODULE_VALIDATION';
export const SET_ACTIVE_CONTRACT = 'SET_ACTIVE_CONTRACT';
export const TOGGLE_CREATE_CONTRACTS_CONFIRMATION = 'TOGGLE_CREATE_CONTRACTS_CONFIRMATION';
export const TOUCH_FIELDS = 'TOUCH_FIELDS';
export const SHOW_ONLY_UNFINISHED = 'SHOW_ONLY_UNFINISHED';

export const PDF_PREVIEW_REQUEST = 'PDF_PREVIEW_REQUEST';
export const PDF_PREVIEW_SUCCESS = 'PDF_PREVIEW_SUCCESS';
export const PDF_PREVIEW_FAILURE = 'PDF_PREVIEW_FAILURE';
export const PDF_CONTRACT_REQUEST = 'PDF_CONTRACT_REQUEST';
export const PDF_CONTRACT_SUCCESS = 'PDF_CONTRACT_SUCCESS';
export const PDF_CONTRACT_FAILURE = 'PDF_CONTRACT_FAILURE';
export const CONTRACT_RESPONSE_SUCCESS = 'CONTRACT_RESPONSE_SUCCESS';

export const TOGGLE_NEW_SIGNATORY_DIALOG = 'TOGGLE_NEW_SIGNATORY_DIALOG';
export const CONTRACT_ADD_SIGNATORY = 'CONTRACT_ADD_SIGNATORY';
export const TOGGLE_SIGNATORY_DETAILS_DIALOG = 'TOGGLE_SIGNATORY_DETAILS_DIALOG';
export const CONTRACT_REMOVE_SIGNATORY = 'CONTRACT_REMOVE_SIGNATORY';
export const CONTRACT_REMOVE_ALL_SIGNATORIES = 'CONTRACT_REMOVE_ALL_SIGNATORIES';
export const CONTRACT_PREFILL_SIGNATORY = 'CONTRACT_PREFILL_SIGNATORY';
export const COPY_SIGNATORIES_TO_ALL = 'COPY_SIGNATORIES_TO_ALL';
export const GET_SIGNATORIES_FOR_CUSTOMER = 'GET_SIGNATORIES_FOR_CUSTOMER';

export const ELECTRONIC_CONTRACTS_SIGNATORIES_SUCCESS = 'ELECTRONIC_CONTRACTS_SIGNATORIES_SUCCESS';
export const ELECTRONIC_CONTRACTS_SIGNATORIES_FAILURE = 'ELECTRONIC_CONTRACTS_SIGNATORIES_FAILURE';

export const SHOW_DISCARD_SESSION_DIALOG = 'SHOW_DISCARD_SESSION_DIALOG';
export const HIDE_DISCARD_SESSION_DIALOG = 'HIDE_DISCARD_SESSION_DIALOG';

export const CONTRACT_LOAD_DRAFT_REQUEST = 'CONTRACT_LOAD_DRAFT_REQUEST';
export const CONTRACT_LOAD_DRAFT_SUCCESS = 'CONTRACT_LOAD_DRAFT_SUCCESS';
const CONTRACT_LOAD_DRAFT_FAILURE = 'CONTRACT_LOAD_DRAFT_FAILURE';

export const MERGE_CONTRACT_COPY = 'MERGE_CONTRACT_COPY';

export const BISNODE_GET = 'BISNODE_GET';
export const BISNODE_UPDATED = 'BISNODE_UPDATED';
export const BISNODE_UPDATING = 'BISNODE_UPDATING';
const CRM_PERSON_SEARCH_REQUEST = 'CRM_PERSON_SEARCH_REQUEST';
const CRM_PERSON_SEARCH_SUCCESS = 'CRM_PERSON_SEARCH_SUCCESS';
const CRM_PERSON_SEARCH_FAILURE = 'CRM_PERSON_SEARCH_FAILURE';
export const HAS_WEB_SERVICE_REQUEST = 'HAS_WEB_SERVICE_REQUEST';
export const HAS_WEB_SERVICE_SUCCESS = 'HAS_WEB_SERVICE_SUCCESS';
export const HAS_WEB_SERVICE_FAILURE = 'HAS_WEB_SERVICE_FAILURE';
export const GET_WEB_SERVICE_CONTRACT_REQUEST = 'GET_WEB_SERVICE_CONTRACT_REQUEST';
export const GET_WEB_SERVICE_CONTRACT_SUCCESS = 'GET_WEB_SERVICE_CONTRACT_SUCCESS';
export const GET_WEB_SERVICE_CONTRACT_FAILURE = 'GET_WEB_SERVICE_CONTRACT_FAILURE';
export const ADD_WEB_SERVICE_CONTRACT_REQUEST = 'ADD_WEB_SERVICE_CONTRACT_REQUEST';
export const ADD_WEB_SERVICE_CONTRACT_SUCCESS = 'ADD_WEB_SERVICE_CONTRACT_SUCCESS';
export const ADD_WEB_SERVICE_CONTRACT_FAILURE = 'ADD_WEB_SERVICE_CONTRACT_FAILURE';

/*
 * action creators
 */

const removeAttachmentDocumentRequest = (id: string) => {
  return <const>{
    type: CONTRACT_REMOVE_ATTACHMENT_REQUEST,
    data: { id },
  };
};

const removeAttachmentDocumentSuccess = (id: string) => {
  return <const>{
    type: CONTRACT_REMOVE_ATTACHMENT_SUCCESS,
    data: { id },
  };
};

const removeAttachmentDocumentFailure = (id: string) => {
  return <const>{
    type: CONTRACT_REMOVE_ATTACHMENT_FAILURE,
    data: { id },
  };
};

const uploadAttachmentDocumentRequest = (fileName: string, descriptiveName: string, temporaryId: string) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_REQUEST,
    data: { id: temporaryId, fileName, descriptiveName },
  };
};

const uploadAttachmentDocumentSuccess = (id: string, contract: Contract) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_SUCCESS,
    data: { id, contract },
  };
};

const uploadAttachmentDocumentFailure = (id: string) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_FAILURE,
    data: { id },
  };
};

export const uploadAttachmentSignatureMethod = (id: string, signatureMethod: string) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_SIGNATURE_METHOD,
    data: { id, signatureMethod },
  };
};

export const uploadAttachmentAddSignature = (attachmentId: string, signature: Signatory) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_ADD_SIGNATURE,
    data: { attachmentId, signature },
  };
};

export const uploadAttachmentRemoveSignature = (attachmentId: string, signature: Signatory) => {
  return <const>{
    type: CONTRACT_UPLOAD_ATTACHMENT_REMOVE_SIGNATURE,
    data: { attachmentId, signature },
  };
};

export const toggleCreateContractsConfirm = (show: boolean) => {
  return <const>{
    type: TOGGLE_CREATE_CONTRACTS_CONFIRMATION,
    show,
  };
};

export const changeSectionField = (key: string, value: string | number | boolean, contractType: string | null) => {
  return <const>{
    type: CHANGE_SECTION_FIELD,
    key,
    value,
    contractType,
  };
};

export const fieldValidation = (key: string, schema: Schema, contractId: string) => {
  return <const>{
    type: FIELD_VALIDATION,
    fieldKey: key,
    schema,
    contractId,
  };
};

export const moduleValidation = (module: Item, schema: Schema, contractId: string) => {
  return <const>{
    type: MODULE_VALIDATION,
    module,
    schema,
    contractId,
  };
};

export const hideUnSavedDataWarning = () => {
  return <const>{
    type: HIDE_UNSAVED_DATA_WARNING,
  };
};

export const resetNotification = () => {
  return <const>{
    type: CONTRACT_RESET_NOTIFICATION,
  };
};

export const createClientSession = (id: string, name: string) => {
  return <const>{
    type: USER_SESSION_CREATE,
    id,
    name,
  };
};

export const showUnSavedDataWarning = () => {
  return <const>{
    type: SHOW_UNSAVED_DATA_WARNING,
  };
};

export const selectSignatureMethod = (contractId: string, method: string) => {
  return <const>{
    type: CONTRACT_SIGNATURE_METHOD,
    contractId,
    method,
  };
};

export const setManualSignatureAmount = (amount: string, contractType: string) => {
  return <const>{
    type: CONTRACT_MANUAL_SIGNATURES,
    amount,
    contractType,
  };
};

const createPreviewRequest = () => {
  return <const>{
    type: CONTRACT_PREVIEW_REQUEST,
  };
};

const createPreviewFailure = (error: string) => {
  return <const>{
    type: CONTRACT_PREVIEW_FAILURE,
    error,
  };
};

const createPreviewSuccess = (previewId: string, contractType: string, name: string) => {
  return <const>{
    type: CONTRACT_PREVIEW_RECEIVE,
    previewId,
    contractType,
    name,
  };
};

export const setActiveContract = (contract: string) => {
  return <const>{
    type: SET_ACTIVE_CONTRACT,
    contract,
  };
};

export const touchFields = (fields: string[], contractId: string) => {
  return <const>{
    type: TOUCH_FIELDS,
    fields,
    contractId,
  };
};

const addAttachment = (attachment: PdfAttachment) => {
  return <const>{
    type: CONTRACT_ADD_ATTACHMENT,
    data: {
      attachment,
    },
  };
};

/*
 * async action creators
 */

export const uploadAttachmentDocument = (file: File, descriptiveName: string): AppThunk<Promise<void>> => {
  const fileName = file.name;
  return async (dispatch, getState) => {
    const temporaryId = PDF_ATTACHMENT_TEMP_ID;
    dispatch(uploadAttachmentDocumentRequest(fileName, descriptiveName, temporaryId));
    try {
      const token = getState().oidc.user.access_token;
      const customerId = getState().profile.customer.get('customerId');
      const attachmentId = await postPdf(file, descriptiveName, customerId, token);
      const attachmentTemplateId = ATTACHMENT_TEMPLATE_ID;

      if (!getState().contracts.values.get(attachmentTemplateId)) {
        await dispatch(loadAttachmentTemplate());
      }

      const contract = getState().contracts.values.get(attachmentTemplateId).toJS();
      const newContract = _.cloneDeep(contract);
      newContract.attachmentId = attachmentId;
      dispatch(uploadAttachmentDocumentSuccess(attachmentId, newContract));
    } catch (error) {
      dispatch(uploadAttachmentDocumentFailure(temporaryId));
    }
  };
};

const loadAttachmentTemplate = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const customerId = getState().profile.customer.get('customerId');
    dispatch(startLoadingContractTemplate(ATTACHMENT_TEMPLATE_ID));
    await dispatch(fetchContractTemplate(ATTACHMENT_TEMPLATE_ID));
    await dispatch(getPrefilledContract(ATTACHMENT_TEMPLATE_ID, customerId));
    const tmpl = getState().contracts.templates.find((t) => t.contractType === ATTACHMENT_TEMPLATE_ID);
    dispatch(finishLoadingContractTemplate(tmpl));
  };
};

const addAttachmentsFromDraft = (draftAttachmentIds: string[]): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const token = getState().oidc.user.access_token;
    if (draftAttachmentIds) {
      try {
        await Promise.all(
          draftAttachmentIds.map(async (draftAttachmentId) => {
            const customerId = getState().profile.customer.get('customerId');
            const attachmentData = await getAttachmentDataRequest(draftAttachmentId, customerId, token);
            const attachmentTemplateId = ATTACHMENT_TEMPLATE_ID;
            if (!getState().contracts.values.get(attachmentTemplateId)) {
              await dispatch(loadAttachmentTemplate());
            }
            const contract = getState().contracts.values.get(attachmentTemplateId).toJS();
            const newContract = _.cloneDeep(contract);
            newContract.attachmentId = draftAttachmentId;
            dispatch(
              addAttachment({
                id: draftAttachmentId,
                fileName: attachmentData.filename,
                descriptiveName: attachmentData.description,
                status: PDF_ATTACHMENT_SUCCESS,
                signatures: [],
                contract: newContract,
                manualSignatures: 1,
              })
            );
          })
        );
        // eslint-disable-next-line no-empty
      } catch (e) {}
    }
  };
};

export const removeAttachmentDocument = (id: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const customerId = getState().profile.customer.get('customerId');
    dispatch(removeAttachmentDocumentRequest(id));
    try {
      const token = getState().oidc.user.access_token;
      const result = await deletePdf(id, customerId, token);
      if (result) {
        dispatch(removeAttachmentDocumentSuccess(id));
      } else {
        throw new Error('removing failed in API');
      }
    } catch (error) {
      dispatch(removeAttachmentDocumentFailure(id));
    }
  };
};

const resetAttachments = () => {
  return <const>{
    type: CONTRACT_RESET_ATTACHMENTS,
  };
};

export const setManualSignatureAmountAttachment = (amount: number, id: string) => {
  return <const>{
    type: SET_ATTACHMENT_MANUAL_SIGNATURES,
    data: { amount, id },
  };
};

export const openSuitabilityAssesment = (): AppThunk => {
  return async (dispatch, getState) => {
    const customerSsn = getState().profile.customer.get('ssn');
    const businessId = getState().profile.customer.get('businessId');
    if (customerSsn) {
      await navigator.clipboard.writeText(customerSsn);
    }
    if (businessId) {
      await navigator.clipboard.writeText(businessId);
    }
    window.open(config.mytySuitabilityAssessmentUrl, 'Soveltuvuusarviointi');
  };
};

const fetchContractTemplate = (id: string): AppThunk<Promise<Template | undefined>> => {
  return async (dispatch, getState) => {
    try {
      const token = getState().oidc.user.access_token;
      const result = await getContractTemplate(id, token);
      dispatch(contractTemplateReceive(result));
      return result;
    } catch (error) {
      dispatch(contractTemplateFailure());
    }
  };
};

const contractTemplateReceive = (result: Template) => ({
  type: CONTRACT_TEMPLATE_RECEIVE,
  result,
});

const contractTemplateFailure = () => ({
  type: CONTRACT_TEMPLATE_FAILURE,
});

const toggleContract = (id: string, language: string) => {
  return <const>{
    type: TOGGLE_CONTRACT,
    contractId: id,
    language,
  };
};

export const getContracts = (customerType: string): AppThunk => {
  return async (dispatch, getState) => {
    let url = '/api/v1/template?requester=jumpstart';

    if (customerType) {
      url += `&customerType=${customerType}`;
    }

    try {
      dispatch(contractTemplatesRequest());
      const token = getState().oidc.user.access_token;
      const result = await getContractTemplateForCustomerType(url, token);
      dispatch(contractTemplatesReceive(result));
    } catch (error) {
      dispatch(contractTemplatesFailure());
    }
  };
};

const contractTemplatesRequest = () => ({
  type: CONTRACT_TEMPLATES_REQUEST,
});

const contractTemplatesReceive = (result: Template) => ({
  type: CONTRACT_TEMPLATES_RECEIVE,
  result,
});

const contractTemplatesFailure = () => ({
  type: CONTRACT_TEMPLATES_FAILURE,
});

export const loadContractDrafts = (clientId: string) => {
  return {
    types: [CONTRACT_LOAD_DRAFT_REQUEST, CONTRACT_LOAD_DRAFT_SUCCESS, CONTRACT_LOAD_DRAFT_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/contract/${clientId}/state`),
  };
};

export const getCustomer = (id: string) => {
  return {
    types: ['CUSTOMER_PROFILE_REQUEST', 'CUSTOMER_PROFILE_SUCCESS', 'CUSTOMER_PROFILE_FAILURE'],
    promise: (client: ApiClient) => client.get(`/api/v1/client/${id}`),
  };
};

export const saveContractDrafts = (clientId: string, notify = true): AppThunk => {
  return (dispatch, getState) => {
    const { contracts } = getState();
    // take only selected contracts and their values
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const values = contracts.values.filter((_, key) => contracts.selected.get(key) === true);
    const withAttachments = values.concat(contracts.attachmentPdfs.map((pdfItem) => [pdfItem.id, pdfItem.contract]));
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return dispatch({
      types: [CONTRACT_SAVE_DRAFT_REQUEST, CONTRACT_SAVE_DRAFT_SUCCESS, CONTRACT_SAVE_DRAFT_FAILURE],
      promise: (client: ApiClient) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return client.put(`/api/v1/contract/${clientId}/state`, {
          data: withAttachments,
        });
      },
    }).then(() => {
      if (!notify) {
        dispatch(resetNotification());
      }
    });
  };
};

export const mergeSavedContract = (customerId: string): AppThunk => {
  return async (dispatch, getState) => {
    const { contracts } = getState();
    const drafts = contracts.draft?.toJS();
    if (drafts) {
      dispatch(resetAttachments());
      const draftTemplateIds = drafts ? Object.keys(drafts) : [];
      const draftAttachments = draftTemplateIds.filter((id) => drafts[id].attachmentId);
      const otherDrafts = draftTemplateIds.filter((id) => !drafts[id].attachmentId);
      await dispatch(addAttachmentsFromDraft(draftAttachments));
      await Promise.all(
        otherDrafts.map(async (draftTemplateId) => {
          await dispatch(fetchContractTemplate(draftTemplateId));
          await dispatch(getPrefilledContract(draftTemplateId, customerId));
          dispatch(contractMergeDraft(draftTemplateId));
        })
      );
    }
  };
};

const contractMergeDraft = (templateId: string) => {
  return <const>{
    type: CONTRACT_MERGE_DRAFT,
    templateId,
  };
};

const deleteDraftRequest = () => {
  return <const>{
    type: DELETE_DRAFT_REQUEST,
  };
};

const deleteDraftSuccess = () => {
  return <const>{
    type: DELETE_DRAFT_SUCCESS,
  };
};

const deleteDraftFailure = () => {
  return <const>{
    type: DELETE_DRAFT_FAILURE,
  };
};

const deleteContractDrafts = (customerId: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(deleteDraftRequest());
    try {
      await deleteDraftContracts(customerId, getState().oidc.user.access_token);
    } catch (error) {
      dispatch(deleteDraftFailure());
    }
    dispatch(deleteDraftSuccess());
  };
};

const clearSelectedAction = () => {
  return <const>{
    type: CLEAR_SELECTED,
  };
};

export const clearSelected = (): AppThunk => {
  return (dispatch) => {
    dispatch(clearSelectedAction());
  };
};

export const setSelectedRiskLevel = (selectedRiskLevel: number) => {
  return <const>{
    type: SET_SELECTED_RISK_LEVEL,
    selectedRiskLevel,
  };
};

const resetUserSession = () => {
  return <const>{
    type: USER_SESSION_RESET,
  };
};

export const resetClientSession = ({ deleteDraft }: { deleteDraft: boolean }): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(resetUserSession());
    if (deleteDraft) {
      const customerId = getState().profile.customer.get('customerId');
      await dispatch(deleteContractDrafts(customerId));
    }
  };
};

const contractCreateRequest = () => {
  return <const>{
    type: CONTRACTS_CREATE_REQUEST,
  };
};

const contractCreateDataError = () => {
  return <const>{
    type: CONTRACTS_CREATE_DATA_ERROR,
  };
};

const contractCreateError = () => {
  return <const>{
    type: CONTRACTS_CREATE_ERROR,
  };
};

const contractCreateSuccess = (contracts: ImmutableContracts) => {
  return <const>{
    type: CONTRACT_RESPONSE_SUCCESS,
    contracts,
  };
};

export const createContracts = (): AppThunk => {
  return async (dispatch, getState) => {
    const { contracts, oidc, profile, common } = getState();
    const contractsList = [];
    const electronicContracts = [];
    const inkSignContracts = [];
    const manualSignContracts = [];
    const language = common.contractLanguage;
    dispatch(contractCreateRequest());

    const selectedContracts = contracts.selected
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .filter((c) => c)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .filter((c) => Object.keys(c)[0] !== SUITABILITY_TEMPLATE_ID);

    const customerName = profile.customer.get('header');
    const customerId = profile.customer.get('customerId');

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    for (const key of selectedContracts.keys()) {
      const contract = contracts.values.get(key).toJS();
      console.log('creating contract', contract.contractName); // eslint-disable-line no-console
      console.log('... with language', contract.language); // eslint-disable-line no-console
      if (language && contract.language !== language) {
        console.log('state language is different, changing contract language to', language); // eslint-disable-line no-console
        contract.language = language;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      contract.state = contracts.signatureMethods.get(key);
      if (contract.state) {
        contractsList.push(contract);
        if (contract.state === SENT_FOR_SIGNING) {
          electronicContracts.push(contract);
        } else if (contract.state === SENT_FOR_MANUAL_SIGNING) {
          manualSignContracts.push(contract);
        } else if (contract.state === SENT_FOR_INK_SIGNING) {
          inkSignContracts.push(contract);
        }
      }
    }

    contracts.attachmentPdfs.forEach((attachment) => {
      const attachmentSignatureMethod = attachment.signatureMethod;
      const newContractAttachment = attachment.contract;
      if (newContractAttachment && attachment.signatureMethod) {
        newContractAttachment.state = attachment.signatureMethod;
        contractsList.push(newContractAttachment);
        if (attachmentSignatureMethod === SENT_FOR_SIGNING) {
          electronicContracts.push(newContractAttachment);
        } else if (attachmentSignatureMethod === SENT_FOR_MANUAL_SIGNING) {
          manualSignContracts.push(newContractAttachment);
        } else if (attachmentSignatureMethod === SENT_FOR_INK_SIGNING) {
          inkSignContracts.push(newContractAttachment);
        }
      }
    });
    const contractsWithSignatories = [];

    // SENT_FOR_SIGNING for tupas signing
    const recipients = contracts.contractsSignatories;
    for (const electronic of electronicContracts.values()) {
      const type = electronic.contractType;
      const attachmentSignatures = contracts.attachmentPdfs.find(
        (pdfItem) => pdfItem.id === electronic.attachmentId
      )?.signatures;

      if (type === ATTACHMENT_TEMPLATE_ID) {
        contractsWithSignatories.push({
          type,
          signatories: attachmentSignatures ? attachmentSignatures : [],
          method: 'Tupas',
          attachmentId: electronic.attachmentId,
        });
      } else {
        contractsWithSignatories.push({
          type,
          signatories: recipients.get(type).toJS(),
          method: 'Tupas',
        });
      }
    }

    // SENT_FOR_INK_SIGNING for ipad signing
    for (const ink of inkSignContracts.values()) {
      const type = ink.contractType;
      const attachmentSignatures = contracts.attachmentPdfs.find(
        (pdfItem) => pdfItem.id === ink.attachmentId
      )?.signatures;

      if (type === ATTACHMENT_TEMPLATE_ID) {
        contractsWithSignatories.push({
          type,
          signatories: attachmentSignatures ? attachmentSignatures : [],
          method: 'Ink',
          attachmentId: ink.attachmentId,
        });
      } else {
        contractsWithSignatories.push({
          type,
          signatories: recipients.get(type).toJS(),
          method: 'Ink',
        });
      }
    }

    const manualSignerAmount = contracts.manualSigners;
    for (const manual of manualSignContracts.values()) {
      const type = manual.contractType;
      if (type === ATTACHMENT_TEMPLATE_ID) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        manual['manualSignersCount'] = contracts.attachmentPdfs.find(
          (pdf) => pdf.id === manual.attachmentId
        )?.manualSignatures;
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        manual['manualSignersCount'] = manualSignerAmount.get(type);
      }
    }

    const newContractRequest: NewContractRequest = {
      contracts: contractsList,
      signatureInfo: contractsWithSignatories,
      customerId,
      customerName,
      language,
    };

    try {
      const result = await postContract(newContractRequest, oidc.user.access_token);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      let mappedContracts = new Map();
      for (const responseContract of result) {
        mappedContracts = mappedContracts.set(
          responseContract.contractInfo.shortId,
          fromJS(responseContract.contractInfo)
        );
      }
      dispatch(contractCreateSuccess(mappedContracts));
    } catch (error) {
      dispatch(contractCreateError());
    }
  };
};

const getContractLanguage = (contract: Contract, commonState: CommonState) => {
  const lang = commonState.contractLanguage ? commonState.contractLanguage : 'fi';
  contract.language = lang;
  return contract;
};

export const getContractPreview = (): AppThunk => {
  return (dispatch, getState) => {
    const { contracts, oidc, common } = getState();
    const selected = contracts.selected.toJS();
    const templates = contracts.availableTemplates;
    const values = contracts.values;

    dispatch(createPreviewRequest());

    forEach(selected, (isSelected, contractType) => {
      const contractData = templates.find((item) => item.contractType === contractType && isSelected);
      if (contractData) {
        let contract = values.get(contractData.contractType).toJS();
        contract = getContractLanguage(contract, common);
        let contractName = contractData.name;
        if (contract.language === 'sv') {
          contractName =
            contractData.names && contractData.names.length > 1 ? contractData.names[1] : contractData.name;
        }
        postContractForPreview(contract, oidc.user.access_token)
          .then((previewId) => {
            dispatch(createPreviewSuccess(previewId, contract.contractType, contractName));
          })
          .catch((error) => {
            dispatch(createPreviewFailure(error));
          });
      }
    });
  };
};

const contractPrefilledRequest = () => {
  return <const>{
    type: CONTRACT_PREFILLED_REQUEST,
  };
};

const contractPrefilledSuccess = (result: Contract, countries: Countries) => {
  return <const>{
    type: CONTRACT_PREFILLED_SUCCESS,
    result,
    countries,
  };
};

const contractPrefilledFailure = (contractId: string) => {
  return <const>{
    type: CONTRACT_PREFILLED_FAILURE,
    contractId,
  };
};

const getPrefilledContract = (templateId: string, clientId: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(contractPrefilledRequest());

    try {
      const result = await getPrefilled(templateId, clientId, getState().oidc.user.access_token);
      result.language = getState().common.contractLanguage;
      dispatch(contractPrefilledSuccess(result, getState().common.countries));
    } catch (error) {
      dispatch(contractPrefilledFailure(templateId));
    }
  };
};

export function fetchContract(contractId: string): AppThunk<Promise<Contract>> {
  return async (dispatch, getState) => {
    dispatch(setFetchingContract(true));
    try {
      return await getContract(contractId, getState().oidc.user.access_token);
    } finally {
      dispatch(setFetchingContract(false));
    }
  };
}

const mergeContractCopy = (contract: { [key: string]: Contract }) => {
  return <const>{
    type: MERGE_CONTRACT_COPY,
    contract,
  };
};

export const copyContract = (contractId: string): AppThunk => {
  return (dispatch, getState) =>
    dispatch(fetchContract(contractId))
      .then((res) => {
        const response = res;
        // @ts-expect-error: Type mismatch
        response.state = null;
        // @ts-expect-error: Type mismatch
        response.id = null;
        // @ts-expect-error: Type mismatch
        response.shortId = null;
        return response;
      })
      .then((response) => {
        const key = response.contractType;
        return dispatch(fetchContractTemplate(key)).then((results) => {
          const template = results as Template;
          const fixedContract = fixContractSchema(response, template, getState().common.countries);
          const fixedVersionsContract = updateContractTemplateVersions(fixedContract, template);
          const contract = { [key]: fixedVersionsContract };
          dispatch(mergeContractCopy(contract));
        });
      });
};

export const startLoadingContractTemplate = (contractType: string) => {
  return <const>{
    type: CONTRACT_TEMPLATE_START_LOADING,
    contractType,
  };
};

export const finishLoadingContractTemplate = (template: Template) => {
  return <const>{
    type: CONTRACT_TEMPLATE_FINISH_LOADING,
    contractType: template.contractType,
    schema: template.schema,
  };
};

export const selectContract = (templateId: string, clientId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(toggleContract(templateId, getState().common.contractLanguage));

    const template = getState().contracts.templates.find((t) => t.contractType === templateId);

    if (!template) {
      try {
        dispatch(startLoadingContractTemplate(templateId));

        await dispatch(fetchContractTemplate(templateId));
        await dispatch(getPrefilledContract(templateId, clientId));

        const tmpl = getState().contracts.templates.find((t) => t.contractType === templateId);
        dispatch(finishLoadingContractTemplate(tmpl));
      } catch (error) {
        console.error(error); // eslint-disable-line no-console
      }
    }
  };
};

const pdfPreviewRequest = (contractType: string) => {
  return <const>{
    type: PDF_PREVIEW_REQUEST,
    contractType,
  };
};

const pdfPreviewSuccess = (contractType: string, blobUrl: string) => {
  return <const>{
    type: PDF_PREVIEW_SUCCESS,
    contractType,
    blobUrl,
  };
};

const pdfPreviewError = (error: Error) => {
  return <const>{
    type: PDF_PREVIEW_FAILURE,
    error,
  };
};

export const getPdfForPreview = (id: string, contractType: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(pdfPreviewRequest(contractType));

    try {
      const blob = await getPdfForPreviewRequest(id, getState().oidc.user.access_token);
      makeBlobUrl(blob, (blobUrl) => dispatch(pdfPreviewSuccess(contractType, blobUrl)));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(pdfPreviewError(error));
      }
    }
  };
};

const pdfContractRequest = (id: string) => {
  return <const>{
    type: PDF_CONTRACT_REQUEST,
    id,
  };
};

const pdfContractSuccess = (id: string, blobUrl: string) => {
  return <const>{
    type: PDF_CONTRACT_SUCCESS,
    id,
    blobUrl,
  };
};

const pdfContractFailure = (error: Error) => {
  return <const>{
    type: PDF_CONTRACT_FAILURE,
    error,
  };
};

export const getContactPdf = (id: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(pdfContractRequest(id));
    return getContractPdf(id, getState().oidc.user.access_token)
      .then((blob) => {
        makeBlobUrl(blob, (blobUrl) => dispatch(pdfContractSuccess(id, blobUrl)));
      })
      .catch((error) => {
        dispatch(pdfContractFailure(error));
      });
  };
};

export const toggleNewSignatoryDialog = (show: boolean, contract: Contract) => {
  const actionBase = { type: TOGGLE_NEW_SIGNATORY_DIALOG };
  if (show) {
    return { ...actionBase, show, contract };
  }
  return { ...actionBase, show };
};

export const addSignatory = (contractType: string, signatory: Signatory) => {
  return <const>{
    type: CONTRACT_ADD_SIGNATORY,
    contractType,
    signatory,
  };
};

export const toggleSignatoryDetailsDialog = (show: boolean, contract: Contract, signatory: Signatory) => {
  return <const>{
    type: TOGGLE_SIGNATORY_DETAILS_DIALOG,
    show,
    contract,
    signatory,
  };
};

export const removeSignatory = (contractType: string, signatory: Signatory) => {
  return <const>{
    type: CONTRACT_REMOVE_SIGNATORY,
    contractType,
    signatory,
  };
};

export const removeAllSignatories = () => {
  return <const>{
    type: CONTRACT_REMOVE_ALL_SIGNATORIES,
  };
};

export const copySignatoriesToAllContracts = (signatories: Signatory[]) => {
  return <const>{
    type: COPY_SIGNATORIES_TO_ALL,
    signatories,
  };
};

export const showOnlyUnfinishedQuestions = (event: string, value: string) => {
  return <const>{
    type: SHOW_ONLY_UNFINISHED,
    value,
  };
};

export const prefillSignatory = (contractType: string) => {
  return <const>{
    type: CONTRACT_PREFILL_SIGNATORY,
    contractType,
  };
};

export const showDiscardSessionDialog = () => {
  return <const>{
    type: SHOW_DISCARD_SESSION_DIALOG,
  };
};

export const hideDiscardSessionDialog = () => {
  return <const>{
    type: HIDE_DISCARD_SESSION_DIALOG,
  };
};

const isContractSelected = (contractType: string, contracts: ContractsState) => {
  return contracts.selected.get(contractType, false);
};

export const selectMandatoryContracts = (customer: Customer): AppThunk => {
  return (dispatch, getState) => {
    if (isProspect(customer) && hasMandatoryContract(customer)) {
      const mandatoryContract = mandatoryContracts(customer);
      forEach(mandatoryContract, (contractType) => {
        if (!isContractSelected(contractType, getState().contracts)) {
          dispatch(selectContract(contractType, customer.customerId));
        }
      });
    }
    const mandatoryKycContract = mandatoryKycContracts(customer);
    forEach(mandatoryKycContract, (contractType) => {
      if (customer.nextKycUpdate) {
        if (moment(customer.nextKycUpdate, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
          if (!isContractSelected(contractType, getState().contracts)) {
            dispatch(selectContract(contractType, customer.customerId));
          }
        }
      } else {
        if (!isContractSelected(contractType, getState().contracts)) {
          dispatch(selectContract(contractType, customer.customerId));
        }
      }
    });
  };
};

export const getSignatureRequests = (contractId: string): AppThunk => {
  return (_dispatch, getState) => {
    return getSignatures(contractId, getState().oidc.user.access_token);
  };
};

const getSignatoriesForCustomer = (signers: Signers) => {
  return <const>{
    type: GET_SIGNATORIES_FOR_CUSTOMER,
    signers,
  };
};

export const getClientSigners = (): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    getSigners(profile.customer.get('customerId'), oidc.user.access_token)
      .then((signers) => {
        dispatch(getSignatoriesForCustomer(signers));
      })
      .catch(() => {
        dispatch(getSignatoriesForCustomer({ signers: [] }));
      });
  };
};

const bisnodeGet = (bisnode: BisnodeUpdate, canUpdateBisnode: boolean) => {
  return <const>{
    type: BISNODE_GET,
    bisnode,
    canUpdateBisnode,
  };
};

const bisnodeUpdated = (bisnode: BisnodeUpdate | {}, canUpdateBisnode: boolean) => {
  return <const>{
    type: BISNODE_UPDATED,
    bisnode,
    canUpdateBisnode,
  };
};

const bisnodeUpdating = () => {
  return <const>{
    type: BISNODE_UPDATING,
  };
};

export const getBisnode = (): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    getBisnodeData(profile.customer.get('customerId'), oidc.user.access_token)
      .then((bisnode: Bisnode) => {
        dispatch(
          bisnodeGet(
            {
              text: bisnode.procurationAbstractDescription,
              updateDate: bisnode.editDate,
            },
            true
          )
        );
      })
      .catch(() => {
        dispatch(bisnodeUpdated({}, true));
      });
  };
};

export const updateBisnode = (): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    dispatch(bisnodeUpdating());

    updateBisnodeData(profile.customer.get('customerId'), profile.customer.get('businessId'), oidc.user.access_token)
      .then((bisnode: Bisnode) => {
        dispatch(
          bisnodeUpdated(
            {
              text: bisnode.procurationAbstractDescription,
              updateDate: bisnode.editDate,
            },
            true
          )
        );
      })
      .catch((e) => {
        dispatch(bisnodeUpdated({ text: e.status === 404 ? 'Tietoja ei löytynyt' : 'Bisnode virhe' }, false));
      });
  };
};

export const crmPersonSearch = (q: string) => {
  return {
    types: [CRM_PERSON_SEARCH_REQUEST, CRM_PERSON_SEARCH_SUCCESS, CRM_PERSON_SEARCH_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/contact/search?text=${encodeURIComponent(q)}`),
  };
};

export const getHasWebServiceContract = (params: FoundPerson): Promise<GetHasWebServiceContractResponse> => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    types: [HAS_WEB_SERVICE_REQUEST, HAS_WEB_SERVICE_SUCCESS, HAS_WEB_SERVICE_FAILURE],
    promise: (client: ApiClient) =>
      client.get(`/api/v1/contact/haswebservice?${qs.stringify(params, { skipNulls: true })}`),
  };
};

const createWebServiceContract = (params: FoundPerson): AppThunk<Promise<CreateWebServiceContractResponse>> => {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    types: [GET_WEB_SERVICE_CONTRACT_REQUEST, GET_WEB_SERVICE_CONTRACT_SUCCESS, GET_WEB_SERVICE_CONTRACT_FAILURE],
    promise: (client: ApiClient) =>
      client.get(`/api/v1/contact/createwebservicecontract?${qs.stringify(params, { skipNulls: true })}`),
  };
};

const addWebServiceContractRequest = () => {
  return <const>{
    type: ADD_WEB_SERVICE_CONTRACT_REQUEST,
  };
};

const addWebServiceContractFailure = (contractExist?: boolean) => {
  return <const>{
    type: ADD_WEB_SERVICE_CONTRACT_FAILURE,
    contractExist,
  };
};

const addWebServiceContractSuccess = () => {
  return <const>{
    type: ADD_WEB_SERVICE_CONTRACT_SUCCESS,
  };
};

export const addWebServiceContract = (params: FoundPerson): AppThunk => {
  return async (dispatch, getState) => {
    const templateId = params.proxyOfCustomerId === null ? WEB_SERVICE_CONTRACT : WEB_SERVICE_CONTRACT_PROXY;
    dispatch(addWebServiceContractRequest());

    let results;
    try {
      results = await Promise.all([
        dispatch(createWebServiceContract(params)),
        dispatch(fetchContractTemplate(templateId)),
      ]);
    } catch (error) {
      dispatch(addWebServiceContractFailure(true));
    }

    if (!results || !results[0].result) {
      dispatch(addWebServiceContractFailure(true));
      return;
    }

    const { contracts, oidc, common } = getState();
    let contract = contracts.values.get(WEB_SERVICE_CONTRACT).toJS();
    const module = contracts.templates.get(templateId).form.items[1];
    const schema = contracts.templates.get(templateId).schema;
    dispatch(moduleValidation(module, schema, WEB_SERVICE_CONTRACT));

    try {
      const previewId = await postContractForPreview(contract, oidc.user.access_token);
      const contractData = await getPrefilled(templateId, params.customerId, oidc.user.access_token);

      contract = getContractLanguage(contract, common);
      let contractName =
        contractData.localizedNames && contractData.localizedNames.length > 1
          ? contractData.localizedNames[0]
          : contractData.name;
      if (contract.language === 'sv') {
        contractName =
          contractData.localizedNames && contractData.localizedNames.length > 1
            ? contractData.localizedNames[1]
            : contractData.name;
      }

      dispatch(createPreviewSuccess(previewId, contract.contractType, contractName));
      dispatch(addWebServiceContractSuccess());
    } catch (error) {
      if (error instanceof Error) {
        dispatch(createPreviewFailure(error.message));
      }
      dispatch(addWebServiceContractFailure());
    }
  };
};

export type ContractsAction = ReturnType<
  | typeof toggleCreateContractsConfirm
  | typeof changeSectionField
  | typeof fieldValidation
  | typeof moduleValidation
  | typeof hideUnSavedDataWarning
  | typeof resetNotification
  | typeof createClientSession
  | typeof showUnSavedDataWarning
  | typeof selectSignatureMethod
  | typeof setManualSignatureAmount
  | typeof createPreviewRequest
  | typeof createPreviewFailure
  | typeof createPreviewSuccess
  | typeof setActiveContract
  | typeof touchFields
  | typeof toggleContract
  | typeof contractMergeDraft
  | typeof clearSelectedAction
  | typeof setSelectedRiskLevel
  | typeof resetUserSession
  | typeof contractCreateRequest
  | typeof contractCreateDataError
  | typeof contractCreateError
  | typeof contractCreateSuccess
  | typeof mergeContractCopy
  | typeof startLoadingContractTemplate
  | typeof finishLoadingContractTemplate
  | typeof pdfPreviewRequest
  | typeof pdfPreviewSuccess
  | typeof pdfPreviewError
  | typeof pdfContractRequest
  | typeof pdfContractSuccess
  | typeof pdfContractFailure
  | typeof addSignatory
  | typeof toggleSignatoryDetailsDialog
  | typeof removeSignatory
  | typeof removeAllSignatories
  | typeof copySignatoriesToAllContracts
  | typeof showOnlyUnfinishedQuestions
  | typeof prefillSignatory
  | typeof showDiscardSessionDialog
  | typeof hideDiscardSessionDialog
  | typeof getSignatoriesForCustomer
  | typeof bisnodeGet
  | typeof bisnodeUpdated
  | typeof bisnodeUpdating
  | typeof addWebServiceContractRequest
  | typeof addWebServiceContractFailure
  | typeof addWebServiceContractSuccess
  | typeof contractPrefilledRequest
  | typeof contractPrefilledSuccess
  | typeof contractPrefilledFailure
  | typeof uploadAttachmentDocumentRequest
  | typeof uploadAttachmentDocumentFailure
  | typeof uploadAttachmentDocumentSuccess
  | typeof uploadAttachmentSignatureMethod
  | typeof uploadAttachmentAddSignature
  | typeof uploadAttachmentRemoveSignature
  | typeof removeAttachmentDocumentRequest
  | typeof removeAttachmentDocumentFailure
  | typeof removeAttachmentDocumentSuccess
  | typeof setManualSignatureAmountAttachment
  | typeof addAttachment
  | typeof addAttachmentsFromDraft
  | typeof openSuitabilityAssesment
>;
