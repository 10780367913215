import React from 'react';
import { useSelector } from 'react-redux';
import ManualSignatorySelection from 'features/contracts/components/ManualSignatorySelection';
import SignatoryList from 'features/contracts/components/SignatoryList';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';
import { useAppDispatch } from 'core/hooks';
import { setManualSignatureAmountAttachment } from 'features/contracts/contractsActions';
import { Signatory } from 'types/ordersState';
import { RootState } from 'types/rootState';
import { SignatureMethodType } from 'types/types';

const SignaturesList = (props: {
  attachmentId: string;
  signatures: Signatory[];
  signatureMethod: SignatureMethodType;
  onNewSignatory: () => void;
  openSignatureDetails: (signature: Signatory) => void;
  copyToAll: (signatures: Signatory[]) => void;
}) => {
  const { signatureMethod, signatures, onNewSignatory, copyToAll, attachmentId, openSignatureDetails } = props;
  const dispatch = useAppDispatch();
  const manualSignatures = useSelector(
    (state: RootState) => state.contracts.attachmentPdfs.find((x) => x.id === attachmentId)?.manualSignatures
  );

  const setManualSignatureAmount = (amount: number) => {
    dispatch(setManualSignatureAmountAttachment(amount, attachmentId));
  };

  if (signatureMethod === SENT_FOR_SIGNING || signatureMethod === SENT_FOR_INK_SIGNING) {
    return (
      <SignatoryList
        onNewSignatoryButtonClick={onNewSignatory}
        onCopyAllSignatoriesButtonClick={() => copyToAll(signatures)}
        onItemSelect={(signature: Signatory) => openSignatureDetails(signature)}
        items={signatures}
        showCopyAllSignatories={signatures.length > 0}
        disableEdit={false}
        disableAddSignatory={signatureMethod === SENT_FOR_INK_SIGNING && signatures.length > 0}
      />
    );
  }

  if (signatureMethod === SENT_FOR_MANUAL_SIGNING) {
    return (
      <ManualSignatorySelection handleChangingManualSigners={setManualSignatureAmount} signers={manualSignatures} />
    );
  }
  return null;
};

export default SignaturesList;
