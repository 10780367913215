import React from 'react';
import getValue from 'lodash/get';
import RegistryField from './RegistryField';
import { Schema } from 'types/contractsState';
import { FieldType } from 'types/ordersState';

interface Props {
  fields: FieldType[];
  values: object;
  changeSectionField: (key: string, value: string | object[]) => void;
  focusIndex?: number;
  schema: Schema;
}

export const RegistryFieldList = (props: Props) => {
  const { changeSectionField, fields, values, focusIndex, schema } = props;
  const registryFields = fields.map((field, index) => (
    <RegistryField
      field={field}
      value={getValue(values, field.key)}
      changeSectionField={changeSectionField}
      key={field.key}
      hasFocus={index === focusIndex}
      schema={schema}
    />
  ));
  return <section>{registryFields}</section>;
};
