import translate from 'counterpart';
import { batch } from 'react-redux';
import { OptimizationForecastType, RiskLevel, AppThunk } from 'types/types';
import { clearError, setError } from 'features/errors/errorActions';
import {
  getPositionAndPlanDifference,
  getPlan,
  mapInstrument,
  postSaveInvestmentPlan,
  generalConstraintsMapper,
  postInvestmentPlanInstrumentsMapper,
  targetAllocationsMapper,
} from './investmentPlanUtils';
import { errorKeys } from 'features/errors/errorUtils';
import { optimizationForecastTypes } from 'constants/allocator';
import {
  AllocatorPortfolio,
  AllocatedPortfolio,
  InvestmentPlanPayload,
  InvestmentPlanResponse,
  PlanState,
} from 'types/investmentPlanState';
import {
  selectRisk,
  selectHasPositions,
  portfoliosHaveNoIds,
  outsidePortfolioMissingInPlan,
} from 'features/allocator/allocatorSelectors';
import {
  setInstrumentsSelected,
  setSelectedInstrumentGroup,
} from 'features/allocator/investmentPlan/investmentPlanInstrumentsActions';
import {
  setUseBackendConstraints,
  storeConstraints,
} from 'features/allocator/investmentPlan/investmentPlanConstraintsActions';
import { RootState } from 'types/rootState';
import { SELECT_CORE_MANUALLY } from 'features/allocator/coreSelection/components/ChoosePlan';
import {
  convertPositionsToOptimizedPortfolios,
  createLoadedPlanPortfolios,
} from 'features/allocator/planPortfolios/planPortfolioCreationUtils';
import { clearPortfolioWeights } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { updateOptimizedPortfolios, updateOptimizedPortfoliosSettings } from 'features/weights/weightsSlice';
import PromiseStore from 'core/PromiseStore';

export const SET_LOADING_PLAN = 'SET_LOADING_PLAN';
export const GET_INVESTMENT_PLAN_NOT_FOUND = 'GET_INVESTMENT_PLAN_NOT_FOUND';
export const SET_PLAN_STATE = 'SET_PLAN_STATE';
export const INITIALIZE_INVESTMENT_PLAN_STATE = 'INITIALIZE_INVESTMENT_PLAN_STATE';
export const FLAG_CHECK_PORTFOLIOS = 'FLAG_CHECK_PORTFOLIOS';
export const CREATE_PLAN_PORTFOLIOS = 'CREATE_PLAN_PORTFOLIOS';
export const SET_OPTIMIZATION_FORECAST_TYPE = 'SET_OPTIMIZATION_FORECAST_TYPE';
export const TARGET_RISK_LEVEL_UPDATE = 'TARGET_RISK_LEVEL_UPDATE';
export const TEST_RISK_LEVEL_UPDATE = 'TEST_RISK_LEVEL_UPDATE';

// Sync actions

export const initializeInvestmentPlanState = () => {
  return <const>{
    type: INITIALIZE_INVESTMENT_PLAN_STATE,
  };
};

export const setTargetRiskLevel = (riskLevel: RiskLevel) => {
  return <const>{
    type: TARGET_RISK_LEVEL_UPDATE,
    result: riskLevel,
  };
};

export const setTestRiskLevel = (riskLevel: RiskLevel) => {
  return <const>{
    type: TEST_RISK_LEVEL_UPDATE,
    result: riskLevel,
  };
};

export const createPlanPortfolios = (result: AllocatorPortfolio[]) => {
  return <const>{
    type: CREATE_PLAN_PORTFOLIOS,
    result,
  };
};

export const flagCheckPortfolios = (value: boolean) => {
  return <const>{
    type: FLAG_CHECK_PORTFOLIOS,
    payload: value,
  };
};

export const setOptimizationForecastType = (value: OptimizationForecastType) => {
  return <const>{
    type: SET_OPTIMIZATION_FORECAST_TYPE,
    value,
  };
};

const setLoadingPlan = (value: boolean) => {
  return <const>{
    type: SET_LOADING_PLAN,
    value,
  };
};

export const setPlanState = (planState: PlanState) => {
  return <const>{
    type: SET_PLAN_STATE,
    payload: planState,
  };
};

const getInvestmentPlanNotFound = () => {
  return <const>{
    type: GET_INVESTMENT_PLAN_NOT_FOUND,
  };
};

export type InvestmentPlanAction = ReturnType<
  | typeof initializeInvestmentPlanState
  | typeof setTargetRiskLevel
  | typeof createPlanPortfolios
  | typeof flagCheckPortfolios
  | typeof setOptimizationForecastType
  | typeof setLoadingPlan
  | typeof setPlanState
  | typeof getInvestmentPlanNotFound
  | typeof setTestRiskLevel
>;

// Async actions

export const getInvestmentPlan = (customerId: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(setLoadingPlan(true));
    dispatch(clearError(errorKeys.loadInvestmentPlan));
    try {
      const accessToken = getState().oidc.user.access_token;
      const result = (await getPlan(customerId, accessToken)) as InvestmentPlanResponse | 204;
      if (result === 204) {
        dispatch(getInvestmentPlanNotFound());
        return;
      }
      dispatch(storeInvestmentPlanToStore(state, result));
    } catch (error) {
      dispatch(setPlanState('loadPlanFailed'));
      dispatch(setError({ context: errorKeys.loadInvestmentPlan }));
    } finally {
      dispatch(setLoadingPlan(false));
    }
  };
};

export const cancelGetInvestmentPlan = (): AppThunk => {
  return (dispatch) => {
    PromiseStore.getInvestmentPlan.cancel();
    dispatch(setLoadingPlan(false));
  };
};

const storeInvestmentPlanToStore =
  (state: RootState, investmentPlan: InvestmentPlanResponse): AppThunk =>
  (dispatch) => {
    const assetClasses = state.portfolioManager.commonData.assetClasses;

    batch(() => {
      dispatch(setTargetRiskLevel(investmentPlan?.risk));
      dispatch(setSelectedInstrumentGroup(investmentPlan?.instrumentGroup));
      dispatch(storeInstruments(investmentPlan.allocatedPortfoliosWithIlliquid));
      dispatch(storeConstraints(investmentPlan.generalConstraints, assetClasses));
      dispatch(setUseBackendConstraints(investmentPlan?.useBackendConstraints));
      dispatch(setOptimizationForecastType(investmentPlan.useCompanyOptimizationForecast ? 'company' : 'neutral'));
    });

    const noPortfolioIds = portfoliosHaveNoIds(state, investmentPlan.allocatedPortfoliosWithoutIlliquid);
    const positionAndPlanDifference = getPositionAndPlanDifference(state, investmentPlan);
    const allPositionsNotInPlan = positionAndPlanDifference.positionsNotInPlan.length > 0;
    const allPlanInstrumentsNotInPositions = positionAndPlanDifference.planInstrumentsNotInPositions.length > 0;

    const outsidePortfolioMissing = outsidePortfolioMissingInPlan(
      state,
      investmentPlan.allocatedPortfoliosWithoutIlliquid
    );

    if (noPortfolioIds || outsidePortfolioMissing) {
      dispatch(setPlanState('invalidPlan'));
      dispatch(
        setError({
          error: translate('errors.obsoletePlan'),
          context: errorKeys.obsoletePlan,
        })
      );
      return;
    }

    const planPortfolios = createLoadedPlanPortfolios(investmentPlan);
    const planPortfoliosWithoutWeights = clearPortfolioWeights(planPortfolios);

    const riskLevel = selectRisk(state) || investmentPlan?.risk; // investmentPlan.targetRiskLevel is not yet in store
    const optimizationForecastType = state.portfolioManager.investmentPlan.optimizationForecastType;

    batch(() => {
      dispatch(createPlanPortfolios(planPortfolios));
      dispatch(
        updateOptimizedPortfolios({
          portfolios: planPortfoliosWithoutWeights,
          riskLevel,
          optimizationForecastType,
        })
      );
      dispatch(updateOptimizedPortfoliosSettings(planPortfolios));
    });

    if (allPositionsNotInPlan) {
      dispatch(flagCheckPortfolios(true));
      dispatch(setPlanState('invalidPlan'));
      dispatch(setError({ context: errorKeys.allPositionsNotInPlan }));
    } else {
      if (allPlanInstrumentsNotInPositions) {
        dispatch(setError({ context: errorKeys.allPlanInstrumentsNotInPositions }));
      }
      const hasPositions = selectHasPositions(state);
      investmentPlan.isDraft || hasPositions
        ? dispatch(setPlanState('unlockedPlan'))
        : dispatch(setPlanState('lockedPlan'));
    }
  };

const storeInstruments =
  (portfolios: AllocatedPortfolio[]): AppThunk =>
  (dispatch) => {
    const instruments = portfolios.flatMap((p) => p.allocatedPortfolioRows.map((i) => mapInstrument(i, p)));
    dispatch(setInstrumentsSelected(instruments));
  };

export const convertExistingPositionsToPlan = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const currentWeights = getState().portfolioManager.weights.currentWeights;
    dispatch(setSelectedInstrumentGroup(SELECT_CORE_MANUALLY));
    dispatch(setInstrumentsSelected(currentWeights.portfolioWeights.flatMap((i) => i.instruments)));

    const portfoliosFromPositions = convertPositionsToOptimizedPortfolios(state);
    const riskLevel = selectRisk(state);
    const optimizationForecastType = state.portfolioManager.investmentPlan.optimizationForecastType;

    dispatch(
      updateOptimizedPortfolios({
        portfolios: portfoliosFromPositions,
        riskLevel,
        optimizationForecastType,
      })
    );

    dispatch(updateOptimizedPortfoliosSettings(portfoliosFromPositions));
    dispatch(flagCheckPortfolios(true));
  };
};

export const saveInvestmentPlan = (customerId: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const isDraft = !selectHasPositions(state);

    const payload: InvestmentPlanPayload = {
      customerId,
      risk: selectRisk(state),
      creator: state.oidc.user.profile.sub,
      useCompanyOptimizationForecast:
        state.portfolioManager.investmentPlan.optimizationForecastType === optimizationForecastTypes.COMPANY,
      instrumentConstraints: [],
      generalConstraints: generalConstraintsMapper(state),
      withoutIlliquidInstruments: postInvestmentPlanInstrumentsMapper({ state, withIlliquid: false }),
      withIllliquidInstruments: postInvestmentPlanInstrumentsMapper({ state, withIlliquid: true }),
      instrumentGroup: state.portfolioManager.investmentPlan.instruments.selectedInstrumentGroup,
      targetAllocationsWithoutIlliquid: targetAllocationsMapper({ state, withIlliquid: false }),
      targetAllocationsWithIlliquid: targetAllocationsMapper({ state, withIlliquid: true }),
      useBackendConstraints: state.portfolioManager.investmentPlan.constraints.useBackendConstraints,
    };

    try {
      dispatch(clearError(errorKeys.saveInvestmentPlan));
      const accessToken = getState().oidc.user.access_token;
      await postSaveInvestmentPlan(payload, accessToken, isDraft);
      dispatch(clearError('obsoletePlan'));
    } catch (error) {
      dispatch(setError({ context: errorKeys.saveInvestmentPlan }));
    }
  };
};
