import React from 'react';
import accounting from 'accounting';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import classnames from 'classnames';
import { SELL, BUY } from 'constants/sides';
import { FUND } from 'constants/instrumentForms';
import { listed, SECURITY_NOT_IN_REPORTING } from 'constants/shareOptions';
import { rowStyles, columnStyles } from 'styles/material-ui/material-ui';
import styles from './PositionRow.scss';
import { isListed } from 'core/portfolios';
import {
  CONSULTATIVE_AGREEMENT_TYPE,
  CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE,
  SECURITIES_BROKERAGE_CONTRACT,
  SECURITIES_BROKERAGE_CONTRACT_TYPE,
} from 'constants/contractNames';
import { PortfolioDetailsById, MarketInfo } from 'types/portfolioState';
import { Position } from 'types/ordersState';
import { countDecimal } from 'core/index';
import { EditorWithSuitabilityErrors, OrderSideType } from 'types/orderDialogState';
import { selectCanDoAssignments } from 'features/portfolio/portfolioSelectors';
import { CLIENT_INITIATIVE, ACCORDING_TO_INVESTMENT_ADVICE } from 'constants/receiveInfoOptions';
import { getTransactionFees } from 'features/orderDialog/orderDialogActions';
import { useAppDispatch } from 'core/hooks';
import { selectEditor } from 'features/orderDialog/orderDialogSelectors';

interface Props {
  position: Position;
  onPositionClick: (assignment: EditorWithSuitabilityErrors) => void;
  marketInfo: MarketInfo;
  portfolioDetailsById: PortfolioDetailsById;
}

export const PositionRow = ({ position, marketInfo, portfolioDetailsById, onPositionClick }: Props) => {
  const dispatch = useAppDispatch();
  const quantity = position.quantity;
  const quantityNet = position.quantityNet;
  const purchasePrice = position.purchasePrice.baseCurrency ? position.purchasePrice.baseCurrency.value : 0;
  const purchaseValue = position.purchaseValue.baseCurrency ? position.purchaseValue.baseCurrency.value : 0;
  const marketValue = position.marketValue.baseCurrency ? position.marketValue.baseCurrency.value : 0;
  const share = (marketValue / marketInfo.marketValue) * 100;
  const marketValueChangePercent = position.marketValueChangePercent.baseCurrency
    ? position.marketValueChangePercent.baseCurrency.value * 100
    : 0;
  const marketValueChange = position.marketValueChange.baseCurrency ? position.marketValueChange.baseCurrency.value : 0;
  /*  const isOwnProduct = position.financialInstrument.isOwnProduct;*/
  const suitabilityErrorsBuy = position.suitabilityErrorsBuy;
  const suitabilityErrorsSell = position.suitabilityErrorsSell;
  const marketValueNet = position.marketValueNet.baseCurrency ? position.marketValueNet.baseCurrency.value : 0;
  const isAllowedForm = _.includes([FUND], position.financialInstrument.financialInstrumentForm);
  const isListedForm = isListed(position.financialInstrument.financialInstrumentForm, SELL);

  const canDoAssignments = useSelector(selectCanDoAssignments);
  const editor = useSelector(selectEditor).toJS();

  const onAssignmentClick = async (OrderSide: OrderSideType) => {
    const fees = await dispatch(
      getTransactionFees(
        position.customer.customerId,
        position.portfolio.portfolioId,
        position.financialInstrument.financialInstrumentId,
        OrderSide
      )
    );

    const contractType = _.get(portfolioDetailsById, [position.portfolio.portfolioId, 'contractType']);
    let defaultOrderBasisByContractType = '';
    if (contractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE || contractType === SECURITIES_BROKERAGE_CONTRACT_TYPE) {
      defaultOrderBasisByContractType = CLIENT_INITIATIVE;
    }
    if (contractType === CONSULTATIVE_AGREEMENT_TYPE) {
      defaultOrderBasisByContractType = ACCORDING_TO_INVESTMENT_ADVICE;
    }

    const editorValues: EditorWithSuitabilityErrors = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      editor: {
        issuer: position.financialInstrument.issuer,
        financialInstrumentId: position.financialInstrument.financialInstrumentId,
        financialInstrumentForm: position.financialInstrument.financialInstrumentForm,
        financialInstrumentName: `${position.financialInstrument.name} (${position.financialInstrument.financialInstrumentId})`,
        portfolioId: position.portfolio.portfolioId,
        _id: Date.now(),
        currency: listed.includes(position.financialInstrument.financialInstrumentForm)
          ? _.get(portfolioDetailsById, [position.portfolio.portfolioId, 'withdrawalCurrency'])
          : position.financialInstrument.currency,
        instrumentCurrency: position.financialInstrument.currency,
        marketPrice: _.get(position, ['marketPrice', 'securityCurrency', 'value']) ?? '',
        marketPriceCurrency: _.get(position, ['marketPrice', 'securityCurrency', 'currency']) ?? '',
        marketPriceBase: _.get(position, ['marketPrice', 'baseCurrency', 'value']) ?? '',
        marketPriceBaseCurrency: _.get(position, ['marketPrice', 'baseCurrency', 'currency'] ?? ''),
        side: OrderSide,
        currentQuantity: quantityNet,
        marketValueNet,
        orderFinancingSell:
          _.get(portfolioDetailsById, [position.portfolio.portfolioId, 'contractName']) ===
          SECURITIES_BROKERAGE_CONTRACT
            ? SECURITY_NOT_IN_REPORTING
            : undefined,
        instrumentForm: position.financialInstrument.financialInstrumentForm,
        orderBasis: editor.orderBasis ? editor.orderBasis : defaultOrderBasisByContractType,
        isSuitable: !!(
          position.financialInstrument.isSuitable === undefined || position.financialInstrument.isSuitable === true
        ),
        defaultFee: fees?.feePercentage,
        subscriptionFee: fees?.feePercentage * 100,
        buyOrSellClicked: true,
      },
      suitabilityErrorsBuy,
      suitabilityErrorsSell,
    };

    onPositionClick(editorValues);
  };

  const financialInstrumentId = position.financialInstrument.financialInstrumentId;
  const buyButtonCell = [];
  const sellButtonCell = [];

  if (canDoAssignments && (isAllowedForm || isListedForm)) {
    buyButtonCell.push(
      <button
        data-testkey={`sell-or-buy-button-${financialInstrumentId}`}
        className={classnames(styles.button, styles.buy)}
        onClick={() => onAssignmentClick(BUY)}
        key={`button-buy-${financialInstrumentId}`}
      >
        Osta
      </button>
    );
  }

  if (canDoAssignments && (isAllowedForm || isListedForm)) {
    sellButtonCell.push(
      <button
        data-testkey={`sell-button-${financialInstrumentId}`}
        className={classnames(styles.button, styles.sell)}
        onClick={() => onAssignmentClick(SELL)}
        key={`button-sell-${financialInstrumentId}`}
      >
        Myy
      </button>
    );
  }

  const colStyles = {
    ...columnStyles,
    fontFamily: 'Calibre',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
  } as React.CSSProperties;

  return (
    <TableRow style={rowStyles} key={financialInstrumentId} className={styles[position.side]}>
      <TableCell style={{ ...colStyles, width: 250, paddingLeft: 5, textAlign: 'left' }}>
        {position.financialInstrument.name}
      </TableCell>

      <TableCell style={{ width: 100, ...colStyles }}>
        {accounting.formatNumber(quantity, countDecimal(quantity))}
        <br />({accounting.formatNumber(quantityNet, countDecimal(quantityNet))})
      </TableCell>

      <TableCell style={colStyles}>{accounting.formatMoney(purchasePrice)}</TableCell>

      <TableCell style={colStyles}>{accounting.formatMoney(purchaseValue)}</TableCell>

      <TableCell style={colStyles}>{accounting.formatMoney(marketValue)}</TableCell>

      <TableCell style={{ width: 80, ...colStyles }}>{accounting.formatNumber(share, 2)}</TableCell>

      <TableCell style={{ width: 100, ...colStyles }}>{accounting.formatMoney(marketValueChange)}</TableCell>

      <TableCell style={{ width: 80, ...colStyles, paddingRight: 5 }}>
        {accounting.formatNumber(marketValueChangePercent, 2)}
      </TableCell>

      <TableCell style={{ width: 60, ...colStyles, paddingRight: 5 }}>{buyButtonCell}</TableCell>
      <TableCell style={{ width: 60, ...colStyles, paddingRight: 5 }}>{sellButtonCell}</TableCell>
    </TableRow>
  );
};
