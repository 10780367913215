import React from 'react';
import styled from 'styled-components';
import { Flex } from 'features/common/StyledComponents';

interface Props {
  myBankerId: string;
  bankerId: string;
  useBanker: (e: React.SyntheticEvent) => void;
}

export const BankerList = ({ myBankerId, bankerId, useBanker }: Props) => {
  const testBankers = [
    myBankerId,
    'FUND\\UITestUser01',
    'FUND\\giucoc',
    'FUND\\atsiiv',
    'FUND\\joukan',
    'FUND\\ollvir',
    'FUND\\jaakos',
    'FUND\\mikmer',
    'FUND\\ollekl',
    'FUND\\pekhal',
    'FUND\\tomala',
    'FUND\\tomhir',
    'FUND\\artkin',
  ];

  return (
    <StyledBankers>
      {testBankers.map((i, index) => (
        <BankerName key={i} onClick={useBanker} data-testkey={`banker-${index}`} active={bankerId === i}>
          {i}
        </BankerName>
      ))}
    </StyledBankers>
  );
};

const StyledBankers = styled(Flex)`
  padding: 0.5rem 2rem;
  flex-wrap: wrap;

  & > * {
    padding: 0.5rem;
  }
`;

const BankerName = styled.a<{ active: boolean }>`
  font-weight: ${(props) => (props.active ? '900' : '500')};
`;
