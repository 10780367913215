import React from 'react';
import styled from 'styled-components';
import { styles } from 'features/common/StyledComponents';

interface Props {
  children: object;
  className?: string;
}

export const Card = ({ children, className }: Props) => {
  return <CardContainer className={className}>{children}</CardContainer>;
};

const CardContainer = styled.div`
  ${styles.card};
`;
