import React from 'react';
import { SelectWithLabel } from './SelectWithLabel';
import { bondPriceTypes } from 'constants/shareOptions';
import { SelectValue } from 'types/types';

interface Props {
  options: typeof bondPriceTypes;
  onChange: (input: SelectValue) => void;
  value: string;
  label: string;
  clearable?: boolean;
}

const SelectBondPriceType = ({ options, onChange, value, clearable, label }: Props) => (
  <SelectWithLabel options={options} onChange={onChange} value={value} clearable={clearable} label={label} />
);

export default SelectBondPriceType;
