import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#00eb64',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#00eb64',
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Calibre',
  },
}));
