import styled from 'styled-components';
import { styles, fontSize, FlexColumn } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { LinkButton } from 'features/allocator/common/LinkButton';

interface StepNavigationItemProps {
  unset: boolean;
}

export const StepNavigationItem = styled.div<StepNavigationItemProps>`
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: space-between;
  ${(props) => (props.unset ? 'background: rgba(255,255,255,.3)' : styles.card)};
  border: ${(props) => props.unset && `1px dashed ${colors.gray_inactive}`};
  padding: 1rem 1.5rem;
  border-radius: 6px;
`;

export const StepItemHeader = styled.h2`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  a,
  span {
    font-size: ${fontSize.normal};
    font-weight: 700;
    margin-left: 1rem;
  }

  span {
    color: ${colors.gray_inactive};
  }

  a {
    text-decoration: underline;
  }
`;

export const LowerPart = styled(FlexColumn)`
  margin-top: 1rem;
  align-items: flex-start;
`;

export const LinkButtonWithMargin = styled(LinkButton)`
  align-self: flex-start;
`;
