import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { selectHasPositions } from 'features/allocator/allocatorSelectors';
import { colors } from 'styles/colors';
import { WEIGHT_LIMIT } from 'constants/allocator';
import { FlexAlignCenter } from 'features/common/StyledComponents';
import { Spinner } from 'features/common/Spinner';

interface Props {
  difference?: number;
  color?: string;
  loading?: boolean;
}

export const DifferenceCell = ({ difference, color, loading = false }: Props) => {
  const hasPositions = useSelector(selectHasPositions);
  const notZero = difference !== undefined && Math.abs(difference) > WEIGHT_LIMIT;

  if (loading) {
    return (
      <td>
        <Spinner size={20} position="right" />
      </td>
    );
  }

  return (
    <TD data-testkey="item-weight-difference">
      <NumberAndBar>
        <BarContainer>
          {notZero && !!difference && (
            <>
              <Line />
              <Bar difference={difference} color={hasPositions ? undefined : color} />
            </>
          )}
        </BarContainer>

        {difference !== undefined ? (
          <Percentage difference={difference} isZero={!notZero}>
            {notZero ? formatToDecimals(difference, 1, '%').replace('-', '–') : '±0'}
          </Percentage>
        ) : (
          <Unknown>?</Unknown>
        )}
      </NumberAndBar>
    </TD>
  );
};

const TD = styled.td`
  padding-right: 0;
  padding-left: 0.5rem;
  text-align: center;
  font-size: 11px;
  color: ${colors.gray_inactive};
  box-sizing: content-box;
  width: 90px;
`;

const NumberAndBar = styled(FlexAlignCenter)``;

const Percentage = styled.div<{ difference: number; isZero: boolean }>`
  width: 45px;
  text-align: right;
  color: ${(props) => (props.isZero && colors.gray_inactive) || colors.dark_gray};

  &:before {
    ${(props) => props.difference >= WEIGHT_LIMIT && 'content: "+"'};
  }
`;

const BarContainer = styled.div`
  position: relative;
  width: 45px;
  height: 12px;
`;

const Line = styled.div`
  height: 100%;
  width: 1px;
  left: 50%;
  position: absolute;
  background: lightgrey;
`;

interface BarStyleProps {
  difference: number;
}

const Bar = styled.div.attrs<BarStyleProps>((props) => ({
  style: {
    width: `calc(25 * ${Math.log10(1 + 100 * Math.abs(props.difference))}%)`,
  },
}))<BarStyleProps>`
  transition: width 0.3s;
  position: absolute;
  top: calc(50% - 3px);
  height: 6px;
  ${(props) =>
    props.difference >= WEIGHT_LIMIT &&
    css`
      background: ${props.color || colors.green};
      left: 50%;
    `};
  ${(props) =>
    props.difference <= -WEIGHT_LIMIT &&
    css`
      background: ${props.color || colors.red};
      right: 50%;
    `};
`;

const Unknown = styled.div`
  width: 45px;
  text-align: right;
`;
