import React, { FocusEvent } from 'react';
import _ from 'lodash';
import Immutable, { Set } from 'immutable';
import translate from 'counterpart';
import Promise from 'bluebird';
import StyledSnackbar from 'features/common/styledComponents/StyledSnackbar';
import { Field } from './Field';
import { getFieldType, doesQuestionPassConditionTestToRender, isRequired } from 'core/functions';
import { ARRAY, CRM_PERSON_SEARCH, CONTACT_ID_SEARCH } from 'constants/fieldTypes';
import ArrayQuestion from 'features/arrayQuestions/ArrayQuestion';
import { isFieldDone, getDependentQuestions, isVisibleItemError } from 'core/index';
import { ModalDialog } from 'features/common/ModalDialog';
import PersonSearchQuestion from 'features/crmPersonSearch/PersonSearchQuestion';
import ContactIdSearchQuestion from 'features/crmPersonSearch/ContactIdSearchQuestion';
import styles from './FieldList.scss';
import { Contract, FieldType, Template, Condition, Value, FoundPerson } from 'types/ordersState';
import {
  FormError,
  Countries,
  Schema,
  Locale,
  ArrayEditorValues,
  SearchPersonResponse,
  Types,
} from 'types/contractsState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  values: Contract;
  fields: FieldType[] | undefined;
  schema: Schema;
  onChange: (key: string, value: Value) => void;
  onFocus: (index: number, ev: FocusEvent<{}>) => void;
  onBlur: (field: FieldType) => void;
  errors: FormError[];
  touched: Immutable.Set<string>;
  resolveValue: (key: string) => Value;
  skipIndex?: boolean;
  wizardSection: boolean;
  countries: Countries;
  contractId: string;
  locale: Locale;
  template: Template;
  showOnlyUnfinished?: boolean;
  saveArrayEditorValue: (contractType: string, key: string, index: number | null) => void;
  profileEditor?: boolean;
  validateUntouched?: boolean;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  fulfillsCondition?: (condition: Condition, value: Value) => boolean;
  isArrayQuestion: boolean | undefined;
  contractLanguage: Locale;
  textFieldAsWizardSection?: boolean | undefined;
}

interface State {
  showNotification: boolean;
  dialogTitle: string | null;
  isDialogOpen: boolean;
  dialogContent: string | null;
}

export default class FieldList extends React.Component<Props, State> {
  static defaultProps = {
    errors: [],
    touched: Set(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showNotification: true,
      isDialogOpen: false,
      dialogTitle: null,
      dialogContent: null,
    };
  }

  onSnackbarClose = () => {
    this.setState({
      showNotification: false,
    });
  };

  getArrayErrors = (errors: FormError[], field: FieldType, values: Contract): FormError[] => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return _.filter(errors, (e) => e.key === field.key || isVisibleItemError(e.key, field, values));
  };

  isQuestionSetvalid = (arrayErrors: FormError[], fieldTouched: boolean) => {
    return fieldTouched ? arrayErrors.length === 0 : undefined;
  };

  openDialog = (title: string, info: string) => {
    this.setState({
      ...this.state,
      isDialogOpen: true,
      dialogTitle: title,
      dialogContent: info,
    });
  };

  closeDialog = () => {
    this.setState({
      ...this.state,
      isDialogOpen: false,
      dialogTitle: null,
      dialogContent: null,
    });
  };

  render() {
    const {
      fields,
      schema,
      values,
      onChange,
      onFocus,
      onBlur,
      errors,
      touched,
      resolveValue,
      skipIndex,
      wizardSection,
      countries,
      contractId,
      locale,
      template,
      showOnlyUnfinished,
      saveArrayEditorValue,
      profileEditor,
      validateUntouched,
      crmPersonSearch,
      fulfillsCondition,
      isArrayQuestion,
      contractLanguage,
      textFieldAsWizardSection,
    } = this.props;

    let fieldsDone = 0;

    if (!fields) {
      return undefined;
    }

    const components = fields.map((field, index) => {
      let component;
      let arrayErrors;
      const fieldTypes: Types = getFieldType(field, schema);
      const fieldValue = resolveValue(field.key);
      const fieldTouched = touched.has(`${contractId}.${field.key}`);
      const fieldRequired = isRequired(schema, field.key);
      const error = _.find(errors, (e) => e.key === field.key);
      const errorText = error && (fieldTouched || validateUntouched) ? error.message : undefined;
      const fieldValid = fieldTouched ? !error : undefined;

      if (field.condition && !doesQuestionPassConditionTestToRender(values, field.condition, template.form.items)) {
        return undefined;
      }

      if (isFieldDone(contractId, touched, field, errors, values)) {
        fieldsDone++;
      }

      if (fieldValid && showOnlyUnfinished) {
        // Array Container
      } else if (fieldTypes.type === ARRAY && field.items) {
        arrayErrors = this.getArrayErrors(errors, field, values);
        component = (
          <ArrayQuestion
            errors={arrayErrors}
            field={field}
            value={fieldValue as ArrayEditorValues[]}
            schema={schema}
            required={fieldRequired}
            onFocus={onFocus}
            onBlur={onBlur}
            valid={this.isQuestionSetvalid(arrayErrors, fieldTouched)}
            openInfo={this.openDialog}
            index={skipIndex ? 0 : index + 1}
            key={index}
            types={fieldTypes}
            contractId={contractId}
            template={template}
            saveArrayEditorValue={saveArrayEditorValue}
            profileEditor={profileEditor}
            crmPersonSearch={crmPersonSearch}
            fulfillsCondition={fulfillsCondition}
            dependentQuestions={getDependentQuestions(field.key, template.form.items)}
            textFieldAsWizardSection={textFieldAsWizardSection}
          />
        );
      } else if (fieldTypes.type === CRM_PERSON_SEARCH) {
        arrayErrors = this.getArrayErrors(errors, field, values);
        component = (
          <PersonSearchQuestion
            field={field}
            value={fieldValue as FoundPerson}
            required={fieldRequired}
            onFocus={onFocus}
            onBlur={onBlur}
            valid={this.isQuestionSetvalid(arrayErrors, fieldTouched)}
            openInfo={this.openDialog}
            index={skipIndex ? 0 : index + 1}
            key={index}
            types={fieldTypes}
            locale={locale}
            profileEditor={profileEditor}
            crmPersonSearch={crmPersonSearch}
            onChange={onChange}
            contractLanguage={contractLanguage}
          />
        );
      } else if (fieldTypes.type === CONTACT_ID_SEARCH) {
        arrayErrors = this.getArrayErrors(errors, field, values);
        component = (
          <ContactIdSearchQuestion
            field={field}
            value={fieldValue as FoundPerson}
            required={fieldRequired}
            onFocus={onFocus}
            onBlur={onBlur}
            valid={this.isQuestionSetvalid(arrayErrors, fieldTouched)}
            openInfo={this.openDialog}
            index={skipIndex ? 0 : index + 1}
            key={index}
            types={fieldTypes}
            locale={locale}
            profileEditor={profileEditor}
            crmPersonSearch={crmPersonSearch}
            onChange={onChange}
            contractLanguage={contractLanguage}
          />
        );
        // Normal Field
      } else {
        component = (
          <Field
            field={field}
            error={errorText}
            types={fieldTypes}
            valid={fieldValid}
            value={fieldValue}
            index={skipIndex ? 0 : index + 1}
            required={fieldRequired}
            validate={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            wizardSection={wizardSection}
            schema={schema}
            key={index}
            countries={countries}
            openInfo={this.openDialog}
            locale={locale}
            fulfillsCondition={fulfillsCondition}
            dependentQuestions={getDependentQuestions(field.key, template.form.items)}
            template={template}
            contractLanguage={contractLanguage}
            isArrayQuestion={isArrayQuestion}
            textFieldAsWizardSection={textFieldAsWizardSection}
          />
        );
      }
      return component;
    });

    const visibleComponents = components.filter((c) => c !== undefined);
    const finished = (fieldsDone / visibleComponents.length) * 100;
    const actions = [
      <StyledButton variant="text" onClick={this.closeDialog} key={'Sulje'}>
        Sulje
      </StyledButton>,
    ];

    return (
      <section className={styles.fieldList}>
        {visibleComponents}
        <StyledSnackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          autoHideDuration={100000}
          open={finished === 100 && this.state.showNotification}
          message={translate('contract.moduleDone', { locale: contractLanguage })}
          onClose={this.onSnackbarClose}
        />
        <ModalDialog
          title={this.state.dialogTitle}
          open={this.state.isDialogOpen}
          actions={actions}
          content={this.state.dialogContent}
        />
      </section>
    );
  }
}
