import React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { Button, ButtonProps } from './Button';

interface LinkButtonProps extends ButtonProps {
  to: string;
}

export const LinkButton = ({
  to,
  label,
  secondary = false,
  className = '',
  disabled,
  small,
  bg,
  id,
  'data-testkey': dataTestKey,
  'data-secondary': dataSecondary,
}: LinkButtonProps) => {
  const button = (
    <Button
      label={label}
      secondary={secondary}
      className={className}
      bg={bg}
      small={small}
      data-testkey={dataTestKey}
      data-secondary={dataSecondary}
      disabled={disabled}
      id={id}
    />
  );

  return disabled ? button : <LinkNoUnderline to={to}>{button}</LinkNoUnderline>;
};

const LinkNoUnderline = styled(Link)`
  text-decoration: none;
  display: inline-flex;

  &&:hover {
    background: none;
    text-decoration: none;
  }
`;
