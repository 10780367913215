import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { ErrorDialog } from 'features/allocator/common/ErrorDialog';
import styles from './OrderSummaryView.scss';

interface Props {
  children: ReactElement;
}

export const OrderSummaryView = ({ children }: Props) => (
  <>
    <ErrorDialog />

    <StyledSection>
      <div className={styles.content}>{children}</div>
    </StyledSection>
  </>
);

const StyledSection = styled.section`
  padding-top: 50px;
`;
