// global actions used in multiple features
import { get } from 'lodash';
import {
  IDENTITY_METHOD_EXPIRATION_DATE,
  IDENTITY_METHOD,
  IDENTITY_METHOD_NUMBER,
  DESCRIPTION,
  IDENTITY_METHOD_ISSUER,
  IDENTITY_METHOD_ISSUER_COUNTRY,
  IDENTITY_METHOD_DATE_OF_ISSUE,
} from 'constants/newCustomer';
import { apiCall } from 'core/apiCall';
import { info } from 'config/info';
import { AppThunk } from 'types/types';
import { Banker, GetCountriesResponse, Info } from 'actions/indexActionsTypes';
import { getBankers, getCountries } from 'actions/indexActionsUtils';

/*
 * action types
 */

export const INIT_ARRAY_EDITOR_VALUES = 'INIT_ARRAY_EDITOR_VALUES';
export const SET_ARRAY_EDITOR_VALUES = 'SET_ARRAY_EDITOR_VALUES';
export const SAVE_ARRAY_EDITOR_VALUES = 'SAVE_ARRAY_EDITOR_VALUES';
export const DELETE_ARRAY_ANSWER = 'DELETE_ARRAY_ANSWER';

const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
const COUNTRIES_FAILURE = 'COUNTRIES_FAILURE';

const BANKERS_REQUEST = 'BANKERS_REQUEST';
export const BANKERS_SUCCESS = 'BANKERS_SUCCESS';
const BANKERS_FAILURE = 'BANKERS_FAILURE';
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const LOG_ERROR = 'LOG_ERROR';
const LOG_ERROR_REQUEST = 'LOG_ERROR_REQUEST';
const LOG_ERROR_SUCCESS = 'LOG_ERROR_SUCCESS';
const LOG_ERROR_FAILURE = 'LOG_ERROR_FAILURE';
export const CHANGE_CONTRACT_LANGUAGE = 'CHANGE_CONTRACT_LANGUAGE';

/*
 * action creators
 */

export function initArrayEditorValues(values: object) {
  return <const>{
    type: INIT_ARRAY_EDITOR_VALUES,
    values,
  };
}

export function setArrayEditorValue(key: string, value: string) {
  return <const>{
    type: SET_ARRAY_EDITOR_VALUES,
    key,
    value,
  };
}

export function saveArrayEditorValue(contractType: string, key: string, index: number) {
  return <const>{
    type: SAVE_ARRAY_EDITOR_VALUES,
    key,
    contractType,
    index,
  };
}

export function deleteArrayAnswer(contractType: string, key: string, index: number) {
  return <const>{
    type: DELETE_ARRAY_ANSWER,
    key,
    contractType,
    index,
  };
}

const getCountriesRequest = () => {
  return <const>{
    type: COUNTRIES_REQUEST,
  };
};

const getCountriesSuccess = (result: GetCountriesResponse) => {
  return <const>{
    type: COUNTRIES_SUCCESS,
    result,
  };
};

const getCountriesFailure = () => {
  return <const>{
    type: COUNTRIES_FAILURE,
  };
};

const getBankersRequest = () => {
  return <const>{
    type: BANKERS_REQUEST,
  };
};

const getBankersSuccess = (result: Banker[]) => {
  return <const>{
    type: BANKERS_SUCCESS,
    result,
  };
};

const getBankersFailure = () => {
  return <const>{
    type: BANKERS_FAILURE,
  };
};

export const showNotification = (message: string) => {
  return <const>{
    type: SHOW_NOTIFICATION,
    message,
  };
};

export const hideNotification = () => {
  return <const>{
    type: HIDE_NOTIFICATION,
  };
};

export const changeContractLanguage = (message: string) => {
  return <const>{
    type: CHANGE_CONTRACT_LANGUAGE,
    message,
  };
};

export type IndexAction = ReturnType<
  | typeof initArrayEditorValues
  | typeof setArrayEditorValue
  | typeof saveArrayEditorValue
  | typeof deleteArrayAnswer
  | typeof getCountriesRequest
  | typeof getCountriesSuccess
  | typeof getCountriesFailure
  | typeof getBankersRequest
  | typeof getBankersSuccess
  | typeof getBankersFailure
  | typeof showNotification
  | typeof hideNotification
  | typeof changeContractLanguage
>;

/*
 * async actions
 */

export function uploadFile(key: string, file: File, info: Info, customerId: string, token: string) {
  const fields = [
    ['customerId', customerId],
    [IDENTITY_METHOD, info.identityMethod ?? ''],
    [IDENTITY_METHOD_EXPIRATION_DATE, info.identityMethodExpirationDate ?? ''],
    [DESCRIPTION, info.description ?? ''],
    [IDENTITY_METHOD_NUMBER, info.identityMethodIdentificationNumber ?? ''],
    [IDENTITY_METHOD_ISSUER, info.identityMethodIssuer ?? ''],
    [IDENTITY_METHOD_ISSUER_COUNTRY, info.identityMethodIssuerCountry ?? ''],
    [IDENTITY_METHOD_DATE_OF_ISSUE, info.identityMethodDateOfIssue ?? ''],
  ];
  const attachment = {
    key,
    file,
  };
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/client/upload',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    fields,
    attachment,
  });
}

export function getInitialData(locale: string): AppThunk {
  return async (dispatch, getState) => {
    const token = getState().oidc.user.access_token;

    dispatch(getCountriesRequest());
    try {
      const result = await getCountries(locale, token);
      dispatch(getCountriesSuccess(result));
    } catch (error) {
      dispatch(getCountriesFailure());
    }

    dispatch(getBankersRequest());
    try {
      const result = await getBankers(token);
      dispatch(getBankersSuccess(result));
    } catch (error) {
      dispatch(getBankersFailure());
    }
  };
}

const postError = (data: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch({ type: LOG_ERROR_REQUEST });
    return apiCall({
      method: 'post',
      path: '/api/v1/logs/error',
      token: getState().oidc.user.access_token,
      contentType: 'text/plain',
      data,
    })
      .then(() => {
        dispatch({ type: LOG_ERROR_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: LOG_ERROR_FAILURE });
      });
  };
};

export const logError = (message: string, error: string): AppThunk => {
  return (dispatch, getState) => {
    const errorData = {
      message,
      info,
      url: window.location.href,
      stack: get(error, ['stack'], 'Stacktrace is missing'),
      state: getState(),
    };
    dispatch({
      type: LOG_ERROR,
      ...errorData,
    });
    dispatch(postError(JSON.stringify(errorData)));
  };
};
