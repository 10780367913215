import React from 'react';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { CompareToType, PlanLength, WeightType } from 'types/types';
import { Portfolio } from 'features/portfolioManager/components/Portfolio';
import { Grid } from 'features/allocator/common/Grid';

interface Props {
  portfolios: AllocatorPortfolio[];
  planLength: PlanLength;
  weightsToUse: WeightType;
  compareTo: CompareToType;
}

export const Portfolios = ({ portfolios, weightsToUse, planLength, compareTo }: Props) => (
  <Grid vertical>
    {portfolios?.map((portfolio) => (
      <Portfolio
        key={portfolio.portfolioId}
        planLength={planLength}
        weightsToUse={weightsToUse}
        compareTo={compareTo}
        portfolio={portfolio}
      />
    ))}
  </Grid>
);
