import React from 'react';
import classnames from 'classnames';
import translate from 'counterpart';
import SubQuestion from './SubQuestion';
import ArrayQuestionButton from './ArrayQuestionButton';
import styles from './SubQuestionSet.scss';
import { Contract, Question } from 'types/ordersState';
import { Countries, FormError, Locale, Schema } from 'types/contractsState';

export interface Props {
  openDialog: (answerIndex: number) => void;
  deleteItem: (index: number) => void;
  answer: Contract;
  questions: Question[];
  locale: Locale;
  field: {
    key: string;
  };
  schema: Schema;
  questionSetIndex: number;
  errors: FormError[];
  countries: Countries;
  hideControls?: boolean;
  hideDiscard?: boolean;
  onFocus?: () => void;
  crmPersonSearch?: () => void;
  contractLanguage: Locale;
}

const SubQuestionSet = (props: Props) => {
  const {
    questions,
    answer,
    locale,
    field,
    schema,
    countries,
    errors,
    questionSetIndex,
    openDialog,
    deleteItem,
    hideControls,
    hideDiscard,
    onFocus,
    crmPersonSearch,
    contractLanguage,
  } = props;
  const answerSet = questions.map((question) => (
    <SubQuestion
      question={question}
      answer={answer}
      locale={locale}
      field={field}
      questionSetIndex={questionSetIndex}
      schema={schema}
      errors={errors}
      countries={countries}
      key={question.key}
      // @ts-expect-error: prop does not exist in SubQuestion
      crmPersonSearch={crmPersonSearch}
      onFocus={onFocus}
      contractLanguage={contractLanguage}
    />
  ));
  return (
    <div className={classnames('row', styles.answerBlock)}>
      <div className="column small-1">
        <strong>{`${questionSetIndex + 1}.`}</strong>
      </div>
      <div className={hideControls ? 'columns small-10' : 'columns small-8'}>{answerSet}</div>
      {hideControls ? (
        false
      ) : (
        <div className={classnames('columns small-3', styles.editBtnContainer)}>
          <ArrayQuestionButton
            label={translate('newContract.edit', { locale: contractLanguage })}
            onClick={openDialog}
            index={questionSetIndex}
            className={classnames('button small float-right', styles.editBtn)}
          />
          {hideDiscard ? (
            false
          ) : (
            <ArrayQuestionButton
              label={translate('newContract.discard', { locale: contractLanguage })}
              onClick={deleteItem}
              index={questionSetIndex}
              className={classnames('button small float-right', styles.editBtn)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SubQuestionSet;
