import superagent, { Response, ResponseError } from 'superagent';
import Promise from 'bluebird';
import { config } from 'config';
import { getStore } from 'stores/store';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

type Options = {
  data?: object | string;
  method: HttpMethod;
  params?: object;
  path: string;
  fields?: string[];
  attachment?: {
    key: string;
    file: {
      name: string;
    };
  };
  responseType?: string;
  responseResolver?: (response: { xhr: XMLHttpRequest }) => {};
  token?: string;
  contentType?: string;
};

export function formatUrl(path: string) {
  const adjustedPath = path[0] !== '/' ? `/${path}` : path;
  return config.baseUrl + adjustedPath;
}

export function showBlob(newWindow: Window, blob: Blob) {
  makeBlobUrl(blob, (url: string) => (newWindow.location.href = url));
}

export function makeBlobUrl(blob: Blob, onSuccess: (url: string) => {}) {
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  if (navigator.userAgent.match('CriOS')) {
    const reader = new FileReader();
    reader.onload = () => {
      onSuccess(reader.result as string);
    };
    reader.readAsDataURL(blob);
  } else {
    onSuccess(window.URL.createObjectURL(blob));
  }
}

const getRequest = (method: HttpMethod, path: string) => {
  if (method === 'get') {
    return superagent.get(formatUrl(path));
  }
  if (method === 'post') {
    return superagent.post(formatUrl(path));
  }
  if (method === 'put') {
    return superagent.put(formatUrl(path));
  }
  if (method === 'patch') {
    return superagent.patch(formatUrl(path));
  }
  if (method === 'delete') {
    return superagent.delete(formatUrl(path));
  }
};

const getAuthToken = () => {
  const store = getStore();
  const state = store.getState();
  return state.oidc.user.access_token;
};

const _apiCall = ({
  method,
  path,
  params,
  data,
  fields,
  attachment,
  responseType,
  responseResolver,
  token = getAuthToken(),
  contentType,
}: Options) => {
  return new Promise((resolve, reject, onCancel) => {
    // if no access token then throw
    if (!token) {
      throw new Error(`No access token given in options.token for request options ${path}`);
    }

    const request = getRequest(method, path);

    if (!request) {
      throw new Error(`Error forming request: ${method} ${path}`);
    }

    void request.set('Authorization', `Bearer ${token}`);

    if (params) {
      void request.query(params);
    }

    if (data) {
      void request.send(data);
    }

    if (fields) {
      fields.map((f) => request.field(f[0], f[1]));
    }

    if (attachment) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      void request.attach(attachment.key, attachment.file, attachment.file.name);
    }

    if (responseType) {
      void request.responseType(responseType);
    }

    if (contentType) {
      void request.type(contentType);
    }

    request.end((err: ResponseError, response: Response) => {
      if (response?.status === 204) {
        return resolve(response?.status);
      }

      if (err && err.status === 401) {
        // handleExpiredSession();
      }

      return err
        ? reject(response?.body ? new Error(response.body) : err)
        : resolve(responseResolver ? responseResolver(response) : response.body);
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    onCancel(() => {
      request.abort();
    });
  });
};

export const apiCall = (options: Options) => {
  return _apiCall(options);
};
