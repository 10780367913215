import cloneDeep from 'lodash/cloneDeep';
import { createSearchParams, createPageParams } from 'core/functions';
import PromiseStore from 'core/PromiseStore';
import { apiCall, showBlob } from 'core/apiCall';
import { AppThunk } from 'types/types';
import { CreatePageParams, CreateSearchParams } from 'core/functionsTypes';
import { ContractListSearchFilter } from 'types/contractListState';
import {
  deleteSavedFilterApiCall,
  getContractsApiCall,
  getSavedontractFilters,
  postFilters,
} from 'features/contractList/contractListUtils';
import {
  GetContractsApiCallResponse,
  GetSavedontractFiltersResponse,
  PostFiltersResponse,
} from 'features/contractList/contractList.types';

// Action types
export const CONTRACTS_REQUEST = 'CONTRACTS_REQUEST';
export const CONTRACTS_SUCCESS = 'CONTRACTS_SUCCESS';
export const CONTRACTS_FAILURE = 'CONTRACTS_FAILURE';
export const SET_CONTRACTS_SEARCH_FILTERS = 'SET_CONTRACTS_SEARCH_FILTERS';
export const MERGE_CONTRACTS_SEARCH_FILTERS = 'MERGE_CONTRACTS_SEARCH_FILTERS';
const SAVED_CONTRACTS_FILTERS_REQUEST = 'SAVED_CONTRACTS_FILTERS_REQUEST';
export const SAVED_CONTRACTS_FILTERS_SUCCESS = 'SAVED_CONTRACTS_FILTERS_SUCCESS';
const ADD_SAVED_CONTRACTS_FILTER_REQUEST = 'ADD_SAVED_CONTRACTS_FILTER_REQUEST';
export const ADD_SAVED_CONTRACTS_FILTER_SUCCESS = 'ADD_SAVED_CONTRACTS_FILTER_SUCCESS';
const ADD_SAVED_CONTRACTS_FILTER_FAILURE = 'ADD_SAVED_CONTRACTS_FILTER_FAILURE';
const DELETE_SAVED_CONTRACTS_FILTERS_REQUEST = 'DELETE_SAVED_CONTRACTS_FILTERS_REQUEST';
const DELETE_SAVED_CONTRACTS_FILTERS_SUCCESS = 'DELETE_SAVED_CONTRACTS_FILTERS_SUCCESS';
const DELETE_SAVED_CONTRACTS_FILTERS_FAILURE = 'DELETE_SAVED_CONTRACTS_FILTERS_FAILURE';
const SAVE_CONTRACTS_FILTERS = 'SAVE_CONTRACTS_FILTERS';
export const DELETE_SAVED_CONTRACTS_FILTER = 'DELETE_SAVED_CONTRACTS_FILTER';
export const CONTRACTS_PDF_DOC_REQUEST = 'CONTRACTS_PDF_DOC_REQUEST';
export const CONTRACTS_PDF_DOC_SUCCESS = 'CONTRACTS_PDF_DOC_SUCCESS';
export const CONTRACTS_PDF_DOC_FAILURE = 'CONTRACTS_PDF_DOC_FAILURE';

const contractsRequest = () => {
  return <const>{
    type: CONTRACTS_REQUEST,
  };
};

const contractsFailure = (error: Error) => {
  return <const>{
    type: CONTRACTS_FAILURE,
    error,
  };
};

const contractsSuccess = (result: GetContractsApiCallResponse) => {
  return <const>{
    type: CONTRACTS_SUCCESS,
    result,
  };
};

export function getContracts(params = {}): AppThunk {
  const searchParams = createSearchParams(params as CreateSearchParams);
  const pageParams = createPageParams(params as CreatePageParams);
  const queryParams = Object.assign(
    {
      ignoreContractMainTypes: 'order',
    },
    searchParams,
    pageParams
  );
  return (dispatch, getState) => {
    const state = getState();
    dispatch(contractsRequest());
    PromiseStore.search.cancel();
    // @ts-expect-error: PromiseStore typing missing
    PromiseStore.search = getContractsApiCall(queryParams, state.oidc.user.access_token)
      .then((result) => {
        dispatch(contractsSuccess(result));
      })
      .catch((e) => dispatch(contractsFailure(e)));
  };
}

const setContractsSearchFilters = (searchFilters: ContractListSearchFilter) => {
  return <const>{
    type: SET_CONTRACTS_SEARCH_FILTERS,
    searchFilters,
  };
};

export function setSearchFilters(filters: ContractListSearchFilter): AppThunk {
  return (dispatch, getState) => {
    const searchFilters = cloneDeep(filters);
    delete searchFilters.searchName;
    dispatch(setContractsSearchFilters(searchFilters));
    const state = getState().contractList;
    return dispatch(
      getContracts({
        skip: 0,
        take: state.searchTake,
        searchFilters: state.searchFilters.toJS(),
      })
    );
  };
}

export function setSearchString(searchString: string) {
  return <const>{
    type: MERGE_CONTRACTS_SEARCH_FILTERS,
    searchFilters: {
      searchString,
    },
  };
}

export function setInstrumentKeywords(instrumentKeywords: string[]) {
  return <const>{
    type: MERGE_CONTRACTS_SEARCH_FILTERS,
    searchFilters: {
      instrumentKeywords,
    },
  };
}

const mergeContractsSearchFilters = (searchFilters: ContractListSearchFilter) => {
  return <const>{
    type: MERGE_CONTRACTS_SEARCH_FILTERS,
    searchFilters,
  };
};

export function mergeSearchFilters(searchFilters: ContractListSearchFilter): AppThunk {
  return (dispatch, getState) => {
    dispatch(mergeContractsSearchFilters(searchFilters));
    const state = getState().contractList;
    return dispatch(
      getContracts({
        skip: 0,
        take: state.searchTake,
        searchFilters: state.searchFilters.toJS(),
      })
    );
  };
}

const savedContractsFiltersRequest = () => {
  return <const>{
    type: SAVED_CONTRACTS_FILTERS_REQUEST,
  };
};

const savedContractsFiltersSuccess = (result: GetSavedontractFiltersResponse) => {
  return <const>{
    type: SAVED_CONTRACTS_FILTERS_SUCCESS,
    result,
  };
};

export function fetchSavedContractFilters(): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(savedContractsFiltersRequest());

    try {
      const result = await getSavedontractFilters(state.oidc.user.profile.sub, state.oidc.user.access_token);
      dispatch(savedContractsFiltersSuccess(result));
    } catch (error) {
      console.error(error); // eslint-disable-line no-console
    }
  };
}

const saveContractsFilters = (filters: ContractListSearchFilter) => {
  return <const>{
    type: SAVE_CONTRACTS_FILTERS,
    filters,
  };
};

const addSavedContractsFilterRequest = () => {
  return <const>{
    type: ADD_SAVED_CONTRACTS_FILTER_REQUEST,
  };
};

const addSavedContractsFilterSuccess = (result: PostFiltersResponse) => {
  return <const>{
    type: ADD_SAVED_CONTRACTS_FILTER_SUCCESS,
    result,
  };
};

const addSavedContractsFilterFailure = () => {
  return <const>{
    type: ADD_SAVED_CONTRACTS_FILTER_FAILURE,
  };
};

export function saveFilters(filters: ContractListSearchFilter): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(saveContractsFilters(filters));
    dispatch(setSearchFilters(filters));

    dispatch(addSavedContractsFilterRequest());

    try {
      const result = await postFilters(filters, state.oidc.user.access_token, state.oidc.user.profile.sub);
      dispatch(addSavedContractsFilterSuccess(result));
    } catch (error) {
      dispatch(addSavedContractsFilterFailure());
    }
  };
}

const deleteSavedContractsFilter = (id: string) => {
  return <const>{
    type: DELETE_SAVED_CONTRACTS_FILTER,
    id,
  };
};

const deleteSavedContractsFiltersRequest = () => {
  return <const>{
    type: DELETE_SAVED_CONTRACTS_FILTERS_REQUEST,
  };
};

const deleteSavedContractsFiltersSuccess = () => {
  return <const>{
    type: DELETE_SAVED_CONTRACTS_FILTERS_SUCCESS,
  };
};

const deleteSavedContractsFiltersFailure = () => {
  return <const>{
    type: DELETE_SAVED_CONTRACTS_FILTERS_FAILURE,
  };
};

export function deleteSavedFilter(id: string): AppThunk {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(deleteSavedContractsFiltersRequest());

    try {
      await deleteSavedFilterApiCall(id, state.oidc.user.profile.sub, state.oidc.user.access_token);
      dispatch(deleteSavedContractsFiltersSuccess());
    } catch (error) {
      dispatch(deleteSavedContractsFiltersFailure());
    }
  };
}

const contractsPdfDocRequest = (id: string) => {
  return <const>{
    type: CONTRACTS_PDF_DOC_REQUEST,
    id,
  };
};

const contractsPdfDocSuccess = () => {
  return <const>{
    type: CONTRACTS_PDF_DOC_SUCCESS,
  };
};

const contractsPdfDocFailure = (error: Error) => {
  return <const>{
    type: CONTRACTS_PDF_DOC_FAILURE,
    error,
  };
};

export function getContractPdf(id: string): AppThunk {
  return (dispatch, getState) => {
    const newWindow = window.open();
    dispatch(contractsPdfDocRequest(id));
    return apiCall({
      method: 'get',
      token: getState().oidc.user.access_token,
      path: `/api/v1/contract/pdffile/${id}`,
      responseType: 'blob',
      responseResolver: (res) => res.xhr.response,
    })
      .then((blob) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        showBlob(newWindow, blob);
        dispatch(contractsPdfDocSuccess());
      })
      .catch((e) => dispatch(contractsPdfDocFailure(e)));
  };
}

export type ContractListAction = ReturnType<
  | typeof contractsRequest
  | typeof contractsFailure
  | typeof contractsSuccess
  | typeof setContractsSearchFilters
  | typeof mergeContractsSearchFilters
  | typeof savedContractsFiltersRequest
  | typeof savedContractsFiltersSuccess
  | typeof deleteSavedContractsFilter
  | typeof deleteSavedContractsFiltersRequest
  | typeof deleteSavedContractsFiltersSuccess
  | typeof deleteSavedContractsFiltersFailure
  | typeof contractsPdfDocRequest
  | typeof contractsPdfDocSuccess
  | typeof contractsPdfDocFailure
  | typeof setInstrumentKeywords
  | typeof setSearchString
  | typeof saveContractsFilters
  | typeof addSavedContractsFilterRequest
  | typeof addSavedContractsFilterSuccess
  | typeof addSavedContractsFilterFailure
>;
