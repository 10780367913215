import { fromJS } from 'immutable';
import { createSelector } from 'reselect';
import {
  selectIsIndividualFullPowerOfAttorneyByPortfolioId,
  /*selectCurrentPortfolioById,*/
  selectIsInsurancePortfolioById,
  selectHasRestrictedPortfolioClassById,
  selectCurrentPortfolio,
} from 'features/portfolio/portfolioSelectors';
import {
  SENT_FOR_BANKER_APPROVAL,
  SENT_FOR_INK_SIGNING,
  SENT_FOR_SIGNING,
  SENT_FOR_MANUAL_SIGNING,
} from 'constants/contractStates';
import { FUND, insuranceFormsWithBankerApproval, InstrumentForm } from 'constants/instrumentForms';
import { BUY, SELL } from 'constants/sides';
import { isIndividualStrategy, isListed, isTaaleriProduct } from 'core/portfolios';
import {
  selectCurrentSideByOrderLineId,
  selectOrderLines,
  selectHasOnlySellOrdersByPortfolioId,
  selectHasOrdersByPortfolioId,
  selectOrderLinesByPortfolioId,
  selectOrderReceivedWrittenByPortfolioId,
  selectOrderLinesHaveReceivedWritten,
  selectAreOrderLinesListed,
  selectContractOrderLinesByOrderLineIds,
  selectContractOrderLinesByPortfolioId,
  selectContractOrderLines,
  //selectIsCurrentInstrumentListedByOrderLineId,
  selectCurrentInstrumentFormByOrderLineId,
} from 'features/orderLines/orderLinesSelectors';
import {
  CONSULTATIVE_AGREEMENT,
  FULL_POWER_OF_ATTORNEY,
  ORDER_CONTRACT_TYPES,
  WRITTEN_ORDER_REQUIRES_SIGNATURE,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
} from 'constants/contractNames';
import { RootState } from 'types/rootState';
import { ImmutableContract, ImmutableOrderLineType, OrderLineType, ReceiveInfo } from 'types/ordersState';
import {
  ShowHasSeenDocumentsToggle,
  PortfolioStrategy,
  PortfolioContractName,
  IsAdviceDeliveredAfterwards,
  IsAdviceDeliveryVisible,
  IsOrderConfirmed,
  InstrumentsListed,
  SignatureMethodByPortfolioId,
  CustomerHasSeenDocuments,
  OrderConfirmed,
} from 'types/portfolioPreviewTypes';
import { SignatureMethodType } from 'types/types';
import { OrderSideType } from 'types/orderDialogState';
import {
  ACCORDING_TO_INVESTMENT_ADVICE,
  AGAINST_INVESTMENT_ADVICE,
  CLIENT_INITIATIVE,
} from '/constants/receiveInfoOptions';

function selectMultipleContracts(state: RootState) {
  const portfolioIds = selectPortfolioIdsWithOrders(state);
  const contracts = portfolioIds.map((portfolioId) => state.orders.ordersByPortfolioId[portfolioId]?.contract?.toJS());
  return contracts;
}

export function selectMultiplePortfolioIds(state: RootState) {
  const portfolioIds = selectPortfolioIdsWithOrders(state);
  const contracts = portfolioIds.map((portfolioId) => state.orders.ordersByPortfolioId[portfolioId]);
  return contracts;
}

export const selectPortfolioIdsWithOrders = (state: RootState): string[] => {
  const portfolioIds = selectOrderLines(state).map((orderLine) => orderLine?.toJS().portfolioId);
  const portfolioIdsWithOrders = portfolioIds
    .toArray()
    .filter((item, index) => portfolioIds.toArray().indexOf(item) === index)
    .filter(Boolean) as string[];
  return portfolioIdsWithOrders;
};

export const selectContractWithRowsByOrderLineIds = (
  state: RootState,
  portfolioId: string,
  orderLinesIds: number[]
): ImmutableContract => {
  const contract = state.orders.ordersByPortfolioId[portfolioId].contract;
  const orderLines = selectContractOrderLinesByOrderLineIds(state, orderLinesIds, portfolioId);
  return contract.setIn(['sections', 'order', 'orderLines'], orderLines) as ImmutableContract;
};

export const selectOrderDetails = (state: RootState, portfolioid: string) =>
  state.orders.ordersByPortfolioId[portfolioid]?.orderDetails;

export const selectAllOrderDetails = (state: RootState) => {
  const portfolioIds = Object.keys(state.orders.ordersByPortfolioId).filter(
    (portfolioId) => state.orders.ordersByPortfolioId[portfolioId]?.orderDetails.length > 0
  );
  return portfolioIds.map((x) => {
    return {
      portfolioId: x,
      orderDetails: state.orders.ordersByPortfolioId[x]?.orderDetails,
    };
  });
};

export const selectAllOrders = (state: RootState) => {
  return selectPortfolioIdsWithOrders(state).map((portfolioId) => {
    const orderLines = selectContractOrderLinesByPortfolioId(state, portfolioId);
    const contract = state.orders.ordersByPortfolioId[portfolioId]?.contract?.setIn(
      ['sections', 'order', 'orderLines'],
      orderLines
    );

    const order = state.orders.ordersByPortfolioId[portfolioId];
    order.contract = fromJS(contract);

    return { portfolioId, order };
  });
};

const selectSignatureMethodsSelector = (state: RootState): SignatureMethodByPortfolioId[] => {
  return Object.keys(state.orders.ordersByPortfolioId).reduce((acc, portfolioId) => {
    state.orders.ordersByPortfolioId[portfolioId]?.orderDetails.map((details) => {
      acc.push({
        portfolioId,
        signatureMethod: details.signatureMethod,
      });
    });
    return acc;
  }, [] as SignatureMethodByPortfolioId[]);
};

const selectCustomerHasSeenDocuments = (state: RootState): CustomerHasSeenDocuments[] => {
  return Object.keys(state.orders.ordersByPortfolioId).reduce((acc, portfolioId) => {
    acc.push({
      portfolioId,
      customerHasSeenDocuments:
        state.orders.ordersByPortfolioId[portfolioId].contract.toJS().sections?.receiveInfo.customerHasSeenDocuments,
    });
    return acc;
  }, [] as CustomerHasSeenDocuments[]);
};

const selectSignatureMethodSelectorByPortfolioId = (state: RootState, portfolioId: string) =>
  state.orders.ordersByPortfolioId[portfolioId]?.orderDetails.map((x) => {
    return { signatureMethod: x.signatureMethod, orderLineIds: x.orderLineIds };
  });

export const selectCreatingContracts = (state: RootState) =>
  selectPortfolioIdsWithOrders(state).every((x) =>
    state.orders.ordersByPortfolioId[x]?.orderDetails.length > 0
      ? state.orders.ordersByPortfolioId[x].orderDetails.every((y) => y.creatingContract)
      : false
  );

export const selectSignatoriesByPortfolioId = (state: RootState, portfolioId: string) =>
  state.orders.ordersByPortfolioId[portfolioId]?.signatories;

export const selectSignatoriesPreview = (state: RootState) => {
  return Object.keys(state.orders.ordersByPortfolioId).map((portfolioId) => {
    return { portfolioId, signatories: state.orders.ordersByPortfolioId[portfolioId]?.signatories };
  });
};

export const selectReceiveInfo = (contract: ImmutableContract): ReceiveInfo =>
  contract?.getIn(['sections', 'receiveInfo'])?.toJS();

export const hasOnlySellOrders = (contract: ImmutableContract) =>
  contract
    ?.getIn(['sections', 'order', 'orderLines'])
    .every((order: ImmutableOrderLineType) => order.get('side') === SELL);

export const hasBuyOrders = (contract: ImmutableContract) =>
  contract
    ?.getIn(['sections', 'order', 'orderLines'])
    .some((order: ImmutableOrderLineType) => order.get('side') === BUY);

const selectIsAdviceDeliveredAfterwardsByPortfolioId = (state: RootState, portfolioId: string) => {
  return state.orders.ordersByPortfolioId[portfolioId]?.contract?.getIn([
    'sections',
    'receiveInfo',
    'adviceDeliveryAfterwards',
  ]);
};

const selectAreAdvicesDeliveredAfterwards = (state: RootState): IsAdviceDeliveredAfterwards[] => {
  return selectMultipleContracts(state).map((contract) => {
    return {
      portfolioId: fromJS(contract)?.getIn(['sections', 'receiveInfo', 'portfolioId']),
      isAdviceDeliveredAfterwards: fromJS(contract)?.getIn(['sections', 'receiveInfo', 'adviceDeliveryAfterwards']),
    };
  });
};

const selectAreInstrumentsListed = (state: RootState): InstrumentsListed[] => {
  const portfolioIds = Object.keys(state.orders.ordersByPortfolioId);

  return portfolioIds.reduce((acc, portfolioId) => {
    const orderDetailsList = state.orders.ordersByPortfolioId[portfolioId].orderDetails;

    orderDetailsList.map((orderDetails) => {
      orderDetails.orderLineIds.find((orderLineId) => {
        const currentInstrumentForm = selectCurrentInstrumentFormByOrderLineId(state, orderLineId);
        const currentSide = selectCurrentSideByOrderLineId(state, orderLineId);
        acc.push({
          portfolioId,
          orderLineId,
          instrumentsListed: isListed(currentInstrumentForm, currentSide),
        });
      });
    });

    return acc;
  }, [] as InstrumentsListed[]);
};

const selectAreOrdersConfirmedWithPortfolioId = (state: RootState): OrderConfirmed[] => {
  return Object.keys(state.orders.ordersByPortfolioId).reduce((acc, portfolioId) => {
    acc.push({
      portfolioId,
      orderConfirmed:
        state.orders.ordersByPortfolioId[portfolioId].contract.toJS().sections?.receiveInfo.isOrderConfirmed,
    });
    return acc;
  }, [] as OrderConfirmed[]);
};

const selectAreOrdersConfirmed = createSelector(selectMultipleContracts, (contracts): boolean[] =>
  contracts?.map((contract) => fromJS(contract)?.getIn(['sections', 'receiveInfo', 'isOrderConfirmed']))
);

export const selectIsOrderConfirmedByPortfolioId = (state: RootState): IsOrderConfirmed[] => {
  return selectPortfolioIdsWithOrders(state).map((portfolioId) => {
    return {
      portfolioId,
      isOrderConfirmed: state.orders.ordersByPortfolioId[portfolioId]?.contract?.getIn([
        'sections',
        'receiveInfo',
        'isOrderConfirmed',
      ]),
    };
  });
};

const selectIsBasisOfAdviceSet = createSelector(selectMultipleContracts, (contracts): string[] =>
  contracts?.map((contract) => fromJS(contract)?.getIn(['sections', 'order', 'basisOfAdvice']))
);

const getInstrumentsListed = (areInstrumentsListed: InstrumentsListed[]) => {
  const all = areInstrumentsListed.every((i) => i.instrumentsListed);
  const none = areInstrumentsListed.every((i) => !i.instrumentsListed);
  const mixed =
    areInstrumentsListed.some((i) => i.instrumentsListed) && areInstrumentsListed.some((i) => !i.instrumentsListed);

  if (all) {
    return 'all';
  }

  if (none) {
    return 'none';
  }

  if (mixed) {
    return 'mixed';
  }
};

const areOrdersValid = (state: RootState) => {
  const orderLines = selectOrderLines(state).toJS();

  if (!orderLines.length) {
    return false;
  }

  const customerHasSeenDocuments = selectCustomerHasSeenDocuments(state);
  const areAdviceDeliveredAfterwards = selectAreAdvicesDeliveredAfterwards(state);
  const areOrdersConfirmed = selectAreOrdersConfirmedWithPortfolioId(state);
  const signatureMethods = selectSignatureMethodsSelector(state);
  const areInstrumentsListed = selectAreInstrumentsListed(state);
  const isBasisOfAdviceRequired = selectIsBasisOfAdviceRequiredByPortfolioId(state);
  const isBasisOfAdviceSet = selectIsBasisOfAdviceSet(state);
  const portfolioIds = state.orderLines.orderLines.toJS().map((orderLine) => orderLine.portfolioId);

  const ordersValid = portfolioIds.map((portfolioId) => {
    const portfolioContractName = state.orders.ordersByPortfolioId[portfolioId].portfolioContractName;
    const onlyAdvice = !selectHasOrdersByPortfolioId(state, portfolioId);

    const instrumentsForPortfolio = areInstrumentsListed.filter((i) => i.portfolioId === portfolioId);
    const instrumentsListed = getInstrumentsListed(instrumentsForPortfolio);

    const signatureMethodsForPortfolio = signatureMethods.filter((s) => s.portfolioId === portfolioId);
    const signatureMethodBankerApproval = !!signatureMethodsForPortfolio.every(
      (i) => i.signatureMethod === SENT_FOR_BANKER_APPROVAL
    );

    const ordersConfirmed = !!areOrdersConfirmed.find((i) => i.portfolioId === portfolioId && i.orderConfirmed);
    const adviceDeliveryAfterwards = !!areAdviceDeliveredAfterwards.find(
      (i) => i.portfolioId === portfolioId && i.isAdviceDeliveredAfterwards
    );

    const customerHasSeenPortfolioDocuments = !!customerHasSeenDocuments.find(
      (i) => i.portfolioId === portfolioId && i.customerHasSeenDocuments
    );

    let isOrderValid = false;
    if (portfolioContractName && ORDER_CONTRACT_TYPES.includes(portfolioContractName) && ordersConfirmed) {
      // Consultative portfolios can include advices, advices have no additional requirements
      if (onlyAdvice) {
        return { portfolioId, orderValid: true };
      }

      // OrderLines in portfolio have only listed instruments
      if (instrumentsListed === 'all') {
        // Required checkbox
        if (adviceDeliveryAfterwards) {
          isOrderValid = true;
        }
        isOrderValid = validateBasisOfAdvice(isBasisOfAdviceRequired, isBasisOfAdviceSet, isOrderValid);
      }

      // OrderLines in portfolio have only non-listed instruments
      if (instrumentsListed === 'none') {
        // Required checkbox or ...
        if (customerHasSeenPortfolioDocuments) {
          isOrderValid = true;
        }

        // ... banker approves the order
        if (signatureMethodBankerApproval) {
          isOrderValid = true;
        }
        isOrderValid = validateBasisOfAdvice(isBasisOfAdviceRequired, isBasisOfAdviceSet, isOrderValid);
      }

      // OrderLines in portfolio have mix of listed and non-listed instruments
      if (instrumentsListed === 'mixed') {
        if (adviceDeliveryAfterwards && signatureMethodBankerApproval) {
          isOrderValid = true;
        }

        if (adviceDeliveryAfterwards && customerHasSeenPortfolioDocuments) {
          isOrderValid = true;
        }
        isOrderValid = validateBasisOfAdvice(isBasisOfAdviceRequired, isBasisOfAdviceSet, isOrderValid);
      }
    }
    return { portfolioId, orderValid: isOrderValid };
  });

  if (ordersValid.length && ordersValid.every((order) => order.orderValid)) {
    return true;
  }
  return false;
};

const validateBasisOfAdvice = (
  isBasisOfAdviceRequired: { portfolioId: string; isBasisOfAdviceRequired: boolean }[],
  isBasisOfAdviceSet: string[],
  isOrderValid: boolean
) => {
  if (isBasisOfAdviceRequired && isOrderValid) {
    const actuallyRequired = isBasisOfAdviceRequired.every((i) => i.isBasisOfAdviceRequired === true);
    if (actuallyRequired) {
      const allSet = isBasisOfAdviceSet.every((i) => i !== undefined && i !== null && i !== '');
      if (!allSet) {
        isOrderValid = false;
      }
    }
  }
  return isOrderValid;
};

export const selectPortfolioContractNameByPortfolioId = (state: RootState, portfolioId: string | undefined) =>
  portfolioId && state.orders.ordersByPortfolioId[portfolioId]?.portfolioContractName;

export const selectPortfolioContractNames = (state: RootState): PortfolioContractName[] =>
  selectPortfolioIdsWithOrders(state)
    .map((x) => {
      return {
        portfolioId: x,
        portfolioContractName: state.orders.ordersByPortfolioId[x].portfolioContractName,
      };
    })
    .filter(Boolean) as PortfolioContractName[];

const selectPortfolioStrategyById = (state: RootState, portfolioId: string) =>
  state.orders.ordersByPortfolioId[portfolioId]?.portfolioStrategy;

export const selectPortfolioStrategies = (state: RootState): PortfolioStrategy[] =>
  selectPortfolioIdsWithOrders(state)
    .map((x) => {
      return {
        portfolioId: x,
        portfolioStrategy: state.orders.ordersByPortfolioId[x].portfolioStrategy,
      };
    })
    .filter(Boolean) as PortfolioStrategy[];

const areSignatureInfosValid = (state: RootState) => {
  const portfolioIds = selectPortfolioIdsWithOrders(state);
  const orders = portfolioIds.map((id) => state.orders.ordersByPortfolioId[id]);

  if (orders.length === 0 || orders?.some((o) => !o?.orderDetails.every((details) => details.signatureMethod))) {
    return false;
  }

  // Applies to all orders: signatories not empty or signature method is not SENT_FOR_SIGNING or SENT_FOR_INK_SIGNING
  return orders?.every(
    (o) =>
      !o?.signatories.isEmpty() ||
      o?.orderDetails.every(
        (details) => details.signatureMethod !== SENT_FOR_SIGNING && details.signatureMethod !== SENT_FOR_INK_SIGNING
      )
  );
};

export const canCreateOrders = createSelector(
  selectCreatingContracts,
  areOrdersValid,
  areSignatureInfosValid,
  selectAreOrdersConfirmed,
  (creatingContracts, ordersValid, areSignatureInfosValid, ordersConfirmed) => {
    const allConfirmed = ordersConfirmed.every((i) => i);

    if (!creatingContracts && areSignatureInfosValid && allConfirmed && ordersValid) {
      return true;
    }

    return false;
  }
);

export const selectIsBasisOfAdviceRequiredByPortfolioId = (state: RootState) => {
  return selectPortfolioIdsWithOrders(state).map((portfolioId) => {
    return {
      portfolioId,
      isBasisOfAdviceRequired:
        ((selectPortfolioContractNameByPortfolioId(state, portfolioId) === CONSULTATIVE_AGREEMENT ||
          selectPortfolioContractNameByPortfolioId(state, portfolioId) === CUSTOMER_RELATIONSHIP_AGREEMENT) &&
          selectOrderLinesByPortfolioId(state, portfolioId).some((line) => !isTaaleriProduct(line?.get('issuer'))) &&
          selectOrderLinesByPortfolioId(state, portfolioId).some(
            (line) => line?.get('orderBasis') !== CLIENT_INITIATIVE
          )) ||
        selectPortfolioContractNameByPortfolioId(state, portfolioId) === FULL_POWER_OF_ATTORNEY,
    };
  });
};

const selectIsAccordingOrAgainstToAdvice = (state: RootState) =>
  isAccordingOrAgainstAdvice(state.orderDialog.editor.get('orderBasis'));

const isAccordingOrAgainstAdvice = (value: string) =>
  value === ACCORDING_TO_INVESTMENT_ADVICE || value === AGAINST_INVESTMENT_ADVICE;

const selectIsAccordingOrAgainstToAdviceInSomeOrder = (state: RootState) => {
  return !!state.orderLines.orderLines.toJS().find((line) => isAccordingOrAgainstAdvice(line.orderBasis));
};

const selectBasisOfAdviceFieldRequiredByFormSelection = createSelector(
  selectIsAccordingOrAgainstToAdvice,
  (isAccordingOrAgainstToAdvice) => {
    return isAccordingOrAgainstToAdvice;
  }
);

const selectCurrentPortfolioSelector = (state: RootState) => {
  return selectCurrentPortfolio(state) ? selectCurrentPortfolio(state) : false;
};

const selectIfSomeOrderFromFullPowerOfAttorneyPortfolio = (state: RootState) => {
  return !!state.orderLines.orderLines.find((line) => {
    if (!line || !line.get('portfolioId') || !state.orders.ordersByPortfolioId[line.get('portfolioId')]) {
      return false;
    }
    return state.orders.ordersByPortfolioId[line.get('portfolioId')].portfolioContractName === FULL_POWER_OF_ATTORNEY;
  });
};

export const selectIsBasisOfAdviceFieldRequired = createSelector(
  selectIsBasisOfAdviceRequiredByPortfolioId,
  selectBasisOfAdviceFieldRequiredByFormSelection,
  selectCurrentPortfolioSelector,
  selectIfSomeOrderFromFullPowerOfAttorneyPortfolio,
  (
    basisOfAdvicesByPortfolio,
    isRequiredByFromSelection,
    currentPortfolio,
    someOrderFromFullPowerOfAttorneyPortfolio
  ) => {
    return (
      !!basisOfAdvicesByPortfolio.find((portfolio) => portfolio.isBasisOfAdviceRequired) ||
      isRequiredByFromSelection ||
      currentPortfolio.contractName === FULL_POWER_OF_ATTORNEY ||
      someOrderFromFullPowerOfAttorneyPortfolio
    );
  }
);

export const selectShowSuitabilityToDateCheck = createSelector(
  selectIsAccordingOrAgainstToAdvice,
  selectIsAccordingOrAgainstToAdviceInSomeOrder,
  (isAccordingOrAgainstToAdvice, isAccordingOrAgainstToAdviceInSomeOrder) => {
    return isAccordingOrAgainstToAdvice || isAccordingOrAgainstToAdviceInSomeOrder;
  }
);

export const selectSuitabilityToDateCheckRequiredAndNotSet = createSelector(
  selectShowSuitabilityToDateCheck,
  (state: RootState) => state.orderDialog.suitabilityAssessmentToDate,
  (required, set) => required && !set
);

export const selectFinishedCreatingOrders = (state: RootState) => {
  const allOrderDetails = Object.keys(state.orders.ordersByPortfolioId)
    .map((portfolioId) => state.orders.ordersByPortfolioId[portfolioId].orderDetails)
    .flat();
  return allOrderDetails.some((orderDetail) => orderDetail.creatingContract === true);
};

//export const selectIsAdviceDeliveryVisible = (state: RootState): boolean => {
//  const adviceDeliveryByPortfolioId = selectIsAdviceDeliveryVisibleByPortfolioId(state);
//  return adviceDeliveryByPortfolioId.some((x) => x.isAdviceDeliveryVisible);
//};

export const selectIsAdviceDeliveryVisibleByPortfolioId = (state: RootState): IsAdviceDeliveryVisible[] => {
  return selectPortfolioIdsWithOrders(state).map((portfolioId) => {
    const contractName = selectPortfolioContractNameByPortfolioId(state, portfolioId);
    const orderLines = selectOrderLinesByPortfolioId(state, portfolioId);
    const hasOrders = selectHasOrdersByPortfolioId(state, portfolioId);
    const hasProductsReceivedWritten = selectOrderReceivedWrittenByPortfolioId(state, portfolioId);
    return {
      portfolioId,
      isAdviceDeliveryVisible:
        (contractName === CONSULTATIVE_AGREEMENT || contractName === CUSTOMER_RELATIONSHIP_AGREEMENT) &&
        orderLines
          .toJS()
          .some(
            (orderLine) => isListed(orderLine.instrumentForm, orderLine.side) && !isTaaleriProduct(orderLine.issuer)
          ) &&
        hasOrders &&
        !hasProductsReceivedWritten,
    };
  });
};

export const selectShowHasSeenDocumentsToggleByPortfolioId = (state: RootState): ShowHasSeenDocumentsToggle[] => {
  return selectPortfolioIdsWithOrders(state).map((portfolioId) => {
    const onlySellOrders = selectHasOnlySellOrdersByPortfolioId(state, portfolioId);
    const name = selectPortfolioContractNameByPortfolioId(state, portfolioId);
    const isIFPA = selectIsIndividualFullPowerOfAttorneyByPortfolioId(state, portfolioId);
    const signatureMethods = selectSignatureMethodSelectorByPortfolioId(state, portfolioId);
    const hasOrders = selectHasOrdersByPortfolioId(state, portfolioId);
    const areListed = selectOrderLinesByPortfolioId(state, portfolioId).some(
      (line) => !isListed(line?.get('financialInstrumentForm') as InstrumentForm, line?.get('side') as OrderSideType)
    );
    return {
      portfolioId,
      hasSeenDocumentsToggle:
        (!onlySellOrders || name !== FULL_POWER_OF_ATTORNEY || isIFPA) &&
        signatureMethods.some((x) => x.signatureMethod !== SENT_FOR_BANKER_APPROVAL) &&
        areListed &&
        hasOrders,
    };
  });
};

const selectIsFullPowerButRequiresSignatureByPortfolioIdAndOrderLineId = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  state: RootState,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  portfolioId: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  orderLineId: number
) => {
  // AMLT-4690 - This all commented out, but left here, because at the moment this is not needed (22.2.2024), but it is unclear if this will have some need at some point
  // At some point in history FULL_POWER_OF_ATTORNEY + insurance portfolio class needed signatures for unlisted instruments, but not anymer. For now at least.
  // Not the prettiest way to do, but there is no point to delete this right this moment.
  // NOTE: if this is ever re-instated to use, remove also the eslinter disables on couple of rows upper :D
  //const portfolioContractyName = selectPortfolioContractNameByPortfolioId(state, portfolioId);
  //const currentPortfolio = selectCurrentPortfolioById(state, portfolioId);
  //const isCurrentInstrumentListed = selectIsCurrentInstrumentListedByOrderLineId(state, orderLineId);

  //if (portfolioContractyName === FULL_POWER_OF_ATTORNEY) {
  //  if (currentPortfolio && currentPortfolio.isInsurancePortfolio && !isCurrentInstrumentListed) {
  //    return true;
  //  }
  //}

  return false;
};

export const selectAreSignaturesRequired = (state: RootState) => {
  const portfolioIds = selectPortfolioIdsWithOrders(state);
  const isAdviceDeliveredAfterwards = selectAreAdvicesDeliveredAfterwards(state).some(
    (advice) => advice.isAdviceDeliveredAfterwards
  );
  const orderLines = selectContractOrderLines(state);
  const areCurrentLinesListed = selectAreOrderLinesListed(orderLines?.toJS());

  const fullPowerRequiresSignature = portfolioIds.some((portfolioId) => {
    const portfolioOrderLines = selectOrderLinesByPortfolioId(state, portfolioId);
    return portfolioOrderLines
      .toJS()
      .some((orderLine: OrderLineType) =>
        selectIsFullPowerButRequiresSignatureByPortfolioIdAndOrderLineId(state, portfolioId, orderLine._id)
      );
  });

  const hasOrders = portfolioIds.some((portfolioId) => selectHasOrdersByPortfolioId(state, portfolioId));
  const contractNames = portfolioIds.map((portfolioId) => selectPortfolioContractNameByPortfolioId(state, portfolioId));
  const orderReceivedWritten = portfolioIds.some((portfolioId) =>
    selectOrderReceivedWrittenByPortfolioId(state, portfolioId)
  );

  return (
    hasOrders &&
    (!isAdviceDeliveredAfterwards ||
      (orderReceivedWritten && contractNames.some((name) => name === CONSULTATIVE_AGREEMENT)) ||
      (!areCurrentLinesListed && contractNames.some((name) => name !== FULL_POWER_OF_ATTORNEY)) ||
      fullPowerRequiresSignature)
  );
};

export const selectIsSignatureRequiredInOrderLine = (
  state: RootState,
  portfolioId: string,
  orderLines: OrderLineType[]
) => {
  const hasOrders = selectHasOrdersByPortfolioId(state, portfolioId);
  const isAdviceDeliveredAfterwards = selectIsAdviceDeliveredAfterwardsByPortfolioId(state, portfolioId);
  const contract = selectPortfolioContractNameByPortfolioId(state, portfolioId);
  const fullPowerRequiresSignature = orderLines.some((orderLine) =>
    selectIsFullPowerButRequiresSignatureByPortfolioIdAndOrderLineId(state, portfolioId, orderLine._id)
  );

  const areOrderLinesListed = selectAreOrderLinesListed(orderLines);
  const orderReceivedWritten = selectOrderLinesHaveReceivedWritten(orderLines);

  return decideIsSignatureRequiredInOrderLine(
    isAdviceDeliveredAfterwards,
    areOrderLinesListed,
    fullPowerRequiresSignature,
    hasOrders,
    contract,
    orderReceivedWritten
  );
};

// export const selectIsAnyOrderBasisClientInitiative = (state: RootState): boolean => {
//   const check = selectPortfolioIdsWithOrders(state).map((portfolioId) => {
//     return {
//       hasClientInitiative:
//         (selectPortfolioContractNameByPortfolioId(state, portfolioId) === CONSULTATIVE_AGREEMENT ||
//           selectPortfolioContractNameByPortfolioId(state, portfolioId) === CUSTOMER_RELATIONSHIP_AGREEMENT) &&
//         selectOrderLinesByPortfolioId(state, portfolioId).some((line) => line?.get('orderBasis') === CLIENT_INITIATIVE),
//     };
//   });

//   if (check && check.some((item) => item.hasClientInitiative === true)) {
//     return true;
//   }
//   return false;
// };

export const decideIsSignatureRequiredInOrderLine = (
  isAdviceDeliveredAfterwards: boolean,
  areOrderLinesListed: boolean,
  fullPowerRequiresSignature: boolean,
  hasOrders: boolean,
  contract: string | undefined,
  orderReceivedWritten: boolean
) => {
  if (hasOrders) {
    if (!isAdviceDeliveredAfterwards) {
      return true;
    }
    if (orderReceivedWritten && WRITTEN_ORDER_REQUIRES_SIGNATURE.includes(contract ?? '')) {
      return true;
    }
    if (!areOrderLinesListed && contract !== FULL_POWER_OF_ATTORNEY) {
      return true;
    }
    if (fullPowerRequiresSignature) {
      return true;
    }
  }

  return false;
};

export const selectSignatureMethods = (
  state: RootState,
  portfolioId: string,
  orderLineIds: number[]
): SignatureMethodType[] => {
  const contractName = selectPortfolioContractNameByPortfolioId(state, portfolioId);
  const isInsurancePortfolio = selectIsInsurancePortfolioById(state, portfolioId);
  const strategy = selectPortfolioStrategyById(state, portfolioId);
  const currentInstrumentForms = orderLineIds.map((id) => selectCurrentInstrumentFormByOrderLineId(state, id));
  const onlyFunds = currentInstrumentForms.every((i) => i === FUND);
  const hasRestrictedPortfolioClass = selectHasRestrictedPortfolioClassById(state, portfolioId);

  const signatureMethods: SignatureMethodType[] = [SENT_FOR_SIGNING, SENT_FOR_MANUAL_SIGNING, SENT_FOR_INK_SIGNING];

  if (contractName === FULL_POWER_OF_ATTORNEY && !hasRestrictedPortfolioClass) {
    if (isIndividualStrategy(strategy) && onlyFunds) {
      signatureMethods.push(SENT_FOR_BANKER_APPROVAL);
    } else if (
      isInsurancePortfolio &&
      currentInstrumentForms.every((form) => insuranceFormsWithBankerApproval.includes(form))
    ) {
      signatureMethods.push(SENT_FOR_BANKER_APPROVAL);
    }
  }
  return signatureMethods;
};

export const selectInstrumentGroupsFromOrderLines = (state: RootState): string[] => {
  const groups: string[] = state.orderLines.orderLines
    .toJS()
    .map((line: OrderLineType) => line.financialInstrument.instrumentGroups ?? [])
    .flat();
  return [...new Set(groups)];
};
