export default {
  biggerThanNetAmount: 'Määrä on yli 95 prosenttia nettomäärästä',
  biggerThanNetQuantity: 'Määrä on suurempi kuin nettomäärä',
  general: 'Virheellinen arvo',
  required: 'Pakollinen arvo',
  pattern: 'Virheellinen arvo',
  minLength: 'Vastaus on liian lyhyt',
  maxLength: 'Vastaus on liian pitkä',
  notStructuredSum: 'Määrä ei ole sallittu',
  numberMinimum: 'Arvo on liian pieni',
  numberMaximum: 'Arvo on liian suuri',
  numberNotANumber: 'Vastaus pitää olla numero',
  dateMinimum: 'Päivämäärä on liian kaukana menneisyydessä',
  dateMaximum: 'Päivämäärä on liian kaukana tulevaisuudessa',
  oneOf: 'Arvo ei ole sallittu toisen arvon kanssa',
  exclusiveMinimum: 'Arvo on yhtä suuri kuin ei-sallittu minimiarvo',
};
