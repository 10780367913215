import { ENOUGH_SHARES, SECURITY_NOT_IN_REPORTING } from 'constants/shareOptions';
import { SECURITIES_BROKERAGE_CONTRACT } from 'constants/contractNames';
import { SELL, SELL_ALL } from 'constants/sides';
import { EXTRA_ORDER_CONFIRMATION_MAP } from 'constants/instrumentGroups';
import { STRUCTURED_PRODUCT } from 'constants/instrumentForms';
import { isBond } from './portfolios';
import { OrderSideType } from 'types/orderDialogState';
import { OrderLineType } from 'types/ordersState';

export const getEditorValuesBasedOnSide = (
  existingValues: OrderLineType,
  side: OrderSideType,
  tradeAll: boolean,
  contractName: string,
  contractType: string
) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const editorValues: OrderLineType = {
    side,
    tradeAll,
    orderFinancingSell: undefined,
    orderFinancingBuy: undefined,
    contractType,
  };

  if (tradeAll && existingValues.currentQuantity) {
    if (isBond(existingValues.financialInstrumentForm)) {
      editorValues.amount = existingValues.currentQuantity;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      editorValues.quantity = undefined;
    } else {
      editorValues.quantity = existingValues.currentQuantity;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      editorValues.amount = undefined;
    }
    editorValues.orderFinancingSell = ENOUGH_SHARES;
  } else {
    editorValues.orderFinancingSell = undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    editorValues.quantity = undefined;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    editorValues.amount = undefined;
    if (editorValues.side === SELL && contractName === SECURITIES_BROKERAGE_CONTRACT) {
      editorValues.orderFinancingSell = SECURITY_NOT_IN_REPORTING;
    }
  }
  editorValues.orderFinancingBuy = undefined;
  return editorValues;
};

export const isStructuredSellOrder = (financialInstrumentForm: string, side: OrderSideType) =>
  financialInstrumentForm === STRUCTURED_PRODUCT && (side === SELL || side === SELL_ALL);

export const askForConfirmation = (instrumentGroups: string[] | undefined, contractType: string): boolean => {
  if (!instrumentGroups || !instrumentGroups.length) {
    return false;
  }
  const groups = instrumentGroups.filter(
    (group) => group in EXTRA_ORDER_CONFIRMATION_MAP && EXTRA_ORDER_CONFIRMATION_MAP[group].includes(contractType)
  );
  return groups.length > 0;
};
