import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import accounting from 'accounting';
import { rowStyles, columnStyles } from 'styles/material-ui/material-ui';
import { NOT_APPLICABLE } from 'constants/common';
import { MarketInfo, PortfolioDetails } from 'types/portfolioState';

interface Props {
  portfolio: PortfolioDetails;
  marketInfo: MarketInfo;
}

export const DailyRow = ({ portfolio, marketInfo }: Props) => {
  const { externalId, portfolioName, withdrawalBalance, withdrawalCurrency } = portfolio;
  const {
    purchaseValue,
    marketValue,
    marketValueChange,
    marketValueChangeCurrency,
    marketValueChangePercent,
    marketValueOfMoneyPositions,
  } = marketInfo;
  const colStyles = {
    ...columnStyles,
    fontFamily: 'Calibre',
    fontSize: 14,
    fontWeight: 400,
    textTransform: 'none',
  } as React.CSSProperties;
  return (
    <TableRow style={rowStyles}>
      <TableCell style={{ ...colStyles, textAlign: 'left' }}>{externalId}</TableCell>
      <TableCell style={{ ...colStyles, textAlign: 'left' }}>{portfolioName}</TableCell>
      <TableCell style={colStyles}>{accounting.formatMoney(purchaseValue)}</TableCell>
      <TableCell style={colStyles}>{accounting.formatMoney(marketValue)}</TableCell>
      <TableCell style={colStyles}>{`${accounting.formatMoney(
        marketValueChange
      )} ${marketValueChangeCurrency} / ${accounting.formatNumber(marketValueChangePercent, 2)} %`}</TableCell>
      <TableCell style={colStyles}>
        {withdrawalCurrency !== NOT_APPLICABLE ? accounting.formatMoney(withdrawalBalance) : ''}
      </TableCell>
      <TableCell style={colStyles}>{accounting.formatMoney(marketValueOfMoneyPositions)}</TableCell>
      <TableCell style={colStyles}>{withdrawalCurrency}</TableCell>
    </TableRow>
  );
};
