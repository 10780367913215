import React from 'react';
import { ASSET_CATEGORY_COLORS } from 'styles/colors';
import { DistributionRow } from './DistributionRow';
import { AssetCategoryWeights } from 'types/weightsState';

interface Props {
  weights: AssetCategoryWeights[];
}

export const AssetCategoryDistribution = ({ weights }: Props) => {
  return (
    <table>
      <tbody>
        {weights?.map((assetCategory) => {
          const dotColor = ASSET_CATEGORY_COLORS[assetCategory.name];
          return (
            <DistributionRow
              key={assetCategory.name}
              dotColor={dotColor}
              item={assetCategory}
              compareTo="none"
              itemType="assetCategory"
            />
          );
        })}
      </tbody>
    </table>
  );
};
