import React, { Component } from 'react';
import { connect } from 'react-redux';
import Immutable from 'immutable';
import { Link } from 'react-router';
import getValue from 'lodash/get';
import classnames from 'classnames';
import Refresh from '@mui/icons-material/Refresh';
import assign from 'lodash/assign';
import has from 'lodash/has';
import translate from 'counterpart';
import moment from 'moment';
import { DocBlock } from 'features/profileEdit/components/DocBlock';
import { isRequired } from 'core/functions';
import SubQuestionSet from 'features/arrayQuestions/SubQuestionSet';
import styles from './SummaryView.scss';
import { companyProfile } from 'constants/companyProfile';
import {
  loadProcurationForm,
  loadCustomerForm,
  setCustomerForm,
  validation,
  changeSectionField,
  getBisnodeData,
  getCompanyRegistryData,
  getProcurationInfo,
  loadStakeholders,
  getCompanyIdentifyAndScreen,
  updateStakeholders,
  screenPerson,
  updateCustomerData,
  copyValues,
  saveArrayEditorValue,
  updateStatuses,
  updateTradeRegistry,
  loadDocuments,
  getStatusesPdf,
  getTradeRegistryPdf,
} from 'features/profileEdit/profileEditActions';
import {
  selectFormValues,
  selectHeader,
  selectStatusesDoc,
  selectTradeRegistryDoc,
  selectFetchingDocuments,
  selectProcurationInfoUpdated,
  selectIdentifyAndScreen,
  selectStakeholders,
  selectLastScreened,
} from 'features/profileEdit/profileEditUtils';
import { NavBar } from 'features/common/NavBar';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Stakeholders } from 'features/profileEdit/components/Stakeholders';
import { Spinner } from 'features/common/Spinner';
import { Contract, FieldType, Item } from 'types/ordersState';
import { RootState } from 'types/rootState';
import { ImmutableIdentifyAndScreen, ImmutableStakeholders } from 'types/profileEditState';
import { ImmutableDocument } from 'types/profileState';

interface Props {
  formValues: Immutable.Map<string, object>;
  params: { id: string };
  loadCustomerForm: (customerId: string) => void;
  loadDocuments: (customerId: string) => void;
  header: string;
  statusesDoc: ImmutableDocument;
  tradeRegistryDoc: ImmutableDocument;
  getStatusesPdf: () => void;
  getTradeRegistryPdf: () => void;
  updateStatuses: () => void;
  updateTradeRegistry: () => void;
  fetchingDocuments: boolean;
  procurationInfoUpdated: string;
  identifyAndScreen: ImmutableIdentifyAndScreen;
  getCompanyIdentifyAndScreen: () => void;
  updateStakeholders: () => void;
  stakeholders: ImmutableStakeholders;
  loadStakeholders: (customerId: string) => void;
  lastScreened: string;
}

class SummaryView extends Component<Props> {
  static defaultProps = {
    procurationInfoUpdated: '',
  };

  componentDidMount() {
    const { params, formValues, loadCustomerForm, loadDocuments, loadStakeholders } = this.props;
    if (formValues.isEmpty()) {
      loadCustomerForm(params.id);
    }
    loadDocuments(params.id);
    loadStakeholders(params.id);
  }

  getField = (field: FieldType, data: Item) => {
    const value = getValue(data, field.key);
    const schema = companyProfile.schema;
    let content;
    let ans;
    if (typeof value === 'string') {
      content = [
        <div key="title">{field.title.fi}</div>,
        <div className="row" key="answer">
          {value}
        </div>,
      ];
    } else if (field.items && value) {
      const questions = field.items.map((q) => assign({}, q, { isRequired: isRequired(schema, q.key) }));
      ans = value.map((answer: Contract, ind: number) => (
        <div className="columns small-6" key={ind}>
          {/* @ts-expect-error: props missing */}
          <SubQuestionSet
            answer={answer}
            questions={questions}
            locale={'fi'}
            field={field}
            schema={schema}
            questionSetIndex={ind}
            countries={{}}
            hideControls={true}
          />
        </div>
      ));

      content = [
        <div className="small-12" key="title">
          {field.title.fi}
        </div>,
        <div className="row" key="answer">
          {ans}
        </div>,
      ];
    }

    return (
      <div key={field.key} className={classnames('row align-justify', styles.field)}>
        {content}
      </div>
    );
  };

  getUpdateInfo = (key: string) => {
    const { procurationInfoUpdated } = this.props;
    let date = '';
    if (key === 'sections.procurationInfo' && procurationInfoUpdated) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      date = (
        <div className={styles.updateInfo}>
          Päivitetty{' '}
          {moment(procurationInfoUpdated, ['MM/DD/YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY', 'DD.MM.YY', 'DDMMYYYY']).format(
            'DD.MM.YYYY'
          )}
        </div>
      );
    }
    return date;
  };

  render() {
    const {
      params,
      header,
      formValues,
      statusesDoc,
      tradeRegistryDoc,
      getStatusesPdf,
      getTradeRegistryPdf,
      updateStatuses,
      updateTradeRegistry,
      fetchingDocuments,
      identifyAndScreen,
      getCompanyIdentifyAndScreen,
      updateStakeholders,
      stakeholders,
      lastScreened,
    } = this.props;
    const rightContent = (
      <div>
        <Link to={`/customer/${params.id}/edit`} className={styles.rightBtn}>
          <Refresh
            style={{
              width: '19px',
              height: '19px',
              verticalAlign: 'middle',
              marginRight: '.6rem',
            }}
            key="icon"
            sx={{
              color: '#d6bb7f',
            }}
          />
          Hae tiedot PRH:sta
        </Link>
        <Link to={`/customer/${params.id}`} className={styles.rightBtn}>
          Sulje&nbsp;
          <i className="icon icon-close" />
        </Link>
      </div>
    );
    const data = formValues.toJS();
    const content = companyProfile.form.items.map((section, index) => (
      <section key={index}>
        <div className={styles.profileSubHeader}>
          <header>{section.title.fi}</header>
          {this.getUpdateInfo(section.key)}
        </div>
        {section.items.map((f) => this.getField(f, data))}
      </section>
    ));

    let documentsContent;
    if (has(data, 'sections.clientData.businessId')) {
      documentsContent = (
        <section key={'documents'}>
          <div className={styles.profileSubHeader}>
            <header>{translate(`customer.documents`)}</header>
          </div>
          {fetchingDocuments ? (
            <Spinner className={styles.spinner} size={100} />
          ) : (
            [
              <DocBlock
                key="tradeRegistry"
                name={data.sections.clientData.name}
                doc={tradeRegistryDoc}
                openFile={getTradeRegistryPdf}
                updateDoc={updateTradeRegistry}
                businessId={data.sections.clientData.businessId}
                title={translate('customer.tradeRegistry')}
              />,
              <DocBlock
                key="statusesDoc"
                name={data.sections.clientData.name}
                doc={statusesDoc}
                openFile={getStatusesPdf}
                updateDoc={updateStatuses}
                businessId={data.sections.clientData.businessId}
                title={translate('customer.statusesDoc')}
              />,
            ]
          )}
        </section>
      );
    }
    const stakeholdersContent = has(data, 'sections.clientData.businessId') ? (
      <section key={'stakeholders'}>
        <div className={styles.profileSubHeader}>
          <header>{translate(`customer.stakeholders`)}</header>
        </div>
        <Stakeholders
          customerId={params.id}
          identifyAndScreen={identifyAndScreen}
          getCompanyIdentifyAndScreen={getCompanyIdentifyAndScreen}
          updateStakeholders={updateStakeholders}
          stakeholders={stakeholders}
          lastScreened={lastScreened}
        />
      </section>
    ) : null;
    return (
      <section>
        <NavBar rightContent={rightContent} leftContent={<div>{header} - Tietojen päivitys</div>} />
        <ErrorBoundary>
          <div className={styles.content}>
            {content}
            {stakeholdersContent}
            {documentsContent}
          </div>
        </ErrorBoundary>
      </section>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    formValues: selectFormValues(state),
    header: selectHeader(state),
    statusesDoc: selectStatusesDoc(state),
    tradeRegistryDoc: selectTradeRegistryDoc(state),
    fetchingDocuments: selectFetchingDocuments(state),
    procurationInfoUpdated: selectProcurationInfoUpdated(state),
    identifyAndScreen: selectIdentifyAndScreen(state),
    stakeholders: selectStakeholders(state),
    lastScreened: selectLastScreened(state),
  };
}

const mapDispatchToProps = {
  loadProcurationForm,
  loadCustomerForm,
  setCustomerForm,
  validation,
  changeSectionField,
  getBisnodeData,
  getCompanyRegistryData,
  getProcurationInfo,
  loadStakeholders,
  getCompanyIdentifyAndScreen,
  updateStakeholders,
  screenPerson,
  updateCustomerData,
  copyValues,
  saveArrayEditorValue,
  updateStatuses,
  updateTradeRegistry,
  loadDocuments,
  getStatusesPdf,
  getTradeRegistryPdf,
};

// @ts-expect-error: ??
export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
