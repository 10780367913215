import find from 'lodash/find';
import { Person, RoleDescription, Role } from 'types/types';

const getDescription = (descriptions: RoleDescription[]) => {
  const descObject = find(descriptions, { language: 'Finnish' });
  return descObject ? descObject.description : '';
};

const boardRolesMapper = (v: Person[]) => {
  const roleMapper = (personRoles: Role[]) => {
    const roles = personRoles.map((r) => `${r.role}, ${getDescription(r.roleDescription)}.`);
    return roles.join(' ');
  };
  return v.map((person: Person) => ({
    firstName: person.firstname,
    lastName: person.lastname,
    ssn: person.personId,
    roles: roleMapper(person.role),
  }));
};

export default {
  privateProfile: [
    {
      source: 'lastname',
      target: 'sections.clientData.lastName',
    },
    {
      source: 'firstname',
      target: 'sections.clientData.firstName',
    },
    {
      source: 'address',
      target: 'sections.clientData.streetName',
    },
    {
      source: 'zipcode',
      target: 'sections.clientData.postalCode',
    },
    {
      source: 'postOffice',
      target: 'sections.clientData.city',
    },
    {
      source: 'phoneNumber',
      target: 'sections.clientData.phoneNumber',
      mapper: (v: string) => {
        let value = v;
        if (value && value.startsWith('0')) {
          value = `+358${value.slice(1)}`;
        }
        return value;
      },
    },
  ],
  privateCurrentData: [
    {
      source: 'ssn',
      target: 'sections.clientData.ssn',
    },
    {
      source: 'lastName',
      target: 'sections.clientData.lastName',
    },
    {
      source: 'firstName',
      target: 'sections.clientData.firstName',
    },
    {
      source: 'streetAddress',
      target: 'sections.clientData.streetName',
    },
    {
      source: 'postalCode',
      target: 'sections.clientData.postalCode',
    },
    {
      source: 'city',
      target: 'sections.clientData.city',
    },
    {
      source: 'phoneNumber',
      target: 'sections.clientData.phoneNumber',
    },
    {
      source: 'email',
      target: 'sections.clientData.email',
    },
  ],
  companyProfile: [
    {
      source: 'toiminimiField.nimiField',
      target: 'sections.clientData.name',
    },
    {
      source: 'yhteystiedotField.postiosoiteField.posoiteField',
      target: 'sections.clientData.streetName',
    },
    {
      source: 'yhteystiedotField.postiosoiteField.ppostinoField',
      target: 'sections.clientData.postalCode',
    },
    {
      source: 'yhteystiedotField.postiosoiteField.ppostitpField',
      target: 'sections.clientData.city',
    },
    {
      source: 'company.0.procurationAbstractDescription',
      target: 'sections.procurationInfo.procurationAbstractDescription',
      mapper: getDescription,
    },
    {
      source: 'company.0.procurationPerson.0.role.0.procurationDescription',
      target: 'sections.procurationInfo.procurationDescription',
      mapper: getDescription,
    },
    {
      source: 'company.0.procurationPerson',
      target: 'sections.procurationInfo.procurationPersons',
      mapper: (v: Person[]) =>
        v.map((person: Person) => ({
          name: person.personName,
          ssn: person.personId,
        })),
    },
    {
      source: 'company.0.board.naturalPerson',
      target: 'sections.procurationInfo.board',
      mapper: boardRolesMapper,
    },
  ],
  companyCurrentData: [
    {
      source: 'name',
      target: 'sections.clientData.name',
    },
    {
      source: 'businessId',
      target: 'sections.clientData.businessId',
    },
    {
      source: 'streetAddress',
      target: 'sections.clientData.streetName',
    },
    {
      source: 'postalCode',
      target: 'sections.clientData.postalCode',
    },
    {
      source: 'city',
      target: 'sections.clientData.city',
    },
    {
      source: 'procurationPersons',
      target: 'sections.procurationInfo.procurationPersons',
    },
    {
      source: 'procurationAbstractDescription',
      target: 'sections.procurationInfo.procurationAbstractDescription',
    },
    {
      source: 'procurationDescription',
      target: 'sections.procurationInfo.procurationDescription',
    },
    {
      source: 'board',
      target: 'sections.procurationInfo.board',
    },
  ],
};
