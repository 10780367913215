import React from 'react';

interface Props {
  onClick: (title: string, info: string) => void;
  title: string;
  info: string;
  className: string;
}

export default class FieldInfo extends React.Component<Props> {
  onClick = () => {
    this.props.onClick(this.props.title, this.props.info);
  };

  render() {
    const { className } = this.props;
    return (
      <div className={className} onClick={this.onClick}>
        ?
      </div>
    );
  }
}
