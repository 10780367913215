import { branch, renderComponent, mapProps, withProps, compose } from 'recompose';
import omit from 'lodash/fp/omit';
import { Spinner } from 'features/common/Spinner';

const omitProps = compose(mapProps, omit);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const spinnerWhileLoading = (isLoading) =>
  branch(
    isLoading,
    renderComponent(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      compose(omitProps(Spinner), withProps({ size: 100, style: { textAlign: 'center', marginTop: '16rem' } }))(Spinner)
    )
  );

export default spinnerWhileLoading;
