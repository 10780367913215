import React from 'react';
import Select from 'react-select-old';
import translate from 'counterpart';
import styles from './SelectWithLabel.scss';
import { SelectValue } from 'types/types';

interface Props {
  options: SelectValue[];
  onChange: (event: SelectValue) => void;
  value: string | number | undefined;
  tabIndex?: string;
  label: string;
  clearable?: boolean;
  disabled?: boolean;
  searchable?: boolean;
  focusRef?: React.MutableRefObject<HTMLElement | undefined>;
  className?: string;
  placeholder?: string;
  'data-testkey'?: string;
}

export const SelectWithLabel = ({
  options,
  onChange,
  value,
  tabIndex = '0',
  label,
  clearable = false,
  disabled = false,
  focusRef,
  searchable = false,
  className,
  placeholder,
  'data-testkey': dataTestKey,
}: Props) => (
  <div className={className} data-testkey={`${dataTestKey}-select`}>
    <div className={styles.label}>{label}</div>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-expect-error */}
    <Select
      options={options}
      onChange={onChange}
      value={value}
      tabIndex={tabIndex}
      clearable={clearable}
      disabled={disabled}
      className="Select-white"
      placeholder={placeholder || translate('order.select')}
      ref={focusRef}
      searchable={searchable}
    />
  </div>
);
