import moment, { Moment } from 'moment';

const DAYS_UNTIL_EXPIRES = 364;
export const EXPIRY_ALERT_DAYS = 30;
export const EXPIRY_WARNING_DAYS = 60;
const formatter = 'DD.MM.YYYY';
const ESG_EXPIRY_DATE = moment('30.10.2024', formatter);
const dateIsFromCRM = 'Vanhentumisaika haettu CRM:stä.';
const dateIsFromMyty = 'Vanhentumisaika haettu MyTystä.';
const dateIsBecauseESGExpiry = (actualExpiry: string, esgExpiry: string) =>
  ` Asiakkaan soveltuvuusarvioinnin alkuperäinen vanhentumisaika on ${actualExpiry}, mutta ESG-kysymykset vanhenevat aiemmin (${esgExpiry}), joten soveltuvuusarviointi vanhenee samalla.`;

interface contractDates {
  signDate: string;
  signDateFormatted: string;
  expirationDate: expiration;
  expirationDateFormatted: string;
  daysUntilExpiry: number | null;
  isFromMyty: boolean;
}

interface expiration {
  date: Moment | null;
  daysUntilExpiry: number | null;
  disclaimer: string | null;
}

function getExpiryDate(signed: string, calculateDays: boolean, isMyty: boolean) {
  if (!signed) {
    const expiration: expiration = {
      date: null,
      daysUntilExpiry: null,
      disclaimer: null,
    };
    return expiration;
  }

  let dateUntilExpires = moment(signed);
  let disclaimer = '';
  let daysUntilExpiry: number | null = null;

  if (calculateDays) {
    dateUntilExpires = moment(signed).add(DAYS_UNTIL_EXPIRES, 'days');
    if (isMyty) {
      disclaimer = dateIsFromMyty;
    } else {
      disclaimer = dateIsFromCRM;
      if (dateUntilExpires > ESG_EXPIRY_DATE) {
        disclaimer =
          disclaimer + dateIsBecauseESGExpiry(dateUntilExpires.format(formatter), ESG_EXPIRY_DATE.format(formatter));
        dateUntilExpires = ESG_EXPIRY_DATE;
      }
    }
  }
  daysUntilExpiry = moment.duration(dateUntilExpires?.diff(moment())).asDays();

  const expiration: expiration = {
    date: dateUntilExpires,
    disclaimer,
    daysUntilExpiry,
  };
  return expiration;
}

export function selectExpirationDate(calculateDays: boolean, signDate: string, mytySignDate?: string) {
  if (signDate && signDate !== undefined) {
    let expDate = getContractDates(signDate, calculateDays, false);
    if (mytySignDate) {
      const mytyExpDate = getContractDates(mytySignDate, calculateDays, true);
      const mytyDate = mytyExpDate?.expirationDate?.date;
      const basicDate = expDate?.expirationDate?.date;
      if (mytyDate && basicDate) {
        if (mytyDate.diff(basicDate) > 0) {
          expDate = mytyExpDate;
        }
      }
    }
    return expDate;
  } else {
    if (mytySignDate) {
      const expDate = getContractDates(mytySignDate, calculateDays, true);
      return expDate;
    }
  }
}

function getContractDates(signDate: string, calculateDays: boolean, isMyty: boolean) {
  const expirationDate = getExpiryDate(signDate, calculateDays, isMyty);

  const contractDates: contractDates = {
    signDate,
    signDateFormatted: moment(signDate).format(formatter),
    expirationDate,
    expirationDateFormatted: moment(expirationDate?.date).format(formatter),
    daysUntilExpiry: expirationDate?.daysUntilExpiry,
    isFromMyty: isMyty,
  };

  return contractDates;
}
