import React from 'react';
import { TableRow, TableCell } from '@mui/material';
import { rowStyles, columnStyles } from 'styles/material-ui/material-ui';
import { PortfolioDetails } from 'types/portfolioState';

interface Props {
  portfolio: PortfolioDetails;
}

const colStyles = {
  ...columnStyles,
  fontFamily: 'Calibre',
  fontSize: 14,
  fontWeight: 400,
  textTransform: 'none',
} as React.CSSProperties;
const StaticRow = ({
  portfolio: {
    externalId,
    portfolioName,
    state,
    custodianName,
    iban,
    paymentReference,
    managementFee,
    tradingCommission,
    accountType,
  },
}: Props) => (
  <TableRow style={rowStyles}>
    <TableCell style={{ ...colStyles, textAlign: 'left' }}>{externalId}</TableCell>
    <TableCell style={{ ...colStyles, textAlign: 'left' }}>{portfolioName}</TableCell>
    <TableCell style={colStyles}>{state}</TableCell>
    <TableCell style={colStyles}>{custodianName}</TableCell>
    <TableCell style={colStyles}>{iban}</TableCell>
    <TableCell style={colStyles}>{accountType === 'AS' ? paymentReference : ''}</TableCell>
    <TableCell style={colStyles}>{managementFee}</TableCell>
    <TableCell style={colStyles}>{tradingCommission}</TableCell>
  </TableRow>
);

export default StaticRow;
