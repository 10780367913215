import { emptyOptimizedPortfolios } from 'constants/allocator';
import {
  isLoadingInitialItems,
  selectHasPositions,
  selectOptimizedWeightsForRiskLevel,
  selectOptimizeFailedForPlanLength,
  selectRisk,
  isOptimizingPlan,
  selectPlanState,
  isLoadingPlan,
  isOptimizingCurrent,
} from 'features/allocator/allocatorSelectors';
import {
  getNonOutsidePortfolioIds,
  selectPortfolioDetailsById,
  selectPositions,
} from 'features/portfolio/portfolioSelectors';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { checkIfCantOptimize, selectNonIgnoredPortfolioIdsForOptimization } from 'features/weights/weightsUtils';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { RootState } from 'types/rootState';
import { PlanLength, RiskLevel } from 'types/types';

export const customerHasOptimizedWeights =
  (planLength: PlanLength) =>
  (state: RootState): boolean => {
    const optimizedWeights = selectOptimizedWeightsForRiskLevel(state);
    return optimizedWeights[planLength].assetCategoryWeights.length > 0;
  };

export const customerHasBothOptimizedWeights = (state: RootState): boolean => {
  const optimizedWeights = selectOptimizedWeightsForRiskLevel(state);
  return (
    optimizedWeights.withIlliquids.assetCategoryWeights.length > 0 &&
    optimizedWeights.withoutIlliquids.assetCategoryWeights.length > 0
  );
};

export const customerHasCurrentWeights = (state: RootState): boolean => {
  return state.portfolioManager.weights.currentWeights.assetCategoryWeights?.length > 0;
};

export const selectOptimizedPortfoliosForRiskLevel = (state: RootState) => (riskLevel: RiskLevel) => {
  const optimizedPortfolios = state.portfolioManager.weights.optimizedPortfolios.find(
    (optimizedPortfolios) => optimizedPortfolios.riskLevel === riskLevel
  );
  return optimizedPortfolios ?? emptyOptimizedPortfolios;
};

export const createOptimizedPortfoliosFromOptimizedValues =
  (riskLevel?: RiskLevel) =>
  (state: RootState): AllocatorPortfolio[] => {
    const risk = riskLevel || selectRisk(state);
    const optimizedPortfolios = selectOptimizedPortfoliosForRiskLevel(state)(risk);
    const optimizedPortfoliosSettings = state.portfolioManager.weights.optimizedPortfoliosSettings;

    // Combine optimized portfolios and portfolio settings
    const planPortfolios = optimizedPortfoliosSettings.map((portfolioSettings) => {
      const optimizedPortfolio = optimizedPortfolios.portfolios.find(
        (portfolios) => portfolios.portfolioId === portfolioSettings.portfolioId
      );

      return {
        ...portfolioSettings,
        allocatedPortfolioRows: optimizedPortfolio ?? { withIlliquids: [], withoutIlliquids: [] },
      };
    });

    return planPortfolios;
  };

export const selectIsLoadingCurrentWeightsDistribution = (state: RootState) => {
  const hasCurrentWeights = customerHasCurrentWeights(state);
  const loadingInitialItems = isLoadingInitialItems(state);
  const hasPositions = selectHasPositions(state);
  const optimizingCurrent = isOptimizingCurrent(state);

  const positions = selectPositions(state);
  const nonIgnoredPortfolioIds = getNonOutsidePortfolioIds(state);
  const hasPositionsInNonIgnoredPortfolio = positions.some((p) =>
    nonIgnoredPortfolioIds.includes(p.portfolio.portfolioId)
  );

  const hasPortfolios = Object.keys(selectPortfolioDetailsById(state)).length > 0;
  const planState = selectPlanState(state);
  const loadingPlan = isLoadingPlan(state);

  const readyToOptimizeCurrent =
    !loadingInitialItems && hasPortfolios && !hasCurrentWeights && planState !== 'notLoaded' && !loadingPlan;

  const cannotOptimizeCurrent = !hasPositions || !hasPositionsInNonIgnoredPortfolio;

  return optimizingCurrent || (!hasCurrentWeights && !(readyToOptimizeCurrent && cannotOptimizeCurrent));
};

export const selectIsLoadingOptimizedWeightsDistribution = (planLength: PlanLength) => (state: RootState) => {
  const optimizingPlan = isOptimizingPlan(state);
  const optimizeFailed = selectOptimizeFailedForPlanLength(planLength)(state);

  const profileLoaded = !!selectCustomerId(state);
  const planState = selectPlanState(state);
  const hasPlan = planState !== 'noPlan' && planState !== 'notLoaded';

  const hasOptimizedWeights = customerHasOptimizedWeights(planLength)(state);
  const hasCurrentWeights = customerHasCurrentWeights(state);

  const cannotOptimize = checkIfCantOptimize(state);

  const readyToOptimizeWithPlan = profileLoaded && hasPlan;
  const readyToOptimizeWithout = profileLoaded && planState === 'noPlan' && !hasCurrentWeights;
  const readyToOptimize = readyToOptimizeWithPlan || readyToOptimizeWithout;

  return optimizingPlan || (!optimizeFailed && !hasOptimizedWeights && !(readyToOptimize && cannotOptimize));
};

export const selectHasPositionsInNonIgnoredPortfolio = (state: RootState) => {
  const positions = selectPositions(state);
  const nonIgnoredPortfolioIds = selectNonIgnoredPortfolioIdsForOptimization(state);
  return positions.some((p) => nonIgnoredPortfolioIds.includes(p.portfolio.portfolioId));
};
