import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { push } from 'react-router-redux';
import { ChoosePlan } from './components/ChoosePlan';
import {
  setSelectedInstrumentGroup,
  handlePlanSelection,
} from 'features/allocator/investmentPlan/investmentPlanInstrumentsActions';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { Card } from 'features/allocator/common/Card';
import { SubPageContainer } from 'features/common/StyledComponents';
import { flagCheckPortfolios } from 'features/allocator/investmentPlan/investmentPlanActions';
import { RootState } from 'types/rootState';
import { Spinner } from 'features/common/Spinner';
import { optimize } from 'features/weights/weightsThunks';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { resetOptimizedPortfoliosSettings } from 'features/weights/weightsSlice';
import { useAppDispatch } from 'core/hooks';

export const CoreSelectionView = () => {
  const loadingAssetClasses = useSelector((state: RootState) => state.portfolioManager.commonData.loadingAssetClasses);
  const loadingInstrumentGroups = useSelector(
    (state: RootState) => state.portfolioManager.commonData.loadingInstrumentGroups
  );
  const instrumentGroups = useSelector((state: RootState) => state.portfolioManager.commonData.instrumentGroups);
  const selectedInstrumentGroup = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.instruments.selectedInstrumentGroup
  );
  const customerId = useSelector(selectCustomerId);

  const isNewCustomer = useSelector(
    (state: RootState) => !state.portfolioManager.weights.currentWeights.assetCategoryWeights
  );

  const [newSelectedInstrumentGroup, setNewSelectedInstrumentGroup] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    setNewSelectedInstrumentGroup(selectedInstrumentGroup);
  }, []);

  const handleSelection = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.currentTarget as HTMLInputElement;
    const value = element.value;
    setNewSelectedInstrumentGroup(value);
  };

  const discardChanges = () => {
    setNewSelectedInstrumentGroup(selectedInstrumentGroup);
  };

  const saveAndResetPortfolios = () => {
    if (isNewCustomer) {
      dispatch(resetOptimizedPortfoliosSettings());
    }
    dispatch(setSelectedInstrumentGroup(newSelectedInstrumentGroup));
    dispatch(handlePlanSelection(newSelectedInstrumentGroup));
    dispatch(flagCheckPortfolios(true));
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
    dispatch(optimize());
  };

  return loadingAssetClasses || loadingInstrumentGroups ? (
    <Spinner />
  ) : (
    <SubPageContainer>
      <AllocatorHeader
        headerLabel={translate('allocator.configureCore.pageTitle')}
        hasChanged={newSelectedInstrumentGroup !== selectedInstrumentGroup}
        customerId={customerId}
        discardChanges={discardChanges}
        saveChanges={saveAndResetPortfolios}
      />
      <Card>
        <ChoosePlan
          planChosen={newSelectedInstrumentGroup}
          instrumentGroups={instrumentGroups}
          onChange={handleSelection}
        />
      </Card>
    </SubPageContainer>
  );
};
