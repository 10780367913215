import { getInitialConstraints } from 'features/allocator/constraints/constraintsUtils';
import { AssetClass } from 'types/commonDataState';
import { Constraint } from 'types/constraintsState';
import { GeneralConstraint } from 'types/investmentPlanState';
import { AppThunk } from 'types/types';

// Action types

export const CUSTOM_CONSTRAINTS_UPDATE = 'CUSTOM_CONSTRAINTS_UPDATE';
export const OPTIMIZATION_CONSTRAINTS_UPDATE = 'OPTIMIZATION_CONSTRAINTS_UPDATE';
export const SET_USE_BACKEND_CONSTRAINTS = 'SET_USE_BACKEND_CONSTRAINTS';

// Sync actions

export function setCustomConstraints(customConstraints: Constraint[]) {
  return <const>{
    type: CUSTOM_CONSTRAINTS_UPDATE,
    result: customConstraints,
  };
}

export function setOptimizationConstraints(optimizationConstraints: Constraint[]) {
  return <const>{
    type: OPTIMIZATION_CONSTRAINTS_UPDATE,
    result: optimizationConstraints,
  };
}

export const setUseBackendConstraints = (value: boolean) => {
  return <const>{
    type: SET_USE_BACKEND_CONSTRAINTS,
    value,
  };
};

export type ConstraintsAction = ReturnType<
  typeof setCustomConstraints | typeof setOptimizationConstraints | typeof setUseBackendConstraints
>;

// Async actions

export const storeConstraints =
  (generalConstraints: GeneralConstraint[], assetClasses: AssetClass[]): AppThunk =>
  (dispatch) => {
    const initialConstraints = getInitialConstraints(assetClasses);

    const optimizationConstraints = initialConstraints.map((initialConstraint) => {
      const modifiedConstraint = generalConstraints.find(
        (generalConstraint) =>
          generalConstraint.label === initialConstraint.label && generalConstraint.constraintType === 'Optimization'
      );

      if (modifiedConstraint) {
        return {
          id: modifiedConstraint.label,
          label: modifiedConstraint.label,
          items: updateConstraintItems(initialConstraint, generalConstraints),
          isCustom: modifiedConstraint.constraintCategory === 'Custom' || false,
          assetClasses: modifiedConstraint.constrained,
          inRelationTo: modifiedConstraint.inRelationTo,
          min: modifiedConstraint.min,
          max: modifiedConstraint.max,
          category: modifiedConstraint.constraintCategory,
        };
      }

      return { ...initialConstraint, items: updateConstraintItems(initialConstraint, generalConstraints) };
    });

    dispatch(setOptimizationConstraints(optimizationConstraints));

    const customConstraints = generalConstraints
      .filter((generalConstraint) => generalConstraint.constraintCategory === 'Custom')
      .map((customConstraint) => {
        return {
          id: customConstraint.label,
          label: customConstraint.label,
          items: [],
          isCustom: customConstraint.constraintCategory === 'Custom' || false,
          assetClasses: customConstraint.constrained,
          inRelationTo: customConstraint.inRelationTo,
          min: customConstraint.min,
          max: customConstraint.max,
          category: customConstraint.constraintCategory,
        };
      });

    dispatch(setCustomConstraints(customConstraints));
  };

const updateConstraintItems = (initialConstraint: Constraint, generalConstraints: GeneralConstraint[]) => {
  return initialConstraint.items.map((item) => {
    const modifiedConstraint = generalConstraints.find((generalConstraint) => generalConstraint.label === item.label);
    if (modifiedConstraint) {
      return { ...item, min: modifiedConstraint.min, max: modifiedConstraint.max };
    }
    return item;
  });
};
