export default {
  appTitle: 'Aktia Varainhoito Oy',
  back: 'Takaisin',
  backToFrontPage: 'Palaa etusivulle',
  navigateToFrontPage: 'Siirry etusivulle',
  bankersDesk: 'Pankkiirin työpöytä',
  cancel: 'Keskeytä',
  cancelAction: 'Peruuta',
  contracts: 'Sopimukset',
  errorMessage: 'Toiminto päättyi virheeseen.',
  errorOccured: 'Tapahtui virhe',
  fetching: 'Hakee...',
  logout: 'Kirjaudu ulos',
  modules: 'Moduulit',
  next: 'Seuraava',
  no: 'Nej',
  previous: 'Edellinen',
  templates: 'Sopimuspohjat',
  yes: 'Ja',
  email: 'Sähköposti',
  phoneNumber: 'Puhelinnumero',
  close: 'Sulje',
  city: 'Postitoimipaikka',
  remove: 'Poista',
  unauthorizedMessage: 'Virhe: Pankkiirin työpöydän käyttöoikeus puuttuu.',
  valueMissing: '(tieto puuttuu)',
  redirectingToApplication: 'Siirrytään Pankkiirin työpöydälle',
  page: 'Sivu',
  ready: 'Valmis',

  portfolioOptimizationModels: {
    Include_portfolio: 'Optimoitava salkku',
    Consider_portfolio: 'Huomioitava salkku',
    Ignore_portfolio: 'Erillinen salkku',
  },
  differenceExplanation: {
    showing: 'Näytetään ',
    value_allocator: 'paino suunnitelmassa ',
    value_manager: 'nykyinen paino ',
    andDifference: 'sekä ero ',
    to_allocator: 'nykyiseen painoon',
    to_manager: 'optimoituun painoon',
    sell: 'myy',
    buy: 'osta',
  },
};
