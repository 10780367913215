import React from 'react';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

const RemovalDialog = (props: { onRemove: () => void; open: boolean; handleClose: () => void }) => {
  const { onRemove, open, handleClose } = props;
  return (
    <ModalDialog
      content={<h4>Poistetaanko liite?</h4>}
      actions={[
        <StyledButton variant="text" onClick={onRemove} data-testkey="remove-file-button" key={'removeFile'}>
          Poista
        </StyledButton>,
        <StyledButton variant="text" onClick={handleClose} key={'cancel'}>
          Peruuta
        </StyledButton>,
      ]}
      open={open}
    />
  );
};

export default RemovalDialog;
