import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import Refresh from '@mui/icons-material/Refresh';
import translate from 'counterpart';
import styles from './DocBlock.scss';
import { OpenFile } from 'features/contracts/components/DownloadPdf';
import { Document } from './Document';
import { Spinner } from 'features/common/Spinner';
import { ImmutableDocument } from 'types/profileState';

interface Props {
  doc: ImmutableDocument;
  openFile: () => void;
  updateDoc: () => void;
  businessId: string;
  title: string;
  name: string;
}

export const DocBlock = (props: Props) => {
  const { doc, name, openFile, updateDoc, businessId, title } = props;
  let content;
  if (doc.has('downloadPath')) {
    const loadFile = openFile.bind(this, doc.get('downloadPath'));
    const headerIcon = <OpenFile isBusy={doc.get('isBusy')} loadFile={loadFile} blobUrl={doc.get('blobUrl')} />;
    content = (
      <Document
        headerIcon={headerIcon}
        headerText={title}
        date={moment(doc.get('lastModified')).format('DD.MM.YYYY')}
      />
    );
  } else {
    content = (
      <div className={styles.missingDoc}>
        <i>{`${title} ${translate('customer.isMissing')}`}</i>
      </div>
    );
  }

  const handleClick = updateDoc.bind(this, businessId, name);

  return (
    <div>
      <div className={classnames(styles.documents, 'row')}>
        <div className="columns small-10">{content}</div>
        <div className={classnames(styles.btnContainer, 'columns small-2')}>
          <button className={styles.actionButton} onClick={handleClick}>
            {doc.get('isUpdating') ? (
              <Spinner color="white" />
            ) : (
              [
                <Refresh
                  style={{
                    width: '15px',
                    height: '15px',
                    verticalAlign: 'middle',
                    marginRight: '.6rem',
                  }}
                  key="icon"
                  sx={{
                    color: '#fff',
                  }}
                />,
                <span key="text">{translate(`customer.update`)}</span>,
              ]
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
