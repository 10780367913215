import { uniq } from 'lodash';
import { customerHasValidPlan } from 'features/allocator/allocatorSelectors';
import { isAllocator } from 'features/allocator/allocatorUtils';
import { RootState } from 'types/rootState';
import { ErrorType, ErrorContext } from 'types/types';

export const getPathContexts = (path: string): string[] => {
  const allErrors = ERRORS_AND_CONTEXTS.filter((i) => path.toLowerCase().includes(i.url)) || [];
  const contexts = allErrors.flatMap((i) => i.contexts);
  return uniq(contexts);
};

const preventAllocatorErrors: ErrorContext[] = [
  'loadCustomerProfile',
  'fetchPositions',
  'fetchInstrumentGroups',
  'fetchAssetClasses',
  'loadInvestmentPlan',
  'optimizeCurrent',
];

const preventPortfolioManagerErrors: ErrorContext[] = ['loadCustomerProfile', 'loadInvestmentPlan', 'optimizeCurrent'];

export const preventAllocatorUse = (state: RootState) =>
  state.common.errors.map((e) => e.context).some((errorKey) => preventAllocatorErrors.includes(errorKey));

export const preventPortfolioManagerUse = (state: RootState) =>
  state.common.errors.map((e) => e.context).some((errorKey) => preventPortfolioManagerErrors.includes(errorKey));

export const errorKeys = {
  optimizeCurrent: 'optimizeCurrent',
  loadInvestmentPlan: 'loadInvestmentPlan',
  saveInvestmentPlan: 'saveInvestmentPlan',
  fetchExamplePortfolios: 'fetchExamplePortfolios',
  getInstrumentPrices: 'getInstrumentPrices',
  optimizePlan: 'optimizePlan',
  optimizePlanWithIlliquids: 'optimizePlanWithIlliquids',
  optimizePlanWithoutIlliquids: 'optimizePlanWithoutIlliquids',
  fetchAssetClasses: 'fetchAssetClasses',
  fetchInstrumentGroups: 'fetchInstrumentGroups',
  setConstraints: 'setConstraints',
  fetchBankersCustomers: 'fetchBankersCustomers',
  fetchPositions: 'fetchPositions',
  fetchPortfolio: 'fetchPortfolio',
  newCustomerSteps: 'newCustomerSteps',
  fetchPortfoliosStatus: 'fetchPortfoliosStatus',
  loadCustomerProfile: 'loadCustomerProfile',
  getInstrumentDetails: 'getInstrumentDetails',
  obsoletePlan: 'obsoletePlan',
  postSimulatedRisk: 'postSimulatedRisk',
  postActualRisk: 'postActualRisk',
  allPositionsNotInPlan: 'allPositionsNotInPlan',
  allPlanInstrumentsNotInPositions: 'allPlanInstrumentsNotInPositions', // this is not shown ever
  negativePositions: 'negativePositions',
  noOptimizedPortfolios: 'noOptimizedPortfolios',
  geographicalValues: 'geographicalValues',
  cannotOptimizeCurrent: 'cannotOptimizeCurrent',
} as const;

const {
  optimizeCurrent,
  optimizePlan,
  optimizePlanWithIlliquids,
  optimizePlanWithoutIlliquids,
  fetchAssetClasses,
  fetchInstrumentGroups,
  loadInvestmentPlan,
  saveInvestmentPlan,
  getInstrumentPrices,
  fetchExamplePortfolios,
  setConstraints,
  fetchBankersCustomers,
  fetchPositions,
  fetchPortfolio,
  newCustomerSteps,
  fetchPortfoliosStatus,
  loadCustomerProfile,
  obsoletePlan,
  allPositionsNotInPlan,
  postSimulatedRisk,
  postActualRisk,
  negativePositions,
  noOptimizedPortfolios,
  geographicalValues,
  cannotOptimizeCurrent,
} = errorKeys;

const ERRORS_AND_CONTEXTS: ErrorType[] = [
  {
    url: 'mycustomers',
    contexts: [fetchBankersCustomers],
  },
  {
    url: 'customer',
    contexts: [fetchPortfoliosStatus, loadInvestmentPlan, loadCustomerProfile, fetchPositions, fetchPortfolio],
  },
  {
    url: 'portfoliomanager',
    contexts: [
      fetchAssetClasses,
      optimizeCurrent,
      optimizePlan,
      optimizePlanWithIlliquids,
      optimizePlanWithoutIlliquids,
      allPositionsNotInPlan,
      negativePositions,
      noOptimizedPortfolios,
      obsoletePlan,
      cannotOptimizeCurrent,
    ],
  },
  {
    url: 'portfoliomanager/trade',
    contexts: [getInstrumentPrices],
  },
  {
    url: 'portfoliomanager/allocator',
    contexts: [fetchInstrumentGroups, saveInvestmentPlan],
  },
  {
    url: 'portfoliomanager/allocator/risk',
    contexts: [fetchExamplePortfolios],
  },
  {
    url: 'portfoliomanager/situationsummary',
    contexts: [geographicalValues],
  },
  {
    url: 'portfolioManager/allocator/plansummary',
    contexts: [geographicalValues],
  },
  {
    url: 'portfolioManager/allocator/constraints',
    contexts: [setConstraints],
  },
  {
    url: 'newCustomerSteps',
    contexts: [newCustomerSteps],
  },
  {
    url: 'portfolio/order/preview',
    contexts: [postSimulatedRisk, postActualRisk],
  },
];

export const selectHiddenOptimizationError = (state: RootState) => {
  const hasPlan = customerHasValidPlan(state);
  const allocator = isAllocator();

  if (!allocator) {
    if (hasPlan) {
      // withoutIlliquid weights are not shown in the view
      return errorKeys.optimizePlanWithIlliquids;
    }
    if (!hasPlan) {
      // withIlliquid weights are not shown in the view
      return errorKeys.optimizePlanWithoutIlliquids;
    }
  }
  return '';
};
