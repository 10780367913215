import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { useRouterHistory } from 'react-router';
import { createHistory } from 'history';
import Promise from 'bluebird';
import { syncHistoryWithStore } from 'react-router-redux';
import { configureStore } from './stores/store';
import Root from './containers/Root';
import { getRoutes } from './routes/Routes';
import { config } from './config';
import { hydrateInitialState } from './stores/hydrate';
import { init } from './core/index';

Promise.config({
  cancellation: true,
  warnings: {
    wForgottenReturn: false,
  },
});

const historyConfig = { basename: config.basePath };
const initialState = hydrateInitialState();
const baseHistory = useRouterHistory(createHistory)(historyConfig); // eslint-disable-line react-hooks/rules-of-hooks
const store = configureStore(baseHistory, initialState);
const routes = getRoutes(store);
const history = syncHistoryWithStore(baseHistory, store);

init(store);

ReactDOM.render(<Root history={history} routes={routes} store={store} />, document.getElementById('app'));
