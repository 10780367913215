import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { RadioButton } from 'features/allocator/common/RadioButton';
import { HelpText } from 'features/common/StyledComponents';

interface Props {
  useBackendConstraints: boolean;
  toggleUseBackendConstraints: () => void;
}

export const BackendConstraints = ({ useBackendConstraints, toggleUseBackendConstraints }: Props) => {
  return (
    <SkipConstraintsContainer>
      <Label data-testkey="useBackendConstraints">
        <RadioButton
          type="checkbox"
          checked={useBackendConstraints}
          onChange={toggleUseBackendConstraints}
          value={'skipBackendConstraints'}
        />

        {translate('allocator.constraintsView.useBackendConstraints')}
      </Label>

      <HelpText>
        {translate('allocator.constraintsView.useBackendConstraintsExplanation1')}
        <br />
        {translate('allocator.constraintsView.useBackendConstraintsExplanation2')}
      </HelpText>
    </SkipConstraintsContainer>
  );
};

const SkipConstraintsContainer = styled.span`
  margin: 0 2rem;
  display: inline-flex;
  flex-direction: column;

  p {
    margin: 1rem calc(1rem + 20px);
  }
`;

const Label = styled.label`
  display: flex;
  user-select: none;
  align-items: center;
  cursor: pointer;
`;
