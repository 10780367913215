import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/system';

const StyledFormControlLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-root': {
    marginRight: 0,
    width: '50%',
  },
  '& .MuiFormControlLabel-label': {
    fontSize: 16,
    fontFamily: 'Calibre',
  },
}) as typeof FormControlLabel;

export default StyledFormControlLabel;
