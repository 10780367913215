import { Map } from 'immutable';
import { push } from 'react-router-redux';
import { makeBlobUrl } from 'core/apiCall';
import { initArrayEditorValues } from 'actions';
import { PERSON } from 'constants/customerTypes';
import { AppThunk } from 'types/types';
import { Customer, CustomerType, ImmutableContract } from 'types/profileState';
import { ApiClient } from 'core/ApiClient';
import { ImmutableArrayEditorValues, Schema } from 'types/contractsState';
import { loadClientData, patchCustomerData, getPdf } from 'features/profileEdit/profileEditUtils';

export const CUSTOMER_FORM_REQUEST = 'CUSTOMER_FORM_REQUEST';
export const CUSTOMER_FORM_SUCCESS = 'CUSTOMER_FORM_SUCCESS';
const CUSTOMER_FORM_FAILURE = 'CUSTOMER_FORM_FAILURE';
export const PROCURATION_FORM_REQUEST = 'PROCURATION_FORM_REQUEST';
export const PROCURATION_FORM_SUCCESS = 'PROCURATION_FORM_SUCCESS';
const PROCURATION_FORM_FAILURE = 'PROCURATION_FORM_FAILURE';
export const SET_CUSTOMER_FORM = 'SET_CUSTOMER_FORM';
export const CHANGE_SECTION_FIELD = 'CHANGE_SECTION_FIELD';
export const BISNODE_REQUEST = 'BISNODE_REQUEST';
export const BISNODE_SUCCESS = 'BISNODE_SUCCESS';
export const BISNODE_COMPANY_SUCCESS = 'BISNODE_COMPANY_SUCCESS';
export const BISNODE_PROCURATIONINFO_SUCCESS = 'BISNODE_PROCURATIONINFO_SUCCESS';
export const BISNODE_FAILURE = 'BISNODE_FAILURE';
export const PATCH_CUSTOMER_REQUEST = 'PATCH_CUSTOMER_REQUEST';
export const PATCH_CUSTOMER_SUCCESS = 'PATCH_CUSTOMER_SUCCESS';
export const PATCH_CUSTOMER_FAILURE = 'PATCH_CUSTOMER_FAILURE';
export const COPY_VALUES = 'COPY_VALUES';
export const SAVE_PROFILE_ARRAY_VALUES = 'SAVE_PROFILE_ARRAY_VALUES';
export const DOCUMENTS_REQUEST = 'DOCUMENTS_REQUEST';
export const DOCUMENTS_SUCCESS = 'DOCUMENTS_SUCCESS';
export const DOCUMENTS_FAILURE = 'DOCUMENTS_FAILURE';
export const STATUSES_PDF_REQUEST = 'STATUSES_PDF_REQUEST';
export const STATUSES_PDF_SUCCESS = 'STATUSES_PDF_SUCCESS';
export const STATUSES_PDF_FAILURE = 'STATUSES_PDF_FAILURE';
export const TRADE_REGISTRY_PDF_REQUEST = 'TRADE_REGISTRY_PDF_REQUEST';
export const TRADE_REGISTRY_PDF_SUCCESS = 'TRADE_REGISTRY_PDF_SUCCESS';
export const TRADE_REGISTRY_PDF_FAILURE = 'TRADE_REGISTRY_PDF_FAILURE';
export const STATUSES_REQUEST = 'STATUSES_REQUEST';
export const STATUSES_SUCCESS = 'STATUSES_SUCCESS';
export const STATUSES_FAILURE = 'STATUSES_FAILURE';
export const TRADE_REGISTRY_REQUEST = 'TRADE_REGISTRY_REQUEST';
export const TRADE_REGISTRY_SUCCESS = 'TRADE_REGISTRY_SUCCESS';
export const TRADE_REGISTRY_FAILURE = 'TRADE_REGISTRY_FAILURE';
export const VALIDATION = 'VALIDATION';
export const CUSTOMER_STAKEHOLDERS_REQUEST = 'CUSTOMER_STAKEHOLDERS_REQUEST';
export const CUSTOMER_STAKEHOLDERS_SUCCESS = 'CUSTOMER_STAKEHOLDERS_SUCCESS';
export const CUSTOMER_STAKEHOLDERS_FAILURE = 'CUSTOMER_STAKEHOLDERS_FAILURE';
export const COMPANY_IDENTIFY_AND_SCREEN_REQUEST = 'COMPANY_IDENTIFY_AND_SCREEN_REQUEST';
export const COMPANY_IDENTIFY_AND_SCREEN_SUCCESS = 'COMPANY_IDENTIFY_AND_SCREEN_SUCCESS';
export const COMPANY_IDENTIFY_AND_SCREEN_FAILURE = 'COMPANY_IDENTIFY_AND_SCREEN_FAILURE';
export const UPDATE_STAKEHOLDERS_REQUEST = 'UPDATE_STAKEHOLDERS_REQUEST';
export const UPDATE_STAKEHOLDERS_SUCCESS = 'UPDATE_STAKEHOLDERS_SUCCESS';
export const UPDATE_STAKEHOLDERS_FAILURE = 'UPDATE_STAKEHOLDERS_FAILURE';
export const SCREEN_PERSON_REQUEST = 'SCREEN_PERSON_REQUEST';
export const SCREEN_PERSON_SUCCESS = 'SCREEN_PERSON_SUCCESS';
export const SCREEN_PERSON_FAILURE = 'SCREEN_PERSON_FAILURE';

export const loadProcurationForm = (clientId: string) => {
  return {
    types: [PROCURATION_FORM_REQUEST, PROCURATION_FORM_SUCCESS, PROCURATION_FORM_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/client/${clientId}/procurationinfo`),
  };
};

const customerFormRequest = () => {
  return <const>{
    type: CUSTOMER_FORM_REQUEST,
  };
};

const customerFormSuccess = (result: Customer) => {
  return <const>{
    type: CUSTOMER_FORM_SUCCESS,
    result,
  };
};

const customerFormFailure = (error: Error) => {
  return <const>{
    type: CUSTOMER_FORM_FAILURE,
    error,
  };
};

export const loadCustomerForm = (clientId: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(customerFormRequest());
    const token = getState().oidc.user.access_token;
    loadClientData(token, clientId)
      .then((result) => {
        dispatch(customerFormSuccess(result));

        if (result.customerType !== PERSON) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          dispatch(loadProcurationForm(result.id));
        }
      })
      .catch((error) => {
        dispatch(customerFormFailure(error));
      });
  };
};

const setCustomerFormAction = (customer: Customer) => {
  return <const>{
    type: SET_CUSTOMER_FORM,
    customer,
  };
};

export const setCustomerForm =
  (customer: Customer): AppThunk =>
  (dispatch) => {
    if (customer.customerType !== PERSON) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      dispatch(loadProcurationForm(customer.id));
    }
    dispatch(setCustomerFormAction(customer));
  };

export const validation = (schema: Schema) => {
  return <const>{
    type: VALIDATION,
    schema,
  };
};

const changeSectionFieldAction = (key: string, value: string) => {
  return <const>{
    type: CHANGE_SECTION_FIELD,
    key,
    value,
  };
};

export const changeSectionField = (key: string, value: string, schema: Schema): AppThunk => {
  return (dispatch) => {
    dispatch(changeSectionFieldAction(key, value));
    dispatch(validation(schema));
  };
};

export const getBisnodeData = (id: string) => {
  return {
    types: [BISNODE_REQUEST, BISNODE_SUCCESS, BISNODE_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/person/${id}`),
  };
};

export const getCompanyRegistryData = (id: string) => {
  return {
    types: [BISNODE_REQUEST, BISNODE_COMPANY_SUCCESS, BISNODE_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/company/${id}`),
  };
};

export const getProcurationInfo = (id: string) => {
  return {
    types: [BISNODE_REQUEST, BISNODE_PROCURATIONINFO_SUCCESS, BISNODE_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/company/${id}/procurationinfo`),
  };
};

export const loadStakeholders = (customerId: string) => {
  return {
    types: [CUSTOMER_STAKEHOLDERS_REQUEST, CUSTOMER_STAKEHOLDERS_SUCCESS, CUSTOMER_STAKEHOLDERS_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/client/${customerId}/stakeholders`),
  };
};

export const getCompanyIdentifyAndScreen = (customerId: string) => {
  return {
    types: [
      COMPANY_IDENTIFY_AND_SCREEN_REQUEST,
      COMPANY_IDENTIFY_AND_SCREEN_SUCCESS,
      COMPANY_IDENTIFY_AND_SCREEN_FAILURE,
    ],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/${customerId}/identify-and-screen`),
  };
};

export const updateStakeholders = (customerId: string): AppThunk => {
  return (dispatch) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({
      types: [UPDATE_STAKEHOLDERS_REQUEST, UPDATE_STAKEHOLDERS_SUCCESS, UPDATE_STAKEHOLDERS_FAILURE],
      promise: (client: ApiClient) => {
        return client.put(`/api/v1/businessinfo/${customerId}/identify-and-screen`).then(() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          dispatch(loadStakeholders(customerId));
        });
      },
    });
};

export const screenPerson = (customerId: string): AppThunk => {
  return (dispatch) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dispatch({
      types: [SCREEN_PERSON_REQUEST, SCREEN_PERSON_SUCCESS, SCREEN_PERSON_FAILURE],
      promise: (client: ApiClient) => client.post(`/api/v1/businessinfo/${customerId}/screen-person`),
    });
};

const patchCustomerRequest = () => {
  return <const>{
    type: PATCH_CUSTOMER_REQUEST,
  };
};

const patchCustomerSuccess = (data: ImmutableContract) => {
  return <const>{
    type: PATCH_CUSTOMER_SUCCESS,
    data,
  };
};

const patchCustomerFailure = (error: string) => {
  return <const>{
    type: PATCH_CUSTOMER_FAILURE,
    error,
  };
};

export const updateCustomerData = (id: string, data: ImmutableContract, customerType: CustomerType): AppThunk => {
  return (dispatch, getState) => {
    dispatch(patchCustomerRequest());
    patchCustomerData(id, data, getState().oidc.user.access_token)
      .then(() => {
        let to;
        if (customerType === PERSON) {
          to = `/customer/${id}`;
        } else {
          to = `/customer/${id}/summary`;
        }
        dispatch(patchCustomerSuccess(data));
        dispatch(push(to));
      })
      .catch((error) => {
        dispatch(patchCustomerFailure(error));
      });
  };
};

const copyValuesAction = (values: Map<string, object>) => {
  return <const>{
    type: COPY_VALUES,
    values,
  };
};

export const copyValues = (values: Map<string, object>, schema: Schema): AppThunk => {
  return (dispatch) => {
    dispatch(copyValuesAction(values));
    dispatch(validation(schema));
  };
};

const saveProfileArrayValues = (key: string, index: number, arrayEditorValues: ImmutableArrayEditorValues) => {
  return <const>{
    type: SAVE_PROFILE_ARRAY_VALUES,
    key,
    index,
    arrayEditorValues,
  };
};

export const saveArrayEditorValue = (_contractType: string, key: string, index: number): AppThunk => {
  return (dispatch, getState) => {
    const arrayEditorValues = getState().contracts.arrayEditorValues;
    dispatch(initArrayEditorValues({}));
    dispatch(saveProfileArrayValues(key, index, arrayEditorValues));
  };
};

export const updateStatuses = (businessId: string) => {
  return {
    types: [STATUSES_REQUEST, STATUSES_SUCCESS, STATUSES_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/company/${businessId}/statuses`),
  };
};

export const updateTradeRegistry = (businessId: string, name: string) => {
  return {
    types: [TRADE_REGISTRY_REQUEST, TRADE_REGISTRY_SUCCESS, TRADE_REGISTRY_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/businessinfo/company/${businessId}/tradegistry?name=${name}`),
  };
};

export const loadDocuments = (clientId: string) => {
  return {
    types: [DOCUMENTS_REQUEST, DOCUMENTS_SUCCESS, DOCUMENTS_FAILURE],
    promise: (client: ApiClient) => client.get(`/api/v1/client/files/${clientId}`),
  };
};

const statusesPdfRequest = () => {
  return <const>{
    type: STATUSES_PDF_REQUEST,
  };
};

const statusesPdfSuccess = (path: string, blobUrl: string) => {
  return <const>{
    type: STATUSES_PDF_SUCCESS,
    path,
    blobUrl,
  };
};

export const getStatusesPdf = (path: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(statusesPdfRequest());
    return getPdf(path, getState().oidc.user.access_token).then((blob: Blob) => {
      makeBlobUrl(blob, (blobUrl) => dispatch(statusesPdfSuccess(path, blobUrl)));
    });
  };
};

const tradeRegistryPdfRequest = () => {
  return <const>{
    type: TRADE_REGISTRY_PDF_REQUEST,
  };
};

const tradeRegistryPdfSuccess = (path: string, blobUrl: string) => {
  return <const>{
    type: TRADE_REGISTRY_PDF_SUCCESS,
    path,
    blobUrl,
  };
};

export const getTradeRegistryPdf = (path: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(tradeRegistryPdfRequest());
    return getPdf(path, getState().oidc.user.access_token).then((blob: Blob) => {
      makeBlobUrl(blob, (blobUrl) => dispatch(tradeRegistryPdfSuccess(path, blobUrl)));
    });
  };
};

export type ProfileEditAction = ReturnType<
  | typeof validation
  | typeof patchCustomerRequest
  | typeof patchCustomerSuccess
  | typeof patchCustomerFailure
  | typeof customerFormRequest
  | typeof customerFormSuccess
  | typeof customerFormFailure
  | typeof setCustomerFormAction
  | typeof changeSectionFieldAction
  | typeof copyValuesAction
  | typeof saveProfileArrayValues
  | typeof statusesPdfRequest
  | typeof statusesPdfSuccess
  | typeof tradeRegistryPdfRequest
  | typeof tradeRegistryPdfSuccess
>;
