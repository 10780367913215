import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { selectCurrentWeights, selectPortfolioName } from 'features/allocator/allocatorSelectors';
import { Flex } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import {
  isIgnoredPortfolio,
  totalCurrentPortfolioValue,
  totalCurrentPortfolioWeight,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { AllocatorPortfolio } from 'types/investmentPlanState';

interface Props {
  cashAmount: number;
  portfolio: AllocatorPortfolio;
  maxWeight: number;
}

export const CustomerSummaryPortfolio = ({ portfolio, maxWeight, cashAmount }: Props) => {
  const currentWeights = useSelector(selectCurrentWeights);
  const portfolioName = useSelector(selectPortfolioName(portfolio.portfolioId));
  const isIgnored = isIgnoredPortfolio(portfolio);

  const totalWeight = () => {
    if (isIgnored) {
      return totalCurrentPortfolioValue(portfolio.portfolioId, currentWeights);
    }
    return totalCurrentPortfolioWeight(portfolio.portfolioId, currentWeights);
  };

  return (
    <tr>
      <TDAlignRight>{isIgnored ? '–' : formatToDecimals(totalWeight(), 1, '%')}</TDAlignRight>
      <TDAlignRight>{formatToDecimals((isIgnored ? 1 : cashAmount) * totalWeight(), 0, '€')}</TDAlignRight>
      <BarTD>
        {!isIgnored && (
          <BarContainer>
            <Bar width={(totalWeight() || 0) / (maxWeight || 1)} />
          </BarContainer>
        )}
      </BarTD>
      <td>{portfolioName}</td>
    </tr>
  );
};

const TDAlignRight = styled.td`
  text-align: right;
`;

const Bar = styled.div<BarProps>`
  width: ${(props) => props.width * 100}%;
  height: 12px;
  background: ${colors.blue};
`;

const BarTD = styled.td`
  && {
    padding-left: 0.5rem;
  }
`;

const BarContainer = styled(Flex)`
  align-items: flex-end;
  width: 100px;
`;

interface BarProps {
  width: number;
}
