import React from 'react';
import { useSelector } from 'react-redux';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { CompareToType } from 'types/types';
import { DistributionTable } from 'features/allocator/common/DistributionTable';
import { ASSET_CATEGORY_ORDER } from 'constants/allocator';
import { AssetCategoryWeights } from 'types/weightsState';
import { Spinner } from 'features/common/Spinner';
import { Card } from 'features/allocator/common/Card';
import { selectAssetClasses } from 'features/allocator/allocatorSelectors';

interface Props {
  assetCategoryWeights: AssetCategoryWeights[];
  compareTo: CompareToType;
  loading?: boolean;
}

export const AssetClassDistribution = ({ assetCategoryWeights, compareTo, loading = false }: Props) => {
  const assetClasses = useSelector(selectAssetClasses);

  const groupedAssetClasses = groupBy(assetClasses, 'assetCategory');

  const weights = Object.keys(groupedAssetClasses).map((assetCategory) => {
    return {
      name: assetCategory,
      assetClasses: groupedAssetClasses[assetCategory].map((assetClass) => ({
        ...assetClass,
        weight: assetCategoryWeights
          ?.find((g) => g.name === assetCategory)
          ?.assetClasses.find((j) => j.assetClassId === assetClass.assetClassId)?.weight,
      })),
    };
  });

  return (
    <GridCard>
      {loading ? (
        <Spinner />
      ) : (
        ASSET_CATEGORY_ORDER.map((assetCategory) => {
          const assetClasses = weights.find((a) => a.name === assetCategory)?.assetClasses || [];
          const assetClassesOrder = assetClasses.map((i) => i.assetClassId);
          const sortedAssetClasses = assetClasses.sort(
            (a, b) => assetClassesOrder.indexOf(a.assetClassId) - assetClassesOrder.indexOf(b.assetClassId)
          );

          return (
            <DistributionTable
              key={assetCategory}
              items={sortedAssetClasses}
              itemType="assetClass"
              header={assetCategory}
              compareTo={compareTo}
            />
          );
        })
      )}
    </GridCard>
  );
};

const GridCard = styled(Card)`
  display: grid;
  grid-gap: 1rem;
`;
