import { sortBy, uniqBy } from 'lodash';
import {
  COUNTRIES_SUCCESS,
  BANKERS_SUCCESS,
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  CHANGE_CONTRACT_LANGUAGE,
  IndexAction,
} from 'actions/index';
import { CLEAR_CONTEXT_ERRORS, SET_ERROR, CLEAR_CONTEXT_ERROR, ErrorAction } from 'features/errors/errorActions';
import { CommonState } from 'types/commonState';

const initialState: CommonState = {
  locale: 'fi',
  countries: {},
  user: null,
  bankers: [],
  notification: '',
  showNotification: false,
  errors: [],
  contractLanguage: 'fi',
};

export function commonReducer(state = initialState, action: ErrorAction | IndexAction) {
  switch (action.type) {
    case CLEAR_CONTEXT_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    case CLEAR_CONTEXT_ERROR: {
      return {
        ...state,
        errors: state.errors.filter((i) => i.context !== action.context),
      };
    }

    case SET_ERROR: {
      const allErrors = state.errors.concat({
        context: action.payload.context,
        errorMessage: action.payload.error,
      });
      return {
        ...state,
        errors: uniqBy(allErrors, 'errorMessage'),
      };
    }

    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.result ? action.result.nameByCode : {},
      };

    case BANKERS_SUCCESS:
      return {
        ...state,
        bankers: sortBy(
          action.result.map((b) => ({
            value: b.id,
            label: b.fullName,
          })),
          ['label']
        ),
      };

    case SHOW_NOTIFICATION:
      return {
        ...state,
        notification: action.message,
        showNotification: true,
      };

    case HIDE_NOTIFICATION:
      return {
        ...state,
        notification: '',
        showNotification: false,
      };

    case CHANGE_CONTRACT_LANGUAGE:
      return {
        ...state,
        contractLanguage: action.message,
      };

    default:
      return state;
  }
}
