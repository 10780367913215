import React from 'react';
import { Link } from 'react-router';
import classnames from 'classnames';
import translate from 'counterpart';
import { NavBar } from 'features/common/NavBar';
import styles from './ContractsNav.scss';
import { ModuleStatus } from 'features/contracts/contracts.types';

interface ReadyButtonProps {
  readyTo: string;
}

function ReadyButton(props: ReadyButtonProps) {
  return (
    <div>
      <Link data-testkey="ready-button" to={props.readyTo} className={classnames('button', styles.finishedBtn)}>
        <span>{translate(`contract.ready`)}</span>
      </Link>
    </div>
  );
}

interface CloseButtonProps {
  closeTo: string;
}

function CloseButton(props: CloseButtonProps) {
  return (
    <div className={styles.right}>
      <Link to={props.closeTo}>
        <span data-testkey="button-return">{translate(`contract.back`)}</span>
        <i className="icon icon-close" />
      </Link>
    </div>
  );
}

interface ProgressBarProps {
  finished: number;
}

function ProgressBar(props: ProgressBarProps) {
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressBar} style={{ width: `${props.finished}%` }} />
    </div>
  );
}

interface LeftContentProps {
  contractName: string;
  moduleName: string;
}

const LeftContent = ({ contractName, moduleName }: LeftContentProps) => (
  <div style={{ fontSize: '16px' }}>
    <span>
      {`${contractName} /`} {moduleName}
    </span>
  </div>
);

interface RightContentProps {
  moduleStatus: ModuleStatus;
  closeLink: string;
  finished: number;
  isRiskConfirmationStep: boolean;
}

const RightContent = ({ moduleStatus, closeLink, finished, isRiskConfirmationStep }: RightContentProps) => {
  let rightBtn;

  if (finished === 100) {
    rightBtn = <ReadyButton readyTo={closeLink} />;
  } else {
    rightBtn = <CloseButton closeTo={closeLink} />;
  }
  const linkTypeKey = 'textLinkKey';
  const tableKey = 'tableKey';
  // filtering of link types (they should not be counted in fields, even though they are visible)
  const doneFiltered = moduleStatus.fieldsDone.filter(
    (field) => field.key && !field.key.includes(linkTypeKey) && !field.key.includes(tableKey)
  );
  const countFiltered = moduleStatus.fieldsVisible.filter(
    (field) => field.key && !field.key.includes(linkTypeKey) && !field.key.includes(tableKey)
  );

  const done = doneFiltered.length;
  const count = countFiltered.length;
  return (
    <div className={styles.rightWrapper}>
      {!isRiskConfirmationStep && count > 0 && (
        <div className={classnames(styles.left, styles.status)} style={{ display: 'block', position: 'relative' }}>
          <div>
            <div className="text-center">
              <span>{translate(`contract.answered`)}</span>:
            </div>
            <div className="text-center">
              {done}/{count}
            </div>
          </div>
        </div>
      )}
      {rightBtn}
    </div>
  );
};

interface NavModuleProps {
  contractName: string;
  moduleName: string;
  closeLink: string;
  moduleStatus: ModuleStatus;
  saveContractDrafts: () => void;
  enableSave: boolean;
}

export default function NavModule(props: NavModuleProps) {
  const { closeLink, moduleStatus, saveContractDrafts, enableSave, ...rest } = props;
  const linkTypeKey = 'textLinkKey';
  const tableKey = 'tableKey';
  const doneFiltered = moduleStatus.fieldsDone.filter(
    (field) => field.key && !field.key.includes(linkTypeKey) && !field.key.includes(tableKey)
  );
  const countFiltered = moduleStatus.fieldsVisible.filter(
    (field) => field.key && !field.key.includes(linkTypeKey) && !field.key.includes(tableKey)
  );
  let finished = (doneFiltered.length / countFiltered.length) * 100;
  if (countFiltered.length === 0) {
    finished = 100;
  }

  const isRiskConfirmationStep = props.moduleName === 'Riskiluku';

  return (
    <section>
      <NavBar
        leftContent={<LeftContent {...rest} />}
        rightContent={
          <RightContent
            moduleStatus={moduleStatus}
            closeLink={closeLink}
            finished={finished}
            isRiskConfirmationStep={isRiskConfirmationStep}
          />
        }
        showModalOnExit={enableSave}
        modalTitle={translate('contract.toFrontPageTitle')}
        modalContent={translate('contract.toFrontPageContent')}
        acceptAction={saveContractDrafts}
        acceptLabel="contract.saveAndNavigate"
      />
      <ProgressBar finished={finished} />
    </section>
  );
}
