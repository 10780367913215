import React from 'react';
import translate from 'counterpart';
import styles from './404.scss';
import img from 'images/404.svg';

const Error = () => {
  return (
    <div className={styles.error}>
      <img src={img} />
      <p>{translate(`common.errorOccured`)}</p>
    </div>
  );
};

export default Error;
