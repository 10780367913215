import React from 'react';
import {
  AttachmentContainer,
  FlexAlignCenterJustifyBetween,
  FlexBoxCol,
  FlexRowCenterFullHeight,
  LeftContainer,
  LeftIcon,
  LeftIconContainer,
  SmallText,
} from 'features/contracts/components/attachment//styledComponents';
import { Entry } from 'features/contracts/contracts.types';

const SuitabilityItem = (props: { value: Entry }) => {
  const { value } = props;
  return (
    <AttachmentContainer>
      <FlexAlignCenterJustifyBetween>
        <FlexRowCenterFullHeight>
          <LeftContainer>
            <LeftIconContainer notClickable={true}>
              <LeftIcon className={`icon icon-document`} />
            </LeftIconContainer>
          </LeftContainer>
          <FlexBoxCol>
            {value.name}
            <SmallText>Lisää liitedokumentteihin aiemmin tehty soveltuvuusarviointi</SmallText>
          </FlexBoxCol>
        </FlexRowCenterFullHeight>
        <FlexRowCenterFullHeight />
      </FlexAlignCenterJustifyBetween>
    </AttachmentContainer>
  );
};

export default SuitabilityItem;
