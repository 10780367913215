import React from 'react';
import translate from 'counterpart';
import { every } from 'lodash';
import { crmPersonFields } from 'constants/crmPersonFields';
import { FoundPerson } from 'types/ordersState';

interface Props {
  data: FoundPerson;
}

const Person = ({ data }: Props) => {
  let rows;
  if (data && every(data, (d) => !!d)) {
    rows = crmPersonFields.map((f, i) => (
      <div className="row" key={i}>
        <div className="column small-5" style={{ fontWeight: 'bold' }}>
          {translate(`newContract.${f}`)}
        </div>

        <div className="column small-7">{data[f]}</div>
      </div>
    ));
  }

  return <div>{rows}</div>;
};

export default Person;
