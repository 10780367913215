import React from 'react';
import translate from 'counterpart';
import iconMale from 'images/Icon_male.svg';
import iconFemale from 'images/Icon_female.svg';
import iconAndrogyne from 'images/Icon_androgyne.svg';
import iconDigiCustomer from 'images/icon_digi_customer.svg';
import { Customer } from 'types/profileState';

export function getProfileImage(profile: Customer, isDigiCustomer = false) {
  const type = profile.customerType;
  try {
    if (isDigiCustomer) {
      return iconDigiCustomer;
    }
    if (type === 'person') {
      const ssn = profile.ssn;
      if (ssn && ssn.length === 11) {
        const part = ssn.substring(7, 10);
        return parseInt(part, 10) % 2 ? iconMale : iconFemale;
      }
    }
  } catch (e) {
    // continue anyway since we have a fallback image
  }
  return iconAndrogyne;
}

interface Props {
  isBusy: boolean;
  profile: Customer;
  profileImageStyle: string;
  isDigiCustomer?: boolean;
  riskProfileNotFoundStyle: string;
}

export const ProfileHeading = ({
  isBusy,
  profile,
  profileImageStyle,
  isDigiCustomer,
  riskProfileNotFoundStyle,
}: Props) => {
  const renderBody = () => {
    if (!isBusy) {
      return (
        <div className="columns">
          <div className="row align-justify">
            <h4>{profile.header}</h4>
          </div>
          <section className="row">
            <div>
              <b>{profile.state}</b>
            </div>
            <div>{isDigiCustomer ? translate(`customer.digiCustomer`) : profile.accountManagerName}</div>
          </section>

          {/* Customer has Myty Contract Signed date */}
          {profile?.mytyContractSigned && profile?.mytyContractSigned.trim().length > 0 ? (
            <section className="row">
              <div>{translate(`customer.riskTolerance`)}</div>
              <div>{profile.riskProfile || '-'}</div>
            </section>
          ) : (
            /* Customer does not have Myty Contract Signed date */
            <>
              <section className="row">
                <div>
                  <b>{translate(`customer.riskTolerance`)}</b>
                </div>
                <div className={riskProfileNotFoundStyle}>{translate(`customer.riskProfileNotFound`)}</div>
              </section>

              <section className="row">
                <div>
                  {translate(`customer.previousRiskProfile`)}: {profile.selectedRiskLevel || '-'}
                </div>
              </section>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div className="columns">
          <div className="row align-justify">
            <h4>Ladataan, odota hetki...</h4>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row">
      <div className="large-3">
        <img className={profileImageStyle} src={getProfileImage(profile, isDigiCustomer ?? false)} />
      </div>
      {renderBody()}
    </div>
  );
};
