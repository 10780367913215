import translate from 'counterpart';
import { ErrorContext } from 'types/types';

export const SET_ERROR = 'SET_ERROR';
export const CLEAR_CONTEXT_ERRORS = 'CLEAR_CONTEXT_ERRORS';
export const CLEAR_CONTEXT_ERROR = 'CLEAR_CONTEXT_ERROR';

export const setError = ({ error, context }: { error?: string; context: ErrorContext }) => {
  return <const>{
    type: SET_ERROR,
    payload: {
      error: error || translate(`errors.${context}`),
      context,
    },
  };
};

export const clearError = (context: ErrorContext) => {
  return <const>{
    type: CLEAR_CONTEXT_ERROR,
    context,
  };
};

export const clearAllErrors = () => {
  return <const>{
    type: CLEAR_CONTEXT_ERRORS,
  };
};

export type ErrorAction = ReturnType<typeof setError | typeof clearError | typeof clearAllErrors>;
