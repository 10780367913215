import { loadCustomerProfile } from 'features/profile/profileActions';
import { fetchContract } from 'features/orders/ordersActions';
import { PositionRequestResponse, PortfolioRequestResponse } from 'features/portfolio/portfolio.types';
import { clearError, setError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { AppThunk } from 'types/types';
import { getPortfolios, getPositions, hasNegativePositions } from 'features/portfolio/portfolioUtils';
import PromiseStore from 'core/PromiseStore';

// Action types

export const PORTFOLIO_REQUEST = 'PORTFOLIO_REQUEST';
export const PORTFOLIO_SUCCESS = 'PORTFOLIO_SUCCESS';
export const PORTFOLIO_FAILURE = 'PORTFOLIO_FAILURE';
export const POSITIONS_REQUEST = 'POSITIONS_REQUEST';
export const POSITIONS_SUCCESS = 'POSITIONS_SUCCESS';
export const POSITIONS_FAILURE = 'POSITIONS_FAILURE';
export const INITIALIZE_PORTFOLIOS_AND_POSITIONS = 'INITIALIZE_PORTFOLIOS_AND_POSITIONS';
export const SET_LOADING_PORTFOLIOS = 'SET_LOADING_PORTFOLIOS';
export const SET_LOADING_POSITIONS = 'SET_LOADING_POSITIONS';

// Actions

export const fetchPortfolio = (id: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(clearError(errorKeys.fetchPortfolio));
    try {
      const token = getState().oidc.user.access_token;
      dispatch(portfolioRequestStart());
      const result = await getPortfolios(id, token);
      dispatch(portfolioRequestSuccess(result));
    } catch (error) {
      dispatch(portfolioRequestFailure());
      dispatch(setError({ context: errorKeys.fetchPortfolio }));
    }
  };
};

export const cancelFetchPortfolio = (): AppThunk => {
  return (dispatch) => {
    PromiseStore.getPortfolios.cancel();
    dispatch(setLoadingPortfolios(false));
  };
};

const setLoadingPortfolios = (loading: boolean) => {
  return <const>{
    type: SET_LOADING_PORTFOLIOS,
    loading,
  };
};

const portfolioRequestStart = () => {
  return <const>{
    type: PORTFOLIO_REQUEST,
  };
};

const portfolioRequestSuccess = (result: PortfolioRequestResponse[]) => {
  return <const>{
    type: PORTFOLIO_SUCCESS,
    result,
  };
};

const portfolioRequestFailure = () => {
  return <const>{
    type: PORTFOLIO_FAILURE,
  };
};

export const fetchPositions = (id: string): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    dispatch(clearError(errorKeys.fetchPositions));
    try {
      const token = getState().oidc.user.access_token;
      dispatch(positionRequestStart());
      const result = await getPositions(id, token);
      if (hasNegativePositions(result.positions)) {
        dispatch(setError({ context: errorKeys.negativePositions }));
      }
      dispatch(positionRequestSuccess(result));
    } catch (error) {
      dispatch(setError({ context: errorKeys.fetchPositions }));
      dispatch(positionRequestFailure());
    }
  };
};

export const cancelFetchPositions = (): AppThunk => {
  return (dispatch) => {
    PromiseStore.getPositions.cancel();
    dispatch(setLoadingPositions(false));
  };
};

const setLoadingPositions = (loading: boolean) => {
  return <const>{
    type: SET_LOADING_POSITIONS,
    loading,
  };
};

const positionRequestStart = () => {
  return <const>{
    type: POSITIONS_REQUEST,
  };
};

const positionRequestSuccess = (result: PositionRequestResponse) => {
  return <const>{
    type: POSITIONS_SUCCESS,
    result,
  };
};

const positionRequestFailure = () => {
  return <const>{
    type: POSITIONS_FAILURE,
  };
};

const getContract = (customerType: string, id: string) => {
  return fetchContract(customerType === 'person' ? 'yksToimeksianto' : 'yhteisoToimeksianto', id);
};

export const fetchPortfolioData = (id: string): AppThunk => {
  return async (dispatch, getState) => {
    let customer = getState().profile.customer;
    dispatch(fetchPortfolio(id))
      .then(() => {
        if (customer.isEmpty() || customer.get('id') !== id) {
          dispatch(loadCustomerProfile(id))
            .then(() => {
              customer = getState().profile.customer;
              dispatch(getContract(customer.get('customerType'), id));
            })
            .catch((error) => {
              console.error(error); // eslint-disable-line no-console
            });
        } else {
          dispatch(getContract(customer.get('customerType'), id));
        }
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
    if (getState().portfolio.positions.length === 0) {
      await dispatch(fetchPositions(id));
    }
  };
};

export const initializePortfoliosAndPositions = () => {
  return <const>{
    type: INITIALIZE_PORTFOLIOS_AND_POSITIONS,
  };
};

export type PortfolioAction = ReturnType<
  | typeof setLoadingPortfolios
  | typeof portfolioRequestStart
  | typeof portfolioRequestSuccess
  | typeof portfolioRequestFailure
  | typeof setLoadingPositions
  | typeof positionRequestStart
  | typeof positionRequestSuccess
  | typeof positionRequestFailure
  | typeof initializePortfoliosAndPositions
>;
