import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import translate from 'counterpart';
import styles from './ManualSignatorySelection.scss';

interface Props {
  signers: number;
  handleChangingManualSigners: (n: number) => void;
}

export default class ManualSignatorySelection extends React.Component<Props> {
  items: JSX.Element[];

  constructor(props: Props) {
    super(props);
    this.items = this.createItems();
  }

  static defaultProps = {
    signers: [],
  };

  componentDidMount() {
    this.props.handleChangingManualSigners(this.props.signers);
  }

  handleOnChange = (event: SelectChangeEvent) => {
    this.props.handleChangingManualSigners(event.target.value as unknown as number);
  };

  createItems = () => {
    const items = [];

    for (let m = 1; m < 11; m++) {
      items.push(
        <MenuItem
          value={m}
          key={m}
          sx={{
            '&.MuiMenuItem-root': {
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
            },
            '&.Mui-selected': {
              fontFamily: 'Calibre, sans-serif',
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
              ':hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.06)',
              },
            },
          }}
        >
          {m}
        </MenuItem>
      );
    }

    return items;
  };

  render() {
    return (
      <div className={styles.header}>
        <span className={styles.label}>{translate(`contract.signerAmount`)}</span>
        <Select
          value={this.props.signers as unknown as string}
          onChange={this.handleOnChange}
          variant="standard"
          sx={{
            '&.MuiPopover-root': {
              top: '50px',
            },
            '& .MuiMenuItem-root': {
              fontSize: '16px',
              fontFamily: 'Calibre, sans-serif',
            },
            '&.MuiInputBase-root': {
              minWidth: '200px',
              marginBottom: '15px',
              color: 'rgba(0, 0, 0, 1)',
              '&::after': {
                borderBottomColor: '#000000',
              },
              ':focus': {
                backgroundColor: '#ffffff',
              },
            },
            '& .MuiSelect-select': {
              backgroundColor: '#FFF',
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
            },
            '& .MuiSelect-nativeInput': {
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
            },
          }}
        >
          {this.items}
        </Select>
      </div>
    );
  }
}
