import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { fontSize, FlexAlignCenter } from 'features/common/StyledComponents';
import { optimizationForecastTypes } from 'constants/allocator';
import { RootState } from 'types/rootState';
import { optimize } from 'features/weights/weightsThunks';
import { isOptimizingPlan, selectPlanState, selectTestRisk } from 'features/allocator/allocatorSelectors';
import { setOptimizationForecastType } from 'features/allocator/investmentPlan/investmentPlanActions';
import { useAppDispatch } from 'core/hooks';

export const OptimizationSwitch = () => {
  const dispatch = useAppDispatch();

  const testRiskLevel = useSelector(selectTestRisk);
  const planState = useSelector(selectPlanState);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const optimizationForecastType = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.optimizationForecastType
  );

  const optimizationForecastOptions = Object.values(optimizationForecastTypes);
  const currentIndex = optimizationForecastOptions.indexOf(optimizationForecastType);

  const onOptimizationForecastChange = () => {
    const nextIndex = (currentIndex + 1) % 2;
    const nextForecastType = optimizationForecastOptions[nextIndex];
    dispatch(setOptimizationForecastType(nextForecastType));
    optimizeIfNeeded();
  };

  const optimizeIfNeeded = () => {
    testRiskLevel === 0
      ? dispatch(optimize({ useCache: true }))
      : dispatch(optimize({ payload: { customerRiskLevel: testRiskLevel }, useCache: true }));
  };

  const disabled = optimizingPlan || planState === 'lockedPlan';

  return (
    <Container>
      <label>{translate('allocator.overviewView.optimizationManner')}</label>
      <SwitchContainer
        onClick={!disabled ? onOptimizationForecastChange : undefined}
        disabled={disabled}
        index={currentIndex}
        data-testkey="optimisation-switch"
      >
        <Options index={currentIndex}>
          <Option>
            <div>{translate(`allocator.overviewView.${optimizationForecastTypes.COMPANY}`)}</div>
          </Option>
          <Option>
            <div>{translate(`allocator.overviewView.${optimizationForecastTypes.NEUTRAL}`)}</div>{' '}
          </Option>
        </Options>
        <ChevronContainer>
          <Chevron index={0} />
          <Chevron index={1} />
        </ChevronContainer>
      </SwitchContainer>
    </Container>
  );
};

const Container = styled(FlexAlignCenter)`
  width: 100%;
  justify-content: flex-end;

  label {
    color: ${colors.dark_gray};
    font-size: ${fontSize.smaller};
    margin-right: 1rem;
  }
`;

interface StyledSwitchProps {
  disabled: boolean;
  index: number;
}

const elementHeight = '44px';

const SwitchContainer = styled.div<StyledSwitchProps>`
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.15);
  height: ${elementHeight};
  padding-right: 2.5rem;
  background: white;
  border: 1px solid ${colors.med_gray};
  border-radius: 3px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  overflow: hidden;
  position: relative;
  justify-content: center;
  text-align: center;
`;

interface StyledPropsWithIndex {
  index: number;
}

const Options = styled.div<StyledPropsWithIndex>`
  white-space: pre;
  margin-top: ${(props) => (props.index === 1 ? `-${elementHeight}` : 0)};
  transition: all 0.2s;
  height: ${elementHeight};
`;

const Option = styled.div`
  padding-left: 1rem;
  height: ${elementHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ChevronContainer = styled(FlexAlignCenter)`
  height: ${elementHeight};
  width: 30px;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
`;

const Chevron = styled.div<StyledPropsWithIndex>`
  margin-bottom: 4px;
  border-top: 2px solid;
  border-left: 2px solid;
  border-color: #666;
  height: 8px;
  width: 8px;
  position: absolute;
  transform: rotateZ(${(props) => (props.index === 0 ? '45deg' : '-135deg')});
  ${(props) => props.index === 0 && `top: 13px`};
  ${(props) => props.index === 1 && `bottom: 11px`};
`;
