import { RootState } from 'types/rootState';
import { Customer } from 'types/profileState';
import { User } from 'types/types';
import { DIGI_ACCOUNT_MANAGER } from 'constants/accountManagerTypes';

// search results is empty when landing on search page, in that case use customer in lastsearched
export const selectIsDigiCustomer = (state: RootState, customerId: string) => {
  const list: (Customer | User)[] = state.search.searchResults.size
    ? state.search.searchResults.toJS()
    : state.search.lastUsers.searched;
  const index: number = list.map((e) => e?.customerId).indexOf(customerId);
  const customer: Customer | User = list[index];
  return customer?.accountManagerName === DIGI_ACCOUNT_MANAGER;
};
