import React, { FocusEvent } from 'react';
import { default as getValue } from 'lodash/get';
import difference from 'lodash/difference';
import Promise from 'bluebird';
import FieldList from 'features/fieldList/FieldList';
import { Countries, FormError, Locale, Schema, SearchPersonResponse } from 'types/contractsState';
import { Contract, FieldType, Template, Value, Condition } from 'types/ordersState';
import { doesQuestionPassConditionTest } from 'core/functions';
import { ImmutableContent } from 'types/profileState';

interface Props {
  values: ImmutableContent;
  onChange: (key: string, value: Value) => void;
  field: FieldType;
  value: Contract;
  locale: Locale;
  template: Template;
  errors: FormError[];
  schema: Schema;
  skipIndex?: boolean;
  countries: Countries;
  contractId: string;
  onBlur: (field: FieldType) => void;
  onFocus: (index: number, ev: FocusEvent<HTMLInputElement>) => void;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  contractLanguage: Locale;
  textFieldAsWizardSection: boolean | undefined;
}

export default class ArrayField extends React.Component<Props> {
  resolveArrayValue(parentKey: string) {
    return (values: object, _index: number, key: string) =>
      getValue(values, difference(key.split('.'), parentKey.split('.')));
  }
  fulfillsCondition = (condition: Condition, value: Value) => {
    const { template } = this.props;
    return doesQuestionPassConditionTest(this.props.value, condition, template.form.items, value);
  };

  render() {
    const arrayIndex = 0;

    const resolveArrayValue = this.resolveArrayValue(this.props.field.key).bind(this, this.props.value, arrayIndex);

    const arrayElems = (
      <FieldList
        key={arrayIndex}
        schema={this.props.schema}
        errors={this.props.errors}
        skipIndex={this.props.skipIndex}
        values={this.props.value}
        wizardSection={false}
        locale={this.props.locale}
        template={this.props.template}
        countries={this.props.countries}
        resolveValue={resolveArrayValue}
        onBlur={this.props.onBlur}
        // @ts-expect-error: disabled to avoid scrolling anywhere on focus
        onFocus={null}
        crmPersonSearch={this.props.crmPersonSearch}
        contractId={this.props.contractId}
        onChange={this.props.onChange}
        field={this.props.field}
        value={this.props.value}
        showProgress={false}
        fields={this.props.field.items}
        contractLanguage={this.props.contractLanguage}
        fulfillsCondition={this.fulfillsCondition}
        isArrayQuestion={true}
        textFieldAsWizardSection={this.props.textFieldAsWizardSection}
      />
    );

    return (
      <section style={{ marginLeft: '30px' }}>
        <label style={{ fontWeight: 'bold', fontSize: '15px' }}>
          {this.props.field.title[this.props.contractLanguage]}
        </label>
        {arrayElems}
      </section>
    );
  }
}
