import { optimizationManners, WEIGHT_LIMIT } from 'constants/allocator';
import { OptimizationMannerId, PlanLength } from 'types/types';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { InstrumentWithWeight, PortfolioInstrument } from 'types/instrumentsState';
import { formatToDecimals, isAllocator } from 'features/allocator/allocatorUtils';
import { Weights } from 'types/weightsState';

export const getPortfolioSettings = (portfolio: AllocatorPortfolio) => ({
  ...portfolio?.optimizedSettings,
  ...portfolio?.manualSettings,
});

export const isOptimizedPortfolio = (p: AllocatorPortfolio): boolean =>
  getPortfolioSettings(p).optimizationManner === optimizationManners.INCLUDE;

export const isConsideredPortfolio = (p: AllocatorPortfolio): boolean =>
  getPortfolioSettings(p).optimizationManner === optimizationManners.CONSIDER;

export const isIgnoredPortfolio = (p: AllocatorPortfolio): boolean =>
  getPortfolioSettings(p).optimizationManner === optimizationManners.IGNORE;

export const isFuturePortfolio = (p: AllocatorPortfolio): boolean => {
  const hasNoWeights = (p: InstrumentWithWeight[]) => p.every((i) => i?.weight && i.weight < WEIGHT_LIMIT);
  const hasNoShortTermWeights = hasNoWeights(p?.allocatedPortfolioRows?.withoutIlliquids);
  const hasWeights = (p: InstrumentWithWeight[]) => p.some((i) => i?.weight && i.weight >= WEIGHT_LIMIT);
  const hasLongTermWeights = hasWeights(p?.allocatedPortfolioRows?.withIlliquids);
  return (
    p?.allocatedPortfolioRows?.withoutIlliquids.length > 0 &&
    p?.allocatedPortfolioRows?.withIlliquids.length > 0 &&
    hasNoShortTermWeights &&
    hasLongTermWeights
  );
};

export const getPortfolioOptimizationModel = (p: AllocatorPortfolio): OptimizationMannerId =>
  getPortfolioSettings(p).optimizationManner;

export const getPlanPortfolioContractType = (portfolio: AllocatorPortfolio) => {
  const settings = getPortfolioSettings(portfolio);
  return settings.contractType;
};

export const getPortfolioWeight = (portfolio: AllocatorPortfolio, currentWeights: Weights) => {
  if (isIgnoredPortfolio(portfolio)) {
    return totalCurrentPortfolioValue(portfolio.portfolioId, currentWeights);
  }
  if (isAllocator()) {
    return totalPlanPortfolioWeight(portfolio, 'withoutIlliquids');
  }
  return totalCurrentPortfolioWeight(portfolio.portfolioId, currentWeights);
};

export const getFormattedPortfolioWeight = (portfolio: AllocatorPortfolio, currentWeights: Weights) => {
  const value = getPortfolioWeight(portfolio, currentWeights);
  if (isIgnoredPortfolio(portfolio)) {
    return formatToDecimals(value, 0, '€');
  }
  return formatToDecimals(value, 1, '%');
};

const totalPlanPortfolioWeight = (portfolio: AllocatorPortfolio, planLength: PlanLength): number =>
  portfolio.allocatedPortfolioRows[planLength]?.reduce((acc, curr) => acc + (curr?.weight || 0), 0);

export const totalCurrentPortfolioWeight = (portfolioId: string | null, currentWeights: Weights): number =>
  !portfolioId
    ? 0
    : currentWeights.portfolioWeights
        ?.find((p) => p.portfolioId === portfolioId)
        ?.instruments.reduce((acc, curr) => acc + (curr?.weight || 0), 0) || 0;

export const totalCurrentPortfolioValue = (portfolioId: string | null, currentWeights: Weights): number =>
  !portfolioId
    ? 0
    : currentWeights.portfolioWeights
        ?.find((p) => p.portfolioId === portfolioId)
        ?.instruments.reduce((acc, curr) => acc + (curr?.marketValue || 0), 0) || 0;

export const clearPortfolioWeights = (portfolios: AllocatorPortfolio[]) => {
  const weightlessPortfolios = portfolios.map((p) => ({
    ...p,
    allocatedPortfolioRows: {
      withIlliquids: clearWeights(p, 'withIlliquids'),
      withoutIlliquids: clearWeights(p, 'withoutIlliquids'),
    },
  }));

  return weightlessPortfolios;
};

const clearWeights = (portfolio: AllocatorPortfolio, planLength: PlanLength) =>
  portfolio.allocatedPortfolioRows[planLength].map(clearInstrumentWeights);

export const clearInstrumentWeights = (instrument: PortfolioInstrument) => ({
  ...instrument,
  weight: undefined,
  marketValue: undefined,
  portfolioCurrencyMarketValue: undefined,
  quantity: undefined,
});

export const getPortfolioIndex = (portfolioId: string) => {
  const array = portfolioId.split('_').filter(Boolean);
  if (array.length > 1) {
    return array[1];
  } else {
    // for new customer: portfolioId = _0, thus array = [0]
    return '';
  }
};
