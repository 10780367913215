import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Immutable from 'immutable';
import translate from 'counterpart';
import { isEmpty, every } from 'lodash';
import {
  getContractPreview,
  selectSignatureMethod,
  createContracts,
  toggleCreateContractsConfirm,
  getPdfForPreview,
  toggleNewSignatoryDialog,
  addSignatory,
  toggleSignatoryDetailsDialog,
  removeSignatory,
  prefillSignatory,
  copySignatoriesToAllContracts,
  getClientSigners,
  updateBisnode,
  getBisnode,
  getHasWebServiceContract,
  addWebServiceContract,
  setManualSignatureAmount,
  resetClientSession,
  /*  fetchDocuments,*/
} from 'features/contracts/contractsActions';
import {
  selectContactFromWebServiceContract,
  canContinueToNextStep,
  selectContactFromAuthorizationContract,
  selectContactFromCompanyAuthorizationContract,
  selectPrimaryContact,
  isPersonIncompetent,
} from 'features/contracts/contractsSelectors';
import {
  WEB_SERVICE_CONTRACT,
  AUTHORIZATION_CONTRACT,
  AUTHORIZATION_CONTRACT_COMPANY,
  BASIC_INFO_PERSON,
  BASIC_INFO_COMPANY,
  BASIC_INFO_INSTITUTION,
  KYC_INFO_PERSON,
  KYC_INFO_COMPANY,
  WEB_SERVICE_CONTRACT_PROXY,
} from 'constants/contractIds';
import Preview from 'features/contracts/components/Preview';
import { getInvalidCrmPersonFields } from 'core/validations';
import { Contract, FoundPerson, ImmutableContracts, Signatory } from 'types/ordersState';
import { ProfileState } from 'types/profileState';
import { RootState } from 'types/rootState';
import { Spinner } from 'features/common/Spinner';

interface Props {
  getContractPreview: () => void;
  selectSignatureMethod: (contractId: string, method: string) => void;
  toggleCreateContractsConfirm: (show: boolean) => void;
  createContracts: () => Promise<void>;
  getPdfForPreview: () => void;
  setManualSignatureAmount: () => void;
  params: { id: string };
  isBusy: boolean;
  isReady: boolean;
  canContinue: boolean;
  showCreateContractsConfirm: boolean;
  pdfPreviewLinks: Immutable.Map<string, object>;
  signatureMethods: Immutable.Map<string, string>;
  toggleNewSignatoryDialog: (show: boolean, contract: Contract) => void;
  showNewSignatoryDialog: object;
  addSignatory: (contractType: string, signatory: Signatory) => void;
  contractsSignatories: object;
  manualSigners: object;
  toggleSignatoryDetailsDialog: (show: boolean, contract: Contract, signatory: Signatory) => void;
  showSignatoryDetailsDialog: { show: boolean; contract: Contract; signatory: Signatory };
  removeSignatory: (contractType: string, signatory: Signatory) => void;
  copySignatoriesToAllContracts: (signatories: Signatory[]) => void;
  prefillSignatory: (contractType: string) => void;
  getClientSigners: () => void;
  savedSigners: Signatory[];
  profile: ProfileState;
  bisnode: object;
  updateBisnode: () => void;
  getBisnode: () => void;
  getHasWebServiceContract: (params: FoundPerson) => Promise<{ result: boolean; type: string }>;
  canUpdateBisnode: boolean;
  webServiceContractExist: boolean;
  fetchingWebServiceContractStatus: boolean;
  webServiceContact: FoundPerson;
  authorizationPerson: FoundPerson;
  companyAuthorizationPerson: FoundPerson;
  selectedContracts: ImmutableContracts;
  addWebServiceContract: (params: FoundPerson) => Promise<void>;
  addingWebServiceContract: boolean;
  contractsCompleted: boolean;
  isCustomerIncompetent: boolean;
  primaryContact: FoundPerson;
  resetClientSession: ({ deleteDraft }: { deleteDraft: boolean }) => Promise<void>;
  /*  fetchDocuments: (customerId: string) => void;*/
}

const ContractPreviewView = ({
  getContractPreview,
  selectSignatureMethod,
  toggleCreateContractsConfirm,
  createContracts,
  getPdfForPreview,
  setManualSignatureAmount,
  params,
  isBusy,
  isReady,
  canContinue,
  showCreateContractsConfirm,
  pdfPreviewLinks,
  signatureMethods,
  toggleNewSignatoryDialog,
  showNewSignatoryDialog,
  addSignatory,
  contractsSignatories,
  manualSigners,
  toggleSignatoryDetailsDialog,
  showSignatoryDetailsDialog,
  removeSignatory,
  copySignatoriesToAllContracts,
  prefillSignatory,
  getClientSigners,
  savedSigners,
  profile,
  bisnode = { text: '', updateDate: '' },
  updateBisnode,
  getBisnode,
  getHasWebServiceContract,
  canUpdateBisnode = true,
  webServiceContractExist,
  fetchingWebServiceContractStatus,
  webServiceContact,
  authorizationPerson,
  companyAuthorizationPerson,
  selectedContracts,
  addWebServiceContract,
  addingWebServiceContract,
  contractsCompleted,
  isCustomerIncompetent = false,
  primaryContact,
  resetClientSession,
}: /*  fetchDocuments,*/
Props) => {
  const [invalidFieldInfo, setInvalidFieldInfo] = useState<string | undefined>(undefined);
  const customerType = profile.customer.toJS().customerType;

  useEffect(() => {
    const loadData = async () => {
      await Promise.all([
        getContractPreview(),
        getClientSigners(),
        getWebServiceContractStatus(),
        customerType === 'company' && getBisnode(),
      ]);
    };
    void loadData();
  }, []);

  useEffect(() => {
    setStatusMessage();
  }, [webServiceContractExist]);

  const setStatusMessage = () => {
    if (basicInfoContractSelected || kycInfoContractSelected) {
      if (webServiceContractExist) {
        setInvalidFieldInfo(translate('newContract.webServiceContractExist'));
      }
    }
  };

  const basicInfoContractSelected =
    selectedContracts.get(BASIC_INFO_PERSON, false) ||
    selectedContracts.get(BASIC_INFO_COMPANY, false) ||
    selectedContracts.get(BASIC_INFO_INSTITUTION, false);
  const personalAuthorizationContractSelected = selectedContracts.get(AUTHORIZATION_CONTRACT, false);
  const companyAuthorizationContractSelected = selectedContracts.get(AUTHORIZATION_CONTRACT_COMPANY, false);
  const kycInfoContractSelected =
    selectedContracts.get(KYC_INFO_PERSON, false) || selectedContracts.get(KYC_INFO_COMPANY, false);

  const getWebServiceContractStatus = async () => {
    if (basicInfoContractSelected || kycInfoContractSelected) {
      const invalidFields = getInvalidCrmPersonFields(primaryContact);
      if (invalidFields !== '') {
        setInvalidFieldInfo(`${translate('newContract.webServiceContractInfo')} ${invalidFields}`);
        return;
      }

      const response = await getHasWebServiceContract(primaryContact);
      if (response?.result === true) {
        setInvalidFieldInfo(translate('newContract.webServiceContractExist'));
        return;
      }
      if (response?.result === false) {
        await addWebServiceContract(primaryContact);
        await getWebServiceContract(primaryContact);
      }
    } else if (personalAuthorizationContractSelected) {
      await addWebServiceContract(authorizationPerson);
      await getWebServiceContract(authorizationPerson);
    } else if (companyAuthorizationContractSelected) {
      await addWebServiceContract(companyAuthorizationPerson);
      await getWebServiceContract(companyAuthorizationPerson);
    } else {
      await getWebServiceContract();
    }
  };

  const getWebServiceContract = async (params?: FoundPerson) => {
    if (!isEmpty(webServiceContact) && every(webServiceContact, (entry) => !!entry)) {
      prefillSignatory(params?.proxyOfCustomerId === null ? WEB_SERVICE_CONTRACT : WEB_SERVICE_CONTRACT_PROXY);
      await getHasWebServiceContract(webServiceContact);
    }
  };

  if (isBusy || addingWebServiceContract || fetchingWebServiceContractStatus) {
    return <Spinner />;
  }

  return (
    <Preview
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      getContractPreview={getContractPreview}
      selectSignatureMethod={selectSignatureMethod}
      toggleCreateContractsConfirm={toggleCreateContractsConfirm}
      createContracts={createContracts}
      getPdfForPreview={getPdfForPreview}
      setManualSignatureAmount={setManualSignatureAmount}
      params={params}
      isBusy={isBusy}
      isReady={isReady}
      canContinue={canContinue}
      showCreateContractsConfirm={showCreateContractsConfirm}
      pdfPreviewLinks={pdfPreviewLinks}
      signatureMethods={signatureMethods}
      toggleNewSignatoryDialog={toggleNewSignatoryDialog}
      showNewSignatoryDialog={showNewSignatoryDialog}
      addSignatory={addSignatory}
      contractsSignatories={contractsSignatories}
      manualSigners={manualSigners}
      toggleSignatoryDetailsDialog={toggleSignatoryDetailsDialog}
      showSignatoryDetailsDialog={showSignatoryDetailsDialog}
      removeSignatory={removeSignatory}
      copySignatoriesToAllContracts={copySignatoriesToAllContracts}
      prefillSignatory={prefillSignatory}
      getClientSigners={getClientSigners}
      savedSigners={savedSigners}
      profile={profile}
      bisnode={bisnode}
      updateBisnode={updateBisnode}
      getBisnode={getBisnode}
      getHasWebServiceContract={getHasWebServiceContract}
      canUpdateBisnode={canUpdateBisnode}
      webServiceContractExist={webServiceContractExist}
      fetchingWebServiceContractStatus={fetchingWebServiceContractStatus}
      webServiceContact={webServiceContact}
      authorizationPerson={authorizationPerson}
      companyAuthorizationPerson={companyAuthorizationPerson}
      selectedContracts={selectedContracts}
      addWebServiceContract={addWebServiceContract}
      addingWebServiceContract={addingWebServiceContract}
      contractsCompleted={contractsCompleted}
      isCustomerIncompetent={isCustomerIncompetent}
      primaryContact
      webServiceContractInfo={invalidFieldInfo}
      resetClientSession={resetClientSession}
      /*      fetchDocuments={fetchDocuments}*/
      showAddAttachment={true}
    />
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getContractPreview,
      selectSignatureMethod,
      createContracts,
      toggleCreateContractsConfirm,
      getPdfForPreview,
      toggleNewSignatoryDialog,
      addSignatory,
      toggleSignatoryDetailsDialog,
      removeSignatory,
      prefillSignatory,
      copySignatoriesToAllContracts,
      getClientSigners,
      updateBisnode,
      getBisnode,
      getHasWebServiceContract,
      addWebServiceContract,
      setManualSignatureAmount,
      resetClientSession,
    },
    dispatch
  );
};

const mapStateToProps = (state: RootState) => {
  const pdfLinks = state.contracts.pdfPreviewLinks;
  const signatureMethods = state.contracts.signatureMethods;
  const canContinue = canContinueToNextStep(state);

  return {
    pdfPreviewLinks: pdfLinks,
    signatureMethods,
    isBusy: state.contracts.isBusy,
    isReady: state.contracts.isReady,
    profile: state.profile,
    savedSigners: state.contracts.savedSigners,
    bisnode: state.contracts.bisnode,
    canUpdateBisnode: state.contracts.canUpdateBisnode,
    canContinue,
    createContractsForSendingFailure: state.contracts.createContractsForSendingFailure,
    showCreateContractsConfirm: state.contracts.showCreateContractsConfirm,
    showNewSignatoryDialog: state.contracts.showNewSignatoryDialog.toJS(),
    contractsSignatories: state.contracts.contractsSignatories,
    manualSigners: state.contracts.manualSigners,
    webServiceContractExist: state.contracts.webServiceContractExist,
    fetchingWebServiceContractStatus: state.contracts.fetchingWebServiceContractStatus,
    showSignatoryDetailsDialog: state.contracts.showSignatoryDetailsDialog.toJS(),
    webServiceContact: selectContactFromWebServiceContract(state.contracts).toJS(),
    selectedContracts: state.contracts.selected,
    addingWebServiceContract: state.contracts.addingWebServiceContract,
    authorizationPerson: selectContactFromAuthorizationContract(state.contracts).toJS(),
    companyAuthorizationPerson: selectContactFromCompanyAuthorizationContract(state.contracts).toJS(),
    primaryContact: selectPrimaryContact(state),
    isCustomerIncompetent: isPersonIncompetent(state),
    contractLanguage: state.common.contractLanguage,
    /*    fetchDocuments,*/
  };
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ContractPreviewView);
