import { Template } from 'types/ordersState';

export const privateProfile: Template = {
  form: {
    contractType: 'customer_private',
    formCreationTime: '0001-01-01T00:00:00',
    editor: 'system',
    formEditTime: '2016-08-19T07:56:21.4007678Z',
    items: [
      {
        key: 'sections.clientData',
        type: 'section',
        title: {
          fi: 'Yhteenveto asiakkaan tiedoista',
          en: 'Customer summary information',
          sv: '',
        },
        items: [
          {
            key: 'sections.clientData.firstName',
            title: {
              fi: 'Etunimi',
              en: 'First name',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.lastName',
            title: {
              fi: 'Sukunimi',
              en: 'Last name',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.ssn',
            title: {
              fi: 'Henkilötunnus',
              en: 'Social Security Number',
              sv: '',
            },
            readOnly: true,
          },
          {
            key: 'sections.clientData.phoneNumber',
            title: {
              fi: 'Puhelinnumero',
              en: 'Phone number',
              sv: '',
            },
            placeHolder: {
              fi: 'Muodossa +358123456789',
              en: '',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.email',
            title: {
              fi: 'Sähköposti',
              en: 'Email',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.streetName',
            title: {
              fi: 'Osoite',
              en: 'Address',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.postalCode',
            title: {
              fi: 'Postinumero',
              en: 'Postal code',
              sv: '',
            },
          },
          {
            key: 'sections.clientData.city',
            title: {
              fi: 'Postitoimipaikka',
              en: 'City',
              sv: '',
            },
          },
        ],
      },
    ],
    id: 'Template/Customer/Form/customer_private',
    creator: '',
    editorFullName: '',
    hasCrmIntegration: false,
  },
  schema: {
    id: 'Template/Customer/Schema/customer_private',
    contractType: 'customer_private',
    schemaCreationTime: '0001-01-01T00:00:00',
    editor: 'system',
    schemaEditTime: '2016-08-19T07:56:21.2487715Z',
    properties: {
      sections: {
        properties: {
          clientData: {
            properties: {
              firstName: {
                minLength: 1,
                type: 'string',
              },
              lastName: {
                minLength: 1,
                type: 'string',
              },
              ssn: {
                pattern:
                  '^(0[1-9]|[12]\\d|3[01])(0[1-9]|1[0-2])(\\d{2})(\\+|-|A|B|C|D|E|F|Y|X|W|V|U)(\\d{3})(\\d|[A-FHJ-NPR-Y])$',
                minLength: 0,
                type: 'string',
                fieldType: 'ssn',
              },
              phoneNumber: {
                minLength: 4,
                type: 'string',
              },
              email: {
                minLength: 0,
                type: 'string',
                format: 'email',
              },
              postalCode: {
                minLength: 1,
                type: 'string',
              },
              streetName: {
                minLength: 1,
                type: 'string',
              },
              city: {
                minLength: 0,
                type: 'string',
              },
            },
            required: ['firstName', 'lastName', 'ssn', 'streetName', 'phoneNumber', 'postalCode', 'city'],
            sectionType: 'BasicInfoSectionPrivate',
            description: 'Customer basic information',
            version: '0.0.1',
            type: 'object',
          },
        },
        type: 'object',
      },
    },
    definitions: {},
    description: 'New private customer questions',
    type: 'object',
    categories: [],
    creator: '',
    customerTypes: [],
    editorFullName: '',
    isArchived: false,
    markFinished: false,
    name: '',
    priority: 1,
  },
  contractType: '',
  isDraft: false,
  versions: [],
  formVersions: [],
};
