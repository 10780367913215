import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Range, Handle } from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { FlexAlignCenter, fontSize } from 'features/common/StyledComponents';
import { Constraint } from 'types/constraintsState';
import { ConstraintType } from 'types/types';
import { AssetCategoryWeights } from 'types/weightsState';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  selectCurrentWeights,
  selectHasPositions,
  selectOptimizedWeightsForRiskLevel,
} from 'features/allocator/allocatorSelectors';

interface Props {
  optimizedWeights?: AssetCategoryWeights[];
  constraint: Constraint;
  onUpdate: (updatedConstraint: Constraint) => boolean;
  value: number[];
  constraintType: ConstraintType;
  updatedItemId: string;
  type: 'assetCategory' | 'assetClass' | 'custom';
}

export const ConstraintSlider = ({ constraint, onUpdate, value, constraintType, updatedItemId, type }: Props) => {
  const min = 0;
  const max = 1;
  const step = 0.01;
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const hasPositions = useSelector(selectHasPositions);

  const currentAssetCategoryWeights = useSelector(selectCurrentWeights).assetCategoryWeights;
  const optimizedAssetCategoryWeights = useSelector(selectOptimizedWeightsForRiskLevel)?.withoutIlliquids
    ?.assetCategoryWeights;

  const assetCategoryWeights = hasPositions ? currentAssetCategoryWeights : optimizedAssetCategoryWeights;
  const assetCategory = assetCategoryWeights?.find((i) => i.name.toLowerCase() === constraint.id.toLowerCase());

  const getWeight = () => {
    switch (type) {
      case 'assetCategory':
        return assetCategory?.weight || 0;
      case 'assetClass': {
        const optimizedAssetClass = assetCategory?.assetClasses.find((a) => a.assetClassId === updatedItemId);
        return optimizedAssetClass?.weight || 0;
      }
      case 'custom': {
        const assetClassWeights = assetCategoryWeights?.flatMap((a) => a.assetClasses);
        const assetClassesSum = constraint.assetClasses.reduce(
          (acc, curr) => acc + (assetClassWeights?.find((i) => i.assetClassId === curr)?.weight || 0),
          0
        );
        const inRelationToSum = constraint.inRelationTo.reduce(
          (acc, curr) => acc + (assetClassWeights?.find((i) => i.assetClassId === curr)?.weight || 0),
          0
        );
        if (inRelationToSum === 0) {
          return 0;
        }
        return assetClassesSum / inRelationToSum;
      }
    }
  };

  const getUpdatedConstraint = (): Constraint => {
    if (constraint.id === updatedItemId) {
      return { ...constraint, min: currentValue[0], max: currentValue[1] };
    }

    const updatedItems = constraint.items.map((item) => {
      if (item.id === updatedItemId) {
        return { ...item, min: currentValue[0], max: currentValue[1] };
      }
      return item;
    });

    return { ...constraint, items: updatedItems };
  };

  const isDefaultValue = value[0] === 0 && value[1] === 1;

  const weight = getWeight();

  return (
    <ConstraintSliderContainer data-testkey="constraint-slider">
      <StyledPercentageContainer>
        {weight < 0 ? (
          '–'
        ) : (
          <>
            <CurrentDot>▼</CurrentDot>
            {formatToDecimals(weight, 0, '%')}
          </>
        )}
      </StyledPercentageContainer>

      <ValuePercentageContainer isDefaultValue={isDefaultValue}>
        {Math.round(100 * value[0])} ... {Math.round(100 * value[1])} %
      </ValuePercentageContainer>

      <SliderContainer>
        <CurrentWeight value={weight * 100}>▼</CurrentWeight>
        <Range
          min={min}
          max={max}
          step={step}
          onChange={(newValue) => setCurrentValue(newValue)}
          onAfterChange={() => {
            if (currentValue[0] !== value[0] || currentValue[1] !== value[1]) {
              if (!onUpdate(getUpdatedConstraint())) {
                setCurrentValue(value.sort());
              }
            }
          }}
          trackStyle={trackStyle}
          railStyle={railStyle}
          value={currentValue}
          handle={(handleProps) => {
            const lastChar = handleProps.className.slice(-1);
            return (
              <CustomHandle
                {...handleProps}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                dragging={handleProps.dragging ? 'true' : 'false'}
                key={handleProps.index}
                data-testkey={`set-constraint-${constraintType}-${updatedItemId}-${lastChar}`}
              >
                <Lines>
                  <Line />
                  <Line />
                  <Line />
                </Lines>
                <HandleContent>{Math.round(handleProps.value * 100)}</HandleContent>
              </CustomHandle>
            );
          }}
        />
      </SliderContainer>
    </ConstraintSliderContainer>
  );
};

const handleSize = 32;

const CustomHandle = styled(Handle)`
  background: linear-gradient(${colors.primary_light}, ${colors.primary});
  border-color: ${colors.primary};
  width: ${handleSize}px;
  height: ${handleSize}px;
  top: -50%;
  margin-top: 0;
  border: 3px solid white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), inset 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const Lines = styled(FlexAlignCenter)`
  justify-content: space-between;
  position: absolute;
  width: 40%;
  left: 30%;
  top: 20%;
  height: 60%;
  align-items: center;
  display: none;
`;

const Line = styled.div`
  width: 1px;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
`;

const ConstraintSliderContainer = styled(FlexAlignCenter)`
  flex: auto;
  height: 60px;
`;

const SliderContainer = styled.div`
  position: relative;
  margin: 0 2rem;
  flex: auto;
  padding-bottom: 6px;
`;

const HandleContent = styled.div`
  bottom: 26px;
  position: absolute;
  width: 100%;
  text-align: center;
  font-size: ${fontSize.smaller};
`;

export const StyledPercentageContainer = styled(FlexAlignCenter)`
  width: 6rem;
  text-align: center;
  justify-content: center;
  font-size: ${fontSize.smaller};
`;

interface StyledContainer {
  isDefaultValue: boolean;
}

const ValuePercentageContainer = styled(StyledPercentageContainer)<StyledContainer>`
  font-size: ${fontSize.normal};
  font-weight: ${(props) => (props.isDefaultValue ? 500 : 700)};
  opacity: ${(props) => (props.isDefaultValue ? 0.7 : 1)};
`;

const dotSize = 10;

interface StyledWeightProps {
  value: number;
}

const CurrentWeight = styled.div<StyledWeightProps>`
  position: absolute;
  left: calc(${(props) => Math.round(props.value)}% - ${dotSize / 2}px);
  ${(props) => props.value < 0 && `display: none`};
  z-index: 1;
  color: ${colors.blue};
  width: ${dotSize}px;
  height: ${dotSize}px;
  top: -4px;
  font-size: 9px;
`;

const CurrentDot = styled.div`
  color: ${colors.blue};
  width: ${dotSize}px;
  height: ${dotSize}px;
  margin-right: 0.25rem;
  font-size: 9px;
`;

const railSize = 10;
const railStyle = {
  height: `${railSize}px`,
  boxShadow: 'inset 0 1px 1px 0px rgba(0,0,0,0.1)',
};

const trackStyle = [
  {
    background: `linear-gradient(${colors.primary_light}, ${colors.primary})`,
    height: '6px',
    marginTop: '2px',
    borderTop: '1px solid rgba(255,255,255,.2)',
    borderBottom: '1px solid rgba(0,0,0,.1)',
  },
];
