import React from 'react';
import { Link } from 'react-router';
import translate from 'counterpart';
import styles from './OptionBox.scss';

export interface OptionBoxProps {
  handler: (option: string) => void;
  link: string;
  text: string;
  option: string;
  selected: boolean;
  icon: string;
  style?: object;
  'data-testkey'?: string;
}

export default class OptionBox extends React.Component<OptionBoxProps> {
  onClick = () => {
    this.props.handler(this.props.option);
  };

  render() {
    const iconClass = this.props.selected ? 'icon-check' : this.props.icon;
    const optionStyle = this.props.selected ? styles.optionSelected : styles.option;
    const { link, text, style } = this.props;
    return (
      <Link
        to={link}
        onClick={this.onClick}
        className={`${styles.optionLink} column small-12 medium-expand`}
        style={style}
        data-testkey={this.props['data-testkey']}
      >
        <div className={optionStyle}>
          <div className={styles.iconContainer}>
            <i className={`icon ${iconClass}`} />
          </div>
          <p>{translate(text)}</p>
        </div>
      </Link>
    );
  }
}
