import { apiCall } from 'core/apiCall';
import { GetCustomerFormResponse } from 'features/prospect/prospectTypes';
import { CustomerType } from 'types/profileState';
import { Prospect } from 'types/prospectsState';

export const postCustomer = (customer: Prospect, token: string): Promise<string> => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/client',
    data: customer,
  });
};

export const getCustomerForm = (customerType: CustomerType, token: string): Promise<GetCustomerFormResponse> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/client/form/${customerType}`,
  });
};
