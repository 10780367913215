import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { BUY, EXCHANGE } from 'constants/sides';
import { SelectFee } from './SelectFee';
import Amount from './Amount';
import { InstrumentSearch } from './InstrumentSearch';
import { QuantityWarning } from './QuantityWarning';
import InvoiceDetails from './InvoiceDetails';
import MoneySource from './MoneySource';
import { ChangePairOption, TouchedFields, ValidationErrorValue } from 'types/orderDialogState';
import { SelectValue } from 'types/types';
import { OrderInstrument, DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { OrderLineType } from 'types/ordersState';
import { FundsOrigin } from 'features/orderDialog/components/FundsOrigin';
import { searchChangePairs } from 'features/orderDialog/orderDialogActions';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { InstrumentForm } from 'constants/instrumentForms';
import { useAppDispatch } from 'core/hooks';

const DEBOUNCE_DELAY = 500;

interface Props {
  assignment: OrderLineType;
  onQuantityChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  touchedFields: TouchedFields;
  currentFee?: number;
  onFeeChange: (value: SelectValue) => void;
  isFetchingFee: boolean;
  defaultFee: number;
  onExchangeInstrumentChange: (params: OrderInstrument) => void;
  changePairOptions: ChangePairOption[];
  isLoadingChangePairOptions: boolean;
  currency: string;
  defaultAccount: DefaultAccount;
  portfolioContractName: string;
  showMoneySourceForBuyOrder: boolean;
  instrumentForm: InstrumentForm;
  isAppropriatenessTestOk: boolean;
  validationErrors: ValidationErrorValue[];
}

export const FundDetails = ({
  assignment,
  touchedFields,
  currentFee,
  onFeeChange,
  isFetchingFee,
  defaultFee,
  onExchangeInstrumentChange,
  changePairOptions,
  isLoadingChangePairOptions,
  currency = '',
  onValueChange,
  onQuantityChange,
  defaultAccount,
  portfolioContractName,
  showMoneySourceForBuyOrder,
  instrumentForm,
  isAppropriatenessTestOk,
  validationErrors,
}: Props) => {
  const dispatch = useAppDispatch();

  const customerId = useSelector(selectCustomerId);

  // Functional components require debounce to be used with useCallback
  const getChangePairsOptions = useCallback(
    debounce(
      (input: string) =>
        dispatch(
          searchChangePairs(
            instrumentForm,
            input,
            customerId,
            assignment.portfolioId,
            assignment.side,
            assignment.orderBasis
          )
        ),
      DEBOUNCE_DELAY
    ),
    [instrumentForm, customerId, assignment.portfolioId, assignment.side, assignment.financialInstrumentId]
  );

  return (
    <div>
      {assignment.side === EXCHANGE ? (
        <div className="row">
          <div className="columns small-9">
            <InstrumentSearch
              onInstrumentChange={onExchangeInstrumentChange}
              value={assignment.counterFinancialInstrumentId}
              getOptions={getChangePairsOptions}
              options={changePairOptions}
              isLoading={isLoadingChangePairOptions}
              data-testkey="counter-instrument-search-field"
              searchDisabled={false}
              isExhangeSearch={true}
            />
          </div>
        </div>
      ) : undefined}

      <div className="row" key="volume">
        <div className={'columns small-4'}>
          <SelectFee
            currentFee={currentFee}
            onFeeChange={onFeeChange}
            isFetchingFee={isFetchingFee}
            defaultFee={defaultFee}
            key="feeComponent"
          />
        </div>

        <div className={'columns small-8'}>
          <Amount
            assignment={assignment}
            currency={currency}
            touchedFields={touchedFields}
            onValueChange={onValueChange}
            onQuantityChange={onQuantityChange}
            quantityHidden={assignment.side === BUY}
            validationErrors={validationErrors}
          />
        </div>
      </div>

      <QuantityWarning
        side={assignment.side}
        orderFinancingSell={assignment.orderFinancingSell}
        volume={assignment.quantity}
        currentQuantity={assignment.currentQuantity}
      />

      <div className="row">
        <div className="columns small-4">
          {showMoneySourceForBuyOrder && (
            <MoneySource
              portfolioContractName={portfolioContractName}
              defaultAccount={defaultAccount}
              assignment={assignment}
            />
          )}
        </div>

        <div className="columns small-8">
          <InvoiceDetails assignment={assignment} defaultAccount={defaultAccount} />
        </div>
      </div>

      <FundsOrigin />

      {assignment.side === BUY && isAppropriatenessTestOk ? (
        <div className="row">
          <div className="columns small-4" />
          <div className="columns small-6" style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
            Asianmukaisuusarviointi <i className="icon icon-check" />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
