import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { List } from 'immutable';
import translate from 'counterpart';
import classNames from 'classnames/bind';
import SubNav from './components/SubNav';
import { getContactPdf } from 'features/contracts/contractsActions';
import { SignatureConfirmItem } from './components/SignatureConfirmItem';
import ElectronicSignatureConfirmItem from './components/ElectronicSignatureConfirmItem';
import { InkSignatureConfirmItem } from './components/InkSignatureConfirmItem';
import { OpenFile } from './components/DownloadPdf';
import styles from './ContractSignaturesView.scss';
import {
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_SIGNING,
  SENT_FOR_INK_SIGNING,
  contractSignatureStates,
} from 'constants/contractStates';
import { Spinner } from 'features/common/Spinner';
import { Contract, ImmutableContract, ImmutableSignatoryList } from 'types/ordersState';
import { RootState } from 'types/rootState';
import { ATTACHMENT_TEMPLATE_ID } from './contractsConstants';
import { PdfAttachment } from './contracts.types';
const cx = classNames.bind(styles);

interface Props {
  isBusy: boolean;
  contracts: ImmutableContract;
  getContactPdf: () => void;
  signatories: ImmutableSignatoryList;
  electronicSignatoriesFailure: boolean;
  attachmentPdfs: PdfAttachment[];
}

class ContractSignaturesView extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  static defaultProps = {
    contracts: List(),
  };

  getActionText = (contract: Contract) => {
    const state = contractSignatureStates[contract.state];
    return contract.confirmed ? state.confirmed : state.unconfirmed;
  };

  render() {
    const { contracts, isBusy } = this.props;

    const categoryList = contracts.entrySeq().map((entry) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const key = entry[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const category = entry[1];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const contractList = category.entrySeq().map((item) => {
        const itemKey = item[0];
        const contract = item[1].toJS();

        let confirmItem;

        const icon = contractSignatureStates[contract.state].icon;
        const loadFile = this.props.getContactPdf.bind(this, contract.shortId);
        const headerIcon = (
          <OpenFile isBusy={contract.isBusy} icon={icon} loadFile={loadFile} blobUrl={contract.blobUrl} />
        );

        if (contract.state === SENT_FOR_MANUAL_SIGNING) {
          confirmItem = <SignatureConfirmItem headerIcon={headerIcon} contract={contract} key={itemKey} />;
        }

        if (contract.state === SENT_FOR_SIGNING) {
          const type = contract.contractType;
          const signatories =
            type === ATTACHMENT_TEMPLATE_ID ? contract.signatures : this.props.signatories.get(type).toJS();
          confirmItem = (
            <ElectronicSignatureConfirmItem
              headerIcon={headerIcon}
              contract={contract}
              signatories={signatories}
              key={itemKey}
            />
          );
        }

        if (contract.state === SENT_FOR_INK_SIGNING) {
          const type = contract.contractType;
          const signatories =
            type === ATTACHMENT_TEMPLATE_ID ? contract.signatures : this.props.signatories.get(type).toJS();
          confirmItem = (
            <InkSignatureConfirmItem
              headerIcon={headerIcon}
              contract={contract}
              signatories={signatories}
              key={itemKey}
            />
          );
        }

        return confirmItem;
      });

      const headerStyles = cx({
        electronic: key === SENT_FOR_SIGNING,
        electronicSentFailure: this.props.electronicSignatoriesFailure,
      });

      return (
        <section className={styles.signatures} key={key}>
          <section className={headerStyles}>
            <header>{translate(`newContract.${key}_header`)}</header>
            <div className={styles.signatoriesError}>
              <i className="icon icon-exclamation" />
              <div>{translate('contract.signatoriesError')}</div>
            </div>
          </section>
          {contractList}
        </section>
      );
    });

    let component;
    if (isBusy) {
      component = <Spinner className={styles.spinner} size={100} />;
    } else {
      component = (
        <div>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <SubNav label={'4. Allekirjoitukset'} />
          {categoryList}
        </div>
      );
    }

    return <section className={styles.contracts}>{component}</section>;
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ getContactPdf }, dispatch);
}

function mapStateToProps(state: RootState) {
  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    contracts: state.contracts.contracts.groupBy((value) => value.get('state')),
    signatories: state.contracts.contractsSignatories,
    attachmentPdfs: state.contracts.attachmentPdfs,
    isBusy: state.contracts.isBusy,
    electronicSignatoriesSent: state.contracts.electronicSignatoriesSent,
    electronicSignatoriesFailure: state.contracts.electronicSignatoriesFailure,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ContractSignaturesView);
