import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ProjectionChart } from 'features/allocator/common/ProjectionChart';
import { ReturnAndVolatility } from 'features/allocator/overview/components/ReturnAndVolatility';
import { Spinner } from 'features/common/Spinner';
import { RootState } from 'types/rootState';
import { RiskLevel } from 'types/types';
import { DistributionPie } from 'features/allocator/riskSelection/components/DistributionPie';
import { AssetCategoryDistribution } from 'features/allocator/common/AssetCategoryDistribution';
import { getExamplePortfolioPropsForRiskLevel } from 'features/portfolioManager/commonData/commonDataUtils';

interface Props {
  riskLevel: RiskLevel;
}

export const RiskYieldAndFigures = ({ riskLevel }: Props) => {
  const examplePortfolios = useSelector((state: RootState) => state.portfolioManager.commonData.examplePortfolios);
  const loadingExamplePortfolios = useSelector(
    (state: RootState) => state.portfolioManager.commonData.loadingExamplePortfolios
  );

  const { confidenceLevels, weights } = useSelector(getExamplePortfolioPropsForRiskLevel(riskLevel));

  if (loadingExamplePortfolios) {
    return <Spinner />;
  }

  if (examplePortfolios.length) {
    return (
      <Container>
        <Title>{translate('newContract.riskConfirmation.estimatedDistribution')}</Title>
        <Flex>
          <DistributionPie weights={weights} sections="assetCategories" />
          <div>
            <AssetCategoryDistribution weights={weights} />
            <ReturnAndVolatility
              returnStatistics={examplePortfolios.filter((i) => i.risk === riskLevel)[0].returnStatistics}
              planLength="withIlliquids"
              loading={loadingExamplePortfolios}
              compareTo="none"
            />
          </div>
        </Flex>
        <ProjectionChart confidenceLevels={confidenceLevels} startValue={100} />
      </Container>
    );
  }
  return null;
};

const Container = styled.div`
  padding: 1.5rem;

  && table {
    tbody {
      background: transparent;
      border: 0;

      td {
        padding: 0 0.25rem;
      }
    }
  }
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 2rem;
`;

const Flex = styled.div`
  display: flex;
`;
