import qs from 'qs';
import { apiCall } from 'core/apiCall';
import { escapePathParam } from 'core/functions';
import { CreatePageParams } from 'core/functionsTypes';
import {
  GetContractsApiCallResponse,
  GetSavedontractFiltersResponse,
  PostFiltersResponse,
} from 'features/contractList/contractList.types';
import { ContractListSearchFilter } from 'types/contractListState';

const FILTER_TYPE = 'contracts-pt';

export const postFilters = (
  filters: ContractListSearchFilter,
  token: string,
  userId: string
): Promise<PostFiltersResponse> => {
  return apiCall({
    method: 'post',
    token,
    path: `/api/v1/user/${escapePathParam(userId)}/searchFilter`,
    data: {
      ...filters,
      filterType: FILTER_TYPE,
    },
  });
};

export const getContractsApiCall = (
  queryParams: CreatePageParams,
  token: string
): Promise<GetContractsApiCallResponse> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/keywords?${qs.stringify(queryParams, { skipNulls: true })}`,
  });
};

export const getSavedontractFilters = (userId: string, token: string): Promise<GetSavedontractFiltersResponse> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/user/${escapePathParam(userId)}/searchFilter?type=${FILTER_TYPE}`,
  });
};

export const deleteSavedFilterApiCall = (
  id: string,
  userId: string,
  token: string
): Promise<GetSavedontractFiltersResponse> => {
  return apiCall({
    method: 'delete',
    token,
    path: `/api/v1/user/${escapePathParam(userId)}/searchFilter/${id}`,
  });
};
