import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { InjectedRouter } from 'react-router';
import translate from 'counterpart';
import { selectMandatoryContracts, selectContract } from 'features/contracts/contractsActions';
import SubNav from './components/SubNav';
import { Locked } from './components/Locked';
import styles from './ContractSelectionView.scss';
import SelectionGroup from 'features/contracts/components/SelectionGroup';
import { Spinner } from 'features/common/Spinner';
import { ModalDialog } from 'features/common/ModalDialog';
import { RootState } from 'types/rootState';
import { selectGroupedTemplates } from 'features/contracts/contractsSelectors';
import { selectIsDigiCustomer, selectIsDigiEligible } from 'features/profile/profileSelectors';
import { GroupedTemplate } from 'types/contractsState';
import { useAppDispatch } from 'core/hooks';
import RadioButtons from 'features/common/RadioButtons';
import { changeContractLanguage, getInitialData } from 'actions';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  params: { id: string };
  router: InjectedRouter;
}

export const ContractSelectionView = (props: Props) => {
  const dispatch = useAppDispatch();
  const { params, router } = props;
  const [openCustomerServiceConfirmation, setOpenCustomerServiceConfirmation] = useState<boolean>(false);
  const langFi = 'fi';
  const langSv = 'sv';
  const [contractFilter, setContractFilter] = useState('');
  const languageFilter = useSelector((state: RootState) => state.common.contractLanguage);

  const templateGroups = useSelector(selectGroupedTemplates);
  const selected = useSelector((state: RootState) => state.contracts.selected);
  const isReady = useSelector((state: RootState) => state.contracts.isReady);
  const templatesLoading = useSelector((state: RootState) => state.contracts.templatesLoading);
  const customer = useSelector((state: RootState) => state.profile.customer);
  const isBusy = useSelector((state: RootState) => state.profile.isBusy || state.contracts.isBusy);
  const isDigiCustomer = useSelector((state: RootState) => selectIsDigiCustomer(state.profile));
  const isDigiEligible = useSelector((state: RootState) => selectIsDigiEligible(state.profile));

  useEffect(() => {
    if (customer) {
      dispatch(selectMandatoryContracts(customer.toJS()));
    }
  }, [customer, languageFilter]);

  const shouldAskConfirmation = () => (isDigiCustomer || isDigiEligible) && !openCustomerServiceConfirmation;

  const handleNext = () => {
    if (shouldAskConfirmation()) {
      setOpenCustomerServiceConfirmation(true);
    } else {
      const nextTo = `customer/${params.id}/contracts/overview`;
      router.push(nextTo);
    }
  };

  const hideModal = () => {
    setOpenCustomerServiceConfirmation(false);
    Object.keys(selected.toJS()).forEach((key) => dispatch(selectContract(key, params.id)));
  };

  const countText = (): string => (hasSelectedContracts() ? ` (${numberOfSelectedContracts()})` : '');
  const numberOfSelectedContracts = (): number => selected.count((val) => val === true);
  const hasSelectedContracts = (): boolean => numberOfSelectedContracts() > 0;
  const isLoadingAnyTemplate = (): boolean => templatesLoading.count((val) => val === true) > 0;
  const isLoadingTemplate = (contractType: string): boolean => templatesLoading.get(contractType) || false;

  const filterByFields = (group: GroupedTemplate, groupId: string) => {
    const groupById = group[groupId];
    let filtered = groupById.filter(
      (f) =>
        f.name.toLowerCase().includes(contractFilter.toLowerCase()) ||
        (f.names && f.names.length > 1 && f.names[1].toLowerCase().includes(contractFilter.toLowerCase())) ||
        contractFilter === ''
    );
    languageFilter
      ? (filtered = filtered.filter((f) =>
          f.languages ? f.languages.includes(languageFilter) : languageFilter === langFi
        ))
      : filtered;
    return filtered;
  };

  if (isReady) {
    return <Locked />;
  }

  const label = `1. ${translate('newContract.selection.title')} ${countText()}`;
  const filter = (
    <input
      id="filter"
      name="filter"
      type="text"
      value={contractFilter}
      onChange={(event) => setContractFilter(event.target.value)}
      placeholder={translate('newContract.selection.searchForContract')}
    />
  );

  const options = [
    {
      value: langFi,
      label: translate('newContract.selection.inFinnish'),
    },
    {
      value: langSv,
      label: translate('newContract.selection.inSwedish'),
    },
  ];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(changeContractLanguage(value));
    dispatch(getInitialData(value));
  };

  const languageSelector = (
    <div className={'column small-8 large-6'}>
      <RadioButtons
        options={options}
        horizontal={true}
        value={languageFilter ? languageFilter : langFi}
        onChange={onChange}
        name={'language'}
      />
    </div>
  );

  const components = templateGroups?.map((group: GroupedTemplate) => {
    const groupId = Object.keys(group)[0];
    return (
      <SelectionGroup
        selectContract={selectContract}
        isLoadingTemplate={isLoadingTemplate}
        customerId={params.id}
        selected={selected}
        templates={filterByFields(group, groupId)}
        groupId={groupId}
        key={groupId}
        language={languageFilter}
      />
    );
  });

  let content;
  if (isBusy) {
    content = <Spinner />;
  } else {
    content = (
      <>
        <ModalDialog
          actions={[
            <StyledButton
              variant="text"
              onClick={handleNext}
              key={'customer.stillContinue'}
              data-testkey="digi-customer-continue-button"
            >
              {translate('customer.stillContinue')}
            </StyledButton>,
            <StyledButton
              variant="text"
              onClick={hideModal}
              key={'customer.cancel'}
              data-testkey="digi-customer-cancel-button"
            >
              {translate('customer.cancel')}
            </StyledButton>,
          ]}
          open={openCustomerServiceConfirmation}
          title={translate('customer.digiCustomerConfirmationTitle')}
          content={translate('customer.digiCustomerConfirmationContent')}
        />
        {components}
      </>
    );
  }

  return (
    <section className={styles.contracts}>
      <SubNav
        label={label}
        onNextClick={handleNext}
        actionDisabled={!hasSelectedContracts() || isLoadingAnyTemplate()}
      />
      {isBusy ? undefined : filter}
      {isBusy ? undefined : languageSelector}
      {isBusy ? undefined : (
        <div>
          <br />
        </div>
      )}
      {content}
    </section>
  );
};
