import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { optimizationManners } from 'constants/allocator';
import { colors } from 'styles/colors';
import { FlexAlignCenter, Flex, FlexJustifyCenter, fontSize } from 'features/common/StyledComponents';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { selectPortfolioName } from 'features/allocator/allocatorSelectors';
import {
  getPlanPortfolioContractType,
  getPortfolioIndex,
  getPortfolioOptimizationModel,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { OptimizationMannerId } from 'types/types';
import { PortfolioInstrument } from 'types/instrumentsState';
import { customerHasCurrentWeights } from 'features/weights/weightsSelectors';

interface Props {
  portfolio: AllocatorPortfolio;
  selectedPortfolioId: string;
  instrumentsSelected: PortfolioInstrument[];
  setSelectedPortfolioId: (id: string) => void;
}

export const PortfolioSelector = ({
  portfolio,
  setSelectedPortfolioId,
  selectedPortfolioId,
  instrumentsSelected,
}: Props) => {
  const { portfolioId } = portfolio;
  const isNewCustomer = !useSelector(customerHasCurrentWeights);
  const portfolioName = useSelector(selectPortfolioName(portfolioId));

  const model = getPortfolioOptimizationModel(portfolio);
  const disabled = model !== optimizationManners.INCLUDE;
  const instrumentCount = instrumentsSelected.filter((i) => i.portfolioId === portfolioId).length;
  const portfolioContractType = getPlanPortfolioContractType(portfolio);

  return (
    <PortfolioButton
      key={portfolioId}
      onClick={() => (disabled ? undefined : setSelectedPortfolioId(portfolioId))}
      selected={selectedPortfolioId === portfolioId}
      disabled={disabled}
      data-testkey={`select-portfolio-${portfolioId}`}
    >
      <LabelContainer>
        <OptimizationModel model={model}>
          {translate(`allocator.investmentPlanSteps.portfolios.${model}`)}
        </OptimizationModel>
      </LabelContainer>
      <ContractType>{portfolioContractType}</ContractType>
      <InstrumentCount>
        {instrumentCount} {translate('allocator.instrumentsView.instruments')}
      </InstrumentCount>
      {!isNewCustomer && <PortfolioId>{getPortfolioIndex(portfolioId)}</PortfolioId>}
      <div>{portfolioName}</div>
    </PortfolioButton>
  );
};

interface ButtonProps {
  selected: boolean;
  disabled: boolean;
}

const PortfolioButton = styled(FlexAlignCenter)<ButtonProps>`
  height: 40px;
  padding: 0.5rem 0.75rem;
  ${(props) => props.selected && 'background: white'};
  transition: all 0.2s;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  border-radius: 4px;
  border: 1px solid transparent;
  background: transparent;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  ${(props) =>
    props.selected &&
    css`
      background: white;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      border-color: ${colors.primary};
    `}

  &:hover {
    ${(props) => !props.selected && !props.disabled && `border: 1px solid ${colors.med_gray}`};
  }
`;

const LabelContainer = styled(Flex)`
  width: 65px;
`;

const OptimizationModel = styled(FlexJustifyCenter)<{ model: OptimizationMannerId }>`
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  padding: 0.15rem 0.5rem;
  border-radius: 15px;

  ${(props) =>
    props.model === optimizationManners.INCLUDE &&
    css`
      background: ${colors.optimized};
      color: white;
      font-weight: 700;
    `};

  ${(props) =>
    props.model === optimizationManners.CONSIDER &&
    css`
      background: ${colors.considered};
    `};

  ${(props) =>
    props.model === optimizationManners.IGNORE &&
    css`
      background: ${colors.med_gray};
      color: ${colors.dark_gray};
    `};
`;

const PortfolioId = styled.div`
  opacity: 0.7;
  font-size: ${fontSize.smaller};
  flex: 0 0 20px;
  font-weight: 900;
  text-align: right;
  margin-left: 1rem;
  margin-right: 0.5rem;
`;

const ContractType = styled.div`
  flex: 0 0 75px;
  font-size: ${fontSize.smaller};
`;

const InstrumentCount = styled.div`
  opacity: 0.7;
  font-size: ${fontSize.smaller};
  flex: 0 0 55px;
  text-align: right;
  margin-right: 1rem;
`;
