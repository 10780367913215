import PropTypes from 'prop-types';
import React from 'react';
import translate from 'counterpart';
import styles from './Nav.scss';
import { NavBar } from 'features/common/NavBar';

export default class Nav extends React.Component {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  getContent = () => (
    <div>
      <button onClick={this.goToHome} className={styles.close}>
        <span>{translate(`common.close`)}</span>
        <i className="icon icon-close" />
      </button>
    </div>
  );

  goToHome = () => {
    this.context.router.push('/');
  };

  render() {
    return <NavBar rightContent={this.getContent()} />;
  }
}
