import React from 'react';
import _ from 'lodash';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material';
import translate from 'counterpart';
import StaticRow from './StaticRow';
import { tableHeaderStyles as headStyles } from 'styles/material-ui/material-ui';
import { PortfolioDetailsById } from 'types/portfolioState';

interface Props {
  portfolioDetailsById: PortfolioDetailsById;
}

export const Static = ({ portfolioDetailsById }: Props) => {
  const headStyle = {
    ...headStyles,
    fontFamily: 'Calibre',
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'none',
  } as React.CSSProperties;
  const rows = _.map(portfolioDetailsById, (p) => <StaticRow key={p.portfolioId} portfolio={p} />);
  return (
    <Table style={{ paddingLeft: 0, tableLayout: 'auto' }}>
      <TableHead style={{ paddingLeft: 0 }}>
        <TableRow style={{ fontSize: 11, height: 35 }}>
          <TableCell style={{ ...headStyle, textAlign: 'left' }}>{translate(`order.id`)}</TableCell>
          <TableCell style={{ ...headStyle, textAlign: 'left' }}>{translate(`order.portfolioName`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.status`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.bank`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.accountNumber`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.referenceNumber`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.assetManagementFee`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.tradingFee`)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ paddingLeft: 0 }}>{rows}</TableBody>
    </Table>
  );
};
