import React from 'react';
import balanceIcon from 'images/icon_balance.svg';
import lockIcon from 'images/icon_lock.svg';
import volatilityIcon from 'images/icon_volatility.svg';
import yieldIcon from 'images/icon_yield.svg';
import returnIcon from 'images/icon_return.svg';
import lockSmallIcon from 'images/icon_lock_small.svg';
import errorIcon from 'images/icon_error.svg';
import allocator from 'images/allocator.svg';

const SvgIconWrapper = (src: string) => {
  const SvgIcon = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
    return <img alt="svg-icon" {...props} src={src} style={{ maxWidth: 'none' }} />;
  };
  return SvgIcon;
};

export const BalanceIcon = SvgIconWrapper(balanceIcon);
export const LockIcon = SvgIconWrapper(lockIcon);
export const YieldIcon = SvgIconWrapper(yieldIcon);
export const VolatilityIcon = SvgIconWrapper(volatilityIcon);
export const ReturnIcon = SvgIconWrapper(returnIcon);
export const LockSmallIcon = SvgIconWrapper(lockSmallIcon);
export const ErrorIcon = SvgIconWrapper(errorIcon);
export const AllocatorIcon = SvgIconWrapper(allocator);
