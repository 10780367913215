import React from 'react';
import { Link } from 'react-router';
import translate from 'counterpart';
import classnames from 'classnames';
import styled from 'styled-components';
import styles from './HomeView.scss';
import { NavBar } from 'features/common/NavBar';
import { FlexColumn } from 'features/common/StyledComponents';
import { NavContent } from 'features/home/components/NavContent';

export const HomeView = () => {
  return (
    <div id="home" className={styles.home} data-testkey="pt-frontpage-loaded-after-login">
      <NavBar rightContent={<NavContent />} />
      <Container>
        <MenuRow>
          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <Link className={styles.findCustomer} to="/search">
              <i />
              <div className="button" data-testkey="find-customer-button">
                {translate(`home.searchCustomer`)}
              </div>
            </Link>
          </div>

          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <Link className={styles.newCustomer} to="/new/type">
              <i />
              <div className="button" data-testkey="new-customer-button">
                {translate(`home.createCustomer`)}
              </div>
            </Link>
          </div>

          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <Link className={styles.myCustomers} to="/mycustomers">
              <i />
              <div className="button" data-testkey="my-customers-button">
                {translate(`home.mycystomers`)}
              </div>
            </Link>
          </div>
        </MenuRow>

        <MenuRow>
          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <Link className={styles.contracts} to="/contracts">
              <i />
              <div className="button" data-testkey="contracts-button">
                {translate(`home.contracts`)}
              </div>
            </Link>
          </div>

          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <Link className={styles.orders} to="/orders">
              <i />
              <div className="button" data-testkey="orders-button">
                {translate(`home.orders`)}
              </div>
            </Link>
          </div>

          <div className={classnames(styles.column, 'small-12 medium-expand')}>
            <span className={styles.allocatorDemo} style={{ cursor: 'default' }} />
          </div>
        </MenuRow>
      </Container>
    </div>
  );
};

const MenuRow = styled.div`
  height: 50%;
  display: flex;
  flex-direction: row;
`;

const Container = styled(FlexColumn)`
  flex: auto;
`;
