import React from 'react';
import { Link } from 'react-router';
import translate from 'counterpart';
import { Location } from 'history';
import styles from './ContractsNav.scss';
import { ContractLink } from 'types/contractsState';

interface Props {
  isReady: boolean;
  location: Location;
  links: ContractLink[];
  contractsCompleted: boolean;
}

export default class Nav extends React.Component<Props> {
  getLinkPath = (link: ContractLink, location: Location) => {
    // Replace last part of URL with the one from links array
    const addr = location.pathname.split('/');
    addr.pop();
    addr.push(link.route);
    const addrString = addr.join('/');
    return addrString;
  };

  getLinkIcon = (disabled: boolean, index: number) =>
    disabled ? (
      <span style={{ marginRight: '0.5rem' }}>
        <i className="icon icon-lock" />
      </span>
    ) : (
      <span style={{ marginRight: '0.5rem' }}>{`${index + 1}.`}</span>
    );

  render() {
    const { links, location, isReady, contractsCompleted } = this.props;
    const items = links.map((link, key) => {
      const to = this.getLinkPath(link, location);
      // if ready disable/lock all but last tab
      // if not ready then lock last tab
      let disabled;
      if (contractsCompleted) {
        disabled = (isReady && key < links.length - 1) || (!isReady && key === links.length - 1);
      } else {
        disabled = (isReady && key < links.length - 1) || (!isReady && key >= links.length - 2);
      }
      const linkIcon = this.getLinkIcon(disabled, key);
      const linkStyle = disabled ? { pointerEvents: 'none' } : {};
      return (
        <li key={key}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <Link to={to} style={linkStyle} className={styles.link} activeClassName={styles.selected} disabled={disabled}>
            {linkIcon}
            <span>{translate(link.translateLink)}</span>
          </Link>
        </li>
      );
    });

    return (
      <section>
        <nav className={styles.secondNav}>
          <ul className="row align-justify">{items}</ul>
        </nav>
      </section>
    );
  }
}
