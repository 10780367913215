import { uniq, sortBy } from 'lodash';
import { InvestmentPlanState } from 'types/investmentPlanState';
import { AvailableTemplate, AdditionalContract } from 'types/contractsState';
import { finishedContractStates, unfinishedContractStates } from 'constants/contractStates';
import { Content } from 'types/profileState';
import { Contract } from 'types/ordersState';
import {
  EMAIL_REPORTS,
  WEB_SERVICE_CONTRACT,
  DISCRETIONARY_CONTRACT_PERSON,
  CONSULTATIVE_CONTRACT_PERSON,
  BASIC_INFO_PERSON,
} from 'constants/contractIds';
import { NO_CONTRACT } from 'constants/contractNames';

export const REQUIRED_CONTRACTS = [DISCRETIONARY_CONTRACT_PERSON, CONSULTATIVE_CONTRACT_PERSON];
export const ADDITIONAL_CONTRACTS = [EMAIL_REPORTS, WEB_SERVICE_CONTRACT];

export const portfolioContractIdToName = {
  TV: DISCRETIONARY_CONTRACT_PERSON,
  TYS: DISCRETIONARY_CONTRACT_PERSON,
  KONS: CONSULTATIVE_CONTRACT_PERSON,
  Tuntematon: 'Tuntematon',
  Koonti: 'Koonti',
  ['Tehtaan salkku']: NO_CONTRACT,
};

export const getRequiredContractsStatus = (additionalContracts: AdditionalContract[]) => {
  const required = additionalContracts.filter((additionalContract) =>
    REQUIRED_CONTRACTS.includes(additionalContract.shortName)
  );
  const allSigned = required.every((i) => i.state === 'done');
  return allSigned;
};

const getRequiredContractNames = (portfolios: InvestmentPlanState) => {
  const portfolioContractTypes = uniq(
    portfolios?.planPortfolios?.map((p) => p.manualSettings?.contractType || 'Tuntematon').filter(Boolean)
  );
  const requiredContractNames = portfolioContractTypes.map((i) => portfolioContractIdToName[i]);
  return requiredContractNames;
};

export const getAdditionalContracts = (
  portfolios: InvestmentPlanState,
  templates: AvailableTemplate[],
  profileContracts: Contract[],
  drafts: Content | undefined
): AdditionalContract[] => {
  const requiredContractNames = getRequiredContractNames(portfolios).concat(ADDITIONAL_CONTRACTS);

  const sortedContracts = sortBy(profileContracts, 'editDate').reverse();

  const finishedContracts = requiredContractNames
    .map((requiredType) => {
      const doneContract = Object.values(sortedContracts).find(
        (contract) => contract.contractType === requiredType && finishedContractStates.includes(contract.state)
      );

      if (doneContract) {
        return doneContract;
      }

      const draftContract = Object.values(sortedContracts).find(
        (contract) => contract.contractType === requiredType && unfinishedContractStates.includes(contract.state)
      );

      if (draftContract) {
        return draftContract;
      }
    })
    .filter((contract) => contract) as Contract[];

  const requiredContracts = requiredContractNames.map((name) => ({
    shortName: name,
    fullName: templates?.find((i) => i.id === name)?.name || '',
    ...getContractState(name, finishedContracts, drafts),
  }));

  return requiredContracts;
};

const getContractState = (name: string, finishedContracts: Contract[], drafts: Content | undefined) => {
  const finishedContract = finishedContracts.find((c) => c?.contractType === name);
  const draftContractNames = getDraftContractNames(drafts);

  if (finishedContract) {
    if (finishedContractStates.includes(finishedContract?.state)) {
      return {
        state: 'done',
        stateName: `contractStates.${finishedContract.state}`,
        contractId: finishedContract.shortId,
      };
    }
    if (unfinishedContractStates.includes(finishedContract?.state)) {
      return {
        state: 'waiting',
        stateName: `contractStates.${finishedContract.state}`,
        contractId: finishedContract.shortId,
      };
    }
  }
  if (draftContractNames.includes(name)) {
    return {
      stateName: 'customer.newCustomerSteps.isDraft',
      state: 'draft',
      contractId: '',
    };
  }
  return {
    stateName: 'customer.newCustomerSteps.isNotFilled',
    state: 'none',
    contractId: '',
  };
};

export const getDraftContractNames = (drafts: Content | undefined) => {
  return drafts ? Object.keys(drafts) : [];
};

export const getContractsById = (profileContracts: Contract[], basicInfoContractId: string) => {
  return Object.values(profileContracts).filter((i) => i.contractType === basicInfoContractId);
};

export const getFinishedBasicInfoContract = (profileContracts: Contract[]) => {
  const basicInfoContracts = getContractsById(profileContracts, BASIC_INFO_PERSON);
  const sortedBasicInfoContracts = sortBy(basicInfoContracts, 'editDate').reverse();
  const finishedBasicInfoContract = sortedBasicInfoContracts.find((i) => finishedContractStates.includes(i.state));
  return finishedBasicInfoContract;
};
