import React from 'react';
import { useStore } from 'react-redux';
import translate from 'counterpart';
import { Iterable } from 'immutable';
import { OrderLinesSummary } from 'features/orderLines/components/OrderLinesSummary';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';
import { ImmutableOrderLineType } from 'types/ordersState';
import { ADVICE, ORDER } from 'constants/orderRowTypes';

interface Props {
  orderLines: Iterable<number, ImmutableOrderLineType>;
}

export const GroupedOrderLines = (props: Props) => {
  const store = useStore();
  const state = store.getState();

  const orderLines = props.orderLines.filter((line) => line?.get('rowType') === ORDER);
  const adviceLines = props.orderLines.filter((line) => line?.get('rowType') === ADVICE);
  const portfolioDetailsById = selectPortfolioDetailsById(state);

  return (
    <section>
      <OrderLinesSummary
        orderLines={orderLines.toJS()}
        portfolioDetailsById={portfolioDetailsById}
        header={translate('order.orders')}
        editable={false}
      />
      <OrderLinesSummary
        orderLines={adviceLines.toJS()}
        portfolioDetailsById={portfolioDetailsById}
        header={translate('order.investmentAdvice')}
        editable={false}
      />
    </section>
  );
};
