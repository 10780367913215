import { groupBy } from 'lodash';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { getAssetClassId } from 'features/allocator/instruments/instrumentsUtils';
import { createConstraintsPayload } from 'features/allocator/constraints/constraintsUtils';
import { clearInstrumentWeights } from 'features/allocator/planPortfolios/planPortfolioUtils';
import {
  OptimizePlanResponseUnit,
  OptimizePlanResponseInstrument,
  OptimizePlanPayload,
  OptimizePlanResponseWeight,
} from 'types/newCustomerOptimization';
import { RootState } from 'types/rootState';
import { AssetCategoryName, PlanLength } from 'types/types';
import { AssetCategoryWeights, OptimizedWeights, PortfolioWeights, Weights } from 'types/weightsState';
import { InstrumentType, PortfolioInstrument } from 'types/instrumentsState';
import {
  AdditionalInstrument,
  ExistingCustomerOptimizePlanPayload,
  ExistingCustomerOptimizePlanResponse,
} from 'types/existingCustomerOptimization';
import {
  allPlanInstrumentsAreNotInPositions,
  selectHasPositions,
  selectRisk,
  selectPlanPortfolios,
  selectInstrumentsSelected,
  selectHasError,
  customerHasValidPlan,
  selectPositionsNotInPlan,
  selectPlanInstrumentsNotInPositions,
  selectPlanState,
} from 'features/allocator/allocatorSelectors';
import {
  createPlanPortfoliosForNewCustomer,
  getDefaultOptimizationManner,
} from 'features/allocator/planPortfolios/planPortfolioCreationUtils';
import { AnythingWithWeight, OptimizationOutputWeights } from 'features/weights/weightTypes';
import { mapExistingCustomerWeights } from 'features/weights/existingCustomerMappers';
import { postOptimizeExistingCustomer, postOptimizeInvestmentPlan } from 'features/weights/weightsApiCalls';
import {
  createOptimizedPortfoliosFromOptimizedValues,
  selectHasPositionsInNonIgnoredPortfolio,
} from 'features/weights/weightsSelectors';
import {
  selectConsideredPortfolioIds,
  selectOptimizedPortfolioIds,
  selectIgnoredPortfolioIds,
} from 'features/allocator/planPortfolios/planPortfolioSelectors';
import { optimizationManners } from 'constants/allocator';

export const optimizeForNewCustomer = async (
  state: RootState,
  params?: Partial<OptimizePlanPayload>
): Promise<OptimizationOutputWeights> => {
  const defaultPayload = createNewCustomerOptimizePayload(state);
  const payload = {
    ...defaultPayload,
    ...params,
  };
  const result = await postOptimizeInvestmentPlan(payload, state);

  const weights = {
    riskLevel: payload.risk,
    optimizationForecastType: state.portfolioManager.investmentPlan.optimizationForecastType,
    withIlliquids: mapOptimizationResult(result.withIlliquid),
    withoutIlliquids: mapOptimizationResult(result.withoutIlliquid),
  };

  const hasPositions = selectHasPositions(state);
  const allPlanInstrumentsNotInPositions = allPlanInstrumentsAreNotInPositions(state);

  const portfolios = {
    riskLevel: payload.risk,
    optimizationForecastType: state.portfolioManager.investmentPlan.optimizationForecastType,
    portfolios:
      hasPositions || allPlanInstrumentsNotInPositions // special case, not new customer
        ? updatePortfolioWeights(state, weights)
        : createPlanPortfoliosForNewCustomer(result),
  };

  return {
    weights,
    portfolios,
  };
};

const updatePortfolioWeights = (state: RootState, weights: OptimizedWeights) => {
  const portfolios = selectPlanPortfolios(state);
  const instrumentWeights = weights.withoutIlliquids.instrumentWeights;
  return portfolios.map((p) => ({
    ...p,
    allocatedPortfolioRows: {
      ...p.allocatedPortfolioRows,
      withoutIlliquids: p.allocatedPortfolioRows.withoutIlliquids.map((i) => ({
        ...i,
        weight: instrumentWeights.find((j) => j.security === i.security)?.weight ?? 0,
      })),
    },
  }));
};

const createNewCustomerOptimizePayload = (state: RootState): OptimizePlanPayload => ({
  minWeight: 0.01,
  instrumentConstraints: [],
  useDefaultConstraints: true,
  useCompanyOptimizationForecast: state.portfolioManager.investmentPlan.optimizationForecastType === 'company',
  assetClassConstraints: createConstraintsPayload(state.portfolioManager.investmentPlan.constraints),
  securitiesAvailable: selectInstrumentsSelected(state)?.flatMap((i) => i?.security || []),
  risk: selectRisk(state),
});

export const optimizeForExistingCustomer = async (
  state: RootState,
  params?: Partial<ExistingCustomerOptimizePlanPayload>
): Promise<OptimizationOutputWeights> => {
  const payload = {
    ...createExistingCustomerOptimizePayload(state),
    ...params,
  };

  const result = await postOptimizeExistingCustomer(payload, state);

  if (result.withIlliquids?.success !== 1 && result.withoutIlliquids?.success !== 1) {
    throw 'Neither had success = 1';
  }

  return {
    weights: {
      ...mapExistingCustomerWeights(result),
      riskLevel: payload.customerRiskLevel,
      optimizationForecastType: state.portfolioManager.investmentPlan.optimizationForecastType,
    },
    portfolios: {
      portfolios: updateExistingCustomerOptimizedPortfolioWeights(state, result),
      riskLevel: payload.customerRiskLevel,
      optimizationForecastType: state.portfolioManager.investmentPlan.optimizationForecastType,
    },
  };
};

const createExistingCustomerOptimizePayload = (state: RootState): ExistingCustomerOptimizePlanPayload => {
  const planInstrumentsNotInPositions = selectPlanInstrumentsNotInPositions({
    removeZeroWeightPlanInstruments: false, // false because we want ALL plan instruments in the payload
  })(state);

  const optimizedPortfolioIds = selectOptimizedPortfolioIds(state);
  const optimizedPlanInstrumentsNotInPositions = planInstrumentsNotInPositions.filter((i) =>
    optimizedPortfolioIds.includes(i.portfolioId)
  );
  const additionalInstruments = convertToAdditionalInstrumentsPayload(optimizedPlanInstrumentsNotInPositions);

  return {
    customerId: state.profile.customer?.toJS().customerId,
    assetClassConstraints: createConstraintsPayload(state.portfolioManager.investmentPlan.constraints),
    customerRiskLevel: selectRisk(state),
    optimizedPortfolios: selectOptimizedPortfolioIds(state),
    staticPortfolios: selectConsideredPortfolioIds(state),
    additionalInstruments,
    useProprietaryInvestmentViews: state.portfolioManager.investmentPlan.optimizationForecastType === 'company',
    useBackendConstraints: state.portfolioManager.investmentPlan.constraints.useBackendConstraints,
  };
};

export const convertToAdditionalInstrumentsPayload = (instruments: AdditionalInstrument[]) => {
  const groupedInstruments = groupBy(instruments, 'portfolioId');
  const instrumentsPayload = Object.entries(groupedInstruments).reduce((acc, entry) => {
    const [portfolioId, instruments] = entry;
    return {
      ...acc,
      [portfolioId]: instruments.map((i) => i.security),
    };
  }, {});

  return instrumentsPayload;
};

const updateExistingCustomerOptimizedPortfolioWeights = (
  state: RootState,
  result: ExistingCustomerOptimizePlanResponse
): AllocatorPortfolio[] => {
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);

  const updatedPortfolios = optimizedPortfolios.map((p) => ({
    ...p,
    allocatedPortfolioRows: {
      withIlliquids: updateWeights(result, p, 'withIlliquids'),
      withoutIlliquids: updateWeights(result, p, 'withoutIlliquids'),
    },
  }));

  return updatedPortfolios;
};

const updateWeights = (
  result: ExistingCustomerOptimizePlanResponse,
  portfolio: AllocatorPortfolio,
  planLength: PlanLength
) => {
  const success = result[planLength]?.success === 1;

  if (!success) {
    return portfolio.allocatedPortfolioRows[planLength].map(clearInstrumentWeights);
  }

  const resultPortfolio = result[planLength].portfolioWeights.portfolios.find(
    (p) => p.portfolioId === portfolio.portfolioId
  );

  // if portfolio is not in results (= ignored), use existing portfolio
  if (!resultPortfolio) {
    return portfolio.allocatedPortfolioRows[planLength];
  }

  return resultPortfolio?.instruments.map((i) => ({
    ...i,
    marketValue: i?.optimalBaseCcyMarketValue,
    portfolioCurrencyMarketValue: i?.optimalPortfolioCcyMarketValue,
  }));
};

/// NEW CUSTOMER utils:

export const mapOptimizationResult = (result: OptimizePlanResponseUnit): Weights => {
  return {
    assetCategoryWeights: mapAssetCategoryWeights(result.weights),
    instrumentWeights: result.instrumentWeights.map(planInstrumentMapper),
    neutralOptimizationForecastStatistics: result.neutralOptimizationForecastStatistics,
    companyOptimizationForecastStatistics: result.companyOptimizationForecastStatistics,
    portfolioWeights: result.instrumentPortfolioWeights?.length ? mapPortfolioWeights(result) : [],
    returnStatistics: {
      portfolioReturn: result.companyOptimizationForecastStatistics.weightStats.expectedReturn,
      portfolioVolatility: result.companyOptimizationForecastStatistics.weightStats.volatility,
      riskFloat: result.companyOptimizationForecastStatistics.weightStats.riskFloat,
    },
  };
};

export const mapAssetCategoryWeights = (weights: OptimizePlanResponseWeight[]): AssetCategoryWeights[] => {
  const weightsRounded = roundWeights(weights);
  return weightsRounded.map((i) => ({
    name: capitalize(i.type) as AssetCategoryName,
    weight: i.weight,
    assetClasses: i.assets.map((a) => ({
      weight: a.weight,
      assetClassId: a.category,
      assetCategory: capitalize(i.type) as AssetCategoryName,
    })),
  }));
};

const capitalize = (string: string) => string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

const mapPortfolioWeights = (result: OptimizePlanResponseUnit): PortfolioWeights[] =>
  result.instrumentPortfolioWeights?.map((portfolioWeight) => ({
    portfolioId: portfolioWeight.portfolioId,
    instruments: portfolioWeight.instruments.map((i) => ({
      ...planInstrumentMapper(i),
      portfolioId: i?.portfolioId,
    })),
    portfolioCurrency: portfolioWeight.portfolioCurrency,
  }));

const planInstrumentMapper = (i: OptimizePlanResponseInstrument): PortfolioInstrument => {
  const assetClassId = getAssetClassId(i.assetClasses);
  return {
    assetCategory: assetClassId === 'ALLOC' ? 'Allokaatiot' : i.assetClassCategory,
    assetClasses: i.assetClasses,
    assetClassId,
    liquidity: i.liquidity,
    marketValue: i.marketValue,
    portfolioCurrencyMarketValue: i.portfolioCurrencyMarketValue,
    name: i.name,
    price: i.unitPrice,
    quantity: i.quantity,
    security: i.security,
    type: i.securityType as InstrumentType,
    weight: i.weight,
    portfolioId: i.portfolioId,
  };
};

const roundWeights = <T extends AnythingWithWeight>(weights: T[]): T[] =>
  largestRemainderRound(weights, 1).map((w) => ({
    ...w,
    assetClasses: largestRemainderRound(w?.assets || [], w.weight),
  }));

// VilleR: 3.6.2019 More information about the largest remainder rounding (the first answer):
// Should this be refactored so that it doesn't round values to 0?
// https://stackoverflow.com/questions/13483430/how-to-make-rounded-percentages-add-up-to-100
const largestRemainderRound = <T extends AnythingWithWeight>(arr: T[], target: number): T[] => {
  const weights = deepCopy(arr);
  const off = Math.round(100 * (target - weights.reduce((acc, w) => acc + roundPercent(w.weight), 0)));
  return weights.map((x, i: number) => ({
    ...x,
    weight: (100 * roundPercent(x.weight) + (off > i ? 1 : 0) - (i >= weights.length + off ? 1 : 0)) / 100,
  }));
};

const deepCopy = <T>(arr: T[]): T[] => JSON.parse(JSON.stringify(arr));

const roundPercent = (percent: number) => Math.round(percent * 1000) / 1000; // 1000 = 10^3 = 3 significant digits

export const checkIfCantOptimize = (state: RootState) => {
  const useNewCustomerOptimization = !selectHasPositions(state);
  const risk = selectRisk(state);
  const negativePositions = selectHasError(['negativePositions'])(state);
  const instrumentsSelected = selectInstrumentsSelected(state);
  const noOptimizedPortfolios = selectOptimizedPortfolioIds(state)?.length === 0;
  const positionsNotInPlan = selectPositionsNotInPlan(state);
  const hasPositionsInNonIgnoredPortfolio = selectHasPositionsInNonIgnoredPortfolio(state);

  if (risk === 0) {
    return true;
  }

  if (useNewCustomerOptimization) {
    if (instrumentsSelected.length === 0) {
      return true;
    }
  } else {
    if (
      positionsNotInPlan.length > 0 ||
      negativePositions ||
      noOptimizedPortfolios ||
      !hasPositionsInNonIgnoredPortfolio
    ) {
      return true;
    }
  }
  return false;
};

export const selectIgnoredPortfolioIdsForOptimization = (state: RootState) => {
  const portfolioIds = state.portfolio.portfolioIds;
  const hasValidPlan = customerHasValidPlan(state);
  const planState = selectPlanState(state);

  if (planState === 'invalidPlan') {
    return [];
  }

  if (hasValidPlan) {
    return selectIgnoredPortfolioIds(state);
  }

  return portfolioIds.filter(
    (portfolioId) => getDefaultOptimizationManner(state, portfolioId) === optimizationManners.IGNORE
  );
};

export const selectNonIgnoredPortfolioIdsForOptimization = (state: RootState) => {
  const portfolioIds = state.portfolio.portfolioIds;
  const ignoredPortfolioIds = selectIgnoredPortfolioIdsForOptimization(state);
  const nonIgnoredPortfolioIds = portfolioIds.filter((x) => !ignoredPortfolioIds.includes(x));
  return nonIgnoredPortfolioIds;
};
