export const BUSINESS = 'Business';
export const FIRST_MEETING = 'FirstMeeting';
export const ORDER = 'Order';
export const INVESTMENT_ADVICE = 'InvestmentAdvice';
export const SOCIAL = 'Social';
export const STATUS_CHECK = 'StatusReview';
export const TAXATION = 'Taxation';
export const MANAGEMENT_APPOINMENT = 'ManagementAppointment';
export const ADDITIONAL_SALES = 'AdditionalSales';

export const meetingReason = {
  BUSINESS,
  FIRST_MEETING,
  ORDER,
  INVESTMENT_ADVICE,
  SOCIAL,
  STATUS_CHECK,
  TAXATION,
  MANAGEMENT_APPOINMENT,
  ADDITIONAL_SALES,
};
