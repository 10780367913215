import React from 'react';
import classnames from 'classnames';
import { SelectFee } from './SelectFee';
import { Volume } from './Volume';
import { Note } from './Note';
import { Leverage } from './Leverage';
import styles from './StructuredProductDetails.scss';
import MoneySource from './MoneySource';
import { ValidationErrorValue, TouchedFields } from 'types/orderDialogState';
import { DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { SelectValue } from 'types/types';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  assignment: OrderLineType;
  touchedFields: TouchedFields;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentFee?: number;
  onFeeChange: (selectedValue: SelectValue) => void;
  isFetchingFee: boolean;
  defaultFee: number;
  onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  structuredStep: number;
  structuredMinimumSum: number;
  getError: (key: string) => ValidationErrorValue | undefined;
  defaultAccount: DefaultAccount;
  portfolioContractName: string;
  isAppropriatenessTestOk: boolean;
}

export const StructuredProductDetails = (props: Props) => {
  const dispatch = useAppDispatch();

  const onLeverageChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditorValue('isLeveraged', _event.target.checked));
  };

  const {
    assignment,
    touchedFields,
    onChange,
    currentFee,
    onFeeChange,
    isFetchingFee,
    defaultFee,
    onNoteChange,
    getError,
    structuredMinimumSum,
    structuredStep,
    portfolioContractName,
    defaultAccount,
    isAppropriatenessTestOk,
  } = props;
  return (
    <div>
      <div className="row" key="volume">
        <div className={'columns small-4'}>
          <SelectFee
            currentFee={currentFee}
            onFeeChange={onFeeChange}
            isFetchingFee={isFetchingFee}
            defaultFee={defaultFee}
          />
        </div>
        <div className={'columns small-8'}>
          <Volume
            error={getError('subscriptionSum')}
            touched={touchedFields.subscriptionSum}
            value={assignment.subscriptionSum}
            onChange={onChange}
            textFieldProps={{
              label:
                structuredMinimumSum && structuredStep
                  ? `Nimellisarvo, min: ${structuredMinimumSum}, littera: ${structuredStep}, myyntihinta: ${
                      assignment.prePayPrice && assignment.prePayPrice * 100
                    } %`
                  : 'Nimellisarvo',
              defaultValue: `00,00 ${assignment.instrumentCurrency || ''}`,
            }}
            data-testkey={'order-volume-price'}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns small-6">
          <Note onNoteChange={onNoteChange} value={assignment.internalNote} marginTop={'6px'} />
        </div>
        <div className={classnames(styles.verticalCenter, 'columns small-6')}>
          <Leverage onLeverageChange={onLeverageChange} value={assignment.isLeveraged} />
        </div>
      </div>
      <div className="row">
        <div className="columns small-4">
          <MoneySource
            portfolioContractName={portfolioContractName}
            defaultAccount={defaultAccount}
            assignment={assignment}
          />
        </div>
        {isAppropriatenessTestOk ? (
          <div className="columns small-6" style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
            Asianmukaisuusarviointi <i className="icon icon-check" />
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
