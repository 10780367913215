import React from 'react';
import translate from 'counterpart';
import styled, { css } from 'styled-components';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import { OptimizationCell, LiabilityCell } from 'features/allocator/planPortfolios/PlanPortfoliosView';
import { customReactSelectStyle, FlexColumn, Flex, styles } from 'features/common/StyledComponents';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { ContractTypeId, PortfolioManagerId, OptimizationMannerId, PortfolioSettingType } from 'types/types';
import { WEIGHT_LIMIT, optimizationManners } from 'constants/allocator';
import { selectHasPositions, selectCurrentWeights, selectPortfolioName } from 'features/allocator/allocatorSelectors';
import { RootState } from 'types/rootState';
import { Spinner } from 'features/common/Spinner';
import {
  getFormattedPortfolioWeight,
  getPortfolioIndex,
  getPortfolioSettings,
  getPortfolioWeight,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';

interface Props {
  portfolio: AllocatorPortfolio;
  hasChanged: boolean;
  toggleOptimized: (id: string, optimizationManner: OptimizationMannerId) => void;
  onChangeSelect: (
    allocatorPortfolioId: string,
    type: PortfolioSettingType,
    value: ContractTypeId | PortfolioManagerId
  ) => void;
}

export const PlanPortfolioSettings = ({ portfolio, hasChanged, toggleOptimized, onChangeSelect }: Props) => {
  const { allocatorPortfolioId, portfolioId } = portfolio;

  const currentWeights = useSelector(selectCurrentWeights);
  const isOptimizingPlan = useSelector((state: RootState) => state.portfolioManager.weights.optimizingPlan);
  const hasPositions = useSelector(selectHasPositions);

  const allocatorPortfolioCount = useSelector(createOptimizedPortfoliosFromOptimizedValues())?.length;

  const portfolioWeight = getPortfolioWeight(portfolio, currentWeights);
  const formattedPortfolioWeight = getFormattedPortfolioWeight(portfolio, currentWeights);

  const handleSelectChange = (value: ReactSelectType, type: PortfolioSettingType) => {
    onChangeSelect(allocatorPortfolioId, type, value?.value);
  };

  const contractTypeOptions = portfolio.allowedSettings.contractType.map((i) => ({
    value: i,
    label: i,
  }));

  const portfolioManagerOptions = portfolio.allowedSettings.portfolioManager.map((i) => ({
    value: i,
    label: translate(`allocator.portfoliosView.managerTypes.${i}`),
  }));

  const optimizationMannerOptions = portfolio.allowedSettings?.optimizationManner;

  const settings = getPortfolioSettings(portfolio);
  const { contractType, portfolioManager, optimizationManner } = settings;

  const isOptimized = optimizationManner === optimizationManners.INCLUDE;
  const isConsidered = optimizationManner === optimizationManners.CONSIDER;
  const isIgnored = optimizationManner === optimizationManners.IGNORE;

  interface ReactSelectType {
    value: ContractTypeId | PortfolioManagerId;
    label: string;
  }
  const portfolioName = useSelector(selectPortfolioName(portfolioId));

  const displayName = portfolioName || translate('allocator.portfoliosView.personalAllocationStrategy');

  const id = getPortfolioIndex(portfolio.portfolioId);

  const showReOptimize = hasChanged && allocatorPortfolioCount > 1;

  return (
    <tr data-testkey="portfolio-row">
      <PortfolioNameCell>
        <Index>{id}</Index>
        <FlexColumn>{displayName}</FlexColumn>
      </PortfolioNameCell>

      <td data-testkey="contract-options">
        {contractTypeOptions.length > 1 ? (
          <Select
            options={contractTypeOptions}
            onChange={(value) => handleSelectChange(value as ReactSelectType, 'contractType')}
            isClearable={false}
            isSearchable={false}
            classNamePrefix="contract"
            value={{
              value: contractType,
              label: contractType,
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            styles={customReactSelectStyle}
          />
        ) : (
          translate(`allocator.portfoliosView.contractTypes.${contractType}`)
        )}
      </td>

      <LiabilityCell>
        {!hasPositions && portfolioManagerOptions.length > 1 ? (
          <Select
            options={portfolioManagerOptions}
            onChange={(value) => handleSelectChange(value as ReactSelectType, 'portfolioManager')}
            isClearable={false}
            isSearchable={false}
            value={{
              value: portfolioManager,
              label: translate(`allocator.portfoliosView.managerTypes.${portfolioManager}`),
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            styles={customReactSelectStyle}
          />
        ) : (
          translate(`allocator.portfoliosView.managerTypes.${portfolioManager}`)
        )}
      </LiabilityCell>

      <OptimizationCell>
        <Flex>
          <OptimizationModelButton
            value={allocatorPortfolioId}
            onClick={() => toggleOptimized(allocatorPortfolioId, optimizationManners.INCLUDE)}
            disabled={
              isOptimized || isOptimizingPlan || !optimizationMannerOptions.includes(optimizationManners.INCLUDE)
            }
            active={isOptimized}
            data-testkey={'button-portfolio-include'}
            model={optimizationManners.INCLUDE}
          >
            {translate('allocator.portfoliosView.toBeOptimized')}
          </OptimizationModelButton>

          <OptimizationModelButton
            value={allocatorPortfolioId}
            onClick={() => toggleOptimized(allocatorPortfolioId, optimizationManners.CONSIDER)}
            disabled={
              !hasPositions ||
              isConsidered ||
              isOptimizingPlan ||
              !optimizationMannerOptions.includes(optimizationManners.CONSIDER)
            }
            active={isConsidered}
            data-testkey="button-portfolio-consider"
            model={optimizationManners.CONSIDER}
          >
            {translate('allocator.portfoliosView.toBeConsidered')}
          </OptimizationModelButton>

          {hasPositions && (
            <OptimizationModelButton
              value={allocatorPortfolioId}
              onClick={() => toggleOptimized(allocatorPortfolioId, optimizationManners.IGNORE)}
              disabled={!hasPositions || isIgnored || isOptimizingPlan}
              active={isIgnored}
              data-testkey="button-portfolio-ignore"
              model={optimizationManners.IGNORE}
            >
              {translate('allocator.portfoliosView.toBeIgnored')}
            </OptimizationModelButton>
          )}
        </Flex>
      </OptimizationCell>

      <WeightCell faded={portfolioWeight < WEIGHT_LIMIT && !showReOptimize}>
        {isOptimizingPlan ? (
          <Spinner size={20} />
        ) : (
          <WeightContainer dim={isIgnored && !hasChanged}>
            {showReOptimize ? (
              <ReOptimize>{translate('allocator.portfoliosView.reoptimize')}</ReOptimize>
            ) : (
              formattedPortfolioWeight
            )}
          </WeightContainer>
        )}
      </WeightCell>
    </tr>
  );
};

const PortfolioNameCell = styled.td`
  white-space: normal;

  && {
    padding: 0 1rem;
  }
`;

const Index = styled.div`
  float: left;
  font-weight: 900;
  margin-right: 0.5rem;
  width: 1.5rem;
`;

const WeightContainer = styled.div<{ dim: boolean }>`
  width: 3rem;
  margin-right: 0.5rem;
  text-align: center;
  opacity: ${(props) => (props.dim ? 0.5 : 1)};
`;

const ReOptimize = styled.div`
  white-space: break-spaces;
`;

const WeightCell = styled.td<{ faded: boolean }>`
  position: relative;

  & > div {
    ${(props) => props.faded && 'opacity: .4'};
  }

  & > img {
    position: absolute;
    right: 100%;
  }
`;

const OptimizationModelButton = styled.button<{ active: boolean; model: OptimizationMannerId }>`
  position: relative;
  user-select: none;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  padding: 0.35rem 0.75rem;
  border-radius: 15px;
  justify-content: center;
  ${styles.link}

  color: ${(props) => (props.active || props.disabled ? colors.black : colors.link_blue)};
  opacity: ${(props) => (!props.disabled || props.active ? 1 : 0.3)};

  &:not(:last-child) {
    margin-right: 0.5rem;
  }

  &:nth-child(1),
  &:nth-child(2) {
    width: 120px;
  }

  &:nth-child(3) {
    width: 90px;
  }

  ${(props) =>
    props.active &&
    props.model === optimizationManners.INCLUDE &&
    css`
      background: ${colors.optimized};
      color: white;
    `};

  ${(props) =>
    props.active &&
    props.model === optimizationManners.CONSIDER &&
    css`
      background: ${colors.considered};
    `};

  ${(props) =>
    props.active &&
    props.model === optimizationManners.IGNORE &&
    css`
      background: ${colors.med_gray};
      color: ${colors.dark_gray};
    `};

  ${(props) => props.active && 'font-weight: 700'}
`;
