import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Location } from 'history';
import Immutable from 'immutable';
import translate from 'counterpart';
import { fetchMeetingData, editNote, mergeNote, saveNote } from 'features/meetingNotes/meetingNotesActions';
import {
  selectNote,
  selectCustomer,
  selectIsLoading,
  selectErrors,
  selectTouched,
} from 'features/meetingNotes/meetingNotesSelectors';
import { selectCustomerHeader } from 'features/profile/profileSelectors';
import Meeting from 'features/meetingNotes/components/Meeting';
import Nav from 'features/meetingNotes/components/Nav';
import { showNotification } from 'actions';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { RootState } from 'types/rootState';
import { Note } from 'types/ordersState';
import { ValidationErrorValue } from 'types/orderDialogState';
import { Customer } from 'types/profileState';

interface Props {
  params: { id: string };
  customerName: string;
  note: Note;
  customer: Customer;
  editNote: (key: string, value: string) => void;
  mergeNote: (note: Partial<Note>) => void;
  saveNote: (customerId: string, markAsComplete: boolean) => Promise<void>;
  location: Location;
  isLoading: boolean;
  errors: ValidationErrorValue[];
  touched: Immutable.Set<string>;
  showNotification: (notification: string) => void;
  fetchMeetingData: (customerId: string, activity: string) => void;
}

interface State {
  savingFailed: boolean;
  errorMessage: string;
}

class MeetingNoteView extends Component<Props, State> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      savingFailed: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    const { fetchMeetingData, params, location } = this.props;
    const activity = location.state ? location.state.activity : undefined;
    fetchMeetingData(params.id, activity);
  }

  finish = () => {
    const { saveNote, params, showNotification } = this.props;
    saveNote(params.id, true)
      .then(() => {
        showNotification(translate('meetings.finishedNotification'));
        this.context.router.goBack();
      })
      .catch((e) => {
        this.setState({
          savingFailed: true,
          errorMessage: e.message || '',
        });
      });
  };

  save = () => {
    const { saveNote, params, showNotification } = this.props;
    saveNote(params.id, false)
      .then(() => {
        showNotification(translate('meetings.savedNotification'));
        this.context.router.goBack();
      })
      .catch((e) => {
        this.setState({
          savingFailed: true,
          errorMessage: e.message || '',
        });
      });
  };

  render() {
    const { params, customerName, note, customer, editNote, mergeNote, isLoading, errors, touched } = this.props;
    const { savingFailed, errorMessage } = this.state;
    return (
      <section>
        <ErrorBoundary>
          <Nav
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            params={params}
            customerName={customerName}
            save={this.save}
            finish={this.finish}
            disabled={errors.length > 0 || isLoading || savingFailed}
          />
          <Meeting
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            note={note}
            customer={customer}
            editNote={editNote}
            mergeNote={mergeNote}
            isLoading={isLoading}
            errors={errors}
            touched={touched}
            savingFailed={savingFailed}
            errorMessage={errorMessage}
          />
        </ErrorBoundary>
      </section>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  customerName: selectCustomerHeader(state.profile),
  note: selectNote(state),
  customer: selectCustomer(state),
  isLoading: selectIsLoading(state),
  errors: selectErrors(state),
  touched: selectTouched(state),
});

const mapDispatchToProps = {
  editNote,
  mergeNote,
  saveNote,
  fetchMeetingData,
  showNotification,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(MeetingNoteView);
