import React from 'react';
import { formatNumber, formatMoney } from 'accounting';
import moment from 'moment';
import { sortBy } from 'lodash';
import styles from './Table.scss';
import { TavatResult } from 'features/orderList/orderList.types';

const getDate = (date: Date) => moment(date).format('DD.MM.YYYY HH:mm');

interface Props {
  status: TavatResult;
}

const TavatOrderStatus = ({ status }: Props) => (
  <div>
    <table className={styles.table}>
      <thead>
        <tr>
          <th>Status</th>
          <th>Aikaleima</th>
          <th>Käyttäjä</th>
          <th className="text-right">Määrä</th>
          <th className="text-right">Hinta</th>
          <th className="text-right">Blokkihinta</th>
          <th className="text-right">Kertynyt korko</th>
          <th>Syy</th>
          <th>Päättymispäivä</th>
        </tr>
      </thead>
      <tbody>
        {sortBy(status.executionReports, (r) => r.valueDate).map((r, i) => (
          <tr key={`tavat-details-row-${i}`}>
            <td>{r.ordStatus}</td>
            <td>{getDate(r.valueDate)}</td>
            <td>{r.statusUpdatedBy}</td>
            <td className="text-right">{formatNumber(r.lastQty)}</td>
            <td className="text-right">{formatMoney(r.lastPx)}</td>
            <td className="text-right">{formatMoney(r.blockPrice)}</td>
            <td className="text-right">{formatMoney(r.accruedInterest)}</td>
            <td>{r.cancelReason}</td>
            <td>{getDate(status.orderSecurity.expireDate)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default TavatOrderStatus;
