import React, { ReactElement } from 'react';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import translate from 'counterpart';
import SignatoryList from './SignatoryList';
import ManualSignatorySelection from './ManualSignatorySelection';
import styles from './SignatureSelection.scss';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';
import { ORDER_ADVICE } from 'constants/contractNames';
import { ORDER } from 'constants/contractTypes';
import { Spinner } from 'features/common/Spinner';
import { SignatureContract } from 'types/contractsState';
import { SignatureMethodType } from 'types/types';
import { Signatory } from 'types/ordersState';

interface Props {
  contract: SignatureContract;
  selectionHintText: string;
  selectedSignatureMethod: SignatureMethodType;
  headerIcon: ReactElement;
  onSignatureMethodSelected: (contractType: string | undefined, value: SignatureMethodType) => void;
  onNewSignatoryButtonClick: (signatureContract: SignatureContract) => void;
  onCopyAllSignatoriesButtonClick?: (signatories: Signatory[]) => void;
  showCopyAllSignatories: boolean;
  onSignatorySelect: (signatoryDetails: Signatory, signatureContract: SignatureContract) => void;
  signatureMethods: SignatureMethodType[];
  preventSigning?: boolean;
  disableSignatoryEdit?: boolean;
  fetchingWebServiceContractStatus?: boolean;
  hideSigningOptions?: boolean;
  disableAddSignatory: boolean;
  setManualSignatureAmount: (amount: number, contractKey?: string) => void;
}

export const SignatureSelection = ({
  contract = {
    key: '',
    name: '',
    link: '',
    signatories: [],
    type: '',
    manualSignerAmount: 1,
    names: [],
  },
  selectionHintText = '',
  selectedSignatureMethod = undefined,
  headerIcon,
  onSignatureMethodSelected = () => {},
  onNewSignatoryButtonClick = () => {},
  onCopyAllSignatoriesButtonClick = () => {},
  showCopyAllSignatories = false,
  onSignatorySelect = () => {},
  signatureMethods,
  preventSigning = false,
  disableSignatoryEdit = false,
  fetchingWebServiceContractStatus = false,
  hideSigningOptions = false,
  disableAddSignatory,
  setManualSignatureAmount,
}: Props) => {
  const getSignatureMenuItems = () =>
    signatureMethods.map((m) => (
      <MenuItem
        value={m}
        key={m}
        sx={{
          '&.MuiMenuItem-root': {
            fontFamily: 'Calibre, sans-serif',
            fontSize: '16px',
          },
          '&.Mui-selected': {
            fontFamily: 'Calibre, sans-serif',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            ':hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.06)',
            },
          },
        }}
      >
        {translate(`contract.${m}`)}
      </MenuItem>
    ));

  const handleSignatureSelection = (event: SelectChangeEvent) => {
    const signatureMethod = event.target.value as SignatureMethodType;
    onSignatureMethodSelected(contract.key, signatureMethod);
  };

  const handleOnNewSignatoryButtonClick = () => {
    onNewSignatoryButtonClick(contract);
  };

  const handleOnCopyAllSignatoriesButtonClick = () => {
    const signatories = contract.signatories;
    if (signatories[0] !== null) {
      onCopyAllSignatoriesButtonClick(signatories);
    }
  };

  const handleOnSignatoryItemSelect = (signatory: Signatory) => {
    onSignatorySelect(signatory, contract);
  };

  const handleChangingManualSigners = (value: number) => {
    setManualSignatureAmount(value, contract.key);
  };

  const isElectronicSignature = (signatureMethod: SignatureMethodType) =>
    signatureMethod === SENT_FOR_SIGNING || signatureMethod === SENT_FOR_INK_SIGNING;

  const getContractDisplayName = () => {
    if (contract.type === ORDER) {
      return ORDER_ADVICE;
    } else {
      return contract.name;
    }
  };

  const renderSignatoryList = () => {
    if (isElectronicSignature(selectedSignatureMethod) && !preventSigning && !hideSigningOptions) {
      return (
        <SignatoryList
          onNewSignatoryButtonClick={handleOnNewSignatoryButtonClick}
          onCopyAllSignatoriesButtonClick={handleOnCopyAllSignatoriesButtonClick}
          onItemSelect={handleOnSignatoryItemSelect}
          items={contract.signatories}
          showCopyAllSignatories={showCopyAllSignatories}
          disableEdit={disableSignatoryEdit}
          disableAddSignatory={disableAddSignatory}
        />
      );
    }

    if (selectedSignatureMethod === SENT_FOR_MANUAL_SIGNING) {
      return (
        <ManualSignatorySelection
          handleChangingManualSigners={handleChangingManualSigners}
          signers={contract.manualSignerAmount}
        />
      );
    }
    return undefined;
  };

  const getSignatureSelector = () => {
    if (hideSigningOptions) {
      return '';
    }
    if (fetchingWebServiceContractStatus) {
      return <Spinner className={styles.spinner} color="#ffffff" />;
    }
    if (preventSigning) {
      return <span className={styles.warning}>{translate(`newContract.contractExists`)}</span>;
    }
    return (
      <FormControl
        variant="standard"
        sx={{
          minWidth: '200px!important',
          backgroundColor: 'white!important',
        }}
      >
        <Select
          renderValue={(selectedSignatureMethod) => {
            if (!selectedSignatureMethod) {
              return <span>{selectionHintText}</span>;
            }
            return translate(`contract.${selectedSignatureMethod}`);
          }}
          onChange={handleSignatureSelection}
          value={selectedSignatureMethod ?? ''}
          label={selectionHintText}
          placeholder={selectionHintText}
          displayEmpty
          data-testkey={
            contract.type === ORDER ? `signature-select-field-order` : `signature-select-field-${contract.key}`
          }
          variant="standard"
          sx={{
            '&.MuiPopover-root': {
              top: '50px',
            },
            '&.MuiInputBase-root': {
              width: '100%',
              marginBottom: '15px',
              color: 'rgba(0, 0, 0, 0.6)',
              '&::after': {
                borderBottomColor: '#000000',
              },
              ':focus': {
                backgroundColor: '#ffffff',
              },
            },
            '& .MuiInputLabel-root': {
              fontFamily: 'Calibre, sans-serif',
              top: '5px',
            },
            '&.MuiSelect-root': {
              backgroundColor: '#ffffff',
              marginLeft: '5px',
            },
            '& .MuiMenuItem-root': {
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
              backgroundColor: '#000',
              '&.Mui-selected': {
                backgroundColor: '#ffffff',
              },
            },
            '& .MuiSelect-select': {
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
            },
            '& .MuiSelect-nativeInput': {
              fontFamily: 'Calibre, sans-serif',
              fontSize: '16px',
            },
            '& .MuiInput-input': {
              color: '#000',
              paddingTop: '15px',
              ':focus': {
                backgroundColor: '#ffffff',
              },
            },
          }}
        >
          {getSignatureMenuItems()}
        </Select>
      </FormControl>
    );
  };

  return (
    <div className={styles.signatureselection}>
      <div className={'accordion-title'}>
        <div className={styles.header}>
          {headerIcon}

          <span className={styles.label}>{getContractDisplayName()}</span>

          {getSignatureSelector()}
        </div>

        {renderSignatoryList()}
      </div>
    </div>
  );
};
