import TextField, { TextFieldProps } from '@mui/material/TextField';
import { styled } from '@mui/system';
import React from 'react';

export const textFieldStyles = {
  '& .MuiInput-input': {
    boxShadow: 'none',
    border: 'none',
    background: 'none',
  },
  '& .MuiInput-root': {
    fontFamily: 'Calibre, sans-serif;',
    '&.Mui-focused': {
      color: '#000000',
    },
  },
  '& .MuiInput-root:hover': {
    borderBottomColor: 'rgba(0, 0, 0, 0.6)',
    '&::before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.6)',
      borderWidth: '1px',
    },
  },
  '& .Mui-error': {
    borderBottomColor: '#d32f2f',
    borderWidth: '2px',
    '&::before': {
      borderWidth: '2px',
    },
  },
  '& .MuiInputBase-input': {
    border: 'none',
    boxShadow: 'none',
    width: '100%',
  },
  '& label': {
    fontFamily: 'Calibre, sans-serif;',
    fontSize: 17,
  },
  '& > p': {
    '&.MuiFormHelperText-root': {
      color: 'rgb(244, 67, 54)',
      fontSize: '12px',
      fontFamily: 'Calibre, sans-serif;',
      lineHeight: '12px',
    },
  },
};

export const TextFieldDefault = (props: TextFieldProps) => (
  <TextField {...props} color="primary" sx={{ ...textFieldStyles, ...props.sx }} />
);

const StyledTextField = styled(TextField)({
  '& .MuiInput-input': {
    boxShadow: 'none',
    border: 'none',
    background: 'none',
  },
  '& .MuiInput-root:hover': {
    borderBottomColor: 'rgba(0, 0, 0, 0.6)',
    '&::before': {
      borderBottomColor: 'rgba(0, 0, 0, 0.6)',
      borderWidth: '1px',
    },
  },
  '& .Mui-focused': {
    color: '#000000',
    '& > input': {
      border: 'none',
      boxShadow: 'none',
    },
    '&::after': {
      borderColor: '#000000',
      left: '3.0rem',
    },
  },
  '& .MuiInputBase-root': {
    fontSize: '16px',
    paddingLeft: '3.5rem',
    '&::after': {
      borderBottomColor: '#000000',
    },
    '&::before': {
      left: '3.0rem',
    },
  },
  '& .Mui-error': {
    borderBottomColor: '#d32f2f',
    borderWidth: '2px',
    '&::before': {
      borderWidth: '2px',
    },
  },
  '& .MuiInputBase-input': {
    marginTop: 14,
    border: 'none',
    boxShadow: 'none',
    width: '100%',
  },
  '& label': {
    color: '#000000',
    margin: 0,
    padding: 0,
    fontSize: '21.6px',
    fontWeight: '700',
    fontFamily: 'Calibre, sans-serif;',
    '&.Mui-focused': {
      fontSize: '24px',
      color: '#000000',
      margin: 0,
      padding: 0,
    },
    '&.Mui-error': {
      color: '#000000',
    },
  },
  '& > p': {
    '&.MuiFormHelperText-root': {
      color: 'rgb(244, 67, 54)',
      fontSize: '12px',
      marginBottom: '24px',
      paddingTop: '15px',
      marginTop: '0px',
      paddingLeft: '3.0rem',
      fontFamily: 'Calibre, sans-serif;',
      lineHeight: '12px',
    },
  },
}) as typeof TextField;

export default StyledTextField;
