import { Config } from 'types/types';

const config: Config = {
  appEnv: 'test', // don't remove the appEnv property here
  baseUrl: 'http://example.com',
  basePath: '/',
  oidc: {
    client_id: '',
    redirect_uri: '',
    response_type: '',
    scope: '',
    authority: '',
    silent_redirect_url: '',
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    acr_values: '',
    triggerAuthFlow: true,
    monitorSession: false,
  },
  portfolioManager: false,
  planSummaryView: true,
  customerListBankers: false,
  enableErrorLogging: false,
  enableReduxTrace: false,
  useBackendConstraints: true,
  existingCustomerTryRisk: true,
  editInstruments: true,
  allowedUserRole: '', // Hard-coded value to configuration, allowed PT User role
  mytySuitabilityAssessmentUrl: '',
};

export default config;
