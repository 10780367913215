import React from 'react';
import { InjectedRouter, withRouter } from 'react-router';
import translate from 'counterpart';
import styles from './Nav.scss';
import { NavBar } from 'features/common/NavBar';
import SaveButton from 'features/common/SaveButton';

interface Props {
  router: InjectedRouter;
  params: { id: string };
  customerName: string;
  save: () => void;
  finish: () => void;
  disabled: boolean;
}

const Nav = ({ customerName, params, router, save, finish, disabled }: Props) => {
  const leftContent = (
    <h1 className={styles.leftLabel}>
      {customerName} {params.id} - {translate('meetings.customerMeeting')}
    </h1>
  );

  const rightContent = (
    <div className={styles.right}>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error*/}
      <button onClick={router.goBack} className={styles.close} tabIndex="-1">
        <span>
          {translate('common.close')} <i className="icon icon-close" style={{ fontSize: '12px' }} />
        </span>
      </button>
      <SaveButton
        label={
          <span>
            TALLENNA
            <br />
            CRM
          </span>
        }
        onClick={save}
        style={{ margin: 0, borderLeft: '1px solid #999999', width: '12rem' }}
        disabled={disabled}
        tabIndex="1"
      />
      <SaveButton
        label={
          <span>
            MERKITSE
            <br />
            VALMIIKSI CRM
          </span>
        }
        onClick={finish}
        style={{ margin: 0, borderLeft: '1px solid #999999', width: '12rem' }}
        disabled={disabled}
        tabIndex="2"
      />
    </div>
  );

  return <NavBar leftContent={leftContent} rightContent={rightContent} />;
};

export default withRouter(Nav);
