import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import translate from 'counterpart';
import { RootState } from 'types/rootState';
import {
  HelpText,
  HelpTextSmall,
  customerHeaderHeight,
  FlexAlignCenter,
  styles,
} from 'features/common/StyledComponents';
import { Button } from 'features/allocator/common/Button';
import { Spinner } from 'features/common/Spinner';
import { TradePortfolios } from './components/TradePortfolios';
import {
  isSameInstrument,
  getInstrumentDetails,
  getInstrumentSuitabilityErrors,
  addSuitabilityErrors,
  convertInstrumentsToOrderLines,
  weightIsOverTradeLimit,
  valueIsOverTradeLimit,
  quantityIsOverTradeLimit,
  hasPortfolioErrors,
  updateTradeOrderLineAmounts,
  getCurrentInstrument,
} from './tradeUtils';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  customerHasValidPlan,
  selectHasError,
  positionsAreEqual,
  isLoadingInitialItems,
  selectHasPositions,
  isOptimizingPlan,
  isOptimizingCurrent,
  selectPlanState,
  isLoadingPlan,
  selectCurrentWeights,
  selectPlanPortfolios,
  hasOptimizeFailedError,
  hasRiskConflict,
  selectOptimizedWeightsForRiskLevel,
  selectAum,
} from 'features/allocator/allocatorSelectors';
import { colors } from 'styles/colors';
import { setTradeOrderLines } from 'features/portfolioManager/valueData/valueDataActions';
import { optimizeForTrade, cancelOptimization } from 'features/weights/weightsThunks';
import { isOptimizedPortfolio } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { emptyOptimizedWeights, MONEY } from 'constants/allocator';
import { PortfolioInstrument } from 'types/instrumentsState';
import { OptimizedWeights } from 'types/weightsState';
import { selectCustomer } from 'features/profile/profileSelectors';
import { getWithdrawalBalance } from 'features/portfolio/portfolioUtils';
import { BOND } from 'constants/instrumentForms';
import { OrderInstrumentDetails, OrderLineType } from 'types/ordersState';
import { isOutsideFundsPortfolio } from 'core/portfolios';
import { CashInput } from 'features/portfolioManager/trade/components/CashInput';
import { LinkBack } from 'features/common/LinkBack';
import {
  customerHasOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';
import { updateOptimizedWeights } from 'features/weights/weightsSlice';
import { useAppDispatch } from 'core/hooks';

interface Props {
  params: { id: number };
}

export const TradeView = ({ params }: Props) => {
  const customer = useSelector(selectCustomer);
  const loadingProfile = useSelector((state: RootState) => state.profile.isBusy);
  const loadingPlan = useSelector(isLoadingPlan);
  const planPortfolios = useSelector(selectPlanPortfolios);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const hasPlan = useSelector(customerHasValidPlan);
  const planState = useSelector(selectPlanState);
  const loadingPortfolios = useSelector((state: RootState) => state.portfolio.loadingPortfolios);
  const positions = useSelector((state: RootState) => state.portfolio.positions);
  const portfolioDetailsById = useSelector((state: RootState) => state.portfolio.portfolioDetailsById);
  const riskConflict = useSelector(hasRiskConflict);

  const AUM = useSelector(selectAum);
  const cashTradeAmount = useSelector((state: RootState) => state.portfolioManager.valueData.cashTradeAmount);

  const withdrawalBalance = useSelector(getWithdrawalBalance);
  const tradeOrderLines = useSelector((state: RootState) => state.portfolioManager.valueData.tradeOrderLines);
  const suitabilityErrors = useSelector((state: RootState) => state.portfolioManager.valueData.suitabilityErrors);

  const optimizingPlan = useSelector(isOptimizingPlan);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const loadingInitialItems = useSelector(isLoadingInitialItems);

  const currentWeights = useSelector(selectCurrentWeights);
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const hasOptimizedWeights = useSelector(customerHasOptimizedWeights('withoutIlliquids'));
  const hasPositions = useSelector(selectHasPositions);

  const optimizeCurrentFailed = useSelector(selectHasError(['optimizeCurrent']));
  const optimizeFailed = useSelector(hasOptimizeFailedError);
  const getPricesFailed = useSelector(selectHasError(['getInstrumentPrices']));

  const positionsNotEqual = !useSelector(positionsAreEqual);

  const [loadingInstrumentDetails, setLoadingInstrumentDetails] = useState<boolean>(false);
  const [cashMismatch, setCashMismatch] = useState<boolean>(false);
  const [selectedAndOptimizedAreEqual, setSelectedAndOptimizedAreEqual] = useState<boolean>(true);
  const [selectedInstruments, setSelectedInstruments] = useState<PortfolioInstrument[]>([]);

  const tradePortfolios = (!hasPositions ? planPortfolios : optimizedPortfolios).filter((p) => isOptimizedPortfolio(p));

  const initialOptimizedWeights = useRef<OptimizedWeights>(emptyOptimizedWeights);
  const exitAction = useRef<'save' | 'cancel'>('cancel');

  const dispatch = useAppDispatch();

  const loadingAnything =
    loadingInstrumentDetails || loadingInitialItems || (hasPositions && (optimizingPlan || optimizingCurrent));

  const totalTradeMoney = !hasPositions ? cashTradeAmount : AUM;

  useEffect(() => {
    return () => {
      dispatch(cancelOptimization('reoptimize'));
      if (exitAction.current === 'cancel') {
        dispatch(updateOptimizedWeights(initialOptimizedWeights.current));
        dispatch(setTradeOrderLines([]));
      }
    };
  }, []);

  useEffect(() => {
    // runs after optimization
    if (tradePortfolios.length && positions.length && hasPositions) {
      const liquidInstruments = selectLiquidInstruments();
      dispatch(addSuitabilityErrors(liquidInstruments, positions));
    }
  }, [planPortfolios, positions]);

  useEffect(() => {
    if (initialOptimizedWeights.current === emptyOptimizedWeights) {
      initialOptimizedWeights.current = optimizedWeights;
    }
  }, [optimizedWeights]);

  const hasProperData = (hasOptimizedWeights || !hasPositions) && tradePortfolios.length > 0;

  useEffect(() => {
    // Runs once when everything ready
    const getData = async () => {
      if (!loadingAnything && hasProperData && tradeOrderLines.length === 0 && !getPricesFailed) {
        await getInstrumentDetailsForTrade();
      }
    };
    void getData();
  }, [loadingAnything, hasProperData, tradeOrderLines]);

  const getInstrumentDetailsForTrade = async () => {
    const liquidInstruments = selectLiquidInstruments();

    // New customer = no positions -> load instrument details and convert instruments to orderlines
    if (!hasPositions || positionsNotEqual) {
      setLoadingInstrumentDetails(true);
      const orderInstruments = (await dispatch(getInstrumentDetails(liquidInstruments))) as OrderInstrumentDetails[];
      setLoadingInstrumentDetails(false);
      dispatch(convertInstrumentsToOrderLines(liquidInstruments, orderInstruments));
      dispatch(addSuitabilityErrors(liquidInstruments, orderInstruments));
    }
    // Customer has positions -> convert positions to orderlines
    else {
      dispatch(convertInstrumentsToOrderLines(liquidInstruments, positions));
    }
  };

  useEffect(() => {
    const defaultInstruments = selectDefaultInstruments();
    if (tradeOrderLines.length > 0) {
      setSelectedInstruments(defaultInstruments);
    }
  }, [tradeOrderLines]);

  useEffect(() => {
    const optimizedIds = tradePortfolios.map((p) => p.portfolioId);
    const withdrawalSum = optimizedIds.reduce((acc, id) => acc + portfolioDetailsById[id]?.withdrawalBalance, 0);
    const cashPositionSum = positions
      .filter((p) => optimizedIds.includes(p.portfolio.portfolioId))
      .filter((p) => p.financialInstrument.financialInstrumentId === MONEY)
      .reduce((acc, pos) => acc + pos.marketValueNet.securityCurrency.value, 0);
    if (withdrawalSum !== cashPositionSum) {
      setCashMismatch(true);
    }
  }, [tradePortfolios, positions, portfolioDetailsById]);

  const getInstrumentOrderSideErrors = (instrument: PortfolioInstrument) => {
    const orderLine = tradeOrderLines?.find((o) => o.financialInstrumentId === instrument.security);
    const side = orderLine?.side;
    const instrumentSuitabilityErrors = getInstrumentSuitabilityErrors(suitabilityErrors, instrument, side);

    return instrumentSuitabilityErrors;
  };

  // Select instruments

  const selectTradeInstruments = () =>
    tradePortfolios.flatMap((p) => p.allocatedPortfolioRows).flatMap((p) => p.withoutIlliquids);

  const selectLiquidInstruments = () =>
    selectTradeInstruments()
      .filter((instrument) => instrument.liquidity === 1)
      .filter((i) => i.security !== MONEY);

  const selectDefaultInstruments = () =>
    selectLiquidInstruments().filter((i) => {
      const suitabilityErrors = getInstrumentOrderSideErrors(i);
      const portfolioDetails = portfolioDetailsById[i.portfolioId];
      const orderLine = tradeOrderLines?.find((o) => o.financialInstrumentId === i.security);
      const currentInstrument = getCurrentInstrument(i.security, i.portfolioId, currentWeights);
      const weightDifference = (i?.weight || 0) - (currentInstrument?.weight || 0);

      return (
        weightIsOverTradeLimit(weightDifference) &&
        valueIsOverTradeLimit(orderLine) &&
        quantityIsOverTradeLimit(orderLine) &&
        suitabilityErrors?.length === 0 &&
        !hasPortfolioErrors(customer, portfolioDetails, tradeOrderLines)
      );
    });

  const liquidInstrumentsWithWeightHaveErrors =
    selectLiquidInstruments()
      .filter((i) => !hasPortfolioErrors(customer, portfolioDetailsById[i.portfolioId], tradeOrderLines))
      .map((i) => getInstrumentOrderSideErrors(i))
      .filter(Boolean)
      .flat().length > 0;

  useEffect(() => {
    // runs after changing money input and after optimization returns
    if (!loadingAnything && tradeOrderLines.length > 0) {
      setSelectedInstruments(selectDefaultInstruments());
      const tradeInstruments = selectTradeInstruments();
      dispatch(updateTradeOrderLineAmounts(tradeOrderLines, tradeInstruments));
    }
  }, [totalTradeMoney, loadingAnything]);

  // Handle selected instruments

  const onToggleInstrument = (instrument: PortfolioInstrument): void => {
    const instrumentIsSelected = !!selectedInstruments.find((i) => isSameInstrument(i, instrument));
    const newInstruments = instrumentIsSelected
      ? selectedInstruments.filter((i) => !isSameInstrument(i, instrument))
      : selectedInstruments.concat(instrument);
    setSelectedInstruments(newInstruments);
  };

  useEffect(() => {
    // check optimized & selected instrument equality
    const tradeSecurities = selectDefaultInstruments().map((i) => i.security);
    const selectedSecurities = selectedInstruments.map((i) => i.security);
    const securitiesAreEqual =
      tradeSecurities.length === selectedSecurities.length &&
      tradeSecurities.sort().every((security, index) => selectedSecurities.sort()[index] === security);
    setSelectedAndOptimizedAreEqual(securitiesAreEqual);
  }, [selectedInstruments, tradePortfolios]);

  // Actions

  const reoptimize = async () => {
    const isNotSelected = (instrument: PortfolioInstrument) =>
      !selectedInstruments.find((i) => isSameInstrument(i, instrument));

    const hasSuitabilityErrors = (instrument: PortfolioInstrument) => {
      const orderLine = tradeOrderLines?.find((o) => o.financialInstrumentId === instrument.security);
      const side = orderLine?.side;
      return getInstrumentSuitabilityErrors(suitabilityErrors, instrument, side).length > 0;
    };
    const isInOutsidePortfolio = (instrument: PortfolioInstrument) => {
      const portfolioDetails = portfolioDetailsById[instrument.portfolioId];
      return isOutsideFundsPortfolio(portfolioDetails);
    };

    const staticInstruments = selectLiquidInstruments()
      .filter(
        (instrument) =>
          (isNotSelected(instrument) && !isInOutsidePortfolio(instrument)) || hasSuitabilityErrors(instrument)
      )
      .map((instrument) => ({
        symbol: instrument.security,
        portfolioId: instrument.portfolioId,
      }));
    await dispatch(
      optimizeForTrade(
        staticInstruments,
        selectedInstruments.map((i) => i.security)
      )
    );
  };

  const confirmTrades = () => {
    exitAction.current = 'save';
    dispatch(push(`customer/${params.id}/portfolio?f=all&importOrderLines=true`));

    const isSelected = (orderLine: OrderLineType) =>
      selectedInstruments.flatMap((i) => i.security).includes(orderLine.financialInstrumentId);

    /**
     * PT requires that orderlines only have "amount" OR "quantity".
     * BOND always only has "amount"
     * Other forms only have "quantity" of tradeAll
     */

    const setAmountsForPT = (orderLine: OrderLineType): OrderLineType => {
      return orderLine.financialInstrumentForm === BOND
        ? {
            ...orderLine,
            amount: orderLine.quantity,
            quantity: 0,
          }
        : {
            ...orderLine,
            amount: isSelected(orderLine) && !orderLine.tradeAll ? orderLine.amount : 0,
            quantity: isSelected(orderLine) && orderLine.tradeAll ? orderLine.quantity : 0,
          };
    };

    const selectedTradeOrderLines = tradeOrderLines.map(setAmountsForPT);
    dispatch(setTradeOrderLines(selectedTradeOrderLines));
  };

  const getErrorText = () => {
    const errors = [];
    if (!hasPositions && withdrawalBalance === 0) {
      return null;
    }
    if (optimizeCurrentFailed) {
      errors.push(translate('portfolioManager.trade.optimizeCurrentFailed'));
    }
    if (!loadingAnything) {
      if (riskConflict) {
        errors.push(translate('portfolioManager.trade.riskConflict'));
      }
      if (hasPositions && optimizeFailed) {
        errors.push(translate('portfolioManager.trade.optimizeFailed'));
      }
      if (liquidInstrumentsWithWeightHaveErrors) {
        errors.push(translate('portfolioManager.trade.instrumentsHaveErrors'));
      }
      if (selectedInstruments.length === 0) {
        errors.push(translate('portfolioManager.trade.noInstrumentsSelected'));
      }
      if (!selectedAndOptimizedAreEqual) {
        errors.push(translate('portfolioManager.trade.instrumentSelectionHasChanged'));
      }
    }
    return errors;
  };

  const returnUrl = hasPositions ? `customer/${params.id}/portfoliomanager` : `customer/${params.id}`;
  const returnLabel = hasPositions
    ? translate('portfolioManager.trade.backToManager')
    : translate('portfolioManager.trade.backToPT');

  return (
    <Container>
      <LinkBack to={returnUrl} label={returnLabel} data-testkey="button-trade-back" />
      <h1>{translate('portfolioManager.trade.title')}</h1>

      {hasPositions && <StyledHelpText>{translate('portfolioManager.trade.pageDescription')}</StyledHelpText>}

      {cashMismatch && (
        <CashMismatch data-testkey="cash-mismatch">{translate('portfolioManager.trade.cashMismatch')}</CashMismatch>
      )}

      {!loadingAnything && !hasPositions && withdrawalBalance === 0 && (
        <Warning>{translate('portfolioManager.trade.noCash')}</Warning>
      )}

      {loadingAnything && hasPositions && (
        <HelpTextSmall>
          {translate('portfolioManager.trade.cashAmount')}: {formatToDecimals(withdrawalBalance, 2, '€')}
        </HelpTextSmall>
      )}

      {planState !== 'notLoaded' && !loadingAnything && !hasPositions && <CashInput />}

      {hasPlan && (
        <Buttons>
          {hasPositions && (
            <OptimizeButton
              label={translate('portfolioManager.trade.buttonRebalance')}
              onClick={reoptimize}
              disabled={
                selectedInstruments.length === 0 ||
                loadingAnything ||
                (!liquidInstrumentsWithWeightHaveErrors && selectedAndOptimizedAreEqual)
              }
              data-testkey="button-re-optimize"
            />
          )}

          <Button
            onClick={confirmTrades}
            label={translate('portfolioManager.trade.acceptTrade')}
            data-testkey="button-accept-trades"
            disabled={
              loadingAnything ||
              !selectedAndOptimizedAreEqual ||
              selectedInstruments.length === 0 ||
              (hasPositions && !hasOptimizedWeights) ||
              (!hasPositions && withdrawalBalance === 0) ||
              liquidInstrumentsWithWeightHaveErrors ||
              riskConflict
            }
          />

          <Spinners>
            {/* eslint-disable no-nested-ternary */}
            {loadingProfile ? (
              <Spinner horizontal message={translate('portfolioManager.trade.loadingProfile')} />
            ) : loadingPlan ? (
              <Spinner horizontal message={translate('portfolioManager.trade.loadingPortfolios')} />
            ) : optimizingCurrent || loadingPortfolios ? (
              <Spinner horizontal message={translate('portfolioManager.trade.calculatingWeights')} />
            ) : hasPositions && optimizingPlan ? (
              <Spinner horizontal message={translate('portfolioManager.trade.optimizing')} />
            ) : (
              loadingInstrumentDetails && (
                <Spinner horizontal message={translate('portfolioManager.trade.loadingPrices')} />
              )
            )}
            {/* eslint-enable no-nested-ternary */}
          </Spinners>
          {!loadingAnything && (
            <Warning data-testkey="trade-view-error">
              {getErrorText()?.map((e) => (
                <div key={e}>{e}</div>
              ))}
            </Warning>
          )}
        </Buttons>
      )}

      {planState === 'invalidPlan' && (
        <Warning>{translate('portfolioManager.overview.planInfo.label_invalidPlan')}</Warning>
      )}

      <TradePortfolios
        portfolios={tradePortfolios}
        selectedRows={selectedInstruments}
        onToggleInstrument={onToggleInstrument}
        loadingInstrumentDetails={loadingInstrumentDetails}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 2rem;
`;

const Warning = styled.div`
  font-weight: 700;
  color: ${colors.red};
`;

const CashMismatch = styled.div`
  ${styles.criticalMessage}
  ${styles.cardPadding}
  margin: 0 0 3rem;
`;

const StyledHelpText = styled(HelpText)`
  margin-bottom: 2rem;
`;

const Buttons = styled(FlexAlignCenter)`
  position: sticky;
  top: ${customerHeaderHeight};
  background: ${colors.gray_light};
  padding: 0.25rem 0;
  z-index: 1;
  margin-top: 1rem;
`;

const OptimizeButton = styled(Button)`
  margin-right: 1rem;
`;

const Spinners = styled.div`
  padding-left: 2rem;
`;
