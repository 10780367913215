import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import accounting from 'accounting';
import styles from './PositionList.scss';
import { PositionRow } from './PositionRow';
import { getListTotals } from 'core/portfolios';
import { tableFooterStyles, tableHeaderStyles } from 'styles/material-ui/material-ui';
import { PortfolioDetailsById, MarketInfo } from 'types/portfolioState';
import { Position } from 'types/ordersState';
import { EditorWithSuitabilityErrors } from 'types/orderDialogState';

interface Props {
  headerText: string;
  positions: Position[];
  onPositionClick: (assignment: EditorWithSuitabilityErrors) => void;
  marketInfo: MarketInfo;
  portfolioDetailsById: PortfolioDetailsById;
}

export const PositionList = ({ headerText, positions, marketInfo, onPositionClick, portfolioDetailsById }: Props) => {
  const totals = getListTotals(positions);
  const rows = positions.map((position, index) => (
    <PositionRow
      position={position}
      marketInfo={marketInfo}
      onPositionClick={onPositionClick}
      portfolioDetailsById={portfolioDetailsById}
      key={index}
    />
  ));

  const headerStyles = {
    ...tableHeaderStyles,
    fontFamily: 'Calibre',
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'none',
  } as React.CSSProperties;
  const footerStyles = {
    ...tableFooterStyles,
    fontFamily: 'Calibre',
    fontSize: 14,
    fontWeight: 500,
    textTransform: 'none',
  } as React.CSSProperties;

  return (
    <section className={styles.list}>
      <div className={styles.subHeader}>{headerText}</div>
      <Table style={{ paddingLeft: 0 }}>
        <TableHead style={{ paddingLeft: 0 }}>
          <TableRow style={{ fontSize: 11, height: 35 }}>
            <TableCell style={{ ...headerStyles, textAlign: 'left', width: 250, paddingLeft: 5 }}>Arvopaperi</TableCell>
            <TableCell style={{ ...headerStyles, width: 100 }}>Kpl (Avoimet)</TableCell>
            <TableCell style={{ ...headerStyles }}>Hankintahinta €</TableCell>
            <TableCell style={{ ...headerStyles }}>Hankinta-arvo €</TableCell>
            <TableCell style={{ ...headerStyles }}>Markkina-arvo €</TableCell>
            <TableCell style={{ ...headerStyles, width: 80 }}>Osuus %</TableCell>
            <TableCell style={{ ...headerStyles, width: 100 }}>Muutos €</TableCell>
            <TableCell style={{ ...headerStyles, width: 80, paddingRight: 5 }}>Muutos %</TableCell>
            <TableCell style={{ ...headerStyles, width: 60, paddingRight: 5 }} />
            <TableCell style={{ ...headerStyles, width: 60, paddingRight: 5 }} />
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
        <TableFooter>
          <TableRow style={{ fontSize: 12, height: 35 }}>
            <TableCell style={{ ...footerStyles, width: 250, textAlign: 'left', paddingLeft: 5 }}>YHTEENSÄ</TableCell>
            <TableCell style={{ ...footerStyles, width: 100 }} />
            <TableCell style={{ ...footerStyles }} />
            <TableCell style={{ ...footerStyles }}>{accounting.formatMoney(totals.totalPurchaseValue)}</TableCell>
            <TableCell style={{ ...footerStyles }}>{accounting.formatMoney(totals.totalMarketValue)}</TableCell>
            <TableCell style={{ ...footerStyles, width: 80 }}>
              {accounting.formatNumber((totals.totalMarketValue / marketInfo.marketValue) * 100, 2)}
            </TableCell>
            <TableCell style={{ ...footerStyles, width: 100 }}>{accounting.formatMoney(totals.totalChange)}</TableCell>
            <TableCell style={{ ...footerStyles, width: 80, paddingRight: 5 }}>
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error */}
              {accounting.formatNumber(totals.totalChangePercent, 2)}
            </TableCell>
            <TableCell style={{ ...footerStyles, width: 60 }} />
            <TableCell style={{ ...footerStyles, width: 60, paddingRight: 5 }} />
          </TableRow>
        </TableFooter>
      </Table>
    </section>
  );
};

PositionList.defaultProps = {
  portfolioDetailsById: {},
};
