import React from 'react';
import translate from 'counterpart';
import styles from './ContractGroup.scss';
import { ActionMenu } from './ActionMenu';

interface Props {
  copyContract: (contractId: string) => void;
  headerIcon: object;
  headerText: string;
  contractState: string;
  contractId: string;
  allowCopy: boolean;
  contractType: string;
}

export default class ContractListItem extends React.Component<Props> {
  onCopyClick = () => {
    this.props.copyContract(this.props.contractId);
  };

  render() {
    const { headerIcon, headerText, contractState, allowCopy, contractType } = this.props;
    let translateDivStyle = { display: 'flex', alignItems: 'center' };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    translateDivStyle = allowCopy ? translateDivStyle : { ...translateDivStyle, paddingRight: '10px' };

    return (
      <li className={styles.contract}>
        <div className={styles.header}>
          {headerIcon}
          <header>
            <span className={styles.label}>{headerText}</span>
            <div style={translateDivStyle}>
              <span className={styles.contractState}>{translate(`contractStates.${contractState}`)}</span>
              {allowCopy ? <ActionMenu copyContract={this.onCopyClick} contractType={contractType} /> : undefined}
            </div>
          </header>
        </div>
      </li>
    );
  }
}
