import React, { Component } from 'react';
import moment from 'moment';
import { default as getValueFromObject } from 'lodash/get';
import classnames from 'classnames';
import { default as lodashFind } from 'lodash/find';
import _ from 'lodash';
import translate from 'counterpart';
import { getFieldType, doesQuestionPassConditionTestToRender } from 'core/functions';
import { SELECT, BOOLEAN, COUNTRY, NUMBER, SELECT_MANY, DATE_TIME } from 'constants/fieldTypes';
import styles from './SubQuestion.scss';
import { Contract, Question } from 'types/ordersState';
import { Countries, FormError, Locale, Schema } from 'types/contractsState';

export interface SubQuestionProps {
  answer: Contract;
  question: Question;
  locale: Locale;
  field: {
    key: string;
  };
  schema: Schema;
  questionSetIndex: number;
  errors: FormError[];
  countries: Countries;
  contractLanguage: Locale;
}

export default class SubQuestion extends Component<SubQuestionProps> {
  getError() {
    const { question, errors, field, questionSetIndex } = this.props;
    const errorKey = question.key.replace(field.key, `${field.key}.${questionSetIndex}`);
    return lodashFind(errors, (e) => e.key === errorKey);
  }

  getAnswer() {
    const { question, answer, field, schema, countries, contractLanguage } = this.props;
    let answerValue = getValueFromObject(answer, question.key.substring(field.key.length + 1));
    const questionTypes = getFieldType(question, schema);

    if (questionTypes.formType === SELECT && answerValue) {
      answerValue = question.titleMap?.[contractLanguage][answerValue]
        ? question.titleMap[contractLanguage][answerValue]
        : answerValue;
    } else if (questionTypes.formType === SELECT_MANY && answerValue) {
      // @ts-expect-error: answerValue is an array here
      answerValue = answerValue.map((val) => {
        let v;
        if (question.titleMap?.[contractLanguage][val]) {
          v = question.titleMap[contractLanguage][val];
        } else {
          v = val;
        }
        return v;
      });
      answerValue = answerValue.join(', ');
    } else if (questionTypes.formType === COUNTRY) {
      if (typeof answerValue === 'string') {
        answerValue = countries[answerValue];
      } else if (typeof answerValue === 'object') {
        answerValue = _.map(answerValue, (country) => countries[country]);
        answerValue = answerValue.join(', ');
      }
    } else if (questionTypes.dataType === DATE_TIME && answerValue && answerValue !== '') {
      answerValue = moment(answerValue).format('DD.MM.YYYY');
    } else if (questionTypes.type === BOOLEAN) {
      answerValue = answerValue
        ? translate('common.yes', { locale: contractLanguage })
        : translate('common.no', { locale: contractLanguage });
    } else if (questionTypes.type === NUMBER && answerValue) {
      answerValue = answerValue.toString().replace('.', ',');
    }

    return answerValue;
  }

  render() {
    const { question, answer, contractLanguage } = this.props;
    const questionLabel = question.isRequired
      ? `${question.title[contractLanguage]}*`
      : question.title[contractLanguage];
    const answerValue = this.getAnswer();
    const error = this.getError();

    if (question.condition && !doesQuestionPassConditionTestToRender(answer, question.condition, [])) {
      return null;
    }

    return (
      <div className="row">
        <div className="column small-12 large-6">
          <strong className={classnames(error ? styles.hasError : null)}>{questionLabel}</strong>
        </div>
        <div className="column small-12 large-6">
          {answerValue}
          <span className={classnames(error ? styles.hasError : null)}>
            {error ? (answerValue ? ' | ' : '') + error.message : false}
          </span>
        </div>
      </div>
    );
  }
}
