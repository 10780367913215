import { Map, List } from 'immutable';
import { USER_FOUND } from 'redux-oidc';
import { OrderListAction } from 'features/orderList/orderListActions';
import { OrderListState } from 'types/orderListState';
import {
  ORDERS_REQUEST,
  ORDERS_SUCCESS,
  ORDERS_FAILURE,
  SET_ORDERS_SEARCH_FILTERS,
  MERGE_ORDERS_SEARCH_FILTERS,
  SAVED_ORDERS_FILTERS_SUCCESS,
  DELETE_SAVED_ORDERS_FILTER,
  ADD_SAVED_ORDERS_FILTER_SUCCESS,
  ORDERS_PDF_DOC_REQUEST,
  ORDERS_PDF_DOC_SUCCESS,
  ORDERS_PDF_DOC_FAILURE,
} from './orderListConstants';

const SEARCH_TAKE = 10;

const initialState: OrderListState = {
  items: List(),
  searchFilters: Map(),
  searchSkip: 0,
  searchTake: SEARCH_TAKE,
  csvLoading: false,
  savedFilters: List(),
  loadingOrders: false,
  totalResults: 0,
  contractPdfLoading: undefined,
  error: '',
};

export function orderListReducer(state = initialState, action: OrderListAction) {
  switch (action.type) {
    case USER_FOUND:
      return {
        ...state,
        // @ts-expect-error: untyped action from redux-oidc
        searchFilters: state.searchFilters.merge({ bankers: [action.payload.profile.sub] }),
      };
    case ORDERS_REQUEST:
      return {
        ...state,
        error: undefined,
        loadingOrders: true,
      };
    case ORDERS_SUCCESS:
      return {
        ...state,
        items: List(action.result.results),
        totalResults: action.result.totalResults,
        searchSkip: action.result.skip,
        searchTake: action.result.take,
        error: undefined,
        loadingOrders: false,
      };
    case ORDERS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingOrders: false,
        items: List(),
      };
    case SET_ORDERS_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: Map(action.searchFilters),
      };
    case MERGE_ORDERS_SEARCH_FILTERS:
      return {
        ...state,
        // @ts-expect-error: ?
        searchFilters: state.searchFilters.merge(action.searchFilters),
        searchSkip: 0,
      };
    case SAVED_ORDERS_FILTERS_SUCCESS:
      return {
        ...state,
        savedFilters: List(action.result),
      };
    case DELETE_SAVED_ORDERS_FILTER:
      return {
        ...state,
        // @ts-expect-error: ?
        savedFilters: state.savedFilters.delete(state.savedFilters?.findIndex((f) => f?.shortId === action.id)),
      };
    case ADD_SAVED_ORDERS_FILTER_SUCCESS:
      return {
        ...state,
        savedFilters: List(action.result),
      };
    case ORDERS_PDF_DOC_REQUEST:
      return {
        ...state,
        contractPdfLoading: action.id,
      };
    case ORDERS_PDF_DOC_SUCCESS:
      return {
        ...state,
        contractPdfLoading: undefined,
      };
    case ORDERS_PDF_DOC_FAILURE:
      return {
        ...state,
        contractPdfLoading: undefined,
      };
    default:
      return state;
  }
}
