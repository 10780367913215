import React, { ReactElement, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { push } from 'react-router-redux';
import { selectCustomer, selectCustomerId, selectIsDemoCustomer } from 'features/profile/profileSelectors';
import { getCustomer } from 'features/profile/profileActions';
import { isAllocator, resetCustomerState } from 'features/allocator/allocatorUtils';
import { RootState } from 'types/rootState';
import { unfinishedContractStates } from 'constants/contractStates';
import { RiskContractResponse } from 'features/contracts/contracts.types';
import { fetchContract, setSelectedRiskLevel } from 'features/contracts/contractsActions';
import { selectBasicInfoContract } from 'features/contracts/contractsSelectors';
import { hasCustomerChanged } from 'features/profile/profileUtils';
import { useAppDispatch } from 'core/hooks';
import { DEMO_CUSTOMER } from 'constants/common';

interface Props {
  params: { id: string };
  children: ReactElement;
}

export const ProfileRoot = ({ children, params }: Props) => {
  const dispatch = useAppDispatch();
  const customerIdFromUrl = params.id;

  if (customerIdFromUrl === DEMO_CUSTOMER && !isAllocator()) {
    dispatch(push(`/`));
  }

  const customer = useSelector(selectCustomer);
  const isBusy = useSelector((state: RootState) => state.profile.isBusy);
  const currentCustomerId = useSelector(selectCustomerId);
  const selectedRiskLevel = useSelector((state: RootState) => state.profile.customer.toJS()?.selectedRiskLevel);
  const contracts = useSelector((state: RootState) => state.profile.contracts.toJS());
  const basicInfoContractId = useSelector(selectBasicInfoContract);
  const isDemoCustomer = useSelector(selectIsDemoCustomer);

  const mountEffect = useCallback(() => {
    const customerHasChanged = hasCustomerChanged(customerIdFromUrl, customer);
    if (!isBusy && (customerHasChanged || isDemoCustomer)) {
      dispatch(resetCustomerState());
      dispatch(getCustomer(customerIdFromUrl));
    }
  }, [currentCustomerId, customerIdFromUrl]);

  useEffect(() => {
    // fetches risk level from contract so that we can show potential risk conflict
    const loadData = async (contractId: string) => {
      const response = (await dispatch(fetchContract(contractId))) as unknown as RiskContractResponse;
      const selectedRiskLevel = response?.sections?.riskConfirmation?.selectedRiskLevel;
      if (selectedRiskLevel) {
        dispatch(setSelectedRiskLevel(selectedRiskLevel));
      }
    };

    const contractKeys = Object.keys(contracts);
    if (!selectedRiskLevel && contractKeys.length) {
      const unfinishedBasicInfoContracts = Object.values(contracts)
        .filter((i) => i.contractType === basicInfoContractId)
        .filter((i) => unfinishedContractStates.includes(i.state));
      const sortedBasicInfoContracts = sortBy(unfinishedBasicInfoContracts, 'editDate').reverse();
      if (sortedBasicInfoContracts?.length) {
        const contractId = sortedBasicInfoContracts[0].shortId;
        void loadData(contractId);
      }
    }
  }, [JSON.stringify(contracts), selectedRiskLevel, JSON.stringify(customer?.toJS())]);

  // ResetEffect is the first child -> its useEffect() will be run first!
  return (
    <>
      <ResetEffect effect={mountEffect} />
      {children}
    </>
  );
};

interface EffectProps {
  effect: () => void;
}

const ResetEffect = ({ effect }: EffectProps) => {
  useEffect(() => {
    effect?.();
  }, [effect]);

  return null;
};
