import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { Card } from 'features/allocator/common/Card';
import { ReturnAndVolatility } from './components/ReturnAndVolatility';
import { AssetClassDistribution } from 'features/allocator/common/AssetClassDistribution';
import { PlanSwitch } from './components/PlanSwitch';
import { allocatorWidth, pageMargin } from 'features/common/StyledComponents';
import { ValueAtRisk } from './components/ValueAtRisk';
import { AssetClassDistributionBar } from '/features/allocator/common/AssetClassDistributionBar';
import { weightTypes } from 'constants/allocator';
import {
  selectHasPositions,
  isLoadingInitialItems,
  positionsAreEqual,
  hasNegativePositions,
  hasRiskConflict,
  isOptimizingCurrent,
  isOptimizingPlan,
  selectCurrentWeights,
  selectInstrumentsSelected,
  selectOptimizedWeightsForRiskLevel,
  selectPlanPortfolios,
  selectPositionsNotInPlan,
  selectPlanInstrumentsNotInPositions,
} from 'features/allocator/allocatorSelectors';
import { DistributionTable } from 'features/allocator/common/DistributionTable';
import { ExistingPortfolios } from './components/ExistingPortfolios';
import { InstrumentDistribution } from 'features/allocator/overview/components/InstrumentDistribution';
import { UnequalPositions } from 'features/portfolioManager/components/UnequalPositions';
import { RiskConflict } from 'features/allocator/overview/components/RiskConflict';
import { PlanLength } from 'types/types';
import { DifferenceExplanation } from 'features/allocator/common/DifferenceExplanation';
import {
  customerHasOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
  selectIsLoadingCurrentWeightsDistribution,
  selectIsLoadingOptimizedWeightsDistribution,
} from 'features/weights/weightsSelectors';
export const OverviewView = () => {
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const loadingInitialItems = useSelector(isLoadingInitialItems);

  const [planLength, setPlanLength] = useState<PlanLength>('withIlliquids');
  const togglePlan = () => setPlanLength(planLength === 'withIlliquids' ? 'withoutIlliquids' : 'withIlliquids');

  const planPortfolios = useSelector(selectPlanPortfolios);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());

  const planInstruments = planPortfolios?.flatMap((p) => p.allocatedPortfolioRows).flatMap((p) => p[planLength]);
  const optimizedPortfoliosInstruments = optimizedPortfolios
    ?.flatMap((p) => p.allocatedPortfolioRows)
    .flatMap((p) => p[planLength]);
  const instrumentsSelected = useSelector(selectInstrumentsSelected);

  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const optimizedInstrumentWeights = optimizedWeights[planLength]?.instrumentWeights;
  const currentWeights = useSelector(selectCurrentWeights);

  const hasOptimizedWeights = useSelector(customerHasOptimizedWeights(planLength));

  const loadingCurrentWeightsDistribution = useSelector(selectIsLoadingCurrentWeightsDistribution);
  const loadingOptimizedWeightsDistribution = useSelector(selectIsLoadingOptimizedWeightsDistribution(planLength));

  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);

  const planInstrumentsNotInPositions = useSelector(
    selectPlanInstrumentsNotInPositions({ removeZeroWeightPlanInstruments: true })
  );

  const hasPositions = useSelector(selectHasPositions);
  const riskConflict = useSelector(hasRiskConflict);
  const positionsEqual = useSelector(positionsAreEqual);
  const negativePositions = useSelector(hasNegativePositions);

  const compareTo = weightTypes.currentWeights;

  const getInstrumentDistributionInstruments = () => {
    if (planInstrumentsNotInPositions.length > 0) {
      return planInstruments;
    }
    if (hasOptimizedWeights && positionsEqual && optimizedInstrumentWeights.length > 0) {
      return optimizedInstrumentWeights;
    }
    if (optimizedPortfoliosInstruments.length > 0) {
      return optimizedPortfoliosInstruments;
    }
    return instrumentsSelected;
  };

  return (
    <Container>
      {!positionsEqual && !negativePositions && <UnequalPositions />}
      {riskConflict ? <RiskConflict /> : null}

      <PlanSwitch planLength={planLength} onClick={togglePlan} />

      <BarCard>
        <h2>{translate('allocator.overviewView.distribution')}</h2>
        {hasPositions && (
          <div>
            <label>{translate('allocator.overviewView.currentDistribution')}</label>
            <AssetClassDistributionBar weights={currentWeights} loading={loadingCurrentWeightsDistribution} />
            <label>{translate('allocator.overviewView.optimizedDistribution')}</label>
          </div>
        )}
        <AssetClassDistributionBar
          weights={optimizedWeights[planLength]}
          loading={loadingOptimizedWeightsDistribution}
        />
        <DistributionTable
          items={optimizedWeights[planLength].assetCategoryWeights}
          itemType="assetCategory"
          compareTo={compareTo}
        />
      </BarCard>

      <FiguresCard>
        <h2>{translate('allocator.overviewView.keyFigures')}</h2>
        <ReturnAndVolatility
          returnStatistics={optimizedWeights[planLength]?.returnStatistics}
          compareTo={compareTo}
          planLength={planLength}
          loading={optimizingPlan || optimizingCurrent || loadingInitialItems}
        />
        <ValueAtRisk
          planLength={planLength}
          compareTo={compareTo}
          loading={optimizingPlan || optimizingCurrent || loadingInitialItems}
        />
      </FiguresCard>

      <DifferenceExplanation />

      <Instruments>
        <h2>
          {hasPositions || positionsNotInPlan.length === 0
            ? translate('allocator.overviewView.portfolios')
            : translate('allocator.overviewView.instruments')}
        </h2>

        {hasPositions || positionsNotInPlan.length === 0 ? (
          <ExistingPortfolios portfolios={optimizedPortfolios} planLength={planLength} />
        ) : (
          <InstrumentDistribution
            instruments={getInstrumentDistributionInstruments()}
            itemType="instrument"
            compareTo="none"
            loading={loadingInitialItems}
          />
        )}
      </Instruments>

      <AssetDistributionContainer>
        <h2>{translate('allocator.overviewView.assetClasses')}</h2>
        <AssetClassDistribution
          assetCategoryWeights={optimizedWeights[planLength].assetCategoryWeights}
          compareTo={compareTo}
          loading={loadingInitialItems}
        />
      </AssetDistributionContainer>
    </Container>
  );
};

const Container = styled.div`
  width: ${allocatorWidth};
  padding: ${pageMargin};
  display: grid;
  grid-template-areas:
    'riskconflict riskconflict riskconflict'
    'unequalpositions unequalpositions unequalpositions'
    'plan plan plan'
    'distribution distribution figures'
    'difference_explanation difference_explanation difference_explanation'
    'instruments assetclasses assetclasses';
  grid-template-columns: auto 100px 400px;
  grid-gap: 1rem 2rem;
`;

const BarCard = styled(Card)`
  flex: 2;
  grid-area: distribution;

  table {
    width: auto;
    min-width: 275px;
  }
`;

const FiguresCard = styled(Card)`
  grid-area: figures;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(3, auto);
  align-content: flex-start;
`;

const Instruments = styled.div`
  grid-area: instruments;
`;

const AssetDistributionContainer = styled.div`
  grid-area: assetclasses;

  & > div:not(:last-child) {
    margin-bottom: 2rem;
  }
`;
