import React from 'react';
import SignatoryListItem from './SignatoryListItem';
import styles from './ElectronicSignatureConfirmItem.scss';
import { Contract, Signatory } from 'types/ordersState';
import { ATTACHMENT_TEMPLATE_ID } from 'features/contracts/contractsConstants';

interface Props {
  contract: Contract;
  signatories: Signatory[];
  headerIcon: JSX.Element;
}

export default class ElectronicSignatureConfirmItem extends React.Component<Props> {
  static defaultProps = {
    contract: {
      contractName: '',
    },
    signatories: [],
  };

  renderTitle = () => {
    const { headerIcon, contract } = this.props;
    return (
      <div className={styles.header}>
        {headerIcon}
        <span className={styles.label}>
          {contract.contractType === ATTACHMENT_TEMPLATE_ID
            ? `Liite: ${contract.descriptiveName}`
            : contract.contractName}
        </span>
      </div>
    );
  };

  renderSignatory = (item: Signatory) => `${item.firstName} ${item.lastName}, ${item.email}`;

  renderSignatories = () => {
    const signatories = this.props.signatories.map((item, index, items) => {
      const divider = index + 1 !== items.length ? <div className={styles.divider} /> : undefined;
      return (
        <div key={index}>
          <SignatoryListItem signatory={item} isSelectable={false} />
          {divider}
        </div>
      );
    });

    return <div className={styles.signatories}>{signatories}</div>;
  };

  render() {
    return (
      <div className={styles.electronicSignatureConfirm}>
        <div className={'accordion-title'}>
          {this.renderTitle()}
          {this.renderSignatories()}
        </div>
      </div>
    );
  }
}
