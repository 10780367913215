import { formatNumber, formatMoney } from 'accounting';
import React from 'react';
import translate from 'counterpart';
import moment from 'moment';
import _ from 'lodash';
import { ModalDialog } from 'features/common/ModalDialog';
import styles from './TableRow.scss';
import TavatOrderStatus from './TavatOrderStatus';
import { isListed } from 'core/portfolios';
import alertIcon from 'images/alert.svg';
import { Spinner } from 'features/common/Spinner';
import { colors } from 'styles/colors';
import { isStructuredSellOrder } from 'core/orders';
import { OrderListItem } from 'types/orderListState';
import { TavatResult } from 'features/orderList/orderList.types';
import StyledButton from 'features/common/styledComponents/StyledButton';

const getDate = (date: Date) => moment(date).format('DD.MM.YYYY HH:mm');

interface Props {
  order: OrderListItem;
  getContractPdf: (id: string) => void;
  contractPdfLoading: string;
  getTavatOrderDetails: (id: string) => Promise<TavatResult>;
}

interface State {
  loading: boolean;
  isTavatPopupOpen: boolean;
  tavantError: string | null;
  tavatResult: TavatResult | null;
}

class OrderListTableRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      isTavatPopupOpen: false,
      tavantError: null,
      tavatResult: null,
    };
  }

  openPopup = () => {
    const { getTavatOrderDetails } = this.props;
    this.setState({ loading: true, isTavatPopupOpen: true, tavantError: null });
    getTavatOrderDetails(this.props.order.clientId)
      .then((response) => {
        this.setState({ loading: false, tavatResult: response });
      })
      .catch((e) => {
        this.setState({ loading: false, tavantError: `Virhe ladattaessa toimeksiannon tietoja: ${e}` });
      });
  };

  closePopup = () => {
    this.setState({ isTavatPopupOpen: false });
  };

  getStatus = () => {
    const { externalSystemStatus, porStatus } = this.props.order;
    const porState = _.get(porStatus, ['statuses', '0', 'state']);
    const status = [];
    if (externalSystemStatus) {
      status.push(externalSystemStatus);
    }
    if (porState) {
      status.push(translate(`orderList.${porState}`));
    }
    return <span>{status.join(' / ')}</span>;
  };

  render = () => {
    const { order, contractPdfLoading, getContractPdf } = this.props;
    let nameContent;
    if (order.contractId === contractPdfLoading) {
      nameContent = <Spinner className={styles.spinner} color={colors.ptGreen} />;
    } else {
      nameContent = <a onClick={getContractPdf.bind(this, order.contractId)}>{order.financialInstrumentName}</a>;
    }

    let stateContent;
    const isin = this.state.tavatResult ? this.state.tavatResult.orderSecurity.isin : '';
    if (isListed(order.financialInstrumentForm, order.side)) {
      stateContent = (
        <a onClick={this.openPopup} data-testkey="tavat-status-link">
          {order.externalSystemStatus}
          <ModalDialog
            open={this.state.isTavatPopupOpen}
            title={`${order.financialInstrumentName} / ${isin}`}
            close={this.closePopup}
            actions={[
              <StyledButton variant="outlined" onClick={this.closePopup} key={'common.close'}>
                {translate('common.close')}
              </StyledButton>,
            ]}
            content={this.renderPopupContent()}
            modalDialogWidth="1280px"
          />
        </a>
      );
    } else {
      stateContent = this.getStatus();
    }

    return (
      <tr data-testkey={`order-row-${order.financialInstrumentId}`}>
        <td>{getDate(order.statuses[0].creationTime)}</td>
        <td>{translate(`orderList.${order.side}`)}</td>
        <td>{nameContent}</td>
        <td>{order.customerName}</td>
        <td className="text-right">{order.totalValue ? `${formatMoney(order.totalValue)} ${order.currency}` : ''}</td>
        <td className="text-right">{formatNumber(order.quantity)}</td>
        <td className="text-right">
          {order.subscriptionFeePct !== null && !isStructuredSellOrder(order.financialInstrumentForm, order.side)
            ? `${formatNumber(order.subscriptionFeePct, 2, '', ',')} %`
            : ''}
        </td>
        <td className="text-right">
          {order.subscriptionFee ? `${formatMoney(order.subscriptionFee)} ${order.currency}` : ''}
        </td>
        <td>{order.portfolioCode}</td>
        <td>{order.contractState ? translate(`contractStates.${order.contractState}`) : ''}</td>
        <td>{stateContent}</td>
        <td>{order.creatorFullName}</td>
        <td style={{ minWidth: '3rem' }}>
          {order.alertState ? <img src={alertIcon} data-tip={order.alertState} style={{ height: '1.25rem' }} /> : ''}
        </td>
      </tr>
    );
  };

  renderPopupContent = () => {
    if (!this.state.isTavatPopupOpen) {
      return;
    }
    if (this.state.loading) {
      return <Spinner className={styles.spinner} />;
    }
    if (this.state.tavantError) {
      return <span>{this.state.tavantError}</span>;
    }
    // @ts-expect-error: tavatResult may be null!
    return <TavatOrderStatus order={this.props.order} status={this.state.tavatResult} />;
  };
}

export default OrderListTableRow;
