import {
  FUND,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
} from './instrumentForms';

export const doNotAskMoneySourceWhenBuy = [
  FUND,
  PRIVATE_EQUITY,
  CO_INVESTMENTS,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
];
