import { RootState } from 'types/rootState';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';
import {
  getPortfolioSettings,
  isConsideredPortfolio,
  isIgnoredPortfolio,
  isOptimizedPortfolio,
} from 'features/allocator/planPortfolios/planPortfolioUtils';

export const selectOptimizedPortfolioIds = (state: RootState): string[] => {
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);
  return optimizedPortfolios?.filter((p) => isOptimizedPortfolio(p)).map((p) => p.portfolioId);
};

export const selectConsideredPortfolioIds = (state: RootState): string[] => {
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);
  return optimizedPortfolios?.filter((p) => isConsideredPortfolio(p)).map((p) => p.portfolioId);
};

export const selectIgnoredPortfolioIds = (state: RootState): string[] => {
  if (state.portfolioManager.investmentPlan.planState === 'invalidPlan') {
    return [];
  }
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);
  return optimizedPortfolios?.filter((p) => isIgnoredPortfolio(p)).map((p) => p.portfolioId);
};

export const hasOptimizedFullPowerOfAttorneyPortfolioInPlan = (state: RootState) => {
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues()(state);
  return optimizedPortfolios.some((p) => {
    const isOptimized = isOptimizedPortfolio(p);
    const settings = getPortfolioSettings(p);
    const isFullPowerOfAttorney = settings.contractType === 'TV';
    return isFullPowerOfAttorney && isOptimized;
  });
};
