import React from 'react';
import translate from 'counterpart';
import { DeleteButton } from 'features/common/DeleteButton';
import { ExpirationDate } from './ExpirationDate';
import { IdentityMethod } from 'types/types';
import { Image } from 'types/profileState';

interface Props {
  image: Image;
  identityMethods: IdentityMethod[];
  onClick: (image: Image) => void;
  deleteIdentityImage: (id: string) => void;
}

export const IdentityListItem = ({ image, onClick, identityMethods, deleteIdentityImage }: Props) => {
  const onClickImage = () => onClick(image);
  const deleteImage = () => deleteIdentityImage(image.id);
  const getIdentityMethod = () => identityMethods.find((method) => method.value === image.identityMethod);
  const getIdentityMethodText = (identityMethod: IdentityMethod) => translate(identityMethod.translateKey);

  const identityMethod = getIdentityMethod();
  const identityMethodText = identityMethod ? getIdentityMethodText(identityMethod) : '';

  return (
    <li>
      <div>
        <span onClick={onClickImage} style={{ cursor: 'pointer', marginRight: '1.2rem' }}>
          {image.description} - {identityMethodText}
          {<ExpirationDate expirationDate={image.identityMethodExpirationDate} />}
        </span>
        <DeleteButton
          confirmHeader={`${translate('customer.remove')} ${identityMethodText}`}
          confirmContent={`${translate('customer.removeId')}`}
          action={deleteImage}
        />
      </div>
    </li>
  );
};
