import React from 'react';
import { branch, renderNothing } from 'recompose';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';
import translate from 'counterpart';
import { isEmpty } from 'lodash';
import styles from './Table.scss';
import OrderListTableRow from './OrderListTableRow';
import { OrderListItem } from 'types/orderListState';
import { TavatResult } from 'features/orderList/orderList.types';

interface Props {
  items: OrderListItem[];
  total: number;
  contractPdfLoading: string;
  getContractPdf: (id: string) => void;
  getTavatOrderDetails: (id: string) => Promise<TavatResult>;
}

const OrderListTable = ({ items, total, getContractPdf, contractPdfLoading, getTavatOrderDetails }: Props) => (
  <table className={classnames(styles.table, 'scroll')}>
    <thead>
      <tr>
        <th>{translate('orderList.date')}</th>
        <th>{translate('orderList.side')}</th>
        <th>{`${translate('orderList.financialInstrumentName')} (${total} kpl)`}</th>
        <th>{translate('orderList.customer')}</th>
        <th>{translate('orderList.totalValue')}</th>
        <th>{translate('orderList.amount')}</th>
        <th>{`${translate('orderList.subscriptionFeePct')} %`}</th>
        <th>{translate('orderList.subscriptionFee')}</th>
        <th>{translate('orderList.portfolio')}</th>
        <th>{translate('orderList.orderStatus')}</th>
        <th>{translate('orderList.externalSystemStatus')}</th>
        <th>{translate('orderList.creatorFullName')}</th>
        <th>
          <ReactTooltip place="left" effect="solid" />
        </th>
      </tr>
    </thead>
    <tbody data-testkey="orders-table">
      {items.map((order, index) => (
        <OrderListTableRow
          order={order}
          key={index}
          getContractPdf={getContractPdf}
          contractPdfLoading={contractPdfLoading}
          getTavatOrderDetails={getTavatOrderDetails}
        />
      ))}
    </tbody>
  </table>
);

// @ts-expect-error: ???
export default branch(({ items }) => isEmpty(items), renderNothing)(OrderListTable);
