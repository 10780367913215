import React from 'react';
import translate from 'counterpart';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Immutable from 'immutable';
import ImageCapture from 'features/common/ImageCapture';
import {
  IMAGE_DATAURI,
  IDENTITY_METHOD_EXPIRATION_DATE,
  DESCRIPTION,
  IDENTITY_METHOD,
  IDENTITY_METHOD_ISSUER,
  IDENTITY_METHOD_ISSUER_COUNTRY,
  IDENTITY_METHOD_DATE_OF_ISSUE,
  IDENTITY_METHOD_NUMBER,
  IMAGE,
} from 'constants/newCustomer';
import { wizardFieldValidation } from 'features/prospect/prospectActions';
import FieldList from 'features/fieldList/FieldList';
import IdentityOptions from 'constants/identityOptions';
import styles from './CustomerIdentification.scss';
import { Countries, Locale } from 'types/contractsState';
import { IdentityMethod } from 'types/types';
import { RootState } from 'types/rootState';
import { Option } from 'types/profileState';
import { Template, Value } from 'types/ordersState';

interface Props {
  handler: (option: Option) => void;
  countries: Countries;
  locale: Locale;
  wizardFieldValidation: (key: string) => void;
  identityMethods: IdentityMethod[];
  formValues: Immutable.Map<string, object>;
  template: Template;
  formErrors: Immutable.List<string>;
  formTouched: Immutable.Set<string>;
}

class CustomerIdentificationView extends React.Component<Props> {
  handleChange = (key: string, value: Value) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.handler({ key, value });
  };

  handleImageDataChange = (option: Option) => {
    this.props.handler(option);

    if (option.key === IDENTITY_METHOD) {
      if (option.value !== 'FinnishDriverLicence') {
        this.props.handler({ key: IDENTITY_METHOD_DATE_OF_ISSUE, value: undefined });
      }

      if (!(option.value as string)?.startsWith('Foreign')) {
        this.props.handler({ key: IDENTITY_METHOD_ISSUER_COUNTRY, value: 'FI' });
      } else {
        this.props.handler({ key: IDENTITY_METHOD_ISSUER_COUNTRY, value: undefined });
      }
    }
  };

  handleClearImage = () => {
    const { handler } = this.props;
    handler({ key: IMAGE, value: undefined });
    handler({ key: IMAGE_DATAURI, value: undefined });
  };

  validate = (field: { key: string }) => {
    this.props.wizardFieldValidation(field.key);
  };

  render() {
    const { formValues, formErrors, formTouched, countries, locale, template } = this.props;
    const imageDataURI = formValues.get(IMAGE_DATAURI) as unknown as string;
    const identityMethod = formValues.get(IDENTITY_METHOD) as unknown as string;
    const identityMethodExpirationDate = formValues.get(IDENTITY_METHOD_EXPIRATION_DATE) as unknown as string;
    const identityMethodNumber = formValues.get(IDENTITY_METHOD_NUMBER) as unknown as string;
    const identityMethodIssuer = formValues.get(IDENTITY_METHOD_ISSUER) as unknown as string;
    const identityMethodIssuerCountry = formValues.get(IDENTITY_METHOD_ISSUER_COUNTRY) as unknown as string;
    const identityMethodDateOfIssue = formValues.get(IDENTITY_METHOD_DATE_OF_ISSUE) as unknown as string;
    const description = formValues.get(DESCRIPTION) as unknown as string;
    const fields = template.form ? template.form.items[0].items : [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const resolveValue = (key) => formValues.getIn(key.split('.'));
    return (
      <section>
        <header>{translate(`customer.customerDetails`)}</header>
        <div className="row">
          <div className="small-12 medium-expand columns">
            <section className={styles.fieldsColumn}>
              <FieldList
                fields={fields}
                errors={formErrors.toJS()}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                schema={template.schema ? template.schema : {}}
                values={formValues.toJS()}
                touched={formTouched}
                onBlur={this.validate}
                onChange={this.handleChange}
                resolveValue={resolveValue}
                wizardSection={true}
                contractId={'wizard'}
                template={template}
                countries={countries}
                locale={locale}
                contractLanguage={locale}
              />
            </section>
          </div>
          <div className="small-12 medium-offset-1 medium-expand columns">
            <section>
              <ImageCapture
                imageDataURI={imageDataURI}
                selectedMethod={identityMethod}
                identityMethodNumber={identityMethodNumber}
                identityMethodIssuer={identityMethodIssuer}
                identityMethodIssuerCountry={identityMethodIssuerCountry}
                identityMethodDateOfIssue={identityMethodDateOfIssue}
                identityExpirationDate={identityMethodExpirationDate}
                description={description}
                identityMethods={this.props.identityMethods}
                countries={countries}
                handler={this.handleImageDataChange}
                clearImageHandler={this.handleClearImage}
                horizontal={false}
                isProspect={true}
              />
            </section>
          </div>
        </div>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ wizardFieldValidation }, dispatch);
}

function mapStateToProps(state: RootState) {
  return {
    identityMethods: IdentityOptions.methods,
    countries: state.common.countries,
    locale: state.common.locale,
    ...state.prospects,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(CustomerIdentificationView);
