import React from 'react';
import { branch, renderNothing } from 'recompose';
import translate from 'counterpart';
import Warning from 'features/common/Warning';

const Pledged = () => <Warning>{translate(`order.pledgedWarning`)}</Warning>;

export default branch(
  ({ isPortfolioPledged }: { isPortfolioPledged: boolean }) => !isPortfolioPledged,
  renderNothing
)(Pledged);
