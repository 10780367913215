import { colors } from 'styles/colors';

const lightGray = '#c0c0c0';
const lightBlack = '#222222';
const fontSize = '8px';
const fontFamily = `'Calibre', 'Helvetica Neue', 'Arial'`;

export const styles = {
  padding: { bottom: 25, left: 50, top: 5, right: 10 },
  height: 200,
  xAxis: {
    axis: {
      stroke: 'transparent',
    },
    tickLabels: {
      fill: lightBlack,
      fontFamily,
      fontSize,
      color: lightBlack,
    },
  },
  yAxis: {
    grid: {
      stroke: 'black',
      opacity: 0.1,
    },
    axis: {
      stroke: 'transparent',
    },
    tickLabels: {
      fill: lightBlack,
      fontFamily,
      fontSize,
      color: lightBlack,
    },
  },
  wideCone: {
    data: {
      fill: colors.primary,
      opacity: 0.2,
    },
  },
  narrowCone: {
    data: {
      fill: colors.primary,
      opacity: 0.6,
    },
  },
  averageLine: {
    data: {
      stroke: colors.black,
      strokeWidth: 1,
      opacity: 1,
    },
  },
  varLine: {
    data: {
      stroke: colors.red,
      strokeDasharray: '5,5',
      strokeWidth: 1,
      opacity: 1,
    },
  },

  historicYAxis: {
    grid: {
      stroke: (tick: number) => determineGridColor(tick),
      strokeWidth: 1,
      strokeOpacity: 1,
      strokeDasharray: '1,3',
    },
    axis: { stroke: lightGray, strokeWidth: 0 },
    ticks: { strokeWidth: 1 },
    tickLabels: {
      fill: lightBlack,
      fontFamily,
      fontSize,
      color: lightBlack,
    },
  },
  lineStyle: {
    axis: { stroke: colors.black, strokeWidth: 1 },
    data: {
      fill: 'transparent',
      stroke: `url(#areaLinearGradient)`,
    },
  },
};

const determineGridColor = (tick: number) => {
  if (tick < 0) {
    return '#00AADA';
  }
  if (tick > 0) {
    return '#00ca00';
  }
  return 'grey';
};
