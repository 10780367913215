import React, { Component } from 'react';
import translate from 'counterpart';
import Immutable from 'immutable';
import moment from 'moment';
import styles from './Activity.scss';
import { getAppointmentSchema, isFieldRequired, getError } from 'core/index';
import Location from './Location';
import { PHONE_CALL } from 'constants/meetingType';
import { ValidationErrorValue } from 'types/orderDialogState';
import { Note } from 'types/ordersState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  scheduledStart: string;
  scheduledEnd: string;
  location: string;
  activity: string;
  editNote: (key: string, value: string) => void;
  mergeNote: (note: Partial<Note>) => void;
  state: string;
  errors: ValidationErrorValue[];
  touched: Immutable.Set<string>;
}

export default class Schedule extends Component<Props> {
  static defaultProps = {
    scheduledStart: '',
    scheduledEnd: '',
    location: '',
  };

  constructor(props: Props) {
    super(props);
    this.handleLocation = this.handleLocation.bind(this);
  }

  handleStart = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.mergeNote({
      scheduledStart: event.currentTarget.value,
      scheduledEnd: moment(event.currentTarget.value, 'YYYY-MM-DDTHH:mm').add(1, 'h').format('YYYY-MM-DDTHH:mm'),
    });
  };

  handleEnd = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('scheduledEnd', event.currentTarget.value);
  };

  handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('location', event.currentTarget.value);
  };

  render() {
    const { scheduledStart, scheduledEnd, activity, location, state, errors, touched } = this.props;
    const commonProps = {
      style: {
        width: '100%',
        boxShadow: 'none',
      },
      type: 'datetime-local',
    };
    const schema = getAppointmentSchema(activity, state);

    return (
      <div className="row">
        <div className="columns small-6">
          <div className={styles.label}>
            {activity === PHONE_CALL ? translate('meetings.callScheduledStart') : translate('meetings.scheduledStart')}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error*/}
            {isFieldRequired('scheduledStart', schema) ? '*' : ''}
          </div>
          <StyledTextField
            name="scheduledStart"
            onChange={this.handleStart}
            value={scheduledStart}
            {...commonProps}
            helperText={touched.has('scheduledStart') ? getError(errors, 'scheduledStart') : undefined}
            variant="standard"
            sx={[
              {
                '&.MuiFormControl-root': {
                  marginTop: '0',
                  width: '100%',
                },
                '& .Mui-focused': {
                  '&::after': {
                    left: 0,
                  },
                },
                '& .MuiInputBase-root': {
                  marginTop: '14px',
                  marginBottom: '15px',
                  paddingLeft: 0,
                  '&::before': {
                    left: 0,
                  },
                  backgroundColor: '#ffffff',
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: 0,
                  height: '100%',
                  paddingTop: 0,
                },
                '& label': {
                  fontSize: '14px',
                  '&.Mui-focused': {
                    fontSize: '14px',
                  },
                },
                '& > p': {
                  '&.MuiFormHelperText-root': {
                    paddingLeft: '0',
                  },
                },
                '&.Mui-error': {
                  borderBottomColor: '#d32f2f',
                  borderWidth: '2px',
                  '&::before': {
                    borderWidth: '2px',
                    borderBottomColor: '#d32f2f',
                  },
                },
              },
            ]}
          />
        </div>
        <div className="columns small-6">
          <div className={styles.label}>
            {activity === PHONE_CALL ? translate('meetings.callScheduledEnd') : translate('meetings.scheduledEnd')}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error*/}
            {isFieldRequired('scheduledEnd', schema) ? '*' : ''}
          </div>
          <StyledTextField
            name="scheduledEnd"
            onChange={this.handleEnd}
            value={scheduledEnd}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            min={scheduledStart}
            helperText={touched.has('scheduledEnd') ? getError(errors, 'scheduledEnd') : undefined}
            {...commonProps}
            variant="standard"
            sx={[
              {
                '&.MuiFormControl-root': {
                  marginTop: '0',
                  width: '100%',
                },
                '& .Mui-focused': {
                  '&::after': {
                    left: 0,
                  },
                },
                '& .MuiInputBase-root': {
                  marginTop: '14px',
                  marginBottom: '15px',
                  paddingLeft: 0,
                  '&::before': {
                    left: 0,
                  },
                  backgroundColor: '#ffffff',
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: 0,
                  height: '100%',
                  paddingTop: 0,
                },
                '& label': {
                  fontSize: '14px',
                  '&.Mui-focused': {
                    fontSize: '14px',
                  },
                },
                '& > p': {
                  '&.MuiFormHelperText-root': {
                    paddingLeft: '0',
                  },
                },
                '&.Mui-error': {
                  borderBottomColor: '#d32f2f',
                  borderWidth: '2px',
                  '&::before': {
                    borderWidth: '2px',
                    borderBottomColor: '#d32f2f',
                  },
                },
              },
            ]}
          />
        </div>
        <Location
          activity={activity}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          handleLocation={this.handleLocation}
          location={location}
          state={state}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          schema={schema}
          errors={errors}
          touched={touched}
        />
      </div>
    );
  }
}
