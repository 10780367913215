import { ContractSignatureStates } from 'features/orders/ordersTypes';
import { SignatureMethodType } from 'types/types';

export const SENT_FOR_SIGNING = 'sent_for_signing';
export const SENT_FOR_MANUAL_SIGNING = 'sent_for_manual_signing';
export const SENT_FOR_BANKER_APPROVAL = 'sent_for_banker_approval';
export const SENT_FOR_INK_SIGNING = 'sent_for_ink_signing';
export const FINISHED = 'finished';
export const SIGNED = 'signed';
const REVIEW = 'review';
const REVIEWED = 'reviewed';
const REJECTED = 'rejected';
const OPENING_SERVICE = 'opening_service';
const SIGNING_HANDLING_FAILED = 'signing_handling_failed';

export const SIGNATURE_REQUIRED: SignatureMethodType[] = [
  SENT_FOR_SIGNING,
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_INK_SIGNING,
];

export const SIGNATURE_NOT_REQUIRED: SignatureMethodType[] = [SENT_FOR_BANKER_APPROVAL, FINISHED];

export const contractStates = {
  SENT_FOR_SIGNING,
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_BANKER_APPROVAL,
  SENT_FOR_INK_SIGNING,
  SIGNED,
  REVIEW,
  REVIEWED,
  REJECTED,
  OPENING_SERVICE,
  FINISHED,
  SIGNING_HANDLING_FAILED,
};

export const finishedContractStates = [SIGNED, REVIEWED, OPENING_SERVICE, FINISHED];

export const unfinishedContractStates = [
  SENT_FOR_SIGNING,
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_BANKER_APPROVAL,
  SENT_FOR_INK_SIGNING,
  REVIEW,
];

export const contractSignatureStates: ContractSignatureStates = {
  [SENT_FOR_MANUAL_SIGNING]: {
    confirmed: 'Allekirjoitettu',
    unconfirmed: 'Kuittaa',
    icon: 'icon-document',
  },
  [SENT_FOR_BANKER_APPROVAL]: {
    confirmed: 'Hyväksytty',
    unconfirmed: 'Hyväksy',
    icon: 'icon-document',
  },
  [SENT_FOR_SIGNING]: {
    confirmed: 'Lähetetty',
    unconfirmed: 'Lähetä',
    icon: 'icon-electronic-signature',
  },
  [SENT_FOR_INK_SIGNING]: {
    confirmed: 'Allekirjoitettu',
    unconfirmed: 'Allekirjoita',
    icon: 'icon-electronic-signature',
  },
  [SIGNED]: {
    confirmed: 'Allekirjoitettu',
    unconfirmed: 'Allekirjoita',
    icon: 'icon-document',
  },
};
