import React, { useState, FocusEvent, ChangeEvent, CSSProperties } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import _ from 'lodash';
import Select from 'react-select-old';
import translate from 'counterpart';
import classnames from 'classnames';
import { NumericFormat } from 'react-number-format';
import { TextField } from '@mui/material';
import { wrapElement, getDependentQuestions } from 'core/index';
import FieldInfo from './FieldInfo';
import { getSchemaValue, getDefaultValue } from 'core/functions';
import {
  SELECT,
  BOOLEAN,
  CHECKBOX,
  NUMBER,
  EMAIL,
  SELECT_MANY,
  COUNTRY,
  ARRAY,
  DATE_TIME,
  IBAN,
} from 'constants/fieldTypes';
import styles from './Field.scss';
import { Template, Condition, Value, OptionValue, FieldType } from 'types/ordersState';
import { Countries, Schema, Types, Locale } from 'types/contractsState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

const validateAfterChangeFields = [SELECT, SELECT_MANY, COUNTRY];

interface Props {
  field: FieldType;
  error: string | undefined;
  openInfo: (title: string, info: string) => void;
  locale: Locale;
  required: boolean;
  onChange: (key: string, value: Value) => void;
  types: Types;
  schema: Schema;
  value: Value;
  valid: boolean | undefined;
  index: number;
  countries: Countries;
  wizardSection: boolean;
  validate: (field: FieldType) => void;
  onFocus: (index: number, ev: FocusEvent<{}>) => void;
  fulfillsCondition?: (condition: Condition, value: Value) => boolean;
  template: Template;
  dependentQuestions: FieldType[];
  contractLanguage: Locale;
  isArrayQuestion: boolean | undefined;
  textFieldAsWizardSection: boolean | undefined;
}

export const Field = ({
  field,
  error,
  openInfo,
  required,
  onChange,
  types,
  schema,
  value,
  valid,
  index,
  countries,
  wizardSection,
  validate,
  onFocus,
  fulfillsCondition,
  template,
  dependentQuestions,
  contractLanguage,
  textFieldAsWizardSection,
}: Props) => {
  const [hasFocus, setHasFocus] = useState(false);

  const TEXT_FIELD_MAX_LENGTH = 100; //... what? why??

  const onChangeField = (types: Types, key: string, ev: ChangeEvent<HTMLInputElement>) => {
    if (ev?.persist) {
      ev.persist();
    }
    let value: Value;
    switch (types.type) {
      case CHECKBOX:
        value = ev.target.checked;

        break;
      case NUMBER:
        try {
          value = ev.target.value;
          value = value.replace(/\s/g, '');
          value = value.replace(',', '.');
          const decimalZeroCheck = value.includes('.') && value.endsWith('0');
          if (!value.endsWith('.') && !decimalZeroCheck) {
            value = parseFloat(value);
          }
        } catch (err) {
          value = undefined;
        }
        break;
      default:
        if (ev?.target) {
          if (types.dataType === IBAN) {
            const target = ev.target;
            let position = target.selectionEnd || 0;
            const length = target.value.length;

            // format string to iban format
            target.value = target.value
              .replace(/[^\dA-Z]/g, '')
              .replace(/(.{4})/g, '$1 ')
              .trim();

            value = target.value;

            // handle caret positioning
            target.selectionEnd = position +=
              target.value.charAt(position - 1) === ' ' &&
              target.value.charAt(length - 1) === ' ' &&
              length !== target.value.length
                ? 1
                : 0;
          } else {
            value = ev.target.value;
          }
        } else {
          value = '';
        }
    }
    // update field value in redux
    onChange(key, value);

    if (validateAfterChangeFields.includes(types.type)) {
      validate(field);
    }

    // on change of value of question answer, handle questions that are conditionally rendered based on this answer
    handleDependentQuestions(value, dependentQuestions);
  };

  const onChangeToggle = (types: Types, key: string, ev: React.ChangeEvent<HTMLInputElement>, value: Value) => {
    if (ev?.persist) {
      ev.persist();
    }

    // update field value in redux
    onChange(key, value);

    if (validateAfterChangeFields.includes(types.type)) {
      validate(field);
    }

    // on change of value of question answer, handle questions that are conditionally rendered based on this answer
    handleDependentQuestions(value, dependentQuestions);
  };

  const onChangeSelect = (types: Types, key: string, ev: OptionValue | OptionValue[] | null) => {
    let value: Value;
    switch (types.type) {
      case SELECT:
      case COUNTRY:
        if (Array.isArray(ev)) {
          if (types.dataType === ARRAY) {
            value = _.map(ev, (v) => v.value);
          }
        } else {
          value = ev ? ev.value : '';
        }
        break;
      case SELECT_MANY:
        if (Array.isArray(ev)) {
          value = _.map(ev, (v) => v.value);
        }
        break;
    }

    // update field value in redux
    onChange(key, value);

    if (validateAfterChangeFields.includes(types.type)) {
      validate(field);
    }

    // on change of value of question answer, handle questions that are conditionally rendered based on this answer
    handleDependentQuestions(value, dependentQuestions);
  };

  const onFocusField = (index: number, event: FocusEvent<{}>) => {
    setHasFocus(true);
    if (typeof onFocus === 'function') {
      onFocus(index, event);
    }
  };

  const onBlur = (field: FieldType, event: FocusEvent<{}>) => {
    setHasFocus(false);
    if (event.target) {
      event.persist();
    }
    validate(field);
  };

  /* If dependent questions don't pass condition test, reset their values to default
   */
  const handleDependentQuestions = (dependsOnValue: Value, dependentQuestions: FieldType[]) => {
    if (dependentQuestions.length > 0) {
      dependentQuestions.forEach((question) => {
        const doesNotFulfillConditionAgainstDependsValue =
          fulfillsCondition && question.condition && !fulfillsCondition(question.condition, dependsOnValue);
        if (doesNotFulfillConditionAgainstDependsValue) {
          let defaultValue = getDefaultValue(schema, question.key);
          if (defaultValue === true) {
            defaultValue = false;
          }
          if (defaultValue === 'array') {
            defaultValue = [];
          }
          if (defaultValue === 'string') {
            defaultValue = '';
          }
          // update field value in redux
          onChange(question.key, defaultValue);
          dependentQuestions = getDependentQuestions(question.key, template.form.items);
          // handle dependent questions recursively, because there can be dependency chains
          if (dependentQuestions.length > 0) {
            handleDependentQuestions(defaultValue, dependentQuestions);
          }
        }
      });
    }
  };

  let iconClass;
  if (valid === true) {
    iconClass = 'icon icon-check';
  } else if (valid === false) {
    iconClass = 'icon icon-exclamation';
  } else {
    iconClass = '';
  }

  const indicator = !wizardSection ? (
    <div className={styles.indicator}>
      <i className={iconClass} />
    </div>
  ) : null;

  const hasLabel = field.title && field.title[contractLanguage];
  const labelTopPx = (field: FieldType) => {
    const labelInLang = hasLabel ? field.title[contractLanguage] : '';
    const hasLabelLength = hasLabel && labelInLang.length > 0;
    if (hasLabelLength) {
      const length = labelInLang.length;
      if (length > 200) {
        return '-20px';
      }
      if (length > 150) {
        return '-15px';
      }
      if (length > 100) {
        return '-10px';
      }
      if (length > 40) {
        return '0px';
      }
    }
    return '24px';
  };
  const labelTop = labelTopPx(field);

  const label = (
    <div>
      {indicator}
      {hasLabel && (required ? `${field.title[contractLanguage]} *` : field.title[contractLanguage])}
    </div>
  );

  const toggleLabelStyle = {
    color: '#000000',
    fontSize: wizardSection || textFieldAsWizardSection ? '.875rem' : '1.125rem',
    fontWeight: 'bold',
  };
  const labelStyle = _.assign({}, toggleLabelStyle, {
    top: labelTop,
  }) as CSSProperties;
  let inputStyle = {
    boxShadow: 'none',
    display: 'block',
  };

  const tabIndex = index;
  const hint = field.placeHolder ? field.placeHolder[contractLanguage] : '';
  const maxLength = getSchemaValue(schema, field.key, 'maxLength');
  const isMultiline = maxLength > TEXT_FIELD_MAX_LENGTH;

  if (!wizardSection) {
    inputStyle = {
      boxShadow: 'none',
      display: 'block',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      padding: isMultiline ? '0 0 0 1.5rem' : '0 0 0 0',
    };
  }

  const theme = createTheme({
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& > p': {
              '&.MuiFormHelperText-root': {
                color: '#F44336',
                fontSize: '12px',
                marginBottom: '24px',
              },
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            marginBottom: '5px',
            '&.Mui-focused': {
              '& > input': {
                border: 'none',
                boxShadow: 'none',
              },
            },
            '&.Mui-error': {
              '&::after': {
                borderColor: '#red',
              },
            },
            '&::after': {
              borderColor: '#000000',
            },
          },
          input: {
            color: '#000000',
            boxShadow: 'none',
            width: '100%',
            fontSize: '16px',
            marginTop: '15px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: '#000000',
            fontSize: wizardSection ? '.875rem' : '1.125rem',
            fontWeight: 'bold',
            top: '5px',
            '&.Mui-focused': {
              color: '#000000',
            },
            '&.Mui-error': {
              color: '#000000',
              bottom: isMultiline ? '25px' : '5px',
            },
          },
        },
      },
    },
  });

  let component: JSX.Element | undefined;
  switch (types.type) {
    case BOOLEAN: {
      const toggleLabel = (
        <div className={styles.toggleLabel}>
          {label}
          <span className={styles.toggleAnswer}>
            {value
              ? translate('common.yes', { locale: contractLanguage })
              : translate('common.no', { locale: contractLanguage })}
          </span>
        </div>
      );
      component = (
        <FormGroup
          sx={[
            {
              '&.MuiFormGroup-root': {
                width: '100%',
                marginLeft: '0',
                float: 'left',
              },
            },
          ]}
        >
          <FormControlLabel
            control={
              <Switch
                onChange={(event, checked) => onChangeToggle(types, field.key, event, checked)}
                onFocus={(event) => onFocusField(index, event)}
                onBlur={(event) => onBlur(field, event)}
                value={typeof value === 'undefined' || value === null ? '' : value}
                tabIndex={tabIndex}
                disabled={field.readOnly}
                data-testkey={field.key}
                color="default"
                checked={typeof value === 'undefined' || value === null ? false : (value as boolean)}
              />
            }
            label={toggleLabel}
            labelPlacement="start"
            sx={[
              {
                '& .MuiTypography-root': {
                  width: '100%',
                },
                '&.MuiFormControlLabel-root': {
                  marginLeft: '0',
                },
              },
            ]}
          />
        </FormGroup>
      );
      break;
    }
    case EMAIL:
      component = (
        <StyledTextField
          error={Boolean(error)}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onChange={(event) => onChangeField(types, field.key, event)}
          onFocus={(event) => onFocusField(index, event)}
          onBlur={(event) => onBlur(field, event)}
          disabled={field.readOnly}
          value={value || ''}
          fullWidth={true}
          type="email"
          placeholder={hint}
          tabIndex={tabIndex}
          helperText={error}
          label={label}
          name={`email-${tabIndex}`}
          data-testkey={field.key}
          variant="standard"
          sx={[
            {
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInputBase-root': {
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
            },
          ]}
        />
      );
      break;
    case SELECT: {
      let options: OptionValue[] = [];
      if (field.titleMap) {
        options = _.map(field.titleMap[contractLanguage], (val, key) => ({
          value: key,
          label: val,
        }));
      }

      component = (
        <div className={styles.select} data-testkey={field.key}>
          <label className={'select-label'}>{label}</label>
          <Select
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={(event) => onChangeSelect(types, field.key, event)}
            onFocus={(event) => onFocusField(index, event)}
            onBlur={(event) => onBlur(field, event)}
            disabled={field.readOnly}
            name={field.id}
            value={value || ''}
            labelKey="label"
            options={options}
            multi={false}
            noResultsText=""
            tabIndex={tabIndex.toString()}
            tabSelectsValue={false}
            placeholder={translate('newContract.select', { locale: contractLanguage })}
            searchable={false}
            openOnFocus={true}
          />
        </div>
      );
      break;
    }
    case SELECT_MANY: {
      let manyOptions: OptionValue[] = [];
      if (field.titleMap) {
        manyOptions = _.map(field.titleMap[contractLanguage], (val, key) => ({
          value: key,
          label: val,
        }));
      }
      let manyValue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (value && typeof value.toJS === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        manyValue = value.toJS();
      } else {
        manyValue = value;
      }
      component = (
        <div className={styles.select} data-testkey={field.key}>
          <label>{label}</label>
          <Select
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={(event) => onChangeSelect(types, field.key, event)}
            onFocus={(event) => onFocusField(index, event)}
            onBlur={(event) => onBlur(field, event)}
            disabled={field.readOnly}
            name={field.id}
            value={manyValue || ''}
            labelKey="label"
            options={manyOptions}
            multi={true}
            noResultsText=""
            tabIndex={tabIndex.toString()}
            tabSelectsValue={false}
            placeholder={translate('newContract.select', { locale: contractLanguage })}
            searchable={false}
            openOnFocus={true}
            backspaceToRemoveMessage={''}
          />
        </div>
      );
      break;
    }
    case NUMBER:
      component = (
        <ThemeProvider theme={theme}>
          <NumericFormat
            min={getSchemaValue(schema, field.key, 'minimum')}
            max={getSchemaValue(schema, field.key, 'maximum')}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeField(types, field.key, event)}
            onFocus={(event: React.FocusEvent<{}>) => onFocusField(index, event)}
            onBlur={(event: React.FocusEvent<{}>) => onBlur(field, event)}
            disabled={field.readOnly}
            type="text"
            value={typeof value === 'undefined' || value === null ? '' : value.toString().replace('.', ',')}
            tabIndex={tabIndex}
            autoCorrect="off"
            autoComplete="off"
            placeholder={hint}
            helperText={error}
            fullWidth={true}
            label={label}
            name={`number-${tabIndex}`}
            thousandSeparator={' '}
            decimalSeparator={','}
            prefix={''}
            customInput={TextField}
            allowNegative={true}
            data-testkey={field.key}
            variant="standard"
          />
        </ThemeProvider>
      );
      break;
    case COUNTRY: {
      const countryOptions = _.chain(countries)
        .map((val, key) => ({
          value: key,
          label: val,
        }))
        .sortBy((kv) => (kv.value === 'FI' ? 'A' : kv.label))
        .value();

      let countryValue;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (value && typeof value.toJS === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        countryValue = value.toJS();
      } else {
        countryValue = value;
      }

      component = (
        <div className={styles.select} data-testkey={field.key}>
          <label>{label}</label>
          <Select
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={(event) => onChangeSelect(types, field.key, event)}
            onFocus={(event) => onFocusField(index, event)}
            onBlur={(event) => onBlur(field, event)}
            disabled={field.readOnly}
            name={field.id}
            value={countryValue || ''}
            labelKey="label"
            options={countryOptions}
            multi={types.dataType === ARRAY}
            noResultsText=""
            //prettier-ignore
            addLabelText="Lisää &quot;{label}&quot;?"
            placeholder={translate('newContract.select', { locale: contractLanguage })}
            tabIndex={tabIndex.toString()}
            tabSelectsValue={false}
            openOnFocus={true}
            backspaceToRemoveMessage={''}
          />
        </div>
      );
      break;
    }
    default:
      if (types.dataType === DATE_TIME) {
        component = (
          <label
            style={{
              display: 'inline-block',
              position: 'relative',
              width: '100%',
            }}
          >
            <div style={labelStyle}>{label}</div>
            <StyledTextField
              error={Boolean(error)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange={(event) => onChangeField(types, field.key, event)}
              onBlur={(event) => onBlur(field, event)}
              disabled={field.readOnly}
              value={value || ''}
              tabIndex={tabIndex}
              autoCorrect="off"
              autoComplete="off"
              placeholder={hint}
              helperText={error}
              fullWidth={true}
              type={'date'}
              name={`date-${tabIndex}`}
              //prettier-ignore
              onFocus={function (ev: { target: { defaultValue: string; }; }) {
                ev.target.defaultValue = '';
              }}
              data-testkey={field.key}
              variant="standard"
            />
          </label>
        );
      } else if (field.type === 'text') {
        const linkTypeKey = 'textLinkKey';
        if (field.key.includes(linkTypeKey)) {
          let link = field.link && field.link[contractLanguage];
          const beforeTextTmp = field.title && field.title[contractLanguage];
          const linkText = field.linkText && field.linkText[contractLanguage];
          const afterTextTmp = field.textAfterLink && field.textAfterLink[contractLanguage];

          const beforeText = beforeTextTmp ? handleNewLines(beforeTextTmp) : '';
          const afterText = afterTextTmp ? handleNewLines(afterTextTmp) : '';

          if (link && !link.includes('://')) {
            link = `https://${link}`;
          }
          component = (
            <div style={inputStyle}>
              {beforeText}{' '}
              <a href={link} target="_blank" rel="noreferrer" style={{ borderBottom: '1px solid #462d23' }}>
                {linkText}
              </a>{' '}
              {afterText}
            </div>
          );
        }
      } else if (field.type === 'table') {
        // we don't want tables to show here
      } else {
        const maxLength = getSchemaValue(schema, field.key, 'maxLength');
        const isMultiline = maxLength > TEXT_FIELD_MAX_LENGTH;

        component = (
          <StyledTextField
            error={Boolean(error)}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={(event) => onChangeField(types, field.key, event)}
            onFocus={(event) => onFocusField(index, event)}
            onBlur={(event) => onBlur(field, event)}
            disabled={field.readOnly}
            value={typeof value === 'undefined' || value === null ? '' : value}
            tabIndex={tabIndex}
            autoCorrect="off"
            autoComplete="off"
            helperText={error}
            fullWidth={true}
            label={label}
            type={'text'}
            name={`text-${tabIndex}`}
            multiline={isMultiline}
            data-testkey={field.key}
            variant="standard"
            placeholder={hint}
            inputProps={{ maxLength: maxLength ? maxLength : 10000 }}
            sx={{
              '& .MuiInput-root': {
                '&::after': {
                  borderBottom: wizardSection ? '1px solid #000000 !important' : '',
                },
              },
              '& .Mui-focused': {
                color: '#000000',
                '& > input': {
                  border: 'none',
                  boxShadow: 'none',
                },
                '&::after': {
                  borderColor: '#000000',
                  left: wizardSection ? '0rem' : '3rem',
                },
              },
              '& .MuiInputBase-root': {
                paddingLeft: wizardSection ? 0 : '3rem',
                '&::before': {
                  left: wizardSection ? '0px !important' : '3rem',
                },
                '&::after': {
                  borderBottomColor: '#000000',
                },
              },
              '& label': {
                color: '#000000',
                margin: 0,
                padding: 0,
                fontSize: wizardSection ? '19px' : '21.6px',
                fontWeight: textFieldAsWizardSection ? '700' : '400',
                fontFamily: 'Calibre, sans-serif;',
                marginTop: textFieldAsWizardSection ? '' : '10px !important',
                marginBottom: '5px !important',
              },
              '& .MuiFormHelperText-root': {
                marginBottom: wizardSection ? '0px !important' : '24px',
                paddingLeft: wizardSection ? '0px !important' : '3rem',
              },
            }}
          />
        );
      }
  }

  function handleNewLines(text: string) {
    return text.indexOf('\n') > -1
      ? text.split('\n').map(function (item: string, index: number) {
          return (
            <span key={index}>
              {item}
              <br />
            </span>
          );
        })
      : text;
  }
  const lang = contractLanguage;
  let theComponent = (
    <div className={classnames(styles.componentContainer, hasFocus ? styles.hasFocus : '')}>
      {component ? component : ''}
      {field.showInfoAlways && field.info && <p>{field.info[lang]}</p>}
      {field.info && !field.showInfoAlways ? (
        <FieldInfo onClick={openInfo} title={field.title[lang]} info={field.info[lang]} className={styles.infoBtn} />
      ) : undefined}
    </div>
  );

  if (!wizardSection) {
    theComponent = wrapElement(theComponent, styles, valid, index);
  }
  return theComponent;
};
