import React, { useEffect, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import translate from 'counterpart';
import { InjectedRouter } from 'react-router';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import {
  selectCreatingContracts,
  selectPortfolioContractNames,
  selectPortfolioStrategies,
  selectShowHasSeenDocumentsToggleByPortfolioId,
  selectIsAdviceDeliveryVisibleByPortfolioId,
  selectIsOrderConfirmedByPortfolioId,
  selectAllOrders,
  hasBuyOrders,
  selectIsBasisOfAdviceRequiredByPortfolioId,
} from 'features/orders/ordersSelectors';
import styles from './OrdersPreviewView.scss';
import { FULL_POWER_OF_ATTORNEY } from 'constants/contractNames';
import { isIndividualFullPowerOfAttorney, isListed } from 'core/portfolios';
import { ImmutableOrderLineType, OrderLineType } from 'types/ordersState';
import {
  hideConfirmDialog,
  createOrders,
  setInitialOrderDetails,
  getOrdersPreview,
  getClientSigners,
  clearOrderDetails,
  changeContractField,
} from 'features/orders/ordersActions';
import { RootState } from 'types/rootState';
import {
  selectContractOrderLines,
  selectCurrentInstrumentFormByOrderLineId,
  selectCurrentSideByOrderLineId,
} from 'features/orderLines/orderLinesSelectors';
import { Spinner } from 'features/common/Spinner';
import { ConfirmationDialog } from 'features/orders/components/ConfirmationDialog';
import { OrderPreview } from 'features/orders/components/OrderPreview';
import { FUND } from 'constants/instrumentForms';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { useAppDispatch } from 'core/hooks';
import { colors } from 'styles/colors';

interface Props {
  router: InjectedRouter;
}

export const OrdersPreviewView = ({ router }: Props) => {
  const dispatch = useAppDispatch();

  const showConfirmDialog = useSelector((state: RootState) => state.orders.showConfirmDialog);
  const customerId = useSelector(selectCustomerId);
  const ordersWithPortfolioId = useSelector(selectAllOrders);
  const creatingContracts = useSelector(selectCreatingContracts);
  const portfolioContractNames = useSelector(selectPortfolioContractNames);
  const portfolioStrategies = useSelector(selectPortfolioStrategies);
  const orderLines = useSelector(selectContractOrderLines);
  const showHasSeenDocumentsToggle = useSelector(selectShowHasSeenDocumentsToggleByPortfolioId);
  const isAdviceDeliveryVisible = useSelector(selectIsAdviceDeliveryVisibleByPortfolioId);
  const basisOfAdviceRequiredIn = useSelector(selectIsBasisOfAdviceRequiredByPortfolioId);
  const isOrderConfirmed = useSelector(selectIsOrderConfirmedByPortfolioId);
  const state = useSelector((state: RootState) => state);
  const basisOfAdvice = useSelector((state: RootState) => state.orderDialog.basisOfAdvice);

  useEffect(() => {
    if (customerId) {
      if (orderLines.size === 0) {
        dispatch(push(`customer/${customerId}/portfolio?f=all`));
      }
      initOrderData();
    }
  }, [orderLines.size, customerId]);

  const initOrderData = () => {
    dispatch(clearOrderDetails());

    ordersWithPortfolioId.forEach((orderWithPortfolioId) => {
      const { portfolioId, order } = orderWithPortfolioId;

      //if (!order.contract.getIn(['sections', 'order', 'basisOfAdvice'])) {
      //  dispatch(changeContractField('sections.order.basisOfAdvice', 'marketOpportunity', portfolioId));
      //}

      const orderLines: OrderLineType[] = order.contract.getIn(['sections', 'order', 'orderLines']).toJS();

      const fundOrderOrderLines = orderLines.filter(
        (orderLine) => orderLine.financialInstrument.financialInstrumentForm === FUND && orderLine.rowType === 'order'
      );
      const fundAdviceOrderLines = orderLines.filter(
        (orderLine) => orderLine.financialInstrument.financialInstrumentForm === FUND && orderLine.rowType === 'advice'
      );
      const nonFundOrderOrderLines = orderLines.filter(
        (orderLine) => orderLine.financialInstrument.financialInstrumentForm !== FUND && orderLine.rowType === 'order'
      );
      const nonFundAdviceOrderLines = orderLines.filter(
        (orderLine) => orderLine.financialInstrument.financialInstrumentForm !== FUND && orderLine.rowType === 'advice'
      );

      fundOrderOrderLines.length &&
        dispatch(
          setInitialOrderDetails({
            portfolioId,
            orderLines: fundOrderOrderLines,
            orderType: 'fundOrder',
          })
        );

      fundAdviceOrderLines.length &&
        dispatch(
          setInitialOrderDetails({
            portfolioId,
            orderLines: fundAdviceOrderLines,
            orderType: 'fundAdvice',
          })
        );

      nonFundOrderOrderLines.length &&
        dispatch(
          setInitialOrderDetails({
            portfolioId,
            orderLines: nonFundOrderOrderLines,
            orderType: 'nonFundOrder',
          })
        );

      nonFundAdviceOrderLines.length &&
        dispatch(
          setInitialOrderDetails({
            portfolioId,
            orderLines: nonFundAdviceOrderLines,
            orderType: 'nonFundAdvice',
          })
        );
      if (basisOfAdvice) {
        basisOfAdviceRequiredIn.forEach((item) => {
          if (item.isBasisOfAdviceRequired) {
            dispatch(changeContractField('sections.order.basisOfAdvice', basisOfAdvice, item.portfolioId));
          }
        });
      }
      dispatch(getOrdersPreview(order.contract));
    });

    dispatch(getClientSigners());
  };

  const getNotifications = () => {
    const notifications: ReactElement[] = [];
    let component: ReactElement | null = null;
    const getIssuer = (line: ImmutableOrderLineType) => line.get('issuer') || '';

    ordersWithPortfolioId.forEach((orderWithPortfolioId) => {
      const { portfolioId, order } = orderWithPortfolioId;

      const contractOrderIds = order.orderDetails.map((c) => c.orderLineIds).flat();

      contractOrderIds.map((orderLineId) => {
        const instrumentForm = selectCurrentInstrumentFormByOrderLineId(state, orderLineId);
        const currentSide = selectCurrentSideByOrderLineId(state, orderLineId);
        if (
          isListed(instrumentForm, currentSide) &&
          (hasBuyOrders(order.contract) ||
            portfolioContractNames.find((x) => x.portfolioId === portfolioId)?.portfolioContractName !==
              FULL_POWER_OF_ATTORNEY ||
            isIndividualFullPowerOfAttorney(
              portfolioContractNames.find((x) => x.portfolioId === portfolioId)?.portfolioContractName,
              portfolioStrategies.find((x) => x.portfolioId === portfolioId)?.portfolioStrategy
            ))
        ) {
          if (orderLines.some((line: ImmutableOrderLineType) => getIssuer(line).toLowerCase() === 'tt syöttörahasto')) {
            notifications.push(
              <div className={styles.notification} key="lombard">
                {translate(`order.notificationLombard`)}
              </div>
            );
          }

          if (
            orderLines.some((line: ImmutableOrderLineType) => getIssuer(line).toLowerCase() === 'tt syöttörahasto iii')
          ) {
            notifications.push(
              <div className={styles.notification} key="swiss">
                {translate(`order.notificationSwiss`)}
              </div>
            );
          }
          if (notifications.length > 0) {
            component = (
              <div className="row" key="notes">
                <div className="columns small-12">{notifications}</div>
              </div>
            );
          }
        }
      });
    });

    return component;
  };

  const hideConfirm = () => {
    dispatch(hideConfirmDialog());
  };

  const confirmSigning = () => {
    dispatch(hideConfirmDialog());
    dispatch(createOrders());
  };

  const showContent = (): boolean => {
    const showContentRequired = ordersWithPortfolioId.filter((orderWithPortfolioId) => {
      return portfolioContractNames.find(
        (portfolioContractName) =>
          portfolioContractName.portfolioId === orderWithPortfolioId.portfolioId &&
          portfolioContractName.portfolioContractName !== FULL_POWER_OF_ATTORNEY
      );
    });

    if (showContentRequired.length > 0) {
      return true;
    }
    return false;
  };

  if (creatingContracts) {
    return <Spinner size={100} />;
  }

  return (
    <section className={styles.preview}>
      <ConfirmationDialog
        hideConfirmDialog={() => hideConfirm()}
        confirmSigning={() => confirmSigning()}
        showContent={showContent()}
        showConfirmDialog={showConfirmDialog}
      />

      <div className="row">
        <div className="columns small-12">
          <button onClick={router.goBack} className={classnames('button', styles.backButton)}>
            <span data-testkey="back-to-orders-button">{translate(`order.backToOrders`)}</span>
          </button>
        </div>
      </div>

      <div className="columns small-12">
        <h4 className={styles.header}>{translate(`order.sendOrders`)}</h4>
      </div>

      <DivContainer>
        {ordersWithPortfolioId.map((orderWithPortfolioId) => (
          <OrderPreview
            key={orderWithPortfolioId.portfolioId}
            orderPreview={orderWithPortfolioId}
            orderLines={orderLines}
            showHasSeenDocumentsToggle={showHasSeenDocumentsToggle}
            isAdviceDeliveryVisible={isAdviceDeliveryVisible}
            isOrderConfirmed={isOrderConfirmed}
            getNotifications={getNotifications}
          />
        ))}
      </DivContainer>
      <br />
    </section>
  );
};

const DivContainer = styled.div`
  margin: 20px;
  padding: 15px;
  border: 3px solid ${colors.gray_inactive};
`;
