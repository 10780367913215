import React from 'react';
import styled from 'styled-components';
import { Header } from 'features/portfolioManager/components/Header';
import { allocatorWidth, GlobalStyles } from 'features/common/StyledComponents';
import ErrorBoundary from 'features/common/ErrorBoundary';

interface Props {
  children: object;
}

export const CustomerListRoot = ({ children }: Props) => {
  return (
    <>
      <GlobalStyles />
      <ErrorBoundary>
        <Header />
        <Container>{children}</Container>
      </ErrorBoundary>
    </>
  );
};

const Container = styled.div`
  width: ${allocatorWidth};
  margin: 0 auto 2rem;
`;
