import React from 'react';
import { Contract } from 'types/ordersState';
import styles from './SignatureConfirmItem.scss';
import { ATTACHMENT_TEMPLATE_ID } from 'features/contracts/contractsConstants';

interface Props {
  contract: Contract;
  headerIcon: JSX.Element;
}

export const SignatureConfirmItem = ({ contract, headerIcon }: Props) => {
  const renderTitle = () => {
    return (
      <div className={styles.header}>
        {headerIcon}
        <span className={styles.label}>
          {contract.contractType === ATTACHMENT_TEMPLATE_ID
            ? `Liite: ${contract.descriptiveName}`
            : contract.contractName}
        </span>
      </div>
    );
  };

  return (
    <div className={styles.signatureConfirm}>
      <div className={'accordion-title'}>{renderTitle()}</div>
    </div>
  );
};
