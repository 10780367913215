import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { styles } from 'features/common/StyledComponents';
import { InstrumentDistribution } from 'features/allocator/overview/components/InstrumentDistribution';
import { RootState } from 'types/rootState';
import { AssetClassDistribution } from 'features/allocator/common/AssetClassDistribution';
import {
  selectHasError,
  isLoadingInitialItems,
  isOptimizingCurrent,
  selectCurrentWeights,
} from 'features/allocator/allocatorSelectors';
import { Grid } from 'features/allocator/common/Grid';
import { weightTypes } from 'constants/allocator';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';

export const DistributionByType = () => {
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const currentWeights = useSelector(selectCurrentWeights);
  const loadingInitialItems = useSelector(isLoadingInitialItems);
  const optimizingCurrent = useSelector(isOptimizingCurrent);

  const planInstruments = optimizedPortfolios.flatMap((portfolio) => portfolio.allocatedPortfolioRows.withoutIlliquids);

  const instrumentWeights =
    currentWeights?.instrumentWeights?.length > 0 ? currentWeights?.instrumentWeights : planInstruments;

  const hasOptimizeCurrentError = useSelector((state: RootState) =>
    selectHasError(['optimizeCurrent', 'fetchPositions'])(state)
  );

  if (hasOptimizeCurrentError) {
    return null;
  }

  return (
    <Container data-testkey="managed-portfolios">
      <Grid gap="2rem">
        <div>
          <h2>{translate('portfolioManager.managedPortfolio.distributionByInstrument')}</h2>
          <InstrumentDistribution
            instruments={instrumentWeights}
            compareTo={weightTypes.optimizedWeights}
            itemType="instrument"
            loading={loadingInitialItems || optimizingCurrent}
          />
        </div>

        <div>
          <h2>{translate('portfolioManager.managedPortfolio.distributionByAssetClass')}</h2>
          <AssetClassDistribution
            assetCategoryWeights={currentWeights?.assetCategoryWeights}
            loading={loadingInitialItems}
            compareTo={weightTypes.optimizedWeights}
          />
        </div>
      </Grid>
    </Container>
  );
};

const Container = styled.div`
  ${styles.fullWidth}

  h2 {
    text-align: center;
  }
`;
