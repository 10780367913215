import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';
import SignatoryListItem from './SignatoryListItem';
import styles from './InkSignatureConfirmItem.scss';
import { Spinner } from 'features/common/Spinner';
import { colors } from 'styles/colors';
import { Contract, Signatory, Signer, GetSignatureRequestsResponse } from 'types/ordersState';
import { getSignatureRequests } from 'features/contracts/contractsActions';
import { useAppDispatch } from 'core/hooks';
import { ATTACHMENT_TEMPLATE_ID } from 'features/contracts/contractsConstants';

const cx = classNames.bind(styles);

interface Props {
  contract: Contract;
  headerIcon: JSX.Element;
  signatories: Signatory[] | undefined;
}

export const InkSignatureConfirmItem = ({ contract, headerIcon, signatories }: Props) => {
  const dispatch = useAppDispatch();

  const POLL_TIMER = 1500;
  let timerId = 0;

  const [signers, setSigners] = useState<Signer[]>([]);

  useEffect(() => {
    // Make first 'get signature' request immediately after component renders
    getSignatureRequestsLocal(contract.shortId);
  }, []);

  useEffect(() => {
    // Whenever 'signers' change, try polling for signatures again
    const allSigned = signers.every((s) => s.hasSigned);

    // Send delayed poll request once ...
    if (!signers.length || !allSigned) {
      timerId = window.setTimeout(() => getSignatureRequestsLocal(contract.shortId), POLL_TIMER);
    }

    return () => {
      if (timerId) {
        // ... and clean up the timer
        window.clearTimeout(timerId);
      }
    };
  }, [signers]);

  const getSignatureRequestsLocal = (contractId: string) => {
    dispatch(getSignatureRequests(contractId))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .then((result: GetSignatureRequestsResponse[]) => {
        const request = result[0];
        if (request?.signers) {
          setSigners(request.signers);
        } else {
          setSigners([]);
        }
      })
      .catch(() => {
        // On error, queue new request so if backend wakes up story can continue
        setSigners([]);
      });
  };

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        {headerIcon}
        <span className={styles.label}>
          {contract.contractType === ATTACHMENT_TEMPLATE_ID
            ? `Liite: ${contract.descriptiveName}`
            : contract.contractName}
        </span>
      </div>
    );
  };

  const renderSignatories = () => {
    const signatoriesList = signatories?.map((item, index, items) => {
      const divider = index + 1 !== items.length ? <div className={styles.divider} /> : undefined;
      const signerInfo = _.find(signers, (s) => s.ssn === item.ssn);

      let link;
      const linkStyles = cx({
        button: true,
        signed: signerInfo?.hasSigned,
        unsigned: !signerInfo?.hasSigned,
      });

      if (!signerInfo?.signUrl) {
        link = <Spinner className={styles.spinner} color={colors.ptGreen} />;
      } else {
        const linkText = signerInfo.hasSigned ? 'Allekirjoitettu' : 'Allekirjoita';
        link = (
          <a
            href={signerInfo.signUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={linkStyles}
            data-testkey="add-signature-link"
          >
            <i className="icon icon-check" />
            {linkText}
          </a>
        );
      }

      return (
        <div key={index}>
          <div className={styles.row}>
            <SignatoryListItem signatory={item} isSelectable={false} />
            {link}
          </div>
          {divider}
        </div>
      );
    });

    return <div className={styles.signatories}>{signatoriesList}</div>;
  };

  return (
    <div className={styles.electronicSignatureConfirm}>
      <div className={'accordion-title'}>
        {renderTitle()}
        {renderSignatories()}
      </div>
    </div>
  );
};
