import React, { CSSProperties } from 'react';
import styles from './InfoText.scss';
import info from 'images/info.svg';

interface Props {
  text: string;
  style?: CSSProperties;
}

const InfoText = ({ text, style }: Props) => (
  <div style={style}>
    <img src={info} /> <span className={styles.info}>{text}</span>
  </div>
);

export default InfoText;
