import React from 'react';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { RiskLevel } from 'types/types';

interface Props {
  riskLevel: RiskLevel;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  selected: boolean;
  disabled: boolean;
}

export const RiskSelectionButton = ({ riskLevel, onClick, selected, disabled }: Props) => {
  return (
    <RiskButtonContainer
      selected={selected}
      disabled={disabled}
      value={riskLevel}
      onClick={onClick}
      type={'button'}
      data-testkey={`select-risk-button-${riskLevel}`}
    >
      <StyledRiskLevel>{riskLevel}</StyledRiskLevel>
    </RiskButtonContainer>
  );
};

interface StyledButtonProps {
  selected: boolean;
  disabled: boolean;
}

const RiskButtonContainer = styled.button<StyledButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin: 2px;
  border-radius: 50%;
  border: ${(props) => `2px dotted ${props.selected ? colors.primary : 'transparent'}`};
  ${(props) => props.selected && `background: ${colors.light_green}`};
  ${(props) => props.disabled && !props.selected && 'opacity: .4'};

  & > * {
    pointer-events: none;
  }
`;

const StyledRiskLevel = styled.div`
  text-align: center;
  font-weight: 700;
  padding: 1rem;
`;
