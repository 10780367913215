import React from 'react';
import { Map } from 'immutable';
import classNames from 'classnames/bind';
import translate from 'counterpart';
import styles from './Identity.scss';
import { IdentityListItem } from './IdentityListItem';
import ImageCapture from 'features/common/ImageCapture';
import { ModalDialog } from 'features/common/ModalDialog';
import { Spinner } from 'features/common/Spinner';
import { colors } from 'styles/colors';
import { ImmutableImage, Image, ImmutableImageList } from 'types/profileState';
import { IdentityMethod } from 'types/types';
import { Countries } from 'types/contractsState';
import { IMAGE, IMAGE_DATAURI } from 'constants/newCustomer';
import StyledButton from 'features/common/styledComponents/StyledButton';

const cx = classNames.bind(styles);

interface Props {
  uploadProfileImage: (image: ImmutableImage) => Promise<void>;
  onPreviewImage: (filepath: string) => void;
  identityMethods: IdentityMethod[];
  countries: Countries;
  images: ImmutableImageList;
  customerState: string;
  uploadingImage: boolean;
  deleteIdentityImage: (imageId: string) => void;
}

interface State {
  open: boolean;
  image: ImmutableImage;
}

export default class Identity extends React.Component<Props, State> {
  static defaultProps = {
    customerState: '',
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      image: Map() as ImmutableImage,
    };
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleOpen = () => {
    this.setState({ open: true, image: Map() as ImmutableImage });
  };

  handleImageDataChange = (option: { key: string; value: string }) => {
    let image: ImmutableImage = this.state.image.set(option.key, option.value) as ImmutableImage;

    if (option.key === 'identityMethod') {
      if (image.get(option.key) !== 'FinnishDriverLicence') {
        image = image.set('identityMethodDateOfIssue', undefined) as ImmutableImage;
      }

      if (image.get(option.key)?.startsWith('Foreign')) {
        image = image.set('identityMethodIssuerCountry', undefined) as ImmutableImage;
      } else {
        image = image.set('identityMethodIssuerCountry', 'FI') as ImmutableImage;
      }
    }

    this.setState({ image });
  };

  handleClearImage = () => {
    const image: ImmutableImage = this.state.image
      .set(IMAGE_DATAURI, undefined)
      .set(IMAGE, undefined) as ImmutableImage;

    this.setState({ image });
  };

  handleUpload = () => {
    this.props
      .uploadProfileImage(this.state.image)
      .then(() => {
        this.setState({ open: false, image: Map() as ImmutableImage });
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  previewImage = (image: Image) => {
    this.props.onPreviewImage(image.downloadPath);
  };

  isImageInfoValid = () => {
    if (!this.state.image.get('description')) {
      return false;
    }
    if (!this.state.image.get('image')) {
      return false;
    }
    if (!this.state.image.get('identityMethod')) {
      return false;
    }
    if (!this.state.image.get('identityMethodExpirationDate')) {
      return false;
    }
    if (!this.state.image.get('identityMethodIdentificationNumber')) {
      return false;
    }
    if (!this.state.image.get('identityMethodIssuer')) {
      return false;
    }
    if (
      this.state.image.get('identityMethod') === 'FinnishDriverLicence' &&
      !this.state.image.get('identityMethodDateOfIssue')
    ) {
      return false;
    }
    if (
      (this.state.image.get('identityMethod') ?? '').startsWith('Foreign') &&
      !this.state.image.get('identityMethodIssuerCountry')
    ) {
      return false;
    }

    return true;
  };

  showMissingImageNotification() {
    const { customerState, images } = this.props;
    if (customerState === 'Account' && images.count() === 0) {
      return true;
    }
    return false;
  }

  render() {
    const { identityMethods, countries, images, uploadingImage, deleteIdentityImage } = this.props;

    const dialogActions = [
      <StyledButton
        startIcon={uploadingImage ? <Spinner className={styles.spinner} color={colors.ptGreen} /> : null}
        variant="text"
        onClick={this.handleUpload}
        disabled={!this.isImageInfoValid() || uploadingImage}
        key={'customer.save'}
      >
        {uploadingImage ? '' : translate('customer.save')}
      </StyledButton>,
      <StyledButton variant="text" onClick={this.handleClose} disabled={uploadingImage} key={'customer.cancel'}>
        {translate('customer.cancel')}
      </StyledButton>,
    ];

    let components = images.toJS().map((image) => {
      return (
        <IdentityListItem
          onClick={this.previewImage}
          image={image}
          identityMethods={identityMethods}
          key={image.downloadPath}
          deleteIdentityImage={deleteIdentityImage}
        />
      );
    });

    const showImageMissing = this.showMissingImageNotification();

    if (showImageMissing) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      components = <div className="row align-center">{translate(`customer.missingImage`)}</div>;
    }

    const componentStyles = cx({
      identity: true,
      noImage: showImageMissing,
    });

    return (
      <section className={componentStyles}>
        <header>
          <div>{translate('customer.authentication')}</div>
          <i onClick={this.handleOpen} style={{ cursor: 'pointer' }} className="icon icon-kamera" />
        </header>
        <div>
          <ModalDialog
            open={this.state.open}
            actions={dialogActions}
            content={
              <ImageCapture
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                handler={this.handleImageDataChange}
                clearImageHandler={this.handleClearImage}
                identityMethods={identityMethods}
                countries={countries}
                horizontal={true}
                imageDataURI={this.state.image.get('imageDataURI')}
                selectedMethod={this.state.image.get('identityMethod')}
                identityMethodNumber={this.state.image.get('identityMethodIdentificationNumber')}
                identityMethodIssuer={this.state.image.get('identityMethodIssuer')}
                identityMethodIssuerCountry={this.state.image.get('identityMethodIssuerCountry')}
                identityExpirationDate={this.state.image.get('identityMethodExpirationDate')}
                identityMethodDateOfIssue={this.state.image.get('identityMethodDateOfIssue')}
                description={this.state.image.get('description')}
              />
            }
            close={this.handleClose}
          />
        </div>
        <ul>{components}</ul>
      </section>
    );
  }
}
