import { get } from 'lodash';
import moment from 'moment';
import PromiseStore from 'core/PromiseStore';
import {
  FUND,
  STRUCTURED_PRODUCT,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS,
  WARRANT,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  SHARE,
  ETF,
  CERTIFICATE,
  OPTION,
  BOND,
  SUBSCRIPTION_RIGHT,
} from 'constants/instrumentForms';
import { BUY, EXCHANGE, SELL } from 'constants/sides';
import { getCurrentQuantity } from 'core/portfolios';
import { getVisiblePositions, selectHasRestrictedPortfolioClass } from 'features/portfolio/portfolioSelectors';
import {
  selectCurrentInstrumentFormByOrderLineId,
  selectCurrentSideByOrderLineId,
  selectOrderLines,
} from 'features/orderLines/orderLinesSelectors';
import { CHANGE_PORTFOLIO_DIALOG, CHANGE_SIDE_DIALOG } from 'constants/orderDialog';
import { getEditorValuesBasedOnSide } from 'core/orders';
import { AppThunk, SelectValue } from 'types/types';
import { OrderSideType, ValidationErrorValue } from 'types/orderDialogState';
import {
  EditorValues,
  InstrumentOption,
  DialogPayload,
  OrderInstrument,
  InitEditorParameters,
  GetNominalValueResponse,
  PrivateEqSearchResponse,
  StructuredSearchResponse,
  WarrantOption,
  ChangePairsSearchResponse,
  SuitabilityResult,
  ChangePairResponse,
} from 'features/orderDialog/orderDialogTypes';
import {
  orderFund,
  orderStructuredProduct,
  orderWarrant,
  orderPrivateEquity,
  orderShare,
  orderBond,
} from 'features/orders/ordersActions';
import {
  OrderLineType,
  InstrumentDetail,
  SuitabilityError,
  OptionValue,
  FeeResponse,
  MinutesOfInvestmentServiceType,
} from 'types/ordersState';
import {
  getBillingAddress,
  getCapitalCallEmail,
  getChangePairs,
  getContractName,
  getFees,
  getInitializerTypes,
  getInstrumentData,
  getMoneySource,
  getNominalValueApiCall,
  getSuitability,
  getValidationErrors,
  getCustomerCategorisation,
} from 'features/orderDialog/orderDialogUtils';
import { clearError, setError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { SECURITIES_BROKERAGE_CONTRACT } from 'constants/contractNames';
import { getInitializerType, getReceivedFromClientMethod } from 'features/orderDialog/orderDialogReducer';
import { selectInstrumentPosition } from './orderDialogSelectors';
import { MINUTES_OF_INVESTMENT_SERVICE } from 'constants/orderFields';
import { REPRESENTATIVE } from 'constants/receiveInfoOptions';
import { PERSON } from 'constants/customerTypes';

const SEARCH_MIN_INPUT = 2;

// Action types

export const INIT_EDITOR = 'INIT_EDITOR';
export const SET_EDITOR_VALUE = 'SET_EDITOR_VALUE';
export const SET_EDITOR_VALUES = 'SET_EDITOR_VALUES';
export const SET_EDITOR_VALUE_BY_PATH = 'SET_EDITOR_VALUE_BY_PATH';
export const SET_BASIS_OF_ADVICE = 'SET_BASIS_OF_ADVICE';
export const SET_CHECK = 'SET_CHECK';
export const CHANGE_INSTRUMENT = 'CHANGE_INSTRUMENT';
export const CHANGE_INSTRUMENT_FORM = 'CHANGE_INSTRUMENT_FORM';
const CHANGE_PAIRS_REQUEST = 'CHANGE_PAIRS_REQUEST';
export const CHANGE_PAIRS_SUCCESS = 'CHANGE_PAIRS_SUCCESS';
const CHANGE_PAIRS_FAILURE = 'CHANGE_PAIRS_FAILURE';
export const DEFAULTFEES_REQUEST = 'DEFAULTFEES_REQUEST';
export const DEFAULTFEES_SUCCESS = 'DEFAULTFEES_SUCCESS';
export const DEFAULTFEES_FAILURE = 'DEFAULTFEES_FAILURE';
export const NOMINAL_VALUE_REQUEST = 'NOMINAL_VALUE_REQUEST';
export const NOMINAL_VALUE_SUCCESS = 'NOMINAL_VALUE_SUCCESS';
export const NOMINAL_VALUE_FAILURE = 'NOMINAL_VALUE_FAILURE';
export const INSTRUMENT_SEARCH_REQUEST = 'INSTRUMENT_SEARCH_REQUEST';
export const INSTRUMENT_SEARCH_SUCCESS = 'INSTRUMENT_SEARCH_SUCCESS';
export const INSTRUMENT_SEARCH_FAILURE = 'INSTRUMENT_SEARCH_FAILURE';
export const SHARE_SEARCH_REQUEST = 'SHARE_SEARCH_REQUEST';
export const SHARE_SEARCH_SUCCESS = 'SHARE_SEARCH_SUCCESS';
export const SHARE_SEARCH_FAILURE = 'SHARE_SEARCH_FAILURE';
export const STRUCTURED_SEARCH_REQUEST = 'STRUCTURED_SEARCH_REQUEST';
export const STRUCTURED_SEARCH_SUCCESS = 'STRUCTURED_SEARCH_SUCCESS';
export const STRUCTURED_SEARCH_FAILURE = 'STRUCTURED_SEARCH_FAILURE';
export const WARRANT_SEARCH_REQUEST = 'WARRANT_SEARCH_REQUEST';
export const WARRANT_SEARCH_SUCCESS = 'WARRANT_SEARCH_SUCCESS';
export const WARRANT_SEARCH_FAILURE = 'WARRANT_SEARCH_FAILURE';
export const PRIVATE_EQUITY_SEARCH_REQUEST = 'PRIVATE_EQUITY_SEARCH_REQUEST';
export const PRIVATE_EQUITY_SEARCH_SUCCESS = 'PRIVATE_EQUITY_SEARCH_SUCCESS';
export const PRIVATE_EQUITY_SEARCH_FAILURE = 'PRIVATE_EQUITY_SEARCH_FAILURE';
export const CHANGE_PAIRS_SEARCH_REQUEST = 'CHANGE_PAIRS_SEARCH_REQUEST';
export const CHANGE_PAIRS_SEARCH_SUCCESS = 'CHANGE_PAIRS_SEARCH_SUCCESS';
export const CHANGE_PAIRS_SEARCH_FAILURE = 'CHANGE_PAIRS_SEARCH_FAILURE';
export const VALIDATE_ORDER = 'VALIDATE_ORDER';
export const EDIT_ORDERLINE = 'EDIT_ORDERLINE';
export const SELECTED_SUITABILITY_REQUEST = 'SELECTED_SUITABILITY_REQUEST';
export const SELECTED_SUITABILITY_SUCCESS = 'SELECTED_SUITABILITY_SUCCESS';
export const SELECTED_SUITABILITY_FAILURE = 'SELECTED_SUITABILITY_FAILURE';
export const SET_INSTRUMENT_OPTIONS = 'SET_INSTRUMENT_OPTIONS';
export const SET_SUITABILITY_ERRORS = 'SET_SUITABILITY_ERRORS';
export const CHANGE_PORTFOLIO = 'CHANGE_PORTFOLIO';
export const HIDE_CONFIRMATION_DIALOG = 'HIDE_CONFIRMATION_DIALOG';
export const SHOW_CONFIRMATION_DIALOG = 'SHOW_CONFIRMATION_DIALOG';
export const UNDO_CHANGE_SIDE = 'UNDO_CHANGE_SIDE';
export const SET_IS_PREVIOUS_ORDER_DRAFT_SUITABLE = 'SET_IS_PREVIOUS_ORDER_DRAFT_SUITABLE';
export const SET_IS_EDITING_EXISTING_LINE = 'SET_IS_EDITING_EXISTING_LINE';
export const COPY_MOIS_TO_CURRENT = 'COPY_MOIS_TO_CURRENT';

// Sync actions

const initEditorAction = (payload: DialogPayload) => {
  return <const>{
    type: INIT_EDITOR,
    payload,
  };
};

const editOrderLineAction = (orderLine: OrderLineType) => {
  return <const>{
    type: EDIT_ORDERLINE,
    orderLine,
  };
};

const validateOrderAction = (errors: ValidationErrorValue[]) => {
  return <const>{
    type: VALIDATE_ORDER,
    errors,
  };
};

export const changeInstrumentForm = (params: SelectValue) => {
  return <const>{
    type: CHANGE_INSTRUMENT_FORM,
    params,
  };
};

export const setInstrumentOptions = (options: InstrumentOption[]) => {
  return <const>{
    type: SET_INSTRUMENT_OPTIONS,
    options,
  };
};

export const setSuitabilityErrors = (errors: SuitabilityError[] | undefined) => {
  return <const>{
    type: SET_SUITABILITY_ERRORS,
    errors,
  };
};

export const setIsPreviousOrderDraftSuitable = (isPreviousSuitable: boolean) => {
  return <const>{
    type: SET_IS_PREVIOUS_ORDER_DRAFT_SUITABLE,
    isPreviousOrderDraftSuitable: isPreviousSuitable,
  };
};

const changePortfolioAction = (editor: OrderLineType, orderInitializerTypes: OptionValue[]) => {
  return <const>{
    type: CHANGE_PORTFOLIO,
    editor,
    orderInitializerTypes,
  };
};

export const undoChangeSide = () => {
  return <const>{
    type: UNDO_CHANGE_SIDE,
  };
};

export const hideConfirmationDialog = () => {
  return <const>{
    type: HIDE_CONFIRMATION_DIALOG,
  };
};

export const showConfirmationDialog = (dialogType: string) => {
  return <const>{
    type: SHOW_CONFIRMATION_DIALOG,
    dialogType,
  };
};

export const setIsEditingExistingLine = (isEditingExistingLine: boolean) => {
  return <const>{
    type: SET_IS_EDITING_EXISTING_LINE,
    isEditingExistingLine,
  };
};

const getNominalValueRequest = () => {
  return <const>{
    type: NOMINAL_VALUE_REQUEST,
  };
};

const getNominalValueSuccess = (result: GetNominalValueResponse) => {
  return <const>{
    type: NOMINAL_VALUE_SUCCESS,
    result,
  };
};

const getNominalValueFailure = () => {
  return <const>{
    type: NOMINAL_VALUE_FAILURE,
  };
};

const setEditorValueAction = (key: string, value: string | number | boolean | undefined) => {
  return <const>{
    type: SET_EDITOR_VALUE,
    key,
    value,
  };
};

const setEditorValuesAction = (values: EditorValues) => {
  return <const>{
    type: SET_EDITOR_VALUES,
    values,
  };
};

const setEditorValueByPropertyPathAction = (key: string, value: string | number | boolean | undefined) => {
  return <const>{
    type: SET_EDITOR_VALUE_BY_PATH,
    key,
    value,
  };
};

const setBasisOfAdviceValueAction = (value: string) => {
  return <const>{
    type: SET_BASIS_OF_ADVICE,
    value,
  };
};

const setCheck = (property: string, value: boolean) => {
  return <const>{
    type: SET_CHECK,
    property,
    value,
  };
};

const changeInstrumentAction = (
  params: OrderInstrument | null,
  currentQuantity: number | undefined,
  portfolioCurrency: string,
  marketValueNet: number | undefined
) => {
  return <const>{
    type: CHANGE_INSTRUMENT,
    params,
    currentQuantity,
    portfolioCurrency,
    marketValueNet,
  };
};

const changePairsRequest = () => {
  return <const>{
    type: CHANGE_PAIRS_REQUEST,
  };
};

const changePairsSuccess = (result: ChangePairResponse[]) => {
  return <const>{
    type: CHANGE_PAIRS_SUCCESS,
    result,
  };
};

const changePairsFailure = () => {
  return <const>{
    type: CHANGE_PAIRS_FAILURE,
  };
};

const defaultFeesRequest = () => {
  return <const>{
    type: DEFAULTFEES_REQUEST,
  };
};

const defaultFeesSuccess = (result: FeeResponse) => {
  return <const>{
    type: DEFAULTFEES_SUCCESS,
    result,
  };
};

const defaultFeesFailure = () => {
  return <const>{
    type: DEFAULTFEES_FAILURE,
  };
};

const privateEquitySearchRequest = () => {
  return <const>{
    type: PRIVATE_EQUITY_SEARCH_REQUEST,
  };
};

const privateEquitySearchSuccess = (results: PrivateEqSearchResponse[]) => {
  return <const>{
    type: PRIVATE_EQUITY_SEARCH_SUCCESS,
    results,
  };
};

const privateEquitySearchFailure = (error: Error) => {
  return <const>{
    type: PRIVATE_EQUITY_SEARCH_FAILURE,
    error,
  };
};

const structuredSearchRequest = () => {
  return <const>{
    type: STRUCTURED_SEARCH_REQUEST,
  };
};

const structuredSearchSuccess = (results: StructuredSearchResponse[]) => {
  return <const>{
    type: STRUCTURED_SEARCH_SUCCESS,
    results,
  };
};

const structuredSearchFailure = (error: Error) => {
  return <const>{
    type: STRUCTURED_SEARCH_FAILURE,
    error,
  };
};

const warrantSearchRequest = () => {
  return <const>{
    type: WARRANT_SEARCH_REQUEST,
  };
};

const warrantSearchSuccess = (results: WarrantOption[]) => {
  return <const>{
    type: WARRANT_SEARCH_SUCCESS,
    results,
  };
};

const warrantSearchFailure = (error: Error) => {
  return <const>{
    type: WARRANT_SEARCH_FAILURE,
    error,
  };
};

const instrumentSearchRequest = () => {
  return <const>{
    type: INSTRUMENT_SEARCH_REQUEST,
  };
};

const instrumentSearchSuccess = (results: InstrumentDetail[]) => {
  return <const>{
    type: INSTRUMENT_SEARCH_SUCCESS,
    results,
  };
};

const instrumentSearchFailure = (error: Error) => {
  return <const>{
    type: INSTRUMENT_SEARCH_FAILURE,
    error,
  };
};

const shareSearchRequest = () => {
  return <const>{
    type: SHARE_SEARCH_REQUEST,
  };
};

const shareSearchSuccess = (results: InstrumentDetail[]) => {
  return <const>{
    type: SHARE_SEARCH_SUCCESS,
    results,
  };
};

const shareSearchFailure = (error: Error) => {
  return <const>{
    type: SHARE_SEARCH_FAILURE,
    error,
  };
};

const changePairsSearchRequest = () => {
  return <const>{
    type: CHANGE_PAIRS_SEARCH_REQUEST,
  };
};

const changePairsSearchSuccess = (results: ChangePairsSearchResponse[]) => {
  return <const>{
    type: CHANGE_PAIRS_SEARCH_SUCCESS,
    results,
  };
};

const changePairsSearchFailure = (error: Error) => {
  return <const>{
    type: CHANGE_PAIRS_SEARCH_FAILURE,
    error,
  };
};

const selectedSuitabilityRequest = () => {
  return <const>{
    type: SELECTED_SUITABILITY_REQUEST,
  };
};

const selectedSuitabilitySuccess = (result: SuitabilityResult[]) => {
  return <const>{
    type: SELECTED_SUITABILITY_SUCCESS,
    result,
  };
};

const selectedSuitabilityFailure = () => {
  return <const>{
    type: SELECTED_SUITABILITY_FAILURE,
  };
};

const copyMOISToCurrentAction = (mois: MinutesOfInvestmentServiceType) => {
  return <const>{
    type: COPY_MOIS_TO_CURRENT,
    mois,
  };
};

export type OrderDialogAction = ReturnType<
  | typeof setError
  | typeof initEditorAction
  | typeof editOrderLineAction
  | typeof validateOrderAction
  | typeof changeInstrumentForm
  | typeof setInstrumentOptions
  | typeof setSuitabilityErrors
  | typeof setIsPreviousOrderDraftSuitable
  | typeof changePortfolioAction
  | typeof undoChangeSide
  | typeof hideConfirmationDialog
  | typeof showConfirmationDialog
  | typeof setIsEditingExistingLine
  | typeof getNominalValueRequest
  | typeof getNominalValueSuccess
  | typeof getNominalValueFailure
  | typeof setEditorValueAction
  | typeof setEditorValuesAction
  | typeof changeInstrumentAction
  | typeof changePairsRequest
  | typeof changePairsSuccess
  | typeof changePairsFailure
  | typeof defaultFeesRequest
  | typeof defaultFeesSuccess
  | typeof defaultFeesFailure
  | typeof privateEquitySearchRequest
  | typeof privateEquitySearchSuccess
  | typeof privateEquitySearchFailure
  | typeof structuredSearchRequest
  | typeof structuredSearchSuccess
  | typeof structuredSearchFailure
  | typeof warrantSearchRequest
  | typeof warrantSearchSuccess
  | typeof warrantSearchFailure
  | typeof instrumentSearchRequest
  | typeof instrumentSearchSuccess
  | typeof instrumentSearchFailure
  | typeof shareSearchRequest
  | typeof shareSearchSuccess
  | typeof shareSearchFailure
  | typeof changePairsSearchRequest
  | typeof changePairsSearchSuccess
  | typeof changePairsSearchFailure
  | typeof selectedSuitabilityRequest
  | typeof selectedSuitabilitySuccess
  | typeof selectedSuitabilityFailure
  | typeof setEditorValueByPropertyPathAction
  | typeof setBasisOfAdviceValueAction
  | typeof copyMOISToCurrentAction
  | typeof setCheck
>;

// Async actions

export const initEditor = ({
  portfolioId,
  isBuyingNew,
  isEditingExistingLine,
  initialValues,
}: InitEditorParameters): AppThunk => {
  return async (dispatch, getState) => {
    const currentPortfolio = getState().portfolio.portfolioDetailsById[portfolioId];
    const accountType = get(currentPortfolio, ['defaultAccount', 'accountType']);
    const contractType = get(currentPortfolio, ['contractType']);

    let minutesOfInvestmentService: MinutesOfInvestmentServiceType = {
      date: moment().format('YYYY-MM-DDTHH:mm'),
      location: '',
      participants: '',
      convener: '',
      info: '',
    };

    if (isEditingExistingLine && initialValues && initialValues.minutesOfInvestmentService) {
      minutesOfInvestmentService = initialValues?.minutesOfInvestmentService;
    }

    const extendedInitialValues = Object.assign(
      {
        portfolioId,
        capitalCallEmail: getCapitalCallEmail(getState()),
        receiverEmail: getBillingAddress(getState()),
        moneySource: getMoneySource(accountType, currentPortfolio.contractName),
        minutesOfInvestmentService,
        customerCategorisation: getCustomerCategorisation(getState()),
      },
      initialValues
    );

    const orderLines = selectOrderLines(getState());
    const orderLineId = initialValues?._id || orderLines.getIn([-1, '_id']);
    const orderBasis = initialValues?.orderBasis || orderLines.getIn([-1, 'orderBasis']);

    const payload: DialogPayload = {
      initialValues: extendedInitialValues,
      isBuyingNew,
      contract: getState().orders.ordersByPortfolioId[portfolioId]?.contract,
      contractName: getContractName(getState(), portfolioId),
      isEditingExistingLine,
      currentInstrumentForm:
        initialValues?.financialInstrumentForm || selectCurrentInstrumentFormByOrderLineId(getState(), orderLineId),
      currentSide: orderLineId ? selectCurrentSideByOrderLineId(getState(), orderLineId) : BUY,
      onlySellAllowed: selectHasRestrictedPortfolioClass(getState()),
      contractType: contractType ?? '',
      orderBasis,
    };

    /// FIX: currentInstrumentForm = undefined

    dispatch(initEditorAction(payload));
    dispatch(validateOrder());

    const editor = getState().orderDialog.editor.toJS();
    const customerId = getState().profile.customer.get('id');
    const token = getState().oidc.user.access_token;
    const customerType = getState().profile.customer.get('customerType');

    if (editor.financialInstrumentId) {
      if (editor.financialInstrumentForm && editor.financialInstrumentForm === FUND && !isEditingExistingLine) {
        await dispatch(getTransactionFees(customerId, portfolioId, editor.financialInstrumentId, editor.side));
      }

      if (editor.financialInstrumentForm && editor.financialInstrumentForm === STRUCTURED_PRODUCT) {
        dispatch(getNominalValueRequest());
        try {
          const result = await getNominalValueApiCall(editor.financialInstrumentId, token);
          dispatch(getNominalValueSuccess(result));
          dispatch(validateOrder());
        } catch (error) {
          dispatch(getNominalValueFailure());
        }
      }
    }

    if (!isBuyingNew && editor.financialInstrumentForm && editor.financialInstrumentForm === FUND) {
      dispatch(fetchChangePairs(editor.financialInstrumentId, customerId, portfolioId));
      dispatch(
        searchShare(
          editor.financialInstrumentForm,
          editor.financialInstrumentId,
          customerId,
          portfolioId,
          editor.side,
          orderBasis,
          editor.orderInitializerType,
          customerType
        )
      );
    }
  };
};

export const editOrderLine = (orderLineId: number): AppThunk => {
  return (dispatch, getState) => {
    const orderLine = getState()
      .orderLines.orderLines.find((orderLine) => orderLine?.get('_id') === orderLineId)
      ?.toJS();

    if (orderLine) {
      // Set values in state.orderDialog.editor
      dispatch(
        initEditor({
          portfolioId: orderLine.portfolioId,
          isBuyingNew: orderLine.side !== EXCHANGE,
          isEditingExistingLine: true,
          initialValues: orderLine,
        })
      );

      // Set values in state.orderDialog.instrumentOptions and state.orderDialog.changePairOptions
      dispatch(editOrderLineAction(orderLine));
    }
  };
};

export const setEditorValue = (key: string, value: string | number | boolean | undefined): AppThunk => {
  return (dispatch) => {
    dispatch(setEditorValueAction(key, value));
    dispatch(validateOrder());
  };
};

export const setEditorValues = (values: EditorValues): AppThunk => {
  return (dispatch) => {
    dispatch(setEditorValuesAction(values));
    dispatch(validateOrder());
  };
};

export const setEditorValueByPropertyPath = (key: string, value: string | number | boolean | undefined): AppThunk => {
  return (dispatch) => {
    dispatch(setEditorValueByPropertyPathAction(key, value));
    dispatch(validateOrder());
  };
};

export const setBasisOfAdviceValue = (value: string): AppThunk => {
  return (dispatch) => {
    dispatch(setBasisOfAdviceValueAction(value));
  };
};

export const setIsSuitabilityAssessmentToDateValue = (value: boolean): AppThunk => {
  return (dispatch) => {
    dispatch(setCheck('suitabilityAssessmentToDate', value));
  };
};

export const changeInstrument = (params: OrderInstrument | null): AppThunk => {
  return (dispatch, getState) => {
    const currentQuantity = params
      ? getCurrentQuantity(getVisiblePositions(getState()), params.financialInstrumentId)
      : undefined;

    const portfolioId = getState().orderDialog.editor.get('portfolioId');

    const portfolioCurrency = get(
      getState(),
      ['portfolio', 'portfolioDetailsById', portfolioId, 'withdrawalCurrency'],
      ''
    );

    const marketValueNet = params?.financialInstrumentId
      ? selectInstrumentPosition(getState(), params?.financialInstrumentId, portfolioId)?.marketValueNet.baseCurrency
          .value
      : undefined;

    dispatch(changeInstrumentAction(params, currentQuantity, portfolioCurrency, marketValueNet));
    dispatch(validateOrder());
  };
};

export const fetchChangePairs = (instrumentId: string, clientId: string, portfolioId: string): AppThunk => {
  return async (dispatch, getState) => {
    const token = getState().oidc.user.access_token;

    dispatch(changePairsRequest());
    try {
      const result = await getChangePairs(instrumentId, clientId, portfolioId, token);
      dispatch(changePairsSuccess(result));
    } catch (error) {
      dispatch(changePairsFailure());
    }
  };
};

export const getTransactionFees = (
  customerId: string,
  portfolioId: string,
  financialInstrumentId: string,
  side: OrderSideType
): AppThunk<Promise<FeeResponse>> => {
  return async (dispatch, getState): Promise<FeeResponse> => {
    const token = getState().oidc.user.access_token;

    dispatch(defaultFeesRequest());

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return getFees(customerId, portfolioId, financialInstrumentId, side, token)
      .then((result) => {
        dispatch(defaultFeesSuccess(result));
        dispatch(validateOrder());
        return result;
      })
      .catch(() => {
        dispatch(defaultFeesFailure());
      });
  };
};

export const searchPrivateEquity = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis: string,
  orderInitializerType: string,
  customerType: string,
  initializedFromBuySell?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }

    dispatch(privateEquitySearchRequest());

    let apiUrl = `api/v1/orders/privateequity/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
    apiUrl =
      orderInitializerType && customerType
        ? `${apiUrl}&orderInitializerType=${orderInitializerType}&customerType=${customerType}`
        : apiUrl;
    apiUrl = initializedFromBuySell ? `${apiUrl}&initializedFromBuySell=true` : apiUrl;

    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as PrivateEqSearchResponse[];
      dispatch(privateEquitySearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(privateEquitySearchFailure(error));
    }
  };
};

export const searchStructuredProduct = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis: string,
  orderInitializerType: string,
  customerType: string,
  initializedFromBuySell?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }

    dispatch(structuredSearchRequest());

    let apiUrl = `api/v1/orders/structured/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
    apiUrl =
      orderInitializerType && customerType
        ? `${apiUrl}&orderInitializerType=${orderInitializerType}&customerType=${customerType}`
        : apiUrl;
    apiUrl = initializedFromBuySell ? `${apiUrl}&initializedFromBuySell=true` : apiUrl;

    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as StructuredSearchResponse[];
      dispatch(structuredSearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(structuredSearchFailure(error));
    }
  };
};

export const searchWarrant = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis: string,
  orderInitializerType: string,
  customerType: string,
  initializedFromBuySell?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }

    dispatch(warrantSearchRequest());

    let apiUrl = `api/v1/orders/structured/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
    apiUrl =
      orderInitializerType && customerType
        ? `${apiUrl}&orderInitializerType=${orderInitializerType}&customerType=${customerType}`
        : apiUrl;
    apiUrl = initializedFromBuySell ? `${apiUrl}&initializedFromBuySell=true` : apiUrl;

    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as WarrantOption[];
      dispatch(warrantSearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(warrantSearchFailure(error));
    }
  };
};

export const searchInstrument = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis: string,
  orderInitializerType: string,
  customerType: string,
  searchType?: string,
  cancelPrevious = true,
  initializedFromBuySell?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    if (cancelPrevious) {
      PromiseStore.search.cancel();
    }

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }
    dispatch(instrumentSearchRequest());
    dispatch(clearError(errorKeys.getInstrumentDetails));
    const searchFromSourceKey = 'sourceInstrument';
    const searchTypeSourceInstrument = searchType && side === EXCHANGE ? searchFromSourceKey : null;

    let apiUrl = `api/v1/orders/instruments/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
    apiUrl = searchType && searchTypeSourceInstrument ? `${apiUrl}&searchType=${searchTypeSourceInstrument}` : apiUrl;
    apiUrl =
      orderInitializerType && customerType
        ? `${apiUrl}&orderInitializerType=${orderInitializerType}&customerType=${customerType}`
        : apiUrl;
    apiUrl = initializedFromBuySell ? `${apiUrl}&initializedFromBuySell=true` : apiUrl;

    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as InstrumentDetail[];
      dispatch(instrumentSearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(instrumentSearchFailure(error));
      dispatch(setError({ context: errorKeys.getInstrumentDetails }));
    }
  };
};

export const searchShare = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis: string,
  orderInitializerType: string,
  customerType: string,
  initializedFromBuySell?: boolean
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }

    dispatch(shareSearchRequest());

    let apiUrl = `api/v1/orders/instruments/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;
    apiUrl =
      orderInitializerType && customerType
        ? `${apiUrl}&orderInitializerType=${orderInitializerType}&customerType=${customerType}`
        : apiUrl;
    apiUrl = initializedFromBuySell ? `${apiUrl}&initializedFromBuySell=true` : apiUrl;
    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as InstrumentDetail[];
      dispatch(shareSearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(shareSearchFailure(error));
    }
  };
};

export const searchChangePairs = (
  type: string,
  term: string,
  clientId: string,
  portfolioId: string,
  side: OrderSideType,
  orderBasis?: string
): AppThunk => {
  return async (dispatch, getState) => {
    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    if (term.length < SEARCH_MIN_INPUT) {
      return;
    }

    dispatch(changePairsSearchRequest());

    let apiUrl = `api/v1/orders/instruments/search?type=${type}&searchTerm=${encodeURIComponent(
      term
    )}&userId=${clientId}&portfolioId=${portfolioId}&side=${side}`;
    apiUrl = orderBasis ? `${apiUrl}&orderBasis=${orderBasis}` : apiUrl;

    PromiseStore.search = PromiseStore.createCancellableRequest(apiUrl, getState().oidc.user.access_token);

    try {
      const results = (await PromiseStore.search) as ChangePairsSearchResponse[];
      dispatch(changePairsSearchSuccess(results));
      return results;
    } catch (error) {
      dispatch(changePairsSearchFailure(error));
    }
  };
};

export const getSuitabilityOfSelectedInstrument = (
  customerId: string,
  portfolioId: string,
  financialInstrumentId: string,
  side: OrderSideType,
  orderInitializerType: string,
  customerType: string,
  orderBasis?: string
): AppThunk => {
  return async (dispatch, getState) => {
    const token = getState().oidc.user.access_token;
    dispatch(selectedSuitabilityRequest());

    if (orderInitializerType !== REPRESENTATIVE || customerType !== PERSON) {
      try {
        const result = await getSuitability(customerId, portfolioId, financialInstrumentId, side, token, orderBasis);
        dispatch(selectedSuitabilitySuccess(result));
      } catch (error) {
        dispatch(selectedSuitabilityFailure());
      }
    }
  };
};

export const changePortfolio = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const orderDialogState = getState().orderDialog;
    const contractName = getContractName(getState(), portfolioId);
    const onlySellAllowed = selectHasRestrictedPortfolioClass(getState());
    const orderInitializerTypes = getInitializerTypes(contractName);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const editor: OrderLineType = {
      portfolioId,
      orderInitializerType: getInitializerType(contractName),
      receivedFromClientMethod: getReceivedFromClientMethod(contractName),
      tradeAll: contractName === SECURITIES_BROKERAGE_CONTRACT ? false : orderDialogState.editor.get('tradeAll'),
      side: onlySellAllowed ? SELL : orderDialogState.editor.get('side'),
      ...getInstrumentData(orderDialogState.editor.get('instrumentForm') as string, contractName),
      orderBasis: '',
    };

    dispatch(changePortfolioAction(editor, orderInitializerTypes));

    const {
      financialInstrumentId,
      financialInstrumentName,
      financialInstrumentForm,
      instrumentCurrency,
      side,
      orderBasis,
    } = getState().orderDialog.editor.toJS();
    const instrumentGroups = getState().orderDialog.instrumentOptions?.find(
      (e: InstrumentOption) => e.financialInstrumentId === financialInstrumentId
    )?.instrumentGroups;
    const instrumentOptions: InstrumentOption[] = [];
    const customerType = getState().profile.customer.get('customerType');
    if (financialInstrumentId) {
      dispatch(
        getSuitabilityOfSelectedInstrument(
          getState().profile.customer.get('id'),
          portfolioId,
          financialInstrumentId,
          side,
          editor.orderInitializerType,
          customerType,
          orderBasis
        )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
      ).then(
        () =>
          getState().orderDialog.suitabilityErrors.length !== 0 &&
          dispatch(showConfirmationDialog(CHANGE_PORTFOLIO_DIALOG))
      );
      instrumentOptions.push({
        financialInstrumentId,
        instrumentGroups,
        value: financialInstrumentId,
        label: financialInstrumentName,
        financialInstrumentForm,
        currency: instrumentCurrency,
      });
    }
    dispatch(setInstrumentOptions(instrumentOptions));
    dispatch(validateOrder());
  };
};

export const handleChangeSideWhenInstrumentChosen = (
  side: OrderSideType,
  assignment: OrderLineType,
  tradeAll: boolean,
  contractName: string,
  contractType: string
): AppThunk => {
  return (dispatch, getState) => {
    const editor = getState().orderDialog.editor.toJS();
    const { financialInstrumentId, portfolioId, orderBasis } = editor;
    const customerType = getState().profile.customer.get('customerType');
    dispatch(
      getSuitabilityOfSelectedInstrument(
        getState().profile.customer.get('id'),
        portfolioId,
        financialInstrumentId,
        side,
        editor.orderInitializerType,
        customerType,
        orderBasis
      )
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
    ).then(() => {
      const suitabilityErrors = getState().orderDialog.suitabilityErrors;
      if (suitabilityErrors.length !== 0) {
        if (suitabilityErrors.length === 1 && suitabilityErrors[0].ruleId === 'AppropriatenessRule') {
          const editorValues = getEditorValuesBasedOnSide(assignment, side, tradeAll, contractName, contractType);
          dispatch(changeSide(editorValues));
        } else {
          dispatch(showConfirmationDialog(CHANGE_SIDE_DIALOG));
        }
      } else {
        const editorValues = getEditorValuesBasedOnSide(assignment, side, tradeAll, contractName, contractType);
        dispatch(changeSide(editorValues));
      }
      const instrumentOptions: InstrumentOption[] = [];
      const { financialInstrumentName, financialInstrumentForm, instrumentCurrency } =
        getState().orderDialog.editor.toJS();
      const instrumentGroups = getState().orderDialog.instrumentOptions?.find(
        (e: InstrumentOption) => e.financialInstrumentId === financialInstrumentId
      )?.instrumentGroups;

      instrumentOptions.push({
        financialInstrumentId,
        instrumentGroups,
        value: financialInstrumentId,
        label: financialInstrumentName,
        financialInstrumentForm,
        currency: instrumentCurrency,
      });
      dispatch(setInstrumentOptions(instrumentOptions));
    });
  };
};

export const changeSide = (newEditorValues: EditorValues): AppThunk => {
  return async (dispatch, getState) => {
    const { financialInstrumentId, financialInstrumentForm, side, portfolioId } = getState().orderDialog.editor.toJS();
    dispatch(setEditorValues(newEditorValues));
    if (financialInstrumentForm === FUND) {
      await dispatch(
        getTransactionFees(getState().profile.customer.get('id'), portfolioId, financialInstrumentId, side)
      );

      const instrumentOptions: InstrumentOption[] = [];
      const { financialInstrumentName, financialInstrumentForm, instrumentCurrency } =
        getState().orderDialog.editor.toJS();
      const instrumentGroups = getState().orderDialog.instrumentOptions?.find(
        (e: InstrumentOption) => e.financialInstrumentId === financialInstrumentId
      )?.instrumentGroups;

      instrumentOptions.push({
        financialInstrumentId,
        instrumentGroups,
        value: financialInstrumentId,
        label: financialInstrumentName,
        financialInstrumentForm,
        currency: instrumentCurrency,
      });
      dispatch(setInstrumentOptions(instrumentOptions));
    }
  };
};

export const copyMOISToCurrent = (mois: MinutesOfInvestmentServiceType): AppThunk => {
  return (dispatch) => {
    dispatch(copyMOISToCurrentAction(mois));
    dispatch(validateOrder());
  };
};

export const copyLastMOISToCurrent = (): AppThunk => {
  return (dispatch, getState) => {
    const previousAssignment = getState().orderLines.orderLines.get(getState().orderLines.orderLines.size - 1);
    if (!previousAssignment) {
      return;
    }
    const previousAssigmentMois = previousAssignment.get(MINUTES_OF_INVESTMENT_SERVICE);
    if (!previousAssigmentMois) {
      return;
    }
    dispatch(copyMOISToCurrentAction(previousAssigmentMois));
    dispatch(validateOrder());
  };
};

export const createOrderLine =
  (assignment: OrderLineType): AppThunk =>
  (dispatch, getState) => {
    const state = getState();

    const ordersByPortfolioId = state.orders.ordersByPortfolioId;
    if (Object.keys(ordersByPortfolioId).length === 0) {
      return;
    }
    switch (assignment.financialInstrumentForm) {
      case FUND:
        dispatch(orderFund(state, assignment));
        break;
      case STRUCTURED_PRODUCT:
        dispatch(orderStructuredProduct(state, assignment));
        break;
      case WARRANT:
        dispatch(orderWarrant(state, assignment));
        break;
      case PRIVATE_EQUITY:
      case PRIVATE_EQUITY_PROFIT_SHARING_LOAN:
      case CO_INVESTMENTS:
      case CO_INVESTMENTS_PROFIT_SHARING_LOAN:
        dispatch(orderPrivateEquity(state, assignment));
        break;
      case SHARE:
      case ETF:
      case CERTIFICATE:
      case SUBSCRIPTION_RIGHT:
      case OPTION:
        dispatch(orderShare(state, assignment));
        break;
      case BOND:
        dispatch(orderBond(state, assignment));
        break;
    }
  };

const validateOrder = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const errors = getValidationErrors(state);
    dispatch(validateOrderAction(errors));
  };
};
