import React, { ChangeEvent } from 'react';
import styles from './Date.scss';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  label: string;
  value: string;
  type: 'date' | 'datetime-local';
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  tabIndex: number;
  max?: string | undefined;
  min?: string;
  error: string;
}

const Date = ({ label, value, type, onChange, tabIndex, max, error, min }: Props) => {
  return (
    <label>
      <div className={styles.label}>{label}</div>
      <StyledTextField
        fullWidth={true}
        type={type}
        inputProps={{
          display: 'block',
          max: { max },
          min: { min },
        }}
        value={value}
        tabIndex={tabIndex}
        name={type}
        helperText={error}
        onChange={onChange}
        variant="standard"
        sx={[
          {
            '& .Mui-focused': {
              '&::after': {
                left: 0,
              },
            },
            '& .MuiInputBase-root': {
              paddingLeft: 0,
              '&::before': {
                left: 0,
              },
            },
            '& .MuiInputBase-input': {
              marginTop: '2px',
            },
            '& .MuiInput-root': {
              '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.2)',
              },
            },
          },
        ]}
      />
    </label>
  );
};

Date.defaultProps = {
  tabIndex: '0',
  error: undefined,
};

export default Date;
