export default {
  finished: 'Valmis',
  opening_service: 'Avaus käynnissä',
  rejected: 'Sopimus hylätty',
  review: 'Vaatii lisäselvityksiä',
  reviewed: 'Hälytykset käsitelty',
  sent_for_manual_signing: 'Odottaa manuaalista allekirjoitusta',
  sent_for_banker_approval: 'Pankkiiri hyväksyy',
  sent_for_signing: 'Odottaa pankkitunnuksilla allekirjoitusta',
  sent_for_ink_signing: 'Odottaa näytölle allekirjoitusta',
  signed: 'Allekirjoitettu',
  signing_handling_failed: 'Allekirjoituksen tarkistus epäonnistunut',
};
