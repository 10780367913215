export const WEB_SERVICE_CONTRACT = 'verkkopalvelu';
export const WEB_SERVICE_CONTRACT_PROXY = 'verkkopalvelu_valtuutettu';
export const AUTHORIZATION_CONTRACT = 'tiedotHenk';
export const AUTHORIZATION_CONTRACT_COMPANY = 'tiedotYhteiso';
export const BASIC_INFO_PERSON = 'yksityishenkilonPerustiedot';
export const BASIC_INFO_COMPANY = 'yhteisonPerustiedot';
export const KYC_PERSON = 'yksityishenkilonKyc';
export const KYC_COMPANY = 'yhteisonKyc';
export const BASIC_INFO_INSTITUTION = 'instituutionPerustiedot';
export const EMAIL_REPORTS = 'emailreports';
export const EMAIL_REPORTS_COMPANY = 'companyemailreports';
export const DISCRETIONARY_CONTRACT_PERSON = 'yksTvsopimus';
export const CONSULTATIVE_CONTRACT_PERSON = 'yksKonssopimus';
export const DISCRETIONARY_CONTRACT_COMMUNITY = 'yhteisoTvsopimus';
export const CONSULTATIVE_CONTRACT_COMMUNITY = 'yhteisoKonssopimus';
export const KYC_INFO_PERSON = 'yksityishenkilonKyc';
export const KYC_INFO_COMPANY = 'yhteisonKyc';
