import React from 'react';
import translate from 'counterpart';
import Immutable, { Map } from 'immutable';
import PropTypes from 'prop-types';
import { isWebServiceContract } from 'constants/contractGroups';
import { SENT_FOR_INK_SIGNING, SENT_FOR_SIGNING } from 'constants/contractStates';
import { DOCUMENT, DOCUMENT_DATAURI } from 'constants/document';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';
import Warning from 'features/common/Warning';
import { getBisnode } from 'features/contracts/contractsActions';
import spinner from 'hocs/spinner';
import { Contract, Signatory } from 'types/ordersState';
import { ImmutableDocument, ProfileState } from 'types/profileState';
import { BisnodeInfo } from './BisnodeInfo';
import { ContractPreviewItem } from './ContractPreviewItem';
import { Locked } from './Locked';
import { NewSignatoryDialog } from './NewSignatoryDialog';
import styles from './Preview.scss';
import SignatoryDetailsDialog from './SignatoryDetailsDialog';
import SubNav from './SubNav';
import { PdfAttachment } from 'features/contracts/contracts.types';
import AddAttachment from 'features/contracts/components/attachment/AddAttachment';

interface Props {
  uploadAttachmentDocument: (document: File, documentDescriptiveName: string) => Promise<void>;
  onPreviewDocument: (filepath: string) => void;
  document: ImmutableDocument;
  uploadingDocument: boolean;
  deleteDocument: (id: string) => void;
  selectSignatureMethod: (contractId: string, method: string) => void;
  toggleCreateContractsConfirm: (show: boolean) => void;
  createContracts: () => Promise<void>;
  getPdfForPreview: () => void;
  params: { id: string };
  isReady: boolean;
  createContractsForSendingFailure: boolean;
  canContinue: boolean;
  showCreateContractsConfirm: boolean;
  pdfPreviewLinks: Immutable.Map<string, object>;
  signatureMethods: Immutable.Map<string, string>;
  toggleNewSignatoryDialog: (show: boolean, contract: Contract) => void;
  showNewSignatoryDialog: { show: boolean; contract: Contract };
  addSignatory: (contractType: string, signatory: Signatory) => void;
  contractsSignatories: object;
  manualSigners: object;
  toggleSignatoryDetailsDialog: (show: boolean, contract: Contract, signatory: Signatory) => void;
  showSignatoryDetailsDialog: { show: boolean; contract: Contract; signatory: Signatory };
  removeSignatory: (contractType: string, signatory: Signatory) => void;
  copySignatoriesToAllContracts: (signatories: Signatory[]) => void;
  savedSigners: Signatory[];
  profile: ProfileState;
  bisnode: object;
  updateBisnode: () => void;
  canUpdateBisnode: boolean;
  webServiceContractExist: boolean;
  fetchingWebServiceContractStatus: boolean;
  prefillSignatory: (contractType: string) => void;
  contractsCompleted: boolean;
  webServiceContractInfo: string;
  setManualSignatureAmount: () => void;
  resetClientSession: ({ deleteDraft }: { deleteDraft: boolean }) => Promise<void>;
  handler: (option: Contract) => void;
  inputElement: HTMLInputElement;
  pdfAttachments: PdfAttachment[];
  showAddAttachment: boolean;
  removeAttachmentDocument: (id: string) => void;
  uploadAttachmentSignatureMethod: (id: string, signatureMethod: string) => Promise<void>;
}

interface State {
  document: ImmutableDocument;
}
class Preview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      document: Map() as ImmutableDocument,
    };
  }

  handleOpen = () => {
    this.setState({ document: Map() as ImmutableDocument });
  };

  handleClearDocument = () => {
    const document: ImmutableDocument = this.state.document
      .set(DOCUMENT_DATAURI, undefined)
      .set(DOCUMENT, undefined) as ImmutableDocument;

    this.setState({ document });
  };

  previewDocument = (document: Document) => {
    this.props.onPreviewDocument(document.documentURI);
  };

  isDocumentValid = () => {
    return true;
  };

  /*END document */

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleOnSignatureMethodSelected = (contractType: string, value: string) => {
    if (value === SENT_FOR_SIGNING || value === SENT_FOR_INK_SIGNING) {
      this.props.prefillSignatory(contractType);
    }
    this.props.selectSignatureMethod(contractType, value);
  };

  hideCreateContractsDialog = () => {
    this.props.toggleCreateContractsConfirm(false);
  };

  showConfirmation = () => {
    this.props.toggleCreateContractsConfirm(true);
  };

  handleNext = async () => {
    const { params, resetClientSession } = this.props;
    this.props
      .createContracts()
      .then(() => {
        this.context.router.push(`customer/${params.id}/contracts/signatures`);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
    await resetClientSession({ deleteDraft: true });
  };

  handleOnNewSignatoryButtonClick = (contract: Contract) => {
    this.props.toggleNewSignatoryDialog(true, contract);
  };

  handleOnCopyAllSignatoriesButtonClick = (signatories: Signatory[]) => {
    this.props.copySignatoriesToAllContracts(signatories);
  };

  handleNewSignatoryDialogOnCloseClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.toggleNewSignatoryDialog(false);
  };

  handleNewSignatoryDialogOnAddClick = (signatory: Signatory, contract: Contract) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.addSignatory(contract.key, signatory);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.toggleNewSignatoryDialog(false);
  };

  handleOnSignatorySelect = (signatory: Signatory, contract: Contract) => {
    this.props.toggleSignatoryDetailsDialog(true, contract, signatory);
  };

  handleSignatoryDetailsDialogOnCloseClick = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.toggleSignatoryDetailsDialog(false);
  };

  handleSignatoryDetailsDialogOnRemoveClick = (signatory: Signatory, contract: Contract) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.toggleSignatoryDetailsDialog(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.removeSignatory(contract.key, signatory);
  };

  handleUpdateBisnode = () => {
    this.props.updateBisnode();
  };

  signatoriesOfContract = (signatories: Signatory[], contractType: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (signatories.has(contractType)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return signatories.get(contractType).toJS();
    }
    return [];
  };

  manualSignatoriesOfContract = (signatories: Signatory[], contractType: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (signatories && signatories.has(contractType)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return signatories.get(contractType);
    }
    return 1;
  };

  render() {
    const {
      pdfPreviewLinks,
      signatureMethods,
      isReady,
      canContinue,
      showCreateContractsConfirm,
      showNewSignatoryDialog,
      contractsSignatories,
      manualSigners,
      showSignatoryDetailsDialog,
      webServiceContractExist,
      fetchingWebServiceContractStatus,
      profile,
      bisnode,
      canUpdateBisnode,
      getPdfForPreview,
      contractsCompleted,
      setManualSignatureAmount,
      createContractsForSendingFailure,
      webServiceContractInfo,
      savedSigners,
      showAddAttachment,
    } = this.props;
    if (isReady) {
      return <Locked />;
    }
    const showBisnodeInfo =
      profile.customer.toJS().customerType === 'company' &&
      contractsCompleted &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      pdfPreviewLinks.some((_value, key) => !isWebServiceContract(key));

    return (
      <section className={styles.contracts}>
        <div>
          {webServiceContractInfo && <Warning>{webServiceContractInfo}</Warning>}

          <SubNav
            label={contractsCompleted ? '3. Esikatsele ja valitse allekirjoitustapa' : '3. Esikatsele'}
            onNextClick={canContinue ? this.showConfirmation : () => {}}
            actionLabel={'Allekirjoita'}
            actionDisabled={!canContinue}
            actionHidden={!contractsCompleted}
          />

          <section className={styles.signatures}>
            {showBisnodeInfo && (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              <BisnodeInfo bisnode={bisnode} updateBisnode={this.handleUpdateBisnode} getOrderBisnode={getBisnode} />
            )}

            {pdfPreviewLinks.entrySeq().map((entry, index) => {
              return (
                <ContractPreviewItem
                  key={index}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  entry={entry}
                  profile={profile}
                  bisnode={bisnode}
                  handleUpdateBisnode={this.handleUpdateBisnode}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  handleOnSignatureMethodSelected={this.handleOnSignatureMethodSelected}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  handleOnNewSignatoryButtonClick={this.handleOnNewSignatoryButtonClick}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  handleOnCopyAllSignatoriesButtonClick={this.handleOnCopyAllSignatoriesButtonClick}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  handleOnSignatorySelect={this.handleOnSignatorySelect}
                  canUpdateBisnode={canUpdateBisnode}
                  signatureMethods={signatureMethods}
                  pdfPreviewLinks={pdfPreviewLinks}
                  fetchingWebServiceContractStatus={fetchingWebServiceContractStatus}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  contractsSignatories={contractsSignatories}
                  webServiceContractExist={webServiceContractExist}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  signatoriesOfContract={this.signatoriesOfContract}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  manualSignatoriesOfContract={this.manualSignatoriesOfContract}
                  getPdfForPreview={getPdfForPreview}
                  hideSigningOptions={!contractsCompleted}
                  setManualSignatureAmount={setManualSignatureAmount}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  manualSigners={manualSigners}
                />
              );
            })}

            {showCreateContractsConfirm && (
              <ModalDialog
                title="Vahvista sopimusten luonti"
                open={true}
                actions={[
                  <StyledButton
                    variant="text"
                    onClick={this.handleNext}
                    data-testkey="create-contracts-button"
                    key={'Luo sopimukset'}
                  >
                    Luo sopimukset
                  </StyledButton>,
                  <StyledButton variant="text" onClick={this.hideCreateContractsDialog} key={'Peruuta'}>
                    Peruuta
                  </StyledButton>,
                ]}
                content={
                  'Olethan varmistanut asiakkaalta tietojen oikeellisuuden ja halutun allekirjoitustavan? Sähköisesti allekirjoitettavat sopimukset lähetetään nyt allekirjoittajille.'
                }
              />
            )}

            {showNewSignatoryDialog.show && (
              <NewSignatoryDialog
                isOpen={true}
                contract={showNewSignatoryDialog.contract}
                savedSigners={savedSigners}
                onCloseClick={this.handleNewSignatoryDialogOnCloseClick}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                onAddClick={this.handleNewSignatoryDialogOnAddClick}
              />
            )}

            {showSignatoryDetailsDialog.show && (
              <SignatoryDetailsDialog
                signatory={showSignatoryDetailsDialog.signatory}
                contract={showSignatoryDetailsDialog.contract}
                onRemoveClick={this.handleSignatoryDetailsDialogOnRemoveClick}
                onCloseClick={this.handleSignatoryDetailsDialogOnCloseClick}
              />
            )}
            <br />

            {showAddAttachment && <AddAttachment title="Liitedokumentit" />}
            {createContractsForSendingFailure && (
              <Warning>{translate('newContract.createContractsForSendingFailure')}</Warning>
            )}
          </section>
        </div>
      </section>
    );
  }
}

export default spinner(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  ({ isBusy, addingWebServiceContract, fetchingWebServiceContractStatus }: Props) =>
    isBusy || addingWebServiceContract || fetchingWebServiceContractStatus
)(Preview);
