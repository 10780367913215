import React, { Component } from 'react';
import classnames from 'classnames';
import translate from 'counterpart';
import HardwareKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import assign from 'lodash/assign';
import styles from './RegistryField.scss';
import { isRequired, getSchemaValue } from 'core/functions';
import SubQuestionSet from 'features/arrayQuestions/SubQuestionSet';
import { Schema } from 'types/contractsState';
import { FieldType, Question } from 'types/ordersState';

interface Props {
  field: FieldType;
  value: string | object[];
  changeSectionField: (key: string, value: string | object[]) => void;
  hasFocus: boolean;
  schema: Schema;
}

export default class RegistryField extends Component<Props> {
  copyValue = () => {
    const { changeSectionField, field, value } = this.props;
    changeSectionField(field.key, value);
  };

  render() {
    const { field, value, hasFocus, schema } = this.props;
    const style = {} as {
      [k in string]: boolean;
    };
    style[styles.focus] = hasFocus;
    const hasValue = value && value !== '';
    const maxLength = getSchemaValue(schema, field.key, 'maxLength');

    let ans;
    if (typeof value === 'string') {
      ans = value;
      if (maxLength > 100) {
        style[styles.textareaField] = true;
      } else {
        style[styles.stringField] = true;
      }
    } else if (field.items && value) {
      style[styles.arrayField] = true;
      const questions = field.items.map((q) => assign({}, q, { isRequired: isRequired(schema, q.key) })) as Question[];
      ans = value.map((answer, ind) => (
        <SubQuestionSet
          // @ts-expect-error: type mismatch
          answer={answer}
          questions={questions}
          locale={'fi'}
          field={field}
          schema={schema}
          questionSetIndex={ind}
          key={ind}
          countries={{}}
          hideControls={true}
        />
      ));
    }

    return (
      <div className={classnames(styles.field, style, 'row')}>
        <div className={classnames(styles.registryColumn, 'columns small-8')}>
          <div className={styles.registryField}>
            <div className={classnames(styles.registryTitle)} key="title">
              {field.title.fi}
            </div>
            {hasValue ? (
              <div className={classnames(styles.registryValue)} key="value">
                {ans}
              </div>
            ) : undefined}
          </div>
        </div>
        <div className={classnames(styles.column, 'columns small-4')}>
          {hasValue ? (
            <div className={classnames(styles.content)} onClick={this.copyValue}>
              <span className={styles.copyLink}>{translate(`customer.copy`)}</span>
              <span className={styles.arrow}>
                <HardwareKeyboardArrowRight
                  sx={{
                    color: '#80c49d',
                  }}
                />
              </span>
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}
