import React from 'react';
import { Link } from 'react-router';
import { merge } from 'lodash';
import translate from 'counterpart';
import classNames from 'classnames';
import { wizardSteps } from 'features/prospect/prospectConstants';
import styles from './NewCustomerWizard.scss';
import { Status } from 'types/types';

interface Props {
  statuses: Status[];
}

const NewCustomerWizard = (props: Props) => {
  const statuses: Status[] = merge([{}, {}, {}], wizardSteps, props.statuses);
  const components = statuses.map((step, index) => {
    const iconClass = classNames('icon', {
      'icon-wizard-done': step.done,
      'icon-wizard-normal': !step.done,
    });
    return (
      <li key={index}>
        <Link to={step.link} activeClassName={styles.selected}>
          <i className={iconClass} />
          <p>{translate(step.text)}</p>
        </Link>
      </li>
    );
  });
  return (
    <nav className={styles.wizard}>
      <ul className="row align-spaced">{components}</ul>
    </nav>
  );
};

export default NewCustomerWizard;
