import React from 'react';
import SignatoryListItem from './SignatoryListItem';
import styles from './SignatoryList.scss';
import { Signatory } from 'types/ordersState';

interface Props {
  items: Signatory[];
  onNewSignatoryButtonClick: () => void;
  onCopyAllSignatoriesButtonClick: () => void;
  showCopyAllSignatories: boolean;
  onItemSelect: (signatory: Signatory) => void;
  disableEdit: boolean;
  disableAddSignatory: boolean;
}

export default class SignatoryList extends React.Component<Props> {
  static defaultProps = {
    items: [],
    onNewSignatoryButtonClick: () => {},
    onCopyAllSignatoriesButtonClick: () => {},
    showCopyAllSignatories: false,
    onItemSelect: () => {},
  };

  signatoryListItems = () => this.props.items || [];

  handleNewSignatoryButtonClick = () => {
    this.props.onNewSignatoryButtonClick();
  };

  handleCopyAllSignatoriesButtonClick = () => {
    this.props.onCopyAllSignatoriesButtonClick();
  };

  handleOnItemSelect = (signatory: Signatory) => {
    if (this.props.onItemSelect) {
      this.props.onItemSelect(signatory);
    }
  };

  render() {
    const { disableEdit, showCopyAllSignatories, disableAddSignatory } = this.props;
    const signatoryListItems = this.signatoryListItems().map((item, index) => (
      <div key={index}>
        <SignatoryListItem
          signatory={item}
          onSelect={disableEdit ? undefined : this.handleOnItemSelect}
          isSelectable={!disableEdit}
        />
        <div className={styles.divider} />
      </div>
    ));

    const copyAllBtn = showCopyAllSignatories ? (
      <div className={styles.actionButton} onClick={this.handleCopyAllSignatoriesButtonClick}>
        Kopioi kaikkiin
      </div>
    ) : undefined;

    return (
      <div className={styles.signatoryListContainer}>
        <div className={styles.signatoryList}>{signatoryListItems}</div>
        <div className={styles.signatoryListActions}>
          {disableAddSignatory ? (
            ''
          ) : (
            <div
              className={styles.actionButton}
              onClick={this.handleNewSignatoryButtonClick}
              data-testkey="add-new-signatory-button"
            >
              Lisää uusi allekirjoittaja
            </div>
          )}
          {copyAllBtn}
        </div>
      </div>
    );
  }
}
