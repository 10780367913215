import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { FlexColumn, HelpTextSmall, pageMargin, styles } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { BalanceIcon, LockIcon } from 'features/allocator/common/SvgIcon';
import { Portfolios } from './Portfolios';
import { LinkButton } from 'features/allocator/common/LinkButton';
import {
  isOptimizedPortfolio,
  isConsideredPortfolio,
  isFuturePortfolio,
  isIgnoredPortfolio,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import {
  customerHasValidPlan,
  selectHasError,
  isLoadingPlan,
  positionsAreEqual,
} from 'features/allocator/allocatorSelectors';
import { Spinner } from 'features/common/Spinner';
import { DifferenceExplanation } from 'features/allocator/common/DifferenceExplanation';
import { Grid } from 'features/allocator/common/Grid';
import { weightTypes } from 'constants/allocator';
import { selectCustomerId } from 'features/profile/profileSelectors';
import {
  customerHasOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';

export const ManagedPortfolios = () => {
  const customerId = useSelector(selectCustomerId);
  const hasPlan = useSelector(customerHasValidPlan);
  const loadingPlan = useSelector(isLoadingPlan);
  const loadingProfile = useSelector((state: RootState) => state.profile.isBusy);
  const hasOptimizedWeightsWithout = useSelector(customerHasOptimizedWeights('withoutIlliquids'));
  const hasOptimizedWeightsWith = useSelector(customerHasOptimizedWeights('withIlliquids'));
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const positionsEqual = useSelector(positionsAreEqual);

  const includedPortfolios = optimizedPortfolios.filter((p) => isOptimizedPortfolio(p));
  const consideredPortfolios = optimizedPortfolios?.filter((p) => isConsideredPortfolio(p) && !isFuturePortfolio(p));
  const ignoredPortfolios = optimizedPortfolios?.filter((p) => isIgnoredPortfolio(p));
  const futurePortfolios = optimizedPortfolios?.filter((p) => isFuturePortfolio(p));

  const hasStaticPortfolios = consideredPortfolios?.length + ignoredPortfolios?.length + futurePortfolios?.length > 0;

  const hasOptimizedWeights = hasPlan ? hasOptimizedWeightsWithout : hasOptimizedWeightsWith;
  const showDifferenceExplanation = hasOptimizedWeights && positionsEqual;

  const hasOptimizeCurrentError = useSelector(selectHasError(['optimizeCurrent', 'fetchPositions']));

  if (hasOptimizeCurrentError) {
    return null;
  }

  const weightsToUse = weightTypes.currentWeights;

  return (
    <Container>
      {showDifferenceExplanation && <DifferenceExplanation />}

      {loadingPlan || loadingProfile ? (
        <PortfoliosContainer>
          <h2>{translate('portfolioManager.managedPortfolio.currentPortfoliosTitle')}</h2>
          <Grid>
            <LoadingCard>
              <Spinner />
            </LoadingCard>
            <LoadingCard>
              <Spinner />
            </LoadingCard>
          </Grid>
        </PortfoliosContainer>
      ) : (
        <PortfoliosContainer>
          {!hasPlan && <h2>{translate('portfolioManager.managedPortfolio.currentPortfoliosTitle')}</h2>}
          <Grid data-testkey="plan-portfolios">
            <div>
              {hasPlan && (
                <TopPart>
                  <BalanceIcon />
                  <h2>{translate('portfolioManager.managedPortfolio.optimizedInvestments')}</h2>
                  <HelpTextSmallCenter>
                    {translate('portfolioManager.managedPortfolio.optimizedInvestmentsHelpText')}
                  </HelpTextSmallCenter>

                  {!!includedPortfolios.length && (
                    <BalanceButton
                      to={`customer/${customerId}/portfoliomanager/trade`}
                      label={translate('portfolioManager.customerPage.balance')}
                      data-testkey="button-trade"
                    />
                  )}
                </TopPart>
              )}
              <Portfolios
                portfolios={includedPortfolios}
                planLength="withoutIlliquids"
                weightsToUse={weightsToUse}
                compareTo={weightTypes.optimizedWeights}
              />
            </div>

            <div>
              {hasPlan && hasStaticPortfolios && (
                <TopPart>
                  <LockIcon />
                  <h2> {translate('portfolioManager.managedPortfolio.fixedInvestments')}</h2>
                  <HelpTextSmallCenter>
                    {translate('portfolioManager.managedPortfolio.fixedInvestmentsHelpText')}
                  </HelpTextSmallCenter>
                </TopPart>
              )}

              <Grid vertical>
                {consideredPortfolios.length > 0 && (
                  <div>
                    <Portfolios
                      portfolios={consideredPortfolios}
                      planLength="withoutIlliquids"
                      weightsToUse={weightsToUse}
                      compareTo="none"
                    />
                  </div>
                )}
                {ignoredPortfolios.length > 0 && (
                  <div>
                    <Portfolios
                      portfolios={ignoredPortfolios}
                      planLength="withoutIlliquids"
                      weightsToUse={weightsToUse}
                      compareTo="none"
                    />
                  </div>
                )}
                {futurePortfolios.length > 0 && (
                  <div>
                    <Portfolios
                      portfolios={futurePortfolios}
                      planLength="withoutIlliquids"
                      weightsToUse={weightsToUse}
                      compareTo="none"
                    />
                  </div>
                )}
              </Grid>
            </div>
          </Grid>
        </PortfoliosContainer>
      )}
    </Container>
  );
};

const Container = styled(Grid)`
  padding: 1rem ${pageMargin} 3rem;

  grid-template-areas:
    'difference_explanation difference_explanation'
    'portfolios portfolios';

  h2 {
    text-align: center;
  }
`;

const TopPart = styled(FlexColumn)`
  align-items: center;
  min-height: 14rem;

  img {
    margin-bottom: 0.5rem;
  }
`;

const PortfoliosContainer = styled.div`
  grid-area: portfolios;
`;

const HelpTextSmallCenter = styled(HelpTextSmall)`
  margin-bottom: 1rem;
  max-width: 500px;
  text-align: center;
`;

const BalanceButton = styled(LinkButton)`
  margin-bottom: 1.5rem;
  align-self: center;
`;

const LoadingCard = styled.div`
  ${styles.card}
  padding: 1.5rem 0;
`;
