import { PROSPECT } from 'constants/customerStates';
import { Customer } from 'types/profileState';

const prospectMandatoryContracts: { [key: string]: string[] } = {
  person: ['cragreementPerson'],
  company: ['cragreementCompany'],
  InstituutioVH: ['cragreementCompany'],
};

const kycMandatoryContracts: { [key: string]: string[] } = {
  person: ['yksityishenkilonKyc'],
  company: ['yhteisonKyc'],
  InstituutioVH: ['yhteisonKyc'],
};

export function isProspect(prospect: Customer) {
  if (prospect) {
    return prospect.state === PROSPECT;
  }
  return false;
}

export function mandatoryContracts(prospect: Customer) {
  if (prospect) {
    return prospectMandatoryContracts[prospect.segment] || prospectMandatoryContracts[prospect.customerType] || [];
  }
  return [];
}

export function mandatoryKycContracts(customer: Customer) {
  if (customer) {
    return kycMandatoryContracts[customer.segment] || kycMandatoryContracts[customer.customerType] || [];
  }
  return undefined;
}

export function hasMandatoryContract(prospect: Customer) {
  return mandatoryContracts(prospect).length > 0;
}
