const MOST_COMMON = 'mostCommon';
const ASSET_MANAGEMENT = 'assetManagement';
const CUSTOMERSHIP_CHANGES = 'customershipChanges';
const PROXY = 'proxy';
const OTHER = 'other';
const INTERNAL = 'internal';
const DIGI = 'digi';
const PRODUCTS = 'products';
const LEGACY_TAALERI = 'legacyTaaleri';
const AKTIA_LIFE_INSURANCE = 'aktiaLifeInsurance';

export const templateCategories = {
  MOST_COMMON,
  ASSET_MANAGEMENT,
  AKTIA_LIFE_INSURANCE,
  CUSTOMERSHIP_CHANGES,
  PRODUCTS,
  PROXY,
  INTERNAL,
  LEGACY_TAALERI,
  DIGI,
  OTHER,
};
