import { apiCall } from 'core/apiCall';
import { GetGeographicalValuesResponse } from 'features/portfolioManager/commonData/commonDataTypes';
import { AssetResponse, InstrumentGroupsResponseInstrument } from 'types/commonDataState';
import { OptimizePlanResponseUnit } from 'types/newCustomerOptimization';
import { RootState } from 'types/rootState';
import { AssetClassId, RiskLevel } from 'types/types';
import { optimizationModelWithWeights } from 'features/allocator/optimizationModel';
import { getPrefix } from 'features/allocator/instruments/instrumentsUtils';

export const getExamplePortfolioPropsForRiskLevel = (riskLevel: RiskLevel) => (state: RootState) => {
  const examplePortfolios = state.portfolioManager.commonData.examplePortfolios;
  const portfolio = examplePortfolios?.[riskLevel - 1];

  const weights = portfolio?.weights;
  const returnStatistics = portfolio?.returnStatistics;
  const companyOptimizationForecastStatistics = portfolio?.companyOptimizationForecastStatistics;

  const confidenceLevels = companyOptimizationForecastStatistics?.confidenceLevels;

  const portfolioVolatility = returnStatistics?.portfolioVolatility;
  const portfolioReturn = returnStatistics?.portfolioReturn;

  return { weights, portfolioVolatility, portfolioReturn, confidenceLevels };
};

export const getExamplePortfolios = async (accessToken: string) =>
  (await apiCall({
    method: 'get',
    token: accessToken,
    path: '/api/v1/allocator/exampleportfolios',
  })) as OptimizePlanResponseUnit[];

export const getAssetClasses = async (accessToken: string) =>
  (await apiCall({
    method: 'get',
    path: 'api/v1/allocator/assets',
    token: accessToken,
  })) as AssetResponse[];

export const getGeographicalValues = async (token: string) => {
  const payload = buildGeographicalValuesPayload();
  const data = (await apiCall({
    method: 'post',
    path: 'api/v1/allocator/geographical',
    token,
    data: payload,
  })) as Promise<GetGeographicalValuesResponse>;
  return data;
};

const buildGeographicalValuesPayload = () => {
  const payload = {
    categories: optimizationModelWithWeights.flatMap((assetCategory) =>
      assetCategory.assetClassGroups.map((assetClassGroup) => ({
        majorCategory: assetCategory.name,
        minorCategory: assetClassGroup.name,
      }))
    ),
  };
  return payload;
};

export const getAssetClassIdForInstrumentWithWeights = (
  instrument: InstrumentGroupsResponseInstrument
): AssetClassId => {
  const weights = instrument?.weights;

  const samePrefix = () =>
    weights?.every((weight) => getPrefix(weight.assetClass) === getPrefix(weights[0].assetClass));

  if (samePrefix()) {
    const assetClassId = weights[0].assetClass;
    return assetClassId;
  }
  return 'ALLOC';
};
