import { default as getValueFromObject } from 'lodash/fp/get';
import split from 'lodash/fp/split';
import flow from 'lodash/fp/flow';
import last from 'lodash/fp/last';
import join from 'lodash/fp/join';
import dropRight from 'lodash/fp/dropRight';
import indexOf from 'lodash/fp/indexOf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import forEachDeep from 'for-each-deep/es5';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import has from 'lodash/has';
import filter from 'lodash/filter';
import { default as setValue } from 'lodash/set';
import { default as unsetValue } from 'lodash/unset';
import moment from 'moment';
import includes from 'lodash/includes';
import { SELECT, ARRAY, COUNTRY, NUMBER, STRING, SELECT_MANY, OBJECT, DATE_TIME } from 'constants/fieldTypes';
import { ASK_IF_MATCH } from 'constants/templateConstants';
import { Countries, Schema } from 'types/contractsState';
import { Condition, Contract, FieldType, Item, Template, Value } from 'types/ordersState';
import { CreatePageParams, CreateSearchParams, SearchParams } from 'core/functionsTypes';

function getSchemaPath(key: string, schema: Schema) {
  let path = '';
  if (key === '') {
    return path;
  }
  if (schema) {
    const keyParts = key.split('.');
    keyParts.forEach((part, index, array) => {
      const propString = index === 0 ? 'properties.' : '.properties.';
      path = path.concat(`${propString}${part}`);
      const schemaObject = getValueFromObject(path, schema);
      if (schemaObject && schemaObject.type === ARRAY && index < array.length - 1) {
        path = path.concat('.items');
      }
    });
  } else {
    path = path.concat('properties.', key.replace(/\./g, '.properties.'));
  }
  return path;
}

function getLastKey(itemKey: string) {
  const itemKeyArr = itemKey.split('.');
  const lastKey = itemKeyArr[itemKeyArr.length - 1];
  return lastKey;
}

function findByKey(key: string, items: Item[]): { item: Item; index: number } {
  let item = items.filter((s) => s.key === key)[0];
  if (!item) {
    // try using last item of the path, as arrayField passes only the last part as the key and will not find with it
    item = items.filter((s) => getLastKey(s.key) === key)[0];
  }

  return {
    item,
    index: items.indexOf(item),
  };
}

function findFromArraysOfArrayByKey(items: Item[], key: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  let result;

  items.forEach((section) => {
    if (section.items) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const { item: dep } = findByKey(key, section.items);
      if (dep) {
        result = dep;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
      } else if (!result) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        result = findFromArraysOfArrayByKey(section.items, key);
      }
    }
  });
  return result;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function doesQuestionPassConditionTestToRender(formData: Contract, condition: Condition, sections: Item[]) {
  const dependsOnValue = getValueFromObject(condition.key, formData);
  const result = testCondition(condition, dependsOnValue);
  /*
  If the question we are testing (A) is conditional on question B value, and test result is falsy then return that test result
  If test result is true and B is conditional on question C value, the return that rest result:
  */
  if (!result) {
    return result;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const resultForDependsOn = handleDependencies(sections, condition, formData);

  return resultForDependsOn;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export function doesQuestionPassConditionTest(
  formData: Contract,
  condition: Condition,
  sections: Item[],
  dependsOnValue: Value
) {
  const result = testCondition(condition, dependsOnValue);
  /*
  If the question we are testing (A) is conditional on question B value, and test result is falsy then return that test result
  If test result is true and B is conditional on question C value, the return that rest result:
  */
  if (!result) {
    return result;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const resultForDependsOn = handleDependencies(sections, condition, formData, dependsOnValue);

  return resultForDependsOn;
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
function handleDependencies(sections: Item[], condition: Condition, formData: Contract, dependsOnValue: Value) {
  const dependsOn = findFromArraysOfArrayByKey(sections, condition.key);

  let resultForDependsOn = true;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (dependsOn && dependsOn.condition) {
    if (dependsOnValue === undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      resultForDependsOn = doesQuestionPassConditionTestToRender(formData, dependsOn.condition, sections);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      resultForDependsOn = doesQuestionPassConditionTest(formData, dependsOn.condition, sections, dependsOnValue);
    }
  }
  return resultForDependsOn;
}

/*
  condition: Condition for the question we are testing (static, stored in contract template (form))
  dependsOnValue: Value of the question that the question we are testing depends on (dynamic, depends on user interaction)
  Function returns true if dependsOnValue fulfills the condition
*/
export function testCondition(condition: Condition, dependsOnValue: Value) {
  let isFulfilled;
  if (condition.type === ASK_IF_MATCH) {
    if (Array.isArray(dependsOnValue)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      isFulfilled = includes(dependsOnValue, condition.value);
    } else {
      isFulfilled = dependsOnValue === condition.value;
    }
  } else {
    if (Array.isArray(dependsOnValue)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      isFulfilled = !includes(dependsOnValue, condition.value);
    } else {
      /*
       in cases where condition type is ASK_IF_NOT_MATCH which depends on a select list value, the field should not be shown when
       a selection has not yet been made at all (dependsOnValue === undefined or dependsOnValue === '') -> this check added here so that kind of fields
       are not visible, until something is actually selected on the select list this field is dependent of.
      */
      if (dependsOnValue === undefined || dependsOnValue === '') {
        return false;
      }
      isFulfilled = dependsOnValue !== condition.value;
    }
  }
  return isFulfilled;
}

export function getFieldType(formField: FieldType, schema: Schema) {
  const schemaPath = getSchemaPath(formField.key, schema);
  let schemaType;
  const value = getValueFromObject(schemaPath, schema);
  let dataType;
  let types;

  if (value) {
    schemaType = value.type;
  } else {
    schemaType = 'string';
  }

  if (formField.type) {
    types = {
      type: formField.type,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      formType: formField.formType || formField.type,
      dataType: schemaType,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
  } else if (formField.dataType) {
    types = {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      type: formField.dataType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      formType: formField.dataType,
      dataType: schemaType,
    };
  } else {
    if (schemaType === 'string' && value) {
      dataType = value.format || 'string';
    } else {
      dataType = 'string';
    }
    types = {
      type: schemaType,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      formType: formField.formType || schemaType,
      dataType,
    };
  }
  return types;
}

export function formatDate(date: string, format: string) {
  return moment(date).format(format);
}

function getRequiredInfo(key: string, schema: Schema) {
  const keyParts = split('.', key);
  const question = last(keyParts);
  const keyWoQuestion = flow(dropRight(1), join('.'))(keyParts);
  let schemaObject;
  if (keyWoQuestion === '') {
    schemaObject = schema;
  } else {
    schemaObject = getValueFromObject(getSchemaPath(keyWoQuestion, schema), schema);
  }
  let schemaPath;

  if (schemaObject && schemaObject.type === ARRAY) {
    schemaPath = getSchemaPath(keyWoQuestion, schema).split('.').concat(['items', 'required']).join('.');
  } else if (schemaObject) {
    schemaPath = getSchemaPath(keyWoQuestion, schema).split('.').concat(['required']).join('.');
  }

  return {
    path: schemaPath,
    question,
  };
}

export function isRequired(schema: Schema, key: string) {
  const { path, question } = getRequiredInfo(key, schema);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const required = flow(getValueFromObject(path), indexOf(question))(schema) >= 0;
  return required;
}

export function getSchemaValue(schema: Schema, key: string, field: string) {
  const path = field ? [getSchemaPath(key, schema), field].join('.') : [getSchemaPath(key, schema)].join('.');
  const obj = getValueFromObject(path, schema);
  return obj;
}

export function getDefaultValue(schema: Schema, key: string) {
  let schemaDefaultValue = getSchemaValue(schema, key, 'default');
  schemaDefaultValue = schemaDefaultValue ?? getSchemaValue(schema, key, 'type');
  return schemaDefaultValue;
}

export function updateContractTemplateVersions(contract: Contract, template: Template) {
  const lastSchemaVersion = template.versions[template.versions.length - 1];
  contract.schema.version = lastSchemaVersion.version;
  const lastFormVersion = template.formVersions[template.formVersions.length - 1];
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  contract.form.version = lastFormVersion.version;
  return contract;
}

export function fixContractSchema(contract: Contract, template: Template, countries: string[] | Countries) {
  let formPath;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  forEachDeep(contract, (value, key, currentObj, currentPath) => {
    formPath = currentPath.replace(/\[\d*]/g, '');
    if (startsWith(currentPath, 'sections.') && !endsWith(currentPath, ']')) {
      const formField = findFromArraysOfArrayByKey(template.form.items, formPath);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (formField && formField.key) {
        const types = getFieldType(formField, template.schema);
        if (types.type === NUMBER) {
          setValue(contract, currentPath, Number(value));
        } else if (value === null && (types.dataType === ARRAY || types.type === ARRAY)) {
          setValue(contract, currentPath, []);
        } else if (value === null && types.dataType === OBJECT) {
          setValue(contract, currentPath, {});
        } else if (value === null) {
          setValue(contract, currentPath, '');
        } else if (types.formType === SELECT) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          if (formField.titleMap && !has(formField.titleMap.fi, value)) {
            setValue(contract, currentPath, '');
          }
        } else if (types.formType === SELECT_MANY) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          const filteredValue = filter(value, (v) => formField.titleMap && has(formField.titleMap.fi, v));
          setValue(contract, currentPath, filteredValue);
        } else if (types.formType === COUNTRY) {
          if (types.dataType === STRING && !has(countries, value)) {
            setValue(contract, currentPath, '');
          } else if (types.dataType === ARRAY) {
            const filteredValue = filter(value, (v) => has(countries, v));
            setValue(contract, currentPath, filteredValue);
          }
        } else if (types.dataType === DATE_TIME) {
          const momentDate = moment(value, ['YYYY-MM-DD', 'DD.MM.YYYY', 'DD.MM.YY', 'MM/DD/YYYY', 'MM/DD/YY']);
          if (momentDate.isValid()) {
            setValue(contract, currentPath, momentDate.format('YYYY-MM-DD'));
          }
        }
      } else if (value === null) {
        unsetValue(contract, currentPath);
      }
    }
  });
  return contract;
}

export function createSearchParams(params: CreateSearchParams) {
  let commaString = '';
  let instrumentCommaString = '';
  const searchParams = {} as SearchParams;
  if (params.searchFilters && params.searchFilters.searchString) {
    commaString = params.searchFilters.searchString.replace(/\s+/g, ',');
    searchParams.keywords = commaString;
  }
  searchParams.states = params.searchFilters.states ? params.searchFilters.states.join(',') : null;
  searchParams.bankers = params.searchFilters.bankers ? params.searchFilters.bankers.join(',') : null;
  searchParams.startDate = params.searchFilters.startDate
    ? moment(params.searchFilters.startDate).format('MM/DD/YYYY, h:mm:ss a')
    : undefined;

  searchParams.endDate = params.searchFilters.endDate
    ? moment(params.searchFilters.endDate)
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds')
        .format('MM/DD/YYYY, h:mm:ss a')
    : undefined;
  searchParams.instrumentForms = params.searchFilters.instrumentForms
    ? params.searchFilters.instrumentForms.join(',')
    : null;
  if (params.searchFilters && params.searchFilters.instrumentKeywords) {
    instrumentCommaString = params.searchFilters.instrumentKeywords.replace(/\s+/g, ',');
    searchParams.instrumentKeywords = instrumentCommaString;
  }
  return searchParams;
}

export function createPageParams(params: CreatePageParams) {
  const pageParams = {} as CreatePageParams;
  pageParams.skip = params.skip ? params.skip : 0;
  pageParams.take = params.take;
  return pageParams;
}

export const escapePathParam = (param: string) => param.replace('\\', '!');

export const pathToProperty = (paths: string[]) => {
  return paths.reduce((accumulator, currentValue, currentIndex) => {
    const newAccumulator = accumulator + currentValue;
    return currentIndex === paths.length - 1 ? newAccumulator : newAccumulator + '.';
  }, '');
};
