import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import translate from 'counterpart';
import { FlexJustifyBetween, styles } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';

export const RiskConflict = () => {
  const contractRisk = useSelector((state: RootState) => state.profile.customer.toJS().selectedRiskLevel);
  const allocatorRisk = useSelector((state: RootState) => state.portfolioManager.investmentPlan.targetRiskLevel);
  return (
    <Container data-testkey="risk-conflict-box">
      <h2>{translate('allocator.riskConflict.title')}</h2>
      <FlexJustifyBetween>
        <div>
          <List>
            <li>
              {translate('allocator.riskConflict.contractRisk')} <b>{contractRisk}</b>
            </li>
            <li>
              {translate('allocator.riskConflict.planRisk')} <b>{allocatorRisk}</b>
            </li>
          </List>
        </div>
        <div>
          {translate('allocator.riskConflict.p1')} <b>{contractRisk}</b>, {translate('allocator.riskConflict.p2')}
        </div>
        <div>{translate('allocator.riskConflict.p3')}</div>
      </FlexJustifyBetween>
    </Container>
  );
};

const Container = styled.div`
  grid-area: riskconflict;
  ${styles.criticalMessage};
  padding: 2rem 0;

  b {
    padding: 0 0.1rem;
    font-weight: 900;
  }

  h2 {
    text-align: center;
    margin-bottom: 2rem;
  }

  & > div > div {
    padding: 0 3rem;
    flex: auto;

    &:not(:first-child) {
      border-left: 1px solid #ccc;
    }
  }
`;

const List = styled.ul`
  white-space: nowrap;
`;
