import React from 'react';
import translate from 'counterpart';
import { ModalDialog } from 'features/common/ModalDialog';
import { PortfolioById } from 'types/portfolioState';
import { SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { OrderLineType } from 'types/ordersState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  initializeState: () => void;
  goBack: () => void;
  editor: OrderLineType;
  selectedPortfolio: PortfolioById;
  suitabilityErrors: SuitabilityResult[];
  isPreviousOrderDraftSuitable: boolean | undefined;
}

export const ChangeSideDialog = ({
  initializeState,
  goBack,
  editor,
  selectedPortfolio,
  suitabilityErrors,
  isPreviousOrderDraftSuitable,
}: Props) => {
  //Initialize info text and buttons
  let infoText = translate('order.suitabilityInfoChangeSideAndCanOnlyEmpty', {
    name: editor.financialInstrumentName,
    portfolio: selectedPortfolio.externalId,
  });
  const actionButtons = [];
  // We only want to allow user to go back to edit previous portfolio, instrument, side order draft combination
  //  if suitability check for it was ok
  if (isPreviousOrderDraftSuitable === undefined || isPreviousOrderDraftSuitable === true) {
    actionButtons.push(
      <StyledButton variant="outlined" onClick={goBack} key={'order.goBack'}>
        {translate('order.goBack')}
      </StyledButton>
    );
    infoText = translate('order.suitabilityInfoChangeSide', {
      name: editor.financialInstrumentName,
      portfolio: selectedPortfolio.externalId,
    });
  }
  actionButtons.push(
    <StyledButton variant="contained" onClick={initializeState} key={'order.empty'}>
      {translate('order.empty')}
    </StyledButton>
  );

  return (
    <ModalDialog
      title={translate('order.suitabilityInfoHeader')}
      open={true}
      actions={actionButtons}
      content={
        <>
          {infoText} <br />
          {suitabilityErrors.map((e) =>
            e.messages.map((i) => (
              <div key={e.ruleId}>
                {translate('order.suitabilityRule', {
                  ruleId: e.ruleId,
                  message: i,
                })}
              </div>
            ))
          )}
        </>
      }
    />
  );
};
