import React, { useEffect, useState } from 'react';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { NumberInput } from 'features/common/StyledComponents';
import { getWithdrawalBalance } from 'features/portfolio/portfolioUtils';
import { setCashTradeAmount } from 'features/portfolioManager/valueData/valueDataActions';
import { useAppDispatch } from 'core/hooks';

export const CashInput = () => {
  const withdrawalBalance = useSelector(getWithdrawalBalance);

  const [cashInput, setCashInput] = useState<string>('0');
  const dispatch = useAppDispatch();

  const onChangeCashInput = (e: React.SyntheticEvent) => {
    const el = e.target as HTMLInputElement;
    const value = el.value;
    const smallerValue = Math.floor(Math.min(Number(value), withdrawalBalance));
    const atLeastZero = Math.floor(Math.max(Number(smallerValue), 0));
    const formattedValue = atLeastZero.toString().replace(/^0+/, '') || '0';

    setCashInput(formattedValue);
    dispatch(setCashTradeAmount(atLeastZero));
  };

  useEffect(() => {
    setCashInput(Math.floor(withdrawalBalance).toString());
    dispatch(setCashTradeAmount(withdrawalBalance));
  }, [withdrawalBalance]);

  return (
    <div>
      <label>{translate('portfolioManager.trade.sumToTrade')}: </label>
      <InputContainer>
        <Input
          value={cashInput}
          onChange={onChangeCashInput}
          min={0}
          data-testkey="cash-input"
          disabled={withdrawalBalance === 0}
        />
      </InputContainer>
    </div>
  );
};

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;

  &:after {
    content: '€';
    position: absolute;
    right: 10px;
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 900;
    height: 100%;
    opacity: 0.75;
  }
`;

const Input = styled(NumberInput)`
  width: 8rem;
  font-size: 18px;
  font-weight: 900;
  text-align: right;
  position: relative;
  padding-right: 1.5rem;
  margin: 0.25rem 0;
`;
