import styled from 'styled-components';
import { Spinner } from 'features/common/Spinner';

export const PreventSigningStyled = styled.span`
  min-width: 183px;
  color: #ed0726;
  text-transform: uppercase;
`;

export const SpinnerStyled = styled(Spinner)`
  margin-top: 34%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
`;

export const SectionContainer = styled.section`
  margin-bottom: 1rem;
`;

export const RemoveIcon = styled.i`
  font-size: 24px;
`;

export const LeftIconContainer = styled.div<{ notClickable?: boolean }>`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  ${(props) => !props.notClickable && 'cursor: pointer;'}
`;

export const LeftIcon = styled.i`
  font-size: 28px;
  padding-left: 0;
  display: block;
  color: white;
`;

export const LeftIconText = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

export const AttachmentContainer = styled.div`
  background: none;
  border: none;
  width: 100%;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-color: $white;
  display: flex;
  flex-direction: column;
  color: $primary-color;
  margin-bottom: 1rem;
  border-bottom: 1px solid #eae9e6;
`;

export const FlexAlignCenterJustifyBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 75px;
  padding-right: 2rem;
`;

export const SmallText = styled.div`
  font-size: 14px;
`;

const FlexJustifyAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeftContainer = styled(FlexJustifyAlignCenter)`
  text-align: center;
  color: white;
  background-color: black;
  width: 75px;
  min-width: 75px;
  height: 100%;
`;

export const RemoveContainer = styled(FlexJustifyAlignCenter)`
  margin-left: 16px;
  margin-right: 24px;
  &:hover {
    background-color: rgb(0, 0, 0, 0.2);
  }
  cursor: pointer;
  width: 75px;
  min-width: 75px;
  height: 100%;
`;

export const FlexBoxCol = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  margin-left: 20px;
`;

export const FlexRowCenterFullHeight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;
