import React, { FocusEvent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import difference from 'lodash/difference';
import assign from 'lodash/assign';
import classnames from 'classnames';
import Promise from 'bluebird';
import translate from 'counterpart';
import { isRequired, getFieldType, getSchemaValue } from 'core/functions';
import { initArrayEditorValues, setArrayEditorValue, deleteArrayAnswer } from 'actions/index';
import { ModalDialog } from 'features/common/ModalDialog';
import ArrayField from './ArrayField';
import FieldInfo from 'features/fieldList/FieldInfo';
import { wrapElement } from 'core/index';
import SubQuestionSet from './SubQuestionSet';
import { BOOLEAN } from 'constants/fieldTypes';
import styles from './ArrayQuestion.scss';
import fieldStyles from 'features/fieldList/Field.scss';
import { Condition, FieldType, Question, Template, Value } from 'types/ordersState';
import {
  FormError,
  Countries,
  Schema,
  Locale,
  Types,
  ImmutableArrayEditorValues,
  ArrayEditorValues,
  SearchPersonResponse,
} from 'types/contractsState';
import { RootState } from 'types/rootState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  setArrayEditorValue: (key: string[], value: Value) => void;
  field: FieldType;
  saveArrayEditorValue: (contractType: string, key: string, index: number | null) => void;
  schema: Schema;
  onBlur: (field: FieldType) => void;
  onFocus: (index: number, ev: FocusEvent<HTMLInputElement>) => void;
  openInfo: (title: string, info: string) => void;
  deleteArrayAnswer: (contractType: string, key: string, index: number) => void;
  initArrayEditorValues: (values: ArrayEditorValues) => void;
  value: ArrayEditorValues[];
  editorValues: ImmutableArrayEditorValues;
  locale: Locale;
  required: boolean;
  errors: FormError[];
  countries: Countries;
  valid: boolean | undefined;
  index: number;
  types: Types;
  contractId: string;
  template: Template;
  profileEditor: boolean | undefined;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  contractLanguage: Locale;
  dependentQuestions: FieldType[];
  fulfillsCondition?: (condition: Condition, value: Value) => boolean;
  textFieldAsWizardSection: boolean | undefined;
}

interface State {
  isDialogOpen: boolean;
  hasFocus: boolean;
  answerIndex: number;
}

class ArrayQuestion extends React.Component<Props, State> {
  handleFocus: (event: FocusEvent<HTMLInputElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      hasFocus: false,
      answerIndex: 0,
    };

    const { index, field } = this.props;
    this.handleFocus = this.onFocus.bind(this, index);
    this.handleBlur = this.onBlur.bind(this, field);
  }

  onFocus = (index: number, event: FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({
      ...this.state,
      hasFocus: true,
    });
    onFocus(index, event);
  };

  onBlur = (field: FieldType) => {
    const { onBlur } = this.props;
    this.setState({
      ...this.state,
      hasFocus: false,
    });

    onBlur(field);
  };

  handleOnChange = (key: string, value: Value) => {
    const { field } = this.props;
    this.props.setArrayEditorValue(difference(key.split('.'), field.key.split('.')), value);
  };

  saveItem = () => {
    const { schema, onBlur, field } = this.props;
    this.closeDialog();
    this.props.saveArrayEditorValue(schema.contractType, field.key, this.state.answerIndex);
    onBlur(field);
  };

  deleteItem = (index: number) => {
    const { schema, field, onBlur } = this.props;
    this.props.deleteArrayAnswer(schema.contractType, field.key, index);
    onBlur(field);
  };

  openDialog = (answerIndex: number) => {
    const { value, field, schema } = this.props;
    let initialEditorValue: ArrayEditorValues = {};
    if (answerIndex >= 0) {
      initialEditorValue = value[answerIndex];
    } else {
      field.items?.forEach((q) => {
        const types = getFieldType(q, schema);
        const key = q.key.split('.').pop();

        if (types.type === BOOLEAN && isRequired(schema, q.key)) {
          if (key) {
            initialEditorValue[key] = false;
          }
        }

        const defaultValue = getSchemaValue(schema, q.key, 'default');
        if (defaultValue) {
          if (key) {
            initialEditorValue[key] = defaultValue;
          }
        }
      });
    }
    this.props.initArrayEditorValues(initialEditorValue);
    this.setState({
      isDialogOpen: true,
      answerIndex,
    });
  };

  closeDialog = () => {
    this.setState({
      isDialogOpen: false,
      answerIndex: 0,
    });
  };

  render() {
    const {
      field,
      editorValues,
      schema,
      value,
      locale,
      required,
      errors,
      countries,
      valid,
      index,
      openInfo,
      contractId,
      template,
      profileEditor,
      crmPersonSearch,
      contractLanguage,
    } = this.props;

    let component;

    let iconClass;
    if (valid === true) {
      iconClass = 'icon icon-check';
    } else if (valid === false) {
      iconClass = 'icon icon-exclamation';
    } else {
      iconClass = '';
    }

    const indicator = (
      <div className={styles.indicator}>
        <i className={iconClass} />
      </div>
    );
    const label = (
      <div className={styles.label}>
        {profileEditor ? undefined : indicator}{' '}
        {required ? `${field.title[contractLanguage]} *` : field.title[contractLanguage]}
      </div>
    );

    const actions = [
      <StyledButton variant="text" onClick={this.closeDialog} key={'Peruuta'} data-testkey="modal-button-cancel">
        {translate('common.cancelAction', { locale: contractLanguage })}
      </StyledButton>,
      <StyledButton variant="text" onClick={this.saveItem} key={'Valmis'} data-testkey="modal-button-confirm">
        {translate('common.ready', { locale: contractLanguage })}
      </StyledButton>,
    ];

    let dialogTitle = translate('newContract.addNew', { locale: contractLanguage });
    if (this.state.answerIndex >= 0) {
      dialogTitle = translate('newContract.edit', { locale: contractLanguage });
    }

    const questions: Question[] =
      field.items?.map((q) => assign({}, q, { isRequired: isRequired(schema, q.key) })) || [];

    const allAnswers = value
      ? value.map((answer, ind) => (
          <SubQuestionSet
            // @ts-expect-error: ArrayEditorValues does not match Contract
            answer={answer}
            questions={questions}
            locale={locale}
            field={field}
            schema={schema}
            questionSetIndex={ind}
            key={ind}
            countries={countries}
            errors={errors}
            openDialog={this.openDialog}
            deleteItem={this.deleteItem}
            hideDiscard={profileEditor}
            contractLanguage={contractLanguage}
          />
        ))
      : false;

    component = (
      <div className={fieldStyles.componentContainer}>
        <div
          className={classnames(styles.arrayQuestionContainer, this.state.hasFocus ? styles.hasFocus : '')}
          tabIndex={index}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          <div className="row">
            <div className="column small-9">{label}</div>
            <div className="column small-3">
              {profileEditor ? undefined : (
                <button
                  type="button"
                  className="button float-right"
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  onClick={this.openDialog}
                  data-testkey={`open-modal-dialog-${field.key}`}
                >
                  <span>{translate(`newContract.addNew`, { locale: contractLanguage })}</span>
                </button>
              )}
            </div>
          </div>
          {allAnswers}
          <ModalDialog
            modalDialogWidth={'75%'}
            title={dialogTitle}
            open={this.state.isDialogOpen}
            actions={actions}
            content={
              // @ts-expect-error // type mismatch
              <ArrayField
                schema={schema}
                field={field}
                skipIndex={true}
                errors={errors}
                countries={countries}
                value={editorValues.toJS()}
                contractId={contractId}
                locale={locale}
                template={template}
                onChange={this.handleOnChange}
                onBlur={this.handleBlur}
                crmPersonSearch={crmPersonSearch}
                onFocus={this.onFocus}
                contractLanguage={contractLanguage}
                textFieldAsWizardSection={this.props.textFieldAsWizardSection}
              />
            }
          />
        </div>
        {field.info ? (
          <FieldInfo
            onClick={openInfo}
            title={field.title[locale]}
            info={field.info[locale]}
            className={fieldStyles.infoBtn}
          />
        ) : undefined}
      </div>
    );

    if (!profileEditor) {
      component = wrapElement(component, styles, valid, index);
    }
    return component;
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      initArrayEditorValues,
      setArrayEditorValue,
      deleteArrayAnswer,
    },
    dispatch
  );
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.common.locale as Locale,
    countries: state.common.countries,
    editorValues: state.contracts.arrayEditorValues,
    contractLanguage: state.common.contractLanguage as Locale,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ArrayQuestion);
