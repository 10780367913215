import React from 'react';
import classnames from 'classnames';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styles from './CheckBox.scss';

interface Props {
  label: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean | undefined;
  showComponent?: boolean;
  'data-testkey'?: string;
}

export const CheckBox = ({
  label,
  onChange,
  value = false,
  showComponent = true,
  'data-testkey': dataTestKey,
}: Props) => {
  if (!showComponent) {
    return null;
  }

  return (
    <div className={classnames(styles.form, 'row')}>
      <div className="columns small-12">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                data-testkey={`checkbox${dataTestKey ? `-${dataTestKey}` : ''}`}
                onChange={onChange}
                checked={!!value}
              />
            }
            label={label}
            sx={[
              {
                '& .MuiTypography-root': {
                  fontSize: '18px',
                  fontWeight: '400',
                  fontFamily: 'Calibre, sans-serif;',
                },
                '& .MuiSvgIcon-root': {
                  fill: '#00eb64',
                },
              },
            ]}
          />
        </FormGroup>
      </div>
    </div>
  );
};
