import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { fontSize, FlexJustifyCenter, customerHeaderHeight, Flex } from 'features/common/StyledComponents';
import { PlanLength } from 'types/types';
import { customerHasOptimizedWeights } from 'features/weights/weightsSelectors';

interface Props {
  planLength: PlanLength;
  onClick: () => void;
}

export const PlanSwitch = ({ planLength, onClick }: Props) => {
  const withoutSelected = planLength === 'withoutIlliquids';
  const withSelected = planLength === 'withIlliquids';
  const hasOptimizedWeightsWithIlliquids = useSelector(customerHasOptimizedWeights('withIlliquids'));
  const hasOptimizedWeightsWithoutIlliquids = useSelector(customerHasOptimizedWeights('withoutIlliquids'));

  const hasWithIlliquidWeightsOnly = hasOptimizedWeightsWithIlliquids && !hasOptimizedWeightsWithoutIlliquids;
  const hasWithoutIlliquidWeightsOnly = !hasOptimizedWeightsWithIlliquids && hasOptimizedWeightsWithoutIlliquids;

  return (
    <Container data-testkey={'plan-switch'}>
      <Background>
        <PlanSwitchButton
          active={withoutSelected}
          onClick={withSelected ? onClick : undefined}
          data-testkey="plan-switch-without-illiquids"
        >
          {translate('allocator.overviewView.startingPortfolio')}
          {hasWithIlliquidWeightsOnly && <Error>!</Error>}
        </PlanSwitchButton>
        <PlanSwitchButton
          active={withSelected}
          onClick={withoutSelected ? onClick : undefined}
          data-testkey="plan-switch-with-illiquids"
        >
          {translate('allocator.overviewView.longTermPortfolio')}
          {hasWithoutIlliquidWeightsOnly && <Error>!</Error>}
        </PlanSwitchButton>
      </Background>
    </Container>
  );
};

const Container = styled(FlexJustifyCenter)`
  position: sticky;
  top: ${customerHeaderHeight};
  z-index: 1;
  grid-area: plan;
`;

interface StyledButtonProps {
  active: boolean;
}

const Background = styled(FlexJustifyCenter)`
  background: ${colors.gray_light};
  padding: 15px 1.5rem 12px;
  border-radius: 3px;
`;

const PlanSwitchButton = styled.div<StyledButtonProps>`
  font-size: ${fontSize.h1};
  line-height: ${fontSize.h2};
  font-weight: 900;
  border-bottom: 3px solid ${(props) => (props.active ? colors.black : 'transparent')};
  cursor: ${(props) => (props.active ? 'default' : 'pointer')};
  color: ${(props) => (props.active ? colors.black : colors.gray_inactive)};
  position: relative;

  &:hover {
    color: ${(props) => !props.active && colors.dark_gray};
  }

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }
`;

const Error = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  right: -10px;
  top: -10px;
  border: 1px solid ${colors.red};
  font-size: 12px;
  font-weight: 900;
  background: ${colors.red};
  color: white;
  border-radius: 50%;
  height: 13px;
  width: 13px;
`;
