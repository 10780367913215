export default {
  optimizePlan:
    'Valitulle riskin, instrumenttien, rajoitusten ja salkkujen yhdistelmälle ei ole mahdollista laskea optimaalisia painoja.',
  optimizePlanWithoutIlliquids:
    'Optimaalisten painojen laskenta epäonnistui osittain: Nykyisen tavoitteen painoja ei voitu laskea.',
  optimizePlanWithIlliquids:
    'Optimaalisten painojen laskenta epäonnistui osittain: Pitkän ajan tavoitteen painoja ei voitu laskea.',
  tryDifferentCombination: 'Kokeile erilaista optimoitavien ja huomioitavien salkkujen kombinaatiota.',
  optimizeCurrent: 'Nykyisten sijoitusten laskenta epäonnistui',
  cannotOptimizeCurrent: 'Asiakkaalla ei ole omistuksia optimoitavissa salkuissa.',
  fetchPositions: 'Omistusten haku epäonnistui',
  fetchPortfolio: 'Salkkujen haku epäonnistui',
  saveInvestmentPlan: 'Sijoitussuunnitelman tallennus epäonnistui',
  loadInvestmentPlan: 'Sijoitussuunnitelman haku epäonnistui',
  getInstrumentPrices: 'Instrumenttien hintojen haku epäonnistui',
  fetchBankersCustomers: 'Asiakkaiden haku epäonnistui',
  setConstraints: 'Rajoitteiden asetus epäonnistui',
  constraintSumCantMustBeAtLeast100: 'Seuraavien rajoitteiden summa ei voi olla alle 100 %%',
  constraintSumCantExceed100: 'Seuraavien rajoitteiden summa ei voi olla yli 100 %%',
  fetchExamplePortfolios: 'Esimerkkisalkkujen haku epäonnistui',
  fetchAssetClasses: 'Omaisuuslajien haku epäonnistui',
  fetchInstrumentGroups: 'Instrumenttiryhmien haku epäonnistui',
  fetchPortfoliosStatus: 'Salkkujen tilan haku epäonnistui',
  createPortfoliosStatus: 'Salkkujen luominen epäonnistui',
  loadCustomerProfile: 'Asiakkaan tietojen hakeminen epäonnistui',
  somethingWentWrong: 'Hmm, jotain meni vikaan...',
  tryAgainLater: 'Kokeile ladata sivu uudestaan, tai yritä myöhemmin uudelleen.',
  getInstrumentDetails: 'Instrumenttien kaupankäyntitietojen haku epäonnistui.',
  allPositionsNotInPlan:
    'Osa asiakkaan omistuksista puuttuu sijoitusuunnitelmalta, joten optimointia ei voida ajaa. Käy päivittämässä suunnitelma vastaamaan omistuksia.',
  allPlanInstrumentsNotInPositions:
    'Kaikkia suunnitelman instrumentteja ei ole omistuksissa. Saat lisätietoja siirtymällä muokkaamaan suunnitelmaa.',
  obsoletePlan:
    'Tallennettu suunnitelma on vanhentunut, joten salkkujen asetukset nollattiin. Optimoi salkut ja tallenna suunnitelma uudelleen.',
  postSimulatedRisk: 'Riskiluvun laskenta epäonnistui',
  postActualRisk: 'Riskiluvun laskenta epäonnistui',
  negativePositions: 'Asiakkaalla on negatiivisia omistuksia. Korjaa omistukset, jotta sijoitukset voidaan optimoida.',
  noOptimizedPortfolios:
    'Omistuksia ei voida optimoida, koska yhtään salkkua ei ole valittu optimoitavaksi. Valitse suunnitelmalla ainakin yksi salkku optimoivaksi.',
  portfolioNameUnknown: '(Nimetön salkku)',
  geographicalValues: 'Tuotto-odotusten ja volatiliteettien haku epäonnistui',
};
