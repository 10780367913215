import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { omit } from 'lodash';
import { emptyWeights } from 'constants/allocator';
import { OptimizedWeights, Weights, WeightsState } from 'types/weightsState';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { OptimizationForecastType, RiskLevel } from 'types/types';

const initialState: WeightsState = {
  optimizedWeights: [],
  currentWeights: emptyWeights,
  optimizingPlan: false,
  optimizingCurrent: false,
  optimizedPortfolios: [],
  optimizedPortfoliosSettings: [],
};

const weightsSlice = createSlice({
  name: 'weights',
  initialState,
  reducers: {
    initializeWeightsState() {
      return initialState;
    },

    setOptimizingPlan(state, action: PayloadAction<boolean>) {
      state.optimizingPlan = action.payload;
    },

    updateOptimizedWeights(state, action: PayloadAction<OptimizedWeights>) {
      state.optimizedWeights = state.optimizedWeights
        .filter(
          (optimizedWeight) =>
            !(
              optimizedWeight.riskLevel === action.payload.riskLevel &&
              optimizedWeight.optimizationForecastType === action.payload.optimizationForecastType
            )
        )
        .concat(action.payload);
    },

    resetOptimizedWeights(state, action: PayloadAction<RiskLevel | undefined>) {
      action.payload
        ? (state.optimizedWeights = state.optimizedWeights.filter((i) => i.riskLevel !== action.payload))
        : (state.optimizedWeights = []);
    },

    setOptimizingCurrent(state, action: PayloadAction<boolean>) {
      state.currentWeights = emptyWeights;
      state.optimizingCurrent = action.payload;
    },

    updateCurrentWeights(state, action: PayloadAction<Weights>) {
      state.currentWeights = action.payload;
      state.optimizingCurrent = false;
    },

    resetCurrentWeights(state) {
      state.currentWeights = initialState.currentWeights;
      state.optimizingCurrent = false;
    },

    updateOptimizedPortfolios(
      state,
      action: PayloadAction<{
        portfolios: AllocatorPortfolio[];
        riskLevel: number;
        optimizationForecastType: OptimizationForecastType;
      }>
    ) {
      state.optimizedPortfolios = state.optimizedPortfolios
        .filter(
          (optimizedPortfolio) =>
            !(
              optimizedPortfolio.riskLevel === action.payload.riskLevel &&
              optimizedPortfolio.optimizationForecastType === action.payload.optimizationForecastType
            )
        )
        .concat({
          riskLevel: action.payload.riskLevel,
          optimizationForecastType: action.payload.optimizationForecastType,
          portfolios: action.payload.portfolios.map((planPortfolio) => {
            return {
              withIlliquids: planPortfolio.allocatedPortfolioRows.withIlliquids,
              withoutIlliquids: planPortfolio.allocatedPortfolioRows.withoutIlliquids,
              portfolioId: planPortfolio.portfolioId,
              allocatorPortfolioId: planPortfolio.allocatorPortfolioId,
            };
          }),
        });
    },

    updateOptimizedPortfoliosSettings(state, action: PayloadAction<AllocatorPortfolio[]>) {
      state.optimizedPortfoliosSettings = action.payload.map((planPortfolio) =>
        omit(planPortfolio, ['allocatedPortfolioRows'])
      );
    },

    resetOptimizedPortfoliosSettings(state) {
      state.optimizedPortfoliosSettings = state.optimizedPortfoliosSettings?.map((portfolioSettings) =>
        omit(portfolioSettings, ['manualSettings'])
      );
    },

    deleteSavedOptimizationResults(state) {
      state.optimizedWeights = [];
      state.optimizedPortfolios = [];
    },
  },
});

export const {
  initializeWeightsState,
  setOptimizingPlan,
  updateOptimizedWeights,
  resetOptimizedWeights,
  setOptimizingCurrent,
  updateCurrentWeights,
  resetCurrentWeights,
  updateOptimizedPortfolios,
  updateOptimizedPortfoliosSettings,
  resetOptimizedPortfoliosSettings,
  deleteSavedOptimizationResults,
} = weightsSlice.actions;

export const weightsReducer = weightsSlice.reducer;
