import React from 'react';
import { ModalDialog } from 'features/common/ModalDialog';
import styles from './SignatoryDetailsDialog.scss';
import { Contract, Signatory } from 'types/ordersState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  onCloseClick: () => void;
  onRemoveClick: (signatory: Signatory, contract: Contract) => void;
  signatory: Signatory;
  contract: Contract;
}

export default class SignatoryDetailsDialog extends React.Component<Props> {
  static defaultProps = {
    onCloseClick: () => {},
    onRemoveClick: () => {},
    signatory: {
      firstName: '',
      lastName: '',
      ssn: '',
      email: '',
      phone: '',
    },
    contract: {},
  };

  handleClose = () => {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  };

  handleRemove = () => {
    if (this.props.onRemoveClick) {
      this.props.onRemoveClick(this.props.signatory, this.props.contract);
    }
  };

  renderTitle = () => `Allekirjoittajan tiedot - ${this.props.contract.name}`;

  renderCloseButton = (label: string) => (
    <StyledButton variant="text" onClick={this.handleClose} key="close-button">
      {label}
    </StyledButton>
  );

  renderRemoveButton = (label: string) => (
    <StyledButton variant="text" onClick={this.handleRemove} key="remove-signer-button">
      {label}
    </StyledButton>
  );

  renderActions = () => [this.renderRemoveButton('Poista allekirjoittaja'), this.renderCloseButton('Peruuta')];

  renderSignatoryDetail = (label: string, value: string) => (
    <div className={styles.signatoryDetailsItem}>
      <span className={styles.signatoryDetailsLabel}>{label}</span>
      <span>{value}</span>
    </div>
  );

  renderContent = () => (
    <div className={styles.signatoryDetailsContainer}>
      {this.renderSignatoryDetail('Etunimi', this.props.signatory.firstName)}
      {this.renderSignatoryDetail('Sukunimi', this.props.signatory.lastName)}
      {this.renderSignatoryDetail('Henkilötunnus', this.props.signatory.ssn)}
      {this.renderSignatoryDetail('Puhelinnumero', this.props.signatory.phone)}
      {this.renderSignatoryDetail('Sähköposti', this.props.signatory.email)}
    </div>
  );

  render() {
    return (
      <ModalDialog
        title={this.renderTitle()}
        actions={this.renderActions()}
        open={true}
        content={this.renderContent()}
      />
    );
  }
}
