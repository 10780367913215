import {
  ExistingCustomerOptimizePlanPayload,
  ExistingCustomerOptimizePlanResponse,
} from 'types/existingCustomerOptimization';
import { OptimizeCurrentPayload, OptimizePlanPayload, OptimizePlanResponseUnit } from 'types/newCustomerOptimization';
import { RootState } from 'types/rootState';
import PromiseStore from 'core/PromiseStore';
import { PromiseType } from 'types/types';
import { apiCall } from 'core/apiCall';

export const cancelPromise = (promiseName: PromiseType) => {
  PromiseStore[promiseName].cancel();
};

export const postOptimizeCurrent = async (payload: OptimizeCurrentPayload, state: RootState) => {
  const accessToken = state.oidc.user.access_token;
  PromiseStore.optimizeCurrent = apiCall({
    path: '/api/v1/allocator/optimize/current',
    token: accessToken,
    method: 'post',
    data: payload,
  });
  return PromiseStore.optimizeCurrent as Promise<OptimizePlanResponseUnit>;
};

export const postOptimizeInvestmentPlan = async (payload: OptimizePlanPayload, state: RootState) => {
  PromiseStore.optimize = apiCall({
    method: 'post',
    token: state.oidc.user.access_token,
    path: '/api/v1/allocator/optimize/investmentplan',
    data: payload,
  });
  return PromiseStore.optimize as Promise<{
    withIlliquid: OptimizePlanResponseUnit;
    withoutIlliquid: OptimizePlanResponseUnit;
  }>;
};

export const postOptimizeExistingCustomer = (
  payload: ExistingCustomerOptimizePlanPayload,
  state: RootState
): Promise<ExistingCustomerOptimizePlanResponse> => {
  const pathEnd = payload.staticInstruments ? 'without_illiquids' : 'with_without_illiquids';
  const promiseName = payload.staticInstruments ? 'reoptimize' : 'optimize';

  PromiseStore[promiseName] = apiCall({
    method: 'post',
    token: state.oidc.user.access_token,
    path: '/api/v1/allocator/optimize/existing_customer_' + pathEnd,
    data: payload,
  });
  return PromiseStore[promiseName] as Promise<ExistingCustomerOptimizePlanResponse>;
};
