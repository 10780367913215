import { Snackbar } from '@mui/material';
import { styled } from '@mui/system';

const StyledSnackbar = styled(Snackbar)({
  '& .MuiPaper-root': {
    background: '#00eb64',
    color: '#333',
    textAlign: 'center',
    fontWeight: 300,
    padding: '6px 70px',
    fontFamily: 'Calibre',
    fontSize: '22px',
  },
}) as typeof Snackbar;

export default StyledSnackbar;
