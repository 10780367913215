import React, { Component, FocusEvent } from 'react';
import classnames from 'classnames';
import Select from 'react-select-old';
import translate from 'counterpart';
import Promise from 'bluebird';
import _ from 'lodash';
import FieldInfo from 'features/fieldList/FieldInfo';
import fieldStyles from 'features/fieldList/Field.scss';
import styles from './PersonSearchQuestion.scss';
import { wrapElement } from 'core/index';
import Person from './Person';
import { getInvalidCrmPersonFields } from 'core/validations';
import { FieldType, Value, FoundPerson } from 'types/ordersState';
import {
  Locale,
  Types,
  SearchPersonResult,
  SearchPersonResponse,
  SearchPersonResults,
  SearchPersonOption,
} from 'types/contractsState';

interface Props {
  field: FieldType;
  value: FoundPerson;
  required: boolean;
  valid: boolean | undefined;
  profileEditor: boolean | undefined;
  onBlur: (field: FieldType) => void;
  onFocus: (index: number, ev: FocusEvent<HTMLInputElement>) => void;
  openInfo: (title: string, info: string) => void;
  locale: Locale;
  index: number;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  onChange: (key: string, value: Value) => void;
  types: Types;
  contractLanguage: Locale;
}

interface State {
  isDialogOpen: boolean;
  hasFocus: boolean;
}

export default class PersonSearchQuestion extends Component<Props, State> {
  SEARCH_MIN_INPUT: number;
  searchTimeout: number;
  previousSearchPromise: Promise<void>;
  handleFocus: (event: FocusEvent<HTMLInputElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      hasFocus: false,
    };
    this.SEARCH_MIN_INPUT = 2;
    this.searchTimeout = 0;
    this.previousSearchPromise = Promise.resolve();

    const { index, field } = this.props;
    this.handleFocus = this.onFocus.bind(this, index);
    this.handleBlur = this.onBlur.bind(this, field);
  }

  onFocus = (index: number, event: FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({
      ...this.state,
      hasFocus: true,
    });
    onFocus(index, event);
  };

  onBlur = (field: FieldType) => {
    const { onBlur } = this.props;
    this.setState({
      ...this.state,
      hasFocus: false,
    });

    onBlur(field);
  };

  onChange = (params: { data: string }) => {
    const { onChange, field } = this.props;
    onChange(field.key, params ? params.data : undefined);
  };

  search = (input: string, callback: (error: null, results: SearchPersonResults) => void) => {
    window.clearTimeout(this.searchTimeout);
    this.searchTimeout = window.setTimeout(this.getOptions.bind(this, input, callback), 500);
  };

  getOptions = (input: string, callback: (error: null, results: SearchPersonResults) => void) => {
    const { crmPersonSearch } = this.props;
    this.previousSearchPromise.cancel();
    if (input.length >= this.SEARCH_MIN_INPUT) {
      this.previousSearchPromise = crmPersonSearch(input)
        .then(this.handleResponse)
        .then((json: SearchPersonOption[]) => ({ options: json }))
        .then((results: SearchPersonResults) => callback(null, results));
    } else {
      this.previousSearchPromise = Promise.resolve({ options: [] }).then((results) => callback(null, results));
    }
  };

  getLabelItem = (str: string) => (str ? `, ${str}` : '');

  getLabel = (val: SearchPersonResult) =>
    `${_.get(val, 'firstName', '')} ${_.get(val, 'lastName', '')}${this.getLabelItem(
      _.get(val, 'customerId', '')
    )}${this.getLabelItem(_.get(val, 'ssn', ''))}${this.getLabelItem(_.get(val, 'email', ''))}`;

  handleResponse = (response: SearchPersonResponse) => {
    let options: SearchPersonOption[] = [];
    if (response.result) {
      options = response.result.map((val) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const invalidFields = getInvalidCrmPersonFields(val);
        return {
          value: val.customerId,
          label: this.getLabel(val),
          disabled: invalidFields !== '',
          subLabel: `${translate('newContract.missingFieldsLabel')} ${invalidFields}`,
          data: val,
        };
      });
    }
    return options;
  };

  optionRenderer(option: SearchPersonOption) {
    return (
      <div>
        <div>{option.label}</div>
        {option.disabled ? (
          <div
            style={{
              fontStyle: 'italic',
              fontSize: '.85rem',
            }}
          >
            {option.subLabel}
          </div>
        ) : undefined}
      </div>
    );
  }

  render() {
    const { valid, profileEditor, field, openInfo, locale, required, index, value, contractLanguage } = this.props;

    let iconClass;
    if (valid === true) {
      iconClass = 'icon icon-check';
    } else if (valid === false) {
      iconClass = 'icon icon-exclamation';
    } else {
      iconClass = '';
    }

    const indicator = (
      <div className={styles.indicator}>
        <i className={iconClass} />
      </div>
    );
    const label = (
      <div className={styles.label}>
        {profileEditor ? undefined : indicator}{' '}
        {required ? `${field.title[contractLanguage]} *` : field.title[contractLanguage]}
      </div>
    );

    let component = (
      <div className={fieldStyles.componentContainer}>
        <div
          className={classnames(styles.arrayQuestionContainer, this.state.hasFocus ? styles.hasFocus : '')}
          tabIndex={index}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          <div className={fieldStyles.select}>
            <label>{label}</label>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <Select.Async
              loadOptions={this.search}
              onChange={this.onChange}
              value={value ? value.customerId : ''}
              ignoreAccents={false}
              placeholder={translate('order.search', { locale: contractLanguage })}
              noResultsText={translate('newContract.noResultsText', { locale: contractLanguage })}
              clearValueText={translate('order.clearValueText', { locale: contractLanguage })}
              searchingText={translate('order.searchingText', { locale: contractLanguage })}
              searchPromptText={
                field.info
                  ? field.info[contractLanguage]
                  : translate('order.searchPromptText', { locale: contractLanguage })
              }
              loadingPlaceholder={translate('order.loadingPlaceholder', { locale: contractLanguage })}
              optionRenderer={this.optionRenderer}
              autoload={false}
              cache={false}
              onSelectResetsInput={false}
              onBlurResetsInput={false}
              inputProps={{ 'data-testkey': field.key }}
            />
          </div>
          <div className={classnames('row', styles.answerBlock)}>
            <div className="columns small-10 small-offset-1">
              <Person data={value} />
            </div>
          </div>
        </div>
        {field.info ? (
          <FieldInfo
            onClick={openInfo}
            title={field.title[locale]}
            info={field.info[locale]}
            className={fieldStyles.infoBtn}
          />
        ) : undefined}
      </div>
    );

    if (!profileEditor) {
      component = wrapElement(component, styles, valid, index);
    }
    return component;
  }
}
