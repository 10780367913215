const identityOptions = {
  methods: [
    // Old document types are marked disabled so that new documents cannot be uploaded using them.
    { value: 'DriverLicence', translateKey: 'customer.driversLicense', disabled: true },
    { value: 'Passport', translateKey: 'customer.passport', disabled: true },
    { value: 'IdentityCard', translateKey: 'customer.idCard', disabled: true },
    { value: 'SocialInsuranceCardWithPhoto', translateKey: 'customer.SocialInsuranceCardWithPhoto', disabled: true },
    { value: 'Other', translateKey: 'customer.Other', disabled: true },

    { value: 'FinnishDriverLicence', translateKey: 'customer.identificationDocumentTypes.finnishDriverLicence' },
    { value: 'FinnishIdentityCard', translateKey: 'customer.identificationDocumentTypes.finnishIdentityCard' },
    { value: 'FinnishPassport', translateKey: 'customer.identificationDocumentTypes.finnishPassport' },
    { value: 'ForeignIdentityCard', translateKey: 'customer.identificationDocumentTypes.foreignIdentityCard' },
    { value: 'ForeignPassport', translateKey: 'customer.identificationDocumentTypes.foreignPassport' },
  ],
};

export default identityOptions;
