import React from 'react';
import { IndexRoute, Route, EnterHook, Redirect } from 'react-router';
import { Store } from 'redux';
import { CUSTOMER_TYPE } from 'constants/newCustomer';
import AppRoot from 'containers/AppRoot';
import ContractsView from 'features/contracts/ContractsView';
import { HomeView } from 'features/home/HomeView';
import SearchView from 'features/search/SearchView';
import ProfileView from 'features/profile/ProfileView';
import ProfileEditorView from 'features/profileEdit/ProfileEditorView';
import SummaryView from 'features/profileEdit/SummaryView';
import NewCustomerView from 'features/prospect/NewCustomerView';
import CallbackPage from 'features/oidc-callback/CallbackPage';
import { PortfolioView } from 'features/portfolio/PortfolioView';
import { PortfolioDetailsView } from 'features/portfolio/PortfolioDetailsView';
import { OrdersPreviewView } from 'features/orders/OrdersPreviewView';
import ContractQuestionsView from 'features/contracts/ContractQuestionsView';
import ContractsOverviewView from 'features/contracts/contractsOverview/ContractsOverviewView';
import ContractPreviewView from 'features/contracts/contractPreview/ContractPreviewView';
import ContractSignaturesView from 'features/contracts/ContractSignaturesView';
import CustomerTypeView from 'features/prospect/components/CustomerTypeView';
import CustomerGroupView from 'features/prospect/components/CustomerGroupView';
import CustomerIdentificationView from 'features/prospect/components/CustomerIdentificationView';
import { customerTypeFailure } from 'features/prospect/prospectActions';
import OrderListView from 'features/orderList/OrderListView';
import ContractListView from 'features/contractList/ContractListView';
import MeetingNoteView from 'features/meetingNotes/MeetingNoteView';
import { UnauthorizedView } from 'features/common/UnauthorizedView';
import { OrderSummaryView } from 'features/orders/components/OrderSummaryView';
import { OrdersSentView } from 'features/orders/OrdersSentView';
import { ConstraintsView } from 'features/allocator/constraints/ConstraintsView';
import { RiskSelectionView } from 'features/allocator/riskSelection/RiskSelectionView';
import { InstrumentsView } from 'features/allocator/instruments/InstrumentsView';
import { CoreSelectionView } from 'features/allocator/coreSelection/CoreSelectionView';
import { AllocatorRoot } from 'features/allocator/AllocatorRoot';
import { AllocatorView } from 'features/allocator/AllocatorView';
import { PlanPortfoliosView } from 'features/allocator/planPortfolios/PlanPortfoliosView';
import { PortfolioManagerRoot } from 'features/portfolioManager/PortfolioManagerRoot';
import { PortfolioManagerView } from 'features/portfolioManager/PortfolioManagerView';
import { TradeView } from 'features/portfolioManager/trade/TradeView';
import { CustomerListView } from 'features/portfolioManager/customerList/CustomerListView';
import { ProfileRoot } from 'features/profile/ProfileRoot';
import { ContractSelectionView } from 'features/contracts/ContractSelectionView';
import { PlanSummaryView } from 'features/allocator/overview/components/PlanSummaryView';
import { SituationSummaryView } from 'features/portfolioManager/customerSummary/SituationSummaryView';
import { CustomerListRoot } from 'features/portfolioManager/customerList/CustomerListRoot';

export const getRoutes = (store: Store) => {
  const onIdentityEnter: EnterHook = () => {
    const { prospects } = store.getState();
    if (!prospects.formValues.getIn(CUSTOMER_TYPE.split('.'))) {
      store.dispatch(customerTypeFailure());
    }
  };

  /*
   *
   * Root component: Shared root component for all child components
   * View component: Top level component which is not root component
   *
   */
  return (
    <>
      <Route path="callback" component={CallbackPage} />

      <Route path="/" component={AppRoot}>
        <IndexRoute component={HomeView} />

        <Route path="new" component={NewCustomerView}>
          <IndexRoute component={CustomerTypeView} />
          <Route path="type" component={CustomerTypeView} />
          <Route path="categorisation" component={CustomerGroupView} />
          <Route path="identity" component={CustomerIdentificationView} onEnter={onIdentityEnter} />
        </Route>

        <Route path="mycustomers" component={CustomerListRoot}>
          <IndexRoute component={CustomerListView} />
        </Route>

        <Route path="customer" component={ProfileRoot}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <Route path=":id" component={ProfileView} />
          <Route path=":id/edit" component={ProfileEditorView} />
          <Route path=":id/summary" component={SummaryView} />
          <Route path=":id/appointment" component={MeetingNoteView} />

          <Route path=":id/portfoliomanager" component={PortfolioManagerRoot}>
            <IndexRoute component={PortfolioManagerView} />
            <Route path="trade" component={TradeView} />
            <Route path="situationsummary" component={SituationSummaryView} />
            <Route path="allocator" component={AllocatorRoot}>
              <IndexRoute component={AllocatorView} />
              <Route path="constraints" component={ConstraintsView} />
              <Route path="core" component={CoreSelectionView} />
              <Route path="instruments" component={InstrumentsView} />
              <Route path="plansummary" component={PlanSummaryView} />
              <Route path="portfolios" component={PlanPortfoliosView} />
              <Route path="risk" component={RiskSelectionView} />
            </Route>
          </Route>

          <Route path=":id/contracts" component={ContractsView}>
            <IndexRoute component={ContractSelectionView} />
            <Route path="selection" component={ContractSelectionView} />
            <Route path="overview" component={ContractsOverviewView} />
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <Route path="signatures" component={ContractSignaturesView} />
            <Route path="preview" component={ContractPreviewView} />
            <Route path=":contract/m/:module" component={ContractQuestionsView} />
          </Route>

          <Route path=":id/portfolio" component={PortfolioView}>
            <IndexRoute component={PortfolioDetailsView} />
            <Route path=":portfolioId" component={PortfolioDetailsView} />

            <Route path="order" component={OrderSummaryView}>
              <Route path="preview" component={OrdersPreviewView} />
              <Route path="sent" component={OrdersSentView} />
            </Route>
          </Route>
        </Route>

        <Route path="search" component={SearchView} />
        <Route path="contracts" component={ContractListView} />
        <Route path="orders" component={OrderListView} />
        <Route path="unauthorized" component={UnauthorizedView} />
      </Route>

      <Redirect from="*" to="/" />
    </>
  );
};
