import React from 'react';
import classnames from 'classnames';
import translate from 'counterpart';
import SavedFilter from './SavedFilter';
import styles from './SavedFilters.scss';
import { SearchFiltersType } from 'types/orderListState';

interface Props {
  savedFilters: SearchFiltersType[];
  setSearchFilters: (filters: SearchFiltersType) => void;
  deleteSavedFilter: () => void;
}

export const SavedFilters = ({ savedFilters, setSearchFilters, deleteSavedFilter }: Props) => {
  let savedFiltersContent;
  if (savedFilters.length > 0) {
    savedFiltersContent = savedFilters.map((filter, index) => (
      <SavedFilter
        filter={filter}
        key={index}
        setSearchFilters={setSearchFilters}
        deleteSavedFilter={deleteSavedFilter}
      />
    ));
  } else {
    savedFiltersContent = <div className={styles.info}>{translate(`orderList.noSavedSearches`)}</div>;
  }

  return (
    <div className={classnames('row', styles.row)}>
      <div className="column small-12">{savedFiltersContent}</div>
    </div>
  );
};
