import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { pickBy } from 'lodash';
import styled from 'styled-components';
import { selectPlanState, hasRiskConflict, isLoadingPlan } from 'features/allocator/allocatorSelectors';
import { optimizeCurrent, optimize } from 'features/weights/weightsThunks';
import { allocatorWidth, GlobalStyles } from 'features/common/StyledComponents';
import { getPortfolioManagerUserData } from 'features/portfolioManager/commonData/commonDataActions';
import {
  convertExistingPositionsToPlan,
  flagCheckPortfolios,
  getInvestmentPlan,
} from 'features/allocator/investmentPlan/investmentPlanActions';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';
import { customerHasCurrentWeights, customerHasOptimizedWeights } from 'features/weights/weightsSelectors';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Header } from 'features/portfolioManager/components/Header';
import { selectCustomerId, selectIsDemoCustomer } from 'features/profile/profileSelectors';
import { useAppDispatch } from 'core/hooks';

interface Props {
  children: object;
  params: { id: string };
}

export const PortfolioManagerRoot = ({ params, children }: Props) => {
  const dispatch = useAppDispatch();

  const customerId = params.id;

  const profileLoaded = !!useSelector(selectCustomerId);

  const hasCurrentWeights = useSelector(customerHasCurrentWeights);
  const loadingPlan = useSelector(isLoadingPlan);
  const planState = useSelector(selectPlanState);
  const isDemoCustomer = useSelector(selectIsDemoCustomer);

  const hasOptimizedWeightsWith = useSelector(customerHasOptimizedWeights('withIlliquids'));
  const hasOptimizedWeightsWithout = useSelector(customerHasOptimizedWeights('withoutIlliquids'));
  const hasNoOptimizedWeights = !hasOptimizedWeightsWith && !hasOptimizedWeightsWithout;

  const [loadingBasicStuff, setLoadingBasicStuff] = useState(true);
  const [optimizeCurrentDone, setOptimizeCurrentDone] = useState<boolean>(false);

  const hasCurrentWeightsRef = useRef<boolean>(false);
  const hasNoOptimizedWeightsRef = useRef<boolean>(false);

  const portfolioDetailsById = useSelector(selectPortfolioDetailsById);
  const filteredPortfolioDetailsById = pickBy(portfolioDetailsById, (portfolio) =>
    portfolio.portfolioId.startsWith(customerId)
  );
  const hasPortfolios = Object.keys(filteredPortfolioDetailsById).length > 0;

  // Store initial hasCurrentWeights value to ref
  useEffect(() => {
    hasCurrentWeightsRef.current = hasCurrentWeights;
    setOptimizeCurrentDone(hasCurrentWeights);
  }, [hasCurrentWeights]);

  // Store initial hasNoOptimizedWeights value to ref
  useEffect(() => {
    hasNoOptimizedWeightsRef.current = hasNoOptimizedWeights;
  }, [hasNoOptimizedWeights]);

  // On mount: Load assetClasses, portolios and positions
  useEffect(() => {
    const getUserData = async () => {
      await dispatch(getPortfolioManagerUserData(customerId));
      setLoadingBasicStuff(false);
    };
    void getUserData();
  }, []);

  // After first load: Load investment plan
  // We must wait for profile because it might include risk level, which is needed in storeInvestmentPlanToStore
  useEffect(() => {
    const loadData = async () => {
      if (!loadingBasicStuff && planState === 'notLoaded' && !loadingPlan && profileLoaded && !isDemoCustomer) {
        await dispatch(getInvestmentPlan(customerId));
      }
    };
    void loadData();
  }, [loadingBasicStuff, planState, loadingPlan, profileLoaded]);

  // After loading plan: Optimize current positions
  useEffect(() => {
    // Note: watching for 'planState' is not reliable because it is set before planPortfolios is set, and planPortfolios is needed in optimizeCurrent
    if (
      !loadingBasicStuff &&
      hasPortfolios &&
      !hasCurrentWeightsRef.current &&
      planState !== 'notLoaded' &&
      !loadingPlan
    ) {
      dispatch(optimizeCurrent({ customerId }));
    }
  }, [loadingBasicStuff, hasPortfolios, planState, loadingPlan]);

  // After investment plan: CASE 1) No plan
  useEffect(() => {
    if (planState === 'noPlan' && hasCurrentWeightsRef.current && profileLoaded && hasNoOptimizedWeightsRef.current) {
      dispatch(convertExistingPositionsToPlan());
      dispatch(optimize());
    }
  }, [optimizeCurrentDone, planState, profileLoaded]);

  // After investment plan: CASE 2) Has a plan
  const hasPlan = planState !== 'noPlan' && planState !== 'notLoaded';
  const riskConflict = useSelector(hasRiskConflict);
  useEffect(() => {
    if (profileLoaded && hasPlan && hasNoOptimizedWeightsRef.current) {
      dispatch(optimize());
      if (riskConflict) {
        dispatch(flagCheckPortfolios(true));
      }
    }
  }, [hasPlan, profileLoaded, riskConflict]);

  return (
    <>
      <GlobalStyles />
      <ErrorBoundary>
        <Header id={params.id} />
        <Container>{children}</Container>
      </ErrorBoundary>
    </>
  );
};

const Container = styled.div`
  width: ${allocatorWidth};
  margin: 0 auto 2rem;
`;
