import { push } from 'react-router-redux';
import { uploadFile } from 'actions/index';
import { USER_SESSION_RESET } from 'features/contracts/contractsActions';
import { apiCall, makeBlobUrl } from 'core/apiCall';
import { setError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { AppThunk } from 'types/types';
import { Info } from 'actions/indexActionsTypes';
import {
  getContractDrafts,
  getContractsApiCall,
  getCustomerImages,
  getCustomerProfile,
  demoCustomer,
} from 'features/profile/profileUtils';
import { BlobInfo, Customer } from 'types/profileState';
import { GetContractDraftsResponse, GetContractsResponse } from 'features/profile/profileTypes';
import { DEMO_CUSTOMER } from 'constants/common';

// Action types

export const CUSTOMER_CONTRACTS_REQUEST = 'CUSTOMER_CONTRACTS_REQUEST';
export const CUSTOMER_CONTRACTS_SUCCESS = 'CUSTOMER_CONTRACTS_SUCCESS';
const CUSTOMER_CONTRACTS_FAILURE = 'CUSTOMER_CONTRACTS_FAILURE';
export const CUSTOMER_UPLOAD_IMAGE_REQUEST = 'CUSTOMER_UPLOAD_IMAGE_REQUEST';
export const CUSTOMER_UPLOAD_IMAGE_SUCCESS = 'CUSTOMER_UPLOAD_IMAGE_SUCCESS';
export const CUSTOMER_UPLOAD_IMAGE_FAILURE = 'CUSTOMER_UPLOAD_IMAGE_FAILURE';
const CUSTOMER_IMAGES_REQUEST = 'CUSTOMER_IMAGES_REQUEST';
export const CUSTOMER_IMAGES_SUCCESS = 'CUSTOMER_IMAGES_SUCCESS';
const CUSTOMER_IMAGES_FAILURE = 'CUSTOMER_IMAGES_FAILURE';
export const CUSTOMER_PDF_CONTRACT_REQUEST = 'CUSTOMER_PDF_CONTRACT_REQUEST';
export const CUSTOMER_PDF_CONTRACT_SUCCESS = 'CUSTOMER_PDF_CONTRACT_SUCCESS';
export const CUSTOMER_DRAFT_REQUEST = 'CUSTOMER_DRAFT_REQUEST';
export const CUSTOMER_DRAFT_SUCCESS = 'CUSTOMER_DRAFT_SUCCESS';
export const CUSTOMER_DRAFT_FAILURE = 'CUSTOMER_DRAFT_FAILURE';
export const CUSTOMER_PROFILE_REQUEST = 'CUSTOMER_PROFILE_REQUEST';
export const CUSTOMER_PROFILE_SUCCESS = 'CUSTOMER_PROFILE_SUCCESS';
export const CUSTOMER_PROFILE_FAILURE = 'CUSTOMER_PROFILE_FAILURE';
export const CUSTOMER_DELETE_ID_IMAGE_REQUEST = 'CUSTOMER_DELETE_ID_IMAGE_REQUEST';
const CUSTOMER_DELETE_ID_IMAGE_SUCCESS = 'CUSTOMER_DELETE_ID_IMAGE_SUCCESS';
const CUSTOMER_DELETE_ID_IMAGE_FAILURE = 'CUSTOMER_DELETE_ID_IMAGE_FAILURE';
export const SET_SELECTED_RISK_LEVEL = 'SET_SELECTED_RISK_LEVEL';
export const SET_FETCHING_CONTRACT = 'SET_FETCHING_CONTRACT';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';

// Sync actions

export const resetCustomer = () => {
  return <const>{
    type: RESET_CUSTOMER,
  };
};

const getCustomerRequest = () => {
  return <const>{
    type: CUSTOMER_PROFILE_REQUEST,
  };
};

const getCustomerSuccess = (result: Customer) => {
  return <const>{
    type: CUSTOMER_PROFILE_SUCCESS,
    result,
  };
};

const getCustomerFailure = () => {
  return <const>{
    type: CUSTOMER_PROFILE_FAILURE,
  };
};

export const setFetchingContract = (value: boolean) => {
  return <const>{
    type: SET_FETCHING_CONTRACT,
    value,
  };
};

export const newCustomerContract = () => {
  return <const>{
    type: USER_SESSION_RESET,
  };
};

const customerContractRequest = () => {
  return <const>{
    type: CUSTOMER_CONTRACTS_REQUEST,
  };
};

const customerContractSuccess = (result: GetContractsResponse) => {
  return <const>{
    type: CUSTOMER_CONTRACTS_SUCCESS,
    result,
  };
};

const customerContractFailure = () => {
  return <const>{
    type: CUSTOMER_CONTRACTS_FAILURE,
  };
};

const customerDraftRequest = () => {
  return <const>{
    type: CUSTOMER_DRAFT_REQUEST,
  };
};

const customerDraftSuccess = (result: GetContractDraftsResponse) => {
  return <const>{
    type: CUSTOMER_DRAFT_SUCCESS,
    result,
  };
};

const customerDraftFailure = () => {
  return <const>{
    type: CUSTOMER_DRAFT_FAILURE,
  };
};

const customerImagesRequest = () => {
  return <const>{
    type: CUSTOMER_IMAGES_REQUEST,
  };
};

const customerImagesSuccess = (result: object[]) => {
  return <const>{
    type: CUSTOMER_IMAGES_SUCCESS,
    result,
  };
};

const customerImagesFailure = () => {
  return <const>{
    type: CUSTOMER_IMAGES_FAILURE,
  };
};

export type ProfileAction = ReturnType<
  | typeof resetCustomer
  | typeof getCustomerRequest
  | typeof getCustomerSuccess
  | typeof getCustomerFailure
  | typeof setFetchingContract
  | typeof newCustomerContract
  | typeof customerContractRequest
  | typeof customerContractSuccess
  | typeof customerContractFailure
  | typeof customerDraftRequest
  | typeof customerDraftSuccess
  | typeof customerDraftFailure
  | typeof customerImagesRequest
  | typeof customerImagesSuccess
  | typeof customerImagesFailure
>;

// Async actions

export const getContracts = (customerId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(customerContractRequest());
    try {
      const token = getState().oidc.user.access_token;
      const result = await getContractsApiCall(customerId, token);
      return dispatch(customerContractSuccess(result));
    } catch (error) {
      dispatch(customerContractFailure());
    }
  };
};

export const loadContractDrafts = (customerId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(customerDraftRequest());
    try {
      const token = getState().oidc.user.access_token;
      const result = await getContractDrafts(customerId, token);
      return dispatch(customerDraftSuccess(result));
    } catch (error) {
      dispatch(customerDraftFailure());
    }
  };
};

export const loadCustomerProfile = (customerId: string): AppThunk<Promise<Customer>> => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return async (dispatch, getState) => {
    dispatch(getCustomerRequest());
    try {
      const token = getState().oidc.user.access_token;
      const result = await getCustomerProfile(customerId, token);
      return dispatch(getCustomerSuccess(result));
    } catch (error) {
      dispatch(setError({ context: errorKeys.loadCustomerProfile }));
      dispatch(getCustomerFailure());
    }
  };
};

export const getCustomer = (customerId: string): AppThunk => {
  return async (dispatch) => {
    if (customerId === DEMO_CUSTOMER) {
      dispatch(resetCustomer());
      return dispatch(getCustomerSuccess(demoCustomer));
    } else {
      await dispatch(loadCustomerProfile(customerId));
      await Promise.all([dispatch(getContracts(customerId)), dispatch(loadContractDrafts(customerId))]);
    }
  };
};

export const uploadImage = (file: File, info: Info, id: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch({ type: CUSTOMER_UPLOAD_IMAGE_REQUEST });
    return uploadFile('image', file, info, id, getState().oidc.user.access_token)
      .then(() => {
        dispatch({ type: CUSTOMER_UPLOAD_IMAGE_SUCCESS });
      })
      .catch(() => {
        dispatch({ type: CUSTOMER_UPLOAD_IMAGE_FAILURE });
      });
  };
};

export const fetchImages = (customerId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(customerImagesRequest());
    try {
      const token = getState().oidc.user.access_token;
      const result = await getCustomerImages(customerId, token);
      return dispatch(customerImagesSuccess(result));
    } catch (error) {
      dispatch(customerImagesFailure());
    }
  };
};

export const copyContract = (customerId: string, contractId: string): AppThunk => {
  return (dispatch) => {
    dispatch(newCustomerContract());
    return dispatch(push({ pathname: `/customer/${customerId}/contracts/overview`, state: { copy: contractId } }));
  };
};

export const getImageforPreview = (filepath: string): AppThunk<Promise<BlobInfo>> => {
  return (_dispatch, getState) =>
    apiCall({
      method: 'get',
      token: getState().oidc.user.access_token,
      path: `/api/v1/client/download${filepath}`,
      responseType: 'blob',
      responseResolver: (res) => {
        return {
          contentType: res.xhr.getResponseHeader('content-type'),
          src: window.URL.createObjectURL(res.xhr.response),
        };
      },
    });
};

export const getContractPdf = (id: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch({ type: CUSTOMER_PDF_CONTRACT_REQUEST, id });
    return apiCall({
      method: 'get',
      token: getState().oidc.user.access_token,
      path: `/api/v1/contract/pdffile/${id}`,
      responseType: 'blob',
      responseResolver: (res) => res.xhr.response,
    }).then((blob) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      makeBlobUrl(blob, (blobUrl) => dispatch({ type: CUSTOMER_PDF_CONTRACT_SUCCESS, id, blobUrl }));
    });
  };
};

export const deleteIdentityImage = (customerId: string, imageId: string): AppThunk => {
  return async (dispatch, getState) => {
    dispatch({ type: CUSTOMER_DELETE_ID_IMAGE_REQUEST, id: imageId });
    try {
      await apiCall({
        method: 'delete',
        token: getState().oidc.user.access_token,
        path: `/api/v1/client/identityimages/${customerId}/${imageId}`,
      });
      dispatch({ type: CUSTOMER_DELETE_ID_IMAGE_SUCCESS });
    } catch (error) {
      dispatch({ type: CUSTOMER_DELETE_ID_IMAGE_FAILURE, error });
      dispatch(fetchImages(customerId));
    }
  };
};
