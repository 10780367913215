import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const StyledButton = styled(Button)({
  color: '#000000',
  height: '36px',
  fontFamily: 'Calibre',
  fontWeight: '400',
  fontSize: '14px',
  borderRadius: 0,
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}) as typeof Button;

export const StyledAddAttachmentButton = styled(Button)({
  color: '#000000',
  height: '36px',
  fontSize: '14px',
  borderRadius: 0,
  ':hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
}) as typeof Button;

export default StyledButton;
