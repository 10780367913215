import React from 'react';
import translate from 'counterpart';
import classnames from 'classnames';
import moment from 'moment';
import { ACCOUNT } from 'constants/customerStates';
import { EXPIRY_ALERT_DAYS, EXPIRY_WARNING_DAYS, selectExpirationDate } from 'constants/contracts';
import styles from './User.scss';
import { CustomerState } from 'types/types';
import iconDigiCustomer from 'images/icon_digi_customer.svg';

function getUpdateDate(
  basicContractSigned: string,
  state: CustomerState,
  expiresTranslationKey: string,
  hasExpiredTranslationKey: string,
  notDoneTranslationKey: string,
  calculateExpirationDate: boolean,
  mytyContractSigned?: string
) {
  if (state !== ACCOUNT) {
    return null;
  }

  const expDate = selectExpirationDate(calculateExpirationDate, basicContractSigned, mytyContractSigned);
  const daysUntilExpiry = expDate?.daysUntilExpiry;

  if (!daysUntilExpiry) {
    return <p className={classnames(styles.hasError)}>{translate('customer.' + notDoneTranslationKey)}</p>;
  }

  const expiryDate = expDate?.expirationDate;
  const expiryDateStr = expDate?.expirationDateFormatted;

  if (expiryDate?.date?.isBefore(moment())) {
    return (
      <p className={classnames(styles.hasError, styles.hasBlink)}>
        {translate('customer.' + hasExpiredTranslationKey)} {expiryDateStr}&nbsp;
        <i className="icon icon-exclamation" title={expDate?.expirationDate?.disclaimer as string} />
      </p>
    );
  }

  if (daysUntilExpiry <= EXPIRY_ALERT_DAYS) {
    return (
      <p className={classnames(styles.hasError, styles.hasBlink)}>
        {translate('customer.' + expiresTranslationKey)} {expiryDateStr}&nbsp;
        <i className="icon icon-exclamation" title={expDate?.expirationDate?.disclaimer as string} />
      </p>
    );
  }

  if (daysUntilExpiry <= EXPIRY_WARNING_DAYS) {
    return (
      <p className={classnames(styles.hasError)}>
        {translate('customer.' + expiresTranslationKey)} {expiryDateStr}&nbsp;
        <i className="icon icon-exclamation" title={expDate?.expirationDate?.disclaimer as string} />
      </p>
    );
  }

  return (
    <p className={classnames(styles.hasEmphasize)}>
      {translate('customer.' + expiresTranslationKey)} {expiryDateStr}&nbsp;
      <i className="icon icon-check" title={expDate?.expirationDate?.disclaimer as string} />
    </p>
  );
}

export interface Props {
  name: string;
  description: string;
  basicContractSigned: string;
  mytyContractSigned: string;
  state: CustomerState;
  isDigiCustomer: boolean;
  nextKycUpdate: string;
}

export const User = (props: Props) => {
  const { name, description, basicContractSigned, state, isDigiCustomer, nextKycUpdate, mytyContractSigned } = props;

  const iconClass = 'icon icon-person';
  const customerIcon = isDigiCustomer ? <img src={iconDigiCustomer} /> : <i className={iconClass} />;
  return (
    <div className={styles.user}>
      <div className="media-object">
        <div className="media-object-section top">{customerIcon}</div>
        <div className="media-object-section">
          <h4>{name}</h4>
          <p>{description}</p>
          {getUpdateDate(
            basicContractSigned,
            state,
            'basicContractExpires',
            'basicContractHasExpired',
            'basicContractNotSigned',
            true,
            mytyContractSigned
          )}
          {getUpdateDate(
            nextKycUpdate,
            state,
            'nextKycUpdate',
            'nextKycUpdateHasExpired',
            'nextKycUpdateMissing',
            false
          )}
        </div>
      </div>
    </div>
  );
};
