import React from 'react';
import { Link } from 'react-router';
import { Set } from 'immutable';
import { Accordion } from '@mui/material';
import translate from 'counterpart';
import styled from 'styled-components';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import styles from './Overview.scss';
import Status from './Status';
import { getModuleStatus } from 'core/index';
import OverviewItemTitle from './OverviewItemTitle';
import { ImmutableContent } from 'types/profileState';
import { ImmutableFormErrors, Locale } from 'types/contractsState';
import { FieldType, Template } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';
import { openSuitabilityAssesment } from 'features/contracts/contractsActions';

interface Props {
  contract: Template;
  values: ImmutableContent;
  formTouched: Set<string>;
  formErrors: ImmutableFormErrors;
  activeContract: string;
  params: { id: string };
  setActiveContract: (contract: string) => void;
  contractLanguage: Locale;
  isSuitability?: boolean;
}

const ContractItem = ({
  contract,
  values,
  params,
  formTouched,
  formErrors,
  activeContract,
  setActiveContract,
  contractLanguage,
  isSuitability = false,
}: Props) => {
  const contractType = contract.contractType;
  let totalCount = 0;
  let totalCompleted = 0;
  const modules = isSuitability
    ? []
    : contract.form.items.map((section, index, sections) => {
        const moduleStatus = getModuleStatus(contractType, formTouched, formErrors, section, values, sections);
        const moduleHasNoFields = !moduleStatus.fieldsVisible || moduleStatus.fieldsVisible.length === 0;
        const linkTypeKey = 'textLinkKey';
        const tableKey = 'tableKey';

        const useVisibleLinksFilteringFeature = true;
        let isEmptySection = false;

        if (useVisibleLinksFilteringFeature) {
          const linkFields = moduleStatus.fieldsDone.filter((f: FieldType) => f.key && f.key.includes(linkTypeKey));
          let hasLinkFields = false;

          if (linkFields) {
            const visibleLinks = linkFields.filter(
              (f: FieldType) => f.linkVisible && f.linkVisible[contractLanguage] === true
            ) as [];
            hasLinkFields = !!(visibleLinks && visibleLinks.length > 0);
          }

          if (moduleHasNoFields && !hasLinkFields) {
            isEmptySection = true;
          }

          const hasTitle = !!section.title[contractLanguage];
          if (!hasTitle) {
            return undefined;
          }
        } else {
          if (moduleHasNoFields) {
            return undefined;
          }
        }

        const moduleLink = isEmptySection ? '' : `customer/${params.id}/contracts/${contractType}/m/${section.key}`;
        const doneFiltered = moduleStatus.fieldsDone.filter(
          (f: FieldType) => f.key && !f.key.includes(linkTypeKey) && !f.key.includes(tableKey)
        );
        const countFiltered = moduleStatus.fieldsVisible.filter(
          (f: FieldType) => f.key && !f.key.includes(linkTypeKey) && !f.key.includes(tableKey)
        );
        const done = doneFiltered.length;
        const count = countFiltered.length;
        totalCompleted += done;
        totalCount += count;
        const sectionClass = done === count ? styles.done : styles.wip;

        const isRiskConfirmationStep = section.key === 'sections.riskConfirmation';
        return (
          <li className={sectionClass} key={section.key} data-testkey={`contract-section-${section.title.fi}`}>
            <Link to={moduleLink}>
              <div>
                <span className={styles.circle} />
                <span>{section.title[contractLanguage]}</span>
              </div>

              <div>
                {!isRiskConfirmationStep && count !== 0 && <span>{`${done}/${count}`}</span>}
                <i className="icon icon-nuoli" />
                <div className={styles.indicator}>
                  <i className="icon icon-check" />
                </div>
              </div>
            </Link>
          </li>
        );
      });
  const dispatch = useAppDispatch();
  const goToSuitabilityAssesment = () => {
    dispatch(openSuitabilityAssesment());
  };
  return (
    <Accordion
      className={'accordion-title'}
      expanded={contractType === activeContract}
      data-testkey={`contract-${contract.contractType}`}
    >
      <OverviewItemTitle
        className={styles.header}
        onClick={isSuitability ? goToSuitabilityAssesment : setActiveContract}
        contractType={contractType}
      >
        <div>
          <i className={'icon icon-document'} />
        </div>
        <span className={styles.label}>
          {contract.schema.names && contract.schema.names.length > 1 && contractLanguage === 'sv'
            ? contract.schema.names[1]
            : contract.schema.name}
        </span>
        {isSuitability ? (
          <SuitabilityLinkContainer onClick={() => goToSuitabilityAssesment()}>
            <SuitabilityLinkTexts>
              <SuitabilityLinkTitle>Siirry arviointiin klikkaamalla</SuitabilityLinkTitle>
              <br />
              <SuitabilityLinkText>(y-tunnus/henkilötunnus kopioidaan leikepöydällesi)</SuitabilityLinkText>
            </SuitabilityLinkTexts>
            <SuitabilityLinkIconContainer>
              <ContentPasteGoIcon />
            </SuitabilityLinkIconContainer>
          </SuitabilityLinkContainer>
        ) : (
          <Status
            label={translate('contract.answered', { locale: contractLanguage })}
            total={totalCount}
            completed={totalCompleted}
          />
        )}
      </OverviewItemTitle>
      {!isSuitability && <ul className={styles.modules}>{modules}</ul>}
    </Accordion>
  );
};

const SuitabilityLinkContainer = styled.span`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40rem;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 4px;
  padding-bottom: 4px;
`;

const SuitabilityLinkTexts = styled.span``;

const SuitabilityLinkTitle = styled.span`
  font-size: 16px;
`;

const SuitabilityLinkText = styled.span`
  font-size: 14px;
`;

const SuitabilityLinkIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ContractItem;
