import React from 'react';
import { Link } from 'react-router';
import translate from 'counterpart';
import styles from './Locked.scss';

export const Locked = () => {
  return (
    <div className={styles.locked}>
      <p>{translate(`contract.lockedHeader`)}</p>
      <p>{translate(`contract.lockedMessage`)}</p>
      <Link className={'button'} to={'/'}>
        <span>{translate(`common.navigateToFrontPage`)}</span>
      </Link>
    </div>
  );
};
