import { push } from 'react-router-redux';
import { apiCall, makeBlobUrl } from 'core/apiCall';
import { SENT_FOR_BANKER_APPROVAL, FINISHED, SIGNATURE_REQUIRED } from 'constants/contractStates';
import { selectPortfolioIdsWithOrders, selectContractWithRowsByOrderLineIds } from 'features/orders/ordersSelectors';
import { AppThunk, SignatureMethodType } from 'types/types';
import {
  PostContractResponse,
  SignedContractResponse,
  Signers,
  BisnodeUpdate,
  SetInitialOrderDetailsParameters,
} from 'features/orders/ordersTypes';
import {
  ImmutableContract,
  OrderLineType,
  Signatory,
  OrderDetailsOrderType,
  Contract,
  Template,
} from 'types/ordersState';
import {
  CONSULTATIVE_AGREEMENT,
  DIGI_CONSULTATIVE_AGREEMENT,
  FULL_POWER_OF_ATTORNEY,
  NO_CONTRACT,
  OWN_FUND_CONTRACT,
  SECURITIES_BROKERAGE_CONTRACT,
  TAALERI_CONTRACT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
} from 'constants/contractNames';
import { getPdfForPreviewRequest, postContractForPreview } from 'features/contracts/contractsUtils';
import {
  getPortfolioParams,
  postContract,
  postSignedContract,
  postFinishedContract,
  getSigners,
  getBisnodeData,
  updateBisnodeData,
  getOrderRequest,
  getPrefilledContractApiCall,
  getOrdersContractTemplateApiCall,
} from 'features/orders/ordersUtils';
import { clearOrderLinesPortfolioId } from 'features/orderLines/orderLinesActions';
import { getSignatureMethod } from 'core/portfolios';
import { RootState } from 'types/rootState';
import { Countries } from 'types/contractsState';
import { SET_BASIS_OF_ADVICE } from 'features/orderDialog/orderDialogActions';

// Action types

export const ORDERS_PREVIEW_REQUEST = 'ORDERS_PREVIEW_REQUEST';
export const ORDERS_PREVIEW_SUCCESS = 'ORDERS_PREVIEW_SUCCESS';
export const ORDERS_PREVIEW_FAILURE = 'ORDERS_PREVIEW_FAILURE';
export const ORDERS_PDF_PREVIEW_REQUEST = 'ORDERS_PDF_PREVIEW_REQUEST';
export const ORDERS_PDF_PREVIEW_SUCCESS = 'ORDERS_PDF_PREVIEW_SUCCESS';
export const ORDERS_PDF_PREVIEW_FAILURE = 'ORDERS_PDF_PREVIEW_FAILURE';
const ORDER_PREFILLED_REQUEST = 'ORDER_PREFILLED_REQUEST';
export const ORDER_PREFILLED_SUCCESS = 'ORDER_PREFILLED_SUCCESS';
const ORDER_PREFILLED_FAILURE = 'ORDER_PREFILLED_FAILURE';
const ORDER_TEMPLATE_REQUEST = 'ORDER_TEMPLATE_REQUEST';
export const ORDER_TEMPLATE_RECEIVE = 'ORDER_TEMPLATE_RECEIVE';
const ORDER_TEMPLATE_FAILURE = 'ORDER_TEMPLATE_FAILURE';
export const ORDER_TEMPLATE_START_LOADING = 'ORDER_TEMPLATE_START_LOADING';
export const ORDER_TEMPLATE_FINISH_LOADING = 'ORDER_TEMPLATE_FINISH_LOADING';
export const ORDER_SIGNATURE_METHOD = 'ORDER_SIGNATURE_METHOD';
export const ORDER_MANUAL_SIGNATURES = 'ORDER_MANUAL_SIGNATURES';
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAILURE = 'ORDER_CREATE_FAILURE';
export const ORDER_PREFILL_SIGNATORY = 'ORDER_PREFILL_SIGNATORY';
export const ORDER_ADD_SIGNATORY = 'ORDER_ADD_SIGNATORY';
export const ORDER_REMOVE_SIGNATORY = 'ORDER_REMOVE_SIGNATORY';
export const CHANGE_CONTRACT_FIELD = 'CHANGE_CONTRACT_FIELD';
export const REMOVE_ORDERLINE_FROM_ORDER = 'REMOVE_ORDERLINE_FROM_ORDER';
export const PDF_ORDER_REQUEST = 'PDF_ORDER_REQUEST';
export const PDF_ORDER_SUCCESS = 'PDF_ORDER_SUCCESS';
export const INIT_ORDER_STATE = 'INIT_ORDER_STATE';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';
export const GET_ORDER_SIGNATORIES_FOR_CUSTOMER = 'GET_ORDER_SIGNATORIES_FOR_CUSTOMER';
export const BISNODE_ORDER_GET = 'BISNODE_ORDER_GET';
export const BISNODE_UPDATE_REQUEST = 'BISNODE_UPDATE_REQUEST';
export const BISNODE_UPDATE_SUCCESS = 'BISNODE_UPDATE_SUCCESS';
export const BISNODE_UPDATE_FAILURE = 'BISNODE_UPDATE_FAILURE';
export const ORDER_CREATE_AND_SIGN_REQUEST = 'ORDER_CREATE_AND_SIGN_REQUEST';
export const ORDER_CREATE_AND_SIGN_SUCCESS = 'ORDER_CREATE_AND_SIGN_SUCCESS';
export const ORDER_CREATE_AND_SIGN_FAILURE = 'ORDER_CREATE_AND_SIGN_FAILURE';
export const ORDER_CREATE_AND_SIGN_BACKGROUND_REQUEST = 'ORDER_CREATE_AND_SIGN_BACKGROUND_REQUEST';
export const ORDER_CREATE_AND_SIGN_BACKGROUND_SUCCESS = 'ORDER_CREATE_AND_SIGN_BACKGROUND_SUCCESS';
export const ORDER_CREATE_AND_SIGN_BACKGROUND_FAILURE = 'ORDER_CREATE_AND_SIGN_BACKGROUND_FAILURE';
export const ORDER_FUND = 'ORDER_FUND';
export const ORDER_STRUCTURED_PRODUCT = 'ORDER_STRUCTURED_PRODUCT';
export const ORDER_WARRANT = 'ORDER_WARRANT';
export const ORDER_PRIVATE_EQUITY = 'ORDER_PRIVATE_EQUITY';
export const ORDER_SHARE = 'ORDER_SHARE';
export const ORDER_BOND = 'ORDER_BOND';
export const SET_INITIAL_ORDER_DETAILS = 'SET_INITIAL_ORDER_DETAILS';
export const CLEAR_ORDER_DETAILS = 'CLEAR_ORDER_DETAILS';
export const REMOVE_ORDERLINE = 'REMOVE_ORDERLINE';

// Actions

export const getOrdersPreview = (orders: ImmutableContract): AppThunk => {
  return (dispatch, getState) => {
    const portfolioId = orders?.toJS().sections.receiveInfo?.portfolioId;
    const token = getState().oidc.user.access_token;
    const order = getState().orders.ordersByPortfolioId[portfolioId];

    order.orderDetails.map(async (details) => {
      const contract = selectContractWithRowsByOrderLineIds(getState(), portfolioId, details.orderLineIds).set(
        'state',
        details.signatureMethod
      );
      dispatch(ordersPreviewRequest(portfolioId, details.orderLineIds));
      try {
        const result = await postContractForPreview(contract.toJS(), token);
        dispatch(ordersPreviewReceive(result, portfolioId, details.orderLineIds));
        return result;
      } catch (error) {
        dispatch(ordersPreviewFailure(portfolioId, error, details.orderLineIds));
      }
    });
  };
};

const ordersPreviewRequest = (portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDERS_PREVIEW_REQUEST,
    portfolioId,
    orderLineIds,
  };
};

const ordersPreviewReceive = (result: string, portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDERS_PREVIEW_SUCCESS,
    result,
    portfolioId,
    orderLineIds,
  };
};

const ordersPreviewFailure = (portfolioId: string, error: Error, orderLineIds: number[]) => {
  return <const>{
    type: ORDERS_PREVIEW_FAILURE,
    portfolioId,
    error,
    orderLineIds,
  };
};

export const getPdfForPreview = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(ordersPdfPreviewRequest(portfolioId));
    getState().orders.ordersByPortfolioId[portfolioId].orderDetails.map(async (orderDetail) => {
      try {
        const result = await getPdfForPreviewRequest(
          orderDetail?.pdfPreviewLink.get('id'),
          getState().oidc.user.access_token
        );

        makeBlobUrl(result, (blobUrl) =>
          dispatch(
            ordersPdfPreviewSuccess(
              blobUrl,
              orderDetail?.pdfPreviewLink.get('id'),
              orderDetail.orderLineIds,
              portfolioId
            )
          )
        );
      } catch (error) {
        dispatch(
          ordersPdfPreviewFailure(error, orderDetail.orderLineIds, portfolioId, orderDetail?.pdfPreviewLink.get('id'))
        );
      }
    });
  };
};

const ordersPdfPreviewRequest = (portfolioId: string) => {
  return <const>{
    type: ORDERS_PDF_PREVIEW_REQUEST,
    portfolioId,
  };
};

const ordersPdfPreviewSuccess = (blobUrl: string, linkId: string, orderLineIds: number[], portfolioId: string) => {
  return <const>{
    type: ORDERS_PDF_PREVIEW_SUCCESS,
    blobUrl,
    linkId,
    orderLineIds,
    portfolioId,
  };
};

const ordersPdfPreviewFailure = (error: string, orderLineIds: number[], portfolioId: string, linkId: string) => {
  return <const>{
    type: ORDERS_PDF_PREVIEW_FAILURE,
    error,
    orderLineIds,
    portfolioId,
    linkId,
  };
};

export const getOrderPdf = (id: string, portfolioId: string, orderLineIds: number[]): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(pdfOrderRequest(id, portfolioId, orderLineIds));
    return (
      apiCall({
        method: 'get',
        token: getState().oidc.user.access_token,
        path: `/api/v1/contract/pdffile/${id}`,
        responseType: 'blob',
        responseResolver: (res) => res.xhr.response,
      }) as Promise<Blob>
    ).then((blob) => {
      makeBlobUrl(blob, (blobUrl) => dispatch(pdfOrderSuccess(blobUrl, id, orderLineIds, portfolioId)));
    });
  };
};

const pdfOrderRequest = (id: string, portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: PDF_ORDER_REQUEST,
    id,
    portfolioId,
    orderLineIds,
  };
};

const pdfOrderSuccess = (blobUrl: string, linkId: string, orderLineIds: number[], portfolioId: string) => {
  return <const>{
    type: PDF_ORDER_SUCCESS,
    blobUrl,
    linkId,
    orderLineIds,
    portfolioId,
  };
};

export const orderFund = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_FUND,
    defaultFee: state.orderDialog.defaultFee,
    statePortfolioId: params.portfolioId,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const orderStructuredProduct = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_STRUCTURED_PRODUCT,
    statePortfolioId: params.portfolioId,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const orderWarrant = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_WARRANT,
    statePortfolioId: params.portfolioId,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const orderPrivateEquity = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_PRIVATE_EQUITY,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const orderShare = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_SHARE,
    statePortfolioId: params.portfolioId,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const orderBond = (state: RootState, params: OrderLineType) => {
  return <const>{
    type: ORDER_BOND,
    statePortfolioId: params.portfolioId,
    ...params,
    ...getPortfolioParams(state, params.portfolioId),
  };
};

export const removeOrderLine = (orderId: number, portfolioId: string): AppThunk => {
  return (dispatch) => {
    dispatch(removeOrderlineFromOrder(orderId, portfolioId));
    dispatch(removeOrderLineAction(orderId));
  };
};

const removeOrderlineFromOrder = (orderId: number, portfolioId: string) => {
  return <const>{
    type: REMOVE_ORDERLINE_FROM_ORDER,
    orderId,
    portfolioId,
  };
};

const removeOrderLineAction = (orderId: number) => {
  return <const>{
    type: REMOVE_ORDERLINE,
    orderId,
  };
};

const orderPrefilledRequest = () => {
  return <const>{
    type: ORDER_PREFILLED_REQUEST,
  };
};

const orderPrefilledSuccess = (result: Contract, portfolioIds: string[], countries: Countries) => {
  return <const>{
    type: ORDER_PREFILLED_SUCCESS,
    result,
    portfolioIds,
    countries,
  };
};

const orderPrefilledFailure = () => {
  return <const>{
    type: ORDER_PREFILLED_FAILURE,
  };
};

const getPrefilledContract = (
  templateId: string,
  clientId: string,
  portfolioIds: string[]
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(orderPrefilledRequest());

    try {
      const result = await getPrefilledContractApiCall(templateId, clientId, state.oidc.user.access_token);
      dispatch(orderPrefilledSuccess(result, portfolioIds, state.common.countries));
    } catch (error) {
      dispatch(orderPrefilledFailure());
    }
  };
};

const orderTemplateRequest = () => {
  return <const>{
    type: ORDER_TEMPLATE_REQUEST,
  };
};

const orderTemplateReceive = (result: Template, portfolioIds: string[]) => {
  return <const>{
    type: ORDER_TEMPLATE_RECEIVE,
    result,
    portfolioIds,
  };
};

const orderTemplateFailure = () => {
  return <const>{
    type: ORDER_TEMPLATE_FAILURE,
  };
};

const getOrdersContractTemplate = (id: string, portfolioIds: string[]): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(orderTemplateRequest());

    try {
      const result = await getOrdersContractTemplateApiCall(id, state.oidc.user.access_token);
      dispatch(orderTemplateReceive(result, portfolioIds));
    } catch (error) {
      dispatch(orderTemplateFailure());
    }
  };
};

const startLoadingContractTemplate = () => {
  return <const>{
    type: ORDER_TEMPLATE_START_LOADING,
  };
};

const finishLoadingContractTemplate = () => {
  return <const>{
    type: ORDER_TEMPLATE_FINISH_LOADING,
  };
};

export const fetchContract = (templateId: string, clientId: string): AppThunk => {
  return (dispatch, getState) => {
    dispatch(startLoadingContractTemplate());
    dispatch(getOrdersContractTemplate(templateId, getState().portfolio.portfolioIds))
      .then(() => {
        dispatch(getPrefilledContract(templateId, clientId, getState().portfolio.portfolioIds))
          .then(() => {
            dispatch(finishLoadingContractTemplate());
          })
          .catch((error) => {
            console.error(error); // eslint-disable-line no-console
          });
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };
};

export const selectSignatureMethod = (method: SignatureMethodType, portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_SIGNATURE_METHOD,
    method,
    portfolioId,
    orderLineIds,
  };
};

export const setManualSignatureAmount = (amount: number, portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_MANUAL_SIGNATURES,
    amount,
    portfolioId,
    orderLineIds,
  };
};

const orderCreateAndSignRequest = (orderLineIds: number[], portfolioId: string) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_REQUEST,
    orderLineIds,
    portfolioId,
  };
};

const orderCreateAndSignRequestSuccess = (
  result: SignedContractResponse,
  orderLineIds: number[],
  portfolioId: string
) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_SUCCESS,
    result,
    orderLineIds,
    portfolioId,
  };
};

const orderCreateAndSignFailure = (orderLineIds: number[], portfolioId: string) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_FAILURE,
    orderLineIds,
    portfolioId,
  };
};

const orderCreateRequest = (orderLineIds: number[], portfolioId: string) => {
  return <const>{
    type: ORDER_CREATE_REQUEST,
    orderLineIds,
    portfolioId,
  };
};

const orderCreateSuccess = (result: PostContractResponse, portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_CREATE_SUCCESS,
    result,
    orderLineIds,
    portfolioId,
  };
};

const orderCreateFailure = (portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_CREATE_FAILURE,
    portfolioId,
    orderLineIds,
  };
};

export const clearOrderDetails = () => {
  return <const>{
    type: CLEAR_ORDER_DETAILS,
  };
};

const clearBasisOfAdvice = () => {
  return <const>{
    type: SET_BASIS_OF_ADVICE,
    value: undefined,
  };
};

export const createOrders = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const customerId = state.profile.customer?.toJS().customerId;

    const portfolioIdsWithOrders = selectPortfolioIdsWithOrders(state);
    dispatch(clearBasisOfAdvice());

    portfolioIdsWithOrders.map((portfolioId) => {
      const portfolioContractName = state.orders.ordersByPortfolioId[portfolioId].portfolioContractName;

      if (portfolioContractName === CONSULTATIVE_AGREEMENT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === NO_CONTRACT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === DIGI_CONSULTATIVE_AGREEMENT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === TAALERI_CONTRACT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === OWN_FUND_CONTRACT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === CUSTOMER_RELATIONSHIP_AGREEMENT) {
        dispatch(createOrdersConsultative(portfolioId));
      }

      if (portfolioContractName === FULL_POWER_OF_ATTORNEY) {
        dispatch(createOrdersDiscretionary(portfolioId));
      }

      if (portfolioContractName === SECURITIES_BROKERAGE_CONTRACT) {
        dispatch(createOrdersDiscretionary(portfolioId));
      }
    });

    dispatch(push(`/customer/${customerId}/portfolio/order/sent`));
  };
};

const createOrdersConsultative = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const oidc = state.oidc;
    const order = state.orders.ordersByPortfolioId[portfolioId];

    order.orderDetails.map(async (orderDetails) => {
      const signatureMethod = orderDetails.signatureMethod;
      const orderLineIds = orderDetails.orderLineIds;

      const contract = selectContractWithRowsByOrderLineIds(state, portfolioId, orderLineIds)
        .set('state', signatureMethod)
        .toJS() as Contract;

      if (signatureMethod === SENT_FOR_BANKER_APPROVAL) {
        dispatch(orderCreateAndSignRequest(orderLineIds, portfolioId));
        try {
          const result = await postSignedContract(contract, oidc.user.access_token);
          dispatch(orderCreateAndSignRequestSuccess(result, orderLineIds, portfolioId));
        } catch (error) {
          dispatch(orderCreateAndSignFailure(orderLineIds, portfolioId));
        }
      }

      if (signatureMethod && SIGNATURE_REQUIRED.includes(signatureMethod)) {
        dispatch(orderCreateRequest(orderLineIds, portfolioId));
        const orderRequest = getOrderRequest(state, contract, orderDetails, order);
        try {
          const result = await postContract(orderRequest, oidc.user.access_token);
          dispatch(orderCreateSuccess(result, portfolioId, orderLineIds));
        } catch (error) {
          dispatch(orderCreateFailure(portfolioId, orderLineIds));
        }
      }

      // Signature method is FINISHED if order type is advice
      if (signatureMethod === FINISHED) {
        dispatch(orderCreateAndSignBackgroundRequest(portfolioId, orderDetails.orderLineIds));
        try {
          const result = await postFinishedContract(contract, oidc.user.access_token);
          dispatch(orderCreateAndSignBackgroundSuccess(portfolioId, result, orderLineIds));
        } catch (error) {
          dispatch(orderCreateAndSignBackgroundFailure(portfolioId, orderLineIds));
        }
      }

      dispatch(clearOrderLinesPortfolioId(portfolioId));
    });
  };
};

const createOrdersDiscretionary = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const oidc = state.oidc;
    const order = state.orders.ordersByPortfolioId[portfolioId];

    order.orderDetails.map(async (orderDetails) => {
      const contract = selectContractWithRowsByOrderLineIds(state, portfolioId, orderDetails.orderLineIds)
        .set('state', orderDetails.signatureMethod)
        .toJS() as Contract;

      dispatch(orderCreateAndSignBackgroundRequest(portfolioId, orderDetails.orderLineIds));

      try {
        const result = await postSignedContract(contract, oidc.user.access_token);
        dispatch(orderCreateAndSignBackgroundSuccess(portfolioId, result, orderDetails.orderLineIds));
      } catch (error) {
        dispatch(orderCreateAndSignBackgroundFailure(portfolioId, orderDetails.orderLineIds));
      }

      dispatch(clearOrderLinesPortfolioId(portfolioId));
    });
  };
};

const orderCreateAndSignBackgroundRequest = (portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_BACKGROUND_REQUEST,
    portfolioId,
    orderLineIds,
  };
};

const orderCreateAndSignBackgroundSuccess = (
  portfolioId: string,
  result: SignedContractResponse,
  orderLineIds: number[]
) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_BACKGROUND_SUCCESS,
    portfolioId,
    result,
    orderLineIds,
  };
};

const orderCreateAndSignBackgroundFailure = (portfolioId: string, orderLineIds: number[]) => {
  return <const>{
    type: ORDER_CREATE_AND_SIGN_BACKGROUND_FAILURE,
    portfolioId,
    orderLineIds,
  };
};

export const prefillSignatory = (portfolioId: string) => {
  return <const>{
    type: ORDER_PREFILL_SIGNATORY,
    portfolioId,
  };
};

export const addSignatory = (signatory: Signatory, portfolioId: string) => {
  return <const>{
    type: ORDER_ADD_SIGNATORY,
    signatory,
    portfolioId,
  };
};

export const removeSignatory = (signatory: Signatory, portfolioId: string) => {
  return <const>{
    type: ORDER_REMOVE_SIGNATORY,
    signatory,
    portfolioId,
  };
};

export const changeContractField = (key: string, value: string | boolean, portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const contract = state.orders.ordersByPortfolioId[portfolioId].contract;
    const contractWithNewValue = contract.setIn(key.split('.'), value) as ImmutableContract;
    dispatch(changeContractFieldAction(portfolioId, contractWithNewValue));
  };
};

const changeContractFieldAction = (portfolioId: string, contractWithNewValue: ImmutableContract) => {
  return <const>{
    type: CHANGE_CONTRACT_FIELD,
    portfolioId,
    contract: contractWithNewValue,
  };
};

export const initOrderState = () => {
  return <const>{
    type: INIT_ORDER_STATE,
  };
};

export const showConfirmDialog = () => {
  return <const>{
    type: SHOW_CONFIRM,
  };
};

export const hideConfirmDialog = () => {
  return <const>{
    type: HIDE_CONFIRM,
  };
};

export const getClientSigners = (): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    getSigners(profile.customer.get('customerId'), oidc.user.access_token)
      .then((signers) => {
        dispatch(getOrderSignatoriesForCustomer(signers, selectPortfolioIdsWithOrders(getState())));
      })
      .catch(() => {
        dispatch(getOrderSignatoriesForCustomer({ signers: [] }, selectPortfolioIdsWithOrders(getState())));
      });
  };
};

const getOrderSignatoriesForCustomer = (signers: Signers, portfolioIds: string[]) => {
  return <const>{
    type: GET_ORDER_SIGNATORIES_FOR_CUSTOMER,
    signers,
    portfolioIds,
  };
};

export const getOrderBisnode = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    getBisnodeData(profile.customer.get('customerId'), oidc.user.access_token)
      .then((bisnode) => {
        dispatch(
          bisnodeOrderGet(
            {
              text: bisnode.procurationAbstractDescription,
              updateDate: bisnode.editDate,
            },
            true,
            portfolioId
          )
        );
      })
      .catch(() => {
        dispatch(bisnodeUpdateFailure({ text: 'Bisnode virhe' }, true, portfolioId));
      });
  };
};

const bisnodeOrderGet = (bisnode: BisnodeUpdate, canUpdateBisnode: boolean, portfolioId: string) => {
  return <const>{
    type: BISNODE_ORDER_GET,
    bisnode,
    canUpdateBisnode,
    portfolioId,
  };
};

export const updateBisnode = (portfolioId: string): AppThunk => {
  return (dispatch, getState) => {
    const { oidc, profile } = getState();

    dispatch(bisnodeUpdateRequest(portfolioId));

    updateBisnodeData(profile.customer.get('customerId'), profile.customer.get('businessId'), oidc.user.access_token)
      .then((bisnode) => {
        dispatch(
          bisnodeUpdateSuccess(
            {
              text: bisnode.procurationAbstractDescription,
              updateDate: bisnode.editDate,
            },
            true,
            portfolioId
          )
        );
      })
      .catch((e) => {
        dispatch(
          bisnodeUpdateFailure({ text: e.status === 404 ? 'Tietoja ei löytynyt' : 'Bisnode virhe' }, false, portfolioId)
        );
      });
  };
};

const bisnodeUpdateRequest = (portfolioId: string) => {
  return <const>{
    type: BISNODE_UPDATE_REQUEST,
    portfolioId,
  };
};

const bisnodeUpdateSuccess = (bisnode: BisnodeUpdate, canUpdateBisnode: boolean, portfolioId: string) => {
  return <const>{
    type: BISNODE_UPDATE_SUCCESS,
    bisnode,
    canUpdateBisnode,
    portfolioId,
  };
};

const bisnodeUpdateFailure = (bisnode: BisnodeUpdate, canUpdateBisnode: boolean, portfolioId: string) => {
  return <const>{
    type: BISNODE_UPDATE_FAILURE,
    bisnode,
    canUpdateBisnode,
    portfolioId,
  };
};

export const setInitialOrderDetails = ({
  portfolioId,
  orderLines,
  orderType,
}: SetInitialOrderDetailsParameters): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const orderLineIds = orderLines.map((orderLine) => orderLine._id);
    const signatureMethod = getSignatureMethod(state, portfolioId, orderLines, orderType);
    dispatch(setInitialOrderDetailsAction(orderLineIds, portfolioId, signatureMethod, orderType));
  };
};

const setInitialOrderDetailsAction = (
  orderLineIds: number[],
  portfolioId: string,
  signatureMethod: string | undefined,
  orderType: OrderDetailsOrderType
) => {
  return <const>{
    type: SET_INITIAL_ORDER_DETAILS,
    orderLineIds,
    portfolioId,
    signatureMethod,
    orderType,
  };
};

export type OrdersAction = ReturnType<
  | typeof ordersPreviewRequest
  | typeof ordersPreviewReceive
  | typeof ordersPreviewFailure
  | typeof ordersPdfPreviewRequest
  | typeof ordersPdfPreviewSuccess
  | typeof ordersPdfPreviewFailure
  | typeof pdfOrderRequest
  | typeof pdfOrderSuccess
  | typeof orderFund
  | typeof orderStructuredProduct
  | typeof orderWarrant
  | typeof orderPrivateEquity
  | typeof orderShare
  | typeof orderBond
  | typeof removeOrderlineFromOrder
  | typeof removeOrderLineAction
  | typeof startLoadingContractTemplate
  | typeof finishLoadingContractTemplate
  | typeof selectSignatureMethod
  | typeof setManualSignatureAmount
  | typeof orderCreateAndSignRequest
  | typeof orderCreateAndSignRequestSuccess
  | typeof orderCreateAndSignFailure
  | typeof orderCreateRequest
  | typeof orderCreateSuccess
  | typeof orderCreateFailure
  | typeof clearOrderDetails
  | typeof orderCreateAndSignBackgroundRequest
  | typeof orderCreateAndSignBackgroundSuccess
  | typeof orderCreateAndSignBackgroundFailure
  | typeof prefillSignatory
  | typeof addSignatory
  | typeof removeSignatory
  | typeof changeContractFieldAction
  | typeof initOrderState
  | typeof showConfirmDialog
  | typeof hideConfirmDialog
  | typeof getOrderSignatoriesForCustomer
  | typeof bisnodeOrderGet
  | typeof bisnodeUpdateRequest
  | typeof bisnodeUpdateSuccess
  | typeof bisnodeUpdateFailure
  | typeof setInitialOrderDetailsAction
  | typeof orderPrefilledRequest
  | typeof orderPrefilledSuccess
  | typeof orderPrefilledFailure
  | typeof orderTemplateRequest
  | typeof orderTemplateReceive
  | typeof orderTemplateFailure
>;
