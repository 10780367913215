import React from 'react';
import { IconButton } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';

interface Props {
  copyContract: () => void;
  contractType: string;
}

export const ActionMenu = ({ copyContract, contractType }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div data-testkey={`unsigned-contract-action-menu-${contractType}`}>
      <IconButton onClick={handleClick}>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={copyContract} data-testkey="unsigned-contract-action-menu-copy">
          Kopioi uuden sopimuksen pohjaksi
        </MenuItem>
      </Menu>
    </div>
  );
};
