import { Middleware } from 'redux';
import { USER_SIGNED_OUT } from 'redux-oidc';
import { userManager } from 'core/userManager';

export const sessionMiddleware: Middleware = () => (next) => (action) => {
  if (action.type === USER_SIGNED_OUT) {
    userManager
      .signoutRedirect()
      .then(() => {
        // log?
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }
  return next(action);
};
