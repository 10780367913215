import { User } from 'types/types';

export const set = (key: string, item: object) => {
  try {
    localStorage.setItem(key, JSON.stringify(item));
    return item;
  } catch (err) {
    return item;
  }
};

export const get = (key: string): User[] | undefined => {
  try {
    return JSON.parse(localStorage.getItem(key) || '');
  } catch (err) {
    return undefined;
  }
};

export const remove = (key: string) => {
  try {
    return localStorage.removeItem(key);
  } catch (err) {
    return undefined;
  }
};
