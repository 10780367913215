import React from 'react';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { selectAllOrderDetails, selectSignatoriesPreview } from 'features/orders/ordersSelectors';
import InfoText from 'features/orders/components/InfoText';
import styles from './OrdersSentView.scss';
import { Sheet } from 'features/common/Sheet';
import { Spinner } from 'features/common/Spinner';
import Error from 'features/common/404';
import { Signatures } from 'features/orders/components/Signatures';
import { SIGNATURE_REQUIRED } from 'constants/contractStates';

export const OrdersSentView = () => {
  const orderDetails = useSelector(selectAllOrderDetails);
  const signatoriesPreview = useSelector(selectSignatoriesPreview);

  const creatingContracts = !!orderDetails.find((details) => {
    return details.orderDetails.find((singleDetail) => {
      return singleDetail.creatingContract;
    });
  });

  const createContractsSucceeded = !!orderDetails.find((details) => {
    return details.orderDetails.find((singleDetail) => {
      return singleDetail.createContractSucceeded;
    });
  });

  const contractSignatoriesFailed = !!orderDetails.find((details) => {
    return details.orderDetails.find((singleDetail) => {
      return singleDetail.contractSignatoriesFailed;
    });
  });

  const showSignatures = !!orderDetails.find((detailsWithPortfolioId) =>
    detailsWithPortfolioId.orderDetails.find((details) => SIGNATURE_REQUIRED.includes(details.signatureMethod))
  );

  if (creatingContracts) {
    return <Spinner size={100} />;
  }

  if (!createContractsSucceeded) {
    return <Error error={translate('order.creatingContractFailed')} />;
  }

  if (contractSignatoriesFailed) {
    return <Error error={translate('order.signatoriesAdditionFailed')} />;
  }

  if (showSignatures) {
    return (
      <>
        {orderDetails
          .map((details) => {
            return details.orderDetails.map((singleDetail) => {
              const pdfPreviewLink = singleDetail.pdfPreviewLink;
              const signatories = signatoriesPreview.find(
                (signatories) => signatories.portfolioId === details.portfolioId
              );
              return (
                <Signatures
                  key={JSON.stringify(singleDetail.orderLineIds)}
                  details={singleDetail}
                  signatories={signatories?.signatories}
                  pdfPreviewLink={pdfPreviewLink}
                  portfolioId={details.portfolioId}
                />
              );
            });
          })
          .flat()}
      </>
    );
  }

  return (
    <Sheet style={{ marginTop: '4rem' }}>
      <div className={styles.content}>
        <InfoText text={translate('order.orderSent')} />
      </div>
    </Sheet>
  );
};
