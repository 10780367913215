import { LIMIT_PRICE, MARKET_PRICE } from 'constants/shareOptions';
import { SELL } from 'constants/sides';

export const sellShareSchema = {
  properties: {
    expireDate: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    financialInstrumentId: {
      type: 'string',
      minLength: 1,
    },
    note: {
      type: 'string',
      minLength: 1,
    },
    orderFinancingSell: {
      type: 'string',
    },
    orderType: {
      type: 'string',
      enum: [MARKET_PRICE, LIMIT_PRICE],
    },
    price: {
      type: 'number',
      minLength: 1,
    },
    quantity: {
      type: 'number',
      minimum: 0,
      exclusiveMinimum: true,
    },
    side: {
      type: 'string',
      enum: [SELL],
    },
    amount: {
      type: 'number',
      minimum: 0,
      exclusiveMinimum: true,
    },
  },
  anyOf: [
    {
      properties: {
        orderType: {
          type: 'string',
          enum: [MARKET_PRICE],
        },
      },
    },
    {
      properties: {
        orderType: {
          type: 'string',
          enum: [LIMIT_PRICE],
        },
      },
      required: ['price'],
    },
  ],
  oneOf: [
    {
      required: ['quantity'],
    },
    {
      required: ['amount'],
    },
  ],
  type: 'object',
  required: ['side', 'expireDate', 'financialInstrumentId', 'orderType', 'orderFinancingSell'],
};
