import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import { FlexColumn, FlexAlignCenter } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { getPathContexts, selectHiddenOptimizationError } from 'features/errors/errorUtils';

interface Props {
  context?: string;
}

export const ErrorDialog = ({ context }: Props) => {
  const path = useSelector((state: RootState) => state.routing.locationBeforeTransitions.pathname);
  const currentContexts = context || getPathContexts(path);
  const errors = useSelector((state: RootState) =>
    state.common.errors.filter((e) => currentContexts.includes(e.context))
  );

  const errorToHide = useSelector(selectHiddenOptimizationError);
  const errorsToShow = errors.filter((e) => e.context !== errorToHide);

  if (!errorsToShow.length) {
    return null;
  }

  return (
    <Container data-testkey="error-dialog">
      {errorsToShow?.map((e) => (
        <Error key={e.context + e.errorMessage} data-testkey={`error-message-${e.context}`}>
          <p>{e.errorMessage}</p>
        </Error>
      ))}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  justify-content: space-between;
  z-index: 1;
  color: white;
`;

const Error = styled(FlexAlignCenter)`
  z-index: 1;
  padding: 0.5rem;
  padding-left: 1rem;
  margin: 2px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  background: ${colors.red};

  p {
    padding: 0;
    margin: 0;
  }
`;
