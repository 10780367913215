import React from 'react';
import styled, { css } from 'styled-components';
import translate from 'counterpart';
import { customerHeaderHeight, Flex } from 'features/common/StyledComponents';
import { SearchResultCustomer } from 'types/customerListState';
import { CustomerListItem } from 'features/portfolioManager/customerList/components/CustomerListItem';
import { colors } from 'styles/colors';

interface Props {
  customers: SearchResultCustomer[];
  sortByKey: keyof SearchResultCustomer;
  setSortBy: (key: keyof SearchResultCustomer) => void;
}

export const CustomerList = React.memo(({ customers, sortByKey, setSortBy }: Props) => {
  const clickSort = (key: keyof SearchResultCustomer) => setSortBy(key);

  if (!customers?.length) {
    return null;
  }

  return (
    <>
      <thead>
        <TitleRow>
          <TitleCell colSpan={1} />
          <th>
            <SortButton active={sortByKey === 'aum'} onClick={() => clickSort('aum')}>
              <label>{translate('portfolioManager.customerList.aum')}</label>
            </SortButton>
          </th>
          <th>
            <SortButton active={sortByKey === 'withdrawalBalance'} onClick={() => clickSort('withdrawalBalance')}>
              <label>
                {translate('portfolioManager.customerList.withdrawalBalanceLine1')}
                <br />
                {translate('portfolioManager.customerList.withdrawalBalanceLine2')}
              </label>
            </SortButton>
          </th>
        </TitleRow>
      </thead>

      <CustomerTable>
        {customers?.map((customer) => {
          return (
            <CustomerRow key={customer.customerId} hidden={!customer.isFound}>
              <CustomerListItem customer={customer} />
            </CustomerRow>
          );
        })}
      </CustomerTable>
    </>
  );
});

CustomerList.displayName = 'CustomerList';

const TitleRow = styled.tr`
  th {
    padding: 2rem 0 0;
    position: sticky;
    background: ${colors.gray_light};
    top: calc(2 * ${customerHeaderHeight});
    vertical-align: bottom;
    text-align: center;
    font-size: 15px;
  }
`;

const TitleCell = styled.th`
  && {
    background: ${colors.gray_light};
    position: sticky;
    top: calc(2 * ${customerHeaderHeight});
    z-index: 2;
    padding: 0 0.5rem 0.5rem;
    text-align: left;
  }
`;

const SortButton = styled(Flex)<{ active: boolean }>`
  justify-content: center;
  align-items: flex-end;
  padding: 2px 0;
  height: 56px;
  width: 100%;

  ${(props) =>
    !props.active &&
    css`
      cursor: pointer;
    `}

  &:hover {
    ${(props) =>
      !props.active &&
      css`
        label {
          border-bottom: 3px solid ${colors.warm_gray};
          cursor: pointer;
        }
      `}
  }

  label {
    color: ${colors.dark_gray};
    border-bottom: 3px solid transparent;
    margin: 0 1rem;
    line-height: 1.3;

    ${(props) =>
      props.active &&
      css`
        color: ${colors.black};
        border-bottom: 3px solid ${colors.primary};
      `};
  }
`;

const CustomerTable = styled.tbody`
  margin-bottom: 2rem;
`;

const CustomerRow = styled.tr<{ hidden: boolean }>`
  padding: 0;
  background: none;

  ${(props) => props.hidden && 'display: hidden'}

  td {
    border-right: 1px dashed #eee;
    background: white;
    border-radius: 0;
    height: 56px;
    padding: 0.25rem 1rem;
  }
`;
