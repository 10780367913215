export const SHARE = 'Share';
export const FUND = 'Fund';
export const STRUCTURED_PRODUCT = 'StructuredProduct';
export const WARRANT = 'Warrant';
export const PRIVATE_EQUITY = 'PrivateEquity';
export const PRIVATE_EQUITY_PROFIT_SHARING_LOAN = 'PrivateEquityProfitSharingLoan';
export const CO_INVESTMENTS = 'CoInvestments';
export const CO_INVESTMENTS_PROFIT_SHARING_LOAN = 'CoInvestmentsProfitSharingLoan';
export const ETF = 'ETF';
export const CERTIFICATE = 'Certificate';
export const BOND = 'Bond';
export const SUBSCRIPTION_RIGHT = 'SubscriptionRight';
export const OPTION = 'Option';

export const instrumentForms = {
  SHARE,
  FUND,
  STRUCTURED_PRODUCT,
  WARRANT,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  ETF,
  CERTIFICATE,
  BOND,
  SUBSCRIPTION_RIGHT,
  OPTION,
} as const;

export const insuranceFormsWithBankerApproval = [
  WARRANT,
  FUND,
  STRUCTURED_PRODUCT,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
];

export const instrumentFormsThatCanBeMixedInTrades = [SHARE, FUND, ETF, BOND];

export type InstrumentForm = (typeof instrumentForms)[keyof typeof instrumentForms];
