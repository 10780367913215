export function getWindowLocationHost(): string {
  if (typeof window !== 'undefined' && window) {
    return window.location.host;
  }
  return '';
}

export function getWindowLocationOrigin(): string {
  if (typeof window !== 'undefined' && window) {
    return window.location.origin;
  }
  return '';
}
