import React, { useState, useEffect, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled, { css } from 'styled-components';
import { groupBy, isEqual } from 'lodash';
import { colors } from 'styles/colors';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { SubPageContainer, FlexColumn, Flex, TextInput } from 'features/common/StyledComponents';
import FormSelectionItem from 'features/allocator/common/FormSelectionItem';
import { setCustomConstraints } from 'features/allocator/investmentPlan/investmentPlanConstraintsActions';
import { Button } from 'features/allocator/common/Button';
import { AssetClassId } from 'types/types';
import { selectAssetClasses, selectCustomConstraints } from 'features/allocator/allocatorSelectors';
import { useAppDispatch } from 'core/hooks';
import { getConstraintId } from 'features/allocator/constraints/constraintsUtils';

interface Props {
  customerId: string;
  selectedConstraintId: string | undefined;
  setShowCustomConstraint: (show: boolean) => void;
}

export const CustomConstraint = ({ customerId, selectedConstraintId, setShowCustomConstraint }: Props) => {
  const dispatch = useAppDispatch();

  const assetClasses = useSelector(selectAssetClasses);
  const customConstraints = useSelector(selectCustomConstraints);

  const [constrainingAssetClasses, setConstrainingAssetClasses] = useState<AssetClassId[]>([]);
  const [initialConstrainingAssetClasses, setInitialConstrainingAssetClasses] = useState<AssetClassId[]>([]);

  const [inRelationToAssetClasses, setInRelationToAssetClasses] = useState<AssetClassId[]>([]);
  const [initialInRelationToAssetClasses, setInitialInRelationToAssetClasses] = useState<AssetClassId[]>([]);

  const [customConstraintLabel, setCustomConstraintLabel] = useState<string>('');
  const [initialCustomConstraintLabel, setInitialCustomConstraintLabel] = useState<string>('');

  const [validationError, setValidationError] = useState<string>('');
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    const existingConstraint =
      selectedConstraintId &&
      customConstraints.find((customConstraint) => customConstraint.id === selectedConstraintId);

    if (existingConstraint) {
      setInitialConstrainingAssetClasses(existingConstraint.assetClasses);
      setInitialInRelationToAssetClasses(existingConstraint.inRelationTo);
      setInitialCustomConstraintLabel(existingConstraint.label);
      loadState(existingConstraint.assetClasses, existingConstraint.inRelationTo, existingConstraint.label);
    }
  }, []);

  const discardChanges = () => {
    loadState(initialConstrainingAssetClasses, initialInRelationToAssetClasses, initialCustomConstraintLabel);
  };

  const loadState = (
    constrainingAssetClasses: AssetClassId[],
    inRelationToAssetClasses: AssetClassId[],
    label: string
  ) => {
    setConstrainingAssetClasses(constrainingAssetClasses);
    setInRelationToAssetClasses(inRelationToAssetClasses);
    setCustomConstraintLabel(label);
  };

  const constrainingAssetOnClick = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const assetLabel = element.value as AssetClassId;
    constrainingAssetClasses.find((constrainingAssetClass) => constrainingAssetClass === assetLabel)
      ? setConstrainingAssetClasses(constrainingAssetClasses.filter((i) => i !== assetLabel))
      : setConstrainingAssetClasses(constrainingAssetClasses.concat([assetLabel]));
    !inRelationToAssetClasses.find((inRelationToAssetClass) => inRelationToAssetClass === assetLabel) &&
      setInRelationToAssetClasses(inRelationToAssetClasses.concat([assetLabel]));
  };

  const inRelationToAssetClassesOnClick = (e: ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const assetLabel = element.value as AssetClassId;
    inRelationToAssetClasses.find((inRelationToAssetClass) => inRelationToAssetClass === assetLabel)
      ? setInRelationToAssetClasses(inRelationToAssetClasses.filter((i) => i !== assetLabel))
      : setInRelationToAssetClasses(inRelationToAssetClasses.concat([assetLabel]));
    constrainingAssetClasses.find((constrainingAssetClass) => constrainingAssetClass === assetLabel) &&
      setConstrainingAssetClasses(constrainingAssetClasses.filter((i) => i !== assetLabel));
  };

  const showError = (message: string) => {
    window.scrollTo(0, 0);
    setValidationError(message);
  };

  const selectionsAreValid = (): boolean => {
    if (isEqual(constrainingAssetClasses, inRelationToAssetClasses)) {
      showError(translate('allocator.customConstraints.assetClassesAreEqual'));
      return false;
    }

    if (!constrainingAssetClasses.length) {
      showError(translate('allocator.customConstraints.noConstrainingAssetClasses'));
      return false;
    }

    if (customConstraintLabel.length > 150) {
      showError(translate('allocator.customConstraints.customConstraintLabelTooLongError'));
      return false;
    }

    if (!customConstraintLabel) {
      showError(translate('allocator.customConstraints.customConstraintNoLabelError'));
      return false;
    }

    if (
      customConstraints.find((i) => i.label === customConstraintLabel) &&
      customConstraintLabel !== customConstraints.find((i) => i.id === selectedConstraintId)?.label
    ) {
      showError(translate('allocator.customConstraints.customContraintNameTakenError'));
      return false;
    }

    return true;
  };
  const saveChanges = () => {
    if (!selectionsAreValid()) {
      return;
    }

    const newConstraint = {
      id: selectedConstraintId || getConstraintId(customConstraints, customConstraintLabel),
      min: 0,
      max: 1,
      assetClasses: constrainingAssetClasses,
      inRelationTo: inRelationToAssetClasses,
      items: [],
      isCustom: true,
      label: customConstraintLabel.trim(),
      assetCategory: 'Custom',
      category: 'custom',
    };

    customConstraints.find((i) => i.id === newConstraint.id)
      ? dispatch(setCustomConstraints(customConstraints.map((i) => (i.id === newConstraint.id ? newConstraint : i))))
      : dispatch(setCustomConstraints(customConstraints.concat([newConstraint])));

    setShowCustomConstraint(false);
  };

  const hasChanged = () => {
    const changedFromInitial =
      !isEqual(constrainingAssetClasses, initialConstrainingAssetClasses) ||
      !isEqual(inRelationToAssetClasses, initialInRelationToAssetClasses) ||
      customConstraintLabel !== initialCustomConstraintLabel;
    return changedFromInitial;
  };

  const deleteCustomConstraint = () => {
    if (confirmingDelete) {
      const newConstraints = customConstraints
        .filter((constraint) => constraint.isCustom)
        .filter((constraint) => constraint.id !== selectedConstraintId);
      dispatch(setCustomConstraints(newConstraints));
      setShowCustomConstraint(false);
      setConfirmingDelete(false);
    } else {
      setConfirmingDelete(true);
    }
  };

  return (
    <SubPageContainer>
      <AllocatorHeader
        headerLabel={translate('allocator.customConstraints.newConstraintGroup')}
        discardChanges={discardChanges}
        hasChanged={hasChanged()}
        customerId={customerId}
        saveChanges={saveChanges}
        onCloseClick={() => setShowCustomConstraint(false)}
      />

      <Flex>
        <LeftSide>
          <TopPart>
            <h3>{translate('allocator.customConstraints.groupName')}</h3>
            <NameInput
              type="text"
              placeholder={translate('allocator.customConstraints.giveName')}
              value={customConstraintLabel}
              onChange={(event) => setCustomConstraintLabel(event.target.value)}
              data-testkey="custom-constraint-name"
            />
          </TopPart>

          <Flex>
            <Column>
              <h3>{translate('allocator.customConstraints.constrainingAssetClasses')}</h3>
              <div>
                {Object.entries(groupBy(assetClasses, 'assetCategory')).map(([assetCategory, assetClasses]) => (
                  <AssetCategoryList key={assetCategory}>
                    <h4>{assetCategory}</h4>
                    {assetClasses.map((assetClass) => (
                      <StyledFormSelectionItem
                        key={assetClass.assetClassId}
                        value={assetClass.assetClassId}
                        selected={
                          !!constrainingAssetClasses.find(
                            (constrainingAsset) => constrainingAsset === assetClass.assetClassId
                          )
                        }
                        onChange={constrainingAssetOnClick}
                        data-testkey={`select-left-${assetClass.assetClassId}`}
                        type={'checkbox'}
                        bg="white"
                      >
                        {assetClass.name}
                      </StyledFormSelectionItem>
                    ))}
                  </AssetCategoryList>
                ))}
              </div>
            </Column>

            <Column>
              <h3>{translate('allocator.customConstraints.inRelationToAssetClasses')}</h3>
              <div>
                {Object.entries(groupBy(assetClasses, 'assetCategory')).map(([assetCategory, assetClasses]) => (
                  <AssetCategoryList key={assetCategory}>
                    <h4>{assetCategory}</h4>
                    {assetClasses.map((assetClass) => (
                      <StyledFormSelectionItem
                        key={assetClass.assetClassId}
                        value={assetClass.assetClassId}
                        selected={
                          !!inRelationToAssetClasses.find(
                            (inRelationToAsset) => inRelationToAsset === assetClass.assetClassId
                          )
                        }
                        onChange={inRelationToAssetClassesOnClick}
                        data-testkey={`select-right-${assetClass.assetClassId}`}
                        type={'checkbox'}
                        bg="white"
                      >
                        {assetClass.name}
                      </StyledFormSelectionItem>
                    ))}
                  </AssetCategoryList>
                ))}
              </div>
            </Column>
          </Flex>
        </LeftSide>

        <RightSide>
          {validationError && <Error>{validationError}</Error>}

          {selectedConstraintId && (
            <DeleteButton
              label={
                confirmingDelete
                  ? translate('allocator.customConstraints.confirmDelete')
                  : translate('allocator.customConstraints.delete')
              }
              onClick={deleteCustomConstraint}
              confirmingDelete={confirmingDelete}
              secondary
            />
          )}
        </RightSide>
      </Flex>
    </SubPageContainer>
  );
};

const LeftSide = styled.div`
  width: 66%;
`;

const RightSide = styled(FlexColumn)`
  align-items: flex-end;
  flex: 1;
`;

const TopPart = styled(Flex)`
  flex: auto;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 3rem;
`;

const Column = styled(FlexColumn)`
  flex: 1;
`;

const StyledFormSelectionItem = styled(FormSelectionItem)`
  padding-left: 1rem;
  padding-right: 2rem;
  margin-right: 2rem;
  white-space: nowrap;
`;

const AssetCategoryList = styled.div`
  margin-bottom: 1rem;
`;

const NameInput = styled(TextInput)`
  width: 100%;
`;

const Error = styled.div`
  color: ${colors.red};
  margin-bottom: 2rem;
`;

interface StyledButtonProps {
  confirmingDelete: boolean;
}

const DeleteButton = styled(Button)<StyledButtonProps>`
  align-self: flex-end;
  float: right;
  border: none;
  ${(props) =>
    props.confirmingDelete
      ? css`
          background: ${colors.red};
          color: white;
          font-weight: 700;
        `
      : css`
          background: white;
        `}
`;
