import React from 'react';
import translate from 'counterpart';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  name: string;
  customerId: string;
  state: string;
}

export const CustomerInfo = ({ name, customerId, state }: Props) => {
  const commonProps = {
    style: {
      width: '100%',
      boxShadow: 'none',
      color: '#000000',
      marginBottom: '15px',
    },
    disabled: true,
  };

  return (
    <div className="row">
      <div className="columns small-4">
        <StyledTextField
          value={name}
          label={translate('meetings.name')}
          {...commonProps}
          variant="standard"
          sx={[
            {
              '&.MuiFormControl-root': {
                marginTop: '0',
                width: '100%',
              },
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInputBase-root': {
                marginTop: '14px',
                marginBottom: '15px',
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
                backgroundColor: '#ffffff',
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Roboto, sans-serif',
                marginTop: 0,
                height: '100%',
                paddingTop: 0,
              },
              '& label': {
                fontSize: '14px',
                '&.Mui-focused': {
                  fontSize: '14px',
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
              '&.Mui-error': {
                borderBottomColor: '#d32f2f',
                borderWidth: '2px',
                '&::before': {
                  borderWidth: '2px',
                  borderBottomColor: '#d32f2f',
                },
              },
            },
          ]}
        />
      </div>
      <div className="columns small-4">
        <StyledTextField
          value={customerId}
          label={translate('meetings.customerId')}
          {...commonProps}
          variant="standard"
          sx={[
            {
              '&.MuiFormControl-root': {
                marginTop: '0',
                width: '100%',
              },
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInputBase-root': {
                marginTop: '14px',
                marginBottom: '15px',
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
                backgroundColor: '#ffffff',
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Roboto, sans-serif',
                marginTop: 0,
                height: '100%',
                paddingTop: 0,
              },
              '& label': {
                fontSize: '14px',
                '&.Mui-focused': {
                  fontSize: '14px',
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
              '&.Mui-error': {
                borderBottomColor: '#d32f2f',
                borderWidth: '2px',
                '&::before': {
                  borderWidth: '2px',
                  borderBottomColor: '#d32f2f',
                },
              },
            },
          ]}
        />
      </div>
      <div className="columns small-4">
        <StyledTextField
          value={state}
          label={translate('meetings.state')}
          {...commonProps}
          variant="standard"
          sx={[
            {
              '&.MuiFormControl-root': {
                marginTop: '0',
                width: '100%',
              },
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInputBase-root': {
                marginTop: '14px',
                marginBottom: '15px',
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
                backgroundColor: '#ffffff',
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Roboto, sans-serif',
                marginTop: 0,
                height: '100%',
                paddingTop: 0,
              },
              '& label': {
                fontSize: '14px',
                '&.Mui-focused': {
                  fontSize: '14px',
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
              '&.Mui-error': {
                borderBottomColor: '#d32f2f',
                borderWidth: '2px',
                '&::before': {
                  borderWidth: '2px',
                  borderBottomColor: '#d32f2f',
                },
              },
            },
          ]}
        />
      </div>
    </div>
  );
};
