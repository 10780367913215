import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled, { css } from 'styled-components';
import { Card } from 'features/allocator/common/Card';
import { LinkButton } from 'features/allocator/common/LinkButton';
import { preventAllocatorUse } from 'features/errors/errorUtils';
import {
  allPlanInstrumentsAreNotInPositions,
  selectHasPositions,
  customerHasValidPlan,
  selectHasError,
  hasNegativePositions,
  selectPlanState,
  hasRiskConflict,
  selectPositionsNotInPlan,
} from 'features/allocator/allocatorSelectors';
import { colors } from 'styles/colors';

interface Props {
  customerId: string;
}

export const PlanInfo = ({ customerId }: Props) => {
  const planState = useSelector(selectPlanState);
  const hasPlan = useSelector(customerHasValidPlan);
  const preventAllocator = useSelector(preventAllocatorUse);

  const obsoletePlan = useSelector(selectHasError(['obsoletePlan']));
  const hasPositions = useSelector(selectHasPositions);
  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);
  const allPlanInstrumentsNotInPositions = useSelector(allPlanInstrumentsAreNotInPositions);
  const negativePositions = useSelector(hasNegativePositions);
  const riskConflict = useSelector(hasRiskConflict);

  const getPlanStateText = () => {
    if (riskConflict) {
      return translate('portfolioManager.overview.planInfo.label_riskConflict');
    }
    if (obsoletePlan) {
      return translate('errors.obsoletePlan');
    }
    if (!negativePositions) {
      if (positionsNotInPlan.length > 0) {
        return translate('errors.allPositionsNotInPlan');
      }
      if (hasPositions && allPlanInstrumentsNotInPositions) {
        return translate('errors.allPlanInstrumentsNotInPositions');
      }
    }
  };

  return (
    <PlanCard hasPlan={hasPlan}>
      <h2 data-testkey={`${planState === 'invalidPlan' ? 'invalid-plan' : ''}`}>
        {translate('portfolioManager.overview.planInfo.title')}
      </h2>

      <>
        <Warning data-testkey="plan-state-warning">{getPlanStateText()}</Warning>
        <LinkButton
          to={`customer/${customerId}/portfoliomanager/allocator`}
          label={translate(`portfolioManager.overview.planInfo.button_${planState}`)}
          secondary={hasPlan || planState === 'notLoaded'}
          data-testkey="go-to-allocator"
          disabled={preventAllocator}
        />
      </>
    </PlanCard>
  );
};

const PlanCard = styled(Card)<{ hasPlan: boolean }>`
  grid-area: plan;
  min-width: 375px;
  ${(props) =>
    !props.hasPlan &&
    css`
      background: hsl(208deg 50% 98% / 70%);
      box-shadow: none;
    `}
`;

const Warning = styled.div`
  color: ${colors.red};
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.3px;
`;
