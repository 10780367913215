import React, { ChangeEvent } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import translate from 'counterpart';
import classnames from 'classnames';
import Select from 'react-select';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ReactTooltip from 'react-tooltip';
import StyledTextField from './styledComponents/StyledTextField';
import StyledFormControlLabel from './styledComponents/StyledFormControlLabel';
import StyledRadioButton from './styledComponents/StyledRadioButton';
import {
  IMAGE_DATAURI,
  IMAGE,
  IDENTITY_METHOD,
  IDENTITY_METHOD_EXPIRATION_DATE,
  IDENTITY_METHOD_NUMBER,
  IDENTITY_METHOD_ISSUER,
  IDENTITY_METHOD_ISSUER_COUNTRY,
  IDENTITY_METHOD_DATE_OF_ISSUE,
  DESCRIPTION,
} from 'constants/newCustomer';
import styles from './ImageCapture.scss';
import { Spinner } from './Spinner';
import { Option } from 'types/profileState';
import { SelectValue } from 'types/types';
import { Countries } from 'types/contractsState';

interface IdentityMethod {
  translateKey: string;
  value: string;
  disabled?: boolean;
}

interface Props {
  handler: (option: Option) => void;
  clearImageHandler: () => void;
  imageDataURI: string;
  identityExpirationDate: string;
  identityMethodDateOfIssue: string;
  identityMethodNumber: string;
  identityMethodIssuer: string;
  identityMethodIssuerCountry: string;
  selectedMethod: string;
  description: string;
  horizontal: boolean;
  identityMethods: IdentityMethod[];
  countries: Countries;
  isProspect: boolean;
}

export default class ImageCapture extends React.Component<Props> {
  reader: FileReader;

  static defaultProps = {
    identityMethods: [],
  };

  constructor(props: Props) {
    super(props);
    this.reader = new FileReader();
  }

  handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (event.target.files.length <= 0) {
      return;
    }
    const { handler } = this.props;
    this.reader = new FileReader();
    this.reader.onload = (e) => {
      handler({ key: IMAGE_DATAURI, value: e.target?.result });
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.reader.readAsDataURL(event.target.files[0]);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    handler({ key: IMAGE, value: event.target.files[0] });
  };

  handleImageClear = () => {
    const { clearImageHandler } = this.props;
    clearImageHandler();
  };

  handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.handler({ key: event.target.name, value: event.target.value });
  };

  handleSelectIssuerCountry = (selectValue?: SelectValue) => {
    this.props.handler({ key: IDENTITY_METHOD_ISSUER_COUNTRY, value: selectValue?.value as string });
  };

  getCountryOptions = () => {
    const { countries } = this.props;

    return _.chain(countries)
      .map((val, key) => ({
        value: key,
        label: val,
      }))
      .filter((kv) => kv.value !== 'FI')
      .value();
  };

  render() {
    const {
      imageDataURI,
      identityExpirationDate,
      identityMethodDateOfIssue,
      identityMethodNumber,
      identityMethodIssuer,
      identityMethodIssuerCountry,
      selectedMethod,
      identityMethods,
      description,
      horizontal,
      isProspect,
    } = this.props;

    const identityBtns = identityMethods
      .filter((method) => !method.disabled)
      .map((method) => {
        const label = translate(method.translateKey);
        return (
          <StyledFormControlLabel
            value={method.value}
            control={<StyledRadioButton />}
            label={label}
            key={method.value}
            data-testkey={`image-options-method-${method.value}`}
          />
        );
      });

    let imageContent;
    if (this.reader.readyState === 1) {
      imageContent = (
        <div className={styles.imageCapture}>
          <Spinner className={styles.spinner} color="000000" />
        </div>
      );
    } else {
      imageContent = (
        <ImageFileReader
          uploadHandler={this.handleImageChange}
          imageDataURI={imageDataURI}
          onClearImage={this.handleImageClear}
          isProspect={isProspect}
        />
      );
    }

    const renderTextField = (
      name: string,
      labelKey: string | undefined,
      value: string,
      type: string | undefined = undefined,
      { ...rest } = {},
      isMultiline: boolean | undefined = false
    ): JSX.Element => (
      <div className={classnames(styles.field)}>
        <label key={name}>
          {labelKey && (
            <div>
              {translate(labelKey)}
              {!isProspect && ' *'}
            </div>
          )}
          <StyledTextField
            style={{ height: 'default' }}
            onChange={this.handleFieldChange}
            name={name}
            type={type}
            value={value || ''}
            data-testkey={`image-options-${name}`}
            variant="standard"
            multiline={isMultiline}
            sx={[
              {
                '&.MuiFormControl-root': {
                  marginTop: '0',
                  width: '100%',
                },
                '& .Mui-focused': {
                  '&::after': {
                    left: 0,
                  },
                },
                '& .MuiInputBase-root': {
                  marginTop: '14px',
                  marginBottom: '15px',
                  paddingLeft: 0,
                  '&::before': {
                    left: 0,
                  },
                  backgroundColor: '#ffffff',
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Calibre, sans-serif',
                  marginTop: 0,
                  height: '100%',
                  paddingTop: 0,
                },
                '& label': {
                  fontSize: '14px',
                  '&.Mui-focused': {
                    fontSize: '14px',
                  },
                },
                '& > p': {
                  '&.MuiFormHelperText-root': {
                    paddingLeft: '0',
                  },
                },
                '&.Mui-error': {
                  borderBottomColor: '#d32f2f',
                  borderWidth: '2px',
                  '&::before': {
                    borderWidth: '2px',
                    borderBottomColor: '#d32f2f',
                  },
                },
              },
            ]}
            {...rest}
          />
        </label>
      </div>
    );

    const renderCountrySelectField = (): JSX.Element => (
      <div className={classnames(styles.field)}>
        <label data-testkey={`image-options-${IDENTITY_METHOD_ISSUER_COUNTRY}`}>
          <div>{translate('wizard.identityMethodIssuerCountry') + (!isProspect ? ' *' : '')}</div>
          <Select
            name={IDENTITY_METHOD_ISSUER_COUNTRY}
            options={this.getCountryOptions()}
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderTopStyle: 'none',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                boxShadow: 'none',
                fontSize: '16px',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'black',
                primary25: '#ededed',
                neutral50: 'black',
                neutral20: 'black',
                neutral60: 'black',
                primary50: '#ededed',
                neutral80: 'black', // selected text color
              },
            })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={this.handleSelectIssuerCountry}
            noOptionsMessage={() => translate('orderList.selectNoResultText')}
            placeholder={translate('orderList.select')}
            value={this.getCountryOptions().filter((option) => option.value === identityMethodIssuerCountry)}
            className={classnames(styles.Select)}
            isClearable
          />
        </label>
      </div>
    );

    const uploadClasses = classnames([styles.imageUpload], { [styles.horizontal]: horizontal });
    const uploadDescription = translate('customer.uploadDescription');

    const showDateOfIssue = selectedMethod === 'FinnishDriverLicence';
    const showIssuerCountry = selectedMethod && selectedMethod.startsWith('Foreign');
    const showImagefields = !isProspect || imageDataURI?.length;

    return (
      <div style={{ marginBottom: '2rem' }}>
        <div className={uploadClasses}>
          {imageContent}
          {showImagefields && (
            <div className={classnames(styles.imageOptions, 'image-options')}>
              <fieldset className={classnames(styles.field)}>
                <legend>{translate(`wizard.documentType`) + (!isProspect ? ' *' : '')}</legend>
                <RadioGroup onChange={this.handleFieldChange} name={IDENTITY_METHOD}>
                  {identityBtns}
                </RadioGroup>
              </fieldset>
              {renderTextField(IDENTITY_METHOD_NUMBER, 'wizard.identityMethodNumber', identityMethodNumber)}
              {renderTextField(IDENTITY_METHOD_ISSUER, 'wizard.identityMethodIssuer', identityMethodIssuer)}
              {showIssuerCountry && renderCountrySelectField()}
              {showDateOfIssue &&
                renderTextField(
                  IDENTITY_METHOD_DATE_OF_ISSUE,
                  'wizard.identityDateOfIssueLabel',
                  identityMethodDateOfIssue,
                  'date'
                )}
              {renderTextField(
                IDENTITY_METHOD_EXPIRATION_DATE,
                'wizard.idValidDateLabel',
                identityExpirationDate,
                'date'
              )}
            </div>
          )}
        </div>
        {showImagefields &&
          renderTextField(
            DESCRIPTION,
            undefined,
            description,
            undefined,
            {
              placeholder: uploadDescription + (!isProspect ? ' *' : ''),
              style: { marginTop: '1rem' },
              fullWidth: true,
            },
            true
          )}
      </div>
    );
  }
}

interface ImageFileReaderProps {
  uploadHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  onClearImage: () => void;
  imageDataURI: string;
  isProspect: boolean;
}

const ImageFileReader = ({ uploadHandler, onClearImage, imageDataURI, isProspect }: ImageFileReaderProps) => {
  const isImage = imageDataURI?.startsWith('data:image/');
  const isPdf = imageDataURI?.startsWith('data:application/pdf');

  let imageElem;
  let textElem;

  const openPdf = () => {
    const pdfPreview = window.open();
    if (pdfPreview) {
      pdfPreview.document.write(
        `<iframe src="${imageDataURI}" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>`
      );
    }
  };

  if (imageDataURI) {
    if (isImage) {
      imageElem = <img src={imageDataURI} />;
    } else if (isPdf) {
      imageElem = <PictureAsPdfIcon />;
    }
    textElem = <div>{translate(`customer.changePhoto`)}</div>;
  } else {
    imageElem = <i className="icon icon-kamera" />;
    textElem = <div>{translate(`customer.uploadPhoto`) + (!isProspect ? ' *' : '')}</div>;
  }

  return (
    <div className={styles.imageCapture}>
      <div className={styles.actions}>
        {imageDataURI && onClearImage !== undefined && (
          <a
            className={styles.clearImage}
            onClick={onClearImage}
            data-tip={translate('customer.clearImage')}
            data-for="image-action-tooltip"
          >
            <CloseIcon />
          </a>
        )}
        {isPdf && (
          <a
            className={styles.openFile}
            onClick={openPdf}
            data-tip={translate('customer.openPreview')}
            data-for="image-action-tooltip"
          >
            <OpenInNewIcon />
          </a>
        )}
        <ReactTooltip place="right" effect="solid" id="image-action-tooltip" />
      </div>
      <input onChange={uploadHandler} type="file" accept="image/*" id="inputimage" name="inputimage" capture="camera" />
      <label htmlFor="inputimage">
        {imageElem}
        {textElem}
      </label>
    </div>
  );
};
