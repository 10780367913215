import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Dot } from './Dot';
import { colors } from 'styles/colors';
import { cannotCompareWeights, formatToDecimals, isAllocator } from 'features/allocator/allocatorUtils';
import {
  isOptimizingCurrent,
  isOptimizingPlan,
  selectCurrentWeights,
  selectPositionsNotInPlan,
} from 'features/allocator/allocatorSelectors';
import { DifferenceCell } from 'features/portfolioManager/components/DifferenceCell';
import { weightTypes, WEIGHT_LIMIT } from 'constants/allocator';
import { LockSmallIcon } from 'features/allocator/common/SvgIcon';
import { Flex } from 'features/common/StyledComponents';
import { CompareToType, DistributionTableItem, ItemType } from 'types/types';
import { Spinner } from 'features/common/Spinner';

interface Props {
  item: DistributionTableItem;
  dotColor: string;
  difference?: number;
  isIgnoredPortfolio?: boolean;
  compareTo: CompareToType;
  itemType: ItemType;
}

export const DistributionRow = ({ dotColor, difference, compareTo, isIgnoredPortfolio, item, itemType }: Props) => {
  const optimizingPlan = useSelector(isOptimizingPlan);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const currentWeights = useSelector(selectCurrentWeights);
  const cantCompare = useSelector(cannotCompareWeights);

  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);

  const showMainSpinner = isAllocator() ? optimizingPlan : optimizingCurrent;
  const showComparisonSpinner = compareTo === weightTypes.currentWeights ? optimizingCurrent : optimizingPlan;

  const illiquid = item?.liquidity === 0;

  const hasWeight = (item?.weight ?? 0) >= WEIGHT_LIMIT;
  const hasDifference = Math.abs(difference ?? 0) >= WEIGHT_LIMIT;

  const showWeight = (hasWeight || hasDifference) && !(isAllocator() && positionsNotInPlan.length > 0); // nasty logic in here

  const showDifference = (hasWeight || hasDifference) && compareTo !== 'none' && !cantCompare;

  const currentItem = currentWeights.portfolioWeights
    ?.find((p) => p.portfolioId === item?.portfolioId)
    ?.instruments.find((i) => i.security === item?.security);

  const inactive =
    !isIgnoredPortfolio &&
    !hasWeight &&
    !showDifference &&
    itemType !== 'instrument' &&
    itemType !== 'portfolioInstrument';

  return (
    <TR data-testkey="distribution-row">
      <DotCell>
        <Dot bg={dotColor} />
      </DotCell>

      <TD inactive={inactive}>
        <Flex>{item.name || item?.security || ''}</Flex>
      </TD>

      {showMainSpinner ? (
        <SpinnerCell>
          <Spinner size={20} position="right" />
        </SpinnerCell>
      ) : (
        <>
          {showDifference && (
            <DifferenceCell difference={difference} color={dotColor} loading={showComparisonSpinner} />
          )}

          {showWeight ? (
            <PercentageCell inactive={inactive} data-testkey="item-weight">
              {isIgnoredPortfolio
                ? formatToDecimals(currentItem?.marketValue, 0, '€')
                : formatToDecimals(item.weight, 1, '%')}
              {illiquid && <LockIcon width="14px" />}
            </PercentageCell>
          ) : (
            <td />
          )}
        </>
      )}
    </TR>
  );
};

const SpinnerCell = styled.td`
  padding: 0;
`;

const TR = styled.tr`
  line-height: 1.3;

  td,
  th {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }
`;

interface CellProps {
  inactive?: boolean;
}

const TD = styled.td<CellProps>`
  ${(props) => props.inactive && `color: ${colors.gray_inactive}`};
  padding-left: 0;
  white-space: break-spaces;
`;

const DotCell = styled.td`
  position: relative;
  width: 1.75rem;
  && {
    padding-left: 0;
    padding-right: 0;
  }
`;

const PercentageCell = styled(TD)`
  text-align: right;
  padding-right: 0;
  width: 75px;
  position: relative;
  white-space: nowrap;
`;

const LockIcon = styled(LockSmallIcon)`
  position: absolute;
  margin-right: 0.5rem;
  left: calc(100% + 5px);
  opacity: 0.8;
  height: 19px;
  top: 50%;
  transform: translate(0, -50%);
`;
