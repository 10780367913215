import { push } from 'react-router-redux';
import { omit, pick } from 'lodash';
import { LocationDescriptor } from 'history';
import {
  IMAGE,
  IMAGE_DATAURI,
  IDENTITY_METHOD,
  IDENTITY_METHOD_EXPIRATION_DATE,
  DESCRIPTION,
  IDENTITY_METHOD_ISSUER,
  IDENTITY_METHOD_ISSUER_COUNTRY,
  IDENTITY_METHOD_NUMBER,
  IDENTITY_METHOD_DATE_OF_ISSUE,
} from 'constants/newCustomer';
import { uploadFile } from 'actions/index';
import { CustomerType } from 'types/profileState';
import { AppThunk } from 'types/types';
import { Prospect } from 'types/prospectsState';
import { getCustomerForm, postCustomer } from 'features/prospect/prospectUtils';
import { GetCustomerFormResponse, Option } from 'features/prospect/prospectTypes';

// Action types

export const CREATE_CUSTOMER_REQUEST = 'CREATE_CUSTOMER_REQUEST';
export const CREATE_CUSTOMER_RECEIVE = 'CREATE_CUSTOMER_RECEIVE';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const DISCARD_CUSTOMER = 'DISCARD_CUSTOMER';
export const NEW_CUSTOMER_OPTION_UPDATE = 'NEW_CUSTOMER_OPTION_UPDATE';
export const NEW_CUSTOMER_FORM_REQUEST = 'FORM_REQUEST';
export const NEW_CUSTOMER_FORM_SUCCESS = 'FORM_SUCCESS';
export const NEW_CUSTOMER_FORM_FAILURE = 'FORM_FAILURE';
export const WIZARD_FIELD_VALIDATION = 'WIZARD_FIELD_VALIDATION';
export const ERROR_OK = 'ERROR_OK';

// Sync actions

export const createCustomerRequest = () => {
  return <const>{
    type: CREATE_CUSTOMER_REQUEST,
  };
};

export const createCustomerReceive = () => {
  return <const>{
    type: CREATE_CUSTOMER_RECEIVE,
    receivedAt: Date.now(),
  };
};

export const createCustomerFailure = (isDuplicate: boolean) => {
  return <const>{
    type: CREATE_CUSTOMER_FAILURE,
    error: isDuplicate ? 'isDuplicate' : 'unexpected',
    receivedAt: Date.now(),
  };
};

export const discardCustomer = () => {
  return <const>{
    type: DISCARD_CUSTOMER,
  };
};

export const newCustomerOptionUpdate = (option: Option) => {
  return <const>{
    type: NEW_CUSTOMER_OPTION_UPDATE,
    option,
  };
};

export const wizardFieldValidation = (key: string) => {
  return <const>{
    type: WIZARD_FIELD_VALIDATION,
    fieldKey: key,
  };
};

export const customerTypeFailure = () => {
  return <const>{
    type: CREATE_CUSTOMER_FAILURE,
    error: 'noCustomerType',
  };
};

export const errorOk = () => {
  return <const>{
    type: ERROR_OK,
  };
};

const newCustomerFormRequest = () => {
  return <const>{
    type: NEW_CUSTOMER_FORM_REQUEST,
  };
};

const newCustomerFormSuccess = (result: GetCustomerFormResponse) => {
  return <const>{
    type: NEW_CUSTOMER_FORM_SUCCESS,
    result,
  };
};

const newCustomerFormFailure = () => {
  return <const>{
    type: NEW_CUSTOMER_FORM_FAILURE,
  };
};

export type ProspectAction = ReturnType<
  | typeof errorOk
  | typeof customerTypeFailure
  | typeof wizardFieldValidation
  | typeof newCustomerOptionUpdate
  | typeof discardCustomer
  | typeof createCustomerFailure
  | typeof createCustomerReceive
  | typeof createCustomerRequest
  | typeof newCustomerFormRequest
  | typeof newCustomerFormSuccess
  | typeof newCustomerFormFailure
>;

// Async actions

export const errorHandler = (fallbackState: LocationDescriptor): AppThunk => {
  return (dispatch) => {
    dispatch(push(fallbackState));
    dispatch(errorOk());
  };
};

export const getNewCustomerForm = (customerType: CustomerType): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(newCustomerFormRequest());

    try {
      const result = await getCustomerForm(customerType, getState().oidc.user.access_token);
      dispatch(newCustomerFormSuccess(result));
    } catch (error) {
      dispatch(newCustomerFormFailure());
    }
  };
};

export const createCustomer = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(createCustomerRequest());
    const { prospects } = getState();
    const formValues = prospects.formValues.toJS();
    const customerData = omit(formValues, [IMAGE, IMAGE_DATAURI]) as Prospect;

    let customerId;
    try {
      customerId = await postCustomer(customerData, getState().oidc.user.access_token);
    } catch (error) {
      const duplicate = error.status === 409;
      dispatch(createCustomerFailure(duplicate));
      return;
    }

    if (!customerId) {
      dispatch(createCustomerFailure(false));
      return;
    }

    const cleanedCustomerId = customerId.replace(/[\\"]/g, '');

    const file = prospects.formValues.get(IMAGE) as File;

    if (file) {
      const info = pick(formValues, [
        IDENTITY_METHOD,
        IDENTITY_METHOD_NUMBER,
        IDENTITY_METHOD_ISSUER,
        IDENTITY_METHOD_ISSUER_COUNTRY,
        IDENTITY_METHOD_DATE_OF_ISSUE,
        IDENTITY_METHOD_EXPIRATION_DATE,
        DESCRIPTION,
      ]);

      try {
        await uploadFile('image', file, info, cleanedCustomerId, getState().oidc.user.access_token);
      } catch (error) {
        dispatch(createCustomerFailure(false));
        return;
      }
    }

    dispatch(createCustomerReceive());
    dispatch(push(`/customer/${encodeURIComponent(cleanedCustomerId)}`));
  };
};
