import PropTypes from 'prop-types';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import _ from 'lodash';
import { Set } from 'immutable';
import { Location } from 'history';
import { WithRouterProps } from 'react-router';
import {
  getContracts as getProfileContracts,
  loadContractDrafts as loadProfileDrafts,
} from 'features/profile/profileActions';
import {
  hideUnSavedDataWarning,
  resetNotification,
  createClientSession,
  showUnSavedDataWarning,
  getContracts,
  loadContractDrafts,
  getCustomer,
  saveContractDrafts,
  mergeSavedContract,
  resetClientSession,
  copyContract,
  showOnlyUnfinishedQuestions,
  showDiscardSessionDialog,
  hideDiscardSessionDialog,
  removeAllSignatories,
} from 'features/contracts/contractsActions';
import ContractsNav from 'features/contracts/components/ContractsNav';
import SecondNav from 'features/contracts/components/SecondNav';
import NavModule from 'features/contracts/components/NavModule';
import { ModalDialog } from 'features/common/ModalDialog';
import { getModuleStatus, getCustomerType } from 'core/index';
import { DiscardSessionDialog } from 'features/contracts/components/DiscardSessionDialog';
import ErrorBoundary from 'features/common/ErrorBoundary';
import styles from './ContractsView.scss';
import { ImmutableTemplates, Template, FieldType } from 'types/ordersState';
import { ImmutableContent, ImmutableCustomer } from 'types/profileState';
import {
  ImmutableFormErrors,
  ImmutableSession,
  GetCustomerResult,
  ImmutableSelectedContracts,
  ContractLink,
  ImmutableContractSignatories,
} from 'types/contractsState';
import { RootState } from 'types/rootState';
import StyledSnackbar from 'features/common/styledComponents/StyledSnackbar';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { SUITABILITY_TEMPLATE_ID } from './contractsConstants';

interface ComponentProps {
  getContracts: (customerType: string) => void;
  getProfileContracts: (customerId: string) => void;
  loadProfileDrafts: (customerId: string) => void;
  getCustomer: (customerId: string) => Promise<GetCustomerResult>;
  createClientSession: (newClientId: string, newClientName: string) => void;
  saveContractDrafts: (oldClientId: string, notify?: boolean) => Promise<void>;
  resetClientSession: ({ deleteDraft }: { deleteDraft: boolean }) => Promise<void>;
  params: { id: string };
  session: ImmutableSession;
  customer: ImmutableCustomer;
  canSave: boolean;
  showUnSavedDataWarning: () => void;
  unsavedDialogOpen: boolean;
  hideUnSavedDataWarning: () => void;
  location: Location;
  mergeSavedContract: (customerId: string) => void;
  showModuleNav: boolean;
  links: ContractLink[];
  isReady: boolean;
  formTouched: Set<string>;
  formErrors: ImmutableFormErrors;
  values: ImmutableContent;
  templates: ImmutableTemplates;
  module: object;
  contractName: string;
  moduleName: string;
  children: ReactElement[];
  notify: boolean;
  notifyMsg: string;
  resetNotification: () => void;
  showOnlyUnfinished: boolean;
  showOnlyUnfinishedQuestions: () => void;
  showDiscardSessionDialog: () => void;
  hideDiscardSessionDialog: () => void;
  isDiscardSessionDialogOpen: boolean;
  requireValidContracts: boolean;
  selected: ImmutableSelectedContracts;
  copyContract: (contractId: string) => void;
  loadContractDrafts: (customerId: string) => Promise<void>;
  removeAllSignatories: () => void;
  signatories: ImmutableContractSignatories;
}

type Props = ComponentProps & WithRouterProps;

class ContractsView extends React.Component<Props> {
  autoSave = 0;
  customerId = this.props.params.id;

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.getCustomerData();
    this.autoSave = this.createAutoSave();
  }

  componentWillUnmount() {
    this.reloadProfileDraftsAndContracts(this.props.params.id);
    window.clearInterval(this.autoSave);
  }

  onDialogSave = (oldClientId: string, newClientId: string, newClientName: string) => {
    this.props
      .saveContractDrafts(oldClientId)
      .then(async () => {
        await this.props.resetClientSession({ deleteDraft: false });
        this.props.createClientSession(newClientId, newClientName);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  onDialogDiscard = async (newClientId: string, newClientName: string) => {
    await this.props.resetClientSession({ deleteDraft: false });
    this.props.createClientSession(newClientId, newClientName);
  };

  reloadProfileDraftsAndContracts = (customerId: string) => {
    this.props.getProfileContracts(customerId);
    this.props.loadProfileDrafts(customerId);
  };

  onReady = () => {
    const { params, removeAllSignatories, signatories } = this.props;
    if (!signatories.isEmpty()) {
      removeAllSignatories();
    }
    this.context.router.push(`/customer/${params.id}`);
  };

  onClose = () => {
    if (this.props.canSave) {
      return this.props.showUnSavedDataWarning();
    }
    this.context.router.push(`/customer/${this.props.params.id}`);
    return undefined;
  };

  onSaveDraftsClick = async () => {
    const { params, saveContractDrafts } = this.props;
    await saveContractDrafts(params.id);
  };

  onDiscardSessionDialogOnCancel = () => {
    this.props.hideDiscardSessionDialog();
  };

  onDiscardSessionDialogOnDiscard = () => {
    this.props.hideDiscardSessionDialog();
    const { params, resetClientSession } = this.props;
    resetClientSession({ deleteDraft: true })
      .then(() => {
        this.context.router.push(`/customer/${params.id}`);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  getCustomerData() {
    this.props
      .getCustomer(this.customerId)
      .then((results) => {
        this.props.getContracts(getCustomerType(results?.result?.customerType, results.result.segment));
        if (this.props.session.count() === 0) {
          const name = results.result.header;
          this.props.createClientSession(this.customerId, name);
          this.mergeSavedContract();
        } else {
          const sessionId = this.props.session.get('id');
          const customerId = this.props.customer.get('shortId');
          if (sessionId && customerId && sessionId === customerId) {
            this.mergeSavedContract();
          }
        }
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  }

  discardUnsavedDataClick = async () => {
    const { hideUnSavedDataWarning, resetClientSession, params } = this.props;
    hideUnSavedDataWarning();
    await resetClientSession({ deleteDraft: false });
    this.context.router.push(`/customer/${params.id}`);
  };

  saveUnsavedDataClick = () => {
    const { params, saveContractDrafts, hideUnSavedDataWarning } = this.props;
    saveContractDrafts(params.id, false)
      .then(() => {
        hideUnSavedDataWarning();
        this.context.router.push(`/customer/${params.id}`);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  closeUnsavedDataDialog = (buttonClicked: boolean) => {
    if (!buttonClicked) {
      this.props.hideUnSavedDataWarning();
    }
  };

  saveUnsavedSession = () => {
    const { session, customer } = this.props;
    const customerId = customer.get('shortId');
    const sessionId = session.get('id');
    const header = customer.get('header');
    this.onDialogSave(sessionId, customerId, header);
  };

  discardUnsavedSession = async () => {
    const { customer } = this.props;
    const customerId = customer.get('shortId');
    const header = customer.get('header');
    await this.onDialogDiscard(customerId, header);
  };

  createAutoSave = () =>
    window.setInterval(() => {
      const { saveContractDrafts, params, canSave } = this.props;
      if (canSave) {
        saveContractDrafts(params.id, false).catch((error) => {
          console.error(error); // eslint-disable-line no-console
        });
      }
    }, 60000);

  showSessionWarning = (customerId: string, sessionId: string) => {
    if (sessionId && customerId && sessionId !== customerId) {
      return true;
    }
    return false;
  };

  discardSession = () => {
    this.props.showDiscardSessionDialog();
  };

  mergeSavedContract = () => {
    const { location, params } = this.props;
    if (location.state && location.state.restore) {
      this.props
        .loadContractDrafts(params.id)
        .then(() => {
          this.props.mergeSavedContract(this.customerId);
        })
        .catch((error) => {
          console.error(error); // eslint-disable-line no-console
        });
    }

    if (location.state && location.state.copy) {
      const contractId = location.state.copy;
      this.props.copyContract(contractId);
    }
  };

  render() {
    const {
      params,
      customer,
      showModuleNav,
      links,
      location,
      canSave,
      session,
      isReady,
      formTouched,
      formErrors,
      module,
      values,
      templates,
      showOnlyUnfinished,
      showOnlyUnfinishedQuestions,
      requireValidContracts,
      selected,
      saveContractDrafts,
    } = this.props;
    let contractsCompleted: boolean;
    let nav;

    if (!showModuleNav) {
      let totalCount = 0;
      let totalCompleted = 0;
      if (requireValidContracts) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const selectedTemplates = templates.filter((t) => selected.get(t.contractType) === true);
        selectedTemplates.valueSeq().forEach((contract) => {
          const contractType = contract?.contractType;
          if (contractType && contract) {
            const linkTypeKey = 'textLinkKey';
            const tableKey = 'tableKey';
            if (contractType === SUITABILITY_TEMPLATE_ID) {
              return;
            }
            contract.form.items.forEach((section, index, sections) => {
              const moduleStatus = getModuleStatus(contractType, formTouched, formErrors, section, values, sections);
              if (!moduleStatus.fieldsVisible || moduleStatus.fieldsVisible.length === 0) {
                return;
              }

              const doneFiltered = moduleStatus.fieldsDone.filter(
                (f: FieldType) => f.key && !f.key.includes(linkTypeKey) && !f.key.includes(tableKey)
              );
              const countFiltered = moduleStatus.fieldsVisible.filter(
                (f: FieldType) => f.key && !f.key.includes(linkTypeKey) && !f.key.includes(tableKey)
              );
              const done = doneFiltered.length;
              const count = countFiltered.length;
              totalCompleted += done;
              totalCount += count;
            });
          }
        });
      }
      contractsCompleted = totalCompleted !== 0 && totalCompleted === totalCount;

      if (!contractsCompleted) {
        contractsCompleted = totalCompleted === 0 && totalCount === 0; // it's also complete, if there is no answerable questions
      }

      nav = (
        <section>
          <ContractsNav
            customerName={customer.get('header')}
            saveDrafts={this.onSaveDraftsClick}
            discard={this.discardSession}
            enableSave={canSave}
            close={this.onClose}
            isReady={isReady}
            onReady={this.onReady}
            saveContractDrafts={saveContractDrafts.bind(this, params.id, false)}
          />
          <SecondNav isReady={isReady} links={links} location={location} contractsCompleted={contractsCompleted} />
        </section>
      );
    } else {
      const overviewLink = `/customer/${params.id}/contracts/overview`;
      const moduleStatus = getModuleStatus(
        params.contract,
        formTouched,
        formErrors,
        module,
        values,
        templates.get(params.contract).form.items
      );
      nav = (
        <NavModule
          contractName={this.props.contractName}
          moduleName={this.props.moduleName}
          closeLink={overviewLink}
          moduleStatus={moduleStatus}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          showOnlyUnfinished={showOnlyUnfinished}
          showOnlyUnfinishedQuestions={showOnlyUnfinishedQuestions}
          saveContractDrafts={saveContractDrafts.bind(this, params.id, false)}
          enableSave={canSave}
        />
      );
    }

    const clientName = session.get('name');

    const children = React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { contractsCompleted })
    );

    return (
      <section>
        {nav}

        <ErrorBoundary>
          <section className={styles.content}>{children}</section>
        </ErrorBoundary>

        <ModalDialog
          title="Tallentamattomia muutoksia"
          open={this.showSessionWarning(this.props.customer.get('shortId'), this.props.session.get('id'))}
          actions={[
            <StyledButton variant="text" onClick={this.saveUnsavedSession} key={'Tallenna'}>
              Tallenna
            </StyledButton>,
            <StyledButton variant="text" onClick={this.discardUnsavedSession} key={'Hylkää'}>
              Hylkää
            </StyledButton>,
          ]}
          content={`Sopimusten muokkaus kesken asiakkaalla: ${clientName}`}
        />

        <ModalDialog
          title="Tallentamattomia muutoksia"
          open={this.props.unsavedDialogOpen}
          actions={[
            <StyledButton
              variant="text"
              onClick={this.saveUnsavedDataClick}
              key={'Tallenna'}
              data-testkey="save-contract-draft"
            >
              Tallenna
            </StyledButton>,
            <StyledButton
              variant="text"
              onClick={this.discardUnsavedDataClick}
              key={'Hylkää'}
              data-testkey="discard-contract-draft"
            >
              Hylkää
            </StyledButton>,
          ]}
          close={this.closeUnsavedDataDialog}
          content={`Keskeneräisissä sopimuksissa tallentamattomia muutoksia.`}
        />

        <DiscardSessionDialog
          isOpen={this.props.isDiscardSessionDialogOpen}
          onCancel={this.onDiscardSessionDialogOnCancel}
          onDiscard={this.onDiscardSessionDialogOnDiscard}
        />

        <StyledSnackbar
          autoHideDuration={1500}
          open={this.props.notify}
          message={this.props.notifyMsg}
          onClose={this.props.resetNotification}
        />
      </section>
    );
  }
}

const getSelectedModuleName = (templates: { [key: string]: Template }, contractId: string, moduleId: string) => {
  const contract = _.find(templates, (template) => template.contractType === contractId);
  const module = _.find(contract?.form.items, (section) => section.key === moduleId);
  return {
    contractName: contract?.schema.name,
    moduleName: module?.title.fi,
    module,
  };
};

const mapStateToProps = (state: RootState, ownProps: WithRouterProps) => {
  let selectedModule = {};
  if (ownProps.params.module && ownProps.params.contract) {
    selectedModule = getSelectedModuleName(
      state.contracts.templates.toJS(),
      ownProps.params.contract,
      ownProps.params.module
    );
  }

  const attachmentsHaveChanged =
    state.contracts.lastAttachmentPdfs &&
    state.contracts.lastAttachmentPdfs.length !== state.contracts.attachmentPdfs.length;

  const canSave =
    (state.contracts.values.count() > 0 && state.contracts.values !== state.contracts.lastSavedValues) ||
    (state.contracts.values.count() > 0 && attachmentsHaveChanged);

  return {
    contracts: state.contracts.contracts,
    templates: state.contracts.templates,
    links: state.contracts.links,
    customer: state.profile.customer,
    showModuleNav: ownProps.params.module !== undefined,
    notify: state.contracts.notify,
    notifyMsg: state.contracts.notifyMsg,
    canSave,
    session: state.contracts.session,
    isReady: state.contracts.isReady,
    unsavedDialogOpen: state.contracts.showUnSavedDataWarning,
    formTouched: state.contracts.formTouched,
    formErrors: state.contracts.formErrors,
    values: state.contracts.values,
    ...selectedModule,
    showOnlyUnfinished: state.contracts.showOnlyUnfinished,
    isDiscardSessionDialogOpen: state.contracts.showDiscardSessionDialog,
    requireValidContracts: state.contracts.requireValidContracts,
    selected: state.contracts.selected,
    signatories: state.contracts.contractsSignatories,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      hideUnSavedDataWarning,
      resetNotification,
      createClientSession,
      showUnSavedDataWarning,
      getContracts,
      loadContractDrafts,
      getCustomer,
      getProfileContracts,
      loadProfileDrafts,
      saveContractDrafts,
      mergeSavedContract,
      resetClientSession,
      copyContract,
      showOnlyUnfinishedQuestions,
      showDiscardSessionDialog,
      hideDiscardSessionDialog,
      removeAllSignatories,
    },
    dispatch
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ContractsView);
