import React from 'react';
import { List } from 'immutable';
import { SearchResultItem } from './SearchResultItem';
import styles from './SearchResults.scss';
import { ImmutableSearchResult } from 'types/searchState';
import { User } from 'types/types';

interface Props {
  results: List<ImmutableSearchResult>;
  clickUser: (user: User) => void;
  title: string;
}

export const SearchResults = ({ results, clickUser, title }: Props) => {
  return (
    <div className={styles.searchResults}>
      <header>
        <h2 data-testkey="search-result-title">{title}</h2>
      </header>
      <ul>
        {results?.map((result, index) => {
          const user = result?.toJS();
          if (!user) {
            return null;
          }
          return <SearchResultItem key={index} onClick={clickUser} user={user} />;
        })}
      </ul>
    </div>
  );
};
