import React, { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { StickyContainer, Sticky } from 'react-sticky';
import { Location } from 'history';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import { Box } from '@mui/material';
import PortfolioDetailsHeader from 'features/portfolio/components/PortfolioDetailsHeader';
import { FilteredPositionList } from 'features/portfolio/components/FilteredPositionList';
import { PortfolioFilter } from 'features/portfolio/components/PortfolioFilter';
import styles from 'features/portfolio/components/Details.scss';
import Error from 'features/common/404';
import { AssignmentEditorView } from 'features/orderDialog/AssignmentEditorView';
import { OrderLines } from 'features/orderLines/OrderLines';
import { Summary } from 'features/portfolioSummary/components/Summary';
import Pledged from 'features/portfolio/components/Pledged';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { ORDER_FROM_BUTTON_FAIL_DIALOG } from 'constants/orderDialog';
import { SELL, BUY, DEFAULT_SIDE_FROM_BUTTON } from 'constants/sides';
import { Spinner } from 'features/common/Spinner';
import {
  initEditor,
  showConfirmationDialog,
  setSuitabilityErrors,
  createOrderLine,
  getTransactionFees,
} from 'features/orderDialog/orderDialogActions';
import { ordersContractsPortfoliosReady } from 'features/portfolio/portfolioUtils';
import { EditorWithSuitabilityErrors } from 'types/orderDialogState';
import {
  getVisiblePositions,
  selectMarketInfoByPortfolio,
  selectVisibleMarketInfo,
  selectCurrentPortfolio,
  selectCanDoAssignments,
  selectIsPortfolioPledged,
  selectContractPrefilled,
  selectAllContractsPrefilled,
} from 'features/portfolio/portfolioSelectors';
import { selectContractOrderLines, selectOrderLines } from 'features/orderLines/orderLinesSelectors';
import { RootState } from 'types/rootState';
import { FUND } from 'constants/instrumentForms';
import { getValidationErrors } from 'features/orderDialog/orderDialogUtils';
import { useAppDispatch } from 'core/hooks';
import { colors } from 'styles/colors';

interface Props {
  location: Location;
}

export const PortfolioDetailsView = ({ location }: Props) => {
  const dispatch = useAppDispatch();
  const editorRef = useRef<HTMLElement>(null);

  const state = useSelector((state: RootState) => state);
  const positions = useSelector(getVisiblePositions);
  const marketInfoByPortfolio = useSelector(selectMarketInfoByPortfolio);
  const marketInfo = useSelector(selectVisibleMarketInfo);
  const newOrders = useSelector(selectContractOrderLines);

  const assignmentsAllowed = useSelector(selectCanDoAssignments);
  const isPortfolioPledged = useSelector(selectIsPortfolioPledged);

  const tradeOrderLines = useSelector((state: RootState) => state.portfolioManager.valueData.tradeOrderLines);
  const customerId = useSelector((state: RootState) => state.profile.customer)?.get('customerId');
  const orders = useSelector((state: RootState) => state.orders.ordersByPortfolioId);
  const portfolios = useSelector((state: RootState) => state.portfolio.portfolios);
  const hasError = useSelector((state: RootState) => state.portfolio.hasError);
  const error = useSelector((state: RootState) => state.portfolio.error);
  const portfolioDetailsById = useSelector((state: RootState) => state.portfolio.portfolioDetailsById);
  const orderLines = useSelector(selectOrderLines);

  const loadingPositions = useSelector((state: RootState) => state.portfolio.loadingPositions);
  const loadingPortfolios = useSelector((state: RootState) => state.portfolio.loadingPortfolios);
  const isBusy = loadingPositions || loadingPortfolios;

  const loadingContractTemplates = useSelector((state: RootState) => state.orders.loadingContractTemplates);

  const filter = location.query.f;
  const importOrderLines = location.query.importOrderLines === 'true';

  const [loadingFees, setLoadingFees] = useState<boolean>(importOrderLines && orderLines.toJS().length === 0);

  const contractReady = filter === 'all' ? selectAllContractsPrefilled(state) : selectContractPrefilled(state);
  const portfoliosReady = ordersContractsPortfoliosReady(orders, portfolioDetailsById, contractReady);

  useEffect(() => {
    if (!location.search) {
      dispatch(push(`${location.pathname}?f=all`));
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (importOrderLines && portfoliosReady && !loadingContractTemplates && orderLines.toJS().length === 0) {
        await importTradeOrderLines();
      }
    };
    void loadData();
  }, [portfoliosReady, loadingContractTemplates]);

  const importTradeOrderLines = async () => {
    setLoadingFees(true);
    const getFeesPromises = tradeOrderLines
      .filter((i) => i.quantity > 0 || i.amount > 0)
      .map(async (i) => {
        if (i.financialInstrumentForm !== FUND) {
          return i;
        } else {
          const fee = await dispatch(getTransactionFees(customerId, i.portfolioId, i.financialInstrumentId, i.side));
          return {
            ...i,
            defaultFee: fee?.feePercentage,
            subscriptionFee: fee?.feePercentage * 100,
          };
        }
      });
    const instrumentsWithFees = await Promise.all(getFeesPromises);
    setLoadingFees(false);

    instrumentsWithFees.map((orderLine) => {
      // To pass validation, quantity and amount cannot both have values
      //   -> zero value must be completely deleted
      if (orderLine.quantity === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete orderLine.quantity;
      }

      if (orderLine.amount === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        delete orderLine.amount;
      }

      const errors = getValidationErrors(state, orderLine);
      // Throw errors -> Cypress tests will catch them
      if (errors.length > 0) {
        throw `Error validating imported orderline \n\nError: ${JSON.stringify(errors)} \n\nOrderline: ${JSON.stringify(
          orderLine
        )} `;
      }

      dispatch(createOrderLine(orderLine));
    });
  };

  const onPositionClick = (assignment: EditorWithSuitabilityErrors) => {
    const portfolio = selectCurrentPortfolio(state);
    dispatch(
      initEditor({
        portfolioId: portfolio.portfolioId,
        isBuyingNew: false,
        isEditingExistingLine: false,
        initialValues: assignment.editor,
      })
    );
    editorRef.current?.scrollIntoView(false);
    // When buying/selling directly from position row, need to set suitability errors also in "normal" place in redux to continue with order flow
    if (DEFAULT_SIDE_FROM_BUTTON === SELL && assignment.suitabilityErrorsSell?.length !== 0) {
      dispatch(setSuitabilityErrors(assignment.suitabilityErrorsSell));
      dispatch(showConfirmationDialog(ORDER_FROM_BUTTON_FAIL_DIALOG));
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (DEFAULT_SIDE_FROM_BUTTON === BUY && assignment.suitabilityErrorsBuy.length !== 0) {
      dispatch(setSuitabilityErrors(assignment.suitabilityErrorsBuy));
      dispatch(showConfirmationDialog(ORDER_FROM_BUTTON_FAIL_DIALOG));
    }
  };

  const hasPortfolioDetailsById = Object.keys(portfolioDetailsById).length > 0;

  let component;
  let loadingComponent = null;
  if (hasError) {
    component = (
      <div style={{ textAlign: 'center', margin: '0 auto' }}>
        <Error error={error} />
      </div>
    );
  } else {
    component = (
      <section className={styles.columnContainer}>
        <div className={styles.filter}>
          <PortfolioFilter
            baseUrl={location.pathname}
            portfolios={portfolios.toJS()}
            marketInfoByPortfolio={marketInfoByPortfolio}
            loading={false}
          />
        </div>

        <StickyContainer style={{ width: '100%', overflow: 'auto', height: 'auto' }}>
          <PortfolioDetailsHeader marketInfo={marketInfo} loading={false} />

          {loadingFees && <StyledSpinner />}

          <Summary
            portfolioDetailsById={portfolioDetailsById}
            marketInfoByPortfolio={marketInfoByPortfolio}
            visible={filter === 'all'}
          />

          {newOrders.size > 0 && hasPortfolioDetailsById && (
            <Box
              style={{
                marginBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingBottom: 25,
                paddingTop: 15,
                borderBottom: '6px solid ' + colors.gray_inactive,
                // borderTop: '6px solid ' + colors.gray_inactive,
                backgroundColor: colors.gray_light,
              }}
            >
              <OrderLines />
            </Box>
          )}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <div ref={editorRef}>
            <Pledged isPortfolioPledged={isPortfolioPledged} />

            <ErrorBoundary>
              <AssignmentEditorView
                customerId={customerId}
                filter={filter as string}
                assignmentsAllowed={assignmentsAllowed}
              />
            </ErrorBoundary>

            {!contractReady && !isPortfolioPledged && filter !== 'all' && (
              <StyledSpinner className={styles.spinner} size={50} />
            )}
          </div>
          <Box
            style={{
              borderTop: '6px solid ' + colors.gray_inactive,
              paddingTop: 20,
            }}
          >
            <FilteredPositionList
              positions={positions}
              marketInfo={marketInfo}
              onPositionClick={onPositionClick}
              portfolioDetailsById={portfolioDetailsById}
            />
          </Box>
        </StickyContainer>
      </section>
    );
    loadingComponent = (
      <section className={styles.portfolioDetails}>
        <section className={styles.columnContainer}>
          <div className={styles.filter}>
            <PortfolioFilter
              baseUrl={''}
              portfolios={portfolios.toJS()}
              marketInfoByPortfolio={marketInfoByPortfolio}
              loading={true}
            />
            <StyledSpinner size={30} />
          </div>

          <StickyContainer style={{ width: '100%', overflow: 'auto', height: 'auto' }}>
            <Sticky relative>
              {({ style }) => <PortfolioDetailsHeader marketInfo={marketInfo} style={style} loading={true} />}
            </Sticky>
            <StyledSpinner size={100} />
          </StickyContainer>
        </section>
      </section>
    );
  }

  return isBusy ? loadingComponent : <section className={styles.portfolioDetails}>{component}</section>;
};

const StyledSpinner = styled(Spinner)`
  margin: 6rem 0;
`;
