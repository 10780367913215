import React, { ReactElement, useState } from 'react';
import translate from 'counterpart';
import classnames from 'classnames';
import styles from './DeleteButton.scss';
import { ModalDialog } from './ModalDialog';
import StyledButton from './styledComponents/StyledButton';

interface Props {
  confirmHeader: string;
  confirmContent: string | ReactElement;
  action: () => void;
}

export const DeleteButton = (props: Props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);

  const openConfirm = () => {
    setConfirmOpen(true);
  };

  const closeConfirm = () => {
    setConfirmOpen(false);
  };

  const handleDelete = () => {
    props.action();
    closeConfirm();
  };
  const { confirmHeader, confirmContent } = props;
  const dialogActions = [
    <StyledButton variant="outlined" onClick={handleDelete} key={'common.remove'}>
      {translate('common.remove')}
    </StyledButton>,
    <StyledButton variant="text" onClick={closeConfirm} key={'common.cancelAction'}>
      {translate('common.cancelAction')}
    </StyledButton>,
  ];

  return (
    <>
      <ModalDialog open={confirmOpen} title={confirmHeader} content={confirmContent} actions={dialogActions} />
      <i className={classnames('icon icon-close', styles.delete)} onClick={openConfirm} title="Poista" />
    </>
  );
};
