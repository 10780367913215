export const SELECT = 'select';
export const BOOLEAN = 'boolean';
export const ARRAY = 'array';
export const CHECKBOX = 'checkbox';
export const COUNTRY = 'country';
export const NUMBER = 'number';
export const STRING = 'string';
export const EMAIL = 'email';
export const SELECT_MANY = 'selectMany';
export const OBJECT = 'object';
export const DATE_TIME = 'date-time';
export const CRM_PERSON_SEARCH = 'crmPersonSearch';
export const CONTACT_ID_SEARCH = 'contactIdSearch';
export const IBAN = 'iban';
