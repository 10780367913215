import React, { useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import TextField from '@mui/material/TextField';
import translate from 'counterpart';
import { isValidEmail } from 'core/validations';
import { SelectWithLabel } from './SelectWithLabel';
import { capitalCallMethods, CAPITAL_CALL_BY_EMAIL } from 'constants/receiveInfoOptions';
import { requireCapitalCall } from 'core/portfolios';
import { SelectValue } from 'types/types';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  assignment: OrderLineType;
  isInsurancePortfolio: boolean | undefined;
}

export const CapitalCall = ({ assignment, isInsurancePortfolio }: Props) => {
  const dispatch = useAppDispatch();

  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const capitalCall = requireCapitalCall(assignment.financialInstrumentId, isInsurancePortfolio);

  if (!capitalCall) {
    return null;
  }

  const setCapitalCall = (obj: SelectValue) => {
    dispatch(setEditorValue('capitalCallMethod', obj.value));
  };

  const setCapitalCallEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isValidEmail(value)) {
      setErrorText('Virheellinen sähköpostiosoite');
    } else {
      setErrorText(undefined);
    }
    dispatch(setEditorValue('capitalCallEmail', value));
  };

  const options = cloneDeep(capitalCallMethods);

  return (
    <div className="row" key="m">
      <div className="columns small-6">
        <SelectWithLabel
          label={translate('order.capitalCallMethod')}
          options={options}
          onChange={setCapitalCall}
          value={assignment.capitalCallMethod}
          clearable={false}
          className={'Select-white'}
          data-testkey="capital-call-method"
        />
      </div>
      {assignment.capitalCallMethod === CAPITAL_CALL_BY_EMAIL && (
        <div className="columns small-3">
          <TextField
            defaultValue={assignment.capitalCallEmail}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onBlur={setCapitalCallEmail}
            name="capitalCallEmail"
            helperText={errorText}
            label={translate('order.capitalCallEmail')}
            InputLabelProps={{
              sx: {
                color: '#30201b',
              },
            }}
            data-testkey="textfield-capital-call-email"
            variant="standard"
          />
        </div>
      )}
    </div>
  );
};
