import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import {
  isLoadingInitialItems,
  isOptimizingPlan,
  selectAum,
  selectHasPositions,
  selectOptimizedWeightsForRiskLevel,
} from 'features/allocator/allocatorSelectors';
import { CustomerSummaryAssetClasses } from 'features/portfolioManager/customerSummary/components/CustomerSummaryAssetClasses';
import { FlexJustifyBetween, NumberInput, styles } from 'features/common/StyledComponents';
import { ASSET_CATEGORY_COLORS } from 'styles/colors';
import { AssetCategoryName } from 'types/types';
import { Grid } from 'features/allocator/common/Grid';
import { ConfidenceLevels } from 'types/weightsState';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { LinkBack } from 'features/common/LinkBack';
import { Spinner } from 'features/common/Spinner';
import { YieldGraphAndLegend } from 'features/allocator/common/YieldGraphAndLegend';
import { CreatePNG } from 'features/portfolioManager/customerSummary/components/CreatePNG';
import { CustomerSummaryInstruments } from 'features/portfolioManager/customerSummary/components/CustomerSummaryInstruments';
import { CustomerSummaryPie } from 'features/portfolioManager/customerSummary/components/CustomerSummaryPie';
import { customerHasOptimizedWeights } from 'features/weights/weightsSelectors';

export const PlanSummaryView = () => {
  const [cashAmount, setCashAmount] = useState<string>('0');

  const hasPositions = useSelector(selectHasPositions);
  const aum = useSelector(selectAum);

  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel)?.withoutIlliquids;
  const customerId = useSelector(selectCustomerId);
  const loadingInitialItems = useSelector(isLoadingInitialItems);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const hasOptimizedWeights = useSelector(customerHasOptimizedWeights('withoutIlliquids'));

  const portfolioReturn = optimizedWeights?.returnStatistics.portfolioReturn;
  const confidenceLevels = (optimizedWeights?.confidenceLevels ||
    optimizedWeights?.companyOptimizationForecastStatistics?.confidenceLevels) as ConfidenceLevels;

  const backLink = `customer/${customerId}/portfolioManager/allocator`;

  const totalValue = hasPositions ? aum : Number(cashAmount) * 1000;
  const maxWeight = Math.max(...optimizedWeights.instrumentWeights.flatMap((a) => a?.weight || 0));

  const onChangeCashInput = (e: React.SyntheticEvent) => {
    const el = e.target as HTMLInputElement;
    const formattedValue = el.value.toString().replace(/^0+/, '') || '';
    setCashAmount(formattedValue);
  };

  if (loadingInitialItems || optimizingPlan) {
    return <StyledSpinner />;
  }

  return (
    <ContainerGrid vertical>
      <LinkBack
        to={backLink}
        label={translate('allocator.summaryView.backToAllocator')}
        data-testkey="summary-back-to-allocator"
      />

      <Settings>
        {!hasPositions && (
          <div>
            <h2>{translate('allocator.summaryView.portfolioSize')}</h2>
            <InputContainer>
              <Input
                onChange={onChangeCashInput}
                value={cashAmount}
                min={0}
                onFocus={(e) => e.target.select()}
                data-testkey="input-portfolio-size"
              />
            </InputContainer>
          </div>
        )}
        <CreatePNG preventCreateImages={!hasOptimizedWeights} />
      </Settings>

      <PrintableArea1 className="customersummary">
        <CustomerSummaryPie weights={optimizedWeights} totalValue={Number(totalValue)} />
        <CustomerSummaryAssetClasses weights={optimizedWeights} showDifference={false} />
        <ProjectionChart>
          <YieldGraphAndLegend
            confidenceLevels={confidenceLevels}
            portfolioReturn={portfolioReturn}
            startValue={Number(totalValue) || 100}
          />
        </ProjectionChart>
      </PrintableArea1>

      <PrintableArea2 className="customersummary">
        <CustomerSummaryInstruments
          instruments={optimizedWeights.instrumentWeights}
          totalValue={Number(totalValue)}
          maxWeight={maxWeight}
        />
        <div />
      </PrintableArea2>
    </ContainerGrid>
  );
};

const StyledSpinner = styled(Spinner)`
  margin: 2rem;
`;

const ContainerGrid = styled(Grid)`
  margin: 2rem;
`;

const Settings = styled(FlexJustifyBetween)`
  justify-content: space-between;
  grid-template-columns: auto auto;
`;

const InputContainer = styled.div`
  position: relative;
  display: inline-flex;
  margin: 0.25rem 0;
  width: 10rem;

  &:after {
    content: '000 €';
    position: absolute;
    right: 0.5rem;
    font-size: 18px;
    font-weight: 900;
    opacity: 0.8;
    height: 100%;
    display: flex;
    align-items: center;
  }
`;

const Input = styled(NumberInput)`
  text-align: right;
  position: relative;
  padding-right: 3.8rem;
  font-size: 18px;
  font-weight: 900;
`;

const PrintableArea1 = styled(Grid)`
  grid-template-columns: 3fr 4fr 2fr;
  grid-template-areas:
    'pie assetClasses assetClasses'
    'chart chart xxx';
  padding-bottom: 0.5rem;
`;

const PrintableArea2 = styled(Grid)`
  grid-template-columns: 3fr 4fr 2fr;
  padding-bottom: 0.5rem;
  grid-template-areas: 'instruments instruments zzz';
`;

interface AssetCategoryRow {
  assetCategory: AssetCategoryName;
}

const Row = styled(FlexJustifyBetween)`
  margin-bottom: 0.5rem;
`;

const AssetCategoryRow = styled(Row)<AssetCategoryRow>`
  color: ${(props) => ASSET_CATEGORY_COLORS[props.assetCategory]};
`;

const ProjectionChart = styled.div`
  ${styles.card}
  box-shadow: none;
  grid-area: chart;
`;
