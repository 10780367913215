import React from 'react';
import translate from 'counterpart';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { errorHandler } from 'features/prospect/prospectActions';
import styles from './WizardError.scss';
import { RootState } from 'types/rootState';

interface Props {
  errorHandler: (fallbackState: string) => void;
  fallbackState: string;
  titleKey: string;
  bodyKey: string;
}

class WizardError extends React.Component<Props> {
  handleOk = () => {
    this.props.errorHandler(this.props.fallbackState);
  };
  render() {
    // Combine with global styles
    const revealClasses = classNames('reveal', styles.reveal);
    const overlayClasses = classNames('reveal-overlay', styles.revealOverlay);
    const { titleKey, bodyKey } = this.props;
    return (
      <div className={overlayClasses}>
        <div className={revealClasses}>
          <div className={styles.title}>
            <h1>{translate(titleKey)}</h1>
          </div>
          <p>{translate(bodyKey)}</p>
          <button onClick={this.handleOk} className="close-button">
            <span>×</span>
          </button>
          <div className={styles.divider} />
          <div className={styles.buttons}>
            <button onClick={this.handleOk} className="button">
              <span>{translate(`wizard.revealOk`)}</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return { ...state.prospects.error };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ errorHandler }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardError);
