import React from 'react';
import styled, { css } from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { FlexJustifyBetween, FlexAlignCenter, Flex, fontSize } from 'features/common/StyledComponents';
import { OptimizationMannerId } from 'types/types';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { colors } from 'styles/colors';
import {
  getPlanPortfolioContractType,
  getPortfolioIndex,
  getPortfolioOptimizationModel,
  isIgnoredPortfolio,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { formatToDecimals, isAllocator } from 'features/allocator/allocatorUtils';
import {
  isOptimizingPlan,
  isOptimizingCurrent,
  selectCurrentWeights,
  selectPlanState,
  selectPortfolioName,
} from 'features/allocator/allocatorSelectors';
import { InstrumentWithWeight } from 'types/instrumentsState';
import { Spinner } from 'features/common/Spinner';
import { optimizationManners } from 'constants/allocator';

interface Props {
  items: InstrumentWithWeight[];
  isIgnored: boolean;
  portfolio: AllocatorPortfolio;
}

export const PortfolioNameRow = ({ items, portfolio, isIgnored }: Props) => {
  const optimizingPlan = useSelector(isOptimizingPlan);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const planState = useSelector(selectPlanState);
  const currentWeights = useSelector(selectCurrentWeights);
  const portfolioName = useSelector(selectPortfolioName(portfolio.portfolioId));

  const totalWeight = items?.reduce((acc, curr) => acc + (curr?.weight || 0), 0);
  const totalValue = items?.reduce((acc, item) => {
    const currentItem = currentWeights?.portfolioWeights
      .find((p) => p.portfolioId === portfolio?.portfolioId)
      ?.instruments.find((i) => i.security === item?.security);
    const value = isIgnored ? currentItem?.marketValue : item?.marketValue;
    return acc + (value || 0);
  }, 0);

  const formattedWeight =
    !!portfolio && isIgnoredPortfolio(portfolio)
      ? formatToDecimals(totalValue, 0, '€')
      : formatToDecimals(totalWeight, 1, '%');

  const optimizationModel = portfolio && getPortfolioOptimizationModel(portfolio);
  const contractType = portfolio ? getPlanPortfolioContractType(portfolio) : null;

  const loading = isAllocator() ? optimizingPlan : optimizingCurrent;

  const portfolioIndex = getPortfolioIndex(portfolio.portfolioId);

  return (
    <Container>
      {planState !== 'notLoaded' && (
        <OptimizationModelLabel model={optimizationModel}>
          <div>{translate(`common.portfolioOptimizationModels.${optimizationModel}_portfolio`)}</div>
          <div>{contractType}</div>
        </OptimizationModelLabel>
      )}
      <div>
        <NameContractWeight>
          <Index>{portfolioIndex}</Index>
          <PortfolioName>{portfolioName}</PortfolioName>
          <Weight>{loading ? <Spinner size={20} position="right" /> : formattedWeight}</Weight>
        </NameContractWeight>
      </div>
    </Container>
  );
};

const Container = styled.div`
  flex: auto;
  position: relative;
  margin-top: 1.5rem;
  border-radius: 6px;
  background: linear-gradient(0deg, hsl(0, 0%, 98%), hsl(0, 0%, 99%));
  padding: 0.6rem 2rem 0.5rem 2.25rem;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05), 0 -10px 25px hsl(210deg 40% 40% / 5%);
  z-index: 0;
`;

const OptimizationModelLabel = styled(Flex)<{ model?: OptimizationMannerId }>`
  position: absolute;
  left: -3px;
  top: calc(-2rem + 6px);
  font-size: 14px;
  border-radius: 3px;
  overflow: hidden;
  background: white;
  z-index: 0;
  height: 2rem;

  & > div {
    padding: 0.25rem 0.75rem;

    &:first-child {
      border-radius: 3px;

      ${(props) =>
        props.model === optimizationManners.INCLUDE &&
        css`
          background: ${colors.optimized};
          color: white;
          font-weight: 700;
        `};

      ${(props) =>
        props.model === optimizationManners.CONSIDER &&
        css`
          background: ${colors.considered};
        `};

      ${(props) =>
        props.model === optimizationManners.IGNORE &&
        css`
          background: ${colors.med_gray};
          color: ${colors.dark_gray};
        `};
    }

    &:last-child {
      color: ${colors.black};
      font-weight: 500;
      letter-spacing: 0.5px;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 10%, transparent 40%);
    }
  }
`;

const NameContractWeight = styled(FlexJustifyBetween)`
  font-weight: bold;
  position: relative;
`;

const Index = styled.div`
  position: absolute;
  right: 100%;
  margin-right: 0.5rem;
  opacity: 0.5;
  font-size: ${fontSize.smaller};
  margin-top: 1px;
`;

const PortfolioName = styled(FlexAlignCenter)`
  white-space: normal;
  padding-right: 1rem;
`;

const Weight = styled.div`
  margin-left: 0.5rem;
  white-space: nowrap;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
