import Radio from '@mui/material/Radio';
import { styled } from '@mui/system';

const StyledRadioButton = styled(Radio)({
  '& .MuiSvgIcon-root': {
    fontSize: 20,
  },
  '&.Mui-checked': {
    color: 'black',
  },
}) as typeof Radio;

export default StyledRadioButton;
