import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { List } from 'immutable';
import { compose } from 'redux';
import Nav from 'features/profile/components/Nav';
import {
  uploadImage,
  fetchImages,
  newCustomerContract,
  copyContract,
  getImageforPreview,
  getContractPdf,
  deleteIdentityImage,
} from 'features/profile/profileActions';
import {
  selectIsBusy,
  selectCustomer,
  selectDraft,
  selectContracts,
  selectImages,
  selectHasError,
  selectUploadingImage,
  selectLoadingDrafts,
  selectIsDigiCustomer,
} from 'features/profile/profileSelectors';
import Error from 'features/common/404';
import IdentityOptions from 'constants/identityOptions';
import { setCustomerForm } from 'features/profileEdit/profileEditActions';
import { ProfilePage } from 'features/profile/components/ProfilePage';
import styles from './ProfileView.scss';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { RootState } from 'types/rootState';
import { ImmutableCustomer, ImmutableDraft, ImmutableImage, Customer, Image, BlobInfo } from 'types/profileState';
import { ErrorDialog } from 'features/allocator/common/ErrorDialog';
import { ImmutableContracts } from 'types/ordersState';
import { selectHasPositions } from 'features/allocator/allocatorSelectors';
import { IdentityMethod } from 'types/types';
import { Countries } from 'types/contractsState';

interface Props {
  isBusy: boolean;
  customer: ImmutableCustomer;
  draft: ImmutableDraft;
  contracts: ImmutableContracts;
  images: List<ImmutableImage>;
  hasError: boolean;
  identityMethods: IdentityMethod[];
  countries: Countries;
  params: { id: string };
  uploadingImage: boolean;
  loadingDrafts: boolean;
  location: Location;
  newCustomerContract: () => void;
  getContractPdf: () => void;
  copyContract: (customerId: string, copyContractId: string | undefined) => void;
  setCustomerForm: (customer: Customer) => void;
  uploadImage: (file: File, info: Partial<Image>, id: string) => Promise<void>;
  fetchImages: (customerId: string) => void;
  getImageforPreview: (filepath: string) => Promise<BlobInfo>;
  deleteIdentityImage: (customerId: string, imageId: string) => void;
  hasPositions: boolean;
  loadingPositions: boolean;
  isDigiCustomer: boolean;
}

const ProfileView = ({
  isBusy,
  customer,
  draft,
  contracts,
  images,
  hasError,
  identityMethods,
  countries,
  params,
  uploadingImage,
  loadingDrafts,
  location,
  newCustomerContract,
  getContractPdf,
  copyContract,
  setCustomerForm,
  uploadImage,
  fetchImages,
  getImageforPreview,
  deleteIdentityImage,
  hasPositions,
  loadingPositions,
  isDigiCustomer,
}: Props) => {
  return (
    <section className={styles.profile}>
      <Nav />
      <ErrorDialog />
      <ErrorBoundary>
        {hasError ? (
          <Error />
        ) : (
          <ProfilePage
            isBusy={isBusy}
            customer={customer}
            draft={draft}
            contracts={contracts}
            images={images}
            identityMethods={identityMethods}
            countries={countries}
            params={params}
            uploadingImage={uploadingImage}
            loadingDrafts={loadingDrafts}
            location={location}
            newCustomerContract={newCustomerContract}
            getContractPdf={getContractPdf}
            copyContract={copyContract}
            setCustomerForm={setCustomerForm}
            uploadImage={uploadImage}
            fetchImages={fetchImages}
            getImageforPreview={getImageforPreview}
            deleteIdentityImage={deleteIdentityImage}
            hasPositions={hasPositions}
            loadingPositions={loadingPositions}
            isDigiCustomer={isDigiCustomer}
          />
        )}
      </ErrorBoundary>
    </section>
  );
};

function mapStateToProps(state: RootState) {
  return {
    isBusy: selectIsBusy(state.profile),
    customer: selectCustomer(state),
    draft: selectDraft(state.profile),
    contracts: selectContracts(state.profile),
    images: selectImages(state.profile),
    hasError: selectHasError(state.profile),
    identityMethods: IdentityOptions.methods,
    countries: state.common.countries,
    uploadingImage: selectUploadingImage(state.profile),
    loadingDrafts: selectLoadingDrafts(state.profile),
    hasPositions: selectHasPositions(state),
    loadingPositions: state.portfolio.loadingPositions,
    isDigiCustomer: selectIsDigiCustomer(state.profile),
  };
}

const mapDispatchToProps = {
  uploadImage,
  fetchImages,
  newCustomerContract,
  copyContract,
  getImageforPreview,
  getContractPdf,
  deleteIdentityImage,
  setCustomerForm,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProfileView);
