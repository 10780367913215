import { getLastSearchedUsers } from 'core/lastSearchedUsers';
import { LastUsers } from 'types/searchState';

export const hydrateInitialState = (): LastUsers => {
  // need to be same stucture with combineReducers
  const lastSearched = getLastSearchedUsers();
  return {
    search: {
      lastUsers: {
        searched: lastSearched,
      },
    },
  };
};
