import { getPortfolioGroup } from 'core/portfolios';
import { optimizationManners, allocatorContractTypes } from 'constants/allocator';
import { GROUP_OUTSIDE_FUNDS } from 'constants/portfolios';
import { RootState } from 'types/rootState';
import { ContractTypeId, PortfolioManagerId } from 'types/types';
import { AllocatorPortfolio, InvestmentPlanResponse, InvestmentPlanState } from 'types/investmentPlanState';
import { OptimizePlanResponse, OptimizePlanResponsePortfolio } from 'types/newCustomerOptimization';
import { getContractType } from 'features/allocator/allocatorUtils';
import { mapInstrument } from 'features/allocator/investmentPlan/investmentPlanUtils';
import { getAssetClassId } from 'features/allocator/instruments/instrumentsUtils';
import { InstrumentType, PortfolioInstrument } from 'types/instrumentsState';
import { NO_CONTRACT } from 'constants/contractNames';
import { clearInstrumentWeights } from 'features/allocator/planPortfolios/planPortfolioUtils';

export const createPlanPortfoliosForNewCustomer = (optimizationResult: OptimizePlanResponse): AllocatorPortfolio[] => {
  return optimizationResult.withIlliquid.portfolios.map((p) => ({
    allocatedPortfolioRows: {
      withIlliquids: createPortfolioRows(p, optimizationResult, 'withIlliquid'),
      withoutIlliquids: createPortfolioRows(p, optimizationResult, 'withoutIlliquid'),
    },
    allocatorPortfolioId: p.allocatorPortfolioId,
    portfolioId: p.allocatorPortfolioId,
    allowedSettings: {
      contractType: p.allowedContractTypes?.map((i) => i.contractTypeId),
      portfolioManager: p.allowedManagers?.map((i) => i.managerTypeId),
      optimizationManner: p.allowedManners?.map((i) => i.mannerTypeId),
    },
    optimizedSettings: {
      contractType: p.contractType?.contractTypeId,
      portfolioManager: p.portfolioManager?.managerTypeId,
      optimizationManner: p.optimizationManner?.mannerTypeId,
    },
  }));
};

const createPortfolioRows = (
  portfolio: OptimizePlanResponsePortfolio,
  optimizationResult: OptimizePlanResponse,
  planLength: 'withIlliquid' | 'withoutIlliquid'
): PortfolioInstrument[] => {
  const fullInstrument = optimizationResult.withIlliquid.instrumentWeights
    .filter((i) => portfolio.instruments.includes(i.security))
    .map((i) => ({
      name: i.name,
      liquidity: i.liquidity,
      assetCategory: i.assetClassCategory,
      security: i.security,
      marketValue: i.marketValue,
      portfolioCurrencyMarketValue: i.portfolioCurrencyMarketValue,
      quantity: 0,
      portfolioId: portfolio.allocatorPortfolioId,
      price: i.unitPrice,
      type: i.securityType as InstrumentType,
      assetClasses: i.assetClasses,
      assetClassId: getAssetClassId(i.assetClasses),
      weight: optimizationResult[planLength].instrumentWeights.find((j) => j.security === i.security)?.weight || 0,
    }));
  return fullInstrument;
};

export const convertPositionsToOptimizedPortfolios = (state: RootState) => {
  const currentWeights = state.portfolioManager.weights.currentWeights;
  const plan = state.portfolioManager.investmentPlan;
  const portfolios = currentWeights.portfolioWeights?.map((p) => {
    const { optimizedSettings, allowedSettings } = getOptimizedAndAllowedSettings(state, p.portfolioId, plan);

    return {
      allocatorPortfolioId: p.portfolioId,
      portfolioId: p.portfolioId,
      allocatedPortfolioRows: {
        withIlliquids: p.instruments.map(clearInstrumentWeights),
        withoutIlliquids: p.instruments.map(clearInstrumentWeights),
      },
      optimizedSettings,
      allowedSettings,
    };
  });
  return portfolios;
};

const getOptimizedAndAllowedSettings = (state: RootState, portfolioId: string, plan?: InvestmentPlanState) => {
  const portfolio = state.portfolio.portfolioDetailsById?.[portfolioId];
  const contractType = getContractType(portfolio);

  const optimizedSettings = {
    contractType,
    portfolioManager: 'Unknown' as PortfolioManagerId,
    optimizationManner: getDefaultOptimizationManner(state, portfolioId, plan),
  };
  const allowedSettings = {
    contractType: [contractType],
    portfolioManager: ['Unknown' as PortfolioManagerId],
    optimizationManner: getAllowedOptimizationManners(contractType),
  };
  return {
    optimizedSettings,
    allowedSettings,
  };
};

export const getDefaultOptimizationManner = (state: RootState, portfolioId: string, plan?: InvestmentPlanState) => {
  const portfolio = state.portfolio.portfolioDetailsById?.[portfolioId];
  const contractType = getContractType(portfolio);
  const oldPortfolio = plan?.planPortfolios.find((p) => p.portfolioId === portfolioId);
  const oldOptimizationManner = oldPortfolio?.manualSettings?.optimizationManner;

  if (oldOptimizationManner) {
    return oldOptimizationManner;
  }
  if (!portfolio) {
    return optimizationManners.CONSIDER;
  }
  if (contractType === NO_CONTRACT || getPortfolioGroup(portfolio) === GROUP_OUTSIDE_FUNDS) {
    return optimizationManners.IGNORE;
  }
  if (contractType === allocatorContractTypes.TV || contractType === allocatorContractTypes.TUNTEMATON) {
    return optimizationManners.CONSIDER;
  }
  return optimizationManners.INCLUDE;
};

const getAllowedOptimizationManners = (contractType: ContractTypeId) => {
  if (contractType === NO_CONTRACT) {
    return [optimizationManners.IGNORE];
  }
  return Object.values(optimizationManners);
};

export const createLoadedPlanPortfolios = (investmentPlan: InvestmentPlanResponse): AllocatorPortfolio[] =>
  investmentPlan.allocatedPortfoliosWithoutIlliquid.map((p) => {
    const allocatorPortfolioId = p.allocatorPortfolioId;
    const portfolioWithIlliquid = investmentPlan.allocatedPortfoliosWithIlliquid.find(
      (p) => p.allocatorPortfolioId === allocatorPortfolioId
    );

    return {
      allocatorPortfolioId,
      portfolioId: p.portfolioId,
      manualSettings: {
        contractType: p.contractType.contractTypeId,
        optimizationManner:
          p.contractType.contractTypeId === allocatorContractTypes.TV && p.optimizationManner.mannerTypeId === 'Include'
            ? 'Consider'
            : p.optimizationManner.mannerTypeId,
        portfolioManager: p.portfolioManager.managerTypeId,
      },
      allowedSettings: {
        contractType: [p.contractType.contractTypeId],
        optimizationManner: getAllowedOptimizationManners(p.contractType.contractTypeId),
        portfolioManager: [p.portfolioManager.managerTypeId],
      },
      allocatedPortfolioRows: {
        withoutIlliquids: p.allocatedPortfolioRows
          .map((i) => mapInstrument(i, p))
          .map((i) => ({
            ...i,
            portfolioId: p.portfolioId,
          })),
        withIlliquids:
          portfolioWithIlliquid?.allocatedPortfolioRows
            ?.map((i) => mapInstrument(i, p))
            ?.map((i) => ({
              ...i,
              portfolioId: p.portfolioId,
            })) || [],
      },
      optimizedSettings: {
        contractType: p.contractType.contractTypeId,
        optimizationManner: p.optimizationManner.mannerTypeId || 'Unknown',
        portfolioManager: p.portfolioManager.managerTypeId,
      },
    };
  });
