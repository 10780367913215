export const customerTypes = [
  { value: 'person', icon: 'icon-person', translateKey: 'customer.person' },
  { value: 'institution', icon: 'icon-company', translateKey: 'customer.institution' },
];

export const customerGroups = [
  { value: 'non_professional', icon: 'icon-ei-ammattimainen', translateKey: 'customer.non_professional' },
  { value: 'professional', icon: 'icon-ammattisijoittaja', translateKey: 'customer.professional' },
  { value: 'eligibleCounterparty', icon: 'icon-hyvaksytty-vastapuoli', translateKey: 'customer.eligibleCounterparty' },
];

export const wizardSteps = [
  { order: 1, text: 'customer.customerType', link: '/new/type' },
  { order: 2, text: 'customer.customerCategory', link: '/new/categorisation' },
  { order: 3, text: 'customer.customerDetails', link: '/new/identity' },
];

export const prospectErrors = {
  isDuplicate: {
    fallbackState: '/',
    titleKey: 'wizard.errors.isDuplicate.title',
    bodyKey: 'wizard.errors.isDuplicate.body',
  },
  noCustomerType: {
    fallbackState: '/new/type',
    titleKey: 'wizard.errors.noCustomerType.title',
    bodyKey: 'wizard.errors.noCustomerType.body',
  },
  unexpected: {
    fallbackState: '/',
    titleKey: 'wizard.errors.unexpected.title',
    bodyKey: 'wizard.errors.unexpected.body',
  },
};
