import React from 'react';
import { List } from 'immutable';
import translate from 'counterpart';
import _ from 'lodash';
import HardwareKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import HardwareKeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import ContractGroup from './ContractGroup';
import styles from './Contracts.scss';
import {
  SENT_FOR_SIGNING,
  SENT_FOR_MANUAL_SIGNING,
  SENT_FOR_BANKER_APPROVAL,
  SENT_FOR_INK_SIGNING,
} from 'constants/contractStates';
import { BASIC_INFO, WEALTH_MANAGEMENT } from 'constants/contractGroups';
import { ImmutableContract, ImmutableContracts } from 'types/ordersState';

interface Props {
  contracts: ImmutableContracts;
  openFile: () => void;
  copyContract: (contractId: string) => void;
}

interface State {
  showUnsigned: boolean;
  showAll: boolean;
}

export default class Contracts extends React.Component<Props, State> {
  static defaultProps = {
    contracts: List(),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      showUnsigned: false,
      showAll: false,
    };
  }

  getContractGroupType = (contract: ImmutableContract) => {
    let group = 'others';
    const type = contract.get('contractType');
    const state = contract.get('state');
    if (
      _.includes([SENT_FOR_SIGNING, SENT_FOR_MANUAL_SIGNING, SENT_FOR_BANKER_APPROVAL, SENT_FOR_INK_SIGNING], state)
    ) {
      group = 'unsigned';
    } else if (_.includes(BASIC_INFO, type)) {
      group = 'basicInfo';
    } else if (_.includes(WEALTH_MANAGEMENT, type)) {
      group = 'wealthManagement';
    }
    return group;
  };

  toggleUnsigned = () => {
    this.setState({
      ...this.state,
      showUnsigned: !this.state.showUnsigned,
    });
  };

  showAll = () => {
    this.setState({
      ...this.state,
      showUnsigned: true,
      showAll: true,
    });
  };

  showNew = () => {
    this.setState({
      ...this.state,
      showUnsigned: false,
      showAll: false,
    });
  };

  createContractGroups = () => {
    const { contracts, ...rest } = this.props;
    const { showAll, showUnsigned } = this.state;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const groups = contracts.groupBy(this.getContractGroupType);
    let unsignedContent = '';

    if (groups && groups.get('unsigned') && groups.get('unsigned').size > 0) {
      const size = groups.get('unsigned').size;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      unsignedContent = (
        <div key="unsignedContent" data-testkey="unsigned-list">
          <div className={styles.unsignedLink} onClick={this.toggleUnsigned}>
            {showUnsigned ? (
              <HardwareKeyboardArrowDown
                sx={{
                  color: '#00eb64',
                }}
              />
            ) : (
              <HardwareKeyboardArrowRight
                sx={{
                  color: '#00eb64',
                }}
              />
            )}{' '}
            <span className={styles.unsignedLinkText}>
              {size} <span>{translate(`customer.waitingForSigning`)}</span>
            </span>
          </div>
          {this.state.showUnsigned ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <ContractGroup showAll={true} contracts={groups.get('unsigned')} allowCopy={true} {...rest} />
          ) : undefined}
        </div>
      );
    }

    const contractGroups = ['basicInfo', 'wealthManagement', 'others'].map((groupId) => {
      let groupsContent = '';
      const group = groups.get(groupId);
      if (group && group.size > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        groupsContent = (
          <ContractGroup
            key={groupId}
            showAll={showAll}
            title={groupId}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            contracts={group}
            allowCopy={false}
            {...rest}
          />
        );
      }
      return groupsContent;
    });

    return [unsignedContent, contractGroups];
  };

  render() {
    const { contracts } = this.props;
    const { showAll } = this.state;
    let content;

    if (contracts.size > 0) {
      content = this.createContractGroups();
    } else {
      content = <div className="row align-center">{translate(`contract.noContracts`)}</div>;
    }

    const newContracts = (
      <span className={showAll ? styles.unselectedFilter : styles.selectedFilter} onClick={this.showNew}>
        {translate(`customer.newest`)}
      </span>
    );

    const allContracts = (
      <span className={showAll ? styles.selectedFilter : styles.unselectedFilter} onClick={this.showAll}>
        {translate(`customer.all`)}
      </span>
    );

    return (
      <section className={styles.contracts}>
        <div className={styles.headerRow}>
          <header>{translate(`contract.contracts`)}</header>
          <div className={styles.filterLinks}>
            {newContracts} <span className={styles.separator}>|</span> {allContracts}
          </div>
        </div>
        {content}
      </section>
    );
  }
}
