import React from 'react';
import styled, { css } from 'styled-components';
import Collapsible from 'react-collapsible';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { StyledPercentageContainer } from './ConstraintSlider';
import { Card } from 'features/allocator/common/Card';
import { FlexAlignCenter } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { Constraint } from 'types/constraintsState';
import { ConstraintType } from 'types/types';
import { OptimizationConstraintRow } from 'features/allocator/constraints/components/OptimizationConstraintRow';
import { CustomConstraintRow } from 'features/allocator/constraints/components/CustomConstraintRow';
import { selectHasPositions } from 'features/allocator/allocatorSelectors';
import { Button } from 'features/allocator/common/Button';

interface Props {
  constraints: Constraint[];
  onUpdate: (updatedConstraint: Constraint) => boolean;
  constraintType: ConstraintType;
  openCustomConstraintEditor: (constraintId?: string) => void;
}
export const ConstraintList = ({ constraints, onUpdate, constraintType, openCustomConstraintEditor }: Props) => {
  const hasPositions = useSelector(selectHasPositions);

  const customConstraints = constraints.filter((constraint) => constraint.isCustom);
  const assetCategoryConstraints = constraints.filter((constraint) => !constraint.isCustom);

  const optimizationAssetConstraintsModified =
    constraintType === 'optimization'
      ? assetCategoryConstraints.filter((i) => i.items.some((j) => j.min !== 0 || j.max !== 1)).map((i) => i.id)
      : [];

  return (
    <ConstraintListCard constraintType={constraintType}>
      <h3>{translate('allocator.constraintsView.absoluteConstraints')}</h3>
      <ColumnHeader>
        <RowLabel />
        <StyledPercentageContainer>
          {hasPositions
            ? translate('allocator.constraintsView.currentWeight')
            : translate('allocator.constraintsView.optimizedWeight')}
        </StyledPercentageContainer>
        <StyledPercentageContainer>{translate('allocator.constraintsView.allowedWeight')}</StyledPercentageContainer>
      </ColumnHeader>

      {assetCategoryConstraints.map((constraint) => {
        return (
          <OptimizationConstraintRow
            key={constraint.id}
            constraint={constraint}
            constraintType={constraintType}
            optimizationAssetConstraintsModified={optimizationAssetConstraintsModified}
            onUpdate={onUpdate}
          />
        );
      })}

      <CustomConstraintsContainer>
        <h3>{translate('allocator.constraintsView.relativeConstraints')}</h3>
        <Collapsible
          key={'customConstraints'}
          contentOuterClassName={'assetCategoryList'}
          trigger={<div />}
          triggerTagName={'div'}
          triggerClassName={'rowLabel'}
          triggerOpenedClassName={'rowLabel'}
          transitionTime={200}
          open={true}
          triggerDisabled
        >
          {customConstraints.map((constraint) => {
            return (
              <CustomConstraintRow
                key={constraint.id}
                constraint={constraint}
                onUpdate={onUpdate}
                constraintType={constraintType}
                openCustomConstraintEditor={openCustomConstraintEditor}
              />
            );
          })}
        </Collapsible>
      </CustomConstraintsContainer>

      {constraintType === 'optimization' && (
        <Button
          onClick={() => openCustomConstraintEditor()}
          data-testkey="add-custom-constraint"
          secondary
          label={`+ ${translate('allocator.constraintsView.newConstraintGroup')}`}
        />
      )}
    </ConstraintListCard>
  );
};

const labelWidth = 300;

interface StyledCardProps {
  constraintType: ConstraintType;
}

const ConstraintListCard = styled(Card)<StyledCardProps>`
  margin: 1rem 0 3rem 0;

  & > .Collapsible {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > .rowLabel {
      display: inline-flex;
      align-items: center;
      width: ${labelWidth}px;
      cursor: ${(props) => (props.constraintType === 'optimization' ? 'pointer' : 'default')};
      ${(props) =>
        props.constraintType === 'optimization' &&
        css`
          &:before {
            content: '+';
            margin-right: 0.75rem;
            font-weight: 900;
            font-size: 24px;
            margin-bottom: 3px;
            color: ${colors.primary};
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.is-open:before {
            content: '–';
          }
        `};
    }

    & > .assetCategoryList {
      width: 100%;
    }

    .Collapsible__contentInner {
      margin-bottom: 2rem;
    }
  }
`;

const ColumnHeader = styled(FlexAlignCenter)`
  color: ${colors.dark_gray};
`;

const RowLabel = styled(FlexAlignCenter)`
  width: ${labelWidth}px;
`;

const CustomConstraintsContainer = styled.div`
  margin-top: 2rem;
`;
