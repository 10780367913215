import React, { ReactElement } from 'react';
import styles from './Warning.scss';

interface Props {
  children: ReactElement | string;
}

const Warning = ({ children }: Props) => <div className={styles.warning}>{children}</div>;

export default Warning;
