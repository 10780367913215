import { Map, fromJS } from 'immutable';
import _ from 'lodash';
import { NOT_APPLICABLE } from 'constants/common';
import { getPortfolioGroup } from 'core/portfolios';
import { PortfolioState } from 'types/portfolioState';
import {
  PortfolioAction,
  PORTFOLIO_REQUEST,
  PORTFOLIO_SUCCESS,
  PORTFOLIO_FAILURE,
  POSITIONS_REQUEST,
  POSITIONS_SUCCESS,
  POSITIONS_FAILURE,
  INITIALIZE_PORTFOLIOS_AND_POSITIONS,
  SET_LOADING_PORTFOLIOS,
  SET_LOADING_POSITIONS,
} from 'features/portfolio/portfolioActions';
import { PortfolioRequestResponse, PositionRequestResponse } from 'features/portfolio/portfolio.types';

const initialState: PortfolioState = {
  loadingPortfolios: false,
  loadingPositions: false,
  positions: [],
  portfolios: Map(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  allPositionsInfo: {},
  portfolioDetailsById: {},
  hasError: false,
  error: undefined,
  searching: false,
  portfolioIds: [],
};

const porftfolioRequest = (state: PortfolioState) => ({
  ...state,
  loadingPortfolios: true,
  portfolios: state.portfolios.clear(),
  hasError: false,
  portfolioDetailsById: {},
  error: undefined,
});

const portfolioDetailsById = (portfolios: PortfolioRequestResponse[]) => {
  const details = portfolios.map((p) => {
    const defaultAccount = p.defaultAccount;
    const account = p.accounts.find((x) => x.accountId === defaultAccount.id);
    const managementFee = _.get(p, ['managementFee', 'feeDescription'], '').replace('Varainhoitopalkkio ', '');
    const tradingCommission = _.get(p, ['tradingCommission', 'feeDescription'], '');
    return {
      portfolioId: p.id,
      iban: defaultAccount && defaultAccount.iban,
      paymentReference: _.get(defaultAccount, ['paymentReference'], ''),
      accountType: _.get(defaultAccount, ['accountType'], ''),
      state: p.statusName,
      withdrawalBalance: _.get(account, ['balance', 'withdrawalBalance', 'value'], 0),
      withdrawalCurrency: _.get(account, ['balance', 'withdrawalBalance', 'currency'], NOT_APPLICABLE),
      defaultAccount,
      externalId: p.externalId,
      contractName: p.contractName,
      strategyName: p.strategyName,
      isInsurancePortfolio: p.isInsurancePortfolio,
      portfolioName: p.nameForCustomer,
      managementFee,
      tradingCommission,
      custodianName: p.custodianName,
      portfolioClasses: p.portfolioClasses,
      contractType: p.contractType,
    };
  });

  return _.keyBy(details, 'portfolioId');
};

const getPortfolioIds = (portfolios: PortfolioRequestResponse[]) => portfolios.map((p) => p.id);

const porftfolioSuccess = (state: PortfolioState, result: PortfolioRequestResponse[]) => {
  const filtered = result.filter((x) => {
    const status = x.contractName ? x.contractName.toLowerCase() : '';
    return x.contractName && status !== 'siirretty';
  });
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const grouped = fromJS(_.groupBy(filtered, (x) => getPortfolioGroup(x)));
  const detailsById = portfolioDetailsById(filtered);
  const portfolioIds = getPortfolioIds(filtered);
  return {
    ...state,
    loadingPortfolios: false,
    portfolios: grouped,
    portfolioDetailsById: detailsById,
    portfolioIds,
  };
};

const porftfolioFailure = (state: PortfolioState) => ({
  ...state,
  loadingPortfolios: false,
  portfolios: state.portfolios.clear(),
  hasError: true,
  error: 'Portfolioiden haku epäonnistui.',
});

const positionsRequest = (state: PortfolioState) => ({
  ...state,
  loadingPositions: true,
  positions: [],
  hasError: false,
  allPositionsInfo: {},
  error: undefined,
});

const positionsSuccess = (state: PortfolioState, result: PositionRequestResponse) => {
  const allPositionsInfo = _.pick(result, [
    'valueDate',
    'marketValue',
    'marketValueChangePercent',
    'marketValueChange',
    'purchaseValue',
  ]);
  const positions = result.positions;
  return {
    ...state,
    positions,
    allPositionsInfo,
    loadingPositions: false,
  };
};

const positionsError = (state: PortfolioState) => ({
  ...state,
  loadingPositions: false,
  positions: [],
  hasError: true,
  error: 'Omistusten haku epäonnistui.',
});

export const portfolioReducer = (state = initialState, action: PortfolioAction) => {
  switch (action.type) {
    case PORTFOLIO_REQUEST:
      return porftfolioRequest(state);
    case PORTFOLIO_SUCCESS:
      return porftfolioSuccess(state, action.result);
    case PORTFOLIO_FAILURE:
      return porftfolioFailure(state);
    case POSITIONS_REQUEST:
      return positionsRequest(state);
    case POSITIONS_SUCCESS:
      return positionsSuccess(state, action.result);
    case POSITIONS_FAILURE:
      return positionsError(state);
    case INITIALIZE_PORTFOLIOS_AND_POSITIONS:
      return initialState;
    case SET_LOADING_PORTFOLIOS:
      return {
        ...state,
        loadingPortfolios: action.loading,
      };
    case SET_LOADING_POSITIONS:
      return {
        ...state,
        loadingPositions: action.loading,
      };
    default:
      return state;
  }
};
