import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { FlexJustifyBetween, styles } from 'features/common/StyledComponents';
import { AssetCategoryName } from 'types/types';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { selectRisk } from 'features/allocator/allocatorSelectors';
import { DistributionPie } from 'features/allocator/riskSelection/components/DistributionPie';
import { Grid } from 'features/allocator/common/Grid';
import { ASSET_CATEGORY_COLORS } from 'styles/colors';
import { Weights } from 'types/weightsState';
import { getWithdrawalBalance } from 'features/portfolio/portfolioUtils';

interface Props {
  totalValue: number;
  weights: Weights;
}

export const CustomerSummaryPie = ({ weights, totalValue }: Props) => {
  const selectedRiskLevel = useSelector(selectRisk);
  const assetCategoryWeights = weights.assetCategoryWeights;
  const portfolioReturn = weights.returnStatistics.portfolioReturn;
  const portfolioVolatility = weights.returnStatistics.portfolioVolatility;
  const withdrawalBalance = useSelector(getWithdrawalBalance);

  return (
    <PieCard>
      <h2>{translate('allocator.summaryView.portfolioSummary')}</h2>
      <Container>
        <PieContainer>
          <DistributionPie weights={assetCategoryWeights} sections="assetClassGroups" />
        </PieContainer>
        <div>
          {assetCategoryWeights.map(
            (assetCategory) =>
              assetCategory.weight > 0 && (
                <AssetCategoryRow assetCategory={assetCategory.name} key={assetCategory.name}>
                  <div>{assetCategory.name}</div>
                  <div>{formatToDecimals(assetCategory.weight, 1, '%')}</div>
                </AssetCategoryRow>
              )
          )}
        </div>
        <Figures>
          <div>{translate('allocator.summaryView.portfolioSize')}</div>
          <div>{formatToDecimals(Number(totalValue), 0, '€')}</div>
          <div>{translate('allocator.summaryView.withdrawalBalance')}</div>
          <div>{formatToDecimals(Number(withdrawalBalance), 0, '€')}</div>
          <div>{translate('allocator.summaryView.riskNumber')}</div>
          <div>{selectedRiskLevel}</div>
          <div>{translate('allocator.summaryView.expectedReturn')}</div>
          <div>
            {formatToDecimals(portfolioReturn, 1, '%')}
            <br />
            {formatToDecimals(totalValue * portfolioReturn, 0, '€')}
          </div>
          <div>{translate('allocator.summaryView.expectedVolatility')}</div>
          <div>{formatToDecimals(portfolioVolatility, 1, '%')}</div>
        </Figures>
      </Container>
    </PieCard>
  );
};

const Container = styled.div`
  grid-gap: 6rem;
  justify-content: start;
`;

const Figures = styled(Grid)`
  grid-template-columns: auto auto;
  grid-gap: 0.5rem 2rem;

  > div:nth-child(even) {
    text-align: right;
  }
`;

const PieCard = styled.div`
  ${styles.card}
  box-shadow: none;
  grid-area: pie;
  font-size: 16px;
  font-weight: bold;
  white-space: nowrap;
`;

const PieContainer = styled.div`
  padding: 2rem;
`;

interface AssetCategoryRowProps {
  assetCategory: AssetCategoryName;
}

const AssetCategoryRow = styled(FlexJustifyBetween)<AssetCategoryRowProps>`
  margin-bottom: 0.5rem;
  text-align: right;

  & > div:first-child {
    padding-right: 2rem;
  }

  color: ${(props) => ASSET_CATEGORY_COLORS[props.assetCategory]};
`;
