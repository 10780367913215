import { Map, List, Set, fromJS } from 'immutable';
import moment from 'moment';
import _ from 'lodash';
import {
  MERGE_NOTE,
  EDIT_NOTE,
  MEETING_DATA_REQUEST,
  MEETING_DATA_SUCCESS,
  MEETING_DATA_FAILURE,
  SAVE_MEETING_DATA_REQUEST,
  SAVE_MEETING_DATA_SUCCESS,
  SAVE_MEETING_DATA_FAILURE,
} from './meetingNotesConstants';
import { PHONE_CALL } from 'constants/meetingType';
import { getAppointmentSchema } from 'core';
import { getMeetingNoteErrors } from 'core/validations';
import { MeetingState } from 'types/meetingState';
import { ImmutableNote, Note } from 'types/ordersState';
import { Customer } from 'types/profileState';

const initialState: MeetingState = {
  note: Map(),
  customer: Map(),
  isLoading: false,
  errors: List(),
  touched: Set(),
};

const getErrors = (state: string, note: ImmutableNote) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  fromJS(getMeetingNoteErrors(note.toJS(), getAppointmentSchema(note.get('activity'), state)));

const editNote = (state: MeetingState, action: { key: string; value: string }) => {
  const note = state.note.set(action.key, action.value);
  return {
    ...state,
    note,
    touched: state.touched.union([action.key]),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    errors: getErrors(state.customer.get('state'), note),
  };
};

const mergeNote = (state: MeetingState, action: { value: Partial<Note> }) => {
  const note = state.note.merge(action.value);
  const add = _.findKey(action.value, (v) => !!v);
  return {
    ...state,
    note,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    errors: getErrors(state.customer.get('state'), note),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    touched: state.touched.union([add]),
  };
};

const initNote = (state: MeetingState, action: { activity: string; data: Customer }) => {
  const scheduledStart = moment().format('YYYY-MM-DDTHH:mm');
  const scheduledEnd = moment(scheduledStart, 'YYYY-MM-DDTHH:mm').add(1, 'h').format('YYYY-MM-DDTHH:mm');
  const note = Map({
    activity: action.activity || PHONE_CALL,
    scheduledStart,
    scheduledEnd,
    includesReporting: false,
    present: `${_.get(action.data, ['contacts', '0', 'firstName'], '')} ${_.get(
      action.data,
      ['contacts', '0', 'lastName'],
      ''
    )}`,
  });
  return {
    ...state,
    note,
    customer: Map({
      customerId: action.data.id,
      name: action.data.header,
      state: action.data.state,
    }),
    isLoading: false,
    errors: getErrors(action.data.state, note),
    touched: Set(),
  };
};

const dataRequest = (state: MeetingState) => ({
  ...state,
  note: Map(),
  customer: Map(),
  isLoading: true,
  errors: List(),
  touched: Set(),
});

const meetingDataFailure = (state: MeetingState) => ({
  ...state,
  note: Map(),
  customer: Map(),
  isLoading: false,
  errors: List(),
  touched: Set(),
});

const saveMeetingRequest = (state: MeetingState) => ({
  ...state,
  isLoading: true,
});

const saveMeetingSuccess = (state: MeetingState) => ({
  ...state,
  note: Map(),
  customer: Map(),
  isLoading: false,
  errors: List(),
  touched: Set(),
});

const saveMeetingFailure = (state: MeetingState) => ({
  ...state,
  isLoading: false,
});

export function meetingNotesReducer(state = initialState, action: { type: string }) {
  switch (action.type) {
    case MERGE_NOTE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return mergeNote(state, action);
    case EDIT_NOTE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return editNote(state, action);
    case MEETING_DATA_REQUEST:
      return dataRequest(state);
    case MEETING_DATA_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return initNote(state, action);
    case MEETING_DATA_FAILURE:
      return meetingDataFailure(state);
    case SAVE_MEETING_DATA_REQUEST:
      return saveMeetingRequest(state);
    case SAVE_MEETING_DATA_SUCCESS:
      return saveMeetingSuccess(state);
    case SAVE_MEETING_DATA_FAILURE:
      return saveMeetingFailure(state);
    default:
      return state;
  }
}
