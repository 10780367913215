export default {
  accountNumber: 'Asiakkaan oma ulkoinen tili',
  all: 'Kaikki',
  authentication: 'Todennus',
  basicContractExpires: 'Soveltuvuusarvioinnin vanhentumisaika',
  basicContractHasExpired: 'Soveltuvuusarviointi vanhentunut',
  basicContractNotSigned: 'Soveltuvuusarviointia ei ole tehty',
  nextKycUpdate: 'Asiakkaan tuntemistietojen vanhentumisaika',
  nextKycUpdateMissing: 'Asiakkaan tuntemistietoja ei ole tehty',
  nextKycUpdateHasExpired: 'Asiakkaan tuntemistiedot vanhentuneet',
  basicInfo: 'Perustiedot',
  businessIdMissing: 'Y-tunnus puuttuu',
  cancel: 'Peruuta',
  changePhoto: 'VAIHDA KUVA',
  company: 'Yritys',
  confirmNewContractContent:
    'Asiakkaalla on tallennettuna keskeneräisiä sopimuksia, jotka poistetaan, jos luot uuden sopimuksen. Haluatko luoda uuden sopimuksen? ',
  wealthManagement: 'Omaisuudenhoito',
  continueToDrafts: 'Jatka keskeneräisiä',
  copy: 'Kopioi tieto',
  copyAll: 'Kopioi kaikki',
  importToSystem: 'Tallenna järjestelmään',
  eligibleCounterparty: 'Hyväksytty vastapuoli',
  customerCategory: 'Asiakkuusluokittelu',
  customerRelationship: 'Asiakassuhde',
  customerType: 'Asiakkuustyyppi',
  customerDetails: 'Asiakkuustiedot',
  digiCustomer: 'Digiasiakas',
  digiCustomerConfirmationContent:
    'Tämä asiakas on siirretty tai siirtymässä digiin, eikä asiakkaalle lähtökohtaisesti saa tehdä uusia sopimuksia. Ota yhteyttä asiakaspalveluun ennen kuin teet uusia sopimuksia asiakaspalvelu.varainhoito@aktia.fi.',
  digiCustomerConfirmationTitle: 'Huom! Digiasiakas',
  documents: 'Asiakirjat',
  fetchData: 'Hae tiedot',
  fetchRegistryData: 'Hae rekisteritiedot',
  hasDrafts: 'Asiakkaalla on keskeneräisiä sopimuksia',
  institution: 'Yhteisö',
  investmentAdvice: 'Sijoitusneuvonta',
  isMissing: 'puuttuu',
  loading: 'Ladataan kuva...',
  newest: 'Uusimmat',
  non_professional: 'Ei-ammattimainen',
  person: 'Henkilö',
  personAuthentication: 'Henkilöllisyyden todentaminen',
  phoneCall: 'Puhelu',
  product: 'Tuoteasiakas',
  professional: 'Ammattisijoittaja',
  selectType: 'Valitse asiakkuustyyppi',
  ssn: 'Henkilötunnus',
  uploadPhoto: 'OTA / LATAA KUVA',
  uploadPhotoDesc: ' henkilötodistuksesta',
  clearImage: 'Tyhjennä',
  openPreview: 'Avaa esikatselu',
  businessId: 'Y-tunnus',
  customerId: 'Asiakasnumero',
  passport: 'Passi',
  prhData: 'PRH:sta haetut tiedot',
  driversLicense: 'Ajokortti',
  idCard: 'Henkilökortti',
  SocialInsuranceCardWithPhoto: 'Kuvallinen KELA-kortti',
  Other: 'Muu',
  others: 'Muut',
  uploadDescription: 'Lisää tähän tunnistusasiakirjasta henkilön nimi muodossa Sukunimi, Etunimi',
  primaryContact: 'Ensisijainen yhteyshenkilö',
  primaryContactPhone: 'Ensisijaisen yhteyshenkilön puhelinnumero',
  primaryContactEmail: 'Ensisijaisen yhteyshenkilön sähköposti',
  registryDataNotFound: 'Väestörekisteristä ei löytynyt tietoja annetulla henkilötunnuksella',
  registryOfficeData: 'Väestörekisteristä haetut tiedot',
  remove: 'Poista',
  removeAndCreate: 'Poista ja luo uusi',
  removeId: 'Haluatko poistaa tunnistamisasiakirjan?',
  save: 'Tallenna',
  segment: 'Segmentti',
  ssnMissing: 'Henkilötunnus puuttuu',
  statusesDoc: 'Yhtiöjärjestys',
  systemData: 'Järjestelmän tiedot',
  missingImage: 'Asiakkaalta puuttuu henkilöllisyystodistuksen kuva',
  tradeRegistry: 'Rekisteriote',
  unsigned: 'Odottaa allekirjoitusta',
  update: 'Päivitä',
  updated: 'Päivitetty',
  waitingForSigning: 'odottaa allekirjoitusta',
  riskTolerance: 'Asiakkaan riskiprofiili',
  leiNumber: 'LEI-tunnus',
  leiNumberExpired: '(vanhentunut)',
  validUntil: 'Voimassa',
  expired: 'Vanhentunut',
  stakeholders: 'Osalliset',
  boardMembers: 'Yrityksen johto',
  representatives: 'Edustajat',
  beneficialOwners: 'Tosiasialliset edunsaajat',
  registryData: 'Rekisteristä haetut tiedot',
  crmData: 'Järjestelmän tiedot',
  notScreened: 'Tee rekisterihaku',
  notScreenedContent:
    'Asiakasyrityksen hallitusta ja tosiasiallisia edunsaajia ei ole tarkastettu kokoonpanon, pakotteiden ja poliittisen vaikutusvallan osalta. Hae osalliset rekisteristä.',
  continueWithoutScreening: 'Ohita',
  continueToScreening: 'Jatka rekisterihakuun',
  notScreenedPerson: 'Tee rekisterihaku',
  notScreenedPersonContent:
    'Asiakkaalle ei ole tehty rekisteritarkistuksia pakotteiden ja poliittisen vaikutusvallan osalta.',
  screenPerson: 'Tee rekisterihaku',
  customerTypeLabel: 'Asiakastyyppi',
  continue: 'Jatka',
  stillContinue: 'Jatka kuitenkin',
  draftContracts: 'Keskeneräiset sopimukset',
  noDraftContracts: 'Ei keskeneräisiä',
  contactCustomer: 'Asiakkaan kohtaaminen',
  appointment: 'Tapaaminen',
  allocator: 'Allokaatiotyökalu',
  portfolioManager: 'Salkunhallinta',
  portfolios: 'Salkut',
  newContract: 'Uusi sopimus',
  newCustomerSteps: {
    title: 'Asiakkuuden luominen',
    basicInfo: 'Perustietosopimus',
    registryInfo: 'Rekisteritiedot',
    isNotFilled: 'Ei täytetty',
    fillBasicInfo: 'Täytä perustietosopimus',
    fillRegistryInfo: 'Täytä rekisteritiedot',
    checkRegistryInfo: 'Tarkista rekisteritiedot',
    isDraft: 'Kesken',
    inConflift: 'Ristiriidassa',
    investmentPlan: 'Sijoitussuunnitelma',
    isDone: 'Tehty',
    notReady: 'Kesken',
    isNotDone: 'Ei tehty',
    errorLoading: 'Virhe tietoja ladatessa',
    resume: 'Jatka',
    isLocked: 'Suunnitelma on lukittu, koska salkut on jo luotu. Lukitus avautuu kauppojen toteutuksen jälkeen.',
    createPlanFirst: 'Tee ensin sijoitussuunnitelma',
    createBasicInfoContractFirst: 'Täytä ensin perustietosopimus',
    createRequiredContractsFirst: 'Täytä ensin tarvittavat sopimukset',
    createRegistryInfoFirst: 'Täytä ensin rekisteritiedot',
    createEmptyPortfoliosFirst: 'Luo ensin salkut',
    editPlan: 'Muokkaa suunnitelmaa',
    watchPlan: 'Katsele suunnitelmaa',
    createPlan: 'Luo suunnitelma',
    cashTransfer: 'Varojen siirto',
    cashAmount: 'Käteisvarat',
    totalPossession: 'Kokonaisvarallisuus',
    riskConflict: 'Sopimuksella ja suunnitelmalla on valittuna eri riskiluvut:',
    risksMustBeEqual: 'Riskilukujen täytyy olla samat voidaksesi jatkaa.',
    changeRiskInPlan: 'Vaihda suunnitelman riskilukua',
    planRisk: 'Suunnitelman riskiluku',
    contractRisk: 'Sopimuksen riskiluku',
    contracts: 'Sopimukset',
    requiredContracts: 'Tarvittavat sopimukset',
    recommendedContracts: 'Suositellut sopimukset',
    otherContractDrafts: 'Muut keskeneräiset sopimukset',
    done: 'Valmis',
    fillContracts: 'Täytä sopimukset',
    alsoOtherDrafts: 'Myös %(count)s muuta keskeneräistä sopimusta',
    portfolios: 'Luo salkut',
    checkAndSendPortolios: 'Tarkista salkut ja lähetä toteutettaviksi',
    portfoliosCreated: 'Salkut luotu',
    portfoliosError: 'Virhe salkkuja luodessa',
    portfolioWeight: 'Salkun paino',
    close: 'Sulje',
    checkPortfolios: 'Tarkista salkut',
    trade: 'Tee kaupat',
    createPortfolios: 'Luo salkut',
    portfolioId: 'Salkkutunnus',
    portfolioType: 'Sopimustyyppi',
    settlementAccountId: 'Ulkoinen pankkitili',
    fundAccountId: 'Tilin tunnus',
    priceRowLinkId: 'Palkkiotiedon tunniste',
    priceRowId: 'Hintarivin tunnus',
    contactPersonId: 'Yhteyshenkilön tunnus',
    portfolioFundAccountLinkId: 'Tili-salkku-liitos',
    allocationDefinitionIds: 'Salkkuallokaatioiden tunnukset',
    missingValue: 'Arvo puuttuu',
    portfolio: 'Salkku',
    taxationCountryNotFinland: 'Asiakkaan verotusmaa on muu kuin Suomi.',
    contactInvestmentSpecialist: 'Pyydä sijoitusasiantuntijaa luomaan salkut manuaalisesti.',
  },
  identificationDocumentTypes: {
    finnishPassport: 'Suomen passi',
    finnishDriverLicence: 'Suomalainen ajokortti (1.10.1990 jälkeen myönnetty)',
    finnishIdentityCard: 'Suomalainen henkilökortti',
    foreignPassport: 'Ulkomainen passi',
    foreignIdentityCard: 'Ulkomainen henkiöllisyystodistus',
  },
  previousRiskProfile: 'Aiempi riskiprofiili on',
  riskProfileNotFound: 'Riskiprofiilia ei löytynyt',
};
