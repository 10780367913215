import React from 'react';
import tv4, { ValidationError } from 'tv4';
import Immutable from 'immutable';
import counterpart from 'counterpart';
import { Store } from 'redux';
import { Element as ScrollElement } from 'react-scroll';
import find from 'lodash/find';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import getValue from 'lodash/get';
import setValue from 'lodash/set';
import accounting from 'accounting';
import { doesQuestionPassConditionTestToRender } from './functions';
import { CUSTOMER_TYPE } from 'constants/newCustomer';
import { COMPANY, INSTITUTION } from 'constants/customerTypes';
import { INSTITUUTIOVH } from 'constants/customerSegments';
import appointmentProspect from 'schemas/appointmentProspect';
import appointmentAccount from 'schemas/appointmentAccount';
import callAccount from 'schemas/callAccount';
import callProspect from 'schemas/callProspect';
import { PHONE_CALL, APPOINTMENT } from 'constants/meetingType';
import { ACCOUNT, PROSPECT } from 'constants/customerStates';
import { logError } from 'actions/index';
import { config } from 'config';
import { OrderDialogSchema, UpdateCustomerOptions, ValidationErrorValue } from 'types/orderDialogState';
import { FieldType, Template, Contract, Item, OrderLineType, MinutesOfInvestmentServiceType } from 'types/ordersState';
import { ProspectsState } from 'types/prospectsState';
import { ImmutableFormErrors, Schema } from 'types/contractsState';
import { ImmutableContent } from 'types/profileState';

const errorCodes = {
  12: 'oneOf',
  101: 'numberMinimum',
  102: 'exclusiveMinimum',
  103: 'numberMaximum',
  105: 'numberNotANumber',
  200: 'minLength',
  201: 'maxLength',
  202: 'pattern',
  302: 'required',
  401: 'dateMinimum',
  402: 'dateMaximum',
};

function getErrorKey(error: ValidationError) {
  const datapath = error.dataPath?.replace('/', '').replace(/\//g, '.');
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const ownKey = error.params.key || '';
  if (ownKey && datapath) {
    return `${datapath}.${ownKey}`;
  } else if (ownKey) {
    return ownKey;
  }
  return datapath;
}

function getRuleName(error: ValidationError | { code: number | string }) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const rule = errorCodes[error.code];
  return rule || 'general';
}

export function getErrorMessage(error: ValidationError | { code: number | string }) {
  const key = `validations.${getRuleName(error)}`;
  return counterpart.translate(key, { fallback: 'validations.general' });
}

export const getErrorObject = (error: ValidationError): ValidationErrorValue => ({
  key: getErrorKey(error),
  message: getErrorMessage(error),
});

// JS values, JS schema
export function validateFormValues(
  values: OrderLineType | Contract | MinutesOfInvestmentServiceType,
  schema: OrderDialogSchema | Schema
) {
  if (!schema) {
    return [];
  }
  const result = tv4.validateMultiple(values, schema);
  const errors: ValidationErrorValue[] = [];
  if (!result.valid) {
    result.errors.forEach((error) => {
      const errObj = getErrorObject(error);
      if (errObj.key) {
        errors.push(errObj);
      } else if (error.subErrors && error.subErrors.length > 0) {
        error.subErrors.forEach((e) => errors.push(getErrorObject(e)));
      } else if (!errObj.key && errObj.message) {
        errors.push(errObj);
      }
    });
  }
  return errors;
}

export function updateCustomerOptions(state: ProspectsState, option: UpdateCustomerOptions) {
  let formValues = state.formValues;
  if (option.key === CUSTOMER_TYPE) {
    formValues = formValues.clear();
  }
  formValues = formValues.setIn(option.key.split('.'), option.value);
  return {
    ...state,
    formValues,
  };
}

export function isFieldRequired(fieldKey: string, schema: OrderDialogSchema) {
  let required;
  if (schema.required !== undefined) {
    for (let i = 0; i < schema.required.length; i++) {
      const key = schema.required[i];
      if (key === fieldKey) {
        required = true;
      }
    }
  }
  return required;
}

export function getModule(template: Template, moduleId: string) {
  const module = find(template.form.items, (section) => section.key === moduleId);
  return {
    module,
    contractId: template.contractType,
    moduleId,
    schema: template.schema,
  };
}

export function wrapElement(
  elem: JSX.Element,
  styles: { [className: string]: string },
  valid: boolean | undefined,
  index: number
) {
  let wrapperClass = styles.wrapper;
  if (valid === true) {
    wrapperClass += ' ';
  } else if (valid === false) {
    wrapperClass += ' error';
  } else {
    wrapperClass += ' empty';
  }

  return (
    <ScrollElement className={wrapperClass} key={index} name={`field${index}`}>
      {elem}
    </ScrollElement>
  );
}

export function isFieldDone(
  contractType: string,
  formTouched: Immutable.Set<string>,
  field: FieldType,
  errors: ValidationErrorValue[],
  values: Contract
) {
  const fieldKey = `${contractType}.${field.key}`.split('.');
  let error;
  if (field.items) {
    error = find(errors, (e) => e.key === field.key || isVisibleItemError(e.key, field, values));
  } else {
    error = find(errors, (e) => e.key === field.key);
  }
  return formTouched.has(fieldKey.join('.')) && !error;
}

export function isVisibleItemError(errorFieldKey: string, parentField: FieldType, values: Contract) {
  if (!keyStartsWith(errorFieldKey, parentField.key)) {
    return false;
  }
  const fieldName = errorFieldKey.substr(errorFieldKey.lastIndexOf('.') + 1);
  const errorField = parentField.items?.find((f) => f.key.endsWith(fieldName));
  if (!errorField?.condition) {
    return true;
  }
  const itemValues = getValue(values, errorFieldKey.substr(0, errorFieldKey.lastIndexOf('.')));
  return doesQuestionPassConditionTestToRender(itemValues, errorField.condition, []);
}

export function getModuleStatus(
  contractType: string,
  formTouched: Immutable.Set<string>,
  errors: ImmutableFormErrors,
  module: object,
  values: ImmutableContent,
  sections: Item[]
) {
  const hasValues = values.has(contractType);
  const linkTypeKey = 'textLinkKey';
  const tableKey = 'tableKey';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  let fieldsDone = module.items.filter((field) => {
    if (!hasValues) {
      return false;
    }
    if (
      (field.condition &&
        !doesQuestionPassConditionTestToRender(values.get(contractType).toJS(), field.condition, sections)) ||
      field.type === 'text' ||
      field.type === 'table'
    ) {
      if (field.key.includes(linkTypeKey) || field.key.includes(tableKey)) {
        return true;
      } else {
        return false;
      }
    }
    const errorObject = errors.get(contractType) ? errors.get(contractType).toJS() : {};
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return isFieldDone(contractType, formTouched, field, errorObject, values.get(contractType).toJS());
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  let fieldsVisible = module.items.filter((field) => {
    if (field.condition) {
      if (
        !values ||
        !hasValues ||
        !doesQuestionPassConditionTestToRender(values.get(contractType).toJS(), field.condition, sections)
      ) {
        return false;
      }
    }
    return field.type !== 'text';
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (module.key === 'sections.riskConfirmation') {
    const riskConfirmed = values?.toJS()[contractType]?.sections?.riskConfirmation?.riskConfirmed;

    fieldsDone = fieldsDone.concat(riskConfirmed && 'riskConfirmed').filter(Boolean);
    fieldsVisible = fieldsVisible.concat('riskConfirmed');
    return {
      fieldsDone,
      fieldsVisible,
    };
  }

  return {
    fieldsDone,
    fieldsVisible,
  };
}

export function getCustomerType(type: string, segment: string) {
  let customerType = type;
  if (type === COMPANY && segment === INSTITUUTIOVH) {
    customerType = INSTITUTION;
  }
  return customerType;
}

export function mapData(
  params = {
    source: {},
    target: {},
    mappings: [],
  }
) {
  const { source, target, mappings } = params;
  const SOURCE_KEY = 'source';
  const TARGET_KEY = 'target';
  const result = cloneDeep(target);
  mappings.forEach((mapping) => {
    let value = getValue(source, mapping[SOURCE_KEY]);
    if (!isNil(value) && value !== '') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (typeof mapping.mapper === 'function') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        value = mapping.mapper(value);
      }
      setValue(result, mapping[TARGET_KEY], value);
    }
  });

  return result;
}

export function getDependentQuestions(key: string, items: Item[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  let dependentQuestions = [];

  items.forEach((section) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    dependentQuestions = dependentQuestions.concat(findDependentQuestions(key, section.items));
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return dependentQuestions;
}

function findDependentQuestions(key: string, items: FieldType[]) {
  let conditionItemsArr: FieldType[] = [];
  conditionItemsArr = items.filter((s) => s.condition && s.condition.key === key);

  if (conditionItemsArr.length === 0) {
    const itemsKeyArr = key.split('.');
    const itemsKey = itemsKeyArr[itemsKeyArr.length - 1];
    items.forEach((item) => {
      const arrayFieldKey = itemsKeyArr.slice(0, -1).join('.');
      if (item !== undefined && item.key === arrayFieldKey && item.items) {
        conditionItemsArr = item.items.filter((s) => s.condition && s.condition.key === itemsKey);
      }
    });
  }

  return conditionItemsArr;
}

export function getAppointmentSchema(activity: string, state: string) {
  let schema = {};
  if (activity === APPOINTMENT && state === PROSPECT) {
    schema = appointmentProspect;
  } else if (activity === APPOINTMENT && state === ACCOUNT) {
    schema = appointmentAccount;
  } else if (activity === PHONE_CALL && state === ACCOUNT) {
    schema = callAccount;
  } else if (activity === PHONE_CALL && state === PROSPECT) {
    schema = callProspect;
  }
  return schema;
}

export function getError(errors: ValidationErrorValue[], key: string) {
  const error = errors.find((e) => e.key === key);
  return error ? error.message : undefined;
}

export function keyStartsWith(fullKey: string, partialKey: string) {
  const fullArray = fullKey.split('.');
  const partialArray = partialKey.split('.');
  for (let i = 0; i < partialArray.length; i++) {
    if (partialArray[i] !== fullArray[i]) {
      return false;
    }
  }
  return true;
}

export function init(store: Store) {
  counterpart.setLocale(store.getState().common.locale);

  setAccountingSettings();

  // NOTE: 8.2.2018 TTu - At the moment there is too many unhandled exceptions so logs get full of these messages
  if (config.enableErrorLogging) {
    window.onerror = (message, file, line, column, error) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      store.dispatch(logError(message, error));
      return false;
    };

    window.addEventListener('unhandledrejection', (event) =>
      store.dispatch(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        logError(
          `Unhandled promise rejection. Reason: ${getValue(event, ['detail', 'reason', 'message'], 'Not available')}`,
          getValue(event, ['detail', 'reason'], {})
        )
      )
    );
  }
}

const setAccountingSettings = () => {
  accounting.settings.number.thousand = ' ';
  accounting.settings.number.decimal = ',';
  accounting.settings.currency.thousand = ' ';
  accounting.settings.currency.decimal = ',';
  accounting.settings.currency.symbol = '';
};

export function countDecimal(number: number | undefined) {
  if (number !== undefined && Math.floor(number.valueOf()) === number.valueOf()) {
    return 0;
  }
  return (number !== undefined && number.toString().split('.')[1].length) || 0;
}
