import { Map, List, fromJS } from 'immutable';
import keyBy from 'lodash/keyBy';
import { PATCH_CUSTOMER_SUCCESS } from 'features/profileEdit/profileEditActions';
import {
  CUSTOMER_PROFILE_REQUEST,
  CUSTOMER_PROFILE_SUCCESS,
  CUSTOMER_PROFILE_FAILURE,
  CUSTOMER_DRAFT_REQUEST,
  CUSTOMER_DRAFT_SUCCESS,
  CUSTOMER_DRAFT_FAILURE,
  CUSTOMER_CONTRACTS_REQUEST,
  CUSTOMER_CONTRACTS_SUCCESS,
  CUSTOMER_IMAGES_SUCCESS,
  CUSTOMER_PDF_CONTRACT_REQUEST,
  CUSTOMER_PDF_CONTRACT_SUCCESS,
  CUSTOMER_UPLOAD_IMAGE_REQUEST,
  CUSTOMER_UPLOAD_IMAGE_SUCCESS,
  CUSTOMER_UPLOAD_IMAGE_FAILURE,
  CUSTOMER_DELETE_ID_IMAGE_REQUEST,
  SET_SELECTED_RISK_LEVEL,
  SET_FETCHING_CONTRACT,
  RESET_CUSTOMER,
  ProfileAction,
} from './profileActions';
import { ImmutableCustomer, ImmutableDraft, ProfileState } from 'types/profileState';
import { ImmutableContracts } from 'types/ordersState';
import { sortByCreationDate } from 'features/profile/profileUtils';

export const initialState: ProfileState = {
  isBusy: false,
  customer: Map() as ImmutableCustomer,
  draft: Map() as ImmutableDraft,
  contracts: Map() as ImmutableContracts,
  images: List(),
  hasError: false,
  uploadingImage: false,
  loadingDrafts: false,
  fetchingContract: false,
};

export function profileReducer(state = initialState, action: ProfileAction) {
  switch (action.type) {
    case CUSTOMER_PROFILE_REQUEST:
      return {
        ...state,
        isBusy: true,
        hasError: false,
        customer: Map(),
      };

    case CUSTOMER_PROFILE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        customer: Map(action.result),
      };

    case CUSTOMER_PROFILE_FAILURE:
      return {
        ...state,
        isBusy: false,
        hasError: true,
      };

    case RESET_CUSTOMER:
      return {
        ...initialState,
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case PATCH_CUSTOMER_SUCCESS:
      return {
        ...state,
        isBusy: false,
        customer: Map({
          ...state,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          ...action.data.sections.clientData,
        }),
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case SET_SELECTED_RISK_LEVEL: {
      const customerToJS = state.customer.toJS();
      return {
        ...state,
        customer: fromJS({
          ...customerToJS,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          selectedRiskLevel: action.selectedRiskLevel,
        }),
      };
    }

    case CUSTOMER_DRAFT_REQUEST:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        draft: state.draft.clear(),
        loadingDrafts: true,
      };

    case CUSTOMER_DRAFT_SUCCESS: {
      const payload = JSON.parse(action.result.payload);
      const draft = {
        count: Object.keys(payload).length,
        modified: action.result.created,
        content: fromJS(JSON.parse(action.result.payload)),
      };
      return {
        ...state,
        draft: fromJS(draft),
        loadingDrafts: false,
      };
    }

    case CUSTOMER_DRAFT_FAILURE:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        draft: state.draft.clear(),
        loadingDrafts: false,
      };

    case CUSTOMER_CONTRACTS_REQUEST:
      return {
        ...state,
        contracts: state.contracts.clear(),
      };

    case CUSTOMER_CONTRACTS_SUCCESS: {
      const contracts = keyBy(action.result.results, 'shortId');
      const contractsSortedByCreationDate = sortByCreationDate(fromJS(contracts));
      return {
        ...state,
        contracts: contractsSortedByCreationDate,
      };
    }

    case CUSTOMER_IMAGES_SUCCESS:
      return {
        ...state,
        images: List(action.result),
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_PDF_CONTRACT_REQUEST:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        contracts: state.contracts.update(action.id, (value) => value.set('isBusy', true)),
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_PDF_CONTRACT_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        contracts: state.contracts.update(action.id, (value) => value.set('blobUrl', action.blobUrl)),
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        uploadingImage: true,
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        uploadingImage: false,
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        uploadingImage: false,
      };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    case CUSTOMER_DELETE_ID_IMAGE_REQUEST:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        images: state.images.filter((img) => img.id !== action.id),
      };

    case SET_FETCHING_CONTRACT:
      return {
        ...state,
        fetchingContract: action.value,
      };

    default:
      return state;
  }
}
