import React, { useState, useEffect, ChangeEvent } from 'react';
import translate from 'counterpart';
import StyledRadioButton from '/features/common/styledComponents/StyledRadioButton';
import StyledFormControlLabel from '/features/common/styledComponents/StyledFormControlLabel';
import StyledButton from '/features/common/styledComponents/StyledButton';
import { RadioGroup } from '@mui/material';
import moment from 'moment';
import { Spinner } from 'features/common/Spinner';
import { ModalDialog } from 'features/common/ModalDialog';
import {
  Answer,
  AnswerOption,
  AppropriatenessResult,
  AppropriatenessTestState,
  CustomerAnswer,
  Question,
  Questionnaire,
  TestGrade,
} from 'types/orderDialogState';
import { getAppropriatenessAssesmentTest, putAppropriatenessAssesment } from '/features/orderDialog/orderDialogUtils';
import { colors } from 'styles/colors';

interface Props {
  token: string;
  productCategoryId: string | undefined;
  customerId: string;
  onTestFinished: (testState: AppropriatenessTestState) => void;
  loading: boolean;
  financialInstrumentId: string;
  financialInstrumentName: string;
  counterFinancialInstrumentName?: string;
  isNormalSearchType: boolean;
}

export const AppropriatenessTest = ({
  productCategoryId,
  token,
  customerId,
  onTestFinished,
  financialInstrumentId,
  financialInstrumentName,
  isNormalSearchType,
  counterFinancialInstrumentName,
}: Props) => {
  const [showPlaceholder, setShowPlaceholder] = useState(true);
  const [placeholderText, setPlaceholderText] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [resultText, setResultText] = useState('');
  const [continueButtonText, setContinueButtonText] = useState('');
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [customerAnswers, setCustomerAnswers] = useState<CustomerAnswer>();
  const [clickMeHandler, setClickMeHandler] = useState(() => () => {});

  const fetchData = () => {
    console.log('loading appropriateness test...'); // eslint-disable-line no-console
    setShowPlaceholder(true);
    setShowLoading(true);
    setShowResult(false);
    setPlaceholderText(translate('order.loadingAppropriatenessTest'));
    const tmpId = undefined;
    productCategoryId = productCategoryId ? productCategoryId : tmpId;
    if (productCategoryId) {
      const call = getAppropriatenessAssesmentTest(customerId, token, productCategoryId);

      call
        .then((result) => {
          setShowPlaceholder(false);
          setPlaceholderText('');
          setQuestionnaire(result);
          setCustomerAnswers(undefined);
          setShowLoading(false);
        })
        .catch(() => {
          setPlaceholderText(translate('order.appropriatenessTestCouldNotBeLoaded'));
          setShowResult(true);
          setResultText(
            `${translate('order.appropriatenessTestCouldNotBeLoadedErrorStart')} ${financialInstrumentId} ${translate(
              'order.appropriatenessTestCouldNotBeLoadedErrorEnd'
            )}`
          );
          setQuestionnaire(undefined);
          setCustomerAnswers(undefined);
          setShowLoading(false);
          setContinueButtonText(translate('order.abortAppropriatenessTest'));
          setClickMeHandler(() => cancelAfterAssessmentError);
        });
    } else {
      setPlaceholderText(translate('order.appropriatenessTestCouldNotBeLoaded'));
      setShowResult(true);
      setResultText(
        `${translate('order.appropriatenessTestCouldNotBeLoadedStart')} ${financialInstrumentId} ${translate(
          'order.appropriatenessTestCouldNotBeLoadedEnd'
        )}`
      );
      setQuestionnaire(undefined);
      setCustomerAnswers(undefined);
      setShowLoading(false);
      setContinueButtonText(translate('order.abortAppropriatenessTest'));
      setClickMeHandler(() => cancelAfterAssessmentError);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const mapResult = (result: AppropriatenessResult | null) => {
    const grade = result?.Grade;
    const disclaimer = result?.Disclaimer;
    const testTime = result?.TestTime;
    if (grade || result?.Grade === TestGrade.Passed) {
      if (grade === TestGrade.Passed) {
        setPlaceholderText(
          `${translate('order.appropriatenessTest')} ${translate('order.testPassed')} ${
            testTime ? moment(testTime).format('DD.MM.YYYY HH:mm') : ''
          }`
        );
        setResultText(disclaimer ? disclaimer : "Disclaimer text missing for test grade 'Passed'");
        setContinueButtonText(translate('order.continue'));
        setClickMeHandler(() => continueAfterAssessment);
      }
      if (grade === TestGrade.Failed) {
        setPlaceholderText(
          `${translate('order.appropriatenessTest')} ${translate('order.testFailed')} ${
            testTime ? moment(testTime).format('DD.MM.YYYY HH:mm') : ''
          }`
        );
        setResultText(translate('order.appropriatenessTestFailed'));
        setContinueButtonText(translate('order.abortAppropriatenessTest'));
        setClickMeHandler(() => quitAfterAssessment);
      }
      if (grade === TestGrade.Ignored) {
        setPlaceholderText(`${translate('order.appropriatenessTest')} ${translate('order.testIgnored')}`);
        setResultText(disclaimer ? disclaimer : "Disclaimer text missing for test grade 'Ignored'");
        setContinueButtonText(translate('order.abortAppropriatenessTest'));
        setClickMeHandler(() => quitAfterAssessment);
      }
      if (grade === TestGrade.AssumedOk) {
        setPlaceholderText(`${translate('order.appropriatenessTest')} ' assumedOk'?"`);
        setResultText(disclaimer ? disclaimer : "Disclaimer text missing for test grade 'AssumedOk'");
        setContinueButtonText(translate('order.abortAppropriatenessTest'));
        setClickMeHandler(() => quitAfterAssessment);
      }
      if (grade === TestGrade.Error) {
        setPlaceholderText(`${translate('order.appropriatenessTest')}: virhe tulosten tallennuksessa / hakemisessa"`);
        setResultText(disclaimer ? disclaimer : translate('order.appropriatenessTestResultRetrievalError'));
        setContinueButtonText(translate('order.abortAppropriatenessTest'));
        setClickMeHandler(() => quitAfterAssessment);
      }
    } else {
      setPlaceholderText(translate('order.appropriatenessTestResultCouldNotBeRetrieved'));
      setResultText(translate('order.appropriatenessTestResultRetrievalError'));
      setClickMeHandler(() => quitAfterAssessment);
    }
    setShowResult(true);
  };

  const putAssessment = () => {
    setShowPlaceholder(true);
    setShowLoading(true);
    setPlaceholderText(translate('order.sendingAppropriatenessTestReceivingResults'));
    console.log('Sending appropriateness assesment', customerAnswers); // eslint-disable-line no-console

    const call = putAppropriatenessAssesment(customerId, token, customerAnswers);

    call
      .then((result) => {
        mapResult(result);
        console.log('Appropriateness assesment result', result); // eslint-disable-line no-console
        setQuestionnaire(undefined);
        setCustomerAnswers(undefined);
        setShowLoading(false);
      })
      .catch(() => {
        setPlaceholderText(translate('order.appropriatenessTestResultCouldNotBeRetrieved'));
        setResultText(translate('order.appropriatenessTestResultRetrievalError'));
        setQuestionnaire(undefined);
        setCustomerAnswers(undefined);
        setShowResult(false);
        setShowLoading(false);
      });
  };

  const cancelAssessment = () => {
    setShowPlaceholder(true);
    setPlaceholderText(translate('order.appropriatenessTestCanceled'));
    setQuestionnaire(undefined);
    setCustomerAnswers(undefined);
    onTestFinished(AppropriatenessTestState.Canceled);
  };

  const continueAfterAssessment = () => {
    setQuestionnaire(undefined);
    setPlaceholderText('');
    setShowPlaceholder(false);
    setCustomerAnswers(undefined);
    onTestFinished(AppropriatenessTestState.Passed);
  };

  const quitAfterAssessment = () => {
    setShowPlaceholder(false);
    setShowResult(false);
    setPlaceholderText('');
    setQuestionnaire(undefined);
    setCustomerAnswers(undefined);
    onTestFinished(AppropriatenessTestState.Failed);
  };

  const cancelAfterAssessmentError = () => {
    setShowPlaceholder(false);
    setShowResult(false);
    setPlaceholderText('');
    setQuestionnaire(undefined);
    setCustomerAnswers(undefined);
    onTestFinished(AppropriatenessTestState.Canceled);
  };

  const checkIsDisabled = () => {
    const questionnaireLength = questionnaire?.Questions.length;
    const answersLength = customerAnswers?.answers.length;

    if (questionnaireLength !== undefined && answersLength !== undefined) {
      return questionnaireLength !== answersLength;
    }
    return true;
  };

  const handleAnswerChange = (event: ChangeEvent<HTMLInputElement>) => {
    const question = questionnaire?.Questions.find((x) => x.Id.toString() === event.target.name);
    const questionnaireId = questionnaire?.Id;
    if (question !== undefined && questionnaireId !== undefined) {
      const selectedEnum = event.target.value as keyof typeof AnswerOption;
      const selected = AnswerOption[selectedEnum];

      const currentCustomerAnswer = customerAnswers;
      let answers: Answer[] = [];
      if (currentCustomerAnswer !== undefined) {
        answers = currentCustomerAnswer.answers;
        const answerToUpdate = answers.find((x) => x.questionId === question.Id);
        if (answerToUpdate !== undefined) {
          const answerPosition = answers.indexOf(answerToUpdate);
          answerToUpdate.selection = selected;
          answers[answerPosition] = answerToUpdate;
          updateAnswer(questionnaireId, answers);
        } else {
          setAnswer(selected, question, answers, questionnaireId);
        }
      } else {
        setAnswer(selected, question, answers, questionnaireId);
      }
    }
  };

  const setAnswer = (answerOption: AnswerOption, question: Question, answers: Answer[], questionnaireId: number) => {
    const currentAnswer: Answer = { questionId: question.Id, selection: answerOption };
    answers.push(currentAnswer);
    updateAnswer(questionnaireId, answers);
  };

  const updateAnswer = (questionnaireId: number, answers: Answer[]) => {
    const customerAnswer: CustomerAnswer = { questionnaireId, answers };
    setCustomerAnswers(customerAnswer);
  };

  const modalContent = () => {
    return (
      <div>
        {placeholderText}
        <br />
        <Spinner className={'spinner'} size={30} position="center" color={colors.ptGreen} />
      </div>
    );
  };

  if (showPlaceholder) {
    return (
      <section className={'assessmentSectionPlaceholder'}>
        <ModalDialog
          title={translate('order.pleaseWait')}
          open={showLoading}
          content={modalContent()}
          actions={<div key={'NoOp'} />}
        />
        <div className="row" key="appropriatenessTestPlaceholder">
          <div className="columns small-12">
            <br />
            <h4>{placeholderText}</h4>
            {showPlaceholder && showResult ? (
              <div className="resultSection">
                <div>{resultText}</div>
                <div>
                  <StyledButton
                    size="small"
                    onClick={clickMeHandler}
                    disabled={false}
                    style={{
                      margin: '1em 1em .5em 0',
                      backgroundColor: 'black',
                      color: '#ffffff',
                    }}
                    variant="contained"
                  >
                    {continueButtonText}
                  </StyledButton>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    );
  } else if (questionnaire) {
    return (
      <section className={'assessmentSection'}>
        <div className="row" key="appropriatenessTest">
          <div className="columns small-12">
            <br />
            <h4>{`${translate('order.appropriatenessTest')} ${
              questionnaire?.Name ? ' / ' + questionnaire?.Name : null
            }`}</h4>
            {isNormalSearchType
              ? translate('order.appropriatenessTestIngress', {
                  name: financialInstrumentName,
                })
              : translate('order.appropriatenessTestExchangeIngress', {
                  pair: counterFinancialInstrumentName,
                  name: financialInstrumentName,
                })}
            <br />
            <br />
            {questionnaire?.Questions.map((question) => (
              <div key={question.Id}>
                <b style={{ fontWeight: 300 }}>{question.QuestionText}</b>
                <br />
                <RadioGroup row onChange={handleAnswerChange} name={question.Id.toString()}>
                  <StyledFormControlLabel
                    value={AnswerOption.Yes}
                    control={<StyledRadioButton />}
                    label={translate('order.answerOptionYes')}
                  />
                  <StyledFormControlLabel
                    value={AnswerOption.No}
                    control={<StyledRadioButton />}
                    label={translate('order.answerOptionNo')}
                  />
                  <StyledFormControlLabel
                    value={AnswerOption.NotKnown}
                    control={<StyledRadioButton />}
                    label={translate('order.answerOptionNotKnown')}
                  />
                </RadioGroup>
                <hr />
              </div>
            ))}
            <StyledButton
              size="small"
              onClick={putAssessment}
              disabled={checkIsDisabled()}
              style={{
                margin: '1em 1em .5em 0',
                backgroundColor: checkIsDisabled() ? 'rgba(0, 0, 0, 0.12)' : 'black',
                color: checkIsDisabled() ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
              }}
              variant="contained"
            >
              {translate('order.sendAppropriatenessTest')}
            </StyledButton>
            <StyledButton
              size="small"
              onClick={cancelAssessment}
              style={{
                margin: '1em 1em .5em 0',
                backgroundColor: 'black',
                color: '#ffffff',
              }}
              variant="contained"
            >
              {translate('order.cancelAppropriatenessTest')}
            </StyledButton>
          </div>
        </div>
      </section>
    );
  } else {
    return null;
  }
};
export const AppropriatenessTestCanceled = () => {
  return (
    <section className={'assessmentSectionPlaceholder'}>
      <div className="row" key="appropriatenessTestPlaceholder">
        <div className="columns small-12">
          <br />
          <h4>{translate('order.appropriatenessTestCanceled')}</h4>
        </div>
      </div>
    </section>
  );
};
