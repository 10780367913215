import { AssetCategoryName, AssetCategoryId, AssetClassGroupId, AssetClassId } from 'types/types';
import { OptimizedPortfolios, OptimizedWeights, Weights } from 'types/weightsState';

export const WEIGHT_LIMIT = 0.0005; // rounds up to 0.1 %
export const TRADE_WEIGHT_LIMIT = 0.001;
export const TRADE_QUANTITY_LIMIT = 0.8;

export const FUND_SELL_LIMIT = 0.95;
export const TRADE_VALUE_LIMIT = 0;
export const TRADE_SHOW_LIMIT = 0.005;

export const ALL_INSTRUMENTS_GROUP = 'ALLOKAATTORI';
export const MONEY = 'MONEY';

export const INSTRUMENT_ASSET_CATEGORY_IDS: AssetCategoryId[] = ['ALLOC', 'ST', 'BO', 'ALT'];

export const ASSET_CATEGORIES = {
  ST: 'Osakkeet',
  BO: 'Korot',
  ALT: 'Vaihtoehtoiset',
  ALLOC: 'Muut',
  OTHER: 'Muut',
} as const;

export const ASSET_CATEGORY_ORDER: AssetCategoryName[] = ['Osakkeet', 'Korot', 'Vaihtoehtoiset', 'Muut'];

export const INSTRUMENT_ASSET_CATEGORY_ORDER: AssetCategoryName[] = [
  'Allokaatiot',
  'Osakkeet',
  'Korot',
  'Vaihtoehtoiset',
  'Muut',
];

export const optimizationForecastTypes = {
  COMPANY: 'company',
  NEUTRAL: 'neutral',
} as const;

export const weightTypes = {
  currentWeights: 'currentWeights',
  optimizedWeights: 'optimizedWeights',
} as const;

export const allocatorContractTypes = {
  KONS: 'KONS',
  TYS: 'TYS',
  TV: 'TV',
  TUNTEMATON: 'Tuntematon',
  KOONTI: 'Koonti',
  NO_CONTRACT: 'Tehtaan salkku',
} as const;

export const optimizationManners = {
  INCLUDE: 'Include',
  CONSIDER: 'Consider',
  IGNORE: 'Ignore',
} as const;

const emptyStatistics = {
  weightStats: {
    expectedReturn: 0,
    volatility: 0,
    sharpe: 0,
    riskFloat: 0,
  },
  confidenceLevels: {
    lowerConfidenceLevel60: [],
    lowerConfidenceLevel80: [],
    lowerConfidenceLevel90: [],
    upperConfidenceLevel60: [],
    upperConfidenceLevel80: [],
    upperConfidenceLevel90: [],
    expectedValue: [],
  },
  illiquidInstrumentsWeight: 0,
};

export const emptyWeights: Weights = {
  assetCategoryWeights: [],
  neutralOptimizationForecastStatistics: emptyStatistics,
  companyOptimizationForecastStatistics: emptyStatistics,
  instrumentWeights: [],
  returnStatistics: {
    portfolioReturn: 0,
    portfolioVolatility: 0,
    riskFloat: 0,
  },
  portfolioWeights: [],
};

export const emptyOptimizedWeights: OptimizedWeights = {
  riskLevel: 0,
  optimizationForecastType: optimizationForecastTypes.COMPANY,
  withIlliquids: emptyWeights,
  withoutIlliquids: emptyWeights,
};

export const emptyOptimizedPortfolios: OptimizedPortfolios = {
  riskLevel: 0,
  optimizationForecastType: optimizationForecastTypes.COMPANY,
  portfolios: [],
};

export const otherAssetCategory = {
  name: 'Muut' as AssetCategoryName,
  assetClassGroups: [
    {
      name: 'Käteinen',
      assetCategory: 'Muut' as AssetCategoryName,
      groupId: 'OTHER' as AssetClassGroupId,
      assetClasses: [
        {
          assetClassId: 'OTHER' as AssetClassId,
          weight: 1,
        },
      ],
    },
  ],
};

export const customerKinds = ['withPlan', 'withPlanNoPositions', 'withoutPlan', 'withErrors'] as const;
