import React from 'react';
import translate from 'counterpart';
import { Grid } from 'features/allocator/common/Grid';
import { ProjectionChart } from 'features/allocator/common/ProjectionChart';
import { VaR } from 'features/allocator/riskSelection/components/VaR';
import { ExpectedReturnFigures } from 'features/allocator/common/ExpectedReturnFigures';
import { ConfidenceLevels } from 'types/weightsState';

interface Props {
  confidenceLevels: ConfidenceLevels;
  portfolioReturn: number;
  startValue: number;
}

export const YieldGraphAndLegend = ({ confidenceLevels, portfolioReturn, startValue }: Props) => (
  <Grid vertical>
    <h2>{translate('allocator.summaryView.expectedYieldOverTime')}</h2>
    <ProjectionChart confidenceLevels={confidenceLevels} startValue={startValue} />
    <Grid autoColumns>
      <ExpectedReturnFigures portfolioReturn={portfolioReturn} />
      <VaR confidenceLevels={confidenceLevels} />
    </Grid>
  </Grid>
);
