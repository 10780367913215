import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { OidcProvider } from 'redux-oidc';
import { History } from 'history';
import { Store } from 'redux';
import { createTheme, ThemeProvider } from '@mui/material';
import { userManager } from 'core/userManager';
import ErrorBoundary from 'features/common/ErrorBoundary';

interface Props {
  history: History;
  routes: JSX.Element;
  store: Store;
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
  },
});

const Root = ({ history, routes, store }: Props) => (
  <ErrorBoundary>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <OidcProvider store={store} userManager={userManager}>
          <Router history={history} routes={routes} />
        </OidcProvider>
      </ThemeProvider>
    </Provider>
  </ErrorBoundary>
);

export default Root;
