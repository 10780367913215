import { SHARE, ETF, CERTIFICATE, BOND, SUBSCRIPTION_RIGHT, OPTION } from './instrumentForms';
import {
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
  RECEIVED_FROM_CLIENT_DATE,
  BASIS_TYPE,
} from './orderFields';

export const MARKET_PRICE = 'Market';
export const LIMIT_PRICE = 'Limit';
export const ENOUGH_MONEY = 'EnoughMoney';
export const MONEY_FROM_OTHER_TRADE = 'MoneyFromOtherTrade';
export const ENOUGH_SHARES = 'EnoughSecurities';
export const SECURITY_NOT_IN_REPORTING = 'SecurityNotInReporting';
export const DIRTY = 'Dirty';
export const CLEAN = 'Clean';
export const PHONE_METHOD = 'Phone';
export const WRITTEN_METHOD = 'Written';
export const NONE = 'None';

export const orderTypes = [
  {
    value: MARKET_PRICE,
    label: 'Markkina',
  },
  {
    value: LIMIT_PRICE,
    label: 'Rajahinta',
  },
];

export const buyFunding = [
  {
    value: ENOUGH_MONEY,
    label: 'Tarpeeksi rahaa',
  },
  {
    value: MONEY_FROM_OTHER_TRADE,
    label: 'Varat myynnistä',
  },
];

export const sellFunding = [
  {
    value: ENOUGH_SHARES,
    label: 'Tarpeeksi arvopapereita',
  },
  {
    value: SECURITY_NOT_IN_REPORTING,
    label: 'Arvopaperi ei raportoinnissa',
  },
];

export const listed = [SHARE, ETF, CERTIFICATE, BOND, SUBSCRIPTION_RIGHT, OPTION];

export const permanentFields = [
  '_id',
  'portfolioId',
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
  RECEIVED_FROM_CLIENT_DATE,
  'side',
  'capitalCallEmail',
  'receiverEmail',
  'moneySource',
  BASIS_TYPE,
];

export const bondPriceTypes = [
  {
    value: CLEAN,
    label: 'Korot eivät sisälly',
  },
  {
    value: DIRTY,
    label: 'Korot sisältyvät',
  },
];

export const receivedFromClientTypes = [
  {
    value: WRITTEN_METHOD,
    label: 'Kirjallinen',
  },
  {
    value: PHONE_METHOD,
    label: 'Puhelinsoitto',
  },
];
