import { BUSINESS, FIRST_MEETING } from 'constants/meetingReason';
import { MEETING_DESCRIPTION_PATTERN } from 'constants/schemas';

export default {
  properties: {
    activity: {
      type: 'string',
      minLength: 1,
    },
    topic: {
      type: 'string',
      enum: [BUSINESS, FIRST_MEETING],
    },
    description: {
      type: 'string',
      pattern: MEETING_DESCRIPTION_PATTERN,
    },
    finished: {
      type: 'boolean',
    },
    location: {
      type: 'string',
      minLength: 1,
    },
    present: {
      type: 'string',
      minLength: 1,
    },
    scheduledEnd: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    scheduledStart: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    subject: {
      type: 'string',
      minLength: 1,
    },
  },
  type: 'object',
  required: ['activity', 'topic', 'subject', 'scheduledStart', 'scheduledEnd', 'location', 'present', 'description'],
};
