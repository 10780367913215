import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { SignatureSelection } from 'features/contracts/components/SignatureSelection';
import { OpenFile } from 'features/contracts/components/DownloadPdf';
import { NewSignatoryDialog } from 'features/contracts/components/NewSignatoryDialog';
import SignatoryDetailsDialog from 'features/contracts/components/SignatoryDetailsDialog';
import { BisnodeInfo } from 'features/contracts/components/BisnodeInfo';
import { SENT_FOR_INK_SIGNING, SENT_FOR_SIGNING } from 'constants/contractStates';
import styles from './Signatories.scss';
import { Signatory, OrderDetailsType } from 'types/ordersState';
import { SignatureMethodType } from 'types/types';
import { SignatureContract } from 'types/contractsState';
import {
  getPdfForPreview,
  addSignatory,
  removeSignatory,
  setManualSignatureAmount,
  prefillSignatory,
  selectSignatureMethod,
  getOrderBisnode,
  updateBisnode,
} from 'features/orders/ordersActions';
import {
  selectSignatureMethods,
  selectSignatoriesByPortfolioId,
  selectPortfolioContractNameByPortfolioId,
} from 'features/orders/ordersSelectors';
import { RootState } from 'types/rootState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  orderDetails: OrderDetailsType;
  portfolioId: string;
}

export const OrderSignatory = ({ orderDetails, portfolioId }: Props) => {
  const dispatch = useAppDispatch();

  const [showNewSignatoryDialog, setShowNewSignatoryDialog] = useState<boolean>(false);
  const [showSignatoryDetailsDialog, setShowSignatoryDetailsDialog] = useState<boolean>(false);
  const [signatoryDetails, setSignatoryDetails] = useState<Signatory | {}>({});

  const signatureMethods = useSelector((state: RootState) =>
    selectSignatureMethods(state, portfolioId, orderDetails.orderLineIds)
  );
  const signatories = useSelector((state: RootState) => selectSignatoriesByPortfolioId(state, portfolioId));
  const savedSigners = useSelector((state: RootState) => state.orders.ordersByPortfolioId[portfolioId].savedSigners);
  const profile = useSelector((state: RootState) => state.profile);
  const portfolioContractName = useSelector((state: RootState) =>
    selectPortfolioContractNameByPortfolioId(state, portfolioId)
  );

  const handleOnNewSignatoryButtonClick = () => {
    setShowNewSignatoryDialog(true);
  };

  const handleNewSignatoryDialogOnCloseClick = () => {
    setShowNewSignatoryDialog(false);
  };

  const handleOnSignatorySelect = (signatoryDetails: Signatory) => {
    setShowSignatoryDetailsDialog(true);
    setSignatoryDetails(signatoryDetails);
  };

  const handleSignatoryDetailsDialogOnCloseClick = () => {
    setShowSignatoryDetailsDialog(false);
    setSignatoryDetails({});
  };

  const handleManualSignerAmount = (amount: number, orderLineIds: number[]) => {
    dispatch(setManualSignatureAmount(amount, portfolioId, orderLineIds));
  };

  const handleNewSignatoryDialogOnAddClick = (signatory: Signatory) => {
    dispatch(addSignatory(signatory, portfolioId));
    handleNewSignatoryDialogOnCloseClick();
  };

  const handleSignatoryDetailsDialogOnRemoveClick = (signatory: Signatory) => {
    handleSignatoryDetailsDialogOnCloseClick();
    dispatch(removeSignatory(signatory, portfolioId));
  };

  const loadFile = () => {
    dispatch(getPdfForPreview(portfolioId));
  };

  const handleOnSignatureMethodSelected = (
    contractType: string | undefined,
    value: SignatureMethodType,
    orderLineIds: number[]
  ) => {
    if (value === SENT_FOR_SIGNING || value === SENT_FOR_INK_SIGNING) {
      dispatch(prefillSignatory(portfolioId));
    }
    dispatch(selectSignatureMethod(value, portfolioId, orderLineIds));
  };

  const bisnode = useSelector((state: RootState) => state.orders.ordersByPortfolioId[portfolioId]?.bisnode);
  const isInkSignAndHasSignatory = orderDetails.signatureMethod === SENT_FOR_INK_SIGNING && signatories.size > 0;

  if (!orderDetails.showSignatureSelection) {
    return null;
  }

  const isPdfBusy = (orderDetails: OrderDetailsType): boolean => {
    const pdfBusy = orderDetails.pdfPreviewLink.get('isBusy');
    const pdfId = orderDetails.pdfPreviewLink.get('id');
    const pdfError = orderDetails.pdfPreviewLink.get('error');

    if (pdfBusy === false && pdfError !== undefined) {
      return false;
    }

    const result = pdfBusy || !pdfId;

    return result;
  };

  /* const acceptLabel = 'order.toFrontPage';
  const cancelLabel = 'order.cancel';
  //const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const isModalOpen = useRef(false);

  const toFrontPage = () => {
    //setModalIsOpen(false);
    isModalOpen.current = false;
  };

  const actions = [
    <StyledButton variant="text" onClick={toFrontPage} key={acceptLabel}>
      {translate(acceptLabel)}
    </StyledButton>
  ]; */

  return (
    <div className={styles.signatories}>
      <h4 className={styles.header}>{translate(`order.sign`)}</h4>

      {/* <ModalDialog title='Titteli' actions={actions} open={isModalOpen.current} content='asdf asdfasdf asdf asdf asdf' /> */}

      {profile.customer.toJS().customerType === 'company' && (
        <BisnodeInfo
          portfolioId={portfolioId}
          bisnode={bisnode}
          getOrderBisnode={getOrderBisnode}
          updateBisnode={updateBisnode}
        />
      )}

      <SignatureSelection
        contract={
          {
            id: orderDetails?.pdfPreviewLink?.get('id'),
            name: portfolioContractName,
            signatories: signatories.toJS(),
            type: 'order',
            manualSignerAmount: orderDetails.manualSigners ?? 1,
          } as SignatureContract
        }
        headerIcon={
          <OpenFile
            blobUrl={orderDetails.pdfPreviewLink.get('blobUrl')}
            loadFile={loadFile}
            isBusy={isPdfBusy(orderDetails)}
            /* isBusy={orderDetails.pdfPreviewLink.get('isBusy') || !orderDetails.pdfPreviewLink.get('id')} */
          />
        }
        selectionHintText="Valitse allekirjoitustapa"
        selectedSignatureMethod={orderDetails.signatureMethod}
        onSignatureMethodSelected={(contractType: string | undefined, value: SignatureMethodType) =>
          handleOnSignatureMethodSelected(contractType, value, orderDetails.orderLineIds)
        }
        onNewSignatoryButtonClick={handleOnNewSignatoryButtonClick}
        showCopyAllSignatories={false}
        onSignatorySelect={handleOnSignatorySelect}
        signatureMethods={signatureMethods}
        disableAddSignatory={isInkSignAndHasSignatory}
        setManualSignatureAmount={(amount: number) => handleManualSignerAmount(amount, orderDetails.orderLineIds)}
      />

      {showNewSignatoryDialog && portfolioContractName && (
        <NewSignatoryDialog
          isOpen={true}
          contract={{ name: portfolioContractName }}
          savedSigners={savedSigners}
          onCloseClick={handleNewSignatoryDialogOnCloseClick}
          onAddClick={handleNewSignatoryDialogOnAddClick}
        />
      )}

      {showSignatoryDetailsDialog && portfolioContractName && (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <SignatoryDetailsDialog
          signatory={signatoryDetails}
          contract={{ name: portfolioContractName }}
          onRemoveClick={handleSignatoryDetailsDialogOnRemoveClick}
          onCloseClick={handleSignatoryDetailsDialogOnCloseClick}
        />
      )}
    </div>
  );
};
