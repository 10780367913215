import React from 'react';
import { Link } from 'react-router';
import Phone from '@mui/icons-material/PhoneInTalk';
import ActionWork from '@mui/icons-material/Work';
import SocialGroup from '@mui/icons-material/Group';
import styled from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { User } from './User';
import styles from './SearchResults.scss';
import { meetingType } from 'constants/meetingType';
import { ACCOUNT } from 'constants/customerStates';
import { config } from 'config';
import { User as UserType } from 'types/types';
import { AllocatorIcon } from 'features/allocator/common/SvgIcon';
import { FlexColumn } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { selectIsDigiCustomer } from 'features/search/searchSelectors';

interface Props {
  user: UserType;
  onClick: (user: UserType) => void;
}

export const SearchResultItem = ({ user, onClick }: Props) => {
  const handleClick = () => onClick(user);
  const appointmentUrl = `customer/${user.customerId}/appointment`;
  const isDigiCustomer = useSelector((state: RootState) => selectIsDigiCustomer(state, user.customerId));
  const description = `${user?.state} | ${
    isDigiCustomer ? translate(`customer.digiCustomer`) : user?.accountManagerName
  }`;

  return (
    <li className={styles.result}>
      <Link onClick={handleClick} to={`customer/${user.customerId}`} data-testkey={user.customerId}>
        <User
          name={user.name}
          description={description}
          basicContractSigned={user.basicContractSigned}
          state={user.state}
          isDigiCustomer={isDigiCustomer}
          nextKycUpdate={user.nextKycUpdate}
          mytyContractSigned={user.mytyContractSigned}
        />
      </Link>
      {user.state === ACCOUNT && (
        <>
          <Link to={{ pathname: appointmentUrl, state: { activity: meetingType.PHONE_CALL } }} key="phone">
            <IconAndLabel>
              <Phone
                sx={{
                  color: '#000000',
                }}
              />
              {translate('search.call')}
            </IconAndLabel>
          </Link>
          <Link to={{ pathname: appointmentUrl, state: { activity: meetingType.APPOINTMENT } }} key="app">
            <IconAndLabel>
              <SocialGroup
                sx={{
                  color: '#000000',
                }}
              />
              {translate('search.meeting')}
            </IconAndLabel>
          </Link>
          <Link onClick={handleClick} to={`customer/${user.customerId}/portfolio?f=all`} key="portfolio">
            <IconAndLabel>
              <ActionWork
                sx={{
                  color: '#000000',
                }}
              />
              {translate('search.portfolios')}
            </IconAndLabel>
          </Link>
          {config.portfolioManager && (
            <Link to={`customer/${user.customerId}/portfolioManager/`} key="allocator" data-testkey={`allocator-link`}>
              <IconAndLabel>
                <AllocatorIcon width="24" />
                {translate('search.allocator')}
              </IconAndLabel>
            </Link>
          )}
        </>
      )}
    </li>
  );
};

const IconAndLabel = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;

  svg,
  img {
    margin-bottom: 0.5rem;
  }
`;
