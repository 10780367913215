import React from 'react';
import translate from 'counterpart';
import _ from 'lodash';
import { PortfolioFilterItem } from './PortfolioFilterItem';
import styles from './PortfolioFilter.scss';
import {
  getPortfolioGroupOrder,
  primarySortOrderPortfolioGroup,
  secondarySortOrderPortfolioGroup,
} from 'core/portfolios';
import { GROUP_OWN_FUNDS, GROUP_INSURANCE_FUNDS, GROUP_OUTSIDE_FUNDS } from 'constants/portfolios';
import { MarketInfoByPortfolio, PortfolioType, PortfolioTypes } from 'types/portfolioState';

interface Props {
  portfolios: PortfolioTypes;
  baseUrl: string;
  marketInfoByPortfolio: MarketInfoByPortfolio;
  loading: boolean;
}

export const PortfolioFilter = ({ portfolios, baseUrl, marketInfoByPortfolio, loading }: Props) => {
  const getHeader = (group: string) => {
    if (group === GROUP_OWN_FUNDS) {
      return translate('portfolios.ownFunds');
    }
    if (group === GROUP_INSURANCE_FUNDS) {
      return translate('portfolios.insuranceFunds');
    }
    if (group === GROUP_OUTSIDE_FUNDS) {
      return translate('portfolios.outsideFunds');
    }
    return translate('portfolios.otherFunds');
  };

  const keys = _.sortBy(_.keys(portfolios), (x) => getPortfolioGroupOrder(x));

  const components = keys.map((group, index) => {
    const groupPortfolios = _.sortBy(
      portfolios[group as PortfolioType],
      (x) => primarySortOrderPortfolioGroup(group, x),
      (p) => secondarySortOrderPortfolioGroup(group, p)
    );
    const header = getHeader(group);
    const list = groupPortfolios.map((portfolio) => {
      return (
        <PortfolioFilterItem
          to={`${baseUrl}?f=${portfolio.id}`}
          name={portfolio.nameForCustomer}
          marketInfo={marketInfoByPortfolio[portfolio.id]}
          isSummary={false}
          key={portfolio.id}
          bankerName={portfolio.bankerName}
          responsiblePartyName={portfolio.responsiblePartyName}
          portfolioId={portfolio.id}
        />
      );
    });
    return (
      <li key={index}>
        <header>{header}</header>
        <ul>{list}</ul>
      </li>
    );
  });

  const loadingComponents = (
    <li>
      <header>Ladataan...</header>
    </li>
  );

  return !loading ? (
    <section className={styles.filter}>
      <ul>
        <PortfolioFilterItem to={`${baseUrl}?f=all`} name={translate('order.summaryOfAssets')} isSummary={true} />
        {components}
      </ul>
    </section>
  ) : (
    <section className={styles.filter}>{loadingComponents}</section>
  );
};
