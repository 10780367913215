import React from 'react';
import { Map } from 'immutable';
import translate from 'counterpart';
import moment from 'moment';
import _ from 'lodash';
import { OpenFile } from 'features/contracts/components/DownloadPdf';
import styles from './ContractGroup.scss';
import ContractListItem from './ContractListItem';
import { FINISHED } from 'constants/contractStates';
import { Contract, ImmutableContract, ImmutableContracts } from 'types/ordersState';

interface Props {
  contracts: ImmutableContracts;
  openFile: () => void;
  copyContract: () => void;
  title: string;
  showAll: boolean;
  allowCopy: boolean;
}

export default class ContractGroup extends React.Component<Props> {
  static defaultProps = {
    contracts: Map(),
  };

  MAX_VISIBLE = 3;

  getHeaderText(contract: Contract) {
    if (contract.state === 'signed') {
      return `Allekirjoitettu ${moment(contract.editDate).format('DD.MM.YYYY')}  ${contract.contractName}`;
    }
    return `Luotu ${moment(contract.creationDate).format('DD.MM.YYYY')}  ${contract.contractName}`;
  }

  render() {
    const { contracts, openFile, copyContract, title, showAll, allowCopy } = this.props;
    let filteredContracts = contracts.toArray();
    let finished;
    if (!showAll) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      finished = _.remove(filteredContracts, (c) => c.get('state') === FINISHED);
      finished = _.slice(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        _.uniqBy(finished, (c) => c.get('contractType')),
        0,
        this.MAX_VISIBLE
      );
      if (finished.length < this.MAX_VISIBLE) {
        filteredContracts = _.slice(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          _.uniqBy(finished.concat(filteredContracts), (c) => c.get('contractType')),
          0,
          this.MAX_VISIBLE
        );
      }
    }

    const components = _.map(filteredContracts, (immutableContract: ImmutableContract) => {
      const contract = immutableContract.toJS();
      const loadFile = openFile.bind(this, contract.shortId);
      const headerIcon = <OpenFile isBusy={contract.isBusy} loadFile={loadFile} blobUrl={contract.blobUrl} />;
      const text = this.getHeaderText(contract);
      return (
        <ContractListItem
          key={contract.shortId}
          contractType={contract.contractType}
          headerIcon={headerIcon}
          headerText={text}
          contractState={contract.state}
          allowCopy={allowCopy}
          copyContract={copyContract}
          contractId={contract.shortId}
        />
      );
    });

    const header = title ? <span className={styles.groupHeader}>{translate(`customer.${title}`)}</span> : '';

    return (
      <section className={styles.contracts}>
        {header}
        <ul>{components}</ul>
      </section>
    );
  }
}
