import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface Props {
  checked: boolean;
  disabled?: boolean;
  className?: string;
  type?: 'checkbox' | 'radio';
}

export const StyledRadioButton = ({ checked, disabled = false, type = 'radio', className }: Props) => (
  <StyledRadioButtonContainer checked={checked} disabled={disabled} type={type} className={className}>
    <Icon viewBox="0 0 24 24">
      <polyline points="20 6 9 17 4 12" />
    </Icon>
  </StyledRadioButtonContainer>
);

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledRadioButtonContainer = styled.div<Props>`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 1rem 0 0;
  transition: all 150ms;

  border-radius: ${(props) => (props.type === 'radio' ? '50%' : '35%')};
  background: ${(props) => (props.checked ? colors.primary : 'transparent')};
  border: ${(props) => `2px solid ${props.disabled ? colors.warm_gray : colors.primary}`};
  opacity: ${(props) => (props.disabled && props.checked ? 0.4 : 1)};

  ${(props) =>
    props.checked &&
    props.disabled &&
    css`
      border: 2px solid ${colors.primary};
    `}

  ${Icon} {
    opacity: ${(props) => (props.checked ? 1 : 0)};
  }
`;
