import React from 'react';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { Box, Table, TableBody, TableContainer } from '@mui/material';
import { SummarySectionHeader } from 'features/orders/components/SummarySectionHeader';
import { PortfolioDetailsById } from 'types/portfolioState';
import { OrderLine } from './OrderLine';
import { OrderLineType } from 'types/ordersState';
import { colors } from 'styles/colors';

export const OrderLinesSummary = ({ orderLines, header, portfolioDetailsById, editable }: Props) => {
  const orderLinesByPortfolioId = groupBy(orderLines, (i) => i.portfolioId);
  const lines = Object.keys(orderLinesByPortfolioId).map((portfolioId: string) => {
    const orderlines = orderLinesByPortfolioId[portfolioId];
    return (
      <React.Fragment key={portfolioId}>
        <Title editable={editable}>{portfolioDetailsById[portfolioId]?.portfolioName}</Title>
        <TableContainer component={PtTableContainer}>
          <PtTable sx={{ minWidth: 200, marginBottom: 0 }} key={portfolioId}>
            <TableBody>
              {orderlines.map((orderline) => (
                <OrderLine
                  order={orderline}
                  portfolioDetails={portfolioDetailsById[orderline.portfolioId]}
                  key={orderline._id}
                  editable={editable}
                />
              ))}
            </TableBody>
          </PtTable>
        </TableContainer>
      </React.Fragment>
    );
  });

  return orderLines.length > 0 ? (
    <SummaryContainer editable={editable} data-testkey={'orderlines-summary'}>
      <SummarySectionHeader text={header} />
      {lines}
    </SummaryContainer>
  ) : null;
};

const PtTable = styled(Table)`
  border: 1px solid rgb(212, 205, 190);
`;

const SummaryContainer = styled.section`
  ${(props: EditableProps) => props.editable && `background-color: ${colors.gray_light};`}
`;

interface EditableProps {
  editable: boolean;
}

const PtTableContainer = styled(Box)`
  border: 1px solid rgb(212, 205, 190);
`;

interface Props {
  orderLines: OrderLineType[];
  header: string;
  portfolioDetailsById: PortfolioDetailsById;
  editable: boolean;
}

const Title = styled.p`
  font-size: 16px;
  font-weight: 400;
  color: black;
  ${(props: EditableProps) => props.editable && `background-color: ${colors.gray_light};`}
  margin-bottom: 0;
  margin-top: 20px;
`;
