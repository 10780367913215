import { Map, List } from 'immutable';
import { USER_FOUND } from 'redux-oidc';
import { ContractListAction } from 'features/contractList/contractListActions';
import { ContractListState } from 'types/contractListState';
import {
  CONTRACTS_REQUEST,
  CONTRACTS_SUCCESS,
  CONTRACTS_FAILURE,
  SET_CONTRACTS_SEARCH_FILTERS,
  MERGE_CONTRACTS_SEARCH_FILTERS,
  SAVED_CONTRACTS_FILTERS_SUCCESS,
  DELETE_SAVED_CONTRACTS_FILTER,
  ADD_SAVED_CONTRACTS_FILTER_SUCCESS,
  CONTRACTS_PDF_DOC_REQUEST,
  CONTRACTS_PDF_DOC_SUCCESS,
  CONTRACTS_PDF_DOC_FAILURE,
} from './contractListActions';

const SEARCH_TAKE = 10;

const initialState: ContractListState = {
  items: List(),
  searchFilters: Map(),
  searchSkip: 0,
  searchTake: SEARCH_TAKE,
  uploadPdfState: undefined,
  csvLoading: false,
  savedFilters: List(),
  loadingContracts: false,
  contractPdfLoading: undefined,
  totalResults: 0,
};

export function contractListReducer(state = initialState, action: ContractListAction) {
  switch (action.type) {
    case USER_FOUND:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        searchFilters: state.searchFilters.merge({ bankers: [action.payload.profile.sub] }),
      };

    case CONTRACTS_REQUEST:
      return {
        ...state,
        error: undefined,
        loadingContracts: true,
      };

    case CONTRACTS_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        items: List(action.result.results),
        totalResults: action.result.totalResults,
        searchSkip: action.result.skip,
        searchTake: action.result.take,
        error: undefined,
        loadingContracts: false,
      };

    case CONTRACTS_FAILURE:
      return {
        ...state,
        error: action.error,
        loadingContracts: false,
        items: List(),
      };

    case SET_CONTRACTS_SEARCH_FILTERS:
      return {
        ...state,
        searchFilters: Map(action.searchFilters),
      };

    case MERGE_CONTRACTS_SEARCH_FILTERS:
      return {
        ...state,
        // @ts-expect-error: Multiple actions with different payloads
        searchFilters: state.searchFilters.merge(action.searchFilters),
        searchSkip: 0,
      };

    case SAVED_CONTRACTS_FILTERS_SUCCESS:
      return {
        ...state,
        savedFilters: List(action.result),
      };

    case DELETE_SAVED_CONTRACTS_FILTER:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        savedFilters: state.savedFilters.delete(state.savedFilters.findIndex((f) => f.shortId === action.id)),
      };

    case ADD_SAVED_CONTRACTS_FILTER_SUCCESS:
      return {
        ...state,
        savedFilters: List(action.result),
      };

    case CONTRACTS_PDF_DOC_REQUEST:
      return {
        ...state,
        contractPdfLoading: action.id,
      };

    case CONTRACTS_PDF_DOC_SUCCESS:
      return {
        ...state,
        contractPdfLoading: undefined,
      };

    case CONTRACTS_PDF_DOC_FAILURE:
      return {
        ...state,
        contractPdfLoading: undefined,
      };

    default:
      return state;
  }
}
