import React from 'react';
import translate from 'counterpart';
import styles from './Document.scss';

interface Props {
  headerIcon: object;
  headerText: string;
  date: string;
}

export const Document = (props: Props) => {
  const { headerIcon, headerText, date } = props;
  return (
    <div className={styles.doc}>
      <div className={styles.header}>
        {headerIcon}
        <header>
          <div>
            <span className={styles.date}>{`${translate('customer.updated')} ${date}`}</span>
            <span className={styles.label}>{headerText}</span>
          </div>
        </header>
      </div>
    </div>
  );
};
