import React, { ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import translate from 'counterpart';
import { Spinner } from 'features/common/Spinner';
import { FlexColumn } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { colors } from 'styles/colors';
import { RiskLevel } from 'types/types';

interface Props {
  recommendedRiskLevel: RiskLevel;
  maximumRiskLevel: RiskLevel;
  selectedRiskLevel: RiskLevel;
  chooseRisk: (value: RiskLevel) => void;
  loadingRisk: boolean;
  reasoning: string;
  onChangeReasoning: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}

export const SelectRisk = ({
  recommendedRiskLevel,
  maximumRiskLevel,
  selectedRiskLevel,
  chooseRisk,
  loadingRisk,
  reasoning,
  onChangeReasoning,
}: Props) => {
  const allocatorRiskLevel = useSelector((state: RootState) => state.portfolioManager.investmentPlan.targetRiskLevel);
  const currentRiskLevel = useSelector((state: RootState) => state.profile.customer.toJS().selectedRiskLevel);
  const actualRiskLevel = useSelector((state: RootState) => state.risk.actualRisk);
  const loadingActualRisk = useSelector((state: RootState) => state.risk.loadingActualRisk);

  const risks = Array(10)
    .fill(1)
    .map((_i, index) => ({
      value: (index + 1) as RiskLevel,
      disabled: index + 1 > maximumRiskLevel,
    }));

  const selectedRiskIsForbidden = selectedRiskLevel > maximumRiskLevel && recommendedRiskLevel > 0;
  const selectedRiskDiffersTooMuch =
    selectedRiskLevel > 0 && Math.abs(selectedRiskLevel - recommendedRiskLevel) > 1 && !selectedRiskIsForbidden;

  const max = maximumRiskLevel;
  const rec = recommendedRiskLevel;
  const recMin = rec === 1 ? 1 : rec - 1;
  const recMax = rec === max ? rec : rec + 1;

  const riskLevelConflict = allocatorRiskLevel > 0 && selectedRiskLevel > 0 && selectedRiskLevel !== allocatorRiskLevel;

  return (
    <>
      <RiskContainer>
        {loadingRisk ? (
          <StyledSpinner message={translate('newContract.riskConfirmation.spinnerMessage')} />
        ) : (
          <>
            <b>{translate('newContract.riskConfirmation.confirmRiskLevel')}</b>

            {currentRiskLevel !== undefined && (
              <RiskLabel>
                {translate('newContract.riskConfirmation.currentRisklevel')} {currentRiskLevel}
              </RiskLabel>
            )}

            {loadingActualRisk && <Spinner size={30} position="left" />}
            {actualRiskLevel ? (
              <RiskLabel>
                {translate('newContract.riskConfirmation.actualRisklevel')} {actualRiskLevel}
              </RiskLabel>
            ) : null}

            <RiskNumbersContainer data-testkey="risk-levels">
              {risks.map((i) => (
                <RiskNumber
                  key={i.value}
                  selected={i.value === selectedRiskLevel}
                  disabled={i.disabled && recommendedRiskLevel > 0}
                  onClick={() => !i.disabled && chooseRisk(i.value)}
                  showRecommendedLevel={recommendedRiskLevel > 0}
                  isRecMin={i.value === recMin}
                  isRecMax={i.value === recMax}
                  isRec={i.value === rec && i.value > recMin && i.value < recMax}
                  data-testkey={i.value === selectedRiskLevel ? 'selected-risk' : undefined}
                >
                  {i.value}
                  {i.value === recommendedRiskLevel && (
                    <RecommendedRisk data-testkey="recommended-risk">
                      <Arrow>△</Arrow>
                      {translate('newContract.riskConfirmation.recommended')}
                    </RecommendedRisk>
                  )}
                  {i.value === allocatorRiskLevel && (
                    <PlanRisk data-testkey="plan-risk">
                      {translate('newContract.riskConfirmation.plan')}
                      <Arrow>▽</Arrow>
                    </PlanRisk>
                  )}
                </RiskNumber>
              ))}
            </RiskNumbersContainer>
          </>
        )}
      </RiskContainer>

      {!loadingRisk && riskLevelConflict && (
        <ConflictText>{translate('newContract.riskConfirmation.conflictWithAllocatorRisk')}</ConflictText>
      )}

      {selectedRiskIsForbidden && <Forbidden>{translate('newContract.riskConfirmation.riskLevelIsTooHigh')}</Forbidden>}

      {!loadingRisk && selectedRiskDiffersTooMuch && (
        <JustifyHigherRisk>
          <span>{translate('newContract.riskConfirmation.reasonsForHigherRiskLevelTitle')}*</span>
          <textarea
            rows={3}
            placeholder={translate('newContract.riskConfirmation.reasonsForHigherRiskLevelPlaceholder')}
            value={reasoning}
            onChange={onChangeReasoning}
            data-testkey="textarea-reasoning"
          />
        </JustifyHigherRisk>
      )}
    </>
  );
};

const StyledSpinner = styled(Spinner)`
  padding-top: 2rem;
`;

const lightGreen = 'hsla(145, 48%, 94%, 1)';
const recommendedGreen = 'hsla(146, 37%, 64%, 0.2)';
export const green = 'hsl(146, 37%, 64%)';

const RiskContainer = styled(FlexColumn)`
  label {
    font-size: 1.125rem;
    font-weight: bold;
  }
`;

const RiskNumbersContainer = styled.div`
  margin: 2.5rem 0 1rem;
  display: flex;
`;

interface RiskNumberProps {
  disabled: boolean;
  selected: boolean;
  showRecommendedLevel: boolean;
  isRecMin: boolean;
  isRecMax: boolean;
  isRec: boolean;
}

const RiskNumber = styled.div<RiskNumberProps>`
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid transparent;
  cursor: ${(props) => (props.disabled || props.selected ? 'default' : 'pointer')};
  border-radius: 50%;

  ${(props) =>
    !props.disabled &&
    !props.selected &&
    css`
      &:hover {
        background: ${lightGreen};
      }
    `};

  ${(props) =>
    props.disabled &&
    css`
      color: #ccc;
    `}

  ${(props) =>
    props.selected &&
    css`
      border-color: ${green};
      background: ${lightGreen};
      font-weight: 600;
    `}

  &:after {
    content: '';
    ${(props) => !props.showRecommendedLevel && 'opacity: 0'};
    position: absolute;
    height: 100%;
    top: -3px;
    left: -3px;
    right: -3px;
    bottom: -3px;
    box-sizing: content-box;
  }

  ${(props) =>
    props.isRecMin &&
    css`
      &:after {
        border: 3px solid ${recommendedGreen};
        border-radius: 50% 0 0 50%;
        border-right-width: 0;
      }
    `};
  ${(props) =>
    props.isRecMax &&
    css`
      &:after {
        border: 3px solid ${recommendedGreen};
        border-radius: 0 50% 50% 0;
        border-left-width: 0;
      }
    `};
  ${(props) =>
    props.isRec &&
    css`
      &:after {
        border: 3px solid ${recommendedGreen};
        border-radius: 0;
        border-left-width: 0;
        border-right-width: 0;
      }
    `};
`;

const SmallText = styled.div`
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
`;

const RecommendedRisk = styled(SmallText)`
  margin-top: 10px;
  top: 100%;
  color: seagreen;
`;

const Arrow = styled.div`
  line-height: 10px;
`;

const allocatorBlue = '#1f8dad';

const PlanRisk = styled(SmallText)`
  margin-bottom: 10px;
  bottom: 100%;
  color: ${allocatorBlue};
`;

const RiskLabel = styled.div`
  color: ${colors.dark_gray};
`;

const Forbidden = styled.div`
  color: red;
  margin-top: 3rem;
`;

const ConflictText = styled.div`
  margin-top: 3rem;
  margin-bottom: 1rem;
  color: ${allocatorBlue};
`;

const JustifyHigherRisk = styled.div`
  margin-top: 2rem;
`;
