import { Banker, GetCountriesResponse } from 'actions/indexActionsTypes';
import { apiCall } from 'core/apiCall';

export const getCountries = (locale: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/data/${locale}/country`,
  }) as Promise<GetCountriesResponse>;
};

export const getBankers = (token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/user`,
  }) as Promise<Banker[]>;
};
