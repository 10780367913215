export const privateRiskQuestions = {
  financialInformation: [
    'monthlyIncome',
    'monthlyExpenditure',
    'incomeFromInvestments',
    'investmentAssets',
    'otherAssets',
    'totalDebt',
  ],
  investorSurvey: [
    'valueDrop',
    'investorExperience',
    'totalInvestmentAssetsManagedByTaaleri',
    'investmentHorizon',
    'nonLiquidAssets',
    'negativePress',
    'impactOnDailyLife',
    'investmentGoals',
  ],
  suitability: [
    'suitabilityPrivateEquity',
    'suitabilityBalancedFund',
    'suitabilityLeveraged',
    'suitabilityStructuredInstrumentsNoCapitalProtection',
    'suitabilityStructuredInstrumentsCapitalProtection',
    'suitabilityEquity',
    'suitabilityCommodity',
    'suitabilityComplex',
    'suitabilityTv',
    'suitabilityFi',
    'suitabilityMoneymarket',
  ],
};

export const corporateRiskQuestions = {
  moneyLaunderingInfoCompany: [
    'operatingTurnover',
    'operatingProfit',
    'balanceSheetTotal',
    'ownCapital',
    'investmentAssets',
    'incomeFromInvestments',
    'purposeOfInvestment',
  ],
  suitabilityAssessmentCompany: [
    'tradingExperience',
    'howOftenFollowsTheMarkets',
    'howOftenMakesTradingDecisions',
    'theValueOfTradingDecisions',

    'suitabilityPrivateEquity',
    'suitabilityBalancedFund',
    'suitabilityLeveraged',
    'suitabilityStructuredInstrumentsNoCapitalProtection',
    'suitabilityStructuredInstrumentsCapitalProtection',
    'suitabilityEquity',
    'suitabilityCommodity',
    'suitabilityComplex',
    'suitabilityTv',
    'suitabilityFi',
    'suitabilityMoneymarket',

    'investmentGoals',
    'investmentHorizon',
    'totalInvestmentAssetsManagedByTaaleri',
    'lossTolerance',

    // if amount is 0 this is false
    'regularCashRequirement',
    'regularCashRequirementAccess',

    // if amount is 0 this is false
    'irregularCashRequirement',
    'irregularCashRequirementAccess',
    'nonLiquidAssets',
  ],
};

export const institutionalRiskQuestions = {
  moneyLaunderingInfoCompany: [
    'regIncomeInvestmentAssetsAmount',
    'regExpencesInvestmentAssetsAmount',
    'investmentAssets',
    'purposeOfInvestment',
  ],
  suitabilityAssessmentCompany: [
    'tradingExperience',
    'howOftenFollowsTheMarkets',
    'howOftenMakesTradingDecisions',
    'theValueOfTradingDecisions',

    'suitabilityPrivateEquity',
    'suitabilityBalancedFund',
    'suitabilityLeveraged',
    'suitabilityStructuredInstrumentsNoCapitalProtection',
    'suitabilityStructuredInstrumentsCapitalProtection',
    'suitabilityEquity',
    'suitabilityCommodity',
    'suitabilityComplex',
    'suitabilityTv',
    'suitabilityFi',
    'suitabilityMoneymarket',

    'investmentGoals',
    'investmentHorizon',
    'totalInvestmentAssetsManagedByTaaleri',
    'lossTolerance',

    // if amount is 0 this is false
    'regularCashRequirementAccess',
    'regularCashRequirement',

    // if amount is 0 this is false
    'irregularCashRequirementAccess',
    'irregularCashRequirement',
    'nonLiquidAssets',
  ],
};
