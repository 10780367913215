import React from 'react';
import styled from 'styled-components';

interface Props {
  bg: string;
}

export const Dot = ({ bg }: Props) => <DotContainer bg={bg} />;

const DotContainer = styled.div<Props>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 0 0.5rem 0 0.75rem;
  background: ${(props) => props.bg || '#bbb'};
`;
