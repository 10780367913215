import {
  Answers,
  CorporateSectionKey,
  PrivateSectionKey,
} from 'features/contracts/riskConfirmation/riskConfirmation.types';
import {
  corporateRiskQuestions,
  institutionalRiskQuestions,
  privateRiskQuestions,
} from 'features/contracts/riskConfirmation/riskConfirmationConstants';
import { Contract } from 'types/ordersState';

export const getPrivateRiskCalculationData = (allContractAnswers: Contract) => {
  const contractTemplate = allContractAnswers.sections;
  const riskQuestionSectionKeys = Object.keys(privateRiskQuestions) as PrivateSectionKey[];
  const riskCalculationData = riskQuestionSectionKeys.reduce((acc, sectionKey) => {
    const sectionQuestions = privateRiskQuestions[sectionKey];
    const section = sectionQuestions.reduce((acc, questionKey) => {
      const answerGroup = contractTemplate[sectionKey] as Answers;
      const answer = answerGroup[questionKey];
      acc[questionKey] = answer;
      return acc;
    }, {} as Answers);
    return {
      ...acc,
      ...section,
    };
  }, {});
  return riskCalculationData;
};

export const getCorporateRiskCalculationData = (allContractAnswers: Contract) => {
  const contractTemplate = allContractAnswers.sections;
  const riskQuestionSectionKeys = Object.keys(corporateRiskQuestions) as CorporateSectionKey[];
  const riskCalculationData = riskQuestionSectionKeys.reduce((acc, sectionKey) => {
    const sectionQuestions = corporateRiskQuestions[sectionKey];
    const section = sectionQuestions.reduce((acc, questionKey) => {
      const answerGroup = contractTemplate[sectionKey] as Answers;
      let answer = answerGroup[questionKey];

      if (Array.isArray(answer)) {
        answer = answer.toString();
      }
      if (questionKey === 'regularCashRequirementAccess' && acc['regularCashRequirement'] !== 0) {
        acc[questionKey] = 'Yes';
        return acc;
      } else if (questionKey === 'regularCashRequirementAccess') {
        acc[questionKey] = 'No';
        return acc;
      }
      if (questionKey === 'irregularCashRequirementAccess' && acc['irregularCashRequirement'] !== 0) {
        acc[questionKey] = 'Yes';
        return acc;
      } else if (questionKey === 'irregularCashRequirementAccess') {
        acc[questionKey] = 'No';
        return acc;
      }
      acc[questionKey] = answer;
      return acc;
    }, {} as Answers);
    return {
      ...acc,
      ...section,
    };
  }, {});
  return riskCalculationData;
};

export const getInstitutionalRiskCalculationData = (allContractAnswers: Contract) => {
  const contractTemplate = allContractAnswers.sections;
  const riskQuestionSectionKeys = Object.keys(institutionalRiskQuestions) as CorporateSectionKey[];
  const riskCalculationData = riskQuestionSectionKeys.reduce((acc, sectionKey) => {
    const sectionQuestions = institutionalRiskQuestions[sectionKey];
    const section = sectionQuestions.reduce((acc, questionKey) => {
      const answerGroup = contractTemplate[sectionKey] as Answers;
      let answer = answerGroup[questionKey];
      if (Array.isArray(answer)) {
        answer = answer.toString();
      }
      if (questionKey === 'regularCashRequirementAccess' && acc['regularCashRequirement'] !== 0) {
        acc[questionKey] = 'Yes';
        return acc;
      } else if (questionKey === 'regularCashRequirementAccess') {
        acc[questionKey] = 'No';
        return acc;
      }
      if (questionKey === 'irregularCashRequirementAccess' && acc['irregularCashRequirement'] !== 0) {
        acc[questionKey] = 'Yes';
        return acc;
      } else if (questionKey === 'irregularCashRequirementAccess') {
        acc[questionKey] = 'No';
        return acc;
      }
      acc[questionKey] = answer;
      return acc;
    }, {} as Answers);
    return {
      ...acc,
      ...section,
    };
  }, {});
  return riskCalculationData;
};
