import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ASSET_CLASS_COLORS } from 'styles/colors';
import { AssetCategoryName, AssetClassId } from 'types/types';
import { AssetClassWithWeight } from 'types/weightsState';
import { getAssetCategoryForAssetClass } from 'features/allocator/instruments/instrumentsUtils';
import { InstrumentWithWeight } from 'types/instrumentsState';
import { selectAssetClasses } from 'features/allocator/allocatorSelectors';

interface Props {
  items: (AssetClassWithWeight | InstrumentWithWeight)[];
  totalWeight: number;
  assetCategory: AssetCategoryName;
}

export const BarChart = ({ items, totalWeight, assetCategory }: Props) => {
  const assetClasses = useSelector(selectAssetClasses);
  const assetClassesOrder = assetClasses.map((i) => i.assetClassId);
  const requiredAssetClasses = assetClasses.filter(
    (a) => getAssetCategoryForAssetClass(a.assetClassId) === assetCategory
  );

  const sortedAssetClasses = requiredAssetClasses
    .map(
      (assetClass) =>
        items.find((i) => i.assetClassId === assetClass.assetClassId) || {
          weight: 0,
          assetClassId: assetClass.assetClassId,
        }
    )
    .sort((a, b) => assetClassesOrder.indexOf(a.assetClassId) - assetClassesOrder.indexOf(b.assetClassId));

  return (
    <AssetTypeSection weight={totalWeight}>
      {sortedAssetClasses.map((item, index) => (
        <AssetClassSection
          key={index}
          weight={!totalWeight ? 0 : (item?.weight || 0) / totalWeight}
          assetClassId={item.assetClassId}
        />
      ))}
    </AssetTypeSection>
  );
};

interface StyledAssetTypeSectionProps {
  weight: number;
}

const AssetTypeSection = styled.div.attrs<StyledAssetTypeSectionProps>((props) => ({
  style: {
    width: `calc(${props.weight * 100}%)`,
  },
}))<StyledAssetTypeSectionProps>`
  display: flex;
  transition: width 0.35s;
  box-sizing: content-box;
  border-radius: 4px;
  overflow: hidden;
  margin-right: ${(props) => (props.weight === 0 ? '0' : '5px')};
`;

interface StyledAssetClassSectionProps {
  weight: number;
  assetClassId: AssetClassId;
}

const AssetClassSection = styled.div.attrs<StyledAssetClassSectionProps>((props) => ({
  style: {
    width: `${Math.ceil(props.weight * 100)}%`,
    background: ASSET_CLASS_COLORS[props.assetClassId],
  },
}))<StyledAssetClassSectionProps>`
  transition: width 0.35s;
`;
