import React from 'react';
import classnames from 'classnames';
import { SelectFee } from './SelectFee';
import { Volume } from './Volume';
import { Leverage } from './Leverage';
import styles from './StructuredProductDetails.scss';
import { CapitalCall } from './CapitalCall';
import { ValidationErrorValue, TouchedFields } from 'types/orderDialogState';
import { SelectValue } from 'types/types';
import { PortfolioById } from 'types/portfolioState';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  assignment: OrderLineType;
  touchedFields: TouchedFields;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currentFee?: number;
  onFeeChange: (value: SelectValue) => void;
  isFetchingFee: boolean;
  defaultFee: number;
  privateEquityMaxSum: number;
  structuredStep: number;
  structuredMinimumSum: number;
  getError: (key: string) => ValidationErrorValue | undefined;
  selectedPortfolio: PortfolioById;
  isAppropriatenessTestOk: boolean;
}

export const PrivateEquityDetails = ({
  assignment,
  touchedFields,
  onChange,
  currentFee,
  onFeeChange,
  isFetchingFee,
  defaultFee,
  privateEquityMaxSum,
  structuredStep,
  structuredMinimumSum,
  getError,
  selectedPortfolio,
  isAppropriatenessTestOk,
}: Props) => {
  const dispatch = useAppDispatch();

  const onLeverageChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditorValue('isLeveraged', _event.target.checked));
  };

  const textFieldProps = {
    label:
      structuredMinimumSum && structuredStep
        ? `Nimellisarvo, min: ${structuredMinimumSum}, max: ${privateEquityMaxSum || '-'} littera: ${structuredStep}`
        : 'Nimellisarvo',
    defaultValue: `00,00 ${assignment.currency || ''}`,
  };

  return (
    <div>
      <div className="row" key="volume">
        <div className={'columns small-4'}>
          <SelectFee
            currentFee={currentFee}
            onFeeChange={onFeeChange}
            isFetchingFee={isFetchingFee}
            defaultFee={defaultFee}
            isLocked={true}
          />
        </div>
        <div className={'columns small-8'}>
          <Volume
            error={getError('subscriptionSum')}
            touched={touchedFields.subscriptionSum}
            value={assignment.subscriptionSum}
            onChange={onChange}
            textFieldProps={textFieldProps}
            data-testkey={'order-volume-price'}
          />
        </div>
      </div>
      <div className="row">
        <div className={classnames(styles.verticalCenter, 'columns small-6 small-offset-6')}>
          <Leverage onLeverageChange={onLeverageChange} value={assignment.isLeveraged} />
        </div>
      </div>
      {isAppropriatenessTestOk ? (
        <div className="row">
          <div className="columns small-6 small-offset-6" style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
            Asianmukaisuusarviointi <i className="icon icon-check" />
          </div>
        </div>
      ) : undefined}
      <CapitalCall assignment={assignment} isInsurancePortfolio={selectedPortfolio.isInsurancePortfolio} />
    </div>
  );
};
