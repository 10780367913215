import React from 'react';
import unionBy from 'lodash/unionBy';
import translate from 'counterpart';
import { getFees } from 'core/portfolios';
import { SelectWithLabel } from './SelectWithLabel';
import { SelectValue } from 'types/types';

interface Props {
  currentFee?: number;
  onFeeChange: (value: SelectValue) => void;
  isFetchingFee: boolean;
  defaultFee: number;
  isLocked?: boolean;
  focusRef?: React.MutableRefObject<HTMLElement | undefined>;
}

export const SelectFee = ({
  currentFee,
  onFeeChange,
  isFetchingFee,
  defaultFee,
  isLocked = false,
  focusRef,
}: Props) => {
  const enableDefaultFeeItem = typeof defaultFee !== 'undefined' && defaultFee !== null && !isFetchingFee;
  let defaultFeeText;

  if (isFetchingFee) {
    defaultFeeText = 'Haetaan oletuspalkkiota...';
  } else if (typeof defaultFee !== 'undefined' && defaultFee !== null) {
    defaultFeeText = `${defaultFee * 100} % Oletuspalkkio`;
  } else {
    defaultFeeText = 'Oletuspalkkiota ei löydy';
  }

  const defaultFeeItem = [
    {
      value: typeof defaultFee === 'number' ? defaultFee * 100 : undefined,
      label: defaultFeeText,
      disabled: !enableDefaultFeeItem,
    },
  ];

  let fees;
  let otherFees;
  if (isLocked && typeof defaultFeeItem[0].value !== 'undefined') {
    fees = defaultFeeItem;
  } else if (typeof defaultFeeItem[0].value !== 'undefined') {
    otherFees = getFees(defaultFeeItem[0].value).map((f) => ({
      value: f,
      label: `${f} %`,
      disabled: !enableDefaultFeeItem,
    }));
    fees = unionBy(defaultFeeItem, otherFees, (item) => item.value);
  } else {
    otherFees = {
      value: 0,
      label: `0 %`,
      disabled: !enableDefaultFeeItem,
    };
    fees = defaultFeeItem.concat(otherFees);
  }

  return (
    <SelectWithLabel
      options={fees}
      onChange={onFeeChange}
      value={currentFee}
      label={translate('order.fee')}
      placeholder="Valitse..."
      data-testkey="order-fee"
      focusRef={focusRef}
    />
  );
};
