import PropTypes from 'prop-types';
import React from 'react';
import translate from 'counterpart';
import { Set } from 'immutable';
import SubNav from './SubNav';
import { Locked } from './Locked';
import styles from './Overview.scss';
import ContractItem from './ContractItem';
import { ImmutableTemplates, Template } from 'types/ordersState';
import { ImmutableContent } from 'types/profileState';
import { ImmutableFormErrors, Locale } from 'types/contractsState';
import { ATTACHMENT_TEMPLATE_ID, SUITABILITY_TEMPLATE_ID } from 'features/contracts/contractsConstants';

interface Props {
  params: { id: string };
  templates: ImmutableTemplates;
  values: ImmutableContent;
  isReady: boolean;
  formTouched: Set<string>;
  formErrors: ImmutableFormErrors;
  activeContract: string;
  setActiveContract: (contract: string) => void;
  contractsCompleted: boolean;
  contractLanguage: string;
}

export default class Overview extends React.Component<Props> {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  handleNext = () => {
    const { params } = this.props;
    const nextTo = `customer/${params.id}/contracts/preview`;
    this.context.router.push(nextTo);
  };

  render() {
    const { templates, isReady, contractsCompleted, contractLanguage, ...rest } = this.props;

    if (isReady) {
      return <Locked />;
    }

    const contracts = templates.valueSeq().map((contract, key) => {
      if (contract?.contractType === ATTACHMENT_TEMPLATE_ID) {
        return null;
      }
      return (
        <ContractItem
          key={key}
          contract={contract as Template}
          contractLanguage={contractLanguage as Locale}
          {...rest}
          isSuitability={contract?.contractType === SUITABILITY_TEMPLATE_ID}
        />
      );
    });

    const label = '2. ' + translate('newContract.overview.title', { locale: contractLanguage });

    return (
      <section className={styles.overview}>
        <SubNav
          label={label}
          onNextClick={this.handleNext}
          actionLabel={contractsCompleted ? translate('contract.continue') : translate('contract.preview')}
        />
        <section>{contracts.size > 0 ? contracts : undefined}</section>
      </section>
    );
  }
}
