import React from 'react';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { PlanLength } from 'types/types';
import { Card } from 'features/allocator/common/Card';
import { isConsideredPortfolio, isIgnoredPortfolio } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { Spinner } from 'features/common/Spinner';
import { isLoadingPlan, isOptimizingCurrent } from 'features/allocator/allocatorSelectors';
import { Portfolio } from 'features/portfolioManager/components/Portfolio';
import { Grid } from 'features/allocator/common/Grid';
import { weightTypes } from 'constants/allocator';
import { AllocatorPortfolio } from 'types/investmentPlanState';

interface Props {
  planLength: PlanLength;
  portfolios: AllocatorPortfolio[];
}

export const ExistingPortfolios = ({ planLength, portfolios }: Props) => {
  const sortedPortfolios = sortBy(portfolios, (i) => i.portfolioId);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const loadingPlan = useSelector(isLoadingPlan);

  if (optimizingCurrent || loadingPlan) {
    return (
      <Card>
        <Spinner />
      </Card>
    );
  }

  return (
    <Grid vertical>
      {sortedPortfolios.map((portfolio) => {
        const noCompare = isIgnoredPortfolio(portfolio) || isConsideredPortfolio(portfolio);

        return (
          <Portfolio
            key={portfolio.portfolioId}
            planLength={planLength}
            portfolio={portfolio}
            weightsToUse={weightTypes.optimizedWeights}
            compareTo={noCompare ? 'none' : weightTypes.currentWeights}
          />
        );
      })}
    </Grid>
  );
};
