import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import styled from 'styled-components';
import translate from 'counterpart';
import { selectHasPositions, selectPlanState } from 'features/allocator/allocatorSelectors';
import { DEMO_CUSTOMER } from 'constants/common';
import { styles } from 'features/common/StyledComponents';

interface Props {
  customerId: string;
}

export const LinkBack = ({ customerId }: Props) => {
  const planState = useSelector(selectPlanState);
  const hasPositions = useSelector(selectHasPositions);

  if (customerId === DEMO_CUSTOMER) {
    return (
      <StyledLinkBack data-testkey="exit-allocator" to={'/'}>
        {translate('allocator.header.exit')}
      </StyledLinkBack>
    );
  }

  const newPlan = (planState === 'unlockedPlan' || planState === 'noPlan') && !hasPositions;
  const backLink = !hasPositions ? `customer/${customerId}` : `customer/${customerId}/portfoliomanager`;

  return (
    <StyledLinkBack data-testkey="exit-allocator" to={backLink}>
      {newPlan ? translate('allocator.header.backToProfile') : translate('allocator.header.exit')}
    </StyledLinkBack>
  );
};

const StyledLinkBack = styled(Link)`
  margin-right: 1rem;
  ${styles.link}
`;
