import React from 'react';
import translate from 'counterpart';
import StyledTextField from 'features/common/styledComponents/StyledTextField';
import { ValidationErrorValue } from 'types/orderDialogState';

interface Props {
  error: ValidationErrorValue | undefined;
  touched: boolean | undefined;
  value: number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  currency: string;
  defaultUnitFee: number;
}

export const UnitFee = ({ error, touched = false, value, onChange, currency, defaultUnitFee = 0 }: Props) => (
  <StyledTextField
    id={'unitFeeField'}
    key={'unitFeeField'}
    label={currency ? `Palkkio (${currency}/kpl), max: ${defaultUnitFee}` : 'Palkkio'}
    defaultValue={`00,00 ${currency}`}
    inputProps={{
      min: 0,
      max: defaultUnitFee,
      display: 'block',
    }}
    autoComplete="off"
    fullWidth={true}
    type={'number'}
    name={`number-${1}`}
    onChange={onChange}
    value={typeof value === 'undefined' || value === null ? '' : value}
    helperText={error && touched ? translate(`validations.${error.message}`) : undefined}
    variant="standard"
    sx={[
      {
        '&.MuiFormControl-root': {
          marginTop: '25px',
        },
        '& .Mui-focused': {
          '&::after': {
            left: 0,
          },
        },
        '& .MuiInputBase-root': {
          marginTop: '14px',
          marginBottom: '15px',
          paddingLeft: 0,
          '&::before': {
            left: 0,
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Calibre, Roboto, sans-serif',
          marginTop: 0,
        },
        '& label': {
          fontSize: '18px',
          fontWeight: '400',
          '&.Mui-focused': {
            fontSize: '18px',
          },
        },
        '& > p': {
          '&.MuiFormHelperText-root': {
            paddingLeft: '0',
          },
        },
        '&.Mui-error': {
          borderBottomColor: '#d32f2f',
          borderWidth: '2px',
          '&::before': {
            borderWidth: '2px',
            borderBottomColor: '#d32f2f',
          },
        },
      },
    ]}
  />
);
