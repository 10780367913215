import Promise from 'bluebird';
import { apiCall } from 'core/apiCall';
import { PromiseType } from 'types/types';

type PromiseStoreProps = {
  [key in PromiseType]: Promise<unknown>;
};

class PromiseStore implements PromiseStoreProps {
  search: Promise<unknown>;
  reoptimize: Promise<unknown>;
  optimize: Promise<unknown>;
  optimizeCurrent: Promise<unknown>;
  getBankersCustomers: Promise<unknown>;
  getBankersCustomersBatch: Promise<unknown>;
  getPositions: Promise<unknown>;
  getPortfolios: Promise<unknown>;
  getInvestmentPlan: Promise<unknown>;

  constructor() {
    this.search = Promise.resolve();
    this.reoptimize = Promise.resolve();
    this.optimize = Promise.resolve();
    this.optimizeCurrent = Promise.resolve();
    this.getBankersCustomers = Promise.resolve();
    this.getBankersCustomersBatch = Promise.resolve();
    this.getPositions = Promise.resolve();
    this.getPortfolios = Promise.resolve();
    this.getInvestmentPlan = Promise.resolve();
  }

  createCancellableRequest(url: string, token: string) {
    return apiCall({
      method: 'get',
      path: url,
      token,
    });
  }
}

const instance = new PromiseStore();
export default instance;
