import _ from 'lodash';
import translate from 'counterpart';
import { createSelector } from 'reselect';
import {
  BASIS_TYPE,
  MINUTES_OF_INVESTMENT_SERVICE,
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
} from 'constants/orderFields';
import { doNotAskMoneySourceWhenBuy } from 'constants/instrumentFormClassifications';
import { INITIALIZER_BANKER } from 'constants/receiveInfoOptions';
import { FULL_POWER_OF_ATTORNEY } from 'constants/contractNames';
import { BUY } from 'constants/sides';
import {
  selectCurrentPortfolio,
  selectPortfolioContractName,
  selectHasRestrictedPortfolioClass,
} from 'features/portfolio/portfolioSelectors';
import {
  canBuyStructuredProducts,
  filterFormOptionsBasedOnOrderlines,
  getFormOptions,
  getSides,
  isListed,
} from 'core/portfolios';
import {
  selectCurrentInstrumentFormByOrderLineId,
  selectCurrentSideByOrderLineId,
  selectOrderLines,
} from 'features/orderLines/orderLinesSelectors';
import { selectIsCustomerIncompetent, selectCustomerCategorisation } from 'features/profile/profileSelectors';
import { GIFT } from 'constants/fundsOrigin';
import { FUND } from 'constants/instrumentForms';
import { RootState } from 'types/rootState';
import { InstrumentOption } from './orderDialogTypes';
import { OrderLineType, Position } from 'types/ordersState';

export const selectEditor = (state: RootState) => state.orderDialog.editor;

export const selectTouchedFields = (state: RootState) => state.orderDialog.touchedFields;

const selectShareOrdersAllowed = (state: RootState) => {
  const initializerType = state.orderDialog.editor.get(ORDER_INITIALIZER_TYPE);
  const contractName = _.get(
    state.portfolio.portfolioDetailsById,
    [state.orderDialog.editor.get('portfolioId'), 'contractName'],
    ''
  );

  return initializerType !== INITIALIZER_BANKER || contractName === (FULL_POWER_OF_ATTORNEY as string);
};

const selectIsCurrentProductNonUcits = (state: RootState) =>
  state.orderDialog.editor.get('legalForm', '')?.toLowerCase().includes('non-ucits');

// export removed because AMLT-4923 -> FundsOrigin (or any other component) does not use this anymore
//export const selectAskFundsOrigin = createSelector(
const selectAskFundsOrigin = createSelector(
  selectIsCustomerIncompetent,
  selectIsCurrentProductNonUcits,
  (isIncompetent, isNonUcits) => isIncompetent && isNonUcits
);

const selectIsFundsOriginValid = createSelector(
  selectEditor,
  selectAskFundsOrigin,
  (editor, requireFundsOrigin) =>
    editor.get('financialInstrumentForm') !== FUND || !requireFundsOrigin || editor.get('fundsOrigin') === GIFT
);

//check performed in order dialog, before we can confirm order
export const selectIsOrderValid = (state: RootState) => {
  const { isFetchingFee, editor, validationErrors, suitabilityErrors } = state.orderDialog;

  const form = editor.get('financialInstrumentForm');
  const side = editor.get('side');
  const notListed = !isListed(form, side);
  const shareOrdersAllowed = selectShareOrdersAllowed(state);
  const isFundsOriginValid = selectIsFundsOriginValid(state);

  return (
    !isFetchingFee &&
    _.isEmpty(suitabilityErrors) &&
    _.isEmpty(validationErrors) &&
    (notListed || shareOrdersAllowed) &&
    isFundsOriginValid
  );
};

const selectHasEmission = (state: RootState) => state.orderDialog.hasEmission;

const selectIsStructuredProductsAllowed = createSelector(selectCurrentPortfolio, (selectedPortfolio) =>
  canBuyStructuredProducts(
    selectedPortfolio.contractName,
    selectedPortfolio.isInsurancePortfolio,
    selectedPortfolio.strategyName
  )
);

const selectBasisType = (state: RootState) => {
  return state.orderDialog.editor.get(BASIS_TYPE);
};
const selectReceiveType = (state: RootState) => {
  return state.orderDialog.editor.get(RECEIVED_FROM_CLIENT_METHOD);
};

export const selectInstrumentFormOptions = (state: RootState, orderLineId: number) => {
  const currentInstrumentForm = selectCurrentInstrumentFormByOrderLineId(state, orderLineId);
  const isStructuredProductsAllowed = selectIsStructuredProductsAllowed(state);
  const portfolioContractName = selectPortfolioContractName(state);
  const customerCategorisation = selectCustomerCategorisation(state);
  const orderLines = selectOrderLines(state).toJS();
  const receiveType = selectReceiveType(state);
  const basisType = selectBasisType(state);

  const formOptions = getFormOptions(
    currentInstrumentForm,
    isStructuredProductsAllowed,
    portfolioContractName,
    customerCategorisation,
    receiveType,
    basisType
  );

  const filteredFormOptions = filterFormOptionsBasedOnOrderlines(formOptions, orderLines, portfolioContractName);

  const mappedFormOptions = filteredFormOptions.map((form) => ({
    value: form,
    label: translate(`order.${form}`),
  }));

  return mappedFormOptions;
};

export const selectSideOptions = (state: RootState, orderLineId: number) => {
  const currentInstrumentForm = selectCurrentInstrumentFormByOrderLineId(state, orderLineId);
  const currentSide = selectCurrentSideByOrderLineId(state, orderLineId);
  const selectedPortfolio = selectCurrentPortfolio(state);
  const editor = selectEditor(state);
  const hasEmission = selectHasEmission(state);
  const hasRestrictedPortfolioClass = selectHasRestrictedPortfolioClass(state);

  let isSuitable = editor.get('isSuitable');
  // check if there is a suitability error and it is AppropriatenessRule = enable all sides (kind of suitable)
  const suitabilityErrorPossiblyAppropriateness =
    state.orderDialog.suitabilityErrors && state.orderDialog.suitabilityErrors.length === 1
      ? state.orderDialog.suitabilityErrors[0]
      : null;
  if (suitabilityErrorPossiblyAppropriateness) {
    if (suitabilityErrorPossiblyAppropriateness.ruleId === 'AppropriatenessRule') {
      const instrumentOptionsAllowsAlways = state.orderDialog.instrumentOptions.find(
        (x) => x.financialInstrumentId === suitabilityErrorPossiblyAppropriateness.financialInstrumentId
      );
      if (instrumentOptionsAllowsAlways) {
        isSuitable = true; //instrumentOptionsAllowsAlways.alwaysEnable;
      }
    }
  }
  const sides = getSides(
    currentInstrumentForm,
    currentSide,
    selectedPortfolio.contractName,
    editor.get('instrumentForm'),
    hasEmission,
    isSuitable,
    hasRestrictedPortfolioClass
  );
  return sides;
};

export const selectShowMoneySourceForBuyOrder = (state: RootState) => {
  const { editor } = state.orderDialog;
  const side = editor.get('side');
  const financialInstrumentForm = editor.get('financialInstrumentForm');

  return !(side === BUY && doNotAskMoneySourceWhenBuy.includes(financialInstrumentForm as string));
};

//if instrument already exists in order line, select from there because instrumentOptions can be empty
export const selectInstrumentGroupsByFinancialInstrumentId = (
  state: RootState,
  financialInstrumentId: string
): string[] | undefined => {
  const orderLines = state.orderLines.orderLines.toJS();
  const { instrumentOptions } = state.orderDialog;
  let groups: string[] | undefined = [];

  if (orderLines.length) {
    groups = orderLines?.find((line: OrderLineType) => line.financialInstrumentId === financialInstrumentId)
      ?.financialInstrument.instrumentGroups;
  }
  if (groups && groups.length) {
    return groups;
  } else {
    groups = instrumentOptions?.find(
      (e: InstrumentOption) => e.financialInstrumentId === financialInstrumentId
    )?.instrumentGroups;
    return groups;
  }
};

export const selectInstrumentPosition = (
  state: RootState,
  financialInstrumentId: string,
  portfolioId: string
): Position | undefined => {
  const position = state.portfolio?.positions?.find(
    (e) =>
      e.financialInstrument.financialInstrumentId === financialInstrumentId && e.portfolio.portfolioId === portfolioId
  );
  return position;
};

export const selectHasPreviousMOIS = (state: RootState): boolean => {
  const orderLines = state.orderLines?.orderLines;
  if (!orderLines) {
    return false;
  }
  const previousAssignment = orderLines.get(orderLines.size - 1);
  if (!previousAssignment) {
    return false;
  }
  const previousAssigmentMois = previousAssignment.get(MINUTES_OF_INVESTMENT_SERVICE);
  if (!previousAssigmentMois) {
    return false;
  }
  return true;
};
