import { xor } from 'lodash';
import { AssetClassWeight, PortfolioInstrument } from 'types/instrumentsState';
import { AssetClassId, InstrumentCategoryId, AssetCategoryName } from 'types/types';
import { ASSET_CATEGORIES } from 'constants/allocator';

export const getPrefix = (string: AssetClassId): InstrumentCategoryId => {
  const returnValue = string.indexOf('_') > -1 ? string.substring(0, string.indexOf('_')) : string;
  return returnValue as InstrumentCategoryId;
};

export const isSameInstrumentSelection = (a1: PortfolioInstrument[], a2: PortfolioInstrument[]): boolean =>
  isEqualPrimitiveArrays(
    a1.map((e) => `${e.security}${e.portfolioId}`),
    a2.map((i) => `${i.security}${i.portfolioId}`)
  );

const isEqualPrimitiveArrays = (a1: string[], a2: string[]) => a1 && a2 && xor(a1, a2).length < 1;

export const getAssetCategoryForAssetClass = (assetClassId: AssetClassId | undefined): AssetCategoryName => {
  if (!assetClassId || assetClassId === 'Tuntematon') {
    return 'Tuntematon';
  }
  if (assetClassId === 'CASH') {
    return 'Muut';
  }

  const prefix = getPrefix(assetClassId);
  if (assetClassId === prefix) {
    return ASSET_CATEGORIES[assetClassId];
  }
  return ASSET_CATEGORIES[prefix];
};

export const getAssetClassId = (assetClasses: AssetClassWeight[]): AssetClassId => {
  if (!assetClasses || assetClasses.length === 0) {
    return 'Tuntematon';
  }

  if (assetClasses?.length === 1) {
    return assetClasses[0].assetClassId;
  }

  const samePrefix = assetClasses?.every(
    (assetClass) => getPrefix(assetClass.assetClassId) === getPrefix(assetClasses[0].assetClassId)
  );
  if (samePrefix) {
    return assetClasses[0].assetClassId;
  }

  return 'ALLOC';
};
