export const ORDERS_REQUEST = 'ORDERS_REQUEST';
export const ORDERS_SUCCESS = 'ORDERS_SUCCESS';
export const ORDERS_FAILURE = 'ORDERS_FAILURE';
export const SET_ORDERS_SEARCH_FILTERS = 'SET_ORDERS_SEARCH_FILTERS';
export const MERGE_ORDERS_SEARCH_FILTERS = 'MERGE_ORDERS_SEARCH_FILTERS';
export const SAVED_ORDERS_FILTERS_SUCCESS = 'SAVED_ORDERS_FILTERS_SUCCESS';
export const ADD_SAVED_ORDERS_FILTER_SUCCESS = 'ADD_SAVED_ORDERS_FILTER_SUCCESS';
export const DELETE_SAVED_ORDERS_FILTER = 'DELETE_SAVED_ORDERS_FILTER';
export const ORDERS_PDF_DOC_REQUEST = 'ORDERS_PDF_DOC_REQUEST';
export const ORDERS_PDF_DOC_SUCCESS = 'ORDERS_PDF_DOC_SUCCESS';
export const ORDERS_PDF_DOC_FAILURE = 'ORDERS_PDF_DOC_FAILURE';
