import React from 'react';
import { map } from 'lodash';
import { Table, TableBody, TableHead, TableCell, TableRow } from '@mui/material';
import translate from 'counterpart';
import { DailyRow } from './DailyRow';
import { tableHeaderStyles as headStyles } from 'styles/material-ui/material-ui';
import { MarketInfoByPortfolio, PortfolioDetailsById } from 'types/portfolioState';

interface Props {
  portfolioDetailsById: PortfolioDetailsById;
  marketInfoByPortfolio: MarketInfoByPortfolio;
}

export const Daily = ({ portfolioDetailsById, marketInfoByPortfolio }: Props) => {
  const headStyle = {
    ...headStyles,
    fontFamily: 'Calibre',
    fontSize: 15,
    fontWeight: 500,
    textTransform: 'none',
  } as React.CSSProperties;
  return (
    <Table style={{ paddingLeft: 0, tableLayout: 'auto' }}>
      <TableHead style={{ paddingLeft: 0 }}>
        <TableRow style={{ fontSize: 11, height: 35 }}>
          <TableCell style={{ ...headStyle, textAlign: 'left' }}>{translate(`order.id`)}</TableCell>
          <TableCell style={{ ...headStyle, textAlign: 'left' }}>{translate(`order.portfolioName`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.purchaseValue`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.marketValue`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.marketValueChange`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.withdravalBalance`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.marketValueOfMoneyPositions`)}</TableCell>
          <TableCell style={headStyle}>{translate(`order.currency`)}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody style={{ paddingLeft: 0 }}>
        {map(portfolioDetailsById, (p) => {
          return <DailyRow key={p.portfolioId} portfolio={p} marketInfo={marketInfoByPortfolio[p.portfolioId]} />;
        })}
      </TableBody>
    </Table>
  );
};
