import _ from 'lodash';
import translate from 'counterpart';
import moment from 'moment';
import tv4 from 'tv4';
import { SELL, EXCHANGE, BUY } from 'constants/sides';
import {
  STRUCTURED_PRODUCT,
  PRIVATE_EQUITY,
  CO_INVESTMENTS,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
} from 'constants/instrumentForms';
import { crmPersonFields } from 'constants/crmPersonFields';
import { validateFormValues, getErrorMessage, getErrorObject } from './index';
import { buyShareSchema } from 'schemas/buyShare';
import { sellShareSchema } from 'schemas/sellShare';
import { tradeFundSchema } from 'schemas/tradeFund';
import {
  BASIS_TYPE,
  EXPIRE_DATE,
  ORDER_FINANCING_BUY,
  ORDER_FINANCING_SELL,
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_DATE,
  RECEIVED_FROM_CLIENT_METHOD,
  REPRESENTATIVE_NAME,
  REPRESENTATIVE_SSN,
} from 'constants/orderFields';
import { CAPITAL_CALL_BY_EMAIL, CLIENT, REPRESENTATIVE } from 'constants/receiveInfoOptions';
import { buyWarrantSchema } from 'schemas/buyWarrant';
import { buyStructuredSchema } from 'schemas/buyStructured';
import { requireCapitalCall } from './portfolios';
import { doNotAskMoneySourceWhenBuy } from 'constants/instrumentFormClassifications';
import { Signatory, FoundPerson, Note, OrderLineType, MinutesOfInvestmentServiceType } from 'types/ordersState';
import { ValidationErrorValue } from 'types/orderDialogState';
import { DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { Schema } from 'types/contractsState';
import { FUND_SELL_LIMIT } from 'constants/allocator';
import { minutesOfInvestmentServiceSchema } from 'schemas/minutesOfInvestmentService';

const SSN_REGEX = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d{2})(\+|-|A|B|C|D|E|F|Y|X|W|V|U)(\d{3})(\d|[A-FHJ-NPR-Y])$/;
const EMAIL_REGEX =
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i;
const PHONE_REGEX =
  /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

function isValid(input: string | undefined, regex: RegExp) {
  if (input) {
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    return input.match(regex) !== null;
  }
  return false;
}

export function isValidSSN(ssn: string) {
  return isValid(ssn, SSN_REGEX);
}

export function isValidEmail(email: string | undefined) {
  return isValid(email, EMAIL_REGEX);
}

export function isEmpty(input: string) {
  if (input) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return !input.length > 0;
  }
  return true;
}

export function isValidPhone(phone: string) {
  return isValid(phone, PHONE_REGEX);
}

export function isValidSignatory(signatory: Signatory) {
  if (!signatory) {
    return false;
  }
  if (isEmpty(signatory.firstName)) {
    return false;
  }
  if (isEmpty(signatory.lastName)) {
    return false;
  }
  if (!isValidSSN(signatory.ssn)) {
    return false;
  }
  if (!isValidEmail(signatory.email)) {
    return false;
  }
  if (!isValidPhone(signatory.phone)) {
    return false;
  }
  return true;
}

export function isStructuredSubscriptionSumValid(value: number, minimum: number, step: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (minimum !== '' && minimum >= 0 && value < minimum) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (minimum !== '' && minimum >= 0 && step !== '' && step >= 0 && (value - minimum) % step !== 0) {
    return false;
  }
  return true;
}

export function isPrivateEquitySubscriptionSumValid(value: number, minimum: number, step: number, maximum: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (minimum !== '' && minimum >= 0 && value < minimum) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (maximum !== '' && maximum > 0 && value > maximum) {
    return false;
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (minimum !== '' && minimum >= 0 && step !== '' && step >= 0 && (value - minimum) % step !== 0) {
    return false;
  }
  return true;
}

const isDatePast = (date: string) => moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'));

const isDateInFuture = (date: string) => moment(date, 'YYYY-MM-DDTHH:mm').isAfter(moment().format('YYYY-MM-DDTHH:mm'));

export const getInvalidCrmPersonFields = (fields: FoundPerson): string => {
  const invalidFields = crmPersonFields.filter((f) => !fields[f]);
  if (fields.email && !isValidEmail(fields.email)) {
    invalidFields.push('email');
  }
  if (fields.ssn && !isValidSSN(fields.ssn)) {
    invalidFields.push('ssn');
  }
  if (fields.phoneNumber && !isValidPhone(fields.phoneNumber)) {
    invalidFields.push('phoneNumber');
  }
  return invalidFields.map((m) => translate(`newContract.${m}`)).join(',');
};

export function validateShareOrder(order: OrderLineType) {
  const errors = [];
  if (
    order.amount >= 10000 ||
    (order.quantity > 0 && !order.marketPriceBase) ||
    (order.quantity > 0 && order.quantity * order.marketPriceBase >= 10000)
  ) {
    if (order.side === BUY && !order[ORDER_FINANCING_BUY]) {
      errors.push({
        key: ORDER_FINANCING_BUY,
        message: getErrorMessage({ code: 302 }),
      });
    } else if (order.side === SELL && !order[ORDER_FINANCING_SELL]) {
      errors.push({
        key: ORDER_FINANCING_SELL,
        message: getErrorMessage({ code: 'general' }),
      });
    }
  }
  if (isDatePast(order[EXPIRE_DATE])) {
    errors.push({
      key: EXPIRE_DATE,
      message: getErrorMessage({ code: 101 }),
    });
  }
  return errors;
}

export function getShareOrderErrors(order: OrderLineType): ValidationErrorValue[] {
  const schema = order.side === SELL ? sellShareSchema : buyShareSchema;
  const shareErrors = validateShareOrder(order);
  const formErrors = validateFormValues(order, schema);
  const orderReceiveInfoErrors = validateOrderReceiveInfo(order);

  const errors = [...shareErrors, ...formErrors, ...orderReceiveInfoErrors];
  return errors;
}

export function getMinutesOfInvestmentServiceErrors(mois: MinutesOfInvestmentServiceType): ValidationErrorValue[] {
  const schema = minutesOfInvestmentServiceSchema;
  const result = tv4.validateMultiple(mois, schema);
  const errors: ValidationErrorValue[] = [];
  if (!result.valid) {
    result.errors.forEach((error) => {
      const errObj = getErrorObject(error);
      if (errObj.key) {
        errors.push(errObj);
      } else if (error.subErrors && error.subErrors.length > 0) {
        error.subErrors.forEach((e) => errors.push(getErrorObject(e)));
      } else if (!errObj.key && errObj.message) {
        errors.push(errObj);
      }
    });
  }
  return errors;
}

function validateFundOrder(order: OrderLineType, defaultAccount: DefaultAccount) {
  const {
    side,
    marketValueNet,
    currentQuantity,
    amount,
    quantity,
    moneySource,
    receiverEmail,
    financialInstrumentForm,
  } = order;

  const errors = [];
  const maxAmount = (marketValueNet ?? 0) * FUND_SELL_LIMIT;

  if ((side === SELL || side === EXCHANGE) && amount && amount > maxAmount) {
    errors.push({
      key: 'subscriptionSum',
      message: 'biggerThanNetAmount',
    });
  }

  if ((side === SELL || side === EXCHANGE) && quantity && quantity > (currentQuantity ?? 0)) {
    errors.push({
      key: 'subscriptionSum',
      message: 'biggerThanNetQuantity',
    });
  }

  if (side !== SELL && !isValidEmail(receiverEmail) && (!defaultAccount || defaultAccount.accountType === '03')) {
    errors.push({
      key: 'receiverEmail',
      message: getErrorMessage({ code: 202 }),
    });
  }

  if (!moneySource && order.side === BUY && !doNotAskMoneySourceWhenBuy.includes(financialInstrumentForm)) {
    errors.push({
      key: 'moneySource',
      message: getErrorMessage({ code: 302 }),
    });
  }
  return errors;
}

export function getFundOrderErrors(order: OrderLineType, defaultAccount: DefaultAccount): ValidationErrorValue[] {
  const fundErrors = validateFundOrder(order, defaultAccount);
  const formErrors = validateFormValues(order, tradeFundSchema);
  const orderReceiveInfoErrors = validateOrderReceiveInfo(order);

  const errors = [...fundErrors, ...formErrors, ...orderReceiveInfoErrors];
  return errors;
}

function validateWarrantOrder(
  order: OrderLineType,
  defaultFee: number | undefined,
  structuredMinimumSum: number | undefined,
  structuredStep: number | undefined,
  defaultAccount: DefaultAccount
) {
  const { side, unitFee, subscriptionSum, moneySource, receiverEmail } = order;
  const errors = [];
  if (_.isUndefined(unitFee) || unitFee < 0) {
    errors.push({
      key: 'unitFee',
      message: 'numberMinimum',
    });
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (_.isUndefined(unitFee) || unitFee > defaultFee) {
    errors.push({
      key: 'unitFee',
      message: 'numberMaximum',
    });
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  if (!isStructuredSubscriptionSumValid(subscriptionSum, structuredMinimumSum, structuredStep)) {
    errors.push({
      key: 'subscriptionSum',
      message: 'notStructuredSum',
    });
  }
  if (side === BUY && !isValidEmail(receiverEmail) && (!defaultAccount || defaultAccount.accountType === '03')) {
    errors.push({
      key: 'receiverEmail',
      message: getErrorMessage({ code: 202 }),
    });
  }
  if (!moneySource && side === BUY) {
    errors.push({
      key: 'moneySource',
      message: getErrorMessage({ code: 302 }),
    });
  }
  return errors;
}

export function getWarrantOrderErrors(
  order: OrderLineType,
  defaultFee: number | undefined,
  structuredMinimumSum: number | undefined,
  structuredStep: number | undefined,
  defaultAccount: DefaultAccount
): ValidationErrorValue[] {
  const errors = validateWarrantOrder(order, defaultFee, structuredMinimumSum, structuredStep, defaultAccount);
  return errors.concat(validateFormValues(order, buyWarrantSchema)).concat(validateOrderReceiveInfo(order));
}

function validateStructuredOrder(
  order: OrderLineType,
  structuredMinimumSum: number | undefined,
  structuredStep: number | undefined,
  privateEquityMaxSum: number | undefined,
  isInsurancePortfolio: boolean
) {
  const {
    side,
    subscriptionFee,
    subscriptionSum,
    financialInstrumentForm,
    financialInstrumentId,
    capitalCallMethod,
    capitalCallEmail,
    moneySource,
  } = order;
  const errors = [];

  if (_.isUndefined(subscriptionFee) || subscriptionFee < 0) {
    errors.push({
      key: 'unitFee',
      message: getErrorMessage({ code: 101 }),
    });
  }
  if (
    financialInstrumentForm === STRUCTURED_PRODUCT &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    !isStructuredSubscriptionSumValid(subscriptionSum, structuredMinimumSum, structuredStep)
  ) {
    errors.push({
      key: 'subscriptionSum',
      message: 'notStructuredSum',
    });
  }
  if (
    financialInstrumentForm === PRIVATE_EQUITY ||
    financialInstrumentForm === CO_INVESTMENTS ||
    financialInstrumentForm === PRIVATE_EQUITY_PROFIT_SHARING_LOAN ||
    financialInstrumentForm === CO_INVESTMENTS_PROFIT_SHARING_LOAN
  ) {
    if (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      !isPrivateEquitySubscriptionSumValid(subscriptionSum, structuredMinimumSum, structuredStep, privateEquityMaxSum)
    ) {
      errors.push({
        key: 'subscriptionSum',
        message: 'notStructuredSum',
      });
    }
    if (requireCapitalCall(financialInstrumentId, isInsurancePortfolio) && !capitalCallMethod) {
      errors.push({
        key: 'capitalCallMethod',
        message: getErrorMessage({ code: 302 }),
      });
    }
    if (!isValidEmail(capitalCallEmail) && capitalCallMethod === CAPITAL_CALL_BY_EMAIL) {
      errors.push({
        key: 'capitalCallEmail',
        message: getErrorMessage({ code: 302 }),
      });
    }
  } else {
    if (!moneySource && side === BUY) {
      errors.push({
        key: 'moneySource',
        message: getErrorMessage({ code: 302 }),
      });
    }
  }
  return errors;
}

export function getStructuredOrderErrors(
  order: OrderLineType,
  structuredMinimumSum: number | undefined,
  structuredStep: number | undefined,
  privateEquityMaxSum: number | undefined,
  isInsurancePortfolio: boolean
): ValidationErrorValue[] {
  const structuredOrderErrors = validateStructuredOrder(
    order,
    structuredMinimumSum,
    structuredStep,
    privateEquityMaxSum,
    isInsurancePortfolio
  );
  const formErrors = validateFormValues(order, buyStructuredSchema);
  const orderReceiveInfoErrors = validateOrderReceiveInfo(order);

  const errors = [...structuredOrderErrors, ...formErrors, ...orderReceiveInfoErrors];
  return errors;
}

export function validateOrderReceiveInfo(order: OrderLineType) {
  const errors = [];

  if (!order[ORDER_INITIALIZER_TYPE]) {
    errors.push({
      key: ORDER_INITIALIZER_TYPE,
      message: getErrorMessage({ code: 302 }),
    });
  }

  if (order[ORDER_INITIALIZER_TYPE] === CLIENT) {
    if (isDateInFuture(order[RECEIVED_FROM_CLIENT_DATE])) {
      errors.push({
        key: RECEIVED_FROM_CLIENT_DATE,
        message: getErrorMessage({ code: 103 }),
      });
    }
  }

  if (order[ORDER_INITIALIZER_TYPE] === REPRESENTATIVE) {
    if (isDateInFuture(order[RECEIVED_FROM_CLIENT_DATE])) {
      errors.push({
        key: RECEIVED_FROM_CLIENT_DATE,
        message: getErrorMessage({ code: 103 }),
      });
    }
  }

  if (!order[RECEIVED_FROM_CLIENT_DATE] || order[RECEIVED_FROM_CLIENT_DATE] === 'Invalid date') {
    errors.push({
      key: RECEIVED_FROM_CLIENT_DATE,
      message: getErrorMessage({ code: 302 }),
    });
  }

  if (!order[RECEIVED_FROM_CLIENT_METHOD]) {
    errors.push({
      key: RECEIVED_FROM_CLIENT_METHOD,
      message: getErrorMessage({ code: 302 }),
    });
  }

  if (order[ORDER_INITIALIZER_TYPE] === CLIENT || order[ORDER_INITIALIZER_TYPE] === REPRESENTATIVE) {
    if (!order[BASIS_TYPE]) {
      errors.push({
        key: BASIS_TYPE,
        message: getErrorMessage({ code: 302 }),
      });
    }
  }

  if (!order[RECEIVED_FROM_CLIENT_METHOD]) {
    errors.push({
      key: RECEIVED_FROM_CLIENT_METHOD,
      message: getErrorMessage({ code: 302 }),
    });
  }

  if (order[ORDER_INITIALIZER_TYPE] === REPRESENTATIVE) {
    if (!order[REPRESENTATIVE_SSN]) {
      errors.push({
        key: REPRESENTATIVE_SSN,
        message: getErrorMessage({ code: 302 }),
      });
    }
    if (order[REPRESENTATIVE_SSN] && !isValidSSN(order[REPRESENTATIVE_SSN])) {
      errors.push({
        key: REPRESENTATIVE_SSN,
        message: getErrorMessage({ code: 202 }),
      });
    }
    if (!order[REPRESENTATIVE_NAME]) {
      errors.push({
        key: REPRESENTATIVE_NAME,
        message: getErrorMessage({ code: 302 }),
      });
    }
  }

  return errors;
}

function validateMeetingNote(note: Note) {
  const errors = [];

  const minDate = moment().subtract(1, 'year');
  const maxDate = moment().add(1, 'year');

  if (moment(note.scheduledStart, 'YYYY-MM-DDTHH:mm').isBefore(minDate)) {
    errors.push({
      key: 'scheduledStart',
      message: getErrorMessage({ code: 401 }),
    });
  }

  if (moment(note.scheduledEnd, 'YYYY-MM-DDTHH:mm').isAfter(maxDate)) {
    errors.push({
      key: 'scheduledEnd',
      message: getErrorMessage({ code: 402 }),
    });
  }

  if (moment(note.scheduledEnd, 'YYYY-MM-DDTHH:mm').isSameOrBefore(moment(note.scheduledStart, 'YYYY-MM-DDTHH:mm'))) {
    errors.push({
      key: 'scheduledEnd',
      message: getErrorMessage({ code: 101 }),
    });
  }
  return errors;
}

export function getMeetingNoteErrors(note: Note, schema: Schema) {
  const errors = validateMeetingNote(note);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return errors.concat(validateFormValues(note, schema));
}
