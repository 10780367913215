import Immutable from 'immutable';
import {
  BUY,
  SELL,
  SELL_ALL,
  EXCHANGE,
  EXCHANGE_ALL,
  DEFAULT_SIDE_FROM_BUTTON,
  INVALID_ORDER_SIDE,
} from 'constants/sides';
import { SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { InstrumentForm } from 'constants/instrumentForms';
import { ImmutableOrderLineType, OrderLineType, SuitabilityError } from 'types/ordersState';

export interface OrderDialogState {
  editor: ImmutableOrderLineType;
  touchedFields: ImmutableTouchedFields;
  isBuyingNew: boolean;
  changePairs: ChangePairType[];
  isFetchingNominalValue: boolean;
  isFetchingFee: boolean;
  hasEmission: boolean;
  searching: boolean;
  validationErrors: ValidationErrorValue[];
  suitabilityErrors: SuitabilityResult[];
  maxReceivedFromClientDate?: string;
  instrumentOptions: InstrumentOption[];
  isLoadingOptions: boolean;
  changePairOptions: ChangePairOption[];
  isLoadingChangePairOptions: boolean;
  fetchingSelectedSuitability: boolean;
  isEditingExistingLine: boolean;
  orderInitializerTypes: OrderInitializerType[];
  defaultFee?: number;
  structuredMinimumSum?: number;
  structuredStep?: number;
  privateEquityMaxSum?: number;
  isPreviousOrderDraftSuitable?: boolean;
  dialogType?: string;
  orderBasisTypes: OrderBasisType[];
  basisOfAdvice?: string;
  suitabilityAssessmentToDate?: boolean;
}

interface ImmutableTouchedFields extends Immutable.Map<string, unknown> {
  toJS(): TouchedFields;
}

export interface TouchedFields {
  [field: string]: boolean;
}

export interface ChangePairType {
  value: string;
  label: string;
}

export interface ChangePairOption {
  value: string;
  label: string;
  name: string;
  disabled: boolean;
  subLabel: string;
  financialInstrumentForm: InstrumentForm;
  issuer: string;
  alwaysEnable: boolean;
}

export interface InstrumentOption {
  financialInstrumentId: string;
  value: string;
  label: string;
  name: string;
  disabled: boolean;
  subLabel: string;
  financialInstrumentForm: InstrumentForm;
  currency: string;
  issuer: string;
  marketPrice: number;
  marketPriceCurrency: string;
  marketPriceBase: string;
  marketPriceBaseCurrency: string;
  instrumentGroups: string[] | undefined;
  alwaysEnable: boolean;
}

export interface EditorWithSuitabilityErrors {
  editor: OrderLineType;
  suitabilityErrorsBuy: SuitabilityError[];
  suitabilityErrorsSell: SuitabilityError[];
}

export interface ValidationErrorValue {
  key: string;
  message: string;
}

export interface OrderInitializerType {
  value: string;
  label: string;
}

export type OrderSideType =
  | typeof BUY
  | typeof SELL
  | typeof SELL_ALL
  | typeof EXCHANGE
  | typeof EXCHANGE_ALL
  | typeof DEFAULT_SIDE_FROM_BUTTON
  | typeof INVALID_ORDER_SIDE;

export interface OrderDialogSchema {
  required: string[];
}

export interface UpdateCustomerOptions {
  key: string;
  value: string;
}

export interface OrderBasisType {
  value: string;
  label: string;
}

export enum AppropriatenessTestState {
  Passed = 1,
  Failed,
  Canceled,
}

export interface Questionnaire {
  Id: number;
  Name: string;
  ProductCategoryId: string;
  Questions: Question[];
}

export interface Question {
  Id: number;
  QuestionnaireId: number;
  Order: number;
  QuestionText: string;
}

export interface CustomerAnswer {
  questionnaireId: number;
  answers: Answer[];
}

export interface Answer {
  questionId: number;
  selection: AnswerOption | null;
}

export enum AnswerOption {
  NotKnown,
  Yes,
  No,
}

export interface AppropriatenessResult {
  CustomerId: string;
  Disclaimer: string;
  Id: number | null;
  PayerPin: string;
  ProductCategoryId: string;
  Grade: TestGrade | null;
  TestTime: string | null;
}

export enum TestGrade {
  Passed,
  Failed,
  Ignored,
  AssumedOk,
  Error,
}
