import React, { Component } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import translate from 'counterpart';
import Select from 'react-select';
import _ from 'lodash';
import Immutable from 'immutable';
import styles from './Activity.scss';
import { MeetingType, meetingType } from 'constants/meetingType';
import { DirectionCodes, directionCodes } from 'constants/directionCodes';
import { meetingReason } from 'constants/meetingReason';
import { getAppointmentSchema, isFieldRequired, getError } from 'core/index';
import { PROSPECT } from 'constants/customerStates';
import { ValidationErrorValue } from 'types/orderDialogState';
import { Note } from 'types/ordersState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  activity: string;
  directionCode: string;
  topic: string;
  subject: string;
  state: string;
  editNote: (field: string, value: string) => void;
  mergeNote: (note: Partial<Note>) => void;
  errors: ValidationErrorValue[];
  touched: Immutable.Set<string>;
  includesReporting: boolean;
}

const theme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          '&.MuiInput-root:hover': {
            borderBottomColor: 'rgba(0, 0, 0, 0.6)',
            '&::before': {
              borderBottomColor: 'rgba(0, 0, 0, 0.6)',
              borderWidth: '1px',
            },
          },
          '&.Mui-focused': {
            '& > textarea': {
              border: 'none',
              boxShadow: 'none',
            },
            '&::after': {
              borderColor: '#000000',
            },
          },
        },
        input: {
          boxShadow: 'none',
          width: '100%',
          fontSize: '14px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingTop: '10px',
          color: '#000000',
          fontSize: '.875rem',
          '&.Mui-focused': {
            color: '#000000',
          },
        },
      },
    },
  },
});

export default class Activity extends Component<Props> {
  activityOptions;
  directionOptions;

  static defaultProps = {
    scheduledStart: '',
    scheduledEnd: '',
    subject: '',
  };

  constructor(props: Props) {
    super(props);
    this.activityOptions = this.getMenuOptions(meetingType);
    this.directionOptions = this.getMenuOptions(directionCodes);
  }

  getMenuOptions = (options: MeetingType | DirectionCodes | string[]) =>
    _.map(options, (a) => ({
      label: translate(`meetings.${a}`),
      value: a,
    }));

  handleActivity = (v: { value: string }) =>
    this.props.mergeNote({
      activity: v.value,
      directionCode: undefined,
      topic: undefined,
      location: undefined,
    });

  handleDirectionCode = (v: { value: string }) => this.props.editNote('directionCode', v.value);

  handleTopic = (v: { value: string }) => {
    this.props.mergeNote({
      topic: v.value,
      includesReporting: this.props.includesReporting || v.value === meetingReason.STATUS_CHECK,
    });
  };

  handleSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('subject', event.currentTarget.value);
  };

  getPhoneCallReasonOptions = () => {
    const { state, directionCode } = this.props;
    const options = [meetingReason.BUSINESS];
    if (state === 'Account') {
      if (directionCode === directionCodes.CUSTOMER) {
        options.push(meetingReason.ORDER);
      }
      options.push(meetingReason.INVESTMENT_ADVICE);
    }
    return this.getMenuOptions(options);
  };

  getAppointmentReasonOptions = () => {
    const { state } = this.props;
    const options = [];
    if (state === PROSPECT) {
      options.push(meetingReason.FIRST_MEETING, meetingReason.BUSINESS);
    } else if (state === 'Account') {
      options.push(
        meetingReason.MANAGEMENT_APPOINMENT,
        meetingReason.ADDITIONAL_SALES,
        meetingReason.SOCIAL,
        meetingReason.STATUS_CHECK,
        meetingReason.TAXATION
      );
    }
    return this.getMenuOptions(options);
  };

  render() {
    const { activity, directionCode, subject, topic, state, errors, touched } = this.props;
    const schema = getAppointmentSchema(activity, state);

    return (
      <div className="row">
        <div className="columns small-4" data-testkey="meeting-type">
          <div className={styles.label}>
            {translate('meetings.meetingType')}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            {isFieldRequired('activity', schema) ? '*' : ''}
          </div>
          <Select
            options={this.activityOptions}
            value={this.activityOptions.filter((option) => option.value === activity)}
            placeholder={translate('meetings.choose')}
            isClearable={false}
            menuShouldScrollIntoView={true}
            className="Select-white"
            components={{
              IndicatorSeparator: () => null,
            }}
            styles={{
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderTopStyle: 'none',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                boxShadow: 'none',
                fontSize: '19px',
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                primary: 'black',
                primary25: '#ededed',
                neutral50: 'black',
                neutral20: 'black',
                neutral60: 'black',
                primary50: '#ededed',
                neutral80: 'black', // selected text color
              },
            })}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={this.handleActivity}
          />
        </div>
        {activity === meetingType.PHONE_CALL ? (
          [
            <div className="columns small-4" key="d">
              <div className={styles.label}>
                {translate('meetings.directionCode')}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error */}
                {isFieldRequired('directionCode', schema) ? '*' : ''}
              </div>
              <Select
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                onChange={this.handleDirectionCode}
                options={this.directionOptions}
                value={this.directionOptions.filter((option) => option.value === directionCode)}
                placeholder={translate('meetings.choose')}
                isClearable={false}
                menuShouldScrollIntoView={true}
                className="Select-white"
                autofocus={true}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    boxShadow: 'none',
                    fontSize: '19px',
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'black',
                    primary25: '#ededed',
                    neutral50: 'black',
                    neutral20: 'black',
                    neutral60: 'black',
                    primary50: '#ededed',
                    neutral80: 'black', // selected text color
                  },
                })}
              />
            </div>,
            <div className="columns small-4" key="r">
              <div className={styles.label}>
                {translate('meetings.phoneCallReason')}
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-expect-error */}
                {isFieldRequired('topic', schema) ? '*' : ''}
              </div>
              <Select
                options={this.getPhoneCallReasonOptions()}
                placeholder={translate('meetings.choose')}
                isClearable={false}
                menuShouldScrollIntoView={true}
                className="Select-white"
                value={this.getPhoneCallReasonOptions().filter((option) => option.value === topic)}
                components={{
                  IndicatorSeparator: () => null,
                }}
                styles={{
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderTopStyle: 'none',
                    borderLeftStyle: 'none',
                    borderRightStyle: 'none',
                    boxShadow: 'none',
                    fontSize: '19px',
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: 'black',
                    primary25: '#ededed',
                    neutral50: 'black',
                    neutral20: 'black',
                    neutral60: 'black',
                    primary50: '#ededed',
                    neutral80: 'black', // selected text color
                  },
                })}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                onChange={this.handleTopic}
              />
            </div>,
          ]
        ) : (
          <div className="columns small-4" key="r" data-testkey="meeting-reason">
            <div className={styles.label}>
              {translate('meetings.appointmentReason')}
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
              {/* @ts-expect-error */}
              {isFieldRequired('topic', schema) ? '*' : ''}
            </div>
            <Select
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange={this.handleTopic}
              options={this.getAppointmentReasonOptions()}
              value={this.getAppointmentReasonOptions().filter((option) => option.value === topic)}
              placeholder={translate('meetings.choose')}
              isClearable={false}
              menuShouldScrollIntoView={true}
              className="Select-white"
              autofocus={true}
              components={{
                IndicatorSeparator: () => null,
              }}
              styles={{
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderTopStyle: 'none',
                  borderLeftStyle: 'none',
                  borderRightStyle: 'none',
                  boxShadow: 'none',
                  fontSize: '19px',
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: 'black',
                  primary25: '#ededed',
                  neutral50: 'black',
                  neutral20: 'black',
                  neutral60: 'black',
                  primary50: '#ededed',
                  neutral80: 'black', // selected text color
                },
              })}
            />
          </div>
        )}
        <div className="columns small-12">
          <ThemeProvider theme={theme}>
            <StyledTextField
              name="subject"
              onChange={this.handleSubject}
              value={subject}
              label={`${
                activity === meetingType.PHONE_CALL ? translate('meetings.callSubject') : translate('meetings.subject')
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
              }${isFieldRequired('subject', schema) ? '*' : ''}`}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              maxLength={200}
              style={{
                width: '100%',
              }}
              rows={1}
              multiline
              helperText={touched.has('subject') ? getError(errors, 'subject') : undefined}
              data-testkey="meeting-subject"
              variant="standard"
              sx={[
                {
                  '&.MuiFormControl-root': {
                    marginTop: '15px',
                    width: '100%',
                  },
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiInput-root:hover': {
                    borderBottomColor: 'rgba(0, 0, 0, 0.6)',
                    '&::before': {
                      borderBottomColor: 'rgba(0, 0, 0, 0.6) !important',
                      borderWidth: '1px !important',
                    },
                  },
                  '& .MuiInputBase-root': {
                    marginTop: '14px',
                    marginBottom: '15px',
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'Roboto, sans-serif',
                    marginTop: 0,
                    height: '100%',
                    paddingTop: 0,
                  },
                  '& label': {
                    fontSize: '14px',
                    '&.Mui-focused': {
                      fontSize: '14px',
                    },
                  },
                  '& > p': {
                    '&.MuiFormHelperText-root': {
                      paddingLeft: '0',
                    },
                  },
                  '&.Mui-error': {
                    borderBottomColor: '#d32f2f',
                    borderWidth: '2px',
                    '&::before': {
                      borderWidth: '2px',
                      borderBottomColor: '#d32f2f',
                    },
                  },
                },
              ]}
            />
          </ThemeProvider>
        </div>
      </div>
    );
  }
}
