import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { styles } from 'features/common/StyledComponents';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import {
  isConsideredPortfolio,
  isIgnoredPortfolio,
  isOptimizedPortfolio,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { Portfolios } from 'features/portfolioManager/components/Portfolios';
import { colors } from 'styles/colors';
import { Grid } from 'features/allocator/common/Grid';
import { weightTypes } from 'constants/allocator';

interface Props {
  portfolios: AllocatorPortfolio[];
}

export const PlanPortfolioContents = ({ portfolios }: Props) => {
  const includedPortfolios = portfolios.filter((p) => isOptimizedPortfolio(p));
  const consideredPortfolios = portfolios.filter((p) => isConsideredPortfolio(p));
  const ignoredPortfolios = portfolios.filter((p) => isIgnoredPortfolio(p));

  const weightsToUse = weightTypes.optimizedWeights;

  return (
    <Grid>
      {includedPortfolios.length > 0 ? (
        <Portfolios
          portfolios={includedPortfolios}
          planLength="withoutIlliquids"
          weightsToUse={weightsToUse}
          compareTo={weightTypes.currentWeights}
        />
      ) : (
        <Empty>{translate('allocator.portfoliosView.noOptimizedPortfolios')}</Empty>
      )}
      <Grid vertical>
        {consideredPortfolios.length > 0 && (
          <Portfolios
            portfolios={consideredPortfolios}
            planLength="withoutIlliquids"
            weightsToUse={weightsToUse}
            compareTo="none"
          />
        )}
        <Portfolios
          portfolios={ignoredPortfolios}
          planLength="withoutIlliquids"
          weightsToUse={weightsToUse}
          compareTo="none"
        />
      </Grid>
    </Grid>
  );
};

const Empty = styled.div`
  background: ${colors.med_gray};
  margin-top: 0.9rem;
  border-radius: 6px;
  opacity: 0.5;
  margin-bottom: 1rem;
  ${styles.cardPadding}
`;
