import Immutable from 'immutable';
import { OrderSideType } from 'types/orderDialogState';
import { InstrumentForm } from 'constants/instrumentForms';
import { Schema, ArrayEditorValues } from 'types/contractsState';
import { BisnodeUpdate } from 'features/orders/ordersTypes';
import { BondPriceType, SignatureMethodType } from 'types/types';

export interface OrdersState {
  showConfirmDialog: boolean;
  ordersByPortfolioId: {
    [portfolioId: string]: Orders;
  };
  loadingContractTemplates: boolean;
}

export interface OrdersType {
  template: Template;
  contract: ImmutableContract;
  orderDetails: OrderDetailsType[];
  contractPrefilled: boolean;
  signatories: ImmutableSignatoryList;
  showNewSignatoryDialog: Immutable.Map<string, unknown>;
  portfolioContractName: string | undefined;
  portfolioStrategy: string | undefined;
  bisnode: BisnodeUpdate;
  canUpdateBisnode?: boolean;
  savedSigners: Signatory[];
}

export interface ImmutableSignatoryList extends Immutable.List<ImmutableSignatory> {
  toJS(): Signatory[];
}

export class Orders implements OrdersType {
  constructor() {
    this.template = {} as Template;
    this.contract = Immutable.Map() as ImmutableContract;
    this.orderDetails = [];
    this.contractPrefilled = false;
    this.signatories = Immutable.List();
    this.showNewSignatoryDialog = Immutable.Map();
    this.portfolioContractName = undefined;
    this.portfolioStrategy = undefined;
    this.savedSigners = [];
    this.bisnode = {
      text: '',
      updateDate: '',
    };
  }
  template: Template;
  contract: ImmutableContract;
  orderDetails: OrderDetailsType[];
  contractPrefilled: boolean;
  signatories: ImmutableSignatoryList;
  showNewSignatoryDialog: Immutable.Map<string, unknown>;
  portfolioContractName: string | undefined;
  portfolioStrategy: string | undefined;
  bisnode: BisnodeUpdate;
  canUpdateBisnode?: boolean | undefined;
  savedSigners: Signatory[];
}

export interface OrderDetailsType {
  orderLineIds: number[];
  pdfPreviewLink: ImmutablePdfPreviewLink;
  signatureMethod?: SignatureMethodType;
  manualSigners: number;
  finalContract: Immutable.Map<string, unknown>;
  contractSigning: boolean;
  creatingContract: boolean;
  createContractMessages: unknown[];
  createContractSucceeded: boolean;
  contractSignatoriesFailed: boolean;
  showSignatureSelection: boolean;
  orderType: OrderDetailsOrderType;
}

export type OrderDetailsOrderType = 'fundOrder' | 'fundAdvice' | 'nonFundOrder' | 'nonFundAdvice';

interface PdfPreviewLink {
  id: string;
  name?: string;
  blobUrl?: string;
  isBusy: boolean;
  error: string;
}

export interface ImmutableTemplates extends Immutable.Map<string, Template> {
  toJS(): { [contractId: string]: Template };
  get(key: string): Template;
  find(matcher: (param: Template) => void): Template;
}

export interface Template {
  contractType: string;
  isDraft: boolean;
  schema: Schema;
  form: Form;
  versions: Version[];
  formVersions: Version[];
}

interface Version {
  isDraft: boolean;
  version: number;
  creationTime: string;
  creator: string;
  editionTime: string;
  editor: string;
}

export interface Form {
  contractType: string;
  creator: string;
  formCreationTime: string;
  editor: string;
  editorFullName: string;
  formEditTime: string;
  items: Item[];
  hasCrmIntegration: boolean;
  id: string;
}

export interface Item {
  key: string;
  type: string;
  title: { fi: string; en: string; sv: string };
  items: FieldType[];
  sectionVisible?: { fi: boolean; en: boolean; sv: boolean };
}

export interface FieldType {
  key: string;
  title: { en: string; fi: string; sv: string };
  id?: string;
  type?: string;
  style?: string;
  condition?: Condition;
  items?: FieldType[];
  info?: { fi: string; en: string; sv: string };
  titleMap?: { fi: KeyValueObject; en: KeyValueObject; sv: KeyValueObject };
  readOnly?: boolean;
  placeHolder?: { fi: string; en: string; sv: string };
  showInfoAlways?: boolean;
  link?: { en: string; fi: string; sv: string };
  linkText?: { en: string; fi: string; sv: string };
  textAfterLink?: { en: string; fi: string; sv: string };
  linkVisible?: { fi: boolean; en: boolean; sv: boolean };
}

export type Question = FieldType & {
  isRequired: boolean;
};

export interface Condition {
  key: string;
  type: string;
  value: string | boolean;
}

interface KeyValueObject {
  [key: string]: string;
}

export interface ImmutableSignatory extends Immutable.Map<string, unknown> {
  toJS(): Signatory;
  get<K extends keyof Signatory>(key: K): Signatory[K];
}

export interface ImmutablePdfPreviewLink extends Immutable.Map<string, unknown> {
  toJS(): PdfPreviewLink;
  get<K extends keyof PdfPreviewLink>(key: K): PdfPreviewLink[K];
}

export interface Signatory {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  ssn: string;
  customerId?: string;
}

export interface SignatoryFormErrors {
  ssn: string | undefined;
  email: string | undefined;
  phone: string | undefined;
}

export interface ImmutableContracts extends Immutable.Map<string, unknown> {
  toJS(): Contract[];
  get(key: string, notSetValue?: boolean): Contract;
}

export interface ImmutableContract extends Immutable.Map<string, unknown> {
  get<K extends keyof Contract>(key: K): Contract[K];
  toJS(): Contract;
}

export interface Contract {
  descriptiveName?: string;
  schema: { id: string; version: number };
  form: Form;
  stateChangeReason: string;
  clientType: string;
  contractMainType: string;
  creatorFullName: string;
  clientName: string;
  clientId: string;
  editorFullName: string;
  footerText: string;
  creator: string;
  manualSignersCount: number;
  contractType: string;
  editor: string;
  creationDate: string;
  sections: Sections;
  state: string;
  isPersonnel: boolean;
  shortId: string;
  editDate: string;
  isPrivate: boolean;
  id: string;
  contractBaseType: string;
  contractName: string;
  confirmed: boolean;
  name: string;
  isBusy: boolean;
  blobUrl: string;
  language: string;
  attachmentId?: string;
  localizedNames?: string[];
}

interface GenericObject {
  [k: string]: string | number;
}

interface Sections {
  receiveInfo: ReceiveInfo;
  order: Order;
  clientSummary: GenericObject;
  financialInformation: GenericObject;
  suitability: GenericObject;
  moneyLaunderingInfoCompany: GenericObject;
  investorSurvey: GenericObject;
  suitabilityAssessmentCompany: GenericObject;
  riskConfirmation: { riskConfirmed: boolean; selectedRiskLevel: number };
  clientData: {
    city: string;
    email: string;
    businessId: string;
    name: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    postalCode: string;
    ssn: string;
    streetName: string;
  };
}

interface Order {
  orderDate: string;
  orderLines: ImmutableOrderLineTypes;
  attachmentReceived: boolean;
  notificationsViaWeb: boolean;
  hasInsurance: boolean;
  hasStructuredProduct: boolean;
  hasPrivateEquity: boolean;
  hasExchangeTraded: boolean;
}

interface ImmutableOrderLineTypes extends Immutable.Map<string, unknown> {
  toJS(): OrderLineType[];
}

export interface ImmutableOrderLineType extends Immutable.Map<string, unknown> {
  toJS(): OrderLineType;
  get<K extends keyof OrderLineType>(key: K, defaultValue?: unknown): OrderLineType[K];
}

export interface OrderLineType {
  _id: number;
  contractName: string;
  contractType?: string;
  currency: string;
  currentQuantity: number;
  expireDate: string;
  financialInstrumentId: string;
  financialInstrumentForm: InstrumentForm;
  financialInstrumentName: string;
  financialInstrument: FinancialInstrumentType;
  instrumentCurrency: string;
  issuer: string;
  instrumentForm: InstrumentForm;
  isSuitable: boolean;
  marketPrice: number;
  marketPriceCurrency: string;
  marketPriceBase: number;
  marketPriceBaseCurrency: string;
  orderFinancingBuy?: string;
  orderFinancingSell?: string;
  orderType: string;
  portfolioId: string;
  receivedFromClientDate: string;
  rowType: string;
  side: OrderSideType;
  tradeAll: boolean;
  value: number;

  bondPriceType: BondPriceType;
  receiverEmail: string;
  capitalCallEmail: string;
  orderInitializerType: string;
  externalPortfolioId: string;
  strategyName: string;
  receivedFromClientMethod: string;
  billingEmail: string;
  defaultAccountType?: string;
  moneySource?: string;

  notionalAmount: number;
  amount: number;
  quantity: number;
  marketValueNet: number;
  quantityNet: number;

  price?: string;
  priceCurrency?: string;
  fundsOrigin?: string;
  note?: string;
  statePortfolioId?: string;
  prePayPrice?: number;
  internalNote?: string;
  isLeveraged?: boolean;
  legalForm?: string;

  // needed for fund, warrants, structured products:
  subscriptionSum?: number;
  subscriptionFee?: number;
  defaultFee?: number;

  // needed for warrants:
  unitFee?: number;

  // needed for private equity
  capitalCallMethod?: string;
  productId?: string;
  productIssuer?: string;

  // needed when exchanging instrument to another:
  counterFinancialInstrumentId?: string;
  counterFinancialInstrumentName?: string;
  counterFinancialInstrumentForm?: InstrumentForm;
  counterFinancialInstrument?: CounterFinancialInstrument;

  orderBasis: string;
  representativeSsn: string;
  representativeName: string;

  // needed for minutes of investment service fields:
  minutesOfInvestmentService?: MinutesOfInvestmentServiceType;

  testPassed?: boolean;

  buyOrSellClicked?: boolean;
  moisInfo?: string;
  moisConvener?: string;
  moisParticipants?: string;
  moisDate?: string;
  moisLocation?: string;
}

export interface MinutesOfInvestmentServiceType extends Map {
  date: string;
  location: string;
  participants: string;
  convener: string;
  info: string;
}

interface Map {
  [key: string]: string;
}

interface FinancialInstrumentType {
  name: string;
  financialInstrumentForm: InstrumentForm;
  symbol: string;
  instrumentGroups?: string[] | undefined;
}

interface CounterFinancialInstrument {
  counterFinancialInstrumentId: string;
  name: string;
  counterFinancialInstrumentForm: InstrumentForm;
}

export interface ReceiveInfo {
  adviceDeliveryAfterwards: boolean;
  capitalCallEmail: string;
  customerHasSeenDocuments: boolean;
  externalPortfolioId: string;
  giveType: string;
  isOrderConfirmed: boolean;
  moneySource: string;
  portfolioId: string;
  receiveDate: string;
  receiverEmail: string;
}

interface ImmutableValues extends Immutable.Map<string, unknown> {
  toJS(): Value[];
}

export interface FoundPerson {
  customerId: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  ssn: string;
  proxyOfCustomerId: string | null;
}

export type Value =
  | boolean
  | number
  | string[]
  | string
  | undefined
  | ImmutableValues
  | ArrayEditorValues[]
  | FoundPerson;

export interface OptionValue {
  value: string;
  label: string;
}

export interface OrderInstrumentDetails {
  financialInstrument: FinancialInstrument;
  marketPrice: PriceValue;
  suitabilityErrorsBuy: SuitabilityError[];
  suitabilityErrorsSell: SuitabilityError[];
  portfolio: {
    portfolioId: string;
  };
}

export interface Position {
  accruedInterest: PriceValue;
  customer: { customerId: string };
  financialInstrument: FinancialInstrument;
  marketPrice: PriceValue;
  marketPriceWithoutInterests: PriceValue;
  marketValue: PriceValue;
  marketValueChange: PriceValue;
  marketValueChangePercent: PriceValue;
  marketValueNet: PriceValue;
  portfolio: PositionPorftolio;
  purchasePrice: PriceValue;
  purchaseValue: PriceValue;
  quantity: number;
  quantityNet: number;
  side: OrderSideType;
  suitabilityErrorsBuy: SuitabilityError[];
  suitabilityErrorsSell: SuitabilityError[];
}

interface FinancialInstrument {
  assetAdditionalInfo: string;
  assetAdditionalInfoId: string;
  assetCategory: string;
  assetCategoryId: string;
  assetClass: string;
  assetClassId: string;
  bondEmissions: object[];
  company: string;
  complexity: string;
  countryCode: string;
  couponRate: number;
  currency: string;
  directedIssues: object[];
  expireDate: string;
  financialInstrumentForm: InstrumentForm;
  financialInstrumentId: string;
  friendlyName: string;
  isExchangeTraded: boolean;
  isImpact: boolean;
  isOwnProduct: boolean;
  isin: string;
  issuer: string;
  isSuitable?: boolean;
  legalForm: string;
  maturityDate: string;
  name: string;
  redemptionFee: string;
  riskLevel: string;
  id: string;
  securityGroups: {
    id: string;
    name: string;
  }[];
  securityType: string;
  securityTypeId: string;
  sortingCode: string;
  subscriptionFee: string;
  ticker: string;
}

export interface PriceValue {
  baseCurrency: Currency;
  securityCurrency: Currency;
}

export interface Currency {
  currency: string;
  value: number;
}

interface PositionPorftolio {
  contractName: string;
  customerId: string;
  externalId: string;
  nameForCustomer: string;
  portfolioCode: string;
  portfolioId: string;
  portfolioName1: string;
  portfolioName2: string;
  portfolioName3: string;
  statusName: string;
  strategyName: string;
}

export interface SuitabilityError {
  customerId: string;
  financialInstrumentId: string;
  isSuitable: boolean;
  messages: string[];
  portfolioId: string;
  ruleId: string;
}

export interface InstrumentDetail {
  assetCategory: string;
  assetClass: string;
  company: string;
  currency: string;
  financialInstrument: {
    name: string;
    symbol: string;
    financialInstrumentForm: InstrumentForm;
  };
  financialInstrumentForm: InstrumentForm;
  financialInstrumentId: string;
  friendlyName: string;
  isExchangeTraded: boolean;
  isOwnProduct: boolean;
  isSuitable: boolean;
  isin: string;
  issuer: string;
  latestPrice: PriceValue;
  legalForm: string;
  name: string;
  securityGroups: {
    id: string;
  }[];
  securityTypeName: string;
  suitabilityErrors: SuitabilityError[];
  alwaysEnable: boolean;
}

interface ImmutableValues extends Immutable.Map<string, unknown> {
  toJS(): Value[];
}

export interface ImmutableNote extends Immutable.Map<string, unknown> {
  toJS(): Note;
}

export interface Note {
  scheduledStart: string;
  scheduledEnd: string;
  directionCode: string;
  present: string;
  description: string;
  subject: string;
  location: string;
  topic: string;
  activity: string;
  includesReporting: boolean;
}

export interface Signer {
  email: string;
  familyName: string;
  givenName: string;
  hasSigned: boolean;
  mobile: string;
  ssn: string;
  statuses: string[];
  signUrl?: string;
}

export interface GetSignatureRequestsResponse {
  contracts: string[];
  id: string;
  keys: unknown;
  method: string;
  signers: Signer[];
}

export interface FeeResponse {
  customerId: string;
  date: string;
  feePercentage: number;
  portfolioId: string;
  side: OrderSideType;
}
