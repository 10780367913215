import { ApiClient } from 'core/ApiClient';

export const clientMiddleware = (client: ApiClient) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return ({ dispatch, getState }) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    (next) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    (action) => {
      if (typeof action === 'function') {
        return action(dispatch, getState);
      }

      const { promise, types, ...rest } = action;
      if (!promise) {
        return next(action);
      }

      const [REQUEST, SUCCESS, FAILURE] = types;
      next({ ...rest, type: REQUEST });

      const oidc = getState().oidc;

      if (oidc.user) {
        client.setToken(oidc.user.access_token);
      }

      return promise(client)
        .then(
          (result: object) => next({ ...rest, result, type: SUCCESS }),
          (error: Error) => next({ ...rest, error, type: FAILURE })
        )
        .catch((error: Error) => {
          next({ ...rest, error, type: FAILURE });
        });
    };
};
