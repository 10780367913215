import ciConfig from './_ci';
import devConfig from './_development';
import prConfig from './_pr';
import sgConfig from './_sg';
import testConfig from './_test';

const getConfig = (env: string) => {
  switch (env) {
    case 'ci':
      return ciConfig;
    case 'development':
      return devConfig;
    case 'pr':
      return prConfig;
    case 'sg':
      return sgConfig;
    case 'test':
      return testConfig;
  }

  return devConfig;
};

const env = process.env.JUMPSTART_ENV || 'development';
export const config = getConfig(env);
