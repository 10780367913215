import * as t from 'io-ts';

// STATE

export interface NewCustomerStepsState {
  portfolioResponses: PortfolioResponse[];
  validationErrors: string[];
}

export type PortfolioResponse = t.TypeOf<typeof PortfolioResponse>;

const PortfolioResponseMandatory = t.type({
  portfolioId: t.string,
  portfolioType: t.string,
  settlementAccountId: t.string,
  fundAccountId: t.string,
  priceRowId: t.string,
  contactPersonId: t.string,
  portfolioFundAccountLinkId: t.string,
  allocationDefinitionIds: t.array(t.string),
});

const PortfolioResponseOptional = t.partial({
  priceRowLinkId: t.string,
  allocationHistoryId: t.string,
});

export const PortfolioResponse = t.intersection([PortfolioResponseMandatory, PortfolioResponseOptional]);

// API CALLS

type UpdateAllocationPlanResponse = t.TypeOf<typeof UpdateAllocationPlanResponse>;

const AllocatedPortfolio = t.type({
  portfolioId: t.string,
  allocatorPortfolioId: t.string,
  contractType: t.string,
  withIlliquids: t.boolean,
});

const UpdateAllocationPlanResponse = t.type({
  planId: t.string,
  isDraft: t.boolean,
  allocatedPortfolios: t.array(AllocatedPortfolio),
});

export type GetPortfoliosStatusResponse = t.TypeOf<typeof GetPortfoliosStatusResponse>;

const GetPortfoliosStatusResponseMandatory = t.type({
  customerId: t.string,
  portfolioResponseDtos: t.array(PortfolioResponse),
});

const GetPortfoliosStatusResponseOptional = t.partial({
  bankAccountId: t.string,
});

export const GetPortfoliosStatusResponse = t.intersection([
  GetPortfoliosStatusResponseMandatory,
  GetPortfoliosStatusResponseOptional,
]);

export type CreatePortfoliosResponse = t.TypeOf<typeof CreatePortfoliosResponse>;

export const CreatePortfoliosResponse = t.type({
  updateAllocationPlanResponse: UpdateAllocationPlanResponse,
  openAllocatorServicesResponse: GetPortfoliosStatusResponse,
});
