import styled, { createGlobalStyle, css } from 'styled-components';
import { colors } from 'styles/colors';

export const fontSize = {
  h1: '24px',
  h2: '20px',
  h3: '16px',
  h4: '13px',
  normal: '16px',
  smaller: '14px',
  small: '12px',
};

export const distributionBarHeight = '44px';
export const subPageHeaderHeight = '60';
export const customerHeaderHeight = '56px';
export const allocatorWidth = '1366px';
export const pageMargin = '2rem';
export const menuWidth = '240px';

export const styles = {
  cardPadding: css`
    padding: 1.5rem 2rem;
  `,
  card: css`
    padding: 1.5rem 2rem;
    border-radius: 6px;
    background: hsl(0, 0%, 99%);
    box-shadow: 0 5px 25px ${colors.shadow}, 0 1px 0 ${colors.shadow}, inset 1px 1px 0 white;
  `,
  criticalMessage: css`
    width: 90%;
    border: 4px double ${colors.red};
    margin: 0 auto 3rem;
    background: white;
    border-radius: 6px;
  `,
  fullWidth: css`
    margin: 0 calc((-100vw + ${allocatorWidth}) / 2);
    padding: ${pageMargin} calc((100vw - ${allocatorWidth} + 2 * ${pageMargin}) / 2);
    background: linear-gradient(135deg, hsla(205deg 100% 80% / 0.1), hsl(205deg 30% 88% / 0.5));
  `,
  link: css`
    color: ${colors.link_blue};

    .disabled {
      color: ${colors.med_gray};
    }

    &:focus,
    &:hover {
      &:not(:disabled) {
        cursor: pointer;
        text-decoration: underline;
        color: ${colors.link_blue};
      }
    }
  `,
};

interface ReactSelectState {
  isFocused: boolean;
  isSelected: boolean;
  isDisabled: boolean;
  hasValue: boolean;
  options: { value: string; label: string }[];
}

export const customReactSelectStyle = {
  option: (provided: object, state: ReactSelectState) => ({
    ...provided,
    background: state.isSelected ? colors.primary : 'white',
  }),
  control: (provided: object, state: ReactSelectState) => ({
    ...provided,
    boxShadow: state.isFocused ? `0 0 0 1px ${colors.primary}` : 'none',
    minWidth: '105px',
    borderColor: state.isFocused ? colors.primary : 'hsl(0, 0%, 70%)',
  }),
  singleValue: () => ({}),
};

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    background: ${colors.gray_light};
    font-weight: 500;
    text-underline-offset: 2px;
  }

  * {
    font-family: 'Calibre', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    line-height: 1.4;
  }

  p {
    margin: 0;
  }

  h1, h2, h3, h4, h5 {
    font-family: 'Calibre', 'Helvetica Neue', sans-serif;
    font-weight: 200;
    padding: unset;
    white-space: nowrap;
  }

  h1 {
    font-weight: 400;
    font-size: ${fontSize.h1};
  }

  h2 {
    font-size: ${fontSize.h2};
    font-weight: 400;
    margin: 0 0 1rem;
  }

  h3 {
    font-size: ${fontSize.h3};
    font-weight: 400;
    margin: 0 0 1rem;
  }

  h4 {
    font-size: ${fontSize.h4};
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: .5px;
    padding: .5rem 0 .25rem;
    margin: 0;
  }

  h5 {
    font-size: ${fontSize.h4};
    padding: 0;
    margin: 0;
  }

  b {
    font-weight: 400;
  }

  a {
    ${styles.link};
  }

  label {
    font-weight: 300;
    font-size: ${fontSize.smaller};
    line-height: 1.5;
  }

  table, tbody {
    background: transparent;
    width: auto;
    margin-bottom: unset;
    border: none;

    thead {
      background: none;
      border: none;
    }

    td, th {
      white-space: nowrap;
      padding-top: .2rem;
      padding-bottom: .2rem;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  li {
    padding-bottom: .25rem;
  }

  input:active, input:focus, button:active, button:focus {
    outline: none;
  }

  input[type='radio'] {
     margin: 0 1rem 0 0;
  }

  input[type='text'] {
    height: auto;
  }

  input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    appearance: none;
  }

  .rc-slider-tooltip {
    font-family: 'Calibre', 'Helvetica Neue', sans-serif;
    font-weight: 300;

    .rc-slider-tooltip-inner {
      background: transparent;
      color: ${colors.black};
      box-shadow: none;
      font-size: ${fontSize.normal};
      margin-top: 10px;
    }

    .rc-slider-tooltip-arrow {
      display: none;
    }
  }
`;

const Input = styled.input`
  color: ${colors.black};
  margin: 0;
  padding: 0.75rem 1rem;
  border-radius: 3px;
  width: 100%;
  box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid ${colors.med_gray};
  transition: all 0.2s;

  &:active,
  &:focus {
    border: 1px solid ${colors.primary_light};
    box-shadow: 0 7px 20px -5px rgb(198, 239, 232);
  }
`;

export const TextInput = styled(Input).attrs({ type: 'text' })``;

export const NumberInput = styled(Input).attrs({ type: 'number' })``;

export const Flex = styled.div`
  display: flex;
`;

export const FlexColumn = styled(Flex)`
  flex-direction: column;
`;

export const FlexAlignCenter = styled(Flex)`
  align-items: center;
`;

export const FlexJustifyCenter = styled(FlexAlignCenter)`
  justify-content: center;
`;

export const FlexJustifyBetween = styled(Flex)`
  justify-content: space-between;
`;

export const SubPageContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${pageMargin};
`;

export const HelpText = styled.p`
  color: ${colors.dark_gray};
  line-height: 1.4;
`;

export const HelpTextSmall = styled(HelpText)`
  font-size: ${fontSize.smaller};
`;

export const Spacer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 1px;
  border-bottom: 1px solid #cacaca;
  margin-bottom: 1rem;
`;

export const InputFilePdf = styled.input`
  display: none;
`;
