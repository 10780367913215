import React from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './FadeEnter.scss';

interface Props {
  children: React.ReactElement;
  timeout: number;
}

const FadeEnter = ({ children, timeout, ...props }: Props) => (
  <CSSTransition
    {...props}
    timeout={timeout}
    classNames={{
      enter: styles['fade-enter'],
      enterActive: styles['fade-enter-active'],
      exit: styles['fade-exit'],
      exitActive: styles['fade-exit-active'],
    }}
  >
    {children}
  </CSSTransition>
);

FadeEnter.defaultProps = {
  timeout: 500,
};

export default FadeEnter;
