import React, { ReactElement, useState } from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { ModalDialog } from './ModalDialog';
import styles from './NavBar.scss';
import { config } from 'config';
import StyledButton from './styledComponents/StyledButton';

interface Props {
  leftContent?: ReactElement;
  rightContent?: ReactElement;
  showModalOnExit?: boolean;
  cancelAction?: () => void;
  acceptAction?: () => void;
  modalTitle?: string;
  modalContent?: string;
  acceptLabel?: string;
  cancelLabel?: string;
}

export const NavBar = ({
  leftContent,
  rightContent = <div style={{ width: '180px' }} />,
  showModalOnExit,
  cancelAction,
  acceptAction,
  modalTitle,
  modalContent,
  acceptLabel = 'order.toFrontPage',
  cancelLabel = 'order.cancel',
}: Props) => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const toFrontPage = () => {
    setModalIsOpen(false);
    if (acceptAction) {
      acceptAction();
    }
    window.location.href = config.basePath;
  };

  const cancelNavigation = () => {
    setModalIsOpen(false);
    if (cancelAction) {
      cancelAction();
    }
  };

  const handleExit = () => {
    if (showModalOnExit) {
      setModalIsOpen(true);
    } else {
      window.location.href = config.basePath;
    }
  };

  const actions = [
    <StyledButton variant="text" onClick={toFrontPage} key={acceptLabel}>
      {translate(acceptLabel)}
    </StyledButton>,
    <StyledButton variant="text" onClick={cancelNavigation} key={cancelLabel}>
      {translate(cancelLabel)}
    </StyledButton>,
  ];

  return (
    <NavBarContainer>
      <ModalDialog title={modalTitle} actions={actions} open={modalIsOpen} content={modalContent} />

      <nav className={styles.nav}>
        <LeftContainer>
          <MenuRow onClick={handleExit} className={styles.topBarLeft} data-testkey="navigate-to-frontpage" />
          <MenuRow className={styles.topBarLeftText}>Pankkiirin työpöytä</MenuRow>
        </LeftContainer>
        <div className={styles.left}>{leftContent}</div>
        <div className={styles.right}>{rightContent}</div>
      </nav>
    </NavBarContainer>
  );
};

const NavBarContainer = styled.div`
  height: 70px;
`;

const LeftContainer = styled.div`
  display: flex;
  min-width: 0;
`;

const MenuRow = styled.div`
  flex: 1;
`;
