import { OrderInitializerType } from 'types/orderDialogState';

export const ORDER = 'order';
export const ADVICE = 'advice';
export const rowTypes: OrderInitializerType[] = [
  {
    value: ORDER,
    label: 'Toimeksianto',
  },
  {
    value: ADVICE,
    label: 'Sijoitusneuvonta',
  },
];
