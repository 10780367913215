import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { FlexAlignCenter } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { Grid } from 'features/allocator/common/Grid';

interface Props {
  portfolioReturn: number;
}

export const ExpectedReturnFigures = ({ portfolioReturn }: Props) => {
  return (
    <Grid vertical gap=".5rem">
      <FlexAlignCenter>
        <LineMark bg="black" />
        <div>
          {translate('allocator.configureRisk.marketReturn')} ({formatToDecimals(portfolioReturn, 1, '%')})
        </div>
      </FlexAlignCenter>

      <FlexAlignCenter>
        <DotMark bg={colors.primary} />
        <div>60 %{translate('allocator.configureRisk.probability')}</div>
      </FlexAlignCenter>

      <FlexAlignCenter>
        <DotMark bg="hsla(170,65%,48%,.25)" />
        <div>80 %{translate('allocator.configureRisk.probability')}</div>
      </FlexAlignCenter>
    </Grid>
  );
};

interface MarkProps {
  bg: string;
}

const Mark = styled.div<MarkProps>`
  height: 1rem;
  width: 1rem;
  margin: 0 0.5rem;
  background: ${(props) => props.bg};
`;

const DotMark = styled(Mark)`
  border-radius: 5px;
`;

const LineMark = styled(Mark)`
  height: 2px;
`;
