import React, { ReactElement, useEffect } from 'react';
import { Location } from 'history';
import styles from 'features/portfolio/PortfolioView.scss';
import { fetchPortfolioData } from 'features/portfolio/portfolioActions';
import { initOrderState } from 'features/orders/ordersActions';
import { PortfolioNav } from 'features/portfolio/components/PortfolioNav';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { useAppDispatch } from 'core/hooks';

interface Props {
  children: ReactElement;
  params: { id: string };
  location: Location;
}

export const PortfolioView = ({ children, params, location }: Props) => {
  const dispatch = useAppDispatch();

  const customerId = params.id;

  useEffect(() => {
    dispatch(fetchPortfolioData(customerId));
    return () => {
      dispatch(initOrderState());
    };
  }, []);

  return (
    <section className={styles.portfolio}>
      <PortfolioNav customerId={customerId} location={location} />
      <ErrorBoundary>{children}</ErrorBoundary>
    </section>
  );
};
