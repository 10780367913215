import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { colors, ASSET_CLASS_COLORS } from 'styles/colors';
import { FlexColumn, FlexAlignCenter, fontSize } from 'features/common/StyledComponents';
import { AssetClassId, AssetCategoryName } from 'types/types';
import { Dot } from 'features/allocator/common/Dot';
import { AssetClass } from 'types/commonDataState';
import { Card } from 'features/allocator/common/Card';

interface Props {
  assetClasses: AssetClass[];
  assetClassesSelected: AssetClassId[];
}

interface AssetCategory {
  name: AssetCategoryName;
  assetClasses: AssetClass[];
}

export const SelectedAssetClasses = ({ assetClasses, assetClassesSelected }: Props) => {
  const assetCategories = [...new Set(assetClasses.map((a) => a.assetCategory))];
  const assetClassesByAssetCategory: AssetCategory[] = assetCategories.map((assetCategory) => ({
    name: assetCategory,
    assetClasses: assetClasses.filter((a) => a.assetCategory === assetCategory),
  }));

  const getAssetColor = (i: AssetClass) => ASSET_CLASS_COLORS[i.assetClassId];
  const isSelected = (assetClass: AssetClass) => assetClassesSelected.includes(assetClass.assetClassId);

  return (
    <Card>
      <H3>{translate('allocator.instrumentsView.chosenAssetClasses')}</H3>

      {assetClassesByAssetCategory.map((assetCategory) => (
        <FlexColumn key={assetCategory.name}>
          <h4>{assetCategory.name}</h4>
          {assetCategory.assetClasses.map((assetClass) => (
            <AssetRow key={assetClass.name}>
              <Icon viewBox="0 4 20 20">{isSelected(assetClass) ? <polyline points="18 8 9 17 4 12" /> : <div />}</Icon>
              <Dot bg={getAssetColor(assetClass)} />
              <StyledAssetClass selected={isSelected(assetClass)}>{assetClass.name}</StyledAssetClass>
            </AssetRow>
          ))}
        </FlexColumn>
      ))}
    </Card>
  );
};

const H3 = styled.h3`
  margin: 0;
`;

const AssetRow = styled(FlexAlignCenter)`
  margin: 0.2rem 0;
  font-size: ${fontSize.smaller};
`;

const Icon = styled.svg`
  fill: none;
  stroke: ${colors.black};
  stroke-width: 2px;
  width: 16px;
  height: 16px;
`;

interface StyledAssetProps {
  selected: boolean;
}

const StyledAssetClass = styled.div<StyledAssetProps>`
  color: ${(props) => (props.selected ? colors.black : colors.gray_inactive)};
  transition: all 0.2s;
`;
