import React from 'react';
import { Link } from 'react-router';
import translate from 'counterpart';
import styles from './404.scss';
import img from 'images/404.svg';

interface Props {
  error?: string;
}

const Error = ({ error }: Props) => {
  let errorMsg;
  if (typeof error === 'string') {
    errorMsg = `Virhe: ${error}`;
  }
  return (
    <div className={styles.error}>
      <img src={img} />
      <p>{translate(`common.errorMessage`)}</p>

      <Link className={'button'} to={'/'}>
        <span>{translate(`common.backToFrontPage`)}</span>
      </Link>
      <div>
        <span>{errorMsg}</span>
      </div>
    </div>
  );
};

export default Error;
