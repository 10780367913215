import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { bindActionCreators, Dispatch } from 'redux';
import classNames from 'classnames';
import translate from 'counterpart';
import NewCustomerWizard from 'features/prospect/components/NewCustomerWizard';
import WizardError from 'features/prospect/components/WizardError';
import { CUSTOMER_TYPE, CUSTOMER_CATEGORISATION } from 'constants/newCustomer';
import {
  createCustomerRequest,
  createCustomerReceive,
  createCustomerFailure,
  discardCustomer,
  newCustomerOptionUpdate,
  getNewCustomerForm,
  createCustomer,
  wizardFieldValidation,
  customerTypeFailure,
  errorOk,
  errorHandler,
} from 'features/prospect/prospectActions';
import { NavBar } from 'features/common/NavBar';
import styles from './NewCustomerView.scss';
import FadeEnter from 'features/common/FadeEnter';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Spinner } from 'features/common/Spinner';
import { ProspectsState } from 'types/prospectsState';
import { RootState } from 'types/rootState';
import { Status } from 'types/types';
import { Option } from 'features/prospect/prospectTypes';

interface Props {
  createCustomer: () => void;
  newCustomerOptionUpdate: (option: Option) => void;
  getNewCustomerForm: (value: string) => void;
  statuses: Status[];
  location: Location;
  wizard: ProspectsState;
  children: React.ReactElement;
  discardCustomer: () => void;
}

interface State {
  prospects: object;
}

class NewCustomerView extends React.Component<Props, State> {
  static contextTypes = {
    location: PropTypes.object,
  };

  getRightNav = () => {
    const { wizard, createCustomer } = this.props;
    const rightBarClass = classNames(styles.rightBar, wizard.valid && !wizard.isBusy ? styles.valid : undefined);

    return (
      <div onClick={createCustomer} className={rightBarClass} data-testkey="create-new-customer-button">
        <span>{translate(`wizard.topBarButton`)}</span>
      </div>
    );
  };

  handleOptionUpdate = (option: Option) => {
    const { newCustomerOptionUpdate, getNewCustomerForm } = this.props;
    newCustomerOptionUpdate(option);
    if (option.key === CUSTOMER_TYPE) {
      getNewCustomerForm(option.value);
    }
  };

  render() {
    const { statuses, wizard, location, discardCustomer } = this.props;
    let components;
    let error;
    if (wizard.isBusy) {
      components = <Spinner className={styles.spinner} size={100} />;
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      error = wizard.error ? <WizardError /> : null;
      components = React.Children.map(this.props.children, (child) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        React.cloneElement(child, { handler: this.handleOptionUpdate, wizardState: wizard })
      );
    }
    return (
      <section className={styles.newCustomer}>
        {error}
        <NavBar
          leftContent={<span className={styles.title}>{translate(`wizard.topBarTitle`)}</span>}
          rightContent={this.getRightNav()}
          showModalOnExit={!wizard.formValues.isEmpty()}
          acceptAction={discardCustomer}
          modalTitle={translate('wizard.additionInProgress')}
          modalContent={translate('wizard.additionInProgressContent')}
        />
        <ErrorBoundary>
          <NewCustomerWizard statuses={statuses} />
          <TransitionGroup>
            <FadeEnter key={location.pathname}>
              <section className={styles.optionSection}>{components}</section>
            </FadeEnter>
          </TransitionGroup>
        </ErrorBoundary>
      </section>
    );
  }
}

function mapStateToProps(state: RootState) {
  const wizard = state.prospects;
  const values = wizard.formValues;
  return {
    wizard,
    statuses: [
      { done: values.getIn(CUSTOMER_TYPE.split('.')) },
      { done: values.getIn(CUSTOMER_CATEGORISATION.split('.')) },
      { done: wizard.valid },
    ],
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      createCustomerRequest,
      createCustomerReceive,
      createCustomerFailure,
      discardCustomer,
      newCustomerOptionUpdate,
      getNewCustomerForm,
      createCustomer,
      wizardFieldValidation,
      customerTypeFailure,
      errorOk,
      errorHandler,
    },
    dispatch
  );
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerView);
