import _, { includes } from 'lodash';
import { createSelector } from 'reselect';
import { selectCustomer } from 'features/profile/profileSelectors';
import { isIndividualFullPowerOfAttorney, isIndividualStrategy } from 'core/portfolios';
import { selectOrderLines } from 'features/orderLines/orderLinesSelectors';
import { selectPortfolioContractNameByPortfolioId, selectMultiplePortfolioIds } from 'features/orders/ordersSelectors';
import { RootState } from 'types/rootState';
import { Position } from 'types/ordersState';
import {
  CO_INVESTMENTS,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  PRIVATE_EQUITY,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  STRUCTURED_PRODUCT,
  WARRANT,
} from 'constants/instrumentForms';
import { FULL_POWER_OF_ATTORNEY, FUNDS, KOONTIRAPORTOINTI_AGREEMENT } from 'constants/contractNames';
import { ImmutableOrderLineTypeList } from 'types/orderLinesState';
import { BUY, INVALID_ORDER_SIDE } from 'constants/sides';
import { ACCOUNT } from 'constants/customerStates';
import { NOT_APPLICABLE } from 'constants/common';
import {
  TAALERI_SHOP_PORTFOLIO_CLASS,
  PLEDGED,
  ALLOWED_PORTFOLIO_STATES,
  RESTRICTED_PORTFOLIO_CLASS,
} from 'features/portfolio/portfolioConstants';
import { PortfolioDetailsById } from 'types/portfolioState';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isNumeric(n: any) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

const getPositionInfoForPortfolio = (positions: Position[]) => {
  const initialValues = {
    marketValueChange: 0,
    purchaseValue: 0,
    marketValue: 0,
    marketValueForPercentage: 0,
    purchaseValueForPercentage: 0,
    marketValueOfMoneyPositions: 0,
  };
  const positionInfo = _.reduce(
    positions,
    (result, p) => {
      let marketValue = 0;
      let purchaseValue = 0;
      let marketValueChange = 0;
      let marketValueOfMoneyPositions = 0;
      if (
        p.purchaseValue.baseCurrency &&
        p.marketValueChange.baseCurrency &&
        isNumeric(p.marketValueChange.baseCurrency.value) &&
        isNumeric(p.purchaseValue.baseCurrency.value)
      ) {
        purchaseValue = p.purchaseValue.baseCurrency.value;
        marketValueChange = p.marketValueChange.baseCurrency.value;
      }

      if (p.marketValue.baseCurrency && isNumeric(p.marketValue.baseCurrency.value)) {
        marketValue = p.marketValue.baseCurrency.value;
      }

      let marketValueForPercentage;
      if (p.financialInstrument && p.financialInstrument.financialInstrumentId === 'MONEY') {
        marketValueOfMoneyPositions = p.marketValue.baseCurrency.value;
        marketValueForPercentage = result.marketValueForPercentage;
      } else {
        marketValueForPercentage = result.marketValueForPercentage + marketValue;
      }

      return {
        purchaseValue: result.purchaseValue + purchaseValue,
        marketValue: result.marketValue + marketValue,
        marketValueChange: result.marketValueChange + marketValueChange,
        marketValueForPercentage,
        purchaseValueForPercentage: result.purchaseValueForPercentage + purchaseValue,
        marketValueOfMoneyPositions: result.marketValueOfMoneyPositions + marketValueOfMoneyPositions,
      };
    },
    initialValues
  );

  const portfolioName = positions[0] ? positions[0].portfolio.nameForCustomer : '';
  const marketValueChangePercent =
    positionInfo.purchaseValue !== 0
      ? (positionInfo.marketValueForPercentage - positionInfo.purchaseValueForPercentage) / positionInfo.purchaseValue
      : 0;

  return {
    purchaseValue: positionInfo.purchaseValue,
    purchaseValueCurrency: 'EUR',
    marketValue: positionInfo.marketValue,
    marketValueCurrency: 'EUR',
    marketValueChange: positionInfo.marketValueChange,
    marketValueChangeCurrency: 'EUR',
    marketValueOfMoneyPositions: positionInfo.marketValueOfMoneyPositions,
    marketValueOfMoneyPositionsCurrency: 'EUR',
    marketValueChangePercent: marketValueChangePercent * 100,
    portfolioName,
  };
};

const filterPositions = (positions: Position[], filter: string) =>
  positions.filter((x) => x.portfolio.portfolioId === filter);

const selectPortfolioId = (state: RootState) => _.get(state, ['routing', 'locationBeforeTransitions', 'query', 'f']);

export const selectPortfolioDetailsById = (state: RootState): PortfolioDetailsById => {
  return state.portfolio.portfolioDetailsById;
};

export const selectCurrentPortfolio = createSelector(selectPortfolioId, selectPortfolioDetailsById, (id, detailsById) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  id && id !== 'all' ? detailsById[id] : undefined
);

export const selectCurrentPortfolioById = (state: RootState, portfolioId: string) =>
  selectPortfolioDetailsById(state)[portfolioId];

export const selectIsInsurancePortfolioById = (state: RootState, portfolioId: string) =>
  _.get(selectCurrentPortfolioById(state, portfolioId), ['isInsurancePortfolio'], false);

export const selectPositionValueDate = (state: RootState) => _.get(state.portfolio, 'allPositionsInfo.valueDate');

export const selectDefaultAccount = createSelector(selectCurrentPortfolio, (portfolio) =>
  portfolio ? portfolio.defaultAccount : undefined
);

export const selectPositions = (state: RootState) => state.portfolio.positions;

const selectAllPositionsInfo = (state: RootState) => state.portfolio.allPositionsInfo;

const selectFilter = (state: RootState) => state.routing.locationBeforeTransitions.query.f;

const selectFilteredPositions = createSelector(selectPositions, selectFilter, (positions, filter) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  filter && filter !== 'all' ? filterPositions(positions, filter) : positions
);

export const getVisiblePositions = createSelector(
  selectFilteredPositions,
  selectOrderLines,
  (positions, orders): Position[] => {
    return positions.map((position) => {
      const assignment = orders
        .toJS()
        .find((order) => order.financialInstrumentId === position.financialInstrument.financialInstrumentId);
      return {
        ...position,
        side: assignment ? assignment.side : INVALID_ORDER_SIDE,
      };
    });
  }
);

const selectPositionInfoForAll = createSelector(selectAllPositionsInfo, (info) => {
  const marketValue = _.get(info, 'marketValue', 0);
  const purchaseValue = _.get(info, 'purchaseValue', 0);
  const marketValueChangePercent = _.get(info, 'marketValueChangePercent', 0);
  const marketValueChange = _.get(info, 'marketValueChange', 0);
  return {
    marketValue: marketValue && marketValue.value,
    marketValueCurrency: marketValue ? marketValue.currency : '',
    purchaseValue: purchaseValue && purchaseValue.value,
    purchaseValueCurrency: purchaseValue ? purchaseValue.currency : '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    marketValueChangePercent: marketValueChangePercent && marketValueChangePercent.value * 100,
    marketValueChange: marketValueChange && marketValueChange.value,
    marketValueChangeCurrency: marketValueChange ? marketValueChange.currency : '',
  };
});

export const selectMarketInfoByPortfolio = createSelector(
  selectPortfolioDetailsById,
  selectPositions,
  (portfolios, positions) => {
    const infos = _.map(portfolios, (portfolio) => {
      const filteredPositions = filterPositions(positions, portfolio.portfolioId);
      const marketInfo = getPositionInfoForPortfolio(filteredPositions);
      return {
        ...marketInfo,
        details: portfolios[portfolio.portfolioId],
      };
    });
    return _.keyBy(infos, (info) => info.details.portfolioId);
  }
);

export const selectVisibleMarketInfo = createSelector(
  selectPositionInfoForAll,
  selectMarketInfoByPortfolio,
  selectFilter,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  (infoForAll, portfolioInfo, filter) => (filter && filter !== 'all' ? portfolioInfo[filter] : infoForAll)
);

export const selectContractPrefilled = (state: RootState) =>
  state.orders.ordersByPortfolioId[selectCurrentPortfolio(state)?.portfolioId]?.contractPrefilled === undefined
    ? false
    : state.orders.ordersByPortfolioId[selectCurrentPortfolio(state)?.portfolioId]?.contractPrefilled;

export const selectAllContractsPrefilled = createSelector(selectMultiplePortfolioIds, (portfolioIds) =>
  portfolioIds?.every((portfolioId) =>
    portfolioId.contractPrefilled === undefined ? false : portfolioId.contractPrefilled
  )
);

const selectIsEditingExistingLine = (state: RootState) => state.orderDialog.isEditingExistingLine;

const selectCurrentPortfolioCurrency = createSelector(
  selectCurrentPortfolio,
  (pf) => Boolean(pf) && pf.withdrawalCurrency
);

const selectCurrentPortfolioClasses = createSelector(selectCurrentPortfolio, (portfolio) =>
  _.get(portfolio, ['portfolioClasses'], [])
);

const selectCurrentPortfolioClassesByPortfolioId = (state: RootState, portfolioId: string) =>
  _.get(selectCurrentPortfolioById(state, portfolioId), ['portfolioClasses'], []);

export const selectHasRestrictedPortfolioClass = createSelector(selectCurrentPortfolioClasses, (classes) =>
  classes.includes(RESTRICTED_PORTFOLIO_CLASS)
);

export const selectHasRestrictedPortfolioClassById = (state: RootState, portfolioId: string) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  selectCurrentPortfolioClassesByPortfolioId(state, portfolioId).includes(RESTRICTED_PORTFOLIO_CLASS);

export const canDoAssignments = (
  filter: string,
  contractIsReady: boolean,
  state: string,
  contractName: string,
  strategy: string,
  customerState: string,
  orderLines: ImmutableOrderLineTypeList,
  isEditingExistingLine: boolean,
  portfolioCurrency: string,
  portfolioClasses: string[]
) => {
  return (
    portfolioIsSelected(filter) &&
    contractIsReady &&
    portfolioHasAllowedState(state) &&
    portfolioHasCorrectContractAndStrategy(contractName, strategy) &&
    canAddMoreThanOneOrderLine(orderLines, isEditingExistingLine) &&
    customerState === ACCOUNT &&
    portfolioCurrency !== NOT_APPLICABLE &&
    !isPortfolioClassForbidden(portfolioClasses)
  );
};

export const selectCanDoAssignments = (state: RootState) => {
  const id = selectPortfolioId(state) as string;
  const contractReady = selectContractPrefilled(state);
  const selectedPortfolio = selectCurrentPortfolio(state);

  const portfolioState = selectedPortfolio?.state;
  const customer = selectCustomer(state);
  const customerState = customer.get('state') ?? '';
  const orderLines = selectOrderLines(state);
  const isEditingExistingLine = selectIsEditingExistingLine(state);
  const portfolioCurrency = selectCurrentPortfolioCurrency(state);
  const classes = selectCurrentPortfolioClasses(state);
  const contractName = selectedPortfolio?.contractName;
  const strategyName = selectedPortfolio?.strategyName;

  return canDoAssignments(
    id,
    contractReady,
    portfolioState,
    contractName,
    strategyName,
    customerState,
    orderLines,
    isEditingExistingLine,
    portfolioCurrency,
    classes
  );
};

export const selectPortfolioContractName = createSelector(
  selectCurrentPortfolio,
  (portfolio) => portfolio.contractName
);

export const selectIsPortfolioPledged = createSelector(
  selectCurrentPortfolio,
  (selectedPortfolio) => !!selectedPortfolio && selectedPortfolio.state === PLEDGED
);

const selectPortfolioStrategyByPortfolioId = (state: RootState, portfolioId: string) =>
  state.orders.ordersByPortfolioId[portfolioId]?.portfolioStrategy;

export const selectIsIndividualFullPowerOfAttorneyByPortfolioId = (state: RootState, portfolioId: string) =>
  isIndividualFullPowerOfAttorney(
    selectPortfolioContractNameByPortfolioId(state, portfolioId),
    selectPortfolioStrategyByPortfolioId(state, portfolioId)
  );

const portfolioIsSelected = (id: string) => !!id && id !== 'all';

export const portfolioHasAllowedState = (state: string) => includes(ALLOWED_PORTFOLIO_STATES, state?.toLowerCase());

export const portfolioHasCorrectContractAndStrategy = (contractName: string, strategy: string) =>
  (contractName !== FULL_POWER_OF_ATTORNEY || isIndividualStrategy(strategy)) &&
  contractName !== KOONTIRAPORTOINTI_AGREEMENT &&
  contractName !== FUNDS;

const canAddMoreThanOneOrderLine = (orderLines: ImmutableOrderLineTypeList, isEditingExistingLine: boolean) => {
  return (
    isEditingExistingLine ||
    orderLines.toJS().every((orderLine) => {
      const { side, financialInstrumentForm: form } = orderLine;
      return (
        (form !== STRUCTURED_PRODUCT || side !== BUY) &&
        (form !== WARRANT || side !== BUY) &&
        form !== PRIVATE_EQUITY &&
        form !== CO_INVESTMENTS &&
        form !== PRIVATE_EQUITY_PROFIT_SHARING_LOAN &&
        form !== CO_INVESTMENTS_PROFIT_SHARING_LOAN
      );
    })
  );
};

export const isPortfolioClassForbidden = (classes: string[]) => classes?.includes(TAALERI_SHOP_PORTFOLIO_CLASS);

export const getNonOutsidePortfolioIds = (state: RootState) => {
  const ownFunds = state.portfolio.portfolios?.toJS().OwnFunds?.map((p) => p.id) || [];
  const insuranceFunds = state.portfolio.portfolios?.toJS().InsuranceFunds?.map((p) => p.id) || [];
  const otherFunds = state.portfolio.portfolios?.toJS().OtherFunds?.map((p) => p.id) || [];
  return ownFunds.concat(insuranceFunds).concat(otherFunds);
};
