import React from 'react';
import translate from 'counterpart';
import { Static } from './Static';
import { Daily } from './Daily';
import styles from './Summary.scss';
import { PortfolioDetailsById, MarketInfoByPortfolio } from 'types/portfolioState';

interface Props {
  portfolioDetailsById: PortfolioDetailsById;
  marketInfoByPortfolio: MarketInfoByPortfolio;
  visible: boolean;
}

export const Summary = ({ portfolioDetailsById, marketInfoByPortfolio, visible }: Props) => {
  if (!visible) {
    return null;
  }

  return (
    <section className={styles.summary}>
      <header>{translate(`order.summaryOfPortfolios`)}</header>
      <div className={styles.subHeader}>{translate(`order.basicInfo`)}</div>
      <Static portfolioDetailsById={portfolioDetailsById} />
      <div className={styles.subHeader}>{translate(`order.dailyInfo`)}</div>
      <Daily portfolioDetailsById={portfolioDetailsById} marketInfoByPortfolio={marketInfoByPortfolio} />
    </section>
  );
};
