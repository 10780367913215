import React, { CSSProperties } from 'react';
import _ from 'lodash';
import translate from 'counterpart';
import { PositionList } from './PositionList';
import { Position } from 'types/ordersState';
import { PortfolioDetailsById, MarketInfo } from 'types/portfolioState';
import { EditorWithSuitabilityErrors } from 'types/orderDialogState';

interface Props {
  positions: Position[];
  onPositionClick: (assignment: EditorWithSuitabilityErrors) => void;
  marketInfo: MarketInfo;
  portfolioDetailsById: PortfolioDetailsById;
}

export const FilteredPositionList = ({ positions, marketInfo, onPositionClick, portfolioDetailsById }: Props) => {
  let components;
  if (positions && positions.length) {
    const group = _.toPairs(_.groupBy(positions, (x) => x.financialInstrument.assetClass));
    // sort by asset class name
    const sortedGroups = _.sortBy(group, [(g) => g[0]]);
    components = sortedGroups.map((g, gi) => {
      let header = g[0]; // assetClass name
      if (header === null || header === 'null') {
        // assetClass is "null", get it from the instrument itself
        const position = g[1];
        header = position[0]?.financialInstrument.securityType;
      }
      const positionsByAssetCategory = _.sortBy(g[1], (x) => x.financialInstrument.assetCategory);
      const positionsSubGroup = _.sortBy(
        _.toPairs(_.groupBy(positionsByAssetCategory, (p) => p.financialInstrument.assetCategory)),
        [(s) => s[0]]
      );
      const grouppedLists = positionsSubGroup.map((p, pi) => {
        let subHeader = p[0]; // assetCategory name
        if (subHeader === null || subHeader === 'null') {
          subHeader = ''; // do not print literal "null"...
        }
        const sortedPositions = _.sortBy(p[1], [(s) => s.financialInstrument.name]);
        return (
          <PositionList
            headerText={subHeader}
            positions={sortedPositions}
            marketInfo={marketInfo}
            onPositionClick={onPositionClick}
            portfolioDetailsById={portfolioDetailsById}
            key={pi}
          />
        );
      });
      const headerStyles: CSSProperties = {
        textTransform: 'none',
        borderBottom: '1px solid #d4cdbe',
        margin: '0.5rem 1rem 0 1rem',
        fontWeight: 500,
        fontSize: 18,
      };
      return (
        <section key={gi}>
          <header style={headerStyles}>{header}</header>
          {grouppedLists}
        </section>
      );
    });
  } else {
    components = (
      <section style={{ padding: '5rem 0', display: 'flex', justifyContent: 'center' }}>
        <div>{translate('portfolios.noPositions')}</div>
      </section>
    );
  }
  return <section>{components}</section>;
};
