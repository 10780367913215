import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AssignmentEditor } from 'features/orderDialog/components/AssignmentEditor';
import { selectEditor, selectIsOrderValid } from 'features/orderDialog/orderDialogSelectors';
import { initEditor, changePortfolio } from 'features/orderDialog/orderDialogActions';
import { selectCurrentPortfolio } from 'features/portfolio/portfolioSelectors';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { RootState } from 'types/rootState';
import { useDidUpdateEffect } from 'features/allocator/allocatorUtils';
import { useAppDispatch } from 'core/hooks';

interface Props {
  filter: string;
  customerId: string;
  assignmentsAllowed: boolean;
}

export const AssignmentEditorView = ({ filter, customerId, assignmentsAllowed }: Props) => {
  const dispatch = useAppDispatch();

  const editor = useSelector(selectEditor).toJS();
  const validationErrors = useSelector((state: RootState) => state.orderDialog.validationErrors);
  const isOrderValid = useSelector(selectIsOrderValid);
  const maxReceivedFromClientDate = useSelector((state: RootState) => state.orderDialog.maxReceivedFromClientDate);
  const isEditingExistingLine = useSelector((state: RootState) => state.orderDialog.isEditingExistingLine);
  const selectedPortfolio = useSelector(selectCurrentPortfolio);
  const suitabilityErrors = useSelector((state: RootState) => state.orderDialog.suitabilityErrors);
  const dialogType = useSelector((state: RootState) => state.orderDialog.dialogType);
  const isPreviousOrderDraftSuitable = useSelector(
    (state: RootState) => state.orderDialog.isPreviousOrderDraftSuitable
  );
  const orderInitializerTypes = useSelector((state: RootState) => state.orderDialog.orderInitializerTypes);
  const orderBasisTypes = useSelector((state: RootState) => state.orderDialog.orderBasisTypes);
  const portfolioId = selectedPortfolio?.portfolioId;
  const contractType = useSelector(
    (state: RootState) => state.portfolio?.portfolioDetailsById[portfolioId]?.contractType
  );
  useEffect(() => {
    if (!isEditingExistingLine && portfolioId) {
      dispatch(initEditor({ portfolioId, isBuyingNew: true, isEditingExistingLine: false }));
    }
  }, [portfolioId]);

  useDidUpdateEffect(() => {
    filter !== 'all' && dispatch(changePortfolio(filter));
  }, [filter]);

  if (!assignmentsAllowed || !portfolioId) {
    return null;
  }

  return (
    <ErrorBoundary>
      <AssignmentEditor
        editor={editor}
        selectedPortfolio={selectedPortfolio}
        customerId={customerId}
        maxReceivedFromClientDate={maxReceivedFromClientDate}
        validationErrors={validationErrors}
        isEditingExistingLine={isEditingExistingLine}
        isOrderValid={isOrderValid}
        orderInitializerTypes={orderInitializerTypes}
        suitabilityErrors={suitabilityErrors}
        isPreviousOrderDraftSuitable={isPreviousOrderDraftSuitable}
        dialogType={dialogType}
        orderBasisTypes={orderBasisTypes}
        contractType={contractType}
      />
    </ErrorBoundary>
  );
};
