import React from 'react';
import classnames from 'classnames/bind';
import styles from './SaveButton.scss';

interface Props {
  disabled?: boolean;
  onClick: () => void;
  label: string | object;
  style?: object;
  tabIndex?: string;
  'data-testkey'?: string;
}

const SaveButton = ({ disabled, onClick, label, tabIndex, 'data-testkey': dataTestKey, ...rest }: Props) => {
  const cx = classnames.bind(styles);
  const classes = cx('button');

  return (
    <button
      data-testkey={dataTestKey ?? 'save-button'}
      onClick={onClick}
      className={classes}
      disabled={disabled}
      tabIndex={tabIndex ? parseInt(tabIndex, 10) : undefined}
      {...rest}
    >
      {label}
    </button>
  );
};

export default SaveButton;
