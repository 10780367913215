import { emptyWeights } from 'constants/allocator';
import { WithWithoutIlliquidWeights } from 'features/weights/weightTypes';
import {
  ExistingCustomerAssetCategoryWeights,
  ExistingCustomerAssetClassWithWeight,
  ExistingCustomerOptimizePlanResponse,
  ExistingCustomerOptimizePlanResponseUnit,
  ExistingCustomerPortfolioInstrument,
  ExistingCustomerPortfolioWeights,
} from 'types/existingCustomerOptimization';
import { PortfolioInstrument } from 'types/instrumentsState';
import { AssetCategoryWeights, AssetClassWithWeight, PortfolioWeights, Weights } from 'types/weightsState';

export const mapExistingCustomerWeights = (
  result: ExistingCustomerOptimizePlanResponse
): WithWithoutIlliquidWeights => {
  return {
    withIlliquids:
      result.withIlliquids?.success === 1 ? mapExistingCustomerReturnStatistics(result.withIlliquids) : emptyWeights,
    withoutIlliquids:
      result.withoutIlliquids?.success === 1
        ? mapExistingCustomerReturnStatistics(result.withoutIlliquids)
        : emptyWeights,
  };
};

const mapExistingCustomerReturnStatistics = (weights: ExistingCustomerOptimizePlanResponseUnit): Weights => {
  return {
    assetCategoryWeights: existingCustomerAssetCategoryWeightsMapper(weights.assetCategoryWeights.assetCategories),
    instrumentWeights: existingCustomerInstrumentWeightsMapper(weights.instrumentWeights.instruments),
    portfolioWeights: existingCustomerPortfolioWeightsMapper(weights.portfolioWeights.portfolios),
    returnStatistics: {
      portfolioReturn: weights.portfolioReturn,
      portfolioVolatility: weights.portfolioVolatility,
    },
    confidenceLevels: weights.confidenceLevels,
  };
};

const existingCustomerAssetCategoryWeightsMapper = (
  assetCategories: ExistingCustomerAssetCategoryWeights[]
): AssetCategoryWeights[] => {
  return assetCategories.map((assetCategory) => {
    return {
      name: assetCategory.assetCategory,
      weight: assetCategory.weight,
      assetClasses: existingCustomerAssetClassWeightsMapper(assetCategory.assetClasses),
    };
  });
};

const existingCustomerAssetClassWeightsMapper = (
  assetClasses: ExistingCustomerAssetClassWithWeight[]
): AssetClassWithWeight[] => {
  return assetClasses.map((assetClass) => {
    return {
      assetClassId: assetClass.assetClassId,
      weight: assetClass.weight,
      assetCategory: assetClass.assetCategory,
    };
  });
};

const existingCustomerInstrumentWeightsMapper = (
  instruments: ExistingCustomerPortfolioInstrument[]
): PortfolioInstrument[] => {
  return instruments.map((instrument) => {
    return {
      assetCategory: instrument.assetCategory,
      assetClasses: instrument.assetClasses,
      assetClassId: instrument.assetClassId,
      liquidity: instrument.liquidity,
      name: instrument.name,
      security: instrument.security,
      type: instrument.type,
      marketValue: instrument.optimalBaseCcyMarketValue,
      weight: instrument.weight,
      portfolioId: instrument.portfolioId,
      portfolioCurrencyMarketValue: instrument.optimalPortfolioCcyMarketValue,
    };
  });
};

const existingCustomerPortfolioWeightsMapper = (portfolios: ExistingCustomerPortfolioWeights[]): PortfolioWeights[] => {
  return portfolios.map((portfolio) => {
    return {
      portfolioId: portfolio.portfolioId,
      instruments: existingCustomerInstrumentWeightsMapper(portfolio.instruments),
      portfolioCurrency: portfolio.instruments[0].portfolioCurrency, // Take currency from first instrument
    };
  });
};
