import common from './sv/common';
import contract from './sv/contract';
import newContract from './sv/newContract';
import order from './sv/order';
import validations from './sv/validations';

export default {
  common,
  contract,
  newContract,
  order,
  validations,
};
