import React from 'react';
import { VictoryChart, VictoryStack, VictoryArea, VictoryLine, VictoryAxis } from 'victory';
import styled from 'styled-components';
import moment from 'moment';
import { styles } from './chartStyles';
import { ConfidenceLevel, ConfidenceLevels } from 'types/weightsState';

type Tuple = [number, number] | [Date, Date];

interface Props {
  confidenceLevels: ConfidenceLevels;
  startValue: number;
}

export const ProjectionChart = ({ confidenceLevels, startValue }: Props) => {
  const CHART_YEARS = 16;
  const xAxisTickCount = 9;
  const yAxisTickCount = 10;
  const yAxisMinimumValuePercentage = 0.8;

  const hasStartValue = startValue !== 100;

  const scaleByFirstValue = (i: ConfidenceLevel) => ({ ...i, itemValue: (i.itemValue * startValue) / 100 });
  const toXYPairs = (arr: ConfidenceLevel[]) => arr?.map((e) => ({ x: new Date(e.date), y: e.itemValue }));

  if (confidenceLevels.expectedValue.length === 0) {
    return null;
  }

  const averageValues = confidenceLevels.expectedValue.map(scaleByFirstValue);
  const highestValues = confidenceLevels?.upperConfidenceLevel80.map(scaleByFirstValue);
  const highValues = confidenceLevels?.upperConfidenceLevel60.map(scaleByFirstValue);
  const lowValues = confidenceLevels?.lowerConfidenceLevel60.map(scaleByFirstValue);
  const lowestValues = confidenceLevels?.lowerConfidenceLevel80.map(scaleByFirstValue);
  const varValues = confidenceLevels?.lowerConfidenceLevel90.map(scaleByFirstValue);

  const averageLine = toXYPairs(averageValues);
  const lowestVaRLine = toXYPairs(varValues);

  const yMax = Math.ceil(highValues[CHART_YEARS]?.itemValue / 100_000) * 100_000;
  const yMin = Math.floor(averageLine[0]?.y / 100_000) * 100_000;

  const yAxisSpacing = (yMax - yMin) / yAxisTickCount;

  const yAxisMax = hasStartValue ? yMax : highValues[CHART_YEARS]?.itemValue;
  const yAxisMin = hasStartValue
    ? Math.floor(yMin / yAxisSpacing) * yAxisSpacing * yAxisMinimumValuePercentage
    : yAxisMinimumValuePercentage * 100;

  const yieldDomain = [yAxisMin, yAxisMax];

  const firstDate = averageLine[0].x;
  const lastDate = averageLine[CHART_YEARS].x;
  const elapsedTime = new Date(lastDate.getTime() - firstDate.getTime());

  const wide = !highestValues
    ? null
    : lowestValues?.map((itemLow, index: number) => ({
        x: new Date(itemLow.date),
        y0: itemLow.itemValue,
        y: highestValues[index].itemValue - itemLow.itemValue,
      }));

  const narrow = !highValues
    ? null
    : lowValues?.map((itemLow, index: number) => ({
        x: new Date(itemLow.date),
        y0: itemLow.itemValue,
        y: highValues[index].itemValue - itemLow.itemValue,
      }));

  const timeTickValues = Array(xAxisTickCount)
    .fill(1)
    .map((_i, index) => new Date(firstDate.getTime() + (elapsedTime.getTime() * index) / (xAxisTickCount - 1)));

  const getDateLabelWithYear = (t: string): string => moment(t).format('YYYY');

  return (
    <ChartContainer>
      <VictoryChart height={styles.height} padding={styles.padding}>
        {wide && (
          <VictoryStack>
            <VictoryArea
              interpolation="natural"
              style={styles.wideCone}
              standalone={true}
              data={wide}
              domain={{
                x: [new Date(firstDate.getTime()), new Date(firstDate.getTime() + elapsedTime.getTime())] as Tuple,
                y: yieldDomain as Tuple,
              }}
              scale={{ x: 'time', y: 'linear' }}
            />
          </VictoryStack>
        )}

        {narrow && (
          <VictoryStack>
            <VictoryArea
              interpolation="natural"
              style={styles.narrowCone}
              standalone={true}
              data={narrow}
              domain={{
                x: [new Date(firstDate.getTime()), new Date(firstDate.getTime() + elapsedTime.getTime())] as Tuple,
                y: yieldDomain as Tuple,
              }}
              scale={{ x: 'time', y: 'linear' }}
            />
          </VictoryStack>
        )}

        {/* average line */}
        <VictoryLine
          interpolation="natural"
          data={averageLine}
          style={styles.averageLine}
          standalone={true}
          domain={{
            x: [new Date(firstDate.getTime()), new Date(firstDate.getTime() + elapsedTime.getTime())] as Tuple,
            y: yieldDomain as Tuple,
          }}
          scale={{ x: 'time', y: 'linear' }}
        />

        {lowestVaRLine && (
          <VictoryLine
            interpolation="natural"
            data={lowestVaRLine}
            style={styles.varLine}
            standalone={true}
            domain={{
              x: [new Date(firstDate.getTime()), new Date(firstDate.getTime() + elapsedTime.getTime())] as Tuple,
              y: yieldDomain as Tuple,
            }}
            scale={{ x: 'time', y: 'linear' }}
          />
        )}

        {/* X axis years */}
        <VictoryAxis
          crossAxis
          style={styles.xAxis}
          offsetY={0}
          tickFormat={getDateLabelWithYear}
          tickValues={timeTickValues}
        />

        {/* Y axis values */}
        <VictoryAxis
          dependentAxis
          crossAxis
          orientation={'left'}
          style={styles.yAxis}
          tickCount={12}
          minDomain={{ y: 0 }}
          tickFormat={(value) => (startValue !== 100 ? `${(value / 1000).toLocaleString('fi')} k€` : value)}
        />
      </VictoryChart>
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  flex: 3;
`;
