import { OrderLineType } from 'types/ordersState';
import { InstrumentSuitabilityError } from 'types/portfolioManagerState';

export const INITIALIZE_VALUE_DATA = 'INITIALIZE_VALUE_DATA';
export const SET_AUM = 'SET_AUM';
export const SET_CASH_TRADE_AMOUNT = 'SET_CASH_TRADE_AMOUNT';
export const SET_TRADE_ORDER_LINES = 'SET_TRADE_ORDER_LINES';
export const SET_SUITABILITY_ERRORS_TRADE_VIEW = 'SET_SUITABILITY_ERRORS_TRADE_VIEW';

export const initializeValueData = () => {
  return <const>{
    type: INITIALIZE_VALUE_DATA,
  };
};

export const setAUM = (result: number) => {
  return <const>{
    type: SET_AUM,
    result,
  };
};

export const setCashTradeAmount = (result: number) => {
  return <const>{
    type: SET_CASH_TRADE_AMOUNT,
    result,
  };
};

export const setTradeOrderLines = (tradeOrderLines: OrderLineType[]) => {
  return <const>{
    type: SET_TRADE_ORDER_LINES,
    tradeOrderLines,
  };
};

export const setSuitabilityErrors = (suitabilityErrors: InstrumentSuitabilityError[]) => {
  return <const>{
    type: SET_SUITABILITY_ERRORS_TRADE_VIEW,
    suitabilityErrors,
  };
};

export type ValueDataAction = ReturnType<
  | typeof initializeValueData
  | typeof setAUM
  | typeof setCashTradeAmount
  | typeof setTradeOrderLines
  | typeof setSuitabilityErrors
>;
