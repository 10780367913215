export const minutesOfInvestmentServiceSchema = {
  properties: {
    date: {
      type: 'string',
      format: 'date-time',
      minLength: 1,
    },
    participants: {
      type: 'string',
      minLength: 1,
    },
    convener: {
      type: 'string',
      minLength: 1,
    },
    location: {
      type: 'string',
      minLength: 1,
    },
    info: {
      type: 'string',
      minLength: 1,
    },
  },
  required: ['date', 'participants', 'convener', 'location', 'info'],
};
