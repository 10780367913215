export default {
  placeholder: 'Etsi sukunimellä, etunimellä, henkilötunnuksella tai asiakasnumerolla (vähintään 4 merkkiä)',
  empty: 'Tyhjennä',
  noLatest: 'Ei etsittyjä asiakkaita',
  lastSearched: 'Viimeksi etsityt',
  lastAdded: 'Viimeksi lisätyt',
  lastMet: 'Viimeksi tavatut',
  resultCount: 'Hakutuloksia %(count)s',
  noResults: 'Ei hakutuloksia',
  all: 'Kaikki',
  own: 'Omat',
  meeting: 'Tapaaminen',
  call: 'Puhelu',
  allocator: 'Allokaatiotyökalu',
  portfolios: 'Salkut',
};
