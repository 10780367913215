export const FULL_POWER_OF_ATTORNEY = 'Täyden valtakirjan sopimus';
export const CONSULTATIVE_AGREEMENT = 'Konsultatiivinen sopimus';
export const KOONTIRAPORTOINTI_AGREEMENT = 'Koontiraportointisopimus';
export const SECURITIES_BROKERAGE_CONTRACT = 'Arvopaperinvälityssopimus';
export const ORDER_ADVICE = 'Toimeksianto / Sijoitusneuvonta';
export const FUNDS = 'Sijoitusrahastot';
export const NO_CONTRACT = 'Tehtaan salkku';
export const DIGI_CONSULTATIVE_AGREEMENT = 'Digikonsultatiivinen sopimus';
export const TAALERI_CONTRACT = 'Taaleri Salkku';
export const OWN_FUND_CONTRACT = 'Omat rahastot';
export const CUSTOMER_RELATIONSHIP_AGREEMENT = 'Asiakassuhdesopimus';
export const CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE = 'CustomerRelationshipAgreement';
export const CONSULTATIVE_AGREEMENT_TYPE = 'ConsultativeAssetManagement';
export const SECURITIES_BROKERAGE_CONTRACT_TYPE = 'TradingAndExecutionAgreement';
export const FULL_POWER_OF_ATTORNEY_TYPE = 'DiscretionaryAssetManagement';

export const ORDER_CONTRACT_TYPES = [
  FULL_POWER_OF_ATTORNEY,
  CONSULTATIVE_AGREEMENT,
  NO_CONTRACT,
  SECURITIES_BROKERAGE_CONTRACT,
  DIGI_CONSULTATIVE_AGREEMENT,
  TAALERI_CONTRACT,
  OWN_FUND_CONTRACT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
];

export const WRITTEN_ORDER_REQUIRES_SIGNATURE = [
  CONSULTATIVE_AGREEMENT,
  SECURITIES_BROKERAGE_CONTRACT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
];
