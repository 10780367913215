import React from 'react';
import { SelectWithLabel } from './SelectWithLabel';
import { buyFunding } from 'constants/shareOptions';
import { SelectValue } from 'types/types';

interface Props {
  options: typeof buyFunding;
  onChange: (input: SelectValue) => void;
  value: string | undefined;
  label: string;
  clearable: boolean;
  className: string;
  'data-testkey'?: string;
}

export const SelectFinancing = ({
  options,
  onChange,
  value,
  clearable,
  label,
  className,
  'data-testkey': dataTestKey,
}: Props) => (
  <SelectWithLabel
    options={options}
    onChange={onChange}
    value={value}
    clearable={clearable}
    label={label}
    className={className}
    data-testkey={dataTestKey}
  />
);
