import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { SelectWithLabel } from './SelectWithLabel';
import { changeInstrumentForm } from 'features/orderDialog/orderDialogActions';
import { SelectValue } from 'types/types';
import { selectInstrumentFormOptions } from 'features/orderDialog/orderDialogSelectors';
import { RootState } from 'types/rootState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  instrumentForm: string;
  className: string;
  focusRef: React.MutableRefObject<HTMLElement | undefined>;
  orderLineId: number;
}

export const SelectInstrumentForm = ({ instrumentForm, focusRef, className, orderLineId }: Props) => {
  const dispatch = useAppDispatch();
  const instrumentFormOptions = useSelector((state: RootState) => selectInstrumentFormOptions(state, orderLineId));
  const onChange = (event: SelectValue) => {
    dispatch(changeInstrumentForm(event));
  };

  return (
    <SelectWithLabel
      options={instrumentFormOptions}
      onChange={onChange}
      value={instrumentForm}
      label={translate('order.instrumentForm')}
      focusRef={focusRef}
      className={className}
      data-testkey="order-instrument-form"
    />
  );
};
