import React, { CSSProperties } from 'react';
import Paper from '@mui/material/Paper';

interface Props {
  style?: CSSProperties;
  elevation?: number;
  children: object;
}

export const Sheet = ({ style = {}, elevation, children }: Props) => {
  const styles = Object.assign({}, defaultStyles, style);
  return (
    <Paper style={styles} elevation={elevation}>
      {children}
    </Paper>
  );
};

const defaultStyles = {
  margin: '20px',
  padding: '10px',
  backgroundColor: '#fefefe',
};
