import React from 'react';
import { groupBy } from 'lodash';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { DistributionTable } from 'features/allocator/common/DistributionTable';
import { AssetCategoryName, CompareToType, ItemType } from 'types/types';
import { InstrumentWithWeight } from 'types/instrumentsState';
import { ASSET_CATEGORY_ORDER } from 'constants/allocator';
import { Spinner } from 'features/common/Spinner';
import { Card } from 'features/allocator/common/Card';
import { selectAssetClasses } from 'features/allocator/allocatorSelectors';

interface Props {
  instruments: InstrumentWithWeight[];
  compareTo: CompareToType;
  isIgnoredPortfolio?: boolean;
  loading?: boolean;
  itemType: ItemType;
}

export const InstrumentDistribution = ({
  instruments,
  compareTo,
  isIgnoredPortfolio,
  loading = false,
  itemType,
}: Props) => {
  const assetClasses = useSelector(selectAssetClasses);
  const groupedByAssetCategory = groupBy(instruments, 'assetCategory');
  const assetCategories = Object.keys(groupedByAssetCategory) as AssetCategoryName[];
  const sortedAssetCategories = assetCategories.sort(
    (a, b) => ASSET_CATEGORY_ORDER.indexOf(a) - ASSET_CATEGORY_ORDER.indexOf(b)
  );

  return (
    <GridCard>
      {loading ? (
        <Spinner />
      ) : (
        sortedAssetCategories.map((assetCategory) => {
          const assetCategoryInstruments = instruments?.filter((i) => i?.assetCategory === assetCategory);
          const assetClassesOrder = assetClasses.map((i) => i.assetClassId);
          const sortedAssetCategoryInstruments = assetCategoryInstruments.sort(
            (a, b) => assetClassesOrder.indexOf(a.assetClassId) - assetClassesOrder.indexOf(b.assetClassId)
          );

          if (!assetCategoryInstruments.length) {
            return null;
          }

          return (
            <DistributionTable
              key={assetCategory}
              header={assetCategory}
              items={sortedAssetCategoryInstruments}
              itemType={itemType}
              data-testkey="instrument-distribution"
              compareTo={compareTo}
              isIgnoredPortfolio={isIgnoredPortfolio}
            />
          );
        })
      )}
    </GridCard>
  );
};

const GridCard = styled(Card)`
  display: grid;
  grid-gap: 1rem;
`;
