import React, { ChangeEvent } from 'react';
import translate from 'counterpart';
import styles from './SearchBar.scss';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  searchChange: (value: string) => void;
  onClear: () => void;
  query: string;
}

export default class SearchBar extends React.Component<Props> {
  handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const { searchChange } = this.props;
    searchChange(ev.target.value);
  };
  render() {
    const { onClear, query } = this.props;
    const disabled = !query || !query.length;
    const placeholder = translate('search.placeholder');
    return (
      <nav className={styles.searchMenu}>
        <input
          type="text"
          value={query}
          onChange={this.handleChange}
          autoCorrect="off"
          autoComplete="off"
          placeholder={placeholder}
          data-testkey="customer-search-input"
        />
        <StyledButton
          style={{ color: '#000', position: 'absolute', right: '1.5rem', fontFamily: 'Calibre' }}
          onClick={onClear}
          disabled={disabled}
        >
          <span>{translate(`search.empty`)}</span>
        </StyledButton>
      </nav>
    );
  }
}
