import React, { useEffect, useReducer, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import translate from 'counterpart';
import { useLocalStorage } from 'react-use';
import { debounce } from 'lodash';
import { Location } from 'history';
import { RootState } from 'types/rootState';
import { customerHeaderHeight, FlexAlignCenter, styles, TextInput } from 'features/common/StyledComponents';
import { Spinner } from 'features/common/Spinner';
import { resetCustomerState } from 'features/allocator/allocatorUtils';
import { config } from 'config';
import { CustomerList } from 'features/portfolioManager/customerList/components/CustomerList';
import {
  handleCustomerBatchResponse,
  setFetching,
  setBanker,
  setCustomers,
  setFilter,
  setInput,
  setSort,
} from 'features/portfolioManager/customerList/customerListComponentActions';
import { colors } from 'styles/colors';
import {
  CustomerListComponentState,
  Customers,
  SearchResultCustomer,
  GetCustomersBatchResponse,
  CustomerKind,
} from 'types/customerListState';
import { listReducer } from 'features/portfolioManager/customerList/customerListComponentReducer';
import { BankerList } from 'features/portfolioManager/customerList/components/BankerList';
import { clearError, setError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import {
  customerValidationErrorMapper,
  getCustomerBatchStatusApiCall,
  getQueryBankerId,
} from 'features/portfolioManager/customerList/customerListUtils';
import { cancelPromise } from 'features/weights/weightsApiCalls';
import { useAppDispatch } from 'core/hooks';

interface Props {
  location: Location;
}

export const CustomerListView = ({ location }: Props) => {
  const dispatchToRedux = useAppDispatch();
  const accessToken = useSelector((state: RootState) => state.oidc.user.access_token);
  const myBankerId = useSelector((state: RootState) => state.oidc.user.profile.sub);

  const initialListState: CustomerListComponentState = {
    inputText: '',
    sortByKey: 'riskDifference',
    sortedCustomers: {},
    bankerId: getQueryBankerId(location) ?? myBankerId,
    isFetching: false,
    filter: '',
  };

  const [state, dispatchToState] = useReducer(listReducer, initialListState);

  const { sortedCustomers, isFetching, bankerId, inputText, sortByKey } = state;

  // Local storage handling

  const [localStorageCustomers, setLocalStorageCustomers] = useLocalStorage<Customers>('localCustomers');

  const setToLocalStorage = (customers: SearchResultCustomer[]) => {
    const newCustomers = {
      ...localStorageCustomers,
      [bankerId]: customers,
    };
    setLocalStorageCustomers(newCustomers);
  };

  const loadCustomersFromLocalStorage = () => {
    if (localStorageCustomers && bankerId && localStorageCustomers[bankerId]) {
      const localStorageResponse = localStorageCustomers[bankerId];
      const validationResult = GetCustomersBatchResponse.decode(localStorageResponse);
      const errors = customerValidationErrorMapper(validationResult);
      if (errors.length === 0) {
        dispatchToState(setCustomers(localStorageCustomers[bankerId]));
      }
    }
  };

  useEffect(() => {
    dispatchToRedux(resetCustomerState());
    loadCustomersFromLocalStorage();
  }, []);

  // Handle banker change

  useEffect(() => {
    const loadData = async () => {
      if (bankerId) {
        loadCustomersFromLocalStorage();
        await getCustomersBatch();
      }
    };
    void loadData();
  }, [bankerId]);

  const getCustomersBatch = async () => {
    dispatchToState(setFetching(true));
    dispatchToRedux(clearError(errorKeys.fetchBankersCustomers));
    cancelPromise('getBankersCustomersBatch');
    try {
      const batchCustomers = await getCustomerBatchStatusApiCall(bankerId, accessToken);
      const convertedCustomers = handleCustomerBatchResponse(batchCustomers);
      dispatchToState(setCustomers(convertedCustomers));
      setToLocalStorage(convertedCustomers);
    } catch (error) {
      dispatchToRedux(setError({ context: errorKeys.fetchBankersCustomers }));
    } finally {
      dispatchToState(setFetching(false));
    }
  };

  const useBanker = (e: React.SyntheticEvent) => {
    const el = e.target as HTMLAnchorElement;
    const bankerId = el.innerHTML;
    dispatchToState(setBanker(bankerId));
  };

  // Search input handling

  const onTypeSearchInput = (e: React.SyntheticEvent) => {
    const el = e.target as HTMLInputElement;
    const value = el.value;
    dispatchToState(setInput(value));
  };

  useEffect(() => {
    debounceSearch.current(inputText);
  }, [inputText]);

  const debounceSearch = useRef(
    debounce((searchInput) => {
      dispatchToState(setFilter(searchInput));
    }, 150)
  );

  const setSortBy = (key: keyof SearchResultCustomer) => dispatchToState(setSort(key));

  const withPlan = sortedCustomers[bankerId]?.filter((i) => i.kind === 'withPlan');
  const withoutPlan = sortedCustomers[bankerId]?.filter((i) => i.kind === 'withoutPlan');
  const withPlanNoPositions = sortedCustomers[bankerId]?.filter((i) => i.kind === 'withPlanNoPositions');
  const withErrors = sortedCustomers[bankerId]?.filter((i) => i.kind === 'withErrors');

  const customers = {
    withPlan,
    withoutPlan,
    withPlanNoPositions,
    withErrors,
  };

  const totalCustomerCount = sortedCustomers[bankerId]?.length || 0;

  return (
    <>
      {config.customerListBankers && (
        <BankerList myBankerId={myBankerId} bankerId={state.bankerId} useBanker={useBanker} />
      )}

      <Container>
        <h1>
          {translate('portfolioManager.customerList.title')} ({totalCustomerCount})
        </h1>
        <InputContainer>
          <CustomerFilter
            value={inputText}
            onChange={onTypeSearchInput}
            placeholder={translate('portfolioManager.customerList.filterByNameOrId')}
          />
          {isFetching && <UpdatingSpinner message={translate('portfolioManager.customerList.updating')} horizontal />}
        </InputContainer>

        <CustomerTable>
          {Object.keys(customers)?.map((customerGroupKey) => (
            <CustomerList
              key={customerGroupKey}
              customers={customers[customerGroupKey as CustomerKind]}
              setSortBy={setSortBy}
              sortByKey={sortByKey}
            />
          ))}
        </CustomerTable>
      </Container>
    </>
  );
};

const Container = styled.div`
  ${styles.cardPadding};
`;

const InputContainer = styled(FlexAlignCenter)`
  background: ${colors.gray_light};
  position: sticky;
  top: ${customerHeaderHeight};
  height: ${customerHeaderHeight};
  z-index: 1;
`;

const CustomerFilter = styled(TextInput)`
  width: 500px;
`;

const UpdatingSpinner = styled(Spinner)`
  margin-left: 2rem;
`;

const CustomerTable = styled.table`
  border-spacing: 0 4px;
  border-collapse: separate;
  min-width: 1200px;
`;
