import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getOrders,
  setSearchFilters,
  setSearchString,
  setInstrumentKeywords,
  mergeSearchFilters,
  fetchSavedOrderFilters,
  deleteSavedFilter,
  getContractPdf,
  saveFilters,
} from 'features/orderList/orderListActions';
import List from 'features/listView/List';
import OrderListTable from 'features/orderList/components/OrderListTable';
import { RootState } from 'types/rootState';
import { ImmutableOrderListItems, SearchFiltersType } from 'types/orderListState';
import { getTavatOrderDetails } from 'features/orderList/orderListUtils';

interface Props {
  orders: ImmutableOrderListItems;
  savedFilters: SearchFiltersType[];
  searchFilters: SearchFiltersType;
  error: object;
  searchSkip: number;
  searchTake: number;
  totalResults: number;
  loadingOrders: boolean;
  contractPdfLoading: string;
  bankers: { value: string }[];
  getOrders: ({ take, skip, searchFilters }: { take: number; skip: number; searchFilters: object }) => void;
  mergeSearchFilters: () => void;
  setSearchString: () => void;
  setSearchFilters: () => void;
  fetchSavedOrderFilters: () => void;
  deleteSavedFilter: () => void;
  setInstrumentKeywords: () => void;
  getContractPdf: () => void;
  saveFilters: (filters: SearchFiltersType) => void;
}

class OrderListView extends Component<Props> {
  componentDidMount() {
    this.fetchOrders();
    this.props.fetchSavedOrderFilters();
  }

  fetchOrders = () => {
    const { getOrders, searchTake, searchSkip, searchFilters } = this.props;
    return getOrders({
      take: searchTake,
      skip: searchSkip,
      searchFilters,
    });
  };

  fetchPage = (searchSkip: number) => {
    const { getOrders, searchTake, searchFilters } = this.props;
    return getOrders({
      skip: searchSkip,
      take: searchTake,
      searchFilters,
    });
  };

  render() {
    const {
      orders,
      savedFilters,
      searchFilters,
      searchSkip,
      searchTake,
      totalResults,
      loadingOrders,
      contractPdfLoading,
      bankers,
    } = this.props;
    return (
      <List
        items={orders}
        type="orders"
        header="orderList.orders"
        fetchItems={this.fetchOrders}
        fetchPage={this.fetchPage}
        totalResults={totalResults}
        handleInstrumentKeywords={this.props.setInstrumentKeywords}
        savedFilters={savedFilters}
        searchFilters={searchFilters}
        searchSkip={searchSkip}
        searchTake={searchTake}
        mergeSearchFilters={this.props.mergeSearchFilters}
        setSearchString={this.props.setSearchString}
        setSearchFilters={this.props.setSearchFilters}
        deleteSavedFilter={this.props.deleteSavedFilter}
        loadingOrders={loadingOrders}
        bankers={bankers}
        saveFilters={this.props.saveFilters}
      >
        <OrderListTable
          // @ts-expect-error: ??
          items={orders.toJS()}
          total={totalResults}
          getContractPdf={this.props.getContractPdf}
          contractPdfLoading={contractPdfLoading}
          getTavatOrderDetails={getTavatOrderDetails}
        />
      </List>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    orders: state.orderList.items,
    searchSkip: state.orderList.searchSkip,
    searchTake: state.orderList.searchTake,
    searchFilters: state.orderList.searchFilters.toJS(),
    totalResults: state.orderList.totalResults,
    savedFilters: state.orderList.savedFilters.toJS(),
    loadingOrders: state.orderList.loadingOrders,
    contractPdfLoading: state.orderList.contractPdfLoading,
    bankers: state.common.bankers,
  };
}

const mapDispatchToProps = {
  getOrders,
  mergeSearchFilters,
  setSearchString,
  setSearchFilters,
  fetchSavedOrderFilters,
  deleteSavedFilter,
  setInstrumentKeywords,
  getContractPdf,
  saveFilters,
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(OrderListView);
