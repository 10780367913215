import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logout from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { userManager } from 'core/userManager';
import { getSub } from 'features/home/homeUtils';
import { info } from 'config/info';
import { selectUser } from 'features/home/homeSelectors';
import styles from 'features/home/HomeView.scss';

export const NavContent = () => {
  const user = useSelector(selectUser);

  const logout = () => {
    userManager.signoutRedirect().catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={styles.navContent}>
      <div className={styles.userLine}>
        <span className={styles.user}>{getSub(user)}</span>
        <IconButton onClick={handleClick}>
          <ArrowDropDownIcon style={{ fill: '#ffffff' }} />
        </IconButton>
        <Menu
          className={styles.menu}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            <ListItemText>{translate('home.logout')}</ListItemText>
          </MenuItem>
        </Menu>
      </div>
      <div className={styles.versionInfo}>
        <span>{`Versio ${info.buildNumber}`}</span>
      </div>
    </div>
  );
};
