import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { push } from 'react-router-redux';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { ConstraintList } from './components/ConstraintList';
import { getInitialConstraints, validateConstraintsMin, validateConstraintsMax } from './constraintsUtils';
import {
  setOptimizationConstraints,
  setCustomConstraints,
  setUseBackendConstraints,
} from 'features/allocator/investmentPlan/investmentPlanConstraintsActions';
import { SubPageContainer, HelpText } from 'features/common/StyledComponents';
import { flagCheckPortfolios } from 'features/allocator/investmentPlan/investmentPlanActions';
import { Constraint } from 'types/constraintsState';
import { setError, clearError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { optimize } from 'features/weights/weightsThunks';
import { config } from 'config';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { resetOptimizedPortfoliosSettings } from 'features/weights/weightsSlice';
import { BackendConstraints } from 'features/allocator/constraints/components/BackendConstraints';
import {
  selectAssetCategoryWeights,
  selectAssetClasses,
  selectCustomConstraints,
  selectOptimizationConstraints,
  selectUseBackendConstraints,
} from 'features/allocator/allocatorSelectors';
import { useAppDispatch } from 'core/hooks';
import { CustomConstraint } from 'features/allocator/constraints/components/CustomConstraint';

export const ConstraintsView = () => {
  const dispatch = useAppDispatch();

  const customerId = useSelector(selectCustomerId);
  const optimizationConstraints = useSelector(selectOptimizationConstraints);
  const customConstraints = useSelector(selectCustomConstraints);
  const assetClasses = useSelector(selectAssetClasses);
  const isNewCustomer = !useSelector(selectAssetCategoryWeights);
  const useBackendConstraints = useSelector(selectUseBackendConstraints);

  const [initialOptimizationConstraints, setInitialOptimizationConstraintsState] = useState<Constraint[]>([]);
  const [initialCustomConstraints, setInitialCustomConstraints] = useState<Constraint[]>([]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [showCustomConstraint, setShowCustomConstraint] = useState<boolean>(false);
  const [selectedConstraintId, setSelectedConstraintId] = useState<string | undefined>();

  useEffect(() => {
    if (!optimizationConstraints.length) {
      saveInitialConstraintsToStore();
      return;
    }
    setInitialOptimizationConstraintsState(optimizationConstraints);
    setInitialCustomConstraints(customConstraints);
  }, [assetClasses]);

  const toggleUseBackendConstraints = () => {
    dispatch(setUseBackendConstraints(!useBackendConstraints));
    setHasChanged(true);
  };

  const discardChanges = () => {
    dispatch(setOptimizationConstraints(initialOptimizationConstraints));
    dispatch(setCustomConstraints(initialCustomConstraints));
    setHasChanged(false);
  };

  const saveInitialConstraintsToStore = () => {
    const initialConstraints = getInitialConstraints(assetClasses);
    dispatch(setOptimizationConstraints(initialConstraints));
    setInitialOptimizationConstraintsState(initialConstraints);
  };

  const handleFailureOnUpdate = (constraints: Constraint[], errorMessage: string) => {
    const invalids = constraints.map((x) => x.label);
    dispatch(
      setError({
        error: `${errorMessage}: ${invalids.join(', ')}`,
        context: errorKeys.setConstraints,
      })
    );
  };

  const onUpdate = (updatedConstraint: Constraint): boolean => {
    const updatedOptimizationConstraints = optimizationConstraints.map((optimizationConstraint) =>
      optimizationConstraint.id === updatedConstraint.id ? updatedConstraint : optimizationConstraint
    );

    if (updatedConstraint.isCustom) {
      dispatch(
        setCustomConstraints(
          customConstraints.map((customConstraint) => {
            return customConstraint.id === updatedConstraint.id ? updatedConstraint : customConstraint;
          })
        )
      );
    } else {
      if (!validateConstraints(updatedOptimizationConstraints)) {
        return false;
      }
      if (!validateConstraints(updatedConstraint.items)) {
        return false;
      }
      dispatch(setOptimizationConstraints(updatedOptimizationConstraints));
    }

    setHasChanged(true);
    return true;
  };

  const validateConstraints = (constraints: Constraint[]): boolean => {
    if (!validateConstraintsMin(constraints)) {
      handleFailureOnUpdate(constraints, translate('errors.constraintSumCantExceed100'));
      return false;
    }
    if (!validateConstraintsMax(constraints)) {
      const constraintTypeIsAssetClass = constraints.every((c) => c.items.length === 0);
      if (!constraintTypeIsAssetClass) {
        handleFailureOnUpdate(constraints, translate('errors.constraintSumCantMustBeAtLeast100'));
        return false;
      }
    }
    return true;
  };

  const saveAndResetPortfolios = () => {
    if (isNewCustomer) {
      dispatch(resetOptimizedPortfoliosSettings());
    }
    dispatch(flagCheckPortfolios(true));
    dispatch(clearError(errorKeys.setConstraints));
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
    dispatch(optimize());
  };

  const openCustomConstraintEditor = (constraintId?: string) => {
    setSelectedConstraintId(constraintId);
    setShowCustomConstraint(true);
  };

  if (showCustomConstraint) {
    return (
      <CustomConstraint
        customerId={customerId}
        selectedConstraintId={selectedConstraintId}
        setShowCustomConstraint={setShowCustomConstraint}
      />
    );
  }

  return (
    <SubPageContainer>
      <AllocatorHeader
        headerLabel={translate('allocator.constraintsView.constraintsTitle')}
        discardChanges={discardChanges}
        hasChanged={hasChanged}
        customerId={customerId}
        saveChanges={saveAndResetPortfolios}
      />

      <h2>{translate('allocator.constraintsView.optimizationConstraints')}</h2>

      <HelpText>{translate('allocator.constraintsView.optimizationConstraintsDescription')}</HelpText>

      <ConstraintList
        constraints={optimizationConstraints.concat(customConstraints)}
        onUpdate={onUpdate}
        constraintType="optimization"
        openCustomConstraintEditor={openCustomConstraintEditor}
      />

      {config.useBackendConstraints && (
        <BackendConstraints
          useBackendConstraints={useBackendConstraints}
          toggleUseBackendConstraints={toggleUseBackendConstraints}
        />
      )}
    </SubPageContainer>
  );
};
