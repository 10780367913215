import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import classnames from 'classnames';
import { CHILD_MONEY, GIFT } from 'constants/fundsOrigin';
import RadioButtons from 'features/common/RadioButtons';
import styles from './FundsOrigin.scss';
import { selectEditor } from 'features/orderDialog/orderDialogSelectors';
//import { selectAskFundsOrigin, selectEditor } from 'features/orderDialog/orderDialogSelectors';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { useAppDispatch } from 'core/hooks';

export const FundsOrigin = () => {
  const dispatch = useAppDispatch();

  const editor = useSelector(selectEditor);
  //TODO: AMLT-4923: this component to be never shown _for now_ -> probably will be refined in the future, so not deleting the component
  //const isVisible = useSelector(selectAskFundsOrigin);
  const isVisible = false;

  const fundsOrigin = editor.get('fundsOrigin');

  const options = [
    {
      value: GIFT,
      label: translate('order.gift'),
    },
    {
      value: CHILD_MONEY,
      label: translate('order.childMoney'),
    },
  ];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    dispatch(setEditorValue('fundsOrigin', value));
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className={classnames('row', styles.container)}>
      <div className={'column small-4 large-3'}>
        <span className={styles.text}>{translate(`order.fundsOrigin`)}</span>
      </div>

      <div className={'column small-8 large-6'}>
        <RadioButtons
          options={options}
          horizontal={true}
          value={fundsOrigin}
          onChange={onChange}
          name={'fundsOrigin'}
        />
      </div>

      <div className={'column small-12'}>
        {fundsOrigin === CHILD_MONEY && (
          <span className={classnames(styles.warning, styles.text)}>{translate(`order.fundsOriginAlert`)}</span>
        )}
      </div>
    </div>
  );
};
