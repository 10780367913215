import React from 'react';
import styles from './DownloadPdf.scss';
import { Spinner } from 'features/common/Spinner';

interface OpenFileProps {
  isBusy: boolean;
  loadFile?: () => void;
  blobUrl: string | null | undefined;
  icon?: string;
}

export const OpenFile = ({
  isBusy = false,
  loadFile = undefined,
  blobUrl = null,
  icon = 'icon-document',
}: OpenFileProps) => {
  if (blobUrl) {
    return <OpenPdf label={'AVAA'} href={blobUrl} icon={icon} />;
  }

  return <DownloadPdf onClick={loadFile} isBusy={isBusy} label={'LATAA'} icon={icon} />;
};

interface DownloadPdfProps {
  isBusy: boolean;
  onClick?: () => void;
  label: string;
  icon: string;
}

const DownloadPdf = ({ isBusy = false, onClick = undefined, label = '', icon = '' }: DownloadPdfProps) => {
  const element = isBusy ? (
    <Spinner className={styles.spinner} color="white" />
  ) : (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <i className={`icon ${icon}`} />
      <span>{label}</span>
    </div>
  );

  return <div className={styles.download}>{element}</div>;
};

interface OpenPdfProps {
  href: string;
  label: string;
  icon: string;
}

const OpenPdf = ({ href = '', label = '', icon = '' }: OpenPdfProps) => {
  return (
    <div className={styles.download}>
      <a onClick={() => window.open(href, '_blank')}>
        <i className={`icon ${icon}`} />
        <span>{label}</span>
      </a>
    </div>
  );
};
