import React from 'react';
import translate from 'counterpart';
import classnames from 'classnames';
import { CheckBox } from './CheckBox';
import styles from 'features/orders/OrdersPreviewView.scss';
import { PRO_ENG_PT, CONFINT_PT, PRO_PT } from 'constants/instrumentGroups';

interface Props {
  instrumentGroups: string[];
  isOrderConfirmed: boolean | undefined;
  onOrderConfirmationChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const OrderConfirmations = ({ isOrderConfirmed, onOrderConfirmationChange, instrumentGroups }: Props) => {
  const orderConfirmationTexts = [];
  if (instrumentGroups.some((e) => [PRO_ENG_PT, PRO_PT].includes(e))) {
    orderConfirmationTexts.push(
      <div key="aurinko">
        <CheckBox
          label={translate('order.orderConfirmation')}
          value={isOrderConfirmed}
          onChange={onOrderConfirmationChange}
          showComponent={true}
          data-testkey="order-confirmation"
        />
        <div className={classnames(styles.form, 'row')} key={'order.confirmNote'}>
          <div className="columns small-12">
            <ul>
              <li style={instrumentGroups.includes(PRO_ENG_PT) ? {} : { display: 'none' }}>
                {translate('order.confirmNote1')}
              </li>
              <li>{translate('order.confirmNote2')}</li>
              <ul>
                <li>{translate('order.confirmNote3')}</li>
                <ul>
                  <li>{translate('order.confirmNote4')}</li>
                  <li>{translate('order.confirmNote5')}</li>
                  <ol>
                    <li>{translate('order.confirmNote6')}</li>
                    <li>{translate('order.confirmNote7')}</li>
                    <li>{translate('order.confirmNote8')}</li>
                  </ol>
                </ul>
              </ul>
              <li>{translate('order.confirmNote9')}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (instrumentGroups.includes(CONFINT_PT)) {
    orderConfirmationTexts.push(
      <div key="bio">
        <CheckBox
          showComponent={true}
          label={translate('order.orderConfirmation')}
          onChange={onOrderConfirmationChange}
          value={isOrderConfirmed}
          data-testkey="order-confirmation"
        />
        <div className={classnames(styles.form, 'row')} key={'order.confirmNote'}>
          <div className="columns small-12">
            <ul>
              <li>{translate('order.confirmNoteConflictOfInterest')}</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
  if (orderConfirmationTexts.length > 0) {
    return (
      <div className="row">
        <div className="columns small-12">{orderConfirmationTexts}</div>
      </div>
    );
  } else {
    return null;
  }
};
