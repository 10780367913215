export default {
  Accepted: 'POR vastaanotettu',
  amount: 'Määrä kpl',
  banker: 'Pankkiiri',
  Buy: 'Osto',
  counterFinancialInstrumentName: 'Kohderahoitusväline',
  creatorFullName: 'Toimeksiannon syöttäjä',
  customer: 'Asiakas',
  date: 'Päivämäärä',
  deleteFilter: 'Poista haku',
  Exchange: 'Vaihto',
  filterByDate: 'Rajaa päivämäärällä',
  financialInstrumentName: 'Rahoitusväline',
  instrumentFilter: 'Hae rahoitusvälineen nimellä',
  instrumentForm: 'Rahoitusvälinetyyppi',
  noContractsFound: 'Sopimuksia ei löytynyt',
  noOrdersFound: 'Toimeksiantoja ei löytynyt',
  noSavedSearches: 'Ei tallennettuja hakuja',
  orders: 'Toimeksiannot',
  orderStatus: 'Toimeksiannon tila',
  portfolio: 'Salkku',
  removeFilters: 'Poista rajaukset',
  savedSearches: 'Tallennetut haut',
  savedSearchName: 'Haun nimi',
  saveNew: 'Tallenna uusi',
  saveSearch: 'Tallenna haku',
  searchLabel: 'Hae asiakkaan nimellä tai tunnuksella',
  select: 'Valitse...',
  selectNoResultText: 'Ei tuloksia',
  Sell: 'Myynti',
  side: 'Tyyppi',
  signingStatus: 'Allekirjoituksen tila',
  externalSystemStatus: 'Tila',
  subscriptionFee: 'Palkkio €',
  subscriptionFeePct: 'Palkkio',
  totalValue: 'Määrä €',
  updateList: 'Päivitä lista',
  wantDeleteFilter: 'Haluatko poistaa tallennetun haun',
  webshop: 'Verkkokauppa',
};
