import { sortBy } from 'lodash';
import {
  BankersCustomersBatchResponse,
  CustomerWithPlan,
  CustomerWithoutPlan,
  CustomerWithPlanNoPositions,
  SearchResultCustomer,
  BasicCustomer,
} from 'types/customerListState';

const mapCustomerWithErrors = (c: BasicCustomer): SearchResultCustomer => ({
  kind: 'withErrors',
  customerId: c.customerId,
  customerName: c.customerName,
  selectedRisk: c.selectedRisk,
  timestamp: c.timestamp,
  isFound: true,
});

const mapCustomerWithPlan = (c: CustomerWithPlan): SearchResultCustomer => ({
  kind: 'withPlan',
  customerId: c.customerId,
  customerName: c.customerName,
  actualRisk: c.actualRisk,
  aum: c?.aum,
  hasInvestmentPlan: true,
  isFound: true,
  riskDifference: !c.selectedRisk || !c.actualRisk ? undefined : c.actualRisk - c.selectedRisk,
  useCompanyOptimizationForecast: c.useCompanyOptimizationForecast,
  selectedRisk: c.selectedRisk,
  timestamp: c.timestamp,
  withdrawalBalance: c.withdrawalBalance,
  weightDifference: c.weightDifference,
});

const mapCustomerWithoutPlan = (c: CustomerWithoutPlan): SearchResultCustomer => ({
  kind: 'withoutPlan',
  customerId: c.customerId,
  customerName: c.customerName,
  actualRisk: c.actualRisk,
  aum: c?.aum,
  hasInvestmentPlan: false,
  isFound: true,
  riskDifference: !c.selectedRisk || !c.actualRisk ? undefined : c.actualRisk - c.selectedRisk,
  useCompanyOptimizationForecast: true,
  selectedRisk: c.selectedRisk,
  timestamp: c.timestamp,
  withdrawalBalance: c.withdrawalBalance,
});

const mapCustomerWithPlanNoPositions = (c: CustomerWithPlanNoPositions): SearchResultCustomer => ({
  kind: 'withPlanNoPositions',
  customerId: c.customerId,
  customerName: c.customerName,
  aum: 0,
  hasInvestmentPlan: true,
  isFound: true,
  useCompanyOptimizationForecast: c.useCompanyOptimizationForecast,
  selectedRisk: c.selectedRisk,
  timestamp: c.timestamp,
  withdrawalBalance: 0,
});

export const handleCustomerBatchResponse = (response: BankersCustomersBatchResponse): SearchResultCustomer[] => {
  return [
    ...response.withPlan.map(mapCustomerWithPlan),
    ...response.withPlanNoPositions.map(mapCustomerWithPlanNoPositions),
    ...response.withoutPlan.map(mapCustomerWithoutPlan),
    ...response.errors.map(mapCustomerWithErrors),
  ];
};

export const setSort = (key: keyof SearchResultCustomer) => <const>{ type: 'sort', key };
export const setInput = (input: string) => <const>{ type: 'setInput', input };
export const setFilter = (filter: string) => <const>{ type: 'filter', filter };
export const setBanker = (bankerId: string) => <const>{ type: 'banker', bankerId };
export const setFetching = (value: boolean) => <const>{ type: 'fetching', value };
export const setCustomers = (customers: SearchResultCustomer[]) =>
  <const>{
    type: 'customers',
    customers,
  };

const findCustomer = (filter: string, customer: SearchResultCustomer) => {
  const searchTerms = filter.split(' ');
  const isFound =
    filter.length < 2 ||
    searchTerms.every(
      (word) => customer.customerName.toLowerCase().includes(word.toLowerCase()) || customer.customerId.startsWith(word)
    );
  return isFound;
};

export const sortCustomers = (
  customers: SearchResultCustomer[],
  sortByKey: keyof SearchResultCustomer,
  filter: string
) => {
  const hasKeyCustomers =
    customers?.filter((customer) => sortByKey in customer && customer[sortByKey] !== undefined) || [];
  const hasNoKeyCustomers =
    customers?.filter((customer) => !(sortByKey in customer) || customer[sortByKey] === undefined) || [];

  const sortedHasKeyCustomers = sortBy(hasKeyCustomers, (customer) => {
    if (sortByKey === 'riskDifference') {
      return Math.abs(customer[sortByKey] as number); // it's not undefined
    }
    return customer[sortByKey];
  }).reverse();

  const sortedCustomers = [...sortedHasKeyCustomers, ...hasNoKeyCustomers];

  return sortedCustomers.map((c) => ({
    ...c,
    isFound: filter.length < 2 || findCustomer(filter, c),
  }));
};

export type CustomerListComponentAction = ReturnType<
  typeof setSort | typeof setInput | typeof setFilter | typeof setBanker | typeof setFetching | typeof setCustomers
>;
