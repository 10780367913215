import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { OverviewView } from './overview/OverviewView';
import { allocatorWidth, FlexColumn } from 'features/common/StyledComponents';
import { StepNavigation } from './stepNavigation/StepNavigation';
import { noRiskOrCoreSelected } from 'features/allocator/allocatorSelectors';

export const AllocatorView = () => {
  const showInstructions = useSelector(noRiskOrCoreSelected);

  return (
    <OverviewContainer>
      <StepNavigation />

      {showInstructions ? (
        <Instructions data-testkey="cant-optimize">
          {translate('allocator.instructionsAtStart.planCanBeCreatedWhen')}
          <b>{translate('allocator.instructionsAtStart.riskAndCore')}</b>
          {translate('allocator.instructionsAtStart.haveBeenChosen')}
        </Instructions>
      ) : (
        <OverviewView />
      )}
    </OverviewContainer>
  );
};

const OverviewContainer = styled(FlexColumn)`
  align-self: center;
`;

const Instructions = styled.div`
  font-size: 18px;
  text-align: center;
  background: white;
  margin: 0 calc((-100vw + ${allocatorWidth}) / 2);
  padding: 1rem 0;
  letter-spacing: 0.2px;

  b {
    margin: 0 0.5rem;
    border-width: 0 0 4px 0;
    border-style: double;
    font-style: italic;
  }
`;
