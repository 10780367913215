import React from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import { bindActionCreators, Dispatch } from 'redux';
import { fromJS, List } from 'immutable';
import translate from 'counterpart';
import {
  searchCustomers,
  searchClear,
  searchVisibilityFilter,
  lastUsersChange,
  searchCustomersRequest,
  addLastSearched,
  updateLastSearched,
} from 'features/search/searchActions';
import { SearchResults } from 'features/search/components/SearchResults';
import SearchBar from 'features/search/components/SearchBar';
import styles from './SearchView.scss';
import NavBar from 'features/profile/components/Nav';
import ErrorBoundary from 'features/common/ErrorBoundary';
import { Spinner } from 'features/common/Spinner';
import { ImmutableSearchResult } from 'types/searchState';
import { RootState } from 'types/rootState';
import { OidcState } from 'types/oidcState';
import { User } from 'types/types';

interface Props {
  searchCustomersRequest: (query: string, managerId: string | null) => void;
  searchCustomers: (query: string) => void;
  searchVisibilityFilter: (checked: boolean) => void;
  addLastSearched: (user: User) => void;
  searchClear: () => void;
  lastUsers: {
    searched: User[];
  };
  isSearching: boolean;
  showSearch: boolean;
  query: string;
  searchResults: List<ImmutableSearchResult>;
  managerId: string | null;
}

class SearchView extends React.Component<Props> {
  searchCustomersRequest: (query: string) => void;

  static DEBOUNCE_DELAY = 300;
  static DEBOUNCE_LEADING = true;
  static MIN_QUERY = 4;

  static defaultProps = {
    managerId: null,
  };

  constructor(props: Props) {
    super(props);
    // Use the debounced action for the actual request
    this.searchCustomersRequest = debounce(
      (query: string) => {
        if (query && query.length >= SearchView.MIN_QUERY) {
          this.props.searchCustomersRequest(query, this.props.managerId);
        }
      },
      SearchView.DEBOUNCE_DELAY,
      { leading: SearchView.DEBOUNCE_LEADING }
    );
  }

  handleChange = (query: string) => {
    this.props.searchCustomers(query);
    this.searchCustomersRequest(query);
  };

  render() {
    const { lastUsers, isSearching, showSearch, searchResults, query } = this.props;

    const lastSearchedUsers = fromJS(lastUsers.searched);

    const searchTitle = searchResults?.count()
      ? translate('search.resultCount', { count: searchResults.count() })
      : translate('search.noResults');

    const lastSearchedTitle = translate('search.lastSearched');

    const content = () => {
      if (isSearching) {
        return <Spinner className={styles.spinner} size={100} />;
      }
      return (
        <SearchResults
          results={showSearch ? searchResults : lastSearchedUsers}
          clickUser={this.props.addLastSearched}
          title={showSearch ? searchTitle : lastSearchedTitle}
        />
      );
    };

    return (
      <section>
        <NavBar />
        <ErrorBoundary>
          <SearchBar query={query} searchChange={this.handleChange} onClear={this.props.searchClear} />
          <section className={styles.searchContent}>{content()}</section>
        </ErrorBoundary>
      </section>
    );
  }
}

function selectManagerId(state: OidcState) {
  if (state.user === null) {
    return null;
  }

  if (state.user.profile === null) {
    return null;
  }

  return state.user.profile.manager_id;
}

function mapStateToProps(state: RootState) {
  return {
    ...state.search,
    managerId: selectManagerId(state.oidc),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      searchCustomers,
      searchClear,
      searchVisibilityFilter,
      lastUsersChange,
      searchCustomersRequest,
      addLastSearched,
      updateLastSearched,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchView);
