import React from 'react';
import { UnitFee } from './UnitFee';
import { Volume } from './Volume';
import { Note } from './Note';
import InvoiceDetails from './InvoiceDetails';
import MoneySource from './MoneySource';
import { ValidationErrorValue, TouchedFields } from 'types/orderDialogState';
import { DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';

interface Props {
  assignment: OrderLineType;
  touchedFields: TouchedFields;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  structuredMinimumSum: number;
  defaultFee: number;
  getError: (key: string) => ValidationErrorValue | undefined;
  defaultAccount: DefaultAccount;
  portfolioContractName: string;
  isAppropriatenessTestOk: boolean;
}

export const WarrantDetails = (props: Props) => {
  const dispatch = useAppDispatch();

  const getVolumeFieldProps = () => {
    const { assignment, structuredMinimumSum } = props;
    return {
      label: structuredMinimumSum
        ? `Määrä, hinta: ${assignment.prePayPrice} ${assignment.instrumentCurrency}/kpl, min: ${structuredMinimumSum}`
        : 'Määrä',
      defaultValue: '0 kpl',
    };
  };

  const onUnitFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditorValue('unitFee', Number(event.target.value)));
  };

  const {
    assignment,
    touchedFields,
    onChange,
    onNoteChange,
    defaultFee,
    getError,
    defaultAccount,
    portfolioContractName,
    isAppropriatenessTestOk,
  } = props;
  return (
    <div>
      <div className="row" key="volume">
        <div className={'columns small-4'}>
          <UnitFee
            error={getError('unitFee')}
            touched={touchedFields.unitFee}
            onChange={onUnitFeeChange}
            value={assignment.unitFee}
            currency={assignment.instrumentCurrency}
            defaultUnitFee={defaultFee}
          />
        </div>
        <div className={'columns small-8'}>
          <Volume
            error={getError('subscriptionSum')}
            touched={touchedFields.subscriptionSum}
            value={assignment.subscriptionSum}
            onChange={onChange}
            textFieldProps={getVolumeFieldProps()}
            data-testkey={'order-volume-price'}
          />
        </div>
      </div>
      <div className="row">
        <div className="columns small-12">
          <Note onNoteChange={onNoteChange} value={assignment.internalNote} />
        </div>
      </div>
      <div className="row">
        <div className="columns small-4">
          <MoneySource
            portfolioContractName={portfolioContractName}
            defaultAccount={defaultAccount}
            assignment={assignment}
          />
        </div>
        <div className="columns small-8">
          <InvoiceDetails assignment={assignment} defaultAccount={defaultAccount} />
        </div>
      </div>

      {isAppropriatenessTestOk ? (
        <div className="row">
          <div className="columns small-4" />
          <div className="columns small-6" style={{ marginTop: '0.5rem', fontSize: '1rem' }}>
            Asianmukaisuusarviointi <i className="icon icon-check" />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};
