import React from 'react';
import Immutable from 'immutable';
import { useSelector } from 'react-redux';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';
import { SignatureSelection } from './SignatureSelection';
import { WEB_SERVICE_CONTRACT, WEB_SERVICE_CONTRACT_PROXY } from 'constants/contractIds';
import { OpenFile } from './DownloadPdf';
import { SIGNATORY_NOT_EDITABLE, isWebServiceContract } from 'constants/contractGroups';
import { ImmutableEntry, Entry } from 'features/contracts/contracts.types';
import { ImmutableSignatory, Signatory } from 'types/ordersState';
import { SignatureMethodType } from 'types/types';
import { RootState } from 'types/rootState';
import { SUITABILITY_TEMPLATE_ID } from 'features/contracts/contractsConstants';
import SuitabilityItem from './SuitabilityItem';

interface Props {
  entry: string | ImmutableEntry[];
  signatureMethods: Immutable.Map<string, string>;
  handleOnSignatureMethodSelected: () => void;
  handleOnNewSignatoryButtonClick: () => void;
  handleOnCopyAllSignatoriesButtonClick: () => void;
  pdfPreviewLinks: Immutable.Map<string, object>;
  handleOnSignatorySelect: () => void;
  webServiceContractExist: boolean;
  fetchingWebServiceContractStatus: boolean;
  contractsSignatories: ImmutableSignatory;
  manualSigners: Immutable.Map<string, number>;
  signatoriesOfContract: (contractsSignatories: ImmutableSignatory, key: string) => Signatory[];
  manualSignatoriesOfContract: (manualSigners: Immutable.Map<string, number>, key: string) => number;
  getPdfForPreview: () => void;
  hideSigningOptions: boolean;
  setManualSignatureAmount: () => void;
}

export const ContractPreviewItem = ({
  entry,
  signatureMethods,
  handleOnSignatureMethodSelected,
  handleOnNewSignatoryButtonClick,
  handleOnCopyAllSignatoriesButtonClick,
  pdfPreviewLinks,
  handleOnSignatorySelect,
  fetchingWebServiceContractStatus,
  contractsSignatories,
  manualSigners,
  manualSignatoriesOfContract,
  webServiceContractExist,
  signatoriesOfContract,
  getPdfForPreview,
  hideSigningOptions,
  setManualSignatureAmount,
}: Props) => {
  const attachmentsLength = useSelector((state: RootState) => state.contracts.attachmentPdfs.length);
  const key = entry[0] as string;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const value = entry[1].toJS() as Entry;
  const signatureMethodKey = key === WEB_SERVICE_CONTRACT_PROXY ? WEB_SERVICE_CONTRACT : key;
  const signatureMethod = signatureMethods.get(signatureMethodKey) as SignatureMethodType;
  const signatories = signatoriesOfContract(contractsSignatories, key);
  const isInkSignAndHasSignatory = signatureMethod === SENT_FOR_INK_SIGNING && signatories.length > 0;
  const manualSignerAmount = manualSignatoriesOfContract(manualSigners, key);
  const contract = { ...value, key, signatories, manualSignerAmount };
  const disableSignatoryEdit = SIGNATORY_NOT_EDITABLE.includes(key);

  const loadFile = getPdfForPreview.bind(window, value.id, key, value.name);
  const headerIcon = <OpenFile blobUrl={value.blobUrl} loadFile={loadFile} isBusy={value.isBusy} />;
  const allToSign = pdfPreviewLinks.size + attachmentsLength;
  const preventSigning = isWebServiceContract(key) && webServiceContractExist;

  if (key === SUITABILITY_TEMPLATE_ID) {
    return <SuitabilityItem value={value} />;
  }

  return (
    <SignatureSelection
      key={key}
      contract={contract}
      headerIcon={headerIcon}
      selectionHintText="Valitse allekirjoitustapa"
      selectedSignatureMethod={signatureMethod}
      onSignatureMethodSelected={handleOnSignatureMethodSelected}
      onNewSignatoryButtonClick={handleOnNewSignatoryButtonClick}
      onCopyAllSignatoriesButtonClick={handleOnCopyAllSignatoriesButtonClick}
      showCopyAllSignatories={allToSign > 1}
      onSignatorySelect={handleOnSignatorySelect}
      signatureMethods={[SENT_FOR_MANUAL_SIGNING, SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING]}
      preventSigning={preventSigning}
      disableSignatoryEdit={disableSignatoryEdit}
      fetchingWebServiceContractStatus={fetchingWebServiceContractStatus}
      hideSigningOptions={hideSigningOptions}
      disableAddSignatory={disableSignatoryEdit || isInkSignAndHasSignatory}
      setManualSignatureAmount={setManualSignatureAmount}
    />
  );
};
