import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { colors } from 'styles/colors';
import { Flex, FlexColumn, customerHeaderHeight, menuWidth } from 'features/common/StyledComponents';
import { urlContains } from 'features/allocator/allocatorUtils';
import { selectIsDemoCustomer } from 'features/profile/profileSelectors';

export const Menu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const toggleMenu = () => setOpen((open) => !open);

  const isDemoCustomer = useSelector(selectIsDemoCustomer);

  interface Link {
    label: string;
    key: string;
    url: string;
    isActive: boolean;
  }

  const links = [
    {
      label: translate('portfolioManager.customerHeader.toFrontPage'),
      key: 'frontPage',
      url: '/',
      isActive: false,
    },
    {
      label: translate('portfolioManager.customerHeader.customerSearch'),
      key: 'search',
      url: '/search',
      isActive: urlContains('/search'),
    },
    !isDemoCustomer && {
      label: translate('portfolioManager.customerHeader.myCustomers'),
      key: 'myCustomers',
      url: '/mycustomers',
      isActive: urlContains('/mycustomers') || urlContains('/portfoliomanager'),
    },
    {
      label: translate('portfolioManager.customerHeader.createNewCustomer'),
      key: 'createCustomer',
      url: '/new',
      isActive: urlContains('/new'),
    },
    {
      label: translate('portfolioManager.customerHeader.contracts'),
      key: 'contracts',
      url: '/contracts',
      isActive: urlContains('/contracts'),
    },
    {
      label: translate('portfolioManager.customerHeader.orders'),
      key: 'orders',
      url: '/orders',
      isActive: urlContains('/orders'),
    },
    /*
    {
      label: translate('portfolioManager.customerHeader.allocatorDemo'),
      key: 'allocatorDemo',
      url: '/customer/demo_customer/portfoliomanager/allocator',
      isActive: urlContains('/customer/demo_customer/portfoliomanager/allocator'),
    },
    */
  ].filter((i) => i) as Link[];

  return (
    <Container>
      <Icon onClick={toggleMenu} data-testkey="toggle-menu">
        <Stripes open={open}>
          <div />
          <div />
          <div />
        </Stripes>
      </Icon>
      <NavigationList visible={open}>
        <ul>
          {links.map((entry, index) => (
            <LI index={index} open={open} active={entry.isActive} key={entry.key}>
              <Link to={entry.url} data-testkey={`menu-${entry.key}`} onClick={toggleMenu}>
                {entry.label}
              </Link>
            </LI>
          ))}
        </ul>
      </NavigationList>
    </Container>
  );
};

const Container = styled(Flex)`
  justify-content: center;
`;

const Icon = styled(Flex)`
  background: ${colors.primary};
  height: ${customerHeaderHeight};
  width: ${customerHeaderHeight};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.1s all;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    filter: brightness(1.1);
  }
`;

const Stripes = styled(FlexColumn)<{ open: boolean }>`
  justify-content: space-between;
  height: 18px;
  width: 24px;
  transition: 0.1s all;

  & > div {
    background: white;
    height: 2px;
    width: 100%;
    transition: transform 0.2s ${(props) => (props.open ? '0.1s' : '0s')};

    &:first-child {
      width: 85%;
      transform: ${(props) => (props.open ? 'translate(1.5px, 8px) rotateZ(45deg) scaleX(1.2)' : 'rotate(0deg)')};
    }
    &:nth-child(2) {
      transform-origin: center left;
      transform: ${(props) => (props.open ? 'scaleX(0)' : 'scaleX(1)')};
      transition: transform 0.2s;
    }
    &:last-child {
      width: 70%;
      transform: ${(props) => (props.open ? 'translate(3px, -8px) rotateZ(-45deg) scaleX(1.45)' : 'rotate(0deg)')};
    }
  }
`;

const NavigationList = styled.div<{ visible: boolean }>`
  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    will-change: transform;
    width: ${menuWidth};
    transition: height 0.2s;
  }
`;

const LI = styled.li<{ active: boolean; open: boolean; index: number }>`
  background: white;
  display: flex;
  position: absolute;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
  height: ${customerHeaderHeight};
  transition: 0.2s transform ease-out;
  border-right: 1px solid #eee;
  width: 100%;
  will-change: transform;
  z-index: ${(props) => (props.active ? 5 : 4 - props.index)};
  transform: ${(props) => (!props.open ? `translateY(0)` : `translateY(${props.index * 56}px)`)};
  font-weight: ${(props) => (props.active ? '900' : '500')};
  padding: 0;

  &&:hover a,
  &&:hover a:hover {
    text-decoration: ${(props) => (props.active && !props.open ? 'underline' : 'none')};
  }

  && a,
  && a:hover {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    height: 100%;
    width: 100%;
    color: ${(props) => (props.active ? colors.link_blue : colors.black)};
  }

  ${(props) =>
    props.open &&
    css`
      &:hover {
        background: hsl(170deg 0% 94%);
      }
    `}
`;
