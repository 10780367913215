import React from 'react';
import translate from 'counterpart';
import { hideQuantityWarning } from 'core/portfolios';
import { OrderSideType } from 'types/orderDialogState';

interface Props {
  side: OrderSideType;
  orderFinancingSell: string | undefined;
  volume: number | undefined;
  currentQuantity: number | undefined;
}

export const QuantityWarning = ({ side, orderFinancingSell, volume, currentQuantity }: Props) => {
  if (hideQuantityWarning({ side, orderFinancingSell, volume, currentQuantity })) {
    return null;
  }

  return (
    <div className={'row'} data-testkey="quantity-bigger-than-position">
      <div className="columns small-6" style={{ marginTop: '0rem', fontSize: '.875rem', color: '#ed0726' }}>
        {translate(`order.quantityBiggerThanPosition`)}
      </div>
    </div>
  );
};
