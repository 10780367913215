import React from 'react';
import translate from 'counterpart';
import { PRICE, ORD_TYPE, BOND_PRICE_TYPE } from 'constants/orderFields';
import { bondPriceTypes, LIMIT_PRICE } from 'constants/shareOptions';
import { STRUCTURED_PRODUCT } from 'constants/instrumentForms';
import SelectBondPriceType from './SelectBondPriceType';
import { Volume } from './Volume';
import { ValidationErrorValue, TouchedFields } from 'types/orderDialogState';
import { SelectValue } from 'types/types';
import { OrderLineType } from 'types/ordersState';

interface Props {
  assignment: OrderLineType;
  validationErrors: ValidationErrorValue[];
  touchedFields: TouchedFields;
  onPriceChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBondPriceTypeChange: (input: SelectValue) => void;
}

const LimitPrice = ({ validationErrors, touchedFields, assignment, onPriceChange, onBondPriceTypeChange }: Props) => {
  let content = null;
  if (assignment[ORD_TYPE] === LIMIT_PRICE) {
    content = (
      <div className={'row'}>
        <div className="columns small-5">
          <Volume
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            error={validationErrors.price}
            touched={touchedFields.price}
            value={assignment[PRICE]}
            onChange={onPriceChange}
            textFieldProps={{
              label: `Hinta (${assignment.instrumentCurrency})`,
              defaultValue: assignment.instrumentCurrency,
            }}
            data-testkey={'order-volume-price'}
          />
        </div>
        {assignment.financialInstrumentForm === STRUCTURED_PRODUCT ? (
          <div className="columns small-7">
            <SelectBondPriceType
              options={bondPriceTypes}
              onChange={onBondPriceTypeChange}
              value={assignment[BOND_PRICE_TYPE]}
              label={translate('order.bondPriceType')}
            />
          </div>
        ) : undefined}
      </div>
    );
  }
  return content;
};

export default LimitPrice;
