import { AssetCategoryName } from 'types/types';

export const colors = {
  primary: 'hsl(170, 65%, 48%)',
  primary_text: 'hsl(170, 30%, 30%)',
  primary_light: 'hsl(170, 71%, 53%)',
  primary_dark: 'hsl(170, 80%, 38%)',
  light_green: 'hsl(170, 60%, 92%)',

  black: 'hsl(28, 27%, 22%)',
  dark_gray: 'hsl(28, 4%, 45%)',
  gray_inactive: 'hsl(28, 4%, 80%)',
  med_gray: 'hsl(28, 4%, 90%)',
  gray_light: 'hsl(205, 10%, 97%)',

  warm_gray: 'hsl(37, 16%, 72%)',
  shadow: 'hsla(210, 40%, 40%, .05)',

  link_blue: 'hsl(194, 100%, 28%)',
  light_blue: 'hsl(205, 35%, 93%)',

  red: 'hsl(5, 70%, 58%)',
  green: 'hsl(140, 82%, 42%)',
  blue: 'hsl(205, 100%, 35%)',
  ptGreen: '#00eb64',

  considered: 'hsl(205, 49%, 87%)',
  optimized: 'hsl(194, 100%, 28%)',

  buy_green: '#f2f9f5',
  mois_green: '#edf0ee',
  sell_red: '#fff3f4',
  mois_red: '#f5e9ea',
  exchange_blue: '#eff7fb',
  mois_blue: '#e6eef1',
};

type AssetCategoryType = {
  [key in AssetCategoryName]: string;
};

export const ASSET_CATEGORY_COLORS: AssetCategoryType = {
  Allokaatiot: 'hsl(340, 100%, 70%)',
  Muut: 'hsl(340, 100%, 70%)',
  Osakkeet: 'hsl(194, 100%, 35%)',
  Korot: 'hsl(170, 64%, 45%)',
  Vaihtoehtoiset: 'hsl(31, 100%, 58%)',
  Custom: '#CCC',
  Tuntematon: '#CCC',
};

export const GROUPED_ASSET_CLASSES_COLORS = {
  ST_Europe: 'hsl(194, 100%, 35%)',
  ST_US: 'hsl(194, 100%, 42%)',
  ST_Pac: 'hsl(194, 100%, 55%)',
  ST_EM: 'hsl(194, 100%, 75%)',
  BO_Gov: 'hsl(170, 64%, 30%)',
  BO_CorpIG: 'hsl(170, 64%, 40%)',
  BO_HY: 'hsl(170, 64%, 60%)',
  BO_EM_Gov: 'hsl(170, 64%, 75%)',
  ALT_RealEstate: 'hsl(31, 100%, 45%)',
  ALT_Infra: 'hsl(31, 100%, 54%)',
  ALT_PrivateDebt: 'hsl(31, 100%, 63%)',
  ALT_PrivateEquity: 'hsl(31, 100%, 72%)',
  ALT_VC: 'hsl(31, 100%, 81%)',
  ALT_HedgeFund: 'hsl(31, 100%, 90%)',
  OTHER: 'hsl(340, 100%, 70%)',
} as const;

export const ASSET_CLASS_COLORS = {
  ALLOC: 'hsl(340, 100%, 70%)',
  ST: 'hsl(194, 100%, 32%)',
  ST_Europe_Value: 'hsl(194, 100%, 25%)',
  ST_Europe_Growth: 'hsl(194, 100%, 32%)',
  ST_Europe_Small: 'hsl(194, 100%, 40%)',
  ST_US_Value: 'hsl(194, 100%, 48%)',
  ST_US_Growth: 'hsl(194, 100%, 56%)',
  ST_US_Small: 'hsl(194, 100%, 64%)',
  ST_Japan: 'hsl(194, 100%, 72%)',
  ST_PacExJap: 'hsl(194, 100%, 78%)',
  ST_EM: 'hsl(194, 100%, 85%)',
  BO: 'hsl(170, 64%, 35%)',
  BO_Europe_Gov: 'hsl(170, 64%, 25%)',
  BO_Europe_CorpIG: 'hsl(170, 64%, 33%)',
  BO_Europe_HY: 'hsl(170, 64%, 42%)',
  BO_US_Gov: 'hsl(170, 64%, 52%)',
  BO_US_CorpIG: 'hsl(170, 64%, 62%)',
  BO_US_HY: 'hsl(170, 64%, 72%)',
  BO_EM_Gov: 'hsl(170, 64%, 80%)',
  ALT: 'hsl(31, 100%, 35%)',
  ALT_PrivateDebt: 'hsl(31, 100%, 42%)',
  ALT_RealEstateCore: 'hsl(31, 100%, 49%)',
  ALT_RealEstateOpportunistic: 'hsl(31, 100%, 56%)',
  ALT_InfraCore: 'hsl(31, 100%, 63%)',
  ALT_InfraOpportunistic: 'hsl(31, 100%, 70%)',
  ALT_PrivateEquity: 'hsl(31, 100%, 77%)',
  ALT_VC: 'hsl(31, 100%, 84%)',
  ALT_HedgeFund: 'hsl(31, 100%, 90%)',
  OTHER: 'hsl(340, 100%, 70%)',
  CASH: 'hsl(340, 100%, 80%)',
  Tuntematon: '#CCC',
} as const;
