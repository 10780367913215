import { apiCall } from 'core/apiCall';
import { AssetClassId, GeneralPosition } from 'types/types';
import {
  AllocatedPortfolio,
  InvestmentPlanPayload,
  InvestmentPlanResponse,
  PlanConstraint,
  PlanResponseInstrument,
  TargetAllocation,
} from 'types/investmentPlanState';
import { InstrumentType, PortfolioInstrument } from 'types/instrumentsState';
import { getAssetClassId } from 'features/allocator/instruments/instrumentsUtils';
import { RootState } from 'types/rootState';
import { MONEY } from 'constants/allocator';
import { createConstraintsPayload } from 'features/allocator/constraints/constraintsUtils';
import {
  getPlanPortfolioContractType,
  getPortfolioOptimizationModel,
  getPortfolioSettings,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import {
  selectHasPositions,
  selectOptimizedWeightsForRiskLevel,
  selectPlanState,
} from 'features/allocator/allocatorSelectors';
import { Position } from 'types/ordersState';
import PromiseStore from 'core/PromiseStore';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';

export const getPlan = async (customerId: string, accessToken: string) => {
  PromiseStore.getInvestmentPlan = apiCall({
    method: 'get',
    token: accessToken,
    path: `/api/v1/allocator/allocationplan?customerId=${customerId}`,
  });

  return (await PromiseStore.getInvestmentPlan) as InvestmentPlanResponse;
};

export const postSaveInvestmentPlan = async (data: InvestmentPlanPayload, accessToken: string, isDraft: boolean) =>
  await apiCall({
    method: 'post',
    token: accessToken,
    path: `/api/v1/allocator/allocationplan?isDraft=${isDraft}`,
    data,
  });

export const mapInstrument = (i: PlanResponseInstrument, portfolio: AllocatedPortfolio): PortfolioInstrument => {
  const assetClasses =
    Object.keys(i.instrument?.Weights)?.map((key) => ({
      assetClassId: key as AssetClassId,
      weight: i.instrument?.Weights[key as AssetClassId],
    })) || [];
  const assetClassId = getAssetClassId(assetClasses);
  const planInstrument = {
    assetCategory: assetClassId === 'ALLOC' ? 'Allokaatiot' : i.instrument?.assetClassCategory || 'Tuntematon',
    assetClassId,
    assetClasses,
    liquidity: i.instrument?.liquidity,
    name: i.instrument?.name,
    security: i.instrument?.Secid,
    type: i.instrument?.type as InstrumentType,
    weight: i.weight,
    marketValue: 0,
    portfolioCurrencyMarketValue: 0,
    portfolioId: portfolio?.portfolioId || '',
  };
  return planInstrument;
};

export const securityPortfolioIdDifference = (params: {
  instrumentList1: GeneralPosition[];
  instrumentList2: GeneralPosition[];
  removeZeroWeightPlanInstruments: boolean;
}) =>
  params.instrumentList1
    .filter((i) => (params.removeZeroWeightPlanInstruments ? i.value > 0 : i))
    .filter(
      (i) =>
        !params.instrumentList2.find(
          (position) => position.security === i.security && position.portfolioId === i.portfolioId
        )
    );

export const securityDifference = (positions: GeneralPosition[], planInstruments: GeneralPosition[]) =>
  positions
    .filter((pos) => pos.value > 0)
    .filter((position) => !planInstruments.find((i) => i.security === position.security));

export const mapPositionsToGeneralPosition = (positions: Position[]) =>
  positions
    .filter((p) => p.financialInstrument.financialInstrumentId !== MONEY)
    .map((i) => ({
      name: i.financialInstrument.friendlyName,
      security: i.financialInstrument.financialInstrumentId,
      portfolioId: i.portfolio.portfolioId,
      value: i.marketValue.baseCurrency.value,
    }));

export const getPositionAndPlanDifference = (state: RootState, investmentPlan: InvestmentPlanResponse) => {
  if (!selectHasPositions(state) || selectPlanState(state) === 'noPlan') {
    return {
      planInstrumentsNotInPositions: [],
      positionsNotInPlan: [],
    };
  }

  const mappedPositions = mapPositionsToGeneralPosition(state.portfolio.positions);

  const mappedPlanInstruments = investmentPlan.allocatedPortfoliosWithoutIlliquid.flatMap((p) =>
    p.allocatedPortfolioRows
      .filter((i) => i.instrument.Secid !== MONEY)
      .map((i) => ({
        name: i.instrument.name,
        security: i.instrument.Secid,
        portfolioId: p.portfolioId,
        value: i.weight,
      }))
  );

  const planInstrumentsNotInPositions = securityPortfolioIdDifference({
    instrumentList1: mappedPlanInstruments,
    instrumentList2: mappedPositions,
    removeZeroWeightPlanInstruments: true,
  });
  const positionsNotInPlan = securityDifference(mappedPositions, mappedPlanInstruments);

  return {
    planInstrumentsNotInPositions,
    positionsNotInPlan,
  };
};

export const generalConstraintsMapper = (state: RootState): PlanConstraint[] => {
  const constraints = state.portfolioManager.investmentPlan.constraints;
  const optimizationConstrains = createConstraintsPayload(constraints);

  return optimizationConstrains.map((optimizationConstraint) => {
    return {
      constrained: optimizationConstraint.assetClassesConstrained,
      inRelationTo: optimizationConstraint.assetClassesInRelation,
      constraintType: 'Optimization',
      constraintCategory: optimizationConstraint.category,
      min: optimizationConstraint.min,
      max: optimizationConstraint.max,
      label: optimizationConstraint.label,
    };
  }) as PlanConstraint[];
};

export const postInvestmentPlanInstrumentsMapper = ({
  state,
  withIlliquid,
}: {
  state: RootState;
  withIlliquid: boolean;
}) => {
  return createOptimizedPortfoliosFromOptimizedValues()(state).map((p) => {
    return {
      allocatorPortfolioId: p.allocatorPortfolioId,
      portfolioId: p.portfolioId,
      optimizationMannerId: getPortfolioOptimizationModel(p),
      contractTypeID: getPlanPortfolioContractType(p),
      portfolioManagerId: getPortfolioSettings(p).portfolioManager,
      allocatedPortfolioRows: withIlliquid
        ? p.allocatedPortfolioRows.withIlliquids
        : p.allocatedPortfolioRows.withoutIlliquids,
    };
  });
};

export const targetAllocationsMapper = ({
  state,
  withIlliquid,
}: {
  state: RootState;
  withIlliquid: boolean;
}): TargetAllocation[] => {
  const optimizedWeights = selectOptimizedWeightsForRiskLevel(state);

  const assetCategoryWeights = withIlliquid
    ? optimizedWeights.withIlliquids.assetCategoryWeights
    : optimizedWeights.withoutIlliquids.assetCategoryWeights;

  return assetCategoryWeights.flatMap((assetCategory) =>
    assetCategory.assetClasses.map((assetClass) => {
      return {
        assetClassId: assetClass.assetClassId,
        weight: assetClass.weight,
      };
    })
  );
};
