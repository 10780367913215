import {
  INITIALIZE_CREATE_PORTFOLIOS_STATE,
  CREATE_PORTFOLIOS_SUCCESS,
  CREATE_PORTFOLIOS_FAILURE,
} from './newCustomerStepsActions';
import { NewCustomerStepsActions } from 'features/newCustomerSteps/newCustomerStepsActions.types';
import { NewCustomerStepsState } from 'types/newCustomerStepsState';

const initialState: NewCustomerStepsState = {
  portfolioResponses: [],
  validationErrors: [],
};

export const newCustomerStepsReducer = (state = initialState, action: NewCustomerStepsActions) => {
  switch (action.type) {
    case INITIALIZE_CREATE_PORTFOLIOS_STATE:
      return initialState;
    case CREATE_PORTFOLIOS_SUCCESS:
      return {
        ...state,
        portfolioResponses: action.payload,
        validationErrors: [],
      };
    case CREATE_PORTFOLIOS_FAILURE:
      return {
        ...state,
        portfolioResponses: action.payload.portfolioResponses,
        validationErrors: action.payload.validationErrors,
      };
    default:
      return state;
  }
};
