import { apiCall } from 'core/apiCall';
import { Customer, ImmutableContract } from 'types/profileState';
import { RootState } from 'types/rootState';

// API calls

export const loadClientData = (token: string, clientId: string): Promise<Customer> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/client/${clientId}`,
  });
};

export const patchCustomerData = (id: string, data: ImmutableContract, token: string): Promise<void> => {
  return apiCall({
    method: 'patch',
    path: `/api/v1/client/${id}`,
    token,
    data,
  });
};

export const getPdf = (path: string, token: string): Promise<Blob> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/client/download/${path}`,
    responseType: 'blob',
    responseResolver: (res) => res.xhr.response,
  });
};

// Selectors

export const selectFormValues = (state: RootState) => state.profileEdit.formValues;
export const selectFormErrors = (state: RootState) => state.profileEdit.formErrors;
export const selectFormTouched = (state: RootState) => state.profileEdit.formTouched;
export const selectBisnodeValues = (state: RootState) => state.profileEdit.bisnodeValues;
export const selectNotFoundFromBisnode = (state: RootState) => state.profileEdit.notFoundFromBisnode;
export const selectFetchingFromBisnode = (state: RootState) => state.profileEdit.fetchingFromBisnode;
export const selectHeader = (state: RootState) => state.profileEdit.header;
export const selectIsSaving = (state: RootState) => state.profileEdit.isSaving;
export const selectHasCrmIdentity = (state: RootState) => state.profileEdit.hasCrmIdentity;
export const selectCustomerType = (state: RootState) => state.profileEdit.customerType;
export const selectStatusesDoc = (state: RootState) => state.profileEdit.statusesDoc;
export const selectTradeRegistryDoc = (state: RootState) => state.profileEdit.tradeRegistryDoc;
export const selectFetchingDocuments = (state: RootState) => state.profileEdit.fetchingDocuments;
export const selectProcurationInfoUpdated = (state: RootState) => state.profileEdit.procurationInfoUpdated;
export const selectIdentifyAndScreen = (state: RootState) => state.profileEdit.identifyAndScreen;
export const selectStakeholders = (state: RootState) => state.profileEdit.stakeholders;
export const selectLastScreened = (state: RootState) => state.profileEdit.lastScreened;
export const selectIsPersonScreeningInProgress = (state: RootState) => state.profileEdit.isPersonScreeningInProgress;
