export default {
  AdditionalSales: 'Lisämyynti',
  AnnualDiscussion: 'Vuosikeskustelu',
  Appointment: 'Tapaaminen',
  appointmentReason: 'Tapaamisen syy',
  Banker: 'Pankkiiri',
  Business: 'Business',
  callDescription: 'Puhelun lisätiedot',
  callScheduledStart: 'Puhelun aloitus',
  callScheduledEnd: 'Puhelun lopetus',
  callSubject: 'Puhelun aihe',
  choose: 'Valitse...',
  Customer: 'Asiakas',
  customerId: 'Asiakas ID',
  customerMeeting: 'Asiakaskohtaaminen',
  description: 'Tapaamisen lisätiedot',
  directionCode: 'Kumpi soitti',
  finishedNotification: 'Kohtaamismerkintä on tallennettu ja merkitty valmiiksi CRM:ään.',
  FirstMeeting: 'Ykköstapaaminen',
  InvestmentAdvice: 'Sijoitusneuvonta',
  location: 'Tapaamispaikka',
  ManagementAppointment: 'Johdon tapaaminen',
  MarketOverview: 'Markkinakatsaus',
  meetingType: 'Kohtaamisen tapa',
  name: 'Nimi',
  Order: 'Toimeksianto',
  PhoneCall: 'Puhelinsoitto',
  phoneCallReason: 'Puhelun syy',
  present: 'Tapaamisessa läsnä',
  Presentation: 'Palveluiden esittely',
  savedNotification: 'Kohtaamismerkintä on tallennettu CRM:ään.',
  scheduledStart: 'Tapaamisen aloitus',
  scheduledEnd: 'Tapaamisen lopetus',
  Social: 'Sosiaalinen',
  state: 'Asiakkaan tila',
  StatusReview: 'Tilannekatsaus',
  subject: 'Tapaamisen aihe',
  Taxation: 'Verotus',
  includesReporting: 'Käsitelty salkun tilannetta tai toimitettu raportti',
};
