import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  selectHasPositions,
  positionsAreEqual,
  selectCurrentWeights,
  selectOptimizedWeightsForRiskLevel,
} from 'features/allocator/allocatorSelectors';
import { RootState } from 'types/rootState';
import { PlanLength, WeightType } from 'types/types';
import { DifferenceCell } from 'features/portfolioManager/components/DifferenceCell';
import { Spinner } from 'features/common/Spinner';
import { formatVarValue } from 'features/allocator/overview/overviewUtils';

interface Props {
  planLength: PlanLength;
  compareTo?: WeightType;
  loading: boolean;
}

export const ValueAtRisk = ({ planLength, compareTo, loading }: Props) => {
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const optimizedWeightsByPlanLength = optimizedWeights[planLength];
  const currentWeights = useSelector(selectCurrentWeights);
  const positionsEqual = useSelector(positionsAreEqual);
  const hasPositions = useSelector(selectHasPositions);
  const optimizationForecastType = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.optimizationForecastType
  );
  const optimizationModel =
    optimizationForecastType === 'company'
      ? 'companyOptimizationForecastStatistics'
      : 'neutralOptimizationForecastStatistics';

  const getConfidenceLevels = (weightType: WeightType) => {
    if (weightType === 'currentWeights') {
      return currentWeights[optimizationModel]?.confidenceLevels?.lowerConfidenceLevel90;
    }

    if (hasPositions && positionsEqual) {
      return optimizedWeightsByPlanLength?.confidenceLevels?.lowerConfidenceLevel90;
    }

    return optimizedWeightsByPlanLength[optimizationModel]?.confidenceLevels?.lowerConfidenceLevel90;
  };

  const getVaR = (year: number, weightType: WeightType) => {
    const confidenceLevels = getConfidenceLevels(weightType);
    return formatVarValue(year, confidenceLevels);
  };

  const getVaRDifference = (year: number) => {
    const currentVaR = getVaR(year, 'currentWeights');
    const optimizedVaR = getVaR(year, 'optimizedWeights');
    if (!currentVaR || !optimizedVaR) {
      return undefined;
    }
    return currentVaR - optimizedVaR;
  };

  const VAROneYear = getVaR(1, 'optimizedWeights');
  const VARFiveYears = getVaR(5, 'optimizedWeights');
  const differenceOneYear = getVaRDifference(1);
  const differenceFiveYears = getVaRDifference(5);
  const doComparison = !!compareTo && positionsEqual;

  return (
    <table>
      <thead>
        <tr>
          <td>
            <b>{translate('allocator.overviewView.varTitle')}:</b>
          </td>
          <td />
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{translate('allocator.configureRisk.inOneYear')}:</td>
          {loading ? (
            <td>
              <Spinner size={20} position="right" />
            </td>
          ) : (
            <>
              <TDRight>{VAROneYear && `–${formatToDecimals(VAROneYear, 1, '%')}`}</TDRight>
              {doComparison && !!differenceOneYear && (
                <DifferenceCell difference={differenceOneYear} loading={loading} />
              )}
            </>
          )}
        </tr>

        <tr>
          <td>{translate('allocator.configureRisk.inFiveYears')}:</td>

          {loading ? (
            <td>
              <Spinner size={20} position="right" />
            </td>
          ) : (
            <>
              <TDRight>{VARFiveYears && `–${formatToDecimals(VARFiveYears, 1, '%')}`}</TDRight>
              {doComparison && !!differenceFiveYears && (
                <DifferenceCell difference={differenceFiveYears} loading={loading} />
              )}
            </>
          )}
        </tr>
      </tbody>
    </table>
  );
};

const TDRight = styled.td`
  text-align: right;
  width: 110px;
`;
