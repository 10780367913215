import React from 'react';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import translate from 'counterpart';
import { FlexAlignCenter, styles } from 'features/common/StyledComponents';
import { StepCore } from './components/StepCore';
import { StepConstraints } from './components/StepConstraints';
import { StepPortfolios } from './components/StepPortfolios';
import { StepRisk } from './components/StepRisk';
import { RootState } from 'types/rootState';
import {
  planIsLocked,
  selectRisk,
  selectHasPositions,
  isLoadingInitialItems,
  isLoadingPlan,
  selectPlanState,
  isOptimizingCurrent,
  isOptimizingPlan,
  selectPlanPortfolios,
  selectFlagCheckPortfolios,
} from 'features/allocator/allocatorSelectors';
import { OptimizationSwitch } from 'features/allocator/overview/components/OptimizationSwitch';
import { config } from 'config';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';

export const StepNavigation = () => {
  const targetRiskLevel = useSelector(selectRisk);

  const optimizationConstraints = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.constraints.optimizationConstraints
  );

  const planState = useSelector(selectPlanState);
  const lockedPlan = useSelector(planIsLocked);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const planPortfolios = useSelector(selectPlanPortfolios);
  const flagCheckPortfolios = useSelector(selectFlagCheckPortfolios);

  const loadingPlan = useSelector(isLoadingPlan);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const hasPositions = useSelector(selectHasPositions);

  const optimizingPlan = useSelector(isOptimizingPlan);
  const loadingInitialItems = useSelector(isLoadingInitialItems);
  const disableEdit = optimizingPlan || loadingInitialItems;

  const coreIsSet = useSelector(
    (state: RootState) => !!state.portfolioManager.investmentPlan.instruments.selectedInstrumentGroup
  );

  const customerId = useSelector(selectCustomerId);
  const summaryLink = `customer/${customerId}/portfolioManager/allocator/plansummary`;

  const getTitle = () => {
    if (planState === 'noPlan') {
      return translate('allocator.header.newInvestmentPlan');
    }
    if (planState === 'lockedPlan') {
      return translate('allocator.header.viewInvestmentPlan');
    }
    if (planState === 'notLoaded') {
      return translate('allocator.header.investmentPlan');
    }
    return translate('allocator.header.editInvestmentPlan');
  };

  return (
    <Container>
      <PageTitle>{getTitle()}</PageTitle>

      <Steps>
        <StepRisk
          unset={!targetRiskLevel}
          disableEdit={disableEdit}
          locked={lockedPlan}
          loading={loadingInitialItems}
        />
        <StepCore
          unset={!coreIsSet}
          disableEdit={disableEdit}
          locked={lockedPlan}
          loading={loadingInitialItems || (hasPositions ? optimizingCurrent : optimizingPlan)}
        />
        <StepConstraints
          unset={!optimizationConstraints.length}
          disableEdit={disableEdit}
          locked={lockedPlan}
          loading={loadingPlan}
        />
        <StepPortfolios
          unset={(!optimizedPortfolios.length && !planPortfolios.length) || flagCheckPortfolios !== false}
          disableEdit={disableEdit}
          locked={lockedPlan}
          loading={loadingInitialItems || optimizingCurrent}
        />
      </Steps>

      <LowerPart>
        {config.planSummaryView ? (
          <LinkToSummary
            to={!optimizedPortfolios.length ? '' : summaryLink}
            data-testkey="link-to-summary"
            disabled={!optimizedPortfolios.length}
          >
            {translate('allocator.investmentPlanSteps.createPlanSummary')}
          </LinkToSummary>
        ) : (
          <div />
        )}
        <OptimizationSwitch />
      </LowerPart>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  ${styles.fullWidth};
  padding-bottom: 1rem;
  padding-top: 1rem;
`;

const PageTitle = styled.h1`
  font-weight: 900;
  text-align: center;
  margin-bottom: 1rem;
`;

const Steps = styled.div`
  display: grid;
  grid-template-columns: minmax(10px, 260px) minmax(10px, 350px) auto auto;
  grid-gap: 1rem 2rem;
`;

const LowerPart = styled(FlexAlignCenter)`
  margin-top: 1rem;
  justify-content: space-between;
`;

const LinkToSummary = styled(Link)`
  white-space: nowrap;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: default;

      &:hover {
        text-decoration: none;
      }
    `};
`;
