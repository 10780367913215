import { useSelector } from 'react-redux';
import React, { useState, ChangeEvent } from 'react';
import { useAppDispatch } from 'core/hooks';
import { Spacer } from 'features/common/StyledComponents';
import StyledButton, { StyledAddAttachmentButton } from 'features/common/styledComponents/StyledButton';
import { removeAttachmentDocument, uploadAttachmentDocument } from 'features/contracts/contractsActions';
import { PDF_ATTACHMENT_LOADING } from 'features/contracts/contractsConstants';
import { RootState } from 'types/rootState';
import { SectionContainer, Header } from 'features/contracts/components/attachment//styledComponents';
import AttachmentItem from 'features/contracts/components/attachment//AttachmentItem';
import RemovalDialog from 'features/contracts/components/attachment//RemovalDialog';
import UploadModal from 'features/contracts/components/attachment/UploadModal';
import { ModalDialog } from 'features/common/ModalDialog';

interface AddAttachmentProps {
  title: string;
}

const AddAttachment = (props: AddAttachmentProps) => {
  const { title } = props;
  const [isUploadModalOpen, setUploadModalOpen] = useState(false);
  const [isRemovalDialogOpen, setRemovalDialogOpen] = useState(false);
  const [isWrongFileTypeDialogOpen, setIsWrongFileTypeDialogOpen] = useState(false);
  const [inRemovalId, setInRemovalId] = useState<string>('');
  const attachments = useSelector((state: RootState) => state.contracts.attachmentPdfs);
  const dispatch = useAppDispatch();

  const handleAttachmentUpload = async (event: ChangeEvent<HTMLInputElement>, documentDescriptiveName: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (event.target.files.length <= 0) {
      return;
    }

    const uploadFileType = event.target.files?.item(0)?.type;
    const expectedFileType = 'application/pdf';
    if (uploadFileType !== expectedFileType) {
      setIsWrongFileTypeDialogOpen(true);
      return;
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    await dispatch(uploadAttachmentDocument(event.target.files[0], documentDescriptiveName));
  };

  const remove = async (id: string) => {
    await dispatch(removeAttachmentDocument(id));
  };

  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
  };

  const openUploadModal = () => {
    setUploadModalOpen(true);
  };

  const handleRemovePress = (id: string) => {
    setInRemovalId(id);
    setRemovalDialogOpen(true);
  };

  const handleUploadAndClose = async (event: ChangeEvent<HTMLInputElement>, documentDescriptiveName: string) => {
    handleUploadModalClose();
    await handleAttachmentUpload(event, documentDescriptiveName);
  };

  const closeAndRemove = async () => {
    setRemovalDialogOpen(false);
    await remove(inRemovalId);
  };

  const handleFileTypeModalClose = () => {
    setIsWrongFileTypeDialogOpen(false);
    openUploadModal();
  };

  const addButtonDisabled = attachments.find((attachment) => attachment.status === PDF_ATTACHMENT_LOADING) && true;
  const wrongFileTypeActionButtons = [];
  wrongFileTypeActionButtons.push(
    <StyledButton variant="outlined" onClick={handleFileTypeModalClose} key={'attachment.incorrectfiletype'}>
      OK
    </StyledButton>
  );

  return (
    <SectionContainer>
      <Header>
        <h4>{title}</h4>
        <StyledAddAttachmentButton
          endIcon={<i onClick={openUploadModal} style={{ cursor: 'pointer' }} className="icon icon-pdf" />}
          variant="text"
          disabled={addButtonDisabled}
          onClick={openUploadModal}
        >
          Lisää dokumentti
        </StyledAddAttachmentButton>
      </Header>
      <UploadModal
        documentName=""
        handleUpload={handleUploadAndClose}
        handleClose={handleUploadModalClose}
        open={isUploadModalOpen}
      />
      <RemovalDialog
        open={isRemovalDialogOpen}
        handleClose={() => setRemovalDialogOpen(false)}
        onRemove={() => closeAndRemove()}
      />
      <ModalDialog
        open={isWrongFileTypeDialogOpen}
        title={'Vain PDF-tyyppisiä tiedostoja!'}
        content={'Valitsemasi tiedoston tyyppi ei kelpaa. Ole hyvä ja valitse PDF-tyyppinen dokumentti.'}
        actions={wrongFileTypeActionButtons}
      />
      <Spacer />
      {attachments.map((attachment) => (
        <AttachmentItem key={attachment.id} handleRemovePress={handleRemovePress} attachment={attachment} />
      ))}
    </SectionContainer>
  );
};

export default AddAttachment;
