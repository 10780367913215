import { CustomerListComponentAction, sortCustomers } from './customerListComponentActions';
import { CustomerListComponentState } from 'types/customerListState';

// This is a component state reducer, not a Redux reducer

export function listReducer(state: CustomerListComponentState, action: CustomerListComponentAction) {
  switch (action.type) {
    case 'setInput':
      return {
        ...state,
        inputText: action.input,
      };
    case 'filter':
      return {
        ...state,
        filter: action.filter,
        sortedCustomers: {
          ...state.sortedCustomers,
          [state.bankerId]: sortCustomers(state.sortedCustomers?.[state.bankerId], state.sortByKey, action.filter),
        },
      };
    case 'sort':
      return {
        ...state,
        sortByKey: action.key,
        sortedCustomers: {
          ...state.sortedCustomers,
          [state.bankerId]: sortCustomers(state.sortedCustomers[state.bankerId], action.key, state.filter),
        },
      };
    case 'customers':
      return {
        ...state,
        sortedCustomers: {
          ...state.sortedCustomers,
          [state.bankerId]: sortCustomers(action.customers, state.sortByKey, state.filter),
        },
      };
    case 'banker':
      return {
        ...state,
        bankerId: action.bankerId,
      };
    case 'fetching':
      return {
        ...state,
        isFetching: action.value,
      };
    default:
      throw 'Unknown key';
  }
}
