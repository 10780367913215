import { RootState } from 'types/rootState';
import { ProfileState } from 'types/profileState';
import { DIGI_ACCOUNT_MANAGER } from 'constants/accountManagerTypes';
import { DEMO_CUSTOMER } from 'constants/common';

export const selectCustomer = (state: RootState) => state.profile.customer;
export const selectCustomerId = (state: RootState) => state.profile.customer?.toJS().customerId;
export const selectIsCustomerIncompetent = (state: RootState) => state.profile.customer.get('incompetent') ?? false;
export const selectCustomerCategorisation = (state: RootState) =>
  state.profile.customer.get('customerCategorisation') ?? '';

export const selectIsDigiCustomer = (state: ProfileState) =>
  state.customer.get('accountManagerName') === DIGI_ACCOUNT_MANAGER;
export const selectIsDigiEligible = (state: ProfileState) => state.customer.get('isDigiEligible');

export const selectIsBusy = (state: ProfileState) => state.isBusy;
export const selectDraft = (state: ProfileState) => state.draft;
export const selectImages = (state: ProfileState) => state.images;
export const selectHasError = (state: ProfileState) => state.hasError;
export const selectCustomerHeader = (state: ProfileState) => state.customer.get('header') ?? '';
export const selectUploadingImage = (state: ProfileState) => state.uploadingImage;
export const selectLoadingDrafts = (state: ProfileState) => state.loadingDrafts;
export const selectContracts = (state: ProfileState) => state.contracts;

export const selectIsDemoCustomer = (state: RootState): boolean => {
  const customerId = selectCustomerId(state);
  return customerId === DEMO_CUSTOMER;
};
