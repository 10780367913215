import React, { useState, useEffect } from 'react';
import translate from 'counterpart';
import { push } from 'react-router-redux';
import styled from 'styled-components';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import BasicData from 'features/profile/components/BasicData';
import Drafts from 'features/profile/components/Drafts';
import Contracts from 'features/profile/components/Contracts';
import Identity from 'features/profile/components/Identity';
import { ModalDialog, ModalDialogProps } from 'features/common/ModalDialog';
import { PERSON } from 'constants/customerTypes';
import styles from 'features/profile/ProfileView.scss';
import { Sidebar } from './Sidebar';
import { Spinner } from 'features/common/Spinner';
import {
  ImmutableCustomer,
  ImmutableDraft,
  ImmutableImage,
  Customer,
  Image,
  ImmutableImageList,
  BlobInfo,
} from 'types/profileState';
import { ImmutableContracts } from 'types/ordersState';
import { Countries } from 'types/contractsState';
import { IdentityMethod } from 'types/types';
import { ProfileHeading } from 'features/profile/components/ProfileHeading';
import { useAppDispatch } from 'core/hooks';
import { FlexColumn } from 'features/common/StyledComponents';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  isBusy: boolean;
  customer: ImmutableCustomer;
  draft: ImmutableDraft;
  contracts: ImmutableContracts;
  images: ImmutableImageList;
  identityMethods: IdentityMethod[];
  countries: Countries;
  fetchImages: (customerId: string) => void;
  getImageforPreview: (filepath: string) => Promise<BlobInfo>;
  params: { id: string };
  uploadImage: (file: File, info: Partial<Image>, id: string) => Promise<void>;
  location: Location;
  newCustomerContract: () => void;
  getContractPdf: () => void;
  copyContract: (customerId: string, copyContractId: string | undefined) => void;
  setCustomerForm: (customer: Customer) => void;
  uploadingImage: boolean;
  loadingDrafts: boolean;
  deleteIdentityImage: (customerId: string, imageId: string) => void;
  hasPositions: boolean;
  loadingPositions: boolean;
  isDigiCustomer: boolean;
}

export const ProfilePage = ({
  isBusy,
  customer,
  draft,
  contracts,
  images,
  identityMethods,
  countries,
  fetchImages,
  getImageforPreview,
  params,
  uploadImage,
  location,
  newCustomerContract,
  getContractPdf,
  copyContract,
  setCustomerForm,
  uploadingImage,
  loadingDrafts,
  deleteIdentityImage,
  hasPositions,
  loadingPositions,
  isDigiCustomer,
}: Props) => {
  const dispatch = useAppDispatch();

  const [openPreview, setOpenPreview] = useState<boolean>(false);
  const [openNewContractConfirm, setOpenNewContractConfirm] = useState<boolean>(false);
  const [openCopyContractConfirm, setOpenCopyContractConfirm] = useState<boolean>(false);
  const [copyContractId, setCopyContractId] = useState<string | undefined>(undefined);
  const [previewBlobInfo, setPreviewBlobInfo] = useState<BlobInfo | undefined>(undefined);
  const [openScreenCustomerConfirm, setOpenScreenCustomerConfirm] = useState<boolean>(false);
  const [openScreenPersonConfirm, setOpenScreenPersonConfirm] = useState<boolean>(false);

  useEffect(() => {
    fetchImages(params.id);
  }, []);

  const previewImage = (filepath: string) => {
    setOpenPreview(true);
    getImageforPreview(filepath)
      .then((res: BlobInfo) => {
        setPreviewBlobInfo(res);
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
      });
  };

  const hideModal = () => {
    setOpenPreview(false);
    setPreviewBlobInfo(undefined);
    setOpenNewContractConfirm(false);
    setOpenCopyContractConfirm(false);
    setOpenScreenCustomerConfirm(false);
    setOpenScreenPersonConfirm(false);
    setCopyContractId(undefined);
  };

  const dismissScreenConfirm = () => {
    setOpenScreenCustomerConfirm(false);
    setOpenScreenPersonConfirm(false);
    newContractOrDraftsConfirm();
  };

  const uploadProfileImage = async (image: ImmutableImage) => {
    const info = {
      description: image.get('description'),
      identityMethod: image.get('identityMethod'),
      identityMethodIdentificationNumber: image.get('identityMethodIdentificationNumber'),
      identityMethodIssuer: image.get('identityMethodIssuer'),
      identityMethodIssuerCountry: image.get('identityMethodIssuerCountry'),
      identityMethodDateOfIssue: image.get('identityMethodDateOfIssue'),
      identityMethodExpirationDate: image.get('identityMethodExpirationDate'),
    };
    const file = image.get('image');
    const id = params.id;
    await uploadImage(file, info, id);
    return fetchImages(params.id);
  };

  const handleNewContract = () => {
    if (!customer.get('lastScreened')) {
      if (customer.get('customerType') === PERSON) {
        setOpenScreenPersonConfirm(true);
      } else {
        setOpenScreenCustomerConfirm(true);
      }
    } else {
      newContractOrDraftsConfirm();
    }
  };

  const newContractOrDraftsConfirm = () => {
    if (draft.size > 0) {
      setOpenNewContractConfirm(true);
    } else {
      navigateNewContract();
    }
  };

  const getUrlSeparator = () => (location.pathname.endsWith('/') ? '' : '/');

  const navigateNewContract = () => {
    const urlSeparator = getUrlSeparator();
    newCustomerContract();
    dispatch(push(`${location.pathname}${urlSeparator}contracts/selection`));
  };

  const navigateToDrafts = () => {
    const urlSeparator = getUrlSeparator();
    dispatch(
      push({
        pathname: `${location.pathname}${urlSeparator}contracts/overview`,
        state: { restore: true },
      })
    );
  };

  const navigateToScreening = () => {
    setCustomerForm(customer.toJS());
    if (customer.get('customerType') === PERSON) {
      dispatch(push(`customer/${customer.get('customerId')}/edit`));
    } else {
      dispatch(push(`customer/${customer.get('customerId')}/summary`));
    }
  };

  const handleCopyContract = (copyContractId: string) => {
    if (draft.size > 0) {
      setOpenCopyContractConfirm(true);
      setCopyContractId(copyContractId);
    } else {
      copyContract(customer.get('id'), copyContractId);
    }
  };

  const navigateCopyContract = () => {
    copyContract(customer.get('id'), copyContractId);
  };

  const getModalProps = (): ModalDialogProps => {
    const properties = {
      open: false,
      title: '',
      content: '',
      actions: [],
    };
    if (openPreview) {
      let content: JSX.Element;
      if (!previewBlobInfo) {
        content = <Spinner className={styles.spinner} size={100} />;
      } else if (previewBlobInfo.contentType?.startsWith('image/')) {
        content = (
          <img
            style={{ display: 'block', margin: '0 auto', maxHeight: '400px' }}
            src={previewBlobInfo.src}
            onLoad={() => {
              window.dispatchEvent(new Event('resize'));
            }}
          />
        );
      } else {
        content = (
          <a href={previewBlobInfo.src} target="blank">
            <IconAndLabel>
              <PictureAsPdfIcon />
              {translate('common.open')}
            </IconAndLabel>
          </a>
        );
      }

      Object.assign(properties, {
        open: true,
        close: hideModal,
        modal: false,
        content,
        actions: [
          <StyledButton onClick={hideModal} key="imagepreview.close">
            {translate('common.close')}
          </StyledButton>,
        ],
      });
    } else if (openScreenCustomerConfirm) {
      Object.assign(properties, {
        open: true,
        title: translate('customer.notScreened'),
        content: translate('customer.notScreenedContent'),
        actions: [
          <StyledButton
            data-testkey={'screen'}
            variant="text"
            onClick={dismissScreenConfirm}
            key="customer.continueWithoutScreening"
          >
            {translate('customer.continueWithoutScreening')}
          </StyledButton>,
          <StyledButton variant="text" onClick={navigateToScreening} key="customer.continueToScreening">
            {translate('customer.continueToScreening')}
          </StyledButton>,
          <StyledButton variant="text" onClick={hideModal} key="customer.cancel">
            {translate('customer.cancel')}
          </StyledButton>,
        ],
      });
    } else if (openScreenPersonConfirm) {
      Object.assign(properties, {
        open: true,
        title: translate('customer.notScreenedPerson'),
        content: translate('customer.notScreenedPersonContent'),
        actions: [
          <StyledButton
            data-testkey={'screen'}
            variant="text"
            onClick={dismissScreenConfirm}
            key="customer.continueWithoutScreening"
          >
            {translate('customer.continueWithoutScreening')}
          </StyledButton>,
          <StyledButton variant="text" onClick={navigateToScreening} key="customer.screenPerson">
            {translate('customer.screenPerson')}
          </StyledButton>,
          <StyledButton variant="text" onClick={hideModal} key="customer.cancel">
            {translate('customer.cancel')}
          </StyledButton>,
        ],
      });
    } else if (openNewContractConfirm || openCopyContractConfirm) {
      Object.assign(properties, {
        open: true,
        title: translate('customer.hasDrafts'),
        content: translate('customer.confirmNewContractContent'),
        actions: [
          <StyledButton
            data-testkey={'removeAndCreate'}
            variant="text"
            onClick={navigateNewContract}
            key={'customer.removeAndCreate'}
          >
            {translate('customer.removeAndCreate')}
          </StyledButton>,
          <StyledButton variant="text" onClick={navigateToDrafts} key={'customer.continueToDrafts'}>
            {translate('customer.continueToDrafts')}
          </StyledButton>,
          <StyledButton variant="text" onClick={hideModal} key={'customer.cancel'}>
            {translate('customer.cancel')}
          </StyledButton>,
        ],
      });
      if (openCopyContractConfirm) {
        Object.assign(properties, {
          actions: [
            <StyledButton variant="text" onClick={navigateCopyContract} key={'customer.removeAndCreate'}>
              {translate('customer.removeAndCreate')}
            </StyledButton>,
            <StyledButton variant="text" onClick={navigateToDrafts} key={'customer.continueToDrafts'}>
              {translate('customer.continueToDrafts')}
            </StyledButton>,
            <StyledButton variant="text" onClick={hideModal} key={'customer.cancel'}>
              {translate('customer.cancel')}
            </StyledButton>,
          ],
        });
      }
    }
    return properties;
  };

  const deleteImage = (imageId: string) => {
    deleteIdentityImage(customer.get('id'), imageId);
  };

  const profile = customer;
  const customerType = profile.get('customerType');

  return (
    <div className={styles.content}>
      <header>
        <ProfileHeading
          isBusy={isBusy}
          profile={profile.toJS()}
          profileImageStyle={styles.profileImg}
          isDigiCustomer={isDigiCustomer}
          riskProfileNotFoundStyle={styles.riskProfileNotFound}
        />
      </header>

      {isBusy ? (
        <section className={'row'}>
          <div className={`${styles.sidebar} large-3`}>
            <ul className={styles.actions}>
              <li>
                <div>
                  <Spinner size={30} />
                  <div>&nbsp;&nbsp;&nbsp;&nbsp; Ladataan...</div>
                </div>
              </li>
            </ul>
          </div>

          <div className="columns">
            <section className={styles.userInfo}>
              <Spinner className={styles.spinner} size={100} />
            </section>
          </div>
        </section>
      ) : (
        <section className={'row'}>
          <div className={`${styles.sidebar} large-3`}>
            <Sidebar
              customer={profile}
              setCustomerForm={setCustomerForm}
              loadingDrafts={loadingDrafts}
              location={location}
              handleNewContract={handleNewContract}
              hasPositions={hasPositions}
              loadingPositions={loadingPositions}
            />
          </div>

          <div className="columns">
            <section className={styles.userInfo}>
              <section>
                <div className={styles.profileSubHeader}>
                  <header>{translate(`customer.basicInfo`)}</header>
                </div>
                <BasicData profile={profile} />
              </section>

              <section>
                <div className="row align-justify">
                  <div>{translate('customer.customerTypeLabel')}</div>
                  <span>{translate(`customer.${customerType}`)}</span>
                </div>
              </section>

              <Identity
                identityMethods={identityMethods}
                countries={countries}
                uploadProfileImage={uploadProfileImage}
                images={images}
                onPreviewImage={previewImage}
                customerState={profile.get('state')}
                uploadingImage={uploadingImage}
                deleteIdentityImage={deleteImage}
              />

              <Drafts draft={draft} link={`${location.pathname}/contracts/overview`} />

              <Contracts contracts={contracts} openFile={getContractPdf} copyContract={handleCopyContract} />
            </section>
          </div>
          <ModalDialog {...getModalProps()} />
        </section>
      )}
    </div>
  );
};

const IconAndLabel = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  height: 100%;

  svg,
  img {
    margin-bottom: 0.5rem;
  }
`;
