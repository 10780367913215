import React, { ChangeEvent } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { fontSize } from 'features/common/StyledComponents';
import { RadioButton } from './RadioButton';
import { StyledRadioButtonContainer } from './StyledRadioButton';

interface Props {
  value: string;
  selected: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: object | string;
  bg?: string;
  disabled?: boolean;
  className?: string;
  type?: 'checkbox' | 'radio';
  'data-testkey'?: string;
}

const FormSelectionItem = ({
  value,
  selected,
  onChange,
  children,
  bg,
  className,
  disabled = false,
  type,
  'data-testkey': dataTestKey,
}: Props) => {
  return (
    <LabelContainer selected={selected} disabled={disabled} className={className} bg={bg} data-testkey={dataTestKey}>
      <RadioButton onChange={onChange} checked={selected} value={value} type={type} disabled={disabled} />
      {children}
    </LabelContainer>
  );
};

interface LabelProps {
  selected: boolean;
  disabled: boolean;
  bg?: string;
}

const LabelContainer = styled.label<LabelProps>`
  position: relative;
  height: 52px;
  display: flex;
  align-items: center;
  font-size: ${fontSize.normal};
  color: ${colors.black};
  border-color: ${colors.warm_gray};
  background: ${(props) => props.bg};
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  margin-top: -2px;
  transition: all 250ms 0ms ease-out;
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.7;
    `}

  ${(props) =>
    props.selected &&
    css`
      z-index: 1;
      color: ${colors.primary_text};
      border-color: ${colors.primary};
      background: ${colors.light_green};
    `};

  ${(props) =>
    props.selected &&
    props.disabled &&
    css`
      border-color: ${colors.med_gray};
      z-index: -1;
    `};

  &:hover {
    ${(props) =>
      !props.selected &&
      !props.disabled &&
      css`
        filter: brightness(0.95);
      `};

    ${StyledRadioButtonContainer} {
      background: ${(props) => !props.selected && 'white'};
      transition: all 150ms 0ms ease-out;
    }
  }
`;

export default FormSelectionItem;
