import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ModalDialog } from 'features/common/ModalDialog';
import { InputFilePdf, TextInput } from 'features/common/StyledComponents';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface UploadModalProps {
  documentName: string;
  handleUpload: (event: ChangeEvent<HTMLInputElement>, descriptiveName: string) => void;
  handleClose: () => void;
  open: boolean;
}

const UploadModal = (props: UploadModalProps) => {
  const { documentName, handleClose, handleUpload, open } = props;
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const inputTextRef = useRef<HTMLInputElement | null>(null);
  const [documentDescriptiveName, setDocumentDescriptiveName] = useState<string>('');

  useEffect(() => {
    if (open === false) {
      setDocumentDescriptiveName('');
    }
  }, [open]);

  const handleFileUploadOpen = () => {
    if (inputFileRef !== null && inputFileRef.current !== null) {
      inputFileRef.current.click();
    }
  };

  const onChangeDocumentDescriptiveNameValue = (event: ChangeEvent<HTMLInputElement>) => {
    setDocumentDescriptiveName(event.target.value);
  };

  return (
    <ModalDialog
      content={
        <>
          <InputFilePdf
            onChange={(event: ChangeEvent<HTMLInputElement>) => handleUpload(event, documentDescriptiveName)}
            type="file"
            accept="application/pdf"
            id="inputimage"
            name="inputpdf"
            capture="pdf"
            ref={inputFileRef}
          />
          <h4>{documentName}</h4>
          <h4>Liitteen nimi:</h4>
          <h6>Esim. &quot;Soveltuvuusarviointi&quot;</h6>
          <TextInput
            value={documentDescriptiveName}
            onChange={onChangeDocumentDescriptiveNameValue}
            ref={inputTextRef}
          />
        </>
      }
      actions={[
        <StyledButton
          variant="text"
          onClick={() => handleFileUploadOpen()}
          data-testkey="select-file-button"
          key={'selectFile'}
          disabled={documentDescriptiveName.length < 1}
        >
          Valitse tiedosto
        </StyledButton>,
        <StyledButton variant="text" onClick={handleClose} key={'cancel'}>
          Peruuta
        </StyledButton>,
      ]}
      open={open}
    />
  );
};

export default UploadModal;
