import _ from 'lodash';
import { get, set } from './localStorage';
import { User } from 'types/types';

export const KEY = 'lastSearchedUsers';
const LIMIT = 6;

function isUserAlreadyIn(users: User[], user: User) {
  return _.find(users, (u) => u.customerId === user.customerId) !== undefined;
}

function userRemoved(users: User[], user: User) {
  return _.filter(users, (u) => u.customerId !== user.customerId);
}

function removeOldVersionOfUserIfExists(users: User[], user: User) {
  if (isUserAlreadyIn(users, user)) {
    return userRemoved(users, user);
  }
  return users;
}

function pushUser(users: User[], user: User) {
  if (users.length >= LIMIT) {
    return _.drop(_.concat(users, user));
  }
  return _.concat(users, user);
}

function getFromStorage() {
  return get(KEY) || [];
}

export function getLastSearchedUsers() {
  return _.reverse(getFromStorage());
}

export function save(user: User) {
  const existingUsers = removeOldVersionOfUserIfExists(getFromStorage(), user);
  const usersUpdated = pushUser(existingUsers, user);
  set(KEY, usersUpdated);
}

export function update(user: User) {
  const existingUsers = getFromStorage();
  if (isUserAlreadyIn(existingUsers, user)) {
    const existingUsersUserRemoved = userRemoved(existingUsers, user);
    const usersUpdated = pushUser(existingUsersUserRemoved, user);
    set(KEY, usersUpdated);
  }
}
