import {
  SET_LOADING_ASSET_CLASSES,
  ASSET_CLASSES_SUCCESS,
  SET_LOADING_EXAMPLE_PORTFOLIOS,
  EXAMPLE_PORTFOLIO_SUCCESS,
  SET_LOADING_INSTRUMENT_GROUPS,
  INSTRUMENT_GROUPS_SUCCESS,
  CommonDataAction,
} from './commonDataActions';
import { AssetResponse, CommonDataState } from 'types/commonDataState';

const initialState: CommonDataState = {
  assetClasses: [],
  loadingAssetClasses: false,
  examplePortfolios: [],
  loadingExamplePortfolios: false,
  loadingInstrumentGroups: false,
  instrumentGroups: [],
};

export function commonDataReducer(state = initialState, action: CommonDataAction) {
  switch (action.type) {
    case SET_LOADING_EXAMPLE_PORTFOLIOS:
      return {
        ...state,
        loadingExamplePortfolios: action.value,
      };

    case EXAMPLE_PORTFOLIO_SUCCESS:
      return {
        ...state,
        examplePortfolios: action.result,
      };

    case SET_LOADING_ASSET_CLASSES:
      return {
        ...state,
        loadingAssetClasses: action.value,
      };

    case ASSET_CLASSES_SUCCESS:
      return {
        ...state,
        assetClasses: action.result.map((i: AssetResponse) => ({
          assetClassId: i.category,
          assetCategory: i.type,
          name: i.label,
        })),
        loadingAssetClasses: false,
      };

    case SET_LOADING_INSTRUMENT_GROUPS:
      return {
        ...state,
        loadingInstrumentGroups: action.value,
      };

    case INSTRUMENT_GROUPS_SUCCESS:
      return {
        ...state,
        instrumentGroups: action.result,
        loadingInstrumentGroups: false,
      };

    default:
      return state;
  }
}
