import React, { ChangeEvent, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import moment from 'moment';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { SelectWithLabel } from './SelectWithLabel';
import Date from './Date';
import {
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
  RECEIVED_FROM_CLIENT_DATE,
  BASIS_TYPE,
  REPRESENTATIVE_SSN,
  REPRESENTATIVE_NAME,
} from 'constants/orderFields';
import { CLIENT, REPRESENTATIVE, INITIALIZER_BANKER, CLIENT_INITIATIVE } from 'constants/receiveInfoOptions';
import { receivedFromClientTypes, WRITTEN_METHOD, NONE } from 'constants/shareOptions';
import { getErrorText } from 'core/portfolios';
import { ValidationErrorValue, OrderInitializerType, OrderBasisType } from 'types/orderDialogState';
import {
  getSuitabilityOfSelectedInstrument,
  setEditorValue,
  setEditorValues,
  setInstrumentOptions,
} from 'features/orderDialog/orderDialogActions';
import { SelectValue } from 'types/types';
import { EditorValues } from 'features/orderDialog/orderDialogTypes';
import { useAppDispatch } from 'core/hooks';
import {
  CONSULTATIVE_AGREEMENT,
  CONSULTATIVE_AGREEMENT_TYPE,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
  CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE,
  SECURITIES_BROKERAGE_CONTRACT_TYPE,
  FULL_POWER_OF_ATTORNEY_TYPE,
} from 'constants/contractNames';
import { PortfolioById } from 'types/portfolioState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';
import { isValidSSN, isEmpty } from 'core/validations';
import { selectEditor } from 'features/orderDialog/orderDialogSelectors';
import { PERSON } from 'constants/customerTypes';
import { InstrumentForm } from 'constants/instrumentForms';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  orderInitializerType: string;
  receivedFromClientDate: string;
  receivedFromClientMethod: string;
  maxReceivedFromClientDate: string | undefined;
  validationErrors: ValidationErrorValue[];
  orderInitializerTypes: OrderInitializerType[];
  orderBasisTypes: OrderBasisType[];
  orderBasis: string;
  contractType?: string;
  representativeSsn: string;
  representativeName: string;
  selectedPortfolio: PortfolioById;
  customerId: string;
  customerType: string;
  instrumentFormOptions: { value: InstrumentForm; label: string }[];
}

export const OrderReceiveInfo = (props: Props) => {
  const dispatch = useAppDispatch();

  const assignment = useSelector(selectEditor).toJS();
  const [errorTextName, setErrorTextName] = useState<string | undefined>();
  const [errorTextSsn, setErrorTextSsn] = useState<string | undefined>();
  const [showNotAvailableModal, setShowNotAvailableModal] = useState(false);

  useEffect(() => {
    if (assignment.financialInstrumentId) {
      const isInstrumentFormAvailable = instrumentFormOptions?.find((x) => x.value === assignment.instrumentForm);
      if (!isInstrumentFormAvailable) {
        setShowNotAvailableModal(true);
      }
    }
  }, [assignment.receivedFromClientMethod]);

  const setReceiveType = (event: SelectValue) => {
    dispatch(setEditorValue(RECEIVED_FROM_CLIENT_METHOD, event ? event.value : ''));
  };

  const setGiveType = (event: SelectValue) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const values: EditorValues = {};
    const type = event ? event.value : '';
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    values[ORDER_INITIALIZER_TYPE] = type;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    values[RECEIVED_FROM_CLIENT_METHOD] = type === CLIENT || type === REPRESENTATIVE ? WRITTEN_METHOD : NONE;

    dispatch(setEditorValues(values));

    // make sure suitability is still ok in possible assignment when instrument is selected, after changing of order initializer type
    if (
      assignment &&
      assignment.financialInstrumentId &&
      (type !== REPRESENTATIVE || props.customerType !== 'person')
    ) {
      dispatch(
        getSuitabilityOfSelectedInstrument(
          props.customerId,
          selectedPortfolio.portfolioId,
          assignment.financialInstrumentId,
          assignment.side,
          CLIENT,
          props.customerType,
          orderBasis
        )
      );
    }
  };

  const setReceiveDate = (_event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditorValue(RECEIVED_FROM_CLIENT_DATE, moment(_event.currentTarget.value).utc().format()));
  };

  const setBasisType = (event: SelectValue) => {
    dispatch(setEditorValue(BASIS_TYPE, event ? event.value : ''));
    // make sure suitability is still ok in possible assignment when instrument is selected, after changing of basis type
    if (
      assignment &&
      assignment.financialInstrumentId &&
      (assignment.orderInitializerType !== REPRESENTATIVE || props.customerType !== PERSON)
    ) {
      dispatch(
        getSuitabilityOfSelectedInstrument(
          props.customerId,
          selectedPortfolio.portfolioId,
          assignment.financialInstrumentId,
          assignment.side,
          orderInitializerType,
          props.customerType,
          event ? (event.value as string) : undefined
        )
      );
    }
    dispatch(setInstrumentOptions([]));
  };

  const setRepresentativeSsn = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isValidSSN(value)) {
      setErrorTextSsn('Virheellinen henkilötunnus');
    } else {
      setErrorTextSsn(undefined);
    }
    dispatch(setEditorValue(REPRESENTATIVE_SSN, event.target.value));
  };

  const setRepresentativeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isEmpty(value)) {
      setErrorTextName('Edustajan nimi on pakollinen tieto');
    } else {
      setErrorTextName(undefined);
    }
    dispatch(setEditorValue(REPRESENTATIVE_NAME, event.target.value));
  };

  const {
    orderInitializerType,
    receivedFromClientDate,
    receivedFromClientMethod,
    maxReceivedFromClientDate,
    validationErrors,
    orderInitializerTypes,
    orderBasisTypes,
    contractType,
    orderBasis,
    representativeSsn,
    representativeName,
    selectedPortfolio,
    instrumentFormOptions,
  } = props;

  let componentScopedContractType;
  if (!contractType) {
    if (selectedPortfolio && selectedPortfolio.contractName) {
      const contractName = selectedPortfolio.contractName;
      componentScopedContractType = contractName === CONSULTATIVE_AGREEMENT ? CONSULTATIVE_AGREEMENT_TYPE : '';
      if (componentScopedContractType === '') {
        componentScopedContractType =
          contractName === CUSTOMER_RELATIONSHIP_AGREEMENT ? CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE : '';
      }
    }
  } else {
    componentScopedContractType = contractType;
  }

  const columnsClass =
    componentScopedContractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE ||
    componentScopedContractType === CONSULTATIVE_AGREEMENT_TYPE ||
    componentScopedContractType === SECURITIES_BROKERAGE_CONTRACT_TYPE
      ? 'columns small-3'
      : 'columns small-4';

  const modalContent = () => {
    return (
      <div>
        {`Toimeksiantoa instrumentille ${
          assignment?.financialInstrumentName
        } ei voi tehdä, kyseiseen rahoitusvälinetyyppiin (${translate(
          `order.${assignment?.financialInstrumentForm}`
        )}) ei voi ottaa toimeksiantoja vastaan puhelimitse.`}
        <br /> Huomaathan, että vastaanottotapa muutetaan automaattisesti takaisin kirjalliseksi.
      </div>
    );
  };
  const closeErrorModal = () => {
    setShowNotAvailableModal(false);
    // since this modal opens only when non-suitable receive method is selected (phone), go back to written
    dispatch(setEditorValue(RECEIVED_FROM_CLIENT_METHOD, WRITTEN_METHOD));
  };
  const errorActions = [
    <StyledButton variant="text" onClick={closeErrorModal} key={'Peruuta'} data-testkey="modal-button-cancel">
      OK
    </StyledButton>,
  ];

  return (
    <div className={'row'}>
      <ModalDialog
        title={'Virheellinen vastaanottotapa'}
        open={showNotAvailableModal}
        content={modalContent()}
        actions={errorActions}
      />
      <div className={columnsClass}>
        <SelectWithLabel
          label={translate('order.giveType')}
          onChange={setGiveType}
          options={orderInitializerTypes}
          value={
            componentScopedContractType === FULL_POWER_OF_ATTORNEY_TYPE ? INITIALIZER_BANKER : orderInitializerType
          }
          clearable={false}
          data-testkey="order-give-type"
        />
      </div>

      {componentScopedContractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE ||
      componentScopedContractType === CONSULTATIVE_AGREEMENT_TYPE ||
      componentScopedContractType === SECURITIES_BROKERAGE_CONTRACT_TYPE ? (
        <div className={columnsClass}>
          <SelectWithLabel
            label={translate('order.basis')}
            onChange={setBasisType}
            options={orderBasisTypes}
            value={componentScopedContractType === SECURITIES_BROKERAGE_CONTRACT_TYPE ? CLIENT_INITIATIVE : orderBasis}
            clearable={false}
            data-testkey="order-basis"
          />
        </div>
      ) : (
        ''
      )}

      <div className={columnsClass}>
        <Date
          label={translate('order.receiveDate')}
          onChange={setReceiveDate}
          value={moment(receivedFromClientDate).format('YYYY-MM-DDTHH:mm')}
          type="datetime-local"
          tabIndex={-1}
          max={orderInitializerType === CLIENT ? maxReceivedFromClientDate : undefined}
          error={getErrorText(RECEIVED_FROM_CLIENT_DATE, validationErrors)}
        />
      </div>

      {componentScopedContractType === CUSTOMER_RELATIONSHIP_AGREEMENT_TYPE ||
      componentScopedContractType === CONSULTATIVE_AGREEMENT_TYPE ||
      componentScopedContractType === SECURITIES_BROKERAGE_CONTRACT_TYPE ? (
        <div className={columnsClass}>
          <SelectWithLabel
            label={translate('order.receiveType')}
            onChange={setReceiveType}
            options={receivedFromClientTypes}
            value={receivedFromClientMethod}
            clearable={false}
            data-testkey="order-receive-type"
          />
        </div>
      ) : (
        ''
      )}

      {orderInitializerType === REPRESENTATIVE ? (
        <div className="columns small-6">
          <FormGroup>
            <FormLabel
              sx={{
                color: '#000000',
                fontSize: '12px',
                marginTop: '0.8rem',
              }}
            >
              {translate('order.representativeName')}
            </FormLabel>
            <StyledTextField
              value={representativeName}
              helperText={errorTextName}
              inputProps={{
                display: 'block',
              }}
              sx={[
                {
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    marginTop: 0,
                  },
                  '& p.MuiFormHelperText-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                },
              ]}
              onChange={setRepresentativeName}
              data-testkey="representative-name-textfield"
              variant="standard"
            />
          </FormGroup>
        </div>
      ) : (
        ''
      )}
      {orderInitializerType === REPRESENTATIVE ? (
        <div className="columns small-6">
          <FormGroup>
            <FormLabel
              sx={{
                color: '#000000',
                fontSize: '12px',
                marginTop: '0.8rem',
              }}
            >
              {translate('order.representativeSsn')}
            </FormLabel>
            <StyledTextField
              value={representativeSsn}
              helperText={errorTextSsn}
              inputProps={{
                display: 'block',
              }}
              sx={[
                {
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    marginTop: 0,
                  },
                  '& p.MuiFormHelperText-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                },
              ]}
              onChange={setRepresentativeSsn}
              data-testkey="representative-ssn-textfield"
              variant="standard"
            />
          </FormGroup>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
