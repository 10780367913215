import { Map, List } from 'immutable';
import _ from 'lodash';
import { createSelector } from 'reselect';
import {
  WEB_SERVICE_CONTRACT,
  AUTHORIZATION_CONTRACT,
  AUTHORIZATION_CONTRACT_COMPANY,
  BASIC_INFO_PERSON,
  BASIC_INFO_COMPANY,
  KYC_PERSON,
  KYC_COMPANY,
  BASIC_INFO_INSTITUTION,
  EMAIL_REPORTS,
  EMAIL_REPORTS_COMPANY,
  WEB_SERVICE_CONTRACT_PROXY,
} from 'constants/contractIds';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';
import { templateCategories } from 'constants/templateCategories';
import {
  ContractsState,
  ImmutableSignatureMethods,
  ImmutableContractSignatories,
  AvailableTemplate,
  GroupedTemplate,
  ImmutableAvailableTemplates,
  ImmutableRepresentatives,
} from 'types/contractsState';
import { RootState } from 'types/rootState';
import { ImmutablePdfPreviewLink, ImmutableTemplates } from 'types/ordersState';
import { SUITABILITY_TEMPLATE_ID } from './contractsConstants';
import { PdfAttachment } from './contracts.types';

export const selectContactFromWebServiceContract = (state: ContractsState) =>
  state.values.getIn([WEB_SERVICE_CONTRACT, 'sections', 'newSection_3', 'user'], Map()) || Map();

export const selectContactFromEmailReportsContract = (state: ContractsState) =>
  state.values.getIn([EMAIL_REPORTS, 'sections', 'newSection', 'newQuestion'], Map()) || Map();

export const selectContactFromEmailReportsCompanyContract = (state: ContractsState) =>
  state.values.getIn([EMAIL_REPORTS_COMPANY, 'sections', 'newSection', 'newQuestion'], Map()) || Map();

export const selectContactFromAuthorizationContract = (state: ContractsState) =>
  state.values.getIn([AUTHORIZATION_CONTRACT, 'sections', 'newSection', 'authorizationPerson'], Map());

export const selectContactFromCompanyAuthorizationContract = (state: ContractsState) =>
  state.values.getIn([AUTHORIZATION_CONTRACT_COMPANY, 'sections', 'newSection', 'authorizationPerson'], Map());

function eachContractHasSignatureMethodSelected(
  pdfLinks: ImmutablePdfPreviewLink,
  signatureMethods: ImmutableSignatureMethods
): boolean {
  return (
    !pdfLinks.isEmpty() &&
    pdfLinks.every((value, key) => {
      if (key === SUITABILITY_TEMPLATE_ID) {
        return true;
      }
      return key ? signatureMethods.has(key === WEB_SERVICE_CONTRACT_PROXY ? WEB_SERVICE_CONTRACT : key) : false;
    })
  );
}

function eachElectricSignatureContractHasSignatory(
  signatureMethods: ImmutableSignatureMethods,
  contractsSignatories: ImmutableContractSignatories
): boolean {
  const filteredSignatureMethods = signatureMethods.filter(
    (value) => value === SENT_FOR_SIGNING || value === SENT_FOR_INK_SIGNING
  );

  const matchingSignatories = filteredSignatureMethods.every((value, key) => {
    let hasSignatory = key ? hasContractSignatory(contractsSignatories, key) : false;
    if (!hasSignatory) {
      key = key === WEB_SERVICE_CONTRACT ? WEB_SERVICE_CONTRACT_PROXY : key;
      hasSignatory = key ? hasContractSignatory(contractsSignatories, key) : false;
    }

    return hasSignatory;
  });

  return matchingSignatories;
}

function hasContractSignatory(signatories: ImmutableContractSignatories, key: string): boolean {
  const contractsSignatoriesHasKey = key ? signatories.has(key) : false;
  const contractsSignatoriesHasSize = key && contractsSignatoriesHasKey ? signatories.get(key).size > 0 : false;
  const hasKeyAndSize = !!(contractsSignatoriesHasKey && contractsSignatoriesHasSize);
  return hasKeyAndSize;
}

function signaturesInAttachmentsAreOk(attachments: PdfAttachment[]): boolean {
  let returnValue = true;
  attachments.forEach((attachment) => {
    if (
      attachment.signatureMethod !== SENT_FOR_SIGNING &&
      attachment.signatureMethod !== SENT_FOR_INK_SIGNING &&
      attachment.signatureMethod !== SENT_FOR_MANUAL_SIGNING
    ) {
      returnValue = false;
    }

    if (
      (attachment.signatureMethod === SENT_FOR_SIGNING || attachment.signatureMethod === SENT_FOR_INK_SIGNING) &&
      attachment.signatures.length < 1
    ) {
      returnValue = false;
    }

    if (
      attachment.signatureMethod === SENT_FOR_MANUAL_SIGNING &&
      (!attachment.manualSignatures || attachment.manualSignatures < 1)
    ) {
      returnValue = false;
    }
  });

  return returnValue;
}

export function canContinueToNextStep(state: RootState): boolean {
  const allContractsHasSignatureMethodSelected = eachContractHasSignatureMethodSelected(
    state.contracts.pdfPreviewLinks,
    state.contracts.signatureMethods
  );
  const allElectricSignatureContractHasSignatory = eachElectricSignatureContractHasSignatory(
    state.contracts.signatureMethods,
    state.contracts.contractsSignatories
  );
  const allSignaturesInAttachmentsAreOk = signaturesInAttachmentsAreOk(state.contracts.attachmentPdfs);
  const allAttachmentOkIfSuitability = attachmentOkIfSuitability(state);
  const doesNotHaveWebServiceContractSelected = !state.contracts.selected.get(WEB_SERVICE_CONTRACT, false);
  const doesNotHaveWebServiceContractProxySelected = !state.contracts.selected.get(WEB_SERVICE_CONTRACT_PROXY, false);
  const doesNotHaveWebServiceContract = !state.contracts.webServiceContractExist;

  const canContinue =
    allContractsHasSignatureMethodSelected &&
    allElectricSignatureContractHasSignatory &&
    allSignaturesInAttachmentsAreOk &&
    allAttachmentOkIfSuitability &&
    (doesNotHaveWebServiceContractSelected ||
      doesNotHaveWebServiceContractProxySelected ||
      doesNotHaveWebServiceContract);

  return canContinue;
}

function attachmentOkIfSuitability(state: RootState): boolean {
  if (!state.contracts.selected.get(SUITABILITY_TEMPLATE_ID)) {
    return true;
  }
  return (
    state.contracts.selected.get(SUITABILITY_TEMPLATE_ID) === true &&
    state.contracts.attachmentPdfs &&
    state.contracts.attachmentPdfs.length > 0
  );
}

export function selectTemplates(state: RootState): ImmutableTemplates {
  const selectedContracts = state.contracts.selected;
  const templates = state.contracts.templates;
  const selectedTemplates = templates?.filter(
    (t) => selectedContracts.get(t?.contractType ?? '') === true
  ) as ImmutableTemplates;
  return selectedTemplates;
}

export const isPersonIncompetent = (state: RootState): boolean =>
  state.contracts.values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'incompetentOrRep'], false) ||
  state.contracts.values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'incompetentOrRep'], false);

const hasPersonPublicTrustee = (state: RootState): boolean =>
  state.contracts.values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'publicTrustee'], false) ||
  state.contracts.values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'publicTrustee'], false);

const selectPrimaryContactFromContacts = (state: RootState) => {
  const contact = _.cloneDeep(state.profile.customer.get('contacts', [])[0]);
  contact.customerId = contact.contactId;
  delete contact.contactId;
  return contact;
};

const selectPrimaryContactFromPerson = (state: RootState, isKycContract: boolean) => {
  const values = state.contracts.values;
  let contact;
  if (!isPersonIncompetent(state) || hasPersonPublicTrustee(state)) {
    if (isKycContract) {
      contact = {
        customerId: values.getIn([KYC_PERSON, 'clientId']),
        proxyOfCustomerId: null,
        firstName: values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'info', 'firstName']),
        lastName: values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'info', 'lastName']),
        ssn: values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'info', 'ssn']),
        email: values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'contact', 'email']),
        phoneNumber: values.getIn([KYC_PERSON, 'sections', 'basicInfo', 'contact', 'phoneNumber']),
      };
    } else {
      contact = {
        customerId: values.getIn([BASIC_INFO_PERSON, 'clientId']),
        proxyOfCustomerId: null,
        firstName: values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'info', 'firstName']),
        lastName: values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'info', 'lastName']),
        ssn: values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'info', 'ssn']),
        email: values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'contact', 'email']),
        phoneNumber: values.getIn([BASIC_INFO_PERSON, 'sections', 'basicInfo', 'contact', 'phoneNumber']),
      };
    }
  } else {
    let proxyOfCustomerId;
    let representatives;
    if (isKycContract) {
      proxyOfCustomerId = values.getIn([KYC_PERSON, 'clientId']);
      representatives = values.getIn(
        [KYC_PERSON, 'sections', 'basicInfo', 'representatives'],
        List() as ImmutableRepresentatives
      );
    } else {
      proxyOfCustomerId = values.getIn([BASIC_INFO_PERSON, 'clientId']);
      representatives = values.getIn(
        [BASIC_INFO_PERSON, 'sections', 'basicInfo', 'representatives'],
        List() as ImmutableRepresentatives
      );
    }

    const first = representatives.first();

    if (first) {
      contact = {
        customerId: first.get('customerId'),
        proxyOfCustomerId,
        firstName: first.get('firstName', ''),
        lastName: first.get('lastName', ''),
        ssn: first.get('identityNumber', ''),
        email: first.get('email', ''),
        phoneNumber: first.get('phoneNumber', ''),
      };
    }
  }
  return contact;
};

const selectProxyOfCustomerId = (state: RootState): string | null => {
  const basicInfoCompany = state.contracts.selected.get(BASIC_INFO_COMPANY, false);
  const basicInfoInstitution = state.contracts.selected.get(BASIC_INFO_INSTITUTION, false);
  const kycInfoCompany = state.contracts.selected.get(KYC_COMPANY, false);

  if (basicInfoCompany) {
    return state.contracts.values.getIn([BASIC_INFO_COMPANY, 'clientId']);
  } else if (basicInfoInstitution) {
    return state.contracts.values.getIn([BASIC_INFO_INSTITUTION, 'clientId']);
  } else if (kycInfoCompany) {
    return state.contracts.values.getIn([KYC_COMPANY, 'clientId']);
  }
  return null;
};

export const selectPrimaryContact = (state: RootState) => {
  let contact;
  const selected = state.contracts.selected;
  if (selected.get(BASIC_INFO_PERSON, false) || selected.get(KYC_PERSON, false)) {
    contact = selectPrimaryContactFromPerson(state, selected.get(KYC_PERSON, false));
  } else if (
    selected.get(BASIC_INFO_COMPANY, false) ||
    selected.get(BASIC_INFO_INSTITUTION, false || selected.get(KYC_COMPANY, false))
  ) {
    contact = selectPrimaryContactFromContacts(state);
    contact.proxyOfCustomerId = selectProxyOfCustomerId(state) ?? '';
  }
  return contact;
};

const selectAvailableTemplates = (state: RootState): ImmutableAvailableTemplates => state.contracts.availableTemplates;

const compare = (a: AvailableTemplate, b: AvailableTemplate): number => {
  const aprio = a.priority;
  const bprio = b.priority;
  if (aprio === bprio) {
    return 0;
  }
  if ((aprio > 0 && aprio < bprio) || bprio === 0) {
    return -1;
  }
  return 1;
};

export const selectGroupedTemplates = createSelector(selectAvailableTemplates, (templates): GroupedTemplate[] => {
  const tmpls = templates.toJS();
  const nonCategorized = tmpls.filter((tmpl) => _.get(tmpl, ['categories'], []).length === 0);
  const groupedTemplates = _.map(templateCategories, (category) => {
    let group: AvailableTemplate[] = tmpls.filter((tmpl) =>
      _.get(tmpl, ['categories'], [] as string[]).includes(category)
    );
    if (category === templateCategories.OTHER) {
      group = group.concat(nonCategorized);
    }
    group.sort(compare);
    const groupedTemplate = {} as GroupedTemplate;
    groupedTemplate[category] = group;
    return groupedTemplate;
  });
  return groupedTemplates;
});

export const selectBasicInfoContract = (state: RootState): string => {
  const profile = state.profile;
  switch (profile.customer?.toJS().customerType) {
    case 'person':
      return BASIC_INFO_PERSON;
    case 'company':
      return BASIC_INFO_COMPANY;
    case 'institution':
      return BASIC_INFO_INSTITUTION;
    default:
      return '';
  }
};
