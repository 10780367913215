import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
}

export const EditIcon = ({ className }: Props) => (
  <SVG viewBox="0 0 24 24" className={className}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </SVG>
);

const SVG = styled.svg<Props>``;
