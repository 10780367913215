import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { RootState } from 'types/rootState';
import { fontSize, styles } from 'features/common/StyledComponents';
import { Spinner } from 'features/common/Spinner';
import { formatMoney } from 'features/allocator/allocatorUtils';
import { isLoadingInitialItems, isOptimizingCurrent, selectAum } from 'features/allocator/allocatorSelectors';
import { getWithdrawalBalance } from 'features/portfolio/portfolioUtils';
import { Grid } from 'features/allocator/common/Grid';

export const PortfolioValue = () => {
  const aum = useSelector(selectAum);
  const loadingWithdrawalBalance = useSelector((state: RootState) => state.portfolio.loadingPortfolios);

  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const loadingInitialItems = useSelector(isLoadingInitialItems);
  const loading = optimizingCurrent || loadingInitialItems;

  const withdrawalBalance = useSelector(getWithdrawalBalance);

  return (
    <ValueCard gap="1rem" vertical>
      <div>
        <label>AUM</label>
        {loading ? <Spinner position="left" size={30} /> : <Currency value={aum} />}
      </div>
      <div>
        <label>{translate('portfolioManager.overview.value.cash')}</label>
        {loadingWithdrawalBalance ? <Spinner position="left" size={30} /> : <Currency value={withdrawalBalance} />}
      </div>
    </ValueCard>
  );
};

const ValueCard = styled(Grid)`
  ${styles.card}

  label {
    opacity: 0.7;
  }
`;

const Currency = ({ value }: { value: number }) => {
  const int = formatMoney(value)[0];
  const decimals = formatMoney(value)[1];
  return (
    <div>
      <Int>{int}</Int>
      <Decimals>,{decimals} €</Decimals>
    </div>
  );
};

const Int = styled.span`
  font-size: ${fontSize.h2};
  font-weight: 900;
`;

const Decimals = styled.span`
  font-size: ${fontSize.smaller};
`;
