import React from 'react';
import { push } from 'react-router-redux';
import { Link } from 'react-router';
import styled from 'styled-components';
import Refresh from '@mui/icons-material/Refresh';
import Phone from '@mui/icons-material/PhoneInTalk';
import translate from 'counterpart';
import { PERSON } from 'constants/customerTypes';
import styles from 'features/profile/ProfileView.scss';
import { meetingType } from 'constants/meetingType';
import { ACCOUNT } from 'constants/customerStates';
import { config } from 'config';
import { Spinner } from 'features/common/Spinner';
import { ImmutableCustomer, Customer } from 'types/profileState';
import { AllocatorIcon } from 'features/allocator/common/SvgIcon';
import { useAppDispatch } from 'core/hooks';

interface Props {
  customer: ImmutableCustomer;
  setCustomerForm: (customer: Customer) => void;
  handleNewContract: () => void;
  loadingDrafts: boolean;
  location: Location;
  hasPositions: boolean;
  loadingPositions: boolean;
}

export const Sidebar = (props: Props) => {
  const dispatch = useAppDispatch();

  const navigateProfileEditorForm = () => {
    const { customer, setCustomerForm } = props;
    setCustomerForm(customer.toJS());

    if (customer.get('customerType') === PERSON) {
      dispatch(push(`customer/${customer.get('customerId')}/edit`));
    } else {
      dispatch(push(`customer/${customer.get('customerId')}/summary`));
    }
  };

  const { loadingDrafts, handleNewContract, location, customer, hasPositions, loadingPositions } = props;
  const urlSeparator = location.pathname.endsWith('/') ? '' : '/';
  const portfolioUrl = `${location.pathname + urlSeparator}portfolio?f=all`;
  const appointmentUrl = `${location.pathname + urlSeparator}appointment`;
  const portfolioManagerLink = `${location.pathname + urlSeparator}portfoliomanager`;
  const allocatorUrlNew = `${location.pathname + urlSeparator}portfoliomanager/allocator`;
  const allocatorLink = hasPositions ? portfolioManagerLink : allocatorUrlNew;

  return (
    <ul className={styles.actions}>
      <li>
        <div title={translate(`customer.fetchRegistryData`)} onClick={navigateProfileEditorForm}>
          <i>
            <Refresh
              style={{ width: '24px' }}
              sx={{
                color: '#000000',
              }}
            />
          </i>
          <div
            className={styles.actionButton}
            onClick={navigateProfileEditorForm}
            data-testkey="sidebar-link-fetch-registry-data"
          >
            <span>{translate(`customer.fetchRegistryData`)}</span>
          </div>
        </div>
      </li>
      <li>
        <div title={translate(`customer.newContract`)}>
          <i className="icon icon-new-contract" onClick={!loadingDrafts ? handleNewContract : undefined} />
          {loadingDrafts ? (
            <Spinner className={styles.smallSpinner} />
          ) : (
            <div onClick={handleNewContract} className={styles.actionButton} data-testkey="sidebar-link-new-contract">
              <span>{translate('customer.newContract')}</span>
            </div>
          )}
        </div>
      </li>
      <li>
        <Link title={translate(`customer.portfolios`)} to={portfolioUrl} data-testkey="sidebar-link-portfolios">
          <i className="icon icon-briefcase" />
          <span>{translate('customer.portfolios')}</span>
        </Link>
      </li>

      {config.portfolioManager && (
        <li>
          <Link
            title={hasPositions ? translate('customer.portfolioManager') : translate('customer.allocator')}
            to={loadingPositions ? '' : allocatorLink}
            data-testkey="sidebar-link-portfolio-manager"
          >
            <StyledAllocatorIcon width="28" />
            {loadingPositions ? (
              <Spinner position="left" />
            ) : (
              <span>{hasPositions ? translate('customer.portfolioManager') : translate('customer.allocator')}</span>
            )}
          </Link>
        </li>
      )}

      {customer.get('state') === ACCOUNT
        ? [
            <div className={styles.sideBarHeader} key="header" title={translate('customer.contactCustomer')}>
              {translate('customer.contactCustomer')}
            </div>,
            <li key="phone">
              <Link
                to={{ pathname: appointmentUrl, state: { activity: meetingType.APPOINTMENT } }}
                data-testkey="sidebar-link-meeting"
                title={translate(`customer.appointment`)}
              >
                <i className="icon icon-person25x25" />
                <div className={styles.actionButton}>
                  <span>{translate(`customer.appointment`)}</span>
                </div>
              </Link>
            </li>,
            <li key="app">
              <Link
                to={{ pathname: appointmentUrl, state: { activity: meetingType.PHONE_CALL } }}
                data-testkey="sidebar-link-phone-call"
                title={translate(`customer.phoneCall`)}
              >
                <i>
                  <Phone
                    style={{ width: '24px' }}
                    sx={{
                      color: '#000000',
                    }}
                  />
                </i>
                <div className={styles.actionButton}>
                  <span>{translate(`customer.phoneCall`)}</span>
                </div>
              </Link>
            </li>,
          ]
        : null}
    </ul>
  );
};

const StyledAllocatorIcon = styled(AllocatorIcon)`
  margin-right: calc(2rem - 4px);
`;
