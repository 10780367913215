import React from 'react';
import moment from 'moment';
import translate from 'counterpart';
import styles from './TableRow.scss';
import { Spinner } from 'features/common/Spinner';
import { colors } from 'styles/colors';
import { Contract } from 'types/ordersState';

interface Props {
  item: Contract;
  getContractPdf: () => void;
  contractPdfLoading: string;
}

const ContractListTableRow = ({ item, getContractPdf, contractPdfLoading }: Props) => {
  let nameContent;

  let contractDisplayName = item.contractName;
  if (item.descriptiveName !== undefined) {
    contractDisplayName = `${item.contractName}: ${item.descriptiveName}`;
  }

  if (item.shortId === contractPdfLoading) {
    nameContent = <Spinner className={styles.spinner} color={colors.ptGreen} />;
  } else {
    nameContent = <a onClick={getContractPdf.bind(this, item.shortId)}>{contractDisplayName}</a>;
  }
  return (
    <tr>
      <td>{nameContent}</td>
      <td>{item.clientId}</td>
      <td>{item.clientName}</td>
      <td>{item.shortId}</td>
      {item.state ? <td>{translate(`contractStates.${item.state}`)}</td> : <td>{item.state}</td>}

      <td>{moment(item.creationDate).format('DD.MM.YYYY HH:mm')}</td>
      {item.creator === 'webshop' ? <td>{translate(`contract.${item.creator}`)}</td> : <td>{item.creator}</td>}
    </tr>
  );
};

export default ContractListTableRow;
