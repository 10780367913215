export const ORD_TYPE = 'orderType';
export const PRICE = 'price';
export const RECEIVED_FROM_CLIENT_DATE = 'receivedFromClientDate';
export const RECEIVED_FROM_CLIENT_METHOD = 'receivedFromClientMethod';
export const ORDER_INITIALIZER_TYPE = 'orderInitializerType';
export const EXPIRE_DATE = 'expireDate';
export const ORDER_FINANCING_BUY = 'orderFinancingBuy';
export const ORDER_FINANCING_SELL = 'orderFinancingSell';
export const NOTE = 'note';
export const BOND_PRICE_TYPE = 'bondPriceType';
export const BASIS_TYPE = 'orderBasis';
export const REPRESENTATIVE_SSN = 'representativeSsn';
export const REPRESENTATIVE_NAME = 'representativeName';

export const MINUTES_OF_INVESTMENT_SERVICE = 'minutesOfInvestmentService';
export const DATE = 'date';
export const LOCATION = 'location';
export const PARTICIPANTS = 'participants';
export const CONVENER = 'convener';
export const INFO = 'info';
