import React from 'react';
import { Link } from 'react-router';
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { ReturnIcon } from 'features/allocator/common/SvgIcon';

interface Props {
  label: string;
  to: string;
  'data-testkey'?: string;
}

export const LinkBack = ({ label, to, 'data-testkey': dataTestKey }: Props) => (
  <StyledLink to={to} data-testkey={dataTestKey}>
    <StyledReturnIcon />
    {label}
  </StyledLink>
);

const StyledLink = styled(Link)`
  padding: 0.5rem 1rem;
  margin-bottom: 2rem;
  text-decoration: none;
  border-radius: 25px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-self: flex-start;
  letter-spacing: 0.2px;
  font-weight: 700;
  padding-right: 1.5rem;
  border: 1px solid ${colors.link_blue};
`;

const StyledReturnIcon = styled(ReturnIcon)`
  margin-right: 0.75rem;
`;
