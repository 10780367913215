import React, { useEffect } from 'react';
import moment from 'moment';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import styles from './BisnodeInfo.scss';
import { RootState } from 'types/rootState';
import { AppThunk } from 'types/types';
import { BisnodeUpdate } from 'features/orders/ordersTypes';
import { useAppDispatch } from 'core/hooks';

interface Props {
  portfolioId: string;
  getOrderBisnode: (portfolioId: string) => AppThunk;
  updateBisnode: (portfolioId: string) => AppThunk;
  bisnode: BisnodeUpdate;
}

export const BisnodeInfo = ({ portfolioId, getOrderBisnode, updateBisnode, bisnode }: Props) => {
  const dispatch = useAppDispatch();
  const canUpdateBisnode =
    useSelector((state: RootState) => state.orders.ordersByPortfolioId[portfolioId]?.canUpdateBisnode) ?? true;

  useEffect(() => {
    dispatch(getOrderBisnode(portfolioId));
  }, []);

  if (!bisnode) {
    return null;
  }

  const dateToShow = bisnode?.updateDate
    ? moment(bisnode?.updateDate, ['YYYY-MM-DD', 'DD.MM.YYYY', 'DD.MM.YY', 'MM/DD/YYYY', 'MM/DD/YY']).format(
        'DD.MM.YYYY'
      )
    : '-';

  return (
    <div className={styles.bisnodeContainer}>
      <div className={styles.bisnodeInfo}>
        <div className={styles.header}>{translate('order.authorityToSign')}:</div>
        <div>{bisnode?.text ?? translate('order.authorityToSignNotFetched')}</div>
      </div>
      <div className={styles.bisnodeActions}>
        <button disabled={!canUpdateBisnode} onClick={() => dispatch(updateBisnode(portfolioId))} className={`button`}>
          {translate('order.update')}
        </button>
        {dateToShow !== '-' && (
          <div>
            {translate('order.updated')}: {dateToShow}
          </div>
        )}
      </div>
    </div>
  );
};
