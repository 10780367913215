export const INITIALIZE_RISK = 'INITIALIZE_RISK';
export const CUSTOMER_RISK_ACTUAL = 'CUSTOMER_RISK_ACTUAL';
export const CUSTOMER_RISK_ACTUAL_SUCCESS = 'CUSTOMER_RISK_ACTUAL_SUCCESS';
export const CUSTOMER_RISK_ACTUAL_FAILURE = 'CUSTOMER_RISK_ACTUAL_FAILURE';
export const CUSTOMER_RISK_SIMULATED_REQUEST = 'CUSTOMER_RISK_SIMULATED_REQUEST';
export const CUSTOMER_RISK_SIMULATED_SUCCESS = 'CUSTOMER_RISK_SIMULATED_SUCCESS';
export const CUSTOMER_RISK_SIMULATED_FAILURE = 'CUSTOMER_RISK_SIMULATED_FAILURE';
export const CUSTOMER_RISK_SIMULATED_AND_ADVICED_REQUEST = 'CUSTOMER_RISK_SIMULATED_AND_ADVICED_REQUEST';
export const CUSTOMER_RISK_SIMULATED_AND_ADVICED_SUCCESS = 'CUSTOMER_RISK_SIMULATED_AND_ADVICED_SUCCESS';
export const CUSTOMER_RISK_SIMULATED_AND_ADVICED_FAILURE = 'CUSTOMER_RISK_SIMULATED_AND_ADVICED_FAILURE';

//export const fetchUserActualRiskTolerance = (customerId: string): AppThunk => {
//  return async (dispatch, getState) => {
//    dispatch(createActualRiskToleranceStart(customerId));
//    dispatch(clearError(errorKeys.postActualRisk));

//    const state = getState();
//    const request = getActualRiskPayload(state, customerId);
//    const token = state.oidc.user.access_token;

//    try {
//      const response = await postActualRisk(request, token);
//      dispatch(createActualRiskToleranceSuccess(response.risk));
//    } catch (error) {
//      dispatch(createActualRiskToleranceFailure(error));
//      dispatch(setError({ context: errorKeys.postActualRisk }));
//    }
//  };
//};

const createActualRiskToleranceStart = (customerId: string) => {
  return <const>{
    type: CUSTOMER_RISK_ACTUAL,
    customerId,
  };
};

const createActualRiskToleranceSuccess = (result: number) => {
  return <const>{
    type: CUSTOMER_RISK_ACTUAL_SUCCESS,
    result,
  };
};

const createActualRiskToleranceFailure = (error: Error) => {
  return <const>{
    type: CUSTOMER_RISK_ACTUAL_FAILURE,
    error,
  };
};

const customerRiskSimulatedRequest = () => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_REQUEST,
  };
};

const customerRiskSimulatedSuccess = (risk: number) => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_SUCCESS,
    risk,
  };
};

const customerRiskSimulatedFailure = () => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_FAILURE,
  };
};

const customerRiskSimulatedAndAdvicedRequest = () => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_AND_ADVICED_REQUEST,
  };
};

const customerRiskSimulatedAndAdvicedSuccess = (risk: number) => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_AND_ADVICED_SUCCESS,
    risk,
  };
};

const customerRiskSimulatedAndAdvicedFailure = () => {
  return <const>{
    type: CUSTOMER_RISK_SIMULATED_AND_ADVICED_FAILURE,
  };
};

export const initializeRisk = () => {
  return <const>{
    type: INITIALIZE_RISK,
  };
};

export type RiskAction = ReturnType<
  | typeof customerRiskSimulatedRequest
  | typeof customerRiskSimulatedSuccess
  | typeof customerRiskSimulatedFailure
  | typeof customerRiskSimulatedAndAdvicedRequest
  | typeof customerRiskSimulatedAndAdvicedSuccess
  | typeof customerRiskSimulatedAndAdvicedFailure
  | typeof createActualRiskToleranceStart
  | typeof createActualRiskToleranceSuccess
  | typeof createActualRiskToleranceFailure
  | typeof initializeRisk
>;
