import moment from 'moment';
import { groupBy } from 'lodash';
import { fromJS } from 'immutable';
import { InstrumentWithWeight, PortfolioInstrument } from 'types/instrumentsState';
import { Weights } from 'types/weightsState';
import {
  FUND_SELL_LIMIT,
  MONEY,
  TRADE_QUANTITY_LIMIT,
  TRADE_VALUE_LIMIT,
  TRADE_WEIGHT_LIMIT,
  WEIGHT_LIMIT,
} from 'constants/allocator';
import { AppThunk } from 'types/types';
import { BUY, INVALID_ORDER_SIDE, SELL } from 'constants/sides';
import { OrderInstrumentDetails, OrderLineType, Position } from 'types/ordersState';
import { setTradeOrderLines, setSuitabilityErrors } from 'features/portfolioManager/valueData/valueDataActions';
import { MARKET_PRICE, ENOUGH_MONEY, ENOUGH_SHARES, MONEY_FROM_OTHER_TRADE } from 'constants/shareOptions';
import { ORDER } from 'constants/orderRowTypes';
import { TAALERI_EXPERTS_EMAIL } from 'constants/emails';
import {
  getBondPriceType,
  getInitializerType,
  getReceivedFromClientMethod,
} from 'features/orderDialog/orderDialogReducer';
import { getBillingAddress } from 'features/orders/ordersUtils';
import { getMoneySource, getCapitalCallEmail } from 'features/orderDialog/orderDialogUtils';
import { selectHasPositions } from 'features/allocator/allocatorSelectors';
import { InstrumentSuitabilityError } from 'types/portfolioManagerState';
import { apiCall } from 'core/apiCall';
import { RootState } from 'types/rootState';
import { PortfolioDetails } from 'types/portfolioState';
import { NOT_APPLICABLE } from 'constants/common';
import { ACCOUNT } from 'constants/customerStates';

import { ImmutableCustomer } from 'types/profileState';
import {
  canDoAssignments,
  isPortfolioClassForbidden,
  portfolioHasAllowedState,
  portfolioHasCorrectContractAndStrategy,
} from 'features/portfolio/portfolioSelectors';
import { BOND, FUND } from 'constants/instrumentForms';
import { setError } from 'features/errors/errorActions';
import { OrderSideType } from 'types/orderDialogState';

export const getCurrentInstrument = (security: string, portfolioId: string, currentWeights: Weights) =>
  currentWeights?.portfolioWeights
    ?.find((p) => p.portfolioId === portfolioId)
    ?.instruments.find((i) => i.security === security);

export const getInstrumentPrice = (
  tradeInstrument: InstrumentWithWeight,
  tradeOrderLines: OrderLineType[]
): number | undefined =>
  tradeOrderLines?.find((i) => i?.financialInstrumentId === tradeInstrument.security)?.marketPrice;

export const getInstrumentDetails =
  (instruments: PortfolioInstrument[]): AppThunk<Promise<OrderInstrumentDetails[] | undefined>> =>
  async (dispatch, getState) => {
    const instrumentsByPortfolio = groupBy(instruments, 'portfolioId');
    try {
      const orderInstrumentDetailsByPortfolio = await Promise.all(
        Object.keys(instrumentsByPortfolio).map(
          async (portfolioId) =>
            await getOrderInstrumentDetails(instrumentsByPortfolio[portfolioId], portfolioId, getState())
        )
      );
      return orderInstrumentDetailsByPortfolio.flat().filter(Boolean);
    } catch (error) {
      dispatch(setError({ context: 'getInstrumentPrices' }));
    }
  };

const getOrderInstrumentDetails = async (instruments: PortfolioInstrument[], portfolioId: string, state: RootState) => {
  const accessToken = state.oidc.user.access_token;
  const customerId = state.profile.customer.toJS().customerId;
  const securities = instruments.map((i) => i.security).join(',');
  return apiCall({
    path: `/api/v1/orders/instruments?instrumentIds=${securities}&customerId=${customerId}&portfolioId=${portfolioId}`,
    token: accessToken,
    method: 'get',
  }) as Promise<OrderInstrumentDetails[]>;
};

// "positions" parameter has different data depending if customer
//   has positions or if information is based on instrument details
export const convertInstrumentsToOrderLines =
  (tradeInstruments: PortfolioInstrument[], positions: OrderInstrumentDetails[] | Position[]): AppThunk =>
  (dispatch, getState) => {
    const orderLines = mapInstrumentsToOrderLines(getState(), tradeInstruments, positions);
    dispatch(setTradeOrderLines(orderLines));
    dispatch(updateTradeOrderLineAmounts(orderLines, tradeInstruments));
  };

const mapInstrumentsToOrderLines = (
  state: RootState,
  instruments: PortfolioInstrument[],
  positions: OrderInstrumentDetails[] | Position[]
): OrderLineType[] => {
  const orderLines = instruments.flatMap((instrument) => {
    const position = positions.find(
      (p) =>
        p.financialInstrument.financialInstrumentId === instrument.security &&
        p.portfolio.portfolioId === instrument.portfolioId
    );
    if (!position) {
      return [];
    }

    return getOrderLineDetails(state, instrument, position);
  });

  return orderLines;
};

const getOrderLineDetails = (
  state: RootState,
  instrument: PortfolioInstrument,
  position: OrderInstrumentDetails | Position
): OrderLineType => {
  const portfolioDetailsById = state.portfolio.portfolioDetailsById[instrument.portfolioId];
  const contractName = portfolioDetailsById?.contractName;
  const accountType = portfolioDetailsById?.defaultAccount?.accountType;
  const financialInstrument = position.financialInstrument;
  const portfolioCurrency = portfolioDetailsById?.withdrawalCurrency;
  const hasPositions = selectHasPositions(state);

  return {
    _id: createHash(instrument.security + instrument.portfolioId),
    expireDate: moment().format('YYYY-MM-DD'),
    receivedFromClientDate: moment().utc().format(),

    financialInstrumentId: financialInstrument.financialInstrumentId,
    financialInstrumentForm: financialInstrument.financialInstrumentForm,
    financialInstrumentName: `${financialInstrument.friendlyName} (${financialInstrument.financialInstrumentId})`,
    financialInstrument: {
      name: `${financialInstrument.friendlyName} (${financialInstrument.financialInstrumentId})`,
      symbol: financialInstrument.financialInstrumentId,
      financialInstrumentForm: financialInstrument.financialInstrumentForm,
    },

    issuer: financialInstrument.issuer,
    instrumentForm: financialInstrument.financialInstrumentForm,
    tradeAll: false,
    orderType: MARKET_PRICE,
    rowType: ORDER,
    portfolioId: instrument.portfolioId,
    externalPortfolioId: portfolioDetailsById?.externalId,
    contractName,
    strategyName: portfolioDetailsById?.strategyName,
    defaultAccountType: portfolioDetailsById?.defaultAccount?.accountType,

    currency: portfolioCurrency,
    instrumentCurrency: financialInstrument.currency,
    marketPrice: position?.marketPrice?.securityCurrency?.value,
    marketPriceCurrency: position?.marketPrice?.securityCurrency?.currency,
    marketPriceBase: position?.marketPrice?.baseCurrency?.value,
    marketPriceBaseCurrency: position?.marketPrice?.baseCurrency?.currency,

    bondPriceType:
      financialInstrument.financialInstrumentForm && getBondPriceType(financialInstrument.financialInstrumentForm),
    orderInitializerType: getInitializerType(contractName),
    receivedFromClientMethod: getReceivedFromClientMethod(contractName),
    moneySource: getMoneySource(accountType, contractName),

    receiverEmail: TAALERI_EXPERTS_EMAIL,
    capitalCallEmail: getCapitalCallEmail(state),
    billingEmail: getBillingAddress(state, instrument.portfolioId),

    isSuitable: !hasPositions || position?.financialInstrument?.isSuitable || false,

    // Funds need these:
    subscriptionSum: 0,
    subscriptionFee: 0,
    defaultFee: 0,

    // Trade amounts
    marketValueNet: 'marketValueNet' in position ? position?.marketValueNet?.baseCurrency?.value : 0,
    currentQuantity: 'quantityNet' in position ? position?.quantityNet : 0,
    quantityNet: 'quantityNet' in position ? position?.quantityNet : 0,

    // Trade amounts that can be updated later
    value: 0,
    notionalAmount: 0,
    amount: 0,
    quantity: 0,
    side: BUY,

    // Only needed for warrants?
    unitFee: 0,

    // TODO: check possible values for these later
    representativeName: '',
    representativeSsn: '',
    orderBasis: '',
  };
};

const createHash = (s: string) =>
  s.split('').reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);

export const updateTradeOrderLineAmounts =
  (orderLines: OrderLineType[], tradeInstruments: PortfolioInstrument[]): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const { aum, cashTradeAmount } = state.portfolioManager.valueData;
    const hasPositions = selectHasPositions(state);
    const totalTradeMoney = hasPositions ? aum : cashTradeAmount;
    const currentWeights = state.portfolioManager.weights.currentWeights;

    const newInstrumentDetails = orderLines.map((orderLine) => {
      const portfolioCurrency = state.portfolio.portfolioDetailsById[orderLine.portfolioId]?.withdrawalCurrency;
      const rate = portfolioCurrency === 'EUR' ? 1 : orderLine.marketPrice / orderLine.marketPriceBase;

      const currentInstrument = getCurrentInstrument(
        orderLine.financialInstrumentId,
        orderLine.portfolioId,
        currentWeights
      );
      const tradeInstrument = tradeInstruments.find(
        (i) => i.security === orderLine.financialInstrumentId && i.portfolioId === orderLine.portfolioId
      );

      // OrderLine is not valid for trade -> return without modifying trade amounts
      if (!tradeInstrument || tradeInstrument?.security === MONEY || tradeInstrument?.liquidity === 0) {
        return orderLine;
      }

      const targetWeight = tradeInstrument?.weight || 0;
      const currentWeight = currentInstrument?.weight || 0;
      const weightDifference = targetWeight - currentWeight;

      const side = getOrderSide(weightDifference);
      const noSide = side === INVALID_ORDER_SIDE;

      const tradeAmount = noSide ? 0 : Math.abs(rate * totalTradeMoney * weightDifference);
      const isFund = orderLine.financialInstrumentForm === FUND;

      const sellAll = () => {
        if (side === SELL) {
          if (!isFund) {
            return targetWeight < WEIGHT_LIMIT;
          } else {
            // selling more than 95 % of current position
            return targetWeight / currentWeight < 1 - FUND_SELL_LIMIT;
          }
        }
        return false;
      };

      const tradeAll = sellAll();
      const marketValueNet = orderLine?.marketValueNet || currentWeight * totalTradeMoney || 0;

      return {
        ...orderLine,
        side,
        tradeAll,
        amount: tradeAll ? marketValueNet : tradeAmount,
        orderFinancingBuy: side === BUY ? getOrderFinancing(hasPositions, side) : '',
        orderFinancingSell: side === SELL ? getOrderFinancing(hasPositions, side) : '',
        isSuitable: hasPositions ? getSuitability(state, orderLine, side) : orderLine.isSuitable,
        quantity: noSide ? 0 : getQuantity(tradeAll, orderLine, portfolioCurrency, tradeAmount),
        notionalAmount: orderLine.financialInstrumentForm === BOND ? Math.round(tradeAmount) : 0,
        marketValueNet,
      };
    });

    dispatch(setTradeOrderLines(newInstrumentDetails));
  };

const getQuantity = (sellAll: boolean, orderLine: OrderLineType, portfolioCurrency: string, tradeAmount: number) => {
  if (sellAll) {
    return orderLine.currentQuantity;
  }

  const portfolioCurrencyPrice = portfolioCurrency === 'EUR' ? orderLine?.marketPriceBase : orderLine?.marketPrice;

  const count = tradeAmount / portfolioCurrencyPrice;
  if (orderLine.financialInstrumentForm === BOND) {
    return count * 100;
  }
  return count;
};

const getOrderFinancing = (hasPositions: boolean, side: OrderSideType) => {
  if (hasPositions) {
    if (side === BUY) {
      return MONEY_FROM_OTHER_TRADE;
    }
    return ENOUGH_SHARES;
  }
  return ENOUGH_MONEY;
};

const getSuitability = (state: RootState, orderLine: OrderLineType, side: OrderSideType) => {
  const position = state.portfolio.positions.find(
    (p) =>
      p.financialInstrument.financialInstrumentId === orderLine.financialInstrumentId &&
      p.portfolio.portfolioId === orderLine.portfolioId
  );
  if (side === BUY) {
    return position?.suitabilityErrorsBuy?.length === 0;
  }
  return position?.suitabilityErrorsSell?.length === 0;
};

export const addSuitabilityErrors =
  (instruments: PortfolioInstrument[], orderInstrumentDetails: OrderInstrumentDetails[]): AppThunk =>
  (dispatch) => {
    const errors = [] as InstrumentSuitabilityError[];
    instruments.map((instrument) => {
      const position = orderInstrumentDetails.find(
        (p) =>
          p.financialInstrument.financialInstrumentId === instrument.security &&
          p?.portfolio?.portfolioId === instrument.portfolioId
      );
      if (position?.suitabilityErrorsBuy?.length || position?.suitabilityErrorsSell?.length) {
        errors.push({
          security: instrument.security,
          portfolioId: instrument.portfolioId,
          Buy: position?.suitabilityErrorsBuy,
          Sell: position?.suitabilityErrorsSell,
        });
      }
    });
    dispatch(setSuitabilityErrors(errors));
  };

export const getInstrumentSuitabilityErrors = (
  suitabilityErrors: InstrumentSuitabilityError[],
  instrument: PortfolioInstrument,
  orderSide?: OrderSideType
) => {
  const errors = suitabilityErrors?.find(
    (error) => error.security === instrument.security && error.portfolioId === instrument.portfolioId
  );
  const side = (orderSide === BUY && BUY) || (orderSide === SELL && SELL) || undefined;
  return errors && side ? errors[side] : [];
};

const getOrderSide = (weightDifference: number): OrderSideType => {
  if (weightDifference >= TRADE_WEIGHT_LIMIT) {
    return BUY;
  }
  if (weightDifference <= -TRADE_WEIGHT_LIMIT) {
    return SELL;
  }
  return INVALID_ORDER_SIDE;
};

export const isSameInstrument = (i: PortfolioInstrument, j: PortfolioInstrument) =>
  i?.security === j?.security && i.portfolioId === j.portfolioId;

export const hasPortfolioErrors = (
  customer: ImmutableCustomer,
  portfolioDetails: PortfolioDetails,
  tradeOrderLines: OrderLineType[]
) => {
  const contractName = portfolioDetails?.contractName;
  const strategyName = portfolioDetails?.strategyName;
  const contractReady = true; // makes no sense in Trade view – api call is only made in PT
  const isEditingExistingLine = false; // makes no sense in Trade view

  const canDo = canDoAssignments(
    portfolioDetails?.portfolioId,
    contractReady,
    portfolioDetails?.state,
    contractName,
    strategyName,
    customer?.toJS().state,
    fromJS(tradeOrderLines || []),
    isEditingExistingLine,
    portfolioDetails?.withdrawalCurrency,
    portfolioDetails?.portfolioClasses
  );
  return !canDo;
};

export const getPortfolioErrors = (customer: ImmutableCustomer, portfolioDetails: PortfolioDetails) => {
  const portfolioStateIsNotAllowed = !portfolioHasAllowedState(portfolioDetails?.state);
  const contractName = portfolioDetails?.contractName;
  const strategyName = portfolioDetails?.strategyName;
  const contractAndStrategyAreNotOk = !portfolioHasCorrectContractAndStrategy(contractName, strategyName);
  const customerStateIsNotAccount = 'state' in customer?.toJS() && customer?.toJS().state !== ACCOUNT;
  const hasNoCurrency = portfolioDetails?.withdrawalCurrency === NOT_APPLICABLE;
  const classIsForbidden = isPortfolioClassForbidden(portfolioDetails?.portfolioClasses);

  const portfolioChecks = {
    portfolioStateIsNotAllowed,
    contractAndStrategyAreNotOk,
    customerStateIsNotAccount,
    hasNoCurrency,
    classIsForbidden,
  };

  return Object.entries(portfolioChecks)
    .filter((entry) => entry[1])
    .map((entry) => entry[0]);
};

export const quantityIsOverTradeLimit = (orderLine?: OrderLineType) => {
  if (!orderLine) {
    return false;
  }
  return (
    Number(Math.abs(orderLine?.quantity).toFixed(2)) >= TRADE_QUANTITY_LIMIT ||
    orderLine?.financialInstrumentForm === FUND
  );
};

export const weightIsOverTradeLimit = (weightDifference: number) => {
  if (!weightDifference) {
    return false;
  }
  return Math.abs(weightDifference) >= TRADE_WEIGHT_LIMIT;
};

export const valueIsOverTradeLimit = (orderLine?: OrderLineType) => {
  if (!orderLine) {
    return false;
  }
  return Math.abs(orderLine?.amount) > TRADE_VALUE_LIMIT || orderLine?.tradeAll;
};
