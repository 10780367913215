import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { VictoryPie } from 'victory';
import { ASSET_CLASS_COLORS, ASSET_CATEGORY_COLORS, GROUPED_ASSET_CLASSES_COLORS } from 'styles/colors';
import { FlexAlignCenter } from 'features/common/StyledComponents';
import { AssetCategoryWeights } from 'types/weightsState';
import { AssetCategoryName, AssetClassId, AssetClassGroupId } from 'types/types';
import { selectAssetClasses } from 'features/allocator/allocatorSelectors';
import { OptimizationModelAssetClassGroup, optimizationModelWithWeights } from 'features/allocator/optimizationModel';
import { otherAssetCategory, WEIGHT_LIMIT } from 'constants/allocator';

interface Props {
  weights: AssetCategoryWeights[];
  sections: 'assetCategories' | 'assetClasses' | 'assetClassGroups';
  className?: string;
}

export const DistributionPie = ({ weights, className, sections }: Props) => {
  const assetClasses = useSelector(selectAssetClasses);

  const assetClassesOrder = assetClasses.map((a) => a.assetClassId);
  const sortedAssetClasses = weights
    .flatMap((i) => i.assetClasses)
    .sort((a, b) => assetClassesOrder.indexOf(a.assetClassId) - assetClassesOrder.indexOf(b.assetClassId));

  const getAssetClassGroupWeight = (group: OptimizationModelAssetClassGroup) => {
    const assetClassWeights = weights.flatMap((i) => i.assetClasses);
    return group.assetClasses?.reduce((acc, assetClass) => {
      const assetClassWeight = assetClassWeights?.find((i) => i.assetClassId === assetClass.assetClassId)?.weight;
      return acc + (assetClassWeight || 0);
    }, 0);
  };

  const groupedAssetClasses = optimizationModelWithWeights.concat(otherAssetCategory).flatMap((category) =>
    category.assetClassGroups.map((group) => ({
      ...group,
      weight: getAssetClassGroupWeight(group),
      assetCategory: group.assetCategory,
    }))
  );

  const data = (() => {
    switch (sections) {
      case 'assetCategories':
        return weights;
      case 'assetClasses':
        return sortedAssetClasses;
      case 'assetClassGroups':
        return groupedAssetClasses;
    }
  })();

  return (
    <Container className={className}>
      <VictoryPie
        padAngle={0.5}
        labels={[]}
        radius={75}
        innerRadius={40}
        width={150}
        height={150}
        x="assetCategory"
        y="weight"
        animate={{ duration: 300 }}
        data={(data as { weight: number }[]).filter((w) => w.weight > WEIGHT_LIMIT)}
        style={{
          data: {
            fill: (d) => {
              switch (sections) {
                case 'assetCategories':
                  return ASSET_CATEGORY_COLORS[d.datum.name as AssetCategoryName];
                case 'assetClassGroups':
                  return GROUPED_ASSET_CLASSES_COLORS[d.datum.groupId as AssetClassGroupId] || 'white';
                case 'assetClasses':
                  return ASSET_CLASS_COLORS[d.datum.assetClassId as AssetClassId];
              }
            },
          },
        }}
      />
    </Container>
  );
};

const Container = styled(FlexAlignCenter)`
  position: relative;
`;
