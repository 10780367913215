export default {
  volatility: 'Volatiliteetti',
  optimized: 'Optimoitu!',
  optimizing: 'Optimoidaan sijoituksia...',
  loadingPlan: 'Ladataan suunnitelmaa...',
  loadingAllocator: 'Ladataan Allokaattoria...',

  header: {
    save: 'Tallenna',
    saving: 'Tallennetaan...',
    saved: 'Tallennettu!',
    close: 'Sulje',
    exit: 'Poistu',
    backToProfile: 'Takaisin profiiliin',
    ok: 'OK',
    cancel: 'Peruuta',
    continue: 'Jatka',
    optimizing: 'Optimoidaan...',
    cancelChanges: 'Peru muutokset',
    backToPT: 'Takaisin Pankkiirin työpöydälle',
    newInvestmentPlan: 'Luo uusi sijoitussuunnitelma',
    editInvestmentPlan: 'Muokkaa sijoitussuunnitelmaa',
    viewInvestmentPlan: 'Katsele lukittua sijoitussuunnitelmaa',
    investmentPlan: 'Sijoitussuunnitelma',
    saveInvestmentPlan: 'Tallenna suunnitelma',
    noOptimizedWeights: 'Et voi tallentaa suunnitelmaa ilman optimoituja painoja',
    checkPortfolios: 'Tarkista salkut ennen suunnitelman tallentamista',
    hasOptimizedFullPowerOfAttorneyPortfolios: 'Et voi tallentaa suunnitelmaa, jossa on optimoitava TV-salkku',
    testRiskLevelSelected: 'Et voi tallentaa suunnitelmaa, kun kokeilet eri riskilukua',
    demoCustomer: 'Demo-asiakas - suunnitelmaa ei voi tallentaa',
  },

  forecastType: {
    company: 'Aktian näkemys',
    neutral: 'Neutraali allokaatio',
  },

  investmentPlanSelections: 'Sijoitussuunnitelman valinnat',
  investmentPlanStepsHelp: {
    selectRiskLevelAndCore: 'Valitse riskiluku ja salkun ydin luodaksesi sijoitussuunnitelma',
    selectRiskLevel: 'Valitse vielä riskiluku luodaksesi sijoitussuunnitelma',
    selectCore: 'Valitse vielä salkun ydin luodaksesi sijoitussuunnitelma',
  },

  instructionsAtStart: {
    planCanBeCreatedWhen: 'Valitse',
    riskAndCore: 'riskiluku ja salkun ydin',
    haveBeenChosen: 'jotta sijoitussuunnitelma voidaan luoda',
  },

  investmentPlanSteps: {
    createPlanSummary: 'Luo yhteenveto suunnitelmasta',
    risk: {
      riskTitle: 'Riski',
      riskLevelOnContract: 'Riskiluku sopimuksella',
      targetRiskLevel: 'Valittu riskiluku',
      riskDetails: 'Valitse riskiluku ja tuotto-odotus',
      chooseRisk: 'Valitse riskiluku',
      changeRisk: 'Vaihda riskilukua',
      viewRisk: 'Näytä kehitysarvio',
      buttonSelect: 'Valitse riskiluku',
      buttonChange: 'Vaihda riskilukua',
      buttonView: 'Näytä kehitysarvio',
      tryRiskLevel: 'Kokeile eri riskilukua',
    },
    core: {
      coreTitle: 'Salkun ydin',
      coreDetails: 'Valitse, mistä tuotteista salkun ydin rakennetaan',
      coreIsManual: 'Instrumentit on valittu käsin',
      noInstrumentsSelected: 'Ei instrumentteja valittuna',
      coreChange: 'Vaihda ydintä',
      coreHasBeenChanged: 'Ydintä on muokattu',
      somePositionsNotInPlan: 'Omistuksissa on instrumentteja, joita ei ole suunnitelmalla. Päivitä suunnitelma.',
      buttonSelect: 'Valitse salkun ydin',
      buttonChange: 'Muokkaa instrumentteja',
      buttonView: 'Näytä instrumentit',
      buttonAdd: 'Lisää instrumentteja',
    },
    constraints: {
      constraintsTitle: 'Rajoitteet',
      constraintsDetails: 'Voit asettaa omaisuusluokkien osuuksille ylä- ja alarajoja',
      customConstraintsIncluded: 'Omia rajoitteita voimassa',
      buttonSelect: 'Aseta rajoitteita',
      buttonChange: 'Muokkaa rajoitteita',
      buttonView: 'Näytä rajoitteet',
    },
    portfolios: {
      portfoliosTitle: 'Salkut',
      portfolio: 'Salkku',
      selectRiskAndCoreToCreatePortfolios: 'Salkut luodaan, kun riskiluku ja ydin on valittu',
      manualSettingsApplied: 'Asetuksia on muokattu',
      creatingPortfolios: 'Optimoidaan salkkuja...',
      buttonCheck: 'Tarkista salkut',
      buttonView: 'Näytä salkut',
      Include: 'Opt.',
      Consider: 'Huom.',
      Ignore: 'Erill.',
    },
  },

  overviewView: {
    loadingBasicStuff: 'Ladataan instrumentteja',
    fetchingPortfolios: 'Haetaan omistuksia',
    optimizingCurrent: 'Lasketaan sijoituksia',
    loadingProfile: 'Ladataan henkilötietoja',
    loadingPlan: 'Ladataan suunnitelmaa',
    distribution: 'Jakauma',
    currentDistribution: 'Nykyinen jakauma',
    optimizedDistribution: 'Suunnitelman jakauma',
    keyFigures: 'Tunnusluvut',
    varTitle: '95 %%:n VaR',
    portfolio: 'Salkku',
    expectedReturn: 'Tuotto-odotus',
    instruments: 'Suunnitelman instrumentit',
    assetClasses: 'Suunnitelman omaisuusluokat',
    portfolios: 'Suunnitelman salkut',
    startingPortfolio: 'Nykyinen tavoite',
    longTermPortfolio: 'Pitkän ajan tavoite',
    company: 'Aktian näkemys',
    neutral: 'Neutraali näkemys',
    noOptimizedPortfolios: 'Yhtään salkkua ei ole valittu optimoitavaksi.',
    chooseOneOptimizedPortfolio: 'Valitse Salkut-näkymässä ainakin yksi salkku optimoitavaksi.',
    cantOptimize: 'Sijoituksia ei voida optimoida nykyisillä asetuksilla.',
    changeSettingsNewCustomer: 'Kokeile vaihtaa riskilukua, instrumentteja tai optimointirajoituksia.',
    changeSettingsExistingCustomer: 'Kokeile vaihtaa optimointirajoituksia tai salkkujen asetuksia.',
    showFiguresWhenOptimized: 'Tunnusluvut voidaan näyttää, kun suunnitelma on optimoitu',
    showAssetClassesWhenOptimized: 'Omaisuusluokkajakauma voidaan näyttää, kun suunnitelma on optimoitu',
    optimizationManner: 'Optimointimalli',
  },

  positionsNotEqual: {
    title: 'Asiakkaan omistukset eivät täsmää suunnitelmaan',
    morePositions:
      'Asiakkaan salkuissa on instrumentteja, joita ei ole sijoitussuunnitelmalla. Omistuksia ei voida tasapainottaa, koska tasapainotus perustuu sijoitusuunnitelmaan. Päivitä suunnitelma, jotta omistukset voidaan tasapainottaa.',
    morePlanInstruments: 'Asiakkaan suunnitelmalla on instrumentteja, joita ei löydy asiakkaan omistuksista.',
    hasOpenTrades: 'Asiakkaalla on avoimia toimeksiantoja, ja kauppojen vahvistuttua ongelma saattaa poistua.',
    bullet1a: 'Jos asiakkaalla on keskeneräisiä toimeksiantoja, ',
    bullet1b: 'odota kauppojen vahvistumista. ',
    bullet2a: 'Jos asiakkaan sijoitussuunnitelman toteutus on kesken, ',
    bullet2b: 'voit jättää tämän ilmoituksen huomiotta. ',
    bullet3a: 'Jos jokin instrumentti on myyty kokonaan pois, ',
    bullet3b: ' päivitä suunnitelma, jotta sijoitusten tasapainotus toimii oikein. ',
    updatePlanInfo1:
      'Sijoitussuunnitelman päivittäminen muuttaa suunnitelmaa siten, että sen salkut ja instrumentit vastaavat täsmälleen omistuksia.',
    updatePlanInfo2: 'Suunnitelman päivittämisen jälkeen se tulee vielä tallentaa, jotta muutokset tulevat voimaan.',
    button: 'Päivitä suunnitelma',
    positionsNotInPlan: 'Omistuksissa olevat instrumentit, joita ei ole suunnitelmalla:',
    planInstrumentsNotInPositions: 'Suunnitelmalla olevat instrumentit, joita ei ole omistuksissa:',
  },

  riskConflict: {
    plan: 'Sopimus',
    contract: 'Suunnitelma',
    title: 'Sopimuksella ja suunnitelmalla on valittuna eri riskiluvut',
    contractRisk: 'Riskiluku sopimuksella:',
    planRisk: 'Riskiluku suunnitelmalla:',
    p1: 'Jotta voit jatkaa asiakkuuden luomista, riskilukujen täytyy olla samat. Tässä näkyvälle suunnitelmalle vaihdettiin automaattisesti sopimuksen riskiluku',
    p2: 'ja instrumenttien painot optimoitiin uudelleen käyttäen tuota riskilukua.',
    p3: 'Tarkista suunnitelma sekä salkkujen sisällöt. Jos kaikki näyttää hyvältä, tallenna suunnitelma, ja voit jatkaa asiakkuuden luomista.',
  },

  configureRisk: {
    riskHeader: 'Riskitaso ja tuotto-odotus',
    selectRiskLevel: 'Valitse riskiluku',
    riskLevel: 'Riskiluku',
    expectedYield: 'Tuotto-odotus',
    estimatedDistribution: 'Esimerkki jakaumasta',
    expectedDevelopment: 'Odotus arvon kehityksestä',
    varExplanation: `Arvon alenema 5 %%:n todennäköisyydellä`,
    inOneYear: 'Vuodessa',
    inFiveYears: '5 vuodessa',
    historicYield: 'Tuoton kehitys',
    probability: ':n todennäköisyys',
    marketReturn: 'Markkinatuotto',
    changeRiskInBasicInfo: 'Riskiluku on valittu perustietosopimuksella, ja sen vaihtaminen vaatii uuden sopimuksen.',
  },

  configureCore: {
    pageTitle: 'Salkun ydin',
    cardTitle: 'Valitse salkun ydinelementit',
    description:
      'Alla on valittua riskilukua vastaavat vaihtoehdot salkun perusrakenteelle. Voit myöhemmin lisätä ja poistaa instrumentteja käsin.',
    createEmpty: 'Tai luo tyhjä sijoitussuunnitelma:',
    emptyCoreSelection: 'Valitse instrumentit käsin',
    choose: 'Valitse...',
    modify: 'Muokkaa...',
  },

  instrumentsView: {
    instrumentsViewTitle: 'Suunnitelman instrumentit',
    prospectus: 'Avaintietoesite',
    instrumentsDescription: 'Hallitse salkussa olevia instrumentteja yksitellen',
    chosenAssetClasses: 'Suunnitelman omaisuusluokat',
    type: 'Tyyppi',
    ST: 'Osakkeet',
    BO: 'Korot',
    ALT: 'Vaihtoehtoiset',
    MONEY: 'Käteinen',
    MUUT: 'Muut',
    ALLOC: 'Allokaatiot',
    ST_portfolio: 'Osakkeet, yleinen',
    BO_portfolio: 'Suorat korot',
    ALLOC_portfolio: 'Suorat allokaatiot',
    MONEY_portfolio: 'Käteinen',
    MUUT_portfolio: 'Muut',
    choosePortfolio: 'Valitse salkku, jonka instrumentteja muokataan',
    instruments: 'instr.',
    inPositions: 'Omistuksissa tässä salkussa',
    notInPositions: 'Ei omistuksissa',
    addedToPlan: 'Lisätty suunnitelmaan',
    removedFromPlan: 'Poistettu suunnitelmasta',
    inPositionsInAnotherPortfolio: 'Omistuksissa salkussa',
    inPlanInAnotherPortfolio: 'Suunnitelmalla salkussa',
  },

  constraintsView: {
    constraintsTitle: 'Rajoitteet',
    absoluteConstraints: 'Absoluuttiset rajoitteet',
    relativeConstraints: 'Suhteelliset rajoitteet',
    constraintsDetails: 'Voit asettaa pääomaluokkien osuuksille ylä- ja alarajoja',
    constraintButtonLabelNotSelected: 'Aseta rajoitteita',
    optimizationConstraintsDescription:
      'Tässä voit asettaa omaisuuslajeille sekä absoluuttisia että keskinäisiä rajoitteita optimointia varten.',
    otherConstraintGroups: 'Omat rajoiteryhmät',
    optimizationConstraints: 'Suunnitelman optimoinnin rajoitteet',
    currentWeight: 'Paino omistuksissa',
    optimizedWeight: 'Optimoitu paino',
    allowedWeight: 'Rajoitettu paino',
    newConstraintGroup: 'Luo oma rajoiteryhmä',
    useBackendConstraints: 'Käytä omaisuuslajeille asetettuja oletusrajoitteita',
    useBackendConstraintsExplanation1:
      'Optimoinnissa on aina käytössä tietyt omaisuuslajirajoitteet käyttäjän lisäämien rajoitteiden lisäksi.',
    useBackendConstraintsExplanation2: 'Poista nämä oletusrajoitteet käytöstä vain, mikäli tiedät mitä olet tekemässä.',
  },

  customConstraints: {
    newConstraintGroup: 'Oma rajoiteryhmä',
    groupName: 'Ryhmän nimi',
    giveName: 'Anna nimi',
    delete: 'Poista rajoiteryhmä',
    confirmDelete: 'Vahvista poisto',
    constrainingAssetClasses: 'Rajoitettavat omaisuusluokat',
    inRelationToAssetClasses: 'Rajoita suhteessa tähän ryhmään',
    assetClassesAreEqual: 'Omaisuusluokat eivät voi olla identtiset',
    noConstrainingAssetClasses: 'Et ole vielä valinnut rajoitettavia omaisuusluokkia',
    customConstraintNoLabelError: 'Anna rajoiteryhmälle vielä nimi',
    customContraintNameTakenError: 'Samanniminen rajoiteryhmä on jo olemassa',
    customConstraintLabelTooLongError: 'Ryhmän nimi on liian pitkä. Suurin sallittu pituus on 150 merkkiä.',
  },

  portfoliosView: {
    title: 'Salkutus',
    pageDescription:
      'Allokaattori luo automaattisesti ehdotuksen instrumenttien jaosta salkkuihin. Voit halutessasi muokata salkkujen asetuksia.',
    portfolioName: 'Salkun nimi',
    portfolioModel: 'Salkunhoitomalli',
    liability: 'Vastuu',
    investmentModel: 'Sijoitusmallin vaikutus',
    portfolioWeight: 'Salkun paino',
    portfolioSettings: 'Salkkujen asetukset',
    portfolioContents: 'Salkkujen sisällöt',
    toBeOptimized: 'Optimoitava',
    toBeConsidered: 'Huomioitava',
    toBeIgnored: 'Erillinen',
    resetSettings: 'Palauta oletusasetukset',
    descriptionTitle: 'Sijoitusmallien selitykset:',
    descriptionOptimize:
      'Optimointi suosittaa salkun instrumenttien painoihin muutoksia, jos omaisuuslajien painot poikkeavat tavoitteesta.',
    descriptionConsider:
      'Salkun sisältö huomioidaan optimoitaessa muita salkkuja, mutta kyseisen salkun instrumenttien painot pidetään ennallaan.',
    descriptionIgnore: 'Salkkua ei huomioida millään tavalla optimoitaessa muita salkkuja.',
    personalAllocationStrategy: 'Henkilökohtainen allokaatiostrategia',
    returnToOverview: 'Palaa yhteenvetonäkymään luodaksesi salkut',
    longTermError: 'Pitkän aikavälin suunnitelman luonti epäonnistui',
    shortTermError: 'lyhyen aikavälin suunnitelman luonti epäonnistui',
    cantSaveBecauseOptimizationFailed: 'Salkkuja ei voida tallentaa, koska optimointi ei onnistunut. ',
    tryDifferentSettings: 'Kokeile erilaista sijoitusmallien yhdistelmää.',
    selectAtLeastOneOptimized: 'Valitse ainakin yksi salkku optimoitavaksi',
    reoptimize: 'Optimoi uudelleen',
    differenceHelpText: 'Näytetään paino suunnitelmassa, sekä ero nykyiseen painoon.',
    noOptimizedPortfolios: 'Yhtään salkkua ei ole valittu optimoitavaksi.',
    managerTypes: {
      Unknown: 'Ei tiedossa',
      Banker: 'Pankkiiri',
      ExchangeManagement: 'Välitys',
      StockPortfolioManagement: 'Osakesalkunhoito',
      BondPortfolioManagement: 'Korkosalkunhoito',
      AllocationPortfolioManagement: 'Allokaatiosalkunhoito',
    },
    contractTypes: {
      Tuntematon: 'Ei tiedossa',
      Unknown: 'Ei tiedossa',
      TV: 'TV',
      TYS: 'TYS',
      KONS: 'KONS',
      Koonti: 'Koonti',
      'Tehtaan salkku': 'Tehtaan salkku',
    },
  },
  summaryView: {
    backToAllocator: 'Takaisin Allokaattoriin',
    expectedYieldOverTime: 'Odotus arvon kehityksestä',
    showWeightDifference: 'Näytä poikkeama optimaalisista painoista',
    portfolioSummary: 'Salkun yhteenveto',
    withdrawalBalance: 'Käytettävissä oleva saldo',
    portfolioSize: 'Salkun koko',
    riskNumber: 'Riskiluku',
    portfolioDistribution: 'Salkkujen jakauma',
    expectedReturn: 'Tuotto-odotus',
    expectedVolatility: 'Odotettu volatiliteetti',
    createPngExplanation: 'Voit luoda yhteenvedosta kuvat esim. asiakkaalle lähetettäväksi.',
    filenamePrefix: 'Yhteenveto',
    createImage: 'Luo kuvat',
    saveImage: 'Tallenna kuva',
    openImage: 'Näytä kuva',

    assetClassDistribution: 'Omaisuuslajien jakauma',
    weight: 'Paino',
    planInstruments: 'Instrumenttien jakauma',
    instrument: 'Tuote',
    share: 'Osuus',
    totalPortfolio: 'Koko salkku',
  },
  errors: {
    errorFetchingExamplePortfolios: 'Esimerkkisalkkujen haku epäonnistui',
  },
};
