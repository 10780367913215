import { apiCall } from 'core/apiCall';
import { NewContractRequest, PdfAttachmentData } from 'features/contracts/contracts.types';
import { PostContractResponse } from 'features/orders/ordersTypes';
import { Contract, Template } from 'types/ordersState';

export const postContractForPreview = (contract: Contract, token: string): Promise<string> => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/contract/preview/pdf/onetime',
    data: contract,
  });
};

export const getPdfForPreviewRequest = (id: string, token: string): Promise<Blob> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/preview/pdf/onetime/${id}`,
    responseType: 'blob',
    responseResolver: (res) => res.xhr.response,
  });
};

export const getSignatures = (contractId: string, token: string): Promise<object> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/signing/requests/${contractId}`,
  });
};

export const postContract = (contract: NewContractRequest, token: string): Promise<PostContractResponse[]> => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/contract',
    data: contract,
  });
};

export const getContract = (contractId: string, token: string): Promise<Contract> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/${contractId}`,
  });
};

export const getContractPdf = (pdfId: string, token: string): Promise<Blob> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/pdffile/${pdfId}`,
    responseType: 'blob',
    responseResolver: (res) => res.xhr.response,
  });
};

export const getContractTemplate = (templateId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/template/${templateId}`,
  }) as Promise<Template>;
};

export const postPdf = async (file: File, descriptiveName: string, customerId: string, token: string) => {
  const fields = [
    ['customerId', customerId],
    ['descriptiveName', descriptiveName],
  ];
  const attachment = {
    key: 'document',
    file,
  };
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/contract/uploadpdf',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    fields,
    attachment,
  }) as Promise<string>;
};

export const deletePdf = async (attachmentId: string, customerId: string, token: string) => {
  // contract/deletepdf/{customerId}/{attachmentId}
  const fields = [
    ['customerId', customerId],
    ['attachmentId', attachmentId],
  ];
  return apiCall({
    method: 'delete',
    token,
    path: `/api/v1/contract/deleteattachment/${customerId}/${attachmentId}`,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    fields,
  }) as Promise<string>;
};

export const getAttachmentPdfRequest = (attachmentId: string, customerId: string, token: string): Promise<Blob> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/getattachmentpdf/${customerId}/${attachmentId}`,
    responseType: 'blob',
    responseResolver: (res) => res.xhr.response,
  });
};

export const getAttachmentDataRequest = (
  attachmentId: string,
  customerId: string,
  token: string
): Promise<PdfAttachmentData> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/contract/getattachmentdata/${customerId}/${attachmentId}`,
  });
};

export const getContractTemplateForCustomerType = (url: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: url,
  }) as Promise<Template>;
};

export const getPrefilled = (templateId: string, clientId: string, token: string): Promise<Contract> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/template/${templateId}/client/${clientId}`,
  });
};

export const deleteDraftContracts = (customerId: string, token: string): Promise<void> => {
  return apiCall({
    method: 'delete',
    token,
    path: `/api/v1/contract/${customerId}/state`,
  });
};
