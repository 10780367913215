import React from 'react';
import styles from './TestDisclaimer.scss';

interface Props {
  env: string;
}

export default function TestDisclaimer(props: Props) {
  const env = `Testiympäristö - ${props.env}`;
  return <div className={styles.disclaimer}>{env}</div>;
}
