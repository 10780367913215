import { BUY, SELL, EXCHANGE } from 'constants/sides';

export const tradeFundSchema = {
  properties: {
    financialInstrumentId: {
      type: 'string',
      minLength: 1,
    },
    amount: {
      type: 'number',
      minimum: 0,
      exclusiveMinimum: true,
    },
    quantity: {
      type: 'number',
      minimum: 0,
      exclusiveMinimum: true,
    },
    side: {
      type: 'string',
      enum: [BUY, SELL, EXCHANGE],
    },
    subscriptionFee: {
      type: 'number',
    },
  },
  anyOf: [
    {
      properties: {
        side: {
          type: 'string',
          enum: [BUY, SELL],
        },
      },
    },
    {
      properties: {
        side: {
          type: 'string',
          enum: [EXCHANGE],
        },
        counterFinancialInstrumentId: {
          type: 'string',
          minLength: 1,
        },
      },
      required: ['counterFinancialInstrumentId'],
    },
  ],
  oneOf: [
    {
      required: ['amount'],
    },
    {
      required: ['quantity'],
    },
  ],
  type: 'object',
  required: ['side', 'subscriptionFee', 'financialInstrumentId'],
};
