export default {
  addNew: 'Lisää uusi',
  assetManagement: 'Omaisuudenhoitosopimukset',
  backspaceToRemoveMessage: 'Paina backspace poistaaksesi',
  contractExists: 'Sopimus on jo olemassa',
  continueButton: 'Jatka',
  customerId: 'Asiakastunnus',
  customershipChanges: 'Muutokset',
  discard: 'Poista',
  edit: 'Muokkaa',
  email: 'Sähköposti',
  firstName: 'Etunimi',
  lastName: 'Sukunimi',
  internal: 'Sisäiset lomakkeet',
  missingFieldsLabel: 'Henkilön tiedoissa puutteita. Tarkista CRM:ssä:',
  mostCommon: 'Yleisimmät',
  other: 'Muut',
  digi: 'Digiin liittyvät sopimukset',
  products: 'Tuotekohtaiset sopimukset ja liitteet',
  noResultsText: 'Henkilöä ei löytynyt',
  phoneNumber: 'Puhelinnumero',
  proxy: 'Valtakirjat',
  legacyTaaleri: 'Legacy Taaleri sopimukset',
  aktiaLifeInsurance: 'Aktia Henkivakuutus Oy',
  search: 'Hae',
  select: 'Valitse...',
  sent_for_manual_signing_header: 'Tulostus ja allekirjoitus',
  sent_for_banker_approval: 'Pankkiiri hyväksyy',
  sent_for_signing_header: 'Sähköinen allekirjoitus',
  sent_for_ink_signing_header: 'Näytölle allekirjoitus',
  selection: {
    title: 'Valitse sopimukset',
    linkText: 'Valitse sopimukset',
    searchForContract: 'Etsi sopimus...',
    inFinnish: 'Suomeksi',
    inSwedish: 'På Svenska',
  },
  ssn: 'Henkilötunnus',
  overview: {
    title: 'Täytä sopimuskohtaiset tiedot',
    linkText: 'Täytä tiedot',
  },
  questions: {
    title: 'Osio',
    button: 'Nappi',
  },
  transfer: {
    title: 'Otsikko',
    linkText: 'Omaisuuden siirto',
  },
  assignment: {
    title: 'Otsikko',
    linkText: 'Toimeksianto',
  },
  preview: {
    title: 'Otsikko',
    linkText: 'Valitse allekirjoitustapa',
  },
  confirm: {
    title: 'Otsikko',
    linkText: 'Allekirjoita',
  },
  webServiceContractInfo: 'Verkkopalvelusopimusta ei voitu luoda ensisijaiselle yhteyshenkilölle. Puuttuvat tiedot:',
  webServiceContractExist:
    'Verkkopalvelusopimusta ei luotu ensisijaiselle yhteyshenkilölle, koska sopimus on jo voimassa.',
  createContractsForSendingFailure:
    'Huom! Sopimusten lähettäminen epäonnistui. Tallenna sopimukset ja uudelleenkäynnistä Pankkiirin työpöytä. Jos virhe toistuu ota yhteyttä sijoitusasiantuntijaasi.',
  riskConfirmation: {
    back: 'Takaisin',
    title: 'Riskin vahvistaminen',
    spinnerMessage: 'Lasketaan riskisuositusta...',
    confirmRiskLevel: 'Valitse tavoiteltu riskitaso Aktian hoidossa oleville varoillesi',
    reasonsForHigherRiskLevelTitle: 'Perustele suosituksesta poikkeava riskivalinta',
    reasonsForHigherRiskLevelPlaceholder: 'Perustelu...',
    recommendedRiskLevel: 'Riskiluku on suosituksen mukainen',
    riskLevelIsTooHigh: 'Valittu riskiluku on liian korkea suhteessa riskiprofiiliin. Valitse alempi riskiluku.',
    riskFetchError:
      'Riskilukusuosituksen laskenta ei onnistunut. Tarkista, että olet vastannut kaikkiin soveltuvuusarviointiin liittyviin kysymyksiin.',
    invalidRiskResponse:
      'Valituilla vastauksilla ei voitu laskea riskilukua. Tarkistathan, että olet vastannut kaikkiin perustietosopimuksen kysymyksiin.',
    threeQuestionsTitle: 'Asiakkaan tavoitteisiin liittyvät kysymykset',
    recommended: 'Suositus',
    plan: 'Suunnitelma',
    confirmRisk: 'Vahvista riskiluku',
    riskIsConfirmed: 'Riskiluku vahvistettu',
    conflictWithAllocatorRisk:
      'Valittu riskiluku poikkeaa suunnitelmalle valitusta riskiluvusta. Jotta salkut voidaan lähettää toteutettavaksi, sopimuksen täyttämisen jälkeen käy Allokaattorissa hyväksymässä uutta riskilukua vastaava sijoitussuunnitelma.',
    estimatedDistribution: 'Esimerkki sijoitusten jakaumasta ja tunnusluvuista',
    expectedReturn: 'Tuotto-odotus',
    volatility: 'Volatiliteetti',
    currentRisklevel: 'Nykyinen riskiluku sopimuksella on: ',
    actualRisklevel: 'Sijoitusten laskennallinen riskiluku on: ',
  },
};
