import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  positionsAreEqual,
  selectCurrentWeights,
  selectOptimizedWeightsForRiskLevel,
} from 'features/allocator/allocatorSelectors';
import { VolatilityIcon, YieldIcon } from 'features/allocator/common/SvgIcon';
import { DifferenceCell } from 'features/portfolioManager/components/DifferenceCell';
import { CompareToType, PlanLength } from 'types/types';
import { Spinner } from 'features/common/Spinner';
import { weightTypes } from 'constants/allocator';
import { ReturnStatistics } from 'types/commonDataState';

interface Props {
  returnStatistics: ReturnStatistics;
  compareTo: CompareToType;
  planLength: PlanLength;
  loading: boolean;
}

export const ReturnAndVolatility = ({ returnStatistics, compareTo, planLength, loading }: Props) => {
  const currentWeights = useSelector(selectCurrentWeights);
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel)[planLength];
  const positionsEqual = useSelector(positionsAreEqual);
  const portfolioVolatility = returnStatistics?.portfolioVolatility || undefined;
  const portfolioReturn = returnStatistics?.portfolioReturn || undefined;

  const comparableWeights = compareTo === weightTypes.currentWeights ? currentWeights : optimizedWeights;
  const factor = compareTo === weightTypes.currentWeights ? -1 : 1;
  const doComparison = !!compareTo && positionsEqual && comparableWeights?.assetCategoryWeights?.length > 0;

  const comparableStatistics = comparableWeights?.returnStatistics || comparableWeights; // for now, return stats are in different locations for new and existing customer

  return (
    <Table>
      <tbody>
        <tr data-testkey="expected-return">
          <td>{translate('allocator.overviewView.expectedReturn')}</td>
          {loading ? (
            <td>
              <Spinner size={20} position="right" />
            </td>
          ) : (
            portfolioReturn && (
              <>
                <PercentageCell>
                  <YieldIcon />
                  <Number data-testkey="expected-return-value">{formatToDecimals(portfolioReturn, 1, '%')}</Number>
                </PercentageCell>
                {doComparison && (
                  <DifferenceCell
                    difference={
                      portfolioReturn ? factor * (comparableStatistics?.portfolioReturn - portfolioReturn) : undefined
                    }
                    loading={loading}
                  />
                )}
              </>
            )
          )}
        </tr>
        <tr data-testkey="expected-volatility">
          <td>{translate('allocator.volatility')}</td>
          {loading ? (
            <td>
              <Spinner size={20} position="right" />
            </td>
          ) : (
            portfolioVolatility && (
              <>
                <PercentageCell>
                  <VolatilityIcon />
                  <Number data-testkey="expected-volatility-value">
                    {formatToDecimals(portfolioVolatility, 1, '%')}
                  </Number>
                </PercentageCell>
                {doComparison && (
                  <DifferenceCell
                    difference={
                      portfolioVolatility
                        ? factor * (comparableStatistics?.portfolioVolatility - portfolioVolatility)
                        : undefined
                    }
                    loading={loading}
                  />
                )}
              </>
            )
          )}
        </tr>
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  img {
    vertical-align: baseline;
  }
`;

const PercentageCell = styled.td`
  text-align: right;
  width: 110px;
`;

const Number = styled.span`
  width: 60px;
  display: inline-block;
`;
