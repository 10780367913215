import React from 'react';
import translate from 'counterpart';
import Immutable from 'immutable';
import { APPOINTMENT } from 'constants/meetingType';
import { isFieldRequired, getError } from 'core/index';
import { OrderDialogSchema, ValidationErrorValue } from 'types/orderDialogState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  location: string;
  activity: string;
  handleLocation: () => void;
  state: string;
  errors: ValidationErrorValue[];
  touched: Immutable.Set<string>;
  schema: OrderDialogSchema;
}

const Location = ({ activity, handleLocation, location, schema, errors, touched }: Props) => {
  let content = null;
  if (activity === APPOINTMENT) {
    content = (
      <div className="columns small-12" data-testkey="appointment-location">
        <StyledTextField
          name="location"
          onChange={handleLocation}
          label={`${translate('meetings.location')}${isFieldRequired('location', schema) ? '*' : ''}`}
          style={{
            width: '100%',
          }}
          value={location}
          multiline
          helperText={touched.has('location') ? getError(errors, 'location') : undefined}
          variant="standard"
          sx={[
            {
              '&.MuiFormControl-root': {
                marginTop: '0',
                width: '100%',
              },
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInput-root:hover': {
                borderBottomColor: 'rgba(0, 0, 0, 0.6)',
                '&::before': {
                  borderBottomColor: 'rgba(0, 0, 0, 0.6) !important',
                  borderWidth: '1px !important',
                },
              },
              '& .MuiInputBase-root': {
                marginTop: '14px',
                marginBottom: '15px',
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Roboto, sans-serif',
                marginTop: 0,
                height: '100%',
                paddingTop: 0,
              },
              '& label': {
                fontSize: '14px',
                '&.Mui-focused': {
                  fontSize: '14px',
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
              '&.Mui-error': {
                borderBottomColor: '#d32f2f',
                borderWidth: '2px',
                '&::before': {
                  borderWidth: '2px',
                  borderBottomColor: '#d32f2f',
                },
              },
            },
          ]}
        />
      </div>
    );
  }
  return content;
};

export default Location;
