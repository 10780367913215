import immutable, { Map } from 'immutable';
import _ from 'lodash';
import moment from 'moment';
import { CLIENT, INITIALIZER_BANKER, CLIENT_INITIATIVE } from 'constants/receiveInfoOptions';
import { DIRTY, MARKET_PRICE, NONE, permanentFields, WRITTEN_METHOD, CLEAN } from 'constants/shareOptions';
import { BOND, SHARE, STRUCTURED_PRODUCT, InstrumentForm } from 'constants/instrumentForms';
import { BUY, SELL } from 'constants/sides';
import {
  isStructuredProduct,
  handleShare,
  handleFund,
  handleStructured,
  handleWarrant,
  handlePrivateEquity,
  handlePairSearch,
} from 'core/portfolios';
import {
  ORDER_FUND,
  ORDER_BOND,
  ORDER_SHARE,
  ORDER_STRUCTURED_PRODUCT,
  ORDER_WARRANT,
  ORDER_PRIVATE_EQUITY,
  INIT_ORDER_STATE,
  OrdersAction,
} from 'features/orders/ordersActions';
import {
  CONSULTATIVE_AGREEMENT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
  SECURITIES_BROKERAGE_CONTRACT,
} from 'constants/contractNames';
import { ORDER } from 'constants/orderRowTypes';
import { OrderDialogState, OrderSideType, ChangePairType } from 'types/orderDialogState';
import {
  SuitabilityResult,
  DialogPayload,
  OrderInstrument,
  StructuredInstrument,
  PrivateEqSearchResponse,
  WarrantOption,
  ChangePairsSearchResponse,
  InstrumentOption,
  ChangePairResponse,
  GetNominalValueResponse,
  EditorValues,
} from 'features/orderDialog/orderDialogTypes';
import {
  FeeResponse,
  ImmutableOrderLineType,
  InstrumentDetail,
  MinutesOfInvestmentServiceType,
  OrderLineType,
  SuitabilityError,
} from 'types/ordersState';
import { getInitializerTypes, getBasisTypes } from 'features/orderDialog/orderDialogUtils';
import {
  INIT_EDITOR,
  SET_EDITOR_VALUE,
  SET_EDITOR_VALUES,
  CHANGE_INSTRUMENT,
  CHANGE_INSTRUMENT_FORM,
  CHANGE_PAIRS_SUCCESS,
  DEFAULTFEES_REQUEST,
  DEFAULTFEES_SUCCESS,
  DEFAULTFEES_FAILURE,
  NOMINAL_VALUE_REQUEST,
  NOMINAL_VALUE_SUCCESS,
  NOMINAL_VALUE_FAILURE,
  VALIDATE_ORDER,
  SHARE_SEARCH_REQUEST,
  INSTRUMENT_SEARCH_REQUEST,
  STRUCTURED_SEARCH_REQUEST,
  WARRANT_SEARCH_REQUEST,
  PRIVATE_EQUITY_SEARCH_REQUEST,
  SHARE_SEARCH_SUCCESS,
  INSTRUMENT_SEARCH_SUCCESS,
  STRUCTURED_SEARCH_SUCCESS,
  WARRANT_SEARCH_SUCCESS,
  PRIVATE_EQUITY_SEARCH_SUCCESS,
  SHARE_SEARCH_FAILURE,
  INSTRUMENT_SEARCH_FAILURE,
  STRUCTURED_SEARCH_FAILURE,
  WARRANT_SEARCH_FAILURE,
  PRIVATE_EQUITY_SEARCH_FAILURE,
  EDIT_ORDERLINE,
  CHANGE_PAIRS_SEARCH_REQUEST,
  CHANGE_PAIRS_SEARCH_SUCCESS,
  CHANGE_PAIRS_SEARCH_FAILURE,
  SELECTED_SUITABILITY_REQUEST,
  SELECTED_SUITABILITY_SUCCESS,
  SELECTED_SUITABILITY_FAILURE,
  SET_INSTRUMENT_OPTIONS,
  SET_SUITABILITY_ERRORS,
  SET_IS_PREVIOUS_ORDER_DRAFT_SUITABLE,
  CHANGE_PORTFOLIO,
  HIDE_CONFIRMATION_DIALOG,
  SHOW_CONFIRMATION_DIALOG,
  UNDO_CHANGE_SIDE,
  SET_IS_EDITING_EXISTING_LINE,
  OrderDialogAction,
  SET_EDITOR_VALUE_BY_PATH,
  SET_BASIS_OF_ADVICE,
  COPY_MOIS_TO_CURRENT,
  SET_CHECK,
} from 'features/orderDialog/orderDialogActions';
import { SelectValue } from 'types/types';
import { MINUTES_OF_INVESTMENT_SERVICE } from 'constants/orderFields';

const initialEditorState: OrderLineType = {
  bondPriceType: 'None',
  portfolioId: '',
  receivedFromClientDate: '',
  orderType: '',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  instrumentForm: '',
  expireDate: '',
  receiverEmail: '',
  orderInitializerType: '',
  rowType: '',
  capitalCallEmail: '',
  customerCategorisation: '',
  side: BUY,
  receivedFromClientMethod: '',
  _id: 0,
  fundsOrigin: '',
  isSuitable: false,
  legalForm: '',
  moneySource: '',
  tradeAll: false,
  financialInstrumentForm: SHARE,
  financialInstrumentId: '',
  financialInstrumentName: '',
  instrumentCurrency: '',
  subscriptionFee: 0,
  orderBasis: '',
};

const initialState: OrderDialogState = {
  editor: immutable.Map(initialEditorState) as ImmutableOrderLineType,
  touchedFields: immutable.Map(),
  isBuyingNew: true,
  defaultFee: undefined,
  changePairs: [],
  isFetchingNominalValue: false,
  isFetchingFee: false,
  structuredMinimumSum: undefined,
  privateEquityMaxSum: undefined,
  structuredStep: undefined,
  hasEmission: true,
  searching: false,
  validationErrors: [],
  suitabilityErrors: [], //for chosen instrument in editor
  isPreviousOrderDraftSuitable: undefined, // created for case where sometimes we don't want user the option to go
  // back to editing order that wasn't suitable
  maxReceivedFromClientDate: undefined,
  instrumentOptions: [], //contains suitability errors for instruments
  isLoadingOptions: false,
  changePairOptions: [],
  isLoadingChangePairOptions: false,
  fetchingSelectedSuitability: false,
  isEditingExistingLine: false,
  dialogType: undefined,
  orderInitializerTypes: [],
  orderBasisTypes: [],
  basisOfAdvice: undefined,
};

const getInstrumentForm = (action: DialogPayload) => {
  const currentForm = action.currentInstrumentForm;
  const editorForm = _.get(action, ['initialValues', 'financialInstrumentForm'], undefined);
  let form;
  if (currentForm) {
    form = currentForm;
  } else if (editorForm) {
    form = editorForm;
  } else {
    form = SHARE;
  }
  return form;
};

export const getBondPriceType = (editorForm: string) => {
  if (editorForm === STRUCTURED_PRODUCT) {
    return DIRTY;
  } else if (editorForm === BOND) {
    return CLEAN;
  }
  return NONE;
};

const getEditorDefaults = () => ({
  expireDate: moment().format('YYYY-MM-DD'),
  orderType: MARKET_PRICE,
  tradeAll: false,
  rowType: ORDER,
});

export const getInitializerType = (contractName: string) =>
  contractName === CONSULTATIVE_AGREEMENT ||
  contractName === SECURITIES_BROKERAGE_CONTRACT ||
  contractName === CUSTOMER_RELATIONSHIP_AGREEMENT
    ? CLIENT
    : INITIALIZER_BANKER;

const getOrderBasis = (contractName: string, payload: DialogPayload) =>
  contractName === SECURITIES_BROKERAGE_CONTRACT ? CLIENT_INITIATIVE : payload.orderBasis;

export const getReceivedFromClientMethod = (contractName: string) =>
  contractName === CONSULTATIVE_AGREEMENT ||
  contractName === SECURITIES_BROKERAGE_CONTRACT ||
  CUSTOMER_RELATIONSHIP_AGREEMENT
    ? WRITTEN_METHOD
    : NONE;

const getSide = (currentInstrumentForm: InstrumentForm, currentSide: OrderSideType, onlySellAllowed: boolean) => {
  if (onlySellAllowed) {
    return SELL;
  }
  if (isStructuredProduct(currentInstrumentForm) && currentSide === SELL) {
    return SELL;
  }
  return BUY;
};

const initEditor = (state: OrderDialogState, action: { payload: DialogPayload }) => {
  const { currentInstrumentForm, currentSide, onlySellAllowed, contractName, contractType } = action.payload;
  return {
    ...state,
    editor: immutable.Map({
      ...getEditorDefaults(),
      _id: Date.now(),
      side: currentInstrumentForm ? getSide(currentInstrumentForm, currentSide, onlySellAllowed) : BUY,
      orderInitializerType: getInitializerType(contractName),
      receivedFromClientMethod: getReceivedFromClientMethod(contractName),
      receivedFromClientDate: moment().utc().format(),
      instrumentForm: getInstrumentForm(action.payload),
      bondPriceType: getBondPriceType(_.get(action.payload, ['initialValues', 'financialInstrumentForm'], undefined)),
      ...action.payload.initialValues,
      minutesOfInvestmentService: immutable.Map(action.payload.initialValues.minutesOfInvestmentService),
      orderBasis: getOrderBasis(contractName, action.payload),
    }),
    touchedFields: immutable.Map(),
    isBuyingNew: action.payload.isBuyingNew,
    isEditingExistingLine: action.payload.isEditingExistingLine,
    maxReceivedFromClientDate: moment().format('YYYY-MM-DDTHH:mm'),
    hasEmission: true,
    orderInitializerTypes: getInitializerTypes(contractName),
    orderBasisTypes: getBasisTypes(contractType, action.payload.initialValues.customerCategorisation),
    instrumentOptions: [],
    changePairOptions: [],
    isPreviousOrderDraftSuitable: undefined,
  };
};

const setEditorValueByPropertyPath = (
  state: OrderDialogState,
  action: { key: string; value: string | number | boolean | undefined }
) => {
  const path = action.key.split('.');
  return {
    ...state,
    editor: state.editor.setIn(path, action.value).filter((val) => typeof val !== 'undefined'),
    touchedFields: state.touchedFields.set(action.key, true),
  };
};

const setEditorValue = (
  state: OrderDialogState,
  action: { key: string; value: string | number | boolean | undefined }
) => {
  return {
    ...state,
    editor: state.editor.set(action.key, action.value).filter((val) => typeof val !== 'undefined'),
    touchedFields: state.touchedFields.set(action.key, true),
  };
};

const setEditorValues = (
  state: OrderDialogState,
  action: {
    values: EditorValues;
  }
) => {
  const touchedFields = Object.keys(action.values).reduce((acc, key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (action.values[key] !== undefined) {
      acc[key] = true;
    }
    return acc;
  }, {} as { [k: string]: unknown });

  return {
    ...state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    editor: state.editor.merge(action.values).filter((val) => typeof val !== 'undefined'),
    touchedFields: state.touchedFields.merge(touchedFields).filter((val) => typeof val !== 'undefined'),
  };
};

const changeInstrument = (
  state: OrderDialogState,
  action: {
    params: OrderInstrument | null;
    currentQuantity: number | undefined;
    portfolioCurrency: string;
    marketValueNet: number | undefined;
  }
) => {
  const params = action.params;
  let result;
  if (params) {
    result = {
      ...state,
      editor: state.editor
        .merge({
          financialInstrumentId: params.financialInstrumentId,
          financialInstrumentName: params.name,
          financialInstrumentForm: params.financialInstrumentForm,
          subscriptionFee: _.isUndefined(params?.fee) ? undefined : params?.fee * 100,
          unitFee: _.isUndefined(params?.unitFee) ? undefined : params?.unitFee,
          currency: action.portfolioCurrency,
          instrumentCurrency: params.currency,
          prePayPrice: params?.prePayPrice,
          issuer: params.issuer,
          isSuitable: !params.disabled && !params.alwaysEnable,
          currentQuantity: action.currentQuantity,
          marketPrice: params.marketPrice,
          marketPriceCurrency: params.marketPriceCurrency,
          marketPriceBase: params.marketPriceBase,
          marketPriceBaseCurrency: params.marketPriceBaseCurrency,
          marketValueNet: action.marketValueNet,
          productId: params?.productId,
          productIssuer: params?.productIssuer,
          legalForm: params?.legalForm,
          fundsOrigin: undefined,
          minutesOfInvestmentService: state.editor.get(MINUTES_OF_INVESTMENT_SERVICE),
        })
        .filter((val) => typeof val !== 'undefined'),
      structuredMinimumSum: params?.min,
      privateEquityMaxSum: params?.max,
      structuredStep: params?.step,
      defaultFee: params?.fee || params?.unitFee,
      hasEmission: true,
      touchedFields: state.touchedFields.set('instrument', true),
    };
  } else {
    result = {
      ...state,
      editor: state.editor
        .merge({
          financialInstrumentId: '',
          financialInstrumentName: '',
          financialInstrumentForm: '',
          subscriptionFee: undefined,
          currency: '',
          issuer: '',
          isSuitable: undefined,
          currentQuantity: undefined,
          marketPrice: '',
          marketPriceCurrency: '',
          marketPriceBase: '',
          marketPriceBaseCurrency: '',
          marketValueNet: '',
          legalForm: undefined,
          fundsOrigin: undefined,
          minutesOfInvestmentService: state.editor.get(MINUTES_OF_INVESTMENT_SERVICE),
        })
        .filter((val) => typeof val !== 'undefined'),
      structuredMinimumSum: undefined,
      privateEquityMaxSum: undefined,
      structuredStep: undefined,
      defaultFee: undefined,
      prePayPrice: undefined,
      hasEmission: false,
      touchedFields: state.touchedFields.set('instrument', true),
    };
  }
  return result;
};

const changeInstrumentForm = (
  state: OrderDialogState,
  action: {
    params: SelectValue;
  }
) => {
  if (typeof action.params.value !== 'string') {
    return;
  }

  let editorValues = _.pick(state.editor.toJS(), permanentFields);
  const val = action.params.value;
  editorValues = _.assign(editorValues, {
    ...getEditorDefaults(),
    instrumentForm: val,
    bondPriceType: getBondPriceType(val),
    minutesOfInvestmentService: state.editor.get(MINUTES_OF_INVESTMENT_SERVICE),
  });
  return {
    ...state,
    editor: immutable.Map(editorValues),
    structuredMinimumSum: undefined,
    privateEquityMaxSum: undefined,
    structuredStep: undefined,
    defaultFee: undefined,
    instrumentOptions: [],
    touchedFields: state.touchedFields.set('instrumentForm', true),
  };
};

const changePairsSuccess = (state: OrderDialogState, action: { result: ChangePairResponse[] }) => {
  let changePairs = [] as ChangePairType[];
  const res = action.result;
  if (res) {
    changePairs = res.map((val) => ({
      value: val.securityTo,
      label: val.securityTo,
    }));
  }
  return {
    ...state,
    changePairs,
  };
};

const defaultFeesSuccess = (state: OrderDialogState, action: { result: FeeResponse }) => {
  const res = action.result;
  let result = {};
  if (res) {
    const fee = res.feePercentage;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (fee !== undefined && fee !== '') {
      result = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        editor: state.editor.set('subscriptionFee', parseFloat(fee) * 100).filter((val) => typeof val !== 'undefined'),
        defaultFee: fee,
      };
    } else {
      result = {
        editor: state.editor.delete('subscriptionFee'),
        defaultFee: undefined,
      };
    }
  }
  return {
    ...state,
    ...result,
    isFetchingFee: false,
  };
};

const nominalValueSuccess = (
  state: OrderDialogState,
  action: {
    result: GetNominalValueResponse;
  }
) => {
  const result = action.result;
  let newState;
  if (result && !_.isEmpty(result.emissions)) {
    const fee = _.get(result, ['emissions', '0', 'defaultProvision'], 0);
    newState = {
      editor: state.editor
        .merge({
          subscriptionFee: fee * 100,
          currency: _.get(result, ['emissions', '0', 'currency']),
        })
        .filter((val) => typeof val !== 'undefined'),
      structuredMinimumSum: _.get(result, ['emissions', '0', 'minSubscription']),
      privateEquityMaxSum: _.get(result, ['emissions', '0', 'maxSubscription']),
      structuredStep: _.get(result, ['emissions', '0', 'lotsize']),
      defaultFee: fee,
      hasEmission: true,
    };
  } else {
    newState = {
      hasEmission: false,
    };
  }
  return {
    ...state,
    ...newState,
    isFetchingNominalValue: false,
  };
};

const addOrderLine = (state: OrderDialogState) => ({
  ...state,
  instrumentOptions: [],
  isLoadingOptions: false,
  changePairOptions: [],
  isLoadingChangePairOptions: false,
});

const shareSearchSuccess = (state: OrderDialogState, action: { results: InstrumentDetail[] }) => {
  return {
    ...state,
    instrumentOptions: handleShare(action.results),
    isLoadingOptions: false,
  };
};

const instrumentSearchSuccess = (state: OrderDialogState, action: { results: InstrumentDetail[] }) => {
  return {
    ...state,
    instrumentOptions: handleFund(action.results),
    isLoadingOptions: false,
  };
};

const structuredSearchSuccess = (
  state: OrderDialogState,
  action: {
    results: {
      descriptions: [];
      financialInstruments: StructuredInstrument[];
      isSuitable: boolean;
      name: string;
      productId: string;
      shortName: string | null;
      suitabilityErrors: SuitabilityResult[];
    }[];
  }
) => {
  return {
    ...state,
    instrumentOptions: handleStructured(action.results),
    isLoadingOptions: false,
  };
};

const warrantSearchSuccess = (state: OrderDialogState, action: { results: WarrantOption[] }) => {
  return {
    ...state,
    instrumentOptions: handleWarrant(action.results),
    isLoadingOptions: false,
  };
};

const privateEqSearchSuccess = (state: OrderDialogState, action: { results: PrivateEqSearchResponse[] }) => {
  return {
    ...state,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    instrumentOptions: handlePrivateEquity(action.results),
    isLoadingOptions: false,
  };
};

const changePairsSearchSuccess = (state: OrderDialogState, action: { results: ChangePairsSearchResponse[] }) => {
  return {
    ...state,
    changePairOptions: handlePairSearch(state.changePairs, action.results),
    isLoadingChangePairOptions: false,
  };
};

const updateSuitability = (state: OrderDialogState, action: { result: SuitabilityResult[] }) => {
  const suitabilityErrors = action.result.filter((e) => !e.isSuitable);
  return {
    ...state,
    editor: state.editor.set('isSuitable', suitabilityErrors.length === 0), // TO DO: is this needed anymore/ could be selector instead
    suitabilityErrors,
    fetchingSelectedSuitability: false,
  };
};

const setInstrumentOptions = (state: OrderDialogState, action: { options: InstrumentOption[] }) => {
  return {
    ...state,
    instrumentOptions: action.options,
  };
};

const setSuitabilityErrors = (state: OrderDialogState, action: { errors: SuitabilityError[] | undefined }) => {
  return {
    ...state,
    suitabilityErrors: action.errors,
  };
};
const setIsPreviousOrderDraftSuitable = (
  state: OrderDialogState,
  action: { isPreviousOrderDraftSuitable: boolean }
) => {
  return {
    ...state,
    isPreviousOrderDraftSuitable: action.isPreviousOrderDraftSuitable,
  };
};

const undoChangeSide = (state: OrderDialogState) => {
  return {
    ...state,
    editor: state.editor.set('isSuitable', true),
    suitabilityErrors: [] as SuitabilityResult[],
    dialogType: undefined,
  };
};

const copyMOISToCurrent = (state: OrderDialogState, mois: MinutesOfInvestmentServiceType) => {
  return {
    ...state,
    editor: state.editor.set('minutesOfInvestmentService', Map(mois)),
  };
};

export function orderDialogReducer(state: OrderDialogState = initialState, action: OrderDialogAction | OrdersAction) {
  switch (action.type) {
    case INIT_EDITOR:
      return initEditor(state, action);
    case SET_EDITOR_VALUE:
      return setEditorValue(state, action);
    case SET_EDITOR_VALUES:
      return setEditorValues(state, action);
    case SET_EDITOR_VALUE_BY_PATH:
      return setEditorValueByPropertyPath(state, action);
    case CHANGE_INSTRUMENT:
      return changeInstrument(state, action);
    case CHANGE_INSTRUMENT_FORM:
      return changeInstrumentForm(state, action);
    case CHANGE_PAIRS_SUCCESS:
      return changePairsSuccess(state, action);
    case DEFAULTFEES_REQUEST:
      return {
        ...state,
        isFetchingFee: true,
      };
    case DEFAULTFEES_SUCCESS:
      return defaultFeesSuccess(state, action);
    case DEFAULTFEES_FAILURE:
      return {
        ...state,
        isFetchingFee: false,
      };
    case NOMINAL_VALUE_REQUEST:
      return {
        ...state,
        isFetchingNominalValue: true,
      };
    case NOMINAL_VALUE_SUCCESS:
      return nominalValueSuccess(state, action);
    case NOMINAL_VALUE_FAILURE:
      return {
        ...state,
        isFetchingNominalValue: false,
      };
    case VALIDATE_ORDER:
      return {
        ...state,
        validationErrors: action.errors,
      };
    case ORDER_PRIVATE_EQUITY:
    case ORDER_WARRANT:
    case ORDER_STRUCTURED_PRODUCT:
    case ORDER_SHARE:
    case ORDER_BOND:
    case ORDER_FUND:
      return addOrderLine(state);
    case INIT_ORDER_STATE:
      return initialState;
    case SHARE_SEARCH_REQUEST:
    case INSTRUMENT_SEARCH_REQUEST:
    case STRUCTURED_SEARCH_REQUEST:
    case WARRANT_SEARCH_REQUEST:
    case PRIVATE_EQUITY_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingOptions: true,
      };
    case SHARE_SEARCH_SUCCESS:
      return shareSearchSuccess(state, action);
    case INSTRUMENT_SEARCH_SUCCESS:
      return instrumentSearchSuccess(state, action);
    case STRUCTURED_SEARCH_SUCCESS:
      return structuredSearchSuccess(state, action);
    case WARRANT_SEARCH_SUCCESS:
      return warrantSearchSuccess(state, action);
    case PRIVATE_EQUITY_SEARCH_SUCCESS:
      return privateEqSearchSuccess(state, action);
    case SHARE_SEARCH_FAILURE:
    case INSTRUMENT_SEARCH_FAILURE:
    case STRUCTURED_SEARCH_FAILURE:
    case WARRANT_SEARCH_FAILURE:
    case PRIVATE_EQUITY_SEARCH_FAILURE:
      return {
        ...state,
        isLoadingOptions: false,
      };
    case EDIT_ORDERLINE:
      return {
        ...state,
        instrumentOptions: [
          {
            value: action.orderLine.financialInstrumentId,
            label: action.orderLine.financialInstrumentName,
            financialInstrumentForm: action.orderLine.financialInstrumentForm,
            currency: action.orderLine.instrumentCurrency,
          },
        ],
        changePairOptions: action.orderLine.counterFinancialInstrumentId
          ? [
              {
                value: action.orderLine.counterFinancialInstrumentId,
                label: action.orderLine.counterFinancialInstrumentName,
                financialInstrumentForm: action.orderLine.counterFinancialInstrumentForm,
              },
            ]
          : [],
      };
    case CHANGE_PAIRS_SEARCH_REQUEST:
      return {
        ...state,
        isLoadingChangePairOptions: true,
      };
    case CHANGE_PAIRS_SEARCH_SUCCESS:
      return changePairsSearchSuccess(state, action);
    case CHANGE_PAIRS_SEARCH_FAILURE:
      return {
        ...state,
        isLoadingChangePairOptions: false,
      };
    case SELECTED_SUITABILITY_REQUEST:
      return {
        ...state,
        fetchingSelectedSuitability: true,
      };
    case SELECTED_SUITABILITY_SUCCESS:
      return updateSuitability(state, action);
    case SELECTED_SUITABILITY_FAILURE:
      return {
        ...state,
        fetchingSelectedSuitability: false,
      };
    case SET_INSTRUMENT_OPTIONS:
      return setInstrumentOptions(state, action);
    case SET_SUITABILITY_ERRORS:
      return setSuitabilityErrors(state, action);
    case SET_IS_PREVIOUS_ORDER_DRAFT_SUITABLE:
      return setIsPreviousOrderDraftSuitable(state, action);
    case CHANGE_PORTFOLIO:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        editor: state.editor.merge(action.editor).filter((val) => typeof val !== 'undefined'),
        orderInitializerTypes: action.orderInitializerTypes,
        isPreviousOrderDraftSuitable: undefined,
      };
    case HIDE_CONFIRMATION_DIALOG:
      return {
        ...state,
        dialogType: undefined,
      };
    case SHOW_CONFIRMATION_DIALOG:
      return {
        ...state,
        dialogType: action.dialogType,
      };
    case UNDO_CHANGE_SIDE:
      return undoChangeSide(state);
    case SET_IS_EDITING_EXISTING_LINE:
      return {
        ...state,
        isEditingExistingLine: action.isEditingExistingLine,
      };
    case SET_BASIS_OF_ADVICE:
      return {
        ...state,
        basisOfAdvice: action.value,
      };
    case COPY_MOIS_TO_CURRENT:
      return copyMOISToCurrent(state, action.mois);
    case SET_CHECK:
      return {
        ...state,
        [action.property]: action.value,
      };
    default:
      return state;
  }
}
