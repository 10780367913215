import { either } from 'fp-ts';
import { Errors, Validation } from 'io-ts';
import { Location } from 'history';
import { apiCall } from 'core/apiCall';
import PromiseStore from 'core/PromiseStore';
import { BankersCustomersBatchResponse } from 'types/customerListState';

export const getCustomerBatchStatusApiCall = async (banker: string, accessToken: string) => {
  PromiseStore.getBankersCustomersBatch = apiCall({
    path: `/api/v1/allocator/bankercustomers?bankerDomainUsername=${encodeURI(banker)}`,
    token: accessToken,
    method: 'get',
  });
  return PromiseStore.getBankersCustomersBatch as Promise<BankersCustomersBatchResponse>;
};

export const customerValidationErrorMapper = (validationResult: Validation<unknown>) => {
  const errors = either.fold(
    (errors: Errors) => errors.map((error) => error),
    () => []
  )(validationResult);

  return errors;
};

export const getQueryBankerId = (location: Location): string | undefined => {
  const queryBankerId = location.query.bankerId;

  if (!queryBankerId) {
    return;
  }

  if (Array.isArray(queryBankerId)) {
    return;
  }

  return queryBankerId;
};
