import React from 'react';
import translate from 'counterpart';
import OptionBox from './OptionBox';
import { CUSTOMER_TYPE } from 'constants/newCustomer';
import { customerTypes } from 'features/prospect/prospectConstants';
import { ProspectsState } from 'types/prospectsState';

interface Handler {
  key: typeof CUSTOMER_TYPE;
  value: string;
}

interface Props {
  handler: ({ key, value }: Handler) => void;
  wizardState: ProspectsState;
}

export default class CustomerTypeView extends React.Component<Props> {
  handleClick = (value: string) => {
    this.props.handler({ key: CUSTOMER_TYPE, value });
  };
  render() {
    const nextStep = '/new/categorisation';
    const tempStyle = {
      pointerEvents: 'none',
      opacity: '.5',
    };
    const selection = this.props.wizardState.formValues.getIn(CUSTOMER_TYPE.split('.'));
    const components = customerTypes.map((option, index) => (
      <OptionBox
        key={index}
        selected={selection === option.value}
        handler={this.handleClick}
        option={option.value}
        link={nextStep}
        text={option.translateKey}
        icon={option.icon}
        style={index === 1 ? tempStyle : {}}
        data-testkey={`new-customer-type-link-${option.translateKey}`}
      />
    ));
    return (
      <section>
        <header>{translate(`customer.selectType`)}</header>
        <section className={'row align-center'}>{components}</section>
      </section>
    );
  }
}
