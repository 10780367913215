import React, { ChangeEvent, useState } from 'react';
import translate from 'counterpart';
import FormGroup from '@mui/material/FormGroup';
import { TextFieldDefault } from '/features/common/styledComponents/StyledTextField';
import styled from 'styled-components';
import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch } from 'core/hooks';
import { isEmpty } from 'core/validations';
import { setBasisOfAdviceValue } from 'features/orderDialog/orderDialogActions';
import { FormTitleSmall } from 'features/common/styledComponents/StyledText';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { colors } from 'styles/colors';

interface Props {
  value?: string;
}

export const BasisOfAdviceField = ({ value }: Props) => {
  const dispatch = useAppDispatch();
  const [errorTextBasisOfAdvice, setErrorTextBasisOfAdvice] = useState<string | undefined>();
  const [infoModalOpen, setInfoModalOpen] = useState<boolean>(false);

  const setBasisOfAdviceText = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (isEmpty(value)) {
      setErrorTextBasisOfAdvice(translate('order.basisOfAdviceRequiredText'));
    } else {
      setErrorTextBasisOfAdvice(undefined);
    }
    dispatch(setBasisOfAdviceValue(value));
  };

  return (
    <Container>
      <FormGroup>
        <TitleLabel>
          {translate('order.basisOfAdviceLabel')} <ModalOpener onClick={() => setInfoModalOpen(true)}>[?]</ModalOpener>
          <Dialog onClose={() => setInfoModalOpen(false)} open={infoModalOpen ? infoModalOpen : false}>
            <DialogContent>
              <ModalAdditionalInformationContent />
            </DialogContent>
            <StyledButton onClick={() => setInfoModalOpen(false)}>Sulje</StyledButton>
          </Dialog>
        </TitleLabel>
        <TextFieldDefault
          onChange={setBasisOfAdviceText}
          variant="outlined"
          multiline
          helperText={errorTextBasisOfAdvice}
          value={value || ''}
          autoComplete="off"
          sx={{
            '& .MuiInputBase-input': {
              color: 'black',
              boxShadow: 'none',
              border: 'none',
              background: 'none',
            },
          }}
        />
      </FormGroup>
    </Container>
  );
};

const TitleLabel = styled(FormTitleSmall)`
  margin-top: 20px;
`;

const Container = styled.div`
  max-width: 60em;
`;

const ModalOpener = styled.span`
  cursor: pointer;
`;

const ModalAdditionalInformationContent = () => {
  //  Spread the props to the underlying DOM element.
  return (
    <div>
      <b>Suosituksen lisätiedot</b>
      <br />
      Annettu sijoitussuositus tai tehty sijoituspäätös ja näihin liittyvät tuotevalinnat tulee aina perustella – miksi
      kyseiselle asiakkaalle suositellaan tai merkitään kyseistä tuotetta tai palvelua. ”Älä tee mitään” on myös
      sijoitusneuvo.
      <br />
      <br />
      Jos asiakas poikkeaa suosituksesta, kirjaa miten asiakas poikkeaa ja miksi. Lisätiedot ovat osa
      soveltuvuuslausuntoa ja näkyvät asiakkaan tulosteella.
      <br />
      <br />
      <b>Vastaavien tuotteiden arviointi</b>
      <br />
      Jos vastaavia tuotteita valikoimassa, kopioi alla oleva teksti ja valitse tilanteeseen sopivat vaihtoehdot.
      <br />
      <InfoBox>
        Tuotevalinnan peruste:
        <ul>
          <li>Vastuullinen sijoittaminen</li>
          <li>Aktian rahastoanalyysin perusteella valittu kolmannen osapuolen tuote</li>
          <li>Muu syy:</li>
        </ul>
      </InfoBox>
      <br />
      <b>Vaihdon perusteet:</b>
      <br />
      Jos kyseessä on myyntisuositus, kopioi alla oleva teksti ja valitse tilanteeseen sopivat vaihtoehdot.
      <br />
      <InfoBox>
        Kyseessä on toimeksianto, jossa myydään pois nykyistä sijoituskohdetta ja ostetaan tilalle toista.
        <br />
        <br />
        Aktia on arvioinut, että vaihdon hyödyt ylittävät siitä aiheutuvat kustannukset seuraavin perusteluin:
        <ul>
          <li>
            Vaihdosta aiheutuu hyötyä sijoitussalkulle, esimerkiksi hajautus paranee, likviditeetti paranee tai salkun
            allokaatio lähestyy Aktian suositusta.
          </li>
          <li>Uudella sijoituskohteella odotetaan olevan nykyistä sijoituskohdetta parempi tuotto.</li>
          <li>
            Asiakkaan tilanne tai asiakkaalle sopiva varojen allokaatio on muuttunut ja se on syytä huomioida
            sijoituksissa.
          </li>
          <li>
            Nykyisessä sijoituskohteessa on tapahtunut epäedullisia muutoksia, esimerkiksi se on muuttunut tai arvion
            mukaan muuttumassa epälikvidiksi.
          </li>
          <li>Muu syy:</li>
        </ul>
        Vaihdon mahdolliset veroseuraamukset on huomioitu.
      </InfoBox>
    </div>
  );
};

const InfoBox = styled.div`
  background-color: ${colors.gray_light};
  padding: 20px;
`;
