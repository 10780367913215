import React from 'react';
import HardwareKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Refresh from '@mui/icons-material/Refresh';
import translate from 'counterpart';
import moment from 'moment';
import styles from './Stakeholders.scss';
import { Spinner } from 'features/common/Spinner';
import { ImmutableIdentifyAndScreen, ImmutableStakeholders, Stakeholder } from 'types/profileEditState';

interface Props {
  identifyAndScreen: ImmutableIdentifyAndScreen;
  getCompanyIdentifyAndScreen: (customerId: string) => void;
  updateStakeholders: (customerId: string) => void;
  customerId: string;
  stakeholders: ImmutableStakeholders;
  lastScreened: string;
}

export const Stakeholders = ({
  customerId,
  getCompanyIdentifyAndScreen,
  identifyAndScreen,
  updateStakeholders,
  stakeholders,
  lastScreened,
}: Props) => {
  function buildStakeholdersList(stakeholders: ImmutableStakeholders, isBusy: boolean) {
    const dashIfEmpty = (arr: string[]) => (arr.length > 0 ? arr : <div>-</div>);
    const formatStakeholder = (p: Stakeholder) => (
      <span style={{ color: p.externalId ? 'black' : '#B3B3B3' }}>
        {p.lastName}, {p.firstName} s. {p.birthDate ? moment(p.birthDate).format('DD.MM.YYYY') : '-'}
      </span>
    );

    if (isBusy) {
      return <Spinner />;
    }
    if (!stakeholders) {
      return null;
    }

    return (
      <div>
        <h4>{translate(`customer.representatives`)}</h4>
        {dashIfEmpty(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          stakeholders
            .get('representatives')
            .toJS()
            .map((p, i) => <div key={`rep-${i}`}>{formatStakeholder(p)}</div>)
        )}
        <h4>{translate(`customer.boardMembers`)}</h4>
        {dashIfEmpty(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          stakeholders
            .get('boardMembers')
            .toJS()
            .map((p, i) => <div key={`boa-${i}`}>{formatStakeholder(p)}</div>)
        )}
        <h4>{translate(`customer.beneficialOwners`)}</h4>
        {dashIfEmpty(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          stakeholders
            .get('beneficialOwners')
            .toJS()
            .map((p, i) => <div key={`ben-${i}`}>{formatStakeholder(p)}</div>)
        )}
      </div>
    );
  }

  const registryPersons = buildStakeholdersList(identifyAndScreen.get('data'), identifyAndScreen.get('isBusy'));
  const crmPersons = buildStakeholdersList(stakeholders.get('data'), stakeholders.get('isBusy'));

  return (
    <div className={styles.stakeholders}>
      <div className="row align-justify">
        <div className={'columns small-5 ' + styles.contentPanel}>
          <h3>{translate(`customer.registryData`)}</h3>
          {identifyAndScreen.get('error') ? (
            <div className={styles.error}>{identifyAndScreen.get('error')}</div>
          ) : undefined}
          {registryPersons ? (
            registryPersons
          ) : (
            <button className={styles.actionButton} onClick={() => getCompanyIdentifyAndScreen(customerId)}>
              <Refresh
                className="refresh"
                key="icon"
                style={{
                  width: '15px',
                  height: '15px',
                  verticalAlign: 'middle',
                  marginRight: '.6rem',
                }}
                sx={{
                  color: '#fff',
                }}
              />
              <span>{translate(`customer.fetchData`)}</span>
            </button>
          )}
        </div>
        <div className="columns small-2">
          {/* eslint-disable no-nested-ternary */}
          {!identifyAndScreen.get('data') ? undefined : identifyAndScreen.get('isBusy') ? (
            <Spinner />
          ) : (
            <div onClick={() => updateStakeholders(customerId)}>
              <span className={styles.copyLink}>{translate(`customer.importToSystem`)}</span>
              <span className={styles.arrow}>
                <HardwareKeyboardArrowRight
                  sx={{
                    color: '#80c49d',
                  }}
                />
              </span>
            </div>
          )}
          {/* eslint-enable no-nested-ternary */}
        </div>
        <div className={'columns small-5 ' + styles.contentPanel}>
          <h3>{translate(`customer.crmData`)}</h3>
          {crmPersons}
          <br />
          <div>
            Viimeksi tarkastettu:&nbsp;
            {lastScreened ? moment(lastScreened).format('DD.MM.YYYY HH:mm') : '-'}
          </div>
        </div>
      </div>
    </div>
  );
};
