import { PortfolioInstrument } from 'types/instrumentsState';
import { AppThunk } from 'types/types';

export const SET_SELECTED_INSTRUMENT_GROUP = 'SET_SELECTED_INSTRUMENT_GROUP';
export const SET_INSTRUMENTS_SELECTED = 'SET_INSTRUMENTS_SELECTED';

export function setInstrumentsSelected(instruments: PortfolioInstrument[]) {
  return <const>{
    type: SET_INSTRUMENTS_SELECTED,
    result: instruments,
  };
}

export function setSelectedInstrumentGroup(instrumentGroup: string) {
  return <const>{
    type: SET_SELECTED_INSTRUMENT_GROUP,
    result: instrumentGroup,
  };
}

export function handlePlanSelection(newSelectedInstrumentGroup: string): AppThunk {
  return (dispatch, getState) => {
    const instrumentGroups = getState().portfolioManager.commonData.instrumentGroups;
    const instruments =
      instrumentGroups?.find((group) => group.groupId === newSelectedInstrumentGroup)?.instruments || [];
    dispatch(setInstrumentsSelected(instruments as PortfolioInstrument[]));
  };
}

export type InstrumentsAction = ReturnType<typeof setInstrumentsSelected | typeof setSelectedInstrumentGroup>;
