import React from 'react';
import { CallbackComponent } from 'redux-oidc';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import translate from 'counterpart';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { userManager } from 'core/userManager';
import { config } from 'config';

interface Props {
  dispatch: Dispatch;
}

class CallbackPage extends React.Component<Props> {
  successCallback = (user: { profile: { role: string }; state: { redirectUrl: string } }) => {
    // The user object gets data passed in store.js/signinRedirect
    // Config 'triggerAuthFlow: true' is required

    const role = _.get(user, ['profile', 'role'], null);
    const allowedUser = config.allowedUserRole;

    if (
      !user ||
      (Array.isArray(role) && !role.find((r) => r === allowedUser)) ||
      (typeof role === 'string' && role !== allowedUser)
    ) {
      this.props.dispatch(push('/unauthorized'));
    } else if (!user?.state?.redirectUrl) {
      this.props.dispatch(push('/'));
    } else {
      const urlBeforeRedirection = user.state.redirectUrl;
      this.props.dispatch(push(urlBeforeRedirection));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  errorCallback = (error: Error) => {
    this.props.dispatch(push('/unauthorized'));
  };

  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        successCallback={this.successCallback}
        errorCallback={this.errorCallback}
      >
        <div>{translate('common.redirectingToApplication')}</div>
      </CallbackComponent>
    );
  }
}

export default connect()(CallbackPage);
