import React, { Component } from 'react';
import translate from 'counterpart';
import styles from './SavedFilter.scss';
import { ModalDialog } from 'features/common/ModalDialog';
import { SearchFiltersType } from 'types/orderListState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  filter: SearchFiltersType;
  setSearchFilters: (f: SearchFiltersType) => void;
  deleteSavedFilter: (id: string) => void;
}

interface State {
  modalOpen: boolean;
}

export default class SavedFilter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  setSearchFilters = () => {
    const { filter, setSearchFilters } = this.props;
    setSearchFilters(filter);
  };

  closeModal = () => {
    this.setState({ modalOpen: false });
  };

  deleteFilter = () => {
    const { filter, deleteSavedFilter } = this.props;
    this.closeModal();
    deleteSavedFilter(filter.shortId);
  };

  confirmDeleteFilter = () => {
    this.setState({ modalOpen: true });
  };

  renderDiscardButton() {
    return (
      <StyledButton
        variant="text"
        onClick={this.deleteFilter}
        data-testkey="confirm-delete-filter"
        key="confirm-delete-filter"
      >
        Poista
      </StyledButton>
    );
  }

  renderCancelButton() {
    return (
      <StyledButton variant="text" onClick={this.closeModal} key="cancel-filter">
        Peruuta
      </StyledButton>
    );
  }

  renderActions() {
    return [this.renderDiscardButton(), this.renderCancelButton()];
  }

  render() {
    const { filter } = this.props;
    return (
      <div className={styles.filter} data-testkey="saved-filter">
        <span className={styles.icon} onClick={this.confirmDeleteFilter} data-testkey="delete-saved-filter">
          ×
        </span>
        <span className={styles.label} onClick={this.setSearchFilters} data-testkey="apply-saved-filter">
          {filter.searchName}
        </span>
        <ModalDialog
          title={translate('orderList.deleteFilter')}
          content={`${translate('orderList.wantDeleteFilter')} ${filter.searchName}?`}
          open={this.state.modalOpen}
          actions={this.renderActions()}
        />
      </div>
    );
  }
}
