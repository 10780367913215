import React from 'react';
import translate from 'counterpart';
import { InstrumentsList } from './InstrumentsList';
import { ASSET_CLASS_COLORS } from 'styles/colors';
import { AssetClassId } from 'types/types';
import { BasicInstrument, PortfolioInstrument } from 'types/instrumentsState';

interface Props {
  assetCategoryId: string;
  allocatorInstruments: BasicInstrument[];
  originalAssetClassesSelected: AssetClassId[];
  handleInstrumentSelect: (instrument: BasicInstrument) => void;
  selectedPortfolioId: string;
  newInstrumentsSelected: PortfolioInstrument[];
  originalInstrumentsSelected: PortfolioInstrument[];
}

export const AssetCategory = ({
  assetCategoryId,
  allocatorInstruments,
  originalAssetClassesSelected,
  handleInstrumentSelect,
  selectedPortfolioId,
  newInstrumentsSelected,
  originalInstrumentsSelected,
}: Props) => {
  const assetClassIds = Object.keys(ASSET_CLASS_COLORS).filter((key) =>
    key.startsWith(assetCategoryId)
  ) as AssetClassId[];

  return (
    <div>
      <h2>{translate(`allocator.instrumentsView.${assetCategoryId}`)}</h2>

      {assetClassIds.map((assetClassId) => {
        const assetClassInstruments = allocatorInstruments.filter(
          (instrument) => instrument.assetClassId === assetClassId
        );
        const assetClassNotInPositions = !originalAssetClassesSelected.includes(assetClassId);

        return (
          <InstrumentsList
            key={assetClassId}
            handleInstrumentSelect={handleInstrumentSelect}
            assetClassId={assetClassId}
            portfolioId={selectedPortfolioId}
            newInstrumentsSelected={newInstrumentsSelected}
            originalInstrumentsSelected={originalInstrumentsSelected}
            instruments={assetClassInstruments}
            assetClassNotInPositions={assetClassNotInPositions}
          />
        );
      })}
    </div>
  );
};
