import { SelectValue } from 'types/types';

// Actions types

export const CHANGE_ROW_TYPE = 'CHANGE_ROW_TYPE';
export const CLEAR_ORDERLINES = 'CLEAR_ORDERLINES';
export const CLEAR_ALL_ORDERLINES = 'CLEAR_ALL_ORDERLINES';

// Actions

export const changeRowType = (id: number, type: SelectValue) => {
  return <const>{
    type: CHANGE_ROW_TYPE,
    payload: {
      id,
      type,
    },
  };
};

export const clearOrderLinesPortfolioId = (portfolioId: string) => {
  return <const>{
    type: CLEAR_ORDERLINES,
    portfolioId,
  };
};

export const clearAllOrderLines = () => {
  return <const>{
    type: CLEAR_ALL_ORDERLINES,
  };
};

export type OrderLinesAction = ReturnType<
  typeof changeRowType | typeof clearOrderLinesPortfolioId | typeof clearAllOrderLines
>;
