import React from 'react';
import translate from 'counterpart';
import { SelectWithLabel } from './SelectWithLabel';
import { orderTypes } from 'constants/shareOptions';
import { SelectValue } from 'types/types';

interface Props {
  options: typeof orderTypes;
  onChange: (input: SelectValue) => void;
  value: string;
  className: string;
}

const SelectOrderType = ({ options, onChange, value, className }: Props) => (
  <SelectWithLabel
    options={options}
    onChange={onChange}
    value={value}
    label={translate('order.orderType')}
    className={className}
    data-testkey="order-type"
  />
);

export default SelectOrderType;
