import React, { useRef } from 'react';
import { push, goBack } from 'react-router-redux';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import moment from 'moment';
import { OrderReceiveInfo } from './OrderReceiveInfo';
import {
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
  RECEIVED_FROM_CLIENT_DATE,
  BASIS_TYPE,
  REPRESENTATIVE_SSN,
  REPRESENTATIVE_NAME,
} from 'constants/orderFields';
import { CHANGE_SIDE_DIALOG, CHANGE_PORTFOLIO_DIALOG, ORDER_FROM_BUTTON_FAIL_DIALOG } from 'constants/orderDialog';
import { ChangePortfolioDialog } from './ChangePortfolioDialog';
import { ChangeSideDialog } from './ChangeSideDialog';
import { OrderFromButtonFailDialog } from './OrderFromButtonFailDialog';
import { ValidationErrorValue, OrderInitializerType, OrderBasisType } from 'types/orderDialogState';
import { PortfolioById } from 'types/portfolioState';
import { SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { OrderLineType } from 'types/ordersState';
import { InstrumentForm, SHARE } from 'constants/instrumentForms';
import {
  initEditor,
  setInstrumentOptions,
  setSuitabilityErrors,
  hideConfirmationDialog,
  setIsPreviousOrderDraftSuitable,
  setEditorValue,
  undoChangeSide,
  createOrderLine,
} from 'features/orderDialog/orderDialogActions';
import {
  selectEditor,
  selectInstrumentFormOptions,
  selectInstrumentGroupsByFinancialInstrumentId,
} from 'features/orderDialog/orderDialogSelectors';
import { RootState } from 'types/rootState';
import { AssignmentInstrumentEditor } from 'features/orderDialog/components/AssignmentInstrumentEditor';
import { useAppDispatch } from 'core/hooks';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { MinutesOfInvestmentService } from 'features/orderDialog/components/MinutesOfInvestmentService';
import { FULL_POWER_OF_ATTORNEY_TYPE } from 'constants/contractNames';
import {
  selectIsBasisOfAdviceFieldRequired,
  selectSuitabilityToDateCheckRequiredAndNotSet,
} from 'features/orders/ordersSelectors';
import { BasisOfAdviceField } from 'features/orders/components/StatementDetailsForm';
import { Checks } from './Checks';

interface Props {
  editor: OrderLineType;
  selectedPortfolio: PortfolioById;
  customerId: string;
  maxReceivedFromClientDate: string | undefined;
  validationErrors: ValidationErrorValue[];
  isEditingExistingLine: boolean;
  isOrderValid: boolean;
  orderInitializerTypes: OrderInitializerType[];
  suitabilityErrors: SuitabilityResult[];
  dialogType: string | undefined;
  isPreviousOrderDraftSuitable: boolean | undefined;
  orderBasisTypes: OrderBasisType[];
  contractType?: string;
}

export const AssignmentEditor = ({
  editor,
  selectedPortfolio,
  customerId,
  maxReceivedFromClientDate,
  validationErrors,
  isEditingExistingLine,
  isOrderValid,
  orderInitializerTypes,
  suitabilityErrors,
  dialogType,
  isPreviousOrderDraftSuitable,
  orderBasisTypes,
  contractType,
}: Props) => {
  const dispatch = useAppDispatch();
  const focusRef = useRef<HTMLElement>();
  const setFocus = () => {
    focusRef.current && focusRef.current.focus();
  };
  const groups = useSelector((state: RootState) =>
    selectInstrumentGroupsByFinancialInstrumentId(state, editor.financialInstrumentId)
  );

  const basisOfAdviceValue = useSelector((state: RootState) => state.orderDialog.basisOfAdvice);
  const isBasisOfAdviceRequired = useSelector((state: RootState) => selectIsBasisOfAdviceFieldRequired(state));
  const basisOfAdviceEmpty = useSelector(
    (state: RootState) => !state.orderDialog.basisOfAdvice || state.orderDialog.basisOfAdvice === ''
  );
  const basisOfAdviceRequiredAndNotSet = isBasisOfAdviceRequired && basisOfAdviceEmpty;

  const suitabilityToDateCheckRequiredAndNotSet = useSelector(selectSuitabilityToDateCheckRequiredAndNotSet);

  const confirmDisabled = !isOrderValid || basisOfAdviceRequiredAndNotSet || suitabilityToDateCheckRequiredAndNotSet;

  const customerType = useSelector((state: RootState) => state.profile.customer?.get('customerType'));

  const assignment = useSelector(selectEditor).toJS();
  const instrumentFormOptions = useSelector((state: RootState) => selectInstrumentFormOptions(state, assignment._id));

  const placeOrder = () => {
    const defaults = {
      currency: 'EUR',
      billingEmail: '',
      defaultAccountType: '',
      quantity: 0,
      notionalAmount: 0,
      amount: 0,
      marketPrice: 0,
      marketPriceBaseCurrency: '',
      financialInstrument: { name: '', financialInstrumentForm: SHARE as InstrumentForm, symbol: '' },
      issuer: '',
      contractName: '',
      orderFinancingBuy: '',
      strategyName: '',
      marketPriceCurrency: '',
      marketPriceBase: 0,
      counterFinancialInstrumentId: '',
      counterFinancialInstrumentName: '',
      counterFinancialInstrumentForm: SHARE as InstrumentForm,
      counterFinancialInstrument: {
        counterFinancialInstrumentId: '',
        name: '',
        counterFinancialInstrumentForm: SHARE as InstrumentForm,
      },
      value: '',
      minutesOfInvestmentService: {
        date: moment().format('YYYY-MM-DDTHH:mm'),
        location: '',
        participants: '',
        convener: '',
        info: '',
      },
    };
    const inputs = editor;

    const params: OrderLineType = {
      ...defaults,
      ...inputs,
      externalPortfolioId: selectedPortfolio.externalId,
      financialInstrument: { ...defaults.financialInstrument, instrumentGroups: groups },
    };
    dispatch(createOrderLine(params));
    setFocus();
    onClear();
  };

  const orderAndConfirm = () => {
    placeOrder();
    dispatch(push(`/customer/${customerId}/portfolio/order/preview`));
  };

  const onClear = () =>
    dispatch(
      initEditor({
        portfolioId: selectedPortfolio.portfolioId,
        isBuyingNew: true,
        isEditingExistingLine: false,
        keepMOISValues: true,
      })
    );

  const initializeState = () => {
    dispatch(
      initEditor({
        portfolioId: selectedPortfolio.portfolioId,
        isBuyingNew: true,
        isEditingExistingLine: false,
      })
    );
    dispatch(setInstrumentOptions([]));
    dispatch(setSuitabilityErrors([]));
    dispatch(hideConfirmationDialog());
  };

  const handleReturningToPreviousPortfolio = () => {
    dispatch(goBack());
    dispatch(setEditorValue('isSuitable', true));
    dispatch(hideConfirmationDialog());
  };

  const handleReturningToNotSuitableOrderFromButton = () => {
    dispatch(setIsPreviousOrderDraftSuitable(false));
    dispatch(hideConfirmationDialog());
  };

  const getConfirmationDialog = () => {
    switch (dialogType) {
      case CHANGE_PORTFOLIO_DIALOG:
        return (
          <ChangePortfolioDialog
            initializeState={initializeState}
            goBack={handleReturningToPreviousPortfolio}
            editor={editor}
            selectedPortfolio={selectedPortfolio}
            suitabilityErrors={suitabilityErrors}
          />
        );
      case CHANGE_SIDE_DIALOG:
        return (
          <ChangeSideDialog
            initializeState={initializeState}
            goBack={() => dispatch(undoChangeSide)}
            editor={editor}
            selectedPortfolio={selectedPortfolio}
            suitabilityErrors={suitabilityErrors}
            isPreviousOrderDraftSuitable={isPreviousOrderDraftSuitable}
          />
        );
      case ORDER_FROM_BUTTON_FAIL_DIALOG:
        return (
          <OrderFromButtonFailDialog
            initializeState={initializeState}
            goBack={handleReturningToNotSuitableOrderFromButton}
            editor={editor}
            selectedPortfolio={selectedPortfolio}
            suitabilityErrors={suitabilityErrors}
          />
        );
      default:
        return undefined;
    }
  };

  return (
    <Box style={{ margin: '0px', padding: '20px' }}>
      <OrderReceiveInfo
        orderInitializerType={editor[ORDER_INITIALIZER_TYPE]}
        receivedFromClientDate={editor[RECEIVED_FROM_CLIENT_DATE] || ''}
        receivedFromClientMethod={editor[RECEIVED_FROM_CLIENT_METHOD]}
        maxReceivedFromClientDate={maxReceivedFromClientDate}
        validationErrors={validationErrors}
        orderInitializerTypes={orderInitializerTypes}
        orderBasisTypes={orderBasisTypes}
        contractType={contractType}
        orderBasis={editor[BASIS_TYPE]}
        representativeSsn={editor[REPRESENTATIVE_SSN]}
        representativeName={editor[REPRESENTATIVE_NAME]}
        selectedPortfolio={selectedPortfolio}
        customerId={customerId}
        customerType={customerType}
        instrumentFormOptions={instrumentFormOptions}
      />

      <AssignmentInstrumentEditor
        customerId={customerId}
        selectedPortfolio={selectedPortfolio}
        focusRef={focusRef}
        setFocus={setFocus}
        orderBasis={editor[BASIS_TYPE]}
        suitabilityErrors={suitabilityErrors}
        testPassed={editor['testPassed']}
      />
      <MinutesOfInvestmentService
        isDiscretionary={selectedPortfolio.contractType === FULL_POWER_OF_ATTORNEY_TYPE}
        validationErrors={validationErrors}
        editorState={editor.minutesOfInvestmentService}
      />
      {isBasisOfAdviceRequired && <BasisOfAdviceField value={basisOfAdviceValue} />}
      <Checks />
      <div className="text-right" data-testkey="assignment-editor">
        <StyledButton
          size="small"
          onClick={onClear}
          style={{ margin: '1em 1em .5em 0', backgroundColor: '#ffffff' }}
          variant="contained"
        >
          {translate('order.clearValueText')}
        </StyledButton>

        <StyledButton
          size="small"
          data-testkey={isEditingExistingLine ? 'edit-instrument-button' : 'add-instrument-button'}
          disabled={!isOrderValid}
          onClick={placeOrder}
          style={{
            margin: '1em 1em .5em 0',
            backgroundColor: !isOrderValid ? 'rgba(0, 0, 0, 0.12)' : 'black',
            color: !isOrderValid ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
          }}
          variant="contained"
        >
          {isEditingExistingLine ? translate('order.saveChanges') : translate('order.addSecurity')}
        </StyledButton>

        <StyledButton
          size="small"
          data-testkey="confirm-add-instrument-button"
          disabled={confirmDisabled}
          onClick={orderAndConfirm}
          style={{
            margin: '1em 1em .5em 0',
            backgroundColor: confirmDisabled ? 'rgba(0, 0, 0, 0.12)' : 'black',
            color: confirmDisabled ? 'rgba(0, 0, 0, 0.26)' : '#ffffff',
          }}
          variant="contained"
        >
          {translate('order.confirm')}
        </StyledButton>
      </div>

      {getConfirmationDialog()}
    </Box>
  );
};
