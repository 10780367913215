import React, { Component, FocusEvent } from 'react';
import classnames from 'classnames';
import Select from 'react-select-old';
import translate from 'counterpart';
import Promise from 'bluebird';
import _ from 'lodash';
import { wrapElement } from 'core/index';
import styles from './PersonSearchQuestion.scss';
import fieldStyles from 'features/fieldList/Field.scss';
import FieldInfo from 'features/fieldList/FieldInfo';
import { getInvalidCrmPersonFields } from 'core/validations';
import { FieldType, FoundPerson } from 'types/ordersState';
import {
  Locale,
  SearchPersonResponse,
  Types,
  SearchPersonResults,
  SearchPersonOption,
  SearchPersonResult,
} from 'types/contractsState';

interface Props {
  field: FieldType;
  value: FoundPerson;
  required: boolean;
  valid: boolean | undefined;
  profileEditor: boolean | undefined;
  onBlur: (field: FieldType) => void;
  onFocus: (index: number, ev: FocusEvent<HTMLInputElement>) => void;
  openInfo: (title: string, info: string) => void;
  locale: Locale;
  index: number;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  onChange: (key: string, value: string) => void;
  types: Types;
  contractLanguage: Locale;
}

interface State {
  isDialogOpen: boolean;
  hasFocus: boolean;
}

export default class ContactIdSearchQuestion extends Component<Props, State> {
  SEARCH_MIN_INPUT: number;
  searchTimeout: number;
  previousSearchPromise: Promise<void>;
  handleFocus: (event: FocusEvent<HTMLInputElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      isDialogOpen: false,
      hasFocus: false,
    };
    this.SEARCH_MIN_INPUT = 2;
    this.searchTimeout = 0;
    this.previousSearchPromise = Promise.resolve();

    const { index, field } = this.props;
    this.handleFocus = this.onFocus.bind(this, index);
    this.handleBlur = this.onBlur.bind(this, field);
  }

  onFocus = (index: number, event: FocusEvent<HTMLInputElement>) => {
    const { onFocus } = this.props;
    this.setState({
      ...this.state,
      hasFocus: true,
    });
    if (onFocus) {
      onFocus(index, event);
    }
  };

  onBlur = (field: FieldType) => {
    const { onBlur } = this.props;
    this.setState({
      ...this.state,
      hasFocus: false,
    });

    onBlur(field);
  };

  onChange = (params: { data: FoundPerson }) => {
    function ssnToBirthDate(ssn: string) {
      if (!ssn || ssn.length !== 11) {
        return '';
      }

      let century = null;
      const centuryChar = ssn[6].toUpperCase();
      if (centuryChar === '+') {
        century = '18';
      }
      if (
        centuryChar === '-' ||
        centuryChar === 'Y' ||
        centuryChar === 'X' ||
        centuryChar === 'W' ||
        centuryChar === 'V' ||
        centuryChar === 'U'
      ) {
        century = '19';
      }
      if (
        centuryChar === 'A' ||
        centuryChar === 'B' ||
        centuryChar === 'C' ||
        centuryChar === 'D' ||
        centuryChar === 'E' ||
        centuryChar === 'F'
      ) {
        century = '20';
      }
      if (!century) {
        return '';
      }
      return century + ssn.substring(4, 6) + '-' + ssn.substring(2, 4) + '-' + ssn.substring(0, 2);
    }

    const { onChange, field } = this.props;
    if (params) {
      onChange(field.key, params.data.customerId);

      // speculatively fill related field names
      const parentKey = field.key.substring(0, field.key.lastIndexOf('.'));
      onChange(`${parentKey}.firstName`, params.data.firstName);
      onChange(`${parentKey}.lastName`, params.data.lastName);
      onChange(`${parentKey}.email`, params.data.email);
      onChange(`${parentKey}.phoneNumber`, params.data.phoneNumber);
      onChange(`${parentKey}.identityNumber`, params.data.ssn);
      if (ssnToBirthDate(params.data.ssn)) {
        onChange(`${parentKey}.birthDate`, ssnToBirthDate(params.data.ssn));
      }
    }
  };

  search = (input: string, callback: (error: null, results: SearchPersonResults) => void) => {
    window.clearTimeout(this.searchTimeout);
    this.searchTimeout = window.setTimeout(this.getOptions.bind(this, input, callback), 500);
  };

  getOptions = (input: string, callback: (error: null, results: SearchPersonResults) => void) => {
    const { crmPersonSearch } = this.props;
    this.previousSearchPromise.cancel();
    if (input.length >= this.SEARCH_MIN_INPUT) {
      this.previousSearchPromise = crmPersonSearch(input)
        .then(this.handleResponse)
        .then((json) => ({ options: json }))
        .then((results) => callback(null, results));
    } else {
      this.previousSearchPromise = Promise.resolve({ options: [] }).then((results) => callback(null, results));
    }
  };

  getLabelItem = (str: string) => (str ? `, ${str}` : '');

  getLabel = (val: SearchPersonResult) =>
    `${_.get(val, 'firstName', '')} ${_.get(val, 'lastName', '')}${this.getLabelItem(
      _.get(val, 'customerId', '')
    )}${this.getLabelItem(_.get(val, 'ssn', ''))}${this.getLabelItem(_.get(val, 'email', ''))}`;

  handleResponse = (response: SearchPersonResponse) => {
    let options: SearchPersonOption[] = [];
    if (response.result) {
      options = response.result.map((val) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const invalidFields = getInvalidCrmPersonFields(val);
        return {
          value: val.customerId,
          label: this.getLabel(val),
          disabled: invalidFields !== '',
          subLabel: `${translate('newContract.missingFieldsLabel')} ${invalidFields}`,
          data: val,
        };
      });
    }
    return options;
  };

  optionRenderer(option: SearchPersonOption) {
    return (
      <div>
        <div>{option.label}</div>
        {option.disabled ? (
          <div
            style={{
              fontStyle: 'italic',
              fontSize: '.85rem',
            }}
          >
            {option.subLabel}
          </div>
        ) : undefined}
      </div>
    );
  }

  render() {
    const { valid, profileEditor, field, openInfo, locale, required, index, value, contractLanguage } = this.props;

    let iconClass;
    if (valid === true) {
      iconClass = 'icon icon-check';
    } else if (valid === false) {
      iconClass = 'icon icon-exclamation';
    } else {
      iconClass = '';
    }

    const indicator = (
      <div className={styles.indicator}>
        <i className={iconClass} />
      </div>
    );
    const label = (
      <div className={styles.label}>
        {profileEditor ? undefined : indicator}{' '}
        {required ? `${field.title[contractLanguage]} *` : field.title[contractLanguage]}
      </div>
    );

    let component = (
      <div className={fieldStyles.componentContainer}>
        <div
          className={classnames(styles.arrayQuestionContainer, this.state.hasFocus ? styles.hasFocus : '')}
          tabIndex={index}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
        >
          <div className={fieldStyles.select}>
            <label>{label}</label>
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
            {/* @ts-expect-error */}
            <Select.Async
              loadOptions={this.search}
              onChange={this.onChange}
              value={value ? value : ''}
              ignoreAccents={false}
              placeholder={value ? value : translate('order.search', { locale: contractLanguage })}
              noResultsText={translate('newContract.noResultsText', { locale: contractLanguage })}
              clearValueText={translate('order.clearValueText')}
              searchingText={translate('order.searchingText', { locale: contractLanguage })}
              searchPromptText={field.info ? field.info[locale] : translate('order.searchPromptText')}
              loadingPlaceholder={translate('order.loadingPlaceholder')}
              optionRenderer={this.optionRenderer}
              autoload={false}
              cache={false}
              onSelectResetsInput={false}
              onBlurResetsInput={false}
              inputProps={{ 'data-testkey': field.key }}
            />
          </div>
        </div>
        {field.info ? (
          <FieldInfo
            onClick={openInfo}
            title={field.title[locale]}
            info={field.info[locale]}
            className={fieldStyles.infoBtn}
          />
        ) : undefined}
      </div>
    );

    if (!profileEditor) {
      component = wrapElement(component, styles, valid, index);
    }
    return component;
  }
}
