import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { Flex, customerHeaderHeight, fontSize, FlexAlignCenter } from 'features/common/StyledComponents';
import { getProfileImage } from 'features/profile/components/ProfileHeading';
import { Spinner } from 'features/common/Spinner';
import { RootState } from 'types/rootState';
import { AllocatorControls } from './AllocatorControls';
import { isAllocatorRoot, isPortfolioManager } from 'features/allocator/allocatorUtils';
import { Menu } from './Menu';
import { ErrorDialog } from 'features/allocator/common/ErrorDialog';
import { colors } from 'styles/colors';

interface Props {
  id?: string;
  title?: string;
}

export const Header = ({ id, title }: Props) => {
  const profile = useSelector((state: RootState) => state.profile);
  const customer = profile.customer?.toJS();
  const customerId = id || customer?.customerId;
  const allocator = isAllocatorRoot();
  const portfolioManager = isPortfolioManager();

  return (
    <Container>
      <ErrorDialog />
      <HeaderContainer>
        <Menu />

        {!!customerId && (
          <CustomerDataContainer>
            <CustomerAndTitle>
              <Customer>
                <ProfileImage src={getProfileImage(customer)} />
                <div>
                  <CustomerName>
                    {profile.isBusy ? <Spinner size={30} /> : <div>{customer?.header || 'Unknown customer'}</div>}
                  </CustomerName>
                  <CustomerId>{customer?.customerId || id}</CustomerId>
                </div>
              </Customer>
              <PageTitle>{title}</PageTitle>
            </CustomerAndTitle>

            {allocator && <AllocatorControls customerId={customerId} />}

            {portfolioManager && (
              <PTLink to={`customer/${customerId}`} data-testkey="button-to-profile-page">
                <div>{translate('portfolioManager.customerHeader.linkToPT')} »</div>
              </PTLink>
            )}
          </CustomerDataContainer>
        )}
      </HeaderContainer>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`;

const HeaderContainer = styled(Flex)`
  background: white;
  padding: 0;
  height: ${customerHeaderHeight};
  display: flex;
  align-items: center;
  justify-content: space-between;
  letter-spacing: 0.3px;
  font-weight: 900;
  box-shadow: 0 0 20px rgb(0, 0, 0, 0.03);
  position: relative;
`;

const CustomerAndTitle = styled(FlexAlignCenter)`
  height: 100%;
`;

const CustomerDataContainer = styled(Flex)`
  justify-content: space-between;
  flex: auto;
  align-items: center;
  height: 100%;
`;

const Customer = styled(Flex)`
  border-right: 1px solid hsl(0deg 0% 95%);
  padding: 0 1.5rem 0 1rem;
  align-items: center;
  height: 100%;
`;

const ProfileImage = styled.img`
  filter: grayscale();
  height: 32px;
  margin-right: 0.75rem;
  opacity: 0.6;
`;

const CustomerName = styled(Flex)`
  display: flex;
  font-size: 16px;
  line-height: 1.2;
`;

const CustomerId = styled.div`
  line-height: 1.2;
  font-size: ${fontSize.small};
  color: ${colors.dark_gray};
`;

const PageTitle = styled.div`
  position: relative;
  margin-left: 1rem;
  font-size: 20px;
  white-space: nowrap;
`;

const PTLink = styled(Link)`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1.5rem;
  color: #b19964;
  text-decoration: none;
  border-left: 1px solid #eee;

  &&:hover {
    color: #b19964;
    text-decoration: underline;
    background: none;
  }
`;
