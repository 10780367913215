import React from 'react';
import translate from 'counterpart';
import { SelectWithLabel } from './SelectWithLabel';
import { OrderInitializerType, OrderSideType } from 'types/orderDialogState';

interface Props {
  sideOptions: OrderInitializerType[];
  sideChange: (val: { value: OrderSideType }) => void;
  side: OrderSideType;
  className: string;
  isInstrumentSelected: boolean;
}

export const SelectSide = ({ sideOptions, sideChange, side, className, isInstrumentSelected }: Props) => {
  return (
    <SelectWithLabel
      options={sideOptions}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      onChange={sideChange}
      value={side}
      label={translate('order.side')}
      className={className}
      data-testkey="order-side"
      disabled={!isInstrumentSelected}
    />
  );
};
