import React, { ReactElement } from 'react';
import styled from 'styled-components';

interface Props {
  children: (ReactElement | false)[];
  className?: string;
  gap?: string;
  vertical?: boolean;
  autoColumns?: boolean;
  'data-testkey'?: string;
}

export const Grid = ({
  children,
  className,
  vertical,
  gap = '2rem',
  autoColumns = false,
  'data-testkey': dataTestKey,
}: Props) => {
  const elements = children.filter(Boolean);

  return (
    <GridContainer
      className={className}
      gap={gap}
      count={vertical ? 1 : elements.length}
      data-testkey={dataTestKey}
      auto={autoColumns}
    >
      {elements.map((c) => c)}
    </GridContainer>
  );
};

interface StyledProps {
  gap: string;
  count: number;
  auto: boolean;
}

const GridContainer = styled.div<StyledProps>`
  display: grid;
  grid-gap: ${(props) => props.gap};
  grid-template-columns: ${(props) => `repeat(${props.count}, ${props.auto ? 'auto' : '1fr'})`};
  align-content: start;
`;
