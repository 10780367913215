import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import { uniq } from 'lodash';
import { push } from 'react-router-redux';
import { colors } from 'styles/colors';
import { ALL_INSTRUMENTS_GROUP, INSTRUMENT_ASSET_CATEGORY_IDS } from 'constants/allocator';
import { AllocatorHeader } from 'features/allocator/common/AllocatorHeader';
import { SelectedAssetClasses } from './components/SelectedAssetClasses';
import { isSameInstrumentSelection } from './instrumentsUtils';
import {
  SubPageContainer,
  FlexJustifyBetween,
  subPageHeaderHeight,
  customerHeaderHeight,
} from 'features/common/StyledComponents';
import { flagCheckPortfolios } from 'features/allocator/investmentPlan/investmentPlanActions';
import { RootState } from 'types/rootState';
import { BasicInstrument, PortfolioInstrument } from 'types/instrumentsState';
import { AssetClassId } from 'types/types';
import { optimize } from 'features/weights/weightsThunks';
import { setInstrumentsSelected } from 'features/allocator/investmentPlan/investmentPlanInstrumentsActions';
import { selectCustomerId } from 'features/profile/profileSelectors';
import {
  isOptimizingPlan,
  selectAssetClasses,
  selectCurrentWeights,
  selectInstrumentsSelected,
  selectPlanInstrumentsNotInPositions,
} from 'features/allocator/allocatorSelectors';
import {
  customerHasCurrentWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';
import { PortfolioSelector } from 'features/allocator/instruments/components/PortfolioSelector';
import { Spinner } from 'features/common/Spinner';
import { resetOptimizedPortfoliosSettings } from 'features/weights/weightsSlice';
import { isSameInstrument } from 'features/portfolioManager/trade/tradeUtils';
import { useAppDispatch } from 'core/hooks';
import { selectOptimizedPortfolioIds } from 'features/allocator/planPortfolios/planPortfolioSelectors';
import { convertToAdditionalInstrumentsPayload } from 'features/weights/weightsUtils';
import { AdditionalInstrument } from 'types/existingCustomerOptimization';
import { isOptimizedPortfolio } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { AssetCategory } from 'features/allocator/instruments/components/AssetCategory';

export const InstrumentsView = () => {
  const dispatch = useAppDispatch();

  const assetClasses = useSelector(selectAssetClasses);
  const instrumentGroups = useSelector((state: RootState) => state.portfolioManager.commonData.instrumentGroups);
  const loadingInstrumentGroups = useSelector(
    (state: RootState) => state.portfolioManager.commonData.loadingInstrumentGroups
  );
  const customerId = useSelector(selectCustomerId);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const currentWeights = useSelector(selectCurrentWeights);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const instrumentsSelected = useSelector(selectInstrumentsSelected);
  const hasCurrentWeights = useSelector(customerHasCurrentWeights);
  const optimizedPortfolioIds = useSelector(selectOptimizedPortfolioIds);

  const planInstrumentsNotInPositions = useSelector(
    selectPlanInstrumentsNotInPositions({
      removeZeroWeightPlanInstruments: false,
    })
  );

  const allocatorInstruments = instrumentGroups?.find((e) => e.groupId === ALL_INSTRUMENTS_GROUP)?.instruments || [];

  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [newInstrumentsSelected, setNewInstrumentsSelected] = useState<PortfolioInstrument[]>([]);
  const [originalInstrumentsSelected, setOriginalInstrumentsSelected] = useState<PortfolioInstrument[]>([]);
  const [assetClassesSelected, setAssetClassesSelected] = useState<AssetClassId[]>([]);
  const [selectedPortfolioId, setSelectedPortfolioId] = useState<string>('');

  const getInstrumentAssetClasses = (instruments: BasicInstrument[]): AssetClassId[] => {
    const assetClasses = instruments.flatMap((i) => i.assetClasses?.flatMap((a) => a.assetClassId) || []);
    return uniq(assetClasses);
  };

  const originalAssetClassesSelected = getInstrumentAssetClasses(originalInstrumentsSelected);

  useEffect(() => {
    setAssetClassesSelected(getInstrumentAssetClasses(newInstrumentsSelected));
  }, [newInstrumentsSelected]);

  useEffect(() => {
    if (instrumentsSelected.length > 0) {
      const originalInstrumentSelection = !hasCurrentWeights
        ? instrumentsSelected
        : currentWeights?.portfolioWeights.flatMap((p) => p.instruments) || [];
      setOriginalInstrumentsSelected(originalInstrumentSelection);
      setNewInstrumentsSelected(originalInstrumentSelection);
      setAssetClassesSelected(getInstrumentAssetClasses(originalInstrumentSelection));
    }
  }, [hasCurrentWeights, instrumentsSelected]);

  useEffect(() => {
    setSelectedPortfolioId(optimizedPortfolios.filter((p) => isOptimizedPortfolio(p))[0]?.portfolioId);

    if (optimizedPortfolios.length > 0) {
      const instrumentPositions = optimizedPortfolios.flatMap((p) => p.allocatedPortfolioRows.withoutIlliquids);
      // if customer has portfolios, re-set instrument selections, because they include portfolioId for each instrument
      setOriginalInstrumentsSelected(instrumentPositions);
      setNewInstrumentsSelected(instrumentPositions);
    }
  }, [JSON.stringify(optimizedPortfolios)]);

  const handleInstrumentSelect = (instrument: BasicInstrument) => {
    const wasSelected = !!newInstrumentsSelected.find(
      (i) => i.security === instrument.security && i.portfolioId === selectedPortfolioId
    );

    const newInstrumentList = !wasSelected
      ? newInstrumentsSelected.concat([
          {
            ...instrument,
            portfolioId: selectedPortfolioId,
          } as PortfolioInstrument,
        ])
      : newInstrumentsSelected.filter(
          (e) => e.security !== instrument.security || e.portfolioId !== selectedPortfolioId
        );

    const hasChanged = !isSameInstrumentSelection(newInstrumentList, originalInstrumentsSelected);
    setIsChanged(hasChanged);
    setNewInstrumentsSelected(hasChanged ? newInstrumentList : originalInstrumentsSelected);
  };

  const discardChanges = () => {
    setIsChanged(false);
    setNewInstrumentsSelected(originalInstrumentsSelected);
    setAssetClassesSelected(getInstrumentAssetClasses(newInstrumentsSelected));
  };

  const saveChanges = () => {
    const additionalSelectedInstruments = newInstrumentsSelected.filter(
      (i) => !originalInstrumentsSelected.find((j) => isSameInstrument(i, j))
    ) as AdditionalInstrument[];

    const optimizedPlanInstrumentsNotInPositions = planInstrumentsNotInPositions.filter((i) =>
      optimizedPortfolioIds.includes(i.portfolioId)
    ) as AdditionalInstrument[];

    const allAdditionalInstruments = additionalSelectedInstruments.concat(optimizedPlanInstrumentsNotInPositions);

    dispatch(setInstrumentsSelected(newInstrumentsSelected));
    dispatch(flagCheckPortfolios(true));
    dispatch(
      optimize({
        payload: hasCurrentWeights
          ? {
              additionalInstruments: convertToAdditionalInstrumentsPayload(allAdditionalInstruments),
            }
          : {
              securitiesAvailable: newInstrumentsSelected.map((i) => i.security),
            },
      })
    );

    if (!hasCurrentWeights) {
      dispatch(resetOptimizedPortfoliosSettings());
    }
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/`));
  };

  return (
    <SubPageContainer>
      <AllocatorHeader
        headerLabel={translate('allocator.instrumentsView.instrumentsViewTitle')}
        discardChanges={discardChanges}
        hasChanged={isChanged}
        customerId={customerId}
        saveChanges={saveChanges}
      />

      <Content>
        <InstrumentListContainer>
          {optimizingPlan ? (
            <Spinner />
          ) : (
            optimizedPortfolios.length > 0 && (
              <Portfolios>
                <h2>{translate('allocator.instrumentsView.choosePortfolio')}</h2>
                {optimizedPortfolios.map((portfolio) => (
                  <PortfolioSelector
                    key={portfolio.portfolioId}
                    portfolio={portfolio}
                    setSelectedPortfolioId={setSelectedPortfolioId}
                    selectedPortfolioId={selectedPortfolioId}
                    instrumentsSelected={newInstrumentsSelected}
                  />
                ))}
              </Portfolios>
            )
          )}

          {loadingInstrumentGroups ? (
            <Spinner />
          ) : (
            INSTRUMENT_ASSET_CATEGORY_IDS.map((assetCategoryId) => (
              <AssetCategory
                key={assetCategoryId}
                assetCategoryId={assetCategoryId}
                allocatorInstruments={allocatorInstruments}
                originalAssetClassesSelected={originalAssetClassesSelected}
                handleInstrumentSelect={handleInstrumentSelect}
                selectedPortfolioId={selectedPortfolioId}
                newInstrumentsSelected={newInstrumentsSelected}
                originalInstrumentsSelected={originalInstrumentsSelected}
              />
            ))
          )}
        </InstrumentListContainer>

        <DistributionContainer>
          <SelectedAssetClasses assetClasses={assetClasses} assetClassesSelected={assetClassesSelected} />
        </DistributionContainer>
      </Content>
    </SubPageContainer>
  );
};

const Content = styled(FlexJustifyBetween)`
  align-items: flex-start;
`;

const InstrumentListContainer = styled.div`
  width: 70%;
`;

const DistributionContainer = styled.div`
  position: sticky;
  top: calc(1rem + ${subPageHeaderHeight}px + ${customerHeaderHeight});
  right: 0;
  width: 25%;
  align-self: flex-start;
`;

const Portfolios = styled.div`
  grid-template-columns: auto;
  justify-items: flex-start;
  align-items: center;
  grid-gap: 0 2rem;
  margin-bottom: 1rem;
  z-index: 2;
  background: ${colors.gray_light};
`;
