import { createStore, compose, applyMiddleware, Store } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { loadUser } from 'redux-oidc';
import { History } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from 'reducers/index';
import { clientMiddleware } from './clientMiddleware';
import { ApiClient } from 'core/ApiClient';
import { userManager } from 'core/userManager';
import { errorMiddleware } from './errorMiddleware';
import { selectIsUserSessionExpired } from 'features/oidc-callback/oidcSelectors';
import { sessionMiddleware } from './sessionMiddleware';
import { LastUsers } from 'types/searchState';
import { config } from 'config';

declare global {
  interface Window {
    Cypress?: unknown;
    store?: Store;
  }
}

let store: Store;

export const configureStore = (history: History, initialState: LastUsers) => {
  const client = new ApiClient();
  const reduxRouterMiddleware = routerMiddleware(history);
  const middlewares = [errorMiddleware, thunk, sessionMiddleware, reduxRouterMiddleware, clientMiddleware(client)];

  const composeEnhancers =
    composeWithDevTools({
      trace: config.enableReduxTrace,
      traceLimit: 25,
    }) || compose;

  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(...middlewares))(createStore);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  store = createStoreWithMiddleware(rootReducer, initialState);

  loadUser(store, userManager)
    .then(() => {
      const locationBeforeTransitions = store.getState()?.routing?.locationBeforeTransitions;
      const redirectUrl = `${locationBeforeTransitions?.pathname}${locationBeforeTransitions?.search}`;
      const sessionExpired = selectIsUserSessionExpired(store.getState());

      if (sessionExpired && !redirectUrl?.includes('callback')) {
        userManager
          .signinRedirect({
            data: { redirectUrl },
          })
          .catch((error) => {
            console.error(error); // eslint-disable-line no-console
          });
      }
    })
    .catch((error) => {
      console.error(error); // eslint-disable-line no-console
    });

  if (window.Cypress) {
    window.store = store;
  }

  return store;
};

// Use this only if absolutely necessary
// Generally you want to access state using thunk actions
export const getStore = (): Store => {
  return store;
};
