import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { Flex, FlexAlignCenter } from 'features/common/StyledComponents';
import { getGeographicalValues } from 'features/portfolioManager/commonData/commonDataUtils';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { Card } from 'features/allocator/common/Card';
import { OptimizationModelAssetClassGroup, optimizationModelWithWeights } from 'features/allocator/optimizationModel';
import { colors, ASSET_CATEGORY_COLORS, GROUPED_ASSET_CLASSES_COLORS } from 'styles/colors';
import { otherAssetCategory, WEIGHT_LIMIT } from 'constants/allocator';
import { RootState } from 'types/rootState';
import { GeographicalValues } from 'features/portfolioManager/commonData/commonDataTypes';
import { clearError, setError } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import { Spinner } from 'features/common/Spinner';
import { Weights } from 'types/weightsState';
import { selectOptimizedWeightsForRiskLevel } from 'features/allocator/allocatorSelectors';
import { Dot } from 'features/allocator/common/Dot';
import { DistributionPie } from 'features/allocator/riskSelection/components/DistributionPie';
import { useAppDispatch } from 'core/hooks';

interface Props {
  weights: Weights;
  showDifference: boolean;
}

export const CustomerSummaryAssetClasses = ({ weights, showDifference = false }: Props) => {
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);

  const dispatch = useAppDispatch();
  const [loadingGeographicalValues, setLoadingGeographicalValues] = useState<boolean>(false);
  const token = useSelector((state: RootState) => state.oidc.user.access_token);

  const [geographicalValues, setGeographicalValues] = useState<GeographicalValues[]>([]);

  useEffect(() => {
    const getValues = async () => {
      try {
        dispatch(clearError(errorKeys.geographicalValues));
        setLoadingGeographicalValues(true);
        const data = await getGeographicalValues(token);
        setGeographicalValues(data.geographies);
      } catch (error) {
        dispatch(setError({ context: errorKeys.geographicalValues }));
      } finally {
        setLoadingGeographicalValues(false);
      }
    };
    void getValues();
  }, []);

  const assetCategoryWeights = weights.assetCategoryWeights;
  const portfolioReturn = weights.returnStatistics.portfolioReturn;
  const portfolioVolatility = weights.returnStatistics.portfolioVolatility;

  const getAssetClassGroupWeight = (group: OptimizationModelAssetClassGroup) => {
    const assetClassWeights = assetCategoryWeights.flatMap((i) => i.assetClasses);
    return group.assetClasses?.reduce((acc, assetClass) => {
      const assetClassWeight = assetClassWeights?.find((i) => i.assetClassId === assetClass.assetClassId)?.weight;
      return acc + (assetClassWeight || 0);
    }, 0);
  };

  const getOptimizedAssetClassGroupWeight = (group: OptimizationModelAssetClassGroup) => {
    const assetClassWeights = optimizedWeights.withoutIlliquids.assetCategoryWeights
      .find((a) => a.name === group.assetCategory)
      ?.assetClasses.filter((a) => group.assetClasses.flatMap((i) => i.assetClassId).includes(a.assetClassId));

    return assetClassWeights?.reduce((acc, assetClass) => acc + (assetClass?.weight || 0), 0) || 0;
  };

  const getAssetClassGroupValues = (group: OptimizationModelAssetClassGroup) => {
    const assetClassValues = geographicalValues?.find(
      (a) => a.majorCategory === group.assetCategory && a.minorCategory === group.name
    );
    return {
      yield: assetClassValues?.expectedReturn || 0,
      vol: assetClassValues?.volatility || 0,
    };
  };

  const maxWeight =
    Math.max(
      ...assetCategoryWeights.concat(optimizedWeights.withoutIlliquids.assetCategoryWeights).flatMap((a) => a.weight)
    ) || 1;

  return (
    <Container data-testkey="managed-portfolios">
      <h2>{translate('allocator.summaryView.assetClassDistribution')}</h2>
      <Table>
        <thead>
          <tr>
            <td />
            <td />
            <td>
              <label>{translate('allocator.summaryView.weight')}</label>
            </td>
            <td />
            <td />
            <td>
              <label>{translate('allocator.summaryView.expectedReturn')}</label>
            </td>
            <td>
              <label>{translate('allocator.summaryView.expectedVolatility')}</label>
            </td>
          </tr>
        </thead>

        {optimizationModelWithWeights.concat(otherAssetCategory).map((assetCategory) => {
          const assetCategoryWeight = assetCategoryWeights.find((i) => i.name === assetCategory.name)?.weight || 0;
          const optimizedAssetCategoryWeight =
            optimizedWeights.withoutIlliquids.assetCategoryWeights.find((a) => a.name === assetCategory.name)?.weight ||
            0;

          if (assetCategoryWeight < WEIGHT_LIMIT) {
            return null;
          }

          const difference = optimizedAssetCategoryWeight - assetCategoryWeight;

          return (
            <React.Fragment key={assetCategory.name}>
              <tbody>
                <tr>
                  <td rowSpan={5}>
                    {assetCategory.name !== 'Muut' && (
                      <StyledDistributionPie
                        weights={assetCategoryWeights.filter((a) => a.name === assetCategory.name)}
                        sections="assetClassGroups"
                      />
                    )}
                  </td>
                  <th>
                    <Left>{assetCategory.name}</Left>
                  </th>
                  <th>
                    <div>{formatToDecimals(assetCategoryWeight, 1, '%')}</div>
                  </th>
                  <th>
                    {!!optimizedAssetCategoryWeight && showDifference && (
                      <Percentage difference={difference}>
                        {formatToDecimals(difference, 1, '%').replace('-', '–')}
                      </Percentage>
                    )}
                  </th>
                  <th>
                    <BarContainer>
                      <Bar width={assetCategoryWeight / maxWeight} color={ASSET_CATEGORY_COLORS[assetCategory.name]} />
                      {showDifference && (
                        <Bar width={optimizedAssetCategoryWeight / maxWeight} color={colors.med_gray} />
                      )}
                    </BarContainer>
                  </th>
                  <th />
                  <th />
                </tr>
                {assetCategory.assetClassGroups.map((group) => {
                  const groupWeight = getAssetClassGroupWeight(group);
                  const groupValues = getAssetClassGroupValues(group);
                  const optimizedGroupWeight = getOptimizedAssetClassGroupWeight(group);

                  if (!groupWeight || groupWeight < WEIGHT_LIMIT) {
                    return null;
                  }

                  const groupWeightDifference = optimizedGroupWeight - groupWeight;

                  return (
                    <tr key={group.name}>
                      <td>
                        <FlexAlignCenter>
                          <Dot bg={GROUPED_ASSET_CLASSES_COLORS[group.groupId]} />
                          <Left>{group.name}</Left>
                        </FlexAlignCenter>
                      </td>

                      <td>
                        <div>{formatToDecimals(groupWeight, 1, '%')}</div>
                      </td>

                      <td>
                        {!!optimizedGroupWeight && showDifference && (
                          <Percentage difference={groupWeightDifference}>
                            {formatToDecimals(groupWeightDifference, 1, '%').replace('-', '–')}
                          </Percentage>
                        )}
                      </td>
                      <td>
                        <BarContainer>
                          <Bar width={groupWeight / maxWeight} color={GROUPED_ASSET_CLASSES_COLORS[group.groupId]} />
                          {showDifference && <Bar width={optimizedGroupWeight / maxWeight} color={colors.med_gray} />}
                        </BarContainer>
                      </td>

                      <td>
                        {!groupValues.yield && loadingGeographicalValues && <Spinner size={20} />}
                        {groupValues.yield > 0 && formatToDecimals(groupValues.yield, 1, '%')}
                      </td>
                      <td>
                        {!groupValues.vol && loadingGeographicalValues && <Spinner size={20} />}
                        {groupValues.vol > 0 && (
                          <span data-testkey="group-volatility">{formatToDecimals(groupValues.vol, 1, '%')}</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </React.Fragment>
          );
        })}

        <thead>
          <tr>
            <td />
            <th>
              <Left>{translate('allocator.summaryView.totalPortfolio')}</Left>
            </th>
            <th>100 %</th>
            <th />
            <th />
            <th>{formatToDecimals(portfolioReturn, 1, '%')}</th>
            <th>{formatToDecimals(portfolioVolatility, 1, '%')}</th>
          </tr>
        </thead>
      </Table>
    </Container>
  );
};

const Container = styled(Card)`
  grid-area: assetClasses;
  box-shadow: none;

  td,
  th {
    padding: 0 0.5rem;

    &:not(:first-child) {
      text-align: right;
    }
  }

  th {
    padding-top: 1rem;
    border-bottom: 1px solid ${colors.gray_inactive};
    font-size: 18px;
    font-weight: 900;
  }

  label {
    white-space: normal;
  }
`;

const Table = styled.table`
  border-spacing: 0 5px;
  border-collapse: separate;
`;

const Left = styled.div`
  text-align: left;
`;

const BarContainer = styled(Flex)`
  flex-direction: column;
  width: 100px;
  justify-content: center;
  border-left: 1px solid ${colors.med_gray};
  height: 1.75rem;

  > *:nth-child(2) {
    height: 4px;
    margin-top: 2px;
  }
`;

interface BarProps {
  color: string;
  width: number;
}

const Bar = styled.div<BarProps>`
  width: ${(props) => props.width * 100}%;
  height: 12px;

  background: ${(props) => props.color};
`;

const Percentage = styled.div<{ difference: number }>`
  font-size: 12px;
  font-weight: 700;
  line-height: 1;

  ${(props) => props.difference > 0 && `color: ${props.color || colors.green}`};
  ${(props) => props.difference < 0 && `color: ${props.color || colors.red}`};

  &:before {
    ${(props) => props.difference >= WEIGHT_LIMIT && 'content: "+"'};
  }
`;

const StyledDistributionPie = styled(DistributionPie)`
  margin-top: 1.5rem;
  margin-right: 1rem;
  width: 100px;
`;
