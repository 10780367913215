import React, { CSSProperties } from 'react';

const headerStyle = {
  fontSize: '15px',
  margin: '0 0 0 0',
  padding: '0.5rem 0',
  borderBottom: '1px solid #d4cdbe',
  fontWeight: 700,
  textTransform: 'uppercase',
  fontFamily: 'Calibre',
} as CSSProperties;

export const SummarySectionHeader = ({ text, style }: { text: string; style?: CSSProperties }) => (
  <h3 style={{ ...headerStyle, ...style }}>{text}</h3>
);
