import React from 'react';
import { branch, renderNothing } from 'recompose';
import translate from 'counterpart';
import { SelectionItem } from './SelectionItem';
import { ImmutableSelectedContracts, AvailableTemplate } from 'types/contractsState';

interface Props {
  templates: AvailableTemplate[];
  selected: ImmutableSelectedContracts;
  customerId: string;
  isLoadingTemplate: (contractType: string) => boolean;
  selectContract: (contractId: string, customerId: string) => void;
  groupId: string;
  language: string;
}

const SelectionGroup = ({
  templates,
  selected,
  customerId,
  isLoadingTemplate,
  selectContract,
  groupId,
  language,
}: Props) => {
  const components = templates.map((contract) => {
    const isSelected = selected.get(contract.id);
    const icon = isSelected ? 'icon icon-check' : 'icon icon-plus';
    const elemClass = isSelected ? 'selected' : '';
    const name = contract.names && contract.names.length > 1 && language === 'sv' ? contract.names[1] : contract.name;
    return (
      <SelectionItem
        key={contract.id}
        className={elemClass}
        onClick={selectContract}
        contractId={contract.id}
        customerId={customerId}
        icon={icon}
        name={name}
        isLoading={isLoadingTemplate(contract.id)}
      />
    );
  });

  return (
    <>
      <h6 style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{translate(`newContract.${groupId}`)}</h6>

      <ul className="row">{components}</ul>
      <hr />
    </>
  );
};

export default branch(({ templates }: Props) => templates.length === 0, renderNothing)(SelectionGroup);
