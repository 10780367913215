import { CONSULTATIVE_AGREEMENT, FULL_POWER_OF_ATTORNEY } from './contractNames';

export const PRO_ENG_PT = 'PRO_ENG_PT';
export const PRO_PT = 'PRO_PT';
export const CONFINT_PT = 'CONFINT_PT';

export const EXTRA_ORDER_CONFIRMATION_MAP: ConfirmationMap = {
  [PRO_ENG_PT]: [CONSULTATIVE_AGREEMENT],
  [PRO_PT]: [CONSULTATIVE_AGREEMENT],
  [CONFINT_PT]: [CONSULTATIVE_AGREEMENT, FULL_POWER_OF_ATTORNEY],
};

interface ConfirmationMap {
  [index: string]: string[];
}
