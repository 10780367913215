import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import styles from './Status.scss';

interface Props {
  label: string;
  completed: number;
  total: number;
}

const Status = ({ label, completed, total }: Props) => {
  const isNonAnswerableContract = completed === 0 && total === 0;
  const text = isNonAnswerableContract ? '' : `${completed} / ${total}`;
  let progress;
  if (isNonAnswerableContract) {
    progress = 100;
  } else {
    progress = total === 0 ? 100 : (completed / total) * 100;
  }

  return (
    <section className={styles.status}>
      <header>
        <span>{isNonAnswerableContract ? 'Valmis' : label}</span>
        <span>{text}</span>
      </header>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={[
          {
            '&.MuiLinearProgress-root': {
              backgroundColor: 'gray',
            },
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#00eb64',
            },
          },
        ]}
      />
    </section>
  );
};

export default Status;
