import translate from 'counterpart';
import { AppThunk } from 'types/types';
import { apiCall } from 'core/apiCall';
import { setError } from 'features/errors/errorActions';
import {
  InitializeCreatePortfoliosState,
  CreatePortfoliosSuccess,
  CreatePortfoliosFailure,
} from 'features/newCustomerSteps/newCustomerStepsActions.types';
import { GetPortfoliosStatusResponse, CreatePortfoliosResponse, PortfolioResponse } from 'types/newCustomerStepsState';
import {
  getAdditionalContracts,
  portfolioContractIdToName,
  getFinishedBasicInfoContract,
} from './newCustomerStepsUtils';
import { validationErrorMapper } from 'core/validationErrorMapper';

export const INITIALIZE_CREATE_PORTFOLIOS_STATE = 'INITIALIZE_CREATE_PORTFOLIOS_STATE';
export const CREATE_PORTFOLIOS_SUCCESS = 'CREATE_PORTFOLIOS_SUCCESS';
export const CREATE_PORTFOLIOS_FAILURE = 'CREATE_PORTFOLIOS_FAILURE';

const initializeInvestmentPlanState = (): InitializeCreatePortfoliosState => {
  return {
    type: INITIALIZE_CREATE_PORTFOLIOS_STATE,
  };
};

const createPortfoliosSuccess = (portfolioResponseDto: PortfolioResponse[]): CreatePortfoliosSuccess => {
  return {
    type: CREATE_PORTFOLIOS_SUCCESS,
    payload: portfolioResponseDto,
  };
};

const createPortfoliosFailure = (
  validationErrors: string[],
  portfolioResponses?: PortfolioResponse[]
): CreatePortfoliosFailure => {
  return {
    type: CREATE_PORTFOLIOS_FAILURE,
    payload: { validationErrors, portfolioResponses },
  };
};

export const createPortfolios = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.oidc.user.access_token;
    const customerId = state.profile.customer.toJS().customerId;
    const profileContracts = state.profile.contracts.toJS();
    const drafts = state.profile.draft?.toJS().content;
    const templates = state.contracts.availableTemplates.toJS();
    const investmentPlan = state.portfolioManager.investmentPlan;

    const basicInfoContract = getFinishedBasicInfoContract(profileContracts);
    const requiredContracts = getAdditionalContracts(investmentPlan, templates, profileContracts, drafts);

    const portfolioRequests = investmentPlan.planPortfolios
      .map((portfolio) => {
        const contract = requiredContracts.find(
          (requiredContract) =>
            requiredContract.shortName ===
            portfolioContractIdToName[portfolio.manualSettings?.contractType || 'Tuntematon']
        );

        return {
          portfolioType: portfolio.manualSettings?.contractType,
          contractId: contract?.contractId,
        };
      })
      .filter((request) => request.portfolioType !== undefined || request.contractId !== undefined);

    const payload = {
      customerId,
      basicInfoContractId: basicInfoContract?.shortId,
      portfolioRequests,
    };

    let response;
    try {
      response = (await apiCall({
        method: 'post',
        token,
        path: '/api/v1/allocator/portfolios',
        data: payload,
      })) as CreatePortfoliosResponse;
    } catch (error) {
      dispatch(
        setError({ error: error?.message || translate('errors.createPortfoliosStatus'), context: 'newCustomerSteps' })
      );
      dispatch(initializeInvestmentPlanState());
      return;
    }

    const portfolioResponseDtos = response?.openAllocatorServicesResponse?.portfolioResponseDtos || [];
    const validationResult = CreatePortfoliosResponse.decode(response);
    const errors = validationErrorMapper(validationResult) || [];
    if (errors.length || !portfolioResponseDtos.length) {
      dispatch(createPortfoliosFailure(errors, portfolioResponseDtos));
      return;
    }

    dispatch(createPortfoliosSuccess(portfolioResponseDtos));
  };
};

export const getPortfoliosStatus = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const token = state.oidc.user.access_token;
    const customerId = state.profile.customer.toJS().customerId;

    let response;
    try {
      response = (await apiCall({
        method: 'get',
        token,
        path: `/api/v1/allocator/portfolios?customerId=${customerId}`,
      })) as GetPortfoliosStatusResponse;
    } catch (error) {
      dispatch(setError({ context: 'fetchPortfoliosStatus' }));
      dispatch(initializeInvestmentPlanState());
      return;
    }

    const portfolioResponseDtos = response?.portfolioResponseDtos || [];
    const validation = GetPortfoliosStatusResponse.decode(response);
    const errors = validationErrorMapper(validation) || [];

    if (errors.length || !portfolioResponseDtos.length) {
      dispatch(createPortfoliosFailure(errors, portfolioResponseDtos));
      return;
    }

    dispatch(createPortfoliosSuccess(portfolioResponseDtos));
  };
};
