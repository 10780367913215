import React from 'react';
import translate from 'counterpart';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  hideConfirmDialog: () => void;
  navigate: () => void;
  isOpen: boolean;
}

export const ConfirmationDialog = ({ hideConfirmDialog, navigate, isOpen }: Props) => {
  const actions = [
    <StyledButton variant="text" onClick={navigate} key={'order.switchPortfolio'}>
      {translate('order.switchPortfolio')}
    </StyledButton>,
    <StyledButton variant="text" onClick={hideConfirmDialog} key={'order.stayInCurrentPortfolio'}>
      {translate('order.cancel')}
    </StyledButton>,
  ];

  return (
    <ModalDialog
      title={translate('order.unsavedChanges')}
      open={isOpen}
      actions={actions}
      content={translate('order.incompleteInfo')}
    />
  );
};
