import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { RootState } from 'types/rootState';
import { FlexAlignCenter, FlexJustifyBetween } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { hasRiskConflict, selectRisk } from 'features/allocator/allocatorSelectors';
import { Grid } from 'features/allocator/common/Grid';
import { Card } from 'features/allocator/common/Card';
import { Spinner } from 'features/common/Spinner';
import { selectIsLoadingCurrentWeightsDistribution } from 'features/weights/weightsSelectors';

export const RiskDeviation = () => {
  const targetRiskLevel = useSelector(selectRisk);
  const actualRisk = useSelector(
    (state: RootState) => state.portfolioManager.weights.currentWeights?.returnStatistics?.riskFloat
  );

  const loadingCurrent = useSelector(selectIsLoadingCurrentWeightsDistribution);

  const riskConflict = useSelector(hasRiskConflict);

  const contractRisk = useSelector((state: RootState) => state.profile.customer.toJS().selectedRiskLevel);
  const allocatorRisk = useSelector((state: RootState) => state.portfolioManager.investmentPlan.targetRiskLevel);

  const loading = !targetRiskLevel || loadingCurrent;

  return (
    <Card>
      <h2>{translate('portfolioManager.overview.risk')}</h2>

      {loading && <Spinner size={30} />}

      {!loading && riskConflict && (
        <RiskConflict data-testkey="risk-number-conflict">
          <li>
            {translate('allocator.riskConflict.plan')}: {contractRisk}
          </li>
          <li>
            {translate('allocator.riskConflict.contract')}: {allocatorRisk}
          </li>
        </RiskConflict>
      )}

      {!loading && !riskConflict && (
        <RiskGrid gap="1rem" vertical>
          <Row>
            <span>{translate('portfolioManager.overview.riskTarget')}</span>
            <RiskNumber>{targetRiskLevel}</RiskNumber>
          </Row>
          <Row>
            <span>{translate('portfolioManager.overview.actualRisk')}</span>
            <b>
              {actualRisk?.toLocaleString('fi', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
              }) || '?'}
            </b>
          </Row>
        </RiskGrid>
      )}
    </Card>
  );
};

const RiskConflict = styled.ul`
  font-weight: 700;
  color: ${colors.red};
  clear: both;

  b {
    font-weight: 900;
  }

  li {
    padding: 0;
  }
`;

const RiskGrid = styled(Grid)`
  align-items: center;
`;

const Row = styled(FlexJustifyBetween)`
  align-items: center;

  & > *:last-child {
    width: 2rem;
    text-align: center;
  }
`;

const RiskNumber = styled(FlexAlignCenter)`
  display: inline-flex;
  font-weight: 900;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 3px solid ${colors.primary};
`;
