import { save, update, getLastSearchedUsers } from 'core/lastSearchedUsers';
import PromiseStore from 'core/PromiseStore';
import { AppThunk, CustomerSearchResults, User } from 'types/types';

/*
 * action types
 */
export const SEARCH_CUSTOMERS_REQUEST = 'SEARCH_CUSTOMERS_REQUEST';
export const SEARCH_CUSTOMERS_RECEIVE = 'SEARCH_CUSTOMERS_RECEIVE';
export const SEARCH_CUSTOMERS_FAILURE = 'SEARCH_CUSTOMERS_FAILURE';
export const SEARCH_CLEAR = 'SEARCH_CLEAR';
export const SEARCH_CHANGE = 'SEARCH_CHANGE';
export const SEARCH_VISIBILITY_FILTER = 'SEARCH_VISIBILITY_FILTER';
export const LAST_USERS_CHANGE = 'LAST_USERS_CHANGE';

/*
 * action creators
 */
export const searchCustomers = (query: string) =>
  <const>{
    type: SEARCH_CHANGE,
    query,
  };

export const searchClear = () =>
  <const>{
    type: SEARCH_CLEAR,
  };

export const searchVisibilityFilter = (filter: boolean) =>
  <const>{
    type: SEARCH_VISIBILITY_FILTER,
    filter,
  };

export const lastUsersChange = (users: User[]) =>
  <const>{
    type: LAST_USERS_CHANGE,
    lastUsers: users,
  };

/*
 * async actions
 */

export function searchCustomersRequest(query: string, managerId: string | null): AppThunk {
  return (dispatch, getState) => {
    dispatch(customerSearchRequest());

    // handlers of the previous request must not be called
    PromiseStore.search.cancel();

    PromiseStore.search = PromiseStore.createCancellableRequest(
      `/api/v1/search/customers?text=${encodeURIComponent(query)}`,
      getState().oidc.user.access_token
    )
      .then((result) => {
        dispatch(customerSearchSuccess(result as CustomerSearchResults, managerId));
      })
      .catch(() => {
        dispatch(customerSearchFailure());
      });

    return PromiseStore.search;
  };
}

const customerSearchRequest = () =>
  <const>{
    type: SEARCH_CUSTOMERS_REQUEST,
  };

const customerSearchSuccess = (results: CustomerSearchResults, managerId: string | null) =>
  <const>{
    type: SEARCH_CUSTOMERS_RECEIVE,
    result: results,
    managerId,
  };

const customerSearchFailure = () =>
  <const>{
    type: SEARCH_CUSTOMERS_FAILURE,
  };

export function addLastSearched(user: User): AppThunk {
  return (dispatch) => {
    save(user);
    const users = getLastSearchedUsers();
    dispatch(lastUsersChange(users));
  };
}

export function updateLastSearched(user: User): AppThunk {
  return (dispatch) => {
    update(user);
    const users = getLastSearchedUsers();
    dispatch(lastUsersChange(users));
  };
}

export type SearchCustomersAction = ReturnType<
  | typeof searchCustomers
  | typeof searchClear
  | typeof searchVisibilityFilter
  | typeof lastUsersChange
  | typeof customerSearchRequest
  | typeof customerSearchSuccess
  | typeof customerSearchFailure
>;
