import React from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { OrderLinesSummary } from 'features/orderLines/components/OrderLinesSummary';
import { selectContractOrderLines } from 'features/orderLines/orderLinesSelectors';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';

export const OrderLines = () => {
  const orderLines = useSelector(selectContractOrderLines);
  const portfolioDetailsById = useSelector(selectPortfolioDetailsById);

  return (
    <OrderLinesSummary
      orderLines={orderLines.toJS()}
      portfolioDetailsById={portfolioDetailsById}
      header={translate('order.ordersOfNewFunds')}
      editable={true}
    />
  );
};
