import React from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import { Constraint } from 'types/constraintsState';
import { ConstraintSlider } from 'features/allocator/constraints/components/ConstraintSlider';
import { ConstraintType } from 'types/types';
import { FlexAlignCenter } from 'features/common/StyledComponents';

interface Props {
  constraint: Constraint;
  constraintType: ConstraintType;
  optimizationAssetConstraintsModified: string[];
  onUpdate: (updatedConstraint: Constraint) => boolean;
}

export const OptimizationConstraintRow = ({
  constraint,
  constraintType,
  optimizationAssetConstraintsModified,
  onUpdate,
}: Props) => {
  return (
    <Collapsible
      key={constraint.id}
      triggerElementProps={{ 'data-testkey': `${constraint.id}-slider-collapse-trigger` }}
      contentOuterClassName={'assetCategoryList'}
      trigger={constraint.label}
      triggerTagName={'div'}
      triggerClassName={`rowLabel trigger-${constraint.id}`}
      triggerOpenedClassName={'rowLabel'}
      triggerDisabled={constraintType === 'contract'}
      transitionTime={200}
      open={optimizationAssetConstraintsModified.includes(constraint.id)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      triggerSibling={() => (
        <ConstraintSlider
          type="assetCategory"
          value={[constraint.min, constraint.max]}
          updatedItemId={constraint.id}
          constraint={constraint}
          onUpdate={onUpdate}
          constraintType={constraintType}
        />
      )}
    >
      {constraintType === 'optimization' &&
        constraint.items.map((item: Constraint) => (
          <CollapsedContainer key={item.id}>
            <RowLabel>
              <StyledSubRowLabel>{item.label}</StyledSubRowLabel>
            </RowLabel>
            <ConstraintSlider
              type="assetClass"
              value={[item.min, item.max]}
              updatedItemId={item.id}
              constraint={constraint}
              onUpdate={onUpdate}
              constraintType={constraintType}
            />
          </CollapsedContainer>
        ))}
    </Collapsible>
  );
};

const labelWidth = 300;

const CollapsedContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RowLabel = styled(FlexAlignCenter)`
  width: ${labelWidth}px;
`;

const StyledSubRowLabel = styled.p`
  margin-left: 3rem;
  white-space: normal;
`;
