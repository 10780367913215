export default {
  answered: 'Vastattu',
  clientId: 'Asiakastunnus',
  clientName: 'Asiakkaan nimi',
  close: 'Sulje',
  continue: 'Jatka',
  contract: 'Sopimus',
  contracts: 'Sopimukset',
  noContracts: 'Ei sopimuksia',
  contractAttachments: 'Sopimuksen liitteet',
  contractStatus: 'Sopimuksen tila',
  contractPreview: 'Sopimuksen esikatselu',
  contractPreviewError: 'PDF:n muodostaminen epäonnistui',
  creator: 'Sopimuksen tekijä',
  date: 'Päivämäärä',
  id: 'Tunnus',
  moduleDone: 'Osio on valmis',
  notes: 'Muistiinpanot',
  pcs: 'Kpl',
  preview: 'Esikatsele',
  ready: 'Valmis',
  relatingContracts: 'Asiakkaan muut sopimukset',
  searchPlaceholder: 'Hae sopimustunnuksella, asiakastunnuksella tai asiakkaan nimellä',
  sent_for_banker_approval: 'Pankkiiri hyväksyy',
  sent_for_ink_signing: 'Näytölle',
  sent_for_manual_signing: 'Manuaalinen',
  sent_for_signing: 'Pankkitunnukset',
  signatoryWarning: 'Sopimusta ei voi allekirjoittaa - allekirjoittajan tiedoissa on virheitä.',
  status: 'Tila',
  lockedHeader: 'Sopimus on jo luotu, palaa etusivulle',
  lockedMessage: 'Mikäli haluat hylätä juuri luomasi sopimuksen ota yhteyttä sijoitusasiantuntijaan.',
  back: 'Palaa',
  signatoriesError: 'Lähetys epäonnistui',
  saveAndNavigate: 'Tallenna ja siirry etusivulle',
  toFrontPageTitle: 'Tallentamattomia muutoksia',
  toFrontPageContent: 'Keskeneräisissä sopimuksissa on tallentamattomia muutoksia.',
  webshop: 'Verkkokauppa',
  signerAmount: 'Allekirjoittajien määrä',
};
