import React from 'react';
import classnames from 'classnames/bind';
import styles from './SubNav.scss';

interface Props {
  label: string;
  onNextClick: () => void;
  actionLabel: string;
  actionDisabled: boolean;
  actionHidden: boolean;
}

export default class Nav extends React.Component<Props> {
  static defaultProps = {
    actionLabel: 'Jatka',
    actionDisabled: false,
    actionHidden: false,
  };

  constructor(props: Props) {
    super(props);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.cx = classnames.bind(styles);
  }

  render() {
    const { label, onNextClick, actionLabel, actionDisabled, actionHidden } = this.props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const classes = this.cx('button', { disabled: actionDisabled });

    let action;
    if (onNextClick && !actionHidden) {
      action = (
        <button onClick={onNextClick} className={classes} data-testkey="button-continue">
          {actionLabel}
        </button>
      );
    }

    return (
      <header className={`${styles.nav} row align-justify`}>
        <div className="column">
          <h4>{label}</h4>
        </div>
        <div>{action}</div>
      </header>
    );
  }
}
