import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { ManualSetting } from 'types/types';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  selectCurrentWeights,
  selectOptimizedWeightsForRiskLevel,
  selectPortfolioName,
} from 'features/allocator/allocatorSelectors';
import { Flex, FlexJustifyBetween } from 'features/common/StyledComponents';
import { SpacerCell } from './TradeRow';
import { RootState } from 'types/rootState';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import {
  getFormattedPortfolioWeight,
  getPortfolioSettings,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { colors } from 'styles/colors';
import { getPortfolioErrors } from 'features/portfolioManager/trade/tradeUtils';
import { selectCustomer } from 'features/profile/profileSelectors';

interface Props {
  portfolio: AllocatorPortfolio;
}

export const TradePortfolioHeader = ({ portfolio }: Props) => {
  const currentWeights = useSelector(selectCurrentWeights);
  const portfolioDetailsById = useSelector((state: RootState) => state.portfolio.portfolioDetailsById);
  const customer = useSelector(selectCustomer);
  const portfolioName = useSelector(selectPortfolioName(portfolio.portfolioId));
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);

  const portfolioWeight = getFormattedPortfolioWeight(portfolio, currentWeights);

  const targetWeight = optimizedWeights.withoutIlliquids?.portfolioWeights
    ?.find((p) => p.portfolioId === portfolio.portfolioId)
    ?.instruments.reduce((acc, curr) => acc + (curr?.weight || 0), 0);

  const settings = getPortfolioSettings(portfolio);

  const portfolioDisplayName = portfolioName || translate('portfolioManager.trade.portfolio');
  const portfolioDetails = portfolioDetailsById[portfolio.portfolioId];
  const portfolioState = portfolioDetails?.state;
  const portfolioErrors = getPortfolioErrors(customer, portfolioDetails);

  return (
    <>
      <tr>
        <PortfolioHeader colSpan={12} data-testkey="trade-portfolio">
          <Container>
            <FlexJustifyBetween>
              <h2>{portfolioDisplayName}</h2>
              {portfolioErrors.length > 0 && (
                <Flex>
                  {portfolioErrors.map((errorKey) => (
                    <PortfolioError data-testkey="trades-not-allowed" key={errorKey}>
                      {translate(`portfolioManager.trade.${errorKey}`)}
                    </PortfolioError>
                  ))}
                </Flex>
              )}
            </FlexJustifyBetween>
            <PortfolioSettings>
              {Object.keys(settings).map((k) => (
                <Setting key={k}>
                  <SettingKey>{translate(`portfolioManager.trade.${k}`)}</SettingKey>
                  <SettingValue>{translate(`portfolioManager.trade.${settings[k as ManualSetting]}`)}</SettingValue>
                </Setting>
              ))}
              <Setting>
                <SettingKey>{translate(`portfolioManager.trade.portfolioWeight`)}</SettingKey>
                <SettingValue>{portfolioWeight}</SettingValue>
              </Setting>
              <Setting>
                <SettingKey>{translate(`portfolioManager.trade.portfolioTargetWeight`)}</SettingKey>
                <SettingValue>{formatToDecimals(targetWeight, 1, '%')}</SettingValue>
              </Setting>
              <Setting>
                <SettingKey>Salkun tila</SettingKey>
                <SettingValue>{portfolioState}</SettingValue>
              </Setting>
            </PortfolioSettings>
          </Container>
        </PortfolioHeader>
      </tr>
      <tr>
        <td colSpan={3} />
        <SpacerCell />
        <NumberCellHeader colSpan={3}>{translate('portfolioManager.trade.possession')}</NumberCellHeader>
        <SpacerCell />
        <NumberCellHeader colSpan={4}>{translate('portfolioManager.trade.trading')}</NumberCellHeader>
      </tr>
    </>
  );
};

const PortfolioHeader = styled.th`
  padding: 3rem 0 0 0;
`;

const Container = styled.div`
  padding: 1rem;
  font-weight: 500;
  background: ${colors.light_blue};
  border-top: 2px solid hsl(205deg 33% 83%);
  margin-bottom: 1rem;

  && {
    text-align: left;
    text-transform: none;
  }
`;

const PortfolioSettings = styled(Flex)`
  margin-top: 1rem;
`;

const Setting = styled.div`
  margin-right: 2rem;
`;

const SettingKey = styled.div`
  margin-bottom: 0.25rem;
  opacity: 0.7;
`;

const SettingValue = styled.div`
  font-weight: 700;
`;

const NumberCellHeader = styled.th`
  letter-spacing: 0.8px;
  padding-bottom: 0.75rem;
`;

const PortfolioError = styled.div`
  color: ${colors.red};
  font-size: 16px;
  margin-left: 0.5rem;
  font-weight: bold;
  background: white;
  align-self: flex-start;
  padding: 0.2rem 1rem;
  border-radius: 6px;
`;
