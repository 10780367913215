import translate from 'counterpart';
import { RiskLevel, ErrorContext, PlanLength } from 'types/types';
import { AllocatedPortfolio, AllocatorPortfolio } from 'types/investmentPlanState';
import { RootState } from 'types/rootState';
import { allocatorContractTypes, emptyOptimizedWeights, MONEY } from 'constants/allocator';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';
import { errorKeys } from 'features/errors/errorUtils';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';
import {
  mapPositionsToGeneralPosition,
  securityDifference,
  securityPortfolioIdDifference,
} from 'features/allocator/investmentPlan/investmentPlanUtils';

export const selectRisk = (state: RootState): RiskLevel =>
  selectTestRisk(state) || selectSelectedRisk(state) || selectTargetRisk(state) || 0;

export const selectActualRisk = (state: RootState): RiskLevel =>
  selectSelectedRisk(state) || selectTargetRisk(state) || 0;

export const selectTestRisk = (state: RootState): RiskLevel => state.portfolioManager.investmentPlan.testRiskLevel || 0;

export const selectSelectedRisk = (state: RootState): RiskLevel =>
  state.profile.customer?.toJS().selectedRiskLevel || 0;

const selectTargetRisk = (state: RootState): RiskLevel => state.portfolioManager.investmentPlan.targetRiskLevel || 0;

export const selectHasPositions = (state: RootState) => {
  const positions = state.portfolio.positions;
  if (!positions?.length) {
    return false;
  }

  // Don't count money as investment
  const positionsOtherThanMoney = positions.filter((position) => position.financialInstrument.ticker !== MONEY);
  if (positionsOtherThanMoney.length > 0) {
    return true;
  }

  return false;
};

export const customerHasValidPlan = (state: RootState) =>
  ['unlockedPlan', 'lockedPlan'].includes(state.portfolioManager.investmentPlan.planState);

export const planIsLocked = (state: RootState): boolean =>
  state.portfolioManager.investmentPlan.planState === 'lockedPlan';

export const portfoliosHaveNoIds = (state: RootState, planPortfolios: AllocatedPortfolio[]): boolean => {
  const hasPositions = selectHasPositions(state);
  const portfolioIdsMissing = !!planPortfolios.length && planPortfolios.some((p) => !p.portfolioId);
  return hasPositions && portfolioIdsMissing;
};

export const outsidePortfolioMissingInPlan = (state: RootState, planPortfolios: AllocatedPortfolio[]): boolean => {
  const hasOutsideFunds = 'OutsideFunds' in state.portfolio.portfolios.toJS();
  const hasNoOutsidePortfolio =
    !!planPortfolios.length &&
    !planPortfolios.some((p) => p.contractType?.contractTypeId === allocatorContractTypes.KOONTI);
  return hasOutsideFunds && hasNoOutsidePortfolio;
};

export const selectHasError = (errorKeys: ErrorContext[]) => (state: RootState) =>
  errorKeys?.some((errorKey) => !!state.common.errors.find((i) => i.context === errorKey));

export const allPlanInstrumentsAreNotInPositions = (state: RootState) =>
  selectHasError(['allPlanInstrumentsNotInPositions'])(state);

export const hasNegativePositions = (state: RootState) => selectHasError(['negativePositions'])(state);

export const hasOptimizeFailedError = (state: RootState) => selectHasError(['optimizePlan'])(state);

export const selectOptimizeFailedForPlanLength = (planLength: PlanLength) => (state: RootState) => {
  const optimizeFailed = selectHasError([errorKeys.optimizePlan])(state);
  const optimizeWithIlliquidsFailed = selectHasError([errorKeys.optimizePlanWithIlliquids])(state);
  const optimizeWithoutIlliquidsFailed = selectHasError([errorKeys.optimizePlanWithoutIlliquids])(state);
  return (
    optimizeFailed || (planLength === 'withIlliquids' ? optimizeWithIlliquidsFailed : optimizeWithoutIlliquidsFailed)
  );
};

export const positionsAreEqual = (state: RootState) => {
  const hasPositions = selectHasPositions(state);
  const positionsNotInPlan = selectPositionsNotInPlan(state);
  const planInstrumentsNotInPositions = selectPlanInstrumentsNotInPositions({ removeZeroWeightPlanInstruments: true })(
    state
  );

  return (planInstrumentsNotInPositions.length === 0 && positionsNotInPlan.length === 0) || !hasPositions;
};

export const isLoadingInitialItems = (state: RootState) =>
  state.portfolio.loadingPositions ||
  state.portfolio.loadingPortfolios ||
  state.portfolioManager.investmentPlan.loadingPlan ||
  state.profile.isBusy;

export const noRiskOrCoreSelected = (state: RootState) => {
  const targetRiskLevel = selectRisk(state);
  const instrumentsSelected = selectInstrumentsSelected(state);
  const loadingAnything = isLoadingInitialItems(state);
  const optimizingPlan = state.portfolioManager.weights.optimizingPlan;
  const optimizingCurrent = state.portfolioManager.weights.optimizingCurrent;
  return (
    (!targetRiskLevel || instrumentsSelected.length === 0) && !loadingAnything && !optimizingPlan && !optimizingCurrent
  );
};

export const hasRiskConflict = (state: RootState) => {
  const contractRisk = state.profile.customer.toJS().selectedRiskLevel;
  const allocatorRisk = state.portfolioManager.investmentPlan.targetRiskLevel;
  return allocatorRisk > 0 && contractRisk > 0 && allocatorRisk !== contractRisk;
};

export const selectAssetClasses = (state: RootState) => state.portfolioManager.commonData.assetClasses;

export const selectCustomConstraints = (state: RootState) =>
  state.portfolioManager.investmentPlan.constraints.customConstraints;

export const selectUseBackendConstraints = (state: RootState) =>
  state.portfolioManager.investmentPlan.constraints.useBackendConstraints;

export const selectOptimizationConstraints = (state: RootState) =>
  state.portfolioManager.investmentPlan.constraints.optimizationConstraints;

export const selectAssetCategoryWeights = (state: RootState) =>
  state.portfolioManager.weights.currentWeights.assetCategoryWeights;

export const selectPlanPortfolios = (state: RootState) => state.portfolioManager.investmentPlan.planPortfolios;

export const selectCurrentWeights = (state: RootState) => state.portfolioManager.weights.currentWeights;

const selectOptimizedWeights = (state: RootState) => state.portfolioManager.weights.optimizedWeights;

export const selectOptimizedWeightsForRiskLevel = (state: RootState) => {
  const riskLevel = selectRisk(state);

  const optimizationForecastType = state.portfolioManager.investmentPlan.optimizationForecastType;

  const optimizedWeightsList = selectOptimizedWeights(state);
  const optimizedWeights = optimizedWeightsList.find(
    (optimizedWeights) =>
      optimizedWeights.riskLevel === riskLevel && optimizedWeights.optimizationForecastType === optimizationForecastType
  );

  return optimizedWeights ?? emptyOptimizedWeights;
};

export const isOptimizingPlan = (state: RootState) => state.portfolioManager.weights.optimizingPlan;

export const isOptimizingCurrent = (state: RootState) => state.portfolioManager.weights.optimizingCurrent;

export const selectPlanState = (state: RootState) => state.portfolioManager.investmentPlan.planState;

export const isLoadingPlan = (state: RootState) => state.portfolioManager.investmentPlan.loadingPlan;

export const selectFlagCheckPortfolios = (state: RootState) =>
  state.portfolioManager.investmentPlan.flagCheckPortfolios;

export const selectInstrumentsSelected = (state: RootState) =>
  state.portfolioManager.investmentPlan.instruments.instrumentsSelected;

export const selectPortfolioName = (portfolioId: string) => (state: RootState) => {
  const hasPositions = selectHasPositions(state);
  const portfolioDetailsById = selectPortfolioDetailsById(state);
  const portfolioDetails = portfolioDetailsById?.[portfolioId];
  const portfolioName = hasPositions
    ? portfolioDetails?.portfolioName || translate('errors.portfolioNameUnknown')
    : translate('allocator.portfoliosView.personalAllocationStrategy');
  return portfolioName;
};

export const selectAum = (state: RootState) => state.portfolioManager.valueData.aum;

export const selectPlanInstrumentsNotInPositions =
  (params: { removeZeroWeightPlanInstruments: boolean }) => (state: RootState) => {
    const positions = state.portfolio.positions;
    const hasPositions = selectHasPositions(state);
    const planPortfolios = selectPlanPortfolios(state);
    const mappedPlanInstruments = mapPlanInstrumentsToGeneralPositions(planPortfolios);
    const mappedPositions = mapPositionsToGeneralPosition(positions);

    // compared SAVED plan to positions
    const planInstrumentsNotInPositions = securityPortfolioIdDifference({
      instrumentList1: mappedPlanInstruments,
      instrumentList2: mappedPositions,
      removeZeroWeightPlanInstruments: params.removeZeroWeightPlanInstruments,
    });

    if (!hasPositions) {
      return [];
    }

    return planInstrumentsNotInPositions;
  };

export const selectPositionsNotInPlan = (state: RootState) => {
  const positions = state.portfolio.positions;
  const riskLevel = selectActualRisk(state); // never use test risk level
  const optimizedPortfolios = createOptimizedPortfoliosFromOptimizedValues(riskLevel)(state);
  const mappedOptimizedInstruments = mapPlanInstrumentsToGeneralPositions(optimizedPortfolios);
  const mappedPositions = mapPositionsToGeneralPosition(positions);

  // compare positions to optimized portfolios (= optimization result)
  const positionsNotInPlan = securityDifference(mappedPositions, mappedOptimizedInstruments);

  if (optimizedPortfolios.length === 0) {
    return [];
  }

  return positionsNotInPlan;
};

const mapPlanInstrumentsToGeneralPositions = (instruments: AllocatorPortfolio[]) =>
  instruments.flatMap((p) =>
    p.allocatedPortfolioRows.withoutIlliquids
      .filter((i) => i.security !== MONEY)
      .map((i) => ({
        name: i.name,
        security: i.security,
        portfolioId: i.portfolioId,
        value: i?.weight || 0,
      }))
  );
