import React, { useState, useEffect, ChangeEvent } from 'react';
import Select from 'react-select';
import { map } from 'lodash';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import styles from 'features/fieldList/Field.scss';
import { SelectRisk, green } from './components/SelectRisk';
import { RiskYieldAndFigures } from './components/RiskYieldAndFigures';
import { changeSectionField } from 'features/contracts/contractsActions';
import { apiCall } from 'core/apiCall';
import {
  getPrivateRiskCalculationData,
  getCorporateRiskCalculationData,
  getInstitutionalRiskCalculationData,
} from './getRiskCalculationData';
import { RootState } from 'types/rootState';
import { RiskDataResponse, ChangeQuestionEvent } from './riskConfirmation.types';
import { FieldType, Item } from 'types/ordersState';
import { BASIC_INFO_PERSON, BASIC_INFO_COMPANY, BASIC_INFO_INSTITUTION } from 'constants/contractIds';
import { getInvestmentPlan } from 'features/allocator/investmentPlan/investmentPlanActions';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { useAppDispatch } from 'core/hooks';
import { fetchPortfolioData } from 'features/portfolio/portfolioActions';
import { BASIC_INFO } from 'constants/contractGroups';
import { RiskLevel } from 'types/types';

export const RiskConfirmationView = () => {
  const dispatch = useAppDispatch();
  const contractId = useSelector((state: RootState) => state.contracts.activeContract) as (typeof BASIC_INFO)[number];
  const allContractQuestions = useSelector((state: RootState) => state.contracts.templates)?.toJS()[contractId];
  const allContractAnswers = useSelector((state: RootState) => state.contracts.values)?.toJS()[contractId];
  const customerId = useSelector(selectCustomerId);
  const accessToken = useSelector((state: RootState) => state.oidc.user.access_token);

  const getHighlightedAnswers = () => {
    switch (contractId) {
      case BASIC_INFO_PERSON:
        return allContractAnswers.sections.investorSurvey;
      case BASIC_INFO_COMPANY:
        return allContractAnswers.sections.suitabilityAssessmentCompany;
      case BASIC_INFO_INSTITUTION:
        return allContractAnswers.sections.suitabilityAssessmentCompany;
    }
  };

  const riskConfirmed = allContractAnswers.sections?.riskConfirmation?.riskConfirmed;
  const riskAnswer = allContractAnswers.sections?.riskConfirmation?.selectedRiskLevel as RiskLevel;

  const [maximumRiskLevel, setMaximumRiskLevel] = useState<RiskLevel | -1>(-1);
  const [calculatedRiskLevel, setCalculatedRiskLevel] = useState<RiskLevel | -1>(-1);
  const [selectedRiskLevel, setSelectedRiskLevel] = useState<RiskLevel | -1>(riskAnswer || -1);
  const [loadingRisk, setLoadingRisk] = useState(false);
  const [reasoning, setReasoning] = useState('');
  const [error, setError] = useState(false);

  const onChangeReasoning = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReasoning(e.target.value);
    if (riskConfirmed) {
      toggleRiskConfirmed(false);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      await dispatch(getInvestmentPlan(customerId));
    };
    void loadData();
  }, []);

  useEffect(() => {
    toggleRiskConfirmed(false);
  }, [selectedRiskLevel]);

  useEffect(() => {
    dispatch(changeSectionField('sections.riskConfirmation.selectedRiskLevel', selectedRiskLevel, contractId));
  }, [selectedRiskLevel]);

  useEffect(() => {
    const loadData = async () => {
      await getRiskLevels();
    };
    void loadData();
  }, [JSON.stringify(getHighlightedAnswers())]);

  const getRiskLevels = async () => {
    setLoadingRisk(true);
    try {
      const res = await fetchRiskData();
      setMaximumRiskLevel(res.maxRisk);
      setCalculatedRiskLevel(res.calculatedRisk);
      dispatch(fetchPortfolioData(customerId));
      if (!riskConfirmed) {
        setSelectedRiskLevel(res.calculatedRisk);
      }
    } catch (error) {
      setError(true);
      return error;
    } finally {
      setLoadingRisk(false);
    }
  };

  const fetchRiskData = async () => {
    const riskData = (await apiCall({
      method: 'post',
      token: accessToken,
      path: getPath(),
      data: getPayload(),
    })) as RiskDataResponse;
    return riskData;
  };

  const getPath = () => {
    switch (contractId) {
      case BASIC_INFO_PERSON:
        return '/api/v1/risk/calculate/private';
      case BASIC_INFO_COMPANY:
        return '/api/v1/risk/calculate/corporate';
      case BASIC_INFO_INSTITUTION:
        return '/api/v1/risk/calculate/institutional';
      default:
        return '';
    }
  };

  const getPayload = () => {
    switch (contractId) {
      case BASIC_INFO_PERSON:
        return getPrivateRiskCalculationData(allContractAnswers);
      case BASIC_INFO_COMPANY:
        return getCorporateRiskCalculationData(allContractAnswers);
      case BASIC_INFO_INSTITUTION:
        return getInstitutionalRiskCalculationData(allContractAnswers);
    }
  };

  const onClickRisk = (value: RiskLevel) => setSelectedRiskLevel(value);

  const onChangeQuestionAnswer = (event: ChangeQuestionEvent, questionKey: string) => {
    const answerKey = event.value;
    toggleRiskConfirmed(false);
    dispatch(changeSectionField(questionKey, answerKey, contractId));
  };

  const toggleRiskConfirmed = (value: boolean) => {
    dispatch(changeSectionField('sections.riskConfirmation.riskConfirmed', value, contractId));
    dispatch(
      changeSectionField(
        'sections.riskConfirmation.riskReasoning',
        reasoning || translate('newContract.riskConfirmation.recommendedRiskLevel'),
        contractId
      )
    );
  };

  const getRiskConfirmationQuestions = () => {
    const questions = [];
    if (contractId === BASIC_INFO_PERSON) {
      const investorSurvey = allContractQuestions.form.items.find(
        (i: Item) => i.key === 'sections.investorSurvey'
      ) as Item;
      questions.push(investorSurvey?.items.find((i) => i.key === 'sections.investorSurvey.investmentGoals'));
      questions.push(investorSurvey?.items.find((i) => i.key === 'sections.investorSurvey.investmentHorizon'));
      questions.push(investorSurvey?.items.find((i) => i.key === 'sections.investorSurvey.nonLiquidAssets'));
    } else if (contractId === BASIC_INFO_COMPANY) {
      const suitabilityAssessmentCompany = allContractQuestions.form.items.find(
        (i) => i.key === 'sections.suitabilityAssessmentCompany'
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find((i) => i.key === 'sections.suitabilityAssessmentCompany.lossTolerance')
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find(
          (i) => i.key === 'sections.suitabilityAssessmentCompany.investmentHorizon'
        )
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find(
          (i) => i.key === 'sections.suitabilityAssessmentCompany.investmentGoals'
        )
      );
    } else if (contractId === BASIC_INFO_INSTITUTION) {
      const suitabilityAssessmentCompany = allContractQuestions.form.items.find(
        (i) => i.key === 'sections.suitabilityAssessmentCompany'
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find((i) => i.key === 'sections.suitabilityAssessmentCompany.lossTolerance')
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find(
          (i) => i.key === 'sections.suitabilityAssessmentCompany.investmentHorizon'
        )
      );
      questions.push(
        suitabilityAssessmentCompany?.items.find(
          (i) => i.key === 'sections.suitabilityAssessmentCompany.investmentGoals'
        )
      );
    }
    return questions;
  };

  const investorSurveyQuestions = getRiskConfirmationQuestions().filter((question) => question) as FieldType[];

  const threeQuestionForm = investorSurveyQuestions.map((q) => {
    const title = q.title.fi;
    const splitKey = q.key.split('.');
    const shortKey = splitKey[splitKey?.length - 1];
    const highlightedAnswers = getHighlightedAnswers();
    const answer = highlightedAnswers[shortKey];

    const options = map(q.titleMap?.fi, (val, key) => ({
      value: key,
      label: val,
    }));
    return {
      key: q.key,
      title,
      options,
      answer,
    };
  });

  const selectedRiskIsValid = selectedRiskLevel > 0 && selectedRiskLevel <= maximumRiskLevel;
  const reasoningIsValid = reasoning.length > 5;
  const sectionIsReady =
    selectedRiskIsValid && (Math.abs(selectedRiskLevel - calculatedRiskLevel) <= 1 || reasoningIsValid);
  const invalidRiskResponse = calculatedRiskLevel === 0 || maximumRiskLevel === 0;

  return (
    <div>
      <h4>{translate('newContract.riskConfirmation.title')}</h4>
      {error || invalidRiskResponse ? (
        <div>
          {error && translate('newContract.riskConfirmation.riskFetchError')}{' '}
          {invalidRiskResponse && translate('newContract.riskConfirmation.invalidRiskResponse')}{' '}
          <StyledLinkBack onClick={() => window.history.back()}>
            {translate('newContract.riskConfirmation.back')}
          </StyledLinkBack>
        </div>
      ) : (
        <Columns>
          <LeftColumn>
            <SelectRisk
              loadingRisk={loadingRisk}
              chooseRisk={onClickRisk}
              selectedRiskLevel={selectedRiskLevel as RiskLevel}
              recommendedRiskLevel={calculatedRiskLevel as RiskLevel}
              maximumRiskLevel={maximumRiskLevel as RiskLevel}
              onChangeReasoning={onChangeReasoning}
              reasoning={reasoning}
            />

            {!loadingRisk && (
              <>
                <StyledThreeQuestions>
                  <h5>{translate('newContract.riskConfirmation.threeQuestionsTitle')}</h5>
                  {threeQuestionForm.map((i) => {
                    return (
                      <StyledQuestion key={i.key} className={styles.select}>
                        <b>{i.title}</b>
                        <Select
                          value={i.options.filter((option) => option.value === i.answer)}
                          options={i.options}
                          name={i.key}
                          isClearable={false}
                          placeholder={translate('newContract.select')}
                          tabSelectsValue={false}
                          isSearchable={false}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                          styles={{
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            control: (baseStyles, state) => ({
                              ...baseStyles,
                              borderTopStyle: 'none',
                              borderLeftStyle: 'none',
                              borderRightStyle: 'none',
                              boxShadow: 'none',
                              fontSize: '19px',
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                              ...theme.colors,
                              primary: 'black',
                              primary25: '#ededed',
                              neutral50: 'black',
                              neutral20: 'black',
                              neutral60: 'black',
                              primary50: '#ededed',
                              neutral80: 'black', // selected text color
                            },
                          })}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          onChange={(e) => onChangeQuestionAnswer(e, i.key)}
                        />
                      </StyledQuestion>
                    );
                  })}
                </StyledThreeQuestions>

                <BottomSection>
                  <button
                    className="button"
                    data-testkey="button-confirm-risk"
                    disabled={!sectionIsReady || riskConfirmed}
                    onClick={() => toggleRiskConfirmed(true)}
                  >
                    {translate('newContract.riskConfirmation.confirmRisk')}
                  </button>

                  {riskConfirmed && (
                    <div>
                      <b>✓ {translate('newContract.riskConfirmation.riskIsConfirmed')}:</b>
                      <ConfirmedRiskNumber>{selectedRiskLevel}</ConfirmedRiskNumber>
                    </div>
                  )}
                </BottomSection>
              </>
            )}
          </LeftColumn>

          {selectedRiskIsValid && !loadingRisk && (
            <Column>
              <RiskYieldAndFigures riskLevel={selectedRiskLevel as RiskLevel} />
            </Column>
          )}
        </Columns>
      )}
    </div>
  );
};

const StyledLinkBack = styled.a`
  display: block;
  font-weight: 700;
  color: ${green};
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  width: 49%;
`;

const LeftColumn = styled(Column)`
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
`;

const StyledThreeQuestions = styled.div`
  margin-top: 4rem;
`;

const StyledQuestion = styled.div`
  margin-bottom: 2rem;
`;

const BottomSection = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ConfirmedRiskNumber = styled.div`
  border: 2px solid ${green};
  border-radius: 20px;
  width: 2rem;
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
`;
