import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { FullScreenError } from 'features/allocator/common/FullScreenError';
import { ManagedPortfolios } from 'features/portfolioManager/components/ManagedPortfolios';
import { styles } from 'features/common/StyledComponents';
import { PortfolioDistribution } from 'features/portfolioManager/components/PortfolioDistribution';
import { PortfolioValue } from 'features/portfolioManager/components/PortfolioValue';
import { PlanInfo } from 'features/portfolioManager/components/PlanInfo';
import { preventPortfolioManagerUse } from 'features/errors/errorUtils';
import { selectHasPositions } from 'features/allocator/allocatorSelectors';
import { DistributionByType } from 'features/portfolioManager/components/DistributionByType';
import { RiskDeviation } from 'features/portfolioManager/components/RiskDeviation';
import { Card } from 'features/allocator/common/Card';
import { Grid } from 'features/allocator/common/Grid';

interface Props {
  params: { id: string };
}

export const PortfolioManagerView = ({ params }: Props) => {
  const preventUse = useSelector(preventPortfolioManagerUse);
  const hasPositions = useSelector(selectHasPositions);

  const summaryLink = `customer/${params.id}/portfolioManager/situationsummary`;

  if (preventUse) {
    return <FullScreenError />;
  }

  return (
    <>
      <PortfolioStatistics>
        <h1>{translate('portfolioManager.customerHeader.portfolioManagement')}</h1>
        <PortfolioDistribution />
        <PlanInfo customerId={params.id} />
        <ValueAndRisk gap="1rem">
          <PortfolioValue />
          <RiskDeviation />
        </ValueAndRisk>
        <CreateSummaryLink>
          <Link to={summaryLink} data-testkey="link-to-situation-summary">
            Luo yhteenveto salkusta
          </Link>
        </CreateSummaryLink>
      </PortfolioStatistics>
      {hasPositions && <ManagedPortfolios />}
      {hasPositions && <DistributionByType />}
    </>
  );
};

const PortfolioStatistics = styled.div`
  ${styles.fullWidth};
  display: grid;
  grid-template-areas:
    'title title'
    'distribution plan'
    'distribution valueAndRisk'
    'distribution createsummary';
  grid-gap: 1rem;
  grid-template-columns: minmax(50%, 70%) auto;
  padding-top: 1rem;

  h1 {
    grid-area: title;
    text-align: center;
    margin: 0;
  }
`;

const ValueAndRisk = styled(Grid)`
  grid-area: valueAndRisk;
  grid-template-columns: auto auto;
`;

const CreateSummaryLink = styled(Card)`
  font-size: 16px;
  grid-area: createsummary;
`;
