import React, { Component } from 'react';
import translate from 'counterpart';
import Immutable from 'immutable';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { getAppointmentSchema, isFieldRequired, getError } from 'core/index';
import { PHONE_CALL, APPOINTMENT } from 'constants/meetingType';
import styles from './Notes.scss';
import { ValidationErrorValue } from 'types/orderDialogState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  present: string;
  description: string;
  topic: string;
  includesReporting: boolean;
  state: string;
  activity: string;
  errors: ValidationErrorValue[];
  editNote: (key: string, value: string) => void;
  touched: Immutable.Set<string>;
}

export default class Notes extends Component<Props> {
  static defaultProps = {
    present: '',
    description: '',
  };

  handlePresent = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('present', event.currentTarget.value);
  };

  handleDescription = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('description', event.currentTarget.value);
  };

  handleReporting = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.editNote('includesReporting', event.target.checked ? 'true' : '');
  };

  render() {
    const { present, description, state, activity, errors, touched, includesReporting } = this.props;
    const commonProps = {
      style: {
        width: '100%',
        boxShadow: 'none',
      },
      multiline: true,
    };
    const schema = getAppointmentSchema(activity, state);
    const toggleHtml = (
      <div className={styles.toggleLabel}>
        {translate('meetings.includesReporting')}
        <span className={styles.toggleAnswer}>
          {includesReporting ? translate('common.yes') : translate('common.no')}
        </span>
      </div>
    );
    return (
      <div className="row">
        {activity === APPOINTMENT && (
          <div className="columns small-12">
            <StyledTextField
              name="present"
              onChange={this.handlePresent}
              inputProps={{
                maxLength: 500,
              }}
              value={present}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              label={`${translate('meetings.present')}${isFieldRequired('present', schema) ? '*' : ''}`}
              rows={1}
              helperText={touched.has('present') ? getError(errors, 'present') : undefined}
              {...commonProps}
              variant="standard"
              sx={[
                {
                  '&.MuiFormControl-root': {
                    marginTop: '0',
                    width: '100%',
                  },
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiInput-root:hover': {
                    borderBottomColor: 'rgba(0, 0, 0, 0.6)',
                    '&::before': {
                      borderBottomColor: 'rgba(0, 0, 0, 0.6) !important',
                      borderWidth: '1px !important',
                    },
                  },
                  '& .MuiInputBase-root': {
                    marginTop: '14px',
                    marginBottom: '15px',
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    fontFamily: 'Roboto, sans-serif',
                    marginTop: 0,
                    height: '100%',
                    paddingTop: 0,
                  },
                  '& label': {
                    fontSize: '14px',
                    '&.Mui-focused': {
                      fontSize: '14px',
                    },
                  },
                  '& > p': {
                    '&.MuiFormHelperText-root': {
                      paddingLeft: '0',
                    },
                  },
                  '&.Mui-error': {
                    borderBottomColor: '#d32f2f',
                    borderWidth: '2px',
                    '&::before': {
                      borderWidth: '2px',
                      borderBottomColor: '#d32f2f',
                    },
                  },
                },
              ]}
            />
          </div>
        )}
        <FormGroup>
          <FormControlLabel
            control={<Switch name="includesReporting" onChange={this.handleReporting} color="default" />}
            label={toggleHtml}
            labelPlacement="start"
          />
        </FormGroup>
        <div className="columns small-12">
          <StyledTextField
            name="description"
            onChange={this.handleDescription}
            inputProps={{
              maxLength: 2000,
            }}
            value={description}
            label={`${
              activity === PHONE_CALL ? translate('meetings.callDescription') : translate('meetings.description')
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
            }${isFieldRequired('description', schema) ? '*' : ''}`}
            rows={2}
            helperText={touched.has('description') ? getError(errors, 'description') : undefined}
            {...commonProps}
            data-testkey="meeting-description"
            variant="standard"
            sx={[
              {
                '&.MuiFormControl-root': {
                  marginTop: '15px',
                  width: '100%',
                },
                '& .Mui-focused': {
                  '&::after': {
                    left: 0,
                  },
                },
                '& .MuiInputBase-root': {
                  marginTop: '14px',
                  marginBottom: '15px',
                  paddingLeft: 0,
                  '&::before': {
                    left: 0,
                  },
                },
                '& .MuiInputBase-input': {
                  fontFamily: 'Roboto, sans-serif',
                  marginTop: 0,
                  height: '100%',
                  paddingTop: 0,
                },
                '& label': {
                  fontSize: '14px',
                  '&.Mui-focused': {
                    fontSize: '14px',
                  },
                },
                '& > p': {
                  '&.MuiFormHelperText-root': {
                    paddingLeft: '0',
                  },
                },
                '&.Mui-error': {
                  borderBottomColor: '#d32f2f',
                  borderWidth: '2px',
                  '&::before': {
                    borderWidth: '2px',
                    borderBottomColor: '#d32f2f',
                  },
                },
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
