import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { get } from 'lodash';
import styles from './Editor.scss';
/*import SelectPortfolio from './SelectPortfolio';*/
import { SelectSide } from './SelectSide';
import { Instrument } from './Instrument';
import { AppropriatenessTestState, OrderSideType } from 'types/orderDialogState';
import { PortfolioById } from 'types/portfolioState';
import { EditorValues, OrderInstrument, SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { SelectValue } from 'types/types';
import {
  changeInstrument,
  changeSide,
  fetchChangePairs,
  getTransactionFees,
  handleChangeSideWhenInstrumentChosen,
  setEditorValue,
  setEditorValues,
  getSuitabilityOfSelectedInstrument,
  setSuitabilityErrors,
} from 'features/orderDialog/orderDialogActions';
import { ExistingPosition } from 'features/orderDialog/components/ExistingPosition';
import {
  selectEditor,
  selectShowMoneySourceForBuyOrder,
  selectSideOptions,
  selectTouchedFields,
} from 'features/orderDialog/orderDialogSelectors';
import { RootState } from 'types/rootState';
import { convertStringToDecimalNumber, isListed, isValidDecimalNumber } from 'core/portfolios';
import { ShareDetails } from 'features/orderDialog/components/ShareDetails';
import { FundDetails } from 'features/orderDialog/components/FundDetails';
import { WarrantDetails } from 'features/orderDialog/components/WarrantDetails';
import { StructuredProductDetails } from 'features/orderDialog/components/StructuredProductDetails';
import { PrivateEquityDetails } from 'features/orderDialog/components/PrivateEquityDetails';
import { FUND, STRUCTURED_PRODUCT, WARRANT } from 'constants/instrumentForms';
import { selectDefaultAccount, selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';
import { EXCHANGE, EXCHANGE_ALL, SELL, SELL_ALL, BUY } from 'constants/sides';
import { getEditorValuesBasedOnSide } from 'core/orders';
import { getCurrency } from 'features/orderDialog/orderDialogUtils';
import { useAppDispatch } from 'core/hooks';
import { AppropriatenessTest, AppropriatenessTestCanceled } from './AppropriatenessTest';
import { CLIENT_INITIATIVE } from 'constants/receiveInfoOptions';

interface Props {
  customerId: string;
  selectedPortfolio: PortfolioById;
  focusRef: React.MutableRefObject<HTMLElement | undefined>;
  setFocus: () => void;
  orderBasis: string;
  suitabilityErrors: SuitabilityResult[];
  testPassed?: boolean;
}

export const AssignmentInstrumentEditor = ({
  focusRef,
  setFocus,
  selectedPortfolio,
  customerId,
  orderBasis,
  suitabilityErrors,
  testPassed,
}: Props) => {
  const dispatch = useAppDispatch();

  const isFetchingNominalValue = useSelector((state: RootState) => state.orderDialog.isFetchingNominalValue);
  const touchedFields = useSelector(selectTouchedFields).toJS();
  const changePairOptions = useSelector((state: RootState) => state.orderDialog.changePairOptions);
  const isLoadingChangePairOptions = useSelector((state: RootState) => state.orderDialog.isLoadingChangePairOptions);
  const defaultFee = useSelector((state: RootState) => state.orderDialog.defaultFee) ?? 0;
  const isFetchingFee = useSelector((state: RootState) => state.orderDialog.isFetchingFee);
  const defaultAccount = useSelector(selectDefaultAccount);
  const showMoneySourceForBuyOrder = useSelector(selectShowMoneySourceForBuyOrder);
  const structuredMinimumSum = useSelector((state: RootState) => state.orderDialog.structuredMinimumSum) ?? 0;
  const structuredStep = useSelector((state: RootState) => state.orderDialog.structuredStep) ?? 0;
  const privateEquityMaxSum = useSelector((state: RootState) => state.orderDialog.privateEquityMaxSum) ?? 0;
  const sideOptions = useSelector((state: RootState) => selectSideOptions(state, orderLineId));
  const assignment = useSelector(selectEditor).toJS();
  const buyNew = useSelector((state: RootState) => state.orderDialog.isBuyingNew);
  const validationErrors = useSelector((state: RootState) => state.orderDialog.validationErrors);
  const instrumentOptions = useSelector((state: RootState) => state.orderDialog.instrumentOptions);
  const isLoadingOptions = useSelector((state: RootState) => state.orderDialog.isLoadingOptions);
  const portfolioDetailsById = useSelector(selectPortfolioDetailsById);
  const suitabilityErrorss = useSelector((state: RootState) => state.orderDialog.suitabilityErrors);

  const orderInitializerType = assignment.orderInitializerType;
  const customerType = useSelector((state: RootState) => state.profile.customer?.get('customerType'));

  const instrumentForm = assignment.instrumentForm;
  const currentFee = assignment.subscriptionFee;
  const orderLineId = assignment._id;
  const financialInstrumentId = assignment.financialInstrumentId;
  const portfolioId = assignment.portfolioId;
  const currency = getCurrency(assignment, portfolioDetailsById);
  const receiveMethod = assignment.receivedFromClientMethod;

  const token = useSelector((state: RootState) => state.oidc.user.access_token);
  const isVisible = assignment.isSuitable !== undefined;

  const [isLoadingTest, setIsLoadingTest] = useState(true);
  const [needsTest, setNeedsTest] = useState(false);
  const [isTestPassed, setIsTestPassed] = useState(!!testPassed);
  const [isTestCanceled, setIsTestCanceled] = useState(false);
  const [clickedInstrumentSearch, setClickedInstrumentSearch] = useState('');

  const normalInstrumentSearch = 'instrumentsearch';
  const exchangeInstrumentSearch = 'exchangeinstrumentsearch';
  const appropriatenessRuleId = 'AppropriatenessRule';

  let sideValue = assignment.side;
  if (sideValue === SELL && assignment.tradeAll) {
    sideValue = SELL_ALL;
  } else if (sideValue === EXCHANGE && assignment.tradeAll) {
    sideValue = EXCHANGE_ALL;
  }

  useEffect(() => {
    setFocus();
  }, []);

  useEffect(() => {
    setNeedsTest(() => !isLoadingTest);
  }, [isLoadingTest, instrumentForm, sideValue]);

  useEffect(() => {
    assignment.financialInstrumentId
      ? dispatch(
          getSuitabilityOfSelectedInstrument(
            customerId,
            portfolioId,
            assignment.financialInstrumentId,
            assignment.side,
            orderInitializerType,
            customerType,
            assignment.orderBasis
          )
        )
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          .then(dispatch(setSuitabilityErrors(suitabilityErrorss)))
      : null;
  }, [assignment.financialInstrumentId, sideValue]);

  useEffect(() => {
    assignment.financialInstrumentId ? getInstrumentDetailsEditorOrAppropriatenessTest() : null;
  }, [assignment.financialInstrumentId]);

  const onInstrumentChange = async (params: OrderInstrument | null) => {
    setIsLoadingTest(true);
    setIsTestPassed(false);
    setIsTestCanceled(false);
    setClickedInstrumentSearch(normalInstrumentSearch);
    dispatch(changeInstrument(params));

    if (params?.alwaysEnable && params?.subLabel.indexOf(appropriatenessRuleId) > -1) {
      await dispatch(
        getSuitabilityOfSelectedInstrument(
          customerId,
          portfolioId,
          params.financialInstrumentId,
          assignment.side,
          orderInitializerType,
          customerType,
          assignment.orderBasis
        )
      )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        .then(dispatch(setSuitabilityErrors(suitabilityErrorss)))
        .then(setIsLoadingTest(false));
    }

    if (params?.financialInstrumentForm === FUND) {
      // Fetch eligible change pairs + clear suitability errors if side is exchange
      if (assignment.side === EXCHANGE && params.financialInstrumentId) {
        dispatch(fetchChangePairs(params.financialInstrumentId, customerId, portfolioId));
        dispatch(setSuitabilityErrors(undefined));
      }
      await updateTransactionFees(assignment.side, params.financialInstrumentId);
    }
  };

  const onExchangeInstrumentChange = async (params: OrderInstrument) => {
    if (params) {
      setIsLoadingTest(true);
      setIsTestPassed(false);
      setIsTestCanceled(false);
      setClickedInstrumentSearch(exchangeInstrumentSearch);
      if (params?.alwaysEnable && params?.subLabel.indexOf(appropriatenessRuleId) > -1) {
        await dispatch(
          getSuitabilityOfSelectedInstrument(
            customerId,
            portfolioId,
            params.value,
            assignment.side,
            orderInitializerType,
            customerType,
            assignment.orderBasis
          )
        )
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          .then(dispatch(setSuitabilityErrors(suitabilityErrorss)))
          .then(setIsLoadingTest(false))
          .then(
            dispatch(
              setEditorValues({
                counterFinancialInstrumentId: params.value,
                counterFinancialInstrumentName: params.label,
                counterFinancialInstrumentForm: params.financialInstrumentForm,
              } as EditorValues)
            )
          );
      } else {
        dispatch(
          setEditorValues({
            counterFinancialInstrumentId: params.value,
            counterFinancialInstrumentName: params.label,
            counterFinancialInstrumentForm: params.financialInstrumentForm,
          } as EditorValues)
        );
      }
    } else {
      dispatch(
        setEditorValues({
          counterFinancialInstrumentId: '',
          counterFinancialInstrumentName: '',
          counterFinancialInstrumentForm: '',
        } as EditorValues)
      );
    }
  };

  const updateTransactionFees = async (side: OrderSideType, financialInstrumentId: string) => {
    const { portfolioId } = assignment;
    await dispatch(getTransactionFees(customerId, portfolioId, financialInstrumentId, side));
  };

  const onQuantityChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = get(ev, ['target', 'value'], '');
    if (isValidDecimalNumber(value)) {
      dispatch(
        setEditorValues({
          quantity: value === '' ? undefined : convertStringToDecimalNumber(value),
          amount: undefined,
        } as EditorValues)
      );
    }
  };

  const onValueChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = get(ev, ['target', 'value'], '');
    if (isValidDecimalNumber(value)) {
      dispatch(
        setEditorValues({
          amount: value === '' ? undefined : convertStringToDecimalNumber(value),
          quantity: undefined,
        } as EditorValues)
      );
    }
  };

  const onSideChange = (val: { value: OrderSideType }) => {
    let tradeAll = false;
    let side = val.value;
    if (val.value === SELL_ALL) {
      tradeAll = true;
      side = SELL;
    } else if (val.value === EXCHANGE_ALL) {
      tradeAll = true;
      side = EXCHANGE;
    }

    // make sure suitability is ok after side change
    if (side !== assignment.side && assignment.financialInstrumentId) {
      dispatch(
        getSuitabilityOfSelectedInstrument(
          customerId,
          portfolioId,
          assignment.financialInstrumentId,
          side,
          orderInitializerType,
          customerType,
          assignment.orderBasis
        )
      )
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        .then(dispatch(setSuitabilityErrors(suitabilityErrorss)));
    }

    // Fetch eligible change pairs + clear suitability errors if side is exchange
    if (side === EXCHANGE && assignment.financialInstrumentId) {
      dispatch(fetchChangePairs(financialInstrumentId, customerId, portfolioId));
    }

    if (side !== assignment.side || assignment.tradeAll !== tradeAll) {
      if (assignment.financialInstrumentId) {
        if (side === EXCHANGE || assignment.orderBasis !== CLIENT_INITIATIVE) {
          const editorValues = getEditorValuesBasedOnSide(
            assignment,
            side,
            tradeAll,
            selectedPortfolio.contractName,
            selectedPortfolio.contractType
          );
          dispatch(changeSide(editorValues));
        } else {
          // Check suitability of instrument and either alert user or
          // get new editor values based on chosen side and update to redux
          dispatch(
            handleChangeSideWhenInstrumentChosen(
              side,
              assignment,
              tradeAll,
              selectedPortfolio.contractName,
              selectedPortfolio.contractType
            )
          );
        }
      } else {
        // Directly get new editor values based on chosen side and update to redux
        const editorValues = getEditorValuesBasedOnSide(
          assignment,
          side,
          tradeAll,
          selectedPortfolio.contractName,
          selectedPortfolio.contractType
        );
        dispatch(changeSide(editorValues));
      }
    }
    // reset suitability errors on Exchange side when instrument selected
    if (side === EXCHANGE && assignment.financialInstrumentId) {
      dispatch(setSuitabilityErrors(undefined));
      dispatch(setEditorValue('isSuitable', true));
    }
  };

  const getError = (key: string) => validationErrors.find((e) => e.key === key);

  const onFeeChange = (selectedValue: SelectValue) => {
    dispatch(setEditorValue('subscriptionFee', selectedValue.value));
  };

  const onNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setEditorValue('internalNote', event.target.value));
  };

  const onVolumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isValidDecimalNumber(value)) {
      dispatch(setEditorValue('subscriptionSum', convertStringToDecimalNumber(value)));
    }
  };

  const getInstrumentDetailsEditorOrAppropriatenessTest = () => {
    if (
      assignment.orderBasis === CLIENT_INITIATIVE &&
      (assignment.side === BUY || clickedInstrumentSearch === exchangeInstrumentSearch)
    ) {
      const isNormalSearch = assignment.side === BUY;
      let assignmentInstrumentId = isNormalSearch ? assignment.financialInstrumentId : undefined;
      let assignmentInstrumentName = isNormalSearch ? assignment.financialInstrumentName : '';
      if (!assignmentInstrumentId && clickedInstrumentSearch === exchangeInstrumentSearch) {
        assignmentInstrumentId = assignment.counterFinancialInstrumentId
          ? assignment.counterFinancialInstrumentId
          : undefined;
        assignmentInstrumentName = assignment.counterFinancialInstrumentName
          ? assignment.counterFinancialInstrumentName
          : '';
      }
      const possibleSuitabilityError = suitabilityErrorss?.find(
        (x) => x.financialInstrumentId === assignmentInstrumentId
      );
      const enabledWithSuitabilityErrors = possibleSuitabilityError?.ruleId === appropriatenessRuleId;
      const productCategoryId = possibleSuitabilityError?.productCategoryId;

      if (assignmentInstrumentId && enabledWithSuitabilityErrors && !isTestPassed) {
        return getAppropriatenessTest(
          true,
          productCategoryId,
          assignmentInstrumentId,
          assignmentInstrumentName,
          isNormalSearch,
          !isNormalSearch ? assignment.financialInstrumentId : ''
        );
      } else {
        if (assignmentInstrumentId && !needsTest) {
          return getInstrumentDetailsEditor();
        } else {
          return isTestCanceled ? getTestCanceled() : null;
        }
      }
    } else {
      const possibleSuitabilityError = suitabilityErrorss?.find(
        (x) => x.financialInstrumentId === assignment.financialInstrumentId
      );
      const suitabilityErrorIsEmptyOrAppropriatenessRule =
        possibleSuitabilityError?.ruleId === appropriatenessRuleId || !possibleSuitabilityError;
      return assignment.financialInstrumentId && suitabilityErrorIsEmptyOrAppropriatenessRule
        ? getInstrumentDetailsEditor()
        : null;
    }
  };
  const onTestFinished = (testState: AppropriatenessTestState) => {
    if (testState === AppropriatenessTestState.Passed) {
      setTestStates(false, true, true);
    } else if (testState === AppropriatenessTestState.Failed) {
      setTestStates(true, false, false);
      console.log('Appropriateness test failed, resetting form'); // eslint-disable-line no-console
    } else if (testState === AppropriatenessTestState.Canceled) {
      setTestStates(true, false, false, true);
      console.log('Appropriateness test canceled, resetting form'); // eslint-disable-line no-console
    }
  };

  const setTestStates = (needsTest: boolean, testPassed: boolean, isSuitable: boolean, isTestCanceled?: boolean) => {
    setNeedsTest(needsTest);
    setIsTestPassed(testPassed);
    dispatch(setSuitabilityErrors(undefined));
    dispatch(setEditorValue('isSuitable', isSuitable ? true : undefined));
    if (testPassed) {
      dispatch(setEditorValue('testPassed', true));
    }
    if (isTestCanceled) {
      dispatch(changeInstrument(null));
      setIsTestCanceled(true);
    } else {
      setIsTestCanceled(false);
    }
  };

  const getAppropriatenessTest = (
    loading: boolean,
    productCategoryId: string | undefined,
    financialInstrumentId: string,
    financialInstrumentName: string,
    isNormalSearchType: boolean,
    counterFinancialInstrumentName?: string
  ) => {
    return (
      <AppropriatenessTest
        token={token}
        productCategoryId={productCategoryId}
        customerId={customerId}
        onTestFinished={onTestFinished}
        loading={loading}
        financialInstrumentId={financialInstrumentId}
        financialInstrumentName={financialInstrumentName}
        counterFinancialInstrumentName={counterFinancialInstrumentName}
        isNormalSearchType={isNormalSearchType}
      />
    );
  };

  const getTestCanceled = () => {
    return <AppropriatenessTestCanceled />;
  };

  const getInstrumentDetailsEditor = () => {
    if (isListed(instrumentForm, assignment.side)) {
      return (
        <ShareDetails
          isFetchingNominalValue={isFetchingNominalValue}
          onQuantityChange={onQuantityChange}
          onValueChange={onValueChange}
          selectedPortfolio={selectedPortfolio}
          currency={currency}
          assignment={assignment}
          validationErrors={validationErrors}
          touchedFields={touchedFields}
          isAppropriatenessTestOk={testPassed ? testPassed : false}
          isLoading={isLoadingOptions}
        />
      );
    } else if (instrumentForm === FUND) {
      return (
        <FundDetails
          onFeeChange={onFeeChange}
          changePairOptions={changePairOptions}
          isLoadingChangePairOptions={isLoadingChangePairOptions}
          currentFee={currentFee}
          defaultFee={defaultFee}
          isFetchingFee={isFetchingFee}
          onExchangeInstrumentChange={onExchangeInstrumentChange}
          onQuantityChange={onQuantityChange}
          onValueChange={onValueChange}
          currency={currency}
          defaultAccount={defaultAccount}
          portfolioContractName={selectedPortfolio.contractName}
          showMoneySourceForBuyOrder={showMoneySourceForBuyOrder}
          assignment={assignment}
          touchedFields={touchedFields}
          instrumentForm={instrumentForm}
          isAppropriatenessTestOk={testPassed ? testPassed : false}
          validationErrors={validationErrors}
        />
      );
    } else if (instrumentForm === WARRANT) {
      return (
        <WarrantDetails
          onNoteChange={onNoteChange}
          structuredMinimumSum={structuredMinimumSum}
          onChange={onVolumeChange}
          defaultFee={defaultFee}
          defaultAccount={defaultAccount}
          portfolioContractName={selectedPortfolio.contractName}
          assignment={assignment}
          touchedFields={touchedFields}
          getError={getError}
          isAppropriatenessTestOk={testPassed ? testPassed : false}
        />
      );
    } else if (instrumentForm === STRUCTURED_PRODUCT) {
      return (
        <StructuredProductDetails
          onFeeChange={onFeeChange}
          onNoteChange={onNoteChange}
          currentFee={currentFee}
          defaultFee={defaultFee}
          isFetchingFee={isFetchingFee}
          structuredStep={structuredStep}
          structuredMinimumSum={structuredMinimumSum}
          onChange={onVolumeChange}
          defaultAccount={defaultAccount}
          portfolioContractName={selectedPortfolio.contractName}
          assignment={assignment}
          touchedFields={touchedFields}
          getError={getError}
          isAppropriatenessTestOk={testPassed ? testPassed : false}
        />
      );
    } else {
      return (
        <PrivateEquityDetails
          onFeeChange={onFeeChange}
          currentFee={currentFee}
          defaultFee={defaultFee}
          isFetchingFee={isFetchingFee}
          privateEquityMaxSum={privateEquityMaxSum}
          structuredStep={structuredStep}
          structuredMinimumSum={structuredMinimumSum}
          onChange={onVolumeChange}
          selectedPortfolio={selectedPortfolio}
          assignment={assignment}
          touchedFields={touchedFields}
          getError={getError}
          isAppropriatenessTestOk={testPassed ? testPassed : false}
        />
      );
    }
  };

  const content =
    assignment.financialInstrumentId || assignment.counterFinancialInstrumentId
      ? getInstrumentDetailsEditorOrAppropriatenessTest()
      : null;
  // temp flag to override behaviour on existing positions
  const showInstrumentSearchInExistingPosition = true;
  return (
    <section className={classnames(styles[assignment.side], styles.editor)}>
      <div className="row" key="instrument">
        <div className={'columns small-9'}>
          {buyNew || showInstrumentSearchInExistingPosition ? (
            <Instrument
              focusRef={focusRef}
              instrumentForm={instrumentForm}
              instrumentOptions={instrumentOptions}
              isLoadingOptions={isLoadingOptions}
              key={'fromEditor'}
              onInstrumentChange={onInstrumentChange}
              selectedPortfolio={selectedPortfolio}
              orderBasis={orderBasis}
              receiveMethod={receiveMethod}
              setFocus={setFocus}
              customerType={customerType}
            />
          ) : (
            <ExistingPosition assignment={assignment} suitabilityErrors={suitabilityErrors} />
          )}
        </div>

        <SelectSide
          sideOptions={sideOptions}
          sideChange={onSideChange}
          side={sideValue}
          className={classnames('columns small-3', styles.selectSide)}
          isInstrumentSelected={true}
        />
      </div>

      {isVisible || isTestCanceled ? content : null}
    </section>
  );
};
