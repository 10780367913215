import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Set } from 'immutable';
import { setActiveContract } from 'features/contracts/contractsActions';
import { selectTemplates } from 'features/contracts/contractsSelectors';
import Overview from 'features/contracts/components/Overview';
import { RootState } from 'types/rootState';
import { ImmutableTemplates } from 'types/ordersState';
import { ImmutableContent } from 'types/profileState';
import { ImmutableFormErrors } from 'types/contractsState';
import { Spinner } from 'features/common/Spinner';

interface Props {
  params: { id: string };
  templates: ImmutableTemplates;
  values: ImmutableContent;
  isReady: boolean;
  formTouched: Set<string>;
  formErrors: ImmutableFormErrors;
  activeContract: string;
  setActiveContract: (contract: string) => void;
  contractsCompleted: boolean;
  contractIsBusy: boolean;
  profileIsBusy: boolean;
  contractLanguage: string;
}

function ContractsOverviewView(props: Props) {
  const {
    params,
    templates,
    values,
    isReady,
    formTouched,
    formErrors,
    activeContract,
    setActiveContract,
    contractsCompleted,
    contractIsBusy,
    profileIsBusy,
    contractLanguage,
  } = props;

  return profileIsBusy || contractIsBusy ? (
    <Spinner size={100} />
  ) : (
    <Overview
      params={params}
      templates={templates}
      values={values}
      isReady={isReady}
      formTouched={formTouched}
      formErrors={formErrors}
      activeContract={activeContract}
      setActiveContract={setActiveContract}
      contractsCompleted={contractsCompleted}
      contractLanguage={contractLanguage}
    />
  );
}

function mapStateToProps(state: RootState) {
  return {
    templates: selectTemplates(state),
    values: state.contracts.values,
    isReady: state.contracts.isReady,
    formTouched: state.contracts.formTouched,
    formErrors: state.contracts.formErrors,
    activeContract: state.contracts.activeContract,
    contractIsBusy: state.contracts.isBusy,
    profileIsBusy: state.profile.isBusy,
    contractLanguage: state.common.contractLanguage,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators({ setActiveContract }, dispatch);
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ContractsOverviewView);
