import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { formatVarValue } from 'features/allocator/overview/overviewUtils';
import { ConfidenceLevels } from 'types/weightsState';
import { FlexAlignCenter } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';

interface Props {
  confidenceLevels: ConfidenceLevels;
}

export const VaR = ({ confidenceLevels }: Props) => {
  const varOneYear = formatVarValue(1, confidenceLevels?.lowerConfidenceLevel90);
  const varFiveYears = formatVarValue(5, confidenceLevels?.lowerConfidenceLevel90);

  return (
    <Container>
      <FlexAlignCenter>
        <DashedMark />
        <b>{translate('allocator.configureRisk.varExplanation')} (95% VaR)</b>
      </FlexAlignCenter>
      <Table>
        <tbody>
          <tr>
            <td>{translate('allocator.configureRisk.inOneYear')}:</td>
            <td> ≥ {formatToDecimals(varOneYear, 1, '%')}</td>
          </tr>
          <tr>
            <td>{translate('allocator.configureRisk.inFiveYears')}:</td>
            <td> ≥ {formatToDecimals(varFiveYears, 1, '%')}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  margin-left: 1rem;
`;

const Table = styled.table`
  margin-top: 0.5rem;

  td {
    padding: 0 1rem 0.5rem 0;
  }
`;

const DashedMark = styled.div`
  background: white;
  width: 15px;
  height: 2px;
  border-left: 5px solid ${colors.red};
  border-right: 5px solid ${colors.red};
  margin-right: 1rem;
  margin-left: -2rem;
`;
