import React from 'react';
import translate from 'counterpart';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { NumericFormat } from 'react-number-format';
import { styled } from '@mui/system';
import StyledTextField from 'features/common/styledComponents/StyledTextField';
import { ValidationErrorValue } from 'types/orderDialogState';

interface Props {
  error?: ValidationErrorValue | undefined;
  touched: string | boolean | number | undefined;
  value: string | number | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  textFieldProps: { label: string; defaultValue: string };
  disabled?: boolean;
  decimalScale?: number;
  'data-testkey'?: string;
}

const CustomizedTextField = styled(StyledTextField)({
  '& .Mui-focused': {
    '&::after': {
      left: 0,
    },
  },
  '& .MuiInputBase-root': {
    paddingLeft: 0,
    '&::before': {
      left: 0,
    },
  },
  '& .MuiInputBase-input': {
    marginTop: 0,
  },
}) as typeof StyledTextField;

export const Volume = ({
  error,
  touched = false,
  value,
  onChange,
  textFieldProps,
  decimalScale,
  disabled = false,
  'data-testkey': dataTestKey,
  ...rest
}: Props) => {
  return (
    <FormGroup>
      <FormLabel
        sx={{
          color: '#000000',
          fontSize: '12px',
          marginTop: '1.1rem',
        }}
      >
        {textFieldProps.label}
      </FormLabel>
      <NumericFormat
        min={0}
        autoCorrect="off"
        autoComplete="off"
        fullWidth={true}
        type={'text'}
        name={`number-${1}`}
        onChange={onChange}
        value={typeof value === 'undefined' || value === null ? '' : value.toString().replace('.', ',')}
        helperText={
          error && touched ? (
            <span data-testkey={`${dataTestKey}-validation-error`}>{translate(`validations.${error.message}`)}</span>
          ) : undefined
        }
        thousandSeparator={' '}
        decimalSeparator={','}
        prefix={''}
        customInput={CustomizedTextField}
        decimalScale={decimalScale}
        data-testkey={dataTestKey ?? 'order-volume'}
        disabled={disabled}
        variant="standard"
        placeholder={textFieldProps.defaultValue}
        {...rest}
      />
    </FormGroup>
  );
};
