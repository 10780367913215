import { BUY } from 'constants/sides';

export const buyStructuredSchema = {
  properties: {
    financialInstrumentId: {
      type: 'string',
      minLength: 1,
    },
    subscriptionSum: {
      type: 'number',
      minLength: 1,
    },
    side: {
      type: 'string',
      enum: [BUY],
    },
    subscriptionFee: {
      type: 'number',
    },
    internalNote: {
      type: 'string',
    },
    isLeveraged: {
      type: 'boolean',
    },
  },
  type: 'object',
  required: ['side', 'subscriptionFee', 'financialInstrumentId', 'subscriptionSum'],
};
