import React from 'react';
import { branch, renderNothing } from 'recompose';
import classnames from 'classnames';
import translate from 'counterpart';
import { isEmpty } from 'lodash';
import styles from './Table.scss';
import TableRow from './ContractListTableRow';
import { Contract } from 'types/ordersState';

interface Props {
  items: Contract[];
  total: number;
  getContractPdf: () => void;
  contractPdfLoading: string;
}

const ContractListTable = ({ items, total, getContractPdf, contractPdfLoading }: Props) => (
  <table className={classnames(styles.table)}>
    <thead>
      <tr>
        <th>{`${translate('contract.contract')} (${total}) ${translate('contract.pcs')} `}</th>
        <th>{translate(`contract.clientId`)}</th>
        <th>{translate(`contract.clientName`)}</th>
        <th>{translate(`contract.id`)}</th>
        <th>{translate(`contract.status`)}</th>
        <th>{translate(`contract.date`)}</th>
        <th>{translate(`contract.creator`)}</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <TableRow item={item} key={index} getContractPdf={getContractPdf} contractPdfLoading={contractPdfLoading} />
      ))}
    </tbody>
  </table>
);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default branch(({ items }) => isEmpty(items), renderNothing)(ContractListTable);
