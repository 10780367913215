import { RootState } from 'types/rootState';
import {
  SignedContractResponse,
  ContractRequest,
  PostContractResponse,
  Signers,
  Bisnode,
} from 'features/orders/ordersTypes';
import { Contract, OrderDetailsType, Orders, Signatory, Template } from 'types/ordersState';
import { apiCall } from 'core/apiCall';
import { SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING } from 'constants/contractStates';

export const getBillingAddress = (state: RootState, id: string) => {
  let billingEmail = '';
  const defaultAccount = state.portfolio.portfolioDetailsById[id]?.defaultAccount;
  if (defaultAccount && defaultAccount.accountType === '03') {
    billingEmail = state.profile.customer.getIn(['contacts'], [{ email: '' }])[0].email;
  } else {
    billingEmail = 'sijoitusasiantuntijat@aktia.fi';
  }
  return billingEmail;
};

export const getPortfolioParams = (state: RootState, id: string) => {
  return {
    billingEmail: getBillingAddress(state, id),
    contractName: state.portfolio.portfolioDetailsById[id].contractName,
    strategyName: state.portfolio.portfolioDetailsById[id].strategyName,
    defaultAccountType: state.portfolio.portfolioDetailsById[id].defaultAccount
      ? state.portfolio.portfolioDetailsById[id].defaultAccount.accountType
      : undefined,
  };
};

export const postContract = (request: ContractRequest, token: string) => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/orders',
    data: request,
  }) as Promise<PostContractResponse>;
};

export const postSignedContract = (contract: Contract, token: string) => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/orders/signed',
    data: contract,
  }) as Promise<SignedContractResponse>;
};

export const postFinishedContract = (contract: Contract, token: string) => {
  return apiCall({
    method: 'post',
    token,
    path: '/api/v1/orders/finished',
    data: contract,
  }) as Promise<PostContractResponse>;
};

export const getSigners = (customerId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/signer/${customerId}`,
  }) as Promise<Signers>;
};

export const getBisnodeData = (customerId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/client/${customerId}/procurationinfo`,
  }) as Promise<Bisnode>;
};

export const updateBisnodeData = (customerId: string, businessId: string, token: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/businessinfo/${customerId}/procurationinfodescription/${businessId}`,
  }) as Promise<Bisnode>;
};

export const getOrderRequest = (
  state: RootState,
  contract: Contract,
  orderDetails: OrderDetailsType,
  orders: Orders
): ContractRequest => {
  const customerName = state.profile.customer.get('header');
  const customerId = state.profile.customer.get('customerId');

  const signatureInfo = {
    method: '',
    signatories: [] as Signatory[],
  };

  if (orderDetails.signatureMethod === SENT_FOR_SIGNING || orderDetails.signatureMethod === SENT_FOR_INK_SIGNING) {
    signatureInfo.signatories = orders.signatories.toJS();
    signatureInfo.method = orderDetails.signatureMethod === SENT_FOR_SIGNING ? 'Tupas' : 'Ink';
  }

  if (orderDetails.signatureMethod === SENT_FOR_MANUAL_SIGNING) {
    signatureInfo.method = 'Manual';
    contract.manualSignersCount = orderDetails.manualSigners;
  }

  const orderRequest = {
    customerName,
    customerId,
    order: contract,
    signatureInfo,
  };

  return orderRequest;
};

export const getPrefilledContractApiCall = (templateId: string, clientId: string, token: string): Promise<Contract> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/template/${templateId}/client/${clientId}`,
  });
};

export const getOrdersContractTemplateApiCall = (id: string, token: string): Promise<Template> => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/template/${id}`,
  });
};
