import classnames from 'classnames';
import moment from 'moment';
import React from 'react';
import translate from 'counterpart';
import styles from './BasicData.scss';
import { EXPIRY_ALERT_DAYS, EXPIRY_WARNING_DAYS, selectExpirationDate } from 'constants/contracts';
import { Customer, ImmutableCustomer } from 'types/profileState';

interface Props {
  profile: ImmutableCustomer;
}

export default class BasicData extends React.Component<Props> {
  getComponentData(type: string, profile: ImmutableCustomer) {
    if (type === 'person') {
      return [
        { key: 'customer.customerId', value: profile.get('customerId') },
        { key: 'customer.ssn', value: profile.get('ssn') },
        { key: 'customer.accountNumber', value: profile.get('accountNumber') },
        { key: 'customer.segment', value: profile.get('segment') },
        { key: 'common.phoneNumber', value: profile.get('phoneNumber') },
        { key: 'common.email', value: profile.get('email') },
        { key: 'common.city', value: profile.get('city') },
        this.getUpdateDate(
          profile,
          'basicContractSigned',
          'basicContractExpires',
          'basicContractHasExpired',
          'basicContractNotSigned',
          true,
          'mytyContractSigned'
        ),
        this.getUpdateDate(
          profile,
          'nextKycUpdate',
          'nextKycUpdate',
          'nextKycUpdateHasExpired',
          'nextKycUpdateMissing',
          false
        ),
      ];
    }

    if (type === 'company') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const leiNumber = `${moment(profile.get('leiValidUntil')).format('DD.MM.YYYY')} / ${profile.get('leiNumber')}`;
      let leiError = null;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      if (!profile.get('leiNumber')) {
        leiError = translate('common.valueMissing');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
      } else if (moment(profile.get('leiValidUntil')).isBefore(new Date())) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        leiError = `${moment(profile.get('leiValidUntil')).format('DD.MM.YYYY')} ${translate(
          'customer.leiNumberExpired'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
        )} / ${profile.get('leiNumber')}`;
      }
      return [
        { key: 'customer.customerId', value: profile.get('customerId') },
        { key: 'customer.businessId', value: profile.get('businessId') },
        { key: 'customer.accountNumber', value: profile.get('accountNumber') },
        { key: 'customer.leiNumber', value: leiNumber, error: leiError },
        { key: 'customer.segment', value: profile.get('segment') },
        { key: 'common.city', value: profile.get('city') },
        { key: 'customer.primaryContact', value: this.getPrimaryContactName(profile) },
        { key: 'customer.primaryContactPhone', value: this.getPrimaryContact(profile, 'phoneNumber') },
        { key: 'customer.primaryContactEmail', value: this.getPrimaryContact(profile, 'email') },
        this.getUpdateDate(
          profile,
          'basicContractSigned',
          'basicContractExpires',
          'basicContractHasExpired',
          'basicContractNotSigned',
          true,
          'mytyContractSigned'
        ),
        this.getUpdateDate(
          profile,
          'nextKycUpdate',
          'nextKycUpdate',
          'nextKycUpdateHasExpired',
          'nextKycUpdateMissing',
          false
        ),
      ];
    }

    return [];
  }

  getUpdateDate = (
    profile: ImmutableCustomer,
    fieldKey: keyof Customer,
    expirationTranslationKey: string,
    hasExpiredTranslationKey: string,
    notDoneTranslationKey: string,
    calculateExpirationDate: boolean,
    mytyContractSigned?: keyof Customer
  ) => {
    const expirationField = this.selectDate(profile, calculateExpirationDate, fieldKey, mytyContractSigned);

    const addIcon = true;
    if (!expirationField || !expirationField.signDate) {
      return {
        key: 'customer.' + notDoneTranslationKey,
        error: translate('common.valueMissing'),
        addIcon,
      };
    }

    const expiryDate = expirationField.expirationDate;
    const expiryDateStr = expirationField.expirationDateFormatted;
    const daysUntilExpiry = expirationField.daysUntilExpiry;
    const disclaimer = expirationField.expirationDate?.disclaimer;

    let classNames = null;
    let error = null;
    let key = 'customer.' + expirationTranslationKey;

    if (expiryDate?.date?.isBefore(moment())) {
      key = 'customer.' + hasExpiredTranslationKey;
      error = expiryDateStr;
      classNames = styles.hasBlink;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
    } else if (daysUntilExpiry <= EXPIRY_ALERT_DAYS) {
      error = expiryDateStr;
      classNames = styles.hasBlink;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
    } else if (daysUntilExpiry <= EXPIRY_WARNING_DAYS) {
      error = expiryDateStr;
    } else {
      classNames = styles.hasEmphasize;
    }

    return {
      key,
      value: expiryDateStr,
      error,
      classNames,
      addIcon,
      disclaimer,
    };
  };

  getPrimaryContactName = (profile: ImmutableCustomer) => {
    const firstName = this.getPrimaryContact(profile, 'firstName');
    const lastName = this.getPrimaryContact(profile, 'lastName');
    return `${firstName} ${lastName}`;
  };

  getPrimaryContact = (profile: object, property: string) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const companyProfile = profile.toJS();
    const contacts = companyProfile.contacts || [];
    const contactinfo = contacts.length ? contacts[0][property] : '';
    return contactinfo;
  };

  selectDate(
    profile: ImmutableCustomer,
    calculateExpirationDate: boolean,
    basicContractSigned: keyof Customer,
    mytyContractSigned?: keyof Customer
  ) {
    const signDate: string = (profile.get(basicContractSigned) as string)
      ? (profile.get(basicContractSigned) as string)
      : '';
    const mytySignDate = mytyContractSigned ? (profile.get(mytyContractSigned) as string) : undefined;

    return selectExpirationDate(calculateExpirationDate, signDate, mytySignDate);
  }

  render() {
    const { profile } = this.props;
    const type = profile.get('customerType');
    const data = this.getComponentData(type, profile);
    const components = data.map((d, index) => (
      <div key={index} className={classnames('row', 'align-justify')}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <div className={classnames(d.error ? styles.hasError : null, d.classNames)} title={d.disclaimer}>
          {translate(d.key)}&nbsp;
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          {d.addIcon === true ? <i className={d.error ? 'icon icon-exclamation' : 'icon icon-check'} /> : null}&nbsp;
        </div>
        <div>{d.error ? d.error : d.value}</div>
      </div>
    ));
    return <div>{components}</div>;
  }
}
