export default {
  additionInProgress: 'Asiakkaan luonti on kesken',
  additionInProgressContent: 'Haluatko siirtyä etusivulle ja poistaa syöttämäsi tiedot?',
  errors: {
    isDuplicate: {
      title: 'Asiakas on jo järjestelmässä',
      body: 'Voit etsiä asiakasta hakutoiminnolla',
    },
    noCustomerType: {
      title: 'Ei valittua asiakastyyppiä',
      body: 'Valitse ensin vähintään asiakastyyppi',
    },
    unexpected: {
      title: 'Virhe',
      body: 'Odottamaton virhetilanne, ole hyvä ja yritä uudelleen.',
    },
  },
  revealOk: 'Jatka',
  topBarTitle: 'Lisää uusi asiakkuus',
  topBarButton: 'Luo asiakkuus',
  takePhoto: 'Valokuva henkilötodistuksesta',
  idValidDateLabel: 'Viimeinen voimassaolopäivä',
  identityDateOfIssueLabel: 'Myöntämispäivä',
  identityMethodIssuer: 'Tunnistamisasiakirjan myöntäjä',
  identityMethodIssuerCountry: 'Tunnistamisasiakirjan myöntänyt valtio',
  identityMethodNumber: 'Tunnistamisasiakirjan numero',
  documentType: 'Tunnistamisasiakirjan tyyppi',
};
