import React from 'react';
import translate from 'counterpart';
import { ModalDialog } from 'features/common/ModalDialog';
import { PortfolioById } from 'types/portfolioState';
import { SuitabilityResult } from 'features/orderDialog/orderDialogTypes';
import { OrderLineType } from 'types/ordersState';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  initializeState: () => void;
  goBack: () => void;
  editor: OrderLineType;
  selectedPortfolio: PortfolioById;
  suitabilityErrors: SuitabilityResult[];
}

export const OrderFromButtonFailDialog = ({
  initializeState,
  goBack,
  editor,
  selectedPortfolio,
  suitabilityErrors,
}: Props) => {
  const actions = [
    <StyledButton variant="outlined" onClick={goBack} key={'order.goBack'}>
      {translate('order.goBack')}
    </StyledButton>,
    <StyledButton variant="contained" onClick={initializeState} key={'order.empty'}>
      {translate('order.empty')}
    </StyledButton>,
  ];

  const infoText = translate('order.suitabilityInfoFromButton', {
    name: editor.financialInstrumentName,
    portfolio: selectedPortfolio.externalId,
  });

  return (
    <ModalDialog
      title={translate('order.suitabilityInfoHeader')}
      open={true}
      actions={actions}
      content={
        <>
          {infoText} <br />
          {suitabilityErrors.map((e) => {
            return e.messages.map((i) => (
              <div key={e.ruleId}>
                {translate('order.suitabilityRule', {
                  ruleId: e.ruleId,
                  message: i,
                })}
              </div>
            ));
          })}
        </>
      }
    />
  );
};
