import React from 'react';
import translate from 'counterpart';
import { formatDate } from 'core/functions';
import styles from './ExpirationDate.scss';

interface Props {
  expirationDate: string;
}

export const ExpirationDate = ({ expirationDate }: Props) => {
  //An ID which expires today is still valid. Thus the current time is set to 00:00:00. 27.2.2019 Ville Reippainen
  const getDateWithoutTime = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const isIdExpired = () => new Date(expirationDate) < getDateWithoutTime();

  const formattedExpirationDate = formatDate(expirationDate, 'D.M.YYYY');

  return isIdExpired() ? (
    <span className={styles.valid}>{` - ${formattedExpirationDate} (${translate('customer.expired')})`}</span>
  ) : (
    <span className={styles.valid}>{` - ${formattedExpirationDate} (${translate('customer.validUntil')})`}</span>
  );
};
