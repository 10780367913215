import React from 'react';
import translate from 'counterpart';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  hideConfirmDialog: () => void;
  confirmSigning: () => void;
  showContent: boolean;
  showConfirmDialog: boolean;
}

export const ConfirmationDialog = ({ hideConfirmDialog, confirmSigning, showContent, showConfirmDialog }: Props) => {
  const actions = [
    <StyledButton
      data-testkey="order-creation-button"
      variant="text"
      onClick={confirmSigning}
      key={'order.createOrders'}
    >
      {translate('order.createOrders')}
    </StyledButton>,
    <StyledButton variant="text" onClick={hideConfirmDialog} key={'order.cancel'}>
      {translate('order.cancel')}
    </StyledButton>,
  ];

  const content = showContent ? translate('order.confirmContent') : '';

  return (
    <ModalDialog
      title={translate('order.confirmOrderCreation')}
      open={showConfirmDialog}
      actions={actions}
      content={content}
    />
  );
};
