import React from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { FlexColumn } from 'features/common/StyledComponents';
import { ErrorIcon } from 'features/allocator/common/SvgIcon';

export const FullScreenError = () => (
  <Container data-testkey="error-prevent-use">
    <ErrorIcon width="120px" />
    <h2>{translate('errors.somethingWentWrong')}</h2>
    <p>{translate('errors.tryAgainLater')}</p>
  </Container>
);

const Container = styled(FlexColumn)`
  align-items: center;

  img {
    margin: 2rem;
  }
`;
