import React, { useState, ChangeEvent, FocusEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Select, SelectChangeEvent } from '@mui/material';
import { ModalDialog } from 'features/common/ModalDialog';
import { isValidSignatory, isValidSSN, isValidEmail, isValidPhone } from 'core/validations';
import styles from './NewSignatoryDialog.scss';
import { Signatory, SignatoryFormErrors } from 'types/ordersState';
import StyledTextField from 'features/common/styledComponents/StyledTextField';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  onCloseClick: () => void;
  onAddClick: (signatory: Signatory, contract: { name: string }) => void;
  isOpen: boolean;
  contract: { name: string };
  savedSigners: Signatory[];
}

export const NewSignatoryDialog = ({
  contract,
  onCloseClick,
  onAddClick,
  isOpen = false,
  savedSigners = [],
}: Props) => {
  const [formValues, setFormValues] = useState<Signatory>({
    firstName: '',
    lastName: '',
    ssn: '',
    email: '',
    phone: '',
  });

  const [formErrors, setFormErrors] = useState<SignatoryFormErrors>({
    ssn: undefined,
    email: undefined,
    phone: undefined,
  });

  const handleSignatureSelection = (event: SelectChangeEvent<Signatory>) => {
    const value = event.target.value as Signatory;
    setFormValues(value);
  };

  const handleClose = () => {
    onCloseClick();
  };

  const handleAdd = () => {
    onAddClick(formValues, contract);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.id]: e.target.value });
  };

  const fieldValidator =
    (field: string, validator: (value: string) => boolean, errorText: string) => (e: ChangeEvent<HTMLInputElement>) => {
      if (!validator(e.target.value)) {
        setFormErrors({ ...formErrors, [field]: errorText });
      } else {
        setFormErrors({ ...formErrors, [field]: undefined });
      }
    };

  const isSignatoryDetailsValid = () => isValidSignatory(formValues);

  const renderTextField = (
    label: string,
    id: string,
    hint?: string,
    onBlurHandler?: (e: FocusEvent<HTMLInputElement>) => void
  ) => {
    return (
      <StyledTextField
        id={id}
        key={id}
        label={label}
        fullWidth={true}
        onChange={handleOnChange}
        onBlur={onBlurHandler}
        helperText={formErrors[id as keyof SignatoryFormErrors]}
        placeholder={hint}
        value={formValues[id as keyof Signatory]}
        autoComplete="off"
        data-testkey={`textfield-${id}`}
        variant="standard"
        sx={[
          {
            '&.MuiFormControl-root': {
              marginTop: '15px',
            },
            '& .Mui-focused': {
              '&::after': {
                left: 0,
              },
            },
            '& .MuiInputBase-root': {
              marginTop: '14px',
              marginBottom: '15px',
              paddingLeft: 0,
              '&::before': {
                left: 0,
              },
            },
            '& .MuiInputBase-input': {
              fontFamily: 'Roboto, sans-serif',
              marginTop: 0,
            },
            '& label': {
              fontSize: '16px',
              '&.Mui-focused': {
                fontSize: '16px',
              },
            },
            '& > p': {
              '&.MuiFormHelperText-root': {
                paddingLeft: '0',
              },
            },
            '&.Mui-error': {
              borderBottomColor: '#d32f2f',
              borderWidth: '2px',
              '&::before': {
                borderWidth: '2px',
                borderBottomColor: '#d32f2f',
              },
            },
          },
        ]}
      />
    );
  };

  const modalDialogActions = (
    <>
      <StyledButton
        onClick={handleAdd}
        disabled={!isSignatoryDetailsValid()}
        data-testkey={`Lisää allekirjoittaja-button`}
        variant="text"
        key="Lisää allekirjoittaja-button"
      >
        {'Lisää allekirjoittaja'}
      </StyledButton>
      <StyledButton variant="text" onClick={handleClose} key="peruuta-button">
        {'Peruuta'}
      </StyledButton>
    </>
  );

  const modalDialogContent = (
    <div>
      {savedSigners.length > 0 && (
        <div className={styles.select}>
          <Select
            onChange={handleSignatureSelection}
            value={formValues}
            placeholder="Valitse allekirjoittaja"
            renderValue={(selected) => {
              if (selected.email === '') {
                return <span>Valitse allekirjoittaja</span>;
              }
              return selected.firstName + ' ' + selected.lastName + ', ' + selected.ssn;
            }}
            data-testkey="saved-signers-select"
            variant="standard"
            sx={{
              '&.MuiPopover-root': {
                top: '50px',
              },
              '&.MuiInputBase-root': {
                width: '40%',
                marginBottom: '15px',
                color: 'rgba(0, 0, 0, 0.6)',
                '&::after': {
                  borderBottomColor: '#000000',
                },
                ':focus': {
                  backgroundColor: '#ffffff',
                },
              },
              '& .MuiSelect-select': {
                backgroundColor: '#FFF',
                fontFamily: 'Calibre, sans-serif',
                fontSize: '16px',
              },
              '& .MuiSelect-nativeInput': {
                fontFamily: 'Calibre, sans-serif',
                fontSize: '16px',
              },
            }}
          >
            {savedSigners.map((m) => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              <MenuItem
                value={m}
                key={m.ssn}
                sx={{
                  '&.MuiMenuItem-root': {
                    fontFamily: 'Calibre, sans-serif',
                    fontSize: '16px',
                  },
                  '&.Mui-selected': {
                    fontFamily: 'Calibre, sans-serif',
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                    ':hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.06)',
                    },
                  },
                }}
              >
                {`${m.firstName} ${m.lastName} (${m.ssn})`}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}

      <div>
        {renderTextField('Etunimi *', 'firstName', '')}

        {renderTextField('Sukunimi *', 'lastName', '')}

        {renderTextField(
          'Henkilötunnus *',
          'ssn',
          '',
          fieldValidator('ssn', isValidSSN, 'Virheellinen henkilötunnus!')
        )}

        {renderTextField(
          'Sähköposti *',
          'email',
          '',
          fieldValidator('email', isValidEmail, 'Virheellinen sähköposti!')
        )}

        {renderTextField(
          'Puhelinnumero *',
          'phone',
          'Muodossa +358123456789',
          fieldValidator('phone', isValidPhone, 'Virheellinen puhelinnumero!')
        )}
      </div>
    </div>
  );

  return (
    <ModalDialog
      title={`Lisää uusi allekirjoittaja - ${contract.name}`}
      actions={modalDialogActions}
      open={isOpen}
      content={modalDialogContent}
    />
  );
};
