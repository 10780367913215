export const IMAGE = 'image';
export const IMAGE_DATAURI = 'imageDataURI';
export const IDENTITY_METHOD = 'identityMethod';
export const DESCRIPTION = 'description';
export const IDENTITY_METHOD_ISSUER = 'identityMethodIssuer';
export const IDENTITY_METHOD_NUMBER = 'identityMethodIdentificationNumber';
export const IDENTITY_METHOD_DATE_OF_ISSUE = 'identityMethodDateOfIssue';
export const IDENTITY_METHOD_EXPIRATION_DATE = 'identityMethodExpirationDate';
export const IDENTITY_METHOD_ISSUER_COUNTRY = 'identityMethodIssuerCountry';
export const CUSTOMER_TYPE = 'sections.basicInfo.customerType';
export const CUSTOMER_CATEGORISATION = 'sections.basicInfo.customerCategorisation';
