import React from 'react';
import classnames from 'classnames';
import translate from 'counterpart';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from 'react-select-old';
import moment from 'moment';
import _ from 'lodash';
import ClearIcon from '@mui/icons-material/Clear';
import styles from './SearchFilters.scss';
import {
  instrumentForms,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  InstrumentForm,
} from 'constants/instrumentForms';
import { contractStates } from 'constants/contractStates';
import { SearchFiltersType } from 'types/orderListState';
import { FilterType } from 'features/listView/listView.types';

interface Props {
  searchFilters: SearchFiltersType;
  mergeSearchFilters: (key: keyof SearchFiltersType, value: string | Date | undefined) => void;
  searchItems: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleInstrumentKeywords: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: FilterType;
  editor: boolean;
  bankers: { value: string }[];
}

interface DateStates {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export default class SearchFilters extends React.Component<Props, DateStates> {
  static defaultProps = {
    editor: false,
    bankers: [],
  };

  bankers: { value: string }[];
  states: {
    value: string;
    label: string;
  }[];
  instrumentTypes: {
    value: InstrumentForm;
    label: string;
  }[];

  constructor(props: Props) {
    super(props);
    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setStates = this.setStates.bind(this);
    this.setForms = this.setForms.bind(this);
    this.resetStartDate = this.resetStartDate.bind(this);
    this.resetEndDate = this.resetEndDate.bind(this);

    this.state = {
      startDate: null,
      endDate: null,
    };

    this.states = _.map(contractStates, (s) => ({
      value: s,
      label: translate(`contractStates.${s}`),
    }));

    this.instrumentTypes = _.map(instrumentForms, (i) => ({
      value: i,
      label: translate(`order.${i}`),
    })).filter((e) => e.value !== PRIVATE_EQUITY_PROFIT_SHARING_LOAN && e.value !== CO_INVESTMENTS_PROFIT_SHARING_LOAN);

    this.bankers = this.getBankers(this.props.bankers);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.bankers.length !== nextProps.bankers.length) {
      this.bankers = this.getBankers(nextProps.bankers);
    }
  }

  getBankers(bankers: { value: string }[]) {
    return bankers.map((c) => {
      if (c.value === 'webshop') {
        return {
          value: 'webshop',
          label: translate('orderList.webshop'),
        };
      }
      return c;
    });
  }

  setStartDate(value: Date | null | undefined, keyboardInputValue?: string | undefined): void {
    this.props.mergeSearchFilters('startDate', keyboardInputValue);
  }

  setEndDate(_ev: never, date: Date) {
    this.props.mergeSearchFilters('endDate', date);
  }

  handleStartDateChange = (date: Dayjs | null) => {
    this.setState({ startDate: date });
    this.props.mergeSearchFilters('startDate', date !== null ? date.toString() : '');
  };

  handleEndDateChange = (date: Dayjs | null) => {
    this.setState({ endDate: date });
    this.props.mergeSearchFilters('endDate', date !== null ? date.toString() : '');
  };

  setStates(states: { value: string }[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.mergeSearchFilters('states', states ? states.map((state) => state.value) : null);
  }

  setBankers = (bankers: { value: string }[] | null) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.mergeSearchFilters('bankers', bankers ? bankers.map((banker) => banker.value) : null);
  };

  setForms(forms: { value: string }[]) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    this.props.mergeSearchFilters('instrumentForms', forms ? forms.map((f) => f.value) : null);
  }

  resetStartDate() {
    this.setState({ startDate: null });
    this.props.mergeSearchFilters('startDate', undefined);
  }

  resetEndDate() {
    this.setState({ endDate: null });
    this.props.mergeSearchFilters('endDate', undefined);
  }

  formatDate = (date: Date) => moment(date).format('DD.MM.YYYY');

  render() {
    const { searchFilters, type, handleInstrumentKeywords, searchItems } = this.props;
    const clearStyle = { width: '15px' };
    const theme = createTheme({
      components: {
        MuiFormControl: {
          styleOverrides: {
            root: {
              '& > p': {
                '&.MuiFormHelperText-root': {
                  color: '#F44336',
                  fontSize: '12px',
                  marginBottom: '24px',
                  marginTop: '0px',
                },
              },
            },
          },
        },
        MuiInput: {
          styleOverrides: {
            root: {
              marginBottom: '5px',
              '&.Mui-focused': {
                '& > input': {
                  border: 'none',
                  boxShadow: 'none',
                },
                '&::after': {
                  borderColor: '#000000',
                },
              },
            },
            input: {
              color: '#000000',
              boxShadow: 'none',
              width: '100%',
              fontSize: '16px',
            },
          },
        },
        MuiInputLabel: {
          styleOverrides: {
            root: {
              color: '#000000',
              fontSize: '14px',
              '&.Mui-focused': {
                color: '#000000',
              },
            },
          },
        },
      },
    });
    return (
      <section>
        <div className={classnames('row', styles.filterRow)}>
          <div className={classnames('columns', styles.filterContainer)}>
            <ThemeProvider theme={theme}>
              <TextField
                label={translate('orderList.searchLabel')}
                onChange={searchItems}
                value={searchFilters.searchString || ''}
                style={{
                  width: '100%',
                }}
                data-testkey="textfield-search-by-name-or-id"
                variant="standard"
              />
            </ThemeProvider>
          </div>
          {type === 'orders' ? (
            <div className={classnames('columns', styles.filterContainer)}>
              <ThemeProvider theme={theme}>
                <TextField
                  label={translate('orderList.instrumentFilter')}
                  onChange={handleInstrumentKeywords}
                  value={searchFilters.instrumentKeywords || ''}
                  style={{
                    width: '100%',
                  }}
                  data-testkey="textfield-search-instrument"
                  variant="standard"
                />
              </ThemeProvider>
            </div>
          ) : (
            ''
          )}
          <div className={classnames('columns', styles.datePicker)}>
            <div className={styles.filterHeader}>{translate(`orderList.filterByDate`)}</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="DD.MM.YYYY"
                value={searchFilters && searchFilters.startDate ? dayjs(searchFilters.startDate) : this.state.startDate}
                onChange={this.handleStartDateChange}
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input': {
                      border: 'none',
                      boxShadow: 'none',
                    },
                    '&.Mui-focused': {
                      '& > .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: '--.--.----',
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <i className={classnames(styles.clearIcon)} onClick={this.resetStartDate}>
              <ClearIcon style={clearStyle} />
            </i>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                inputFormat="DD.MM.YYYY"
                value={searchFilters && searchFilters.endDate ? dayjs(searchFilters.endDate) : this.state.endDate}
                onChange={this.handleEndDateChange}
                InputProps={{
                  sx: {
                    '& .MuiInputBase-input': {
                      border: 'none',
                      boxShadow: 'none',
                    },
                    '&.Mui-focused': {
                      '& > .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23)',
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: '--.--.----',
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <i className={classnames(styles.clearIcon)} onClick={this.resetEndDate}>
              <ClearIcon style={clearStyle} />
            </i>
          </div>
        </div>
        <div className={classnames('row', styles.filterRow)}>
          <div className={classnames('columns', styles.filterContainer)} data-testkey="select-state">
            <div className={styles.filterHeader}>{translate(`contract.contractStatus`)}</div>
            <Select
              name="state"
              options={this.states}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange={this.setStates}
              multi={true}
              noResultsText={translate('orderList.selectNoResultText')}
              placeholder={translate('orderList.select')}
              value={searchFilters ? searchFilters.states : undefined}
              backspaceToRemoveMessage=""
            />
          </div>
          <div className={classnames('columns', styles.filterContainer)}>
            <div className={styles.filterHeader}>{translate(`orderList.banker`)}</div>
            <Select
              name="bankers"
              options={this.bankers}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              onChange={this.setBankers}
              multi={true}
              noResultsText={translate('orderList.selectNoResultText')}
              placeholder={translate('orderList.select')}
              value={searchFilters ? searchFilters.bankers : undefined}
              backspaceToRemoveMessage=""
            />
          </div>
          {type === 'orders' ? (
            <div className={classnames('columns', styles.filterContainer)}>
              <div className={styles.filterHeader}>{translate(`orderList.instrumentForm`)}</div>
              <Select
                name="forms"
                options={this.instrumentTypes}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                onChange={this.setForms}
                multi={true}
                noResultsText={translate('orderList.selectNoResultText')}
                placeholder={translate('orderList.select')}
                value={searchFilters ? searchFilters.instrumentForms : undefined}
                backspaceToRemoveMessage=""
              />
            </div>
          ) : (
            ''
          )}
        </div>
      </section>
    );
  }
}
