import { apiCall } from 'core/apiCall';
import { ApiCallData } from 'types/types';

export class ApiClient {
  token = '';

  get(path: string, apiCallData?: ApiCallData) {
    return apiCall({
      method: 'get',
      path,
      params: apiCallData?.params ?? undefined,
      data: apiCallData?.data ?? undefined,
      token: this.getToken(),
    });
  }

  post(path: string, apiCallData?: ApiCallData) {
    return apiCall({
      method: 'post',
      path,
      params: apiCallData?.params ?? undefined,
      data: apiCallData?.data ?? undefined,
      token: this.getToken(),
    });
  }

  put(path: string, apiCallData?: ApiCallData) {
    return apiCall({
      method: 'put',
      path,
      params: apiCallData?.params ?? undefined,
      data: apiCallData?.data ?? undefined,
      token: this.getToken(),
    });
  }

  patch(path: string, apiCallData?: ApiCallData) {
    return apiCall({
      method: 'patch',
      path,
      params: apiCallData?.params ?? undefined,
      data: apiCallData?.data ?? undefined,
      token: this.getToken(),
    });
  }

  delete(path: string, apiCallData?: ApiCallData) {
    return apiCall({
      method: 'delete',
      path,
      params: apiCallData?.params ?? undefined,
      data: apiCallData?.data ?? undefined,
      token: this.getToken(),
    });
  }

  getToken() {
    return this.token;
  }

  setToken(token: string) {
    this.token = token;
  }
}
