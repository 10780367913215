import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Flex } from 'features/common/StyledComponents';
import { StyledRadioButton } from './StyledRadioButton';

interface Props {
  checked: boolean;
  disabled?: boolean;
  value: string | number;
  type?: 'checkbox' | 'radio';
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

export const RadioButton = ({ checked, disabled = false, className, value, type, onChange }: Props) => (
  <Flex data-testkey={`${checked ? '' : 'not-'}checked`}>
    <HiddenRadioButton checked={checked} value={value} type={type} onChange={onChange} disabled={disabled} />
    <StyledRadioButton checked={checked} disabled={disabled} type={type} className={className} />
  </Flex>
);

// Hide checkbox visually but remain accessible to screen readers.
const HiddenRadioButton = styled.input.attrs((props) =>
  props.type === 'checkbox' ? { type: 'checkbox' } : { type: 'radio' }
)`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
