import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import { Card } from 'features/allocator/common/Card';
import { Flex } from 'features/common/StyledComponents';
import { INSTRUMENT_ASSET_CATEGORY_ORDER, WEIGHT_LIMIT } from 'constants/allocator';
import { ASSET_CATEGORY_COLORS } from 'styles/colors';
import { InstrumentWithWeight } from 'types/instrumentsState';

interface Props {
  totalValue: number;
  instruments: InstrumentWithWeight[];
  maxWeight: number;
}

export const CustomerSummaryInstruments = ({ totalValue, instruments, maxWeight }: Props) => {
  return (
    <InstrumentsCard>
      <h2>{translate('allocator.summaryView.planInstruments')}</h2>
      <table>
        <thead>
          <tr>
            <th>
              <label>{translate('allocator.summaryView.instrument')}</label>
            </th>
            <th>
              <label>{translate('allocator.summaryView.weight')}</label>
            </th>
            <th />
            <th>
              <label>EUR</label>
            </th>
          </tr>
        </thead>

        {INSTRUMENT_ASSET_CATEGORY_ORDER.map((assetCategory) => {
          const assetCategoryInstruments = instruments?.filter((i) => i.assetCategory === assetCategory) || [];
          const assetCategoryWeight = assetCategoryInstruments.reduce((acc, curr) => acc + (curr?.weight || 0), 0);

          if (assetCategoryInstruments?.length === 0 || assetCategoryWeight < WEIGHT_LIMIT) {
            return null;
          }

          return (
            <React.Fragment key={assetCategory}>
              <thead>
                <tr>
                  <th colSpan={5}>{assetCategory}</th>
                </tr>
              </thead>
              <tbody>
                {assetCategoryInstruments.map((i) => {
                  if (!i.weight || i.weight < WEIGHT_LIMIT) {
                    return null;
                  }
                  return (
                    <tr key={i.security}>
                      <td>{i.name}</td>
                      <td>{formatToDecimals(i.weight, 1, '%')}</td>
                      <BarTD>
                        <BarContainer>
                          <Bar width={(i?.weight || 0) / maxWeight} bg={ASSET_CATEGORY_COLORS[assetCategory]} />
                        </BarContainer>
                      </BarTD>
                      <td>{formatToDecimals(totalValue * (i?.weight || 0), 0, '€')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </React.Fragment>
          );
        })}
      </table>
    </InstrumentsCard>
  );
};

const InstrumentsCard = styled(Card)`
  box-shadow: none;
  grid-area: instruments;

  table {
    width: 100%;
  }

  td,
  th {
    &:not(:first-child) {
      text-align: right;
    }

    padding-left: 1rem;
  }

  th {
    padding-top: 1rem;
    font-weight: 900;
  }
`;

const BarTD = styled.td`
  && {
    padding-left: 0.5rem;
  }
`;

const BarContainer = styled(Flex)`
  align-items: flex-end;
  width: 100px;
`;

interface BarProps {
  width: number;
  bg: string;
}

const Bar = styled.div<BarProps>`
  width: ${(props) => props.width * 100}%;
  height: 12px;
  background: ${(props) => props.bg};
`;
