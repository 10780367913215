import { Map, List, Set, fromJS } from 'immutable';
import {
  WIZARD_FIELD_VALIDATION,
  NEW_CUSTOMER_OPTION_UPDATE,
  NEW_CUSTOMER_FORM_REQUEST,
  NEW_CUSTOMER_FORM_SUCCESS,
  NEW_CUSTOMER_FORM_FAILURE,
  CREATE_CUSTOMER_REQUEST,
  CREATE_CUSTOMER_RECEIVE,
  CREATE_CUSTOMER_FAILURE,
  DISCARD_CUSTOMER,
  ERROR_OK,
  ProspectAction,
} from './prospectActions';
import { validateFormValues, updateCustomerOptions } from 'core/index';
import { ProspectsState } from 'types/prospectsState';
import { prospectErrors } from 'features/prospect/prospectConstants';

export const initialState: ProspectsState = {
  valid: false,
  isFetching: false,
  isBusy: false,
  formFields: [],
  formValues: Map(),
  formErrors: List(),
  formTouched: Set(),
  // @ts-expect-error: null is not of type Schema
  schema: null,
  template: {},
  ui: {
    canContinue: false,
    isSearching: false,
  },
  error: null,
  errors: prospectErrors,
};

// Validate active module against schema
const formValidation = (state: ProspectsState, fieldKey: string) => {
  const errors = fromJS(validateFormValues(state.formValues.toJS(), state.schema));
  const touched = state.formTouched.add(`wizard.${fieldKey}`);
  return {
    ...state,
    formErrors: errors,
    formTouched: touched,
    valid: errors.size === 0,
  };
};

export const prospectReducer = (state = initialState, action: ProspectAction) => {
  switch (action.type) {
    case WIZARD_FIELD_VALIDATION:
      return formValidation(state, action.fieldKey);

    case NEW_CUSTOMER_OPTION_UPDATE:
      return updateCustomerOptions(state, action.option);

    case NEW_CUSTOMER_FORM_REQUEST:
      return {
        ...state,
        isFetching: true,
        formFields: [],
        formTouched: state.formTouched.clear(),
        formErrors: state.formErrors.clear(),
        schema: null,
      };

    case NEW_CUSTOMER_FORM_SUCCESS: {
      const schema = action.result.schema;
      const errors = fromJS(validateFormValues(state.formValues.toJS(), schema));
      return {
        ...state,
        isFetching: false,
        formFields: action.result.form.items[0].items,
        schema: action.result.schema,
        formErrors: errors,
        valid: errors.size === 0,
        template: action.result,
      };
    }

    case NEW_CUSTOMER_FORM_FAILURE:
      return {
        ...state,
        isFetching: false,
        formFields: [],
        schema: null,
      };

    case CREATE_CUSTOMER_REQUEST:
      return {
        ...state,
        isBusy: true,
      };

    case CREATE_CUSTOMER_RECEIVE:
      return {
        ...state,
        formValues: state.formValues.clear(),
        formTouched: state.formTouched.clear(),
        formErrors: state.formErrors.clear(),
        isBusy: false,
        valid: false,
        error: null,
      };

    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        isBusy: false,
        error: state.errors[action.error],
      };

    case DISCARD_CUSTOMER:
      return {
        ...state,
        formValues: state.formValues.clear(),
        formTouched: state.formTouched.clear(),
        formErrors: state.formErrors.clear(),
        schema: null,
        valid: false,
      };

    case ERROR_OK:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
