import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { Card } from 'features/allocator/common/Card';
import { createOptimizedPortfoliosFromOptimizedValues } from 'features/weights/weightsSelectors';
import { CustomerSummaryPortfolio } from 'features/portfolioManager/customerSummary/components/CustomerSummaryPortfolio';

interface Props {
  cashAmount: number;
}

export const CustomerSummaryPortfolios = ({ cashAmount }: Props) => {
  const portfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const portfolioWeights = portfolios.map((p) =>
    p.allocatedPortfolioRows.withoutIlliquids.reduce((acc, curr) => acc + (curr?.weight || 0), 0)
  );
  const maxWeight = Math.max(...portfolioWeights);

  return (
    <InstrumentsCard>
      <h2>{translate('allocator.summaryView.portfolioDistribution')}</h2>

      <table>
        <tbody>
          {portfolios.map((portfolio) => (
            <CustomerSummaryPortfolio
              key={portfolio.portfolioId}
              portfolio={portfolio}
              maxWeight={maxWeight}
              cashAmount={cashAmount}
            />
          ))}
        </tbody>
      </table>
    </InstrumentsCard>
  );
};

const InstrumentsCard = styled(Card)`
  grid-area: portfolios;
  box-shadow: none;

  table {
    width: 100%;
  }

  th {
    padding-top: 1rem;
    font-weight: 900;
  }

  td {
    padding-right: 1rem;
  }
`;
