import React, { FocusEvent } from 'react';
import { connect } from 'react-redux';
import Scroll from 'react-scroll';
import { bindActionCreators, Dispatch } from 'redux';
import Immutable from 'immutable';
import Promise from 'bluebird';
import { WithRouterProps } from 'react-router';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import { isRequired, doesQuestionPassConditionTest } from 'core/functions';
import { getModule } from 'core/index';
import FieldList from 'features/fieldList/FieldList';
import {
  changeSectionField,
  fieldValidation,
  moduleValidation,
  touchFields,
  crmPersonSearch,
} from 'features/contracts/contractsActions';
import { saveArrayEditorValue } from 'actions';
import { RiskConfirmationView } from 'features/contracts/riskConfirmation/RiskConfirmationView';
import { ImmutableContent } from 'types/profileState';
import { FormError, Schema, Countries, Locale, SearchPersonResponse } from 'types/contractsState';
import { Template, Item, FieldType, Condition, Value } from 'types/ordersState';
import { RootState } from 'types/rootState';

const scroller = Scroll.scroller;

interface ComponentProps {
  values: ImmutableContent;
  formTouched: Immutable.Set<string>;
  touchFields: (touchedFields: FieldType[], contractId: string) => void;
  moduleValidation: (module: Item, schema: Schema, contractId: string) => void;
  changeSectionField: (key: string, value: Value, contractId: string) => void;
  fieldValidation: (key: string, schema: Schema, contractId: string) => void;
  module: Item;
  schema: Schema;
  formErrors: FormError[];
  countries: Countries;
  template: Template;
  contractId: string;
  locale: Locale;
  showOnlyUnfinished: boolean;
  saveArrayEditorValue: (contractType: string, key: string, index: number | null) => void;
  crmPersonSearch: (input: string) => Promise<SearchPersonResponse>;
  contractLanguage: Locale;
}

type Props = ComponentProps & WithRouterProps;

class ContractQuestionsView extends React.Component<Props> {
  static DURATION = 750;
  static OFFSET = -150;

  componentDidMount() {
    const { module, contractId, schema } = this.props;
    const touchedFields = module.items.filter((field) => {
      const fieldRequired = isRequired(schema, field.key);
      const value = this.resolveValue(field.key);

      const fieldIsNotRequired = !fieldRequired;
      const valueNotEmpty = value !== '';
      const valueNotZero = value !== 0;
      const valueIsArray = Array.isArray(value) ? value.length >= 0 : true;
      const valueNotUndefined = !isUndefined(value);
      const valueNotNull = !isNull(value);

      let addToTouched =
        fieldIsNotRequired || (valueNotEmpty && valueNotZero && valueIsArray && valueNotUndefined && valueNotNull);

      if (field.key.indexOf('customTextData') > -1) {
        addToTouched = false;
      }
      return addToTouched;
    });

    this.props.touchFields(touchedFields, contractId);
  }

  componentWillUnmount() {
    const { module, schema, contractId, moduleValidation } = this.props;
    moduleValidation(module, schema, contractId);
  }

  handleFocus = (index: number, ev: FocusEvent<{}>) => {
    const targetElement = `field${index}`;
    scroller.scrollTo(targetElement, {
      duration: ContractQuestionsView.DURATION,
      offset: ContractQuestionsView.OFFSET,
      smooth: 'easeInOutQuint',
    });
    ev.preventDefault();
    ev.stopPropagation();
  };

  handleChange = (key: string, value: Value) => {
    const { contractId, changeSectionField } = this.props;
    changeSectionField(key, value, contractId);
  };

  validate = (field: FieldType) => {
    const { schema, contractId, fieldValidation } = this.props;
    fieldValidation(field.key, schema, contractId);
  };

  resolveValue = (key: string): Value => {
    const { values, contractId } = this.props;
    const path = [contractId].concat(key.split('.'));
    let value = values.getIn(path);
    if (value && value.toJS) {
      value = value.toJS();
    }
    return value;
  };

  fulfillsCondition = (condition: Condition, value: Value) => {
    const { values, contractId, template } = this.props;
    return doesQuestionPassConditionTest(values.get(contractId).toJS(), condition, template.form.items, value);
  };
  render() {
    const {
      module,
      schema,
      values,
      contractId,
      formErrors,
      formTouched,
      countries,
      locale,
      template,
      showOnlyUnfinished,
      contractLanguage,
    } = this.props;

    // skip paragraphs which should not be shown.
    const linkTypeKey = 'textLinkKey';
    const tableKey = 'tableKey';
    const fields = module.items.filter(
      (field) => field.type !== 'text' || field.key.includes(linkTypeKey) || field.key.includes(tableKey)
    );

    if (module.key === 'sections.riskConfirmation') {
      return <RiskConfirmationView />;
    }

    return (
      <FieldList
        fields={fields}
        schema={schema}
        values={values.get(contractId).toJS()}
        onChange={this.handleChange}
        onFocus={this.handleFocus}
        onBlur={this.validate}
        errors={formErrors}
        touched={formTouched}
        resolveValue={this.resolveValue}
        wizardSection={false}
        countries={countries}
        contractId={contractId}
        locale={locale}
        template={template}
        showOnlyUnfinished={showOnlyUnfinished}
        saveArrayEditorValue={this.props.saveArrayEditorValue}
        crmPersonSearch={this.props.crmPersonSearch}
        fulfillsCondition={this.fulfillsCondition}
        isArrayQuestion={false}
        contractLanguage={contractLanguage}
        textFieldAsWizardSection={true}
      />
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    { changeSectionField, fieldValidation, moduleValidation, touchFields, saveArrayEditorValue, crmPersonSearch },
    dispatch
  );
}

function mapStateToProps(state: RootState, ownProps: WithRouterProps) {
  const contractId = ownProps.params.contract;
  const moduleId = ownProps.params.module;
  const template = state.contracts.templates.get(contractId);
  const activeModule = getModule(template, moduleId);
  const formErrors = state.contracts.formErrors.get(contractId);
  return {
    values: state.contracts.values,
    formTouched: state.contracts.formTouched,
    showOnlyUnfinished: state.contracts.showOnlyUnfinished,
    formErrors: formErrors ? formErrors.toJS() : [],
    countries: state.common.countries,
    template,
    locale: state.common.locale as Locale,
    ...activeModule,
    contractLanguage: state.common.contractLanguage as Locale,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
export default connect(mapStateToProps, mapDispatchToProps)(ContractQuestionsView);
