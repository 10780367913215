import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import styled from 'styled-components';
import {
  isLoadingInitialItems,
  isOptimizingPlan,
  selectAum,
  selectCurrentWeights,
} from 'features/allocator/allocatorSelectors';
import { CustomerSummaryAssetClasses } from 'features/portfolioManager/customerSummary/components/CustomerSummaryAssetClasses';
import { FlexJustifyBetween, styles } from 'features/common/StyledComponents';
import { ASSET_CATEGORY_COLORS } from 'styles/colors';
import { AssetCategoryName } from 'types/types';
import { Grid } from 'features/allocator/common/Grid';
import { ConfidenceLevels } from 'types/weightsState';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { LinkBack } from 'features/common/LinkBack';
import { Spinner } from 'features/common/Spinner';
import { YieldGraphAndLegend } from 'features/allocator/common/YieldGraphAndLegend';
import { CreatePNG } from 'features/portfolioManager/customerSummary/components/CreatePNG';
import { RadioButton } from 'features/allocator/common/RadioButton';
import { CustomerSummaryPie } from 'features/portfolioManager/customerSummary/components/CustomerSummaryPie';
import { CustomerSummaryPortfolios } from 'features/portfolioManager/customerSummary/components/CustomerSummaryPortfolios';
import { CustomerSummaryInstruments } from 'features/portfolioManager/customerSummary/components/CustomerSummaryInstruments';
import { customerHasCurrentWeights } from 'features/weights/weightsSelectors';

export const SituationSummaryView = () => {
  const [showDifference, setShowDifference] = useState<boolean>(true);
  const aum = useSelector(selectAum);
  const currentWeights = useSelector(selectCurrentWeights);
  const hasCurrentWeights = useSelector(customerHasCurrentWeights);

  const customerId = useSelector(selectCustomerId);
  const loadingInitialItems = useSelector(isLoadingInitialItems);
  const optimizingPlan = useSelector(isOptimizingPlan);

  const confidenceLevels = (currentWeights?.confidenceLevels ||
    currentWeights?.companyOptimizationForecastStatistics?.confidenceLevels) as ConfidenceLevels;

  const portfolioReturn = currentWeights.returnStatistics.portfolioReturn;

  const backLink = `customer/${customerId}/portfolioManager`;

  const toggleDifference = () => setShowDifference((showDifference) => !showDifference);

  const maxWeight = Math.max(
    ...currentWeights.portfolioWeights.flatMap((p) => p.instruments).flatMap((a) => a?.weight || 0)
  );

  return (
    <ContainerGrid vertical>
      <LinkBack
        to={backLink}
        label={translate('portfolioManager.general.backToManager')}
        data-testkey="summary-back-to-allocator"
      />

      {(loadingInitialItems || optimizingPlan) && <StyledSpinner />}

      <Settings>
        <ShowDifferenceToggle>
          <RadioButton
            type="checkbox"
            checked={showDifference}
            onChange={toggleDifference}
            value={'skipBackendConstraints'}
          />
          {translate('allocator.summaryView.showWeightDifference')}
        </ShowDifferenceToggle>
        <CreatePNG preventCreateImages={!hasCurrentWeights} />
      </Settings>

      <PrintableArea1 className="customersummary">
        <CustomerSummaryPie weights={currentWeights} totalValue={aum} />
        <CustomerSummaryAssetClasses weights={currentWeights} showDifference={showDifference} />
        <ProjectionChart>
          <YieldGraphAndLegend confidenceLevels={confidenceLevels} portfolioReturn={portfolioReturn} startValue={aum} />
        </ProjectionChart>
      </PrintableArea1>

      <PrintableArea2 className="customersummary">
        <CustomerSummaryPortfolios cashAmount={aum} />
        <Instruments>
          <CustomerSummaryInstruments
            maxWeight={maxWeight}
            instruments={currentWeights.instrumentWeights}
            totalValue={aum}
          />
        </Instruments>
      </PrintableArea2>
    </ContainerGrid>
  );
};

const StyledSpinner = styled(Spinner)`
  margin: 2rem;
`;

const ContainerGrid = styled(Grid)`
  margin: 2rem;
`;

const PrintableArea1 = styled(Grid)`
  grid-template-columns: 3fr 4fr 2fr;
  grid-template-areas:
    'pie assetClasses assetClasses'
    'chart chart zzz';
`;

const PrintableArea2 = styled(Grid)`
  grid-template-columns: 3fr 4fr 2fr;
  grid-template-areas:
    'portfolios portfolios xxx'
    'instruments instruments yyy';
`;

interface AssetCategoryRow {
  assetCategory: AssetCategoryName;
}

const Row = styled(FlexJustifyBetween)`
  margin-bottom: 0.5rem;
`;

const AssetCategoryRow = styled(Row)<AssetCategoryRow>`
  color: ${(props) => ASSET_CATEGORY_COLORS[props.assetCategory]};
`;

const ProjectionChart = styled.div`
  ${styles.card}
  box-shadow: none;
  grid-area: chart;
`;

const Instruments = styled.div`
  grid-area: instruments;
`;

const Settings = styled(FlexJustifyBetween)`
  justify-content: space-between;
  grid-template-columns: auto auto;
  align-items: flex-start;
`;

const ShowDifferenceToggle = styled.label`
  padding-top: 1.5rem;
  display: flex;
  user-select: none;
  align-items: center;
  cursor: pointer;
  margin-left: 2rem;
`;
