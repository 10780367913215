import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { CheckBox } from 'features/orders/components/CheckBox';
import { FormTitleSmall } from 'features/common/styledComponents/StyledText';
import { selectShowSuitabilityToDateCheck } from 'features/orders/ordersSelectors';
import { useAppDispatch } from 'core/hooks';
import { setIsSuitabilityAssessmentToDateValue } from 'features/orderDialog/orderDialogActions';
import { RootState } from 'types/rootState';

const SuitabilityToDateCheck = ({ onChange }: { onChange: (value: boolean) => void }) => {
  const suitabilityAssessmentToDate = useSelector((state: RootState) => state.orderDialog.suitabilityAssessmentToDate);
  return (
    <CheckBox
      label={'Soveltuvuusarviointi on ajan tasalla'}
      onChange={(event) => onChange(event.target.checked)}
      value={suitabilityAssessmentToDate}
    />
  );
};

export const Checks = () => {
  const showSuitabilityToDateCheck = useSelector(selectShowSuitabilityToDateCheck);
  const showChecks = showSuitabilityToDateCheck;

  const dispatch = useAppDispatch();

  if (!showChecks) {
    return null;
  }

  return (
    <ChecksContainer>
      <FormTitleSmall>Tarkistukset</FormTitleSmall>
      {showSuitabilityToDateCheck && (
        <SuitabilityToDateCheck onChange={(checked) => dispatch(setIsSuitabilityAssessmentToDateValue(checked))} />
      )}
    </ChecksContainer>
  );
};

const ChecksContainer = styled.div`
  margin-top: 20px;
`;
