import React from 'react';
import translate from 'counterpart';
import { NavBar } from 'features/common/NavBar';
import styles from './ContractsNav.scss';

interface Props {
  customerName: string;
  saveDrafts: () => void;
  discard: () => void;
  enableSave: boolean;
  close: () => void;
  isReady: boolean;
  onReady: () => void;
  saveContractDrafts: () => void;
}

export default class ContractsNav extends React.Component<Props> {
  static defaultProps = {
    customerName: '',
  };

  defaultActions = () => {
    const { saveDrafts, enableSave, discard, close } = this.props;
    return (
      <div className={styles.right}>
        <button disabled={!enableSave} onClick={saveDrafts} data-testkey="save-contract-drafts-button">
          <i className="icon icon-save" />
        </button>
        <button onClick={discard} data-testkey="delete-contract-drafts-button">
          <i className="icon icon-delete" />
        </button>
        <button onClick={close} className={styles.close} data-testkey="close-contract-view-button">
          <span>{translate('contract.close')}</span>
          <i className="icon icon-close" />
        </button>
      </div>
    );
  };

  readyActions = () => {
    const { onReady } = this.props;
    return (
      <div>
        <button onClick={onReady} className={styles.ready} data-testkey="button-contract-ready">
          {translate('contract.ready')}
        </button>
      </div>
    );
  };

  render() {
    const { customerName, isReady, enableSave, saveContractDrafts } = this.props;
    const headerText = customerName ? `${customerName} - Uusi sopimus` : '';
    const actions = isReady ? this.readyActions() : this.defaultActions();
    return (
      <NavBar
        leftContent={<div className={styles.header}>{headerText}</div>}
        rightContent={actions}
        showModalOnExit={enableSave}
        modalTitle={translate('contract.toFrontPageTitle')}
        modalContent={translate('contract.toFrontPageContent')}
        acceptAction={saveContractDrafts}
        acceptLabel="contract.saveAndNavigate"
      />
    );
  }
}
