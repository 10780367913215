import React from 'react';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Link } from 'react-router';
import { config } from 'config';
import { HelpText, HelpTextSmall } from 'features/common/StyledComponents';
import { StepNavigationItem, StepItemHeader, LowerPart, LinkButtonWithMargin } from './StepNavigationStyles';
import { RootState } from 'types/rootState';
import { SELECT_CORE_MANUALLY } from 'features/allocator/coreSelection/components/ChoosePlan';
import { StepNavigationStepProps } from 'features/allocator/stepNavigation/stepNavigationTypes';
import { Spinner } from 'features/common/Spinner';
import { selectCustomerId } from 'features/profile/profileSelectors';
import {
  selectHasPositions,
  selectInstrumentsSelected,
  selectPositionsNotInPlan,
} from 'features/allocator/allocatorSelectors';
import { customerHasCurrentWeights } from 'features/weights/weightsSelectors';

export const StepCore = ({ unset, disableEdit, locked, loading }: StepNavigationStepProps) => {
  const instrumentsSelected = useSelector(selectInstrumentsSelected);
  const selectedInstrumentGroup = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.instruments.selectedInstrumentGroup
  );
  const hasCurrentWeights = useSelector(customerHasCurrentWeights);
  const customerId = useSelector(selectCustomerId);
  const hasPositions = useSelector(selectHasPositions);
  const instrumentGroups = useSelector((state: RootState) => state.portfolioManager.commonData.instrumentGroups);
  const positionsNotInPlan = useSelector(selectPositionsNotInPlan);

  const coreIsSet = !!selectedInstrumentGroup;

  const coreSelection = `customer/${customerId}/portfolioManager/allocator/core`;
  const instrumentSelection = `customer/${customerId}/portfolioManager/allocator/instruments`;

  const coreHasBeenModified =
    coreIsSet &&
    selectedInstrumentGroup &&
    selectedInstrumentGroup !== SELECT_CORE_MANUALLY &&
    !isEqual(
      instrumentGroups.find((g) => g.groupId === selectedInstrumentGroup)?.securities.sort(),
      instrumentsSelected.map((instrument) => instrument?.security).sort()
    );

  const buttonText = (hasCurrentWeights && 'Add') || (coreIsSet ? 'Change' : 'Select');

  const allowEditInstruments = !hasPositions || config.editInstruments;

  return (
    <StepNavigationItem unset={unset}>
      <div>
        <StepItemHeader>
          {translate('allocator.investmentPlanSteps.core.coreTitle')}
          {coreIsSet &&
            (hasCurrentWeights || disableEdit || locked ? (
              <span>{translate('allocator.investmentPlanSteps.core.coreChange')}</span>
            ) : (
              <Link to={coreSelection}>{translate('allocator.investmentPlanSteps.core.coreChange')}</Link>
            ))}
        </StepItemHeader>

        {loading && <Spinner position="left" size={30} />}

        {!loading &&
          (coreIsSet ? (
            instrumentGroups.find((g) => g.groupId === selectedInstrumentGroup)?.displayName ||
            translate('allocator.investmentPlanSteps.core.coreIsManual')
          ) : (
            <HelpText>{translate('allocator.investmentPlanSteps.core.coreDetails')}</HelpText>
          ))}
      </div>

      <LowerPart>
        {coreIsSet && instrumentsSelected.length === 0 && (
          <HelpTextSmall>{translate('allocator.investmentPlanSteps.core.noInstrumentsSelected')}!</HelpTextSmall>
        )}

        {coreHasBeenModified && (
          <HelpTextSmall>{translate('allocator.investmentPlanSteps.core.coreHasBeenChanged')}</HelpTextSmall>
        )}

        {positionsNotInPlan.length > 0 && (
          <HelpTextSmall>{translate('allocator.investmentPlanSteps.core.somePositionsNotInPlan')}</HelpTextSmall>
        )}

        <LinkButtonWithMargin
          to={coreIsSet ? instrumentSelection : coreSelection}
          secondary={coreIsSet}
          label={translate(`allocator.investmentPlanSteps.core.button${buttonText}`)}
          disabled={disableEdit || locked || !allowEditInstruments || positionsNotInPlan.length > 0}
          data-testkey={'select-core-button'}
        />
      </LowerPart>
    </StepNavigationItem>
  );
};
