import styled from 'styled-components';
import React from 'react';
import { Constraint } from 'types/constraintsState';
import { FlexAlignCenter, Flex } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { EditIcon } from 'features/allocator/common/EditIcon';
import { ConstraintSlider } from 'features/allocator/constraints/components/ConstraintSlider';
import { ConstraintType } from 'types/types';
import { Button } from 'features/allocator/common/Button';

interface Props {
  constraint: Constraint;
  onUpdate: (updatedConstraint: Constraint) => boolean;
  constraintType: ConstraintType;
  openCustomConstraintEditor: (constraintId?: string) => void;
}

export const CustomConstraintRow = ({ constraint, onUpdate, constraintType, openCustomConstraintEditor }: Props) => {
  return (
    <Flex key={constraint.id}>
      <RowLabel>
        <EditCustomConstraintButton label={constraint.label} onClick={() => openCustomConstraintEditor(constraint.id)}>
          <StyledEditIcon />
          <StyledCustomConstraintSubRowLabel>{constraint.label}</StyledCustomConstraintSubRowLabel>
        </EditCustomConstraintButton>
      </RowLabel>

      <ConstraintSlider
        type="custom"
        value={[constraint.min, constraint.max]}
        updatedItemId={constraint.id}
        constraint={constraint}
        onUpdate={onUpdate}
        constraintType={constraintType}
      />
    </Flex>
  );
};

const labelWidth = 300;

const StyledSubRowLabel = styled.p`
  margin-left: 3rem;
  white-space: normal;
`;

const StyledEditIcon = styled(EditIcon)`
  && {
    width: 1.5rem;
    margin-right: 0.75rem;
    fill: ${colors.primary};
  }
`;

const StyledCustomConstraintSubRowLabel = styled(StyledSubRowLabel)`
  margin: 0;
`;

const EditCustomConstraintButton = styled(Button)`
  display: flex;
  align-self: center;
  flex: auto;
  justify-content: flex-start;
  background: none;
  color: ${colors.link_blue};
  box-shadow: none;
  border: none;
  font-weight: 500;
  padding: 0;
`;

const RowLabel = styled(FlexAlignCenter)`
  width: ${labelWidth}px;
`;
