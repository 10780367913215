import { Config } from 'types/types';
import { getWindowLocationHost, getWindowLocationOrigin } from './configHelper';

const config: Config = {
  appEnv: 'pr', // feel free to remove the appEnv property here
  baseUrl: `//${getWindowLocationHost()}/jumpstart-api`,
  basePath: '/',
  oidc: {
    client_id: 'aad.prod',
    redirect_uri: `${getWindowLocationOrigin()}/callback/`,
    response_type: 'id_token token',
    scope:
      'openid read:customers write:customers read:account-managers read:templates write:templates read:contracts write:contracts write:signature-requests read:signature-requests roles read:business-information read:orders write:orders read:jobs read:products read:privateequityfundinvestments write:users r:difs',
    authority: 'https://wealth-api.aktia.fi/identity',
    silent_redirect_url: `${getWindowLocationOrigin()}/silent_renew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    acr_values: 'idp:aad',
    triggerAuthFlow: true,
    monitorSession: false,
  },
  portfolioManager: false,
  planSummaryView: true,
  customerListBankers: false,
  enableErrorLogging: false,
  enableReduxTrace: false,
  useBackendConstraints: true,
  existingCustomerTryRisk: true,
  editInstruments: false,
  allowedUserRole: 'bd-user-prd', // bd-user-prd, Azure AD Application role --> adfs old value: PT_User
  mytySuitabilityAssessmentUrl: 'https://myty.aktia.biz/Soveltuvuusarvio/#/aloitus',
};

export default config;
