import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import html2canvas from 'html2canvas';
import { selectCustomer } from 'features/profile/profileSelectors';
import { Flex, HelpText, styles } from 'features/common/StyledComponents';
import { Button } from 'features/allocator/common/Button';
import { Grid } from 'features/allocator/common/Grid';
import { Spinner } from 'features/common/Spinner';

interface Props {
  preventCreateImages: boolean;
}

export const CreatePNG = ({ preventCreateImages }: Props) => {
  const [imageData, setImageData] = useState<string[]>([]);
  const [creatingImage, setCreatingImage] = useState<boolean>(false);

  const customerId = useSelector(selectCustomer).toJS().customerId;

  const createPNG = async () => {
    const domElements = document.getElementsByClassName('customersummary');
    setCreatingImage(true);
    const arr = Array.from(domElements) as HTMLElement[];

    if (domElements) {
      setImageData([]);
      const data = await createImageData(arr);
      setImageData(data);
      setCreatingImage(false);
    }
  };

  const createImageData = async (arr: HTMLElement[]) => {
    const newData: string[] = [];
    await Promise.all(
      arr.map(async (element) => {
        await html2canvas(element, {
          backgroundColor: null,
          logging: false, // set to true for debugging
        }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          newData.push(imgData);
        });
      })
    );
    return newData;
  };

  const download = (index: number) => {
    const fileName = `${translate('allocator.summaryView.filenamePrefix')}_${customerId}_${index}.png`;
    const link = document.createElement('a');
    link.download = fileName;
    link.href = imageData[index];
    link.click();
  };

  const openNewWindow = (index: number) => {
    const newWindow = window.open('about:blank', translate('allocator.summaryView.filenamePrefix'));
    newWindow?.document.write(
      `<img src=${imageData[index].replace(/^data:image\/[^;]/, 'data:application/octet-stream')} />`
    );
  };

  return (
    <ImagesGrid vertical>
      <div>
        {creatingImage && <Spinner />}
        {!creatingImage && !imageData.length && (
          <HelpText>{translate('allocator.summaryView.createPngExplanation')}</HelpText>
        )}

        <CreateImagesButton
          onClick={createPNG}
          label={translate('allocator.summaryView.createImage')}
          disabled={preventCreateImages}
          data-testkey="button-create-image"
        />
      </div>

      {imageData.length > 0 && (
        <Grid autoColumns>
          {imageData.map((i, index) => (
            <div key={i}>
              <Image key={index} src={i} onClick={() => openNewWindow(index)} data-testkey={`plan-image-${index}`} />
              <Buttons>
                {imageData ? (
                  <a onClick={() => download(index)} data-testkey={`save-plan-image_${index}`}>
                    {translate('allocator.summaryView.saveImage')}
                  </a>
                ) : null}
                {imageData ? (
                  <a onClick={() => openNewWindow(index)} data-testkey={`open-plan-image_${index}`}>
                    {translate('allocator.summaryView.openImage')}
                  </a>
                ) : null}
              </Buttons>
            </div>
          ))}
        </Grid>
      )}
    </ImagesGrid>
  );
};

const ImagesGrid = styled(Grid)`
  ${styles.card}
`;

const Image = styled.img`
  max-height: 200px;
  max-width: 200px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Buttons = styled(Flex)`
  padding: 0.5rem 0;

  > a {
    padding-right: 1rem;
  }
`;

const CreateImagesButton = styled(Button)`
  margin-top: 1rem;
`;
