import React from 'react';
import styled from 'styled-components';
import translate from 'counterpart';
import { isAllocator } from 'features/allocator/allocatorUtils';
import { HelpText } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';

export const DifferenceExplanation = () => {
  const path = isAllocator() ? 'allocator' : 'manager';

  return (
    <HelpTextCenter>
      {translate(`common.differenceExplanation.showing`)}
      {translate(`common.differenceExplanation.value_${path}`)}
      {translate(`common.differenceExplanation.andDifference`)}
      {translate(`common.differenceExplanation.to_${path}`)} (
      <Green>{translate(`common.differenceExplanation.buy`)}</Green> /{' '}
      <Red>{translate(`common.differenceExplanation.sell`)}</Red>)
    </HelpTextCenter>
  );
};

const HelpTextCenter = styled(HelpText)`
  grid-area: difference_explanation;
  font-weight: 500;
  margin: 1rem 0 1rem 0;
  display: inline-block;
  padding-left: 10px;
  border-left: 5px dotted ${colors.primary};
  margin-right: 0;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: ${colors.black};
`;

const Red = styled.span`
  color: ${colors.red};
`;
const Green = styled.span`
  color: ${colors.green};
`;
