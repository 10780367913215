import { Config } from 'types/types';
import { getWindowLocationHost, getWindowLocationOrigin } from './configHelper';

const config: Config = {
  appEnv: 'ci', // feel free to remove the appEnv property here
  baseUrl: `//${getWindowLocationHost()}/jumpstart-api`,
  basePath: '/jumpstart',
  oidc: {
    client_id: 'aad.dev',
    redirect_uri: `${getWindowLocationOrigin()}/jumpstart/callback/`,
    response_type: 'id_token token',
    scope:
      'openid read:customers write:customers read:account-managers read:templates write:templates read:contracts write:contracts write:signature-requests read:signature-requests roles read:business-information read:orders write:orders read:jobs read:products read:privateequityfundinvestments write:users r:difs',
    authority: 'https://apitest.aktia.biz/identity',
    silent_redirect_url: `${getWindowLocationOrigin()}/jumpstart/silent_renew.html`,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    acr_values: 'idp:aad',
    triggerAuthFlow: true,
    monitorSession: false,
  },
  portfolioManager: false,
  planSummaryView: true,
  customerListBankers: true,
  enableErrorLogging: false,
  enableReduxTrace: false,
  useBackendConstraints: true,
  existingCustomerTryRisk: true,
  editInstruments: true,
  allowedUserRole: 'bd-user-dev', // bd-user-dev, Azure AD Application role
  mytySuitabilityAssessmentUrl: 'https://int-myty.aktia.biz/Soveltuvuusarvio/#/aloitus',
};

export default config;
