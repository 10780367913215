import React from 'react';
import translate from 'counterpart';
import styled, { css } from 'styled-components';
import { useSelector } from 'react-redux';
import { LinkButton } from './LinkButton';
import {
  Flex,
  FlexAlignCenter,
  subPageHeaderHeight,
  customerHeaderHeight,
  styles,
} from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { Button } from './Button';
import { Spinner } from 'features/common/Spinner';
import { selectHasPositions, isOptimizingPlan } from 'features/allocator/allocatorSelectors';
import { customerHasBothOptimizedWeights } from 'features/weights/weightsSelectors';

interface Props {
  headerLabel: string;
  customerId?: string;
  hasChanged: boolean;
  view?: string;
  cancelLabel?: string;
  returnPath?: string;
  discardChanges: () => void;
  saveChanges: () => void;
  onCloseClick?: () => void;
}

export const AllocatorHeader = ({
  customerId,
  headerLabel,
  hasChanged,
  discardChanges,
  saveChanges,
  cancelLabel,
  returnPath,
  view,
  onCloseClick,
}: Props) => {
  const optimizingPlan = useSelector(isOptimizingPlan);
  const hasOptimizedWeights = useSelector(customerHasBothOptimizedWeights);

  const existingCustomer = useSelector(selectHasPositions);
  const disableSave = optimizingPlan || !customerId || (existingCustomer && view === 'portfolios' && hasChanged);
  const optimized = !optimizingPlan && !disableSave && hasOptimizedWeights;

  const showCancelButton = view === 'portfolios' || hasChanged;
  const showSaveButton = hasChanged || (view === 'portfolios' && hasOptimizedWeights);
  const showCloseAsButton = !hasChanged && view !== 'portfolios';

  return (
    <HeaderContainer>
      <h1>{headerLabel}</h1>
      <FlexAlignCenter>
        <OptimizingText optimizingPlan={optimizingPlan}>
          {optimized && `✔ ${translate('allocator.optimized')}`}
          {optimizingPlan && <Spinner size={30} horizontal message={translate('allocator.header.optimizing')} />}
        </OptimizingText>

        {showCancelButton && (
          <CancelButton onClick={discardChanges} data-testkey={'header-cancel-button'} disabled={!customerId}>
            {cancelLabel || translate('allocator.header.cancelChanges')}
          </CancelButton>
        )}

        {showSaveButton && (
          <Button
            onClick={saveChanges}
            disabled={disableSave}
            label={translate('allocator.header.save')}
            data-testkey={'header-save-button'}
          />
        )}

        {showCloseAsButton && !onCloseClick && (
          <LinkButton
            to={returnPath || `customer/${customerId}/portfolioManager/allocator/`}
            label={translate('allocator.header.close')}
            data-testkey={'header-close-button'}
            disabled={optimizingPlan || !customerId}
          />
        )}

        {showCloseAsButton && onCloseClick && (
          <Button
            onClick={onCloseClick}
            label={translate('allocator.header.close')}
            data-testkey={'header-close-button'}
            disabled={optimizingPlan || !customerId}
          />
        )}
      </FlexAlignCenter>
    </HeaderContainer>
  );
};

interface OptimizingProps {
  optimizingPlan: boolean;
}

const OptimizingText = styled.div<OptimizingProps>`
  opacity: ${(props) => (props.optimizingPlan ? 1 : 0)};
  transition: opacity ${(props) => (props.optimizingPlan ? '.1s' : '1s 2s')};
  margin-right: 1rem;
  ${(props) =>
    !props.optimizingPlan &&
    css`
      color: ${colors.primary_dark};
      font-weight: 700;
    `}
`;

const HeaderContainer = styled(Flex)`
  border-bottom: 1px solid ${colors.warm_gray};
  margin: 0 0 1.5rem;
  height: ${subPageHeaderHeight}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: ${customerHeaderHeight};
  z-index: 3;
  background: ${colors.gray_light};
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  h1 {
    margin-bottom: 0;
  }
`;

const CancelButton = styled.button`
  color: ${colors.link_blue};
  font-weight: 500;
  margin-right: 1rem;

  ${styles.link}

  &:disabled {
    opacity: 0.5;
  }
`;
