import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as oidcReducer } from 'redux-oidc';
import { weightsReducer } from 'features/weights/weightsSlice';
import { portfoliosReducer } from 'features/allocator/investmentPlan/investmentPlanReducer';
import { commonReducer } from './modules/commonReducer';
import { searchReducer } from 'features/search/searchReducer';
import { contractsReducer } from 'features/contracts/contractsReducer';
import { prospectReducer } from 'features/prospect/prospectReducer';
import { profileReducer } from 'features/profile/profileReducer';
import { profileEditReducer } from 'features/profileEdit/profileEditReducer';
import { portfolioReducer } from 'features/portfolio/portfolioReducer';
import { ordersReducer } from 'features/orders/ordersReducer';
import { orderListReducer } from 'features/orderList/orderListReducer';
import { contractListReducer } from 'features/contractList/contractListReducer';
import { meetingNotesReducer } from 'features/meetingNotes/meetingNotesReducer';
import { orderDialogReducer } from 'features/orderDialog/orderDialogReducer';
import { orderLinesReducer } from 'features/orderLines/orderLinesReducer';
import { riskReducer } from 'features/risk/riskReducer';
import { newCustomerStepsReducer } from 'features/newCustomerSteps/newCustomerStepsReducer';
import { valueDataReducer } from 'features/portfolioManager/valueData/valueDataReducer';
import { commonDataReducer } from 'features/portfolioManager/commonData/commonDataReducer';

export const rootReducer = combineReducers({
  portfolioManager: combineReducers({
    weights: weightsReducer,
    investmentPlan: portfoliosReducer,
    commonData: commonDataReducer,
    valueData: valueDataReducer,
  }),
  oidc: oidcReducer,
  routing: routerReducer,
  common: commonReducer,
  search: searchReducer,
  prospects: prospectReducer,
  contracts: contractsReducer,
  profile: profileReducer,
  profileEdit: profileEditReducer,
  portfolio: portfolioReducer,
  orderDialog: orderDialogReducer,
  orders: ordersReducer,
  orderList: orderListReducer,
  orderLines: orderLinesReducer,
  contractList: contractListReducer,
  meeting: meetingNotesReducer,
  risk: riskReducer,
  newCustomerSteps: newCustomerStepsReducer,
});
