import React, { Component } from 'react';
import classnames from 'classnames';
import HardwareKeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Refresh from '@mui/icons-material/Refresh';
import translate from 'counterpart';
import { Map, List, Set } from 'immutable';
import Promise from 'bluebird';
import FieldList from 'features/fieldList/FieldList';
import { crmPersonSearch } from 'features/contracts/contractsActions';
import styles from './TabContent.scss';
import { RegistryFieldList } from './RegistryFieldList';
import { SSN_PATH, BUSINESSID_PATH } from 'constants/templateConstants';
import { PERSON, COMPANY, INSTITUTION } from 'constants/customerTypes';
import { Spinner } from 'features/common/Spinner';
import { Template, Item, Value } from 'types/ordersState';
import { Locale, Schema, SearchPersonResponse } from 'types/contractsState';
import { ImmutableContract } from 'types/profileState';

interface Props {
  formValues: ImmutableContract;
  bisnodeValues: Map<string, object>;
  formErrors: List<string>;
  formTouched: Set<string>;
  changeSectionField: (key: string, value: Value, schema: Schema) => void;
  getBisnodeData: (value: string) => void;
  saveArrayEditorValue: () => void;
  notFoundFromBisnode: boolean;
  fetchingFromBisnode: boolean;
  copyValues: (bisnodeValues: object, schema: Schema) => void;
  getCompanyRegistryData: (value: string) => void;
  getProcurationInfo: (value: string) => void;
  validation: (schema: Schema) => void;
  section: Item;
  template: Template;
  customerType: string;
  sectionIndex: number;
  contractLanguage: Locale;
}

interface State {
  focusIndex: number | undefined;
  idPath: string;
}

export default class TabContent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      focusIndex: undefined,
      idPath: this.setIdPath(props.customerType),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    this.setState({
      ...this.state,
      idPath: this.setIdPath(nextProps.customerType),
    });
  }

  onFocus = (index: number) => {
    this.setState({
      ...this.state,
      focusIndex: index - 1,
    });
  };

  setIdPath = (customerType: string) => {
    let path = '';
    if (customerType === PERSON) {
      path = SSN_PATH;
    } else if (customerType === COMPANY || customerType === INSTITUTION) {
      path = BUSINESSID_PATH;
    }
    return path;
  };

  getRegistryData = () => {
    const { getBisnodeData, getCompanyRegistryData, getProcurationInfo, customerType, sectionIndex } = this.props;
    if (customerType === PERSON) {
      getBisnodeData(this.resolveValue(this.state.idPath));
    } else if (sectionIndex === 0) {
      getCompanyRegistryData(this.resolveValue(this.state.idPath));
    } else {
      getProcurationInfo(this.resolveValue(this.state.idPath));
    }
  };

  resolveValue = (key: string): string => {
    const { formValues } = this.props;
    const path = key.split('.');
    let value = formValues.getIn(path);
    if (value && value.toJS) {
      value = value.toJS();
    }
    return value;
  };

  handleChange = (key: string, value: Value) => {
    const { changeSectionField, template } = this.props;
    changeSectionField(key, value, template.schema);
  };

  copyAll = () => {
    const { bisnodeValues, copyValues, template } = this.props;
    copyValues(bisnodeValues.deleteIn(this.state.idPath.split('.')), template.schema);
  };

  validate = () => {
    const { validation, template } = this.props;
    validation(template.schema);
  };

  render() {
    const {
      bisnodeValues,
      notFoundFromBisnode,
      fetchingFromBisnode,
      formValues,
      formErrors,
      formTouched,
      section,
      changeSectionField,
      template,
      saveArrayEditorValue,
      customerType,
      sectionIndex,
      contractLanguage,
    } = this.props;
    const ssn = this.resolveValue(this.state.idPath);
    let hasSsn = false;
    if (ssn && ssn !== '') {
      hasSsn = true;
    }

    const hasBisnodeValues = !bisnodeValues.isEmpty();
    let registryContent;
    if (notFoundFromBisnode) {
      registryContent = <i>{translate(`customer.registryDataNotFound`)}</i>;
    } else if (hasBisnodeValues) {
      registryContent = (
        <RegistryFieldList
          fields={template.form.items[sectionIndex].items}
          values={bisnodeValues.toJS()}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          changeSectionField={changeSectionField}
          focusIndex={this.state.focusIndex}
          schema={template.schema}
        />
      );
    } else if (!hasSsn) {
      registryContent = (
        <i>{translate(customerType === PERSON ? 'customer.ssnMissing' : 'customer.businessIdMissing')}</i>
      );
    }

    return (
      <div className={styles.content}>
        <div className={classnames(styles.headerRow, 'row')}>
          <div className="columns small-6">
            <div className="row">
              <div className="columns small-8">
                {customerType === PERSON ? (
                  <h6>{translate(`customer.registryOfficeData`)}</h6>
                ) : (
                  <h6>{translate(`customer.prhData`)}</h6>
                )}
              </div>
              <div className={classnames(styles.copyBtn, 'columns small-4')}>
                {hasBisnodeValues ? (
                  <div onClick={this.copyAll}>
                    <span className={styles.copyLink}>{translate(`customer.copyAll`)}</span>
                    <span className={styles.arrow}>
                      <HardwareKeyboardArrowRight
                        sx={{
                          color: '#80c49d',
                        }}
                      />
                    </span>
                  </div>
                ) : undefined}
              </div>
            </div>
            <div>
              {fetchingFromBisnode ? (
                <button className={styles.actionButton} disabled={!hasSsn}>
                  <Spinner color="white" />
                </button>
              ) : (
                <button onClick={this.getRegistryData} className={styles.actionButton} disabled={!hasSsn}>
                  <Refresh
                    style={{
                      width: '15px',
                      height: '15px',
                      verticalAlign: 'middle',
                      marginRight: '.6rem',
                    }}
                    key="icon"
                    sx={{
                      color: '#fff',
                    }}
                  />
                  <span key="text">{translate(`customer.fetchData`)}</span>
                </button>
              )}
            </div>
          </div>
          <div className={classnames('columns small-5 small-offset-1', styles.rightHeader)}>
            <h6>{translate(`customer.systemData`)}</h6>
          </div>
        </div>
        <div className="row">
          <div className="columns small-7">{registryContent}</div>

          <div className="columns small-5">
            <FieldList
              values={formValues.toJS()}
              fields={section.items}
              schema={template.schema}
              onChange={this.handleChange}
              onBlur={this.validate}
              errors={formErrors.toJS()}
              touched={formTouched}
              resolveValue={this.resolveValue}
              wizardSection={true}
              countries={{}}
              contractId="profile"
              locale="fi"
              crmPersonSearch={crmPersonSearch as unknown as (input: string) => Promise<SearchPersonResponse>}
              template={template}
              onFocus={this.onFocus}
              saveArrayEditorValue={saveArrayEditorValue}
              profileEditor={true}
              validateUntouched={false}
              contractLanguage={contractLanguage}
              isArrayQuestion={false}
            />
          </div>
        </div>
      </div>
    );
  }
}
