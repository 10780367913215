import { List } from 'immutable';
import {
  CLEAR_ORDERLINES,
  CLEAR_ALL_ORDERLINES,
  CHANGE_ROW_TYPE,
  OrderLinesAction,
} from 'features/orderLines/orderLinesActions';
import { ImmutableOrderLineType } from 'types/ordersState';
import { OrderLinesState, ImmutableOrderLineTypeList } from 'types/orderLinesState';
import {
  OrdersAction,
  INIT_ORDER_STATE,
  ORDER_BOND,
  ORDER_CREATE_AND_SIGN_SUCCESS,
  ORDER_FUND,
  ORDER_PRIVATE_EQUITY,
  ORDER_SHARE,
  ORDER_STRUCTURED_PRODUCT,
  ORDER_WARRANT,
  REMOVE_ORDERLINE,
} from 'features/orders/ordersActions';
import { addOrderLine } from 'features/orderLines/orderLinesUtils';

const initialState = {
  orderLines: List() as ImmutableOrderLineTypeList,
};

export const orderLinesReducer = (state: OrderLinesState = initialState, action: OrderLinesAction | OrdersAction) => {
  switch (action.type) {
    case ORDER_PRIVATE_EQUITY:
    case ORDER_WARRANT:
    case ORDER_STRUCTURED_PRODUCT:
    case ORDER_SHARE:
    case ORDER_BOND:
    case ORDER_FUND:
      return {
        ...state,
        orderLines: addOrderLine(state, action),
      };

    case CLEAR_ORDERLINES:
      return {
        ...state,
        orderLines: state.orderLines.filter((line) => line?.toJS().portfolioId !== action.portfolioId),
      };

    case INIT_ORDER_STATE:
    case CLEAR_ALL_ORDERLINES:
      return { ...state, orderLines: List() };

    case REMOVE_ORDERLINE:
      return {
        ...state,
        orderLines: state.orderLines.filterNot(
          (line: ImmutableOrderLineType | undefined) => line?.get('_id') === action.orderId
        ),
      };

    case ORDER_CREATE_AND_SIGN_SUCCESS:
      return {
        ...state,
        orderLines: state.orderLines.filter((line) => line?.toJS().portfolioId !== action.portfolioId),
      };

    case CHANGE_ROW_TYPE:
      return {
        ...state,
        orderLines: state.orderLines.update(
          state.orderLines.findIndex((l) => l?.get('_id') === action.payload.id),
          (value: ImmutableOrderLineType) => value?.set('rowType', action.payload.type.value) as ImmutableOrderLineType
        ),
      };

    default:
      return state;
  }
};
