import React from 'react';
import translate from 'counterpart';
import { CheckBox } from 'features/orders/components/CheckBox';

interface Props {
  value: boolean | undefined;
  onLeverageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Leverage = ({ value, onLeverageChange }: Props) => (
  <CheckBox
    label={translate('order.useLeverage')}
    onChange={onLeverageChange}
    value={value}
    data-testkey="use-leverage"
  />
);
