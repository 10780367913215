import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import { DistributionRow } from './DistributionRow';
import { ASSET_CATEGORY_COLORS, ASSET_CLASS_COLORS } from 'styles/colors';
import {
  customerHasValidPlan,
  selectCurrentWeights,
  selectOptimizedWeightsForRiskLevel,
} from 'features/allocator/allocatorSelectors';
import { AssetCategoryName, AssetClassId, CompareToType, DistributionTableItem, ItemType } from 'types/types';
import { ASSET_CATEGORY_ORDER, weightTypes } from 'constants/allocator';

interface Props {
  items: DistributionTableItem[];
  itemType: ItemType;
  compareTo: CompareToType;
  isIgnoredPortfolio?: boolean;
  header?: string;
  'data-testkey'?: string;
}

export const DistributionTable = ({
  items,
  itemType,
  header,
  compareTo,
  isIgnoredPortfolio,
  'data-testkey': dataTestKey,
}: Props) => {
  const hasPlan = useSelector(customerHasValidPlan);
  const optimizedWeights = useSelector(selectOptimizedWeightsForRiskLevel);
  const currentWeights = useSelector(selectCurrentWeights);
  const comparablePlanLength = hasPlan ? 'withoutIlliquids' : 'withIlliquids';

  const comparableWeights =
    compareTo === weightTypes.currentWeights ? currentWeights : optimizedWeights[comparablePlanLength];

  const sortedItems = () => {
    if (itemType === 'assetCategory') {
      return items
        .slice()
        .sort(
          (a, b) =>
            ASSET_CATEGORY_ORDER.indexOf(a.name as AssetCategoryName) -
            ASSET_CATEGORY_ORDER.indexOf(b.name as AssetCategoryName)
        );
    }
    if (itemType === 'instrument' || itemType === 'portfolioInstrument') {
      return sortBy(items, 'name');
    }
    return items;
  };

  const getComparableItemWeight = (item: DistributionTableItem): number | undefined => {
    switch (itemType) {
      case 'instrument':
        return comparableWeights?.instrumentWeights?.find((a) => a.security === item.security)?.weight;
      case 'portfolioInstrument':
        return comparableWeights?.portfolioWeights
          ?.flatMap((p) => p.instruments)
          ?.find((a) => a.security === item.security && a.portfolioId === item.portfolioId)?.weight;
      case 'assetClass':
        return comparableWeights?.assetCategoryWeights
          ?.flatMap((a) => a.assetClasses)
          .find((j) => j.assetClassId === item.assetClassId)?.weight;
      case 'assetCategory':
        return comparableWeights?.assetCategoryWeights?.find((a) => a.name === item.name)?.weight;
    }
  };

  return (
    <div data-testkey={dataTestKey}>
      {header && <h4>{header}</h4>}
      <Table>
        <tbody>
          {sortedItems()?.map((item, index) => {
            const dotColor = getDotColor(item, itemType);
            const comparedWeight = getComparableItemWeight(item);
            const factor = compareTo === weightTypes.currentWeights ? -1 : 1;
            const difference =
              comparedWeight !== undefined && item?.weight !== undefined
                ? factor * (comparedWeight - item?.weight)
                : undefined;

            return (
              <DistributionRow
                key={index} // because all props can be identical
                dotColor={dotColor}
                item={item}
                compareTo={compareTo}
                difference={difference}
                isIgnoredPortfolio={isIgnoredPortfolio}
                itemType={itemType}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

const getDotColor = (i: DistributionTableItem, itemType: ItemType): string => {
  switch (itemType) {
    case 'instrument':
    case 'portfolioInstrument':
    case 'assetClass':
      return ASSET_CLASS_COLORS[i.assetClassId as AssetClassId];
    case 'assetCategory':
      return ASSET_CATEGORY_COLORS[i.name as AssetCategoryName];
  }
};

const Table = styled.table`
  width: 100%;
`;
