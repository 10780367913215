import { TextFieldProps, Box, Grid } from '@mui/material';
import moment from 'moment';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import React from 'react';
import { MINUTES_OF_INVESTMENT_SERVICE, DATE, LOCATION, PARTICIPANTS, CONVENER, INFO } from 'constants/orderFields';
import { useAppDispatch } from 'core/hooks';
import { getErrorText } from 'core/portfolios';
import { ValidationErrorValue } from 'types/orderDialogState';
import { MinutesOfInvestmentServiceType } from 'types/ordersState';
import { RootState } from 'types/rootState';
import { copyLastMOISToCurrent, setEditorValueByPropertyPath } from 'features/orderDialog/orderDialogActions';
import { pathToProperty } from 'core/functions';
import Date from './Date';
import { colors } from 'styles/colors';
import { StyledTooltip } from 'features/orders/components/Tooltip';
import { TextFieldDefault, textFieldStyles } from 'features/common/styledComponents/StyledTextField';
import { FormTitleSmall } from 'features/common/styledComponents/StyledText';
import StyledButton from 'features/common/styledComponents/StyledButton';
import { selectHasPreviousMOIS } from 'features/orderDialog/orderDialogSelectors';

interface MinutesOfInvestmentProps {
  editorState?: MinutesOfInvestmentServiceType;
  validationErrors: ValidationErrorValue[];
  isDiscretionary: boolean;
}

const pathToMOISProperty = (propertyName: string) => {
  return pathToProperty([MINUTES_OF_INVESTMENT_SERVICE, propertyName]);
};

export const MinutesOfInvestmentService = (props: MinutesOfInvestmentProps) => {
  const { editorState, validationErrors, isDiscretionary } = props;
  const dispatch = useAppDispatch();
  const orderLinesState = useSelector((state: RootState) => state);
  const dateTouched = useSelector(
    (state: RootState) => state.orderDialog.touchedFields.toJS()[pathToMOISProperty(DATE)]
  );
  const changeValue = (fieldKey: string, value: string) => {
    dispatch(setEditorValueByPropertyPath(fieldKey, value));
  };

  const dateErrorText = getErrorText(DATE, validationErrors);

  const textFieldProps = {
    validationErrors,
    onChange: changeValue,
    editorState,
    isDiscretionary,
  };

  return (
    <>
      <MOISTitle>
        {translate(`order.${pathToMOISProperty('title')}`)}{' '}
        <StyledTooltip
          className="basisOfAdviceToolTip"
          title={translate('order.minutesOfInvestmentServiceTip')}
          placement="right-start"
        >
          <span>[?]</span>
        </StyledTooltip>
      </MOISTitle>
      {isDiscretionary && <MOISNotice>{translate(`order.minutesOfInvestmentServiceNotRequired`)}</MOISNotice>}
      <Box
        sx={{
          border: 1,
          borderColor: colors.gray_inactive,
          padding: 2,
          marginBottom: 1,
        }}
      >
        <Grid container spacing={0}>
          <Grid item lg={3}>
            <MOISDateFieldContainer>
              <Date
                label={translate(`order.${pathToMOISProperty(DATE)}`)}
                onChange={(event) => {
                  changeValue(pathToMOISProperty(DATE), event.target.value);
                }}
                value={moment(editorState && editorState[DATE]).format('YYYY-MM-DDTHH:mm')}
                type="datetime-local"
                tabIndex={-1}
                max={undefined}
                error={dateTouched && dateErrorText ? dateErrorText : undefined}
              />
            </MOISDateFieldContainer>
          </Grid>
          <Grid item lg={3}>
            <MinutesOfInvestmentServiceField propertyName={LOCATION} {...textFieldProps} />
          </Grid>
          <Grid item lg={3}>
            <MinutesOfInvestmentServiceField propertyName={PARTICIPANTS} {...textFieldProps} />
          </Grid>
          <Grid item lg={3}>
            <MinutesOfInvestmentServiceField propertyName={CONVENER} {...textFieldProps} />
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item md={8}>
            <MinutesOfInvestmentServiceField
              propertyName={INFO}
              {...textFieldProps}
              addedProps={{
                multiline: true,
                variant: 'outlined',
              }}
            />
          </Grid>
          <Grid style={{ textAlign: 'end', alignSelf: 'flex-end' }} item md={4}>
            {selectHasPreviousMOIS(orderLinesState) ? (
              <StyledButton onClick={() => dispatch(copyLastMOISToCurrent())}>Liitä edellinen pöytäkirja</StyledButton>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

interface MinutesOfInvestmentServiceTextFieldProps {
  label: string;
  value?: string;
  onChange: (value: string) => void;
  error?: string;
  addedProps?: TextFieldProps;
}

interface MinutesOfInvestmentServiceFieldProps {
  propertyName: string;
  onChange: (path: string, value: string) => void;
  editorState?: MinutesOfInvestmentServiceType;
  validationErrors: ValidationErrorValue[];
  addedProps?: TextFieldProps;
  isDiscretionary: boolean;
}

const MinutesOfInvestmentServiceTextField = (props: MinutesOfInvestmentServiceTextFieldProps) => {
  const { label, value, onChange, error, addedProps } = props;

  return (
    <MOISTextFieldContainer>
      <TextFieldDefault
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        maxLength={255}
        autoCorrect="off"
        autoComplete="off"
        fullWidth={true}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        value={value || ''}
        label={label}
        error={!!error}
        helperText={error}
        variant="standard"
        color="primary"
        sx={textFieldStyles}
        {...addedProps}
      />
    </MOISTextFieldContainer>
  );
};

const MinutesOfInvestmentServiceField = (props: MinutesOfInvestmentServiceFieldProps) => {
  const { propertyName, editorState, onChange, validationErrors, isDiscretionary, addedProps } = props;
  const pathToProperty = pathToMOISProperty(propertyName);
  const touched = useSelector((state: RootState) => state.orderDialog.touchedFields.toJS()[pathToProperty]);
  const errorMessage = getErrorText(propertyName, validationErrors);

  let error: string | undefined = '';
  if ((touched && errorMessage && !isDiscretionary) || isDiscretionary) {
    error = errorMessage;
  }

  return (
    <MinutesOfInvestmentServiceTextField
      label={translate(`order.${pathToProperty}`)}
      value={editorState && editorState[propertyName]}
      onChange={(value: string) => onChange(pathToProperty, value)}
      error={error}
      addedProps={addedProps}
    />
  );
};

const MOISTextFieldContainer = styled.div`
  margin-top: 18px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
`;

const MOISDateFieldContainer = styled(MOISTextFieldContainer)`
  margin-top: 8px;
`;

const MOISTitle = styled(FormTitleSmall)`
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 16px;
`;

const MOISNotice = styled.p`
  font-size: 14px;
  margin-bottom: 0;
`;
