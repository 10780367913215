import get from 'lodash/get';
import translate from 'counterpart';
import _ from 'lodash';
import accounting from 'accounting';
import { config } from 'config';
import {
  FULL_POWER_OF_ATTORNEY,
  CONSULTATIVE_AGREEMENT,
  SECURITIES_BROKERAGE_CONTRACT,
  NO_CONTRACT,
  CUSTOMER_RELATIONSHIP_AGREEMENT,
} from 'constants/contractNames';
import {
  SHARE,
  FUND,
  STRUCTURED_PRODUCT,
  WARRANT,
  PRIVATE_EQUITY,
  CO_INVESTMENTS,
  PRIVATE_EQUITY_PROFIT_SHARING_LOAN,
  CO_INVESTMENTS_PROFIT_SHARING_LOAN,
  ETF,
  CERTIFICATE,
  BOND,
  SUBSCRIPTION_RIGHT,
  OPTION,
  InstrumentForm,
  instrumentFormsThatCanBeMixedInTrades,
} from 'constants/instrumentForms';
import { NO_CAPITAL_CALL } from 'constants/noCapitalCall';
import { BUY, SELL, EXCHANGE, EXCHANGE_ALL, SELL_ALL } from 'constants/sides';
import { SENT_FOR_BANKER_APPROVAL, FINISHED } from 'constants/contractStates';
import { listed, PHONE_METHOD, SECURITY_NOT_IN_REPORTING, WRITTEN_METHOD } from 'constants/shareOptions';
import {
  EXPIRE_DATE,
  NOTE,
  ORD_TYPE,
  ORDER_FINANCING_BUY,
  ORDER_FINANCING_SELL,
  ORDER_INITIALIZER_TYPE,
  RECEIVED_FROM_CLIENT_METHOD,
  PRICE,
  RECEIVED_FROM_CLIENT_DATE,
  BOND_PRICE_TYPE,
  BASIS_TYPE,
} from 'constants/orderFields';
import { TAALERI_ISSUER_CODE } from 'constants/issuers';
import { GROUP_OWN_FUNDS, GROUP_INSURANCE_FUNDS, GROUP_OUTSIDE_FUNDS, GROUP_OTHER_FUNDS } from 'constants/portfolios';
import { PROFESSIONAL, ELIGIBLE_COUNTERPARTY } from 'constants/customerCategorisation';
import { OrderSideType, ChangePairType, ValidationErrorValue } from 'types/orderDialogState';
import { SuitabilityError, PriceValue, OrderLineType, Position, OrderDetailsOrderType } from 'types/ordersState';
import { Portfolio, PortfolioDetails } from 'types/portfolioState';
import {
  HandleFundResponse,
  HandleFundReturn,
  HandleStructuredResponse,
  HandleStructuredReturn,
  HandleWarrantResponse,
  HandleWarrantReturn,
  HandlePrivateEquityResponse,
  HandlePrivateEquityReturn,
  HandlePairSearchResponse,
  HandlePairSearchReturn,
  HandleShareResponse,
  HandleShareReturn,
} from 'features/orders/ordersTypes';
import { TAALERI_SHOP_PORTFOLIO_CLASS } from 'features/portfolio/portfolioConstants';
import { selectIsSignatureRequiredInOrderLine } from 'features/orders/ordersSelectors';
import { RootState } from 'types/rootState';
import {
  ACCORDING_TO_INVESTMENT_ADVICE,
  AGAINST_INVESTMENT_ADVICE,
  CLIENT_INITIATIVE,
} from 'constants/receiveInfoOptions';

const FEE_SPACING = 0.25;

export const isIndividualOrIndividualJvk = (strategy: string) =>
  strategy.toLowerCase() === 'yksilöllinen' || strategy.toLowerCase() === 'yksilöllinen jvk';

export const isIndividualStrategy = (strategy: string | undefined) =>
  strategy?.toLowerCase().indexOf('yksilöllinen') !== -1;

export const canBuyStructuredProducts = (contractName: string, isInsurancePortfolio: boolean, strategy: string) =>
  contractName !== FULL_POWER_OF_ATTORNEY || isInsurancePortfolio || isIndividualStrategy(strategy);

export const fundAmount = (fee: number | undefined, sum: number) =>
  fee !== undefined && Number(((1 - fee / (100 + fee)) * sum).toFixed(2));

export const fundSubscriptionSumOnSell = (fee: number | undefined, sum: number) =>
  fee !== undefined && Number(((1 + fee / (100 - fee)) * sum).toFixed(2));

export const structuredSum = (fee: number | undefined, sum: number | undefined, price: number | undefined) =>
  fee !== undefined && sum !== undefined && price !== undefined && (price + fee / 100) * sum;

const privateEquitySum = (fee: number | undefined, sum: number | undefined) =>
  fee !== undefined && sum !== undefined && sum + (fee / 100) * sum;

const warrantSum = (fee: number | undefined, amount: number | undefined, price: number | undefined) =>
  fee !== undefined && amount !== undefined && price !== undefined && (fee + price) * amount;

export const isIndividualFullPowerOfAttorney = (name: string | undefined, strategy: string | undefined) =>
  name === FULL_POWER_OF_ATTORNEY && isIndividualStrategy(strategy);

export const getRange = (n: unknown) =>
  Array(n)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    .fill()
    .map((x, y) => y * FEE_SPACING);

export const getFees = (defaultFee: number) => getRange(Math.ceil(defaultFee / FEE_SPACING)).reverse();

const getNeedsAppropriatenessTest = (suitabilityErrors: SuitabilityError[]) => {
  const needsAppro =
    suitabilityErrors.length === 1 && suitabilityErrors.map((x) => x.ruleId === 'AppropriatenessRule').pop();
  return needsAppro;
};

const getSubLabel = (suitabilityErrors: SuitabilityError[]) => {
  const all = suitabilityErrors
    .map((x) => x.messages.map((message) => `${message} (${x.ruleId})`))
    .flat()
    .join(', ');

  if (all) {
    return all;
  }

  return null;
};

const getMarketPrice = (price: PriceValue) => {
  const marketPrice = _.get(price, ['securityCurrency', 'value']);
  const securityCurrency = _.get(price, ['securityCurrency', 'currency']);
  return marketPrice ? `${accounting.formatMoney(marketPrice)} ${securityCurrency}` : '';
};

export const handleFund = (response: HandleFundResponse[]) => {
  let options: HandleFundReturn[] = [];
  if (response) {
    options = response.map((val) => ({
      value: val.financialInstrumentId,
      financialInstrumentId: val.financialInstrumentId,
      label: `${val.name} (${val.financialInstrumentId})`,
      name: `${val.name} (${val.financialInstrumentId})`,
      disabled:
        val.suitabilityErrors.length !== 0 &&
        !(getNeedsAppropriatenessTest(val.suitabilityErrors) && val.suitabilityErrors.length === 1),
      subLabel: getSubLabel(val.suitabilityErrors),
      financialInstrumentForm: val.financialInstrumentForm,
      currency: val.currency,
      issuer: val.issuer,
      legalForm: val.legalForm,
      alwaysEnable: !!getNeedsAppropriatenessTest(val.suitabilityErrors),
    }));
  }
  return options;
};

export const getListTotals = (positions: Position[]) => {
  const totals = {
    totalMarketValue: _.sumBy(positions, (x) => (x.marketValue.baseCurrency ? x.marketValue.baseCurrency.value : 0)),
    totalPurchaseValue: _.sumBy(positions, (x) =>
      x.purchaseValue.baseCurrency ? x.purchaseValue.baseCurrency.value : 0
    ),
    totalChange: _.sumBy(positions, (x) =>
      x.marketValueChange.baseCurrency ? x.marketValueChange.baseCurrency.value : 0
    ),
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  totals.totalChangePercent = totals.totalPurchaseValue
    ? (totals.totalMarketValue / totals.totalPurchaseValue - 1) * 100
    : '';

  return totals;
};

export const handleStructured = (response: HandleStructuredResponse[]) => {
  let options: HandleStructuredReturn[] = [];
  if (response) {
    options = response.map((val) => ({
      value: val.productId,
      financialInstrumentId: val.productId,
      label: `${val.name} (${val.productId})`,
      name: `${val.name} (${val.productId})`,
      disabled:
        val.suitabilityErrors.length !== 0 &&
        !(getNeedsAppropriatenessTest(val.suitabilityErrors) && val.suitabilityErrors.length === 1),
      subLabel: getSubLabel(val.suitabilityErrors),
      financialInstrumentForm: get(val, ['financialInstruments', '0', 'financialInstrumentType']),
      min: get(val, ['financialInstruments', '0', 'emissions', '0', 'minSubscription']),
      step: get(val, ['financialInstruments', '0', 'emissions', '0', 'lotsize']),
      fee: get(val, ['financialInstruments', '0', 'emissions', '0', 'defaultProvision']),
      currency: get(val, ['financialInstruments', '0', 'emissions', '0', 'currency']),
      prePayPrice: get(val, ['financialInstruments', '0', 'emissions', '0', 'prePayPrice']),
      issuer: get(val, ['financialInstruments', '0', 'issuer']),
      alwaysEnable: !!getNeedsAppropriatenessTest(val.suitabilityErrors),
    }));
  }
  return options;
};

export const handleWarrant = (response: HandleWarrantResponse[]) => {
  let options: HandleWarrantReturn[] = [];
  if (response) {
    options = response.map((val) => {
      const defaultProvision = get(val, ['financialInstruments', '0', 'directedIssues', '0', 'defaultProvision']);
      const prePayPrice = get(val, ['financialInstruments', '0', 'directedIssues', '0', 'prePayPrice']);
      return {
        value: val.productId,
        financialInstrumentId: val.productId,
        label: `${val.name} (${val.productId})`,
        name: `${val.name} (${val.productId})`,
        disabled:
          val.suitabilityErrors.length !== 0 &&
          !(getNeedsAppropriatenessTest(val.suitabilityErrors) && val.suitabilityErrors.length === 1),
        subLabel: getSubLabel(val.suitabilityErrors),
        financialInstrumentForm: get(val, ['financialInstruments', '0', 'financialInstrumentType']),
        min: get(val, ['financialInstruments', '0', 'directedIssues', '0', 'minSubscription']),
        step: get(val, ['financialInstruments', '0', 'directedIssues', '0', 'lotsize']),
        unitFee: +(prePayPrice * defaultProvision).toFixed(2) || 0,
        currency: get(val, ['financialInstruments', '0', 'directedIssues', '0', 'currency']),
        issuer: get(val, ['financialInstruments', '0', 'issuer']),
        prePayPrice,
        alwaysEnable: !!getNeedsAppropriatenessTest(val.suitabilityErrors),
      };
    });
  }
  return options;
};

export const handlePrivateEquity = (response: HandlePrivateEquityResponse[]) => {
  let options: HandlePrivateEquityReturn[] = [];
  if (response) {
    options = response.map((val) => ({
      value: val.productId,
      financialInstrumentId: val.productId,
      label: `${val.name} (${val.productId})`,
      name: `${val.name} (${val.productId})`,
      disabled:
        val.suitabilityErrors.length !== 0 &&
        !(getNeedsAppropriatenessTest(val.suitabilityErrors) && val.suitabilityErrors.length === 1),
      subLabel: getSubLabel(val.suitabilityErrors),
      financialInstrumentForm: get(val, ['financialInstruments', '0', 'financialInstrumentType']),
      min: getPrivateValueMin(val.financialInstruments),
      max: get(val, ['financialInstruments', '0', 'emissions', '0', 'maxSubscription']),
      step: getPrivateValueStep(val.financialInstruments),
      fee: getPrivateValueFee(val.financialInstruments),
      currency: getPrivateValueCurrency(val.financialInstruments),
      issuer: get(val, ['financialInstruments', '0', 'issuer']),
      productId: get(val, ['financialInstruments', '0', 'productId']),
      productIssuer: get(val, ['financialInstruments', '0', 'productIssuer']),
      instrumentGroups: get(val, ['financialInstruments', '0', 'securityGroups'])?.map((e: { id: string }) => e.id),
      alwaysEnable: !!getNeedsAppropriatenessTest(val.suitabilityErrors),
    }));
  }
  return options;
};

const getPrivateValueMin = (response: InstrumentForm) => {
  const emissions = get(response, ['0', 'emissions']);
  const directedIssues = get(response, ['0', 'directedIssues']);
  if (emissions.length > 0) {
    return get(emissions, ['0', 'minSubscription']);
  }
  if (directedIssues.length > 0) {
    return get(directedIssues, ['0', 'minSubscription']);
  }
  return 0;
};

const getPrivateValueStep = (response: InstrumentForm) => {
  const emissions = get(response, ['0', 'emissions']);
  const directedIssues = get(response, ['0', 'directedIssues']);
  if (emissions.length > 0) {
    return get(emissions, ['0', 'lotsize']);
  }
  if (directedIssues.length > 0) {
    return get(directedIssues, ['0', 'lotsize']);
  }
  return 0;
};

const getPrivateValueFee = (response: InstrumentForm) => {
  const emissions = get(response, ['0', 'emissions']);
  const directedIssues = get(response, ['0', 'directedIssues']);
  if (emissions.length > 0) {
    return get(emissions, ['0', 'defaultProvision']);
  }
  if (directedIssues.length > 0) {
    return get(directedIssues, ['0', 'defaultProvision']);
  }
  return 0;
};

const getPrivateValueCurrency = (response: InstrumentForm) => {
  const emissions = get(response, ['0', 'emissions']);
  const directedIssues = get(response, ['0', 'directedIssues']);
  if (emissions.length > 0) {
    return get(emissions, ['0', 'currency']);
  }
  if (directedIssues.length > 0) {
    return get(directedIssues, ['0', 'currency']);
  }
  return 0;
};

export const handlePairSearch = (changePairs: ChangePairType[], response: HandlePairSearchResponse[]) => {
  let options: HandlePairSearchReturn[] = [];
  if (response) {
    options = response.map((val) => {
      let subLabel;
      let needsAppropriatenessTest = getNeedsAppropriatenessTest(val.suitabilityErrors);
      const hasPair = !!changePairs.find((pair) => val.financialInstrumentId === pair.value);
      if (!hasPair) {
        subLabel = translate('order.changeNotAllowed');
        needsAppropriatenessTest = false;
      } else if (val.suitabilityErrors.length !== 0) {
        subLabel = getSubLabel(val.suitabilityErrors);
      }
      return {
        value: val.financialInstrumentId,
        label: `${val.name} (${val.financialInstrumentId})`,
        name: `${val.name} (${val.financialInstrumentId})`,
        disabled: (!val.isSuitable || !hasPair) && !(needsAppropriatenessTest && val.suitabilityErrors.length === 1),
        subLabel,
        financialInstrumentForm: val.financialInstrumentForm,
        issuer: val.issuer,
        alwaysEnable: !!needsAppropriatenessTest,
      };
    });
  }
  return options;
};

export const handleShare = (response: HandleShareResponse[]) => {
  let options: HandleShareReturn[] = [];
  if (response) {
    options = response.map((val) => ({
      value: val.financialInstrumentId,
      financialInstrumentId: val.financialInstrumentId,
      label: `${val.name} (${val.financialInstrumentId}) ${getMarketPrice(val.latestPrice)}`,
      name: `${val.name} (${val.financialInstrumentId})`,
      disabled:
        val.suitabilityErrors.length !== 0 &&
        !(getNeedsAppropriatenessTest(val.suitabilityErrors) && val.suitabilityErrors.length === 1),
      subLabel: getSubLabel(val.suitabilityErrors),
      financialInstrumentForm: val.financialInstrumentForm,
      currency: val.currency,
      issuer: val.issuer,
      marketPrice: _.get(val, ['latestPrice', 'securityCurrency', 'value'], ''),
      marketPriceCurrency: _.get(val, ['latestPrice', 'securityCurrency', 'currency'], ''),
      marketPriceBase: _.get(val, ['latestPrice', 'baseCurrency', 'value'], ''),
      marketPriceBaseCurrency: _.get(val, ['latestPrice', 'baseCurrency', 'currency'], ''),
      alwaysEnable: !!getNeedsAppropriatenessTest(val.suitabilityErrors),
    }));
  }
  return options;
};

export const showMoneySource = (accountType: string, hasBuyOrder: boolean, currentInstrumentForm: InstrumentForm) =>
  accountType !== '03' &&
  hasBuyOrder &&
  currentInstrumentForm !== PRIVATE_EQUITY &&
  currentInstrumentForm !== CO_INVESTMENTS &&
  currentInstrumentForm !== PRIVATE_EQUITY_PROFIT_SHARING_LOAN &&
  currentInstrumentForm !== CO_INVESTMENTS_PROFIT_SHARING_LOAN;

export const requireCapitalCall = (
  financialInstrumentId: string,
  isInsurancePortfolio: boolean | undefined
): boolean => {
  if (isInsurancePortfolio) {
    return false;
  }
  return !NO_CAPITAL_CALL.includes(financialInstrumentId.toLowerCase());
};

export const getSignatureMethod = (
  state: RootState,
  portfolioId: string,
  orderLines: OrderLineType[],
  orderType: OrderDetailsOrderType
) => {
  const isSignatureRequired = selectIsSignatureRequiredInOrderLine(state, portfolioId, orderLines);
  const hasOrders = orderType === 'fundOrder' || orderType === 'nonFundOrder';

  // These do not require signature
  if (!hasOrders) {
    return FINISHED;
  } else if (!isSignatureRequired) {
    return SENT_FOR_BANKER_APPROVAL;
  }

  // Signature required, it is selected later
  return undefined;
};

export const createOrderLine = (action: OrderLineType, order: OrderLineType): OrderLineType => {
  const {
    financialInstrument,
    financialInstrumentName,
    financialInstrumentId,
    financialInstrumentForm,
    portfolioId,
    _id,
    side,
    instrumentCurrency,
    rowType,
    issuer,
    representativeName,
    representativeSsn,
    orderBasis,
  } = action;
  const orderLine = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    financialInstrument: {
      name: financialInstrumentName,
      symbol: financialInstrumentId,
      financialInstrumentForm,
      instrumentGroups: financialInstrument?.instrumentGroups,
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    portfolioId,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    _id,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    side,
    priceCurrency: instrumentCurrency,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    rowType,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    issuer,
    ...order,
  };

  if (action.representativeName) {
    _.assign(orderLine, {
      representativeName,
    });
  }

  if (action.representativeSsn) {
    _.assign(orderLine, {
      representativeSsn,
    });
  }

  if (action.orderBasis) {
    _.assign(orderLine, {
      orderBasis,
    });
  }

  if (action.side === EXCHANGE) {
    _.assign(orderLine, {
      counterFinancialInstrument: {
        name: action.counterFinancialInstrumentName,
        symbol: action.counterFinancialInstrumentId,
      },
    });
  }
  return orderLine;
};

const createShareOrderLine = (action: OrderLineType, order: OrderLineType) => ({
  ...createOrderLine(action, order),
  ..._.pick(action, [
    ORDER_INITIALIZER_TYPE,
    EXPIRE_DATE,
    ORDER_FINANCING_BUY,
    ORDER_FINANCING_SELL,
    NOTE,
    ORD_TYPE,
    PRICE,
    RECEIVED_FROM_CLIENT_DATE,
    RECEIVED_FROM_CLIENT_METHOD,
    BOND_PRICE_TYPE,
    BASIS_TYPE,
  ]),
});

export const getFundOrderData = (action: OrderLineType): OrderLineType => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const order: OrderLineType = _.pick(action, [
    ORDER_INITIALIZER_TYPE,
    RECEIVED_FROM_CLIENT_DATE,
    RECEIVED_FROM_CLIENT_METHOD,
    'defaultFee',
    'subscriptionFee',
    'receiverEmail',
    'moneySource',
    'fundsOrigin',
    BASIS_TYPE,
  ]);
  if (action.amount) {
    _.assign(order, { currency: action.currency });
    if (action.side === BUY) {
      _.assign(order, {
        subscriptionSum: action.amount,
        amount: fundAmount(action.subscriptionFee, action.amount),
      });
    } else {
      _.assign(order, {
        subscriptionSum: fundSubscriptionSumOnSell(action.subscriptionFee, action.amount),
        amount: action.amount,
      });
    }
  } else {
    _.assign(order, {
      quantity: action.quantity,
    });
  }
  return order;
};

export const getFundOrderLine = (action: OrderLineType) => {
  const order = getFundOrderData(action);
  return createOrderLine(action, order);
};

export const getStructuredProductOrderData = (action: OrderLineType): OrderLineType => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const order: OrderLineType = _.pick(action, [
    ORDER_INITIALIZER_TYPE,
    RECEIVED_FROM_CLIENT_DATE,
    RECEIVED_FROM_CLIENT_METHOD,
    BASIS_TYPE,
  ]);
  _.assign(order, {
    subscriptionSum:
      action.side === BUY
        ? structuredSum(action.subscriptionFee, action.subscriptionSum, action.prePayPrice)
        : action.amount,
    notionalAmount: action.side === BUY ? action.subscriptionSum : action.amount,
    internalNote: action.internalNote,
    isLeveraged: action.isLeveraged || false,
    subscriptionFee: action.subscriptionFee,
    prePayPrice: action.prePayPrice ? action.prePayPrice * 100 : 0,
    bondPriceType: action.bondPriceType,
    currency: action.instrumentCurrency,
    moneySource: action.moneySource,
  });
  return order;
};

export const getStructuredProductOrderLine = (action: OrderLineType) => {
  const order = getStructuredProductOrderData(action);
  return createShareOrderLine(action, order);
};

export const getWarrantOrderData = (action: OrderLineType) => ({
  currency: action.instrumentCurrency,
  quantity: action.subscriptionSum,
  subscriptionSum: warrantSum(action.unitFee, action.subscriptionSum, action.prePayPrice),
  ..._.pick(action, [
    ORDER_INITIALIZER_TYPE,
    RECEIVED_FROM_CLIENT_DATE,
    RECEIVED_FROM_CLIENT_METHOD,
    'internalNote',
    'unitFee',
    'prePayPrice',
    'receiverEmail',
    'moneySource',
    BASIS_TYPE,
  ]),
});

export const getWarrantOrderLine = (action: OrderLineType) => {
  let order;
  if (action.side === BUY) {
    order = getWarrantOrderData(action);
  } else {
    order = getShareOrderData(action);
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return createShareOrderLine(action, order);
};

export const getPrivateEquityOrderData = (action: OrderLineType) => ({
  subscriptionSum: privateEquitySum(action.subscriptionFee, action.subscriptionSum),
  notionalAmount: action.subscriptionSum,
  isLeveraged: action.isLeveraged || false,
  ..._.pick(action, [
    ORDER_INITIALIZER_TYPE,
    RECEIVED_FROM_CLIENT_DATE,
    RECEIVED_FROM_CLIENT_METHOD,
    'subscriptionFee',
    'internalNote',
    'currency',
    'issuer',
    'capitalCallEmail',
    'capitalCallMethod',
    'productId',
    'productIssuer',
    BASIS_TYPE,
  ]),
});

export const getPrivateEquityOrderLine = (action: OrderLineType) => {
  const order = getPrivateEquityOrderData(action);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return createOrderLine(action, order);
};

const getShareOrderData = (action: OrderLineType) => ({
  quantity: action.quantity,
  amount: action.amount,
  currency: action.currency,
});

export const getShareOrderLine = (action: OrderLineType) => {
  const order = getShareOrderData(action);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return createShareOrderLine(action, order);
};

const getBondOrderData = (action: OrderLineType) => ({
  notionalAmount: action.amount,
  currency: action.instrumentCurrency,
  bondPriceType: action.bondPriceType,
});

export const getBondOrderLine = (action: OrderLineType) => {
  const order = getBondOrderData(action);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return createShareOrderLine(action, order);
};

export const isListed = (instrumentForm: InstrumentForm, side: OrderSideType) =>
  listed.includes(instrumentForm) ||
  (side === SELL && (instrumentForm === WARRANT || instrumentForm === STRUCTURED_PRODUCT));

export const getFormOptions = (
  currentInstrumentForm: InstrumentForm,
  isStructuredProductsAllowed: boolean,
  contractName: string,
  customerCategorisation: string,
  receiveType: string,
  basisType: string
): InstrumentForm[] => {
  const formOptions = [] as InstrumentForm[];
  const isProfessionalOrEligibleCounterparty =
    customerCategorisation === PROFESSIONAL || customerCategorisation === ELIGIBLE_COUNTERPARTY;

  if (contractName === SECURITIES_BROKERAGE_CONTRACT) {
    formOptions.push(SHARE, ETF, BOND, SUBSCRIPTION_RIGHT, OPTION);

    if (isProfessionalOrEligibleCounterparty && currentInstrumentForm !== FUND) {
      formOptions.push(STRUCTURED_PRODUCT, WARRANT, CERTIFICATE);
    }
    return formOptions;
  }

  if (!isStructuredProduct(currentInstrumentForm)) {
    formOptions.push(SHARE, ETF, BOND, FUND);
  }

  if (isStructuredProductsAllowed && !isNonStructuredShare(currentInstrumentForm) && currentInstrumentForm !== FUND) {
    formOptions.push(STRUCTURED_PRODUCT, WARRANT);
  }

  if (!currentInstrumentForm && currentInstrumentForm !== FUND) {
    formOptions.push(PRIVATE_EQUITY, CO_INVESTMENTS);
  }

  if (!isStructuredProduct(currentInstrumentForm) && currentInstrumentForm !== FUND) {
    formOptions.push(SUBSCRIPTION_RIGHT, OPTION, CERTIFICATE);
  }

  if (currentInstrumentForm === PRIVATE_EQUITY_PROFIT_SHARING_LOAN) {
    formOptions.push(PRIVATE_EQUITY);
  }

  if (currentInstrumentForm === CO_INVESTMENTS_PROFIT_SHARING_LOAN) {
    formOptions.push(CO_INVESTMENTS);
  }

  const filtered = limitInstrumentsByContract(formOptions, contractName, receiveType, basisType);

  return filtered;
};

const limitInstrumentsByContract = (
  formOptions: InstrumentForm[],
  contractName: string,
  receiveType: string,
  basisType: string
) => {
  if (contractName === CUSTOMER_RELATIONSHIP_AGREEMENT || contractName === CONSULTATIVE_AGREEMENT) {
    if (receiveType === WRITTEN_METHOD && basisType === CLIENT_INITIATIVE) {
      const allowed = formOptions.filter((option) => ![PRIVATE_EQUITY, CO_INVESTMENTS].includes(option));
      return allowed;
    }

    if (
      receiveType === WRITTEN_METHOD &&
      (basisType === ACCORDING_TO_INVESTMENT_ADVICE || basisType === AGAINST_INVESTMENT_ADVICE)
    ) {
      const allowed = formOptions.filter((option) => ![CO_INVESTMENTS].includes(option));
      return allowed;
    }

    if (receiveType === PHONE_METHOD) {
      const allowed = formOptions.filter(
        (option) => ![FUND, STRUCTURED_PRODUCT, WARRANT, PRIVATE_EQUITY, CO_INVESTMENTS].includes(option)
      );
      return allowed;
    }
  }

  return formOptions;
};

export const filterFormOptionsBasedOnOrderlines = (
  formOptions: InstrumentForm[],
  orderLines: OrderLineType[],
  contractName: string
) => {
  const addedForms = orderLines.map((o) => o.financialInstrumentForm);
  const hasOnlyMixableForms =
    addedForms.length > 0 && addedForms.every((f) => instrumentFormsThatCanBeMixedInTrades.includes(f));

  if (hasOnlyMixableForms && contractName !== SECURITIES_BROKERAGE_CONTRACT) {
    const allowedFormOptions = formOptions.filter((option) => instrumentFormsThatCanBeMixedInTrades.includes(option));
    return allowedFormOptions;
  }
  return formOptions;
};

export const isBond = (form: InstrumentForm) => form === BOND || form === STRUCTURED_PRODUCT;

export const isStructuredProduct = (form: InstrumentForm) => form === STRUCTURED_PRODUCT || form === WARRANT;

const isNonStructuredShare = (form: InstrumentForm) => listed.includes(form);

export const getErrorText = (key: string, errors: ValidationErrorValue[]) => {
  const error = _.find(errors, (e) => e.key === key);
  return error ? error.message : undefined;
};

export const getCurrentQuantity = (positions: Position[], id: string) => {
  const pos = positions.find((p) => p.financialInstrument.financialInstrumentId === id);
  return pos ? pos.quantityNet : undefined;
};

export const getSides = (
  currentInstrumentForm: InstrumentForm,
  side: OrderSideType,
  contractName: string,
  instrumentForm: InstrumentForm,
  hasEmission: boolean,
  isSuitable: boolean,
  hasRestrictedPortfolioClass: boolean
) => {
  const sideOptions = [
    {
      value: BUY,
      label: translate('order.buy'),
    },
    {
      value: SELL,
      label: translate('order.sell'),
    },
    {
      value: SELL_ALL,
      label: translate('order.sellAll'),
    },
  ];

  if (isStructuredProduct(currentInstrumentForm) && side === SELL) {
    _.remove(sideOptions, (opt) => opt.value === BUY);
  }

  if (instrumentForm === STRUCTURED_PRODUCT && !hasEmission) {
    _.remove(sideOptions, (opt) => opt.value === BUY);
  }

  if (contractName === SECURITIES_BROKERAGE_CONTRACT) {
    _.remove(sideOptions, (opt) => opt.value === SELL_ALL);
  }

  if (isSuitable === false) {
    _.remove(sideOptions, (opt) => opt.value === BUY);
  }

  if (instrumentForm === FUND) {
    sideOptions.push(
      {
        value: EXCHANGE,
        label: translate('order.exchange'),
      },
      {
        value: EXCHANGE_ALL,
        label: translate('order.exchangeAll'),
      }
    );
  }

  if (
    instrumentForm === PRIVATE_EQUITY ||
    instrumentForm === CO_INVESTMENTS ||
    instrumentForm === PRIVATE_EQUITY_PROFIT_SHARING_LOAN ||
    instrumentForm === CO_INVESTMENTS_PROFIT_SHARING_LOAN
  ) {
    _.remove(sideOptions, (opt) => opt.value === SELL);
    _.remove(sideOptions, (opt) => opt.value === SELL_ALL);
  }

  if (hasRestrictedPortfolioClass) {
    _.remove(sideOptions, (opt) => opt.value === BUY);
    _.remove(sideOptions, (opt) => opt.value === EXCHANGE);
    _.remove(sideOptions, (opt) => opt.value === EXCHANGE_ALL);
  }

  return sideOptions;
};

interface HideQuantityWarningParams {
  side: OrderSideType;
  orderFinancingSell: string | undefined;
  volume: number | undefined;
  currentQuantity: number | undefined;
}

export const hideQuantityWarning = ({ side, orderFinancingSell, volume, currentQuantity }: HideQuantityWarningParams) =>
  side !== SELL ||
  orderFinancingSell === SECURITY_NOT_IN_REPORTING ||
  !volume ||
  (!!volume && !!currentQuantity && volume <= currentQuantity);

export const isValidDecimalNumber = (value: string) => {
  const isValid = value.endsWith(',') || (value.includes(',') && value.endsWith('0'));
  return !isValid;
};

export const convertStringToDecimalNumber = (value: string) => Number(value.replace(/\s/g, '').replace(',', '.'));

export const isTaaleriProduct = (issuer: string | undefined) => issuer === TAALERI_ISSUER_CODE;

export const canBeAdviced = (contractName: string, issuer: string) =>
  contractName === CONSULTATIVE_AGREEMENT && !isTaaleriProduct(issuer);

const hasAnyClass = (portfolio: PortfolioDetails | Portfolio, classes: string[]) => {
  if (!portfolio.portfolioClasses) {
    return false;
  }
  return portfolio.portfolioClasses.some((x) => classes.includes(x));
};

// Konsultatiivinen = kuuluu salkkuluokkaan 1028
export const isConsultative = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1028']);

// Täyden valtakirjan yksilöllinen = kuuluu salkkuluokkaan 1022 tai 1188
export const isFullPowerOfAttorneyIndividual = (portfolio: PortfolioDetails | Portfolio) =>
  hasAnyClass(portfolio, ['1022', '1023', '1130', '1131', '1132', '1133', '1134', '1188']);

// Täysi valtakirja (pankkiiri tai välitys hoitaa) = kuuluu salkkuluokkaan 1210, huom! Tämä testissä 1205
export const isFullPowerOfAttorneyBankerOrServiceDesk = (portfolio: PortfolioDetails | Portfolio) => {
  let classId = '1205';
  if (config.appEnv === 'pr') {
    classId = '1210';
  }

  return hasAnyClass(portfolio, [classId]);
};

// Täysi valtakirja (salkunhoito hoitaa) = kuuluu esim. salkkuluokkaan 1011 tai 1018
export const isFullPowerOfAttorneyPortfolioManagementPortfolio = (portfolio: PortfolioDetails | Portfolio) =>
  hasAnyClass(portfolio, ['1010', '1011', '1012', '1013', '1014', '1015', '1016', '1018', '1019', '1021']);

// Digiasiakas = kuuluu salkkuluokkaan 1204
const isDigi = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1204']);

// Arvopaperinvälitys = kuuluu salkkuluokkaan 1030
const isSecuritiesBrokeragePortfolio = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1030']);

// Tuotesalkku = kuuluu salkkuluokkaan 1034, 1035, 1139, 1161 tai 1162
const isProductPortfolio = (portfolio: PortfolioDetails | Portfolio) =>
  hasAnyClass(portfolio, ['1034', '1035', '1139', '1161', '1162']);

// Taaleri kauppa = kuuluu salkkuluokkaan 1154
const isShopPortfolio = (portfolio: PortfolioDetails | Portfolio) =>
  hasAnyClass(portfolio, [TAALERI_SHOP_PORTFOLIO_CLASS]);

const isInsurancePortfolio = (portfolio: PortfolioDetails) => hasAnyClass(portfolio, ['1002']);

// Koonti = kuuluu salkkuluokkaan 1029
export const isOutsideFundsPortfolio = (portfolio: PortfolioDetails) => hasAnyClass(portfolio, ['1029']);

export const isNoContractPortfolio = (portfolio: PortfolioDetails) => portfolio.contractName === NO_CONTRACT;

const isHenkiFennia = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1049']);
const isSwissLife = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1138']);
const isLombard = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1050']);
const isSebLife = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1052']);
const isRoyalSkandia = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1051']);
const isMandatumLife = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1053']);
const isLahiTapiola = (portfolio: PortfolioDetails | Portfolio) => hasAnyClass(portfolio, ['1054']);

export const getPortfolioGroup = (portfolio: PortfolioDetails) => {
  if (isOutsideFundsPortfolio(portfolio)) {
    return GROUP_OUTSIDE_FUNDS;
  }

  const isInsurance = isInsurancePortfolio(portfolio);

  if (isInsurance) {
    if (
      isConsultative(portfolio) ||
      isFullPowerOfAttorneyIndividual(portfolio) ||
      isFullPowerOfAttorneyBankerOrServiceDesk(portfolio) ||
      isFullPowerOfAttorneyPortfolioManagementPortfolio(portfolio)
    ) {
      return GROUP_INSURANCE_FUNDS;
    }
  } else {
    if (
      isConsultative(portfolio) ||
      isFullPowerOfAttorneyIndividual(portfolio) ||
      isFullPowerOfAttorneyBankerOrServiceDesk(portfolio) ||
      isFullPowerOfAttorneyPortfolioManagementPortfolio(portfolio) ||
      isDigi(portfolio) ||
      isSecuritiesBrokeragePortfolio(portfolio) ||
      isProductPortfolio(portfolio) ||
      isShopPortfolio(portfolio)
    ) {
      return GROUP_OWN_FUNDS;
    }
  }

  return GROUP_OTHER_FUNDS;
};

export const getPortfolioGroupOrder = (portfolioGroup: string) => {
  if (portfolioGroup === GROUP_OWN_FUNDS) {
    return 0;
  }
  if (portfolioGroup === GROUP_INSURANCE_FUNDS) {
    return 1;
  }
  if (portfolioGroup === GROUP_OUTSIDE_FUNDS) {
    return 2;
  }
  if (portfolioGroup === GROUP_OTHER_FUNDS) {
    return 3;
  }
  return 4;
};

const sortOrderOwnFunds = (portfolio: PortfolioDetails | Portfolio) => {
  if (isConsultative(portfolio)) {
    return 0;
  }
  if (isFullPowerOfAttorneyIndividual(portfolio)) {
    return 1;
  }
  if (isFullPowerOfAttorneyBankerOrServiceDesk(portfolio)) {
    return 2;
  }
  if (isFullPowerOfAttorneyPortfolioManagementPortfolio(portfolio)) {
    return 3;
  }
  if (isDigi(portfolio)) {
    return 4;
  }
  if (isSecuritiesBrokeragePortfolio(portfolio)) {
    return 5;
  }
  if (isProductPortfolio(portfolio)) {
    return 6;
  }
  if (isShopPortfolio(portfolio)) {
    return 7;
  }
  return 100;
};

const sortOrderInsuranceFunds = (portfolio: PortfolioDetails | Portfolio) => {
  if (isHenkiFennia(portfolio)) {
    return 0;
  }
  if (isSwissLife(portfolio)) {
    return 1;
  }
  if (isLombard(portfolio)) {
    return 2;
  }
  if (isSebLife(portfolio)) {
    return 3;
  }
  if (isRoyalSkandia(portfolio)) {
    return 4;
  }
  if (isMandatumLife(portfolio)) {
    return 5;
  }
  if (isLahiTapiola(portfolio)) {
    return 6;
  }
  return 100;
};

export const primarySortOrderPortfolioGroup = (portfolioGroup: string, portfolio: Portfolio) => {
  if (portfolioGroup === GROUP_OWN_FUNDS) {
    return sortOrderOwnFunds(portfolio);
  }
  if (portfolioGroup === GROUP_INSURANCE_FUNDS) {
    return sortOrderInsuranceFunds(portfolio);
  }
  if (portfolioGroup === GROUP_OUTSIDE_FUNDS) {
    return 0;
  }
  return 0;
};

export const secondarySortOrderPortfolioGroup = (portfolioGroup: string, portfolio: PortfolioDetails | Portfolio) => {
  if (portfolioGroup !== GROUP_INSURANCE_FUNDS) {
    return 0;
  }
  if (isConsultative(portfolio)) {
    return 0;
  }
  if (isFullPowerOfAttorneyIndividual(portfolio)) {
    return 1;
  }
  if (isFullPowerOfAttorneyBankerOrServiceDesk(portfolio)) {
    return 2;
  }
  if (isFullPowerOfAttorneyPortfolioManagementPortfolio(portfolio)) {
    return 3;
  }
  return 100;
};
