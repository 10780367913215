import React from 'react';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { sortBy } from 'lodash';
import styled, { css } from 'styled-components';
import { FlexColumn, FlexJustifyBetween, HelpText, FlexJustifyCenter, Flex } from 'features/common/StyledComponents';
import { StepNavigationItem, StepItemHeader, LinkButtonWithMargin, LowerPart } from './StepNavigationStyles';
import { RootState } from 'types/rootState';
import { Spinner } from 'features/common/Spinner';
import {
  getFormattedPortfolioWeight,
  getPortfolioOptimizationModel,
} from 'features/allocator/planPortfolios/planPortfolioUtils';
import { StepNavigationStepProps } from 'features/allocator/stepNavigation/stepNavigationTypes';
import { OptimizationMannerId } from 'types/types';
import { colors } from 'styles/colors';
import { optimizationManners } from 'constants/allocator';
import {
  isOptimizingPlan,
  selectCurrentWeights,
  selectFlagCheckPortfolios,
} from 'features/allocator/allocatorSelectors';
import { selectCustomerId } from 'features/profile/profileSelectors';
import {
  customerHasOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';

export const StepPortfolios = ({ unset, disableEdit, locked, loading }: StepNavigationStepProps) => {
  const customerId = useSelector(selectCustomerId);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const flagCheckPortfolios = useSelector(selectFlagCheckPortfolios);
  const hasOptimizedWeights = useSelector(customerHasOptimizedWeights('withoutIlliquids'));

  const sortedPortfolios = sortBy(optimizedPortfolios, (i) => i.allocatorPortfolioId);

  const optimizingPlan = useSelector(isOptimizingPlan);
  const currentWeights = useSelector(selectCurrentWeights);

  const portfolioDetailsById = useSelector((state: RootState) => state.portfolio?.portfolioDetailsById);
  const portfoliosLink = `customer/${customerId}/portfolioManager/allocator/portfolios/`;
  const buttonText = flagCheckPortfolios === false || !hasOptimizedWeights ? 'View' : 'Check';

  return (
    <StepNavigationItem unset={unset}>
      <div>
        <StepItemHeader>{translate('allocator.investmentPlanSteps.portfolios.portfoliosTitle')}</StepItemHeader>
        {loading && <Spinner position="left" size={30} />}
        {!loading &&
          sortedPortfolios?.map((portfolio) => {
            const portfolioWeight = getFormattedPortfolioWeight(portfolio, currentWeights);
            const portfolioName =
              portfolio.portfolioId && portfolioDetailsById?.[portfolio.portfolioId]
                ? portfolioDetailsById?.[portfolio.portfolioId]?.portfolioName
                : translate('allocator.portfoliosView.personalAllocationStrategy');

            const optimizationModel = getPortfolioOptimizationModel(portfolio);

            return (
              <FlexColumn key={portfolio?.portfolioId || portfolio?.allocatorPortfolioId}>
                <PortfolioRow data-testkey="portfolio-step-row">
                  <Name>{portfolioName}</Name>
                  <Flex>
                    <Weight>{optimizingPlan ? <Spinner position="center" size={20} /> : portfolioWeight}</Weight>
                    <LabelContainer>
                      <OptimizationModel model={optimizationModel}>
                        {translate(`allocator.investmentPlanSteps.portfolios.${optimizationModel}`)}
                      </OptimizationModel>
                    </LabelContainer>
                  </Flex>
                </PortfolioRow>
              </FlexColumn>
            );
          })}

        {!loading && !sortedPortfolios.length && (
          <HelpText>
            {translate('allocator.investmentPlanSteps.portfolios.selectRiskAndCoreToCreatePortfolios')}
          </HelpText>
        )}
      </div>

      <LowerPart>
        <LinkButtonWithMargin
          to={portfoliosLink}
          label={translate(`allocator.investmentPlanSteps.portfolios.button${buttonText}`)}
          disabled={disableEdit || locked || !sortedPortfolios.length}
          secondary={flagCheckPortfolios === false && !loading}
          data-secondary={flagCheckPortfolios === false}
          data-testkey={'select-portfolios-button'}
        />
      </LowerPart>
    </StepNavigationItem>
  );
};

const PortfolioRow = styled(FlexJustifyBetween)`
  margin-bottom: 0.5rem;
`;

const LabelContainer = styled(Flex)`
  width: 55px;
`;

const Weight = styled.div`
  text-align: right;
  margin: 0 0.5rem;
  white-space: nowrap;
  font-weight: 700;
  letter-spacing: -0.3px;
`;

const Name = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const OptimizationModel = styled(FlexJustifyCenter)<{ model: OptimizationMannerId }>`
  font-size: 12px;
  align-items: center;
  font-weight: 600;
  padding: 0 0.5rem;
  border-radius: 15px;

  ${(props) =>
    props.model === optimizationManners.INCLUDE &&
    css`
      background: ${colors.optimized};
      color: white;
      font-weight: 700;
    `};

  ${(props) =>
    props.model === optimizationManners.CONSIDER &&
    css`
      background: ${colors.considered};
    `};

  ${(props) =>
    props.model === optimizationManners.IGNORE &&
    css`
      background: ${colors.med_gray};
      color: ${colors.dark_gray};
    `};
`;
