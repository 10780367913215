import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SENT_FOR_INK_SIGNING, SENT_FOR_MANUAL_SIGNING, SENT_FOR_SIGNING } from 'constants/contractStates';
import { useAppDispatch } from 'core/hooks';
import { PdfAttachment } from 'features/contracts/contracts.types';
import {
  uploadAttachmentAddSignature,
  uploadAttachmentRemoveSignature,
  copySignatoriesToAllContracts,
  uploadAttachmentSignatureMethod,
} from 'features/contracts/contractsActions';
import { PDF_ATTACHMENT_LOADING, PDF_ATTACHMENT_FAILURE } from 'features/contracts/contractsConstants';
import { getAttachmentPdfRequest } from 'features/contracts/contractsUtils';
import { Signatory, Contract } from 'types/ordersState';
import { RootState } from 'types/rootState';
import { NewSignatoryDialog } from 'features/contracts/components/NewSignatoryDialog';
import SignatoryDetailsDialog from 'features/contracts/components/SignatoryDetailsDialog';
import {
  AttachmentContainer,
  FlexAlignCenterJustifyBetween,
  FlexRowCenterFullHeight,
  LeftContainer,
  LeftIconContainer,
  LeftIcon,
  LeftIconText,
  FlexBoxCol,
  SmallText,
  RemoveContainer,
  RemoveIcon,
} from './styledComponents';
import { Spinner } from 'features/common/Spinner';
import SignatureSelection from 'features/contracts/components/attachment/SignatureSelection';
import SignaturesList from 'features/contracts/components/attachment/SignaturesList';

interface AttachmentItemProps {
  attachment: PdfAttachment;
  handleRemovePress: (id: string) => void;
}

const emptySignature = {
  firstName: '',
  lastName: '',
  ssn: '',
  email: '',
  phone: '',
};

const AttachmentItem = (props: AttachmentItemProps) => {
  const { attachment, handleRemovePress } = props;
  const [signatureDetailsModal, setSignatureDetailsModal] = useState({
    open: false,
    signature: emptySignature,
  });
  const dispatch = useAppDispatch();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const customerId = useSelector((state: RootState) => state.profile.customer.get('customerId'));
  const token = useSelector((state: RootState) => state.oidc.user.access_token);
  const savedSigners = useSelector((state: RootState) => state.contracts.savedSigners);
  const openSignatureDetails = (signature: Signatory) => {
    setSignatureDetailsModal({ open: true, signature });
  };

  const addSignature = (attachmentId: string, formValues: Signatory) => {
    dispatch(uploadAttachmentAddSignature(attachmentId, formValues));
    setShowNewSignatoryDialog(false);
  };

  const removeSignature = (signature: Signatory, contract: Contract) => {
    if (contract.attachmentId) {
      dispatch(uploadAttachmentRemoveSignature(contract.attachmentId, signature));
    }
    setSignatureDetailsModal({ open: false, signature: emptySignature });
  };

  const copyToAll = () => {
    dispatch(copySignatoriesToAllContracts(attachment.signatures));
  };

  const openPdf = async () => {
    setLoadingPdf(true);
    try {
      const blob = await getAttachmentPdfRequest(attachment.id, customerId, token);
      window.open(window.URL.createObjectURL(blob), '_blank');
    } catch (error) {
      setLoadingPdf(false);
    }
    setLoadingPdf(false);
  };

  const [showNewSignatoryDialog, setShowNewSignatoryDialog] = useState(false);

  const selectedSignatureMethod = useSelector(
    (state: RootState) =>
      !!state.contracts.attachmentPdfs &&
      state.contracts.attachmentPdfs.find((pdfItem) => pdfItem.id === attachment.id)?.signatureMethod
  );

  const setSignatureMethod = (attachmentId: string, method: string) => {
    dispatch(uploadAttachmentSignatureMethod(attachmentId, method));
  };

  const onNewSignatory = () => {
    setShowNewSignatoryDialog(true);
  };

  return (
    <AttachmentContainer>
      <FlexAlignCenterJustifyBetween key={attachment.id}>
        <FlexRowCenterFullHeight>
          <LeftContainer>
            <LeftIconContainer onClick={openPdf}>
              {loadingPdf || attachment.status === PDF_ATTACHMENT_LOADING ? (
                <Spinner color="white" />
              ) : (
                <>
                  <LeftIcon className={`icon icon-document`} />
                  <LeftIconText>LATAA</LeftIconText>
                </>
              )}
            </LeftIconContainer>
          </LeftContainer>
          <FlexBoxCol>
            {attachment.descriptiveName}
            <SmallText>{attachment.fileName}</SmallText>
          </FlexBoxCol>
        </FlexRowCenterFullHeight>
        <FlexRowCenterFullHeight>
          {attachment.status === PDF_ATTACHMENT_FAILURE && <ErrorOutlineIcon />}
          {attachment.status !== PDF_ATTACHMENT_LOADING && (
            <>
              <RemoveContainer onClick={() => handleRemovePress(attachment.id)}>
                <RemoveIcon className="icon icon-delete" onClick={() => handleRemovePress(attachment.id)} />
              </RemoveContainer>
              <SignatureSelection
                selectionHintText="Valitse allekirjoitustapa"
                selectedSignatureMethod={selectedSignatureMethod}
                onSignatureMethodSelected={(value) => value && setSignatureMethod(attachment.id, value)}
                selectableSignatureMethods={[SENT_FOR_MANUAL_SIGNING, SENT_FOR_SIGNING, SENT_FOR_INK_SIGNING]}
              />
            </>
          )}
        </FlexRowCenterFullHeight>
      </FlexAlignCenterJustifyBetween>
      <SignaturesList
        attachmentId={attachment.id}
        signatures={attachment.signatures}
        signatureMethod={selectedSignatureMethod}
        onNewSignatory={onNewSignatory}
        copyToAll={copyToAll}
        openSignatureDetails={openSignatureDetails}
      />
      {showNewSignatoryDialog && (
        <NewSignatoryDialog
          isOpen={true}
          contract={{ ...attachment.contract, name: 'Liite' }}
          savedSigners={savedSigners ?? []}
          onCloseClick={() => setShowNewSignatoryDialog(false)}
          onAddClick={(formValues) => addSignature(attachment.id, formValues)}
        />
      )}

      {signatureDetailsModal.open && (
        <SignatoryDetailsDialog
          signatory={signatureDetailsModal.signature}
          contract={attachment.contract}
          onRemoveClick={removeSignature}
          onCloseClick={() =>
            setSignatureDetailsModal({
              open: false,
              signature: emptySignature,
            })
          }
        />
      )}
    </AttachmentContainer>
  );
};

export default AttachmentItem;
