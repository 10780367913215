import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getContracts,
  mergeSearchFilters,
  setSearchString,
  setSearchFilters,
  fetchSavedContractFilters,
  deleteSavedFilter,
  setInstrumentKeywords,
  getContractPdf,
  saveFilters,
} from 'features/contractList/contractListActions';
import List from 'features/listView/List';
import ContractListTable from 'features/contractList/components/ContractListTable';
import { RootState } from 'types/rootState';
import { SearchFiltersType } from 'types/orderListState';
import { ImmutableContracts } from 'types/ordersState';

interface Props {
  contracts: ImmutableContracts;
  savedFilters: SearchFiltersType[];
  error: object;
  searchSkip: number;
  searchTake: number;
  totalResults: number;
  getContracts: (a: object) => void;
  mergeSearchFilters: () => void;
  setSearchString: () => void;
  saveFilters: () => void;
  setSearchFilters: (filters: SearchFiltersType | {}) => void;
  fetchSavedContractFilters: () => void;
  deleteSavedFilter: () => void;
  setInstrumentKeywords: () => void;
  getContractPdf: () => void;
  loadingContracts: boolean;
  contractPdfLoading: string;
  bankers: { value: string }[];
  searchFilters: SearchFiltersType;
}

class ContractListView extends Component<Props> {
  componentDidMount() {
    this.fetchOrders();
    this.props.fetchSavedContractFilters();
  }

  fetchOrders = () => {
    const { getContracts, searchTake, searchSkip, searchFilters } = this.props;
    return getContracts({
      take: searchTake,
      skip: searchSkip,
      searchFilters,
    });
  };

  fetchPage = (searchSkip: number) => {
    const { getContracts, searchTake, searchFilters } = this.props;
    return getContracts({
      skip: searchSkip,
      take: searchTake,
      searchFilters,
    });
  };

  render() {
    const {
      contracts,
      loadingContracts,
      contractPdfLoading,
      totalResults,
      searchFilters,
      searchSkip,
      searchTake,
      bankers,
      savedFilters,
    } = this.props;
    return (
      <List
        setSearchFilters={this.props.setSearchFilters}
        setSearchString={this.props.setSearchString}
        mergeSearchFilters={this.props.mergeSearchFilters}
        fetchItems={this.fetchOrders}
        fetchPage={this.fetchPage}
        deleteSavedFilter={this.props.deleteSavedFilter}
        saveFilters={this.props.saveFilters}
        handleInstrumentKeywords={this.props.setInstrumentKeywords}
        searchFilters={searchFilters}
        loadingOrders={loadingContracts}
        items={contracts}
        totalResults={totalResults}
        type="contracts"
        header="contract.contracts"
        searchTake={searchTake}
        searchSkip={searchSkip}
        bankers={bankers}
        savedFilters={savedFilters}
      >
        <ContractListTable
          //@ts-expect-error: something does not match here
          contractPdfLoading={contractPdfLoading}
          total={totalResults}
          items={contracts.toJS()}
          getContractPdf={this.props.getContractPdf}
        />
      </List>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    contracts: state.contractList.items,
    searchSkip: state.contractList.searchSkip,
    searchTake: state.contractList.searchTake,
    searchFilters: state.contractList.searchFilters.toJS(),
    totalResults: state.contractList.totalResults,
    savedFilters: state.contractList.savedFilters.toJS(),
    loadingContracts: state.contractList.loadingContracts,
    contractPdfLoading: state.contractList.contractPdfLoading,
    bankers: state.common.bankers,
  };
}

const mapDispatchToProps = {
  getContracts,
  mergeSearchFilters,
  setSearchString,
  setSearchFilters,
  fetchSavedContractFilters,
  deleteSavedFilter,
  setInstrumentKeywords,
  getContractPdf,
  saveFilters,
};

//@ts-expect-error: ?
export default connect(mapStateToProps, mapDispatchToProps)(ContractListView);
