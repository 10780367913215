import React from 'react';
import styled, { css } from 'styled-components';
import { ImpulseSpinner } from 'react-spinners-kit';

type SpinnerSize = 20 | 30 | 50 | 100;
interface Props {
  message?: string;
  className?: string;
  horizontal?: boolean;
  color?: string;
  size?: SpinnerSize;
  position?: 'left' | 'center' | 'right';
}

export const Spinner = ({ message, horizontal = false, className, color = 'black', size = 50, position }: Props) => (
  <SpinnerContainer
    horizontal={horizontal}
    position={position}
    className={className}
    size={size}
    data-testkey="spinner"
  >
    <ImpulseSpinner size={size} frontColor={color} backColor={'rgba(0,0,0,.05)'} />
    {message && <SpinnerMessage>{message}</SpinnerMessage>}
  </SpinnerContainer>
);

interface StyleProps {
  horizontal: boolean;
  position?: 'left' | 'center' | 'right';
  size: SpinnerSize;
}

const SpinnerContainer = styled.div<StyleProps>`
  display: flex;
  margin: 0;
  padding: 0.25rem;
  justify-content: ${(props) => (props.position === 'left' ? 'flex-start' : 'center')};
  ${(props) => props.position === 'left' && 'align-items: flex-start'};
  ${(props) => props.position === 'right' && 'align-items: flex-end'};
  ${(props) => props.position === 'center' && 'align-items: center'};
  ${(props) => !props.position && 'align-items: center'};

  ${(props) =>
    props.position === 'center' &&
    css`
      height: 100%;
    `}

  ${(props) =>
    !props.horizontal &&
    css`
      flex-direction: column;
    `};

  & > div {
    justify-content: space-between;

    & > div {
      animation-duration: 1.4s;
      animation-name: zoom;
      animation-timing-function: ease-in-out;
      top: auto;
      position: unset;
      opacity: 0;
      will-change: opacity, transform;

      @keyframes zoom {
        0% {
          transform: scale(0.1);
          opacity: 0.1;
        }
        40% {
          transform: scale(1.5);
          opacity: 0.8;
        }
        100% {
          transform: scale(0.1);
          opacity: 0.1;
        }
      }

      &:nth-child(1) {
        animation-delay: 0s;
      }
      &:nth-child(2) {
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        animation-delay: 0.8s;
      }
    }
  }
`;

const SpinnerMessage = styled.span`
  display: block;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
`;
