export const EXTERNAL_SOURCE = 'externalSource';
export const ADDITIONAL_INVESTMENT = 'additionalInvestment';
const INTERNAL_SOURCE = 'internalSource';
export const CAPITAL_CALL_BY_EMAIL = 'email';
const CAPITAL_CALL_BY_MAIL = 'mail';
export const CUSTOMER = 'customer';
export const CLIENT = 'Client';
export const BANKER = 'banker';
export const INITIALIZER_BANKER = 'Banker';
export const REPRESENTATIVE = 'Representative';
export const PHONE = 'phone';
export const POST = 'post';
export const ACCORDING_TO_INVESTMENT_ADVICE = 'accordingToAdvice';
export const AGAINST_INVESTMENT_ADVICE = 'againstAdvice';
export const CLIENT_INITIATIVE = 'clientInitiative';

export const initializerTypes = [
  {
    value: INITIALIZER_BANKER,
    label: 'Pankkiiri',
  },
  {
    value: CLIENT,
    label: 'Asiakas',
  },
  {
    value: REPRESENTATIVE,
    label: 'Edustaja',
  },
];

export const basisTypes = [
  {
    value: CLIENT_INITIATIVE,
    label: 'Asiakkaan aloite',
  },
  {
    value: ACCORDING_TO_INVESTMENT_ADVICE,
    label: 'Sijoitusneuvonta, neuvon mukainen',
  },
  {
    value: AGAINST_INVESTMENT_ADVICE,
    label: 'Sijoitusneuvonta, neuvon vastainen',
  },
];

export const moneySources = [
  {
    value: EXTERNAL_SOURCE,
    label: 'Maksetaan ulkopuolelta',
  },
  {
    value: ADDITIONAL_INVESTMENT,
    label: 'Tehdään lisäsijoitus',
  },
  {
    value: INTERNAL_SOURCE,
    label: 'Otetaan varainhoidossa olevista varoista',
  },
];

export const capitalCallMethods = [
  {
    value: CAPITAL_CALL_BY_EMAIL,
    label: 'Sähköpostilla',
  },
  {
    value: CAPITAL_CALL_BY_MAIL,
    label: 'Postitse',
  },
];
