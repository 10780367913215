import React from 'react';
import classnames from 'classnames';
import translate from 'counterpart';
import styles from './OrderSide.scss';

interface Props {
  type: string;
}

export const OrderSide = ({ type }: Props) => {
  const side = type.toLowerCase();
  return (
    <span className={classnames(styles.content, styles[side])}>
      <i className="icon icon-briefcase" />
      <span className={styles.side}>{translate(`order.${side}`)}</span>
    </span>
  );
};
