import allocator from './fi/allocator';
import common from './fi/common';
import contract from './fi/contract';
import customer from './fi/customer';
import home from './fi/home';
import prospects from './fi/prospects';
import wizard from './fi/wizard';
import search from './fi/search';
import newContract from './fi/newContract';
import validations from './fi/validations';
import contractStates from './fi/contractStates';
import order from './fi/order';
import orderList from './fi/orderList';
import meetings from './fi/meetings';
import portfolios from './fi/portfolios';
import portfolioManager from './fi/portfolioManager';
import errors from './fi/errors';

export default {
  allocator,
  common,
  contract,
  customer,
  home,
  prospects,
  wizard,
  search,
  newContract,
  validations,
  contractStates,
  order,
  meetings,
  orderList,
  portfolios,
  portfolioManager,
  errors,
};
