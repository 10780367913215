import React from 'react';
import { range } from 'lodash';
import translate from 'counterpart';
import styled from 'styled-components';
import { RiskLevel } from 'types/types';
import { RiskScaleButton } from './RiskScaleButton';
import { colors } from 'styles/colors';
import { fontSize, HelpTextSmall } from 'features/common/StyledComponents';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  risk: RiskLevel;
  disabled: boolean;
}

export const RiskScale = ({ onClick, risk, disabled }: Props) => {
  const riskLevels = range(10, 0) as RiskLevel[];

  return (
    <>
      <h2>{translate('allocator.configureRisk.selectRiskLevel')}</h2>

      {disabled && (
        <HelpTextSmall>{disabled && translate('allocator.configureRisk.changeRiskInBasicInfo')}</HelpTextSmall>
      )}

      <RiskLevelsContainer>
        <RiskHeaderRow>
          <RiskLevelHeader>{translate('allocator.configureRisk.riskLevel')}</RiskLevelHeader>
          <YieldValueHeader>{translate('allocator.configureRisk.expectedYield')}</YieldValueHeader>
        </RiskHeaderRow>
        {riskLevels.map((e) => (
          <RiskScaleButton key={e} selected={e === risk} onClick={onClick} riskLevel={e} disabled={disabled} />
        ))}
      </RiskLevelsContainer>
    </>
  );
};

const RiskLevelsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const RiskHeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: pre;

  & > div {
    font-size: ${fontSize.smaller};
    padding: 1rem 0;
  }
`;

const RiskLevelHeader = styled.div`
  font-weight: 600;
  width: 4rem;
`;

const YieldValueHeader = styled.div`
  color: ${colors.dark_gray};
  width: 6rem;
  margin-left: 0.5rem;
`;
