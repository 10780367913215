import React from 'react';
import translate from 'counterpart';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  value: number | string | undefined;
  onNoteChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  marginTop?: string;
}

export const Note = ({ value, onNoteChange, marginTop }: Props) => (
  <StyledTextField
    id={'noteField'}
    key={'noteField'}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    maxLength={255}
    multiline={true}
    autoCorrect="off"
    spellCheck="false"
    autoComplete="off"
    fullWidth={true}
    name={`note-${1}`}
    onChange={onNoteChange}
    value={value}
    label={translate('order.internalNote')}
    data-testkey="internal-note-textfield"
    variant="standard"
    sx={[
      {
        '&.MuiFormControl-root': {
          marginTop: marginTop ? marginTop : '0',
          width: '100%',
        },
        '& .Mui-focused': {
          '&::after': {
            left: 0,
          },
        },
        '& .MuiInput-root:hover': {
          borderBottomColor: 'rgba(0, 0, 0, 0.6)',
          '&::before': {
            borderBottomColor: 'rgba(0, 0, 0, 0.6) !important',
            borderWidth: '1px !important',
          },
        },
        '& .MuiInputBase-root': {
          marginTop: '14px',
          marginBottom: '15px',
          paddingLeft: 0,
          '&::before': {
            left: 0,
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Calibre, Roboto, sans-serif',
          marginTop: 0,
          height: '100%',
          paddingTop: 0,
        },
        '& label': {
          fontSize: '15px',
          fontWeight: '400',
          '&.Mui-focused': {
            fontSize: '15px',
          },
        },
        '& > p': {
          '&.MuiFormHelperText-root': {
            paddingLeft: '0',
          },
        },
        '&.Mui-error': {
          borderBottomColor: '#d32f2f',
          borderWidth: '2px',
          '&::before': {
            borderWidth: '2px',
            borderBottomColor: '#d32f2f',
          },
        },
      },
    ]}
  />
);
