import React, { useEffect } from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { colors } from 'styles/colors';
import {
  selectHasPositions,
  selectTestRisk,
  selectActualRisk,
  selectSelectedRisk,
} from 'features/allocator/allocatorSelectors';
import { StepNavigationItem, StepItemHeader, LowerPart, LinkButtonWithMargin } from './StepNavigationStyles';
import { FlexJustifyBetween, HelpText } from 'features/common/StyledComponents';
import { RootState } from 'types/rootState';
import { StepNavigationStepProps } from 'features/allocator/stepNavigation/stepNavigationTypes';
import { Spinner } from 'features/common/Spinner';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { RiskLevel } from 'types/types';
import { RiskSelection } from 'features/allocator/riskSelection/components/RiskSelection';
import { config } from 'config';
import { optimize } from 'features/weights/weightsThunks';
import { setTestRiskLevel } from 'features/allocator/investmentPlan/investmentPlanActions';
import { customerHasCurrentWeights } from 'features/weights/weightsSelectors';
import { useAppDispatch } from 'core/hooks';

export const StepRisk = ({ unset, disableEdit, locked, loading }: StepNavigationStepProps) => {
  const dispatch = useAppDispatch();

  const actualRisk = useSelector(selectActualRisk);
  const selectedRisk = useSelector(selectSelectedRisk);
  const testRisk = useSelector(selectTestRisk);

  const hasPositions = useSelector(selectHasPositions);
  const hasCurrentWeights = useSelector(customerHasCurrentWeights);
  const loadingProfile = useSelector((state: RootState) => state.profile.isBusy);

  const customerId = useSelector(selectCustomerId);
  const riskSelectionLink = `customer/${customerId}/portfolioManager/allocator/risk`;

  const loadingDrafts = useSelector((state: RootState) => state.profile.loadingDrafts);
  const fetchingContract = useSelector((state: RootState) => state.profile.fetchingContract);
  const loadingRisk = loadingDrafts || fetchingContract;

  useEffect(() => {
    dispatch(setTestRiskLevel(actualRisk));
  }, [actualRisk]);

  const onRiskButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const element = event.target as HTMLButtonElement;
    const testRiskLevel = Number(element.value) as RiskLevel;
    dispatch(setTestRiskLevel(testRiskLevel));
    dispatch(optimize({ payload: { customerRiskLevel: testRiskLevel }, useCache: true }));
  };

  const showTryRisk = config.existingCustomerTryRisk && hasPositions;

  return (
    <StepNavigationItem unset={unset}>
      <div>
        <StepItemHeader>{translate('allocator.investmentPlanSteps.risk.riskTitle')}</StepItemHeader>

        {actualRisk > 0 && (
          <RiskRow>
            <div>
              {selectedRisk > 0
                ? translate('allocator.investmentPlanSteps.risk.riskLevelOnContract')
                : translate('allocator.investmentPlanSteps.risk.targetRiskLevel')}
            </div>
            <RiskNumber>{actualRisk}</RiskNumber>
          </RiskRow>
        )}

        {!actualRisk &&
          (loadingProfile || loadingRisk ? (
            <Spinner position="left" size={30} />
          ) : (
            <HelpText>{translate('allocator.investmentPlanSteps.risk.riskDetails')}</HelpText>
          ))}

        {showTryRisk && (
          <TryRisk data-testkey="try-risk-level">
            {translate('allocator.investmentPlanSteps.risk.tryRiskLevel')}
            <RiskSelection disabled={loadingRisk} onClick={onRiskButtonClick} risk={testRisk} />
          </TryRisk>
        )}
      </div>

      {!showTryRisk && (
        <LowerPart>
          <LinkButtonWithMargin
            to={riskSelectionLink}
            secondary={!!actualRisk}
            label={translate(`allocator.investmentPlanSteps.risk.button${actualRisk ? 'Change' : 'Select'}`)}
            disabled={disableEdit || locked || loading || hasCurrentWeights}
            data-testkey={'select-risk-level-button'}
          />
        </LowerPart>
      )}
    </StepNavigationItem>
  );
};

const RiskRow = styled(FlexJustifyBetween)`
  align-items: center;
`;

const RiskNumber = styled.div`
  border-radius: 50%;
  border: 2px solid ${colors.primary};
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 900;
  margin-left: 1rem;
`;

const TryRisk = styled.div`
  border-top: 1px dashed lightgrey;
  padding-top: 1rem;
  margin-top: 1rem;
`;
