import { Map, List, fromJS, Set } from 'immutable';
import { get } from 'lodash';
import {
  CONTRACT_UPLOAD_ATTACHMENT_REQUEST,
  CONTRACT_UPLOAD_ATTACHMENT_SUCCESS,
  CONTRACT_UPLOAD_ATTACHMENT_FAILURE,
  FIELD_VALIDATION,
  MODULE_VALIDATION,
  TOUCH_FIELDS,
  TOGGLE_CONTRACT,
  CONTRACT_TEMPLATES_RECEIVE,
  CONTRACT_TEMPLATES_REQUEST,
  CONTRACT_TEMPLATES_FAILURE,
  CHANGE_SECTION_FIELD,
  CONTRACT_TEMPLATE_RECEIVE,
  CONTRACT_TEMPLATE_FAILURE,
  CONTRACT_TEMPLATE_START_LOADING,
  CONTRACT_TEMPLATE_FINISH_LOADING,
  CONTRACT_SAVE_DRAFT_SUCCESS,
  CONTRACT_RESET_NOTIFICATION,
  CONTRACT_PREFILLED_SUCCESS,
  CONTRACT_PREFILLED_FAILURE,
  CONTRACT_LOAD_DRAFT_REQUEST,
  CONTRACT_LOAD_DRAFT_SUCCESS,
  CONTRACT_MERGE_DRAFT,
  MERGE_CONTRACT_COPY,
  USER_SESSION_CREATE,
  CLEAR_SELECTED,
  USER_SESSION_RESET,
  CONTRACT_PREVIEW_REQUEST,
  CONTRACT_PREVIEW_RECEIVE,
  CONTRACT_SIGNATURE_METHOD,
  CONTRACT_MANUAL_SIGNATURES,
  SHOW_UNSAVED_DATA_WARNING,
  HIDE_UNSAVED_DATA_WARNING,
  CONTRACTS_CREATE_REQUEST,
  CONTRACTS_CREATE_SUCCESS,
  CONTRACTS_CREATE_ERROR,
  CONTRACTS_CREATE_DATA_ERROR,
  SET_ACTIVE_CONTRACT,
  TOGGLE_CREATE_CONTRACTS_CONFIRMATION,
  PDF_PREVIEW_REQUEST,
  PDF_PREVIEW_SUCCESS,
  PDF_PREVIEW_FAILURE,
  PDF_CONTRACT_SUCCESS,
  PDF_CONTRACT_REQUEST,
  PDF_CONTRACT_FAILURE,
  CONTRACT_RESPONSE_SUCCESS,
  TOGGLE_NEW_SIGNATORY_DIALOG,
  CONTRACT_ADD_SIGNATORY,
  SHOW_ONLY_UNFINISHED,
  CONTRACT_REMOVE_SIGNATORY,
  COPY_SIGNATORIES_TO_ALL,
  TOGGLE_SIGNATORY_DETAILS_DIALOG,
  ELECTRONIC_CONTRACTS_SIGNATORIES_SUCCESS,
  ELECTRONIC_CONTRACTS_SIGNATORIES_FAILURE,
  CONTRACT_PREFILL_SIGNATORY,
  SHOW_DISCARD_SESSION_DIALOG,
  HIDE_DISCARD_SESSION_DIALOG,
  GET_SIGNATORIES_FOR_CUSTOMER,
  BISNODE_GET,
  BISNODE_UPDATED,
  BISNODE_UPDATING,
  HAS_WEB_SERVICE_REQUEST,
  HAS_WEB_SERVICE_SUCCESS,
  HAS_WEB_SERVICE_FAILURE,
  GET_WEB_SERVICE_CONTRACT_REQUEST,
  GET_WEB_SERVICE_CONTRACT_SUCCESS,
  GET_WEB_SERVICE_CONTRACT_FAILURE,
  ADD_WEB_SERVICE_CONTRACT_REQUEST,
  ADD_WEB_SERVICE_CONTRACT_SUCCESS,
  ADD_WEB_SERVICE_CONTRACT_FAILURE,
  ContractsAction,
  CONTRACT_REMOVE_ALL_SIGNATORIES,
  CONTRACT_REMOVE_ATTACHMENT_FAILURE,
  CONTRACT_REMOVE_ATTACHMENT_SUCCESS,
  CONTRACT_REMOVE_ATTACHMENT_REQUEST,
  CONTRACT_UPLOAD_ATTACHMENT_SIGNATURE_METHOD,
  CONTRACT_UPLOAD_ATTACHMENT_ADD_SIGNATURE,
  CONTRACT_UPLOAD_ATTACHMENT_REMOVE_SIGNATURE,
  SET_ATTACHMENT_MANUAL_SIGNATURES,
  CONTRACT_ADD_ATTACHMENT,
  CONTRACT_RESET_ATTACHMENTS,
} from './contractsActions';
import {
  INIT_ARRAY_EDITOR_VALUES,
  SET_ARRAY_EDITOR_VALUES,
  SAVE_ARRAY_EDITOR_VALUES,
  DELETE_ARRAY_ANSWER,
  IndexAction,
} from 'actions/index';
import { validateFormValues } from 'core/index';
import { isValidSignatory } from 'core/validations';
import { fixContractSchema } from 'core/functions';
import {
  selectContactFromEmailReportsCompanyContract,
  selectContactFromEmailReportsContract,
  selectContactFromWebServiceContract,
} from './contractsSelectors';
import {
  WEB_SERVICE_CONTRACT,
  EMAIL_REPORTS,
  EMAIL_REPORTS_COMPANY,
  WEB_SERVICE_CONTRACT_PROXY,
} from 'constants/contractIds';
import { SENT_FOR_INK_SIGNING } from 'constants/contractStates';
import { SIGNATORY_NOT_EDITABLE, isWebServiceContract } from 'constants/contractGroups';
import {
  ContractsState,
  ImmutableAvailableTemplates,
  ImmutableFormErrors,
  ImmutableSelectedContracts,
  ImmutableSession,
  ImmutableTemplatesLoading,
  Schema,
} from 'types/contractsState';
import {
  Contract,
  ImmutableContract,
  ImmutableContracts,
  ImmutablePdfPreviewLink,
  ImmutableTemplates,
  Item,
  Signatory,
  Template,
} from 'types/ordersState';
import { ImmutableContent } from 'types/profileState';
import { SignatureMethodType } from 'types/types';
import { BisnodeUpdate, Signers } from 'features/orders/ordersTypes';
import {
  PDF_ATTACHMENT_FAILURE,
  PDF_ATTACHMENT_LOADING,
  PDF_ATTACHMENT_SUCCESS,
  PDF_ATTACHMENT_TEMP_ID,
} from './contractsConstants';
import { PdfAttachment } from './contracts.types';

export const initialState: ContractsState = {
  links: [
    {
      translateTitle: 'newContract.selection.title',
      translateLink: 'newContract.selection.linkText',
      route: 'selection',
    },
    {
      translateTitle: 'newContract.overview.title',
      translateLink: 'newContract.overview.linkText',
      route: 'overview',
    },
    {
      translateTitle: 'newContract.preview.title',
      translateLink: 'newContract.preview.linkText',
      route: 'preview',
    },
    {
      translateTitle: 'newContract.confirm.title',
      translateLink: 'newContract.confirm.linkText',
      route: 'signatures',
    },
  ],
  attachmentPdfs: [],
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  availableTemplates: List() as ImmutableAvailableTemplates,
  selected: Map() as ImmutableSelectedContracts,
  values: Map() as ImmutableContent,
  templates: Map() as ImmutableTemplates,
  templatesLoading: Map() as ImmutableTemplatesLoading,
  notify: false,
  notifyMsg: '',
  lastSavedValues: null,
  formTouched: Set(),
  formErrors: Map() as ImmutableFormErrors,
  pdfPreviewLinks: Map() as ImmutablePdfPreviewLink,
  draft: Map(),
  session: Map() as ImmutableSession,
  signatureMethods: Map(),
  showUnSavedDataWarning: false,
  showCreateContractsConfirm: false,
  showNewSignatoryDialog: Map(),
  showSignatoryDetailsDialog: Map(),
  arrayEditorValues: Map(),
  isBusy: false,
  contracts: Map() as ImmutableContracts,
  contractsSignatories: Map(),
  manualSigners: Map(),
  isReady: false,
  isRestored: false,
  activeContract: null,
  showOnlyUnfinished: false,
  electronicSignatoriesSent: false,
  electronicSignatoriesFailure: false,
  createContractsForSendingFailure: false,
  showDiscardSessionDialog: false,
  requireValidContracts: true,
  contractsSigning: Map(),
  webServiceContractExist: false,
  fetchingWebServiceContractStatus: false,
  addingWebServiceContract: false,
  language: 'fi',
  uploadingDocument: false,
  lastAttachmentPdfs: [],
};

function touchFields(state: ContractsState, fieldKeys: string[]) {
  return state.formTouched.union(fieldKeys);
}

export function formValidation(state: ContractsState, schema: Schema, contractId: string) {
  const values = state.values.get(contractId);
  const valuesJS = values.toJS();
  const contractErrors = fromJS(validateFormValues(valuesJS, schema));
  return state.formErrors.set(contractId, contractErrors);
}

function getFieldKeys(items: Item[], contractId: string) {
  const fieldKeys = items.map((i) => `${contractId}.${i.key}`);
  return fieldKeys;
}

function addSignatoryToContract(state: ContractsState, action: { signatory: Signatory; contractType: string }) {
  const signatory = action.signatory;
  if (
    !isValidSignatory(signatory) ||
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    state.contractsSignatories.get(action.contractType, List()).some((s) => s.get('ssn') === signatory.ssn)
  ) {
    return state;
  }

  const newSignatories = state.contractsSignatories.updateIn([action.contractType], List(), (signatories) =>
    signatories.push(Map(signatory))
  );
  return {
    ...state,
    contractsSignatories: newSignatories,
  };
}

function removeSignatoryFromContract(state: ContractsState, action: { contractType: string }) {
  const newSignatories = state.contractsSignatories.updateIn([action.contractType], List(), (signatories) =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    signatories.filter((signatory) => signatory.get('ssn') !== action.signatory.ssn)
  );
  return { ...state, contractsSignatories: newSignatories };
}

function copySignatoriesToAllContracts(state: ContractsState, action: { signatories: Signatory[] }) {
  const updatedSignatories = Object.keys(state.templates.toJS()).reduce((prev, current) => {
    let result;
    if (SIGNATORY_NOT_EDITABLE.includes(current)) {
      result = prev;
    } else {
      result = prev.setIn([current], fromJS(action.signatories));
    }
    return result;
  }, state.contractsSignatories);

  const updatedAttachments = state.attachmentPdfs.map((pdfItem) => ({ ...pdfItem, signatures: action.signatories }));

  return {
    ...state,
    contractsSignatories: updatedSignatories,
    attachmentPdfs: updatedAttachments,
  };
}

function toggleSignatoryDetailsDialog(
  state: ContractsState,
  action: { show: boolean; signatory: Signatory; contract: Contract }
) {
  return {
    ...state,
    showSignatoryDetailsDialog: fromJS({
      show: action.show,
      signatory: action.signatory,
      contract: action.contract,
    }),
  };
}

function receiveContractTemplate(state: ContractsState, action: { result: Template }) {
  return {
    ...state,
    templates: state.templates.set(action.result.contractType, action.result),
  };
}

function receiveContractTemplateFailure(state: ContractsState, action: { contractId: string }) {
  return {
    ...state,
    templatesLoading: state.templatesLoading.set(action.contractId, false),
    selected: state.selected.set(action.contractId, false),
  };
}

function contractPrefilledFailure(state: ContractsState, action: { contractId: string }) {
  return receiveContractTemplateFailure(state, action);
}

function startLoadingContractTemplate(state: ContractsState, action: { contractType: string }) {
  return {
    ...state,
    templatesLoading: state.templatesLoading.set(action.contractType, true),
  };
}

function finishLoadingContractTemplate(state: ContractsState, action: { contractType: string; schema: Schema }) {
  return {
    ...state,
    templatesLoading: state.templatesLoading.set(action.contractType, false),
    formErrors: formValidation(state, action.schema, action.contractType),
  };
}

function isCustomerTypePerson(state: ContractsState, contractName: string) {
  const types = get(state.templates.get(contractName), ['schema', 'customerTypes'], []);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return types.includes('person');
}

function getSignatory(state: ContractsState, contractName: string, contract: ImmutableContract) {
  let signatory;
  if (!state.selected.get(contractName, false)) {
    signatory = undefined;
  } else if (contractName === 'yksityishenkilonPerustiedot' || contractName === 'yksityishenkilonveromaat') {
    const { firstName, lastName, ssn } = contract.getIn(['sections', 'basicInfo', 'info'], Map()).toJS();
    const { phoneNumber, email } = contract.getIn(['sections', 'basicInfo', 'contact'], Map()).toJS();
    signatory = { firstName, lastName, ssn, email, phone: phoneNumber };
  } else if (isCustomerTypePerson(state, contractName)) {
    const { firstName, lastName, ssn, email, phoneNumber } = contract
      .getIn(['sections', 'clientSummary'], Map())
      .toJS();
    signatory = { firstName, lastName, ssn, email, phone: phoneNumber };
  }
  return signatory;
}

function contractHasSignatories(state: ContractsState, action: { contractType: string }) {
  return (
    state.contractsSignatories.has(action.contractType) && state.contractsSignatories.get(action.contractType).size > 0
  );
}

function prefillSignatory(state: ContractsState, action: { contractType: string }) {
  let contact;
  let signatory;
  if (
    [EMAIL_REPORTS, EMAIL_REPORTS_COMPANY].includes(action.contractType) ||
    isWebServiceContract(action.contractType)
  ) {
    if (isWebServiceContract(action.contractType)) {
      contact = selectContactFromWebServiceContract(state).toJS();
    }

    switch (action.contractType) {
      case EMAIL_REPORTS:
        contact = selectContactFromEmailReportsContract(state).toJS();
        break;
      case EMAIL_REPORTS_COMPANY:
        contact = selectContactFromEmailReportsCompanyContract(state).toJS();
        break;
    }
    const { phoneNumber: phone, ...rest } = contact;
    signatory = {
      phone,
      ...rest,
    };
  } else if (!contractHasSignatories(state, action)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const signatories = state.values.entrySeq().map((c) => getSignatory(state, c[0], c[1]));
    signatory = List(signatories)
      .filter((s) => s !== undefined)
      .first();
  }

  if (signatory) {
    return addSignatoryToContract(state, { signatory, contractType: action.contractType });
  }
  return state;
}

function showDiscardSessionDialog(state: ContractsState) {
  return { ...state, showDiscardSessionDialog: true };
}

function hideDiscardSessionDialog(state: ContractsState) {
  return { ...state, showDiscardSessionDialog: false };
}

function sortContractTemplatesByName(contractTemplates: Template[]) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return contractTemplates.sort((t1, t2) => t1.name.localeCompare(t2.name));
}

function contractsCreateError(state: ContractsState) {
  return { ...state, isBusy: false, isReady: false, electronicSignatoriesFailure: true };
}

function contractsCreateDataError(state: ContractsState) {
  return { ...state, isBusy: false, isReady: false, createContractsForSendingFailure: true };
}

function getClientSigners(state: ContractsState, action: { signers: Signers }) {
  return { ...state, savedSigners: action.signers.signers };
}

function bisnodeUpdated(state: ContractsState, action: { bisnode: BisnodeUpdate | {}; canUpdateBisnode: boolean }) {
  return { ...state, bisnode: action.bisnode, canUpdateBisnode: action.canUpdateBisnode };
}

function setWebServiceContract(state: ContractsState, action: { result: Contract }) {
  let newState = state;
  if (action.result !== null) {
    newState = {
      ...state,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      values: state.values.set(WEB_SERVICE_CONTRACT, fromJS(action.result)),
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      selected: state.selected.set(WEB_SERVICE_CONTRACT, true),
    };
  }
  return newState;
}

const addSignatureToAttachment = (state: ContractsState, attachmentId: string, signature: Signatory) => {
  if (
    // TODO: !isValidSignatory(signature) ||
    state.attachmentPdfs
      .find((pdfItem: PdfAttachment) => pdfItem.id === attachmentId)
      ?.signatures.find((signatureItem) => signatureItem.ssn === signature.ssn)
  ) {
    return state;
  }
  return {
    ...state,
    attachmentPdfs: state.attachmentPdfs.map((pdfItem: PdfAttachment) => {
      if (pdfItem.id === attachmentId) {
        return {
          ...pdfItem,
          signatures: pdfItem.signatures.concat(signature),
        };
      } else {
        return pdfItem;
      }
    }),
  };
};

const removeSignatureFromAttachment = (state: ContractsState, attachmentId: string, signature: Signatory) => {
  return {
    ...state,
    attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
      if (attachmentId === pdfItem.id) {
        return {
          ...pdfItem,
          signatures: pdfItem.signatures.filter((signatureItem) => signatureItem.ssn !== signature.ssn),
        };
      } else {
        return pdfItem;
      }
    }),
  };
};

export function contractsReducer(state = initialState, action: ContractsAction | IndexAction) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  switch (action.type) {
    case FIELD_VALIDATION:
      return {
        ...state,
        formTouched: touchFields(state, [`${action.contractId}.${action.fieldKey}`]),
        formErrors: formValidation(state, action.schema, action.contractId),
      };

    case MODULE_VALIDATION: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const fieldKeys = getFieldKeys(action.module.items, action.contractId);
      return {
        ...state,
        formTouched: touchFields(state, fieldKeys),
        formErrors: formValidation(state, action.schema, action.contractId),
      };
    }

    case TOUCH_FIELDS: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const touchKeys = getFieldKeys(action.fields, action.contractId);
      return {
        ...state,
        formTouched: touchFields(state, touchKeys),
      };
    }

    case TOGGLE_CONTRACT:
      return {
        ...state,
        selected: state.selected.update(action.contractId, (v) => !v),
        language: state.contracts.setIn([action.contractId, 'language'], action.language),
      };
    case CONTRACT_TEMPLATES_REQUEST:
      return {
        ...state,
        isBusy: true,
      };
    case CONTRACT_TEMPLATES_FAILURE:
      return {
        ...state,
        isBusy: false,
      };
    case CONTRACT_TEMPLATES_RECEIVE:
      return {
        ...state,
        isBusy: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        availableTemplates: sortContractTemplatesByName(List(action.result.results)),
      };

    case CHANGE_SECTION_FIELD: {
      const path = [action.contractType].concat(action.key.split('.'));
      return {
        ...state,
        values: state.values.setIn(path, fromJS(action.value)),
      };
    }

    case CONTRACT_TEMPLATE_RECEIVE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return receiveContractTemplate(state, action);
    case CONTRACT_TEMPLATE_FAILURE:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return receiveContractTemplateFailure(state, action);

    case CONTRACT_TEMPLATE_START_LOADING:
      return startLoadingContractTemplate(state, action);

    case CONTRACT_TEMPLATE_FINISH_LOADING:
      return finishLoadingContractTemplate(state, action);
    case CONTRACT_SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        lastSavedValues: state.values,
        lastAttachmentPdfs: state.attachmentPdfs,
        notify: true,
        notifyMsg: 'Keskeneräiset sopimukset tallennettu.',
      };

    case CONTRACT_RESET_NOTIFICATION:
      return {
        ...state,
        notify: false,
        notifyMsg: '',
      };

    case CONTRACT_PREFILLED_SUCCESS: {
      const contract = action.result;
      const template = state.templates.get(contract.contractType);
      const fixedContract = fixContractSchema(contract, template, action.countries);
      return {
        ...state,
        values: state.values.merge({ [contract.contractType]: fixedContract }),
      };
    }

    case CONTRACT_PREFILLED_FAILURE:
      return contractPrefilledFailure(state, action);

    case CONTRACT_LOAD_DRAFT_REQUEST: {
      return {
        ...state,
        draft: state.draft?.clear(),
      };
    }

    case CONTRACT_LOAD_DRAFT_SUCCESS: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        draft: fromJS(JSON.parse(action.result.payload)),
      };
    }

    case CONTRACT_MERGE_DRAFT: {
      const allValues = state.values.toJS();
      const currentValues = state.values.toJS()[action.templateId];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      const draft = state.draft.toJS()[action.templateId];
      draft.schema = currentValues.schema;
      draft.form = currentValues.form;

      allValues[action.templateId] = draft;

      return {
        ...state,
        values: Map(fromJS(allValues)),
        selected: state.selected.set(action.templateId, true),
        isRestored: true,
      };
    }

    case MERGE_CONTRACT_COPY: {
      const copy = action.contract;
      let selected = state.selected.clear();
      const key = Object.keys(copy)[0];
      selected = selected.set(key, true);
      return {
        ...state,
        values: state.values.merge(fromJS(copy)),
        selected,
      };
    }

    case USER_SESSION_CREATE: {
      return {
        ...state,
        session: Map({ id: action.id, name: action.name }),
      };
    }

    case CLEAR_SELECTED: {
      return {
        ...state,
        selected: state.selected.clear(),
      };
    }

    case USER_SESSION_RESET: {
      return {
        ...state,
        session: state.session.clear(),
        selected: state.selected.clear(),
        values: state.values.clear(),
        templates: state.templates.clear(),
        isReady: false,
        isRestored: false,
        contracts: state.contracts.clear(),
        signatureMethods: state.signatureMethods.clear(),
        pdfPreviewLinks: state.pdfPreviewLinks.clear(),
        electronicSignatoriesSent: false,
        formTouched: state.formTouched.clear(),
        availableTemplates: state.availableTemplates.clear(),
        attachmentPdfs: [],
      };
    }

    case CONTRACT_PREVIEW_REQUEST:
      return {
        ...state,
        createContractsForSendingFailure: false,
        pdfPreviewLinks: state.pdfPreviewLinks.clear(),
      };

    case CONTRACT_PREVIEW_RECEIVE:
      return {
        ...state,
        pdfPreviewLinks: state.pdfPreviewLinks.set(
          action.contractType,
          fromJS({
            name: action.name,
            id: action.previewId,
          })
        ),
      };

    case CONTRACT_SIGNATURE_METHOD:
      // eslint-disable-next-line no-case-declarations
      const contractId = action.contractId === WEB_SERVICE_CONTRACT_PROXY ? WEB_SERVICE_CONTRACT : action.contractId;

      return {
        ...state,
        signatureMethods: state.signatureMethods.set(contractId, action.method as SignatureMethodType),
        contractsSignatories:
          action.method === SENT_FOR_INK_SIGNING
            ? state.contractsSignatories.updateIn([contractId], List(), (signatories) => signatories.take(1))
            : state.contractsSignatories,
      };

    case CONTRACT_MANUAL_SIGNATURES:
      return {
        ...state,
        manualSigners: state.manualSigners.setIn([action.contractType], action.amount),
      };

    case SHOW_UNSAVED_DATA_WARNING:
      return {
        ...state,
        showUnSavedDataWarning: true,
      };

    case HIDE_UNSAVED_DATA_WARNING:
      return {
        ...state,
        showUnSavedDataWarning: false,
      };

    case INIT_ARRAY_EDITOR_VALUES:
      return {
        ...state,
        arrayEditorValues: fromJS(action.values),
      };

    case SET_ARRAY_EDITOR_VALUES:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        arrayEditorValues: state.arrayEditorValues.setIn(action.key, action.value),
      };

    case SAVE_ARRAY_EDITOR_VALUES: {
      const basePath = [action.contractType].concat(action.key.split('.'));
      let values;
      if (action.index >= 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        values = state.values.setIn(basePath.concat(action.index), state.arrayEditorValues);
      } else {
        values = state.values.updateIn(basePath, (arr) => {
          if (arr) {
            return arr.push(state.arrayEditorValues);
          }
          return fromJS([state.arrayEditorValues]);
        });
      }
      return {
        ...state,
        values,
        arrayEditorValues: Map(),
      };
    }

    case DELETE_ARRAY_ANSWER:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        values: state.values.removeIn([action.contractType].concat(action.key.split('.'), action.index)),
      };

    case CONTRACTS_CREATE_REQUEST:
      return {
        ...state,
        isBusy: true,
        contracts: state.contracts.clear(),
        showCreateContractsConfirm: false,
      };
    case CONTRACTS_CREATE_SUCCESS:
      return {
        ...state,
        isBusy: false,
        isReady: true,
      };

    case CONTRACTS_CREATE_ERROR:
      return contractsCreateError(state);

    case CONTRACTS_CREATE_DATA_ERROR:
      return contractsCreateDataError(state);

    case SET_ACTIVE_CONTRACT:
      return {
        ...state,
        activeContract: action.contract,
      };

    case TOGGLE_CREATE_CONTRACTS_CONFIRMATION:
      return {
        ...state,
        showCreateContractsConfirm: action.show,
      };

    case PDF_PREVIEW_REQUEST: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        pdfPreviewLinks: state.pdfPreviewLinks.update(action.contractType, (value) => value.set('isBusy', true)),
      };
    }

    case PDF_PREVIEW_SUCCESS:
      return {
        ...state,
        pdfPreviewLinks: state.pdfPreviewLinks.update(action.contractType, (value) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value.set('blobUrl', action.blobUrl)
        ),
      };

    case PDF_PREVIEW_FAILURE: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        pdfPreviewLinks: state.pdfPreviewLinks.update(action.contractType, (value) => value.set('isBusy', false)),
      };
    }

    case PDF_CONTRACT_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        contracts: state.contracts.update(action.id, (value) => value.set('blobUrl', action.blobUrl)),
      };

    case PDF_CONTRACT_REQUEST: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        contracts: state.contracts.update(action.id, (value) => value.set('isBusy', true)),
      };
    }

    case PDF_CONTRACT_FAILURE: {
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        contracts: state.contracts.update(action.id, (value) => value.set('isBusy', false)),
      };
    }

    case CONTRACT_RESPONSE_SUCCESS:
      return {
        ...state,
        contracts: Map(action.contracts),
        isBusy: false,
        isReady: true,
        electronicSignatoriesSent: true,
      };
    case TOGGLE_NEW_SIGNATORY_DIALOG:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        showNewSignatoryDialog: state.showNewSignatoryDialog.merge({ show: action.show, contract: action.contract }),
      };

    case CONTRACT_ADD_SIGNATORY:
      return addSignatoryToContract(state, action);

    case SHOW_ONLY_UNFINISHED:
      return {
        ...state,
        showOnlyUnfinished: action.value,
      };

    case CONTRACT_REMOVE_SIGNATORY:
      return removeSignatoryFromContract(state, action);

    case CONTRACT_REMOVE_ALL_SIGNATORIES:
      return {
        ...state,
        contractsSignatories: state.contractsSignatories.clear(),
      };

    case COPY_SIGNATORIES_TO_ALL:
      return copySignatoriesToAllContracts(state, action);

    case TOGGLE_SIGNATORY_DETAILS_DIALOG:
      return toggleSignatoryDetailsDialog(state, action);
    case ELECTRONIC_CONTRACTS_SIGNATORIES_SUCCESS:
      return {
        ...state,
        electronicSignatoriesSent: true,
      };
    case ELECTRONIC_CONTRACTS_SIGNATORIES_FAILURE:
      return {
        ...state,
        electronicSignatoriesFailure: true,
      };

    case CONTRACT_PREFILL_SIGNATORY:
      return prefillSignatory(state, action);

    case SHOW_DISCARD_SESSION_DIALOG:
      return showDiscardSessionDialog(state);

    case HIDE_DISCARD_SESSION_DIALOG:
      return hideDiscardSessionDialog(state);

    case GET_SIGNATORIES_FOR_CUSTOMER:
      return getClientSigners(state, action);

    case BISNODE_GET:
      return bisnodeUpdated(state, action);

    case BISNODE_UPDATED:
      return bisnodeUpdated(state, action);

    case BISNODE_UPDATING:
      return {
        ...state,
        canUpdateBisnode: false,
      };
    case HAS_WEB_SERVICE_REQUEST:
      return {
        ...state,
        fetchingWebServiceContractStatus: true,
        webServiceContractExist: false,
      };
    case HAS_WEB_SERVICE_SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        webServiceContractExist: action.result,
        fetchingWebServiceContractStatus: false,
      };
    case HAS_WEB_SERVICE_FAILURE:
      return {
        ...state,
        webServiceContractExist: false,
        fetchingWebServiceContractStatus: false,
      };
    case GET_WEB_SERVICE_CONTRACT_REQUEST:
      return {
        ...state,
      };

    case GET_WEB_SERVICE_CONTRACT_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return setWebServiceContract(state, action);

    case GET_WEB_SERVICE_CONTRACT_FAILURE:
      return {
        ...state,
      };

    case ADD_WEB_SERVICE_CONTRACT_REQUEST:
      return {
        ...state,
        addingWebServiceContract: true,
        webServiceContractExist: false,
      };

    case ADD_WEB_SERVICE_CONTRACT_SUCCESS:
      return {
        ...state,
        addingWebServiceContract: false,
      };

    case ADD_WEB_SERVICE_CONTRACT_FAILURE:
      return {
        ...state,
        addingWebServiceContract: false,
        webServiceContractExist: action.contractExist,
      };
    case CONTRACT_ADD_ATTACHMENT:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.concat([action.data.attachment]),
      };
    case CONTRACT_UPLOAD_ATTACHMENT_REQUEST:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.concat([
          {
            id: action.data.id,
            fileName: action.data.fileName,
            status: PDF_ATTACHMENT_LOADING,
            descriptiveName: action.data.descriptiveName,
            signatures: [],
            signatureMethod: undefined,
          },
        ]),
      };
    case CONTRACT_UPLOAD_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
          if (pdfItem.id === PDF_ATTACHMENT_TEMP_ID) {
            return {
              ...pdfItem,
              id: action.data.id,
              status: PDF_ATTACHMENT_SUCCESS,
              contract: action.data.contract,
              manualSignatures: 1,
            };
          } else {
            return pdfItem;
          }
        }),
      };
    case CONTRACT_UPLOAD_ATTACHMENT_FAILURE:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) =>
          pdfItem.id === action.data.id
            ? {
                ...pdfItem,
                status: PDF_ATTACHMENT_FAILURE,
              }
            : pdfItem
        ),
      };
    case CONTRACT_REMOVE_ATTACHMENT_REQUEST:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
          if (pdfItem.id === action.data.id) {
            return {
              ...pdfItem,
              status: PDF_ATTACHMENT_LOADING,
            };
          } else {
            return pdfItem;
          }
        }),
      };
    case CONTRACT_REMOVE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.filter((pdfItem) => pdfItem.id !== action.data.id),
      };
    case CONTRACT_REMOVE_ATTACHMENT_FAILURE:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
          if (pdfItem.id === action.data.id) {
            return {
              ...pdfItem,
              status: PDF_ATTACHMENT_FAILURE,
            };
          } else {
            return pdfItem;
          }
        }),
      };
    case CONTRACT_UPLOAD_ATTACHMENT_SIGNATURE_METHOD:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
          if (pdfItem.id === action.data.id) {
            return {
              ...pdfItem,
              signatureMethod: action.data.signatureMethod,
            };
          } else {
            return pdfItem;
          }
        }),
      };
    case CONTRACT_UPLOAD_ATTACHMENT_ADD_SIGNATURE:
      return addSignatureToAttachment(state, action.data.attachmentId, action.data.signature);
    case CONTRACT_UPLOAD_ATTACHMENT_REMOVE_SIGNATURE:
      return removeSignatureFromAttachment(state, action.data.attachmentId, action.data.signature);
    case SET_ATTACHMENT_MANUAL_SIGNATURES:
      return {
        ...state,
        attachmentPdfs: state.attachmentPdfs.map((pdfItem) => {
          if (pdfItem.id === action.data.id) {
            return {
              ...pdfItem,
              manualSignatures: action.data.amount,
            };
          } else {
            return pdfItem;
          }
        }),
      };
    case CONTRACT_RESET_ATTACHMENTS:
      return {
        ...state,
        attachmentPdfs: [],
      };
    default:
      return state;
  }
}
