import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { groupBy, sortBy } from 'lodash';
import translate from 'counterpart';
import { TradeRow, SpacerCell } from './TradeRow';
import { TradePortfolioHeader } from './TradePortfolioHeader';
import { fontSize } from 'features/common/StyledComponents';
import { colors } from 'styles/colors';
import { INSTRUMENT_ASSET_CATEGORY_ORDER, MONEY } from 'constants/allocator';
import { PortfolioInstrument } from 'types/instrumentsState';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { isSameInstrument } from 'features/portfolioManager/trade/tradeUtils';
import { NOT_APPLICABLE } from 'constants/common';
import { selectPortfolioDetailsById } from 'features/portfolio/portfolioSelectors';

interface Props {
  onToggleInstrument: (value: PortfolioInstrument) => void;
  selectedRows: PortfolioInstrument[];
  portfolios: AllocatorPortfolio[];
  loadingInstrumentDetails: boolean;
}

export const TradePortfolios = ({ portfolios, onToggleInstrument, selectedRows, loadingInstrumentDetails }: Props) => {
  const portfolioDetailsById = useSelector(selectPortfolioDetailsById);

  return (
    <Table>
      {portfolios?.map((p, index) => {
        const instruments = p.allocatedPortfolioRows.withoutIlliquids;
        const instrumentsByAssetCategory = groupBy(instruments, 'assetCategory');
        const portfolioCurrency = portfolioDetailsById[p.portfolioId]?.withdrawalCurrency || NOT_APPLICABLE;

        return (
          <React.Fragment key={index}>
            <thead>
              <TradePortfolioHeader portfolio={p} />
            </thead>
            {INSTRUMENT_ASSET_CATEGORY_ORDER.map((assetCategory) => {
              if (!instrumentsByAssetCategory[assetCategory]) {
                return null;
              }
              const sortedInstruments = sortBy(instrumentsByAssetCategory[assetCategory], 'name');
              return (
                <React.Fragment key={assetCategory}>
                  <thead>
                    <HeaderRow>
                      <td colSpan={3}>
                        <AssetCategory>{assetCategory}</AssetCategory>
                      </td>
                      <SpacerCell />
                      <NumberCell>{translate('portfolioManager.trade.quantity')}</NumberCell>
                      <NumberCell>%</NumberCell>
                      <NumberCell>{portfolioCurrency}</NumberCell>
                      <SpacerCell />
                      <OrderSideCell>{translate('portfolioManager.trade.orderSide')}</OrderSideCell>
                      <NumberCell>{translate('portfolioManager.trade.quantity')}</NumberCell>
                      <NumberCell>{translate('portfolioManager.trade.percentagePoints')}</NumberCell>
                      <NumberCell>{portfolioCurrency}</NumberCell>
                    </HeaderRow>
                  </thead>

                  <tbody>
                    {sortedInstruments.map((i) => {
                      const isSelected =
                        (selectedRows?.find((j) => isSameInstrument(i, j)) && i.security !== MONEY) || false;
                      return (
                        <TradeRow
                          key={i.security}
                          instrument={i}
                          selected={isSelected}
                          loadingInstrumentDetails={loadingInstrumentDetails}
                          onChange={onToggleInstrument}
                        />
                      );
                    })}
                  </tbody>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      })}
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  margin: 2rem 0;

  th {
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    vertical-align: bottom;
  }
`;

const HeaderRow = styled.tr`
  td {
    padding: 0.5rem 0.75rem 0.25rem;
    color: ${colors.dark_gray};
    font-size: ${fontSize.h4};
    font-weight: 500;
  }
`;
const AssetCategory = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${colors.black};
`;

const NumberCell = styled.td`
  text-align: right;
`;
const OrderSideCell = styled.td`
  text-align: center;
`;
