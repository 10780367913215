import { apiCall } from 'core/apiCall';
import { escapePathParam } from 'core/functions';
import { TavatResult } from 'features/orderList/orderList.types';
import { SearchFiltersType } from 'types/orderListState';
import { OrderSearchResults } from 'types/types';

const FILTER_TYPE = 'orders-pt';

export const getSavedOrderFilters = (token: string, sub: string) => {
  return apiCall({
    method: 'get',
    token,
    path: `/api/v1/user/${escapePathParam(sub)}/searchFilter?type=${FILTER_TYPE}`,
  }) as Promise<OrderSearchResults>;
};

export const postSearchFilters = (token: string, sub: string, filters: SearchFiltersType) => {
  return apiCall({
    method: 'post',
    token,
    path: `/api/v1/user/${escapePathParam(sub)}/searchFilter`,
    data: {
      ...filters,
      filterType: FILTER_TYPE,
    },
  }) as Promise<void>;
};

export const deleteSearchFilter = (token: string, sub: string, id: string) => {
  return apiCall({
    method: 'delete',
    token,
    path: `/api/v1/user/${escapePathParam(sub)}/searchFilter/${id}`,
  }) as Promise<void>;
};

export const getTavatOrderDetails = (id: string) => {
  return apiCall({
    method: 'get',
    path: `/api/v1/orderlines/${id}/tavatdetails`,
  }) as Promise<TavatResult>;
};
