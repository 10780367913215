import React, { ReactElement } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

export interface ButtonProps {
  label: string;
  onClick?: () => void;
  secondary?: boolean;
  disabled?: boolean;
  className?: string;
  bg?: string;
  small?: boolean;
  id?: string;
  'data-testkey'?: string;
  'data-secondary'?: boolean;
  children?: ReactElement | ReactElement[];
}

export const Button = ({
  label,
  onClick,
  disabled = false,
  secondary = false,
  className = '',
  bg,
  id,
  small = false,
  'data-testkey': dataTestKey,
  'data-secondary': dataSecondary,
  children,
}: ButtonProps) => (
  <StyledButton
    onClick={onClick}
    secondary={secondary}
    disabled={disabled}
    className={className}
    bg={bg}
    small={small}
    data-testkey={dataTestKey}
    data-secondary={dataSecondary}
    id={id}
  >
    {children || label}
  </StyledButton>
);

interface StyledButtonProps {
  disabled: boolean;
  secondary: boolean;
  bg?: string;
  small?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  padding: 0 ${(props) => (props.small ? '1.2rem' : '1.5rem')};
  height: ${(props) => (props.small ? '34px' : '44px')};
  font-size: ${(props) => (props.small ? '15px' : '16px')};
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  white-space: pre;
  font-weight: 700;
  transition: all 0.2s;
  border-bottom: 1px solid hsla(0deg, 0%, 0%, 9%);
  box-shadow: 0 1px 0 rgb(0 0 0 / 9%);

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
      filter: grayscale(0.7);
    `};

  ${(props) =>
    !props.secondary
      ? css`
          color: white;
          background: ${props.bg || colors.primary};
        `
      : css`
          background: ${props.bg || '#f2f2f2'};
          color: ${colors.black};
        `}

  &:hover {
    ${(props) => !props.disabled && `filter: ${props.secondary ? 'brightness(1.02)' : 'brightness(1.08)'}`}
  }
`;
