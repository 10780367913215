import { Map, List, Set, fromJS } from 'immutable';
import moment from 'moment';
import find from 'lodash/find';
import {
  CUSTOMER_FORM_REQUEST,
  CUSTOMER_FORM_SUCCESS,
  SET_CUSTOMER_FORM,
  CHANGE_SECTION_FIELD,
  BISNODE_REQUEST,
  BISNODE_SUCCESS,
  BISNODE_COMPANY_SUCCESS,
  BISNODE_PROCURATIONINFO_SUCCESS,
  BISNODE_FAILURE,
  COPY_VALUES,
  PATCH_CUSTOMER_REQUEST,
  PATCH_CUSTOMER_SUCCESS,
  PATCH_CUSTOMER_FAILURE,
  SAVE_PROFILE_ARRAY_VALUES,
  PROCURATION_FORM_REQUEST,
  PROCURATION_FORM_SUCCESS,
  DOCUMENTS_REQUEST,
  DOCUMENTS_SUCCESS,
  DOCUMENTS_FAILURE,
  STATUSES_PDF_REQUEST,
  STATUSES_PDF_SUCCESS,
  STATUSES_PDF_FAILURE,
  TRADE_REGISTRY_PDF_REQUEST,
  TRADE_REGISTRY_PDF_SUCCESS,
  TRADE_REGISTRY_PDF_FAILURE,
  STATUSES_REQUEST,
  STATUSES_SUCCESS,
  STATUSES_FAILURE,
  TRADE_REGISTRY_REQUEST,
  TRADE_REGISTRY_SUCCESS,
  TRADE_REGISTRY_FAILURE,
  VALIDATION,
  CUSTOMER_STAKEHOLDERS_REQUEST,
  CUSTOMER_STAKEHOLDERS_SUCCESS,
  CUSTOMER_STAKEHOLDERS_FAILURE,
  COMPANY_IDENTIFY_AND_SCREEN_REQUEST,
  COMPANY_IDENTIFY_AND_SCREEN_SUCCESS,
  COMPANY_IDENTIFY_AND_SCREEN_FAILURE,
  UPDATE_STAKEHOLDERS_REQUEST,
  UPDATE_STAKEHOLDERS_SUCCESS,
  UPDATE_STAKEHOLDERS_FAILURE,
  SCREEN_PERSON_REQUEST,
  SCREEN_PERSON_SUCCESS,
  SCREEN_PERSON_FAILURE,
  ProfileEditAction,
} from './profileEditActions';
import { mapData, validateFormValues } from 'core/index';
import mappings from 'constants/mappings';
import { PERSON } from 'constants/customerTypes';
import {
  ImmutableIdentifyAndScreen,
  ImmutableStakeholders,
  ImmutableStatusesDoc,
  ImmutableTradeRegistryDoc,
  ProfileEditState,
} from 'types/profileEditState';
import { Schema } from 'types/contractsState';
import { Customer, CustomerType, ImmutableContract } from 'types/profileState';

const initialState: ProfileEditState = {
  isBusy: false,
  bisnodeValues: Map(),
  formValues: Map() as ImmutableContract,
  formErrors: List(),
  formTouched: Set(),
  notFoundFromBisnode: false,
  fetchingFromBisnode: false,
  header: undefined,
  isSaving: false,
  hasCrmIdentity: false,
  customerType: '',
  statusesDoc: Map() as ImmutableStatusesDoc,
  tradeRegistryDoc: Map() as ImmutableTradeRegistryDoc,
  fetchingDocuments: false,
  procurationInfoUpdated: undefined,
  identifyAndScreen: Map() as ImmutableIdentifyAndScreen,
  stakeholders: Map() as ImmutableStakeholders,
  lastScreened: undefined,
  isPersonScreeningInProgress: false,
};

function formValidation(state: ProfileEditState, schema: Schema) {
  return fromJS(validateFormValues(state.formValues.toJS(), schema));
}

const customerFormRequest = () => ({
  ...initialState,
  isBusy: true,
});

const customerFormSuccess = (
  state: ProfileEditState,
  action: { result: { customerType: CustomerType; businessId: string; ssn?: string; header: string } }
) => {
  let mapping;
  let hasCrmIdentity;
  const customerType = action.result.customerType;
  if (customerType === PERSON) {
    mapping = mappings.privateCurrentData;
    hasCrmIdentity = action.result.ssn && action.result.ssn !== '';
  } else {
    mapping = mappings.companyCurrentData;
    hasCrmIdentity = action.result.businessId && action.result.businessId !== '';
  }

  return {
    ...state,
    isBusy: false,
    formValues: fromJS(
      mapData({
        source: action.result,
        target: {},
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mappings: mapping,
      })
    ),
    notFoundFromBisnode: false,
    header: action.result.header,
    hasCrmIdentity,
    customerType,
  };
};

const setCustomerForm = (state: ProfileEditState, action: { customer: Customer }) => {
  let mapping;
  let hasCrmIdentity;
  const customerType = action.customer.customerType;
  if (customerType === PERSON) {
    mapping = mappings.privateCurrentData;
    hasCrmIdentity = action.customer.ssn && action.customer.ssn !== '';
  } else {
    mapping = mappings.companyCurrentData;
    hasCrmIdentity = action.customer.businessId && action.customer.businessId !== '';
  }

  return {
    ...state,
    isBusy: false,
    bisnodeValues: Map(),
    formValues: fromJS(
      mapData({
        source: action.customer,
        target: {},
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        mappings: mapping,
      })
    ),
    notFoundFromBisnode: false,
    header: action.customer.header,
    hasCrmIdentity,
    customerType,
    statusesDoc: Map(),
    tradeRegistryDoc: Map(),
    lastScreened: action.customer.lastScreened,
    identifyAndScreen: initialState.identifyAndScreen,
  };
};

const changeSectionField = (state: ProfileEditState, action: { key: string; value: string }) => ({
  ...state,
  isBusy: false,
  formValues: state.formValues.setIn(action.key.split('.'), action.value),
});

const bisnodeRequest = (state: ProfileEditState) => ({
  ...state,
  notFoundFromBisnode: false,
  fetchingFromBisnode: true,
});

const bisnodeSuccess = (state: ProfileEditState, action: { result: object }) => {
  const d = mapData({
    source: action.result,
    target: {},
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    mappings: mappings.privateProfile,
  });
  return {
    ...state,
    isBusy: false,
    bisnodeValues: fromJS(d),
    notFoundFromBisnode: false,
    fetchingFromBisnode: false,
  };
};

const bisnodeCompanySuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  isBusy: false,
  bisnodeValues: state.bisnodeValues.mergeDeep(
    mapData({
      source: action.result,
      target: {},
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      mappings: mappings.companyProfile,
    })
  ),
  notFoundFromBisnode: false,
  fetchingFromBisnode: false,
});

const bisnodeProcurationInfoSuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  isBusy: false,
  bisnodeValues: state.bisnodeValues.mergeDeep(
    mapData({
      source: action.result,
      target: {},
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      mappings: mappings.companyProfile,
    })
  ),
  notFoundFromBisnode: false,
  fetchingFromBisnode: false,
});

const bisnodeFailure = (state: ProfileEditState, action: { error: { status: number } }) => ({
  ...state,
  isBusy: false,
  notFoundFromBisnode: action.error && action.error.status === 404,
  fetchingFromBisnode: false,
});

const copyValues = (state: ProfileEditState, action: { values: Map<string, object> }) => ({
  ...state,
  formValues: state.formValues.mergeDeep(action.values),
});

const validation = (state: ProfileEditState, action: { schema: Schema }) => ({
  ...state,
  formErrors: formValidation(state, action.schema),
});

const patchCustomerRequest = (state: ProfileEditState) => ({
  ...state,
  isSaving: true,
});

const patchCustomerSuccess = (state: ProfileEditState) => ({
  ...state,
  isSaving: false,
});

const patchCustomerFailure = (state: ProfileEditState) => ({
  ...state,
  isSaving: false,
});

const saveProfileArrayValue = (
  state: ProfileEditState,
  action: { key: string; index: number; arrayEditorValues: object }
) => {
  const basePath = action.key.split('.');
  let formValues;
  if (action.index >= 0) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    formValues = state.formValues.setIn(basePath.concat(action.index), action.arrayEditorValues);
  } else {
    formValues = state.formValues.updateIn(basePath, (arr) => {
      if (arr) {
        return arr.push(action.arrayEditorValues);
      }
      return fromJS([action.arrayEditorValues]);
    });
  }
  return {
    ...state,
    formValues,
  };
};

const procurationFormRequest = (state: ProfileEditState) => ({
  ...state,
  procurationInfoUpdated: undefined,
});

const procurationFormSuccess = (state: ProfileEditState, action: { result: { editDate: string } }) => ({
  ...state,
  formValues: state.formValues.mergeDeep(
    mapData({
      source: action.result,
      target: {},
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      mappings: mappings.companyCurrentData,
    })
  ),
  procurationInfoUpdated: action.result.editDate,
});

const documentsRequest = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: Map(),
  tradeRegistryDoc: Map(),
  fetchingDocuments: true,
});

const documentsSuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  statusesDoc: fromJS(find(action.result, { resultFileType: 'status' })) || Map(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  tradeRegistryDoc: fromJS(find(action.result, { resultFileType: 'traderegistry' })) || Map(),
  fetchingDocuments: false,
});

const documentsFailure = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: Map(),
  tradeRegistryDoc: Map(),
  fetchingDocuments: false,
});

const statusesRequest = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: state.statusesDoc.merge(
    fromJS({
      isUpdating: true,
    })
  ),
});

const statusesSuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  statusesDoc: fromJS(action.result),
});

const statusesFailure = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: state.statusesDoc.merge(
    fromJS({
      isUpdating: false,
    })
  ),
});

const tradeRegistryRequest = (state: ProfileEditState) => ({
  ...state,
  tradeRegistryDoc: state.tradeRegistryDoc.merge(
    fromJS({
      isUpdating: true,
    })
  ),
});

const tradeRegistrySuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  tradeRegistryDoc: fromJS(action.result),
});

const tradeRegistryFailure = (state: ProfileEditState) => ({
  ...state,
  tradeRegistryDoc: state.tradeRegistryDoc.merge(
    fromJS({
      isUpdating: false,
    })
  ),
});

const statusesPdfRequest = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: state.statusesDoc.set('isBusy', true),
});

const statusesPdfSuccess = (state: ProfileEditState, action: { blobUrl: string }) => ({
  ...state,
  statusesDoc: state.statusesDoc.merge(
    fromJS({
      blobUrl: action.blobUrl,
      isBusy: false,
    })
  ),
});

const statusesPdfFailure = (state: ProfileEditState) => ({
  ...state,
  statusesDoc: state.statusesDoc.set('isBusy', false),
});

const tradeRegistryPdfRequest = (state: ProfileEditState) => ({
  ...state,
  tradeRegistryDoc: state.tradeRegistryDoc.set('isBusy', true),
});

const tradeRegistryPdfSuccess = (state: ProfileEditState, action: { blobUrl: string }) => ({
  ...state,
  tradeRegistryDoc: state.tradeRegistryDoc.merge(
    fromJS({
      blobUrl: action.blobUrl,
      isBusy: false,
    })
  ),
});

const tradeRegistryPdfFailure = (state: ProfileEditState) => ({
  ...state,
  tradeRegistryDoc: state.tradeRegistryDoc.set('isBusy', false),
});

const customerStakeholdersRequest = (state: ProfileEditState) => ({
  ...state,
  identifyAndScreen: state.identifyAndScreen.set('data', null),
  stakeholders: state.stakeholders.merge(fromJS({ isBusy: true, data: null })),
});

const customerStakeholdersSuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  stakeholders: state.stakeholders.merge(fromJS({ isBusy: false, data: action.result })),
});

const customerStakeholdersFailure = (state: ProfileEditState) => ({
  ...state,
  stakeholders: state.stakeholders.set('isBusy', false),
});

const updateStakeholdersRequest = (state: ProfileEditState) => ({
  ...state,
  stakeholders: state.stakeholders.set('isBusy', true),
  identifyAndScreen: state.identifyAndScreen.set('isBusy', true),
});

const updateStakeholdersSuccess = (state: ProfileEditState) => ({
  ...state,
  stakeholders: state.stakeholders.set('isBusy', false),
  identifyAndScreen: state.identifyAndScreen.set('isBusy', false),
});

const updateStakeholdersFailure = (state: ProfileEditState) => ({
  ...state,
  identifyAndScreen: state.identifyAndScreen.merge(fromJS({ isBusy: false, data: null })),
  stakeholders: state.stakeholders.set('isBusy', false),
});

const companyIdentifyAndScreenRequest = (state: ProfileEditState) => ({
  ...state,
  identifyAndScreen: state.identifyAndScreen.merge(fromJS({ isBusy: true, error: null })),
});

const companyIdentifyAndScreenSuccess = (state: ProfileEditState, action: { result: object }) => ({
  ...state,
  identifyAndScreen: state.identifyAndScreen.merge(fromJS({ isBusy: false, data: action.result })),
  lastScreened: moment().format('YYYY-MM-DD HH:mm'),
});

const companyIdentifyAndScreenFailure = (state: ProfileEditState) => ({
  ...state,
  identifyAndScreen: state.identifyAndScreen.merge(fromJS({ isBusy: false, error: 'Virhe tietojen haussa' })),
});

const screenPersonRequest = (state: ProfileEditState) => ({
  ...state,
  lastScreened: undefined,
  isPersonScreeningInProgress: true,
});

const screenPersonSuccess = (state: ProfileEditState) => ({
  ...state,
  lastScreened: moment().format('YYYY-MM-DD HH:mm'),
  isPersonScreeningInProgress: false,
});

const screenPersonFailure = (state: ProfileEditState) => ({
  ...state,
  isPersonScreeningInProgress: false,
});

export function profileEditReducer(state = initialState, action: ProfileEditAction) {
  switch (action.type) {
    case CUSTOMER_FORM_REQUEST:
      return customerFormRequest();

    case CUSTOMER_FORM_SUCCESS:
      return customerFormSuccess(state, action);

    case SET_CUSTOMER_FORM:
      return setCustomerForm(state, action);

    case CHANGE_SECTION_FIELD:
      return changeSectionField(state, action);

    // @ts-expect-error: Action not typed
    case BISNODE_REQUEST:
      return bisnodeRequest(state);

    // @ts-expect-error: Action not typed
    case BISNODE_SUCCESS:
      return bisnodeSuccess(state, action);

    // @ts-expect-error: Action not typed
    case BISNODE_COMPANY_SUCCESS:
      return bisnodeCompanySuccess(state, action);

    // @ts-expect-error: Action not typed
    case BISNODE_PROCURATIONINFO_SUCCESS:
      return bisnodeProcurationInfoSuccess(state, action);

    // @ts-expect-error: Action not typed
    case BISNODE_FAILURE:
      return bisnodeFailure(state, action);

    case COPY_VALUES:
      return copyValues(state, action);

    case PATCH_CUSTOMER_REQUEST:
      return patchCustomerRequest(state);

    case PATCH_CUSTOMER_SUCCESS:
      return patchCustomerSuccess(state);

    case PATCH_CUSTOMER_FAILURE:
      return patchCustomerFailure(state);

    case SAVE_PROFILE_ARRAY_VALUES:
      return saveProfileArrayValue(state, action);

    // @ts-expect-error: Action not typed
    case PROCURATION_FORM_REQUEST:
      return procurationFormRequest(state);

    // @ts-expect-error: Action not typed
    case PROCURATION_FORM_SUCCESS:
      return procurationFormSuccess(state, action);

    // @ts-expect-error: Action not typed
    case DOCUMENTS_REQUEST:
      return documentsRequest(state);

    // @ts-expect-error: Action not typed
    case DOCUMENTS_SUCCESS:
      return documentsSuccess(state, action);

    // @ts-expect-error: Action not typed
    case DOCUMENTS_FAILURE:
      return documentsFailure(state);

    case STATUSES_PDF_REQUEST:
      return statusesPdfRequest(state);

    case STATUSES_PDF_SUCCESS:
      return statusesPdfSuccess(state, action);

    // @ts-expect-error: Action not typed
    case STATUSES_PDF_FAILURE:
      return statusesPdfFailure(state);

    case TRADE_REGISTRY_PDF_REQUEST:
      return tradeRegistryPdfRequest(state);

    case TRADE_REGISTRY_PDF_SUCCESS:
      return tradeRegistryPdfSuccess(state, action);

    // @ts-expect-error: Action not typed
    case TRADE_REGISTRY_PDF_FAILURE:
      return tradeRegistryPdfFailure(state);

    // @ts-expect-error: Action not typed
    case STATUSES_REQUEST:
      return statusesRequest(state);

    // @ts-expect-error: Action not typed
    case STATUSES_SUCCESS:
      return statusesSuccess(state, action);

    // @ts-expect-error: Action not typed
    case STATUSES_FAILURE:
      return statusesFailure(state);

    // @ts-expect-error: Action not typed
    case TRADE_REGISTRY_REQUEST:
      return tradeRegistryRequest(state);

    // @ts-expect-error: Action not typed
    case TRADE_REGISTRY_SUCCESS:
      return tradeRegistrySuccess(state, action);

    // @ts-expect-error: Action not typed
    case TRADE_REGISTRY_FAILURE:
      return tradeRegistryFailure(state);

    case VALIDATION:
      return validation(state, action);

    // @ts-expect-error: Action not typed
    case CUSTOMER_STAKEHOLDERS_REQUEST:
      return customerStakeholdersRequest(state);

    // @ts-expect-error: Action not typed
    case CUSTOMER_STAKEHOLDERS_SUCCESS:
      return customerStakeholdersSuccess(state, action);

    // @ts-expect-error: Action not typed
    case CUSTOMER_STAKEHOLDERS_FAILURE:
      return customerStakeholdersFailure(state);

    // @ts-expect-error: Action not typed
    case COMPANY_IDENTIFY_AND_SCREEN_REQUEST:
      return companyIdentifyAndScreenRequest(state);

    // @ts-expect-error: Action not typed
    case COMPANY_IDENTIFY_AND_SCREEN_SUCCESS:
      return companyIdentifyAndScreenSuccess(state, action);

    // @ts-expect-error: Action not typed
    case COMPANY_IDENTIFY_AND_SCREEN_FAILURE:
      return companyIdentifyAndScreenFailure(state);

    // @ts-expect-error: Action not typed
    case UPDATE_STAKEHOLDERS_REQUEST:
      return updateStakeholdersRequest(state);

    // @ts-expect-error: Action not typed
    case UPDATE_STAKEHOLDERS_SUCCESS:
      return updateStakeholdersSuccess(state);

    // @ts-expect-error: Action not typed
    case UPDATE_STAKEHOLDERS_FAILURE:
      return updateStakeholdersFailure(state);

    // @ts-expect-error: Action not typed
    case SCREEN_PERSON_REQUEST:
      return screenPersonRequest(state);

    // @ts-expect-error: Action not typed
    case SCREEN_PERSON_SUCCESS:
      return screenPersonSuccess(state);

    // @ts-expect-error: Action not typed
    case SCREEN_PERSON_FAILURE:
      return screenPersonFailure(state);

    default:
      return state;
  }
}
