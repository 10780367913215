import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { Flex, FlexAlignCenter, FlexJustifyCenter } from 'features/common/StyledComponents';
import {
  planIsLocked,
  isOptimizingPlan,
  noRiskOrCoreSelected,
  selectFlagCheckPortfolios,
  isLoadingInitialItems,
  selectTestRisk,
  selectActualRisk,
} from 'features/allocator/allocatorSelectors';
import { Spinner } from 'features/common/Spinner';
import { RootState } from 'types/rootState';
import { Button } from 'features/allocator/common/Button';
import { setError, SET_ERROR } from 'features/errors/errorActions';
import { errorKeys } from 'features/errors/errorUtils';
import {
  createPlanPortfolios,
  saveInvestmentPlan,
  setPlanState,
} from 'features/allocator/investmentPlan/investmentPlanActions';
import { hasOptimizedFullPowerOfAttorneyPortfolioInPlan } from 'features/allocator/planPortfolios/planPortfolioSelectors';
import { colors } from 'styles/colors';
import {
  customerHasBothOptimizedWeights,
  createOptimizedPortfoliosFromOptimizedValues,
} from 'features/weights/weightsSelectors';
import { DEMO_CUSTOMER } from 'constants/common';
import { LinkBack } from 'features/portfolioManager/components/LinkBack';
import { useAppDispatch } from 'core/hooks';

interface Props {
  customerId: string;
}

export const AllocatorControls = ({ customerId }: Props) => {
  const dispatch = useAppDispatch();
  const loadingInitialItems = useSelector(isLoadingInitialItems);

  const planLocked = useSelector(planIsLocked);
  const flagCheckPortfolios = useSelector(selectFlagCheckPortfolios);

  const [savingPlan, setSavingPlan] = useState<boolean>(false);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const errors = useSelector((state: RootState) => state.common.errors);

  const hasOptimizedWeights = useSelector(customerHasBothOptimizedWeights);
  const noRiskOrCore = useSelector(noRiskOrCoreSelected);
  const optimizedPortfolios = useSelector(createOptimizedPortfoliosFromOptimizedValues());
  const hasOptimizedFullPowerOfAttorneyPortfolios = useSelector(hasOptimizedFullPowerOfAttorneyPortfolioInPlan);

  const actualRisk = useSelector(selectActualRisk);
  const testRisk = useSelector(selectTestRisk);
  const actualRiskAndTestRiskMismatch = testRisk !== actualRisk;

  const saveAndWait = async () => {
    try {
      setSavingPlan(true);
      await dispatch(saveInvestmentPlan(customerId));
    } catch (error) {
      return dispatch(setError({ context: errorKeys.saveInvestmentPlan }));
    } finally {
      setSavingPlan(false);
    }
  };

  const savePlan = async () => {
    const response = (await saveAndWait()) as unknown as { type: string };
    if (response?.type !== SET_ERROR) {
      dispatch(setPlanState('unlockedPlan'));
      dispatch(createPlanPortfolios(optimizedPortfolios));
    }
  };

  const hasPortfolioError = errors.some(
    (i) => i.context === errorKeys.loadInvestmentPlan || i.context === errorKeys.saveInvestmentPlan
  );
  const saved = !savingPlan && !hasPortfolioError;

  const cannotSave = flagCheckPortfolios || hasOptimizedFullPowerOfAttorneyPortfolios || !hasOptimizedWeights;
  const disableSave =
    savingPlan || optimizingPlan || !customerId || noRiskOrCore || cannotSave || actualRiskAndTestRiskMismatch;

  const getErrorText = () => {
    if (customerId === DEMO_CUSTOMER) {
      return translate('allocator.header.demoCustomer');
    }
    if (!hasOptimizedWeights) {
      return translate('allocator.header.noOptimizedWeights');
    }
    if (actualRiskAndTestRiskMismatch) {
      return translate('allocator.header.testRiskLevelSelected');
    }
    if (flagCheckPortfolios) {
      return translate('allocator.header.checkPortfolios');
    }
    if (hasOptimizedFullPowerOfAttorneyPortfolios) {
      return translate('allocator.header.hasOptimizedFullPowerOfAttorneyPortfolios');
    }
  };

  return (
    <Container>
      {!savingPlan && <LinkBack customerId={customerId} />}

      {!planLocked && (
        <SaveContainer>
          {!loadingInitialItems && !optimizingPlan && getErrorText() ? (
            <CantSaveText data-testkey="cannot-save-plan-reason">{getErrorText()}</CantSaveText>
          ) : (
            <FlexJustifyCenter>
              <SavingText savingPlan={savingPlan} optimizingPlan={optimizingPlan}>
                {savingPlan && <Spinner message={translate('allocator.header.saving')} horizontal size={30} />}
                {saved && `✔ ${translate('allocator.header.saved')}`}
              </SavingText>
              <SaveButton
                onClick={savePlan}
                label={translate('allocator.header.saveInvestmentPlan')}
                disabled={disableSave}
                savingPlan={savingPlan}
                data-testkey={'investment-plan-save-button'}
                optimizingPlan={optimizingPlan}
              />
            </FlexJustifyCenter>
          )}
        </SaveContainer>
      )}
    </Container>
  );
};

const Container = styled(FlexAlignCenter)`
  padding: 0 2rem;
`;

interface SavingPlanProps {
  savingPlan: boolean;
  optimizingPlan: boolean;
}

const SavingText = styled.div<SavingPlanProps>`
  pointer-events: none;
  ${(props) =>
    props.savingPlan
      ? css`
          transition: 0.1s;
          opacity: 1;
        `
      : css`
          transition: 0.5s 2s;
          opacity: 0;
        `}

  font-weight: 700;
  position: absolute;
`;

const SaveContainer = styled.div`
  position: relative;
`;

const CantSaveText = styled(Flex)`
  justify-content: flex-end;
  padding: 0.25rem 0.5rem;
  width: 100%;
  right: 0;
  top: 0;
  font-size: 14px;
  text-align: center;
  z-index: 1;
  color: ${colors.red};
  max-width: 250px;
  font-weight: 600;
  letter-spacing: 0;
  border-radius: 4px;
  height: 44px;
  align-items: center;
`;

const SaveButton = styled(Button)<SavingPlanProps>`
  ${(props) =>
    props.savingPlan || props.optimizingPlan
      ? css`
          transition: 0.1s;
          opacity: 0.1;
        `
      : css`
          transition: 0.5s 2s;
        `}
`;
