import React, { Component } from 'react';
import translate from 'counterpart';
import classnames from 'classnames';
import StyledTextField from 'features/common/styledComponents/StyledTextField';
import SearchFilters from './SearchFilters';
import styles from './SearchFilterEditor.scss';
import { FilterType } from 'features/listView/listView.types';
import { SearchFiltersType } from 'types/orderListState';

interface Props {
  searchFilterEditor: SearchFiltersType;
  setFilterEditorData: (f: SearchFiltersType) => void;
  type: FilterType;
  bankers: { value: string }[];
}

interface State {
  isNameEmpty: boolean;
}

export default class SearchFilterEditor extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isNameEmpty: false,
    };
  }

  setSearchFilterEditor = (key: keyof SearchFiltersType, value: string | Date | undefined) => {
    const filters = {} as SearchFiltersType;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    filters[key] = value;
    this.props.setFilterEditorData(filters);
  };

  saveSearchName = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.value === undefined || e?.target?.value === '') {
      this.setState({
        ...this.state,
        isNameEmpty: true,
      });
    } else {
      this.setState({
        ...this.state,
        isNameEmpty: false,
      });
    }
    this.setSearchFilterEditor('searchName', e?.target?.value);
  };

  saveSearchString = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setSearchFilterEditor('searchString', e.target.value);
  };

  handleInstrumentKeywords = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setSearchFilterEditor('instrumentKeywords', e.target.value);
  };

  render() {
    const { searchFilterEditor, type, bankers } = this.props;
    return (
      <section>
        <div className={classnames('row', styles.textFields)}>
          <div className="columns small-8">
            <StyledTextField
              label={`${translate('orderList.savedSearchName')}*`}
              style={{
                width: '100%',
              }}
              value={searchFilterEditor.searchName === undefined ? '' : searchFilterEditor.searchName}
              onChange={this.saveSearchName}
              helperText={this.state.isNameEmpty ? 'Nimi on pakollinen' : ''}
              error={this.state.isNameEmpty}
              data-testkey="search-name"
              variant="standard"
              sx={[
                {
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    marginTop: 0,
                  },
                  '& label': {
                    fontSize: '14px',
                    fontWeight: '400',
                    '&.Mui-focused': {
                      fontSize: '12px',
                    },
                  },
                  '& > p': {
                    '&.MuiFormHelperText-root': {
                      paddingLeft: '0',
                    },
                  },
                  '&.Mui-error': {
                    borderBottomColor: '#d32f2f',
                    borderWidth: '2px',
                    '&::before': {
                      borderWidth: '2px',
                      borderBottomColor: '#d32f2f',
                    },
                  },
                },
              ]}
            />
          </div>
        </div>
        <SearchFilters
          searchFilters={searchFilterEditor}
          mergeSearchFilters={this.setSearchFilterEditor}
          handleInstrumentKeywords={this.handleInstrumentKeywords}
          type={type}
          editor={true}
          bankers={bankers}
          searchItems={this.saveSearchString}
        />
      </section>
    );
  }
}
