import { MONEY } from 'constants/allocator';
import { apiCall } from 'core/apiCall';
import { isOutsideFundsPortfolio } from 'core/portfolios';
import PromiseStore from 'core/PromiseStore';
import { PortfolioRequestResponse, PositionRequestResponse } from 'features/portfolio/portfolio.types';
import { OrdersType, Position } from 'types/ordersState';
import { PortfolioDetailsById } from 'types/portfolioState';
import { RootState } from 'types/rootState';

export const ordersContractsPortfoliosReady = (
  orders: { [portfolioId: string]: OrdersType },
  portfolioDetailsById: PortfolioDetailsById,
  contractReady: boolean
) => {
  return Object.keys(orders).length > 0 && Object.keys(portfolioDetailsById).length > 0 && contractReady;
};

export const getWithdrawalBalance = (state: RootState): number =>
  Object.values(state.portfolio?.portfolioDetailsById)
    .filter((p) => !isOutsideFundsPortfolio(p))
    .reduce((acc, portfolioDetails) => acc + portfolioDetails?.withdrawalBalance || 0, 0);

export const getPositions = async (id: string, token: string): Promise<PositionRequestResponse> => {
  PromiseStore.getPositions = apiCall({
    path: `api/v1/reports/positions/${id}`,
    method: 'get',
    token,
  });

  return (await PromiseStore.getPositions) as PositionRequestResponse;
};

export const getPortfolios = async (id: string, token: string): Promise<PortfolioRequestResponse[]> => {
  PromiseStore.getPortfolios = apiCall({
    path: `api/v1/customers/${id}/portfolios`,
    method: 'get',
    token,
  });

  return (await PromiseStore.getPortfolios) as PortfolioRequestResponse[];
};

export const hasNegativePositions = (positions: Position[]) => {
  return positions
    .filter((position) => position.financialInstrument.financialInstrumentId !== MONEY)
    .some((position) => position.marketValueNet.baseCurrency.value < 0);
};
