import React, { useState } from 'react';
import translate from 'counterpart';
import { branch, renderNothing } from 'recompose';
import { isValidEmail } from 'core/validations';
import InfoText from 'features/orders/components/InfoText';
import { SELL } from 'constants/sides';
import { DefaultAccount } from 'features/orderDialog/orderDialogTypes';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  defaultAccount: DefaultAccount;
  assignment: OrderLineType;
}

const InvoiceDetails = (props: Props) => {
  const dispatch = useAppDispatch();

  const [errorText, setErrorText] = useState<string | undefined>();

  const setReceiverEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (!isValidEmail(value)) {
      setErrorText('Virheellinen sähköpostiosoite');
    } else {
      setErrorText(undefined);
    }
    dispatch(setEditorValue('receiverEmail', event.target.value));
  };

  const { defaultAccount, assignment } = props;
  let content;

  if (!defaultAccount || defaultAccount.accountType === '03') {
    content = (
      <div>
        <StyledTextField
          defaultValue={assignment.receiverEmail}
          label={translate('order.receiverEmail')}
          style={{
            width: '100%',
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          onBlur={setReceiverEmail}
          helperText={errorText}
          data-testkey="customer-email-textfield"
          variant="standard"
          sx={[
            {
              '&.MuiFormControl-root': {
                marginTop: '15px',
              },
              '& .Mui-focused': {
                '&::after': {
                  left: 0,
                },
              },
              '& .MuiInputBase-root': {
                marginTop: '14px',
                marginBottom: '15px',
                paddingLeft: 0,
                '&::before': {
                  left: 0,
                },
              },
              '& .MuiInputBase-input': {
                fontFamily: 'Roboto, sans-serif',
                marginTop: 0,
              },
              '& label': {
                fontSize: '14px',
                '&.Mui-focused': {
                  fontSize: '14px',
                },
              },
              '& > p': {
                '&.MuiFormHelperText-root': {
                  paddingLeft: '0',
                },
              },
              '&.Mui-error': {
                borderBottomColor: '#d32f2f',
                borderWidth: '2px',
                '&::before': {
                  borderWidth: '2px',
                  borderBottomColor: '#d32f2f',
                },
              },
            },
          ]}
        />
        <InfoText text={translate('order.sendBillToCustomer')} />
      </div>
    );
  } else {
    content = <InfoText text={translate('order.sendBillToExpert')} style={{ marginTop: '2rem' }} />;
  }

  return (
    <div className={'row'}>
      <div className={'columns small-12'}>{content}</div>
    </div>
  );
};

export default branch(({ assignment }: Props) => assignment.side === SELL, renderNothing)(InvoiceDetails);
