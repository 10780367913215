import { AssetCategoryName, AssetClassGroupId, AssetClassId } from 'types/types';

interface OptimizationModelAssetCategory {
  name: AssetCategoryName;
  assetClassGroups: OptimizationModelAssetClassGroup[];
}

export interface OptimizationModelAssetClassGroup {
  name: string;
  groupId: AssetClassGroupId;
  assetCategory: AssetCategoryName;
  assetClasses: OptimizationModelAssetClassWithWeight[];
}

interface OptimizationModelAssetClassWithWeight {
  assetClassId: AssetClassId;
  weight: number;
}

export const optimizationModelWithWeights: OptimizationModelAssetCategory[] = [
  {
    name: 'Osakkeet',
    assetClassGroups: [
      {
        name: 'Eurooppa',
        groupId: 'ST_Europe',
        assetCategory: 'Osakkeet',
        assetClasses: [
          {
            assetClassId: 'ST_Europe_Value',
            weight: 0.425,
          },
          {
            assetClassId: 'ST_Europe_Growth',
            weight: 0.425,
          },
          {
            assetClassId: 'ST_Europe_Small',
            weight: 0.15,
          },
        ],
      },
      {
        name: 'Yhdysvallat',
        groupId: 'ST_US',
        assetCategory: 'Osakkeet',
        assetClasses: [
          {
            assetClassId: 'ST_US_Value',
            weight: 0.425,
          },
          {
            assetClassId: 'ST_US_Growth',
            weight: 0.425,
          },
          {
            assetClassId: 'ST_US_Small',
            weight: 0.15,
          },
        ],
      },
      {
        name: 'Tyynimeri',
        groupId: 'ST_Pac',
        assetCategory: 'Osakkeet',
        assetClasses: [
          {
            assetClassId: 'ST_Japan',
            weight: 0.5,
          },
          {
            assetClassId: 'ST_PacExJap',
            weight: 0.5,
          },
        ],
      },
      {
        name: 'Kehittyvät markkinat',
        assetCategory: 'Osakkeet',
        groupId: 'ST_EM',
        assetClasses: [
          {
            assetClassId: 'ST_EM',
            weight: 1,
          },
        ],
      },
    ],
  },
  {
    name: 'Korot',
    assetClassGroups: [
      {
        name: 'Valtionlainat',
        assetCategory: 'Korot',
        groupId: 'BO_Gov',
        assetClasses: [
          {
            assetClassId: 'BO_Europe_Gov',
            weight: 1,
          },
          {
            assetClassId: 'BO_US_Gov',
            weight: 0,
          },
        ],
      },
      {
        name: 'Yrityslainat',
        assetCategory: 'Korot',
        groupId: 'BO_CorpIG',
        assetClasses: [
          {
            assetClassId: 'BO_Europe_CorpIG',
            weight: 1,
          },
          {
            assetClassId: 'BO_US_CorpIG',
            weight: 0,
          },
        ],
      },
      {
        name: 'Riskiyrityslainat',
        assetCategory: 'Korot',
        groupId: 'BO_HY',
        assetClasses: [
          {
            assetClassId: 'BO_Europe_HY',
            weight: 1,
          },
          {
            assetClassId: 'BO_US_HY',
            weight: 0,
          },
        ],
      },
      {
        name: 'Kehittyvien markkinoiden lainat',
        assetCategory: 'Korot',
        groupId: 'BO_EM_Gov',
        assetClasses: [
          {
            assetClassId: 'BO_EM_Gov',
            weight: 1,
          },
        ],
      },
    ],
  },
  {
    name: 'Vaihtoehtoiset',
    assetClassGroups: [
      {
        name: 'Kiinteistösijoitukset',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_RealEstate',
        assetClasses: [
          {
            assetClassId: 'ALT_RealEstateCore',
            weight: 0.5,
          },
          {
            assetClassId: 'ALT_RealEstateOpportunistic',
            weight: 0.5,
          },
        ],
      },
      {
        name: 'Infrasijoitukset',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_Infra',
        assetClasses: [
          {
            assetClassId: 'ALT_InfraCore',
            weight: 0.5,
          },
          {
            assetClassId: 'ALT_InfraOpportunistic',
            weight: 0.5,
          },
        ],
      },
      {
        name: 'Private Debt',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_PrivateDebt',
        assetClasses: [
          {
            assetClassId: 'ALT_PrivateDebt',
            weight: 1,
          },
        ],
      },
      {
        name: 'Pääomasijoitukset',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_PrivateEquity',
        assetClasses: [
          {
            assetClassId: 'ALT_PrivateEquity',
            weight: 1,
          },
        ],
      },
      {
        name: 'VC-sijoitukset',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_VC',
        assetClasses: [
          {
            assetClassId: 'ALT_VC',
            weight: 1,
          },
        ],
      },
      {
        name: 'Hedge Fund',
        assetCategory: 'Vaihtoehtoiset',
        groupId: 'ALT_HedgeFund',
        assetClasses: [
          {
            assetClassId: 'ALT_HedgeFund',
            weight: 1,
          },
        ],
      },
    ],
  },
];
