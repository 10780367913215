import React from 'react';
import styles from './SelectionItem.scss';
import { Spinner } from 'features/common/Spinner';
import { useAppDispatch } from 'core/hooks';

interface Props {
  onClick: (contractId: string, customerId: string) => void;
  contractId: string;
  customerId: string;
  name: string;
  icon: string;
  isLoading: boolean;
  className: string;
}

export const SelectionItem = ({ name, icon, contractId, className, isLoading, customerId, onClick }: Props) => {
  const dispatch = useAppDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const handleClick = () => dispatch(onClick(contractId, customerId));
  return (
    <li onClick={handleClick} data-testkey={contractId}>
      <div className={className || undefined}>
        {isLoading ? (
          <Spinner className={styles.spinner} color="white" position="center" size={30} />
        ) : (
          <i className={icon} />
        )}
      </div>
      <span>{name}</span>
    </li>
  );
};
