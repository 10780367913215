import { loadCustomerProfile } from 'features/profile/profileActions';
import {
  MEETING_DATA_REQUEST,
  MEETING_DATA_SUCCESS,
  MEETING_DATA_FAILURE,
  EDIT_NOTE,
  MERGE_NOTE,
  SAVE_MEETING_DATA_REQUEST,
  SAVE_MEETING_DATA_SUCCESS,
  SAVE_MEETING_DATA_FAILURE,
} from './meetingNotesConstants';
import { selectNote } from './meetingNotesSelectors';
import { apiCall } from 'core/apiCall';
import { AppThunk } from 'types/types';
import { Note } from 'types/ordersState';

export function fetchMeetingData(id: string, activity: string): AppThunk {
  return (dispatch, getState) => {
    const customer = getState().profile.customer;
    if (customer.isEmpty() || customer.get('id') !== id) {
      dispatch({ type: MEETING_DATA_REQUEST });
      dispatch(loadCustomerProfile(id))
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        .then((data) => dispatch({ type: MEETING_DATA_SUCCESS, data: data.result, activity }))
        .catch((error) => dispatch({ type: MEETING_DATA_FAILURE, error }));
    } else {
      dispatch({ type: MEETING_DATA_SUCCESS, data: customer.toJS(), activity });
    }
  };
}

export function editNote(key: string, value: string) {
  return {
    type: EDIT_NOTE,
    key,
    value,
  };
}

export function mergeNote(value: Partial<Note>) {
  return {
    type: MERGE_NOTE,
    value,
  };
}

export function saveNote(id: string, markAsComplete: string): AppThunk {
  return (dispatch, getState) => {
    const note = selectNote(getState());
    Object.assign(note, { markAsComplete });
    dispatch({ type: SAVE_MEETING_DATA_REQUEST });
    return apiCall({
      method: 'post',
      token: getState().oidc.user.access_token,
      path: `/api/v1/customers/${id}/encounters`,
      data: note,
    })
      .then((result) => {
        dispatch({ type: SAVE_MEETING_DATA_SUCCESS });
        return result;
      })
      .catch((error) => {
        dispatch({ type: SAVE_MEETING_DATA_FAILURE, error });
        throw new Error(error.Message);
      });
  };
}
