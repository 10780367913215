import React from 'react';
import styled, { css } from 'styled-components';
import translate from 'counterpart';
import { useSelector } from 'react-redux';
import { formatToDecimals } from 'features/allocator/allocatorUtils';
import {
  allPlanInstrumentsAreNotInPositions,
  selectHasPositions,
  isOptimizingCurrent,
  isOptimizingPlan,
} from 'features/allocator/allocatorSelectors';
import { RadioButton } from 'features/allocator/common/RadioButton';
import { colors } from 'styles/colors';
import { fontSize } from 'features/common/StyledComponents';
import { Spinner } from 'features/common/Spinner';
import { TRADE_WEIGHT_LIMIT, TRADE_SHOW_LIMIT, MONEY, TRADE_QUANTITY_LIMIT } from 'constants/allocator';
import { RootState } from 'types/rootState';
import {
  getCurrentInstrument,
  getInstrumentPrice,
  getInstrumentSuitabilityErrors,
  valueIsOverTradeLimit,
  quantityIsOverTradeLimit,
  hasPortfolioErrors,
} from 'features/portfolioManager/trade/tradeUtils';
import { LockSmallIcon } from 'features/allocator/common/SvgIcon';
import { PortfolioInstrument } from 'types/instrumentsState';
import { BUY, INVALID_ORDER_SIDE, SELL, SELL_ALL } from 'constants/sides';
import { OrderSideType } from 'types/orderDialogState';
import { selectCurrentPortfolioById } from 'features/portfolio/portfolioSelectors';
import { selectCustomer } from 'features/profile/profileSelectors';
import { NOT_APPLICABLE } from 'constants/common';
import { FUND } from 'constants/instrumentForms';

interface Props {
  instrument: PortfolioInstrument;
  selected: boolean;
  loadingInstrumentDetails: boolean;
  onChange: (v: PortfolioInstrument) => void;
}

export const TradeRow = ({ instrument, selected, onChange, loadingInstrumentDetails }: Props) => {
  const { weight, liquidity, type, security, portfolioId, name } = instrument;
  const customer = useSelector(selectCustomer);
  const hasPositions = useSelector(selectHasPositions);
  const currentWeights = useSelector((state: RootState) => state.portfolioManager.weights.currentWeights);
  const suitabilityErrors = useSelector((state: RootState) => state.portfolioManager.valueData.suitabilityErrors);
  const tradeOrderLines = useSelector((state: RootState) => state.portfolioManager.valueData.tradeOrderLines);

  const portfolioDetails = useSelector((state: RootState) => selectCurrentPortfolioById(state, instrument.portfolioId));
  const portfolioCurrency = portfolioDetails?.withdrawalCurrency || NOT_APPLICABLE;
  const assignmentsForbiddenInPortfolio = hasPortfolioErrors(customer, portfolioDetails, tradeOrderLines);

  // loading states
  const loadingPortfolios = useSelector((state: RootState) => state.portfolio.loadingPortfolios);
  const optimizingCurrent = useSelector(isOptimizingCurrent);
  const optimizingPlan = useSelector(isOptimizingPlan);
  const loadingPossessions = optimizingCurrent || loadingPortfolios;
  const loadingNewWeights =
    loadingPortfolios || loadingInstrumentDetails || (hasPositions && (optimizingCurrent || optimizingPlan));
  const allPlanInstrumentsNotInPositions = useSelector(allPlanInstrumentsAreNotInPositions);

  // instrument props
  const isLiquid = liquidity === 1;
  const isCash = security === MONEY;
  const showLock = !isLiquid || assignmentsForbiddenInPortfolio;

  const currentInstrument = getCurrentInstrument(instrument.security, instrument.portfolioId, currentWeights);

  const instrumentPrice = getInstrumentPrice(instrument, tradeOrderLines);

  // possessions
  const orderLine = tradeOrderLines?.find((o) => o.financialInstrumentId === security);
  const currentWeight = currentInstrument?.weight || 0;
  const currentValue = currentInstrument?.portfolioCurrencyMarketValue || 0;
  const currentQuantity = currentInstrument?.quantity || 0;
  const possessionWeight = formatToDecimals(currentWeight, 2, '%');
  const possessionMarketValue = formatToDecimals(currentValue, 2, portfolioCurrency);

  // differences

  const tradeAll = orderLine?.side === SELL && orderLine?.tradeAll;

  const weightDifference = tradeAll ? -currentWeight : (weight || 0) - currentWeight;
  const marketPrice = orderLine?.marketPrice || 0;
  const quantity = orderLine?.quantity || 0;
  const amount = isCash ? currentValue * (weightDifference / currentWeight) : orderLine?.amount || 0;

  const tradeAmount = amount || quantity * marketPrice;
  const tradeQuantity = quantity || (marketPrice ? amount / marketPrice : 0);

  const decimals =
    orderLine?.financialInstrumentForm === FUND || Math.abs(tradeQuantity) < TRADE_QUANTITY_LIMIT ? 2 : 0;
  const formattedPossessionQuantity = isCash ? '' : formatToDecimals(currentQuantity, decimals);

  const quantityDifferenceIsBelowTradeLimit =
    !showLock &&
    !isCash &&
    !quantityIsOverTradeLimit(orderLine) &&
    (Math.abs(weightDifference) >= TRADE_SHOW_LIMIT || Math.abs(tradeAmount) >= TRADE_SHOW_LIMIT);

  const valueDifferenceIsBelowTradeLimit =
    !showLock &&
    !isCash &&
    !valueIsOverTradeLimit(orderLine) &&
    (Math.abs(weightDifference) >= TRADE_SHOW_LIMIT || Math.abs(tradeQuantity) >= TRADE_SHOW_LIMIT);

  const formattedWeightDifference = () => {
    const value = handleNulls();
    return value ?? `${formatToDecimals(weightDifference, 2, '%')}`;
  };

  const formattedMarketValueDifference = () => {
    const value = handleNulls();
    const plusMinus = isCash ? '' : showPlusMinus;
    return value ?? `${plusMinus}${formatToDecimals(tradeAmount, 2, portfolioCurrency)}`;
  };

  const formattedQuantityDifference = () => {
    const value = handleNulls();
    if (isCash) {
      return '';
    }
    if (instrumentPrice === undefined) {
      return '–';
    }
    return value ?? `${showPlusMinus}${formatToDecimals(tradeQuantity, decimals)}`;
  };

  const handleNulls = () => {
    if (isCash) {
      return;
    }
    if (!isLiquid) {
      return '';
    }
    if (Math.abs(weightDifference) < TRADE_WEIGHT_LIMIT) {
      return '0';
    }
    if (weight === undefined) {
      return '–';
    }
  };

  const orderSideKey = orderLine?.side;
  const orderSideTextKey = tradeAll ? SELL_ALL : orderLine?.side;
  const orderSideText = (isLiquid && orderSideKey && translate(`portfolioManager.trade.${orderSideTextKey}`)) || '';

  const instrumentSuitabilityErrors = getInstrumentSuitabilityErrors(suitabilityErrors, instrument, orderSideKey);
  const showSuitabilityErrors = !showLock && !!instrumentSuitabilityErrors?.length;
  const showPlusMinus = (orderSideKey === BUY && '+') || (orderSideKey === SELL && '–') || '';

  const disabled =
    !isLiquid ||
    assignmentsForbiddenInPortfolio ||
    isCash ||
    !hasPositions ||
    optimizingPlan ||
    loadingInstrumentDetails ||
    instrumentPrice === undefined ||
    !!instrumentSuitabilityErrors?.length ||
    (!!currentInstrument && allPlanInstrumentsNotInPositions);
  const classes = `${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`;

  const onClickRow = () => onChange(instrument);

  const tooLowDifference =
    !loadingNewWeights &&
    !loadingInstrumentDetails &&
    (quantityDifferenceIsBelowTradeLimit || valueDifferenceIsBelowTradeLimit);

  return (
    <>
      <Row
        selected={selected}
        onClick={!disabled ? onClickRow : undefined}
        isCash={isCash}
        disabled={disabled || showLock}
        className={classes}
        unsuitable={showSuitabilityErrors}
        data-testkey={`trade-row-${security}-${portfolioId}`}
      >
        <RadioButtonCell data-testkey={selected ? `trade-row-selected` : ''}>
          {!showLock && !isCash && (
            <StyledRadioButton
              checked={selected}
              value={name}
              onChange={onClickRow}
              type="checkbox"
              disabled={disabled}
            />
          )}
          {showLock && <Lock width="16px" />}
        </RadioButtonCell>

        <NameCell data-testkey="trade-row">{name || security}</NameCell>

        <InstrumentTypeCell data-testkey={isLiquid ? 'liquid' : 'illiquid'} data-security={security}>
          {instrument?.type && translate(`portfolioManager.trade.${type.trim()}`)}
        </InstrumentTypeCell>

        <SpacerCell />

        {loadingPossessions ? (
          <SpinnerCell colSpan={3}>
            <Spinner size={20} />
          </SpinnerCell>
        ) : (
          <>
            <NumberCell>{formattedPossessionQuantity}</NumberCell>
            <NumberCell data-testkey={`trade-row-possession-${currentInstrument?.weight ? 'not-' : ''}zero`}>
              {possessionWeight}
            </NumberCell>
            <NumberCell data-testkey="possession-value">{possessionMarketValue}</NumberCell>
          </>
        )}

        <SpacerCell />

        {!isLiquid && (
          <TDTextCenter colSpan={5}>{!isLiquid && translate('portfolioManager.trade.illiquid')}</TDTextCenter>
        )}

        {loadingNewWeights && isLiquid && (
          <SpinnerCell colSpan={5}>
            <Spinner size={20} />
          </SpinnerCell>
        )}

        {!loadingNewWeights && isLiquid && (
          <>
            <TradeSideCell>
              {orderSideKey && !tooLowDifference && orderSideKey !== INVALID_ORDER_SIDE && (
                <OrderSideTag orderSide={orderSideKey} data-testkey={`orderSide-${orderSideKey}`}>
                  {orderSideText}
                </OrderSideTag>
              )}
              {tooLowDifference && (
                <TooLowTradeAmount>{translate('portfolioManager.trade.tooLowTradeAmount')}</TooLowTradeAmount>
              )}
            </TradeSideCell>

            {loadingInstrumentDetails ? (
              <SpinnerCell>
                <Spinner size={20} />
              </SpinnerCell>
            ) : (
              <NumberCell red={quantityDifferenceIsBelowTradeLimit} data-testkey="quantity-difference">
                {formattedQuantityDifference()}
              </NumberCell>
            )}

            <NumberCell data-testkey="weight-difference">{formattedWeightDifference()}</NumberCell>

            <NumberCell
              red={!loadingInstrumentDetails && valueDifferenceIsBelowTradeLimit}
              data-testkey="marketvalue-difference"
            >
              <b>{formattedMarketValueDifference()}</b>
            </NumberCell>
          </>
        )}
      </Row>

      {showSuitabilityErrors && (
        <ErrorsRow data-testkey="suitability-errors">
          <td colSpan={13}>
            {instrumentSuitabilityErrors?.map((e) =>
              e.messages.map((message) => (
                <div key={message}>
                  <b>{e.ruleId} –</b> {message}
                </div>
              ))
            )}
          </td>
        </ErrorsRow>
      )}
    </>
  );
};

interface StyledRowProps {
  selected: boolean;
  disabled: boolean;
  isCash: boolean;
  unsuitable: boolean;
}

const Row = styled.tr<StyledRowProps>`
  background: white;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.05);
  transition: all 0.1s;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  height: 45px;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background: ${props.selected ? 'hsl(170 61% 86%)' : 'hsl(170 30% 97%);'};
      }
    `};

  ${(props) =>
    props.disabled &&
    !props.isCash &&
    !props.selected &&
    css`
      box-shadow: none;
      color: ${colors.dark_gray};
      opacity: 0.8;

      && td {
        border-color: #f3f3f3;
      }
    `};

  ${(props) =>
    props.unsuitable &&
    css`
      background: #fff1f1;
      box-shadow: inset 0 1px 0 #ffe8e8;

      && td {
        border-color: #ffe8e8;
      }
    `};

  ${(props) =>
    props.selected &&
    css`
      background: ${colors.light_green};
      color: ${colors.primary_text};
      z-index: 1;

      &:not(:first-child) {
        box-shadow: inset 0 2px 0 ${colors.primary};
      }

      && td {
        border-color: ${colors.primary};
      }

      & + tr {
        &.selected,
        &.disabled {
          box-shadow: none;
        }
      }
    `}

  td {
    padding: 0.5rem 0.75rem;
    border-width: 1px 0 1px 0;
    border-style: solid;
    border-color: #f3f3f3;

    &:first-child {
      padding: 0.75rem;
      border-left-width: 1px;
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-right-width: 1px;
      border-radius: 0 4px 4px 0;
    }
  }

  &:first-child {
    td {
      &:first-child {
        border-top-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
      }
    }
  }

  &:last-child {
    td:first-child {
      border-bottom-left-radius: 4px;
    }
    td:last-child {
      border-bottom-right-radius: 4px;
    }
  }
`;

const Lock = styled(LockSmallIcon)`
  opacity: 0.7;
`;

const NameCell = styled.td`
  white-space: normal;
`;
const InstrumentTypeCell = styled.td`
  font-size: ${fontSize.smaller};
`;

const TradeSideCell = styled.td`
  text-align: center;
`;

const buyGreen = '147deg 40% 40%';
const sellRed = '15deg 82% 56%';

const OrderSideTag = styled.div<{ orderSide: OrderSideType | undefined }>`
  font-size: ${fontSize.small};
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.2rem 0.7rem;
  display: inline;
  text-align: center;
  border-radius: 15px;
  ${(props) =>
    props.orderSide === SELL &&
    css`
      color: hsl(${sellRed});
      background: hsl(${sellRed} / 0.1);
      border: 1px solid hsl(${sellRed} / 0.3);
    `};
  ${(props) =>
    props.orderSide === BUY &&
    css`
      color: hsl(${buyGreen});
      background: hsl(${buyGreen} / 0.1);
      border: 1px solid hsl(${buyGreen} / 0.3);
    `};
`;

const TooLowTradeAmount = styled.div`
  letter-spacing: 0.2px;
  line-height: 1.3;
  color: ${colors.dark_gray};
  font-size: 13px;
  white-space: normal;
`;

const NumberCell = styled.td<{ red?: boolean }>`
  text-align: right;
  ${(props) =>
    props.red &&
    css`
      color: ${colors.red};
      font-weight: bold;
    `}
`;

const RadioButtonCell = styled.td`
  width: 2rem;
  text-align: center;
`;

const StyledRadioButton = styled(RadioButton)`
  margin-right: 0;
`;

export const SpacerCell = styled.td`
  && {
    padding: 0;
    opacity: 1;
    border: none;
  }
  background: ${colors.med_gray};
  width: 2px;
`;

const ErrorsRow = styled.tr`
  font-size: 13px;
  color: ${colors.red};

  && td {
    padding-left: calc(20px + 2.25rem);
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 0.5rem;
  }
`;

const TDTextCenter = styled.td`
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 13px;
`;

const SpinnerCell = styled.td`
  will-change: opacity, transform;
`;
