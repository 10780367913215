import React, { ChangeEvent } from 'react';
import translate from 'counterpart';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import FormSelectionItem from 'features/allocator/common/FormSelectionItem';
import { colors } from 'styles/colors';
import { FlexColumn, HelpText, FlexAlignCenter } from 'features/common/StyledComponents';
import {
  setSelectedInstrumentGroup,
  setInstrumentsSelected,
} from 'features/allocator/investmentPlan/investmentPlanInstrumentsActions';
import { RootState } from 'types/rootState';
import { Button } from 'features/allocator/common/Button';
import { InstrumentGroup } from 'types/commonDataState';
import { ALL_INSTRUMENTS_GROUP } from 'constants/allocator';
import { selectCustomerId } from 'features/profile/profileSelectors';
import { selectInstrumentsSelected } from 'features/allocator/allocatorSelectors';
import { useAppDispatch } from 'core/hooks';

export const SELECT_CORE_MANUALLY = 'none';

interface Props {
  planChosen: string;
  instrumentGroups: InstrumentGroup[];
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const ChoosePlan = ({ planChosen, instrumentGroups, onChange }: Props) => {
  const customerId = useSelector(selectCustomerId);
  const instrumentsSelected = useSelector(selectInstrumentsSelected);
  const selectedInstrumentGroup = useSelector(
    (state: RootState) => state.portfolioManager.investmentPlan.instruments.selectedInstrumentGroup
  );
  const noInstruments = instrumentsSelected.length === 0;

  const dispatch = useAppDispatch();

  const goToInstrumentSelection = () => {
    dispatch(setSelectedInstrumentGroup(SELECT_CORE_MANUALLY));
    dispatch(push(`customer/${customerId}/portfolioManager/allocator/instruments/`));
    if (selectedInstrumentGroup !== SELECT_CORE_MANUALLY) {
      dispatch(setInstrumentsSelected([]));
    }
  };

  return (
    <CoreSelector>
      <h2>{translate('allocator.configureCore.cardTitle')}</h2>
      <HelpTextWithMargin>{translate('allocator.configureCore.description')}</HelpTextWithMargin>
      <FlexColumn>
        {instrumentGroups
          .filter((g) => g.groupId !== ALL_INSTRUMENTS_GROUP)
          .map((g) => (
            <div key={g.groupId} data-testkey={`choose-plan-${g.groupId}`}>
              <FormSelectionItemWithMargin
                key={g.groupId}
                selected={planChosen === g.groupId}
                value={g.groupId}
                onChange={onChange}
                bg={colors.gray_light}
              >
                {g.displayName}
              </FormSelectionItemWithMargin>
            </div>
          ))}
        <EmptyCore>
          <HelpTextWithMargin>{translate('allocator.configureCore.createEmpty')}</HelpTextWithMargin>
          <FormSelectionItemWithMargin
            selected={planChosen === SELECT_CORE_MANUALLY}
            value={SELECT_CORE_MANUALLY}
            onChange={onChange}
            bg={colors.gray_light}
            data-testkey="choose-plan-manual"
          >
            {translate('allocator.configureCore.emptyCoreSelection')}
            {planChosen === SELECT_CORE_MANUALLY && (
              <SelectInstrumentsContainer>
                {!noInstruments &&
                  selectedInstrumentGroup === SELECT_CORE_MANUALLY &&
                  `(${instrumentsSelected.length} valittu)`}
                <SelectInstrumentsButton
                  onClick={goToInstrumentSelection}
                  data-testkey="select-instruments"
                  label={
                    selectedInstrumentGroup !== SELECT_CORE_MANUALLY
                      ? translate('allocator.configureCore.choose')
                      : translate('allocator.configureCore.modify')
                  }
                />
              </SelectInstrumentsContainer>
            )}
          </FormSelectionItemWithMargin>
        </EmptyCore>
      </FlexColumn>
    </CoreSelector>
  );
};

const SelectInstrumentsContainer = styled(FlexAlignCenter)`
  position: absolute;
  right: 5px;
`;

const SelectInstrumentsButton = styled(Button)`
  margin-left: 1rem;
`;

const HelpTextWithMargin = styled(HelpText)`
  margin-bottom: 1rem;
`;

const CoreSelector = styled(FlexColumn)`
  max-width: 50%;
`;

const EmptyCore = styled.div`
  margin: 1rem 0 0;
`;

const FormSelectionItemWithMargin = styled(FormSelectionItem)`
  padding: 0 1rem;
  margin-bottom: 0.5rem;
`;
