export default {
  accountNumber: 'Vastatilin numero',
  actualRisk: 'Ennen toimeksiantoa:',
  additionalInfo: 'Lisätiedot',
  addSecurity: 'Lisää arvopaperi',
  adviceDelivery: 'Asiakas hyväksyy sijoitusneuvonnan lausunnon toimituksen toimeksiannon toteuttamisen jälkeen.',
  assetManagementFee: 'Varainhoito\u200bpalkkio',
  backToOrders: 'Palaa toimeksiantoihin',
  bank: 'Säilytys\u200byhteisö',
  basicInfo: 'Perustiedot',
  Bond: 'JVK',
  bondPriceType: 'Hinnan tyyppi',
  buy: 'Osta',
  cancel: 'Peruuta',
  capitalCall: 'Pääomakutsu',
  capitalCallEmail: 'Sähköpostiosoite pääomakutsulle',
  capitalCallMethod: 'Pääomakutsut lähetetään',
  Certificate: 'Sertifikaatti',
  CertificateStructured: 'Strukturoitu sertifikaatti',
  change: 'Muutos',
  changeNotAllowed: 'Vaihto ei ole sallittu',
  checkBasicInfo: 'Tarkista asiakkaan perustiedot.',
  childMoney: 'Lapsen varoja',
  clearValueText: 'Tyhjennä',
  confirm: 'Vahvista',
  confirmContent:
    'Olethan varmistanut asiakkaalta tietojen oikeellisuuden ja halutun allekirjoitustavan? Sähköisesti allekirjoitettavat toimeksiannot lähetetään nyt allekirjoittajille.',
  confirmOrderCreation: 'Vahvista toimeksiantojen luonti',
  confirmNote1:
    'Asiakas hallitsee englanninkieltä siten, että pystyy tutustumaan englanninkieliseen materiaalin ja pystyy tekemään päätöksen sijoittamisesta sen perusteella.',
  confirmNote2:
    'Asiakas on ammattimainen asiakas tai pyytää tulla kohdelluksi ammattimaisena asiakkaana tämän sijoituksen osalta, ja hänellä on riittävät tiedot ja osaaminen tehdä itsenäisiä sijoituspäätöksiä ja ymmärtää niihin liittyvät riskit.',
  confirmNote3:
    'Mikäli asiakas pyytää tulla kohdelluksi ammattimaisena asiakkaana tämän sijoituksen osalta, asiakkaan tulee joko',
  confirmNote4: 'olla yhteisö, jonka pääasiallinen toimiala on rahoitusvälineisiin sijoittaminen TAI',
  confirmNote5: 'täyttää kaksi seuraavista vaatimuksista:',
  confirmNote6: 'Asiakkaan sijoitusten kokonaisarvo on vähintään 500.000 euroa',
  confirmNote7: 'Asiakas on tehnyt huomattavan suuria sijoituksia keskimäärin kymmenen kertaa vuosineljänneksessä',
  confirmNote8: 'Asiakas toimii tai on toiminut vähintään vuoden ajan tehtävässä, joka edellyttää sijoitustietämystä',
  confirmNote9:
    'Asiakas on tietoinen ja hyväksyy, että ammattimaisena asiakkaana hän ei ole sijoittajien korvausrahaston ja sijoituspalvelulain menettelytapasäännösten suojan piirissä.',
  confirmNoteConflictOfInterest:
    'Asiakkaalle on kerrottu sijoitukseen liittyvästä eturistiriidasta ja asiakas on sen hyväksynyt.',
  createOrders: 'Luo toimeksiannot',
  creatingContractFailed: 'Toimeksiannon luonti epäonnistui',
  currency: 'Valuutta',
  customerRisks: 'Asiakkaan kokonaisriskiluvut',
  dailyInfo: 'Päivän tiedot',
  empty: 'Tyhjennä valinnat',
  ETF: 'ETF',
  exchange: 'Vaihda',
  exchangeAll: 'Vaihda kaikki',
  expireDate: 'Päättymispäivä',
  fee: 'Palkkio',
  fetchFailed: 'Haku epäonnistui',
  financingBuy: 'Oston rahoitus',
  financingSell: 'Myynnin rahoitus',
  Fund: 'Sijoitusrahasto',
  fundsOrigin: 'Varojen alkuperä',
  fundsOriginAlert:
    'Edellyttää maistraatin lupaa, merkintä Diven kautta. Ota tarvittaessa yhteys sijoitusasiantuntijaan.',
  gift: 'Lahja',
  giveType: 'Toimeksiannon antaja',
  goBack: 'Palaa',
  customerHasSeenDocumentsLabel:
    'Asiakkaalle on toimitettu toimeksiannon kohteena oleviin rahoitusvälineisiin liittyvät dokumentit ja hänellä on ollut mahdollisuus tutustua niiden sisältöön.',
  id: 'Tunnus',
  incompleteInfoWhenMovingFrontPage:
    'Salkussa on keskeneräisiä toimeksiantoja, jotka häviävät, jos siirryt etusivulle. Haluatko siirtyä etusivulle?',
  instrumentForm: 'Rahoitusvälinetyyppi',
  investmentAdvice: 'Sijoitusneuvonta',
  Limit: 'LMT',
  loadingPlaceholder: 'Hakee...',
  Market: 'MKT',
  marketPrice: 'Markkinahinta',
  marketValue: 'Markkina-arvo',
  marketValueChange: 'Muutos',
  marketValueOfMoneyPositions: 'Tapahtumapäivän saldo',
  money: 'Toimeksiannon rahoitus',
  moneyTransfers: 'Toimeksiantoon liittyvät rahasiirrot',
  newOrder: 'Uusi toimeksianto',
  noEmission: 'Tuotteella ei ole emissio käynnissä',
  noResultsText: 'Tuotteita ei löytynyt',
  notificationLombard: 'MUISTA TOIMITTAA LOMBARDIN WAIVER!',
  notificationSwiss: 'MUISTA TOIMITTAA SWISS LIFEN WAIVER JA PIPA!',
  notSuitable: 'Tuote ei sovellu asiakkaalle',
  noValidationAgainstPositions: 'Myyntiriviä ei validoida asiakkaan ajantasaisia omistuksia vasten',
  Option: 'Optio',
  orderConfirmation: 'Olen käynyt läpi seuraavat kohdat asiakkaan kanssa',
  orderInfo: 'Toimeksiannon tiedot ja yhteenveto',
  orders: 'Toimeksiannot',
  ordersOfNewFunds: 'Kaikkien salkkujen uudet toimeksiannot',
  orderSummary: 'Yhteenveto toimeksiannoista',
  orderSent:
    'Toimeksianto on lähetetty eteenpäin. Tarkistathan, että saat sähköpostitse kuittauksen toimeksiannon läpimenosta. Rahastotoimeksiannot voit tarkistaa pankkiirin työpöydän toimeksiantonäkymästä.',
  orderType: 'Toimeksiannon tyyppi',
  pledgedWarning: 'Salkku on pantattu, joten toimeksiantoja ei voi tehdä. Ota yhteys sijoitusasiantuntijaan.',
  portfolio: 'Salkku',
  portfolioName: 'Salkun nimi',
  portfolioHasIncompleteOrders: 'Salkussa on keskeneräisiä toimeksiantoja',
  purchaseValue: 'Hankinta-arvo',
  qty: 'kpl',
  quantityBiggerThanPosition: 'Määrä on suurempi kuin viimeksi päivitetyt omistukset',
  receiveDate: 'Vastaanottoaika',
  receiverEmail: 'Toimeksiannon laskun vastaanottajan sähköposti',
  receiveType: 'Vastaanottotapa',
  referenceNumber: 'Viite',
  saveChanges: 'Tallenna muutokset',
  search: 'Hae...',
  searchingForInstrument: 'Haetaan tuotetta %(value)s...',
  searchForInstrument: 'Hae rahoitusvälinettä...',
  searchExchange: 'Hae vaihdon kohderahoitusvälinettä...',
  searchingText: 'Hakee...',
  searchPromptText: 'Kirjoita hakusana',
  select: 'Valitse...',
  sell: 'Myy',
  sellAll: 'Myy kaikki',
  sendBillToCustomer:
    'Tämän toimeksiannon lasku lähtee automaattisesti ylläolevaan sähköpostiosoitteeseen. Voit halutessasi vaihtaa laskun vastaanottajan.',
  sendBillToExpert: 'Sijoitusasiantuntija toteuttaa tarvittavat rahasiirrot.',
  sendOrders: 'Lähetä toimeksiannot',
  sendOrdersAndAdvices: 'Lähetä toimeksiannot ja sijoitusneuvonta',
  side: 'Kaupan suunta',
  sign: 'Allekirjoitus',
  signatoriesAdditionFailed: 'Allekirjoittajien lisäys epäonnistui',
  signatures: 'Allekirjoitukset',
  simulatedRisk: 'Toimeksiannon jälkeen:',
  simulatedAndAdvicedRisk: 'Myös ehdotettujen toimeksiantojen jälkeen:',
  Share: 'Osake',
  status: 'Status',
  stay: 'Jää salkkuun',
  StructuredProduct: 'Strukturoitu tuote',
  SubscriptionRight: 'Merkintäoikeus',
  suitabilityInfoChangePortfolio:
    'Syötetty toimeksianto, %(name)s, ei sovellu salkkuun %(portfolio)s. Haluatko palata edelliseen salkkuun vai jäädä tähän salkkuun, jolloin syöttämäsi tiedot tyhjennetään?',
  suitabilityInfoChangeSide:
    'Kaupan suunta ei ole sallittu toimeksiannolle %(name)s salkussa %(portfolio)s. Haluatko palata edelliseen valintaan tai tyhjentää syöttämäsi tiedot?',
  suitabilityInfoChangeSideAndCanOnlyEmpty:
    'Kaupan suunta ei ole sallittu toimeksiannolle %(name)s salkussa %(portfolio)s. Myös aiempi valintasi ei ollut sallittu joten syöttämäsi tiedot tyhjennetään',
  suitabilityInfoFromButton:
    'Syötetty toimeksianto, %(name)s, ei sovellu salkkuun %(portfolio)s. Haluatko palata toimeksiantoon tai tyhjentää syöttämäsi tiedot?',
  suitabilityInfoHeader: 'Syötetty toimeksianto ei sovellu salkkuun',
  suitabilityRule: '(Sääntö %(ruleId)s: %(message)s )',
  summaryOfAssets: 'Yhteenveto varoista',
  summaryOfPortfolios: 'Yhteenveto salkuista',
  toFrontPage: 'Etusivulle',
  tradingFee: 'Kaupankäynti\u200bpalkkio',
  Warrant: 'Warrantti',
  whereDoesMoneyComeFrom: 'Mistä raha tulee',
  withdravalBalance: 'Käytettävissä oleva saldo',
  PrivateEquity: 'Pääomarahasto',
  PrivateEquityProfitSharingLoan: 'Pääomarahasto',
  CoInvestments: 'Kanssasijoitus',
  CoInvestmentsProfitSharingLoan: 'Kanssasijoitus',
  statementDetailsFrom: 'Sijoitusneuvonnan lausunto',
  basisOfAdviceLabel: 'Toimeksiannon lisätiedot',
  basisOfAdviceHelpText:
    'Kirjaa lisätietoihin mitä toimenpiteitä on suositeltu ja miksi. Jos asiakas poikkeaa suosituksesta, kirjaa miten asiakas poikkeaa ja miksi. Lisätiedot ovat osa soveltuvuuslausuntoa ja näkyvät asiakkaan tulosteella.',
  basisOfAdviceRequiredText: 'Toimeksiannon lisätiedot on pakollinen tieto',
  basisOfAdvice: [
    { value: 'marketOpportunity', label: 'Markkinamahdollisuus' },
    { value: 'increaseRisk', label: 'Riskin nosto' },
    { value: 'decreaseRisk', label: 'Riskin alentaminen' },
  ],
  authorityToSign: 'Nimenkirjoitusoikeus',
  authorityToSignNotFetched: 'Nimenkirjoitusoikeustietoja ei haettu',
  update: 'Päivitä',
  updated: 'Päivitetty',
  incompleteInfo:
    'Sinulla on keskeneräinen toimeksianto. Tallentamattomat tiedot häviävät, jos vaihdat salkkua. Haluatko vaihtaa salkkua?',
  unsavedChanges: 'Tallentamattomia muutoksia',
  switchPortfolio: 'Vaihda salkkua',
  removeOrders: 'Poista toimeksiannot',
  send: 'Lähetä',
  move: 'Siirry',
  toCustomerSearch: 'Asiakashakuun',
  return: 'Palaa',
  toPortfolio: 'Salkkuun',
  useLeverage: 'Merkinnässä käytetään lainarahaa/vipua',
  internalNote: 'Aktian sisäisiä merkintöjä',
  riskCalculationFailed: 'Riskin laskenta epäonnistui',
  basis: 'Toimeksiannon peruste',
  selectBasis: 'Haku ei käytettävissä, valitse ',
  representativeName: 'Edustajan nimi',
  representativeSsn: 'Edustajan henkilötunnus',
  appropriatenessTest: 'Asianmukaisuusarvio',
  testPassed: 'hyväksytty',
  testFailed: 'hylätty',
  testIgnored: 'ohitettu',
  loadingAppropriatenessTest: 'Ladataan asianmukaisuusarviota...',
  appropriatenessTestCouldNotBeLoaded: 'Asianmukaisuusarviota ei voitu ladata.',
  abortAppropriatenessTest: 'Keskeytä',
  sendingAppropriatenessTestReceivingResults: 'Lähetetään asianmukaisuusarvio ja haetaan tulokset, odota hetki...',
  continue: 'Jatka',
  appropriatenessTestResultCouldNotBeRetrieved: 'Asianmukaisuusarvion tulosta ei voitu hakea.',
  appropriatenessTestResultRetrievalError: 'Asianmukaisuudenarvion tulosta ei voitu hakea, tapahtui virhe.',
  appropriatenessTestCanceled: 'Asianmukaisuusarvio peruutettu. Tee uusi instrumenttihaku.',
  appropriatenessTestIngress:
    'Olet tekemässä toimeksiantoa asianmukaisuusarvion vaativaan tuotteeseen %(name)s. Haluamme varmistaa, että tietosi ja kokemuksesi ovat riittäviä tuotteen ostamiseksi.',
  appropriatenessTestExchangeIngress:
    'Olet vaihtamassa tuotetta %(pair)s asianmukaisuusarvion vaativaan tuotteeseen %(name)s. Haluamme varmistaa, että tietosi ja kokemuksesi ovat riittäviä tuotteen vaihtamiseksi.',
  sendAppropriatenessTest: 'Lähetä asianmukaisuusarvio',
  cancelAppropriatenessTest: 'Peruuta',
  answerOptionYes: 'Kyllä',
  answerOptionNo: 'Ei',
  answerOptionNotKnown: 'En tiedä',
  appropriatenessTestCouldNotBeLoadedErrorStart: 'Tapahtui virhe. Merkittävälle arvopaperille',
  appropriatenessTestCouldNotBeLoadedErrorEnd: 'ei ole saatavilla asianmukaisuusarvion testiä.',
  appropriatenessTestCouldNotBeLoadedStart: 'Tapahtui virhe. Merkittävälle arvopaperille',
  appropriatenessTestCouldNotBeLoadedEnd:
    'ei ole saatavilla asianmukaisuusarvion testiä, koska kategorian tunnus (ProductCategoryId) puuttuu Aktistockista.',
  pleaseWait: 'Odota hetki',
  appropriatenessTestFailed:
    'Aktia arvioi, että valittu sijoituskohde ei ole asiakkaalle asianmukainen huomioiden asiakkaan sijoituskokemus ja -tietämys. Kyseisen tuoteryhmän asianmukaisuusarvioinnin voi suorittaa uudestaan kerran. Mikäli kahden suoritetun asianmukaisuusarvioinnin jälkeen tulos on, että valittu sijoituskohde ei ole asiakkaalle asianmukainen, ei toimeksiantoa sijoituskohteeseen voi jättää. Kyseisen tuoteryhmän asianmukaisuusarvioinnin voi uusia tässä tapauksessa 30 päivän päästä.',
  mytyTextError:
    'Toimeksiannon tekstien haussa havaittu virhe. Pdf saattaa olla puutteellinen. Ota yhteyttä käytön tukeen. Puhelin: (010 247) 6000, valinta 4. Sähköposti: spara@aktia.fi & saastaminen@aktia.fi. Teams: Aktia Varainhoito - 6000 - säästämisen tuki.',
  minutesOfInvestmentService: {
    title: 'Pöytäkirja sijoituspalvelusta',
    date: 'Päivämäärä ja kellonaika',
    location: 'Tapaamispaikka',
    participants: 'Läsnäolijat',
    convener: 'Koollekutsuja',
    info: 'Kooste ja olennaiset tiedot keskustelusta ja toimeksiannosta',
  },
  minutesOfInvestmentServiceTip:
    'Pöytäkirjan sisältövaateet perustuvat sääntelyyn. "Kooste ja olennaiset tiedot keskustelusta ja toimeksiannosta" -osioon kirjataan kaikki lisätiedot ja seikat, jotka vaikuttivat keskusteluun, sijoitusneuvoon tai suoritettuihin toimenpiteisiin. Tällaisia lisätietoja ovat esimerkiksi erilaiset muutokset markkinatilanteessa, asiakkaan sijoitustavoitteissa, tarpeissa, elämäntilanteessa tai taloudellisessa tilanteessa. Pöytäkirja kirjataan erikseen jokaiseen rahoitusvälineeseen. "Lisää arvopaperi" kopioi ensimmäisen pöytäkirjan tiedot automaattisesti seuraavalle arvopaperille. Jos teet muutoksia yksittäisen arvopaperin tietoihin, muista tallentaa muutokset painamalla "tallenna muutokset". Näin varmistat, että muutokset kirjataan vain kyseisen arvopaperin kohdalle eivätkä ne vaikuta muihin pöytäkirjoihin. Valmiit pöytäkirjat tallentuvat sekä CRM-järjestelmään että asiakkaan tulosteelle. Tällä varmistetaan, että kaikki tarvittavat tiedot ovat saatavilla ja että asiakkaalle jää oma kopio tapaamisen yksityiskohdista.',
  minutesOfInvestmentServiceNotRequired:
    'Täyden valtakirjan toimeksiannoissa pöytäkirja ei aina ole pakollinen, kirjaa tarvittaessa',
  suitabilityAssessmentToDate: 'Soveltuvuusarviointi on ajan tasalla',
};
