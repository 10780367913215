import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import translate from 'counterpart';
import { GroupedOrderLines } from 'features/orderLines/GroupedOrderLines';
import {
  selectReceiveInfo,
  selectOrderDetails,
  selectInstrumentGroupsFromOrderLines,
} from 'features/orders/ordersSelectors';
import { CheckBox } from './CheckBox';
import { OrderConfirmations } from './OrderConfirmations';
import {
  OrderPreviewType,
  ShowHasSeenDocumentsToggle,
  IsAdviceDeliveryVisible,
  IsOrderConfirmed,
} from 'types/portfolioPreviewTypes';
import { changeContractField } from 'features/orders/ordersActions';
import { ImmutableOrderLineTypeList } from 'types/orderLinesState';
import { SIGNATURE_NOT_REQUIRED } from 'constants/contractStates';
import { RootState } from 'types/rootState';
import { OrderSignatory } from 'features/orders/components/OrderSignatory';
import { useAppDispatch } from 'core/hooks';
import { ModalDialog } from 'features/common/ModalDialog';
import StyledButton from 'features/common/styledComponents/StyledButton';

interface Props {
  orderPreview: OrderPreviewType;
  orderLines: ImmutableOrderLineTypeList;
  showHasSeenDocumentsToggle: ShowHasSeenDocumentsToggle[];
  isAdviceDeliveryVisible: IsAdviceDeliveryVisible[];
  isOrderConfirmed: IsOrderConfirmed[];
  getNotifications: () => void;
}

export const OrderPreview = ({
  orderPreview,
  orderLines,
  showHasSeenDocumentsToggle,
  isAdviceDeliveryVisible,
  isOrderConfirmed,
  getNotifications,
}: Props) => {
  const dispatch = useAppDispatch();

  const portfolioId = orderPreview.portfolioId;
  const orderDetailsList = useSelector((state: RootState) => selectOrderDetails(state, portfolioId));
  const instrumentGroups: string[] = useSelector(selectInstrumentGroupsFromOrderLines);
  const [mytyTextErrorModalIsOpen, setMytyTextErrorModalIsOpen] = useState<boolean>(false);
  const isMytyTextErrorModalShown = useRef(false);
  const mytyTextError = 'order.mytyTextError';

  const onAdviceDeliveryChange = (value: boolean, portfolioId: string) => {
    dispatch(changeContractField('sections.receiveInfo.adviceDeliveryAfterwards', value, portfolioId));
  };

  const onOrderConfirmationChange = (value: boolean, portfolioId: string) => {
    dispatch(changeContractField('sections.receiveInfo.isOrderConfirmed', value, portfolioId));
  };

  // if any of instruments in the order lines are linked to instrumentGroups there might be
  // extra order confirmation texts that have to be added to order preview based on instrumentGroup
  const getOrderConfirmations = () => {
    if (instrumentGroups) {
      return (
        <OrderConfirmations
          onOrderConfirmationChange={(_event: React.ChangeEvent<HTMLInputElement>) =>
            onOrderConfirmationChange(_event.target.checked, portfolioId)
          }
          instrumentGroups={instrumentGroups}
          isOrderConfirmed={isOrderConfirm}
        />
      );
    } else {
      return null;
    }
  };

  const setHasSeenDocuments = (value: boolean, portfolioId: string) => {
    dispatch(changeContractField('sections.receiveInfo.customerHasSeenDocuments', value, portfolioId));
  };

  const receiveInfo = selectReceiveInfo(orderPreview.order.contract);
  const filteredOrderlines = orderLines.filter((x) => x?.toJS().portfolioId === portfolioId);
  const isAdviceDelivered = orderPreview.order.contract.getIn(['sections', 'receiveInfo', 'adviceDeliveryAfterwards']);
  const isAdviceVisible = isAdviceDeliveryVisible.find((x) => x.portfolioId === portfolioId)?.isAdviceDeliveryVisible;
  const isOrderConfirm = isOrderConfirmed.find((x) => x.portfolioId === portfolioId)?.isOrderConfirmed;
  const hasSeenSeenDocumentsToggle = showHasSeenDocumentsToggle.find(
    (x) => x.portfolioId === portfolioId
  )?.hasSeenDocumentsToggle;

  const closeMytyErrorModal = () => {
    setMytyTextErrorModalIsOpen(false);
  };

  const openMytyErrorModal = () => {
    if (!mytyTextErrorModalIsOpen && !isMytyTextErrorModalShown.current) {
      setMytyTextErrorModalIsOpen(true);
      isMytyTextErrorModalShown.current = true;
    }
  };

  // Show only orderLines which require signature but don't hide orderLines that are updated to these values
  const filteredOrderDetailsList = orderDetailsList.map((orderDetails) => {
    if (!SIGNATURE_NOT_REQUIRED.includes(orderDetails.signatureMethod)) {
      orderDetails.showSignatureSelection = true;
    }

    if (SIGNATURE_NOT_REQUIRED.includes(orderDetails.signatureMethod) && orderDetails.showSignatureSelection !== true) {
      orderDetails.showSignatureSelection = false;
    }

    const pdfError = orderDetails.pdfPreviewLink.get('error');
    if (pdfError) {
      openMytyErrorModal();
    }

    return orderDetails;
  });

  const mytyTextErrorModalActions = [
    <StyledButton variant="text" onClick={closeMytyErrorModal} key={mytyTextError}>
      Ok
    </StyledButton>,
  ];

  return (
    <React.Fragment key={portfolioId}>
      <ModalDialog
        title="Virhe"
        actions={mytyTextErrorModalActions}
        open={mytyTextErrorModalIsOpen}
        content={translate(mytyTextError)}
      />
      <div className="row">
        <div className="columns small-12">
          <GroupedOrderLines orderLines={filteredOrderlines} />
        </div>
      </div>

      <CheckBox
        label={translate('order.customerHasSeenDocumentsLabel')}
        onChange={(_event: React.ChangeEvent<HTMLInputElement>) =>
          setHasSeenDocuments(_event.target.checked, portfolioId)
        }
        showComponent={hasSeenSeenDocumentsToggle}
        value={!!receiveInfo.customerHasSeenDocuments}
        data-testkey={`customer-has-seen-documents-${portfolioId}`}
      />

      {getNotifications()}

      <CheckBox
        label={translate('order.adviceDelivery')}
        onChange={(_event: React.ChangeEvent<HTMLInputElement>) =>
          onAdviceDeliveryChange(_event.target.checked, portfolioId)
        }
        showComponent={isAdviceVisible}
        value={!!isAdviceDelivered}
        data-testkey={`advice-delivery-${portfolioId}`}
      />

      {getOrderConfirmations()}

      <div className="row">
        <div className="columns small-12">
          {filteredOrderDetailsList.map((orderDetails) => {
            return (
              <OrderSignatory
                key={orderDetails.orderLineIds.toString()}
                orderDetails={orderDetails}
                portfolioId={portfolioId}
              />
            );
          })}
        </div>
      </div>
      <PaddingDiv />
    </React.Fragment>
  );
};

const PaddingDiv = styled.div`
  width: auto;
  height: auto;
  padding-bottom: 2rem;
`;
