import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { InstrumentDistribution } from 'features/allocator/overview/components/InstrumentDistribution';
import { selectCurrentWeights } from 'features/allocator/allocatorSelectors';
import { AllocatorPortfolio } from 'types/investmentPlanState';
import { PortfolioNameRow } from 'features/allocator/overview/components/PortfolioNameRow';
import { isIgnoredPortfolio } from 'features/allocator/planPortfolios/planPortfolioUtils';
import { CompareToType, PlanLength, WeightType } from 'types/types';
import { weightTypes } from 'constants/allocator';

interface Props {
  planLength: PlanLength;
  compareTo: CompareToType;
  portfolio: AllocatorPortfolio;
  weightsToUse: WeightType;
}

export const Portfolio = ({ planLength, compareTo, weightsToUse, portfolio }: Props) => {
  const currentWeights = useSelector(selectCurrentWeights);

  const currentPortfolios =
    currentWeights?.portfolioWeights?.find((i) => i.portfolioId === portfolio.portfolioId)?.instruments || [];

  const isIgnored = isIgnoredPortfolio(portfolio);

  const items =
    weightsToUse === weightTypes.optimizedWeights ? portfolio.allocatedPortfolioRows[planLength] : currentPortfolios;

  return (
    <DistributionCard data-testkey="portfolio-contents">
      <PortfolioNameRow portfolio={portfolio} items={items} isIgnored={isIgnored} />
      <InstrumentDistribution
        instruments={items}
        compareTo={compareTo}
        itemType="portfolioInstrument"
        isIgnoredPortfolio={isIgnored}
      />
    </DistributionCard>
  );
};

const DistributionCard = styled.div`
  align-self: flex-start;
`;
