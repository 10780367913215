import React from 'react';

interface Props {
  onClick: (index: number) => void;
  index: number;
  label: string;
  className: string;
}

export default class ArrayQuestionButton extends React.Component<Props> {
  onClick = () => {
    this.props.onClick(this.props.index);
  };

  render() {
    const { label, className } = this.props;
    return (
      <button className={className} type="button" onClick={this.onClick}>
        <span>{label}</span>
      </button>
    );
  }
}
