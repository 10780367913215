import React from 'react';
import { range } from 'lodash';
import styled from 'styled-components';
import { RiskLevel } from 'types/types';
import { RiskSelectionButton } from 'features/allocator/riskSelection/components/RiskSelectionButton';

interface Props {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  risk: RiskLevel;
  disabled: boolean;
}

export const RiskSelection = ({ onClick, risk, disabled }: Props) => {
  const riskLevels = range(1, 11) as RiskLevel[];

  return (
    <RiskLevelsContainer>
      {riskLevels.map((riskLevel) => (
        <RiskSelectionButton
          key={riskLevel}
          selected={riskLevel === risk}
          onClick={onClick}
          riskLevel={riskLevel}
          disabled={disabled}
        />
      ))}
    </RiskLevelsContainer>
  );
};

const RiskLevelsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-self: center;
`;
