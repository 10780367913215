import React, { Component } from 'react';
import { connect } from 'react-redux';
import counterpart from 'counterpart';
import { createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import { branch, compose, renderNothing } from 'recompose';
import StyledSnackbar from 'features/common/styledComponents/StyledSnackbar';
import fi from 'locales/fi';
import sv from 'locales/sv';
import { getInitialData, hideNotification } from 'actions';
import { initializePortfoliosAndPositions } from 'features/portfolio/portfolioActions';
import { config } from 'config';
import TestDisclaimer from './TestDisclaimer';
import { selectIsUserSessionExpired } from 'features/oidc-callback/oidcSelectors';
import 'styles/foundation/custom.scss';
import 'styles/foundation/_avenirnext.scss';
import 'styles/react-select/react-select.scss';
import { RootState } from 'types/rootState';
import { initializeRisk } from 'features/risk/riskActions';

counterpart.registerTranslations('fi', fi);
counterpart.registerTranslations('sv', sv);

const theme = createTheme({
  spacing: 2,
  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: green[500],
    },
  },
});

interface Props {
  locale: string;
  children: object;
  getInitialData: (locale: string) => void;
  hideNotification: () => void;
  notification: string;
  showNotification: boolean;
  params: { id: string };
  customerId: string;
  initializePortfoliosAndPositions: () => void;
  initializeRisk: () => void;
}

class AppRoot extends Component<Props> {
  static defaultProps = {
    notification: '',
    showNotification: false,
  };

  static childContextTypes = {
    muiTheme: () => null,
  };

  getChildContext() {
    return {
      muiTheme: theme,
    };
  }

  componentDidMount() {
    const { locale, getInitialData } = this.props;
    getInitialData(locale);
  }

  componentDidUpdate() {
    const { params, customerId, initializePortfoliosAndPositions, initializeRisk } = this.props;
    if (params.id && customerId && params.id !== customerId) {
      initializePortfoliosAndPositions();
      initializeRisk();
    }
  }

  render() {
    const { children, notification, showNotification, hideNotification } = this.props;
    const disclaimer = config.appEnv !== 'pr' ? <TestDisclaimer env={config.appEnv} /> : undefined;
    return (
      <div className="root">
        <div className="content">{children}</div>
        {disclaimer}
        <StyledSnackbar
          autoHideDuration={3000}
          open={showNotification}
          message={notification}
          onClose={hideNotification}
        />
      </div>
    );
  }
}

function mapStateToProps(state: RootState) {
  return {
    locale: state.common.locale,
    showNotification: state.common.showNotification,
    notification: state.common.notification,
    isUserSessionExpired: selectIsUserSessionExpired(state),
    customerId: state.profile.customer.get('customerId'),
  };
}

export default compose(
  connect(mapStateToProps, {
    getInitialData,
    hideNotification,
    initializePortfoliosAndPositions,
    initializeRisk,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  branch(({ isUserSessionExpired }) => isUserSessionExpired, renderNothing)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
)(AppRoot);
