import React, { useState, MouseEvent } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import { isEmpty } from 'lodash';
import accounting from 'accounting';
import translate from 'counterpart';
import styles from './PortfolioFilter.scss';
import { MarketInfo } from 'types/portfolioState';
import { RootState } from 'types/rootState';
import { ConfirmationDialog } from './ConfirmationDialog';
import { setIsEditingExistingLine } from 'features/orderDialog/orderDialogActions';
import { useAppDispatch } from 'core/hooks';

interface Props {
  name: string;
  to: string;
  marketInfo?: MarketInfo | {};
  isSummary: boolean;
  bankerName?: string;
  responsiblePartyName?: string;
  portfolioId?: string;
}

export const PortfolioFilterItem = ({
  name,
  to,
  marketInfo = {},
  isSummary,
  bankerName = '',
  responsiblePartyName = '',
  portfolioId,
}: Props) => {
  const dispatch = useAppDispatch();
  const touchedFields = useSelector((state: RootState) => state.orderDialog.touchedFields.toJS());
  const currentFilter = useSelector((state: RootState) => state.routing.locationBeforeTransitions.query.f);

  const [showDialog, setShowDialog] = useState<boolean>(false);

  const handleClick = (event: MouseEvent) => {
    event.preventDefault();

    const filter = portfolioId || 'all';

    if (filter === currentFilter) {
      return;
    }

    if (!isEmpty(touchedFields)) {
      setShowDialog(true);
      return;
    }

    dispatch(setIsEditingExistingLine(false));
    navigate();
  };

  const hideConfirm = () => {
    setShowDialog(false);
  };

  const navigate = () => {
    setShowDialog(false);
    dispatch(push(to));
  };

  const getDetailText = () => {
    if (isSummary) {
      return null;
    }

    const values = [];
    values.push('Salkkuvastuu: ');
    if (responsiblePartyName) {
      values.push(responsiblePartyName);
    } else if (bankerName) {
      values.push(translate('portfolios.banker'));
    }

    return values.join(' ');
  };

  const getMarketValueText = () => {
    if (isSummary) {
      return null;
    }
    const marketValues = [];
    marketValues.push('Markkina-arvo: ');
    'marketValue' in marketInfo && marketValues.push(accounting.formatMoney(marketInfo.marketValue));
    'marketValue' in marketInfo && marketValues.push(marketInfo.marketValueCurrency);
    return marketValues.join(' ');
  };

  return (
    <li className={styles.portfolioItem}>
      <ConfirmationDialog hideConfirmDialog={hideConfirm} navigate={navigate} isOpen={showDialog} />

      <Link to={to} activeClassName={styles.selectedFilter} onClick={handleClick}>
        <div data-testkey={`portfolio-link-${portfolioId ?? 'all'}`}>{name}</div>
        <div className={styles.aum}>
          {getDetailText()}
          {!isSummary ? <br /> : null}
          {getMarketValueText()}
        </div>
      </Link>
    </li>
  );
};
