import React from 'react';
import translate from 'counterpart';
import _ from 'lodash';
import moment from 'moment';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import SelectOrderType from './SelectOrderType';
import { orderTypes, buyFunding, sellFunding, SECURITY_NOT_IN_REPORTING, ENOUGH_SHARES } from 'constants/shareOptions';
import { SelectFinancing } from './SelectFinancing';
import Date from './Date';
import { BUY, SELL } from 'constants/sides';
import Amount from './Amount';
import {
  EXPIRE_DATE,
  NOTE,
  ORD_TYPE,
  ORDER_FINANCING_BUY,
  ORDER_FINANCING_SELL,
  PRICE,
  BOND_PRICE_TYPE,
} from 'constants/orderFields';
import LimitPrice from './LimitPrice';
import { SECURITIES_BROKERAGE_CONTRACT } from 'constants/contractNames';
import { QuantityWarning } from './QuantityWarning';
import { BOND, STRUCTURED_PRODUCT } from 'constants/instrumentForms';
import { convertStringToDecimalNumber, isValidDecimalNumber } from 'core/portfolios';
import { Spinner } from 'features/common/Spinner';
import { ValidationErrorValue, TouchedFields } from 'types/orderDialogState';
import { PortfolioById } from 'types/portfolioState';
import { SelectValue } from 'types/types';
import { setEditorValue } from 'features/orderDialog/orderDialogActions';
import { OrderLineType } from 'types/ordersState';
import { useAppDispatch } from 'core/hooks';
import StyledTextField from 'features/common/styledComponents/StyledTextField';

interface Props {
  assignment: OrderLineType;
  onQuantityChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onValueChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  validationErrors: ValidationErrorValue[];
  touchedFields: TouchedFields;
  currency: string;
  selectedPortfolio: PortfolioById;
  isFetchingNominalValue: boolean;
  isAppropriatenessTestOk: boolean;
  isLoading: boolean;
}

export const ShareDetails = ({
  assignment,
  onQuantityChange,
  onValueChange,
  validationErrors,
  touchedFields,
  currency = '',
  selectedPortfolio,
  isFetchingNominalValue,
  isAppropriatenessTestOk,
  isLoading,
}: Props) => {
  const dispatch = useAppDispatch();

  const MIN_EXPIRE_DATE = moment().format('YYYY-MM-DD');

  const onOrderTypeChange = (input: SelectValue) => dispatch(setEditorValue(ORD_TYPE, input.value));

  const onBondPriceTypeChange = (input: SelectValue) => dispatch(setEditorValue(BOND_PRICE_TYPE, input.value));

  const onPriceChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const value = _.get(ev, ['target', 'value'], '');
    if (isValidDecimalNumber(value)) {
      dispatch(setEditorValue(PRICE, value === '' ? undefined : convertStringToDecimalNumber(value)));
    }
  };

  const onExpireDateChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setEditorValue(EXPIRE_DATE, ev.currentTarget.value));

  const onFinancingBuyChange = (input: SelectValue) =>
    dispatch(setEditorValue(ORDER_FINANCING_BUY, input ? input.value : undefined));

  const onFinancingSellChange = (input: SelectValue) =>
    dispatch(setEditorValue(ORDER_FINANCING_SELL, input ? input.value : undefined));

  const onNoteChange = (ev: React.ChangeEvent<HTMLInputElement>) =>
    dispatch(setEditorValue(NOTE, ev.currentTarget.value === '' ? undefined : ev.currentTarget.value));

  const sellFundingOptions = (contractName: string) => {
    const options = _.cloneDeep(sellFunding);
    if (contractName === SECURITIES_BROKERAGE_CONTRACT) {
      _.remove(options, (opt) => opt.value === ENOUGH_SHARES);
    }
    return options;
  };

  return (
    <div>
      {isFetchingNominalValue || isLoading ? (
        <Spinner />
      ) : (
        <div className="row" key="volume">
          <div className={'columns small-4'}>
            <Amount
              assignment={assignment}
              currency={currency}
              touchedFields={touchedFields}
              onValueChange={onValueChange}
              onQuantityChange={onQuantityChange}
              validationErrors={validationErrors}
            />
          </div>
          <SelectOrderType
            options={orderTypes}
            onChange={onOrderTypeChange}
            value={assignment[ORD_TYPE]}
            className="columns small-3"
          />
          <div className={'columns small-5'}>
            <LimitPrice
              assignment={assignment}
              validationErrors={validationErrors}
              touchedFields={touchedFields}
              onPriceChange={onPriceChange}
              onBondPriceTypeChange={onBondPriceTypeChange}
            />
          </div>
        </div>
      )}
      <QuantityWarning
        side={assignment.side}
        orderFinancingSell={assignment.orderFinancingSell}
        volume={
          assignment.instrumentForm === STRUCTURED_PRODUCT || assignment.instrumentForm === BOND
            ? assignment.amount
            : assignment.quantity
        }
        currentQuantity={assignment.currentQuantity}
      />
      <div className="row" key="date">
        <div className="columns small-3">
          <Date
            label={translate('order.expireDate')}
            value={assignment[EXPIRE_DATE] || ''}
            onChange={onExpireDateChange}
            type="date"
            min={MIN_EXPIRE_DATE}
          />
        </div>
        <div className="columns small-9">
          <FormGroup>
            <FormLabel
              sx={{
                color: '#000000',
                fontSize: '12px',
                marginTop: '1.1rem',
              }}
            >
              {translate('order.additionalInfo')}
            </FormLabel>
            <StyledTextField
              fullWidth={true}
              type={'text'}
              onChange={onNoteChange}
              value={assignment.note || ''}
              data-testkey={'order-additional-info'}
              variant="standard"
              sx={[
                {
                  '& .Mui-focused': {
                    '&::after': {
                      left: 0,
                    },
                  },
                  '& .MuiFormLabel-root': {
                    fontFamily: 'Calibre',
                    marginTop: '1.5rem',
                  },
                  '& .MuiInputBase-root': {
                    paddingLeft: 0,
                    '&::before': {
                      left: 0,
                    },
                  },
                  '& .MuiInputBase-input': {
                    marginTop: 0,
                  },
                },
              ]}
            />
          </FormGroup>
        </div>
      </div>
      <div className="row" key="financing">
        {assignment.side === BUY ? (
          <SelectFinancing
            options={buyFunding}
            value={assignment.orderFinancingBuy}
            onChange={onFinancingBuyChange}
            className="columns small-6"
            clearable={true}
            label={translate('order.financingBuy')}
            data-testkey={'order-financing-buy'}
          />
        ) : (
          <SelectFinancing
            options={sellFundingOptions(selectedPortfolio.contractName)}
            value={assignment.orderFinancingSell}
            onChange={onFinancingSellChange}
            className="columns small-6"
            clearable={true}
            label={translate('order.financingSell')}
            data-testkey={'order-financing-sell'}
          />
        )}
        {assignment.side === BUY && isAppropriatenessTestOk ? (
          <div className="columns small-6" style={{ marginTop: '2rem', fontSize: '1rem' }}>
            Asianmukaisuusarviointi <i className="icon icon-check" />
          </div>
        ) : undefined}
        {assignment.side === SELL && assignment.orderFinancingSell === SECURITY_NOT_IN_REPORTING ? (
          <div className="columns small-6" style={{ marginTop: '2rem', fontSize: '.875rem' }}>
            {translate(`order.noValidationAgainstPositions`)}
          </div>
        ) : undefined}
      </div>
    </div>
  );
};
